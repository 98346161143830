/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from "react";
import {
  ActionItem,
  ActionItemProps,
  MenuList,
  Popover,
  Button,
} from "@komodorio/design-system/deprecated";
import { ChevronDown16, ChevronRight16 } from "@komodorio/design-system/icons";
import { HelmChartSummary, ResourceTableModelRow } from "komodor-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import {
  ActionsUpgradeRequired,
  useActionsNeedUpgradeAgent,
} from "../Actions/common/actionsUpgradeRequired";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../shared/hooks/analytics";
import { extractPathFromLocation } from "../Actions/common/utils";
import { ResourceTabName } from "../ResourceView/resources";
import ScaleModal from "../Actions/modals/ScaleModal/ScaleModal";
import RestartModal from "../Actions/modals/RestartModal/RestartModal";
import DeleteModal from "../Actions/modals/DeleteModal/DeleteModal";
import JobRerunModal from "../Actions/modals/RerunJobModal/RerunJobModal";
import JobRunNowModal from "../Actions/modals/RunNowJobModal/RunNowJobModal";
import EditModal from "../Actions/modals/EditModal/EditModal";
import CordonUncordonModal from "../Actions/modals/CordonModal/CordonModal";
import DrainModal from "../Actions/modals/DrainModal/DrainModal";
import { useUserMetadata } from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { ContainerSelection } from "../Actions/buttons/ExecPodShell/ContainerSelection";
import {
  isAgentAtmSupported,
  REQUIRED_AGENT_PORT_FORWARDING,
  REQUIRED_AGENT_VERSION_POD_EXEC,
} from "../../shared/utils/agent/agent";
import { useHasPermissions } from "../../shared/hooks/useUserMetadata/rbac";
import { AriaLabels } from "../../shared/config/ariaLabels";
import {
  SupportedResourceKindEnum,
  usePortForward,
} from "../../shared/context/PortForwardProvider";
import useAtmGetResource, {
  AtmGetResourceResponseType,
} from "../../shared/hooks/ATM/requests/useAtmGetResource";
import { AtmOutputType } from "../../shared/hooks/ATM/useAtmRequest";
import { useDrawersStackStore } from "../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../shared/store/drawersStackStore/types";
import { SELECTED_TAB_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { ZIndex } from "../../constants/zIndex";

import {
  AvailableActions,
  KubernetesPodsResource,
  KubernetesResource,
} from "./inspectionConfiguration/SupportedResourcesTypes";
import { ChartRollbackModalForSummary } from "./Helm/drawers/ChartRollbackDrawer";
import { ChartUninstallModalForSummary } from "./Helm/drawers/ChartUninstallDrawer";
import { ChartUpgradeModalForSummary } from "./Helm/drawers/ChartUpgradeDrawer";
import { useGetPodExecInfo } from "./utils/useGetPodExecInfo";
import { getAvailablePortsFromResource } from "./utils/getAvailablePortsFromResource";
import { useAvailableActions } from "./utils/useAvailableActions";

import { useAvailableCronjobActions } from "@/components/Inspection/utils/useAvailableCronjobActions";
import { SuspendResumeCronjobModal } from "@/components/Actions/modals/SuspendResumeCronjobModal/SuspendResumeCronjobModal";
import { SuspendResumeActionType } from "@/components/Actions/modals/SuspendResumeCronjobModal/suspendResumeCronjobTypes";
import { useAgentPropertiesById } from "@/shared/hooks/useAgents";

const Container = styled.div`
  float: right;
`;

interface Action {
  type: AvailableActions;
  available: boolean;
  item: ActionItemProps;
}

export interface ResourceActionsListProps {
  clusterName: string;
  resource: ResourceTableModelRow;
  resourceType: KubernetesResource;
  agentId: string;
  refreshResults: () => void;
}

const FlexContainer = styled.div`
  display: flex;
`;

const ExpandedActionText = styled.span`
  margin-inline-end: 0.75rem;
`;

const ResourceActionsList: React.FC<ResourceActionsListProps> = ({
  clusterName,
  resource,
  resourceType,
  agentId,
  refreshResults,
}) => {
  const [openScale, setOpenScale] = useState(false);
  const [openRestart, setOpenRestart] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openRerunJob, setOpenRerunJob] = useState(false);
  const [openRunNowJob, setOpenRunNowJob] = useState(false);
  const [openCordonUncordon, setOpenCordonUncordon] = useState(false);
  const [openDrain, setOpenDrain] = useState(false);
  const [openRollbackHelmChart, setOpenRollbackHelmChart] = useState(false);
  const [openUninstallHelmChart, setOpenUninstallHelmChart] = useState(false);
  const [openUpgradeHelmChart, setOpenUpgradeHelmChart] = useState(false);
  const [openSuspendResumeCronjob, setOpenSuspendResumeCronjob] = useState<{
    open: boolean;
    actionType: SuspendResumeActionType;
  }>({ open: false, actionType: "suspend" });

  const [openActions, setOpenActions] = useState(false);
  const [displayUpgradeRequired, setDisplayUpgradeRequired] = useState(false);

  const agentUpgradeRequired = useActionsNeedUpgradeAgent({
    agentId,
    cluster: clusterName,
  });

  const agentUpgradeRequiredForPortForwarding = useActionsNeedUpgradeAgent({
    agentId,
    cluster: clusterName,
    action: "portForward",
    minimalSupportedVersion: REQUIRED_AGENT_PORT_FORWARDING,
  });

  const agentUpgradeRequiredForPodExec = useActionsNeedUpgradeAgent({
    agentId,
    cluster: clusterName,
    action: "podExec",
    minimalSupportedVersion: REQUIRED_AGENT_VERSION_POD_EXEC,
  });

  const { accountName } = useUserMetadata();
  const {
    setIsPortForwardModalOpen,
    setAvailablePorts,
    setNamespace,
    setResourceName,
    setResourceKind,
    setClusterName,
  } = usePortForward();

  const { createUpdateResourceAction } = useOverridableFlags();
  const location = useLocation();
  const showUncordon =
    resource.status?.includes("Ready") &&
    resource.status?.includes("SchedulingDisabled");
  const { canManageHelm } = useHasPermissions();

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const fetchLogsCallback = useCallback(() => {
    setOpenActions(false);
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: clusterName,
      namespace: resource.namespace,
      resourceType: resourceType.Kind,
      resourceName: resource.name,
      urlStates: {
        [SELECTED_TAB_PARAM_KEY]: ResourceTabName.Logs,
      },
    });
  }, [
    clusterName,
    pushDrawer,
    resource.name,
    resource.namespace,
    resourceType.Kind,
  ]);

  const { agentInfo } = useAgentPropertiesById(agentId ?? "");
  const atmSupported = isAgentAtmSupported({
    agentInfo,
    agentId,
  });

  const { showSuspendCronjobAction, showResumeCronjobAction } =
    useAvailableCronjobActions(resourceType, resource);

  const { execute: getResource } = useAtmGetResource(
    agentId,
    clusterName,
    resource.namespace,
    resourceType.Kind,
    resource.name,
    AtmOutputType.Json,
    (response: AtmGetResourceResponseType) => {
      return setAvailablePorts(
        getAvailablePortsFromResource(resourceType.Kind, response.data)
      );
    }
  );

  const onStartPortForward = useCallback(() => {
    setOpenActions(false);
    setIsPortForwardModalOpen(true);
    setNamespace(resource.namespace);
    setClusterName(clusterName);
    resourceType.NameInK8S === KubernetesPodsResource.NameInK8S &&
      setResourceKind(SupportedResourceKindEnum.Pod);
    setResourceName(resource.name);
    getResource();
  }, [
    clusterName,
    getResource,
    resource.name,
    resource.namespace,
    resourceType.NameInK8S,
    setClusterName,
    setIsPortForwardModalOpen,
    setNamespace,
    setResourceKind,
    setResourceName,
  ]);

  const {
    onContainerSelected,
    containersOptions,
    getPodFromAtm,
    initContainersOptions,
    showPodExecContainerSelection,
    setShowPodExecContainerSelection,
  } = useGetPodExecInfo({
    agentId,
    clusterName,
    resource,
    resourceType,
  });

  const actions = useMemo<Action[]>(
    () => [
      {
        type: AvailableActions.FetchLogs,
        available: true,
        item: {
          children: "Fetch Logs",
          onClick: fetchLogsCallback,
        },
      },
      {
        type: AvailableActions.Edit,
        available: !!createUpdateResourceAction,
        item: {
          children: "Edit YAML",
          onClick: () => setOpenEdit(true),
        },
      },
      {
        type: AvailableActions.Scale,
        available: true,
        item: {
          children: "Scale",
          onClick: () => setOpenScale(true),
        },
      },
      {
        type: AvailableActions.Restart,
        available: true,
        item: {
          children: "Restart",
          onClick: () => setOpenRestart(true),
        },
      },
      {
        type: AvailableActions.JobRerun,
        available: true,
        item: {
          children: "Rerun",
          onClick: () => setOpenRerunJob(true),
        },
      },
      {
        type: AvailableActions.RunNow,
        available: true,
        item: {
          children: "Run now",
          onClick: () => setOpenRunNowJob(true),
        },
      },
      {
        type: AvailableActions.CordonUncordon,
        available: true,
        item: {
          children: showUncordon ? "Uncordon" : "Cordon",
          onClick: () => setOpenCordonUncordon(true),
        },
      },
      {
        type: AvailableActions.Drain,
        available: true,
        item: {
          children: "Drain",
          onClick: () => setOpenDrain(true),
        },
      },
      {
        type: AvailableActions.ResumeCronJob,
        available: showResumeCronjobAction,
        item: {
          children: "Resume",
          onClick: () => {
            setOpenSuspendResumeCronjob({ open: true, actionType: "resume" });
          },
        },
      },
      {
        type: AvailableActions.SuspendCronJob,
        available: showSuspendCronjobAction,
        item: {
          children: "Suspend",
          onClick: () => {
            setOpenSuspendResumeCronjob({ open: true, actionType: "suspend" });
          },
        },
      },
      {
        type: AvailableActions.Delete,
        available: true,
        item: {
          children: "Delete",
          onClick: () => setOpenDelete(true),
          variant: "danger",
        },
      },
      {
        type: AvailableActions.UpgradeHelmChart,
        available: canManageHelm,
        item: {
          children: "Check for versions",
          onClick: () => setOpenUpgradeHelmChart(true),
        },
      },
      {
        type: AvailableActions.RollbackHelmChart,
        available:
          canManageHelm &&
          Number((resource as unknown as HelmChartSummary)?.revision) > 1,
        item: {
          children: "Rollback",
          onClick: () => setOpenRollbackHelmChart(true),
        },
      },
      {
        type: AvailableActions.UninstallHelmChart,
        available: canManageHelm,
        item: {
          children: "Uninstall",
          variant: "danger",
          onClick: () => setOpenUninstallHelmChart(true),
        },
      },
      {
        type: AvailableActions.ExecPodShell,
        available: atmSupported,
        item: {
          children: (
            <FlexContainer aria-label={AriaLabels.PodExec.OpenNewSession}>
              <ExpandedActionText>Pod Exec</ExpandedActionText>
              <ChevronRight16 />
            </FlexContainer>
          ),
          onClick: () => {
            getPodFromAtm();
            setShowPodExecContainerSelection(true);
            setOpenActions(false);
          },
        },
      },
      {
        type: AvailableActions.PortForward,
        available: atmSupported,
        item: {
          children: (
            <FlexContainer
              aria-label={AriaLabels.PortForwarding.OpenNewSession}
            >
              <ExpandedActionText>Port Forward</ExpandedActionText>
            </FlexContainer>
          ),
          onClick: onStartPortForward,
        },
      },
    ],
    [
      fetchLogsCallback,
      createUpdateResourceAction,
      showUncordon,
      showResumeCronjobAction,
      showSuspendCronjobAction,
      canManageHelm,
      resource,
      atmSupported,
      onStartPortForward,
      getPodFromAtm,
      setShowPodExecContainerSelection,
    ]
  );

  const availableResourceActions = useAvailableActions(
    resourceType.SupportedActions,
    resourceType.Kind
  );

  const availableActions = useMemo(
    () =>
      actions.filter(
        ({ type, available }) =>
          availableResourceActions?.includes(type) && available
      ),
    [actions, availableResourceActions]
  );

  const onActionsButtonClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      type: AvailableActions,
      onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
      // [CU-86c1gn74n] fix max-params
      // eslint-disable-next-line max-params
    ) => {
      const upgradeRequiredDueToPodExec =
        type === AvailableActions.ExecPodShell &&
        agentUpgradeRequiredForPodExec;
      const upgradeRequiredDueToPortForwarding =
        type === AvailableActions.PortForward &&
        agentUpgradeRequiredForPortForwarding;
      const upgradeRequiredForNonFetchLogsAction =
        agentUpgradeRequired && type !== AvailableActions.FetchLogs;

      const shouldDisplayUpgradeRequired =
        upgradeRequiredDueToPodExec ||
        upgradeRequiredForNonFetchLogsAction ||
        upgradeRequiredDueToPortForwarding;

      if (shouldDisplayUpgradeRequired) {
        return setDisplayUpgradeRequired(true);
      }

      onClick?.(e);
    },
    [
      agentUpgradeRequired,
      agentUpgradeRequiredForPodExec,
      agentUpgradeRequiredForPortForwarding,
    ]
  );

  if (!availableActions.length) {
    return null;
  }

  const popoverContent = (
    <MenuList width="100%">
      {availableActions.map(({ type, item: { onClick, ...restItemProps } }) => (
        <ActionItem
          key={type}
          onClick={(e) => onActionsButtonClick(e, type, onClick)}
          {...restItemProps}
        />
      ))}
    </MenuList>
  );
  const handleActionsButtonClick = () => {
    dispatchEvent(AnalyticEvents.Actions.Actions_inspection_button, {
      path: extractPathFromLocation(location),
      resourceType: resourceType,
    });
    setOpenActions(!openActions);
  };

  const resourceId = `${accountName}.${clusterName}-${resource.namespace}.${resource.name}`;
  const getDesiredReplicas = () => {
    if (!resource.ready) {
      return 0;
    }

    const [, desired] = resource.ready.split("/");

    return Number(desired);
  };

  return (
    <Container>
      <Popover
        isOpen={openActions}
        handleClose={() => setOpenActions(false)}
        content={popoverContent}
        containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
      >
        <Button
          size="small"
          variant="secondary"
          onClick={handleActionsButtonClick}
        >
          Actions
          <ChevronDown16 />
        </Button>
      </Popover>

      <Popover
        isOpen={showPodExecContainerSelection}
        handleClose={() => setShowPodExecContainerSelection(false)}
        content={
          <ContainerSelection
            onSelect={onContainerSelected}
            containerOptions={containersOptions}
            initContainersOptions={initContainersOptions}
          />
        }
        onClickOutside={() => {
          setShowPodExecContainerSelection(false);
          setOpenActions(false);
        }}
        containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
        children={null}
      />

      {openScale && (
        <ScaleModal
          isModalOpen
          agentId={agentId}
          handleClose={() => setOpenScale(false)}
          afterEffect={refreshResults}
          resourceId={resourceId}
          resourceName={resource.name}
          controlledBy={resource.controlledBy}
          namespace={resource.namespace}
          cluster={clusterName}
          desiredReplicas={getDesiredReplicas()}
          resourceType={resourceType.NameInK8S}
        />
      )}
      {openRestart && (
        <RestartModal
          isModalOpen
          agentId={agentId}
          handleClose={() => setOpenRestart(false)}
          afterEffect={refreshResults}
          resourceName={resource.name}
          controlledBy={resource.controlledBy}
          namespace={resource.namespace}
          cluster={clusterName}
          resourceId={resourceId}
          resourceType={resourceType.NameInK8S}
        />
      )}
      {openDelete && (
        <DeleteModal
          isModalOpen
          agentId={agentId}
          handleClose={() => setOpenDelete(false)}
          afterEffect={refreshResults}
          resourceName={resource.name}
          controlledBy={resource.controlledBy}
          namespace={resource.namespace}
          cluster={clusterName}
          resourceType={resourceType.NameInK8S}
        />
      )}
      {openRerunJob && (
        <JobRerunModal
          isModalOpen
          handleClose={() => setOpenRerunJob(false)}
          resourceId={resourceId}
          resourceName={resource.name}
          resourceType={resourceType.NameInK8S}
          namespace={resource.namespace}
          cluster={clusterName}
          agentId={agentId}
          afterEffect={refreshResults}
        />
      )}
      {openRunNowJob && (
        <JobRunNowModal
          isModalOpen
          handleClose={() => setOpenRunNowJob(false)}
          resourceId={resourceId}
          resourceName={resource.name}
          resourceType={resourceType.NameInK8S}
          namespace={resource.namespace}
          cluster={clusterName}
          agentId={agentId}
          afterEffect={refreshResults}
        />
      )}
      {openEdit && (
        <EditModal
          open
          handleClose={() => setOpenEdit(false)}
          afterEffect={refreshResults}
          agentId={agentId}
          resourceType={resourceType.NameInK8S}
          resourceName={resource.name}
          cluster={clusterName}
          namespace={resource.namespace}
        />
      )}
      {openCordonUncordon && (
        <CordonUncordonModal
          isModalOpen
          agentId={agentId}
          handleClose={() => setOpenCordonUncordon(false)}
          afterEffect={refreshResults}
          nodeName={resource.name}
          cluster={clusterName}
          isUncordon={showUncordon}
        />
      )}
      {openDrain && (
        <DrainModal
          isModalOpen
          agentId={agentId}
          handleClose={() => setOpenDrain(false)}
          afterEffect={refreshResults}
          nodeName={resource.name}
          cluster={clusterName}
        />
      )}
      {openRollbackHelmChart && (
        <ChartRollbackModalForSummary
          chartSummary={resource as unknown as HelmChartSummary}
          open={openRollbackHelmChart}
          agentId={agentId}
          onClose={() => {
            setOpenRollbackHelmChart(false);
            refreshResults();
          }}
        />
      )}
      {openUninstallHelmChart && (
        <ChartUninstallModalForSummary
          chartSummary={resource as unknown as HelmChartSummary}
          open={openUninstallHelmChart}
          agentId={agentId}
          onClose={() => setOpenUninstallHelmChart(false)}
        />
      )}
      {openUpgradeHelmChart && (
        <ChartUpgradeModalForSummary
          chartSummary={resource as unknown as HelmChartSummary}
          open={openUpgradeHelmChart}
          agentId={agentId}
          onClose={() => setOpenUpgradeHelmChart(false)}
        />
      )}
      {displayUpgradeRequired && (
        <ActionsUpgradeRequired
          handleClose={() => setDisplayUpgradeRequired(false)}
        />
      )}
      <SuspendResumeCronjobModal
        {...openSuspendResumeCronjob}
        onClose={() =>
          setOpenSuspendResumeCronjob({
            open: false,
            actionType: openSuspendResumeCronjob.actionType,
          })
        }
        resourceData={{
          name: resource.name,
          cluster: clusterName,
          namespace: resource.namespace,
          agentId,
        }}
        onActionComplete={refreshResults}
        afterEffect={refreshResults}
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourceActionsList;
