import { useCallback } from "react";
import { GridRowParams } from "@mui/x-data-grid-pro";

import {
  BasicViolation,
  CheckType,
} from "../../../../../../../generated/reliabilityApi";
import { useDrawersStackStore } from "../../../../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../../../../shared/store/drawersStackStore/types";

export const useOpenDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    (id: string, checkType?: CheckType) => {
      pushDrawer({
        drawerType: DrawerType.ViolationDrawer,
        violationId: id,
        checkType,
      });
    },
    [pushDrawer]
  );
};

export const useOpenDrawerFromDataGrid = () => {
  const openDrawer = useOpenDrawer();
  return useCallback(
    (params: GridRowParams<BasicViolation>) => {
      openDrawer(params.row.id, params.row.checkType);
    },
    [openDrawer]
  );
};
