import React from "react";
import { Link } from "@komodorio/design-system/deprecated";
import Tooltip from "@mui/material/Tooltip";
import { formatDistanceToNowStrict } from "date-fns";
import { muiPalette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import EventGroup from "../../common/EventGroup";
import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/Insight";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { lightMuiTooltipStyle } from "../../../shared/styles/tooltip";

export const CurrentAvailabilityHealthTooltip: React.FC<{
  correlatedIssue: WorkflowIssueEventGroup | undefined;
  setCorrelatedEvent?: (event: EventGroup | null) => void;
  service: ServiceInfo | undefined;
  children: React.ReactElement;
  namespace: string;
}> = ({
  correlatedIssue,
  setCorrelatedEvent,
  service,
  children,
  namespace,
}) => {
  return (
    <Tooltip
      placement="bottom"
      componentsProps={lightMuiTooltipStyle}
      arrow={false}
      title={
        <InsightTooltipContainer
          aria-label={AriaLabels.OpenIssue.CurrentAvailabilityHealthTooltip}
        >
          <InsightTooltipDetails>
            {correlatedIssue?.startTime && (
              <>
                <Typography variant="body3">Started</Typography>
                <Typography variant="h6">
                  {formatDistanceToNowStrict(correlatedIssue.startTime)} ago
                </Typography>
              </>
            )}
            <Typography variant="body3">Namespace</Typography>
            <Typography variant="h6">{namespace}</Typography>
            {service?.title && (
              <>
                <Typography variant="body3">Service</Typography>
                <Typography variant="h6">{service?.title}</Typography>
              </>
            )}
            {correlatedIssue?.reasons?.length ? (
              <>
                <Typography variant="body3">Reason</Typography>
                <Typography variant="h6">
                  {correlatedIssue.reasons.join(", ")}
                </Typography>
              </>
            ) : null}
            <Typography variant="body3">Status</Typography>
            {correlatedIssue?.isCompleted ? (
              <Typography variant="h6" color={muiPalette.success?.dark}>
                CLOSED
              </Typography>
            ) : (
              <Typography variant="h6" color={muiPalette.error?.dark}>
                OPEN
              </Typography>
            )}

            {correlatedIssue && setCorrelatedEvent && (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  setCorrelatedEvent(correlatedIssue);
                }}
                aria-label={
                  AriaLabels.OpenIssue.CurrentAvailabilityHealthTooltipLink
                }
              >
                View details
              </Link>
            )}
          </InsightTooltipDetails>
        </InsightTooltipContainer>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};
