export const MAX_KEY_RULES = 5;

export const newKeyValueFilterRuleStructure = [
  {
    field: "key",
    operator: "is",
    value: "",
  },
  {
    field: "value",
    operator: "matchesWildcard",
    value: "",
  },
];
