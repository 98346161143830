import React, { useMemo } from "react";

import {
  CustomSearch,
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../shared/types";
import { convertValueToFreeSoloOption } from "../advancedMultiSelectUtils";
import { MultiSelectItem } from "../../MultiSelectPopover/MultiSelectItem";

type GetEnabledItemsProps<T extends MuiSelectionOptionValue> = {
  enabledOptions: MuiSelectionOption<T>[];
  selectedOptions: MuiSelectionOption<T>[];
  onItemSelect: (value: T) => void;
  customOptionElement?: (
    option: MuiSelectionOption<T>,
    isChecked: boolean
  ) => React.ReactNode;
};
export const useGetEnabledItems = <T extends MuiSelectionOptionValue>({
  enabledOptions,
  selectedOptions,
  onItemSelect,
  customOptionElement,
}: GetEnabledItemsProps<T>): React.ReactElement[] => {
  return useMemo(
    () =>
      enabledOptions.map((option) => {
        return (
          <MultiSelectItem
            option={option}
            customOptionElement={customOptionElement}
            selectedOptions={selectedOptions}
            onSelect={onItemSelect}
            key={option.value}
          />
        );
      }),
    [customOptionElement, enabledOptions, onItemSelect, selectedOptions]
  );
};

type GetDisabledItemsProps<T extends MuiSelectionOptionValue> = {
  disabledOptions: MuiSelectionOption<T>[];
  pause: boolean;
  customOptionElement?: (
    option: MuiSelectionOption<T>,
    isChecked: boolean
  ) => React.ReactNode;
  selectedOptions: MuiSelectionOption<T>[];
};

export const useGetDisabledItems = <T extends MuiSelectionOptionValue>({
  disabledOptions,
  pause,
  customOptionElement,
  selectedOptions,
}: GetDisabledItemsProps<T>): React.ReactElement[] => {
  return useMemo(
    () =>
      pause
        ? []
        : disabledOptions.map((option) => {
            return (
              <MultiSelectItem
                option={option}
                customOptionElement={customOptionElement}
                selectedOptions={selectedOptions}
                key={option.value}
              />
            );
          }),
    [customOptionElement, disabledOptions, pause, selectedOptions]
  );
};

type FilteredOptions<T extends MuiSelectionOptionValue> = {
  enabledOptions: MuiSelectionOption<T>[];
  disabledOptions: MuiSelectionOption<T>[];
  freeSoloOption?: MuiSelectionOption<T>[];
};

type GetOptionsBySearchTermParams<T extends MuiSelectionOptionValue> = {
  freeSolo: boolean;
  options: MuiSelectionOption<T>[];
  searchTerm: string;
  customSearch?: CustomSearch<T>;
};

type GetOptionsBySearchTermOutput<T extends MuiSelectionOptionValue> = {
  filteredOptionsBySearch: MuiSelectionOption<T>[];
  enabledOptions: MuiSelectionOption<T>[];
  disabledOptions: MuiSelectionOption<T>[];
};

export const useGetOptionsBySearchTerm = <T extends MuiSelectionOptionValue>({
  options,
  searchTerm,
  freeSolo,
  customSearch,
}: GetOptionsBySearchTermParams<T>): GetOptionsBySearchTermOutput<T> => {
  return useMemo(() => {
    const filteredOptionsBySearch = customSearch
      ? customSearch(searchTerm, options)
      : options.filter(({ label }) =>
          label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    const isSearchTermAnExistingOption = options.some(
      ({ label }) => label.toLowerCase() === searchTerm.toLowerCase()
    );

    const { enabledOptions, disabledOptions } = filteredOptionsBySearch.reduce<
      FilteredOptions<T>
    >(
      (acc, curr) => {
        if (curr.disabled) {
          acc.disabledOptions.push(curr);
        } else {
          acc.enabledOptions.push(curr);
        }
        return acc;
      },
      { enabledOptions: [], disabledOptions: [] }
    );

    if (freeSolo && !isSearchTermAnExistingOption && searchTerm) {
      enabledOptions.push({
        value: searchTerm as T,
        label: convertValueToFreeSoloOption(searchTerm),
      });
    }
    return {
      filteredOptionsBySearch,
      enabledOptions,
      disabledOptions,
    };
  }, [customSearch, freeSolo, options, searchTerm]);
};
