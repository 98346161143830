import React from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { FlowType } from "@/components/AiInvestigation/types";
import { K8sAddonLiveStateDrawerType } from "@/shared/store/drawersStackStore/types";
import { useCurrentDrawerState } from "@/shared/context/drawersStack/helpers";
import Resource from "@/components/ResourceView/resources";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";

type AddOnRootCauseAnalysisProps = {
  isHealthy: boolean;
  resource: Resource;
  flowType: FlowType;
};

export const AddOnRootCauseAnalysis: React.FC<AddOnRootCauseAnalysisProps> = ({
  isHealthy,
  resource,
  flowType,
}) => {
  const { klaudiaAiAddons } = useOverridableFlags();

  const currentDrawerState =
    useCurrentDrawerState<K8sAddonLiveStateDrawerType>() as K8sAddonLiveStateDrawerType;

  if (!resource || !klaudiaAiAddons) {
    return null;
  }

  return (
    <AiInvestigation
      isHealthy={isHealthy}
      resource={resource}
      requestId={resource.id}
      flowType={flowType}
      drawerData={currentDrawerState}
      padding="8px 24px"
    />
  );
};
