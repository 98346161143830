import { immer } from "zustand/middleware/immer";
import { create } from "zustand";

import { LogsViewerStore } from "./types";
import { initialState } from "./initialState";

export const useLogsViewerStore = create(
  immer<LogsViewerStore>((set) => ({
    ...initialState,
    setAnalyzingLogs(isAnalyzing) {
      set((state) => {
        state.isAnalyzingLogs = isAnalyzing;
      });
    },
    setLiveTailEnabled: (isEnabled: boolean) => {
      set((state) => {
        state.isLiveTailEnabled = isEnabled;
      });
    },
  }))
);

export const resetLogsViewerStore = () => {
  useLogsViewerStore.setState(initialState);
};
