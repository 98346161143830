import React, { useEffect, useMemo } from "react";
import { muiColors, palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Loader16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import Button from "@mui/material/Button";
import ChevronRight from "@mui/icons-material/ChevronRight";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../resources/deployment";
import DaemonSet from "../resources/daemonset";
import StatefulSet from "../resources/statefulset";
import { formatTimeIntervalFromDates } from "../../../shared/utils/dateUtils";
import Rollout from "../resources/rollout";
import { SELECTED_TAB_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useResourceViewStore } from "../../../shared/store/resourceViewStore/resourceViewStore";
import { setFetchingStateSelector } from "../../../shared/store/resourceViewStore/resourceViewSelectors";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { InvalidServiceTooltip } from "./utils/tooltips/InvalidServiceTooltip";
import { AvailabilityTooltip } from "./utils/tooltips/AvailabilityTooltip";
import {
  StatusColumn,
  StatusContainer,
  StatusRow,
  StatusTitle,
  StatusValue,
} from "./HeaderStyles";

import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import { useGetMonitorIssues } from "@/shared/hooks/monitors-api/client/issues/useGetMonitorIssues";
import { MonitorType } from "@/generated/monitorsApi";
import { ServiceOpenAvailabilityIssuesFields } from "@/components/ResourceView/headers/ServiceOpenAvailabilityIssueFields";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { groupWorkflowIssuesEvents } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";

const { Container, Loader } =
  AriaLabels.ResourceView.Header.ServiceHealth.Availability;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const enum ServiceStatus {
  "DELETED" = "DELETED",
  "INACTIVE" = "INACTIVE",
  "HEALTHY" = "HEALTHY",
  "UNHEALTHY" = "UNHEALTHY",
}
const ServiceStatusColorMapping: { [key in ServiceStatus]?: string } = {
  [ServiceStatus.DELETED]: palette.gray[600],
  [ServiceStatus.INACTIVE]: palette.gray[600],
  [ServiceStatus.HEALTHY]: palette.green[800],
  [ServiceStatus.UNHEALTHY]: palette.pink[600],
};

const StyledTypography = styled(Typography)`
  line-height: 21px;
`;

interface KomodorServiceHealthAvailabilityProps {
  resource: Deployment | DaemonSet | StatefulSet | Rollout;
}

export const KomodorServiceHealthAvailability: React.FC<
  KomodorServiceHealthAvailabilityProps
> = ({ resource }) => {
  // TODOYOTAM - remove this when gql is done
  const setFetchingState = useResourceViewStore(setFetchingStateSelector);

  const status = useMemo(() => {
    return resource.isDeleted
      ? ServiceStatus.DELETED
      : resource.inactive
      ? ServiceStatus.INACTIVE
      : resource.healthy
      ? ServiceStatus.HEALTHY
      : ServiceStatus.UNHEALTHY;
  }, [resource.healthy, resource.inactive, resource.isDeleted]);

  const { data: openAvailabilityIssue, refetch } = useGetMonitorIssues({
    types: [MonitorType.Availability],
    includeOpen: true,
    includeClose: false,
    serviceIds: [resource.id],
    order: "DESC",
    fields: ServiceOpenAvailabilityIssuesFields,
  });

  useEffect(() => {
    refetch();
  }, [refetch, status]);

  const openAvailabilityEventGroup = useMemo(() => {
    if (!openAvailabilityIssue?.data?.length) {
      return undefined;
    }
    return groupWorkflowIssuesEvents(
      [openAvailabilityIssue.data[0]],
      resource.id
    )[0];
  }, [openAvailabilityIssue?.data, resource.id]);

  useEffect(() => {
    setFetchingState({
      key: "isFetchingHealthAvailability",
      value: !openAvailabilityIssue,
    });
  }, [openAvailabilityIssue, setFetchingState]);

  const isActiveService = useMemo(
    () => ![ServiceStatus.DELETED, ServiceStatus.INACTIVE].includes(status),
    [status]
  );
  const isUnhealthy = status === ServiceStatus.UNHEALTHY;

  const formatReasons =
    openAvailabilityEventGroup &&
    openAvailabilityEventGroup.reasons.length > 0 &&
    openAvailabilityEventGroup.reasons[0] +
      (openAvailabilityEventGroup.reasons.length > 1
        ? ` +${openAvailabilityEventGroup.reasons.length - 1}`
        : "");

  const [, setSelectedTab] = useDrawerUrlState<string>(SELECTED_TAB_PARAM_KEY);
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return (
    <StatusContainer
      aria-label={Container}
      onClick={() => {
        if (openAvailabilityEventGroup) {
          pushDrawer({
            drawerType: DrawerType.EventDrawerById,
            eventIds: [openAvailabilityEventGroup.id],
            eventType: MonitorType.Availability,
            serviceId: openAvailabilityEventGroup.serviceId,
          });
        }
        if (!isUnhealthy) {
          setSelectedTab("Pods");
        }
      }}
      clickable={openAvailabilityEventGroup !== undefined || !isUnhealthy}
    >
      <StatusColumn>
        {isActiveService && (
          <StatusRow>
            <StatusTitle>HEALTH</StatusTitle>
            {isUnhealthy && openAvailabilityEventGroup?.workflowConfig && (
              <AvailabilityTooltip
                workflowConfig={openAvailabilityEventGroup?.workflowConfig}
              />
            )}
          </StatusRow>
        )}
        <StatusRow alignItems="center">
          <StyledTypography
            data-e2e-selector="serviceHeader-label-health"
            color={ServiceStatusColorMapping[status]}
            bold
            size="medium"
          >
            {status}
          </StyledTypography>
          {!isActiveService && <InvalidServiceTooltip resource={resource} />}
        </StatusRow>
      </StatusColumn>
      <StatusColumn>
        <StatusTitle>REPLICAS</StatusTitle>
        <StatusValue>
          {resource.replicas
            ? `${isActiveService ? resource.replicas.ready : "0"}/${
                resource.replicas.desired
              }`
            : " no replicas"}
        </StatusValue>
      </StatusColumn>
      {isUnhealthy &&
        (openAvailabilityEventGroup ? (
          <>
            <StatusColumn>
              <StatusTitle>DURATION</StatusTitle>
              <StatusValue>
                {formatTimeIntervalFromDates(
                  openAvailabilityEventGroup.startTime,
                  new Date()
                )}
              </StatusValue>
            </StatusColumn>
            {formatReasons && (
              <StatusColumn>
                <StatusTitle>REASON</StatusTitle>
                <StatusValue>{formatReasons}</StatusValue>
              </StatusColumn>
            )}
            <Button
              variant="outlined"
              size="small"
              endIcon={<ChevronRight width="10px" height="10px" />}
            >
              Investigate
            </Button>
          </>
        ) : (
          <Loader16 color={muiColors.gray[500]} aria-label={Loader} />
        ))}
    </StatusContainer>
  );
};
