import React, { createContext, useCallback, useState } from "react";

import { MetricType } from "@/components/Metrics/types";

type RequestValues = Record<MetricType, number | undefined>;

export type HpaReachedMaxContextState = {
  serviceKomodorUid: string | undefined;
  setServiceKomodorUid: (serviceKomodorUid: string | undefined) => void;
  requestValues: RequestValues;
  setRequestValues: (val: Partial<RequestValues>) => void;
};

const initialState: HpaReachedMaxContextState = {
  serviceKomodorUid: undefined,
  requestValues: { cpu: undefined, memory: undefined },
  setServiceKomodorUid: () => undefined,
  setRequestValues: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const HpaReachedMaxContext =
  createContext<HpaReachedMaxContextState>(initialState);

export const HpaReachedMaxContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [serviceKomodorUid, setServiceKomodorUid] = useState(
    initialState.serviceKomodorUid
  );
  const [requestValues, setRequestValues] = useState<RequestValues>(
    initialState.requestValues
  );

  const onSetRequestValues = useCallback((val: Partial<RequestValues>) => {
    setRequestValues((prev) => ({ ...prev, ...val }));
  }, []);

  const state: HpaReachedMaxContextState = {
    serviceKomodorUid,
    setServiceKomodorUid,
    requestValues,
    setRequestValues: onSetRequestValues,
  };

  return (
    <HpaReachedMaxContext.Provider value={state}>
      {children}
    </HpaReachedMaxContext.Provider>
  );
};
