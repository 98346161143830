import { VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS } from "./ViolationTableWithContext/violationTableConstants";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { ImpactGroupIdentifier } from "@/generated/reliabilityApi";

const {
  violationsTable: { violationsTable: tableAriaLabel },
} = reliabilityArialLabels;

export const tableProps = {
  "aria-label": tableAriaLabel,
  disableColumnMenu: true,
  pageSizeOptions: VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS,
  pagination: true,
  disableColumnReorder: true,
  disableColumnResize: true,
};

export const groupBetaStatus: Partial<Record<ImpactGroupIdentifier, boolean>> =
  {
    [ImpactGroupIdentifier.CascadingFailure]: true,
  };
