import { muiColors, muiTheme } from "@komodorio/design-system";
import { Ellipsis16 } from "@komodorio/design-system/icons";
import Delete from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import Edit from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

import { AddEditWorkspaceModal } from "../AddEditWorkspaceModal/AddEditWorkspaceModal";
import {
  useNavigateToWorkspace,
  useWorkspaces,
} from "../WorkspacesTopBar/hooks";

import { WorkspaceDeletionDialog } from "./WorkspaceDeletionDialog";

import { getEditAppViewRoute } from "@/components/appView/sections/AppView/utils/getRoutes";
import { useDeleteWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useDeleteWorkspace";

const ThreeDotButton = styled(Button)`
  && {
    padding: 6px;
    height: 32px;
    min-width: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${muiColors.gray[300]};
    color: ${muiColors.gray[500]};
  }
`;

type WorkspaceActionsButtonProps = {
  kind: string;
};

export const WorkspaceActionsButton: React.FC<WorkspaceActionsButtonProps> = ({
  kind,
}) => {
  const navigate = useNavigate();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
  const { isClustersGroupWorkspace, currentWorkspace, currentWorkspaceId } =
    useWorkspaces();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const onEdit = useCallback(() => {
    setOpen(false);
    if (isClustersGroupWorkspace) {
      setIsEditModalOpen(true);
    } else {
      currentWorkspaceId && navigate(getEditAppViewRoute(currentWorkspaceId));
    }
  }, [currentWorkspaceId, isClustersGroupWorkspace, navigate]);
  const navigateToWorkspace = useNavigateToWorkspace();
  const deleteWorkspaceMutation = useDeleteWorkspace();

  return (
    <div>
      {isEditModalOpen ? (
        <AddEditWorkspaceModal
          existingWorkspace={currentWorkspace}
          onClose={() => setIsEditModalOpen(false)}
        />
      ) : null}
      <ThreeDotButton
        data-e2e-selector={"workspace-three-dots"}
        ref={anchorEl}
        onClick={() => setOpen(true)}
      >
        <Ellipsis16 />
      </ThreeDotButton>
      <Menu
        anchorEl={anchorEl?.current}
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          paper: { sx: { "&&.MuiPaper-root": { marginTop: "2px" } } },
        }}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon
            sx={{ "&&.MuiListItemIcon-root": { minWidth: "32px" } }}
          >
            <Edit fontSize="medium" sx={{ color: muiColors.gray[500] }} />
          </ListItemIcon>
          <ListItemText>Edit {kind}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
            setIsDeletionDialogOpen(true);
          }}
        >
          <ListItemIcon
            sx={{ "&&.MuiListItemIcon-root": { minWidth: "32px" } }}
          >
            <Delete
              fontSize="medium"
              sx={{ color: muiTheme.palette.error.main }}
            />
          </ListItemIcon>
          <ListItemText>Delete {kind}</ListItemText>
        </MenuItem>
      </Menu>
      {isDeletionDialogOpen && currentWorkspace ? (
        <WorkspaceDeletionDialog
          workspace={currentWorkspace}
          onClose={(ok) => {
            if (ok && currentWorkspaceId) {
              deleteWorkspaceMutation.mutate(
                { id: currentWorkspaceId },
                {
                  onSettled: () => {
                    setIsDeletionDialogOpen(false);
                  },
                  onSuccess: () => {
                    navigateToWorkspace(undefined);
                  },
                }
              );
            } else {
              setIsDeletionDialogOpen(false);
            }
          }}
        />
      ) : null}
    </div>
  );
};
