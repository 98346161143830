import { useRef, useEffect } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { DriftType } from "../context/types";
import { getDriftBaselineSelectionKey } from "../context/utils";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const useResetBaselineOnWorkspaceChange = () => {
  const { currentWorkspaceId } = useWorkspaces();
  const lastWorkspaceId = useRef<string | undefined>(currentWorkspaceId);
  const [baselineServicesSelection, setServicesBaselineSelection] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>>(
      getDriftBaselineSelectionKey(DriftType.Services)
    );
  const [baselineHelmSelection, setHelmBaselineSelection] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>>(
      getDriftBaselineSelectionKey(DriftType.Helm)
    );
  useEffect(() => {
    if (lastWorkspaceId.current !== currentWorkspaceId) {
      lastWorkspaceId.current = currentWorkspaceId;
      if (baselineServicesSelection) {
        setServicesBaselineSelection(null);
      }
      if (baselineHelmSelection) {
        setHelmBaselineSelection(null);
      }
    }
  }, [
    currentWorkspaceId,
    setServicesBaselineSelection,
    setHelmBaselineSelection,
    baselineServicesSelection,
    baselineHelmSelection,
  ]);
};
