import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { muiColors } from "@komodorio/design-system";

import { AppNotificationsHeader } from "@/shared/components/AppNotificationsCenter/AppNotificationsHeader";
import {
  MAX_NOTIFICATIONS_HEIGHT,
  PANEL_WIDTH_CLOSED,
  PANEL_WIDTH_OPEN,
} from "@/shared/components/AppNotificationsCenter/appNotificationsCenterConstants";
import { appNotificationsAriaLabels } from "@/shared/components/AppNotificationsCenter/appNotificationsAriaLabels";
import { useAppNotificationsStore } from "@/shared/store/appNotificationsStore/appNotificationsStore";
import {
  selectAppNotifications,
  selectRemoveNotification,
} from "@/shared/store/appNotificationsStore/appNotificationsStoreSelectors";
import { NotificationStatus } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { AppNotificationItem } from "@/shared/components/AppNotificationsCenter/AppNotificationItem";

const StyledPaper = styled(Paper)<{
  $open: boolean;
  $hasNotifications: boolean;
}>`
  && {
    margin-right: 16px;
    border: 1px solid ${muiColors.gray[200]};
    width: ${({ $open, $hasNotifications }) => {
      if (!$hasNotifications) {
        return 0;
      }
      if ($open) {
        return `${PANEL_WIDTH_OPEN}px`;
      }
      return `${PANEL_WIDTH_CLOSED}px`;
    }};
    max-height: ${({ $hasNotifications }) =>
      $hasNotifications ? "2000px" : "0"};
    transition: ${({ $hasNotifications }) =>
      $hasNotifications
        ? "width 0.2s, max-height 0.3s ease-in-out 0.5s"
        : "width 0.2s max-height 0.2s"};
  }
`;

const ContentContainer = styled.div<{ $open: boolean }>`
  max-height: ${({ $open }) => ($open ? `${MAX_NOTIFICATIONS_HEIGHT}px` : "0")};
  transition: max-height 0.2s;
  overflow: auto;
`;

export const AppNotifications: React.FC = () => {
  const notifications = useAppNotificationsStore(selectAppNotifications);
  const removeNotification = useAppNotificationsStore(selectRemoveNotification);
  const [open, setIsOpen] = useState<boolean>(false);

  const onCloseNotifications = useCallback(() => {
    notifications.forEach((notification) => {
      if (notification.status == NotificationStatus.Loading) return;
      notification.onClose?.();
      removeNotification(notification.id);
    });
  }, [notifications, removeNotification]);

  const notificationsNum = notifications.length;
  const content = useMemo(() => {
    return notifications.map((notification) => {
      return (
        <AppNotificationItem
          notification={notification}
          key={notification.id}
          onClose={() => {
            notification.onClose?.();
            removeNotification(notification.id);
          }}
          onClick={notification.onClick}
        />
      );
    });
  }, [notifications, removeNotification]);

  return (
    <StyledPaper
      elevation={4}
      $open={open}
      $hasNotifications={notificationsNum > 0}
      aria-label={appNotificationsAriaLabels.getMainContainerAriaLabel(
        notificationsNum
      )}
    >
      <AppNotificationsHeader
        open={open}
        setIsOpen={setIsOpen}
        notificationsNum={notificationsNum}
        onClose={onCloseNotifications}
      />
      <ContentContainer
        $open={open}
        aria-label={appNotificationsAriaLabels.itemsContainer}
      >
        {content}
      </ContentContainer>
    </StyledPaper>
  );
};
