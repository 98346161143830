import { ImpactGroupIdentifier } from "@/generated/reliabilityApi";
import { ViolationTableWithContextProps } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/ViolationTableWithContext";
import { useGetCascadingFailureColumns } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationsGroup/hooks/useGetCascadingFailureColumns";

export const useCustomViolationTablePropsByGroupId = (
  id: ImpactGroupIdentifier
): Partial<ViolationTableWithContextProps> => {
  const getCascadingFailureColumns = useGetCascadingFailureColumns();

  switch (id) {
    case ImpactGroupIdentifier.CascadingFailure: {
      return { customColumnsDefinitions: getCascadingFailureColumns() };
    }
    default: {
      return {};
    }
  }
};
