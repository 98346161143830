import React from "react";

import {
  ACTIONS_ROUTE,
  POLICIES_ROUTE,
  ROLES_ROUTE,
} from "@/components/routes/routes";
import {
  RBAC_ACTION,
  RBAC_POLICY,
  RBAC_ROLE,
} from "@/shared/config/urlSearchParamsKeys";
import {
  ColumnDefinitionGetterProps,
  ColumnsDefinitions,
  TooltipTextCellProps,
} from "@/components/Settings/Users/EffectivePermissions/types";
import { COLUMN_MAX_WIDTH } from "@/components/Settings/Users/EffectivePermissions/constants";
import { TooltipTextCellWrapper } from "@/components/Settings/Users/EffectivePermissions/TooltipTextCellWrapper";
import { TooltipTextCell } from "@/components/Settings/Users/components/TooltipTextCell";
import { AuthzSelector, AuthzSelectorPattern } from "@/generated/auth";

export const getColumnDefinition = ({
  allowLinks,
}: ColumnDefinitionGetterProps) => [
  {
    field: "cluster",
    headerName: "Cluster",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "namespace",
    headerName: "Namespace",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "selectorData",
    headerName: "Selector",
    renderCell: (params: TooltipTextCellProps) => {
      if (params.value === undefined) {
        return null;
      }
      const link = (params.colDef as ColumnsDefinitions).urlGetter?.(
        params.value
      );
      const accountFeature = (params.colDef as ColumnsDefinitions)
        .accountFeature;

      if (isAuthzSelector(params.value)) {
        return (
          <TooltipTextCell
            text={params.value.key}
            tooltipText={`Type: ${params.value.type}, Key: ${params.value.key}, Value: ${params.value.value}`}
            link={params.allowLinks ? link : undefined}
            accountFeature={accountFeature}
          />
        );
      } else if (isAuthzSelectorPattern(params.value)) {
        let tooltipText = `Type: ${params.value.type}, Key: ${params.value.key}, Includes: ${params.value.value.include}`;
        if (params.value.value?.exclude !== "") {
          tooltipText += `, Excludes: ${params.value.value.exclude}`;
        }
        return (
          <TooltipTextCell
            text={params.value.key}
            tooltipText={tooltipText}
            link={params.allowLinks ? link : undefined}
            accountFeature={accountFeature}
          />
        );
      }
      // This should never happen
      return null;
    },
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
    showOnlyForV2: true,
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    urlGetter: (action: string) => `${ACTIONS_ROUTE}?${RBAC_ACTION}=${action}`,
    accountFeature: "rbacClusterSync",
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "policyName",
    headerName: "Policy",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    urlGetter: (policy: string) => `${POLICIES_ROUTE}?${RBAC_POLICY}=${policy}`,
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
  {
    field: "roleName",
    headerName: "Role",
    renderCell: (params: TooltipTextCellProps) => (
      <TooltipTextCellWrapper {...params} allowLinks={allowLinks} />
    ),
    urlGetter: (role: string) => `${ROLES_ROUTE}?${RBAC_ROLE}=${role}`,
    maxWidth: COLUMN_MAX_WIDTH,
    flex: 1,
  },
];

const isAuthzSelector = (
  selector?: AuthzSelector | AuthzSelectorPattern
): selector is AuthzSelector => {
  return typeof (selector as AuthzSelector)?.value === "string";
};

const isAuthzSelectorPattern = (
  selector?: AuthzSelector | AuthzSelectorPattern
): selector is AuthzSelectorPattern => {
  return typeof (selector as AuthzSelectorPattern)?.value === "object";
};
