import React from "react";

import { useInspectionNeedUpgradeAgentWithByInfo } from "../InspectionUpgradeRequired";
import { useInspectionResourceListAgentTask } from "../utils/useInspectionResourceList";

import { InspectionViewComponent, InspectionComponentProps } from "./common";
const InspectionViewAgentTask: React.FC<InspectionComponentProps> = ({
  kubernetesResource,
  cluster,
  onClusterChange,
  agentInfo,
  agentId,
}) => {
  const agentUpgradeRequired =
    useInspectionNeedUpgradeAgentWithByInfo(agentInfo);
  const { resourceList, refresh } = useInspectionResourceListAgentTask({
    kubernetesResource,
    agentId,
    agentInfo,
    agentUpgradeRequired,
  });

  return (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      agentUpgradeRequired={agentUpgradeRequired}
      resourceList={resourceList}
      kubernetesResource={kubernetesResource}
      refresh={refresh}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InspectionViewAgentTask;
