/* eslint-disable max-lines */
import Link from "@mui/material/Link";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { Divider } from "@komodorio/design-system/deprecated";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import pluralize from "pluralize";
import { useDebouncedCallback } from "use-debounce";
import { sumBy } from "lodash";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { parseKomodorUid } from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useOpenIssueByResource } from "../../../../../OpenIssues/useOpenIssuesByResource";
import EventGroup from "../../../../EventGroup";
import {
  HealthyIcon,
  InactiveIcon,
  OpenIssueAlertIcon,
} from "../../../../../OpenIssues/IndicationIconStyles";
import { CurrentAvailabilityHealthTooltip } from "../../../../../OpenIssues/tooltips/CurrentAvailabilityHealthTooltip";
import { useMinifiedMonitorEventGroupById } from "../../../../../monitorsView/useWorkflowsRuns";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../../../shared/constants/systemWideServiceId";
import { AriaLabels } from "../../../../../../shared/config/ariaLabels";
import {
  ARGO_WORKFLOW_KIND,
  ARGO_WORKFLOW_KOMODOR_KIND,
} from "../../../../../ResourceView/resources/argoWorkflow";
import { WORKFLOW_RUN_PARAM_KEY } from "../../../../../../shared/config/urlSearchParamsKeys";
import { argoKomodorKindToImpactKind } from "../../../../../argoWorkflowsView/convertArgoKinds";
import { useArgoWorkflowsRootOwner } from "../../../../../argoWorkflowsView/ArgoExternalLink";
import { useFindService } from "../../../../../../shared/hooks/useFindService";

import { parseGeneralWorkflowName } from "@/components/common/ProcessList/details/NodeChangeDetails/ImpactedResourcesSection/utils";
import { Addon, Entity, WorkflowDagFromJSON } from "@/generated/addonsApi";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { buildKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { MonitorType } from "@/generated/monitorsApi";

const Tile = styled.div<{ clickable: boolean }>`
  display: grid;
  grid-template-columns: max-content auto 1px max-content 1px max-content;
  column-gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]}}
  ${({ clickable }) =>
    clickable &&
    `
    &:hover {
      cursor: pointer;
      border-color: ${muiColors.blue[500]};
    }
  `}
`;

const HealthIndicationWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 0.1rem;
`;

const TileItem = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const GeneralWorkflowTooltipContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImpactedKomodorServiceTile: React.FC<{
  komodorUid: string;
  terminatedPodsCount: number;
  setSelectedServiceId: (serviceId: string | null) => void;
  setCorrelatedEvent?: (event: EventGroup | null) => void;
  showHealthIndication?: boolean;
  issueId?: string;
}> = ({
  komodorUid,
  terminatedPodsCount,
  setSelectedServiceId,
  setCorrelatedEvent,
  showHealthIndication = true,
  issueId,
}) => {
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const service = useFindService({
    value: name,
    clusterName: cluster,
    pageName: kind,
    namespace,
  });

  const openIssue = useOpenIssueByResource(
    cluster,
    namespace,
    kind,
    name,
    service?.id
  );
  const correlatedIssue = useMinifiedMonitorEventGroupById({
    runId: issueId ?? openIssue?.id ?? "",
    serviceId: service?.id ?? SYSTEM_WIDE_SERVICE_ID,
    type: issueId ? MonitorType.Availability : openIssue?.type,
  });
  const onTileClick = service
    ? () => setSelectedServiceId(service.id)
    : undefined;

  return (
    <Tile onClick={onTileClick} clickable={!!onTileClick}>
      {showHealthIndication ? (
        <HealthIndicationWrapper>
          <CurrentAvailabilityHealthTooltip
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            service={service}
            namespace={namespace}
          >
            {correlatedIssue ? (
              <OpenIssueAlertIcon
                onClick={(e) => {
                  e.stopPropagation();
                  if (correlatedIssue) setCorrelatedEvent?.(correlatedIssue);
                }}
                aria-label={
                  AriaLabels.OpenIssue.CurrentAvailabilityHealthTooltipLink
                }
              />
            ) : service ? (
              <HealthyIcon />
            ) : (
              <InactiveIcon />
            )}
          </CurrentAvailabilityHealthTooltip>
        </HealthIndicationWrapper>
      ) : (
        <div />
      )}

      <ImpactedResourceTileContent
        kind={kind}
        name={name}
        terminatedPodsCount={terminatedPodsCount}
      />
    </Tile>
  );
};

export const ImpactedOrphanPodTile: React.FC<{
  komodorUid: string;
}> = ({ komodorUid }) => {
  const { kind = "", name = "" } = parseKomodorUid(komodorUid) ?? {};

  return (
    <Tile clickable={false}>
      <div />
      <ImpactedResourceTileContent
        kind={kind}
        name={name}
        terminatedPodsCount={1}
      />
    </Tile>
  );
};

export const ImpactedArgoWorkflowTile: React.FC<{
  komodorUid: string;
  terminatedPodsCount: number;
  setSelectedKomodorUid: (komodorUid: string | null) => void;
}> = ({ komodorUid, terminatedPodsCount, setSelectedKomodorUid }) => {
  const [, setSelectedRunId] = useStateInSearchParams(WORKFLOW_RUN_PARAM_KEY);
  const { kind = "", name = "" } = parseKomodorUid(komodorUid) ?? {};

  const [clickIntent, setClickIntent] = useState(false);
  const { callback: debouncedSetClickIntent } = useDebouncedCallback(
    (intent: boolean) => setClickIntent(intent),
    100
  );

  const rootOwnerKomodorUid = useArgoWorkflowsRootOwner(
    clickIntent ? komodorUid : undefined
  );

  const onTileClick = rootOwnerKomodorUid
    ? () => {
        const { kind = "" } = parseKomodorUid(rootOwnerKomodorUid) ?? {};
        if (kind !== ARGO_WORKFLOW_KOMODOR_KIND) {
          setSelectedRunId(
            komodorUid.replace(ARGO_WORKFLOW_KOMODOR_KIND, ARGO_WORKFLOW_KIND)
          );
        }
        setSelectedKomodorUid(rootOwnerKomodorUid);
      }
    : undefined;

  return (
    <Tile
      onClick={onTileClick}
      clickable={!!onTileClick}
      onMouseEnter={() => debouncedSetClickIntent(true)}
      onMouseLeave={() => debouncedSetClickIntent(false)}
    >
      <div />
      <ImpactedResourceTileContent
        kind={argoKomodorKindToImpactKind[kind] ?? kind}
        name={name}
        terminatedPodsCount={terminatedPodsCount}
      />
    </Tile>
  );
};

export const ImpactedGeneralWorkflowTile: React.FC<{
  workflowSet: {
    komodorUid: string;
    terminatedPodsCount: number;
  }[];
}> = ({ workflowSet }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const {
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(workflowSet[0].komodorUid) ?? {};
  const { engine = "", dag = "" } = parseGeneralWorkflowName(name) ?? {};

  const [clickIntent, setClickIntent] = useState(false);
  const { callback: debouncedSetClickIntent } = useDebouncedCallback(
    (intent: boolean) => setClickIntent(intent),
    100
  );

  const terminatedPodsCount = sumBy(workflowSet, "terminatedPodsCount");

  const komodorWorkflowId = useMemo(
    () =>
      buildKomodorWorkflowId({
        cluster,
        namespace,
        engine: engine,
        dagId: dag,
      }) ?? "",
    [cluster, namespace, engine, dag]
  );

  // In order to check if dag exists, we need to fetch the dag from the backend
  const { data, isFetching } = useGetAddonFullLiveState(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      uid: komodorWorkflowId,
      name: dag,
      getEntityRequest: {
        clusterName: cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: WorkflowDagFromJSON(json?.data?.data),
    }),
    { enabled: clickIntent }
  );

  const onTileClick = useMemo(
    () =>
      !isFetching && data?.data
        ? (run: string) => {
            pushDrawer({
              drawerType: DrawerType.K8sAddonLiveStateDrawer,
              addon: Addon.Workflow,
              entity: Entity.WorkflowDag,
              clusterName: cluster,
              uid: komodorWorkflowId,
              name: dag ?? "",
              urlStates: {
                [WORKFLOW_RUN_PARAM_KEY]: run ?? "",
              },
            });
          }
        : undefined,
    [isFetching, data, pushDrawer, cluster, komodorWorkflowId, dag]
  );

  const tooltipContent = useMemo(
    () => (
      <GeneralWorkflowTooltipContentContainer>
        {workflowSet.map(({ komodorUid }) => {
          const { name = "" } = parseKomodorUid(komodorUid) ?? {};
          const { run = "" } = parseGeneralWorkflowName(name) ?? {};
          return onTileClick ? (
            <Link
              onClick={() => onTileClick?.(run)}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {run}
            </Link>
          ) : (
            <Typography variant={"h6"} color={muiColors.gray["600"]}>
              {run}
            </Typography>
          );
        })}
      </GeneralWorkflowTooltipContentContainer>
    ),
    [workflowSet, onTileClick]
  );

  return (
    <Tile
      clickable={!!onTileClick}
      onMouseEnter={() => debouncedSetClickIntent(true)}
      onMouseLeave={() => debouncedSetClickIntent(false)}
    >
      <div />
      <ImpactedResourceTileContent
        kind={engine}
        name={dag}
        terminatedPodsCount={terminatedPodsCount}
        additionalInfo={{
          key: "Runs",
          value: workflowSet.length.toString(),
          tooltipContent,
        }}
      />
    </Tile>
  );
};

const ImpactedResourceTileContent: React.FC<{
  name: string;
  kind: string;
  terminatedPodsCount: number;
  additionalInfo?: {
    key: string;
    value: string;
    tooltipContent?: React.ReactNode;
  };
}> = ({ name, kind, terminatedPodsCount, additionalInfo }) => {
  const renderTileItem = (title: string, subtitle: string) => (
    <TileItem>
      <Typography variant="h5">{title}</Typography>
      <Typography
        variant="overline"
        color={muiColors.gray[500]}
        lineHeight={1.5}
        fontWeight={500}
      >
        {subtitle}
      </Typography>
    </TileItem>
  );

  return (
    <>
      <TileItem>
        <LightTooltip title={name} placement="top">
          <Typography variant="h5" noWrap sx={{ width: "fit-content" }}>
            {name}
          </Typography>
        </LightTooltip>
        <Typography
          variant="overline"
          color={muiColors.gray[500]}
          lineHeight={1.5}
          fontWeight={500}
        >
          {kind}
        </Typography>
      </TileItem>

      <Divider variant="vertical" />

      {additionalInfo && (
        <LightTooltip
          title={additionalInfo.tooltipContent ?? additionalInfo.value}
          placement="top"
        >
          {renderTileItem(additionalInfo.value, additionalInfo.key)}
        </LightTooltip>
      )}

      {additionalInfo && <Divider variant="vertical" />}

      {renderTileItem(
        pluralize("Pods", terminatedPodsCount, true),
        "Terminated"
      )}
    </>
  );
};
