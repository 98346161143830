import { Secret } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { KubernetesResource } from "./KubernetesResource";
import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/SupportedResourcesKinds";

export const KubernetesSecretsResource: KubernetesResource = {
  NameInK8S: "secrets",
  Group: "configuration",
  NavBarName: "Secrets",
  PresentName: "Secrets",
  Namespaced: true,
  UnnecessaryColumns: ["Data"],
  SupportedActions: [AvailableActions.Edit, AvailableActions.Delete],
  Kind: SupportedResourcesKinds.KubernetesSecretsResource,
  Icon: Secret,
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Type" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  hasLabels: true,
};
