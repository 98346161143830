import React, { useState } from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { muiColors } from "@komodorio/design-system";

import { liveStateDrawerAriaLabels } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerAriaLabels";

const StyledAccordion = styled(Accordion)`
  &&& {
    background: transparent;
    box-shadow: none;
    margin: 0;
    &:before {
      opacity: 0;
    }
  }
`;

const AnimatedExpandMoreIcon = styled(ExpandMoreIcon)<{ expanded: boolean }>`
  && {
    transform: rotate(${({ expanded }) => (expanded ? "0deg" : "-90deg")});
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &&& {
    min-height: 0;
    margin-top: 8px;
    > div {
      margin: 0;
    }
  }
`;

const AccordionSummaryContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

const StyledPaper = styled(Paper)`
  && {
    & > div {
      padding: 12px 16px;
      &:not(:last-child) {
        border-bottom: 1px solid ${muiColors.gray[200]};
      }
    }
  }
`;

type AccordionWrapperProps = {
  title: string;
  accordionSummary?: React.ReactNode;
  accordionContent: React.ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
  forceShrink?: boolean;
};

export const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  title,
  accordionSummary,
  accordionContent,
  ariaLabel,
  disabled,
  forceShrink,
}) => {
  const [expanded, setIsExpanded] = useState(true);
  return (
    <StyledAccordion
      id={title}
      expanded={forceShrink ? false : expanded}
      aria-label={ariaLabel}
    >
      <StyledAccordionSummary>
        <AccordionSummaryContent
          onClick={() => !disabled && setIsExpanded(!expanded)}
        >
          <AnimatedExpandMoreIcon
            expanded={forceShrink ? false : expanded}
            color="action"
          />
          {accordionSummary ?? <Typography variant={"h5"}>{title}</Typography>}
        </AccordionSummaryContent>
      </StyledAccordionSummary>
      <AccordionDetails>
        <StyledPaper aria-label={liveStateDrawerAriaLabels.detailsSection}>
          {accordionContent}
        </StyledPaper>
      </AccordionDetails>
    </StyledAccordion>
  );
};
