import { useState } from "react";
import { muiColors, muiPalette } from "@komodorio/design-system";
import { Markdown } from "@komodorio/design-system/komodor-ui";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Components } from "react-markdown";
import { capitalize } from "lodash";
import styled from "styled-components";

import { BaseMessage, Marker } from "@/generated/aiApi";
import { LinkTranslator } from "@/components/AiInvestigation/AiInvestigationChat/LinkTranslator";
import { AriaLabels } from "@/shared/config/ariaLabels";

function extractMarkdownLinkText(text: string): string {
  const match = text.match(/\[([^\]]+)\]\([^)]+\)/);
  return match?.length ? match[1] : "";
}

type MessageStepType = {
  number: number;
  title: string;
  content: string;
  isLoading: boolean;
  isFailed: boolean;
  isNoData: boolean;
  defaultExpanded: boolean;
};
const groupMessagesToSteps = (
  messages: BaseMessage[],
  isGroupCompleted: boolean
) => {
  const messageSteps: MessageStepType[] = [];
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const title = capitalize(extractMarkdownLinkText(message.content));
    let content = message.content;
    let isFailed = false;
    let isNoData = false;
    if (message.markers?.includes(Marker.ContainsQuery)) {
      const nextMessage = messages[i + 1];
      if (
        nextMessage &&
        nextMessage.markers?.includes(Marker.RespondsQuery) &&
        nextMessage.documents?.length
      ) {
        if (
          nextMessage.documents.every(
            (doc) =>
              doc.markers?.includes(Marker.NoData) ||
              doc.markers?.includes(Marker.Failure)
          )
        ) {
          if (
            nextMessage.documents.some((doc) =>
              doc.markers?.includes(Marker.Failure)
            )
          ) {
            isFailed = true;
          } else {
            isNoData = true;
          }
        } else {
          nextMessage.documents.forEach((doc) => {
            if (
              doc.markers?.includes(Marker.NoData) ||
              doc.markers?.includes(Marker.Failure)
            ) {
              return;
            }
            content += `\n\n${capitalize(doc.name)}:\n\n${doc.content}`;
          });
        }
        i++;
      }
    }
    const isLastStep = i >= messages.length - 1;
    messageSteps.push({
      number: Math.round((i + 1) / 2),
      title: isLastStep ? "Summary" : title,
      content,
      isLoading: isLastStep && !isGroupCompleted,
      isFailed,
      isNoData,
      defaultExpanded: isLastStep && isGroupCompleted,
    });
  }
  return messageSteps;
};

export const GroupedMessage = ({
  messages,
  isGroupCompleted,
}: {
  messages: BaseMessage[];
  isGroupCompleted: boolean;
}) => {
  const messageSteps = groupMessagesToSteps(messages, isGroupCompleted);
  return (
    <Stack gap="12px" width="100vw" maxWidth="100%">
      <Typography variant="h5">
        Displaying investigation steps{"\n\n"}
      </Typography>
      {messageSteps.map((step) => (
        <MessageStep key={step.number} step={step} />
      ))}
    </Stack>
  );
};

const LiveDots = styled.span`
  @keyframes dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }

  ::after {
    content: "";
    animation: dots 1.5s infinite steps(2);
  }
`;

const customMarkdownComponents: Components = {
  a: LinkTranslator,
};
const MessageStep = ({
  step: {
    number,
    title,
    content,
    isLoading,
    isFailed,
    isNoData,
    defaultExpanded,
  },
}: {
  step: MessageStepType;
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Stack>
      <Stack
        direction="row"
        gap="4px"
        alignItems="center"
        borderBottom={`1px solid ${muiPalette.divider}`}
        sx={{ cursor: "pointer" }}
        onClick={() => setExpanded(!expanded)}
        aria-label={AriaLabels.KlaudiaChat.ChatGroupedMessageStep}
      >
        <IconButton size="small">
          <ChevronRight
            fontSize="small"
            sx={{
              rotate: expanded ? "90deg" : "none",
              transition: "rotate 0.2s",
            }}
          />
        </IconButton>
        <Typography variant="body2">Step {number}</Typography>
        <Typography variant="h5" color="text.secondary">
          |
        </Typography>
        {isLoading ? (
          <Typography variant="body2" color="primary.main">
            Loading
            <LiveDots />
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            {title}
            {"\n\n"}
          </Typography>
        )}
      </Stack>
      {expanded && (
        <>
          {isFailed || isNoData ? (
            <Box
              padding="8px"
              sx={{
                backgroundColor: muiColors.gray[25],
                borderBottom: `1px solid ${muiPalette.divider}`,
              }}
            >
              <Typography variant="body3" color="text.secondary">
                {isFailed
                  ? "We're having trouble fetching the data."
                  : "No data found."}
                {"\n\n"}
              </Typography>
            </Box>
          ) : (
            <Box padding="8px">
              <Markdown components={customMarkdownComponents}>
                {content}
              </Markdown>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};
