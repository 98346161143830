import { useCallback, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid-pro";

type PaginationConfig = {
  defaultPageSize: number;
};

export function useGridPagination(config: PaginationConfig) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(config.defaultPageSize);

  const setPaginationModel = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setPage(newPaginationModel.page);
      setPageSize(newPaginationModel.pageSize);
    },
    []
  );

  function addPaginationParams<T>(
    params: T
  ): T & { offset: number; pageSize: number } {
    return {
      ...params,
      offset: page,
      pageSize,
    };
  }

  return { page, pageSize, setPaginationModel, addPaginationParams };
}
