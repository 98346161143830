import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import { compact } from "lodash";

import { ExternalDNSNotSyncedSupportingData } from "@/generated/reliabilityApi";
import {
  Stat,
  StatsList,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/StatsList";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import {
  Addon,
  Entity,
  ExternalDnsRecordFullFromJSON,
  ExternalDnsRecordSyncStatusEnum,
} from "@/generated/addonsApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ManagedRecordsTable } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ManagedRecordsTable";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { useResource } from "@/components/ResourceView/useResource";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import { FlowType } from "@/components/AiInvestigation/types";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";

const {
  violationDrawer: {
    externalDnsNotSynced: {
      outOfSyncRecordsStat: outOfSyncRecordsStatAriaLabel,
      servicesAtRiskStat: servicesAtRiskStatAriaLabel,
    },
  },
} = reliabilityArialLabels;

export const WhatHappened: React.FC<{
  supportingData: ExternalDNSNotSyncedSupportingData | undefined;
  komodorUid: string | undefined;
}> = ({ supportingData, komodorUid = "" }) => {
  const {
    cluster = "",
    namespace = "",
    kind = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};
  const agentId = useActiveAgent(cluster) ?? "";
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { resource, isFetching: isLoadingResource } = useResource({
    agentId,
    cluster,
    namespace,
    resourceType: kind,
    resourceName: name,
  });

  const {
    data,
    isFetching: isLoadingExternalDNS,
    refetch: refetchManagedRecords,
  } = useGetAddonFullLiveState(
    {
      addon: Addon.ExternalDns,
      entity: Entity.ExternalDnsOperator,
      uid: komodorUid,
      name,
      getEntityRequest: {
        clusterName: cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: ExternalDnsRecordFullFromJSON(json?.data?.data),
    })
  );

  useEffect(() => {
    reportLoadingState("ExternalDNSNotSyncedResource", isLoadingResource);
    reportLoadingState(
      "ExternalDNSNotSyncedLiveStateData",
      isLoadingExternalDNS
    );
  }, [isLoadingExternalDNS, isLoadingResource, reportLoadingState]);

  const externalDns = data?.data;

  if (isLoadingExternalDNS || (!resource && isLoadingResource)) {
    return (
      <Box margin="auto">
        <LinesLoader />
      </Box>
    );
  }

  if (!supportingData && !externalDns) {
    return null;
  }

  const stats: Stat[] = compact([
    supportingData?.numberOfRecordsOutOfSync !== undefined
      ? {
          label: "Out-of-sync records",
          value: supportingData.numberOfRecordsOutOfSync,
          ariaLabel: outOfSyncRecordsStatAriaLabel,
        }
      : undefined,
    externalDns?.affectedWorkloads !== undefined
      ? {
          label: "Services at risk",
          value: externalDns.affectedWorkloads,
          ariaLabel: servicesAtRiskStatAriaLabel,
        }
      : undefined,
  ]);

  return (
    <>
      <StatsList stats={stats} />
      {resource && externalDns?.syncStatus && (
        <AiInvestigation
          requestId={resource?.id}
          resource={resource}
          isHealthy={
            externalDns.syncStatus === ExternalDnsRecordSyncStatusEnum.Synced
          }
          flowType={FlowType.UnsyncedExternalDns}
        />
      )}
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h5">Managed records</Typography>
        <ManagedRecordsTable
          isLoadingManagedRecords={isLoadingExternalDNS}
          managedRecords={externalDns?.managedRecords ?? []}
          refetchManagedRecords={refetchManagedRecords}
          isErrorFetchingManagedRecords={
            externalDns?.failedFetchingManagedRecords ?? false
          }
        />
      </Box>
    </>
  );
};
