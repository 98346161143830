import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DELETE_ICON_STYLE } from "@/components/Settings/constants";
import { TrackedKey } from "@/generated/accounts";

export interface ActionsCellProps {
  params: GridRenderCellParams;
  onDelete: (key: TrackedKey) => void;
}

export const ActionsCell = ({ params, onDelete }: ActionsCellProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="end"
      justifyContent="center"
      aria-label="Actions cell"
    >
      {params.row.hovered && (
        <Stack direction="row">
          <LightTooltip title="Delete key" key="delete">
            <IconButton
              onClick={() => onDelete(params.row as TrackedKey)}
              aria-label={"Delete key"}
            >
              <DeleteOutlineIcon sx={DELETE_ICON_STYLE} fontSize="small" />
            </IconButton>
          </LightTooltip>
        </Stack>
      )}
    </Stack>
  );
};
