import { LocalStorageItem } from "@/shared/utils/localStorageSettings";

export const BASELINE_DRIFT_OPTION_KEY = "baselineDriftOption";
export const COMPARED_DRIFT_OPTION_KEY = "comparedDriftOptions";

export const CELL_HEIGHT = "137px";
export const CELL_WIDTH = "150px";
export const CELL_MAX_WIDTH = "174px";

export const policyPopupStorage = new LocalStorageItem(
  "drift-policy-popup-shown"
);
