import { useFormContext } from "react-hook-form";
import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { getSuffixByCheckType } from "../utils/getSuffixByCheckType";

import { CheckType } from "@/generated/reliabilityApi";

export const useGetReadOnlyTextPerCheckType = () => {
  const { getValues } = useFormContext();
  return useCallback(
    (checkType: CheckType, configurationNames: string[]) => {
      const values = getValues(configurationNames);
      const suffixes = getSuffixByCheckType(checkType);

      switch (checkType) {
        case CheckType.ThrottledCpu:
        case CheckType.HpaMax:
          return (
            <Typography variant={"body2"}>
              {values[0]}
              {suffixes[0]}
            </Typography>
          );

        case CheckType.RestartingContainers:
        case CheckType.NoisyNeighbor:
          return <Typography variant={"body2"}>{values[0]}</Typography>;
        case CheckType.DeprecatedApis:
          return (
            <Box display="flex" gap={"4px"}>
              <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
                {values[0]}
              </Typography>
              <Typography variant={"body2"}>{suffixes[0]}</Typography>
              <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
                {values[1]}
              </Typography>
              <Typography variant={"body2"}>{suffixes[1]}</Typography>
            </Box>
          );
        case CheckType.UnderProvisionedWorkloads:
          return (
            <Typography variant={"body2"}>
              {`${values[0]} ${suffixes[0]} ${suffixes[1]} ${values[1]} ${suffixes[2]}`}
            </Typography>
          );
        case CheckType.KubernetesEndOfLife:
          return (
            <Typography variant={"body2"}>
              {values[0]} {suffixes[0]}
            </Typography>
          );
        case CheckType.SinglePointOfFailure:
        case CheckType.ExternalDnsNotSynced:
          return (
            <Typography variant={"body2"}>
              {values[0]} {suffixes[0]}
            </Typography>
          );
        case CheckType.CertificateExpiration:
          return (
            <Typography variant={"body2"}>
              {values[0]} {suffixes[0]}
            </Typography>
          );
        default:
          return null;
      }
    },
    [getValues]
  );
};
