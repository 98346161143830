import React from "react";
import styled from "styled-components";
import { MagicAi24 } from "@komodorio/design-system/icons";
import MuiTypography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { muiColors } from "@komodorio/design-system";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import {
  useIsDemoService,
  useNextStep,
} from "../../../InvestigationMode/StepsLogic";

const Container = styled.div`
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;

  border-radius: 0.25rem;
  border: 1px solid var(--secondary-dark, #7ddcff);
  background: var(--secondary-light, #e5f8ff);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
`;

const Body = styled.div`
  display: flex;
  padding-left: 2rem;
`;

export const LogsAiDemo: React.FC = () => {
  const { demoLogsUi } = useOverridableFlags();
  const { goToNextStep } = useNextStep();
  const isDemoService = useIsDemoService();

  const shouldDisplay = demoLogsUi && isDemoService;

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <Header>
        <MagicAi24 />
        <Title>
          <MuiTypography
            variant={"body2"}
            sx={{ fontStyle: "normal", fontWeight: 700 }}
          >
            AI Log Analysis
          </MuiTypography>
          <MuiTypography
            variant="caption"
            display="inline"
            sx={{ fontSize: "0.5rem" }}
          >
            BETA
          </MuiTypography>
        </Title>
        <MuiTypography
          variant={"body2"}
          sx={{ fontStyle: "italic" }}
          color={muiColors.gray[500]}
        >
          Powered by Klaudia AI
        </MuiTypography>
        <LightTooltip
          placement={"top"}
          arrow={false}
          title={
            "Responses are generated by Klaudia AI based on the logs shown below, and have not been vetted by humans."
          }
        >
          <InfoOutlined
            sx={{
              color: muiColors.gray[500],
              fontSize: "16px",
            }}
          />
        </LightTooltip>
      </Header>
      <Body>
        <MuiTypography variant={"body2"}>
          <span style={{ fontWeight: "bold" }}>Root cause:</span> A variable
          called api_rate_limit which is larger than the acceptable max size
          (&gt;= 2000) <br />
          <span style={{ fontWeight: "bold" }}>Possible solution:</span> Check
          if this{" "}
          <Link style={{ cursor: "pointer" }} onClick={goToNextStep}>
            specific config
          </Link>{" "}
          (or validation) has recently changed
        </MuiTypography>
      </Body>
    </Container>
  );
};
