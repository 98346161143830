import { useState } from "react";
import { muiColors } from "@komodorio/design-system";

import { nodeAutoscalersTypeMapper } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/nodeAutoscalersTypeMapper";
import { OverProvisionedCountedDataOverview } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionCountedDataOverview";
import {
  overProvisionedHowToFixDescription,
  overProvisionedWhatHappenedContent,
  overProvisionedWhyIsThisImportantContent,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedClusterConstants";
import { OverProvisionedNodeUtilizationContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedNodeUtilizationContainer";
import { OverProvisionNodesDataTable } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionNodesDataTable";
import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";
import { DrawerViolationSection } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/OverviewContentComponents";
import { SupportingDataContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/SupportingDataComponents";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { useGetPolicyLink } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetPolicyLink";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import {
  GetSummaryDataOutput,
  MetadataItemContent,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerTypes";
import { ResourceNotFound } from "@/components/ResourceView/ResourceNotFound";
import { AutoScalerEnum } from "@/generated/addonsApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { isInEnum } from "@/shared/utils/isInEnum";
import { OverProvisionedNodeGroupBySelector } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedNodeGroupBySelector";
import { NodesTableGroupBy } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedDataTypes";

export const OverProvisionedClusterDrawer: React.FC = () => {
  const { data } = useViolation();
  const [nodesDataGroupBy, setNodesDataGroupBy] = useState<NodesTableGroupBy>(
    NodesTableGroupBy.Nodes
  );
  const violation = data?.data.violation;
  const { supportingData, headerProps, komodorUid } =
    useGetCommonDrawerProps(violation);
  const policyLinkMetadata = useGetPolicyLink({
    policyName: violation?.policyName,
    policyId: violation?.policyId,
  });

  if (!komodorUid || !supportingData?.overProvisionedCluster) {
    return <ResourceNotFound />;
  }

  const { cluster = "" } = parseKomodorUid(komodorUid) ?? {};
  const autoScalerType = supportingData?.overProvisionedCluster?.autoscalerKind;
  const { title: autoscalerTypeTitle, icon: AutoscalerTypeIcon = undefined } =
    !!autoScalerType && isInEnum(AutoScalerEnum, autoScalerType)
      ? nodeAutoscalersTypeMapper[autoScalerType]
      : {
          title: autoScalerType,
        };
  const metadataItems: MetadataItemContent[] = [
    { title: "cluster", value: cluster },
    {
      title: "Autoscaler type",
      value: autoscalerTypeTitle ?? "Unknown",
      leftIcon: AutoscalerTypeIcon
        ? () => <AutoscalerTypeIcon size={20} color={muiColors.gray[600]} />
        : undefined,
    },
  ];
  if (policyLinkMetadata) {
    metadataItems.push(policyLinkMetadata);
  }

  const violationSummaryData: GetSummaryDataOutput = {
    komodorUid,
    metadataItems,
  };

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: violationSummaryData }}
      content={
        <div>
          <DrawerViolationSection
            type="what"
            content={overProvisionedWhatHappenedContent}
          />
          <OverProvisionedCountedDataOverview
            violationData={supportingData.overProvisionedCluster}
          />
          <SupportingDataContainer>
            <OverProvisionedNodeUtilizationContainer cluster={cluster} />
          </SupportingDataContainer>
          <SupportingDataContainer>
            {autoScalerType === AutoScalerEnum.Karpenter && (
              <OverProvisionedNodeGroupBySelector
                selectedValue={nodesDataGroupBy}
                onSelectedValueChange={setNodesDataGroupBy}
              />
            )}
            <OverProvisionNodesDataTable
              overProvisionedNodes={
                nodesDataGroupBy === NodesTableGroupBy.Nodes
                  ? supportingData.overProvisionedCluster.dataByNodeTypes
                  : supportingData.overProvisionedCluster.dataByNodePool ?? {}
              }
            />
          </SupportingDataContainer>
          <SupportingDataContainer>
            <DrawerViolationSection
              type="why"
              content={overProvisionedWhyIsThisImportantContent}
            />
          </SupportingDataContainer>
          <DrawerViolationSection
            type="how"
            content={overProvisionedHowToFixDescription}
          />
        </div>
      }
    />
  );
};
