import React, { useCallback, useMemo } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FilterableTreeView } from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";

import {
  actionsToTree,
  filterActionsBy,
  getActionsByScope,
  getActionsGroups,
  getDependsOnActions,
  omitGroupsFromSelectedActions,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import {
  SELECT_ALL_ACTION,
  VIEW_ALL_ACTION,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/constants";
import { ActionsPanelSkeleton } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/ActionsPanelSkeleton";
import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { useRbacActionsContext } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/useRbacActionsContext";

const VIEW_ALL_ALLOWED_SCOPES = [Scope.cluster, Scope.namespace];

export type ActionsPanelProps = {
  selectedActions: string[];
  setSelectedActions: (actions: string[]) => void;
};

export const ActionsPanel = ({
  selectedActions,
  setSelectedActions,
}: ActionsPanelProps) => {
  const { scope } = useStatementDrawerContext();
  const { isLoadingActions, globalActions } = useRbacActionsContext();
  const [requiredActions, setRequiredActions] = React.useState<Set<string>>(
    new Set()
  );

  const actionsByScope = useMemo(() => {
    if (!globalActions) {
      return [];
    }
    return getActionsByScope(globalActions, scope);
  }, [globalActions, scope]);

  const areAllActionsSelected = useMemo(() => {
    if (!actionsByScope?.length) {
      return false;
    }

    // view:all is an action that we add to the selected actions, but it is not considered in the selected actions
    return (
      actionsByScope.length ===
      filterActionsBy(
        omitGroupsFromSelectedActions(selectedActions, globalActions),
        (action: string) => action !== VIEW_ALL_ACTION.id
      ).length
    );
  }, [actionsByScope, selectedActions, globalActions]);

  const selectedNodes = useMemo(() => {
    if (areAllActionsSelected) {
      return new Set([SELECT_ALL_ACTION.id, ...selectedActions]);
    }

    return new Set(
      filterActionsBy(
        selectedActions,
        (action) => action !== SELECT_ALL_ACTION.id
      )
    );
  }, [selectedActions, areAllActionsSelected]);

  const handleSelected = useCallback(
    (selected: Set<string>) => {
      const dependsOnInScopeSet = getDependsOnActions(
        selected,
        globalActions ?? []
      );

      setRequiredActions(dependsOnInScopeSet);
      setSelectedActions([
        ...Array.from(new Set([...selected, ...dependsOnInScopeSet])),
      ]);
    },
    [setSelectedActions, globalActions]
  );

  const handleRemoveAction = useCallback(
    (id: string) => {
      const newActions = selectedActions.filter((a) => a !== id);
      handleSelected(new Set(newActions));
    },
    [selectedActions, handleSelected]
  );

  // A group can be selected in the tree, but it should not be displayed in the selected actions
  const displayedSelectedActions = useMemo(() => {
    if (!selectedActions || !globalActions) {
      return [];
    }
    return selectedActions.filter(
      (a) =>
        !!globalActions.find((action) => action.action === a) ||
        a === VIEW_ALL_ACTION.id
    );
  }, [selectedActions, globalActions]);

  const selectedActionsContent = useMemo(() => {
    if (!displayedSelectedActions?.length) {
      return (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ margin: "0 auto" }}
        >
          No actions selected
        </Typography>
      );
    }

    return displayedSelectedActions.map((id, index) => (
      <Chip
        key={index}
        label={id}
        onDelete={() => handleRemoveAction(id)}
        aria-label={`chip-${id}`}
        disabled={requiredActions.has(id)}
      />
    ));
  }, [displayedSelectedActions, handleRemoveAction, requiredActions]);

  const canViewAll = useMemo(
    () => VIEW_ALL_ALLOWED_SCOPES.includes(scope),
    [scope]
  );

  const treeData = useMemo(() => {
    if (!actionsByScope) {
      return [];
    }
    return actionsToTree(actionsByScope);
  }, [actionsByScope]);

  const expandedNodes = useMemo(() => {
    if (!actionsByScope) {
      return new Set<string>();
    }
    return getActionsGroups(actionsByScope);
  }, [actionsByScope]);

  const handleSelectAll = useCallback(
    (isChecked: boolean) => {
      if (!isChecked) {
        handleSelected(new Set());
        return;
      }
      handleSelected(
        new Set([
          ...actionsByScope.map((a) => a.action),
          SELECT_ALL_ACTION.id,
          canViewAll ? VIEW_ALL_ACTION.id : "",
        ])
      );
    },
    [actionsByScope, handleSelected, canViewAll]
  );

  const selectAllActionNode = useMemo(() => {
    return {
      ...SELECT_ALL_ACTION,
      onCheck: handleSelectAll,
    };
  }, [handleSelectAll]);

  const viewAllActionNode = useMemo(() => {
    return {
      ...VIEW_ALL_ACTION,
      disabled: requiredActions.has(VIEW_ALL_ACTION.id),
    };
  }, [requiredActions]);

  const fixedNodes = useMemo(() => {
    return canViewAll
      ? [selectAllActionNode, viewAllActionNode]
      : [selectAllActionNode];
  }, [canViewAll, selectAllActionNode, viewAllActionNode]);

  if (isLoadingActions) {
    return <ActionsPanelSkeleton />;
  }

  return (
    <Stack
      direction="row"
      columnGap="40px"
      width="100%"
      sx={{ overflow: "auto" }}
    >
      <Stack sx={{ flex: "1 1 0px", overflow: "auto" }}>
        <Typography variant="h4">Actions</Typography>
        <FilterableTreeView
          treeData={treeData}
          onSelect={handleSelected}
          initialState={{
            selectedNodes,
            expandedNodes,
          }}
          fixedNodes={fixedNodes}
        />
      </Stack>
      <Stack sx={{ flex: "1 1 0px" }} rowGap="16px">
        <Typography
          variant="h4"
          aria-label={createPolicyAriaLabels.actionsPanel.selectedActions}
        >
          Selected Actions ({displayedSelectedActions.length})
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap"
          columnGap="8px"
          rowGap="8px"
          sx={{
            border: `1px solid ${muiColors.gray[200]}`,
            borderRadius: "4px",
            backgroundColor: muiColors.gray[25],
            padding: "8px",
            marginBottom: "16px",
            overflowY: "auto",
          }}
        >
          {selectedActionsContent}
        </Stack>
      </Stack>
    </Stack>
  );
};
