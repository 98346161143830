import React, { useEffect, useMemo } from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import {
  useGetFiltersOptions,
  useIsFilterFetchingData,
} from "@/components/k8sAddons/components/AddonFilterHandler/addonFilterHandlerHooks";
import { SelectFilterHandler } from "@/shared/components/urlFilters/SelectFilterHandler";
import { GenericFilter } from "@/generated/addonsApi";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type SelectProps = Omit<
  React.ComponentProps<typeof AdvancedMultiSelect<string>>,
  "options" | "values" | "onChange" | "onClose" | "onOpen"
>;
type FiltersContainerProps = {
  filterCategory: string;
  label: string;
  additionalFilters?: GenericFilter[];
  selectProps?: SelectProps;
  labelCreator?: (key: string) => string;
};

type LabelCreationConfig = {
  labelCreator?: (key: string) => string;
};

function createLabelForKey(
  key: string,
  labelCreationConfig: LabelCreationConfig
) {
  const { labelCreator } = labelCreationConfig;
  if (labelCreator) {
    return labelCreator(key);
  }
  return key;
}

export const AddonFilterHandler: React.FC<FiltersContainerProps> = ({
  filterCategory,
  label,
  additionalFilters = [],
  selectProps,
  labelCreator,
}) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { data, isFetching, isLoading } = useGetFiltersOptions({
    filterCategory,
    additionalFilters,
  });

  const { isFetchingData } = useIsFilterFetchingData({
    filterCategory,
    isFetching,
  });

  useEffect(() => {
    reportLoadingState(
      `addon-filter-handler-${filterCategory}`,
      isLoading || isFetchingData
    );
  }, [filterCategory, isFetchingData, isLoading, reportLoadingState]);

  const options = useMemo<MuiSelectionOption<string>[]>(() => {
    return (
      data?.data.items?.map((item) => ({
        value: item.key ?? "",
        label: createLabelForKey(item.key ?? "", {
          labelCreator,
        }),
      })) ?? []
    );
  }, [data?.data.items, labelCreator]);

  return (
    <SelectFilterHandler
      filterCategory={filterCategory}
      label={label}
      selectProps={selectProps}
      options={options}
      disable={isLoading || isFetchingData}
    />
  );
};
