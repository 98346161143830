import React, { useCallback, useMemo } from "react";
import Paper from "@mui/material/Paper";
import {
  DataGrid,
  Loading,
  NoResults,
} from "@komodorio/design-system/komodor-ui";
import {
  GridColumnResizeParams,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";

import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";
import { RbacRole } from "@/generated/auth";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/RolesPage/types";
import {
  getColumnsDefinitions,
  POLICIES_CELL_WIDTH,
} from "@/pages/organization-settings/access-management/RolesPage/Grid/gridHelpers";
import { ariaLabels } from "@/pages/organization-settings/access-management/RolesPage/ariaLabels";
import { usePaginationContext } from "@/shared/context/tableContext/usePaginationContext";

interface RolesGridProps {
  width?: number;
  filteredRoles: RbacRole[];
  setRoleToEdit: OnEdit;
  setRoleToDelete: OnDelete;
  isLoading: boolean;
}

export const RolesGrid = ({
  width,
  filteredRoles,
  setRoleToEdit,
  setRoleToDelete,
  isLoading,
}: RolesGridProps) => {
  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();

  const { pageSize, setPageSize } = usePaginationContext();
  const [page, setPage] = React.useState<number>(0);
  const [policiesColumnWidth, setPoliciesColumnWidth] =
    React.useState<number>(POLICIES_CELL_WIDTH);

  const rows = useMemo(
    () =>
      filteredRoles.map((role: RbacRole) => ({
        ...role,
        actions: role.id,
        hovered: role.id === hoveredRowId,
      })),
    [filteredRoles, hoveredRowId]
  );

  const columns = useMemo(
    () =>
      getColumnsDefinitions({
        onEdit: setRoleToEdit,
        onDelete: setRoleToDelete,
        policiesCellWidth: policiesColumnWidth,
      }),
    [setRoleToEdit, setRoleToDelete, policiesColumnWidth]
  );

  const onColumnResize = useCallback((params: GridColumnResizeParams) => {
    if (params.colDef.field === "rbacPolicies") {
      setPoliciesColumnWidth(params.width);
    }
  }, []);

  return (
    <Paper
      variant="elevation"
      elevation={1}
      sx={{ width: width || "100%", overflow: "auto" }}
    >
      <DataGrid
        autoHeight
        columns={columns}
        rows={rows}
        loading={isLoading}
        disableVirtualization
        paginationModel={{ pageSize, page }}
        onPaginationModelChange={(newModel: GridPaginationModel) => {
          setPageSize(newModel.pageSize);
          setPage(newModel.page);
        }}
        slotProps={{
          row: {
            onMouseEnter: handleRowHovered,
            onMouseLeave: handleRowLeaved,
          },
        }}
        slots={{
          loadingOverlay: () => <Loading />,
          noRowsOverlay: () => <NoResults />,
        }}
        sx={{
          "--DataGrid-overlayHeight": "300px",
        }}
        aria-label={ariaLabels.table.container}
        onColumnWidthChange={onColumnResize}
      />
    </Paper>
  );
};
