import React, { useCallback, useMemo, useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import styled from "styled-components";
import { getInitialPageConfig } from "@komodorio/design-system/komodor-ui";

import { Title } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import {
  ServiceTerminationImpactData,
  StandardTerminationImpactData,
} from "@/generated/reliabilityApi";
import {
  AffectedDataRow,
  createInitialSorting,
  getAffectedJobsColumns,
  getAffectedWorkloadsColumns,
  getUnhealthyServicesColumns,
  sharedTableProps,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ImpactedWorkloadsSectionTableBuilder";
import { scaleDownImpactDrawerAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ScaleDownImpactConstants";
import { ClientSideDataGridWithFilter } from "@/shared/components/ClientSideDataGridWithFilter/ClientSideDataGridWithFilter";
import { parseTimeWindowDataToUrlData } from "@/components/ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { getInitialTimeWindow } from "@/components/appView/sections/AppView/utils/timeWindow";
import { Timeframe } from "@/shared/types/TimeWindow";
import { useOpenResourceDrawer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/hooks/useOpenResourceDrawer";
import { useOpenWorkflowDrawer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/hooks/useOpenWorkflowDrawer";
import {
  getAffectedJobsRowData,
  getAffectedWorkflowsRowData,
  getUnhealthyServicesRowData,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/impactedWorkloadsSectionData";
import { ImpactedResourceClickParams } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/scaleDownImpactTypes";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { dispatchEvent } from "@/shared/hooks/analytics";
import { VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE } from "@/components/reliability/components/pages/violations/violationsConstants";

const TITLE_TOOLTIP_CONTENT =
  "Showing the top 100 impacted workloads from each category (Services, Jobs, Workflows)";

const HelpIcon = styled(HelpOutlineOutlined)`
  margin-left: 6px;
  vertical-align: middle;
`;

const InternalTabPanel = styled(TabPanel)`
  && {
    padding: 16px 0;
  }
`;

enum ImpactedWorkloadsSectionTabs {
  UnhealthyServices = "unhealthy-service",
  AffectedJobs = "affected-jobs",
  Workflows = "workflows",
}

type ImpactedWorkloadsSectionProps = {
  unhealthyServices: ServiceTerminationImpactData[];
  affectedJobs: StandardTerminationImpactData[];
  affectedWorkflows: StandardTerminationImpactData[];
};

function getInitialTab({
  affectedJobsCount,
  affectedWorkflowsCount,
  unhealthyServicesCount,
}: {
  affectedJobsCount: number;
  affectedWorkflowsCount: number;
  unhealthyServicesCount: number;
}): ImpactedWorkloadsSectionTabs {
  if (unhealthyServicesCount > 0) {
    return ImpactedWorkloadsSectionTabs.UnhealthyServices;
  }
  if (affectedJobsCount > 0) {
    return ImpactedWorkloadsSectionTabs.AffectedJobs;
  }
  if (affectedWorkflowsCount > 0) {
    return ImpactedWorkloadsSectionTabs.Workflows;
  }

  return ImpactedWorkloadsSectionTabs.UnhealthyServices;
}

export const ImpactedWorkloadsSection: React.FC<
  ImpactedWorkloadsSectionProps
> = ({ affectedJobs, affectedWorkflows, unhealthyServices }) => {
  const unhealthyServicesCount = unhealthyServices.length;
  const affectedJobsCount = affectedJobs.length;
  const affectedWorkflowsCount = affectedWorkflows.length;

  const [tab, setTab] = useState(
    getInitialTab({
      affectedJobsCount,
      affectedWorkflowsCount,
      unhealthyServicesCount,
    })
  );

  const timeWindow = getInitialTimeWindow(Timeframe.Last7Days);
  const { urlTimeWindow, urlTimeframe } =
    parseTimeWindowDataToUrlData(timeWindow);
  const openResourceDrawer = useOpenResourceDrawer();
  const openWorkflowDrawer = useOpenWorkflowDrawer();

  const onOpenResourceClicked = useCallback(
    (komodorUid: string) => {
      dispatchEvent<ImpactedResourceClickParams>(
        AnalyticEvents.Reliability.ScaleDownImpact.ImpactedResourceClicked,
        { resourceUid: komodorUid }
      );
      openResourceDrawer({
        komodorUid,
        urlTimeframe,
        urlTimeWindow,
      });
    },
    [openResourceDrawer, urlTimeWindow, urlTimeframe]
  );

  const onOpenWorkflowClicked = useCallback(
    (komodorUid: string) => {
      openWorkflowDrawer({
        komodorUid,
        urlTimeframe,
        urlTimeWindow,
      });
    },
    [openWorkflowDrawer, urlTimeWindow, urlTimeframe]
  );

  const unhealthyServicesRowData: AffectedDataRow[] = useMemo(() => {
    return getUnhealthyServicesRowData(unhealthyServices);
  }, [unhealthyServices]);
  const affectedJobsRowData: AffectedDataRow[] = useMemo(() => {
    return getAffectedJobsRowData(affectedJobs);
  }, [affectedJobs]);

  const affectedWorkloadsRowData: AffectedDataRow[] = useMemo(() => {
    return getAffectedWorkflowsRowData(affectedWorkflows);
  }, [affectedWorkflows]);

  return (
    <>
      <Title
        aria-label={
          scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection.title
        }
      >
        Impacted workloads
        <Tooltip title={TITLE_TOOLTIP_CONTENT} placement="top">
          <HelpIcon fontSize={"small"} color={"action"} />
        </Tooltip>
      </Title>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={(_eve, newSelectedTab) => setTab(newSelectedTab)}
        >
          <Tab
            value={ImpactedWorkloadsSectionTabs.UnhealthyServices}
            label={`Unhealthy Services (${unhealthyServicesCount})`}
            aria-label={
              scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection
                .unhealthyServicesTabHeader
            }
            disabled={unhealthyServicesCount === 0}
          ></Tab>
          <Tab
            value={ImpactedWorkloadsSectionTabs.AffectedJobs}
            label={`Affected Jobs (${affectedJobsCount})`}
            aria-label={
              scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection
                .affectedJobsTabHeader
            }
            disabled={affectedJobsCount === 0}
          ></Tab>
          <Tab
            value={ImpactedWorkloadsSectionTabs.Workflows}
            label={`Workflows (${affectedWorkflowsCount})`}
            aria-label={
              scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection
                .workflowsTabHeader
            }
            disabled={affectedWorkflowsCount === 0}
          ></Tab>
        </Tabs>
        <InternalTabPanel
          value={ImpactedWorkloadsSectionTabs.UnhealthyServices}
        >
          <ClientSideDataGridWithFilter
            {...sharedTableProps}
            {...getInitialPageConfig(VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE, {
              sorting: createInitialSorting("availabilityIssuesCount"),
            })}
            aria-label={
              scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection
                .unhealthyServicesTable
            }
            rows={unhealthyServicesRowData}
            columns={getUnhealthyServicesColumns(onOpenResourceClicked)}
          ></ClientSideDataGridWithFilter>
        </InternalTabPanel>
        <InternalTabPanel value={ImpactedWorkloadsSectionTabs.AffectedJobs}>
          <ClientSideDataGridWithFilter
            {...sharedTableProps}
            {...getInitialPageConfig(VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE, {
              sorting: createInitialSorting("terminatedPodsCount"),
            })}
            aria-label={
              scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection
                .affectedJobsTable
            }
            rows={affectedJobsRowData}
            columns={getAffectedJobsColumns(onOpenResourceClicked)}
          ></ClientSideDataGridWithFilter>
        </InternalTabPanel>
        <InternalTabPanel value={ImpactedWorkloadsSectionTabs.Workflows}>
          <ClientSideDataGridWithFilter
            {...sharedTableProps}
            {...getInitialPageConfig(VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE, {
              sorting: createInitialSorting("terminatedPodsCount"),
            })}
            aria-label={
              scaleDownImpactDrawerAriaLabels.impactedWorkloadsSection
                .workflowsTable
            }
            rows={affectedWorkloadsRowData}
            columns={getAffectedWorkloadsColumns(onOpenWorkflowClicked)}
          ></ClientSideDataGridWithFilter>
        </InternalTabPanel>
      </TabContext>
    </>
  );
};
