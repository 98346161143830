import {
  BatchActionsStoreState,
  SubmitRequestWithCb,
} from "@/shared/store/batchActionsStore/batchActionsStore";
import { GetBulkActionsResultsResp } from "@/generated/actionsApi";

// getters
export const selectedBatchActionsRequestSelector = (
  state: BatchActionsStoreState
): SubmitRequestWithCb | undefined => state.batchActionsRequest;
export const selectBatchActionsResponses = (state: BatchActionsStoreState) =>
  state.batchActionsResponses;
export const selectBatchActionResponseToShow = (
  state: BatchActionsStoreState
) => state.batchActionResponseToShow;
export const selectBatchActionsProcessCompleteCbDict = (
  state: BatchActionsStoreState
) => state.batchActionsProcessCompleteCbDict;

// setters
export const setBatchActionsRequestSelector = (
  state: BatchActionsStoreState
): ((bulkActionsRequest: SubmitRequestWithCb) => void) =>
  state.setBatchActionsRequest;
export const clearBatchActionsRequestSelector = (
  state: BatchActionsStoreState
): (() => void) => state.clearBatchActionsRequest;
export const setBatchActionsResponseSelector = (
  state: BatchActionsStoreState
): ((
  bulkActionsResponse: GetBulkActionsResultsResp,
  onProcessCompleteCb: (() => void) | undefined
) => void) => state.setBatchActionsResponse;
export const setUpdateBatchActionsResponseSelector = (
  state: BatchActionsStoreState
): ((bulkActionsResponse: GetBulkActionsResultsResp) => void) =>
  state.updateBatchActionsResponse;
export const setRemoveBatchActionsResponseSelector = (
  state: BatchActionsStoreState
): ((bulkId: string) => void) => state.removeBatchActionsResponse;
export const setBatchResponseToShowSelector = (
  state: BatchActionsStoreState
): ((bulkId: string) => void) => state.setBatchResponseToShow;
export const clearBatchResponseToShowSelector = (
  state: BatchActionsStoreState
): (() => void) => state.clearBatchResponseToShow;
export const selectClearBatchActionsProcessCompleteCb = (
  state: BatchActionsStoreState
): ((bulkId: string) => void) => state.clearBatchActionsProcessCompleteCb;
