import { muiColors } from "@komodorio/design-system";

import { nodeAutoscalersTypeMapper } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/nodeAutoscalersTypeMapper";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { useGetPolicyLink } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetPolicyLink";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import {
  GetSummaryDataOutput,
  MetadataItemContent,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerTypes";
import { ResourceNotFound } from "@/components/ResourceView/ResourceNotFound";
import { AutoScalerEnum } from "@/generated/addonsApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { isInEnum } from "@/shared/utils/isInEnum";
import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";
import { SupportingDataContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/SupportingDataComponents";
import { DrawerViolationSection } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/OverviewContentComponents";
import {
  unschedulablePodsHowToFix,
  unschedulablePodsWhatHappened,
  unschedulablePodsWhyIsThisImportant,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/unschedulablePodsConstants";
import { UnschedulablePodsCountedData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/UnschedulablePodsCountedData";
import { formatSeconds } from "@/shared/utils/timeUtils";
import { UnschedulablePodsImpactedTable } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/UnschedulablePodsImpactedTable";
import { useOpenAutoscalerDrawer } from "@/components/k8sAddons/addons/NodeAutoscalers/useOpenAutoscalerDrawer";
import { UnschedulablePodsTimeDistribution } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/UnschedulablePodsTimeDistribution";

export const UnschedulablePodsDrawer: React.FC = () => {
  const { data } = useViolation();
  const violation = data?.data.violation;

  const { supportingData, headerProps, komodorUid } =
    useGetCommonDrawerProps(violation);
  const policyLink = useGetPolicyLink({
    policyName: violation?.policyName,
    policyId: violation?.policyId,
  });
  const openAutoscalerDrawer = useOpenAutoscalerDrawer();

  if (!komodorUid) {
    return <ResourceNotFound />;
  }

  const { cluster = "", name = "" } = parseKomodorUid(komodorUid) ?? {};
  const unschedulablePodsSupportingData = supportingData?.unschedulablePods;
  if (!unschedulablePodsSupportingData || cluster === "") {
    return <ResourceNotFound />;
  }
  const autoScalerType = unschedulablePodsSupportingData.autoscalerKind;
  const { title: autoscalerTypeTitle, icon: AutoscalerTypeIcon = undefined } =
    !!autoScalerType && isInEnum(AutoScalerEnum, autoScalerType)
      ? nodeAutoscalersTypeMapper[autoScalerType]
      : {
          title: autoScalerType,
        };
  const metadataItems: MetadataItemContent[] = [
    { title: "cluster", value: cluster },
    {
      title: "autoscaler",
      value: name,
      onValueClick: () => openAutoscalerDrawer({ cluster, name }),
    },
    {
      title: "Autoscaler type",
      value: autoscalerTypeTitle ?? "Unknown",
      leftIcon: AutoscalerTypeIcon
        ? () => <AutoscalerTypeIcon size={20} color={muiColors.gray[600]} />
        : undefined,
    },
  ];
  if (policyLink) {
    metadataItems.push(policyLink);
  }

  const violationSummaryData: GetSummaryDataOutput = {
    komodorUid,
    metadataItems,
  };

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: violationSummaryData }}
      content={
        <>
          <SupportingDataContainer>
            <DrawerViolationSection
              type="what"
              content={unschedulablePodsWhatHappened({
                clusterName: cluster,
                time: formatSeconds(
                  unschedulablePodsSupportingData.totalUnschedulableTime
                ),
              })}
            />
          </SupportingDataContainer>
          <SupportingDataContainer>
            <UnschedulablePodsCountedData
              avgTimeToSchedule={
                unschedulablePodsSupportingData.avgUnschedulableTime
              }
              unschedulablePods={
                unschedulablePodsSupportingData.unschedulablePods
              }
              unschedulableTime={
                unschedulablePodsSupportingData.totalUnschedulableTime
              }
            />
          </SupportingDataContainer>
          <SupportingDataContainer>
            <UnschedulablePodsTimeDistribution
              distribution={
                unschedulablePodsSupportingData.unschedulePodsDistribution
              }
            />
          </SupportingDataContainer>
          <SupportingDataContainer>
            <UnschedulablePodsImpactedTable
              supportingData={unschedulablePodsSupportingData}
            />
          </SupportingDataContainer>
          <SupportingDataContainer>
            <DrawerViolationSection
              type="why"
              content={unschedulablePodsWhyIsThisImportant}
            />
          </SupportingDataContainer>
          <DrawerViolationSection
            type="how"
            content={unschedulablePodsHowToFix}
          />
        </>
      }
    />
  );
};
