import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";
import { GridFeatureMode } from "@mui/x-data-grid/models/gridFeatureMode";

import { useClearAllFilters } from "@/components/k8sAddons/hooks/filters/useClearAllFilters";
import { ADD_ON_TABLE_PAGE_SIZE_OPTIONS } from "@/components/k8sAddons/constants/table";
import { NoResultsFound } from "@/components/k8sAddons/components/NoResultsFound";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";

export const useCommonDataGridProps = (
  error: unknown
): Partial<DataGridProProps> => {
  const onClearFilters = useClearAllFilters();
  const { disableServerSidePagination } = useAddonContext();
  const paginationMode: GridFeatureMode = disableServerSidePagination
    ? "client"
    : "server";

  return {
    disableColumnMenu: true,
    pagination: true,
    paginationMode,
    disableColumnResize: true,
    disableVirtualization: true,
    pageSizeOptions: ADD_ON_TABLE_PAGE_SIZE_OPTIONS,
    slots: {
      noResultsOverlay: () => (
        <NoResultsFound onClearFilters={onClearFilters} isError={!!error} />
      ),
      noRowsOverlay: () => (
        <NoResultsFound onClearFilters={onClearFilters} isError={!!error} />
      ),
    },
  };
};
