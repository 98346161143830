import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const customKeyOptionElement = (option: MuiSelectionOption<string>) => {
  const { value } = option;
  const [type, name] = value.split(":");
  return (
    <Stack>
      <Typography variant="body2" color="text.primary">
        {name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {type}
      </Typography>
    </Stack>
  );
};
