import { useHelmReleasesList } from "./useHelmReleasesList";
import { HelmComparisonSelection } from "./HelmComparisonSelection";
import { HelmComparisonContent } from "./HelmComparisonContent";
import { HelmChartsEmptyState } from "./HelmChartsEmptyState";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import Divider from "@/components/monitorsView/common/Divider";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

export const HelmDriftContent: React.FC = () => {
  const { helmReleases, isLoading, isError } = useHelmReleasesList();
  const { currentWorkspace } = useWorkspaces();

  const shouldShowReleasesEmptyStateOnWorkspace =
    !isLoading && helmReleases?.length === 0 && !isError && currentWorkspace;
  if (shouldShowReleasesEmptyStateOnWorkspace) {
    return <HelmChartsEmptyState />;
  }
  return (
    <>
      <HelmComparisonSelection />
      <Divider />
      {!isLoading && !isError && (
        <DatadogReportLoadingTimeContextProvider
          viewOptions={{
            name: DatadogViewNamesEnum.helmDriftAnalysisAttributes,
            context: {
              feTeam: "troubleshooting",
              beTeam: "troubleshooting",
            },
          }}
        >
          <ResponsiveLayout>
            <HelmComparisonContent />
          </ResponsiveLayout>
        </DatadogReportLoadingTimeContextProvider>
      )}
    </>
  );
};
