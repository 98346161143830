import { useMemo } from "react";

import { useQueryPolling } from "../../../shared/hooks/useQueryPolling";
import { useFetchAllServicesQuery } from "../../../generated/graphql";
import {
  processDataFromHasuraAction,
  useGetServicesFields,
} from "../ProcessServiceFetchRequests";

import { FetchServicesOutput, GetServicesParams } from "./types";
import { useGetServicePollingIntervalInSeconds } from "./useGetServicePollingIntervalInSeconds";
import { servicesListToDictionary } from "./servicesListToDictionary";
import { useProcessServicesFromResourcesApi } from "./useProcessServicesFromResourcesApi";

export const useGetServices = ({
  includeLabels,
  includeAnnotations,
  fetchAllServicesFromResourcesApiFF,
  fetchServicesFromMaterializedView,
  pause,
}: GetServicesParams): FetchServicesOutput => {
  const pollingInterval = useGetServicePollingIntervalInSeconds();
  const enableResourcesApi = !pause && !!fetchAllServicesFromResourcesApiFF;
  const pauseGraphQl = pause || !!fetchAllServicesFromResourcesApiFF;
  const { servicesDictionary, servicesList, fetchMode, isFetching } =
    useProcessServicesFromResourcesApi({
      type: "services",
      requestFields: useGetServicesFields(
        !!includeLabels,
        !!includeAnnotations
      ),
      enableResourcesApi,
    });

  const [services] = useQueryPolling(
    useFetchAllServicesQuery({
      variables: {
        fetchServicesFromMaterializedView: !!fetchServicesFromMaterializedView,
        includeLabels: !!includeLabels,
        includeAnnotations: !!includeAnnotations,
      },
      pause: pauseGraphQl,
    }),
    pollingInterval * 1000,
    pauseGraphQl
  );

  const processedServices = useMemo(
    () => processDataFromHasuraAction(services?.fetchAllServices),
    [services?.fetchAllServices]
  );

  return useMemo(() => {
    if (fetchAllServicesFromResourcesApiFF) {
      return {
        servicesDictionary,
        servicesList,
        fetchMode,
        isFetching,
      };
    }

    return {
      servicesList: processedServices,
      servicesDictionary: servicesListToDictionary(processedServices),
      fetchMode: "full",
      isFetching,
    };
  }, [
    fetchAllServicesFromResourcesApiFF,
    fetchMode,
    processedServices,
    servicesDictionary,
    servicesList,
    isFetching,
  ]);
};
