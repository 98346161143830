import styled, { css } from "styled-components";

import {
  blueForUIText,
  gray10,
  gray5,
  gray9,
  offWhite,
  pinkBrand,
} from "../../../Colors";
import Table from "../../common/table/Table";
import {
  lightStyle,
  smallLightShortStyle,
} from "../monitorsConfiguration/common/styles";
import LogsIcon from "../assets/horizontalBarsBlack.svg?react";
import { FlexRows } from "../../common/FlexContainers";
import Link from "../../common/Link";
import { LogsContainer } from "../../common/LogsViewer/LogsBlackText";

export const flexAlignedCenter = css`
  display: flex;
  align-items: center;
`;

export const Title = styled.div<{ fontSize?: string }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize ?? "16px"};
`;

export const StyledTitle = styled(Title)`
  font-family: Roboto;
  font-weight: 400;
`;

export const FilterTag = styled.div`
  display: flex;
  flex-direction: row;
  ${smallLightShortStyle}
  padding: 0.25rem 0.75rem;
  width: fit-content;
  height: fit-content;
  white-space: pre;
  margin: 0.2rem 0.5rem 0.2rem 0;

  background: ${gray9};
  border-radius: 0.25rem;
`;

export const NormalText = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const WrapWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapWithTag = styled.div`
  padding-left: 1rem;
  display: flex;
  align-items: center;
`;

export const GrayHighlight = styled.span`
  font-family: Roboto Mono;
  font-size: 13px;
  background-color: ${gray9};
  padding: 0 0.3rem;
  border-radius: 4px;
`;

export const paddingAndDivider = css`
  padding: 1rem;
  border-bottom: 1px solid ${gray5};
`;

export const StyledTable = styled(Table)`
  background: white;
  border-radius: 4px;
  border: 1px solid #c9cbcf;
  margin: 0.5rem 0;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${gray5};
`;

export const Spacer = styled.div`
  padding-bottom: 1rem;
`;

export const buttonTextStyle = css`
  font-family: Poppins;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const LinkButton = styled.a<{ fontSize?: string }>`
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize ?? "12px"};
  color: ${blueForUIText};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const LightLinkButton = styled.a`
  ${lightStyle}
  font-size: 12px;
  font-weight: 500;
  padding: 3px 12px;
  border: 1px solid #dcdddf;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #dcdddf;
  }
`;

export const Grid = styled.div`
  display: grid;
`;

export const SmallPadding = styled.span`
  padding: 0 0.5rem;
`;

export const Icon = styled.div<{ icon: string }>`
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
`;

export enum TestState {
  Success = "success",
  Failed = "failed",
  Loading = "loading",
}

const handleTestStateColor = (state: TestState) => {
  switch (state) {
    case TestState.Success:
      return "#0E8E5D";
    case TestState.Failed:
      return pinkBrand;
    case TestState.Loading:
      return blueForUIText;
  }
};

export const TestMessage = styled.div<{ testState: TestState }>`
  ${lightStyle}
  font-size: 12px;
  font-weight: 500;
  color: ${({ testState }) => handleTestStateColor(testState)};
`;

export const ResultIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1.25rem;
  margin-right: 0.25rem;
`;

export const NoUnderlinedLink = styled(Link)`
  font-family: Roboto Mono;
  text-decoration: none;
  font-weight: 400;
  display: contents;
`;

export const TableContainer = styled.div`
  max-height: 16.7rem;
  overflow-y: auto;
  border: 1px solid #c9cbcf;
  border-radius: 4px;
  margin: 0.7rem 0 0;
`;

export const StyledBlackContainer = styled(LogsContainer)`
  ${smallLightShortStyle}
  border-radius: 4px;
  word-break: break-all;
  color: white;
  margin: 0.3rem 0;
  min-height: 5rem;
  max-height: 9.5rem;
  overflow-y: scroll;
`;

export const LongTableContainer = styled.div`
  overflow-y: auto;
  border: 1px solid #c9cbcf;
  border-radius: 4px;
  margin: 0.7rem 0 0;
  max-height: 21rem;
`;

export const ContentPadding = styled.div`
  padding: 0 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: inherit;
`;

export const StyledFontTable = styled(Table)`
  td,
  th {
    font-family: Roboto Mono;
  }
`;

export const Margin = styled.div`
  margin: 0 2.6rem;
`;
export const MarginTop = styled.div`
  margin-top: 1rem;
`;

export const UnderlinedButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledText = styled(NormalText)`
  font-weight: bold;
  color: ${gray10};
  padding: 0.4rem 0.7rem;
`;

export const ColoredLogsIcon = styled(LogsIcon)`
  fill: ${blueForUIText};
`;

export const ContainerStateField = styled(FlexRows)`
  margin: 0.25rem 0.9rem 0.25rem 0;
  font-family: Roboto Mono;
  overflow-wrap: anywhere;
  max-width: 12rem;
`;

export const ContainerStateFieldTitle = styled.span`
  margin-right: 0.5rem;
  font-family: Roboto Mono;
`;
export const BorderedContent = styled.div`
  ${smallLightShortStyle}
  background-color: ${offWhite};
  padding: 0.3rem 1rem;
  border: 1px solid #dcdddf;
  border-radius: 4px;
`;

const wrapStyle = css`
  flex-wrap: wrap;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const WrappedRows = styled(FlexRows)`
  flex-wrap: wrap;
  width: 30rem;
  margin: 0.5rem 0;
`;

export const FixedContent = styled(BorderedContent)`
  max-height: 5rem;
  padding: 0.15rem 0.4rem;
  ${wrapStyle}
`;

export const ErrorMessage = styled.div`
  ${lightStyle}
  padding-top: 0.3rem;
  color: ${pinkBrand};
`;
