import { useMemo } from "react";

import { parseAgentToAgentProperties } from "@/shared/hooks/useAgentInfo/useAgentInfo";
import { useActiveAgentInfo } from "@/shared/hooks/useAgents";
import { doesAgentVersionSupportResourceListFromATM } from "@/shared/utils/agent/agent";
import { KomodorServiceResourceKind } from "@/shared/types/Resource";

export const useAtmInServiceTabs = (
  resource: KomodorServiceResourceKind
): boolean | undefined => {
  const agent = useActiveAgentInfo(resource.cluster);
  const agentProps = parseAgentToAgentProperties(agent);
  return useMemo(() => {
    if (!agent || !agentProps || !resource.cluster) return undefined;
    return doesAgentVersionSupportResourceListFromATM(agentProps);
  }, [agent, agentProps, resource.cluster]);
};
