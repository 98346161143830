import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";
import { NavLink } from "react-router-dom";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";

const LINK_CLASSNAME = "nav-link";

const WRAPPER_STYLE = {
  [`& .${LINK_CLASSNAME}:visited`]: {
    color: muiTheme.palette.text.primary,
  },
  [`& .${LINK_CLASSNAME}:link`]: {
    color: muiTheme.palette.text.primary,
  },
  [`& .${LINK_CLASSNAME}:hover`]: {
    color: muiTheme.palette.primary.main,
  },
};

const Wrapper = ({ link, text }: { link?: string; text: string }) => {
  if (!link) {
    return (
      <Typography variant="body2" noWrap>
        {text}
      </Typography>
    );
  }

  return (
    <NavLink to={link} className={LINK_CLASSNAME}>
      <Typography
        variant="body2"
        noWrap
        sx={{
          "&:hover": {
            color: muiTheme.palette.primary.main,
          },
        }}
      >
        {text}
      </Typography>
    </NavLink>
  );
};

export const TooltipTextCell: React.FC<{
  text: string;
  tooltipText?: string;
  link?: string;
  accountFeature?: string;
}> = ({ text, tooltipText, link, accountFeature }) => {
  const { data } = useAccountFeatures();
  const hasSufficientFeatures = accountFeature ? data?.[accountFeature] : true;
  return (
    <Tooltip title={tooltipText ?? text} componentsProps={lightMuiTooltipStyle}>
      <Box overflow="hidden" sx={WRAPPER_STYLE}>
        <Wrapper link={hasSufficientFeatures && link} text={text} />
      </Box>
    </Tooltip>
  );
};
