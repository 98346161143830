import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { useTrackedKeysContext } from "@/shared/context/trackedKeys/useTrackedKeysContext";
import { Refetch } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";

const DISABLED_TOOLTIP =
  "Adding a new key is disabled because you have reached the maximum number of keys";

export const AddKeyButton = ({
  handleRefetch,
  disabled,
}: {
  handleRefetch: Refetch;
  disabled: boolean;
}) => {
  const { addNewKey } = useTrackedKeysContext();

  return (
    <LightTooltip title={disabled && DISABLED_TOOLTIP}>
      <Box>
        <Button
          variant={"contained"}
          onClick={() => addNewKey(handleRefetch)}
          disabled={disabled}
        >
          Add Key
        </Button>
      </Box>
    </LightTooltip>
  );
};
