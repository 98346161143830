import React from "react";

import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeNetworkAvailableDetails from "./detailsComponents/NodeNetworkAvailableDetails";

import { StateInSearchParamsType } from "@/shared/hooks/state/useStateInSearchParams";
import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

type ConditionStatus = "True" | "False" | "Unknown";
export interface NodeNetworkAvailableOutput {
  status: ConditionStatus;
  reason: string;
  message: string;
  lastHeartbeat: number;
  lastTransition: number;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeNetworkAvailableCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "Network Status";
    this.shortOutput = check.passed
      ? "The network is configured correctly"
      : "The network is not configured correctly";
    this.action = (
      <>
        We ran{" "}
        <GrayHighlight>
          kubectl get pods --all-namespaces -o wide --field-selector
          spec.nodeName={"<NODE>"}
        </GrayHighlight>{" "}
        to check the networkUnavailable status condition.
      </>
    );
    this.question = "Is the network available?";
    this.description = check.passed ? (
      "All your nodes need network connections, and this status indicates that there’s something wrong with a node’s network connection."
    ) : (
      <>
        All your nodes need network connections, and this status indicates that
        there’s something wrong with a node’s network connection.
        <br />
        <br />
        Either it wasn’t set up properly (due to route exhaustion or a
        misconfiguration), or there’s a physical problem with the network
        connection to your hardware.
        <br />
        <br />
        Check the Kube-system pods to see that all network related-pods are
        running and healthy.
      </>
    );
    this.output = check.output as NodeNetworkAvailableOutput;
  }

  renderCheckDetails(
    setSelectedCheckType: StateInSearchParamsType[1]
  ): JSX.Element {
    return (
      <NodeNetworkAvailableDetails
        check={this}
        setSelectedCheckType={setSelectedCheckType}
      />
    );
  }
}
