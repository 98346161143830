import { RuleGroupType } from "react-querybuilder";

import {
  handleClusters,
  handleClustersPatterns,
  handleNamespaces,
  handleNamespacesPatterns,
  handleSelectorsPatterns,
} from "../handlers";

import { RbacPolicyAction, RbacPolicyStatementsInner } from "@/generated/auth";
import { assumeScopeFromStatement } from "@/pages/organization-settings/access-management/PoliciesPage/utils/assumeScopeFromStatement";
import { INITIAL_CLUSTER_QUERY } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/constants";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { queryByScope } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/queries";

export const statementToQuery = (
  statement: RbacPolicyStatementsInner,
  globalActions: RbacPolicyAction[]
): RuleGroupType | string => {
  const scope = assumeScopeFromStatement(statement, globalActions);

  const resourcesScope = statement.resourcesScope;
  if (!resourcesScope) {
    return INITIAL_CLUSTER_QUERY;
  }

  if (scope === Scope["komodor-actions"]) {
    return queryByScope[scope];
  }

  const clustersRules = [
    handleClusters(resourcesScope),
    handleClustersPatterns(resourcesScope),
  ];

  // A resource scope must have some kind of clusters + namespaces or clusters + selectors
  // but, we can define a "cluster" scope, which is a combination of clusters and namespacesPatterns
  // of { include: '*', exclude: '' }
  // So if we have a cluster scope, we can ignore the rest of the rules
  if (scope === Scope.cluster) {
    return {
      combinator: "and",
      rules: clustersRules.filter(Boolean) as RuleGroupType[],
    };
  }

  if (scope === Scope.cwr) {
    return {
      combinator: "and",
      rules: [...clustersRules, handleSelectorsPatterns(resourcesScope)].filter(
        Boolean
      ) as RuleGroupType[],
    };
  }

  const rules = [
    ...clustersRules,
    handleNamespaces(resourcesScope),
    handleNamespacesPatterns(resourcesScope),
    handleSelectorsPatterns(resourcesScope),
  ].filter(Boolean) as RuleGroupType[];
  return {
    combinator: "and",
    rules,
  };
};
