import React, { ComponentProps } from "react";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { Lock16 } from "../../../../icons";
import { lightMuiTooltipStyle } from "../../../../styles/styles";
import { muiTheme } from "../../../../themes";

const NO_PERMISSIONS_MESSAGE = "Access denied due to lack of permissions";

const LINK_TAB_STYLE = {
  alignItems: "flex-start",
  marginLeft: "8px",
  fontSize: "14px",
  minHeight: "32px",
  padding: "4px 16px",
  letterSpacing: "0.4px",
  fontWeight: 400,
  // Put opacity in "&&" to override the default opacity of the disabled Tab component
  "&&": {
    opacity: 1,
  },
};

interface LinkTabProps {
  linkComponent: React.ElementType;
  title?: React.ReactNode;
  route?: string;
}

export const LinkTab: React.FC<LinkTabProps> = ({ linkComponent, ...rest }) => {
  return (
    <Tab
      component={linkComponent}
      to={rest.route}
      sx={LINK_TAB_STYLE}
      label={rest.title}
      aria-label={rest.title}
      {...rest}
    />
  );
};

interface LockedTabProps {
  title: React.ReactNode;
  value: string;
  tooltip?: string;
  tooltipPlacement?: ComponentProps<typeof Tooltip>["placement"];
}

export const LockedTab: React.FC<LockedTabProps> = ({
  title,
  value,
  tooltip,
  tooltipPlacement,
}) => {
  const Label = (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      aria-label={`${title}`}
    >
      <Typography variant="body2" color={muiTheme.palette.text.secondary}>
        {title}
      </Typography>
      <Lock16 color={muiTheme.palette.action.active} />
    </Stack>
  );

  return (
    <Tooltip
      title={
        <Typography variant="body2">
          {tooltip || NO_PERMISSIONS_MESSAGE}
        </Typography>
      }
      placement={tooltipPlacement || "left-end"}
      componentsProps={lightMuiTooltipStyle}
      slotProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: 170,
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
              {
                marginRight: "-10px",
              },
          },
        },
      }}
    >
      <Stack>
        <Tab
          disabled
          key={value}
          label={Label}
          value={value}
          sx={LINK_TAB_STYLE}
        />
      </Stack>
    </Tooltip>
  );
};

type TabProps = ComponentProps<typeof Tab>;

export const HeaderTab: React.FC<TabProps> = (props) => {
  return (
    <Tab
      sx={{
        alignItems: "flex-start",
        color: muiTheme.palette.text.secondary,
        pointerEvents: "none",
        fontSize: "12px",
        textTransform: "uppercase",
        letterSpacing: "1.5px",
      }}
      {...props}
    />
  );
};
