import React, { ComponentProps, useMemo } from "react";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import Warning from "@mui/icons-material/Warning";
import { muiColors } from "@komodorio/design-system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { getTimeElapsedStr } from "@/components/k8sAddons/components/table/TimeElapsedCell/timeElapsedCellUtils";
import { CertificateStatusEnum } from "@/generated/addonsApi";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";

const {
  container: containerAriaLabel,
  tooltipContent: tooltipContentAriaLabel,
} = certificatesPageAriaLabels.statusChipWithValidation;

type StatusChipWithValidationProps = ComponentProps<typeof AddonStatusChip> & {
  expiration: string | undefined;
  status: CertificateStatusEnum;
};

export const WarningIconTooltipContent = () => (
  <Typography variant={"body2"} aria-label={tooltipContentAriaLabel}>
    The certificate is marked as <b>'Valid'</b> <br />
    because the <b>Ready condition is true;</b>
    <br />
    however, it has already <b>expired</b>
  </Typography>
);

export const StatusChipWithValidation: React.FC<
  StatusChipWithValidationProps
> = ({ color, size, label, expiration, status }) => {
  const shouldDisplayExpiredWarning = useMemo(() => {
    if (!expiration) return false;
    const { daysElapsed } = getTimeElapsedStr(expiration);
    return status === CertificateStatusEnum.Valid && daysElapsed < 0;
  }, [expiration, status]);

  const tooltipTitle = shouldDisplayExpiredWarning ? (
    <WarningIconTooltipContent />
  ) : null;

  return (
    <LightTooltip title={tooltipTitle} placement="top">
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"8px"}
        aria-label={containerAriaLabel}
      >
        <AddonStatusChip color={color} label={label} size={size} />

        {shouldDisplayExpiredWarning && (
          <Warning sx={{ color: muiColors.yellow[800] }} />
        )}
      </Stack>
    </LightTooltip>
  );
};
