import { CRD } from "@komodorio/design-system/icons";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesCRResource = {
  NameInK8S: "none",
  Group: "crds",
  NavBarName: "Resources",
  PresentName: "Custom Resources instances",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: SupportedResourcesKinds.KubernetesCRResource,
  Icon: CRD,
  hasLabels: true,
  ignoreNavigation: true,
  isCustomResource: true,
};
