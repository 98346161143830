import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { LoadingSpinnerIcon } from "@/shared/components/LoadingSpinnerIcon";
import { PreviewDefinition } from "@/shared/components/PreviewDefintion/PreviewDefinition";
import { Cluster } from "@/shared/components/PreviewDefintion/types";

export type PreviewDefinitionContainerProps = {
  queryError: string;
  isLoadingResolveScope: boolean;
  showPreviewDefinition: boolean;
  onClose: () => void;
  clusters: Cluster[];
  handlePreviewClick: () => void;
  ariaLabels?: {
    button: string;
  };
};

export const PreviewDefinitionContainer = ({
  queryError,
  isLoadingResolveScope,
  showPreviewDefinition,
  onClose,
  clusters,
  handlePreviewClick,
  ariaLabels,
}: PreviewDefinitionContainerProps) => {
  return (
    <>
      <LightTooltip title={queryError} placement="top">
        <Box>
          <Button
            startIcon={
              isLoadingResolveScope ? <LoadingSpinnerIcon /> : <PreviewIcon />
            }
            onClick={handlePreviewClick}
            sx={{ alignSelf: "baseline" }}
            disabled={!!queryError}
            aria-label={ariaLabels?.button}
          >
            Preview definition
          </Button>
        </Box>
      </LightTooltip>
      {showPreviewDefinition && (
        <PreviewDefinition onClose={onClose} clusters={clusters} />
      )}
    </>
  );
};
