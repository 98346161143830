import React, { createContext, PropsWithChildren, useState } from "react";

export type AllPoliciesTableContextState = {
  isUpdatingPolicy: boolean;
  setIsUpdatingPolicy: (val: boolean) => void;
};

const initialState: AllPoliciesTableContextState = {
  isUpdatingPolicy: false,
  setIsUpdatingPolicy: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const AllPoliciesTableContext =
  createContext<AllPoliciesTableContextState>(initialState);

const { isUpdatingPolicy: initialIsUpdatingPolicy } = initialState;

export const AllPoliciesTableContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isUpdatingPolicy, setIsUpdatingPolicy] = useState<boolean>(
    initialIsUpdatingPolicy
  );

  const contextState: AllPoliciesTableContextState = {
    isUpdatingPolicy,
    setIsUpdatingPolicy,
  };

  return (
    <AllPoliciesTableContext.Provider value={contextState}>
      {children}
    </AllPoliciesTableContext.Provider>
  );
};
