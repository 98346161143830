import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";

import { TruncatedCellWithTooltip } from "./TruncatedCellWithTooltip";

export function DefaultCell<R, V, F>({
  formattedValue: value,
}: GridRenderCellParams<R, V, F, GridTreeNodeWithRender>): React.ReactNode {
  return <TruncatedCellWithTooltip value={value.toString()} />;
}
