import { useMemo } from "react";

import { SameIssueInClusterInsightDataParsed } from "../../common/types";
import { WorkflowIssueStatus } from "../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";

import { useGetMonitorIssuesByType } from "@/shared/hooks/monitors-api/client/issues/useGetMonitorIssuesByType";
import { MonitorType } from "@/generated/monitorsApi";

export const useUpdatedSameIssuesInsight = (
  issuesInsights: SameIssueInClusterInsightDataParsed[]
): SameIssueInClusterInsightDataParsed[] => {
  const ids = useMemo(() => issuesInsights.map((e) => e.id), [issuesInsights]);
  const { data: monitorIssuesResponse } = useGetMonitorIssuesByType(
    {
      ids,
      type: MonitorType.Availability,
      fields: ["id", "closedAt"],
    },
    ids.length > 0
  );

  return useMemo(() => {
    return issuesInsights.map((issue) => {
      const issueClosedAt = monitorIssuesResponse?.data?.find(
        (issueUpdated) => issue.id === issueUpdated.id
      )?.closedAt;
      return {
        ...issue,
        status: issueClosedAt
          ? WorkflowIssueStatus.closed
          : issueClosedAt === null
          ? WorkflowIssueStatus.open
          : issue.status,
      };
    });
  }, [monitorIssuesResponse?.data, issuesInsights]);
};

export const useSameIssueInClusterInsight = (
  sameIssuesInClusterInsights: SameIssueInClusterInsightDataParsed[]
) => {
  const sameIssuesInClusterInsightsUpdated = useUpdatedSameIssuesInsight(
    sameIssuesInClusterInsights
  );
  const issuesLength = sameIssuesInClusterInsightsUpdated.length;

  return {
    shouldShow: issuesLength > 0,
    text: `The same issue appeared on ${issuesLength} other service${
      issuesLength > 1 ? "s" : ""
    } in the cluster within 2 hours before the issue started`,
    sameIssuesInClusterInsightsUpdated,
  };
};
