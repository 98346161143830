import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import { RowFieldContainer } from "../../monitorsView/monitorsConfiguration/common/styles";
import Switcher from "../controls/Switcher";
import DeletedPodsSwitch from "../../Inspection/filters/DeletedPodsFilter";

import FetchingLabel from "./FetchingLabel";
import { PageLabel, StyledVerticalDivider } from "./PageLabel";

import { ResourceKind } from "@/generated/searchApi";
import { CustomCommandBar } from "@/components/common/ResourceListTable/CustomCommandBar";
import { DeletedPodsSearchButton } from "@/components/common/ResourceListTable/DeletedPodsSearchButton";
import { INSPECTION_POD_ROUTE } from "@/components/routes/routes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useInspectionLiveDataInUrl } from "@/components/common/ResourceListTable/hooks/useInspectionLiveDataInUrl";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LiveUpdateContainer = styled(Container)`
  gap: 0.75rem;
  align-items: center;
`;

const getClusterName = (path: string): string | undefined => {
  return path.match(/\/workloads\/pods\/([^/]+)/)?.[1];
};

const ResourceListTableSecondaryHeader: React.FC<{
  clusterName?: string;
  hidePageLabel: boolean;
  isPaginating: boolean | undefined;
  rowsOffset: number;
  pageSize: number;
  listSize: number;
  accessory?: ReactNode;
  showLiveDataButton?: boolean;
  showDeletedPodsControls?: boolean;
}> = ({
  clusterName,
  hidePageLabel,
  isPaginating,
  rowsOffset,
  pageSize,
  listSize,
  accessory,
  showLiveDataButton = false,
  showDeletedPodsControls = false,
}) => {
  const [isAtmLiveData, setStoredFetchAtmLiveData] =
    useInspectionLiveDataInUrl();

  const isPodsInspectionRoute =
    location.pathname.startsWith(INSPECTION_POD_ROUTE);
  const currentCluster = getClusterName(location.pathname);
  const { showDeletedPodsSearchButton } = useOverridableFlags();

  const isLive = useMemo(() => isAtmLiveData ?? true, [isAtmLiveData]);
  return !hidePageLabel || isPaginating !== undefined ? (
    <>
      <Container>
        <RowFieldContainer>
          {!hidePageLabel && (
            <PageLabel
              itemOffset={rowsOffset}
              pageSize={pageSize}
              listSize={listSize}
              accessory={accessory}
            />
          )}
          {isPaginating !== undefined && (
            <FetchingLabel isPaginating={isPaginating} />
          )}
          {showDeletedPodsControls && !accessory && (
            <>
              <StyledVerticalDivider />
              {isPodsInspectionRoute && showDeletedPodsSearchButton ? (
                <CustomCommandBar
                  button={<DeletedPodsSearchButton />}
                  cluster={currentCluster}
                  kinds={[ResourceKind.DeletedPod]}
                  placeholder={"Search Deleted Pods"}
                />
              ) : (
                <DeletedPodsSwitch clusterName={clusterName} />
              )}
            </>
          )}
        </RowFieldContainer>
        {showLiveDataButton ? (
          <LiveUpdateContainer aria-label="pod-inspection live-update">
            <Switcher
              checked={isLive}
              handleSwitcherChange={() => setStoredFetchAtmLiveData(!isLive)}
              color={palette.green[700]}
            />
            <Typography size="medium" variant="text">
              Live updates
            </Typography>
            <div></div>
          </LiveUpdateContainer>
        ) : null}
      </Container>
      <br />
    </>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourceListTableSecondaryHeader;
