import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  apiV1ChatSessionsIdGetUrl,
  ChatGetResponse,
  DefaultApiApiV1ChatSessionsIdGetRequest,
} from "@/generated/aiApi";
import { useAiApiClient } from "@/shared/hooks/ai-api/client/apiClient";

export const CHAT_SESSION_BY_ID_PATH = "/chat/session/:id";

const fetchChatSessionById = async (
  apiClient: AxiosInstance,
  params: DefaultApiApiV1ChatSessionsIdGetRequest
) => {
  const { data } = await apiClient.get<ChatGetResponse>(
    apiV1ChatSessionsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetChatSessionById = (
  params: DefaultApiApiV1ChatSessionsIdGetRequest,
  options?: {
    enabled?: boolean;
  }
) => {
  const apiClient = useAiApiClient();
  return useQuery(
    [CHAT_SESSION_BY_ID_PATH, params],
    () => fetchChatSessionById(apiClient, params),
    options
  );
};
