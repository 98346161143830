import Link from "@mui/material/Link";
import {
  GridColDef,
  GridPaginationInitialState,
  GridSortingInitialState,
} from "@mui/x-data-grid-pro";
import { ComponentProps } from "react";

import { ClientSideDataGridWithFilter } from "@/shared/components/ClientSideDataGridWithFilter/ClientSideDataGridWithFilter";

const DEFAULT_PAGE_SIZE = 5;

export type AffectedDataRow = {
  name: string;
  kind: string;
  komodorUid: string;
  availabilityIssuesCount?: number;
  terminatedPodsCount: number;
};

function getNameColumn(
  onOpenResourceClicked: (komodorUid: string) => void
): GridColDef<AffectedDataRow> {
  return {
    headerName: "Name",
    type: "string",
    field: "name",
    flex: 1,
    renderCell: (params) => {
      return (
        <Link
          variant="body2"
          underline="hover"
          noWrap
          onClick={() => {
            onOpenResourceClicked(params.row.komodorUid);
          }}
        >
          {params.value}
        </Link>
      );
    },
  };
}

const kindColumn: GridColDef<AffectedDataRow> = {
  headerName: "Kind",
  type: "string",
  field: "kind",
  flex: 1,
};

const availabilityIssuesColumn: GridColDef<AffectedDataRow> = {
  headerName: "Availability Issues",
  flex: 1,
  type: "number",
  field: "availabilityIssuesCount",
  align: "left",
  headerAlign: "left",
};

const terminatedPodsColumn: GridColDef<AffectedDataRow> = {
  headerName: "Total terminated Pods",
  flex: 1,
  type: "number",
  field: "terminatedPodsCount",
  align: "left",
  headerAlign: "left",
};

export function getUnhealthyServicesColumns(
  onOpenResourceClicked: (komodorUid: string) => void
): readonly GridColDef<AffectedDataRow>[] {
  return [
    getNameColumn(onOpenResourceClicked),
    kindColumn,
    availabilityIssuesColumn,
    terminatedPodsColumn,
  ];
}

export function getAffectedJobsColumns(
  onOpenResourceClicked: (komodorUid: string) => void
): GridColDef<AffectedDataRow>[] {
  return [
    getNameColumn(onOpenResourceClicked),
    kindColumn,
    terminatedPodsColumn,
  ];
}

export function getAffectedWorkloadsColumns(
  onOpenResourceClicked: (komodorUid: string) => void
): GridColDef<AffectedDataRow>[] {
  return [
    getNameColumn(onOpenResourceClicked),
    kindColumn,
    terminatedPodsColumn,
  ];
}

export const rowIdSelector = (row: AffectedDataRow) =>
  `${row.kind}-${row.name}`;
export function isRowMatchingSearch(
  row: AffectedDataRow,
  searchTerm: string
): boolean {
  return row.name.includes(searchTerm);
}

export const paginationInitialState: GridPaginationInitialState = {
  paginationModel: { pageSize: DEFAULT_PAGE_SIZE },
};

export function createInitialSorting(
  fieldName: string
): GridSortingInitialState {
  return {
    sortModel: [{ field: fieldName, sort: "desc" }],
  };
}

const SEARCH_PLACEHOLDER = "Resource name";

export const sharedTableProps: Partial<
  ComponentProps<typeof ClientSideDataGridWithFilter<AffectedDataRow>>
> &
  Pick<
    ComponentProps<typeof ClientSideDataGridWithFilter<AffectedDataRow>>,
    "filter"
  > = {
  getRowId: rowIdSelector,
  searchBoxPlaceholder: SEARCH_PLACEHOLDER,
  filter: isRowMatchingSearch,
  density: "compact",
};
