/* tslint:disable */
/* eslint-disable */
/**
 * Agents Service API
 * This is an API that exposes agent-related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface AccountPartial
 */
export interface AccountPartial {
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'trialEndAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountPartial
     */
    'plan'?: string | null;
}


/**
 * Check if a given object implements the AccountPartial interface.
 */
export function instanceOfAccountPartial(value: object): value is AccountPartial {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function AccountPartialFromJSON(json: any): AccountPartial {
    return AccountPartialFromJSONTyped(json, false);
}

export function AccountPartialFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountPartial {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'trialEndAt': json['trialEndAt'] == null ? undefined : json['trialEndAt'],
        'plan': json['plan'] == null ? undefined : json['plan'],
    };
}

export function AccountPartialToJSON(value?: AccountPartial | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'trialEndAt': value['trialEndAt'],
        'plan': value['plan'],
    };
}

        /**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'clusterName': string;
    /**
     * 
     * @type {AgentConfiguration}
     * @memberof Agent
     */
    'configuration': AgentConfiguration;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'lastSeenAlive': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'installationId': string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'accountId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    'inactive': boolean;
}


/**
 * Check if a given object implements the Agent interface.
 */
export function instanceOfAgent(value: object): value is Agent {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('configuration' in value) || value['configuration'] === undefined) return false;
    if (!('lastSeenAlive' in value) || value['lastSeenAlive'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('installationId' in value) || value['installationId'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('inactive' in value) || value['inactive'] === undefined) return false;
    return true;
}

export function AgentFromJSON(json: any): Agent {
    return AgentFromJSONTyped(json, false);
}

export function AgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Agent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'clusterName': json['clusterName'],
        'configuration': AgentConfigurationFromJSON(json['configuration']),
        'lastSeenAlive': json['lastSeenAlive'],
        'createdAt': json['createdAt'],
        'installationId': json['installationId'],
        'accountId': json['accountId'],
        'inactive': json['inactive'],
    };
}

export function AgentToJSON(value?: Agent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'clusterName': value['clusterName'],
        'configuration': AgentConfigurationToJSON(value['configuration']),
        'lastSeenAlive': value['lastSeenAlive'],
        'createdAt': value['createdAt'],
        'installationId': value['installationId'],
        'accountId': value['accountId'],
        'inactive': value['inactive'],
    };
}

        /**
 * 
 * @export
 * @interface AgentConfiguration
 */
export interface AgentConfiguration {
    [key: string]: any | any;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'defaultnamespaceuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'clusterregion'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AgentConfiguration
     */
    'nodelabels'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'component'?: string;
    /**
     * 
     * @type {AppMetadataConfig}
     * @memberof AgentConfiguration
     */
    'appmetadata'?: AppMetadataConfig;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'serverhost'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'tasksserverhost'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'mgmtserverhost'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'wshost'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentConfiguration
     */
    'servershealthcheckport'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'httpproxy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'httpsproxy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'noproxy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfiguration
     */
    'debug'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'kubeconfigpath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'kubemasterurl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'wspingperiod'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'wskeeplive'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'wsconntimeout'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'wsconncooldown'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'watchnamespace'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'namespacesdenylist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'logsnamespacesdenylist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'logsnamespaceallowlist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'namespacesblacklist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'namedenylist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'nameblacklist'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfiguration
     */
    'collecthistory'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'collecthistoryfrom'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'redact'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfiguration
     */
    'redactlogs'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfiguration
     */
    'enablehelm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfiguration
     */
    'enableagenttaskexecution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfiguration
     */
    'enableagenttaskexecutionv2'?: boolean;
    /**
     * 
     * @type {AgentConfigurationMemorythresholdsafetycheck}
     * @memberof AgentConfiguration
     */
    'memorythresholdsafetycheck'?: AgentConfigurationMemorythresholdsafetycheck;
    /**
     * 
     * @type {string}
     * @memberof AgentConfiguration
     */
    'monitoringfqdn'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AgentConfiguration
     */
    'tags'?: { [key: string]: string; };
    /**
     * 
     * @type {ResourcesConfig}
     * @memberof AgentConfiguration
     */
    'resources'?: ResourcesConfig;
    /**
     * 
     * @type {TelemetryConfig}
     * @memberof AgentConfiguration
     */
    'telemetry'?: TelemetryConfig;
    /**
     * 
     * @type {NetworkMapperConfig}
     * @memberof AgentConfiguration
     */
    'networkmapper'?: NetworkMapperConfig;
    /**
     * 
     * @type {QueueConfig}
     * @memberof AgentConfiguration
     */
    'queue'?: QueueConfig;
    /**
     * 
     * @type {LoggerConfig}
     * @memberof AgentConfiguration
     */
    'logger'?: LoggerConfig;
    /**
     * 
     * @type {DaemonConfig}
     * @memberof AgentConfiguration
     */
    'daemon'?: DaemonConfig;
    /**
     * 
     * @type {ControllerConfig}
     * @memberof AgentConfiguration
     */
    'controller'?: ControllerConfig;
    /**
     * 
     * @type {LiveDataResourceWatcherSessionConfig}
     * @memberof AgentConfiguration
     */
    'livedataresourcewatcher'?: LiveDataResourceWatcherSessionConfig;
    /**
     * 
     * @type {PeriodicTasksConfig}
     * @memberof AgentConfiguration
     */
    'periodictasks'?: PeriodicTasksConfig;
    /**
     * 
     * @type {PodPhase}
     * @memberof AgentConfiguration
     */
    'podphase'?: PodPhase;
    /**
     * 
     * @type {RedactionConfig}
     * @memberof AgentConfiguration
     */
    'redaction'?: RedactionConfig;
    /**
     * 
     * @type {FiltersConfig}
     * @memberof AgentConfiguration
     */
    'filters'?: FiltersConfig;
    /**
     * 
     * @type {LogsConfig}
     * @memberof AgentConfiguration
     */
    'logs'?: LogsConfig;
    /**
     * 
     * @type {WatchOptionsConfig}
     * @memberof AgentConfiguration
     */
    'watchoptions'?: WatchOptionsConfig;
}


/**
 * Check if a given object implements the AgentConfiguration interface.
 */
export function instanceOfAgentConfiguration(value: object): value is AgentConfiguration {
    return true;
}

export function AgentConfigurationFromJSON(json: any): AgentConfiguration {
    return AgentConfigurationFromJSONTyped(json, false);
}

export function AgentConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentConfiguration {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'defaultnamespaceuid': json['defaultnamespaceuid'] == null ? undefined : json['defaultnamespaceuid'],
        'clusterregion': json['clusterregion'] == null ? undefined : json['clusterregion'],
        'nodelabels': json['nodelabels'] == null ? undefined : json['nodelabels'],
        'component': json['component'] == null ? undefined : json['component'],
        'appmetadata': json['appmetadata'] == null ? undefined : AppMetadataConfigFromJSON(json['appmetadata']),
        'serverhost': json['serverhost'] == null ? undefined : json['serverhost'],
        'tasksserverhost': json['tasksserverhost'] == null ? undefined : json['tasksserverhost'],
        'mgmtserverhost': json['mgmtserverhost'] == null ? undefined : json['mgmtserverhost'],
        'wshost': json['wshost'] == null ? undefined : json['wshost'],
        'servershealthcheckport': json['servershealthcheckport'] == null ? undefined : json['servershealthcheckport'],
        'httpproxy': json['httpproxy'] == null ? undefined : json['httpproxy'],
        'httpsproxy': json['httpsproxy'] == null ? undefined : json['httpsproxy'],
        'noproxy': json['noproxy'] == null ? undefined : json['noproxy'],
        'debug': json['debug'] == null ? undefined : json['debug'],
        'kubeconfigpath': json['kubeconfigpath'] == null ? undefined : json['kubeconfigpath'],
        'kubemasterurl': json['kubemasterurl'] == null ? undefined : json['kubemasterurl'],
        'wspingperiod': json['wspingperiod'] == null ? undefined : json['wspingperiod'],
        'wskeeplive': json['wskeeplive'] == null ? undefined : json['wskeeplive'],
        'wsconntimeout': json['wsconntimeout'] == null ? undefined : json['wsconntimeout'],
        'wsconncooldown': json['wsconncooldown'] == null ? undefined : json['wsconncooldown'],
        'watchnamespace': json['watchnamespace'] == null ? undefined : json['watchnamespace'],
        'namespacesdenylist': json['namespacesdenylist'] == null ? undefined : json['namespacesdenylist'],
        'logsnamespacesdenylist': json['logsnamespacesdenylist'] == null ? undefined : json['logsnamespacesdenylist'],
        'logsnamespaceallowlist': json['logsnamespaceallowlist'] == null ? undefined : json['logsnamespaceallowlist'],
        'namespacesblacklist': json['namespacesblacklist'] == null ? undefined : json['namespacesblacklist'],
        'namedenylist': json['namedenylist'] == null ? undefined : json['namedenylist'],
        'nameblacklist': json['nameblacklist'] == null ? undefined : json['nameblacklist'],
        'collecthistory': json['collecthistory'] == null ? undefined : json['collecthistory'],
        'collecthistoryfrom': json['collecthistoryfrom'] == null ? undefined : json['collecthistoryfrom'],
        'redact': json['redact'] == null ? undefined : json['redact'],
        'redactlogs': json['redactlogs'] == null ? undefined : json['redactlogs'],
        'enablehelm': json['enablehelm'] == null ? undefined : json['enablehelm'],
        'enableagenttaskexecution': json['enableagenttaskexecution'] == null ? undefined : json['enableagenttaskexecution'],
        'enableagenttaskexecutionv2': json['enableagenttaskexecutionv2'] == null ? undefined : json['enableagenttaskexecutionv2'],
        'memorythresholdsafetycheck': json['memorythresholdsafetycheck'] == null ? undefined : AgentConfigurationMemorythresholdsafetycheckFromJSON(json['memorythresholdsafetycheck']),
        'monitoringfqdn': json['monitoringfqdn'] == null ? undefined : json['monitoringfqdn'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'resources': json['resources'] == null ? undefined : ResourcesConfigFromJSON(json['resources']),
        'telemetry': json['telemetry'] == null ? undefined : TelemetryConfigFromJSON(json['telemetry']),
        'networkmapper': json['networkmapper'] == null ? undefined : NetworkMapperConfigFromJSON(json['networkmapper']),
        'queue': json['queue'] == null ? undefined : QueueConfigFromJSON(json['queue']),
        'logger': json['logger'] == null ? undefined : LoggerConfigFromJSON(json['logger']),
        'daemon': json['daemon'] == null ? undefined : DaemonConfigFromJSON(json['daemon']),
        'controller': json['controller'] == null ? undefined : ControllerConfigFromJSON(json['controller']),
        'livedataresourcewatcher': json['livedataresourcewatcher'] == null ? undefined : LiveDataResourceWatcherSessionConfigFromJSON(json['livedataresourcewatcher']),
        'periodictasks': json['periodictasks'] == null ? undefined : PeriodicTasksConfigFromJSON(json['periodictasks']),
        'podphase': json['podphase'] == null ? undefined : PodPhaseFromJSON(json['podphase']),
        'redaction': json['redaction'] == null ? undefined : RedactionConfigFromJSON(json['redaction']),
        'filters': json['filters'] == null ? undefined : FiltersConfigFromJSON(json['filters']),
        'logs': json['logs'] == null ? undefined : LogsConfigFromJSON(json['logs']),
        'watchoptions': json['watchoptions'] == null ? undefined : WatchOptionsConfigFromJSON(json['watchoptions']),
    };
}

export function AgentConfigurationToJSON(value?: AgentConfiguration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'defaultnamespaceuid': value['defaultnamespaceuid'],
        'clusterregion': value['clusterregion'],
        'nodelabels': value['nodelabels'],
        'component': value['component'],
        'appmetadata': AppMetadataConfigToJSON(value['appmetadata']),
        'serverhost': value['serverhost'],
        'tasksserverhost': value['tasksserverhost'],
        'mgmtserverhost': value['mgmtserverhost'],
        'wshost': value['wshost'],
        'servershealthcheckport': value['servershealthcheckport'],
        'httpproxy': value['httpproxy'],
        'httpsproxy': value['httpsproxy'],
        'noproxy': value['noproxy'],
        'debug': value['debug'],
        'kubeconfigpath': value['kubeconfigpath'],
        'kubemasterurl': value['kubemasterurl'],
        'wspingperiod': value['wspingperiod'],
        'wskeeplive': value['wskeeplive'],
        'wsconntimeout': value['wsconntimeout'],
        'wsconncooldown': value['wsconncooldown'],
        'watchnamespace': value['watchnamespace'],
        'namespacesdenylist': value['namespacesdenylist'],
        'logsnamespacesdenylist': value['logsnamespacesdenylist'],
        'logsnamespaceallowlist': value['logsnamespaceallowlist'],
        'namespacesblacklist': value['namespacesblacklist'],
        'namedenylist': value['namedenylist'],
        'nameblacklist': value['nameblacklist'],
        'collecthistory': value['collecthistory'],
        'collecthistoryfrom': value['collecthistoryfrom'],
        'redact': value['redact'],
        'redactlogs': value['redactlogs'],
        'enablehelm': value['enablehelm'],
        'enableagenttaskexecution': value['enableagenttaskexecution'],
        'enableagenttaskexecutionv2': value['enableagenttaskexecutionv2'],
        'memorythresholdsafetycheck': AgentConfigurationMemorythresholdsafetycheckToJSON(value['memorythresholdsafetycheck']),
        'monitoringfqdn': value['monitoringfqdn'],
        'tags': value['tags'],
        'resources': ResourcesConfigToJSON(value['resources']),
        'telemetry': TelemetryConfigToJSON(value['telemetry']),
        'networkmapper': NetworkMapperConfigToJSON(value['networkmapper']),
        'queue': QueueConfigToJSON(value['queue']),
        'logger': LoggerConfigToJSON(value['logger']),
        'daemon': DaemonConfigToJSON(value['daemon']),
        'controller': ControllerConfigToJSON(value['controller']),
        'livedataresourcewatcher': LiveDataResourceWatcherSessionConfigToJSON(value['livedataresourcewatcher']),
        'periodictasks': PeriodicTasksConfigToJSON(value['periodictasks']),
        'podphase': PodPhaseToJSON(value['podphase']),
        'redaction': RedactionConfigToJSON(value['redaction']),
        'filters': FiltersConfigToJSON(value['filters']),
        'logs': LogsConfigToJSON(value['logs']),
        'watchoptions': WatchOptionsConfigToJSON(value['watchoptions']),
    };
}

        /**
 * 
 * @export
 * @interface AgentConfigurationMemorythresholdsafetycheck
 */
export interface AgentConfigurationMemorythresholdsafetycheck {
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfigurationMemorythresholdsafetycheck
     */
    'enable'?: boolean;
}


/**
 * Check if a given object implements the AgentConfigurationMemorythresholdsafetycheck interface.
 */
export function instanceOfAgentConfigurationMemorythresholdsafetycheck(value: object): value is AgentConfigurationMemorythresholdsafetycheck {
    return true;
}

export function AgentConfigurationMemorythresholdsafetycheckFromJSON(json: any): AgentConfigurationMemorythresholdsafetycheck {
    return AgentConfigurationMemorythresholdsafetycheckFromJSONTyped(json, false);
}

export function AgentConfigurationMemorythresholdsafetycheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentConfigurationMemorythresholdsafetycheck {
    if (json == null) {
        return json;
    }
    return {
        
        'enable': json['enable'] == null ? undefined : json['enable'],
    };
}

export function AgentConfigurationMemorythresholdsafetycheckToJSON(value?: AgentConfigurationMemorythresholdsafetycheck | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enable': value['enable'],
    };
}

        /**
 * 
 * @export
 * @interface AgentWithAccount
 */
export interface AgentWithAccount {
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'clusterName': string;
    /**
     * 
     * @type {AgentConfiguration}
     * @memberof AgentWithAccount
     */
    'configuration': AgentConfiguration;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'lastSeenAlive': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'installationId': string;
    /**
     * 
     * @type {string}
     * @memberof AgentWithAccount
     */
    'accountId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWithAccount
     */
    'inactive': boolean;
    /**
     * 
     * @type {AccountPartial}
     * @memberof AgentWithAccount
     */
    'account'?: AccountPartial;
}


/**
 * Check if a given object implements the AgentWithAccount interface.
 */
export function instanceOfAgentWithAccount(value: object): value is AgentWithAccount {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('configuration' in value) || value['configuration'] === undefined) return false;
    if (!('lastSeenAlive' in value) || value['lastSeenAlive'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('installationId' in value) || value['installationId'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('inactive' in value) || value['inactive'] === undefined) return false;
    return true;
}

export function AgentWithAccountFromJSON(json: any): AgentWithAccount {
    return AgentWithAccountFromJSONTyped(json, false);
}

export function AgentWithAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentWithAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'clusterName': json['clusterName'],
        'configuration': AgentConfigurationFromJSON(json['configuration']),
        'lastSeenAlive': json['lastSeenAlive'],
        'createdAt': json['createdAt'],
        'installationId': json['installationId'],
        'accountId': json['accountId'],
        'inactive': json['inactive'],
        'account': json['account'] == null ? undefined : AccountPartialFromJSON(json['account']),
    };
}

export function AgentWithAccountToJSON(value?: AgentWithAccount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'clusterName': value['clusterName'],
        'configuration': AgentConfigurationToJSON(value['configuration']),
        'lastSeenAlive': value['lastSeenAlive'],
        'createdAt': value['createdAt'],
        'installationId': value['installationId'],
        'accountId': value['accountId'],
        'inactive': value['inactive'],
        'account': AccountPartialToJSON(value['account']),
    };
}

        /**
 * 
 * @export
 * @interface AgentWithAccountAllOf
 */
export interface AgentWithAccountAllOf {
    /**
     * 
     * @type {AccountPartial}
     * @memberof AgentWithAccountAllOf
     */
    'account'?: AccountPartial;
}


/**
 * Check if a given object implements the AgentWithAccountAllOf interface.
 */
export function instanceOfAgentWithAccountAllOf(value: object): value is AgentWithAccountAllOf {
    return true;
}

export function AgentWithAccountAllOfFromJSON(json: any): AgentWithAccountAllOf {
    return AgentWithAccountAllOfFromJSONTyped(json, false);
}

export function AgentWithAccountAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentWithAccountAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'account': json['account'] == null ? undefined : AccountPartialFromJSON(json['account']),
    };
}

export function AgentWithAccountAllOfToJSON(value?: AgentWithAccountAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'account': AccountPartialToJSON(value['account']),
    };
}

        /**
 * 
 * @export
 * @interface AppMetadataConfig
 */
export interface AppMetadataConfig {
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'buildDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'gitCommit'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'gitBranch'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'targetOs'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'targetArch'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfig
     */
    'component'?: string;
    /**
     * 
     * @type {AppMetadataConfigKubernetesVersion}
     * @memberof AppMetadataConfig
     */
    'kubernetesVersion'?: AppMetadataConfigKubernetesVersion;
}


/**
 * Check if a given object implements the AppMetadataConfig interface.
 */
export function instanceOfAppMetadataConfig(value: object): value is AppMetadataConfig {
    return true;
}

export function AppMetadataConfigFromJSON(json: any): AppMetadataConfig {
    return AppMetadataConfigFromJSONTyped(json, false);
}

export function AppMetadataConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppMetadataConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'buildDate': json['buildDate'] == null ? undefined : json['buildDate'],
        'gitCommit': json['gitCommit'] == null ? undefined : json['gitCommit'],
        'gitBranch': json['gitBranch'] == null ? undefined : json['gitBranch'],
        'version': json['version'] == null ? undefined : json['version'],
        'targetOs': json['targetOs'] == null ? undefined : json['targetOs'],
        'targetArch': json['targetArch'] == null ? undefined : json['targetArch'],
        'component': json['component'] == null ? undefined : json['component'],
        'kubernetesVersion': json['kubernetesVersion'] == null ? undefined : AppMetadataConfigKubernetesVersionFromJSON(json['kubernetesVersion']),
    };
}

export function AppMetadataConfigToJSON(value?: AppMetadataConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'buildDate': value['buildDate'],
        'gitCommit': value['gitCommit'],
        'gitBranch': value['gitBranch'],
        'version': value['version'],
        'targetOs': value['targetOs'],
        'targetArch': value['targetArch'],
        'component': value['component'],
        'kubernetesVersion': AppMetadataConfigKubernetesVersionToJSON(value['kubernetesVersion']),
    };
}

        /**
 * 
 * @export
 * @interface AppMetadataConfigKubernetesVersion
 */
export interface AppMetadataConfigKubernetesVersion {
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'major'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'minor'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'gitVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'gitCommit'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'gitTreeState'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'buildDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'goVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'compiler'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMetadataConfigKubernetesVersion
     */
    'platform'?: string;
}


/**
 * Check if a given object implements the AppMetadataConfigKubernetesVersion interface.
 */
export function instanceOfAppMetadataConfigKubernetesVersion(value: object): value is AppMetadataConfigKubernetesVersion {
    return true;
}

export function AppMetadataConfigKubernetesVersionFromJSON(json: any): AppMetadataConfigKubernetesVersion {
    return AppMetadataConfigKubernetesVersionFromJSONTyped(json, false);
}

export function AppMetadataConfigKubernetesVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppMetadataConfigKubernetesVersion {
    if (json == null) {
        return json;
    }
    return {
        
        'major': json['major'] == null ? undefined : json['major'],
        'minor': json['minor'] == null ? undefined : json['minor'],
        'gitVersion': json['gitVersion'] == null ? undefined : json['gitVersion'],
        'gitCommit': json['gitCommit'] == null ? undefined : json['gitCommit'],
        'gitTreeState': json['gitTreeState'] == null ? undefined : json['gitTreeState'],
        'buildDate': json['buildDate'] == null ? undefined : json['buildDate'],
        'goVersion': json['goVersion'] == null ? undefined : json['goVersion'],
        'compiler': json['compiler'] == null ? undefined : json['compiler'],
        'platform': json['platform'] == null ? undefined : json['platform'],
    };
}

export function AppMetadataConfigKubernetesVersionToJSON(value?: AppMetadataConfigKubernetesVersion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'major': value['major'],
        'minor': value['minor'],
        'gitVersion': value['gitVersion'],
        'gitCommit': value['gitCommit'],
        'gitTreeState': value['gitTreeState'],
        'buildDate': value['buildDate'],
        'goVersion': value['goVersion'],
        'compiler': value['compiler'],
        'platform': value['platform'],
    };
}

        /**
 * 
 * @export
 * @interface ArgoWorkflowsResourcesConfig
 */
export interface ArgoWorkflowsResourcesConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsResourcesConfig
     */
    'workflows'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsResourcesConfig
     */
    'workflowtemplates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsResourcesConfig
     */
    'clusterworkflowtemplates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsResourcesConfig
     */
    'cronworkflows'?: boolean;
}


/**
 * Check if a given object implements the ArgoWorkflowsResourcesConfig interface.
 */
export function instanceOfArgoWorkflowsResourcesConfig(value: object): value is ArgoWorkflowsResourcesConfig {
    return true;
}

export function ArgoWorkflowsResourcesConfigFromJSON(json: any): ArgoWorkflowsResourcesConfig {
    return ArgoWorkflowsResourcesConfigFromJSONTyped(json, false);
}

export function ArgoWorkflowsResourcesConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArgoWorkflowsResourcesConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'workflows': json['workflows'] == null ? undefined : json['workflows'],
        'workflowtemplates': json['workflowtemplates'] == null ? undefined : json['workflowtemplates'],
        'clusterworkflowtemplates': json['clusterworkflowtemplates'] == null ? undefined : json['clusterworkflowtemplates'],
        'cronworkflows': json['cronworkflows'] == null ? undefined : json['cronworkflows'],
    };
}

export function ArgoWorkflowsResourcesConfigToJSON(value?: ArgoWorkflowsResourcesConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflows': value['workflows'],
        'workflowtemplates': value['workflowtemplates'],
        'clusterworkflowtemplates': value['clusterworkflowtemplates'],
        'cronworkflows': value['cronworkflows'],
    };
}

        /**
 * 
 * @export
 * @interface ControllerConfig
 */
export interface ControllerConfig {
    /**
     * 
     * @type {ControllerConfigResync}
     * @memberof ControllerConfig
     */
    'resync'?: ControllerConfigResync;
}


/**
 * Check if a given object implements the ControllerConfig interface.
 */
export function instanceOfControllerConfig(value: object): value is ControllerConfig {
    return true;
}

export function ControllerConfigFromJSON(json: any): ControllerConfig {
    return ControllerConfigFromJSONTyped(json, false);
}

export function ControllerConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControllerConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'resync': json['resync'] == null ? undefined : ControllerConfigResyncFromJSON(json['resync']),
    };
}

export function ControllerConfigToJSON(value?: ControllerConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resync': ControllerConfigResyncToJSON(value['resync']),
    };
}

        /**
 * 
 * @export
 * @interface ControllerConfigResync
 */
export interface ControllerConfigResync {
    /**
     * 
     * @type {string}
     * @memberof ControllerConfigResync
     */
    'period'?: string;
}


/**
 * Check if a given object implements the ControllerConfigResync interface.
 */
export function instanceOfControllerConfigResync(value: object): value is ControllerConfigResync {
    return true;
}

export function ControllerConfigResyncFromJSON(json: any): ControllerConfigResync {
    return ControllerConfigResyncFromJSONTyped(json, false);
}

export function ControllerConfigResyncFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControllerConfigResync {
    if (json == null) {
        return json;
    }
    return {
        
        'period': json['period'] == null ? undefined : json['period'],
    };
}

export function ControllerConfigResyncToJSON(value?: ControllerConfigResync | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': value['period'],
    };
}

        /**
 * 
 * @export
 * @interface DaemonConfig
 */
export interface DaemonConfig {
    /**
     * 
     * @type {boolean}
     * @memberof DaemonConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DaemonConfig
     */
    'varsconfigmapname'?: string;
}


/**
 * Check if a given object implements the DaemonConfig interface.
 */
export function instanceOfDaemonConfig(value: object): value is DaemonConfig {
    return true;
}

export function DaemonConfigFromJSON(json: any): DaemonConfig {
    return DaemonConfigFromJSONTyped(json, false);
}

export function DaemonConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaemonConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'varsconfigmapname': json['varsconfigmapname'] == null ? undefined : json['varsconfigmapname'],
    };
}

export function DaemonConfigToJSON(value?: DaemonConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
        'varsconfigmapname': value['varsconfigmapname'],
    };
}

        /**
 * 
 * @export
 * @interface FiltersConfig
 */
export interface FiltersConfig {
    /**
     * 
     * @type {PodFiltersConfig}
     * @memberof FiltersConfig
     */
    'pod'?: PodFiltersConfig;
}


/**
 * Check if a given object implements the FiltersConfig interface.
 */
export function instanceOfFiltersConfig(value: object): value is FiltersConfig {
    return true;
}

export function FiltersConfigFromJSON(json: any): FiltersConfig {
    return FiltersConfigFromJSONTyped(json, false);
}

export function FiltersConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiltersConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'pod': json['pod'] == null ? undefined : PodFiltersConfigFromJSON(json['pod']),
    };
}

export function FiltersConfigToJSON(value?: FiltersConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pod': PodFiltersConfigToJSON(value['pod']),
    };
}

        /**
 * 
 * @export
 * @interface LiveDataResourceWatcherSessionConfig
 */
export interface LiveDataResourceWatcherSessionConfig {
    /**
     * 
     * @type {boolean}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'stopsessionbyretrywatcher'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'reportsessionlistinterval'?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'sendtimeout'?: string;
    /**
     * 
     * @type {number}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'errorthresholdtofailsession'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'overrideresourceversion'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'addmetricslabelsforkinds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof LiveDataResourceWatcherSessionConfig
     */
    'runwatchandlistconcurrently'?: boolean;
}


/**
 * Check if a given object implements the LiveDataResourceWatcherSessionConfig interface.
 */
export function instanceOfLiveDataResourceWatcherSessionConfig(value: object): value is LiveDataResourceWatcherSessionConfig {
    return true;
}

export function LiveDataResourceWatcherSessionConfigFromJSON(json: any): LiveDataResourceWatcherSessionConfig {
    return LiveDataResourceWatcherSessionConfigFromJSONTyped(json, false);
}

export function LiveDataResourceWatcherSessionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveDataResourceWatcherSessionConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'stopsessionbyretrywatcher': json['stopsessionbyretrywatcher'] == null ? undefined : json['stopsessionbyretrywatcher'],
        'reportsessionlistinterval': json['reportsessionlistinterval'] == null ? undefined : json['reportsessionlistinterval'],
        'sendtimeout': json['sendtimeout'] == null ? undefined : json['sendtimeout'],
        'errorthresholdtofailsession': json['errorthresholdtofailsession'] == null ? undefined : json['errorthresholdtofailsession'],
        'overrideresourceversion': json['overrideresourceversion'] == null ? undefined : json['overrideresourceversion'],
        'addmetricslabelsforkinds': json['addmetricslabelsforkinds'] == null ? undefined : json['addmetricslabelsforkinds'],
        'runwatchandlistconcurrently': json['runwatchandlistconcurrently'] == null ? undefined : json['runwatchandlistconcurrently'],
    };
}

export function LiveDataResourceWatcherSessionConfigToJSON(value?: LiveDataResourceWatcherSessionConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stopsessionbyretrywatcher': value['stopsessionbyretrywatcher'],
        'reportsessionlistinterval': value['reportsessionlistinterval'],
        'sendtimeout': value['sendtimeout'],
        'errorthresholdtofailsession': value['errorthresholdtofailsession'],
        'overrideresourceversion': value['overrideresourceversion'],
        'addmetricslabelsforkinds': value['addmetricslabelsforkinds'],
        'runwatchandlistconcurrently': value['runwatchandlistconcurrently'],
    };
}

        /**
 * 
 * @export
 * @interface LoggerConfig
 */
export interface LoggerConfig {
    /**
     * 
     * @type {boolean}
     * @memberof LoggerConfig
     */
    'prettyprint'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoggerConfig
     */
    'loglevel'?: string;
    /**
     * 
     * @type {LoggerRemoteConfig}
     * @memberof LoggerConfig
     */
    'remote'?: LoggerRemoteConfig;
}


/**
 * Check if a given object implements the LoggerConfig interface.
 */
export function instanceOfLoggerConfig(value: object): value is LoggerConfig {
    return true;
}

export function LoggerConfigFromJSON(json: any): LoggerConfig {
    return LoggerConfigFromJSONTyped(json, false);
}

export function LoggerConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'prettyprint': json['prettyprint'] == null ? undefined : json['prettyprint'],
        'loglevel': json['loglevel'] == null ? undefined : json['loglevel'],
        'remote': json['remote'] == null ? undefined : LoggerRemoteConfigFromJSON(json['remote']),
    };
}

export function LoggerConfigToJSON(value?: LoggerConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'prettyprint': value['prettyprint'],
        'loglevel': value['loglevel'],
        'remote': LoggerRemoteConfigToJSON(value['remote']),
    };
}

        /**
 * 
 * @export
 * @interface LoggerRemoteConfig
 */
export interface LoggerRemoteConfig {
    /**
     * 
     * @type {boolean}
     * @memberof LoggerRemoteConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoggerRemoteConfig
     */
    'loglevel'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoggerRemoteConfig
     */
    'numsenders'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoggerRemoteConfig
     */
    'bufferlength'?: number;
}


/**
 * Check if a given object implements the LoggerRemoteConfig interface.
 */
export function instanceOfLoggerRemoteConfig(value: object): value is LoggerRemoteConfig {
    return true;
}

export function LoggerRemoteConfigFromJSON(json: any): LoggerRemoteConfig {
    return LoggerRemoteConfigFromJSONTyped(json, false);
}

export function LoggerRemoteConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerRemoteConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'loglevel': json['loglevel'] == null ? undefined : json['loglevel'],
        'numsenders': json['numsenders'] == null ? undefined : json['numsenders'],
        'bufferlength': json['bufferlength'] == null ? undefined : json['bufferlength'],
    };
}

export function LoggerRemoteConfigToJSON(value?: LoggerRemoteConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
        'loglevel': value['loglevel'],
        'numsenders': value['numsenders'],
        'bufferlength': value['bufferlength'],
    };
}

        /**
 * 
 * @export
 * @interface LogsConfig
 */
export interface LogsConfig {
    /**
     * 
     * @type {LogsThrottleConfig}
     * @memberof LogsConfig
     */
    'throttle'?: LogsThrottleConfig;
}


/**
 * Check if a given object implements the LogsConfig interface.
 */
export function instanceOfLogsConfig(value: object): value is LogsConfig {
    return true;
}

export function LogsConfigFromJSON(json: any): LogsConfig {
    return LogsConfigFromJSONTyped(json, false);
}

export function LogsConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogsConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'throttle': json['throttle'] == null ? undefined : LogsThrottleConfigFromJSON(json['throttle']),
    };
}

export function LogsConfigToJSON(value?: LogsConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'throttle': LogsThrottleConfigToJSON(value['throttle']),
    };
}

        /**
 * 
 * @export
 * @interface LogsThrottleConfig
 */
export interface LogsThrottleConfig {
    /**
     * 
     * @type {boolean}
     * @memberof LogsThrottleConfig
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LogsThrottleConfig
     */
    'bytes'?: number;
}


/**
 * Check if a given object implements the LogsThrottleConfig interface.
 */
export function instanceOfLogsThrottleConfig(value: object): value is LogsThrottleConfig {
    return true;
}

export function LogsThrottleConfigFromJSON(json: any): LogsThrottleConfig {
    return LogsThrottleConfigFromJSONTyped(json, false);
}

export function LogsThrottleConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogsThrottleConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'enable': json['enable'] == null ? undefined : json['enable'],
        'bytes': json['bytes'] == null ? undefined : json['bytes'],
    };
}

export function LogsThrottleConfigToJSON(value?: LogsThrottleConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enable': value['enable'],
        'bytes': value['bytes'],
    };
}

        /**
 * 
 * @export
 * @interface NetworkMapperConfig
 */
export interface NetworkMapperConfig {
    /**
     * 
     * @type {boolean}
     * @memberof NetworkMapperConfig
     */
    'enable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapperConfig
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapperConfig
     */
    'queryinterval'?: string;
}


/**
 * Check if a given object implements the NetworkMapperConfig interface.
 */
export function instanceOfNetworkMapperConfig(value: object): value is NetworkMapperConfig {
    return true;
}

export function NetworkMapperConfigFromJSON(json: any): NetworkMapperConfig {
    return NetworkMapperConfigFromJSONTyped(json, false);
}

export function NetworkMapperConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkMapperConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'enable': json['enable'] == null ? undefined : json['enable'],
        'host': json['host'] == null ? undefined : json['host'],
        'queryinterval': json['queryinterval'] == null ? undefined : json['queryinterval'],
    };
}

export function NetworkMapperConfigToJSON(value?: NetworkMapperConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enable': value['enable'],
        'host': value['host'],
        'queryinterval': value['queryinterval'],
    };
}

        /**
 * 
 * @export
 * @interface PeriodicTasksConfig
 */
export interface PeriodicTasksConfig {
    /**
     * 
     * @type {PodStatusAggregatorConfig}
     * @memberof PeriodicTasksConfig
     */
    'podstatusaggregator'?: PodStatusAggregatorConfig;
}


/**
 * Check if a given object implements the PeriodicTasksConfig interface.
 */
export function instanceOfPeriodicTasksConfig(value: object): value is PeriodicTasksConfig {
    return true;
}

export function PeriodicTasksConfigFromJSON(json: any): PeriodicTasksConfig {
    return PeriodicTasksConfigFromJSONTyped(json, false);
}

export function PeriodicTasksConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodicTasksConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'podstatusaggregator': json['podstatusaggregator'] == null ? undefined : PodStatusAggregatorConfigFromJSON(json['podstatusaggregator']),
    };
}

export function PeriodicTasksConfigToJSON(value?: PeriodicTasksConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'podstatusaggregator': PodStatusAggregatorConfigToJSON(value['podstatusaggregator']),
    };
}

        /**
 * 
 * @export
 * @interface PodFiltersConfig
 */
export interface PodFiltersConfig {
    /**
     * 
     * @type {boolean}
     * @memberof PodFiltersConfig
     */
    'drophealthy'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodFiltersConfig
     */
    'goodstatuses'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PodFiltersConfig
     */
    'cacheinvalidationminutes'?: number;
}


/**
 * Check if a given object implements the PodFiltersConfig interface.
 */
export function instanceOfPodFiltersConfig(value: object): value is PodFiltersConfig {
    return true;
}

export function PodFiltersConfigFromJSON(json: any): PodFiltersConfig {
    return PodFiltersConfigFromJSONTyped(json, false);
}

export function PodFiltersConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodFiltersConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'drophealthy': json['drophealthy'] == null ? undefined : json['drophealthy'],
        'goodstatuses': json['goodstatuses'] == null ? undefined : json['goodstatuses'],
        'cacheinvalidationminutes': json['cacheinvalidationminutes'] == null ? undefined : json['cacheinvalidationminutes'],
    };
}

export function PodFiltersConfigToJSON(value?: PodFiltersConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'drophealthy': value['drophealthy'],
        'goodstatuses': value['goodstatuses'],
        'cacheinvalidationminutes': value['cacheinvalidationminutes'],
    };
}

        /**
 * 
 * @export
 * @interface PodPhase
 */
export interface PodPhase {
    /**
     * 
     * @type {boolean}
     * @memberof PodPhase
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodPhase
     */
    'eventadditionaldata'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PodPhase
     */
    'queueworkercount'?: number;
}


/**
 * Check if a given object implements the PodPhase interface.
 */
export function instanceOfPodPhase(value: object): value is PodPhase {
    return true;
}

export function PodPhaseFromJSON(json: any): PodPhase {
    return PodPhaseFromJSONTyped(json, false);
}

export function PodPhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodPhase {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'eventadditionaldata': json['eventadditionaldata'] == null ? undefined : json['eventadditionaldata'],
        'queueworkercount': json['queueworkercount'] == null ? undefined : json['queueworkercount'],
    };
}

export function PodPhaseToJSON(value?: PodPhase | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
        'eventadditionaldata': value['eventadditionaldata'],
        'queueworkercount': value['queueworkercount'],
    };
}

        /**
 * 
 * @export
 * @interface PodStatusAggregatorConfig
 */
export interface PodStatusAggregatorConfig {
    /**
     * 
     * @type {string}
     * @memberof PodStatusAggregatorConfig
     */
    'interval'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PodStatusAggregatorConfig
     */
    'enable'?: boolean;
}


/**
 * Check if a given object implements the PodStatusAggregatorConfig interface.
 */
export function instanceOfPodStatusAggregatorConfig(value: object): value is PodStatusAggregatorConfig {
    return true;
}

export function PodStatusAggregatorConfigFromJSON(json: any): PodStatusAggregatorConfig {
    return PodStatusAggregatorConfigFromJSONTyped(json, false);
}

export function PodStatusAggregatorConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodStatusAggregatorConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'interval': json['interval'] == null ? undefined : json['interval'],
        'enable': json['enable'] == null ? undefined : json['enable'],
    };
}

export function PodStatusAggregatorConfigToJSON(value?: PodStatusAggregatorConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interval': value['interval'],
        'enable': value['enable'],
    };
}

        /**
 * 
 * @export
 * @interface QueueConfig
 */
export interface QueueConfig {
    /**
     * 
     * @type {number}
     * @memberof QueueConfig
     */
    'maxretries'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueConfig
     */
    'workercount'?: number;
}


/**
 * Check if a given object implements the QueueConfig interface.
 */
export function instanceOfQueueConfig(value: object): value is QueueConfig {
    return true;
}

export function QueueConfigFromJSON(json: any): QueueConfig {
    return QueueConfigFromJSONTyped(json, false);
}

export function QueueConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueueConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'maxretries': json['maxretries'] == null ? undefined : json['maxretries'],
        'workercount': json['workercount'] == null ? undefined : json['workercount'],
    };
}

export function QueueConfigToJSON(value?: QueueConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maxretries': value['maxretries'],
        'workercount': value['workercount'],
    };
}

        /**
 * 
 * @export
 * @interface RedactionConfig
 */
export interface RedactionConfig {
    /**
     * 
     * @type {RedactionSecretConfig}
     * @memberof RedactionConfig
     */
    'secret'?: RedactionSecretConfig;
}


/**
 * Check if a given object implements the RedactionConfig interface.
 */
export function instanceOfRedactionConfig(value: object): value is RedactionConfig {
    return true;
}

export function RedactionConfigFromJSON(json: any): RedactionConfig {
    return RedactionConfigFromJSONTyped(json, false);
}

export function RedactionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedactionConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'secret': json['secret'] == null ? undefined : RedactionSecretConfigFromJSON(json['secret']),
    };
}

export function RedactionConfigToJSON(value?: RedactionConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'secret': RedactionSecretConfigToJSON(value['secret']),
    };
}

        /**
 * 
 * @export
 * @interface RedactionSecretConfig
 */
export interface RedactionSecretConfig {
    /**
     * 
     * @type {boolean}
     * @memberof RedactionSecretConfig
     */
    'enable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedactionSecretConfig
     */
    'keeponlyhelmreleases'?: boolean;
}


/**
 * Check if a given object implements the RedactionSecretConfig interface.
 */
export function instanceOfRedactionSecretConfig(value: object): value is RedactionSecretConfig {
    return true;
}

export function RedactionSecretConfigFromJSON(json: any): RedactionSecretConfig {
    return RedactionSecretConfigFromJSONTyped(json, false);
}

export function RedactionSecretConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedactionSecretConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'enable': json['enable'] == null ? undefined : json['enable'],
        'keeponlyhelmreleases': json['keeponlyhelmreleases'] == null ? undefined : json['keeponlyhelmreleases'],
    };
}

export function RedactionSecretConfigToJSON(value?: RedactionSecretConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enable': value['enable'],
        'keeponlyhelmreleases': value['keeponlyhelmreleases'],
    };
}

        /**
 * 
 * @export
 * @interface ResourcesConfig
 */
export interface ResourcesConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'event'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'deployment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'replicationcontroller'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'replicaset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'daemonset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'service'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'pod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'job'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'node'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'clusterrole'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'serviceaccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'persistentvolume'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'persistentvolumeclaim'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'namespace'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'secret'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'configmap'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'ingress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'statefulset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'storageclass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'endpoints'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'rollout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'cronjob'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'limitrange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'podtemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'resourcequota'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'mutatingwebhookconfiguration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'validatingwebhookconfiguration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'controllerrevision'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'selfsubjectaccessreview'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'selfsubjectrulesreview'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'subjectaccessreview'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'horizontalpodautoscaler'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'certificatesigningrequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'lease'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'endpointslice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'flowschema'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'prioritylevelconfiguration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'ingressclass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'networkpolicy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'runtimeclass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'poddisruptionbudget'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'clusterrolebinding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'rolebinding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'role'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'priorityclass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'csidriver'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'csinode'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'csistoragecapacity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'volumeattachment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesConfig
     */
    'customresourcedefinition'?: boolean;
    /**
     * 
     * @type {ArgoWorkflowsResourcesConfig}
     * @memberof ResourcesConfig
     */
    'argoworkflows'?: ArgoWorkflowsResourcesConfig;
}


/**
 * Check if a given object implements the ResourcesConfig interface.
 */
export function instanceOfResourcesConfig(value: object): value is ResourcesConfig {
    return true;
}

export function ResourcesConfigFromJSON(json: any): ResourcesConfig {
    return ResourcesConfigFromJSONTyped(json, false);
}

export function ResourcesConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourcesConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'event': json['event'] == null ? undefined : json['event'],
        'deployment': json['deployment'] == null ? undefined : json['deployment'],
        'replicationcontroller': json['replicationcontroller'] == null ? undefined : json['replicationcontroller'],
        'replicaset': json['replicaset'] == null ? undefined : json['replicaset'],
        'daemonset': json['daemonset'] == null ? undefined : json['daemonset'],
        'service': json['service'] == null ? undefined : json['service'],
        'pod': json['pod'] == null ? undefined : json['pod'],
        'job': json['job'] == null ? undefined : json['job'],
        'node': json['node'] == null ? undefined : json['node'],
        'clusterrole': json['clusterrole'] == null ? undefined : json['clusterrole'],
        'serviceaccount': json['serviceaccount'] == null ? undefined : json['serviceaccount'],
        'persistentvolume': json['persistentvolume'] == null ? undefined : json['persistentvolume'],
        'persistentvolumeclaim': json['persistentvolumeclaim'] == null ? undefined : json['persistentvolumeclaim'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'secret': json['secret'] == null ? undefined : json['secret'],
        'configmap': json['configmap'] == null ? undefined : json['configmap'],
        'ingress': json['ingress'] == null ? undefined : json['ingress'],
        'statefulset': json['statefulset'] == null ? undefined : json['statefulset'],
        'storageclass': json['storageclass'] == null ? undefined : json['storageclass'],
        'endpoints': json['endpoints'] == null ? undefined : json['endpoints'],
        'rollout': json['rollout'] == null ? undefined : json['rollout'],
        'cronjob': json['cronjob'] == null ? undefined : json['cronjob'],
        'limitrange': json['limitrange'] == null ? undefined : json['limitrange'],
        'podtemplate': json['podtemplate'] == null ? undefined : json['podtemplate'],
        'resourcequota': json['resourcequota'] == null ? undefined : json['resourcequota'],
        'mutatingwebhookconfiguration': json['mutatingwebhookconfiguration'] == null ? undefined : json['mutatingwebhookconfiguration'],
        'validatingwebhookconfiguration': json['validatingwebhookconfiguration'] == null ? undefined : json['validatingwebhookconfiguration'],
        'controllerrevision': json['controllerrevision'] == null ? undefined : json['controllerrevision'],
        'selfsubjectaccessreview': json['selfsubjectaccessreview'] == null ? undefined : json['selfsubjectaccessreview'],
        'selfsubjectrulesreview': json['selfsubjectrulesreview'] == null ? undefined : json['selfsubjectrulesreview'],
        'subjectaccessreview': json['subjectaccessreview'] == null ? undefined : json['subjectaccessreview'],
        'horizontalpodautoscaler': json['horizontalpodautoscaler'] == null ? undefined : json['horizontalpodautoscaler'],
        'certificatesigningrequest': json['certificatesigningrequest'] == null ? undefined : json['certificatesigningrequest'],
        'lease': json['lease'] == null ? undefined : json['lease'],
        'endpointslice': json['endpointslice'] == null ? undefined : json['endpointslice'],
        'flowschema': json['flowschema'] == null ? undefined : json['flowschema'],
        'prioritylevelconfiguration': json['prioritylevelconfiguration'] == null ? undefined : json['prioritylevelconfiguration'],
        'ingressclass': json['ingressclass'] == null ? undefined : json['ingressclass'],
        'networkpolicy': json['networkpolicy'] == null ? undefined : json['networkpolicy'],
        'runtimeclass': json['runtimeclass'] == null ? undefined : json['runtimeclass'],
        'poddisruptionbudget': json['poddisruptionbudget'] == null ? undefined : json['poddisruptionbudget'],
        'clusterrolebinding': json['clusterrolebinding'] == null ? undefined : json['clusterrolebinding'],
        'rolebinding': json['rolebinding'] == null ? undefined : json['rolebinding'],
        'role': json['role'] == null ? undefined : json['role'],
        'priorityclass': json['priorityclass'] == null ? undefined : json['priorityclass'],
        'csidriver': json['csidriver'] == null ? undefined : json['csidriver'],
        'csinode': json['csinode'] == null ? undefined : json['csinode'],
        'csistoragecapacity': json['csistoragecapacity'] == null ? undefined : json['csistoragecapacity'],
        'volumeattachment': json['volumeattachment'] == null ? undefined : json['volumeattachment'],
        'customresourcedefinition': json['customresourcedefinition'] == null ? undefined : json['customresourcedefinition'],
        'argoworkflows': json['argoworkflows'] == null ? undefined : ArgoWorkflowsResourcesConfigFromJSON(json['argoworkflows']),
    };
}

export function ResourcesConfigToJSON(value?: ResourcesConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event': value['event'],
        'deployment': value['deployment'],
        'replicationcontroller': value['replicationcontroller'],
        'replicaset': value['replicaset'],
        'daemonset': value['daemonset'],
        'service': value['service'],
        'pod': value['pod'],
        'job': value['job'],
        'node': value['node'],
        'clusterrole': value['clusterrole'],
        'serviceaccount': value['serviceaccount'],
        'persistentvolume': value['persistentvolume'],
        'persistentvolumeclaim': value['persistentvolumeclaim'],
        'namespace': value['namespace'],
        'secret': value['secret'],
        'configmap': value['configmap'],
        'ingress': value['ingress'],
        'statefulset': value['statefulset'],
        'storageclass': value['storageclass'],
        'endpoints': value['endpoints'],
        'rollout': value['rollout'],
        'cronjob': value['cronjob'],
        'limitrange': value['limitrange'],
        'podtemplate': value['podtemplate'],
        'resourcequota': value['resourcequota'],
        'mutatingwebhookconfiguration': value['mutatingwebhookconfiguration'],
        'validatingwebhookconfiguration': value['validatingwebhookconfiguration'],
        'controllerrevision': value['controllerrevision'],
        'selfsubjectaccessreview': value['selfsubjectaccessreview'],
        'selfsubjectrulesreview': value['selfsubjectrulesreview'],
        'subjectaccessreview': value['subjectaccessreview'],
        'horizontalpodautoscaler': value['horizontalpodautoscaler'],
        'certificatesigningrequest': value['certificatesigningrequest'],
        'lease': value['lease'],
        'endpointslice': value['endpointslice'],
        'flowschema': value['flowschema'],
        'prioritylevelconfiguration': value['prioritylevelconfiguration'],
        'ingressclass': value['ingressclass'],
        'networkpolicy': value['networkpolicy'],
        'runtimeclass': value['runtimeclass'],
        'poddisruptionbudget': value['poddisruptionbudget'],
        'clusterrolebinding': value['clusterrolebinding'],
        'rolebinding': value['rolebinding'],
        'role': value['role'],
        'priorityclass': value['priorityclass'],
        'csidriver': value['csidriver'],
        'csinode': value['csinode'],
        'csistoragecapacity': value['csistoragecapacity'],
        'volumeattachment': value['volumeattachment'],
        'customresourcedefinition': value['customresourcedefinition'],
        'argoworkflows': ArgoWorkflowsResourcesConfigToJSON(value['argoworkflows']),
    };
}

        /**
 * 
 * @export
 * @interface TelemetryConfig
 */
export interface TelemetryConfig {
    /**
     * 
     * @type {boolean}
     * @memberof TelemetryConfig
     */
    'enable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelemetryConfig
     */
    'serverhost'?: string;
    /**
     * 
     * @type {number}
     * @memberof TelemetryConfig
     */
    'flushtimeout'?: number;
    /**
     * 
     * @type {number}
     * @memberof TelemetryConfig
     */
    'bufferflushinterval'?: number;
    /**
     * 
     * @type {number}
     * @memberof TelemetryConfig
     */
    'samplingrate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TelemetryConfig
     */
    'collectapiservermetrics'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TelemetryConfig
     */
    'bufferlength'?: number;
}


/**
 * Check if a given object implements the TelemetryConfig interface.
 */
export function instanceOfTelemetryConfig(value: object): value is TelemetryConfig {
    return true;
}

export function TelemetryConfigFromJSON(json: any): TelemetryConfig {
    return TelemetryConfigFromJSONTyped(json, false);
}

export function TelemetryConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'enable': json['enable'] == null ? undefined : json['enable'],
        'serverhost': json['serverhost'] == null ? undefined : json['serverhost'],
        'flushtimeout': json['flushtimeout'] == null ? undefined : json['flushtimeout'],
        'bufferflushinterval': json['bufferflushinterval'] == null ? undefined : json['bufferflushinterval'],
        'samplingrate': json['samplingrate'] == null ? undefined : json['samplingrate'],
        'collectapiservermetrics': json['collectapiservermetrics'] == null ? undefined : json['collectapiservermetrics'],
        'bufferlength': json['bufferlength'] == null ? undefined : json['bufferlength'],
    };
}

export function TelemetryConfigToJSON(value?: TelemetryConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enable': value['enable'],
        'serverhost': value['serverhost'],
        'flushtimeout': value['flushtimeout'],
        'bufferflushinterval': value['bufferflushinterval'],
        'samplingrate': value['samplingrate'],
        'collectapiservermetrics': value['collectapiservermetrics'],
        'bufferlength': value['bufferlength'],
    };
}

        /**
 * 
 * @export
 * @interface WatchOptionsConfig
 */
export interface WatchOptionsConfig {
    /**
     * 
     * @type {boolean}
     * @memberof WatchOptionsConfig
     */
    'allowwatchbookmarks'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WatchOptionsConfig
     */
    'resourceversionmatch'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WatchOptionsConfig
     */
    'sendinitialevents'?: boolean;
}


/**
 * Check if a given object implements the WatchOptionsConfig interface.
 */
export function instanceOfWatchOptionsConfig(value: object): value is WatchOptionsConfig {
    return true;
}

export function WatchOptionsConfigFromJSON(json: any): WatchOptionsConfig {
    return WatchOptionsConfigFromJSONTyped(json, false);
}

export function WatchOptionsConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): WatchOptionsConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'allowwatchbookmarks': json['allowwatchbookmarks'] == null ? undefined : json['allowwatchbookmarks'],
        'resourceversionmatch': json['resourceversionmatch'] == null ? undefined : json['resourceversionmatch'],
        'sendinitialevents': json['sendinitialevents'] == null ? undefined : json['sendinitialevents'],
    };
}

export function WatchOptionsConfigToJSON(value?: WatchOptionsConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowwatchbookmarks': value['allowwatchbookmarks'],
        'resourceversionmatch': value['resourceversionmatch'],
        'sendinitialevents': value['sendinitialevents'],
    };
}

    
        /**
* Get all active agents
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AgentsActiveGetUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/agents/active`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* List agents
* @param params AgentsApiApiV1AgentsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AgentsGetUrl = (params: AgentsApiApiV1AgentsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/agents`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.installationId !== undefined) {
            localVarQueryParameter['installationId'] = params.installationId;
    }
    if (params.configurationParam !== undefined) {
            localVarQueryParameter['configurationParam'] = params.configurationParam;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.inactive !== undefined) {
            localVarQueryParameter['inactive'] = params.inactive;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    if (params.distinct !== undefined) {
            localVarQueryParameter['distinct'] = params.distinct;
    }
    if (params.fromLastSeenAliveEpoch !== undefined) {
            localVarQueryParameter['fromLastSeenAliveEpoch'] = params.fromLastSeenAliveEpoch;
    }
    if (params.toLastSeenAliveEpoch !== undefined) {
            localVarQueryParameter['toLastSeenAliveEpoch'] = params.toLastSeenAliveEpoch;
    }
    if (params.offset !== undefined) {
            localVarQueryParameter['offset'] = params.offset;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get Agent by ID
* @param params AgentsApiApiV1AgentsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AgentsIdGetUrl = (params: AgentsApiApiV1AgentsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1AgentsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/agents/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AgentsGet operation in AgentsApi.
* @export
* @interface AgentsApiApiV1AgentsGetRequest
*/
export interface AgentsApiApiV1AgentsGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly clusterNames?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'clusterName' | 'configuration' | 'lastSeenAlive' | 'createdAt' | 'installationId' | 'accountId' | 'inactive'>}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly fields?: Array<'id' | 'clusterName' | 'configuration' | 'lastSeenAlive' | 'createdAt' | 'installationId' | 'accountId' | 'inactive'>

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly installationId?: string

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly configurationParam?: string

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {boolean}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly inactive?: boolean

    /**
    * 
    * @type {number}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly limit?: number

    /**
    * if no sort order was specified, the order will be random from the database. desc syntax is &#x60;-fieldName&#x60;. e.g. -lastSeenAlive
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly sort?: string

    /**
    * distinct by fields
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly distinct?: string

    /**
    * Start LastSeenAlive in epoch format
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly fromLastSeenAliveEpoch?: string

    /**
    * End LastSeenAlive in epoch format
    * @type {string}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly toLastSeenAliveEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof AgentsApiApiV1AgentsGet
    */
    readonly offset?: number
}

/**
* Request parameters for apiV1AgentsIdGet operation in AgentsApi.
* @export
* @interface AgentsApiApiV1AgentsIdGetRequest
*/
export interface AgentsApiApiV1AgentsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {string}
    * @memberof AgentsApiApiV1AgentsIdGet
    */
    readonly accountId?: string
}


    
