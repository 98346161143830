import React from "react";
import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { UseQueryResult } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import styled from "styled-components";

import { tableProps } from "../../violationsBreakdownConstants";
import { useEmptyColumns, useIssuesColumns } from "../hooks/useIssuesColumns";
import { useIssuesTableContext } from "../context/useIssuesTableContext";
import { useOpenIssueDrawer } from "../hooks/useOpenIssueDrawer";
import { VIOLATIONS_TABLE_ROW_HEIGHT } from "../../ViolationTableWithContext/violationTableConstants";

import { generateEmptyRows } from "@/shared/utils/tableUtils";
import {
  BasicViolation,
  GetViolationsResponse,
} from "@/generated/reliabilityApi";
import { useRowsCount } from "@/components/reliability/components/shared/table/useRowsCount";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { useIssuesTablePaginationModel } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/hooks/useIssuesTablePaginationModel";
import { useSetPaginationModel } from "@/components/reliability/hooks/useViolationTableHooks";
import { ViolationTableCustomPaginationModel } from "@/components/reliability/ReliabilityTypes";
import { useReportTableLoadingState } from "@/components/reliability/hooks/useReportTableLoadingState";

const Container = styled.div`
  position: relative;
`;

const StyledDataGridPro = styled(DataGridPro)`
  && {
    .MuiDataGrid-row {
      min-height: ${VIOLATIONS_TABLE_ROW_HEIGHT}px!important;
    }
    .MuiDataGrid-row:hover {
      cursor: pointer;
    }
  }
`;

type IssuesTableProps = {
  customPaginationObj?: ViolationTableCustomPaginationModel;
  violationsRes: UseQueryResult<AxiosResponse<GetViolationsResponse>>;
  containerWidth?: number;
};

export const IssuesTable: React.FC<IssuesTableProps> = ({
  customPaginationObj,
  violationsRes,
  containerWidth,
}) => {
  const { data, isFetching, error } = violationsRes;
  const { uid, violationsGroupBy } = useIssuesTableContext();
  const { customPaginationModel, setCustomPaginationModel } =
    customPaginationObj ?? {};
  const rowsCount = useRowsCount(data?.data?.totalResults);

  useReportTableLoadingState({
    uid: uid ?? "",
    violationsRes,
  });

  const openIssueDrawer = useOpenIssueDrawer();

  const onRowClickHandler = (params: GridRowParams<BasicViolation>) => {
    openIssueDrawer(params);
    dispatchEvent(AnalyticEvents.Reliability.IssuesRowClicked, {
      rowCheckType: params.row.checkType,
    });
  };

  const hasNoResults = data?.data.violations?.length === 0 || !!error;
  const paginationModel = useIssuesTablePaginationModel(customPaginationModel);

  const setPaginationModel = useSetPaginationModel(uid, violationsGroupBy);

  const rowsToUse =
    isFetching || hasNoResults
      ? generateEmptyRows<BasicViolation>(paginationModel.pageSize)
      : data?.data.violations;

  const columns = useIssuesColumns();
  const emptyColumns = useEmptyColumns();

  const columnsToUse = isFetching || hasNoResults ? emptyColumns : columns;

  const ariaLabel =
    isFetching || hasNoResults
      ? `${tableProps["aria-label"]} loading`
      : `${tableProps["aria-label"]} content`;

  return (
    <Container style={containerWidth ? { width: containerWidth } : {}}>
      <StyledDataGridPro
        {...tableProps}
        getRowHeight={() => "auto"}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        onRowClick={onRowClickHandler}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowsCount}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setCustomPaginationModel ?? setPaginationModel}
      />
    </Container>
  );
};
