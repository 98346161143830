// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import {
  ariaLabels,
  generateContextMenuAriaLabels,
  SupportedActions,
} from "./constants";

import { ContextMenu } from "../Menus/ContextMenu";
import { Ellipsis16 } from "../../../icons";
import { muiTheme } from "../../../themes";

const AddRuleMenuItem = ({
  onAddRuleExclusion,
}: {
  onAddRuleExclusion: () => void;
}) => ({
  route: SupportedActions.ADD,
  CustomComponent: ({ onClose }) => (
    <MenuItem
      onClick={() => {
        onAddRuleExclusion();
        onClose();
      }}
      aria-label={ariaLabels.contextMenu.addRuleExclusion}
    >
      <Stack direction="row" columnGap="4px" alignItems="flex-start">
        <Typography variant="body2">Add exclusion</Typography>
      </Stack>
    </MenuItem>
  ),
});

const RemoveRuleMenuItem = ({
  onRemoveRule,
}: {
  onRemoveRule: () => void;
}) => ({
  route: SupportedActions.REMOVE_RULE,
  CustomComponent: ({ onClose }) => (
    <MenuItem
      onClick={() => {
        onRemoveRule();
        onClose();
      }}
      aria-label={ariaLabels.contextMenu.removeRule}
    >
      <Stack direction="row" columnGap="4px" alignItems="flex-start">
        <Typography variant="body2" color={muiTheme.palette.error.dark}>
          Remove
        </Typography>
      </Stack>
    </MenuItem>
  ),
});

const RemoveRuleExclusionMenuItem = ({
  onRemoveRuleExclusion,
}: {
  onRemoveRuleExclusion: () => void;
}) => ({
  route: SupportedActions.REMOVE_EXCLUSION,
  CustomComponent: ({ onClose }) => (
    <MenuItem
      onClick={() => {
        onRemoveRuleExclusion();
        onClose();
      }}
      aria-label={ariaLabels.contextMenu.removeRuleExclusion}
    >
      <Stack direction="row" columnGap="4px" alignItems="flex-start">
        <Typography variant="body2">Remove exclusion</Typography>
      </Stack>
    </MenuItem>
  ),
});

export const getRuleContextMenu = ({
  id,
  canAddRuleExclusion,
  canRemoveRule,
  canRemoveRuleExclusion,
  onAddRuleExclusion,
  onRemoveRule,
  onRemoveRuleExclusion,
}: {
  id: string;
  canAddRuleExclusion: boolean;
  canRemoveRule: boolean;
  canRemoveRuleExclusion: boolean;
  onAddRuleExclusion: () => void;
  onRemoveRule: () => void;
  onRemoveRuleExclusion: () => void;
}) => {
  if (
    [canAddRuleExclusion, canRemoveRule, canRemoveRuleExclusion].every(
      (action) => !action
    )
  ) {
    return null;
  }

  const AddMenuItem = !canAddRuleExclusion
    ? null
    : AddRuleMenuItem({ onAddRuleExclusion });
  const RemoveMenuItem = !canRemoveRule
    ? null
    : RemoveRuleMenuItem({ onRemoveRule });
  const RemoveExclusionMenuItem = !canRemoveRuleExclusion
    ? null
    : RemoveRuleExclusionMenuItem({ onRemoveRuleExclusion });

  const ariaLabels = generateContextMenuAriaLabels(id);

  return (
    <ContextMenu
      id={id}
      ariaLabels={{
        button: ariaLabels.button,
        menu: ariaLabels.menu,
      }}
      items={[AddMenuItem, RemoveExclusionMenuItem, RemoveMenuItem].filter(
        Boolean
      )}
      LinkComponent={Link}
      buttonComponent={IconButton}
      sx={{ ".MuiMenu-paper": { width: "150px" } }}
    >
      <Ellipsis16 />
    </ContextMenu>
  );
};
