import {
  useActiveAgent,
  useAgentPropertiesById,
} from "@/shared/hooks/useAgents";
import { doesAgentVersionSupportCustomResourceInspection } from "@/shared/utils/agent/agent";

export const useIsAgentSupportedCRDsFromAtm = (
  clusterName: string
): { isAgentSupported: boolean; fetching: boolean } => {
  const agentId = useActiveAgent(clusterName) ?? "";
  const { agentInfo, isFetching } = useAgentPropertiesById(agentId);

  return {
    isAgentSupported:
      doesAgentVersionSupportCustomResourceInspection(agentInfo),
    fetching: isFetching,
  };
};
