import React, { useCallback, useMemo, useState } from "react";
import { GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import Stack from "@mui/material/Stack";
import {
  DataGrid,
  LightTooltip,
  SearchField,
} from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Skeleton from "@mui/material/Skeleton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  ExternalDnsManagedRecord,
  ExternalDnsManagedRecordStatusEnum,
} from "@/generated/addonsApi";
import { SourceDetailsPanel } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/SourceDetailsPanel";
import { StatusCell } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/StatusCell";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { ConnectedWorkloadsCell } from "@/shared/components/ConnectedWorkloadsCell/ConnectedWorkloadsCell";
import { EmptyTableResults } from "@/components/common/table/EmptyResults";
import { ErrorTableResults } from "@/components/common/table/ErrorResults";

const DEFAULT_SORT_MODEL: GridSortModel = [{ field: "status", sort: "desc" }];

const DEFAULT_COLUMN_LOADER_WIDTH = "100px";

const mockRows = Array.from({ length: 3 }).map((_, index) => ({
  host: `host-${index}`,
  source: `Deployment|test|test|test-${index}`,
  namespace: "",
  connectedResources: [],
  status: ExternalDnsManagedRecordStatusEnum.Resolved,
  targetAddresses: [],
})) as ExternalDnsManagedRecord[];

const columns: GridColDef<ExternalDnsManagedRecord>[] = [
  {
    field: "host",
    headerName: "Host",
    flex: 1,
  },
  {
    field: "targetAddresses",
    headerName: "Targets",
    flex: 1,
  },
  {
    field: "connectedResources",
    headerName: "Connected Workloads",
    flex: 1,
    sortComparator: (a: string[], b: string[]) => a.length - b.length,
    renderCell: ({ row: { host, source, connectedResources } }) => (
      <ConnectedWorkloadsCell
        host={host}
        source={source}
        connectedWorkloadsUids={connectedResources}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    maxWidth: 110,
    renderHeader: (params) => (
      <Box display="flex" columnGap="4px" alignItems="center">
        {params.colDef.headerName}
        <LightTooltip
          slotProps={{
            tooltip: { sx: { maxWidth: "340px" } },
          }}
          placement="left"
          title="A DNS record is considered resolved if the domain name can be successfully looked up and translated into it's associated IP address or hostname from within the cluster"
        >
          <InfoOutlined fontSize="small" color="action" />
        </LightTooltip>
      </Box>
    ),
    renderCell: ({ row: { status } }) => <StatusCell status={status} />,
  },
];

const loadingColumns = columns.map((column) => ({
  ...column,
  renderCell: () => <Skeleton width={DEFAULT_COLUMN_LOADER_WIDTH} />,
}));

const NoRowsOverlay: React.FC<{
  isError: boolean;
  onRetry?: () => void;
  onClear?: () => void;
}> = ({ isError, onRetry, onClear }) => {
  return (
    <Box
      sx={{
        width: "100%",
        paddingBlock: "60px",
      }}
    >
      {isError ? (
        <ErrorTableResults
          message={"We were unable to get the DNS records"}
          onRetry={onRetry}
        />
      ) : (
        <EmptyTableResults onClearFilters={onClear} />
      )}
    </Box>
  );
};

export const ManagedRecordsTable: React.FC<{
  isLoadingManagedRecords: boolean;
  isErrorFetchingManagedRecords: boolean;
  managedRecords: ExternalDnsManagedRecord[];
  refetchManagedRecords: () => void;
}> = ({
  isLoadingManagedRecords,
  managedRecords,
  isErrorFetchingManagedRecords,
  refetchManagedRecords,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredRecords = useMemo(
    () => managedRecords.filter((record) => record.host.includes(searchTerm)),
    [managedRecords, searchTerm]
  );

  const getSourcePanel = useCallback(
    (params: GridRowParams<ExternalDnsManagedRecord>) => {
      return (
        <SourceDetailsPanel
          sourceKomodorUid={params.row.source}
          isUnresolved={params.row.status !== "resolved"}
        />
      );
    },
    []
  );

  const rows = useMemo(
    () => (isLoadingManagedRecords ? mockRows : filteredRecords),
    [isLoadingManagedRecords, filteredRecords]
  );

  const cols = useMemo(() => {
    return isLoadingManagedRecords ? loadingColumns : columns;
  }, [isLoadingManagedRecords]);

  return (
    <Stack gap="8px">
      <SearchField
        value={searchTerm}
        onSearch={setSearchTerm}
        placeholder="Host name"
        aria-label={
          externalDnsListPageAriaLabels.drawer.sections.managedRecords
            .searchField
        }
      />
      <DataGrid
        sx={{
          "& .MuiDataGrid-overlayWrapper": {
            height: "100%",
            width: "100%",
          },
          "& .MuiDataGrid-overlayWrapperInner": {
            height: "unset",
          },
        }}
        autoHeight
        disableVirtualization
        rows={rows}
        columns={cols}
        getRowId={(row) => `${row.host}-${row.source}`}
        getDetailPanelContent={
          isLoadingManagedRecords ? () => null : getSourcePanel
        }
        initialState={{
          sorting: { sortModel: DEFAULT_SORT_MODEL },
        }}
        aria-label={
          externalDnsListPageAriaLabels.drawer.sections.managedRecords.table
        }
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              isError={isErrorFetchingManagedRecords}
              onRetry={refetchManagedRecords}
              onClear={searchTerm ? () => setSearchTerm("") : undefined}
            />
          ),
          noResultsOverlay: () => (
            <NoRowsOverlay
              isError={isErrorFetchingManagedRecords}
              onRetry={refetchManagedRecords}
              onClear={searchTerm ? () => setSearchTerm("") : undefined}
            />
          ),
          detailPanelExpandIcon: KeyboardArrowDownIcon,
          detailPanelCollapseIcon: KeyboardArrowUpIcon,
        }}
      />
    </Stack>
  );
};
