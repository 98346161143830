import { ResourceTabName } from "@/components/ResourceView/resources";
import { SELECTED_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { MinimizedSession } from "@/components/AiInvestigation/types";

export function getDrawerStateForMinimizedSession(
  session: MinimizedSession
): DrawerStatePush | undefined {
  switch (session.drawerType) {
    case DrawerType.EventDrawerById:
      return {
        drawerType: session.drawerType,
        eventIds: session.eventIds,
        eventType: session.eventType,
        serviceId: session.serviceId,
      };
    case DrawerType.ResourceDrawerByData:
      return {
        drawerType: DrawerType.ResourceDrawerByData,
        cluster: session.sessionIdentifier.cluster,
        namespace: session.sessionIdentifier.namespace,
        resourceType: session.sessionIdentifier.kind,
        resourceName: session.sessionIdentifier.name,
        urlStates: {
          [SELECTED_TAB_PARAM_KEY]: ResourceTabName.RootCauseAnalysis,
        },
      };
    case DrawerType.K8sAddonLiveStateDrawer:
      return {
        drawerType: session.drawerType,
        addon: session.addon,
        entity: session.entity,
        clusterName: session.clusterName,
        name: session.name,
        uid: session.uid,
      };
    case DrawerType.HelmReleaseDrawer:
      return {
        drawerType: session.drawerType,
        chartSummary: session.chartSummary,
      };
    default:
      return undefined;
  }
}
