import {
  DataGridPro,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";

import {
  ApiServerConfigClusterErrorResponse,
  RbacClusterStatus,
} from "@/generated/auth";
import {
  getColumns,
  columnsInitialSorting,
  columnsInitialState,
} from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/columns";
import { prepareRbacClusterStatusRowsForDataGrid } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/rows";
import { useActiveAgents } from "@/shared/hooks/useAgents";
import { ToolbarActionRbacClusterScheduleSyncForClusters } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/customToolbarActions";

export const RbacClusterStatusDataTable: React.FC<{
  isLoading: boolean;
  data?: RbacClusterStatus[];
  onApiServerUrlChange: (clusterName: string, newValue: string) => void;
  setHasValidationError(value: boolean): void;
  clustersThatFailedToUpdate: ApiServerConfigClusterErrorResponse[];
  pushToastMessage: (message: string) => void;
}> = ({
  isLoading,
  data,
  onApiServerUrlChange,
  setHasValidationError,
  clustersThatFailedToUpdate,
  pushToastMessage,
}) => {
  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const activeAgents = useActiveAgents();
  const preparedRows = useMemo(() => {
    return prepareRbacClusterStatusRowsForDataGrid(
      activeAgents,
      data,
      clustersThatFailedToUpdate
    ).filter((cluster) => cluster.clusterName !== "*" && cluster.isConnected);
    // We don't want to refresh everytime the agents are refreshed, it will cause user input to reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, clustersThatFailedToUpdate]);

  return (
    <DataGridPro
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{
        toolbar: {
          pushToastMessage,
          selectedClusters,
        },
      }}
      loading={isLoading}
      initialState={{
        columns: columnsInitialState,
        sorting: columnsInitialSorting,
      }}
      columns={getColumns({
        onApiServerUrlChange,
        setHasValidationError,
      })}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
        setSelectedClusters(
          preparedRows
            .map((row) => row.clusterName)
            .filter((clusterName) => newRowSelectionModel.includes(clusterName))
        );
      }}
      rowSelectionModel={rowSelectionModel}
      getRowId={(row) => row.clusterName}
      rows={preparedRows}
      rowReordering={false}
      hideFooter={true}
      checkboxSelection={true}
    />
  );
};

export const CustomToolbar: React.FC<{
  selectedClusters: string[];
  pushToastMessage: (message: string) => void;
}> = ({ selectedClusters, pushToastMessage }) => {
  return (
    <GridToolbarContainer>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        width="100%"
      >
        <GridToolbarQuickFilter />
        <Stack direction="row" columnGap="16px">
          <ToolbarActionRbacClusterScheduleSyncForClusters
            clustersToSync={selectedClusters}
            pushToastMessage={pushToastMessage}
          />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
};
