import React from "react";
import Link from "@mui/material/Link";

import { useResource } from "@/components/ResourceView/useResource";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { AriaLabels } from "@/shared/config/ariaLabels";

const KOMODOR_PROTOCOL = "komodor://";

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkTranslator: React.FC<AnchorProps> = (props) => {
  if (props.href?.startsWith(KOMODOR_PROTOCOL)) {
    return <KomodorLink {...props} />;
  }
  return <Link target="_blank" rel="noopener noreferrer" {...props} />;
};

const KomodorLink: React.FC<AnchorProps> = ({ href, children, ...props }) => {
  const komodorLinkData = (href ?? "")
    .replace(KOMODOR_PROTOCOL, "")
    .replaceAll("%7C", "|");
  const [resourceKomodorUid] = komodorLinkData.split("#");
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(resourceKomodorUid) ?? {};
  const agentId = useActiveAgent(cluster) ?? "";
  const { resource } = useResource({
    cluster,
    namespace,
    resourceType: kind,
    resourceName: name,
    agentId,
  });
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  if (!resource || typeof children !== "string") {
    return children;
  }

  const splittedTextByResourceName = children.split(name, 2);
  return splittedTextByResourceName.length < 2
    ? children
    : [
        splittedTextByResourceName[0],
        <Link
          {...props}
          key={name}
          aria-label={AriaLabels.KlaudiaChat.ChatKomodorLinkInsideMessage}
          onClick={() =>
            pushDrawer({
              drawerType: DrawerType.ResourceDrawerByData,
              cluster,
              namespace,
              resourceType: kind,
              resourceName: name,
            })
          }
        >
          {name}
        </Link>,
        splittedTextByResourceName[1],
      ];
};
