import { useCallback, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { generateSocketPayload } from "../utils/generateSocketPayload";
import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "../types/datadogReporting";
import { KOMODOR_LOADING_TIME } from "../constants/datadog";

import { isSocketReadyToSend } from "./isSocketReadyToSend";
import { SocketMessagePayload, SocketMessageType } from "./types";
import { extractPayloadFromMessage } from "./utils";

import { isCurrentBrowserTabActive } from "@/shared/utils/isCurrentBrowserTabActive";

type InitiatePodExecSessionParams = {
  connection: WebSocket;
  onInitialized: () => void;
  sessionId?: string;
  resolve: (value: void) => void;
  reject: () => void;
  initData?: Record<string, string | number>;
  messageType: SocketMessageType;
  onConnectionConfirmedByTheAgent?: () => void;
  onAck?: () => void;
};

const messageTypesToStartView: Partial<
  Record<SocketMessageType, DatadogViewNamesEnum>
> = {
  pod_logs_init: DatadogViewNamesEnum.podLogsTab,
  pod_exec_init: DatadogViewNamesEnum.podExecAction,
};

const reportIfInActiveBrowserTab = (fnName: string, fn: () => void) => {
  if (isCurrentBrowserTabActive()) {
    fn();
  } else {
    datadogRum.addAction(`skipped_${fnName}_for_hidden_tab`);
  }
};

export const useHandleConnectionOnOpen = (): ((
  props: InitiatePodExecSessionParams
) => void) => {
  const initDataRef = useRef<Record<string, string | number> | undefined>();

  return useCallback(
    ({
      connection,
      onInitialized,
      sessionId,
      resolve,
      reject,
      initData: data,
      messageType,
      onConnectionConfirmedByTheAgent,
      onAck,
    }: InitiatePodExecSessionParams) => {
      const msgToSend = generateSocketPayload({
        messageType,
        data,
      });

      connection.onopen = () => {
        const isReady = isSocketReadyToSend(connection);

        if (isReady) {
          resolve();
          const isInitDataChanged = initDataRef?.current !== data;
          if (!sessionId || isInitDataChanged) {
            onConnectionConfirmedByTheAgent &&
              onConnectionConfirmedByTheAgent();
            initDataRef.current = data;
            connection.send(JSON.stringify(msgToSend));

            reportIfInActiveBrowserTab("startView", () => {
              datadogRum.startView({
                name: messageTypesToStartView[messageType],
                context: {
                  feTeam: "operate",
                  beTeam: "operate",
                } as DatadogContext,
              });
            });
          }
        } else {
          reject();
        }

        onInitialized();
      };
      connection.onmessage = (message: MessageEvent<SocketMessagePayload>) => {
        const { messageType, data } = extractPayloadFromMessage(message);

        if (messageType === SocketMessageType.Ack) {
          if (data?.ackedMessageID === msgToSend.messageId) {
            onAck && onAck();

            datadogRum.addAction("received_ack");
            reportIfInActiveBrowserTab("addTiming", () => {
              datadogRum.addTiming(KOMODOR_LOADING_TIME);
            });
          }
        }
      };
    },
    []
  );
};
