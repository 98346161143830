import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

// Create an array for the left side actions list items
const leftSideItems = Array(7).fill(0);

// Create an array for the right side selected actions
const rightSideItems = Array(13).fill(0);

const CheckboxSkeleton = ({ marginLeft = 0 }: { marginLeft?: number }) => (
  <Skeleton
    variant="rectangular"
    width={16}
    height={16}
    sx={{ marginRight: 2, marginLeft }}
  />
);

export const ActionsPanelSkeleton: React.FC = () => {
  return (
    <Stack
      direction="row"
      columnGap="40px"
      aria-label="Actions panel loading skeleton"
    >
      {/* Left side - Actions list */}
      <Stack sx={{ flex: "1 1 0px" }}>
        <Box sx={{ padding: "8px" }}>
          <Typography variant="h5" sx={{ marginBottom: "16px" }}>
            <Skeleton width="40%" />
          </Typography>

          <Box sx={{ marginBottom: 2 }}>
            <Skeleton
              variant="rectangular"
              height={30}
              sx={{ borderRadius: 1 }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <Skeleton
              variant="rectangular"
              width={16}
              height={16}
              sx={{ marginRight: 2 }}
            />
            <Skeleton width="60%" />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <CheckboxSkeleton />
            <Skeleton width="70%" />
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <CheckboxSkeleton />
            <Skeleton width="80%" />
          </Box>

          {leftSideItems.map((_, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <CheckboxSkeleton marginLeft={3} />
              <Skeleton width={`${Math.floor(Math.random() * 30) + 60}%`} />
            </Box>
          ))}
        </Box>
      </Stack>

      {/* Right side - Selected Actions */}
      <Stack sx={{ flex: "1 1 0px" }}>
        <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 1 }}>
          <Skeleton width="60%" />
        </Typography>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{ padding: 2, borderRadius: 2 }}
        >
          <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
            {rightSideItems.map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={Math.floor(Math.random() * (160 - 100 + 1)) + 100}
                height={20}
                sx={{ borderRadius: 4, marginBottom: 1 }}
              />
            ))}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};
