import { ActionType } from "@/generated/actionsApi";
import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const MAX_BATCH_ACTION_ITEMS = 50;

export const AvailableActionsToBatchApiActionsMap: Partial<
  Record<AvailableActions, ActionType>
> = {
  [AvailableActions.Delete]: ActionType.DeleteResource,
  [AvailableActions.Scale]: ActionType.Scale,
  [AvailableActions.Restart]: ActionType.RestartService,
};

export const batchApiActionsToLabel: Record<ActionType, string> = {
  [ActionType.DeleteResource]: "Delete",
  [ActionType.Scale]: "Scale",
  [ActionType.RestartService]: "Restart",
  [ActionType.ArgoRolloutRestart]: "Restart",
};
