import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";

import { GetBulkActionsResultsResp } from "@/generated/actionsApi";
import { useAppNotificationsStore } from "@/shared/store/appNotificationsStore/appNotificationsStore";
import {
  selectAddNotification,
  selectUpdateNotification,
} from "@/shared/store/appNotificationsStore/appNotificationsStoreSelectors";
import {
  Notification,
  NotificationStatus,
} from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { useFindNotificationById } from "@/shared/store/appNotificationsStore/appNotificationsStoreHooks";
import { batchApiActionsToLabel } from "@/components/common/ResourceListTable/batchActions/batchActionsConstants";
import { useGetBatchActionsResults } from "@/shared/hooks/actions-api/useGetBatchActionsResults";
import { useExecuteSuccessCbOnResponseComplete } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsResponsePoller/BatchActionsResponsePollerHooks";
import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { setUpdateBatchActionsResponseSelector } from "@/shared/store/batchActionsStore/batchActionsSelectors";
import {
  useOpenBatchActionsDialog,
  useProcessActions,
} from "@/components/common/ResourceListTable/batchActions/components/BatchActionsResponsePoller/batchActionsPollerHooks";
import { getActionCompleteMessage } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsResponsePoller/batchActionsPollerUtils";

const REFETCH_INTERVAL = 500;

type BatchActionPollerItemProps = {
  bulkActionRes: GetBulkActionsResultsResp;
  onClose: () => void;
};

export const BatchActionPollerItem: React.FC<BatchActionPollerItemProps> = ({
  bulkActionRes,
  onClose,
}) => {
  const addAppNotification = useAppNotificationsStore(selectAddNotification);
  const updateAppNotification = useAppNotificationsStore(
    selectUpdateNotification
  );
  const updateBatchAction = useBatchActionsStore(
    setUpdateBatchActionsResponseSelector
  );
  const existingNotification = useFindNotificationById(bulkActionRes.bulkId);
  const notificationWasAdded = useRef(false);

  const [hasError, setHasError] = useState(false);
  const [isInProgress, setIsInProgress] = useState(bulkActionRes.isInProgress);

  const actionType =
    batchApiActionsToLabel[bulkActionRes.actions[0].type].toLowerCase();
  const resource = bulkActionRes.actions[0].resource.kind.toLowerCase();

  const refetchInterval =
    isInProgress && !hasError ? REFETCH_INTERVAL : undefined;

  const { data, error } = useGetBatchActionsResults(
    { bulkId: bulkActionRes.bulkId },
    { refetchInterval }
  );

  useEffect(() => {
    if (data?.data) {
      updateBatchAction(data.data);
    }
  }, [data?.data, updateBatchAction]);

  const { totalActions, inProgressCount, failuresCount } = useProcessActions(
    data?.data.actions ?? bulkActionRes.actions
  );

  const openBatchActionsDialog = useOpenBatchActionsDialog(bulkActionRes);

  const updatedNotification = useMemo((): Notification => {
    return {
      status: NotificationStatus.Loading,
      title: `Batch ${actionType}`,
      message: `Batch ${actionType} in progress (${
        totalActions - inProgressCount
      } / ${totalActions})`,
      id: bulkActionRes.bulkId,
      onClose,
    };
  }, [
    actionType,
    inProgressCount,
    totalActions,
    bulkActionRes.bulkId,
    onClose,
  ]);

  useExecuteSuccessCbOnResponseComplete(bulkActionRes.bulkId, !isInProgress);

  useEffect(() => {
    setIsInProgress(data?.data.isInProgress ?? true);
  }, [data?.data.isInProgress]);

  useEffect(() => {
    if (!existingNotification && !notificationWasAdded.current) {
      notificationWasAdded.current = true;
      addAppNotification(updatedNotification);
      return;
    }

    if (error && !data?.data) {
      updateAppNotification({
        id: bulkActionRes.bulkId,
        status: NotificationStatus.Error,
        message: "Batch action failed",
      });
      setHasError(true);
      return;
    }

    if (!data?.data) return;
    const hasDataChanged = !isEqual(data.data, bulkActionRes);
    if (!hasDataChanged) return;

    const isComplete = data.data.isInProgress === false;
    const hasErrorInAction = failuresCount > 0;
    if (!isComplete) {
      updateAppNotification(updatedNotification);
      return;
    }

    const status = hasErrorInAction
      ? NotificationStatus.Error
      : NotificationStatus.Success;
    const message = getActionCompleteMessage({
      actionType,
      resource,
      failuresCount,
      hasErrorInAction,
    });

    updateAppNotification({
      ...updatedNotification,
      ...(failuresCount > 0 && { onClick: openBatchActionsDialog }),
      status,
      message,
    });
  }, [
    failuresCount,
    actionType,
    bulkActionRes,
    data?.data,
    error,
    updatedNotification,
    resource,
    updateAppNotification,
    openBatchActionsDialog,
    existingNotification,
    addAppNotification,
  ]);

  return null;
};
