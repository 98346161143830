// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useMemo } from "react";
import {
  Path,
  QueryActions,
  QueryBuilderContextProps,
  ValidationResult,
  UseRule,
} from "react-querybuilder";

import { ErrorInputAdornment } from "../ErrorInputAdornment/ErrorInputAdornment";
import { TextInput } from "../TextInput/TextInput";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  SingleSelect,
} from "../Select";
import { ExtendedOperator } from "./types";

import { EditorTypes } from "./constants";

type CustomValueProps = {
  fieldData: UseRule["fieldData"];
  operator: string;
  operators: ExtendedOperator[];
  actions: QueryActions;
  path: Path;
  context: QueryBuilderContextProps<string, string>;
  value?: string;
  error?: ValidationResult;
};

export const CustomValue = (props: CustomValueProps) => {
  const { fieldData, operator, operators, actions, value, error } = props;
  const { values } = fieldData;

  const operatorData = useMemo(
    () => operators.find((op) => op.name === operator) ?? {},
    [operators, operator]
  );

  const selectedValue = useMemo(() => {
    if (operatorData?.editorType === "multiselect") {
      return values?.filter((option) => value?.includes(option.value));
    }
    return values?.find((option) => option.value === value);
  }, [values, value, operatorData]);

  const handleChange = useCallback(
    (newValue: string) => {
      actions.onPropChange("value", newValue, props.path, props.context);
    },
    [actions]
  );

  const onHandleChange = useCallback(
    (selected: string | null) => {
      handleChange(selected ?? "");
    },
    [handleChange]
  );

  const onHandleChangeOption = useCallback(
    (selected: MuiSelectionOption<string> | null) => {
      handleChange(selected?.value ?? "");
    },
    [handleChange]
  );

  const onHandleMultiChange = useCallback(
    (selected: MuiSelectionOption<string>[] | null) => {
      const newValues = selected.filter((s) => !values.includes(s));
      fieldData.onCreateNewOptions?.(newValues);
      handleChange(selected?.map((s) => s.value).join(",") ?? "");
    },
    [handleChange, fieldData.onCreateNewOptions, values]
  );

  switch (operatorData?.editorType) {
    case EditorTypes.SELECT:
      return (
        <SingleSelect
          options={values}
          value={selectedValue ?? null}
          onChange={onHandleChangeOption}
          width="fit-content"
          placeholder={operatorData.placeholder}
          selectPopoverProps={{
            ...{
              width: "max-content",
              maxHeight: "500px",
              height: "max-content",
            },
            ...fieldData.selectPopoverProps,
          }}
          ariaLabel={`${operatorData.editorType} value`}
          customOptionElement={fieldData.customOptionElement}
          error={error?.reasons[0]}
          isInlineError
          disableBlankOption
          menuListTrailingElement={fieldData.menuListTrailingElement}
          enableFreeSolo={fieldData.enableFreeSolo}
        />
      );
    case EditorTypes.MULTISELECT:
      return (
        <AdvancedMultiSelect
          options={values}
          value={selectedValue}
          placeholder={operatorData.placeholder}
          onChange={onHandleMultiChange}
          width="200px"
          selectPopoverProps={{
            ...{
              width: "max-content",
              maxHeight: "500px",
              height: "max-content",
            },
            ...fieldData.selectPopoverProps,
          }}
          ariaLabel={`${operatorData.editorType} value`}
          error={error?.reasons?.[0]}
          isInlineError
          enableFreeSolo={fieldData.enableFreeSolo}
        />
      );
    default:
      return (
        <TextInput
          value={props.value}
          onChange={(e) => onHandleChange(e.target.value)}
          placeholder={operatorData.placeholder}
          aria-label={`${operatorData.editorType} value`}
          error={error?.valid === false}
          InputProps={{
            endAdornment: error?.valid === false && (
              <ErrorInputAdornment tooltipText={error?.reasons?.[0]} />
            ),
          }}
        />
      );
  }
};
