import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import {
  DataGrid,
  getInitialPageConfig,
} from "@komodorio/design-system/komodor-ui";
import { useCallback, useMemo } from "react";

import { Title } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import {
  getImpactedServicesColumns,
  ImpactedServicesDataRow,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/unscheduledPodsImpactedTableBuilder";
import { UnschedulablePodsSupportingData } from "@/generated/reliabilityApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useOpenResourceDrawer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/hooks/useOpenResourceDrawer";
import { getInitialTimeWindow } from "@/components/appView/sections/AppView/utils/timeWindow";
import { Timeframe } from "@/shared/types/TimeWindow";
import { parseTimeWindowDataToUrlData } from "@/components/ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { unscheduledPodsAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/unscheduledPodsAriaLabels";
import { VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE } from "@/components/reliability/components/pages/violations/violationsConstants";

const HelpIcon = styled(HelpOutlineOutlined)`
  margin-left: 6px;
  vertical-align: middle;
`;

const ImpactedSectionTitle = styled(Title)`
  && {
    margin-bottom: 8px;
  }
`;

const TITLE_TOOLTIP_CONTENT = "impact on effected services in the last 7 days";

type UnschedulablePodsImpactedTableProps = {
  supportingData: UnschedulablePodsSupportingData;
};

export const UnschedulablePodsImpactedTable: React.FC<
  UnschedulablePodsImpactedTableProps
> = ({ supportingData }) => {
  const timeWindow = getInitialTimeWindow(Timeframe.Last7Days);
  const { urlTimeWindow, urlTimeframe } =
    parseTimeWindowDataToUrlData(timeWindow);
  const openResourceDrawer = useOpenResourceDrawer();
  const rows: ImpactedServicesDataRow[] = useMemo(
    () =>
      supportingData.topImpactedServices
        .map((service) => {
          const { name = "" } = parseKomodorUid(service.komodorUid) ?? {};
          return {
            name,
            komodorUid: service.komodorUid,
            pendingPods: service.unschedulablePodCount,
            avgUnschedulableTime: service.avgUnscheduledTimeSeconds,
            availabilityIssues: service.availabilityIssueCount,
          };
        })
        .filter((service) => service.name !== ""),
    [supportingData.topImpactedServices]
  );

  const onOpenResourceClicked = useCallback(
    (komodorUid: string) => {
      openResourceDrawer({
        komodorUid,
        urlTimeframe,
        urlTimeWindow,
      });
    },
    [openResourceDrawer, urlTimeWindow, urlTimeframe]
  );
  return (
    <>
      <ImpactedSectionTitle>
        Top impacted services
        <Tooltip title={TITLE_TOOLTIP_CONTENT} placement="top">
          <HelpIcon fontSize="small" color="action" />
        </Tooltip>
      </ImpactedSectionTitle>
      <DataGrid
        aria-label={unscheduledPodsAriaLabels.table}
        columns={getImpactedServicesColumns(onOpenResourceClicked)}
        getRowId={(row) => row.name}
        rows={rows}
        {...getInitialPageConfig(VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE, {
          sorting: { sortModel: [{ field: "pendingPods", sort: "asc" }] },
        })}
        renderFalsyValues
        density="compact"
      ></DataGrid>
    </>
  );
};
