import parse from "parse-duration";
import { ResourceTableModelRow } from "komodor-types/build/entities/AgentTask";

import { Direction } from "../../common/ProcessList/SortTitle";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";

import {
  extractQuantity,
  getQuantityInBytes,
} from "@/shared/utils/formatQuantity";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default (
  headers: string[],
  sortDirection: Direction,
  sortColumnIndex: number,
  resource: KubernetesResource
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ((a: string[], b: string[]) => number) => {
  return (a, b) => {
    const column = headers[sortColumnIndex].toLowerCase();
    return sort(
      sortDirection,
      column,
      resource,
      a[sortColumnIndex],
      b[sortColumnIndex]
    );
  };
};

export const sortTableModel = (
  sortDirection: Direction,
  column: string,
  resource: KubernetesResource
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ((a: ResourceTableModelRow, b: ResourceTableModelRow) => number) => {
  return (a, b) =>
    sort(sortDirection, column, resource, a[column] ?? "", b[column] ?? "");
};

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function sort(
  direction: Direction,
  column: string,
  resource: KubernetesResource,
  v1: string,
  v2: string
) {
  if (resource?.CustomSortOrder?.[column]) {
    const order = resource.CustomSortOrder[column];
    if (order[v1] === undefined) {
      order[v1] = Math.max.apply(null, Object.values(order)) + 1;
    }
    if (order[v2] === undefined) {
      order[v2] = Math.max.apply(null, Object.values(order)) + 1;
    }
    const customOrderResult = direction * (order[v1] - order[v2]);
    if (customOrderResult === 0) {
      return sortDefault(direction, v1, v2);
    }
    return customOrderResult;
  }
  if (column.toLowerCase() === "age" || column.toLowerCase() === "duration") {
    return sortAge(direction, v1, v2);
  }

  if (column.toLowerCase() === "capacity") {
    return sortCapacity(direction, v1, v2);
  }
  return sortDefault(direction, v1, v2);
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function sortDefault(d: Direction, v1: string, v2: string) {
  return d * (+v1 && +v2 ? +v1 - +v2 : v1?.localeCompare(v2));
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
function sortAge(d: Direction, v1: string, v2: string) {
  return d * ((parse(v2) ?? 0) - (parse(v1) ?? 0));
}

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export function sortCapacity(d: Direction, v1: string, v2: string) {
  const valueInBytes1 = getQuantityInBytes(extractQuantity(v1));
  const valueInBytes2 = getQuantityInBytes(extractQuantity(v2));
  return d * (valueInBytes1 - valueInBytes2);
}
