import InfoRounded from "@mui/icons-material/InfoRounded";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type CategoryNumberItemProps = {
  title: string;
  value: number | string;
  valueAnnotation?: string;
  tooltip?: {
    title: string;
    ariaLabel?: string;
  };
};

export const CountedDataItem: React.FC<CategoryNumberItemProps> = ({
  value,
  title,
  tooltip,
  valueAnnotation,
}) => {
  const { title: tooltipTitle, ariaLabel: tooltipAriaLabel } = tooltip || {};
  return (
    <div>
      <Stack alignItems={"baseline"} direction={"row"} spacing={1}>
        <Typography variant={"h4"}>{value}</Typography>
        {valueAnnotation && (
          <Typography variant={"body2"} color={"text.primary"}>
            {valueAnnotation}
          </Typography>
        )}
      </Stack>
      <Stack>
        <Typography variant={"body2"} color={"text.secondary"}>
          {title}
        </Typography>
        {tooltipTitle && (
          <Tooltip title={tooltipTitle} placement={"top"}>
            <InfoRounded
              color={"action"}
              fontSize={"small"}
              aria-label={tooltipAriaLabel}
            />
          </Tooltip>
        )}
      </Stack>
    </div>
  );
};
