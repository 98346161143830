import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Button from "@mui/material/Button";
import { Command, CommandPopper } from "@komodorio/design-system/komodor-ui";
import { KomobotEmptyState } from "@komodorio/design-system/assets";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import differenceInHours from "date-fns/differenceInHours";
import { useDebounce } from "use-debounce";

import { useAppViewsStore } from "../../../shared/store/appViewsStore/appViewsStore";
import {
  selectedAppViewIdSelector,
  setSelectedAppViewIdSelector,
} from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";

import { isWorkspaceItemMapping, useItemMappings } from "./components/Items";
import { AllWorkspacesToggle } from "./AllWorkspacesToggle";
import { getLabelTextByWorkspace } from "./utils";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import {
  CommandPopperContentQueryParams,
  useSearchAPIGet,
} from "@/shared/hooks/search-api/client";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type SerializedItem = {
  kind: string;
  id: string;
  isDeleted: boolean;
  deletedAt?: string;
};

const LinkButton = styled(Button)`
  padding: 0;
  && {
    font-weight: normal;
    &:hover {
      background: none;
    }
  }
`;
const DEBOUNCE_TIME_MS = 100;

export const DefaultCommandKPlaceholder = "Find a workload, page, workspace...";

interface CommandPopperContentProps {
  setIsOpen: (isOpen: boolean) => void;
  query: string;
  setQuery: (query: string) => void;
  searchInAllWorkspaces: boolean;
  setSearchInAllWorkspaces: Dispatch<SetStateAction<boolean>>;
  queryParams?: CommandPopperContentQueryParams;
  placeholder?: string;
  forceWorkspacesToggle?: boolean;
}
export const CommandPopperContent: React.FC<CommandPopperContentProps> = ({
  setIsOpen,
  query,
  setQuery,
  searchInAllWorkspaces,
  setSearchInAllWorkspaces,
  queryParams,
  placeholder,
  forceWorkspacesToggle,
}) => {
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const { isClusterWorkspace, isClustersGroupWorkspace } = useWorkspaces();
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);

  const onClose = useCallback(() => {
    dispatchEvent(AnalyticEvents.GlobalSearch.Closed);
    setIsOpen(false);
  }, [setIsOpen]);

  const ITEM_MAPPINGS = useItemMappings(onClose);

  const [debouncedQuery] = useDebounce(query, DEBOUNCE_TIME_MS, {
    leading: false,
    trailing: true,
  });

  const toggleSearchInAllWorkspaces = useCallback(() => {
    setSearchInAllWorkspaces((val) => !val);
  }, [setSearchInAllWorkspaces]);

  const updatedQueryParams = useMemo(() => {
    if (!queryParams) {
      return undefined;
    }
    if (searchInAllWorkspaces) {
      return {
        ...queryParams,
        cluster: undefined,
        namespace: undefined,
      };
    }
    return queryParams;
  }, [queryParams, searchInAllWorkspaces]);

  const { data, loading } = useSearchAPIGet({
    query: debouncedQuery,
    workspaceId: selectedAppViewId,
    options: {
      pause: debouncedQuery === "" || query != debouncedQuery,
      staleTime: DEBOUNCE_TIME_MS,
      skipAppViewInHeaders: searchInAllWorkspaces,
      skipWorkspaceHeader: searchInAllWorkspaces,
    },
    queryParams: updatedQueryParams,
  });

  const onSelect = useCallback(
    (value: string) => {
      const { kind, id, isDeleted, deletedAt } = JSON.parse(
        value
      ) as SerializedItem;
      const itemIndex = data?.results?.findIndex((it) => it.id === id);
      if (itemIndex === undefined || itemIndex < 0) {
        return;
      }

      dispatchEvent(AnalyticEvents.GlobalSearch.SelectedItem, {
        query,
        id,
        kind,
        index: itemIndex,
        isDeleted,
        deletedBeforeHours: deletedAt
          ? differenceInHours(new Date(), new Date(deletedAt))
          : undefined,
      });

      const item = data?.results?.[itemIndex];

      let itemRenderer = ITEM_MAPPINGS[kind];
      itemRenderer = isWorkspaceItemMapping(itemRenderer, kind)
        ? itemRenderer(setSelectedAppViewId)
        : itemRenderer;
      if (!item || !itemRenderer?.onClick) {
        return;
      }

      if (searchInAllWorkspaces) {
        setSelectedAppViewId(undefined);
      }
      itemRenderer.onClick(item);
    },
    [
      data?.results,
      query,
      ITEM_MAPPINGS,
      setSelectedAppViewId,
      searchInAllWorkspaces,
    ]
  );

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("searchData", loading),
    [loading, reportLoadingState]
  );

  const items = useMemo(() => {
    return data?.results?.map((result) => {
      const kind = result.kind.toLowerCase();
      const item = ITEM_MAPPINGS[kind];
      if (!item) {
        return null;
      }
      const isWorkspaceItem = isWorkspaceItemMapping(item, kind);
      const itemRenderer = isWorkspaceItem ? item(setSelectedAppViewId) : item;
      const content = itemRenderer.content(result);
      const deslugify = (kind: string) => kind.replace(/-/g, " ");
      return content ? (
        <Command.Item
          key={result.id}
          kind={deslugify(result.kind)}
          value={JSON.stringify({
            deletedAt: result.isDeleted ? result.deletedAt : undefined,
            isDeleted: result.isDeleted,
            kind: result.kind.toLowerCase(),
            id: result.id,
          } as SerializedItem)}
          icon={
            typeof itemRenderer.icon === "function"
              ? itemRenderer.icon(result)
              : itemRenderer.icon
          }
          enterKeyText={isWorkspaceItem ? "Switch to" : "Enter"}
        >
          {content}
        </Command.Item>
      ) : null;
    });
  }, [ITEM_MAPPINGS, data?.results, setSelectedAppViewId]);

  return (
    <CommandPopper
      query={query}
      onQueryChange={setQuery}
      onSelect={onSelect}
      onClose={onClose}
      placeholder={placeholder ?? DefaultCommandKPlaceholder}
    >
      {query !== "" && (
        <AllWorkspacesToggle
          searchInAllWorkspaces={searchInAllWorkspaces}
          onClick={toggleSearchInAllWorkspaces}
          overrideClusterName={
            forceWorkspacesToggle ? queryParams?.cluster : undefined
          }
        />
      )}
      <Command.Results loading={loading && query !== ""}>
        <Command.Empty>
          <KomobotEmptyState />
          <Typography variant="h4">No results</Typography>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="body2" color="textSecondary">
              Check your spelling and try again
            </Typography>
            {selectedAppViewId != null && (
              <LinkButton
                sx={{ padding: 0, background: "none" }}
                variant="text"
                onClick={() => setSearchInAllWorkspaces(true)}
              >
                <Typography variant="body2">
                  Change search scope to{" "}
                  {getLabelTextByWorkspace(
                    isClusterWorkspace || isClustersGroupWorkspace
                  )}
                </Typography>
              </LinkButton>
            )}
          </Box>
        </Command.Empty>
        {query !== "" ? items : null}
      </Command.Results>
    </CommandPopper>
  );
};
export const CommandBarContent: React.FC<CommandPopperContentProps> = (
  props
) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.searchBar,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
        },
      }}
    >
      <CommandPopperContent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
