import { HelmChartStatusEnum } from "@/generated/addonsApi";
import { SeverityColor } from "@/components/k8sAddons/types";

export const DEFAULT_HELM_SORT_BY_FIELD = "status";
export const DEFAULT_HELM_SORT_BY_DIRECTION = "desc";
export const SEARCH_TABLE_PLACEHOLDER = "Search chart by name";
export const REFETCH_INTERVAL_MS = 900000; // 15 minutes
export const HELM_UPDATED_AT_DATE_FORMAT = "yyyy-MM-dd, hh:mm:ss a";

export const helmChartStatusToColor: Record<
  HelmChartStatusEnum,
  SeverityColor
> = {
  [HelmChartStatusEnum.Deployed]: "success",
  [HelmChartStatusEnum.Failed]: "error",
  [HelmChartStatusEnum.PendingInstall]: "warning",
  [HelmChartStatusEnum.PendingRollback]: "warning",
  [HelmChartStatusEnum.PendingUpgrade]: "warning",
  [HelmChartStatusEnum.Superseded]: "info",
  [HelmChartStatusEnum.Uninstalled]: "info",
  [HelmChartStatusEnum.Uninstalling]: "info",
  [HelmChartStatusEnum.Unknown]: "info",
};
