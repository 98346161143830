import React from "react";
import { ActionItem, Button } from "@komodorio/design-system/deprecated";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import Resource from "../../ResourceView/resources";
import { ButtonProps } from "../buttons/types";

import {
  actionsMapping,
  ButtonStylesProps,
  cordonUncordonStyles,
} from "./actionsStyleMap";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { getIsNodeUnschedulable } from "@/components/Actions/common/utils";

export const getButton = (
  type: AvailableActions,
  forceDisable = false,
  resource?: Resource,
  overrideStyles?: ButtonStylesProps
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const ButtonStyle: React.FC<ButtonProps> = ({
    onClick,
    disabled = false,
    ariaLabel,
  }) => {
    const isNodeUnschedulable = getIsNodeUnschedulable(resource);

    const styles = overrideStyles
      ? overrideStyles
      : type === AvailableActions.CordonUncordon
      ? cordonUncordonStyles(isNodeUnschedulable ?? false)
      : actionsMapping[type].style;

    return (
      <Button
        variant={styles.buttonVariant}
        icon={styles.icon}
        onClick={onClick}
        disabled={forceDisable || disabled || styles.disabled}
        aria-label={
          ariaLabel ??
          `${AriaLabels.ResourceView.Header.ActionButtonPrefix}${type}`
        }
      >
        {styles.text}
      </Button>
    );
  };

  return ButtonStyle;
};

export const getActionItem = (
  type: AvailableActions,
  forceDisable = false,
  resource?: Resource
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): React.FC<{ onClick: () => void }> => {
  const ButtonStyle: React.FC<{
    onClick: () => void;
    disabled?: boolean;
    ariaLabel?: string;
  }> = ({ onClick, disabled = false, ariaLabel }) => {
    const isNodeUnschedulable = getIsNodeUnschedulable(resource);

    const styles =
      type === AvailableActions.CordonUncordon
        ? cordonUncordonStyles(isNodeUnschedulable ?? false)
        : actionsMapping[type].style;

    return (
      <ActionItem
        icon={styles.icon}
        onClick={onClick}
        variant={styles.actionItemVariant}
        disabled={forceDisable || disabled || styles.disabled}
        aria-label={
          ariaLabel ??
          `${AriaLabels.ResourceView.Header.ActionButtonPrefix}${type}`
        }
      >
        {styles.text}
      </ActionItem>
    );
  };

  return ButtonStyle;
};
