import React, { ComponentProps } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Warning from "@mui/icons-material/Warning";
import { tooltipClasses } from "@mui/material";

import { LightTooltip } from "../LightTooltip/LightTooltip";
import { muiTheme } from "../../../themes";

export const ErrorInputAdornment = ({
  tooltipText,
  tooltipPlacement = "top",
}: {
  tooltipText: string;
  tooltipPlacement?: ComponentProps<typeof LightTooltip>["placement"];
}) => {
  return (
    <InputAdornment position="end">
      <LightTooltip
        placement={tooltipPlacement}
        title={tooltipText}
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]: {
                color: `${muiTheme.palette.error.dark}`,
                backgroundColor: muiTheme.palette.common.white,
                boxShadow: muiTheme.shadows[1],
              },
            },
          },
        }}
      >
        <Box sx={{ lineHeight: 0, cursor: "default" }}>
          <Warning color="error" />
        </Box>
      </LightTooltip>
    </InputAdornment>
  );
};
