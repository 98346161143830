import React from "react";

import { LogoIconProps } from "../types";

export const VCluster: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="VCluster logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9302 14.4402L12.4854 10.2033L17.0591 2.18359L21.9078 2.20465L14.9302 14.4402ZM11.642 7.50596L14.0447 3.23754H4.99071C2.79861 3.23754 1.42887 5.59807 2.50387 7.50596H11.6407H11.642ZM9.75518 9.61386L4.9065 9.63491L11.8841 21.881L14.3289 17.6336L9.75518 9.61386Z"
        fill="#F27405"
      />
    </svg>
  );
};
