import React, { useState } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const Container = styled.div<{
  padding?: string;
  borderColor?: string;
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding ?? "16px"};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  & > div:first-child {
    flex-grow: 1;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TextContianer = styled.div`
  display: flex;
  flex-direction: column;
`;

type AlertProps = {
  severity: "warning" | "info";
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  ctaButton?: React.ReactNode;
  ariaLabel?: string;
  padding?: string;
  showCloseButton?: boolean;
  className?: string;
};

type ColorScheme = {
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
};

const colorScheme: Record<AlertProps["severity"], ColorScheme> = {
  warning: {
    textColor: muiColors.orange[700],
    backgroundColor: muiColors.orange[50],
    borderColor: muiColors.orange[200],
  },
  info: {
    textColor: muiColors.blue[600],
    backgroundColor: muiColors.blue[50],
    borderColor: muiColors.blue[300],
  },
};

export const Alert: React.FC<AlertProps> = ({
  severity,
  title,
  text,
  icon,
  ctaButton,
  ariaLabel,
  padding,
  className,
  showCloseButton = false,
}) => {
  const [showAlert, setShowAlert] = useState(true);

  const { textColor, backgroundColor, borderColor } = colorScheme[severity];

  const Icon = icon;

  if (!showAlert) return null;
  return (
    <Container
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      aria-label={ariaLabel}
      padding={padding}
      className={className}
    >
      <ContentContainer>
        <Icon style={{ color: textColor }} />
        <TextContianer>
          {title && (
            <Typography variant={"subtitle2"} color={textColor}>
              {title}
            </Typography>
          )}
          <Typography variant={"body2"} color={textColor}>
            {text}
          </Typography>
        </TextContianer>
      </ContentContainer>
      {ctaButton}
      {(severity === "info" || showCloseButton) && (
        <IconButton onClick={() => setShowAlert(false)}>
          <CloseOutlined />
        </IconButton>
      )}
    </Container>
  );
};
