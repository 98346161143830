import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  HelmComparisonRequest,
  HelmComparisonResponse,
  apiV1DriftComparisonHelmPostUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspacesApiClient } from "../apiClient";
import { useWorkspaceQueryKey } from "../useWorkspaceQueryKey";

export const DRIFT_HELM_COMPARISON_PATH = "drift/comparison/helm";

const fetchHelmDriftComparison = async (
  apiClient: AxiosInstance,
  params: HelmComparisonRequest
): Promise<HelmComparisonResponse> => {
  const { data } = await apiClient.post<HelmComparisonResponse>(
    apiV1DriftComparisonHelmPostUrl({}, apiClient.defaults.baseURL ?? ""),
    params
  );

  return data;
};

export const useHelmDriftComparison = (
  params: HelmComparisonRequest,
  options?: {
    enabled?: boolean;
  }
) => {
  const apiClient = useWorkspacesApiClient();
  const keys = useWorkspaceQueryKey([DRIFT_HELM_COMPARISON_PATH, params]);
  return useQuery(
    keys,
    () => fetchHelmDriftComparison(apiClient, params),
    options
  );
};
