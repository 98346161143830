import styled from "styled-components";
import Box from "@mui/material/Box";

type StyledProps = {
  $clear: boolean;
  $borderColor?: string;
  color: string;
};

export const ColorIndication = styled(Box)<StyledProps>`
  height: 10px;
  width: 10px;
  border: 2px solid ${(props) => props.color};
  ${({ $clear, color }) => !$clear && `background-color: ${color};`};
`;
