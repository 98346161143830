import styled from "styled-components";
import Accordion from "@mui/material/Accordion";

import { muiTheme } from "../../../themes";

export const StyledAccordion = styled(Accordion).attrs({
  disableGutters: true,
  elevation: 0,
  square: true,
})`
  border-bottom: 0 solid ${muiTheme.palette.divider};

  // Hide the default border/divider
  &::before {
    display: none;
  }

  .MuiAccordion-root & > .MuiAccordion-region .MuiAccordion-root:last-of-type {
    padding-bottom: 8px;
  }
`;
