import { useCallback, useEffect, useMemo, useRef } from "react";

import { useSearchHpaEvents } from "../../../../../../../../../../shared/hooks/resources-api/client/events/hpaEvents/useHpaEvents";
import { getEpochsFromLastEvaluatedAt } from "../../../../violationDrawerUtils";
import { notifyDDError } from "../../../../../../../../../../shared/hooks/exceptionManagement";
import {
  AverageUtilizationMetricType,
  basicRequestParams,
  NewSpec,
  ScalingMetric,
} from "../hpaReachedMaxTypes";
import { Dictionary } from "../../../../../../../../../../shared/types/Dictionary";

import { useSetServiceUidFromHpaData } from "./useServiceUidFromHpaData";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export type HpaDataOutput = {
  hpaData?: {
    hpaName: string;
    minReplicas?: number;
    maxReplicas?: number;
    scalingMetrics: ScalingMetric[];
  };
  errorState: "error" | "noData" | undefined;
  loading: boolean;
};

export const useHpaData = (
  hpaUid: string | undefined,
  lastEvaluatedAt: number
): HpaDataOutput => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { toEpoch, fromEpoch } = getEpochsFromLastEvaluatedAt(
    lastEvaluatedAt,
    "milliseconds"
  );
  const sentErrors = useRef<Dictionary<unknown>>({});

  const {
    data: hpaEventsData,
    isFetching,
    error,
  } = useSearchHpaEvents({
    enabled: !!hpaUid,
    komodorUids: [hpaUid ?? ""],
    ...basicRequestParams,
    fields: ["newSpec", "services", "id"],
    fromEpoch: fromEpoch.toString(),
    toEpoch: toEpoch.toString(),
  });

  useEffect(() => {
    error && notifyDDError(error as Error);
  }, [error]);

  useEffect(() => {
    reportLoadingState("hpaData", isFetching);
  }, [error, isFetching, reportLoadingState]);

  const newSpec = hpaEventsData?.data?.[0]?.newSpec as NewSpec;
  const scaleTargetRef = newSpec?.spec?.scaleTargetRef;

  useSetServiceUidFromHpaData({
    hpaUid,
    scaleTargetRef,
  });

  const notifyError = useCallback((error: unknown) => {
    const errorAsString = JSON.stringify(error);
    if (sentErrors.current[errorAsString]) return;
    sentErrors.current[errorAsString] = true;
    notifyDDError(new Error(`Unknown spec metric: ${JSON.stringify(error)}}`), {
      action: "HPA Reached Max Drawer",
    });
  }, []);

  return useMemo(() => {
    if (error) {
      return {
        errorState: "error",
        loading: isFetching,
      };
    }
    if (hpaEventsData?.data?.length === 0) {
      return {
        errorState: "noData",
        loading: isFetching,
      };
    }

    const hpaName = newSpec?.metadata?.name || "N/A";

    const scalingMetrics = (newSpec?.spec?.metrics || [])
      .filter(({ resource }) => {
        return resource?.name === "cpu" || resource?.name === "memory";
      })
      .sort((a) => {
        if (a.resource.name === "cpu") return -1;
        return 0;
      })
      .map((metric) => {
        const { averageUtilization, averageValue } = metric.resource.target;

        if (!averageUtilization && !averageValue) {
          notifyError(metric.resource.target);
        }

        const metricType: AverageUtilizationMetricType = averageUtilization
          ? "percentage"
          : "unit";

        return {
          resourceType: metric.resource.name,
          metricType,
          value: averageUtilization ?? averageValue ?? 0,
        };
      });

    return {
      hpaData: {
        minReplicas: newSpec?.spec?.minReplicas,
        maxReplicas: newSpec?.spec?.maxReplicas,
        scalingMetrics,
        hpaName,
      },
      loading: isFetching,
      errorState: undefined,
    };
  }, [
    error,
    hpaEventsData?.data?.length,
    isFetching,
    newSpec?.metadata?.name,
    newSpec?.spec?.maxReplicas,
    newSpec?.spec?.metrics,
    newSpec?.spec?.minReplicas,
    notifyError,
  ]);
};

export const useScalingMetricsAsRecord = (scalingMetrics?: ScalingMetric[]) => {
  return useMemo<Record<string, ScalingMetric> | undefined>(() => {
    return scalingMetrics?.reduce((acc, metric) => {
      return {
        ...acc,
        [metric.resourceType]: metric,
      };
    }, {});
  }, [scalingMetrics]);
};
