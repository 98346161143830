import { Dictionary } from "@/shared/types/Dictionary";
import { LocalStorageItem } from "@/shared/utils/localStorageSettings";

export type InspectionViewPreferences = {
  tablePreferences: {
    pageSize: number;
  };
  selectedNamespacesPerCluster: Dictionary<Dictionary<string[]>>;
};

export const inspectionViewPreferencesKey = "inspectionViewPreferences";

export const inspectionViewPreferencesInLsItem = new LocalStorageItem(
  inspectionViewPreferencesKey
);

export const defaultInspectionViewPreferences: InspectionViewPreferences = {
  tablePreferences: {
    pageSize: 10,
  },
  selectedNamespacesPerCluster: {},
};
