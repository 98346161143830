import React, { useState } from "react";

import CordonModal from "../modals/CordonModal/CordonModal";
import Node from "../../ResourceView/resources/node";

import { ActionButtonProps } from "./types";

import Action from ".";

const getIsUncordon = (resource: Node) => resource.unschedulable || false;

export const CordonUncordonButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsActionActive?.(false);
  };

  return (
    <>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={handleClick}
        Button={Button}
      >
        <CordonModal
          isModalOpen={open}
          handleClose={handleClose}
          nodeName={resource.name}
          cluster={resource.cluster}
          agentId={resource.agentId}
          isUncordon={getIsUncordon(resource as Node)}
        />
      </Action>
    </>
  );
};
