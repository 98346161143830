import Link from "@mui/material/Link";
import { GridColDef } from "@mui/x-data-grid-pro";

import { formatSeconds } from "@/shared/utils/timeUtils";
export type ImpactedServicesDataRow = {
  name: string;
  komodorUid: string;
  pendingPods: number;
  avgUnschedulableTime: number;
  availabilityIssues: number;
};

export function getImpactedServicesColumns(
  onOpenResourceClicked: (komodorUid: string) => void
): GridColDef<ImpactedServicesDataRow>[] {
  return [
    {
      headerName: "Name",
      type: "string",
      field: "name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            variant="body2"
            underline="hover"
            noWrap
            onClick={() => {
              onOpenResourceClicked(params.row.komodorUid);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      headerName: "Pending Pods",
      type: "number",
      field: "pendingPods",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Avg Unschedulable Time",
      type: "number",
      field: "avgUnschedulableTime",
      flex: 1,
      align: "left",
      headerAlign: "left",
      valueFormatter: ({ value }) => formatSeconds(value),
    },
    {
      headerName: "Availability Issues",
      type: "number",
      field: "availabilityIssues",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
  ];
}
