import React, { createContext, useContext } from "react";

import {
  useFetchEventsLimitsQuery,
  FetchEventsLimitsOutput,
} from "@/generated/graphql";
import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";
import { useGetAccountEventLimit } from "@/shared/hooks/accounts-service/client/events/useGetAccountEventLimit";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  FetchEventsLimits,
  IHasuraMigrations,
} from "@/shared/constants/hasuraMigrationFeatureFlag";

const useFetchEventsLimits = (
  pause?: boolean
): FetchEventsLimitsOutput | null => {
  const isAnonymous = useIsAnonymousUser();
  const { hasuraMigrations } = useOverridableFlags();
  const hasuraMigrationFF = hasuraMigrations as IHasuraMigrations;
  const shouldUseMigration =
    hasuraMigrationFF?.endpoints.includes(FetchEventsLimits);

  // don't fetch data for an anonymous user
  const [{ data: eventsLimitGql }] = useFetchEventsLimitsQuery({
    pause: (isAnonymous || shouldUseMigration) ?? pause,
  });
  const eventsLimitRest = useGetAccountEventLimit(shouldUseMigration);

  const eventsLimits = shouldUseMigration ? eventsLimitRest : eventsLimitGql;

  if (!eventsLimits?.fetchEventsLimits) return null;
  return eventsLimits.fetchEventsLimits;
};

const EventsLimitContext = createContext<FetchEventsLimitsOutput | null>(null);

export const EventsLimitProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const eventsLimit = useFetchEventsLimits();
  return (
    <EventsLimitContext.Provider value={eventsLimit}>
      {children}
    </EventsLimitContext.Provider>
  );
};

const useEventsLimit = (): FetchEventsLimitsOutput | null =>
  useContext(EventsLimitContext);

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default useEventsLimit;
