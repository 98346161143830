import { MetricGraphProps } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/MetricGraph";
import { Uid } from "@/shared/utils/generateUid";

type GraphContainerPropsData = {
  hasError: boolean;
  isLoading: boolean;
  cluster: string;
};

export function createGraphContainerProps({
  hasError: error,
  cluster,
  isLoading,
}: GraphContainerPropsData): MetricGraphProps["graphContainerProps"] {
  const uid: Uid = {
    cluster,
    name: "",
    namespace: "",
  };
  return {
    uid,
    hasGraphData: !error && !isLoading,
    error: error ? "Something went wrong" : null,
    loading: isLoading,
  };
}
