export enum SupportedResourcesKinds {
  ArgoRolloutResource = "rollouts.argoproj.io",
  KubernetesDeploymentResource = "Deployment",
  KubernetesClusterRoleBindingsResource = "ClusterRoleBinding",
  KubernetesClusterRolesResource = "ClusterRole",
  KubernetesConfigMapResource = "ConfigMap",
  KubernetesCRDResource = "CustomResourceDefinition",
  KubernetesCronJobResource = "CronJob",
  KubernetesCRResource = "CustomResource",
  KubernetesDaemonSetResource = "DaemonSet",
  KubernetesEndpointResource = "Endpoints",
  KubernetesEndpointSliceResource = "EndpointSlice",
  KubernetesHelmResource = "Helm",
  KubernetesHPAResource = "HorizontalPodAutoscaler",
  KubernetesIngressResource = "Ingress",
  KubernetesJobResource = "Job",
  KubernetesLimitRangeResource = "LimitRange",
  KubernetesNamespaceResource = "Namespace",
  KubernetesNetworkPolicyResource = "NetworkPolicy",
  KubernetesNodesResource = "Node",
  KubernetesPDBResource = "PodDisruptionBudget",
  KubernetesPodsResource = "Pod",
  KubernetesPVCResource = "PersistentVolumeClaim",
  KubernetesPVResource = "PersistentVolume",
  KubernetesReplicaSetResource = "ReplicaSet",
  KubernetesResourceQuotaResource = "ResourceQuota",
  KubernetesRoleBindingsResource = "RoleBinding",
  KubernetesRolesResource = "Role",
  KubernetesSecretsResource = "Secret",
  KubernetesServiceAccountsResource = "ServiceAccount",
  KubernetesServiceResource = "Service",
  KubernetesStatefulSetResource = "StatefulSet",
  KubernetesStorageClassResource = "StorageClass",
}
