import { useMemo } from "react";
import { parseISO } from "date-fns";

import { useFilterListByInspectionFilters } from "@/components/Inspection/filters/useFilterListByInspectionFilters";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { KubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ResourceListResponse } from "@/components/Inspection/InspectionViews/types";

type UseChartsByClusterParams = {
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  cluster: string;
  resourceKeyInLS: string | undefined;
};

export const useChartsByCluster = ({
  cluster,
  kubernetesResource,
  resourceList,
  resourceKeyInLS,
}: UseChartsByClusterParams) => {
  const { formatToParts } = useDateFormatter({
    month: "2-digit",
    day: "2-digit",
  });

  const filteredTableResults = useFilterListByInspectionFilters({
    resourceList,
    kubernetesResource,
    resourceKeyInLS,
    clusterName: cluster,
  });

  return useMemo(() => {
    return filteredTableResults
      .filter((row) => row?.cluster === cluster)
      .map((row) => {
        const dateParts = Object.fromEntries(
          formatToParts(parseISO(row?.lastUpdated ?? "")).map(
            ({ type, value }) => [type, value]
          )
        );
        const { day, month, year, hour, minute, second, dayPeriod } = dateParts;
        return {
          ...row,
          lastUpdated: `${year}-${month}-${day}, ${hour}:${minute}:${second} ${dayPeriod}`,
        };
      });
  }, [cluster, filteredTableResults, formatToParts]);
};
