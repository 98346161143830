import { PodLogsDirection, PodLogsStore } from "./types";

export const initialValues = {
  logs: [],
  direction: PodLogsDirection.NewestFirst, // TODO: Yossi to change that to be the opposite
  isLoading: true,
};

// currently the store of the live logs only, but once we deprecated the old logs so the name will be OK
export const initialState: PodLogsStore = {
  ...initialValues,
  resetLogs: () => undefined,
  addLogs: () => undefined,
  setDirection: () => undefined,
  setIsLoading: () => undefined,
};
