import React from "react";
import Stack from "@mui/material/Stack";
import { muiTheme, muiColors } from "@komodorio/design-system";
import IconButton from "@mui/material/IconButton";
import { Close24 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";

import { User } from "@/generated/auth";

interface UserDrawerHeaderProps {
  user: User;
  onClose: () => void;
}

export const UserDrawerHeader: React.FC<UserDrawerHeaderProps> = ({
  user,
  onClose,
}) => (
  <Stack
    direction="row"
    padding="12px"
    alignItems="center"
    columnGap="24px"
    sx={{
      backgroundColor: muiTheme.palette.background.paper,
      borderBottom: `1px solid ${muiColors.gray[200]}`,
    }}
  >
    <IconButton onClick={onClose}>
      <Close24 />
    </IconButton>
    <Typography variant="h3">{user?.displayName}</Typography>
  </Stack>
);
