import { Kubernetes } from "@komodorio/design-system/icons";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesPDBResource = {
  NameInK8S: "poddisruptionbudgets",
  Group: "configuration",
  NavBarName: "PDB",
  PresentName: "Pod Disruption Budget",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: SupportedResourcesKinds.KubernetesPDBResource,
  Icon: Kubernetes,
  hasLabels: true,
};
