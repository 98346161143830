import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { comparedOptionsSorter } from "../common/selectionUtils";

import {
  buildKomodorUid,
  BuildKomodorUidParams,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { Entity, HelmChart } from "@/generated/addonsApi";
import { KubernetesSecretsResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { getHelmSecretName } from "@/components/Inspection/Helm/hooks/utils";

const getHelmLabelName = (name: string, chart: string) => {
  return `${name} (${chart})`;
};

export const buildHelmLabelKomodorUidParams = (
  helmRelease: HelmChart
): BuildKomodorUidParams => {
  return {
    clusterName: helmRelease.clusterName,
    namespace: helmRelease.namespace,
    resourceName: getHelmLabelName(helmRelease.name, helmRelease.chart),
    kind: Entity.HelmChart,
  };
};

type BuildHelmOptionValueParams = {
  name: string;
  clusterName: string;
  namespace: string;
  revision: number;
};
export const buildHelmOptionValue = ({
  name,
  clusterName,
  namespace,
  revision,
}: BuildHelmOptionValueParams): string => {
  return buildKomodorUid({
    kind: KubernetesSecretsResource.Kind,
    namespace,
    resourceName: getHelmSecretName(name, revision),
    clusterName,
  });
};

export const sortHelmReleasesSelection = ({
  releases,
  baselineSelection,
  comparedSelections,
}: {
  releases: HelmChart[] | undefined;
  baselineSelection: MuiSelectionOption<string> | null;
  comparedSelections: MuiSelectionOption<string>[] | null;
}) => {
  return (releases ?? []).sort((a, b) => {
    return comparedOptionsSorter({
      aKomodorUidParams: buildHelmLabelKomodorUidParams(a),
      bKomodorUidParams: buildHelmLabelKomodorUidParams(b),
      baselineSelection,
      comparedSelections,
    });
  });
};

export const getComparedHelmReleasesOptions = ({
  helmReleases,
  baselineSelection,
  comparedSelections,
}: {
  helmReleases: HelmChart[];
  baselineSelection: MuiSelectionOption<string> | null;
  comparedSelections: MuiSelectionOption<string>[] | null;
}) => {
  return sortHelmReleasesSelection({
    releases: helmReleases,
    baselineSelection,
    comparedSelections,
  }).reduce<MuiSelectionOption<string>[]>((acc, helmRelease) => {
    const uid = buildHelmOptionValue(helmRelease);
    if (uid !== baselineSelection?.value) {
      acc.push(buildOptionForHelmRelease(helmRelease));
    }
    return acc;
  }, []);
};

interface BuildOptionFromHelmReleaseParams {
  name: string;
  chart: string;
  clusterName: string;
  namespace: string;
  revision: number;
}
export const buildOptionForHelmRelease = ({
  name,
  chart,
  clusterName,
  namespace,
  revision,
}: BuildOptionFromHelmReleaseParams) => {
  return {
    label: buildKomodorUid({
      resourceName: getHelmLabelName(name, chart),
      clusterName,
      namespace,
      kind: Entity.HelmChart,
    }),
    value: buildHelmOptionValue({
      name,
      clusterName,
      namespace,
      revision,
    }),
  };
};
