import { useMemo, useState } from "react";

import { DisplayOption } from "../../logsTypes";
import { logsViewerAriaLabels } from "../../logsViewerAriaLabels";

import { useLogsViewerInLocalStorage } from "@/components/common/LogsViewer/localStorage/useLogsViewerInLocalStorage";

const {
  showLineNumber: showLineNumberAriaLabel,
  showTimestamp: showTimestampAriaLabel,
  wrapText: wrapTextAriaLabel,
} = logsViewerAriaLabels.dialog.checkbox;

type CheckboxesData = {
  values: {
    showTimestamp: boolean;
    showLineNumber: boolean;
    wrapText: boolean;
  };
  checkboxData: DisplayOption[];
};

export const useCheckboxData = () => {
  const [{ wrapText, showLineNumber, showTimestamp }] =
    useLogsViewerInLocalStorage();

  const [shouldShowTimestamp, setShouldShowTimestamp] = useState(showTimestamp);
  const [shouldShowLineNumber, setShouldShowLineNumber] =
    useState(showLineNumber);
  const [shouldWrapText, setShouldWrapText] = useState(wrapText);

  return useMemo<CheckboxesData>(() => {
    return {
      values: {
        showTimestamp: shouldShowTimestamp,
        showLineNumber: shouldShowLineNumber,
        wrapText: shouldWrapText,
      },
      checkboxData: [
        {
          name: "Show timestamps",
          enabled: shouldShowTimestamp,
          setEnabled: setShouldShowTimestamp,
          ariaLabel: showTimestampAriaLabel,
        },
        {
          name: "Show line numbers",
          enabled: shouldShowLineNumber,
          setEnabled: setShouldShowLineNumber,
          ariaLabel: showLineNumberAriaLabel,
        },
        {
          name: "Enable text wrap",
          enabled: shouldWrapText,
          setEnabled: setShouldWrapText,
          ariaLabel: wrapTextAriaLabel,
        },
      ],
    };
  }, [shouldShowLineNumber, shouldShowTimestamp, shouldWrapText]);
};
