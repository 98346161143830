import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DefaultRoleCell } from "@/pages/organization-settings/access-management/RolesPage/Grid/DefaultRoleCell";
import { CopyText } from "@/components/Settings/styles";
import {
  ActionsCell,
  ActionsCellProps,
} from "@/pages/organization-settings/access-management/RolesPage/Grid/ActionsCell";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/RolesPage/types";
import { getTagsCell } from "@/pages/organization-settings/access-management/RolesPage/Grid/TagsCell";

export const POLICIES_CELL_WIDTH = 400;

export const getColumnsDefinitions = ({
  onEdit,
  onDelete,
  policiesCellWidth = POLICIES_CELL_WIDTH,
}: {
  onEdit: OnEdit;
  onDelete: OnDelete;
  policiesCellWidth?: number;
}) => [
  {
    field: "name",
    headerName: "Name",
    renderCell: (params: GridRenderCellParams) => {
      return <DefaultRoleCell params={params} />;
    },
    flex: 1,
  },
  {
    field: "rbacPolicies",
    headerName: "Policies",
    renderCell: (params: GridRenderCellParams) =>
      getTagsCell({ params, width: policiesCellWidth }),
    width: policiesCellWidth,
    sortable: false,
  },
  {
    field: "id",
    headerName: "ID",
    renderCell: (params: GridRenderCellParams) => {
      return <CopyText text={params.value} />;
    },
    flex: 1.5,
  },
  {
    field: "actions",
    headerName: "",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) =>
      getActionsCell({
        params,
        onEdit,
        onDelete,
      }),
    sortable: false,
  },
];

const getActionsCell = ({ params, onEdit, onDelete }: ActionsCellProps) => {
  return <ActionsCell params={params} onEdit={onEdit} onDelete={onDelete} />;
};
