export enum NotificationStatus {
  Success = "success",
  Error = "error",
  Loading = "loading",
}

export type NotificationResource = {
  clusterName: string;
  namespace?: string;
  name: string;
};

export type Notification = {
  id: string;
  status: NotificationStatus;
  title: string;
  message: string;
  resource?: NotificationResource;
  onClick?: () => void;
  onClose?: () => void;
};
