import { MouseEventHandler, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ChevronDown16, Close16 } from "../../../icons";
import { MenuList } from "../MenuList/MenuList";
import { Popover } from "../Popover/Popover";
import { Input, InputProps } from "../Input/Input";

import { Option, OptionProps } from "./Option";

const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;

/** @deprecated */
export type OptionType<T> = {
  subLabel?: string;
  label: string;
  value: T;
} & Pick<OptionProps, "disabled">;
/** @deprecated */
export interface SelectProps<T>
  extends Omit<InputProps, "value" | "onChange" | "ref"> {
  options: OptionType<T>[];
  listMaxHeight?: string;
  listZIndex?: string;
  value?: OptionType<T>;
  searchable?: boolean;
  onChange?: (selected: OptionType<T>) => void;
  onClean?: () => void;
  onClose?: () => void;
}

/**
 * @deprecated Use MUI Select instead.
 */
export const Select = <T,>({
  width = "100%",
  listMaxHeight,
  listZIndex = "0",
  disabled,
  options,
  value,
  searchable,
  onChange,
  onClean,
  onClose,
  ...rest
}: SelectProps<T>) => {
  const [openList, setOpenList] = useState(false);
  const [divWidth, setDivWidth] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const divWidthRef = useRef<HTMLDivElement>(null);
  const isRelativeWidth = width.includes("%");
  const widthAsString = isRelativeWidth ? `${divWidth}px` : width;

  useEffect(() => {
    setDivWidth(divWidthRef.current?.clientWidth || 0);
  }, []);

  const optionClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    const optionLabelText =
      e.currentTarget.querySelector("#option-label").textContent;
    const selected = options.find((o) => o.label === optionLabelText);
    if (selected && onChange) {
      onChange(selected);
    }
    handleClose();
  };

  const inputClickHandler = () => setOpenList(!openList);
  const handleClose = () => {
    setOpenList(false);
    onClose?.();
  };

  return (
    <div aria-label="select an option">
      <Popover
        isOpen={openList}
        handleClose={handleClose}
        maxHeight={listMaxHeight}
        containerStyle={{ zIndex: listZIndex }}
        align={"start"}
        content={
          <MenuList
            width={widthAsString}
            onSearch={
              searchable
                ? (event) => {
                    setSearchTerm(event.target.value);
                  }
                : undefined
            }
          >
            {options
              .filter(
                ({ label }) =>
                  !searchable ||
                  !searchTerm ||
                  label.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(({ label, value: _, ...rest }, i) => (
                <Option
                  key={i}
                  variant="single"
                  subLabel={value?.subLabel}
                  selected={value?.label === label}
                  onClick={optionClickHandler}
                  {...rest}
                >
                  {label}
                </Option>
              ))}
          </MenuList>
        }
      >
        <Input
          readOnly
          width={widthAsString}
          disabled={disabled}
          value={value?.label}
          trailingElement={
            <Flex>
              {value.label && onClean && <Close16 onClick={onClean} />}
              <ChevronDown16 />
            </Flex>
          }
          onClick={inputClickHandler}
          {...rest}
        />
      </Popover>
      <div ref={divWidthRef} style={{ width }} />
    </div>
  );
};

Select.defaultProps = {
  width: "100%",
  value: { label: "" },
  disabled: false,
  searchable: false,
};
