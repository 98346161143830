import { useQuery } from "@tanstack/react-query";

import {
  apiV1MetricsNodeTerminationsGetUrl,
  OverTimeApiApiV1MetricsNodeTerminationsGetRequest,
  OverTimeNodeTerminationsResponse,
} from "@/generated/resourcesApi";
import { QueryOptions } from "@/shared/hooks/reliability-api/apiClient";
import { useResourcesApiClient } from "@/shared/hooks/resources-api/client/apiClient";

export const METRICS_NODE_TERMINATIONS_ENDPOINT = "/metrics/node-terminations";

export enum ValidNodeTerminationIntervals {
  Minute = 60,
  Hour = 3600,
  Day = 86400,
  Week = 604800,
  Month = 2592000,
  Quarter = 7776000,
  Year = 31557600,
}

export function useNodeTerminationMetricsQuery(
  params: OverTimeApiApiV1MetricsNodeTerminationsGetRequest,
  queryOptions: QueryOptions
) {
  const apiClient = useResourcesApiClient();
  const url = apiV1MetricsNodeTerminationsGetUrl(
    params,
    apiClient.defaults.baseURL ?? ""
  );

  return useQuery(
    [METRICS_NODE_TERMINATIONS_ENDPOINT, params],
    async () => {
      const response = await apiClient.get<OverTimeNodeTerminationsResponse>(
        url
      );
      return response.data;
    },
    queryOptions
  );
}
