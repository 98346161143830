import { useMemo } from "react";

import {
  Deploy,
  DeploysApiApiV1DeploysSearchGetRequest,
} from "../../../../generated/monitorsApi";
import { useGetDeploys } from "../../../../shared/hooks/monitors-api/client/deploys/useGetDeploys";
const defaultFields: DeploysApiApiV1DeploysSearchGetRequest["fields"] = [
  "id",
  "eventTime",
  "endEventTime",
  "changeType",
  "updatedAt",
  "status",
  "versionTo",
  "versionFrom",
  "failed_container",
  "gitCompare",
  "strategy",
  "namespace",
  "clusterName",
  "generation",
  "oldSpec",
  "newSpec",
  "deploymentDiffKeys",
  "services",
  "kubernetesConfigChanges",
  "deploymentName",
  "isEmptyDeployment",
  "trackedFiles",
];
export const useDeploysByIds = (
  ids: string[],
  fields = defaultFields
): [Deploy[], boolean] => {
  const { data, isFetching } = useGetDeploys(
    {
      ids: ids,
      fields,
    },
    ids.length > 0
  );
  return useMemo(
    () => [data?.data || [], isFetching],
    [data?.data, isFetching]
  );
};
