import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { capitalize } from "lodash";

import { Certificate } from "@/generated/addonsApi";
import { TimeElapsedCell } from "@/components/k8sAddons/components/table/TimeElapsedCell/TimeElapsedCell";
import { AgeCell } from "@/components/k8sAddons/components/table/AgeCell/AgeCell";
import { getTypographySchemeByTimeElapsed } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/utils/certificatePageUtils";
import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { useDisableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import { generateRandRangeList } from "@/components/k8sAddons/utils/tableUtils";
import { useGenerateSkeleton } from "@/components/k8sAddons/hooks/table/useGenerateSkeleton";
import { StatusChipWithValidation } from "@/components/k8sAddons/addons/CertManager/shared/StatusChipWithValidation";

export const useColumnsConfig = () =>
  useDisableLocalSortingInTableColumns<Certificate>({
    name: {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    clusterName: {
      field: "clusterName",
      headerName: "Cluster",
      flex: 1,
    },
    namespace: {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    issuerName: {
      field: "issuerName",
      headerName: "Issuer",
      flex: 1,
    },
    age: {
      field: "age",
      headerName: "Age",
      maxWidth: 100,
    },
    expiration: {
      field: "expiration",
      headerName: "Expiration time",
      minWidth: 130,
    },
    status: {
      field: "status",
      headerName: "Status",
      width: 130,
    },
  });

export const useCertificateTableColumns = (): GridColDef<Certificate>[] => {
  const columnsConfig = useColumnsConfig();
  return useMemo(() => {
    return [
      { ...columnsConfig.name },
      { ...columnsConfig.clusterName },
      { ...columnsConfig.namespace },
      { ...columnsConfig.issuerName },
      {
        ...columnsConfig.age,
        renderCell: (params) => {
          return <AgeCell age={params.row.age} includeDateUnits={["y", "d"]} />;
        },
      },
      {
        ...columnsConfig.expiration,
        renderCell: (params) => {
          return (
            <TimeElapsedCell
              targetDateString={params.value}
              getTypographyScheme={getTypographySchemeByTimeElapsed}
            />
          );
        },
      },
      {
        ...columnsConfig.status,
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <StatusChipWithValidation
              status={status}
              color={certificateStatusToColor[status]}
              label={capitalize(params.value)}
              expiration={params.row.expiration}
            />
          );
        },
      },
    ];
  }, [
    columnsConfig.age,
    columnsConfig.clusterName,
    columnsConfig.expiration,
    columnsConfig.issuerName,
    columnsConfig.name,
    columnsConfig.namespace,
    columnsConfig.status,
  ]);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof Certificate, number[]>> = {
  name: generateRandRangeList(50, 150),
  clusterName: generateRandRangeList(50, 150),
  namespace: generateRandRangeList(50, 150),
  issuerName: generateRandRangeList(50, 150),
  age: generateRandRangeList(40, 60),
  expiration: generateRandRangeList(50, 100),
  status: generateRandRangeList(50, 100),
};

export const useCertificateLoadingColumns = (): GridColDef<Certificate>[] => {
  const columnsConfig = useColumnsConfig();
  const generateSkeleton = useGenerateSkeleton(randRangeColumns);
  return useMemo(
    () => [
      {
        ...columnsConfig.name,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "name" }),
      },
      {
        ...columnsConfig.clusterName,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "clusterName" }),
      },
      {
        ...columnsConfig.namespace,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "namespace" }),
      },
      {
        ...columnsConfig.issuerName,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "issuerName" }),
      },
      {
        ...columnsConfig.age,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "age" }),
      },
      {
        ...columnsConfig.expiration,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "expiration" }),
      },
      {
        ...columnsConfig.status,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "status",
            skeletonProps: { sx: { borderRadius: "16px" } },
          }),
      },
    ],
    [
      columnsConfig.age,
      columnsConfig.clusterName,
      columnsConfig.expiration,
      columnsConfig.issuerName,
      columnsConfig.name,
      columnsConfig.namespace,
      columnsConfig.status,
      generateSkeleton,
    ]
  );
};
