import { useMemo } from "react";

import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { HelmChart } from "@/generated/addonsApi";

export const useHelmReleasesList = () => {
  const { data, isError, refetch, isFetching } = useFetchLiveStateList({
    additionalRequestFilters: undefined,
  });
  const { isLoading: isLoadingWorkspace } = useWorkspaces();

  const helmReleases = data?.data.data;
  return useMemo(
    () => ({
      helmReleases: helmReleases as HelmChart[] | undefined,
      isLoading: isLoadingWorkspace || isFetching,
      isError,
      refetch,
    }),
    [helmReleases, isLoadingWorkspace, isFetching, isError, refetch]
  );
};
