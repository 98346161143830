import React from "react";

import { K8SEOLSupportingData } from "../../../../../../../../../../generated/reliabilityApi";
import { ViolationSummaryItem } from "../../../shared/ViolationSummary/ViolationSummary";
import { getIsEolDatePassed } from "../utils";

import { EolDate, SuggestedVersion } from "./summaryUtilsComponents";

export const getSummaryItems = (
  supportingData: K8SEOLSupportingData | undefined
): ViolationSummaryItem[] => {
  if (!supportingData?.clusterVersionShort) return [];
  return [
    {
      label: "Kubernetes Version",
      value: `v${supportingData.clusterVersionShort}`,
    },
    {
      label: "End of Life Date",
      value: <EolDate eol={supportingData.eolDate} />,
    },
    ...(getIsEolDatePassed(new Date(supportingData.eolDate)) &&
    supportingData.suggestedVersion &&
    supportingData.suggestedVersionEOL
      ? [
          {
            label: "Maximal safe version",
            value: (
              <SuggestedVersion
                suggestion={supportingData.suggestedVersion}
                suggestionEOL={supportingData.suggestedVersionEOL}
              />
            ),
          },
        ]
      : []),
  ];
};
