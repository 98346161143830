import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import { useResourcesApiClient } from "@/shared/hooks/resources-api/client/apiClient";
import {
  AccountServiceAttributes,
  apiV1AccountServiceAttributesInitPostUrl,
} from "@/generated/resourcesApi";

const initializeServiceAttributesForAccount = async (
  apiClient: AxiosInstance
): Promise<AccountServiceAttributes | undefined> => {
  const { data } = await apiClient.post(
    apiV1AccountServiceAttributesInitPostUrl(apiClient.defaults.baseURL ?? "")
  );
  return data;
};
export const useInitializeServiceAttributesForAccountRest = () => {
  const apiClient = useResourcesApiClient();
  return useMutation(() => initializeServiceAttributesForAccount(apiClient));
};
