import { useEffect, useRef, useState } from "react";

import { LocalStorageItem } from "@/shared/utils/localStorageSettings";
import { Addon, Entity } from "@/generated/addonsApi";
import {
  getLocalStorageItem,
  getLocalStorageKey,
} from "@/components/k8sAddons/utils/addonUtils";
import { DRAWER_STACK_STATE_URL_PARAM } from "@/shared/config/urlSearchParamsKeys";
import { useQueryStringInLocalStorage } from "@/shared/hooks/state/useQueryStringInLocalStorage";

export const useAddonsQueryStringInLocalStorage = (
  addonType: Addon,
  addonEntity: Entity
) => {
  const localStorageKey = getLocalStorageKey(addonType, addonEntity);
  const [item, setItem] = useState<LocalStorageItem>();
  const lsItemWasSet = useRef(false);

  useEffect(() => {
    if (lsItemWasSet.current) return;
    if (!item || getLocalStorageItem(addonType, addonEntity) === null) {
      setItem(new LocalStorageItem(localStorageKey));
      lsItemWasSet.current = true;
    }
  }, [localStorageKey, item, addonType, addonEntity]);

  useQueryStringInLocalStorage({
    item,
    excludeFromLocalStorage: [{ paramKey: DRAWER_STACK_STATE_URL_PARAM }],
  });
};
