import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { capitalize, compact } from "lodash";

import { AddonFilterHandler } from "@/components/k8sAddons/components/AddonFilterHandler/AddonFilterHandler";
import { PageFiltersContainer } from "@/components/k8sAddons/styles";
import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { SEARCH_TABLE_PLACEHOLDER } from "@/components/k8sAddons/addons/workflows/DagsListPage/constants";
import { columnsConfig } from "@/components/k8sAddons/addons/workflows/hooks/dagsTableHooks";
import { SwitchAsGenericFilter } from "@/components/k8sAddons/components/filters/SwitchAsGenericFilter";
import { Operator } from "@/generated/addonsApi";
import { WorkflowEngine } from "@/components/k8sAddons/addons/workflows/types";
import { sharedFilterProps } from "@/components/k8sAddons/constants/filters";

export const Filters: React.FC<{ engineType?: WorkflowEngine }> = ({
  engineType,
}) => {
  const { searchFieldComponent, searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
    searchFieldProps: {
      placeholder: SEARCH_TABLE_PLACEHOLDER,
      label: "Search",
    },
  });

  const additionalFilters = compact([
    {
      name: columnsConfig.workflowEngine.field,
      operator: engineType ? Operator.In : Operator.Nin,
      value: engineType ? [engineType] : Object.values(WorkflowEngine),
    },
    searchFilter,
  ]);

  return (
    <PageFiltersContainer>
      {searchFieldComponent}
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.cluster.field}
        label={"Cluster"}
        additionalFilters={additionalFilters}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.namespace.field}
        label={"Namespace"}
        additionalFilters={additionalFilters}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.status.field}
        label={"Latest Run Status"}
        additionalFilters={additionalFilters}
        labelCreator={capitalize}
      />
      {!engineType && (
        <AddonFilterHandler
          {...sharedFilterProps}
          filterCategory={columnsConfig.workflowEngine.field}
          label={"Workflow Engine"}
          additionalFilters={additionalFilters}
        />
      )}
      <Box display="flex" flexDirection="column" rowGap="8px">
        <Typography variant="body2">Issues Only</Typography>
        <SwitchAsGenericFilter filterName={columnsConfig.issues.field} />
      </Box>
    </PageFiltersContainer>
  );
};
