import {
  ContentType,
  LabeledContent,
} from "@/components/common/LabeledData/LabeledContent.types";
import { AutoScalerFull } from "@/generated/addonsApi";

type ConfigLabels = {
  title: string;
  data: LabeledContent;
  tooltip?: string;
};

type AutoscalerConfigLabelsData = {
  onNodePoolClick: (nodePool: string) => void;
};

export function getAutoscalerConfigLabels(
  autoscaler: AutoScalerFull,
  config: AutoscalerConfigLabelsData
): ConfigLabels[] {
  if (autoscaler.config == null) {
    return [];
  }
  if (autoscaler.config.type === "ClusterAutoScalerConfig") {
    const featureGateConfigField: ConfigLabels[] =
      autoscaler.config.featureGates.length > 0
        ? [
            {
              title: "Feature Gates",
              data: {
                type: ContentType.Chips,
                value: autoscaler.config.featureGates,
              },
            },
          ]
        : [];
    return [
      {
        title: "Node group auto discovery",
        data: {
          type: ContentType.String,
          value: autoscaler.config.nodeGroupsAutoDiscovery,
        },
      },
      {
        title: "Balance Similar Node Groups",
        data: {
          type: ContentType.String,
          value: String(autoscaler.config.balanceSimilarNodeGroups),
        },
      },
      {
        title: "New Pod Scale Up Delay",
        data: {
          type: ContentType.String,
          value: autoscaler.config.newPodScaleUpDelay,
        },
      },
      {
        title: "Skip nodes with local storage",
        data: {
          type: ContentType.String,
          value: String(autoscaler.config.skipNodesWithLocalStorage),
        },
      },
      {
        title: "Min replica count",
        data: {
          type: ContentType.String,
          value: String(autoscaler.config.minReplicaCount),
        },
      },
      ...featureGateConfigField,
      {
        title: "Node delete delay",
        data: {
          type: ContentType.String,
          value: autoscaler.config.nodeDeletionDelayTimeout,
        },
      },
      {
        title: "Scan interval",
        data: {
          type: ContentType.String,
          value: autoscaler.config.scanInterval,
        },
      },
    ];
  }

  const { onNodePoolClick } = config;
  const karpenterConfigFields: ConfigLabels[] = [
    {
      title: "Node Pool(s)",
      data: {
        type: ContentType.ButtonsList,
        value: autoscaler.config.nodePools.map((nodePool) => ({
          content: nodePool,
          onClick: () => {
            onNodePoolClick(nodePool);
          },
        })),
      },
    },
    {
      title: "Zones",
      data: {
        type: ContentType.Chips,
        value: autoscaler.config.zones,
      },
    },
    {
      title: "Batch Max Duration",
      data: {
        type: ContentType.String,
        value: autoscaler.config.batchMaxDuration,
      },
    },
    {
      title: "Batch Idle Duration",
      data: {
        type: ContentType.String,
        value: autoscaler.config.batchIdleDuration,
      },
    },
  ];

  if (autoscaler.config.featureGates.length > 0) {
    karpenterConfigFields.push({
      title: "Feature Gates",
      data: {
        type: ContentType.Chips,
        value: autoscaler.config.featureGates,
      },
    });
  }
  return karpenterConfigFields;
}
