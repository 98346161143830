import { useCallback, useEffect, useState } from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import type Resource from "@/components/ResourceView/resources";
import { useResource } from "@/components/ResourceView/useResource";
import { ResourceResultType } from "@/components/ResourceView/types";
import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useInspectionStore } from "@/shared/store/inspection/inspectionStore";
import {
  removeReportedActionSelector,
  selectReportedActionsSelector,
} from "@/shared/store/inspection/inspectionSelectors";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const useFilterRelevantActions = () => {
  const { showSuspendResumeAction } = useOverridableFlags();
  const [serviceAsResource, setServiceAsResource] = useState<
    Resource | undefined
  >();
  const { resource: fullResource, clearState } = useResource({
    agentId: serviceAsResource?.agentId ?? "",
    cluster: serviceAsResource?.cluster ?? "",
    namespace: serviceAsResource?.namespace ?? "",
    resourceName: serviceAsResource?.name ?? "",
    resourceType: serviceAsResource?.kind ?? "",
    preferredResultType: ResourceResultType.RawResource,
    executeMutation: !!serviceAsResource,
    fetchFromDb: !!serviceAsResource,
  });

  useClearStateOnSuspendChange(serviceAsResource, clearState);

  return useCallback(
    (resource: Resource) => {
      return resource.actions.filter((action) => {
        if (
          action === AvailableActions.SuspendCronJob ||
          action === AvailableActions.ResumeCronJob
        ) {
          if (!showSuspendResumeAction) {
            return false;
          }

          const resourceWithSuspend = (fullResource || resource) as Resource & {
            suspend: boolean | undefined;
          };

          if (resourceWithSuspend.suspend === undefined && !serviceAsResource) {
            // Komodor service as resource has no "suspend" field, fetch the full resource from ATM
            setServiceAsResource(resource);
            return;
          }

          if (action === AvailableActions.SuspendCronJob) {
            return resourceWithSuspend.suspend === false;
          }
          if (action === AvailableActions.ResumeCronJob) {
            return resourceWithSuspend.suspend === true;
          }
        }
        return true;
      });
    },
    [fullResource, serviceAsResource, showSuspendResumeAction]
  );
};

const useClearStateOnSuspendChange = (
  serviceAsResource: Resource | undefined,
  clearState: () => void
) => {
  const reportedActions = useInspectionStore(selectReportedActionsSelector);
  const removeReportedAction = useInspectionStore(removeReportedActionSelector);

  useEffect(() => {
    if (!serviceAsResource) return;
    const resourceId = buildKomodorUid({
      kind: serviceAsResource.kind,
      clusterName: serviceAsResource.cluster,
      namespace: serviceAsResource.namespace,
      resourceName: serviceAsResource.name,
    });
    if (reportedActions[resourceId] === "changeCronjobSuspendState") {
      removeReportedAction(resourceId);
      clearState();
    }
  }, [clearState, removeReportedAction, reportedActions, serviceAsResource]);
};
