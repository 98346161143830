import { useCallback } from "react";

import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";

export const useRefreshResultsByRoute = () => {
  const { resourceType, refreshResultsCb } = useInspectionBatchActionsContext();

  return useCallback(() => {
    const resourceTypePath = resourceType.NameInK8S.toLowerCase();
    const isSameResourcePath =
      window.location.pathname.includes(resourceTypePath);
    if (isSameResourcePath) {
      refreshResultsCb?.();
    }
  }, [refreshResultsCb, resourceType.NameInK8S]);
};
