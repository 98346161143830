import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

const List = styled.ul<{ withBullets?: boolean }>`
  margin-block: 0;
  padding-inline-start: 12px;
  list-style-type: ${({ withBullets }) => (withBullets ? "disc" : "none")};
`;

const ListItem = styled.li`
  &::marker {
    color: ${muiColors.gray[600]};
  }
`;

interface ListRendererProps {
  names: string[] | undefined;
  isBaseline: boolean;
  maxItems: number;
  withBullets?: boolean;
}

export const ListRenderer: React.FC<ListRendererProps> = memo(
  ({ names, isBaseline, maxItems, withBullets = true }) => {
    return (
      <>
        <List withBullets={withBullets}>
          {names
            ?.slice(
              0,
              (names?.length ?? 0) === maxItems + 1 ? maxItems + 1 : maxItems
            )
            .map((name) => (
              <ListItem key={name}>
                <Typography
                  title={name}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="body2"
                  color={isBaseline ? muiColors.gray[600] : "text.primary"}
                >
                  {name}
                </Typography>
              </ListItem>
            ))}
          {names != null && names.length > maxItems + 1 ? (
            <LightTooltip
              placement="bottom-start"
              title={names.slice(maxItems).join("\n")}
            >
              <Typography
                variant="body2"
                color={isBaseline ? "text.secondary" : "text.primary"}
                sx={{ cursor: "pointer" }}
              >
                +{(names?.length ?? 0) - maxItems}
              </Typography>
            </LightTooltip>
          ) : null}
        </List>
      </>
    );
  }
);
