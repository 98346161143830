import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAiApiClient } from "../apiClient";

import {
  DefaultApiApiV1ChatPostRequest,
  apiV1ChatPostUrl,
} from "@/generated/aiApi";

const postMessage = async (
  apiClient: AxiosInstance,
  body: DefaultApiApiV1ChatPostRequest
) => {
  const { data } = await apiClient.post(
    apiV1ChatPostUrl(body, apiClient.defaults.baseURL ?? ""),
    body.chatInput
  );

  return data;
};

export const usePostMessage = (
  body: DefaultApiApiV1ChatPostRequest,
  options?: { onMutate?: () => void }
) => {
  const apiClient = useAiApiClient();
  return useMutation(
    (userMessage: DefaultApiApiV1ChatPostRequest["chatInput"]["userMessage"]) =>
      postMessage(apiClient, {
        ...body,
        chatInput: { ...body.chatInput, userMessage },
      }),
    options
  );
};
