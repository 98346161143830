import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: baseline;
  margin-left: -4px;

  .MuiCheckbox-root {
    padding: 4px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 400;
  }
`;
