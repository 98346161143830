import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Field, RuleGroupType } from "react-querybuilder";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

import { useWorkspacesData } from "../../hooks/useWorkspacesData";
import { INITIAL_RULE } from "../../constants";

import { StatementPanel } from "./StatementPanel";

import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { defaultValidator } from "@/shared/components/QueryBuilder/defaultValidator";
import {
  doesntMatchWildcardOperator,
  matchesWildcardOperator,
  multiIsOperator,
} from "@/shared/components/QueryBuilder/operators";
import { PreviewDefinition } from "@/shared/components/PreviewDefintion/PreviewDefinition";

const clusterField = {
  name: "cluster",
  label: "Cluster",
  operators: [
    multiIsOperator,
    matchesWildcardOperator,
    doesntMatchWildcardOperator,
  ],
  validator: defaultValidator,
  values: [],
};

const namespaceField = {
  name: "namespace",
  label: "Namespace",
  operators: [
    multiIsOperator,
    matchesWildcardOperator,
    doesntMatchWildcardOperator,
  ],
  validator: defaultValidator,
  values: [],
};

const selectorField = {
  name: "selector",
  label: "Selector",
  operators: [matchesWildcardOperator, doesntMatchWildcardOperator],
  validator: defaultValidator,
  values: [],
};

interface Props {
  definitions: RuleGroupType[];
  setDefinition: (definitions: RuleGroupType, index: number) => void;
  onAddDefinition: (definition: RuleGroupType) => void;
}

export const ScopedWorkspaceQueryBuilder: React.FC<Props> = ({
  definitions,
  setDefinition,
  onAddDefinition,
}) => {
  const [showPreviewDefinition, setShowPreviewDefinition] = useState(false);
  const { clusters, namespaces, isFetching } = useWorkspacesData();

  const fields = useMemo((): Field[] => {
    const clusterNames = clusters?.map(({ clusterName, id }) => ({
      label: clusterName,
      value: id,
    }));

    const namespaceNames = namespaces?.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    return [
      { ...clusterField, values: clusterNames ?? [] },
      { ...namespaceField, values: namespaceNames ?? [] },
      selectorField,
    ];
  }, [clusters, namespaces]);

  const onAddStatementClick = () => {
    onAddDefinition(INITIAL_RULE);
  };

  const onQueryChange = useCallback(
    (query: RuleGroupType, index: number) => {
      // Remove rule group entries with no rules
      const newRules = query.rules.filter(
        (rule) => "rules" in rule && rule.rules.length > 0
      );
      const filteredQuery = { ...query, rules: newRules };

      setDefinition(filteredQuery, index);
    },
    [setDefinition]
  );

  const statementPanels = useMemo(() => {
    const panels = definitions.map((def, index) => (
      <StatementPanel
        key={`stmt_${index}`}
        definition={def}
        fields={fields}
        index={index}
        onQueryChange={onQueryChange}
      />
    ));

    return panels.map((panel, index) => (
      <Fragment key={index}>
        {panel}
        {index !== panels.length - 1 && (
          <Box>
            <Chip
              label={<Typography variant="overline2">Or</Typography>}
              sx={{ borderRadius: "4px" }}
            />
          </Box>
        )}
      </Fragment>
    ));
  }, [definitions, fields, onQueryChange]);

  return !isFetching ? (
    <Stack direction="column" gap="8px">
      {statementPanels}

      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={onAddStatementClick}
        >
          Add Statement
        </Button>
      </Box>

      {showPreviewDefinition && (
        <PreviewDefinition
          onClose={() => setShowPreviewDefinition(false)}
          clusters={(clusters ?? [])?.map((item) => ({
            id: item.id,
            name: item.clusterName,
            namespaces: [],
          }))}
        />
      )}
    </Stack>
  ) : (
    <CenteredLoader />
  );
};
