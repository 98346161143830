import React, { useMemo, useState } from "react";
import { StackedComparisonTable } from "@komodorio/design-system/komodor-ui";

import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";
import {
  DiffModal,
  DiffModalParams,
  SeeAllModalParams,
} from "../../common/DiffModal";
import { TableNoData } from "../../common/TableNoData";
import { DriftType } from "../../context/types";
import { MAX_COMPARED_SERVICES } from "../constants";
import { YamlViewer } from "../../common/YamlViewer";

import { ContainerComparisonTable } from "./ContainerComparisonTable";
import { useContainerTableAttributes } from "./ContainersTableUtils";

import { ServiceComparisonResponse } from "@/generated/workspacesApi";

export type ContainersAttributesComparisonProps = {
  data: ServiceComparisonResponse | undefined;
  showOnlyDrift: boolean;
  isLoading: boolean;
  isError: boolean;
  errorStateElement: React.ReactNode;
};

export const ContainersAttributesComparison: React.FC<
  ContainersAttributesComparisonProps
> = ({ data, showOnlyDrift, isLoading, isError, errorStateElement }) => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const [seeAllModal, setSeeAllModal] = useState<SeeAllModalParams | null>(
    null
  );
  const [diffModal, setDiffModal] = useState<DiffModalParams | null>(null);
  const isContainersDriftDetected = useMemo(() => {
    return data?.containers?.some((container) => container.isDiff) ?? false;
  }, [data?.containers]);

  const emptyAttributes = useContainerTableAttributes({});
  const shouldShowContainersTable = !showOnlyDrift || isContainersDriftDetected;
  if (!shouldShowContainersTable) {
    return null;
  }
  const isContainersDataExists = data?.containers !== undefined;
  return (
    <>
      {(!showOnlyDrift || isContainersDriftDetected) && (
        <StackedComparisonTable title="Container Attributes">
          {isContainersDataExists ? (
            data.containers.map((container) => {
              return (
                <ContainerComparisonTable
                  key={container.name}
                  targetContainer={container}
                  targetServices={data.targetServices}
                  isSingleContainer={data.containers.length === 1}
                  isComparedEmpty={!comparedSelections?.length}
                  isLoading={isLoading}
                  setDiffModal={setDiffModal}
                  setSeeAllModal={setSeeAllModal}
                />
              );
            })
          ) : (
            <TableNoData
              isLoading={isLoading}
              isError={isError}
              errorStateElement={errorStateElement}
              maxComparedItems={MAX_COMPARED_SERVICES}
              driftType={DriftType.Services}
              attributes={emptyAttributes}
            />
          )}
        </StackedComparisonTable>
      )}
      {baselineSelection !== null && seeAllModal !== null ? (
        <YamlViewer
          baselineUid={baselineSelection.value}
          title={seeAllModal.title}
          content={seeAllModal.content}
          onClose={() => setSeeAllModal(null)}
        />
      ) : null}
      {baselineSelection !== null && diffModal !== null ? (
        <DiffModal
          title={diffModal?.title}
          baselineUid={baselineSelection.value}
          comparedUid={diffModal.comparedUid}
          oldObj={diffModal?.oldObj}
          newObj={diffModal?.newObj}
          onClose={() => setDiffModal(null)}
          isYaml
        />
      ) : null}
    </>
  );
};
