import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { muiColors } from "@komodorio/design-system";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { selectedBatchActionsRequestSelector } from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { useBatchActionsLabel } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useBatchActionsLabel";
import { batchActionsAriaLabels } from "@/components/common/ResourceListTable/batchActions/batchActionsAriaLabels";

const Container = styled.div`
  display: flex;
  gap: 4px;
`;

const BoldTypography = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

export const BatchActionsConfirmationSubtitle: React.FC = () => {
  const { bulkActionsRequest } =
    useBatchActionsStore(selectedBatchActionsRequestSelector) ?? {};
  const actionLabel = useBatchActionsLabel();
  const totalResources = bulkActionsRequest?.actions.length ?? 0;
  const resourceKind = bulkActionsRequest?.actions[0].resource.kind ?? "";

  return (
    <Container aria-label={batchActionsAriaLabels.batchActionsDialog.subtitle}>
      <Typography variant={"body2"}>Are you sure you want to</Typography>
      <Typography variant={"body2"} color={muiColors.pink[600]}>
        {capitalize(actionLabel)}
      </Typography>
      <Typography variant={"body2"}>the following</Typography>
      <BoldTypography variant={"body2"}>{`${totalResources} ${pluralize(
        resourceKind,
        totalResources
      )}:`}</BoldTypography>
    </Container>
  );
};
