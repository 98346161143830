import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Select,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Dictionary } from "lodash";

import {
  Container,
  Content,
  FormTextInput,
  lightStyle,
  Spacer,
  WorkflowConfigModalStyle,
} from "../styles";
import useInstallationSubscription, {
  Installation,
} from "../../../../integrations/management/installed/useInstallationSubscription";
import IntegrationTile from "../../../../integrations/management/IntegrationTile";
import {
  useFetchOpsgenieRespondersQuery,
  useValidateOpsgenieApiKeyQuery,
} from "../../../../../generated/graphql";
import opsGenieIcon from "../../../../integrations/management/logos/opsgenie.svg?react";
import { Divider, LinkButton } from "../../../common/styles";
import { useUpsertInstallationWithType } from "../../../../integrations/installation/useUpsertInstallation";
import { OpsgenieResponder } from "../../../common/types";
import ModalComponent from "../../../common/ModalComponent";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import { OPSGENIE_INTEGRATION_SEND_ALERT_DOCS_LINK } from "../../../../../shared/constants/docsLinks";
import { GenericSuggestions } from "../suggestions";

import { ChannelLabel } from "./common";
import { InfoButton } from "./AddPagerDutyIntegrationKeyModal";

import { MonitorConfiguration } from "@/generated/monitorsApi";

const Label = styled.div`
  padding-bottom: 0.5rem;
  ${lightStyle}
`;

const Grid = styled.div`
  display: grid;
`;

export const StyledIntegrationTile = styled(IntegrationTile)`
  width: 20rem;
  min-height: 2rem;
  border: 1px solid #dcdddf;
  box-shadow: unset;
  padding: 0.75rem 1.5rem;
`;

const OpsgenieSinkOutput: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule: configData, setRule: setConfigData }) => {
  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const installations = useInstallationSubscription().data;

  const loading = useMemo(() => {
    return installations === undefined;
  }, [installations]);

  const installation: Installation | undefined = useMemo(() => {
    const configurations = installations?.filter(
      (i) => i.integration === "opsgenie-api"
    );
    return configurations ? configurations[0] : undefined;
  }, [installations]);

  const [{ data }, getResponders] = useFetchOpsgenieRespondersQuery();
  const [suggestions, setSuggestions] = useState<GenericSuggestions<string>>(
    []
  );

  const [responder, setResponder] = useState<string>(
    configData.sinks?.opsgenie?.[0] ?? ""
  );

  useEffect(() => {
    if (data && data.fetchOpsgenieResponders) {
      const { responders: r } = data.fetchOpsgenieResponders;
      // ATTAN: hack because of graphql code generator, this is not really a string
      const responders = r as unknown as OpsgenieResponder[];
      setSuggestions(responders.map((c) => ({ label: c.name, value: c.name })));
    } else setSuggestions([]);
  }, [data, isIntegrationModalOpen]);

  useEffect(() => {
    setConfigData({
      ...configData,
      sinks: {
        ...configData.sinks,
        opsgenie: responder === "" ? undefined : [responder],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responder]);

  return loading ? null : (
    <>
      {installation ? (
        <>
          <ChannelLabel>
            <Typography size="medium">Opsgenie Responder</Typography>
            <LinkButton onClick={() => setIsIntegrationModalOpen(true)}>
              Update Opsgenie Integration
            </LinkButton>
          </ChannelLabel>
          <Select
            size="medium"
            width="100%"
            listMaxHeight="15rem"
            placeholder="select responder team"
            searchable={suggestions.length > 5}
            options={suggestions}
            value={
              suggestions.find((o) => o.value === responder) ?? {
                label: responder,
                value: responder,
              }
            }
            onChange={(selected) => {
              setResponder(selected.value);
            }}
            onClean={() => setResponder("")}
          />
        </>
      ) : (
        <>
          <Label>
            Keep your team up-to-date with the current state and health of your
            system by installing Komodor's Opsgenie integration
          </Label>
          <StyledIntegrationTile
            logoWidth="70px"
            logo={opsGenieIcon}
            title="Opsgenie"
          >
            <div>Get your Komodor alerts when and where it matters</div>
            <Button
              variant="primary"
              onClick={() => setIsIntegrationModalOpen(true)}
            >
              Add API Key
            </Button>
          </StyledIntegrationTile>
        </>
      )}
      {isIntegrationModalOpen && (
        <AddOpsgenieIntegration
          isOpen
          handleClose={() => {
            getResponders();
            setIsIntegrationModalOpen(false);
          }}
          installation={installation}
        />
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default OpsgenieSinkOutput;

export const AddOpsgenieIntegration: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  installation: Installation | undefined;
}> = ({ isOpen, handleClose, installation }) => {
  const [apiKey, setAPIKey] = useState(
    installation?.configuration
      ? (installation?.configuration as Dictionary<string>)["apiKey"]
      : ""
  );

  const [apiKeyValidation, validateApiKey] = useValidateOpsgenieApiKeyQuery({
    variables: { apiKey },
    pause: true,
  });

  const isApiKeyValid = useMemo(() => {
    return apiKeyValidation.data?.validateOpsgenieApiKey?.valid;
  }, [apiKeyValidation]);

  useEffect(() => {
    if (apiKey) {
      validateApiKey({ apiKey });
    }
  }, [apiKey, validateApiKey]);

  const createOpsgenieAPIInstallation = useUpsertInstallationWithType<{
    apiKey: string;
  }>("opsgenie-api");

  const handleAddAPIKey = async () => {
    await createOpsgenieAPIInstallation({ apiKey }, installation?.id);
    handleClose();
  };

  return (
    <ModalComponent
      title=""
      isOpen={isOpen}
      handleCloseModal={handleClose}
      customStyle={WorkflowConfigModalStyle}
    >
      <Container>
        <Header closeModalCallback={handleClose} title="Opsgenie Integration" />
        <Divider />
        <Content>
          <Label>
            To Integrate between Komodor and your Opsgenie, enter your API Key
          </Label>
          <Spacer />
          <Label>API Key</Label>
          <Grid>
            <FormTextInput
              value={apiKey}
              width="31"
              placeholder="Enter API Key"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAPIKey(e.target.value);
              }}
              isValid={isApiKeyValid}
            />
          </Grid>
          <Spacer />
          <InfoButton
            href={OPSGENIE_INTEGRATION_SEND_ALERT_DOCS_LINK}
            target="_blank"
          >
            How do I Generate an API Key?
          </InfoButton>
        </Content>
        <Divider />
        <Footer
          backData={{
            label: "Cancel",
            action: handleClose,
          }}
          nextData={{ label: "Save", action: handleAddAPIKey }}
          isNextDisabled={!isApiKeyValid}
        />
      </Container>
    </ModalComponent>
  );
};
