import { useEffect, useState, useCallback } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { BaseComparisonSelection } from "../common/BaseComparisonSelection";
import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import {
  useComparedOptionsWithDisabled,
  useSelectedComparedOptionsValues,
} from "../common/hooks";

import { MAX_COMPARED_RELEASES } from "./constants";
import { getComparedHelmReleasesOptions } from "./selectionOptionsUtils";
import { useBaselineHelmReleasesOptions } from "./useBaselineHelmReleasesOptions";
import { useHelmReleasesList } from "./useHelmReleasesList";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";

const ErrorMessage = ({ refetch }: { refetch: () => void }) => (
  <Typography color="error" variant="body2">
    Helm releases are unavailable. <Link onClick={refetch}>Retry</Link>
  </Typography>
);

export const HelmComparisonSelection: React.FC = () => {
  const {
    baselineSelection,
    comparedSelections,
    isBaselineSelectorOpen,
    isComparedSelectorOpen,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  } = useDriftComparisonContext();

  const { helmReleases, isLoading, isError, refetch } = useHelmReleasesList();

  const updateComparedHelmReleasesOptions = useCallback(() => {
    setComparedHelmReleasesOptions(
      getComparedHelmReleasesOptions({
        helmReleases: helmReleases ?? [],
        baselineSelection,
        comparedSelections,
      })
    );
  }, [helmReleases, baselineSelection, comparedSelections]);

  const [comparedHelmReleasesOptions, setComparedHelmReleasesOptions] =
    useState<MuiSelectionOption<string>[]>([]);

  useEffect(() => {
    if (!isComparedSelectorOpen) {
      updateComparedHelmReleasesOptions();
    }
  }, [isComparedSelectorOpen, updateComparedHelmReleasesOptions]);

  const comparedOptions = useComparedOptionsWithDisabled({
    selectedComparedOptions: comparedSelections,
    comparedOptions: comparedHelmReleasesOptions,
    maxCompared: MAX_COMPARED_RELEASES,
  });

  const baselineHelmReleasesOptions: MuiSelectionOption<string>[] =
    useBaselineHelmReleasesOptions(baselineSelection, helmReleases);

  const selectedComparedOptionsValues =
    useSelectedComparedOptionsValues(comparedSelections);

  return (
    <BaseComparisonSelection
      baselineSelectEvent={AnalyticEvents.DriftAnalysis.Helm.BaselineSelected}
      comparedSelectEvent={
        AnalyticEvents.DriftAnalysis.Helm.ComparedHelmSelected
      }
      singleSelectProps={{
        virtualizerProps: { estimateSize: () => 28 },
        error: isError ? <ErrorMessage refetch={refetch} /> : undefined,
        ariaLabel: AriaLabels.DriftAnalysis.Helm.HelmSelection.BaselineSelector,
        options: baselineHelmReleasesOptions,
        loading: isLoading,
        placeholder:
          baselineHelmReleasesOptions.length === 0 && !isLoading
            ? "Releases not found"
            : "Select comparison baseline",
        label: "Baseline Comparison Helm Release",
        value: baselineSelection || undefined,
        texts: { searchPlaceholder: "Search Helm Release" },
        disabled: baselineHelmReleasesOptions.length === 0 && !isError,
        externalOpen: isBaselineSelectorOpen,
        onClose: () => setIsBaselineSelectorOpen(false),
      }}
      advancedMultiSelectProps={{
        options: comparedOptions,
        label: "Compared Helm Releases",
        ariaLabel: AriaLabels.DriftAnalysis.Helm.HelmSelection.ComparedSelector,
        placeholder: "Select helm releases to compare",
        value: selectedComparedOptionsValues,
        disabled: isLoading || !helmReleases?.length || !baselineSelection,
        externalOpen: isComparedSelectorOpen,
        onClose: () => setIsComparedSelectorOpen(false),
      }}
      maxCompared={MAX_COMPARED_RELEASES}
      comparedOptionsLength={helmReleases?.length ?? 0}
    />
  );
};
