import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { muiTheme } from "@komodorio/design-system";

import { RbacRole } from "@/generated/auth";

export const DefaultRoleCell: React.FC<{
  params: GridRenderCellParams<RbacRole>;
}> = ({ params }) => {
  return (
    <Stack direction="row" columnGap="4px">
      <Typography variant="body2">{params.row.name}</Typography>
      {params.row.isDefault && (
        <Typography variant="body2" color={muiTheme.palette.text.secondary}>
          (default role)
        </Typography>
      )}
    </Stack>
  );
};
