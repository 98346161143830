import React, { ChangeEvent, ReactNode } from "react";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import MuiCheckbox from "@mui/material/Checkbox";

import { textStyle } from "../typography";

import { AriaLabels } from "@/shared/config/ariaLabels";

const OptionContainer = styled.div<{ containerMargin?: string }>`
  display: flex;
  align-items: center;
  margin: ${({ containerMargin }) => containerMargin || "0.3rem 0"};
`;

const OptionLabel = styled.label<{ disabled: boolean }>`
  display: block;
  color: ${({ disabled }) => (disabled ? "#9ea0a4" : "#3d4048")};
  ${textStyle}
`;

const Description = styled.div`
  color: #3d4048;
  margin-top: 0.3rem;
  font-size: 0.8rem;
  line-height: 1rem;
`;

const checkboxSelectorAriaLabel = AriaLabels.Common.CheckboxSelector;

const StyledMuiCheckbox = styled(MuiCheckbox)<{ $rightMargin?: string }>`
  && {
    margin-right: ${({ $rightMargin }) => $rightMargin || "0"};
    padding-top: 0;
    padding-bottom: 0;
    height: 12px;
    width: 12px;
  }
`;

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

export const CheckboxContainer: React.FC<{
  id: string;
  disabled?: boolean;
  checked: boolean;
  className?: string;
  handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  rightMargin?: string;
  containerMargin?: string;
  children?: React.ReactNode;
  indeterminate?: boolean;
}> = ({
  id,
  disabled,
  checked,
  className,
  handleCheckboxChange,
  rightMargin,
  containerMargin,
  children,
  indeterminate,
}) => (
  <OptionContainer containerMargin={containerMargin} className={className}>
    <StyledMuiCheckbox
      disabled={disabled}
      onClick={stopPropagation}
      onChange={handleCheckboxChange}
      checked={checked}
      size={"small"}
      $rightMargin={rightMargin}
      indeterminate={indeterminate}
      id={id}
      aria-label={checkboxSelectorAriaLabel}
    />
    {children}
  </OptionContainer>
);

export const OptionLabelContainer: React.FC<{
  id: string;
  disabled: boolean;
  fieldName: ReactNode;
  description?: string;
}> = ({ id, disabled, fieldName, description }) => (
  <Stack direction="row" alignItems="center">
    <OptionLabel htmlFor={id} disabled={disabled} onClick={stopPropagation}>
      {fieldName}
    </OptionLabel>
    {description && <Description>{description}</Description>}
  </Stack>
);

const Checkbox: React.FC<{
  id: string;
  disabled?: boolean;
  fieldName: ReactNode;
  checked: boolean;
  indeterminate?: boolean;
  handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  description?: string;
  className?: string;
  rightMargin?: string;
}> = ({
  id,
  disabled = false,
  fieldName,
  description,
  checked,
  handleCheckboxChange,
  className,
  rightMargin,
  indeterminate,
}) => (
  <CheckboxContainer
    id={id}
    disabled={disabled}
    checked={checked}
    handleCheckboxChange={handleCheckboxChange}
    className={className}
    rightMargin={rightMargin}
    indeterminate={indeterminate}
  >
    <div>
      <OptionLabelContainer
        id={id}
        disabled={disabled}
        fieldName={fieldName}
        description={description}
      />
    </div>
  </CheckboxContainer>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Checkbox;
