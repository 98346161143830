export const batchActionsAriaLabels = {
  mainContainer: "batch actions header container",
  clearSelectionButton: "clear selection button",
  actionButton: "action button",
  selectionOverview: "selection overview",
  batchActionsDialog: {
    mainContent: "batch actions dialog main content",
    title: "batch actions dialog title",
    subtitle: "batch actions dialog subtitle",
  },
};
