import React, { useEffect, useMemo, useState } from "react";
import { camelCase } from "lodash";

import { LinesLoader } from "../loaders/Line";
import { NoItemsContainer } from "../../Inspection/styles";
import { KubernetesPodsResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";

import { Container, StyledTable, TableFooter } from "./TableStyles";
import { ResourceListTableBody } from "./ResourceListTableBody";
import { PageSize } from "./PageSize";
import { Pagination } from "./Pagination";
import ResourceListTableHead, {
  InspectionTableHeader,
} from "./ResourceListTableHead";
import { SelectedRowsProvider } from "./SelectedRowsProvider";
import { ResourceListTableProps } from "./ResourceListTableProps";
import { useInitialInspectionHeaders } from "./hooks/useInitialInspectionHeaders";
import ResourceListTableSecondaryHeader from "./ResourceListTableSecondaryHeader";
import {
  useGetRawHeaders,
  useTableSortOrder,
  useToggleAllRowsSelection,
} from "./hooks/resourcesListTableHooks";
import { RelatedResourcesPanel } from "./styles";
import RelatedResourceListError from "./RelatedResourceListError";

import { useTableBodyProperties } from "@/components/common/ResourceListTable/hooks/useTableBodyProperties";
import { DEFAULT_COLUMN_BY_WHICH_TO_SORT } from "@/components/common/ResourceListTable/resourceListTableConstants";
import { useRowsOnPage } from "@/components/common/ResourceListTable/hooks/useRowsOnPage";
import { useRowsSelectedState } from "@/components/common/ResourceListTable/hooks/useRowsSelectedState";
import { InspectionBatchActionsContextProvider } from "@/components/common/ResourceListTable/batchActions/context/InspectionBatchActionsContext";
import { useRowSelectionForBatchActions } from "@/components/common/ResourceListTable/batchActions/hooks/useRowSelectionForBatchActions";
import { useIsBatchActionsModeOn } from "@/components/common/ResourceListTable/batchActions/hooks/useIsBatchActionsModeOn";
import { ResourceTableBatchActionsHeader } from "@/components/common/ResourceListTable/batchActions/components/ResourceTableBatchActionsHeader";
import { getResourceName } from "@/components/Inspection/utils/getResourceName";
import { DEFAULT_PAGE_SIZE } from "@/components/common/ResourceListTable/constants";

const ResourceListTableContent: React.FC<ResourceListTableProps> = ({
  rows,
  overridingColumns,
  resourceType,
  cluster,
  refreshResults,
  overrideRowClick,
  initialSortOrder,
  rowSelection,
  pageLabelAccessory,
  isPaginating,
  hidePageLabel = false,
  configurablePageSize = true,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  pageSize,
  setPageSize,
  includeActionsColumn = true,
  loadingProps,
  showLiveDataButton = false,
  enableDeletedPodsControls = true,
  errorMessage,
}) => {
  const headersOrder = useMemo(
    () =>
      Object.keys(rows?.[0] ?? []).filter(
        (key) => !["id", "deletedAt"].includes(key)
      ),
    [rows]
  );
  const [tableHeaders, setTableHeaders] = useState<InspectionTableHeader[]>([]);
  const rowSelectionForBatchActions = useRowSelectionForBatchActions();
  const rowSelectionToUse = rowSelection ?? rowSelectionForBatchActions;
  const isBatchActionsModeOn = useIsBatchActionsModeOn();

  const properties = useTableBodyProperties(
    overridingColumns,
    overrideRowClick
  );

  const rawHeaders = useGetRawHeaders({
    resourceType,
    overridingColumns,
    headersOrder: headersOrder,
  });

  const initialHeaders = useInitialInspectionHeaders(rawHeaders, resourceType);

  useEffect(() => {
    setTableHeaders(
      rowSelectionToUse
        ? [{ name: "Checkbox", displayName: "" }, ...initialHeaders]
        : initialHeaders
    );
  }, [initialHeaders, rowSelectionToUse]);

  const [page, setPage] = useState(0);
  const [localPageSize, setLocalPageSize] = useState(defaultPageSize);
  const onSetPageSize = (newPageSize: number) => {
    if (setPageSize) {
      if (newPageSize !== pageSize) {
        setPageSize(newPageSize);
        return;
      }
    }
    newPageSize !== localPageSize && setLocalPageSize(newPageSize);
  };
  const pageSizeToUse = pageSize ?? localPageSize;

  const sortOrder = useTableSortOrder({ initialSortOrder, resourceType });

  const [sortColumn, setSortColumn] = useState("");

  useEffect(() => {
    setSortColumn(
      initialSortOrder?.column ??
        resourceType.DefaultSort?.ColumnName ??
        DEFAULT_COLUMN_BY_WHICH_TO_SORT
    );
  }, [initialSortOrder?.column, resourceType.DefaultSort?.ColumnName]);

  const [sortDirection, setSortDirection] = useState(sortOrder);

  useEffect(() => {
    setSortDirection(sortOrder);
  }, [sortOrder]);

  const totalPages = useMemo(
    () => Math.max(Math.ceil(rows.length / pageSizeToUse), 1),
    [rows.length, pageSizeToUse]
  );

  const rowsOffset = useMemo(
    () => (rows.length === 0 ? 0 : (page * pageSizeToUse) % rows.length),
    [page, pageSizeToUse, rows.length]
  );

  const rowsOnPage = useRowsOnPage({
    sortColumn,
    tableHeaders,
    rows,
    sortDirection,
    resourceType,
    rowsOffset,
    pageSizeToUse,
  });

  const { allRowsSelected, someRowsSelected } = useRowsSelectedState(
    rowSelectionToUse,
    rowsOnPage
  );

  const toggleAllRowsSelection = useToggleAllRowsSelection({
    rowSelection: rowSelectionToUse,
    allRowsSelected,
    rowsOnPage,
  });

  const activeTableHeaders = useMemo(
    () => tableHeaders.filter((th) => !th.hide).map((th) => camelCase(th.name)),
    [tableHeaders]
  );

  // Go back to first page whenever rows are changed (i.e. filtered) or sorted.
  useEffect(() => {
    if (totalPages < page + 1) {
      setPage(0);
    }
  }, [rows, sortColumn, sortDirection, totalPages, page]);
  if (errorMessage) {
    return <RelatedResourceListError />;
  }
  if (loadingProps?.isLoading) {
    return <>{loadingProps.loaderElement ?? <LinesLoader marginTop="20%" />}</>;
  }

  const showDeletedPodsControls =
    resourceType.Kind === KubernetesPodsResource.Kind &&
    enableDeletedPodsControls;

  const tableSecondaryComponent = (
    <>
      {isBatchActionsModeOn ? (
        <ResourceTableBatchActionsHeader
          resourceName={getResourceName(resourceType)}
          currentPage={page}
          pageSize={pageSizeToUse}
        />
      ) : (
        <ResourceListTableSecondaryHeader
          clusterName={cluster}
          hidePageLabel={hidePageLabel}
          isPaginating={isPaginating}
          rowsOffset={rowsOffset}
          pageSize={pageSizeToUse}
          listSize={rows.length}
          accessory={pageLabelAccessory}
          showLiveDataButton={showLiveDataButton}
          showDeletedPodsControls={showDeletedPodsControls}
        />
      )}
    </>
  );

  const resourceListTable = (
    <>
      {tableSecondaryComponent}
      {rows.length === 0 && !loadingProps?.isLoading ? (
        <RelatedResourcesPanel>
          <NoItemsContainer>There are no items to show</NoItemsContainer>
        </RelatedResourcesPanel>
      ) : (
        <Container>
          <StyledTable>
            <ResourceListTableHead
              headers={tableHeaders}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              sortColumn={sortColumn}
              setSortColumn={setSortColumn}
              setTableHeaders={setTableHeaders}
              kubernetesResource={resourceType}
              includeColumnSelector={includeActionsColumn}
              allRowsSelection={
                rowSelectionToUse?.includeAllRowsSelection
                  ? {
                      allRowsSelected,
                      someRowsSelected,
                      onAllRowsSelection: toggleAllRowsSelection,
                    }
                  : undefined
              }
            />
            <ResourceListTableBody
              tableRows={rowsOnPage}
              headers={activeTableHeaders}
              resourceType={resourceType}
              clusterName={cluster}
              properties={properties}
              refreshResults={refreshResults}
              includeActionsColumn={includeActionsColumn}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
            />
          </StyledTable>
          <TableFooter>
            {configurablePageSize && (
              <PageSize onChange={onSetPageSize} defaultPageSize={pageSize} />
            )}
            <Pagination page={page} setPage={setPage} totalPages={totalPages} />
          </TableFooter>
        </Container>
      )}
    </>
  );

  return rowSelectionToUse ? (
    <SelectedRowsProvider rowSelectionController={rowSelectionToUse}>
      {resourceListTable}
    </SelectedRowsProvider>
  ) : (
    resourceListTable
  );
};

export const ResourceListTable: React.FC<ResourceListTableProps> = (props) => {
  return (
    <InspectionBatchActionsContextProvider
      clusterName={props.cluster}
      resourceType={props.resourceType}
      refreshResultsCb={props.refreshResults}
    >
      <ResourceListTableContent {...props} />
    </InspectionBatchActionsContextProvider>
  );
};
