import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import { useResourcesApiClient } from "@/shared/hooks/resources-api/client/apiClient";
import {
  AccountServiceAttributes,
  AccountServiceAttributesApiApiV1AccountServiceAttributesGetRequest,
  apiV1AccountServiceAttributesGetUrl,
} from "@/generated/resourcesApi";

const PATH = "/api/v1/account-service-attributes";

const getServiceAttributesForAccount = async (
  apiClient: AxiosInstance,
  params: AccountServiceAttributesApiApiV1AccountServiceAttributesGetRequest | null
): Promise<AccountServiceAttributes[]> => {
  if (!params) {
    return [];
  }
  const { data } = await apiClient.get(
    apiV1AccountServiceAttributesGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};
export const useGetServiceAttributesForAccountRest = (
  shouldUseMigration: boolean
) => {
  const apiClient = useResourcesApiClient();
  const params: AccountServiceAttributesApiApiV1AccountServiceAttributesGetRequest | null =
    shouldUseMigration
      ? {
          fields: ["displayName", "keyName", "mappings"],
          isServiceGroup: true,
        }
      : null;
  const { data, refetch } = useQuery([PATH, params], () =>
    getServiceAttributesForAccount(apiClient, params)
  );
  return {
    data: { service_attributes_for_account: data ? data : [] },
    refetch,
  };
};
