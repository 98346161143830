import { useGetRbacPolicyActions } from "@/shared/hooks/auth-service/client/rbacActions/useGetRbacPolicyActions";

// TODO Lior: fetch also custom-actions
export const useActions = () => {
  const { data: globalActions, isLoading: isLoadingGlobalActions } =
    useGetRbacPolicyActions();

  return {
    isLoading: isLoadingGlobalActions,
    globalActions,
  };
};
