/* eslint-disable max-lines */
import {
  MouseEventHandler,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ifProp } from "styled-tools";

import { ChevronDown16 } from "../../../icons";
import { theme } from "../../../theme";
import { InputContainer } from "../Input/InputContainer";
import { MenuList } from "../MenuList/MenuList";
import { Popover } from "../Popover/Popover";
import { Option } from "../Select/Option";
import { OptionType, SelectProps } from "../Select/Select";
import { Tag } from "../Tag/Tag";
import { Typography } from "../Typography/Typography";

/** @deprecated */
export interface MultiSelectProps<T>
  extends Omit<SelectProps<T>, "value" | "onChange"> {
  value?: OptionType<T>[];
  onChange?: (selected: OptionType<T>[]) => void;
  tagsColor?: string;
  enableDeleteTags?: boolean;
  enableBreakLines?: boolean;
  enableSelectAll?: boolean;
  allSelectLabel?: string;
  ariaLabel?: string;
}

const COLUMN_GAP = 8;
const Tags = styled.button.attrs({ type: "button" })<{
  enableBreakLines: boolean;
  width: string;
}>`
  border: none;
  background-color: unset;
  padding: unset;

  position: relative;
  display: flex;
  gap: 0.3rem ${COLUMN_GAP}px;
  ${ifProp(
    "enableBreakLines",
    `flex-wrap: wrap;`,
    `white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;`
  )}
  cursor: pointer;
  width: ${({ width }) => `calc(${width} - 2.3rem)`};
  align-items: center;

  :focus {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const HideableTag = styled((props) => <Tag {...props} />)`
  ${ifProp("hide", "visibility: hidden;")}
`;

const HIDDEN_COUNT_WIDTH = 14;
const HiddenCount = styled(Typography).attrs({ size: "medium" })<{
  leftPos: string;
}>`
  position: absolute;
  width: ${HIDDEN_COUNT_WIDTH}px;
  left: ${({ leftPos }) => leftPos};
`;

const ClickableLabel = styled(Typography)`
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

/**
 * @deprecated Use MUI Autocomplete or Select instead.
 */
export const MultiSelect = <T,>({
  width = "100%",
  listMaxHeight,
  listZIndex = "0",
  disabled,
  options,
  value = [],
  onChange,
  placeholder,
  tagsColor,
  allSelectLabel,
  enableDeleteTags = false,
  enableBreakLines = false,
  enableSelectAll = false,
  searchable = true,
  ariaLabel,
  onClose,
  ...rest
}: MultiSelectProps<T>) => {
  const { size = "small" } = rest;
  const [openList, setOpenList] = useState(false);
  const [divWidth, setDivWidth] = useState(0);
  const divWidthRef = useRef<HTMLDivElement>(null);
  const isRelativeWidth = width.includes("%");
  const widthAsString = isRelativeWidth ? `${divWidth}px` : width;

  useEffect(() => {
    setDivWidth(divWidthRef.current?.clientWidth || 0);
  }, []);
  const optionClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    const optionLabelText =
      e.currentTarget.querySelector("#option-label").textContent;

    const selected = options.find((o) => o.label === optionLabelText);
    if (selected && onChange) {
      const isChecked = value.some((v) => v.label === selected.label);
      if (isChecked && value) {
        onChange(value.filter((v) => v.label !== selected.label));
      } else {
        onChange(value ? [...value, selected] : [selected]);
      }
    }
  };

  const inputClickHandler = () => setOpenList(!openList);
  const handleClose = () => {
    setOpenList(false);
    onClose?.();
  };

  const [searchPhrase, setSearchPhrase] = useState("");
  const filteredOptions = useMemo(
    () =>
      options.filter(
        (o) =>
          !searchable ||
          o.label.toLowerCase().includes(searchPhrase.toLowerCase())
      ),
    [options, searchPhrase, searchable]
  );
  const enabledOptions = useMemo(
    () => options.filter((o) => !o.disabled),
    [options]
  );
  const enabledValues = useMemo(
    () => value.filter((o) => !o.disabled),
    [value]
  );
  const tagsEl = useRef<HTMLButtonElement>(null);
  const [overflow, setOverflow] = useState({
    index: value.length,
    width: "0px",
  });
  useLayoutEffect(() => {
    const ref = tagsEl?.current;
    if (ref && !enableBreakLines) {
      let sumWidth = HIDDEN_COUNT_WIDTH;
      for (let i = 0; i < ref.children.length; i++) {
        const childWidth = ref.children[i].clientWidth + COLUMN_GAP;
        if (sumWidth + childWidth > ref.clientWidth) {
          return setOverflow({
            index: i,
            width: `${sumWidth - HIDDEN_COUNT_WIDTH}px`,
          });
        }
        sumWidth += childWidth;
      }
    }
    setOverflow({ index: value.length, width: "0px" });
  }, [enableBreakLines, value]);
  const inputValueComponent = (
    <Tags
      disabled={disabled}
      ref={tagsEl}
      enableBreakLines={enableBreakLines}
      width={widthAsString}
    >
      {!value.length ? (
        <Typography size={size} color={theme.foreground.fgDisabled}>
          {placeholder}
        </Typography>
      ) : allSelectLabel && enabledValues.length === enabledOptions.length ? (
        <Typography size={size}>{allSelectLabel}</Typography>
      ) : (
        <>
          {value.map((val, i) => (
            <HideableTag
              key={val.label}
              size="small"
              color={disabled ? theme.background.bgGrayDark : tagsColor}
              onDelete={
                enableDeleteTags && !disabled && !val.disabled
                  ? () => onChange?.(value.filter((v) => v.label !== val.label))
                  : undefined
              }
              hide={i >= overflow.index}
            >
              <Typography
                color={disabled ? theme.foreground.fgSubtle : undefined}
              >
                {val.label}
              </Typography>
            </HideableTag>
          ))}
          {value.length - overflow.index > 0 && (
            <HiddenCount
              leftPos={overflow.width}
              color={disabled ? theme.foreground.fgSubtle : undefined}
            >
              +{value.length - overflow.index}
            </HiddenCount>
          )}
        </>
      )}
    </Tags>
  );

  return (
    <div aria-label="select multiple values">
      <Popover
        isOpen={openList}
        handleClose={handleClose}
        maxHeight={listMaxHeight}
        containerStyle={{ zIndex: listZIndex }}
        align={"start"}
        content={
          <MenuList
            width={widthAsString}
            onSearch={
              searchable ? (e) => setSearchPhrase(e.target.value) : undefined
            }
            aria-label={ariaLabel}
          >
            {enableSelectAll && (
              <Row>
                {enabledValues.length === enabledOptions.length ? (
                  <ClickableLabel
                    color={theme.button.primary.bg}
                    onClick={() => {
                      onChange && onChange(value.filter((o) => o.disabled));
                    }}
                  >
                    Deselect All
                  </ClickableLabel>
                ) : (
                  <ClickableLabel
                    color={theme.button.primary.bg}
                    onClick={() => {
                      onChange &&
                        onChange([
                          ...value.filter((o) => o.disabled),
                          ...enabledOptions,
                        ]);
                    }}
                  >
                    Select All
                  </ClickableLabel>
                )}
                <Typography>{enabledValues.length} Selected</Typography>
              </Row>
            )}

            {filteredOptions.map(({ label, value: _, ...rest }, i) => (
              <Option
                key={i}
                variant="multi"
                selected={value.some((v) => v.label === label)}
                onClick={optionClickHandler}
                {...rest}
              >
                {label}
              </Option>
            ))}
          </MenuList>
        }
      >
        <InputContainer
          readOnly
          width={widthAsString}
          disabled={disabled}
          trailingElement={<ChevronDown16 />}
          onClick={inputClickHandler}
          {...rest}
        >
          {inputValueComponent}
        </InputContainer>
      </Popover>
      <div ref={divWidthRef} style={{ width }} />
    </div>
  );
};

MultiSelect.defaultProps = {
  size: "small",
  width: "100%",
  value: [],
  disabled: false,
  tagsColor: theme.background.bgGray,
  enableDeleteTags: false,
  enableBreakLines: false,
  searchable: true,
};
