import React from "react";
import styled from "styled-components";

import { ResourceUsageSingleMetric } from "@/components/k8sAddons/components/table/ResourceUsageBar/ResourceUsageSingleMetric";
import { MetricType } from "@/components/Metrics/types";
import { ResourceData } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageTypes";

const Container = styled.div`
  width: 100%;
`;

type ResourceUsageCellProps = {
  resourceUsageData: ResourceData;
  title: string;
  type: MetricType;
  percentage: number;
};

export const ResourceUsageCell: React.FC<ResourceUsageCellProps> = ({
  resourceUsageData,
  title,
  type,
  percentage,
}) => {
  return (
    <Container>
      <ResourceUsageSingleMetric
        resourceData={resourceUsageData}
        title={title}
        type={type}
        percentage={percentage}
      />
    </Container>
  );
};
