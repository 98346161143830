import { getAppConfig } from "../../../config/appConfig";
import { useCreateAxiosClient } from "../../useCreateAxiosClient";

const getAiAPIBasePath = (): string => {
  return getAppConfig().aiServiceAPIServerURL;
};

export const useAiApiClient = () => {
  return useCreateAxiosClient(getAiAPIBasePath());
};
