import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import MoreVert from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import styled from "styled-components";

type ActionType =
  | {
      name: string;
      label: string | React.ReactNode;
      enabled?: boolean;
      icon?: React.ReactNode;
      onClick?: (actionName: string) => void;
      ariaLabel?: string;
      tooltip?: React.ReactNode;
    }
  | "-";

interface ActionsMenuButtonProps {
  icon?: React.ReactNode;
  actions: ActionType[];
}

const Contents = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ActionsMenuButton: React.FC<ActionsMenuButtonProps> = ({
  icon,
  actions,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClicked = (
    actionName: string,
    onClick?: (actionName: string) => void
  ) => {
    if (onClick) {
      onClick(actionName);
      onClose();
    }
  };

  const MenuIcon = icon ?? <MoreVert color="action" />;

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        {MenuIcon}
      </IconButton>

      {actions.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Fade}
        >
          {actions.map((action, idx) => {
            if (action === "-") {
              return <Divider key={idx} />;
            }

            const {
              name,
              icon: Icon,
              label,
              onClick,
              enabled = true,
              ariaLabel,
              tooltip,
            } = action;
            return (
              <Tooltip
                title={tooltip}
                placement="bottom"
                key={`tooltip-${name}`}
              >
                <span>
                  <MenuItem
                    key={name}
                    onClick={() => onMenuItemClicked(name, onClick)}
                    dense={true}
                    disabled={!enabled}
                    aria-label={ariaLabel}
                  >
                    <Contents>
                      {Icon}
                      {label}
                    </Contents>
                  </MenuItem>
                </span>
              </Tooltip>
            );
          })}
        </Menu>
      )}
    </>
  );
};
