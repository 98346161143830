import React, { useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Controller, useForm } from "react-hook-form";
import {
  LightTooltip,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

import { apiGroupsOptions } from "@/components/Settings/Actions/Form/apiGroupsOptions";
import { resourcesOptions } from "@/components/Settings/Actions/Form/resourcesOptions";
import { rbacRuleVerbsList } from "@/components/Settings/Actions/Form/types";
import { useAgentsContext } from "@/shared/hooks/agents/context/useAgentsContext";
import useNamespacesAtm from "@/shared/hooks/ATM/useNamespacesAtm";
import {
  CanISelection,
  CanISelections,
  SelectorKey,
  selectors,
} from "@/components/Settings/Users/Permissions/CanI/useCanI";
import { Status } from "@/shared/hooks/ATM/useAtmRequest";
import { ariaLabels } from "@/components/Settings/Users/Permissions/constants";

const SELECTOR_WIDTH = "19.2%";
const WIDE_SELECTOR_WIDTH = "25.2%";
const NARROW_SELECTOR_WIDTH = "13.2%";
const MAX_HEIGHT = "350px";
const REQUIRED_FIELD_ERROR = "This field is required";
const SELECT_POPOVER_PROPS = { maxHeight: MAX_HEIGHT };

interface ArgumentsSectionProps {
  setters: {
    [selectors.apiGroup]: (value: CanISelection) => void;
    [selectors.resource]: (value: CanISelection) => void;
    [selectors.verb]: (value: CanISelection) => void;
    [selectors.cluster]: (value: CanISelection) => void;
    [selectors.namespace]: (value: CanISelection) => void;
  };
  values: CanISelections;
  onRequest: () => void;
}

const RESOURCE_OPTIONS = resourcesOptions.map((resource) => ({
  label: resource,
  value: resource,
}));

const VERBS_OPTIONS = rbacRuleVerbsList.map((verb) => ({
  label: verb,
  value: verb,
}));

export const ArgumentsSection: React.FC<ArgumentsSectionProps> = ({
  setters,
  values,
  onRequest,
}) => {
  const { agents } = useAgentsContext();
  const { control, setError, clearErrors } = useForm();

  const clustersOptions = useMemo(
    () =>
      agents?.activeAgents.map((agent) => ({
        value: agent.id,
        label: agent.clusterName,
      })) || [],
    [agents]
  );

  const { result: namespacesData, status } = useNamespacesAtm({
    agentId: values[selectors.cluster]?.value ?? "",
    cluster: values[selectors.cluster]?.label ?? "",
    refetchUntilCompletion: true,
  });

  const namespacesOptions = useMemo(
    () =>
      namespacesData?.data.map(({ name }) => ({
        value: name,
        label: name,
      })) || [],
    [namespacesData]
  );

  const handleSelectChange = useCallback(
    (key: SelectorKey) => (selected: CanISelection) => {
      if (!selected) {
        setError(
          key,
          { message: REQUIRED_FIELD_ERROR },
          { shouldFocus: false }
        );
      } else {
        clearErrors(key);
      }
      setters[key](selected);

      // In case we change the cluster, we need to clear the namespace
      if (key === selectors.cluster) {
        setters[selectors.namespace](undefined);
      }
    },
    [clearErrors, setters, setError]
  );

  const handleFormSubmit = useCallback(() => {
    let hasError = false;
    Object.entries(values).forEach(([key, value]) => {
      if (!value) {
        hasError = true;
        setError(
          key,
          { message: REQUIRED_FIELD_ERROR },
          { shouldFocus: false }
        );
      }
    });

    if (!hasError) {
      onRequest();
    }
  }, [values, onRequest, setError]);

  const handleClear = useCallback(() => {
    Object.keys(values).forEach((key: string) =>
      setters[key as SelectorKey](undefined)
    );
  }, [setters, values]);

  const isNamespaceDisabled =
    status === Status.Initializing || namespacesOptions.length === 0;

  return (
    <Stack rowGap="24px" aria-label={ariaLabels.cani.sections.arguments}>
      <Stack
        direction="row"
        columnGap={"1%"}
        useFlexGap
        sx={{ flexWrap: "wrap" }}
      >
        <Controller
          name={selectors.cluster}
          control={control}
          render={({ fieldState }) => (
            <SingleSelect
              ariaLabel={ariaLabels.cani.form.cluster}
              options={clustersOptions}
              value={values[selectors.cluster]}
              onChange={handleSelectChange(selectors.cluster)}
              label={"Cluster"}
              width={WIDE_SELECTOR_WIDTH}
              error={fieldState.error?.message}
              enableSearch
              selectPopoverProps={SELECT_POPOVER_PROPS}
            />
          )}
        />
        <Controller
          name={selectors.namespace}
          control={control}
          render={({ fieldState }) => (
            <LightTooltip
              title={
                isNamespaceDisabled ? "Waiting for a cluster to be picked" : ""
              }
            >
              <Box width={SELECTOR_WIDTH}>
                <SingleSelect
                  ariaLabel={ariaLabels.cani.form.namespace}
                  options={namespacesOptions}
                  value={values[selectors.namespace]}
                  onChange={handleSelectChange(selectors.namespace)}
                  label={"Namespace"}
                  error={fieldState.error?.message}
                  disabled={isNamespaceDisabled}
                  enableSearch
                  selectPopoverProps={SELECT_POPOVER_PROPS}
                />
              </Box>
            </LightTooltip>
          )}
        />
        <Controller
          name={selectors.resource}
          control={control}
          render={({ fieldState }) => (
            <SingleSelect
              ariaLabel={ariaLabels.cani.form.resource}
              options={RESOURCE_OPTIONS}
              value={values[selectors.resource]}
              onChange={handleSelectChange(selectors.resource)}
              label={"Resource"}
              width={SELECTOR_WIDTH}
              enableFreeSolo
              enableSearch
              selectPopoverProps={SELECT_POPOVER_PROPS}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={selectors.apiGroup}
          control={control}
          render={({ fieldState }) => (
            <SingleSelect
              ariaLabel={ariaLabels.cani.form.apiGroup}
              options={apiGroupsOptions}
              value={values[selectors.apiGroup]}
              onChange={handleSelectChange(selectors.apiGroup)}
              label={"API Group"}
              width={SELECTOR_WIDTH}
              enableSearch
              selectPopoverProps={SELECT_POPOVER_PROPS}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={selectors.verb}
          control={control}
          render={({ fieldState }) => (
            <SingleSelect
              ariaLabel={ariaLabels.cani.form.verb}
              options={VERBS_OPTIONS}
              value={values[selectors.verb]}
              onChange={handleSelectChange(selectors.verb)}
              label={"Verb"}
              width={NARROW_SELECTOR_WIDTH}
              enableFreeSolo
              enableSearch
              error={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack direction="row" columnGap="8px" alignSelf="flex-end">
        <Button variant="outlined" onClick={handleClear}>
          Clear All
        </Button>
        <Button variant="contained" color="primary" onClick={handleFormSubmit}>
          Can I...?
        </Button>
      </Stack>
    </Stack>
  );
};
