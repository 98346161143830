import React from "react";
import styled from "styled-components";

import { OverProvisionedClusterSupportingData } from "@/generated/reliabilityApi";
import {
  CountedDataContent,
  CountedDataValue,
} from "@/components/reliability/components/shared/CountedData/CountedDataContent";
import { compactOptions, formatNumber } from "@/shared/utils/formatNumber";

const MarginedCountedDataContent = styled(CountedDataContent)`
  margin: 16px 0 32px;
`;

type OverProvisionedCountedDataOverviewProps = {
  violationData: OverProvisionedClusterSupportingData;
};

export const OverProvisionedCountedDataOverview: React.FC<
  OverProvisionedCountedDataOverviewProps
> = (props) => {
  const { violationData } = props;
  const items: CountedDataValue[] = [
    {
      title: `Potential CPU waste (${
        violationData.violatingCPUPercentage ?? 0
      }%)`,
      value: `${formatNumber(
        violationData.potentialCPUSavings,
        compactOptions
      )}/${formatNumber(violationData.cpuCapacity, compactOptions)}`,
      valueAnnotation: "(core hours)",
    },
    {
      title: `Potential memory waste (${
        violationData.violatingMemoryPercentage ?? 0
      }%)`,
      value: `${formatNumber(
        violationData.potentialMemorySavings,
        compactOptions
      )}/${formatNumber(violationData.memoryCapacity, compactOptions)}`,
      valueAnnotation: "(GiB hours)",
    },
    {
      title: "Potential Cost Savings",
      value: `$ ${formatNumber(
        violationData.potentialSavings,
        compactOptions
      )}`,
    },
  ];
  return <MarginedCountedDataContent items={items} />;
};
