import { HealthType } from "../hooks/useHealthType";

import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";
import { CheckType } from "@/generated/reliabilityApi";
import { ReliabilityRoutes } from "@/components/reliability/constants/reliabilityConstants";

export const checkTypeToViewNameMap: Partial<
  Record<CheckType, DatadogViewNamesEnum>
> = {
  [CheckType.CertificateExpiration]:
    DatadogViewNamesEnum.reliabilityDrawerCertificateExpiration,
  [CheckType.HpaMax]:
    DatadogViewNamesEnum.reliabilityDrawerExpiredHpaReachedMax,
  [CheckType.SinglePointOfFailure]:
    DatadogViewNamesEnum.reliabilityDrawerSinglePointOfFailure,
  [CheckType.ThrottledCpu]: DatadogViewNamesEnum.reliabilityDrawerCpuThrottled,
  [CheckType.RestartingContainers]:
    DatadogViewNamesEnum.reliabilityDrawerContainerRestarts,
  [CheckType.SyntheticDegradedService]:
    DatadogViewNamesEnum.reliabilityDrawerDegradedService,
  [CheckType.UnderProvisionedWorkloads]:
    DatadogViewNamesEnum.reliabilityDrawerUnderProvisionedWorkloads,
  [CheckType.RequestsLimitsRatio]:
    DatadogViewNamesEnum.reliabilityDrawerHighRequestsToLimitRatio,
  [CheckType.SyntheticNodePressure]:
    DatadogViewNamesEnum.reliabilityDrawerNodePressure,
  [CheckType.NoisyNeighbor]:
    DatadogViewNamesEnum.reliabilityDrawerNoisyNeighbor,
  [CheckType.SyntheticEolDeprecationOutdated]:
    DatadogViewNamesEnum.reliabilityDrawerEolDeprecationOutdated,
  [CheckType.DeprecatedApis]:
    DatadogViewNamesEnum.reliabilityDrawerDeprecatedApi,
  [CheckType.KubernetesEndOfLife]:
    DatadogViewNamesEnum.reliabilityDrawerKubernetesEndOfLife,
  [CheckType.DeploymentMissingReplicas]:
    DatadogViewNamesEnum.reliabilityDrawerDeploymentMissingReplicas,
  [CheckType.ExternalDnsNotSynced]:
    DatadogViewNamesEnum.reliabilityDrawerExternalDnsNotSynced,
  [CheckType.HpaMinAvailability]:
    DatadogViewNamesEnum.reliabilityDrawerHpaMinAvailability,
  [CheckType.LivenessProbeMissing]:
    DatadogViewNamesEnum.reliabilityDrawerLivenessProbeMissing,
  [CheckType.CpuLimitsMissing]:
    DatadogViewNamesEnum.reliabilityDrawerCpuLimitsMissing,
  [CheckType.CpuRequestsMissing]:
    DatadogViewNamesEnum.reliabilityDrawerCpuRequestsMissing,
  [CheckType.MissingHpa]: DatadogViewNamesEnum.reliabilityDrawerMissingHpa,
  [CheckType.MemoryLimitsMissing]:
    DatadogViewNamesEnum.reliabilityDrawerMemoryLimitsMissing,
  [CheckType.MemoryRequestsMissing]:
    DatadogViewNamesEnum.reliabilityDrawerMemoryRequestsMissing,
  [CheckType.MissingPdb]: DatadogViewNamesEnum.reliabilityDrawerMissingPdb,
  [CheckType.MissingTopologySpreadConstraint]:
    DatadogViewNamesEnum.reliabilityDrawerMissingTopologySpreadConstraint,
  [CheckType.PriorityClassNotSet]:
    DatadogViewNamesEnum.reliabilityDrawerPriorityClassNotSet,
  [CheckType.ReadinessProbeMissing]:
    DatadogViewNamesEnum.reliabilityDrawerReadinessProbeMissing,
  [CheckType.ScaleDownImpact]:
    DatadogViewNamesEnum.reliabilityDrawerScaleDownImpact,
  [CheckType.OverProvisionedCluster]:
    DatadogViewNamesEnum.reliabilityDrawerOverProvisionedCluster,
};

export const getFullPageViewName = (
  healthType: HealthType,
  currentTab?: string
): DatadogViewNamesEnum => {
  if (healthType === HealthType.WORKLOAD) {
    if (currentTab === ReliabilityRoutes.bestPractices) {
      return DatadogViewNamesEnum.reliabilityBestPractices;
    }
    return DatadogViewNamesEnum.reliabilityWorkloadHealth;
  }
  return DatadogViewNamesEnum.reliabilityInfrastructureHealth;
};

export const staticReliabilityContext: DatadogContext = {
  feTeam: "operate",
  beTeam: "operate",
};
