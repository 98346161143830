import { subHours } from "date-fns";
import React, { useEffect } from "react";

import {
  createTimeWindowKey,
  Timeframe,
  TimeWindow,
} from "../../../shared/types/TimeWindow";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import useEventsViewEvents from "../../eventsView/useEventsViewEvents";
import {
  ImpactfulEventsQuery,
  InvestigateEventsQuery,
  JobFragment,
} from "../../../generated/graphql";
import { CustomEventWithServiceId } from "../EventGroup/customEvent/types";
import { AgentDowntimeRawKubernetesAgentInfo } from "../EventGroup/agentDowntimeEvent/agentDowntimeTypes";
import { MonitorsResponseData } from "../EventGroup/workflowIssues/useWorkflowIssues";
import {
  ConfigMapEventsResponseDataInner,
  HpaEventsResponseDataInner,
  NodeEventsResponseDataInner,
  PdbEventsResponseDataInner,
  PodGroups,
} from "../../../generated/resourcesApi";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import useServiceViewEvents from "./useServiceViewEvents";

const DEFAULT_NUMBER_OF_HOURS_PAGINATION = 12;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export function splitToTimeWindow(
  timeWindow: TimeWindow,
  eventsPaginationHoursChunk: number | undefined
): Pick<TimeWindow, "start" | "end">[] {
  const HOURS_TO_PAGINATE =
    eventsPaginationHoursChunk || DEFAULT_NUMBER_OF_HOURS_PAGINATION;
  const diffHours =
    (timeWindow.end.getTime() - timeWindow.start.getTime()) / (1000 * 3600);
  const split = Math.ceil(diffHours / HOURS_TO_PAGINATE);
  if (split === 1) {
    return [timeWindow];
  } else {
    const timeWindows = [];
    let latEndWindowTime = timeWindow.end;
    for (let i = 0; i < split; i++) {
      const newTimeWindow = {
        start: subHours(latEndWindowTime, HOURS_TO_PAGINATE),
        end: latEndWindowTime,
        timeframe: Timeframe.LastHour,
      };
      latEndWindowTime = subHours(latEndWindowTime, HOURS_TO_PAGINATE);
      timeWindows.push(newTimeWindow);
    }
    return timeWindows;
  }
}

export interface EventsWindow {
  impactfulEvents: ImpactfulEventsQuery | InvestigateEventsQuery;
  configMapEvents: ConfigMapEventsResponseDataInner[];
  workflowResults: MonitorsResponseData;
  hpaEvents: HpaEventsResponseDataInner[];
  pdbEvents: PdbEventsResponseDataInner[];
  agentDowntimeEvents?: AgentDowntimeRawKubernetesAgentInfo[];
  jobEvents?: JobFragment[];
  nodeCreatedEvents: NodeEventsResponseDataInner[];
  nodeTerminatedEvents: NodeEventsResponseDataInner[];
  customEvents: CustomEventWithServiceId[];
  groupedPodEvents: PodGroups[];
}

export interface EventPagesMap {
  fullTimeWindow: TimeWindow;
  pages: Record<string, EventsWindow>;
}

interface EventViewEventsPaginationProps {
  pageTimeWindow: Pick<TimeWindow, "start" | "end">;
  setEventPagesMap: React.Dispatch<React.SetStateAction<EventPagesMap>>;
}
interface ServiceViewEventsPaginationProps {
  timeWindow: Pick<TimeWindow, "start" | "end">;
  index: number;
  serviceId: string;
  selectedResourceNamesForEvents: { [key: string]: string[] };
  clusterName: string | undefined;
  namespace: string | undefined;
  setEventWindowMap: (page: EventsWindow, index: number) => void;
}
export const EventsPagination: React.FC<EventViewEventsPaginationProps> = ({
  pageTimeWindow,
  setEventPagesMap,
}) => {
  const page = useEventsViewEvents(pageTimeWindow);
  useEffect(() => {
    if (page) {
      const key = createTimeWindowKey(pageTimeWindow);
      setEventPagesMap(({ fullTimeWindow, pages }) => ({
        fullTimeWindow,
        pages: { ...pages, [key]: page },
      }));
    }
  }, [page, setEventPagesMap, pageTimeWindow]);

  return null;
};

export const ServiceViewEventsPagination: React.FC<
  ServiceViewEventsPaginationProps
> = ({
  timeWindow,
  index,
  serviceId,
  selectedResourceNamesForEvents,
  setEventWindowMap,
  clusterName,
  namespace,
}) => {
  const page = useServiceViewEvents(
    serviceId,
    timeWindow,
    clusterName,
    namespace,
    selectedResourceNamesForEvents
  );

  useEffect(() => {
    if (page) {
      setEventWindowMap(page, index);
    }
  }, [page, index, setEventWindowMap]);
  return null;
};
