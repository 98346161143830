import { muiTheme } from "@komodorio/design-system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { EmptyState } from "@komodorio/design-system/komodor-ui";
import Skeleton from "@mui/material/Skeleton";
import { ReactDiffViewerStylesOverride } from "react-diff-viewer";

import { ResourceBreadcrumbs } from "../ResourceBreadcrumbs";
import { ModalHeader } from "../Services/Attributes/ModalHeader";

import { ObjectDiff, ObjectDiffAsYaml } from "@/components/common/ObjectDiff";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

const StyledCustomOptionSelection = styled(ResourceBreadcrumbs)`
  flex-grow: 1;
`;

const overridingStyles: ReactDiffViewerStylesOverride = {
  gutter: {
    borderLeft: `1px solid ${muiTheme.palette.divider}`,
  },
  diffContainer: {
    border: `1px solid ${muiTheme.palette.divider}`,
    borderRadius: "4px",
    "-webkit-mask-image": "-webkit-radial-gradient(white, black)",
  },
  contentText: {
    maxWidth: "550px",
    overflowWrap: "break-word",
  },
};

export type SeeAllModalParams = {
  title: string;
  content: string;
};

export type DiffModalParams = {
  title: string;
  comparedUid: string;
  oldObj: unknown;
  newObj: unknown;
};

type DiffModalProps = DiffModalParams & {
  baselineUid: string;
  isYaml?: boolean;
  isLoading?: boolean;
  emptyStateTitle?: React.ReactNode;
  onClose: () => void;
};

export const DiffModal: React.FC<DiffModalProps> = ({
  title,
  baselineUid,
  comparedUid,
  oldObj,
  isLoading = false,
  emptyStateTitle,
  newObj,
  isYaml = false,
  onClose,
}) => {
  const baseline = parseKomodorUid(baselineUid);
  const compared = parseKomodorUid(comparedUid);
  if (!baseline || !compared) {
    return null;
  }
  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{ sx: { height: "606px" } }}
    >
      <ModalHeader title={title} onClose={onClose} />
      <DialogContent dividers={true}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" marginBottom="9px">
          <Box display="flex" gap="9px" flexGrow={1} paddingRight="16px">
            <Typography variant="h6">Baseline:</Typography>
            <ResourceBreadcrumbs
              clusterName={baseline.cluster}
              namespace={baseline.namespace}
              name={baseline.name}
            />
          </Box>
          <StyledCustomOptionSelection
            clusterName={compared.cluster}
            namespace={compared.namespace}
            name={compared.name}
          />
        </Box>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height="50%" />
        ) : emptyStateTitle ? (
          <EmptyState title={emptyStateTitle} />
        ) : isYaml ? (
          <ObjectDiffAsYaml
            oldObj={oldObj}
            newObj={newObj}
            overridingStyles={overridingStyles}
          />
        ) : (
          <ObjectDiff
            oldObj={oldObj}
            newObj={newObj}
            overridingStyles={overridingStyles}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
