import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";

import { DriftMetricsLoadingStateIds } from "./consts";
import { DriftWorkloadsMetricsGraph } from "./graphs/WorkloadsGraph/DriftWorkloadsMetricsGraph";
import { WorkloadsMetricsPerContainer } from "./graphs/ContainersGraph/WorkloadsMetricsPerContainer";
import { MetricsNotSupportedSection } from "./graphs/MetricsNotSupportedSection";
import { EmptyStateMetricsGraphs } from "./graphs/WorkloadsGraph/EmptyStateMetricsGraphs";

import {
  MetricsContainer,
  MainContainer,
  GraphsContainer,
} from "@/components/ResourceView/tabs/MetricsTab/styles";
import { RowContainer } from "@/components/Metrics/MetricsPreferences/MetricsPreferences";
import { workloadsMetricsAriaLabels } from "@/components/ResourceView/tabs/WorkloadMetricsTab/ariaLabel";
import { Timeframe } from "@/shared/types/TimeWindow";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { MetricsAggregationSelector } from "@/components/Metrics/MetricsPreferences/MetricsAggregationSelector";
import { MetricsControlsSelector } from "@/components/ResourceView/common/Metrics/MetricsControlsSelector";

const GraphHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MetricsComparisonTabContent: React.FC = () => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();

  const syncId = baselineSelection?.value ?? "";
  const {
    timeWindow,
    setTimeWindow,
    selectedAggregationMetric,
    setSelectedAggregationMetric,
  } = useWorkloadMetricsTabContext();

  const baselineKomodorUid = useMemo(
    () =>
      baselineSelection?.value
        ? parseKomodorUid(baselineSelection?.value)
        : undefined,
    [baselineSelection]
  );

  const komodorUids = useMemo(() => {
    if (!baselineSelection?.value) return [];
    const comparedKomodorUids =
      comparedSelections?.map((service) => service.value) ?? [];
    return [baselineSelection.value, ...comparedKomodorUids];
  }, [baselineSelection?.value, comparedSelections]);

  if (!baselineSelection) {
    return <EmptyStateMetricsGraphs komodorUids={komodorUids} />;
  }

  return (
    <MetricsContainer>
      <MetricsNotSupportedSection komodorUids={komodorUids} />
      <MainContainer>
        <GraphHeaderContainer>
          <Typography variant="body2" fontSize="14px">
            Aggregated Service View
          </Typography>
          <RowContainer>
            <MetricsAggregationSelector
              ariaLabel={workloadsMetricsAriaLabels.aggregationToggle}
              aggregationMetric={selectedAggregationMetric}
              setAggregationMetric={setSelectedAggregationMetric}
            />
            <MetricsControlsSelector
              setTimeWindow={setTimeWindow}
              timeWindow={timeWindow}
              options={[Timeframe.Last24Hours, Timeframe.Last7Days]}
            />
          </RowContainer>
        </GraphHeaderContainer>

        <GraphsContainer>
          <DriftWorkloadsMetricsGraph
            loadingStateId={DriftMetricsLoadingStateIds.driftWorkloadsMemory}
            type={"memory"}
            syncId={syncId}
            title="Memory Utilizations"
            komodorUids={komodorUids}
          />
          <DriftWorkloadsMetricsGraph
            loadingStateId={DriftMetricsLoadingStateIds.driftWorkloadsCPU}
            type={"cpu"}
            syncId={syncId}
            title="CPU Utilizations"
            komodorUids={komodorUids}
          />
        </GraphsContainer>
      </MainContainer>
      {baselineKomodorUid && (
        <WorkloadsMetricsPerContainer
          syncId={syncId}
          baselineKomodorUid={baselineKomodorUid}
          komodorUids={komodorUids}
        />
      )}
    </MetricsContainer>
  );
};
