import Skeleton from "@mui/material/Skeleton";
import { memo } from "react";

import { useNeedUpgradeAgent } from "@/components/common/UpdateAgentModal/hooks";
import { UpgradeAgentButton } from "@/components/common/UpdateAgentModal/UpgradeAgentButton";
import { UpgradeCommands } from "@/components/common/upgradeAgentCta/UpgradeAgentCTA";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import { useGetHelmInstallationCommand } from "@/shared/hooks/useGetHelmInstallationCommand";
import { doesAgentVersionSupportExternalDNSStatus } from "@/shared/utils/agent/agent";
import { getRandRange } from "@/shared/utils/getRandRange";

const Loader = memo(() => (
  <Skeleton variant="rounded" height={21} width={getRandRange(70, 120)} />
));

export const AgentUpgradeRequiredWrapper: React.FC<{
  cluster: string;
  children: React.ReactNode;
}> = ({ cluster, children }) => {
  const agentId = useActiveAgent(cluster);
  const agentUpgradeRequired = useNeedUpgradeAgent(
    agentId,
    doesAgentVersionSupportExternalDNSStatus
  );
  const command = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND
  );

  if (!agentId) {
    return <Loader />;
  }
  if (agentUpgradeRequired) {
    return <UpgradeAgentButton command={command} desiredFeature="this data." />;
  }
  return children;
};
