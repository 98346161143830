import { useMemo } from "react";
import { camelCase } from "lodash";

import { sortTableModel } from "@/components/Inspection/utils/getSortedColumns";
import { InspectionTableHeader } from "@/components/common/ResourceListTable/ResourceListTableHead";
import { BaseResourceListTableProps } from "@/components/common/ResourceListTable/ResourceListTableProps";
import { DEFAULT_COLUMN_BY_WHICH_TO_SORT } from "@/components/common/ResourceListTable/resourceListTableConstants";

type UseRowsOnPageProps = {
  sortColumn: string;
  tableHeaders: InspectionTableHeader[];
  rows: BaseResourceListTableProps["rows"];
  sortDirection: number;
  resourceType: BaseResourceListTableProps["resourceType"];
  rowsOffset: number;
  pageSizeToUse: number;
};

export const useRowsOnPage = ({
  sortColumn,
  tableHeaders,
  rows,
  sortDirection,
  resourceType,
  rowsOffset,
  pageSizeToUse,
}: UseRowsOnPageProps) => {
  return useMemo(() => {
    let columnByWhichToSort = camelCase(sortColumn);

    if (
      !tableHeaders.some(
        (header) => camelCase(header.name) === columnByWhichToSort
      )
    ) {
      columnByWhichToSort = camelCase(DEFAULT_COLUMN_BY_WHICH_TO_SORT);
    }

    const sortedRows = [...rows].sort(
      sortTableModel(sortDirection, columnByWhichToSort, resourceType)
    );

    return sortedRows.slice(rowsOffset, rowsOffset + pageSizeToUse);
  }, [
    sortColumn,
    tableHeaders,
    rows,
    sortDirection,
    resourceType,
    rowsOffset,
    pageSizeToUse,
  ]);
};
