import { Addon, Entity } from "@/generated/addonsApi";
import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProviderProps } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";

export const useDatadogReportLoadingTimeContextProps =
  (): DatadogReportLoadingTimeContextProviderProps => {
    const { addonType, addonEntity } = useAddonContext();
    return {
      viewOptions: {
        name: getDatadogViewName(addonType, addonEntity),
        context: datadogReportingContextMap[addonType],
      },
    };
  };

const getDatadogViewName = (
  addonType: Addon,
  addonEntity: Entity
): DatadogViewNamesEnum => {
  switch (addonType) {
    case Addon.CertManager: {
      return addonEntity === Entity.Certificate
        ? DatadogViewNamesEnum.certManagerCertificatesPage
        : DatadogViewNamesEnum.certManagerControllersPage;
    }
    case Addon.AutoScalers: {
      return DatadogViewNamesEnum.autoScalersPage;
    }
    case Addon.ExternalDns: {
      return DatadogViewNamesEnum.externalDnsPage;
    }
    case Addon.HelmCharts: {
      return DatadogViewNamesEnum.helmPage;
    }
  }

  // TODO: add all addons types and entities
  return DatadogViewNamesEnum.certManagerCertificatesPage;
};

const datadogReportingContextMap: Record<Addon, DatadogContext> = {
  [Addon.CertManager]: { feTeam: "operate", beTeam: "operate" },
  [Addon.AutoScalers]: { feTeam: "operate", beTeam: "operate" },
  [Addon.ExternalDns]: { feTeam: "troubleshooting", beTeam: "troubleshooting" },
  [Addon.Workflow]: { feTeam: "troubleshooting", beTeam: "troubleshooting" },
  [Addon.HelmCharts]: { feTeam: "operate", beTeam: "operate" },
};
