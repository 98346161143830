import React, { useRef, useState } from "react";
import {
  ComparisonTable,
  LabeledSwitch,
  LightTooltip,
} from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { ErrorState } from "../Services/Attributes/ErrorState";
import { EmptyStateComparedItems } from "../common/EmptyStateComparedItems";
import { MAX_COMPARED_SERVICES } from "../Services/constants";
import { useShowOnlyDrift } from "../common/hooks";
import { DriftType } from "../context/types";
import { EmptyStateBaseline } from "../common/EmptyStateBaseline";
import { TableContainer } from "../common/styles";
import { useDriftDatadogReportTime } from "../Services/useDriftDatadogReportTime";
import { DiffModal, DiffModalParams } from "../common/DiffModal";
import { InfoSection } from "../common/InfoSection";

import { HELM_DRIFT_ATTRIBUTES_COMPONENT_NAME } from "./constants";
import { useAttributesTableData } from "./tablesDataHooks";
import { useHelmReleasesList } from "./useHelmReleasesList";
import { HelmResourcesComparison } from "./HelmResourcesComparison";

import { useHelmDriftComparison } from "@/shared/hooks/workspaces-api/drift/useHelmDriftComparison";

export const HelmComparisonContent: React.FC = () => {
  const {
    baselineSelection,
    comparedSelections,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  } = useDriftComparisonContext();

  const baselineKomodorUid = baselineSelection?.value ?? "";
  const { data, isLoading, isFetching, isError, refetch } =
    useHelmDriftComparison(
      {
        baselineKomodorUid,
        targetKomodorUids:
          comparedSelections?.map((service) => service.value) ?? [],
        withResourcesComparison: true,
      },
      {
        enabled: !!baselineKomodorUid,
      }
    );

  const divRef = useRef<HTMLDivElement>(null);
  useDriftDatadogReportTime({
    component: HELM_DRIFT_ATTRIBUTES_COMPONENT_NAME,
    isLoading: isFetching,
    divRef,
  });

  const {
    showOnlyDriftTooltipTitle,
    isDriftDetected,
    showOnlyDrift,
    onShowOnlyDriftChange,
  } = useShowOnlyDrift({
    targetElements: data?.targetHelmAttributes,
  });

  const { helmReleases } = useHelmReleasesList();
  const [diffModal, setDiffModal] = useState<DiffModalParams | null>(null);

  const { attributes, baseline, compared } = useAttributesTableData({
    showOnlyDrift: showOnlyDrift ?? false,
    data,
    helmReleases,
    setDiffModal,
  });

  return (
    <Stack ref={divRef} direction="column" gap="16px" paddingBottom="24px">
      {isLoading && baselineKomodorUid && (
        <InfoSection
          icon={<CircularProgress size="14px" />}
          content={
            <Typography variant="body2" color={muiColors.blue[600]}>
              Drift analysis is running. This process may take a while.
            </Typography>
          }
        />
      )}
      <TableContainer>
        <ComparisonTable
          title="Helm Attributes"
          titleRightElement={
            <LightTooltip title={showOnlyDriftTooltipTitle}>
              <div>
                <LabeledSwitch
                  label="Show only drift"
                  disabled={!isDriftDetected}
                  sx={{
                    typography: {
                      color: isDriftDetected
                        ? "primary.main"
                        : "text.secondary",
                      fontSize: "12px",
                      fontWeight: 500,
                    },
                  }}
                  checked={showOnlyDrift ?? false}
                  onCheck={onShowOnlyDriftChange}
                />
              </div>
            </LightTooltip>
          }
          limit={MAX_COMPARED_SERVICES}
          isLoading={isLoading && baselineSelection !== null}
          isError={isError}
          attributes={attributes}
          baseline={baseline}
          compared={compared}
          noBaselineElement={
            <EmptyStateBaseline
              type={DriftType.Helm}
              disabled={!helmReleases?.length}
              onButtonClick={() => setIsBaselineSelectorOpen(true)}
            />
          }
          noComparedItemsElement={
            comparedSelections != null &&
            comparedSelections.length > 0 ? null : (
              <EmptyStateComparedItems
                type={DriftType.Helm}
                withButton={baselineSelection !== null}
                onButtonClick={() => setIsComparedSelectorOpen(true)}
              />
            )
          }
          errorStateElement={<ErrorState onRetryClick={refetch} />}
        />
        <HelmResourcesComparison
          data={data}
          showOnlyDrift={showOnlyDrift ?? false}
          isLoading={isLoading && baselineSelection !== null}
          helmReleases={helmReleases ?? []}
          isError={isError}
          errorStateElement={<ErrorState onRetryClick={refetch} />}
        />
        {diffModal ? (
          <DiffModal
            title={diffModal?.title}
            baselineUid={baseline?.komodorUidLabel ?? ""}
            comparedUid={diffModal.comparedUid}
            oldObj={diffModal?.oldObj}
            newObj={diffModal?.newObj}
            onClose={() => setDiffModal(null)}
          />
        ) : null}
      </TableContainer>
    </Stack>
  );
};
