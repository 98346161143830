import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Box from "@mui/material/Box";

import { muiColors } from "themes";
import { MessageBubble } from "components/komodor-ui/Chat/MessageBubble/MessageBubble";
import { ChatMessageId } from "components/komodor-ui/Chat/types";

export interface FailedMessageBubbleProps {
  id: ChatMessageId;
  isUser: boolean;
  children: React.ReactNode;
}
export const FailedMessageBubble: React.FC<FailedMessageBubbleProps> = ({
  id,
  isUser,
  children,
}) => (
  <MessageBubble
    id={id}
    isUser={isUser}
    sx={{
      padding: "0 16px",
      backgroundColor: muiColors.pink[50],
      color: muiColors.pink[700],
    }}
  >
    <Box display="flex" alignItems="center" columnGap="10px">
      <WarningAmberOutlined />
      {children}
    </Box>
  </MessageBubble>
);
