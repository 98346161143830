import Link from "@mui/icons-material/Link";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  ariaLabel: string;
  onCopyFailed?: (err: Error) => void;
}

export const CopyButton: React.FC<Props> = ({ ariaLabel, onCopyFailed }) => {
  const [anchorCopyEl, setAnchorCopyEl] = useState<null | HTMLElement>(null);

  const { callback: debouncedSetAnchorCopyEl } = useDebouncedCallback(
    setAnchorCopyEl,
    1250
  );

  const onCopy = (event: React.MouseEvent<HTMLElement>) => {
    try {
      navigator.clipboard.writeText(window.location.href);
      if (!anchorCopyEl) {
        setAnchorCopyEl(event.currentTarget);
        debouncedSetAnchorCopyEl(null);
      }
    } catch (error) {
      onCopyFailed?.(error as Error);
    }
  };

  const open = Boolean(anchorCopyEl);
  const id = open ? "copy-label-popper" : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        aria-label={ariaLabel}
        size="small"
        onClick={onCopy}
      >
        <Link fontSize="medium" color="action" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorCopyEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: { sx: { borderRadius: "20px", boxShadow: "none" } },
        }}
      >
        <Chip
          label={<Typography variant="h6">COPIED!</Typography>}
          size="small"
        />
      </Popover>
    </>
  );
};
