import Link from "@mui/material/Link";
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import { EMPTY_CELL } from "@komodorio/design-system/komodor-ui";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import {
  ConnectedWorkloadsModal,
  ConnectedWorkloadsModalProps,
} from "@/shared/components/ConnectedWorkloadsCell/ConnectedWorkloadsModal";
import { ModalProvider } from "@/shared/context/modal/ModalProvider";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { ConnectedWorkloadsTableProps } from "@/shared/components/ConnectedWorkloadsCell/ConnectedWorkloadsTable";
import { connectedWorkloadsModalAriaLabels } from "@/shared/components/ConnectedWorkloadsCell/ariaLabels";

type ConnectedWorkloadsCellProps = {
  host: string;
  source: string;
  connectedWorkloadsUids: string[];
  modalProps?: ConnectedWorkloadsModalProps["modalProps"];
  tableProps?: ConnectedWorkloadsTableProps["customTableProps"];
};

export const ConnectedWorkloadsCell: React.FC<ConnectedWorkloadsCellProps> = ({
  host,
  source,
  connectedWorkloadsUids,
  modalProps,
  tableProps,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  if (!connectedWorkloadsUids.length) {
    return EMPTY_CELL;
  }
  const {
    cluster = "",
    namespace = "",
    kind = "",
    name = "",
  } = parseKomodorUid(connectedWorkloadsUids[0]) ?? {};

  return (
    <>
      <Stack
        direction="row"
        gap="4px"
        width="100%"
        aria-label={connectedWorkloadsModalAriaLabels.connectedWorkloadCell}
      >
        {connectedWorkloadsUids.length == 1 && (
          <Link
            variant="body2"
            underline="hover"
            noWrap
            onClick={(e: React.MouseEvent<Element>) => {
              e.stopPropagation();
              pushDrawer({
                drawerType: DrawerType.ResourceDrawerByData,
                cluster,
                namespace,
                resourceType: kind,
                resourceName: name,
              });
            }}
          >
            {name}
          </Link>
        )}
        {connectedWorkloadsUids.length > 1 && (
          <Link
            variant="body2"
            underline="hover"
            onClick={(e: React.MouseEvent<Element>) => {
              e.stopPropagation();
              setOpenModal(true);
            }}
          >
            {connectedWorkloadsUids.length} workloads
          </Link>
        )}
      </Stack>
      <ModalProvider open={openModal} onClose={() => setOpenModal(false)}>
        <ConnectedWorkloadsModal
          host={host}
          source={source}
          workloadsUids={connectedWorkloadsUids}
          modalProps={modalProps}
          tableProps={tableProps}
        />
      </ModalProvider>
    </>
  );
};
