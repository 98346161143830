import React from "react";
import Typography from "@mui/material/Typography";

import { BatchActionsConfirmationSubtitle } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/BatchActionsConfirmationSubtitle";
import { useBatchActionsLabel } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useBatchActionsLabel";
import { batchActionsAriaLabels } from "@/components/common/ResourceListTable/batchActions/batchActionsAriaLabels";

export const ConfirmationTitle: React.FC = () => {
  const actionLabel = useBatchActionsLabel().toLowerCase();
  const title = `Confirm batch ${actionLabel}`;
  return (
    <>
      <Typography
        variant={"h3"}
        aria-label={batchActionsAriaLabels.batchActionsDialog.title}
      >
        {title}
      </Typography>
      <BatchActionsConfirmationSubtitle />
    </>
  );
};
