import debounce from "lodash/debounce";
import { useState } from "react";
import { getTextWidth } from "@komodorio/design-system";

import { TickFormatterFn } from "@/components/Metrics/types";
import { Y_AXIS_MINIMUM_WIDTH } from "@/components/Metrics/graphs/graphConstants";

export const TEXT_PADDING = 5;

export interface DynamicYAxisCalculator {
  tickFormatter: TickFormatterFn;
}

export type DynamicYAxisCalculatorCreationConfig = {
  fontSize: number;
  tickFormatter?: TickFormatterFn;
};

export function createDynamicYAxisCalculator(
  onNewWidthAvailable: (width: number) => void,
  config: DynamicYAxisCalculatorCreationConfig
) {
  const debouncedOnNewWidthAvailable = debounce(onNewWidthAvailable, 10);
  let longestTickLength = 0;

  const calculatingTickFormatter = (tick: string) => {
    const formattedValue = config.tickFormatter
      ? config.tickFormatter(tick)
      : String(tick);
    if (formattedValue.length > longestTickLength) {
      longestTickLength = formattedValue.length;
      const calculatedNewWidth =
        getTextWidth(formattedValue, `${config.fontSize}`) + TEXT_PADDING;
      debouncedOnNewWidthAvailable(
        Math.max(calculatedNewWidth, Y_AXIS_MINIMUM_WIDTH)
      );
    }
    return formattedValue;
  };

  return {
    tickFormatter: calculatingTickFormatter,
  };
}

export function useDynamicYAxisWidth(
  config: DynamicYAxisCalculatorCreationConfig
) {
  const [yAxisCalculatedWidth, setYAxisCalculatedWidth] = useState(0);
  const dynamicYAxisCalculator = createDynamicYAxisCalculator((width) => {
    if (width !== yAxisCalculatedWidth) {
      setYAxisCalculatedWidth(width);
    }
  }, config);

  return {
    yAxisCalculatedWidth,
    tickCalculatingFormatter: dynamicYAxisCalculator.tickFormatter,
  };
}
