import Typography from "@mui/material/Typography";

type LabeledDataProps = {
  title: string;
};

export const LabeledData: React.FC<LabeledDataProps> = ({ title }) => {
  return (
    <Typography variant={"h5"} color={"text.secondary"}>
      {title}
    </Typography>
  );
};
