import { useMemo } from "react";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { DiffModal } from "../Services/Attributes/types";

import { AttributesTableData } from "./types";
import {
  getBaselineTableData,
  getAttributesTableData,
  getAttributesComparedTableData,
} from "./AttributesTableUtils";
import {
  getBaselineResourceKindComparison,
  getComparedResourcesTableData,
} from "./ResourcesTableDataUtils";
import { useHelmResourcesTableAttributes } from "./ResourcesTableAttributesUtils";
import { ResourcesDiffModalParams } from "./ResourcesDiffModal";

import { HelmChart } from "@/generated/addonsApi";
import {
  HelmComparisonResponse,
  HelmComparisonResponseBaselineAttributes,
  HelmComparisonResponseTargetAttributes,
  HelmResourcesComparisonForKind,
} from "@/generated/workspacesApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const useAttributesTableData = ({
  showOnlyDrift,
  data,
  helmReleases,
  setDiffModal,
}: {
  showOnlyDrift: boolean;
  data: HelmComparisonResponse | undefined;
  helmReleases: HelmChart[] | undefined;
  setDiffModal: (diffModal: DiffModal | null) => void;
}): AttributesTableData => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useMemo(() => {
    const baseline = getBaselineTableData(data, baselineSelection);

    return {
      attributes: getAttributesTableData({
        baselineData: baseline,
        data,
        showOnlyDrift,
        helmReleases,
        pushDrawer,
        setDiffModal,
      }),
      compared: getAttributesComparedTableData({
        data,
        comparedSelections,
        showOnlyDrift,
      }),
      baseline,
    };
  }, [
    data,
    baselineSelection,
    comparedSelections,
    showOnlyDrift,
    helmReleases,
    pushDrawer,
    setDiffModal,
  ]);
};

export const useHelmResourcesTableData = ({
  resourceKindComparison,
  baselineAttributesComparisonData,
  targetAttributesComparisonData,
  helmReleases,
  setResourcesDiffModal,
}: {
  resourceKindComparison: HelmResourcesComparisonForKind;
  baselineAttributesComparisonData: HelmComparisonResponseBaselineAttributes;
  targetAttributesComparisonData: HelmComparisonResponseTargetAttributes[];
  helmReleases: HelmChart[] | undefined;
  setResourcesDiffModal: (diffModal: ResourcesDiffModalParams | null) => void;
}) => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const { showOnlyDriftKey } = useDriftComparisonContext();
  const [showOnlyDrift] =
    useStringifiedStateInSearchParams<boolean>(showOnlyDriftKey);
  const baselineResourceKindComparison = getBaselineResourceKindComparison({
    baselineSelection,
    baselineHelmResourcesData: resourceKindComparison.baselineHelmResources,
    baselineAttributesComparisonData,
  });
  const attributes = useHelmResourcesTableAttributes({
    resourceKind: resourceKindComparison.kind,
    isResourceKindSupported: resourceKindComparison.isSupportedKomodorKind,
    baselineResourceKindComparison,
    helmReleases,
    setResourcesDiffModal,
    baselineResourceData: resourceKindComparison.baselineHelmResources,
  });
  return useMemo(
    () => ({
      attributes,
      compared: getComparedResourcesTableData({
        baselineHelmResourcesData: resourceKindComparison.baselineHelmResources,
        resourceKindComparison,
        comparedSelections,
        targetAttributesComparisonData,
        showOnlyDrift: showOnlyDrift ?? false,
      }),
      baseline: baselineResourceKindComparison,
    }),
    [
      attributes,
      baselineResourceKindComparison,
      comparedSelections,
      resourceKindComparison,
      showOnlyDrift,
      targetAttributesComparisonData,
    ]
  );
};
