import { getAppConfig } from "../../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

export const useAuthApiClient = (options?: {
  skipAppViewInHeaders?: boolean;
  skipWorkspaceHeader?: boolean;
}) => {
  return useCreateAxiosClient(getAppConfig().authServiceAPIServerURL, {
    skipAppViewInHeaders: options?.skipAppViewInHeaders,
    skipWorkspaceHeader: options?.skipWorkspaceHeader,
  });
};
