import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

export const Row = styled.div`
  height: 3rem;
  max-width: 27rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const BaseText = styled(Typography).attrs({ size: "medium" })`
  && {
    color: white;
    font-weight: 500;
  }
`;

export const MiddleText = styled(BaseText)`
  padding-left: 0.5rem;
  padding-right: 0.25rem;
`;

export const ContainerNameText = styled(BaseText)`
  padding-left: 0.5rem;
  overflow: hidden;
  max-width: 23rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const podExecSesssionTitle = (
  podName: string,
  containerName?: string
): string => {
  return containerName ? `${podName} - ${containerName}` : podName;
};
