import { HelmChartSummary } from "komodor-types";

import { generateSecretId } from "@/components/Inspection/Helm/hooks/utils";
import { HelmChart } from "@/generated/addonsApi";

export const helmChartToHelmChartSummary = (
  helmChart: HelmChart
): HelmChartSummary => {
  const {
    name,
    revision,
    clusterName,
    namespace,
    status,
    appVersion,
    updatedAt,
  } = helmChart;
  const secretId = generateSecretId(name, revision.toString());
  return {
    cluster: clusterName,
    name,
    namespace,
    revision: revision.toString(),
    status,
    version: appVersion,
    lastUpdated: updatedAt,
    secretId,
  };
};
