export const nodeAutoscalersRoutes = {
  nodeAutoscalers: "/",
  noAutoscalersFound: "/no-autoscalers-found",
};

export const DEFAULT_AUTOSCALERS_SORT_BY_FIELD = "numberOfRisks";
export const DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION = "desc";

export const SEARCH_TABLE_PLACEHOLDER = "Search autoscalers";

export const NO_AUTOSCALERS_FOUND_TITLE = "No Autoscalers detected";
export const NO_AUTOSCALERS_FOUND_TEXT =
  "We did not detect any Autoscalers on your clusters. " +
  "When deployed, you'll be able to get operational data and insights in this screen!";
