import { DataGrid, SearchField } from "@komodorio/design-system/komodor-ui";
import { ComponentProps, useMemo, useState } from "react";
import { GridValidRowModel } from "@mui/x-data-grid-pro";
import styled from "styled-components";

const SEARCH_FIELD_CONTAINER_CLASS = "searchFieldContainer";

const SearchFieldContainer = styled.div`
  margin-bottom: 8px;
`;

type DataGridWithFilterProps<T extends GridValidRowModel> = ComponentProps<
  typeof DataGrid<T>
> & {
  filter: (item: T, searchTerm: string) => boolean;
  searchBoxPlaceholder?: string;
};

type DataGridWithFilterComponent<T extends GridValidRowModel> = React.FC<
  DataGridWithFilterProps<T>
>;
export const ClientSideDataGridWithFilter: <T extends GridValidRowModel>(
  ...props: Parameters<DataGridWithFilterComponent<T>>
) => ReturnType<DataGridWithFilterComponent<T>> = ({
  filter,
  searchBoxPlaceholder,
  ...dataGridProps
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredRows = useMemo(
    () => dataGridProps.rows.filter((row) => filter(row, searchTerm)),
    [dataGridProps.rows, filter, searchTerm]
  );
  return (
    <>
      <SearchFieldContainer>
        <SearchField
          onSearch={setSearchTerm}
          value={searchTerm}
          placeholder={searchBoxPlaceholder}
          classNames={{ container: SEARCH_FIELD_CONTAINER_CLASS }}
        />
      </SearchFieldContainer>
      <DataGrid {...dataGridProps} rows={filteredRows} />
    </>
  );
};
