import { FunctionComponent, SVGProps } from "react";

import type { NodeCheckType } from "../workflowRunView/checks/node/types";
import type { PVCCheckType } from "../workflowRunView/checks/pvc/types";

import type { TriageDataParsed } from "@/components/common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import { AvailabilityInvestigationCheckType } from "@/components/InvestigationMode/availability/types";
import { MonitorConfiguration } from "@/generated/monitorsApi";

export enum WorkflowConfigType {
  PVC = "PVC",
  NodeIssue = "node",
  Availability = "availability",
  Job = "job",
  CronJob = "cronJob",
  Deploy = "deploy",
  Workflow = "workflow",
}

export type Tab = {
  label: string;
  route: string;
  accessLevel: string[];
};

export type WorkflowTemplate = {
  title: string;
  shortDescription: string;
  longDescription: string;
  configurationType: WorkflowConfigType;
  previewDescription?: string;
  endpoint?: string;
  displayName?: string;
  checksDescription: Array<string>;
  requiredAgentVersion: string;
  triggerConditions: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  sinks: AvailableSinks[];
  docsLink: string;
};

export type Sensor = {
  cluster: string;
  namespaces?: string[];
};

export type SinksOptions = {
  notifyOn?: string[];
  shouldSend?: boolean;
};

export enum AvailableSinks {
  Slack = "Slack",
  Teams = "Teams",
  Opsgenie = "Opsgenie",
  PagerDuty = "PagerDuty",
  GenericWebhook = "Webhook",
}

export type K8sService = {
  apiVersion: string;
  Kind: string;
  Metadata: Record<string, unknown>;
  Spec: Record<string, unknown>;
};

export type OpsgenieResponder = {
  name: string;
  id: string;
};

export type CheckDetails = {
  workflowType: "" | WorkflowConfigType;
  results: BaseWorkflowCheck[];
  eventTime?: Date;
  closedAt?: Date | null;
  services?: string[];
  clusterName?: string;
  namespace?: string;
};

export const SelectorTypes = [
  "namespaces",
  "annotations",
  "labels",
  "services",
];

export enum AvailabilityCheckType {
  WorkloadPodsHealthy = "workload-pods-healthy",
  WorkloadDescribe = "workload-describe",
  WorkloadInfo = "workload-info",
  WorkloadPodsHealthyPrevious = "workload-pods-healthy-previous",
  WorkloadDescribePrevious = "workload-describe-previous",
  WorkloadInfoPrevious = "workload-info-previous",
}
export enum JobCheckTypeEnum {
  JobCheckType = "job-info",
}
export enum DeployCheckType {
  DeployPodsHealthy = "deploy-pods-healthy",
}
export enum PodCheckType {
  PodHealthy = "pod-healthy",
}
export type AllChecksTypes =
  | PVCCheckType
  | NodeCheckType
  | AvailabilityCheckType
  | JobCheckTypeEnum.JobCheckType
  | DeployCheckType.DeployPodsHealthy
  | PodCheckType.PodHealthy
  | AvailabilityInvestigationCheckType.Introduction
  | AvailabilityInvestigationCheckType.UnhealthyPods
  | AvailabilityInvestigationCheckType.Logs
  | AvailabilityInvestigationCheckType.CorrelatedDeploys
  | AvailabilityInvestigationCheckType.NodeIssues
  | AvailabilityInvestigationCheckType.KlaudiaSession;

export interface BaseWorkflowCheck {
  passed: boolean;
  type: AllChecksTypes;
  shortOutput: string;
  cta: string;
  output: unknown;
}

export type WorkflowRun = {
  id: string;
  sourceEventId?: string;
  type: WorkflowConfigType;
  shortResourceName: string;
  workflowConfiguration: MonitorConfiguration | null;
  closedAt: Date | null;
  closeReason?: string;
  resultsSummary: { [key: string]: boolean };
  cluster?: string;
  namespace?: string;
  reasons: string[];
  eventTime: Date;
};
export type WorkflowRunWithResults = WorkflowRun & {
  results: BaseWorkflowCheck[];
  services?: string[];
  triageData: TriageDataParsed;
};
