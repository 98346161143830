import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

import { CustomGraphDataPoint } from "../../../../../../../../Metrics/types";
import { addToMetricsData } from "../../../../../../../../ResourceView/tabs/MetricsTab/utils";
import { WorkloadMetricsContainersRestartsDataPoints } from "../../../../../../../../../generated/metricsApi";
import { parseKomodorUid } from "../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetEpochsFromViolation } from "../../../hooks/useGetEpochsFromViolation";
import { useGetWorkloadContainerRestarts } from "../../../../../../../../../shared/hooks/metrics-api/client/useGetWorkloadContainerRestarts";

import { ContainerRestartsSupportingDataRestartReasonsBreakdownInner } from "@/generated/reliabilityApi";
import { formatPercentage } from "@/shared/utils/formatPercentage";

export const useGraphDataPoints = (
  containers?: WorkloadMetricsContainersRestartsDataPoints[]
) => {
  return useMemo(
    () =>
      containers?.reduce<CustomGraphDataPoint[]>((acc, curr) => {
        return addToMetricsData({
          containerName: curr.containerName,
          newMetricsList: curr.dataPoints.map((datapoint) => ({
            time: datapoint.timestampMs,
            [curr.containerName]: datapoint.value,
          })),
          prevMetricsList: acc,
        });
      }, []),
    [containers]
  );
};

export const useFetchContainerRestartsData = (komodorUid: string) => {
  const {
    cluster = "",
    namespace = "",
    name = "",
    kind = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const hasRequiredData = !!cluster && !!namespace && !!name && !!kind;

  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();

  return useGetWorkloadContainerRestarts(
    {
      komodorServiceKind: kind,
      clusterName: cluster,
      namespace,
      komodorServiceName: name,
      fromEpoch,
      toEpoch,
    },
    hasRequiredData
  );
};

export const useRestartReasonsBreakdownColumns =
  (): GridColDef<ContainerRestartsSupportingDataRestartReasonsBreakdownInner>[] => {
    return useMemo(
      () => [
        { field: "message", headerName: "Reason", flex: 1 },
        {
          field: "percent",
          headerName: "Percent",
          valueGetter: (params) => formatPercentage(params.value),
          maxWidth: 100,
        },
      ],
      []
    );
  };
