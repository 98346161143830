import React, { useMemo } from "react";
import { sortBy } from "lodash";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { DEFAULT_PAGE_SIZES } from "@komodorio/design-system/komodor-ui";

import { User } from "@/generated/auth";
import { ariaLabels } from "@/components/Settings/Users/const";
import { getColumnDefinitions } from "@/components/Settings/Users/gridHelpers";
import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";
import { useDataGridPagination } from "@/shared/hooks/dataGrid/useDataGridPagination";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { usePageWidth } from "@/pages/hooks/usePageWidth";

export const useUsersPage = ({
  isFetching,
  search,
  users,
  setUserToEdit,
  setUserToDelete,
  setUserToRestore,
  setUserToViewPermissions,
}: {
  isFetching: boolean;
  search: string;
  users: User[] | undefined;
  setUserToEdit: (user: User) => void;
  setUserToDelete: (user: User) => void;
  setUserToRestore: (user: User) => void;
  setUserToViewPermissions: (user: User) => void;
}) => {
  const { ref, width } = usePageWidth();
  const { showTemporaryRoles: showTemporaryRolesFF } = useOverridableFlags();
  const { paginationModel, handlePaginationModelChange } =
    useDataGridPagination();

  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();

  const filteredUsers = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return users
      ? sortBy(
          users.filter((u) => {
            return [u.displayName, u.email].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          [(u) => u.deletedAt !== null, (u) => u.displayName]
        )
      : [];
  }, [users, search]);

  const columns = useMemo(() => {
    const toReturn = getColumnDefinitions({
      onEdit: setUserToEdit,
      onDelete: setUserToDelete,
      onRestore: setUserToRestore,
      onViewPermissions: setUserToViewPermissions,
    });

    if (!showTemporaryRolesFF) {
      return toReturn.filter((column) => column.field !== "tempRoles");
    }

    return toReturn;
  }, [
    showTemporaryRolesFF,
    setUserToDelete,
    setUserToRestore,
    setUserToEdit,
    setUserToViewPermissions,
  ]);

  const tableContent = useMemo(() => {
    return (
      <Paper
        variant="elevation"
        elevation={1}
        sx={{ width: width || "100%", overflow: "auto" }}
      >
        <DataGridPro
          loading={isFetching}
          aria-label={ariaLabels.page.table}
          disableVirtualization
          pagination
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          columns={columns}
          disableColumnMenu
          rows={filteredUsers.map((user) => {
            return {
              disabled: !!user.deletedAt,
              ...user,
              hovered: user.id === hoveredRowId,
            };
          })}
          slotProps={{
            row: {
              onMouseEnter: handleRowHovered,
              onMouseLeave: handleRowLeaved,
            },
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
        />
      </Paper>
    );
  }, [
    filteredUsers,
    width,
    paginationModel,
    handlePaginationModelChange,
    columns,
    hoveredRowId,
    handleRowLeaved,
    handleRowHovered,
    isFetching,
  ]);

  return { tableContent, ref };
};
