import { useMemo } from "react";

import { DeployIssueDataParsed } from "../../common/types";
import { useOverridableFlags } from "../../../../../../../../shared/context/featureFlags/OverridableFlags";
import {
  filterRullIfHpaInRelatedResources,
  isAutomaticDeployByChangeType,
} from "../../../../../../EventGroup/deployEvent/DeployEventGroup";
import getDeployStatus from "../../../../../../EventGroup/deployEvent/getDeployStatus";

import { useGetDeploys } from "@/shared/hooks/monitors-api/client/deploys/useGetDeploys";

export const useFilteredDeployInsight = (
  latestDeploysInsights: DeployIssueDataParsed[]
): DeployIssueDataParsed[] => {
  const { hpaEventsInRelatedResources, replicasOnlyAsAutomaticDeploys } =
    useOverridableFlags();
  return useMemo(() => {
    const filterdAutomaticDeploys = latestDeploysInsights.filter(
      (deploy) =>
        !isAutomaticDeployByChangeType(
          deploy.changeType,
          replicasOnlyAsAutomaticDeploys
        )
    );
    return hpaEventsInRelatedResources
      ? filterdAutomaticDeploys.filter((event) =>
          filterRullIfHpaInRelatedResources(event.changeType)
        )
      : filterdAutomaticDeploys;
  }, [
    hpaEventsInRelatedResources,
    latestDeploysInsights,
    replicasOnlyAsAutomaticDeploys,
  ]);
};

export const useUpdatedDeployInsight = (
  latestDeploysInsights: DeployIssueDataParsed[]
): DeployIssueDataParsed[] => {
  const ids = useMemo(
    () => latestDeploysInsights.map((e) => e.id),
    [latestDeploysInsights]
  );
  const { data: deploysStatusesRest } = useGetDeploys(
    {
      ids,
      fields: ["id", "status"],
    },
    ids.length > 0
  );

  return useMemo(() => {
    return latestDeploysInsights.map(
      (deploy) => ({
        ...deploy,
        status: getDeployStatus(
          deploysStatusesRest?.data?.find((status) => status.id === deploy.id)
            ?.status ?? deploy.status
        ),
      }),
      []
    );
  }, [deploysStatusesRest?.data, latestDeploysInsights]);
};

export const useLatestDeploysInsight = (
  latestDeploysInsights: DeployIssueDataParsed[]
) => {
  const deployInsightsFiltered = useFilteredDeployInsight(
    latestDeploysInsights
  );
  const latestDeployInsightsUpdated = useUpdatedDeployInsight(
    deployInsightsFiltered
  );
  const deploysLength = latestDeployInsightsUpdated.length;

  return {
    shouldShow: latestDeployInsightsUpdated.length > 0,
    text: `${deploysLength} correlated deploy${
      deploysLength > 1 ? "s" : ""
    } within 1 hour before the issue started`,
    latestDeployInsightsUpdated: latestDeployInsightsUpdated,
  };
};
