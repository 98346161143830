import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { subHours } from "date-fns";

import { Timeframe } from "../../../shared/types/TimeWindow";
import Divider from "../../monitorsView/common/Divider";
import { useActiveAgentInfo } from "../../../shared/hooks/useAgents";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { getRoutePathFromKubernetesResource } from "../inspectionConfiguration/SupportedKubernetesResources";
import ResourceListErrorState from "../ResourceListErrorState";
import { useFilterListByInspectionFilters } from "../filters/useFilterListByInspectionFilters";
import { Container } from "../styles";
import {
  KubernetesResource,
  SupportedResourcesKinds,
} from "../inspectionConfiguration/SupportedResourcesTypes";
import { MainViewContent } from "../getMainViewContent";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { INSPECTION_PERMISSIONS_ERROR } from "../../../shared/hooks/ATM/constants/atmResources";
import { useFilteredClusters } from "../filters/useFilteredClusters";
import { InspectionFilters } from "../filters/InspectionFilters";
import { KubernetesStatefulSetResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesStatefulSetResource";
import { KubernetesDaemonSetResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesDeploymentResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";
import { useKomodorServices } from "../../../shared/hooks/useKomodorServices";
import { ServiceStatus } from "../../ResourceView/headers/KomodorServiceHealthAvailability";
import { buildServiceId } from "../../../shared/utils/serviceHelpers";
import { KubernetesJobResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";
import { useAccountName } from "../../../shared/hooks/useAccountName";

import { Agent } from "@/generated/agents";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import {
  NamespacesResult,
  ResourceListResponse,
} from "@/components/Inspection/InspectionViews/types";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialTimeWindow = {
  start: subHours(new Date(), 1),
  end: new Date(),
  timeframe: Timeframe.LastHour,
};
export interface InspectionComponentProps {
  kubernetesResource: KubernetesResource;
  cluster: string;
  onClusterChange: (clusterName: string) => void;
  agentInfo: AgentProperties | null;
  agentId: string;
}
interface InspectionParams {
  cluster: string | undefined;
  onClusterChange: (clusterName: string) => void;
  agentId: string | undefined;
  agent: Agent | undefined;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useInspectionParams = (
  kubernetesResource: KubernetesResource
): InspectionParams => {
  const resourceName = kubernetesResource.NameInK8S;
  useEffect(() => {
    dispatchEvent(AnalyticEvents.Inspection.Inspection_resource_list_start, {
      resourceName: resourceName,
    });
  }, [resourceName]);

  const { cluster } = useParams<{
    cluster: string | undefined;
  }>();
  const navigate = useNavigate();
  const onClusterChange = useCallback(
    (clusterName: string) => {
      const route = getRoutePathFromKubernetesResource(
        kubernetesResource,
        clusterName
      );
      if (route !== window.location.pathname) {
        navigate(route);
      }
    },
    [kubernetesResource, navigate]
  );

  const agentInfo = useActiveAgentInfo(cluster);

  return {
    cluster,
    onClusterChange,
    agent: agentInfo,
    agentId: agentInfo?.id,
  };
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isDeploymentSTSOrDS = (kind: string): boolean =>
  [
    KubernetesDeploymentResource.Kind,
    KubernetesStatefulSetResource.Kind,
    KubernetesDaemonSetResource.Kind,
  ].includes(kind as SupportedResourcesKinds);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isJobFn = (kind: string): boolean =>
  KubernetesJobResource.Kind === kind;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useResourceListWithMonitors = (
  kind: string,
  resourceList: ResourceListResponse,
  cluster: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): ResourceListResponse => {
  const accountName = useAccountName();
  const { servicesAsDictionary } = useKomodorServices();

  const isService = isDeploymentSTSOrDS(kind);
  const isJob = isJobFn(kind);

  return useMemo(() => {
    return isService || isJob
      ? {
          ...resourceList,
          rows: resourceList.rows.map((row) => {
            const serviceId = buildServiceId(
              accountName,
              cluster,
              row.namespace,
              row.name
            );
            const { healthy } = servicesAsDictionary.all[serviceId] ?? {};
            return isService
              ? {
                  ...row,
                  health:
                    healthy === undefined
                      ? ""
                      : healthy
                      ? ServiceStatus.HEALTHY
                      : ServiceStatus.UNHEALTHY,
                }
              : { ...row, status: row.status };
          }),
        }
      : resourceList;
  }, [
    isService,
    isJob,
    resourceList,
    accountName,
    cluster,
    servicesAsDictionary.all,
  ]);
};
export const InspectionViewComponent: React.FC<{
  cluster: string;
  onClusterChange: (clusterName: string) => void;
  agentUpgradeRequired?: boolean;
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  refresh: () => void;
  namespaces?: NamespacesResult;
  shouldUseLiveData?: boolean;
}> = ({
  cluster,
  onClusterChange,
  agentUpgradeRequired = false,
  resourceList,
  kubernetesResource,
  refresh,
  namespaces: namespacesResult,
  shouldUseLiveData = false,
}) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const resourceListWithMonitors = useResourceListWithMonitors(
    kubernetesResource.Kind,
    resourceList,
    cluster
  );
  const filteredTableResults = useFilterListByInspectionFilters({
    resourceList: resourceListWithMonitors,
    kubernetesResource,
    clusterName: cluster,
  });
  const { filteredClusterSuggestions, clusterToUse } = useFilteredClusters(
    cluster,
    onClusterChange
  );

  const hasError = !!(
    resourceListWithMonitors.errorMessage || namespacesResult?.errorMessage
  );

  const hasReportedLoadingStateDone = useRef(false);
  useEffect(() => {
    // this is to make sure we will report DD timing only once, even for polling requests
    if (hasReportedLoadingStateDone.current) {
      return;
    }

    reportLoadingState("resource", hasError ? false : resourceList.fetching);
    if (!resourceList.fetching && !resourceList.isPaginating) {
      hasReportedLoadingStateDone.current = true;
    }
  }, [
    hasError,
    reportLoadingState,
    resourceList.fetching,
    resourceList.isPaginating,
    resourceList.rows,
  ]);

  useEffect(() => {
    hasReportedLoadingStateDone.current = false;
  }, [kubernetesResource]);

  return (
    <Container>
      <InspectionFilters
        kubernetesResource={kubernetesResource}
        resourceList={resourceListWithMonitors}
        selectedCluster={clusterToUse}
        onClusterChange={onClusterChange}
        namespaces={namespacesResult?.namespaces}
        clustersOptions={filteredClusterSuggestions}
      />
      <Divider />
      <br />
      {hasError ? (
        <ResourceListErrorState
          resourceName={kubernetesResource.NameInK8S}
          errorMessage={
            namespacesResult?.errorMessage ||
            resourceListWithMonitors.errorMessage
          }
          refreshCallback={
            resourceListWithMonitors.errorMessage !==
            INSPECTION_PERMISSIONS_ERROR
              ? refresh
              : undefined
          }
        />
      ) : (
        <MainViewContent
          clusterName={clusterToUse}
          agentUpgradeRequired={agentUpgradeRequired}
          resourceList={resourceListWithMonitors}
          kubernetesResource={kubernetesResource}
          filteredTableResults={filteredTableResults}
          refresh={refresh}
          shouldUseLiveData={shouldUseLiveData}
        />
      )}
    </Container>
  );
};
