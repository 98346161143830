import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { ResourceData } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageTypes";
import { resourceUsageColorScheme } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageConstants";
import { formatNumber } from "@/shared/utils/formatNumber";
import {
  formatResourcePercentage,
  getAllocatedPercentage,
  getUsagePercentage,
} from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageUtils";
import { MetricsType } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/metrics/components/MetricsTable/types";
import { ResourceUsageBarAriaLabels } from "@/components/k8sAddons/components/table/ResourceUsageBar/ResourceUsageBarAriaLabels";

const StyledPaper = styled(Paper)`
  background-color: white;
  padding: 16px;
`;

const LineContainer = styled.div`
  display: grid;
  grid-template-columns: 10px 100px auto;
  gap: 4px;
  align-items: center;
  & > * {
    margin: auto;
    &:last-child {
      justify-self: end;
    }
  }
`;

const RoundedSquare = styled.div<{ color: string | undefined }>`
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const Title = styled(Typography).attrs({
  variant: "body2",
  color: "text.secondary",
})``;

type ResourceUsageTooltipProps = {
  resourceData: ResourceData;
  type: MetricsType;
  allocatedPercentage?: number;
};

export const ResourceUsageTooltipContent: React.FC<
  ResourceUsageTooltipProps
> = ({
  resourceData,
  type,
  allocatedPercentage: predefinedAllocatedPercentage,
}) => {
  const { allocated, capacity, usage } = resourceData;
  const unit = type === "cpu" ? "Cores" : "GiB";
  const usagePercentage = getUsagePercentage(resourceData);
  const allocatedPercentage =
    predefinedAllocatedPercentage ?? getAllocatedPercentage(resourceData);

  return (
    <StyledPaper elevation={4}>
      <LineContainer>
        <RoundedSquare color={resourceUsageColorScheme.usage} />
        <Title>Usage:</Title>
        <Typography variant={"body2"}>{`(${formatResourcePercentage(
          usagePercentage
        )}) ${formatNumber(usage)} ${unit}`}</Typography>
      </LineContainer>
      <LineContainer aria-label={ResourceUsageBarAriaLabels.tooltip.allocated}>
        <RoundedSquare color={resourceUsageColorScheme.allocated} />
        <Title>Allocated:</Title>
        <Typography variant={"body2"}>{`(${formatResourcePercentage(
          allocatedPercentage
        )}) ${formatNumber(allocated)} ${unit}`}</Typography>
      </LineContainer>
      <LineContainer>
        <RoundedSquare color={resourceUsageColorScheme.capacity} />
        <Title>Capacity:</Title>
        <Typography variant={"body2"}>{`${formatNumber(
          capacity
        )} ${unit}`}</Typography>
      </LineContainer>
    </StyledPaper>
  );
};
