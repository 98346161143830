import React from "react";
import styled, { css } from "styled-components";
import { switchProp } from "styled-tools";
import Typography from "@mui/material/Typography";

import { Size } from "../../../types";
import { palette } from "../../../themes/palette";

export type StatusColor =
  | "error"
  | "warning"
  | "success"
  | "running"
  | "neutral";

export type StatusVariant = "text" | "outlined";

export type StatusTextTransform = "uppercase" | "capitalize" | "lowercase";

export type StatusProps = {
  label: string;
  color: StatusColor;
  size?: Size;
  variant?: StatusVariant;
  textTransform?: StatusTextTransform;
};

export const StyledTypography = styled(Typography)<{
  size: StatusProps["size"];
  color: StatusProps["color"];
  textTransform: StatusTextTransform;
}>`
  && {
    ${switchProp("size", {
      small: css`
        font-size: 10px;
        font-weight: 600;
      `,
      medium: css`
        font-size: 12px;
        font-weight: 500;
      `,
      large: css`
        font-size: 14px;
        font-weight: 500;
      `,
    })};
    ${switchProp("color", {
      error: css`
        color: ${palette.error["dark"]};
      `,
      warning: css`
        color: ${palette.warning["dark"]};
      `,
      success: css`
        color: ${palette.success["dark"]};
      `,
      running: css`
        color: ${palette.running["dark"]};
      `,
      neutral: css`
        color: ${palette.info["dark"]};
      `,
    })};

    ${({ textTransform }) =>
      textTransform && `text-transform: ${textTransform};`}
  }
`;

const StyledStatus = styled.div`
  border: 1px solid transparent;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 2px 8px;
`;

export const StyledContainer = styled(StyledStatus)<{
  color: StatusProps["color"];
  variant: StatusProps["variant"];
}>`
  && {
    ${switchProp("color", {
      error: css`
        border-color: ${palette.error["main"]};
        background: ${palette.error["light"]};
      `,
      warning: css`
        border-color: ${palette.warning["main"]};
        background: ${palette.warning["light"]};
      `,
      success: css`
        border-color: ${palette.success["main"]};
        background: ${palette.success["light"]};
      `,
      running: css`
        border-color: ${palette.running["main"]};
        background: ${palette.running["light"]};
      `,
      neutral: css`
        border-color: ${palette.info["main"]};
        background: ${palette.info["light"]};
      `,
    })};

    ${switchProp("variant", {
      text: css`
        border: none;
        background: transparent;
      `,
    })};
  }
`;

export const Status: React.FC<StatusProps> = ({
  label,
  color,
  size = "medium",
  variant = "outlined",
  textTransform,
}) => {
  return (
    <StyledContainer variant={variant} color={color}>
      <StyledTypography size={size} textTransform={textTransform} color={color}>
        {label}
      </StyledTypography>
    </StyledContainer>
  );
};
