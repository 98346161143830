import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import styled from "styled-components";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import {
  clearBatchActionsRequestSelector,
  clearBatchResponseToShowSelector,
  selectBatchActionResponseToShow,
  selectedBatchActionsRequestSelector,
} from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { ResourcesTable } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/ResourcesTable";
import { ConfirmationTitle } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/ConfirmationTitle";
import { ConfirmationActionButtons } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/ConfirmationActionButtons";
import { ResultsTitle } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/ResultsTitle";
import { ResponseActionButtons } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/ResponseActionButtons";
import { useGetDisplayMode } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useGetDisplayMode";
import { batchActionsAriaLabels } from "@/components/common/ResourceListTable/batchActions/batchActionsAriaLabels";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 60%;
    min-width: 900px;
    max-width: unset;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 8px 24px 16px;
  }
`;

export const BatchActionsDialog: React.FC = () => {
  const batchActionsRequest = useBatchActionsStore(
    selectedBatchActionsRequestSelector
  );
  const batchActionResponseToShow = useBatchActionsStore(
    selectBatchActionResponseToShow
  );
  const clearBatchActionsRequest = useBatchActionsStore(
    clearBatchActionsRequestSelector
  );

  const clearBatchResponseToShow = useBatchActionsStore(
    clearBatchResponseToShowSelector
  );

  const displayMode = useGetDisplayMode();
  const open = !!batchActionsRequest || !!batchActionResponseToShow;

  const onDialogClose = () => {
    displayMode === "confirmation"
      ? clearBatchActionsRequest()
      : clearBatchResponseToShow();
  };

  return (
    <StyledDialog
      open={open}
      onClose={onDialogClose}
      aria-label={batchActionsAriaLabels.batchActionsDialog.mainContent}
    >
      <StyledDialogTitle>
        {displayMode === "confirmation" ? (
          <ConfirmationTitle />
        ) : (
          <ResultsTitle />
        )}
      </StyledDialogTitle>

      <DialogContent>
        <ResourcesTable />
      </DialogContent>
      <StyledDialogActions>
        {displayMode === "confirmation" ? (
          <ConfirmationActionButtons />
        ) : (
          <ResponseActionButtons />
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};
