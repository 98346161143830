import {
  BaseOption,
  FullField,
  Operator,
  OptionGroup,
  RuleGroupType,
  RuleType,
  ValidationResult,
} from "react-querybuilder";

export interface ExtendedOperator extends Operator {
  successors?: string[];
  predecessors?: string[];
  placeholder?: string;
}

export interface FieldWithOperators extends FullField {
  value: string;
  operators: ExtendedOperator[];
}

export type UIRuleType = RuleType & {
  canAddRule?: boolean;
  allowRemove?: boolean;
};

export type QueryWithError = RuleGroupType & { error?: string };
export type GroupWithError = RuleGroupType & { error?: string };
export type RuleWithError = RuleType & { error?: ValidationResult };

export function isBaseOption(
  o: BaseOption | OptionGroup<BaseOption>
): o is BaseOption {
  return !("options" in o);
}
