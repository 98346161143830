import { muiTheme } from "@komodorio/design-system";
import { useMemo } from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import type JobEventGroup from "@/components/common/EventGroup/jobEvent/JobEventGroup";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";
import { FlowType } from "@/components/AiInvestigation/types";
import { useWorkflowRunById } from "@/components/common/EventGroup/workflowIssues/useWorkflowRunById";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";
import { AiInvestigationTitle } from "@/components/AiInvestigation/AiInvestigationTitle";
import { KlaudiaSessionCheckType } from "@/components/monitorsView/useWorkflowsRuns";
import { AiInvestigationOutput } from "@/components/InvestigationMode/availability/AiInvestigation/types";
import { JobState } from "@/shared/types/job";
import { KubernetesJobResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";

export const FailedJobInvestigationSection: React.FC<{
  eventGroup: JobEventGroup;
}> = ({ eventGroup }) => {
  const jobResource = useMemo(
    () => ({
      kind: KubernetesJobResource.Kind,
      name: eventGroup.name,
      namespace: eventGroup.namespace,
      cluster: eventGroup.clusterName,
    }),
    [eventGroup.clusterName, eventGroup.name, eventGroup.namespace]
  );

  const lastEvent = eventGroup.events[eventGroup.events.length - 1];
  const eventType = lastEvent.cronJobUid
    ? MonitorType.CronJob
    : MonitorType.Job;
  const { data: wfRun } = useWorkflowRunById(lastEvent.id, eventType);

  const aiInvestigationSessionId = useMemo(() => {
    const aiInvestigationInfo = wfRun?.results.find(
      (c) => c.type === KlaudiaSessionCheckType
    );
    return (aiInvestigationInfo?.output as AiInvestigationOutput | undefined)
      ?.sessionId;
  }, [wfRun]);

  if (!aiInvestigationSessionId && eventGroup.status === JobState.deleted) {
    return (
      <>
        <Stack padding="16px 24px" gap={1}>
          <AiInvestigationTitle richSession={null} />
          <Alert
            variant="outlined"
            severity="error"
            sx={{
              width: "fit-content",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color={muiTheme.palette.error.dark}
              marginRight="8px"
            >
              AI investigation cannot be executed on a job that has been
              deleted.
            </Typography>
          </Alert>
        </Stack>
        <Divider />
      </>
    );
  }

  return (
    <>
      <AiInvestigation
        drawerData={{
          drawerType: DrawerType.EventDrawerById,
          eventIds: eventGroup.events.map((event) => event.id),
          eventType: MonitorType.Job,
          serviceId: eventGroup.serviceId,
        }}
        requestId={eventGroup.jobUid}
        sessionId={aiInvestigationSessionId}
        resource={jobResource}
        isHealthy={!eventGroup.isFailed}
        flowType={FlowType.FailedJobRun}
        padding="16px"
      />
      <Divider />
    </>
  );
};
