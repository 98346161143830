import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useMemo } from "react";
import { parseISO } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../../../../components/ResourceView/resources/pod";
import {
  apiV1WorkloadsPodsGroupedNativeEventsSearchPostUrl,
  GroupedPodEventsRequest,
  GroupedPodEventsResponse,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";
import { buildKomodorUid } from "../../resourcesAPIUtils";
import { useWorkspaceQueryKey } from "../../../workspaces-api/useWorkspaceQueryKey";

const WORKLOADS_GROUPED_POD_EVENTS_SEARCH_PATH =
  "/workloads/pods/grouped-native-events/search";

const fetchGroupedPodEvents = async (
  apiClient: AxiosInstance,
  params: GroupedPodEventsRequest
): Promise<GroupedPodEventsResponse> => {
  const { data } = await apiClient.post(
    apiV1WorkloadsPodsGroupedNativeEventsSearchPostUrl(
      {
        groupedPodEventsRequest: params,
      },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useGetGroupedPodEvents = (
  params: GroupedPodEventsRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([
    WORKLOADS_GROUPED_POD_EVENTS_SEARCH_PATH,
    params,
  ]);

  return useQuery(keys, () => fetchGroupedPodEvents(apiClient, params), {
    enabled,
    keepPreviousData: true,
  });
};

export const useGetGroupedPodEventsForResource = (resource: Pod) => {
  const podEventsParams = useMemo(() => {
    return {
      podIdentifiers: [
        {
          komodorUid: buildKomodorUid({
            resourceName: resource.name,
            clusterName: resource.cluster,
            namespace: resource.namespace,
            kind: resource.kind,
          }),
          podUid: resource.id,
        },
      ],
      fromEpoch: new Date(
        parseISO(resource.fullObj?.metadata?.creationTimestamp ?? "")
      ).getTime(),
      toEpoch: resource.fullObj?.metadata?.deletionTimestamp
        ? new Date(
            parseISO(resource.fullObj.metadata.deletionTimestamp)
          ).getTime() + 5000 // Add 5 seconds to the deletion timestamp to make sure we get events in proximity to the deletion timestamp
        : Date.now(),
    };
  }, [resource]);

  return useGetGroupedPodEvents(podEventsParams, true);
};
