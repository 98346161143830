import Resource from "./resources";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { IRbacV2Flag } from "@/shared/types/featureFlags";
import { getViewActionForResource } from "@/shared/utils/rbac/resourceToAction";
import { useCanIDoAnyInScope } from "@/shared/hooks/auth-service/client/auth/useCanIDoAnyInScope";

interface overrideNamespaceInterface {
  Resources: string[];
}
export const useHasPermissionToViewResource = (resource: Resource) => {
  const { cluster, namespace, kind } = resource ?? {};
  const { isKomodorAdmin } = useUserMetadata();
  const {
    hasPermissionToViewResource,
    overrideNamespaceScopeForResource,
    rbacV2,
  } = useOverridableFlags();
  const rbacV2Flag = rbacV2 as IRbacV2Flag;
  const overrideValue =
    overrideNamespaceScopeForResource as overrideNamespaceInterface;

  const actionsAllowedForResource: string[] = ["view:all"]; // view:all HAS to be the first action in the list in order to be backward-compatible with the V1 rbac system
  if (rbacV2Flag?.useScopePermissionsToResolveCanI) {
    actionsAllowedForResource.push(getViewActionForResource(kind));
  }

  const { data, isLoading } = useCanIDoAnyInScope(
    {
      actions: actionsAllowedForResource,
      scope: [{ cluster, ...(namespace && { namespaces: [namespace] }) }],
    },
    { enabled: !!cluster, staleTime: 0 }
  );

  if (!hasPermissionToViewResource) {
    return {
      canViewResource: true,
      isLoadingCanViewResource: false,
    };
  }
  if (overrideValue?.Resources.includes(kind)) {
    return {
      canViewResource: true,
      isLoadingCanViewResource: false,
    };
  }
  return {
    canViewResource: data?.canDo || isKomodorAdmin,
    isLoadingCanViewResource: isLoading,
  };
};
