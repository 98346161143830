import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Typography, { typographyClasses } from "@mui/material/Typography";
import MuiBreadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import type { BreadcrumbsProps as MuiBreadcrumbsProps } from "@mui/material/Breadcrumbs";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";

const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  className?: string;
  breadcrumbs: string[];
  allowOverflow?: boolean;
  sx?: MuiBreadcrumbsProps["sx"];
  lastIsActive?: boolean;
  compact?: boolean;
}

export const Breadcrumbs: React.FC<Props> = ({
  breadcrumbs,
  className,
  allowOverflow = true,
  sx,
  lastIsActive = false,
  compact = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      className={className}
    >
      {breadcrumbs.length > 0 && (
        <MuiBreadcrumbs
          sx={
            allowOverflow
              ? sx
              : {
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  "& .MuiBreadcrumbs-ol": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    "& .MuiBreadcrumbs-li": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      flex: "0 auto",
                      flexShrink: "1000",
                      display: "inline-block",
                    },
                  },
                  [`& .${breadcrumbsClasses.separator}`]: compact && {
                    marginLeft: 0,
                    marginRight: 0,
                  },
                  [`& .${breadcrumbsClasses.li}:last-of-type .${typographyClasses.root}`]:
                    compact && {
                      color: "text.primary",
                    },
                  ...(sx ?? {}),
                }
          }
          separator={
            <ChevronRight fontSize="small" sx={{ width: 16, height: 16 }} />
          }
        >
          {breadcrumbs.map((breadcrumb, index) => (
            <Tooltip
              title={breadcrumb}
              placement="bottom"
              arrow={false}
              key={breadcrumb}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
              disableInteractive={true}
            >
              <EllipsisTypography
                key={breadcrumb}
                variant="body3"
                color={
                  lastIsActive && index === breadcrumbs.length - 1
                    ? "text.primary"
                    : "text.secondary"
                }
                aria-label={breadcrumb}
              >
                {breadcrumb}
              </EllipsisTypography>
            </Tooltip>
          ))}
        </MuiBreadcrumbs>
      )}
    </Box>
  );
};
