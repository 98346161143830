import { useMemo } from "react";

import { useKomodorServices } from "../../../../../../../../shared/hooks/useKomodorServices";
import { Dictionary } from "../../../../../../../../shared/types/Dictionary";

import { WorkspaceKind } from "@/generated/workspacesApi";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

type AggregatedServiceProperties = {
  namespaces: Set<string>;
  labels: Dictionary<Set<string>>;
};

type ServiceProperty = keyof AggregatedServiceProperties;
const servicePropertyToServiceIdentifierType: Record<
  ServiceProperty,
  WorkspaceKind
> = {
  namespaces: WorkspaceKind.Namespace,
  labels: WorkspaceKind.Label,
};

export const getServiceIdentifierTypeBy = (
  serviceProperty: string
): WorkspaceKind => {
  const key = serviceProperty as ServiceProperty;
  return servicePropertyToServiceIdentifierType[key];
};

export const useGetAggregatedServicesScopes = ():
  | AggregatedServiceProperties
  | undefined => {
  const { allowCreationOldWorkspaces } = useOverridableFlags();

  const fetchedServices = useKomodorServices().unscopedServices;
  return useMemo(() => {
    const aggregatedServiceProperties: AggregatedServiceProperties = {
      namespaces: new Set(),
      labels: {},
    };

    return fetchedServices?.reduce((acc, curr) => {
      /** TODO: add support for future service properties such as annotations */
      curr.env && acc.namespaces.add(curr.env);

      if (curr.labels && allowCreationOldWorkspaces) {
        Object.entries(curr.labels).forEach(([key, value]) => {
          if (!acc.labels[key]) {
            acc.labels[key] = new Set();
          }

          acc.labels[key].add(value);
        });
      }

      return acc;
    }, aggregatedServiceProperties);
  }, [allowCreationOldWorkspaces, fetchedServices]);
};
