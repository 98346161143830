import React, { useCallback, useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import { UseQueryResult } from "@tanstack/react-query";

import { TrackedKey } from "@/generated/accounts";
import { useDeleteTrackedKey } from "@/shared/hooks/accounts-service/client/trackedKeys/useDeleteTrackedKey";

export type Refetch = UseQueryResult<TrackedKey[], unknown>["refetch"];

export const DeleteTrackedKeyModal = ({
  onClose,
  keyToDelete,
  refetch,
}: {
  onClose: () => void;
  keyToDelete: TrackedKey;
  refetch: Refetch;
}) => {
  const { mutateAsync: onDelete, isLoading } = useDeleteTrackedKey();
  const [error, setError] = React.useState<Error | null>(null);

  const handleClose = useCallback(
    async (confirm: boolean) => {
      if (!confirm) {
        return onClose();
      }

      try {
        await onDelete({ keyId: keyToDelete.id });
        onClose();
        await refetch();
      } catch (e) {
        setError(e as Error);
      }
    },
    [onClose, onDelete, keyToDelete, refetch]
  );

  const content = useMemo(
    () => (
      <Stack rowGap={1}>
        <Typography variant="body2">
          Are you sure you want to delete the {keyToDelete.type}{" "}
          <b>{keyToDelete.name}</b>?
        </Typography>
        {!!error && (
          <Typography
            variant="body3"
            color="error"
            sx={{ whiteSpace: "pre-wrap", lineHeight: 0.75 }}
          >
            An error occurred: {error.message}.{"\n\n"}
            Please try again later.
          </Typography>
        )}
      </Stack>
    ),
    [keyToDelete, error]
  );

  return (
    <ConfirmationDialog
      title="Delete key"
      content={content}
      onClose={handleClose}
      type="error"
      width="unset"
      okText={isLoading ? "Deleting..." : "Delete key"}
      okDisabled={isLoading}
      height="max-content"
    />
  );
};
