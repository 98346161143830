/* eslint-disable max-lines */
import {
  Compared,
  ComparisonTableProps,
  LightTooltip,
  MuiSelectionOption,
  RowData,
} from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Code from "@mui/icons-material/Code";
import { useMemo } from "react";

import { SHOW_ONLY_DRIFT_SERVICES_DRAWER_KEY } from "../Services/constants";

import { ResourcesDiffModalParams } from "./ResourcesDiffModal";
import {
  ResourcesRecords,
  ComparisonHelmResourcesWithResources,
  Resource,
  CellRendererDynamicResources,
  DiffType,
} from "./types";
import { komodorServiceKind } from "./constants";

import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { dispatchEvent } from "@/shared/hooks/analytics";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
const resourceCellRenderer =
  (field: keyof ResourcesRecords): CellRendererDynamicResources =>
  (rowData) => {
    const { isBaseline, data } = rowData;
    if (!isBaseline) {
      return null;
    }
    const resource = data[field];
    const value = resource?.namespace
      ? `${resource.value} (Namespace: ${resource.namespace})`
      : resource?.value;

    return value ? (
      <Typography
        variant="body2"
        color={isBaseline ? muiColors.gray[600] : "text.primary"}
      >
        {value}
      </Typography>
    ) : null;
  };

const onResourceClick = ({
  field,
  rowData,
  baselineResourceKindComparison,
  resourceKind,
  setResourcesDiffModal,
  pushDrawer,
  setShowOnlyServicesDrift,
  isResourceKindSupported,
}: {
  field: keyof ResourcesRecords;
  rowData: RowData<ComparisonHelmResourcesWithResources>;
  baselineResourceKindComparison: ComparisonHelmResourcesWithResources;
  resourceKind: string;
  isResourceKindSupported: boolean;
  setResourcesDiffModal: (diffModal: ResourcesDiffModalParams | null) => void;
  pushDrawer: (drawerData: DrawerStatePush) => void;
  setShowOnlyServicesDrift: (showOnlyServicesDrift: boolean) => void;
}) => {
  const { isBaseline, data } = rowData;
  if (isBaseline) {
    return;
  }
  const diffType = getDiffTypeByData({
    field,
    data,
    isResourceKindSupported,
    resourceKind,
  });
  if (
    diffType === DiffType.ResourceDoesNotExist ||
    diffType === DiffType.ResourceKindNotSupported
  ) {
    return;
  }
  const resource = baselineResourceKindComparison?.[field];
  if (!resource || !data.data?.cluster?.value) {
    return;
  }
  const baselineKomodorUidParams = {
    kind: resourceKind,
    resourceName: resource.value,
    namespace: resource.namespace ?? "",
    clusterName: baselineResourceKindComparison.cluster,
  };
  const comparedKomodorUidParams = {
    kind: resourceKind,
    resourceName: resource.value,
    namespace: resource.namespace ?? "",
    clusterName: data.data.cluster.value,
  };
  if (diffType === DiffType.CompareYamls) {
    dispatchEvent(AnalyticEvents.DriftAnalysis.Helm.YamlComparison, {
      baselineKomodorUidParams,
      comparedKomodorUidParams,
    });
    setResourcesDiffModal({
      baselineKomodorUidParams,
      comparedKomodorUidParams,
    });
    return;
  }
  if (diffType === DiffType.CompareServicesDrift) {
    const baselineKomodorUid = buildKomodorUid(baselineKomodorUidParams);
    const comparedKomodorUid = buildKomodorUid(comparedKomodorUidParams);
    dispatchEvent(
      AnalyticEvents.DriftAnalysis.Helm.ServicesDriftComparisonDrawer,
      {
        baselineKomodorUidParams,
        comparedKomodorUidParams,
      }
    );
    setShowOnlyServicesDrift(true);
    pushDrawer(
      getServicesDriftDrawerData({
        baselineSelection: {
          label: baselineKomodorUid,
          value: baselineKomodorUid,
        },
        comparedSelections: [
          {
            label: comparedKomodorUid,
            value: comparedKomodorUid,
          },
        ],
      })
    );
  }
};

const enableClickBasedOnData = ({
  field,
  rowData,
  resourceKind,
  isResourceKindSupported,
}: {
  field: keyof ResourcesRecords;
  rowData: RowData<ComparisonHelmResourcesWithResources>;
  resourceKind: string;
  isResourceKindSupported: boolean;
}): boolean => {
  const { isBaseline, data } = rowData;
  if (isBaseline) {
    return false;
  }
  const diffType = getDiffTypeByData({
    field,
    data,
    isResourceKindSupported,
    resourceKind,
  });
  // cell will enable click on cases:
  // 1. for komodor services comparison - if resource exists and has diff - compare services drift
  // 2. for non komodor services comparison - if resource exists and does not have diff - compare yamls
  return (
    diffType === DiffType.CompareYamls ||
    diffType === DiffType.CompareServicesDrift
  );
};

const getDiffTypeByData = ({
  field,
  data,
  isResourceKindSupported,
  resourceKind,
}: {
  field: keyof ResourcesRecords;
  data: Compared<ComparisonHelmResourcesWithResources>;
  isResourceKindSupported: boolean;
  resourceKind: string;
}) => {
  const isEmpty = data.data?.[field]?.isEmpty;
  const isDiff = data.data?.[field]?.isDiff ?? false;
  if (isEmpty) return DiffType.ResourceDoesNotExist;
  if (komodorServiceKind[resourceKind] && isDiff) {
    return DiffType.CompareServicesDrift;
  }
  if (!isResourceKindSupported) {
    return DiffType.ResourceKindNotSupported;
  }
  if (!komodorServiceKind[resourceKind] && !isDiff) {
    return DiffType.CompareYamls;
  }
  return "";
};

export const useResourcesFieldsAttributes = ({
  baselineResourceKindComparison,
  resourceKind,
  isResourceKindSupported,
  showOnlyDrift,
  setResourcesDiffModal,
}: {
  baselineResourceKindComparison:
    | ComparisonHelmResourcesWithResources
    | undefined;
  resourceKind: string | undefined;
  isResourceKindSupported: boolean;
  showOnlyDrift: boolean;
  setResourcesDiffModal:
    | ((diffModal: ResourcesDiffModalParams | null) => void)
    | undefined;
}): ComparisonTableProps<ComparisonHelmResourcesWithResources>["attributes"] => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const [, setShowOnlyServicesDrift] =
    useStringifiedStateInSearchParams<boolean>(
      SHOW_ONLY_DRIFT_SERVICES_DRAWER_KEY
    );

  return useMemo(() => {
    if (
      !baselineResourceKindComparison ||
      !resourceKind ||
      !setResourcesDiffModal
    ) {
      return [];
    }
    const resourceEntries = Object.entries(
      baselineResourceKindComparison ?? {}
    ).filter(
      ([key, value]) => key.startsWith("resource-") && typeof value === "object"
    ) as [keyof ResourcesRecords, Resource][];

    return resourceEntries
      .filter(([, resource]) => !showOnlyDrift || resource.isDiff)
      .map(([field]) => {
        return {
          headerName: "",
          field,
          renderCell: resourceCellRenderer(field),
          hideValueIfDifferent: true,
          tooltipTitleBasedOnData: (data) => {
            // 1. for non supported resource kind - if resource exists and has no diff - show tooltip that resource kind is not supported
            // 2. if compared resource does not exist (N/A) - resource does not exist tooltip
            const diffType = getDiffTypeByData({
              field,
              data,
              isResourceKindSupported,
              resourceKind,
            });
            switch (diffType) {
              case DiffType.ResourceKindNotSupported:
                return "YAML comparison is not allowed, resource kind is not supported";
              case DiffType.ResourceDoesNotExist:
                return "Resource does not exist";
              default:
                return "";
            }
          },
          isExistanceComparison: !komodorServiceKind[resourceKind],
          hoverableRightItem: (data) => {
            const diffType = getDiffTypeByData({
              field,
              data,
              isResourceKindSupported,
              resourceKind,
            });
            if (diffType === DiffType.CompareYamls) {
              return (
                <LightTooltip title="Compare YAML" placement="top">
                  <Code />
                </LightTooltip>
              );
            }
            return null;
          },
          enableClickBasedOnData: (rowData) => {
            return enableClickBasedOnData({
              field,
              rowData,
              resourceKind,
              isResourceKindSupported,
            });
          },
          onClick: (rowData) => {
            onResourceClick({
              field,
              rowData,
              baselineResourceKindComparison,
              resourceKind,
              pushDrawer,
              setResourcesDiffModal,
              isResourceKindSupported,
              setShowOnlyServicesDrift,
            });
          },
        };
      });
  }, [
    baselineResourceKindComparison,
    isResourceKindSupported,
    pushDrawer,
    resourceKind,
    setResourcesDiffModal,
    setShowOnlyServicesDrift,
    showOnlyDrift,
  ]);
};

const getServicesDriftDrawerData = ({
  baselineSelection,
  comparedSelections,
}: {
  baselineSelection: MuiSelectionOption<string> | null;
  comparedSelections: MuiSelectionOption<string>[] | null;
}): DrawerStatePush => {
  return {
    drawerType: DrawerType.DriftServicesAttributesComparisonDrawer,
    baselineSelection,
    comparedSelections,
  };
};
