import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { useEffect, useMemo, useState, useCallback } from "react";

import { BaseComparisonSelection } from "../common/BaseComparisonSelection";
import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import {
  useComparedOptionsWithDisabled,
  useSelectedComparedOptionsValues,
} from "../common/hooks";

import { MAX_COMPARED_SERVICES } from "./constants";
import { getComparedServicesOptions } from "./selectionOptionsUtils";
import { useBaselineServiceOptions } from "./hooks";

import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";

export const ServicesComparisonSelection: React.FC = () => {
  const { services } = useKomodorServices().servicesAsDictionary;
  const {
    baselineSelection,
    comparedSelections,
    isBaselineSelectorOpen,
    isComparedSelectorOpen,
  } = useDriftComparisonContext();

  const updateComparedServicesOptions = useCallback(() => {
    setComparisonServicesOptions(
      getComparedServicesOptions({
        services,
        baselineServiceId: baselineSelection,
        comparedServicesIds: comparedSelections,
      })
    );
  }, [services, baselineSelection, comparedSelections]);

  const [comparedServicesOptions, setComparisonServicesOptions] = useState<
    MuiSelectionOption<string>[]
  >([]);

  useEffect(() => {
    if (!isComparedSelectorOpen) {
      updateComparedServicesOptions();
    }
  }, [isComparedSelectorOpen, updateComparedServicesOptions]);

  const comparedOptions = useComparedOptionsWithDisabled({
    selectedComparedOptions: comparedSelections,
    comparedOptions: comparedServicesOptions,
    maxCompared: MAX_COMPARED_SERVICES,
  });

  const selectedComparedOptionsValues =
    useSelectedComparedOptionsValues(comparedSelections);

  const baselineServicesOptions: MuiSelectionOption<string>[] =
    useBaselineServiceOptions(baselineSelection, services);

  const servicesLength = useMemo(
    () => Object.keys(services).length,
    [services]
  );

  const baselineServiceKind = useMemo(
    () =>
      baselineSelection
        ? parseKomodorUid(baselineSelection?.value)?.kind
        : undefined,
    [baselineSelection]
  );

  return (
    <BaseComparisonSelection
      baselineSelectEvent={
        AnalyticEvents.DriftAnalysis.Service.BaselineSelected
      }
      comparedSelectEvent={
        AnalyticEvents.DriftAnalysis.Service.ComparedServicesSelected
      }
      singleSelectProps={{
        virtualizerProps: { estimateSize: () => 28 },
        ariaLabel:
          AriaLabels.DriftAnalysis.Service.ServicesSelection.BaselineSelector,
        options: baselineServicesOptions,
        placeholder: "Select comparison baseline",
        label: "Comparison Baseline",
        value: baselineSelection || undefined,
        texts: { searchPlaceholder: "Search service" },
        disabled: servicesLength === 0,
        externalOpen: isBaselineSelectorOpen,
      }}
      advancedMultiSelectProps={{
        options: comparedOptions,
        label: "Compared Services",
        ariaLabel:
          AriaLabels.DriftAnalysis.Service.ServicesSelection
            .ComparedServicesSelector,
        placeholder: "Select services to compare",
        value: selectedComparedOptionsValues,
        disabled: servicesLength === 0 || !baselineSelection,
        externalOpen: isComparedSelectorOpen,
      }}
      baselineKind={baselineServiceKind}
      maxCompared={MAX_COMPARED_SERVICES}
      comparedOptionsLength={comparedOptions.length}
      withPolicyPopup
    />
  );
};
