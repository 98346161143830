import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  apiV1PlansGetUrl,
  Plan,
  PlansApiApiV1PlansGetRequest,
} from "@/generated/accounts";
import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";

const PLAN_PATH = "/api/v1/plans";

const getPlan = async (
  apiClient: AxiosInstance,
  params: PlansApiApiV1PlansGetRequest | null
): Promise<Plan[]> => {
  if (!params) {
    return [];
  }
  const { data } = await apiClient.get(
    apiV1PlansGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAccountPlan = (accountPlan?: string) => {
  const apiClient = useAccountsApiClient();
  const params = accountPlan ? { accountPlan: accountPlan } : null;
  const { data } = useQuery([PLAN_PATH, params], () =>
    getPlan(apiClient, params)
  );
  // this is in order to return the same signature as the gql hook. can be modified to return the data when gql is deleted
  return data?.length ? { plan: data } : null;
};
