import React, { useMemo } from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import WorkflowIssueEventGroup, {
  toWorkflowIssueEvent,
} from "../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { groupDeployEvents } from "../EventGroup/deployEvent/DeployEventGroup";
import { groupJobEvents } from "../EventGroup/jobEvent/JobEventGroup";

import EventDrawer from "./EventDrawer";
import { IEventDrawerById } from "./types";

import { useGetMonitorRunById } from "@/shared/hooks/monitors-api/client/monitors/useGetMonitorRunById";
import { useJobEventsByIds } from "@/shared/hooks/resources-api/client/events/jobs/useJobEventById";
import { MonitorType } from "@/generated/monitorsApi";
import { ResourceNotFound } from "@/components/ResourceView/ResourceNotFound";
import { useGetDeploys } from "@/shared/hooks/monitors-api/client/deploys/useGetDeploys";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { JobFragment } from "@/generated/graphql";
import { DrawerProps } from "@/shared/store/drawersStackStore/types";

const isAvailabilityEnabled = (eventType: MonitorType) =>
  eventType === MonitorType.Availability ||
  eventType === MonitorType.Node ||
  eventType === MonitorType.Pvc;

export const EventDrawerById: React.FC<DrawerProps & IEventDrawerById> = ({
  open,
  onClose,
  eventIds,
  eventType,
  serviceId,
  violationId,
}) => {
  const flags = useOverridableFlags();
  const availIsEnabled = isAvailabilityEnabled(eventType);
  const deployIsEnabled = eventType === MonitorType.Deploy;
  const jobIsEnabled = eventType === MonitorType.Job;
  const { isLoading: availIsLoading, data: availData } = useGetMonitorRunById(
    {
      id: eventIds[0],
      type: eventType,
    },
    { enabled: availIsEnabled }
  );
  const { isLoading: deployIsLoading, data: deployData } = useGetDeploys(
    {
      ids: eventIds,
    },
    deployIsEnabled
  );
  const { isLoading: jobIsLoading, data: jobData } = useJobEventsByIds(
    eventIds.map((id) => ({ id })),
    { enabled: jobIsEnabled }
  );

  const event = useMemo(() => {
    switch (eventType) {
      case MonitorType.Node:
      case MonitorType.Pvc:
      case MonitorType.Availability:
        return availData?.data
          ? new WorkflowIssueEventGroup(
              toWorkflowIssueEvent(availData.data, serviceId)
            )
          : undefined;
      case MonitorType.Deploy:
        return deployData?.data
          ? groupDeployEvents(deployData.data, flags)?.[0]
          : undefined;
      case MonitorType.Job:
        return jobData
          ? groupJobEvents(jobData.flatMap((j) => j.data) as JobFragment[])?.[0]
          : undefined;
      default:
        return undefined;
    }
  }, [availData?.data, deployData?.data, eventType, flags, jobData, serviceId]);

  const isLoading =
    (availIsEnabled && availIsLoading) ||
    (deployIsEnabled && deployIsLoading) ||
    (jobIsEnabled && jobIsLoading);

  return (
    <EventDrawer event={event} open={open} onClose={onClose}>
      {isLoading ? (
        <LinesLoader />
      ) : !event ? (
        <ResourceNotFound />
      ) : (
        event.renderEventDetails(onClose, violationId)
      )}
    </EventDrawer>
  );
};
