import React from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import {
  AgentStatus,
  APIKeyCell,
  ColumnHeader,
  DateCell,
  RestartCell,
  TextCell,
} from "@/pages/organization-settings/account/AgentsPage/components";
import { APIKeyCellProps } from "@/pages/organization-settings/account/AgentsPage/types";
import { Agent } from "@/generated/agents";

export const agentDataGetters = {
  getAgentVersion: (params: Partial<GridValueGetterParams>) => {
    return params.row.configuration.appmetadata.version;
  },
  getChartVersion: (params: Partial<GridValueGetterParams>) => {
    return params.row.configuration.chartversion;
  },
  getK8sVersion: (params: Partial<GridValueGetterParams>) => {
    const { major, minor } =
      params.row.configuration.appmetadata.kubernetesVersion;
    return `v${major}.${minor}`;
  },
};

export const getColumnsDefinitions = ({
  refetch,
  setAgentToRestart,
}: {
  refetch: () => void;
  setAgentToRestart: (agent: Agent) => void;
}): GridColDef<Agent>[] =>
  [
    {
      headerName: "",
      field: "inactive",
      width: 10,
      minWidth: 40,
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      resizable: false,
      disableReorder: true,
      renderHeader: ColumnHeader,
      includeHeaders: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        return <AgentStatus inactive={row.inactive} />;
      },
      type: "string",
    },
    {
      headerName: "Cluster Name",
      field: "clusterName",
      flex: 1,
      renderHeader: ColumnHeader,
      renderCell: TextCell,
    },
    {
      headerName: "Agent Version",
      field: "agentVersion",
      flex: 1,
      renderHeader: ColumnHeader,
      type: "string",
      valueGetter: agentDataGetters.getAgentVersion,
      sortComparator: (v1: string, v2: string) =>
        v1.localeCompare(v2, undefined, { numeric: true }),
    },
    {
      headerName: "Agent ID",
      field: "id",
      flex: 2,
      renderHeader: ColumnHeader,
      renderCell: TextCell,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      renderHeader: ColumnHeader,
      renderCell: DateCell,
    },
    {
      headerName: "Last Heartbeat Recieved",
      field: "lastSeenAlive",
      flex: 1,
      renderHeader: ColumnHeader,
      renderCell: DateCell,
    },
    {
      headerName: "Chart Version",
      field: "chartVersion",
      flex: 1,
      renderHeader: ColumnHeader,
      valueGetter: agentDataGetters.getChartVersion,
    },
    {
      headerName: "API Key",
      field: "installationId",
      flex: 2,
      renderHeader: ColumnHeader,
      renderCell: (params: GridRenderCellParams<Agent>) => (
        <APIKeyCell {...params} refetch={refetch} visibility="hidden" />
      ),
      cellClassName: "api-key-cell",
    },
    {
      headerName: "K8s Version",
      field: "K8sVersion",
      flex: 1,
      renderHeader: ColumnHeader,
      valueGetter: agentDataGetters.getK8sVersion,
    },
    {
      renderHeader: ColumnHeader,
      headerName: "Actions",
      field: "actions",
      disableColumnMenu: true,
      sortable: false,
      hideable: false,
      resizable: false,
      disableReorder: true,
      renderCell: (params: APIKeyCellProps) => (
        <RestartCell
          {...params}
          refetch={refetch}
          setRestartAgent={setAgentToRestart}
        />
      ),
    },
  ] as GridColDef[];
