import { useEffect, useMemo, useState } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { useFetchNodeStatusQuery } from "../../../generated/graphql";
import useAgentTaskExecution from "../useAgentTaskExecution/useAgentTaskExecution";
import { useQueryRefreshWithVariables } from "../useQueryWithVariables";
import { KomodorServiceResourceKind } from "../../types/Resource";

export interface K8sNode {
  nodeName: string;
  eventTime: string;
  readyState: string;
  readyReason: string;
  readyMessage: string;
  ready: boolean;
  id: string;
  lastChanged: Date;
  isDeleted: boolean;
  cpuCapacity: string;
  memoryPressure: string;
  diskPressure: string;
  lastHeartbeat: string;
  agentId: string;
  clusterName: string;
  pods?: string[];
}

interface capacity {
  cpu: string;
  pods: string;
  memory: string;
}

interface condition {
  type: string;
  reason: string;
  status: string;
  message: string;
  lastHeartbeatTime: string;
  lastTransitionTime: string;
}

interface LiveNodeStatus {
  capacity: capacity;
  conditions: condition[];
}

interface LiveNode {
  clusterName: string;
  pods: string[];
  nodeStatus: LiveNodeStatus;
}

const TASK_TYPE = "node-status";

const concatMatchLabels = (matchLabels: Record<string, string>): string => {
  return Object.entries(matchLabels)
    .map(([label, value]) => `${label}=${value}`)
    .join(",");
};

export const useNodesByServiceLiveData = (
  resource: KomodorServiceResourceKind,
  agentId: string
): ResourceTableModelRow[] | null => {
  const matchLabels = useMemo(() => {
    return concatMatchLabels(resource.selector?.matchLabels ?? {});
  }, [resource.selector?.matchLabels]);

  const [retries, setRetries] = useState(0);

  const executeTaskVars = useMemo(() => {
    return {
      agentId,
      type: TASK_TYPE,
      data: {
        labelSelector: matchLabels,
        namespace: resource.namespace,
      },
    };
  }, [matchLabels, agentId, resource?.namespace]);

  const [fetchPayloadVars, failureMessage, , setTaskTimeoutCallback] =
    useAgentTaskExecution(executeTaskVars);

  const [nodesDataRes, refresh] = useQueryRefreshWithVariables(
    useFetchNodeStatusQuery,
    fetchPayloadVars
  );

  const MAX_RETRIES = 300;

  useEffect(() => {
    if (
      nodesDataRes?.node_status_agent_task.length ||
      retries > MAX_RETRIES ||
      failureMessage
    ) {
      if (retries > MAX_RETRIES) {
        setTaskTimeoutCallback();
      }
      return;
    }
    const timeout = setTimeout(() => {
      if (!nodesDataRes?.node_status_agent_task.length) {
        refresh();
      }
      setRetries(retries + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [failureMessage, nodesDataRes, refresh, retries, setTaskTimeoutCallback]);

  const nodesLiveData = nodesDataRes?.node_status_agent_task[0]?.status as
    | Record<string, LiveNode>
    | undefined;

  const nodes = useMemo(() => {
    return nodesLiveData ? mapLiveData(nodesLiveData, resource.cluster) : null;
  }, [nodesLiveData, resource.cluster]);

  return useMemo(() => {
    if (!nodes) return null;
    return nodes.map((node) => {
      return {
        name: node.nodeName,
        status: node.ready ? "Ready" : "NotReady",
      };
    });
  }, [nodes]);
};

const mapLiveData = (
  liveData: Record<string, LiveNode>,
  clusterName: string | undefined
): K8sNode[] => {
  const nodes: K8sNode[] = [];

  Object.keys(liveData).forEach((k) => {
    const readyCondition = liveData[k]?.nodeStatus.conditions.filter(
      (c) => c.type === "Ready"
    )[0];
    const diskPressure = liveData[k]?.nodeStatus.conditions.filter(
      (c) => c.type === "DiskPressure"
    )[0];
    const memoryPressure = liveData[k]?.nodeStatus.conditions.filter(
      (c) => c.type === "MemoryPressure"
    )[0];

    nodes.push({
      agentId: "",
      clusterName: clusterName ?? "N/A",
      cpuCapacity: liveData[k]?.nodeStatus.capacity.cpu ?? "N/A",
      diskPressure: !diskPressure?.status
        ? "Unknown"
        : diskPressure?.status === "False"
        ? "Low"
        : "Sufficient",
      eventTime: "",
      id: "",
      isDeleted: false,
      lastChanged: new Date(
        liveData[k]?.nodeStatus.conditions[0].lastHeartbeatTime || ""
      ),
      lastHeartbeat:
        liveData[k]?.nodeStatus.conditions[0].lastHeartbeatTime || "",
      memoryPressure: !memoryPressure?.status
        ? "Unknown"
        : memoryPressure?.status === "False"
        ? "Low"
        : "Sufficient",
      nodeName: k,
      pods: liveData[k]?.pods,
      ready: readyCondition?.status === "True",
      readyMessage: readyCondition?.message ?? "-",
      readyReason: readyCondition?.reason ?? "-",
      readyState: readyCondition?.status === "True" ? "Ready" : "Not Ready",
    });
  });
  return nodes;
};
