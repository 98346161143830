import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { DriftType } from "../context/types";

type EmptyStateComparedItemsProps = {
  withButton?: boolean;
  onButtonClick?: () => void;
  type: DriftType;
};

export function EmptyStateComparedItems({
  withButton = false,
  onButtonClick,
  type,
}: EmptyStateComparedItemsProps) {
  const comparisonTypeText =
    type === DriftType.Services ? "service" : "helm release";
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Typography variant="body2">
        No {type === DriftType.Services ? "services" : "helm releases"} were
        added,
        <br />
        please add a {comparisonTypeText} for comparison
      </Typography>
      {withButton && (
        <Button
          sx={{ flexGrow: 0 }}
          variant="contained"
          size="medium"
          onClick={onButtonClick}
        >
          Add {comparisonTypeText} to compare
        </Button>
      )}
    </Box>
  );
}
