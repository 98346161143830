import Typography from "@mui/material/Typography";

import { RichSessionData } from "../useEnrichedSession";

export const SuggestedRemediation: React.FC<{
  session: RichSessionData;
}> = ({ session }) => {
  return session.recommendation ? (
    <>
      <Typography variant="body2" color="text.secondary">
        Suggested
        <br />
        Remediation:
      </Typography>
      <Typography variant="body2">{session.recommendation}</Typography>
    </>
  ) : null;
};
