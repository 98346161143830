import React from "react";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { DataGridPro } from "@mui/x-data-grid-pro";

import {
  GROUPS_ROW_HEIGHT,
  TABLE_STYLES,
  TOTAL_ROW_CLASSNAME,
  TOTAL_ROW_ID,
} from "@/components/Settings/General/NodesCount/consts";
import { NoRowsOverlay } from "@/components/Settings/General/NodesCount/NoRowsOverlay";
import { getColumnsDefinitions } from "@/components/Settings/General/NodesCount/utils";
import { useClustersTable } from "@/components/Settings/General/NodesCount/hooks";
import { NodeCount as GeneratedNodeCount } from "@/generated/accounts";

interface NodesCountTableProps {
  isError?: boolean;
  isFetching?: boolean;
  width: number | string;
  sortedMonths: string[];
  data?: GeneratedNodeCount[];
  clusters: string[];
}

export const NodesCountTable: React.FC<NodesCountTableProps> = ({
  width,
  isError,
  isFetching,
  data,
  sortedMonths,
  clusters,
}) => {
  const { rows, totalRow, columnGroupingModel } = useClustersTable({
    clusters,
    data,
    sortedMonths,
  });

  if (isError) {
    return (
      <Typography color={muiTheme.palette.error.main}>
        Failed to load data
      </Typography>
    );
  }

  if (isFetching) {
    return <CircularProgress size={16} />;
  }

  return (
    <Paper
      sx={{
        width,
        position: "relative",
        height: "100%",
        minHeight: 300,
      }}
    >
      <DataGridPro
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        rows={rows}
        columns={getColumnsDefinitions(sortedMonths)}
        hideFooter
        disableVirtualization
        getRowClassName={(params) =>
          params.id === TOTAL_ROW_ID ? TOTAL_ROW_CLASSNAME : ""
        }
        pinnedRows={{ bottom: [totalRow] }}
        disableColumnMenu
        disableColumnReorder
        sx={TABLE_STYLES}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        columnHeaderHeight={GROUPS_ROW_HEIGHT}
      />
    </Paper>
  );
};
