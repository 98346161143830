import React, { useMemo, useState } from "react";
import { Label } from "recharts";

import { parseKomodorUid } from "../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { GraphContainerWithStatus } from "../../../../../../../../Metrics/GraphContainerWithStatus";
import { Uid } from "../../../../../../../../../shared/utils/generateUid";
import { GraphContainerWithChildren } from "../../../../../../../../Metrics/GraphContainerWithChildren";
import { EnlargedMetricsGraph } from "../../../../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { useReportDrawerLoadingTime } from "../../../hooks/useReportDrawerLoadingTime";
import { ContainerRestartsSupportingDataContainersInner } from "../../../../../../../../../generated/reliabilityApi";
import { useGetCorrelatedDeployEvents } from "../../shared/metrics/hooks/useGetCorrelatedDeployEvents";
import { useIsViewDeployEventDetailsClicked } from "../../shared/metrics/hooks/useIsViewDeployEventDetailsClicked";

import {
  useFetchContainerRestartsData,
  useGraphDataPoints,
} from "./containerRestartsHooks";
import { getOnlyRestartingContainersMetricsData } from "./containerRestartsUtils";

import {
  GraphDataGroup,
  ReferenceAxis,
  ReferenceData,
  XAxisConfig,
  YAxisConfig,
} from "@/components/Metrics/graphs/graphTypes";
import { colorPalette } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ContainerRestarts/containerRestartsContants";
import { BarChart } from "@/components/Metrics/graphs/BarChart";
import { calculateTimeXAxis } from "@/components/Metrics/graphs/timeGraphUtils";
import { CustomGraphDataPoint } from "@/components/Metrics/types";
import { INVALID_DATAPOINT_VALUE } from "@/components/Metrics/graphs/hooks";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { GetEventIcon } from "@/components/Metrics/graphs/EventsGraph";
import { minimalEventIconsHeight } from "@/components/Metrics/constants";
import { SupportingDataContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/SupportingDataComponents";
import { NUM_OF_TICKS } from "@/components/common/EventsChart/TimelineChart/TimelineChart";

type DetailsProps = {
  komodorUid: string;
  containers: ContainerRestartsSupportingDataContainersInner[];
};

export const Details: React.FC<DetailsProps> = ({ komodorUid, containers }) => {
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);
  const { data, isFetching, error, fetchStatus } =
    useFetchContainerRestartsData(komodorUid);

  useReportDrawerLoadingTime(fetchStatus === "idle");

  const {
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const uid: Uid = {
    cluster,
    namespace,
    name,
  };

  const containersData = useMemo(
    () =>
      getOnlyRestartingContainersMetricsData(
        containers,
        data?.data?.containers
      ),
    [containers, data?.data?.containers]
  );

  const dataPoints = useGraphDataPoints(containersData);

  const events = useGetCorrelatedDeployEvents({
    komodorUid: komodorUid,
    graphDataPoints: dataPoints,
  });

  const shouldClosePopoverContentOnClickCapture =
    useIsViewDeployEventDetailsClicked();

  const dataGroups: GraphDataGroup[] = (containersData ?? []).map(
    (container, idx) => {
      return {
        color: colorPalette[idx % colorPalette.length],
        name: container.containerName,
        values: dataPoints ?? [],
        customDataKey: container.containerName,
      };
    }
  );

  const referenceDataConfig: ReferenceData[] = (events ?? []).map((event) => ({
    axis: ReferenceAxis.x,
    color: event.highlight ? event.color : "transparent",
    value: event.time,
    label: (
      <Label
        position="top"
        offset={50}
        content={(props) => <GetEventIcon {...props} event={event} />}
      />
    ),
    margin: { top: minimalEventIconsHeight },
  }));

  const dataWithoutNegatives = useMemo<CustomGraphDataPoint[]>(
    () =>
      (dataPoints ?? []).map((dataPoint) => {
        const values = Object.entries(dataPoint).map(([key, value]) => [
          key,
          value === INVALID_DATAPOINT_VALUE ? null : value,
        ]);
        return Object.fromEntries(values);
      }),
    [dataPoints]
  );

  const xAxisConfig = useMemo<XAxisConfig>(() => {
    return calculateTimeXAxis(dataWithoutNegatives, {
      numOfTicks: NUM_OF_TICKS,
    });
  }, [dataWithoutNegatives]);
  const yAxisConfig: YAxisConfig = {
    label: "Restarts",
    dataKey: "value",
  };

  const graphContainerContent = (
    <BarChart
      aria-label={AriaLabels.MetricsAvailability.BarChart.Graph}
      data={dataGroups}
      xAxisConfig={{ ...xAxisConfig, range: undefined }}
      yAxisConfig={yAxisConfig}
      graphConfig={{
        shouldUseTimeTooltipHeader: true,
        stackBarsData: { values: dataWithoutNegatives ?? [] },
      }}
      referenceDataConfig={referenceDataConfig}
    />
  );

  const graphContainerProps = {
    uid,
    title: "Container restarts (last 7d)",
    hasGraphData: !error && !isFetching && !!data?.data?.containers?.length,
    error: error ? "Something went wrong" : null,
    loading: isFetching,
  };

  return (
    <SupportingDataContainer>
      <GraphContainerWithStatus
        {...graphContainerProps}
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      >
        {graphContainerContent}
      </GraphContainerWithStatus>
      {isGraphEnlarged && (
        <EnlargedMetricsGraph
          {...graphContainerProps}
          shouldCloseOnClickCapture={shouldClosePopoverContentOnClickCapture}
          onClose={() => setIsGraphEnlarged(false)}
        >
          <GraphContainerWithChildren graphContent={graphContainerContent}>
            <></>
          </GraphContainerWithChildren>
        </EnlargedMetricsGraph>
      )}
    </SupportingDataContainer>
  );
};
