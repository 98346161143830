import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

import ResponsiveLayout from "../../common/ResponsiveLayout";
import { useGetClusterByName } from "../../../shared/hooks/workspaces-api/clusters/useGetClusterByName";
import { PageLoader } from "../../../shared/components/PageLoader";
import { PathNotFound } from "../../404/PathNotFound";

import { ClusterViewHeader } from "./components/ClusterViewHeader";
import { CostSection } from "./components/CostSection/CostSection";
import { InventorySection } from "./components/InventorySection/InventorySection";
import { INACTIVE_CLUSTER_MESSAGE } from "./constants";
import { OverviewContainer } from "./styles";
import { HealthSection } from "./components/HealthSection/HealthSection";
import { AddonsSection } from "./components/AddonsSection/AddonsSection";

import { useCanViewCluster } from "@/components/ClustersView/ClusterOverview/permissionsHooks";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { WorkspaceKind } from "@/generated/workspacesApi";

interface ClusterOverviewProps {
  clusterName: string;
}
const ClusterOverviewComponent: React.FC<ClusterOverviewProps> = ({
  clusterName,
}) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { data: canViewCluster, isLoading: isLoadingCanViewClusters } =
    useCanViewCluster(clusterName);

  const { data: cluster, isLoading } = useGetClusterByName(
    { name: clusterName },
    { enabled: !!clusterName, staleTime: 0 }
  );

  useEffect(() => {
    reportLoadingState("isFetchingCluster", isLoading);
  }, [reportLoadingState, isLoading]);

  if (isLoadingCanViewClusters || !cluster) {
    return <PageLoader />;
  }

  if (!canViewCluster) {
    return <PathNotFound />;
  }

  return (
    <>
      <ClusterViewHeader cluster={cluster} />
      <ResponsiveLayout>
        <OverviewContainer>
          {cluster.inactive && (
            <Alert variant="outlined" severity="info">
              {INACTIVE_CLUSTER_MESSAGE}
            </Alert>
          )}
          <HealthSection />
          <AddonsSection />
          <CostSection clusters={[cluster.clusterName]} />
          <InventorySection
            clusterName={cluster.clusterName}
            isClusterInactive={cluster.inactive}
            pods={cluster.pods}
            nodes={cluster.nodes}
            services={cluster.services}
            jobs={cluster.jobs}
            isPodsEnabled={cluster.additionalInfo.isPodsEnabled}
          />
        </OverviewContainer>
      </ResponsiveLayout>
    </>
  );
};

export const ClusterOverview: React.FC<ClusterOverviewProps> = (props) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.workspaceOverview,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
          workspaceKind: WorkspaceKind.Cluster,
        },
      }}
    >
      <ClusterOverviewComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
