import type { RuleGroupType } from "react-querybuilder";

import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { DEFAULT_MATCHES_WILDCARD_VALUE } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/constants";

export const clusterScopeInitialQuery = {
  id: "root",
  combinator: "and",
  rules: [
    {
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: "matchesWildcard",
          value: DEFAULT_MATCHES_WILDCARD_VALUE,
        },
      ],
    },
  ],
};

export const namespaceScopeInitialQuery = {
  id: "root",
  combinator: "and",
  rules: [
    {
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: "matchesWildcard",
          value: DEFAULT_MATCHES_WILDCARD_VALUE,
          allowRemove: false,
        },
      ],
    },
    {
      combinator: "and",
      rules: [
        {
          field: "namespace",
          operator: "matchesWildcard",
          value: "",
          allowRemove: false,
        },
      ],
    },
  ],
};

export const cwrScopeInitialQuery = {
  id: "root",
  combinator: "and",
  rules: [
    {
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: "matchesWildcard",
          value: DEFAULT_MATCHES_WILDCARD_VALUE,
          allowRemove: false,
        },
      ],
    },
    {
      combinator: "and",
      rules: [
        {
          field: "key",
          operator: "is",
          value: "",
        },
        {
          field: "value",
          operator: "matchesWildcard",
          value: "",
        },
      ],
    },
  ],
};

export const queryByScope: { [key: string]: RuleGroupType | string } = {
  [Scope.cluster]: clusterScopeInitialQuery,
  [Scope.namespace]: namespaceScopeInitialQuery,
  [Scope.cwr]: cwrScopeInitialQuery,
  [Scope["komodor-actions"]]:
    "Komodor actions and permissions are not limited to specific resources",
};
