import React, { useMemo, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SearchField, SimpleDialog } from "@komodorio/design-system/komodor-ui";
import { muiTheme } from "@komodorio/design-system";
import { ChevronRight16 } from "@komodorio/design-system/icons";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";

import {
  HeaderRow,
  RowSpaceFiller,
  StyledAccordion,
} from "@/shared/components/PreviewDefintion/components";
import { Cluster } from "@/shared/components/PreviewDefintion/types";

const ITEM_HEIGHT = 48;
const MAX_VISIBLE_ITEMS = 5;

const StyledSimpleDialog = styled(SimpleDialog)<{ $minHeight: number }>`
  .MuiDialog-paper {
    min-height: ${({ $minHeight }) => `${$minHeight}px`};
  }
`;

interface PreviewDefinitionContentProps {
  clusters: Cluster[];
  onClose: () => void;
}

export const PreviewDefinition: React.FC<PreviewDefinitionContentProps> = ({
  clusters,
  onClose,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [expanded, setExpanded] = useState<string>("");

  const createChangeHandler =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };

  // Calculate total namespaces
  const totalNamespaces = useMemo(
    () =>
      clusters.reduce((total, cluster) => total + cluster.namespaces.length, 0),
    [clusters]
  );

  // Filter clusters and namespaces based on search input
  const filteredClusters = useMemo(
    () =>
      clusters
        .map((cluster) => ({
          ...cluster,
          namespaces: cluster.namespaces.filter((ns) =>
            ns.name.toLowerCase().includes(searchFilter.toLowerCase())
          ),
        }))
        .filter(
          (cluster) =>
            cluster.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
            cluster.namespaces.length > 0
        ),
    [clusters, searchFilter]
  );

  const clustersData = useMemo(() => {
    return (
      <Stack rowGap="8px">
        <Typography variant="body2" color="text.primary">
          {clusters.length} clusters and {totalNamespaces} namespaces in defined
          scope
        </Typography>

        <SearchField
          placeholder="filter"
          size="small"
          value={searchFilter}
          onSearch={(value) => setSearchFilter(value)}
        />
        <Box
          sx={{
            border: `1px solid ${muiTheme.palette.divider}`,
            borderRadius: "4px",
          }}
        >
          <HeaderRow>
            <RowSpaceFiller />
            <Box sx={{ width: "50%" }}>Cluster</Box>
            <Box sx={{ width: "50%" }}>Namespace</Box>
          </HeaderRow>

          <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
            {filteredClusters.map((cluster) => (
              <StyledAccordion
                key={cluster.id}
                expanded={expanded === cluster.name}
                onChange={createChangeHandler(cluster.name)}
                disableGutters
              >
                <AccordionSummary
                  aria-controls={`${cluster.name}-content`}
                  id={`${cluster.name}-header`}
                >
                  <Stack direction="row" sx={{ width: "100%", flex: 1 }}>
                    <Box
                      sx={{
                        height: "18px",
                        padding: "0 16px",
                        transform:
                          expanded === cluster.name
                            ? "rotate(90deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <ChevronRight16 />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2">{cluster.name}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2">
                        {cluster.namespaces.length}
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 0,
                  }}
                >
                  <List
                    sx={{
                      padding: 0,
                      maxHeight: `${ITEM_HEIGHT * MAX_VISIBLE_ITEMS}px`,
                      overflowY: "auto",
                      borderBottom: `1px solid ${muiTheme.palette.divider}`,
                    }}
                  >
                    {cluster.namespaces.map((ns) => (
                      <ListItem
                        key={ns.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "&:hover": { backgroundColor: "action.hover" },
                        }}
                      >
                        <RowSpaceFiller />
                        <Box sx={{ width: "50%" }}></Box>
                        <Box
                          sx={{
                            width: "50%",
                            marginLeft:
                              cluster.namespaces.length > MAX_VISIBLE_ITEMS
                                ? "16px"
                                : 0,
                          }}
                        >
                          <Typography variant="body2">{ns.name}</Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </Box>
        </Box>
      </Stack>
    );
  }, [
    clusters.length,
    totalNamespaces,
    searchFilter,
    filteredClusters,
    expanded,
  ]);

  const content = useMemo(() => {
    if (clusters.length === 0) {
      return (
        <Stack
          direction="row"
          columnGap="8px"
          alignItems="center"
          aria-label={"preview definition empty state"}
        >
          <InfoIcon color="info" sx={{ height: "16px", width: "16px" }} />
          <Typography variant="body2" color="text.primary">
            The defined scope did not match with any existing clusters or
            namespaces
          </Typography>
        </Stack>
      );
    }

    return clustersData;
  }, [clusters.length, clustersData]);

  return (
    <StyledSimpleDialog
      title="Preview definition"
      content={content}
      onClose={onClose}
      width="600px"
      $minHeight={clusters.length === 0 ? 0 : 660}
    />
  );
};
