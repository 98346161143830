/* eslint-disable max-lines */
import { useEffect, useMemo } from "react";

import {
  IngressFragment,
  Kubernetes_Service_ResourceFragment,
  SecretFragment,
} from "../../../generated/graphql";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { relatedResources } from "../../ResourceView/tabs/EventsTab/content/RelatedResourcesSelector";
import {
  KubernetesHPAResource,
  KubernetesPodsResource,
  KubernetesResource,
  KubernetesConfigMapResource,
  KubernetesServiceResource,
  KubernetesIngressResource,
  KubernetesSecretsResource,
} from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import {
  ConfigMapEventsResponseDataInner,
  HpaEventsResponseDataInner,
  PodGroups,
} from "../../../generated/resourcesApi";
import { setFetchingStateSelector } from "../../../shared/store/resourceViewStore/resourceViewSelectors";
import { useResourceViewStore } from "../../../shared/store/resourceViewStore/resourceViewStore";

import {
  isConfig_MapFragments,
  isHpaEventsResponseDataInners,
  isKubernetes_Service_ResourceFragments,
  isIngressFragments,
  isSecretFragments,
} from "./typeGuards";

export const useRelatedResourcesEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const { groupedNativePodEvents, isFetchingGroupedNativePodEvents } =
    useNativePodEvents(
      selectedResourceNamesForEvents,
      clusterName,
      namespace,
      timeWindow,
      resourceViewKind
    );

  const [hpaEvents, isFetchingHpaEvents] = useHpaEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [configMapEvents, isFetchingConfigMapEvents] = useConfigMapsEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [kubernetesServiceEvents, isFetchingK8sServiceEvents] =
    useKubernetesServicesEvents(
      selectedResourceNamesForEvents,
      clusterName,
      namespace,
      timeWindow,
      resourceViewKind
    );

  const [ingressEvents, isFetchingIngressEvents] = useIngressEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [secretEvents, isFetchingSecretEvents] = useSecretsEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const setFetchingState = useResourceViewStore(setFetchingStateSelector);

  const isFetchingRelatedResourcesEvents =
    isFetchingGroupedNativePodEvents ||
    isFetchingHpaEvents ||
    isFetchingConfigMapEvents ||
    isFetchingK8sServiceEvents ||
    isFetchingIngressEvents ||
    isFetchingSecretEvents;

  useEffect(() => {
    setFetchingState({
      key: "isFetchingRelatedResourcesEvents",
      value: isFetchingRelatedResourcesEvents,
    });
  }, [isFetchingRelatedResourcesEvents, setFetchingState]);

  return useMemo(
    () => ({
      groupedNativePodEvents,
      hpaEvents,
      configMapEvents,
      kubernetesServiceEvents,
      ingressEvents,
      secretEvents,
    }),
    [
      groupedNativePodEvents,
      hpaEvents,
      configMapEvents,
      kubernetesServiceEvents,
      ingressEvents,
      secretEvents,
    ]
  );
};

const useResourceEventsFetch = (
  resourceType: KubernetesResource,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined,
  selectedResourceNames: string[] | undefined,
  disableFetch?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [unknown[] | undefined, boolean] => {
  const useFetchRelatedResourceEvents =
    relatedResources.get(resourceType)?.useFetchEvents ??
    (() => [undefined, false]);

  return useFetchRelatedResourceEvents(
    selectedResourceNames,
    clusterName,
    namespace,
    timeWindow,
    disableFetch
  );
};

const useNativePodEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
): {
  groupedNativePodEvents: PodGroups[];
  isFetchingGroupedNativePodEvents: boolean;
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
} => {
  const podsSearchParamsKey = relatedResources
    .get(KubernetesPodsResource)
    ?.searchParamKey(resourceViewKind);

  const selectedPodCustomUids = useMemo(
    () => selectedResourceNamesForEvents?.[podsSearchParamsKey ?? ""] ?? [],
    [podsSearchParamsKey, selectedResourceNamesForEvents]
  );

  const [groupedNativePodEvents, isFetchingGroupedNativePodEvents] =
    useResourceEventsFetch(
      KubernetesPodsResource,
      clusterName,
      namespace,
      timeWindow,
      selectedPodCustomUids
    );

  return useMemo(
    () => ({
      groupedNativePodEvents:
        groupedNativePodEvents && selectedPodCustomUids.length
          ? (groupedNativePodEvents as PodGroups[])
          : [],
      isFetchingGroupedNativePodEvents,
    }),
    [
      groupedNativePodEvents,
      isFetchingGroupedNativePodEvents,
      selectedPodCustomUids.length,
    ]
  );
};

const useHpaEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [HpaEventsResponseDataInner[], boolean] => {
  const { hpaEventsInRelatedResources } = useOverridableFlags();

  const hpaSearchParamsKey = relatedResources
    .get(KubernetesHPAResource)
    ?.searchParamKey(resourceViewKind);

  const selectedHpaNames = useMemo(
    () => selectedResourceNamesForEvents?.[hpaSearchParamsKey ?? ""] ?? [],
    [hpaSearchParamsKey, selectedResourceNamesForEvents]
  );

  const [hpaEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesHPAResource,
    clusterName,
    namespace,
    timeWindow,
    selectedHpaNames
  );

  return useMemo(() => {
    return [
      !hpaEventsInRelatedResources ||
      !hpaEventsFromPg ||
      !isHpaEventsResponseDataInners(hpaEventsFromPg)
        ? []
        : hpaEventsFromPg,
      isFetching,
    ];
  }, [hpaEventsFromPg, hpaEventsInRelatedResources, isFetching]);
};

const useConfigMapsEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [ConfigMapEventsResponseDataInner[], boolean] => {
  const configMapsSearchParamKey = relatedResources
    .get(KubernetesConfigMapResource)
    ?.searchParamKey(resourceViewKind);

  const selectedConfigMapNames = useMemo(
    () =>
      selectedResourceNamesForEvents?.[configMapsSearchParamKey ?? ""] ?? [],
    [configMapsSearchParamKey, selectedResourceNamesForEvents]
  );

  const [configMapEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesConfigMapResource,
    clusterName,
    namespace,
    timeWindow,
    selectedConfigMapNames
  );

  return useMemo(() => {
    return [
      !configMapEventsFromPg || !isConfig_MapFragments(configMapEventsFromPg)
        ? []
        : configMapEventsFromPg,
      isFetching,
    ];
  }, [configMapEventsFromPg, isFetching]);
};

const useKubernetesServicesEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [Kubernetes_Service_ResourceFragment[], boolean] => {
  const kubernetesServiceSearchParamKey = relatedResources
    .get(KubernetesServiceResource)
    ?.searchParamKey(resourceViewKind);

  const selectedKubernetesServiceNames = useMemo(
    () =>
      selectedResourceNamesForEvents?.[kubernetesServiceSearchParamKey ?? ""] ??
      [],
    [kubernetesServiceSearchParamKey, selectedResourceNamesForEvents]
  );

  const [kubernetesServicesEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesServiceResource,
    clusterName,
    namespace,
    timeWindow,
    selectedKubernetesServiceNames
  );

  return useMemo(() => {
    return [
      !kubernetesServicesEventsFromPg ||
      !isKubernetes_Service_ResourceFragments(kubernetesServicesEventsFromPg)
        ? []
        : kubernetesServicesEventsFromPg,
      isFetching,
    ];
  }, [isFetching, kubernetesServicesEventsFromPg]);
};

const useIngressEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [IngressFragment[], boolean] => {
  const ingressSearchParamKey = relatedResources
    .get(KubernetesIngressResource)
    ?.searchParamKey(resourceViewKind);

  const selectedIngressesNames = useMemo(
    () => selectedResourceNamesForEvents?.[ingressSearchParamKey ?? ""] ?? [],
    [ingressSearchParamKey, selectedResourceNamesForEvents]
  );

  const [ingressesEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesIngressResource,
    clusterName,
    namespace,
    timeWindow,
    selectedIngressesNames
  );

  return useMemo(() => {
    return [
      !ingressesEventsFromPg || !isIngressFragments(ingressesEventsFromPg)
        ? []
        : ingressesEventsFromPg,
      isFetching,
    ];
  }, [ingressesEventsFromPg, isFetching]);
};

const useSecretsEvents = (
  selectedResourceUidsForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end">,
  resourceViewKind: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [SecretFragment[], boolean] => {
  const secretUidsSearchParamKey = relatedResources
    .get(KubernetesSecretsResource)
    ?.searchParamKey(resourceViewKind);

  const selectedSecretsNames = useMemo(
    () => selectedResourceUidsForEvents?.[secretUidsSearchParamKey ?? ""] ?? [],
    [secretUidsSearchParamKey, selectedResourceUidsForEvents]
  );

  const [secretEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesSecretsResource,
    clusterName,
    namespace,
    timeWindow,
    selectedSecretsNames
  );

  return useMemo(() => {
    return [
      !secretEventsFromPg || !isSecretFragments(secretEventsFromPg)
        ? []
        : secretEventsFromPg,
      isFetching,
    ];
  }, [isFetching, secretEventsFromPg]);
};
