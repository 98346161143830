import { useEffect, useMemo, useState } from "react";

import { useFilterTagsByAppViews } from "../../../shared/hooks/appViews/appViewFilterHooks";
import { useLiveDataWithUrlParam } from "../../../shared/hooks/ATM/useAtmResourcesListLiveResult";
import useNamespacesAtm, {
  UseNamespaceAtmParams,
} from "../../../shared/hooks/ATM/useNamespacesAtm";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";

import { useInspectionResourceListAtm } from "./useInspectionResourceList";

import { ResourceListResponse } from "@/components/Inspection/InspectionViews/types";

export interface UseInspectionDataArgs {
  kubernetesResource: KubernetesResource;
  cluster: string;
  agentInfo: AgentProperties | null;
  agentId: string;
  shouldStop?: boolean;
  fieldSelector?: string;
}

export interface UseInspectionDataResult {
  namespacesResult: {
    namespaces: string[] | undefined;
    errorMessage: string;
  };
  resourceList: ResourceListResponse;
  refresh: () => void;
  isLiveDataSupported: boolean;
}

export const useFilteredNamespacesFromAtm = (params: UseNamespaceAtmParams) => {
  const {
    result: namespacesData,
    refresh: namespacesRefresh,
    errorMessage,
  } = useNamespacesAtm(params);

  const filterTagsByAppViews = useFilterTagsByAppViews();

  const namespaces = useMemo(() => {
    const names = namespacesData?.data.map((n) => n.name).sort();
    return filterTagsByAppViews("namespace", names);
  }, [filterTagsByAppViews, namespacesData?.data]);

  const namespacesResult = useMemo(() => {
    return {
      namespaces,
      errorMessage: errorMessage,
    };
  }, [errorMessage, namespaces]);

  return {
    namespacesResult,
    refresh: namespacesRefresh,
  };
};

export const useInspectionData = ({
  agentId,
  agentInfo,
  cluster,
  kubernetesResource,
  shouldStop,
  fieldSelector,
}: UseInspectionDataArgs): UseInspectionDataResult => {
  const { namespacesResult, refresh: namespacesRefresh } =
    useFilteredNamespacesFromAtm({ agentId, cluster });

  const { isLiveActive, isLiveDataSupported } =
    useLiveDataWithUrlParam(agentId);

  const { resourceList, refresh: listRefresh } = useInspectionResourceListAtm({
    kubernetesResource,
    agentInfo,
    agentId,
    cluster,
    isLiveData: isLiveActive,
    shouldStop,
    fieldSelector,
  });

  const refresh = useMemo(
    () => (namespacesResult.errorMessage ? namespacesRefresh : listRefresh),
    [listRefresh, namespacesRefresh, namespacesResult.errorMessage]
  );

  const [lastCluster, setLastCluster] = useState(cluster);
  useEffect(() => {
    if (lastCluster !== cluster) {
      setLastCluster(cluster);
      refresh();
    }
  }, [cluster, lastCluster, refresh, setLastCluster]);

  return {
    namespacesResult,
    resourceList,
    refresh,
    isLiveDataSupported,
  };
};
