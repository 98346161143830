import React from "react";

import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity, Operator } from "@/generated/addonsApi";
import { AddonPageContainer } from "@/components/k8sAddons/components/AddonPageContainer";
import {
  DEFAULT_HELM_SORT_BY_FIELD,
  DEFAULT_HELM_SORT_BY_DIRECTION,
  REFETCH_INTERVAL_MS,
} from "@/components/k8sAddons/addons/HelmCharts/helmChartsConstants";
import { HelmChartsFilters } from "@/components/k8sAddons/addons/HelmCharts/HelmChartsFilters";
import { HelmChartsTable } from "@/components/k8sAddons/addons/HelmCharts/HelmChartsTable";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";

export const HelmCharts: React.FC = () => {
  return (
    <ResponsiveLayout>
      <FiltersContextProvider
        urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
        searchTypeFilterOperator={Operator.Like}
      >
        <AddonContextProvider
          addonType={Addon.HelmCharts}
          addonEntity={Entity.HelmChart}
          refetchInterval={REFETCH_INTERVAL_MS}
          defaultSortModel={{
            field: DEFAULT_HELM_SORT_BY_FIELD,
            sort: DEFAULT_HELM_SORT_BY_DIRECTION,
          }}
        >
          <AddonPageContainer>
            <HelmChartsFilters />
            <HelmChartsTable />
          </AddonPageContainer>
        </AddonContextProvider>
      </FiltersContextProvider>
    </ResponsiveLayout>
  );
};
