import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isRuleGroupType, RuleGroupType } from "react-querybuilder";
import { muiTheme } from "@komodorio/design-system";

import { valueField } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/fields";
import { useResolveScope } from "@/shared/hooks/auth-service/client/rbacPolicies/useResolveScope";
import { queryToResourcesScope } from "@/pages/organization-settings/access-management/PoliciesPage/utils/queryToResourcesScope";
import { prepareResourcesScope } from "@/pages/organization-settings/access-management/PoliciesPage/utils/prepareResourcesScope";
import {
  assignErrorsToQuery,
  doesQueryHaveErrors,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";
import { ConnectedClustersQB } from "@/shared/components/QueryBuilder/ConnectedClustersQB";
import { ConnectedClustersNamespacesQB } from "@/shared/components/QueryBuilder/ConnectedClustersNamespacesQB";
import { ConnectedClustersKeysQB } from "@/shared/components/QueryBuilder/ConnectedClustersKeysQB";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import {
  MAX_KEY_RULES,
  newKeyValueFilterRuleStructure,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/constants";
import { getKeyValueRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getKeyValueRulesFromQuery";
import { usePreviewDefinition } from "@/shared/components/PreviewDefintion/usePreviewDefinition";
import { PreviewDefinitionContainer } from "@/shared/components/PreviewDefintion/PreviewDefinitionContainer";
import { getClusterRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getClusterRulesFromQuery";
import { getNamespaceRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getNamespaceRulesFromQuery";

const QUERY_ERRORS = {
  queryContainsErrors: "Query contains errors",
  conversionError: "Error converting query",
  resolveError: "There was an error resolving the scope",
};

const DEFAULT_FIELDS = [valueField];
const SCOPES_WITH_PREVIEW_DEFINITION = [Scope.cluster, Scope.namespace];

export type QueryBuilderPanelProps = {
  ruleBuilderQuery: RuleGroupType | string | null;
  setRuleBuilderQuery: (query: RuleGroupType) => void;
};

export const QueryBuilderPanel = ({
  ruleBuilderQuery,
  setRuleBuilderQuery,
}: QueryBuilderPanelProps) => {
  const [queryError, setQueryError] = useState<string>("");
  const { scope } = useStatementDrawerContext();

  const { mutateAsync: resolveScope, isLoading } = useResolveScope();

  const onResolveScope = useCallback(async () => {
    if (!isRuleGroupType(ruleBuilderQuery)) {
      return { data: [] };
    }
    const resourceScope = queryToResourcesScope(ruleBuilderQuery);

    return await resolveScope({
      scopes: [prepareResourcesScope(resourceScope, scope)],
      resolveEverythingPatterns: true,
    });
  }, [ruleBuilderQuery, resolveScope, scope]);

  const {
    showPreviewDefinition,
    closePreviewDefinition,
    handlePreviewClick,
    clusters,
    resolveError,
  } = usePreviewDefinition({
    query: ruleBuilderQuery as RuleGroupType,
    resolveScope: onResolveScope,
    initialQueryError: queryError,
  });

  useEffect(() => {
    if (!isRuleGroupType(ruleBuilderQuery)) {
      return setQueryError("");
    }

    try {
      const queryWithErrors = assignErrorsToQuery(ruleBuilderQuery);
      if (doesQueryHaveErrors(queryWithErrors)) {
        return setQueryError(QUERY_ERRORS.queryContainsErrors);
      }
    } catch (e) {
      return setQueryError(QUERY_ERRORS.conversionError);
    }

    return setQueryError("");
  }, [ruleBuilderQuery]);

  const clustersOptions = useMemo(() => {
    const clusterRules = getClusterRulesFromQuery(ruleBuilderQuery);
    return clusterRules?.clusters ?? [];
  }, [ruleBuilderQuery]);

  const namespacesOptions = useMemo(() => {
    const namespacesRules = getNamespaceRulesFromQuery(ruleBuilderQuery);
    return namespacesRules?.namespaces ?? [];
  }, [ruleBuilderQuery]);

  const QueryBuilderComponent = useMemo(() => {
    if (scope === "cluster") {
      return ConnectedClustersQB;
    }

    if (scope === "namespace") {
      return ConnectedClustersNamespacesQB;
    }

    return ConnectedClustersKeysQB;
  }, [scope]);

  const canAddRule = useMemo(() => {
    if (scope !== Scope.cwr) {
      return false;
    }

    const result = getKeyValueRulesFromQuery(ruleBuilderQuery);
    return (result?.selectorsPatterns?.length ?? 0) < MAX_KEY_RULES;
  }, [scope, ruleBuilderQuery]);

  if (!ruleBuilderQuery) {
    return null;
  }

  if (typeof ruleBuilderQuery === "string") {
    return (
      <Stack rowGap="4px">
        <Typography variant="h4">Where</Typography>
        <Typography variant="body2" color="text.secondary">
          {ruleBuilderQuery}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack rowGap="4px">
      <Typography variant="h4">Where</Typography>
      <Box
        sx={{
          border: `1px solid ${muiTheme.palette.divider}`,
          borderRadius: "4px",
          padding: "8px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <QueryBuilderComponent
            query={ruleBuilderQuery}
            onQueryChange={setRuleBuilderQuery}
            fields={DEFAULT_FIELDS}
            isChangeable={false}
            addRuleGroupLabel={"Add filter rule"}
            showAddRuleGroup={scope === Scope.cwr}
            disabledAddRuleGroup={!canAddRule}
            newRuleGroupStructure={newKeyValueFilterRuleStructure}
            clustersInitialOptions={clustersOptions}
            namespaceInitialOptions={namespacesOptions}
          />
          {SCOPES_WITH_PREVIEW_DEFINITION.includes(scope) && (
            <PreviewDefinitionContainer
              showPreviewDefinition={showPreviewDefinition}
              onClose={closePreviewDefinition}
              clusters={clusters}
              isLoadingResolveScope={isLoading}
              queryError={resolveError || queryError}
              handlePreviewClick={handlePreviewClick}
              ariaLabels={{
                button: createPolicyAriaLabels.statementForm.previewDefinition,
              }}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
