import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 4px 8px 4px 4px;
  border: 1px solid ${muiColors.blue[300]};
  border-radius: 4px;
  align-items: center;
  background-color: white;
`;
export const InfoSection: React.FC<{
  ariaLabel?: string;
  content: React.ReactNode;
  icon: React.ReactNode;
}> = ({ ariaLabel, content, icon }) => {
  return (
    <Container aria-label={ariaLabel}>
      <Box
        sx={{
          padding: "8px",
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "4px",
          padding: "4px",
        }}
      >
        {content}
      </Box>
    </Container>
  );
};
