import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import pluralize from "pluralize";

import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";
import { useDisableLiveAtmData } from "@/components/common/ResourceListTable/batchActions/hooks/useDisableLiveAtmData";
import { useCancelSelectionOnFilterChange } from "@/components/common/ResourceListTable/batchActions/hooks/useCancelSelectionOnFilterChange";
import { batchActionsAriaLabels } from "@/components/common/ResourceListTable/batchActions/batchActionsAriaLabels";
import { useGetAvailableActions } from "@/components/common/ResourceListTable/batchActions/hooks/useGetAvailableActions";
import { BatchActionButton } from "@/components/common/ResourceListTable/batchActions/components/BatchActionButton";

const Container = styled.div``;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 30px;
`;
const ActionButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 12px;
`;

const {
  clearSelectionButton: clearSelectionAriaLabels,
  selectionOverview: selectionOverviewAriaLabel,
  mainContainer: mainContainerAriaLabel,
} = batchActionsAriaLabels;

type ResourceTableBatchActionsHeaderProps = {
  resourceName: string;
  currentPage: number;
  pageSize: number;
};

export const ResourceTableBatchActionsHeader: React.FC<
  ResourceTableBatchActionsHeaderProps
> = ({ resourceName, currentPage, pageSize }) => {
  const { selectedTableRows, clearSelectedTableRows } =
    useInspectionBatchActionsContext();

  useDisableLiveAtmData();
  useCancelSelectionOnFilterChange(currentPage, pageSize);

  const resource =
    selectedTableRows.length === 1
      ? pluralize.singular(resourceName.toLowerCase())
      : resourceName.toLowerCase();

  const title = `${selectedTableRows.length} ${resource} selected`;

  const availableActions = useGetAvailableActions();
  const actionButtons = useMemo(() => {
    return availableActions?.map((action) => (
      <BatchActionButton key={action} action={action} />
    ));
  }, [availableActions]);

  return (
    <Container aria-label={mainContainerAriaLabel}>
      <FlexContainer>
        <Typography
          variant="body3"
          color={"text.secondary"}
          aria-label={selectionOverviewAriaLabel}
        >
          {title}
        </Typography>
        <Divider variant={"middle"} orientation={"vertical"} />
        <Button
          variant={"text"}
          onClick={() => clearSelectedTableRows()}
          aria-label={clearSelectionAriaLabels}
        >
          Clear selection
        </Button>
        <ActionButtonsContainer>{actionButtons}</ActionButtonsContainer>
      </FlexContainer>
      <br />
    </Container>
  );
};
