import { ActionMetadataBase, TaskType } from "komodor-types";
import { useCallback, useState } from "react";
import { GraphQLErrorExtensions } from "graphql/error/GraphQLError";
import { OperationResult } from "urql";

import {
  AgentTaskOutput,
  ExecuteAgentTaskMutationVariables,
  Maybe,
  useExecuteAgentTaskMutation,
} from "../../../generated/graphql";
import { findActiveAgent } from "../useAgents";
import { useAgentsContext } from "../agents/context/useAgentsContext";

export enum ActionTaskErrorType {
  AuthorizationDenied = "AuthorizationDenied",
  BadRequest = "BadRequest",
}

interface RequestDenied {
  type: ActionTaskErrorType;
}

export interface AuthorizationDenied extends RequestDenied {
  action: string;
  resource: {
    cluster: string;
    namespace?: string;
  };
  message: string;
}

export interface BadRequestError extends RequestDenied {
  action: string;
  message: string;
}

type AgentTaskResult = OperationResult<
  { __typename?: "mutation_root" } & {
    executeAgentTask?: Maybe<
      { __typename?: "AgentTaskOutput" } & Pick<
        AgentTaskOutput,
        | "success"
        | "result"
        | "failureMessage"
        | "taskId"
        | "timeToStartInMs"
        | "timeToCompleteInMs"
        | "timeToResultInMs"
      >
    >;
  },
  ExecuteAgentTaskMutationVariables
>;
export type AgentTaskDeniedError =
  | AuthorizationDenied
  | BadRequestError
  | undefined;

export interface UseAgentTaskResult {
  execute: () => Promise<void>;
  failureMessage: string;
  deniedObject: AuthorizationDenied | BadRequestError | undefined;
  isFetching: boolean;
  result: unknown;
  resetAgentTask: () => void;
}

const useAgentTask = (
  agentId: string,
  type: TaskType,
  metadata = {}
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): UseAgentTaskResult => {
  const [failureMessage, setFailureMessage] = useState("");
  const [deniedObject, setDeniedObject] =
    useState<AgentTaskDeniedError>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [result, setResult] = useState<unknown>("");
  const [, executeTask] = useExecuteAgentTaskMutation();

  const execute = useCallback(async () => {
    if (!agentId) return;
    setIsFetching(true);
    const res = await executeTask({
      agentId,
      type,
      metadata,
    });
    handleAgentTaskResponse(res, setResult, setFailureMessage, setDeniedObject);
    setIsFetching(false);
  }, [executeTask, agentId, type, metadata]);

  const resetAgentTask = useCallback(() => {
    setResult(undefined);
    setIsFetching(false);
    setDeniedObject(undefined);
    setFailureMessage("");
  }, []);

  return {
    execute,
    failureMessage,
    deniedObject,
    isFetching,
    result,
    resetAgentTask,
  };
};

const handleAgentTaskResponse = (
  res: AgentTaskResult,
  setResultCb: (value: unknown) => void,
  setFailureCb: (value: string) => void,
  setDeniedCb: (value: AgentTaskDeniedError) => void
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  let extensions: Maybe<GraphQLErrorExtensions>[] = [];
  const graphQLErrors = res.error?.graphQLErrors;
  if (graphQLErrors && graphQLErrors.length) {
    extensions = graphQLErrors.map(({ message, extensions }) => extensions);
  }

  if (extensions[0]?.code === 401) {
    setDeniedCb({
      type: ActionTaskErrorType.AuthorizationDenied,
      ...extensions[0]?.reason,
    });
  }

  if (extensions[0]?.code === 400) {
    setDeniedCb({
      type: ActionTaskErrorType.BadRequest,
      ...extensions[0]?.reason,
    });
  }
  setFailureCb(res.data?.executeAgentTask?.failureMessage ?? "");
  setResultCb(res.data?.executeAgentTask?.result ?? "");
};

export const useMultiClusterAgentTask = (): {
  execute: (
    type: TaskType,
    metadata: ActionMetadataBase,
    clusters: string[]
  ) => Promise<void>;
  failureMessages: string[];
  deniedObjects: AgentTaskDeniedError[];
  isFetching: boolean;
  results: unknown[];
} => {
  const { agents } = useAgentsContext();
  const [isFetching, setIsFetching] = useState(false);
  const [, executeTask] = useExecuteAgentTaskMutation();
  const [results, setResults] = useState<unknown[]>([]);
  const [failures, setFailures] = useState<string[]>([]);
  const [deniedRequests, setDeniedRequests] = useState<AgentTaskDeniedError[]>(
    []
  );

  const execute = useCallback(
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
    async (type: TaskType, metadata = {}, clusters: string[]) => {
      if (!clusters) {
        return;
      }
      setIsFetching(true);
      for (const c of clusters) {
        const agentId = findActiveAgent(agents, c) ?? "";
        if (!agentId) continue;
        const res = await executeTask({
          agentId,
          type,
          metadata,
        });
        handleAgentTaskResponse(
          res,
          (value) => {
            setResults([value].concat(results));
          },
          (value) => {
            setFailures([value].concat(failures));
          },
          (value) => {
            setDeniedRequests([value].concat(deniedRequests));
          }
        );
      }
      setIsFetching(false);
    },
    [agents, deniedRequests, executeTask, failures, results]
  );
  return {
    execute,
    failureMessages: failures,
    deniedObjects: deniedRequests,
    isFetching,
    results,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useAgentTask;
