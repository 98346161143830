import React, { useMemo } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Eye16, Pencil16 } from "@komodorio/design-system/icons";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { ariaLabels } from "../ariaLabels";

import { RbacPolicy } from "@/generated/auth";
import { isPolicyEditable } from "@/pages/organization-settings/access-management/PoliciesPage/utils";
import { DELETE_ICON_STYLE } from "@/components/Settings/constants";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/PoliciesPage/types";

export interface ActionsCellProps {
  params: GridRenderCellParams;
  onEdit: OnEdit;
  onDelete: OnDelete;
}

const cellAriaLabels = ariaLabels.table.actionsCell;

export const ActionsCell = ({ params, onEdit, onDelete }: ActionsCellProps) => {
  const canEdit = isPolicyEditable(params.row as RbacPolicy);

  const actions = useMemo(() => {
    if (!canEdit) {
      return [
        <LightTooltip title="View policy" key="view">
          {/*  EditModal has isReadOnly prop that represent "view" mode  */}
          <IconButton
            onClick={() => onEdit(params.row as RbacPolicy)}
            aria-label={cellAriaLabels.view}
          >
            <Eye16 />
          </IconButton>
        </LightTooltip>,
      ];
    }

    return [
      <LightTooltip title="Edit policy" key="edit">
        <IconButton
          onClick={() => onEdit(params.row as RbacPolicy)}
          aria-label={cellAriaLabels.edit}
        >
          <Pencil16 />
        </IconButton>
      </LightTooltip>,
      <LightTooltip title="Delete policy" key="delete">
        <IconButton
          onClick={() => onDelete(params.row as RbacPolicy)}
          aria-label={cellAriaLabels.delete}
        >
          <DeleteOutlineIcon sx={DELETE_ICON_STYLE} fontSize="small" />
        </IconButton>
      </LightTooltip>,
    ];
  }, [canEdit, onDelete, onEdit, params.row]);

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="end"
      justifyContent="center"
      aria-label={ariaLabels.table.actionsCell.container}
    >
      {params.row.hovered && <Stack direction="row">{actions}</Stack>}
    </Stack>
  );
};
