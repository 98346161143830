import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import * as colors from "../../../../themes/colors";

export interface SimpleDialogProps {
  title: string;
  content: React.ReactNode;
  closeText?: string;
  closeDisabled?: boolean;
  onClose: () => void;
  width?: string;
  height?: string;
  className?: string;
  type?: "primary" | "error" | "warning" | "success";
}

export function SimpleDialog({
  title,
  content,
  closeText,
  closeDisabled = false,
  onClose,
  width = "unset",
  height = "unset",
  className,
  type = "primary",
}: SimpleDialogProps) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width,
          height,
        },
      }}
      maxWidth={false}
      open={true}
      onClose={onClose}
      className={className}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ flexGrow: 1 }} variant="h3">
          {title}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            color: colors.gray[500],
            width: "20px",
            height: "20px",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Button
          onClick={onClose}
          variant="contained"
          color={type}
          disabled={closeDisabled}
        >
          {closeText || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
