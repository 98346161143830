import { useMemo } from "react";

import { useAuthorization } from "../roles/useAuthorization";
import { useAppViewsStore } from "../../store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  selectedAppViewIdSelector,
} from "../../store/appViewsStore/appViewStoreSelectors";

import { APP_VIEW_ID_HEADER, WORKSPACE_ID_HEADER } from "./constants";

import { useIsScopedWorkspace } from "@/components/workspaces/WorkspacesTopBar/hooks";

export type RequestHeadersParams = {
  skipAppViewInHeaders?: boolean;
  skipWorkspaceHeader?: boolean;
  accept?: string;
};

export const useRequestHeaders = (params?: RequestHeadersParams) => {
  const { skipAppViewInHeaders, skipWorkspaceHeader, accept } = params ?? {};
  const authorization = useAuthorization() ?? undefined;
  const appViewId = useAppViewsStore(selectedAppViewIdSelector);
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const isScopedWorkspace = useIsScopedWorkspace(currentAppView);

  return useMemo(() => {
    let wsHeaders = {};
    if (isScopedWorkspace && !skipWorkspaceHeader) {
      wsHeaders = { [WORKSPACE_ID_HEADER]: currentAppView.id };
    }

    if (!isScopedWorkspace && !skipAppViewInHeaders && appViewId) {
      wsHeaders = { [APP_VIEW_ID_HEADER]: appViewId };
    }

    return {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...wsHeaders,
      ...(accept && { Accept: accept }),
    };
  }, [
    isScopedWorkspace,
    skipWorkspaceHeader,
    skipAppViewInHeaders,
    appViewId,
    authorization,
    accept,
    currentAppView.id,
  ]);
};
