import { useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

export const useOptions = ({
  initialOptions,
  storeOptions,
}: {
  initialOptions?: string[];
  storeOptions?: MuiSelectionOption<string>[];
}) => {
  const [options, setOptions] = useState<MuiSelectionOption<string>[]>(
    initialOptions?.map((option) => ({ value: option, label: option })) ?? []
  );

  const onCreateNewOptions = (newOptions: MuiSelectionOption<string>[]) => {
    setOptions((prevOptions) => [...prevOptions, ...newOptions]);
  };

  const optionsToUse = useMemo(
    () =>
      [...(storeOptions ?? []), ...options].sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    [storeOptions, options]
  );

  return { onCreateNewOptions, optionsToUse };
};
