import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from "@komodorio/design-system/komodor-ui";

export const UnsavedChangesDialog = ({
  onClose,
  entityName,
}: {
  onClose: ConfirmationDialogProps["onClose"];
  entityName: string;
}) => (
  <ConfirmationDialog
    type="error"
    title="Discard changes?"
    content={`Are you sure you want to discard changes to ${entityName}?`}
    okText="Discard"
    onClose={onClose}
  />
);
