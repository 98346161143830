import { ResourceTableModelRow } from "komodor-types/build/entities/AgentTask";
import { clone } from "lodash";
import { useMemo } from "react";

import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";
import {
  filterResourceTableByColumn,
  filterResourceTableByLabels,
  filterResourceTableBySearchTerm,
} from "../utils/filterResultsList";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { ResourceListResponse } from "@/components/Inspection/InspectionViews/types";
import { useInspectionFilters } from "@/components/Inspection/filters/useInspectionFilters";

interface FilteringArgs {
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  filterNamespaces?: string[] | null;
  filterStatus?: string[] | null;
  filterGroup?: string[] | null;
  filterLabels?: string[] | null;
  filterLabelsExactMatch?: boolean | null;
  filterSearchTerm?: string | null;
  filterIps?: string[] | null;
  showIpFilter?: boolean | null;
}

export const filterInspectionResourcesBySearchParams = ({
  resourceList,
  kubernetesResource,
  filterNamespaces,
  filterStatus,
  filterGroup,
  filterLabels,
  filterLabelsExactMatch,
  filterIps,
  showIpFilter,
  filterSearchTerm,
}: FilteringArgs): ResourceTableModelRow[] => {
  let output = clone(resourceList.rows);
  if (kubernetesResource.Namespaced && filterNamespaces?.length) {
    output = filterResourceTableByColumn(output, filterNamespaces, "namespace");
  }
  if (kubernetesResource.ShowStatusFilter && filterStatus?.length) {
    output = filterResourceTableByColumn(output, filterStatus, "status");
  }

  if (kubernetesResource.showGroupFilter && filterGroup?.length) {
    output = filterResourceTableByColumn(output, filterGroup, "group");
  }

  if (filterLabels?.length) {
    output = filterResourceTableByLabels(
      output,
      filterLabels,
      filterLabelsExactMatch ?? false
    );
  }

  if (filterSearchTerm) {
    output = filterResourceTableBySearchTerm(output, filterSearchTerm);
  }

  if (
    kubernetesResource.NameInK8S === "pods" &&
    showIpFilter === true &&
    filterIps?.length
  ) {
    output = filterResourceTableByColumn(output, filterIps, "ip");
  }

  return output;
};

type InspectionFiltersParams = {
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  resourceKeyInLS?: string;
  clusterName: string;
};

export const useFilterListByInspectionFilters = ({
  resourceList,
  kubernetesResource,
  resourceKeyInLS,
  clusterName,
}: InspectionFiltersParams): ResourceTableModelRow[] => {
  const { showIpFilter: showIpFilterFF } = useOverridableFlags();
  const showIpFilter = validateFFtype(showIpFilterFF);

  const {
    filterGroup,
    filterLabels,
    filterLabelsExactMatch,
    filterNamespaces,
    filterSearchTerm,
    filterStatus,
    filterIps,
  } = useInspectionFilters(clusterName, resourceKeyInLS);

  return useMemo(
    () =>
      filterInspectionResourcesBySearchParams({
        filterGroup,
        filterLabels,
        filterLabelsExactMatch,
        filterNamespaces,
        filterSearchTerm,
        filterStatus,
        kubernetesResource,
        resourceList,
        filterIps,
        showIpFilter,
      }),
    [
      filterGroup,
      filterLabels,
      filterLabelsExactMatch,
      filterNamespaces,
      filterSearchTerm,
      filterStatus,
      kubernetesResource,
      resourceList,
      filterIps,
      showIpFilter,
    ]
  );
};

const validateFFtype = (ffValue: unknown): boolean => ffValue === true;
