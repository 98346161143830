import { useQuery } from "@tanstack/react-query";

import { useWorkspaceQueryKey } from "@/shared/hooks/workspaces-api/useWorkspaceQueryKey";
import {
  ActionsApiGetBulkActionsResultsRequest,
  GetBulkActionsResultsResp,
  getBulkActionsResultsUrl,
} from "@/generated/actionsApi";
import { useActionsApiClient } from "@/shared/hooks/actions-api/apiClient";
import { QueryOptions } from "@/shared/constants/reactQueryOptions";

export const ACTIONS_API_GET_BATCH_ACTIONS_RESULTS_PATH =
  "/actions/api/v1/bulk/:id";

export const useGetBatchActionsResults = (
  params: ActionsApiGetBulkActionsResultsRequest,
  queryOptions?: QueryOptions
) => {
  const apiClient = useActionsApiClient();

  const keys = useWorkspaceQueryKey([
    ACTIONS_API_GET_BATCH_ACTIONS_RESULTS_PATH,
    params,
  ]);
  return useQuery(
    keys,
    async () =>
      await apiClient.get<GetBulkActionsResultsResp>(
        getBulkActionsResultsUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    { ...queryOptions }
  );
};
