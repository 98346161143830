import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { useEffect, useMemo } from "react";

import { useSharedProcessListProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useSharedProcessListProps";
import { Title } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import { ScaleDownImpactSupportingData } from "@/generated/reliabilityApi";
import { ProcessList } from "@/components/common/ProcessList";
import { useNodeEventsByIds } from "@/components/common/EventGroup/nodeEvent/useNodeChangeEvents";
import { groupNodeChangeEvents } from "@/components/common/EventGroup/nodeEvent/NodeChangeGroup";
import { scaleDownImpactDrawerAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ScaleDownImpactConstants";
import {
  ProcessListColumnTypes,
  ProcessListSortKeys,
} from "@/components/common/ProcessList/processListConstants";
import EventGroup from "@/components/common/EventGroup";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type ScaleDownImpactRelatedEventsTableProps = {
  komodorUid: string;
  fromEpoch: number;
  toEpoch: number;
  correlatedIssues: ScaleDownImpactSupportingData["terminationEvents"];
  onLoad?: () => void;
};

const EventsContainer = styled.div`
  background-color: ${muiColors.gray["50"]};
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 16px;
  margin-top: 8px;
`;

export const AVAILABILITY_ISSUE_COUNT_COLUMN = "availabilityIssuesCount";
export const TERMINATED_PODS_COUNT_COLUMN = "terminatedPodsCount";

const eventGroupFirstEventIdGetter = (eventGroup: EventGroup) => {
  return eventGroup.events[0].id;
};

export const ScaleDownImpactRelatedEventsTable: React.FC<
  ScaleDownImpactRelatedEventsTableProps
> = ({ komodorUid, fromEpoch, toEpoch, correlatedIssues, onLoad }) => {
  const {
    eventId,
    setEventId,
    setIntentId,
    timeWindow,
    setTimeWindow,
    serviceIds,
  } = useSharedProcessListProps({
    komodorUids: [komodorUid],
    fromEpoch,
    toEpoch,
  });
  const correlatedNodeEventsData = useMemo(
    () => ({
      eventIds: correlatedIssues?.map((issue) => issue.eventId) ?? [],
      extraData: Object.fromEntries(
        correlatedIssues?.map((issue) => [
          issue.eventId,
          {
            [AVAILABILITY_ISSUE_COUNT_COLUMN]: issue.availabilityIssuesCount,
            [TERMINATED_PODS_COUNT_COLUMN]: issue.terminatedPodsCount,
          },
        ]) ?? []
      ),
    }),
    [correlatedIssues]
  );

  const [nodeEvents, isFetching] = useNodeEventsByIds(
    correlatedNodeEventsData.eventIds
  );

  const eventsGroups = groupNodeChangeEvents(nodeEvents, serviceIds[0]);
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(() => {
    reportLoadingState("scaleDownImpactRelatedEventsTable", isFetching);
  }, [isFetching, reportLoadingState]);

  if (isFetching) {
    return <LinesLoader />;
  }

  return (
    <>
      <Title
        aria-label={scaleDownImpactDrawerAriaLabels.relatedEventsTable.title}
      >
        Related Events({correlatedIssues?.length ?? 0})
      </Title>
      {nodeEvents.length > 0 && (
        <EventsContainer>
          <ProcessList
            eventId={eventId}
            setEventId={setEventId}
            setIntentId={setIntentId}
            highlightedEvents={[]}
            showServiceColumn={false}
            eventGroups={eventsGroups}
            showMoreEventsPanel={true}
            defaultSortKey={ProcessListSortKeys.endtime}
            timeWindow={timeWindow}
            setTimeWindow={(tw) => {
              setTimeWindow({ ...timeWindow, ...tw });
            }}
            columns={[
              ProcessListColumnTypes.Type,
              ProcessListColumnTypes.Summary,
              ProcessListColumnTypes.StartDate,
              ProcessListColumnTypes.EndDate,
              {
                type: ProcessListColumnTypes.Custom,
                customDataKey: AVAILABILITY_ISSUE_COUNT_COLUMN,
                columnHeader: "Availability Issues",
                fallbackValue: "--",
                getEventGroupIdForCustomData: eventGroupFirstEventIdGetter,
              },
              {
                type: ProcessListColumnTypes.Custom,
                customDataKey: TERMINATED_PODS_COUNT_COLUMN,
                columnHeader: "Terminated Pods",
                fallbackValue: "--",
                getEventGroupIdForCustomData: eventGroupFirstEventIdGetter,
              },
            ]}
            eventsExtraDataMap={correlatedNodeEventsData.extraData}
          />
        </EventsContainer>
      )}
    </>
  );
};
