import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { useMemo } from "react";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import Typography from "@mui/material/Typography";

import { BarChart } from "@/components/Metrics/graphs/BarChart";
import {
  GraphDataGroup,
  XAxisConfig,
  XAxisDataType,
  YAxisConfig,
} from "@/components/Metrics/graphs/graphTypes";
import { UnschedulablePodsDistribution } from "@/generated/reliabilityApi";
import { TextTick } from "@/components/Metrics/graphs/ticks/TextTick";
import { unscheduledPodsAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/UnschedulablePods/unscheduledPodsAriaLabels";

const TITLE_TOOLTIP_CONTENT =
  "Pods unable to be scheduled due to insufficient nodes, tracked until scheduled or deleted";

const GraphCard = styled(Card)`
  && {
    padding: 8px 16px 22px;
  }
`;

const GraphContainer = styled.div`
  margin-top: 8px;
  height: 15rem;
`;

const HelpIcon = styled(HelpOutlineOutlined)`
  margin-left: 6px;
  vertical-align: middle;
`;

const numberOfPodsColor = muiColors.indigo[500];
const percentageColor = muiColors.deepPurple[300];

type UnschedulablePodsTimeDistributionProps = {
  distribution: UnschedulablePodsDistribution;
};

export const UnschedulablePodsTimeDistribution: React.FC<
  UnschedulablePodsTimeDistributionProps
> = ({ distribution }) => {
  const data: GraphDataGroup[] = useMemo(
    () => [
      {
        color: numberOfPodsColor,
        name: "Number of pods",
        values: distribution.timeBuckets.map((point) => ({
          x: point.bucketRange,
          y: point.podCount,
        })),
      },
      {
        color: percentageColor,
        name: "Percentage",
        values: distribution.timeBuckets.map((point) => ({
          x: point.bucketRange,
          y: point.podPercentage,
        })),
        useSecondaryYAxis: true,
      },
    ],
    [distribution.timeBuckets]
  );

  const xAxisConfig: XAxisConfig = {
    dataKey: "x",
    dataType: XAxisDataType.category,
    tickComponent: <TextTick />,
  };
  const yAxisConfig: YAxisConfig = {
    dataKey: "y",
    dynamicWidth: true,
    color: numberOfPodsColor,
  };

  const secondaryYAxisConfig: YAxisConfig = {
    dataKey: "y",
    color: percentageColor,
  };

  return (
    <GraphCard
      variant="outlined"
      aria-label={unscheduledPodsAriaLabels.timeDistributionGraph.container}
    >
      <Typography variant="h5">
        Unschedulable pods time distribution
        <Tooltip title={TITLE_TOOLTIP_CONTENT} placement="top">
          <HelpIcon fontSize="small" color="action" />
        </Tooltip>
      </Typography>
      <GraphContainer>
        <BarChart
          aria-label={unscheduledPodsAriaLabels.timeDistributionGraph.graph}
          data={data}
          xAxisConfig={xAxisConfig}
          yAxisConfig={yAxisConfig}
          secondaryYAxisConfig={secondaryYAxisConfig}
        />
      </GraphContainer>
    </GraphCard>
  );
};
