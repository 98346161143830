import React, { useCallback, useMemo } from "react";
import { TooltipWrapper } from "react-tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {
  Props as DefaultLegendProps,
  Payload as DefaultLegendPayload,
} from "recharts/types/component/DefaultLegendContent";
import Box from "@mui/material/Box";

import { muiColors } from "../../../../themes";
import { LightTooltip } from "../../LightTooltip/LightTooltip";

import { ColorIndication } from "./ColorIndication";
import { Path } from "./Path";

export interface LegendProps {
  hide: { [key: string]: boolean };
  setHide: (value: string) => void;
  props: DefaultLegendProps;
  setCurrentValueHover: (value: string | null) => void;
  direction?: "row" | "column";
  reverse?: boolean;
  showTooltip?: boolean;
}

export const ChartLegend: React.FC<LegendProps> = ({
  hide,
  setHide,
  props,
  setCurrentValueHover,
  direction = "row",
  reverse = false,
  showTooltip = true,
}) => {
  const { payload } = props;

  const ItemContent = useCallback(
    ({
      legendItem,
      isHidden,
    }: {
      legendItem: DefaultLegendPayload;
      isHidden: boolean;
    }) => {
      return (
        <Stack
          direction="row"
          alignItems="center"
          columnGap="6px"
          padding="2px"
          marginLeft="40px"
          key={legendItem.value}
          onClick={() => setHide(legendItem.value)}
        >
          <ColorIndication $clear={isHidden} color={legendItem.color} />
          <Typography variant="caption" color={`${muiColors.gray[700]}`}>
            <Path value={[legendItem.value]} isLimitedPath />
          </Typography>
        </Stack>
      );
    },
    [setHide]
  );

  const content = useMemo(() => {
    if (!payload) {
      return null;
    }

    const payloadInOrder = reverse ? [...payload].reverse() : payload;

    return (
      <>
        {payloadInOrder.map((legendItem) => {
          const { value } = legendItem;
          const isHidden = hide[value];

          const child = (
            <ItemContent legendItem={legendItem} isHidden={isHidden} />
          );

          if (!showTooltip) {
            return child;
          }

          return (
            <LightTooltip
              id={value}
              key={value}
              title={<Path value={[value]} isHighlighted={false} />}
            >
              <Box
                onMouseEnter={() =>
                  isHidden ? null : setCurrentValueHover(value)
                }
                onMouseLeave={() => setCurrentValueHover(null)}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <TooltipWrapper tooltipId={value}>{child}</TooltipWrapper>
              </Box>
            </LightTooltip>
          );
        })}
      </>
    );
  }, [ItemContent, hide, payload, reverse, setCurrentValueHover, showTooltip]);

  return (
    <Stack
      spacing="4px"
      padding="4px"
      direction={direction}
      justifyContent="center"
    >
      {content}
    </Stack>
  );
};
