import React from "react";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import {
  useHelmChartsLoadingColumns,
  useHelmChartsTableColumns,
} from "@/components/k8sAddons/addons/HelmCharts/hooks/helmChartsTableHooks";
import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { SEARCH_TABLE_PLACEHOLDER } from "@/components/k8sAddons/addons/HelmCharts/helmChartsConstants";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  LiveListTableTopSection,
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import { helmChartsAriaLabels } from "@/components/k8sAddons/addons/HelmCharts/helmChartsAriaLabels";
import { useOpenHelmReleaseDrawer } from "@/components/k8sAddons/addons/HelmCharts/hooks/useOpenHelmReleaseDrawer";
import { DataFreshnessAlert } from "@/components/k8sAddons/components/DataFreshnessAlert";

const TableTopSectionContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;
export const HelmChartsTable: React.FC = () => {
  const { searchFilter, searchFieldComponent } = useSearchFieldAsGenericFilter({
    searchFieldProps: { placeholder: SEARCH_TABLE_PLACEHOLDER },
  });

  const response = useFetchLiveStateList({
    ...(searchFilter && { additionalRequestFilters: [searchFilter] }),
  });

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useHelmChartsLoadingColumns(),
      dataStateColumns: useHelmChartsTableColumns(),
      tableTitle: "Helm charts",
    });

  const ariaLabel = isFetchingData
    ? `${helmChartsAriaLabels.table} loading`
    : `${helmChartsAriaLabels.table} content`;
  return (
    <PageTableContainer>
      <TableTopSectionContainer>
        <LiveListTableTopSection>
          <Typography variant="h4">{title}</Typography>
          {searchFieldComponent}
        </LiveListTableTopSection>
        <DataFreshnessAlert text="Chart data may be delayed by approximately 15 minutes." />
      </TableTopSectionContainer>

      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...useCommonLiveStateTableProps(isFetchingData)}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        onRowClick={useOpenHelmReleaseDrawer()}
      />
    </PageTableContainer>
  );
};
