import React, { useMemo } from "react";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";
import { Reload16 } from "@komodorio/design-system/icons";

import { InsightContainer } from "../Insight";
import { useOverridableFlags } from "../../../../../../../../shared/context/featureFlags/OverridableFlags";
import WorkflowIssueEventGroup from "../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";

import { SuspiciousLogsInsight } from "./insights/SuspiciousLogsInsight";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ImpactedServicesInsight } from "./insights/ImpactedServicesInsight";
import { BoldText, LightningBoltDemoInsight } from "./common";

import { WorkflowRunWithResults } from "@/components/monitorsView/common/types";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useShowDemoInsights = (shortResourceName: string): boolean => {
  const { demoAccountInsights } = useOverridableFlags();
  return (
    (demoAccountInsights as boolean) &&
    shortResourceName === "events-puller-service"
  );
};

const Container = styled(InsightContainer)`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

export interface DemoImpactedServicesData {
  started: string;
  namespace: string;
  service: string;
  reason: string;
  status: string;
}

export const DemoInsights: React.FC<{
  namespace: string | undefined;
  wfRun: WorkflowRunWithResults;
  eventGroup: WorkflowIssueEventGroup;
}> = ({ namespace = "demo2", wfRun, eventGroup }) => {
  const demoImpactedServicesData: DemoImpactedServicesData[] = useMemo(
    () => [
      {
        started: "9 minutes",
        namespace,
        service: "node-connect",
        reason: "OOMKilled - Exit code: 137",
        status: "UNHEALTHY",
      },
      {
        started: "12 minutes",
        namespace,
        service: "transaction-api",
        reason: "OOMKilled - Exit code: 137",
        status: "UNHEALTHY",
      },
      {
        started: "35 minutes",
        namespace,
        service: "datasync-worker",
        reason: "OOMKilled - Exit code: 137",
        status: "UNHEALTHY",
      },
    ],
    [namespace]
  );

  return (
    <>
      <Container>
        <LightningBoltDemoInsight>
          There was a <BoldText>deploy</BoldText> 20 seconds prior to this issue
          <Button icon={Reload16} variant="secondary">
            Rollback
          </Button>
        </LightningBoltDemoInsight>
        <SuspiciousLogsInsight wfRun={wfRun} eventGroup={eventGroup} />
        <ImpactedServicesInsight
          demoImpactedServicesData={demoImpactedServicesData}
        />
      </Container>
    </>
  );
};
