import { Addon, AutoScalerEnum } from "@/generated/addonsApi";
import { Dictionary } from "@/shared/types/Dictionary";

export const isCurrentlyDeploying = (
  lastDeployStartTime: string | undefined | null,
  lastDeployEndTime: string | undefined | null
): boolean => {
  return (
    !!lastDeployStartTime &&
    (!lastDeployEndTime || lastDeployStartTime > lastDeployEndTime)
  );
};

export const isExternalDNS = (labels: Dictionary<string>): boolean => {
  return Object.entries(labels).some((entry) => {
    const label = entry.join(":");
    return [
      "app.kubernetes.io/name:external-dns",
      "komodor.io/detect:external-dns",
    ].includes(label);
  });
};

const CLUSTER_AUTOSCALER_LABELS = [
  ["app.kubernetes.io/instance", "cluster-autoscaler"],
  ["app.kubernetes.io/name", "aws-cluster-autoscaler"],
  ["app", "cluster-autoscaler"],
  ["app", "autoscaler"],
];

export const getAutoscalerType = (
  labels: Record<string, string>
): AutoScalerEnum | void => {
  if (labels["app.kubernetes.io/name"] === "karpenter") {
    return AutoScalerEnum.Karpenter;
  }
  if (
    CLUSTER_AUTOSCALER_LABELS.some(
      ([labelKey, labelValue]) => labels[labelKey] === labelValue
    )
  ) {
    return AutoScalerEnum.ClusterAutoScaler;
  }
};

export const isAutoscaler = (labels: Record<string, string>): boolean => {
  return !!getAutoscalerType(labels);
};

export function getAddonTypeFromLabels(
  labels: Dictionary<string>
): Addon | "none" {
  if (isExternalDNS(labels)) {
    return Addon.ExternalDns;
  }
  if (isAutoscaler(labels)) {
    return Addon.AutoScalers;
  }
  return "none";
}
