import React, { useCallback, useMemo } from "react";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import { TaskType } from "komodor-types";
import Typography from "@mui/material/Typography";

import useAgentTask from "@/shared/hooks/useAgentTask/useAgentTask";
import { Agent } from "@/generated/agents";

export const AgentRestartDialog: React.FC<{
  selectedAgent: Agent;
  onClose: () => void;
}> = ({ selectedAgent, onClose }) => {
  const [executed, setExecuted] = React.useState(false);
  const { execute, result, deniedObject, isFetching } = useAgentTask(
    selectedAgent.id || "",
    TaskType.RESTART_AGENT
  );

  const handleCloseDialog = useCallback(
    (confirmed: boolean) => {
      if (confirmed) {
        execute();
        setExecuted(true);

        setTimeout(() => {
          if (!deniedObject) {
            onClose();
          }
        }, 1000);
      } else {
        onClose();
      }
    },
    [execute, onClose, deniedObject]
  );

  const content = useMemo(() => {
    if (deniedObject) {
      return <Typography variant="body2">Failed to restart agent</Typography>;
    }

    if (result) {
      return (
        <Typography variant="body2">Agent restarted successfully</Typography>
      );
    }

    if (isFetching || executed) {
      return <Typography variant="body2">Restarting agent...</Typography>;
    }

    return (
      <Typography variant="body2">
        Are you sure you want to restart agent: {selectedAgent.clusterName} (
        {selectedAgent.id})
      </Typography>
    );
  }, [selectedAgent, isFetching, deniedObject, result, executed]);

  return (
    <ConfirmationDialog
      title="Restart Agent"
      onClose={handleCloseDialog}
      okText="Restart"
      content={content}
    />
  );
};
