import { muiColors } from "@komodorio/design-system";

import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import {
  GetSummaryDataOutput,
  MetadataItemContent,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerTypes";
import { ResourceNotFound } from "@/components/ResourceView/ResourceNotFound";
import { useGetPolicyLink } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetPolicyLink";
import { DrawerViolationSection } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/OverviewContentComponents";
import {
  howToFixDescription,
  scaleDownImpactDrawerAriaLabels,
  whyIsThisImportantContent,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ScaleDownImpactConstants";
import { ImpactedWorkloadsSection } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ImpactedWorkloadsSection";
import { nodeAutoscalersTypeMapper } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/nodeAutoscalersTypeMapper";
import { AutoScalerEnum } from "@/generated/addonsApi";
import { useOpenAutoscalerDrawer } from "@/components/k8sAddons/addons/NodeAutoscalers/useOpenAutoscalerDrawer";
import { SupportingDataContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/SupportingDataComponents";
import { ScaleDownImpactRelatedEventsTable } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ScaleDownImpactRelatedEventsTable";
import { useGetEpochsFromViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetEpochsFromViolation";
import { TerminationImpactGraphSection } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/TerminationImpactGraphSection";
import { isInEnum } from "@/shared/utils/isInEnum";
import { AutoscalerKind } from "@/generated/reliabilityApi";

export const ScaleDownImpactDrawer: React.FC = () => {
  const { data } = useViolation();
  const violation = data?.data.violation;
  const { supportingData, headerProps, komodorUid } =
    useGetCommonDrawerProps(violation);
  const policyLink = useGetPolicyLink({
    policyName: violation?.policyName,
    policyId: violation?.policyId,
  });
  const openAutoscalerDrawer = useOpenAutoscalerDrawer();
  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();

  if (!komodorUid) {
    return <ResourceNotFound />;
  }
  const { cluster = "", name = "" } = parseKomodorUid(komodorUid) ?? {};
  const autoScalerType = supportingData?.scaleDownImpact?.autoscalerKind;
  const { title: autoscalerTypeTitle, icon: AutoscalerTypeIcon = undefined } =
    !!autoScalerType && isInEnum(AutoScalerEnum, autoScalerType)
      ? nodeAutoscalersTypeMapper[autoScalerType]
      : {
          title: autoScalerType,
        };
  const metadataItems: MetadataItemContent[] = [
    { title: "cluster", value: cluster },
    {
      title: "autoscaler",
      value: name,
      onValueClick: () => openAutoscalerDrawer({ cluster, name }),
    },
    {
      title: "Autoscaler type",
      value: autoscalerTypeTitle ?? "Unknown",
      leftIcon: AutoscalerTypeIcon
        ? () => <AutoscalerTypeIcon size={20} color={muiColors.gray[600]} />
        : undefined,
    },
  ];
  if (policyLink) {
    metadataItems.push(policyLink);
  }

  const violationSummaryData: GetSummaryDataOutput = {
    komodorUid,
    metadataItems,
  };

  if (!supportingData?.scaleDownImpact) {
    return null;
  }

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: violationSummaryData }}
      content={
        <>
          <SupportingDataContainer>
            <TerminationImpactGraphSection
              scaleDownImpactViolationData={supportingData.scaleDownImpact}
              clusterName={cluster}
            />
          </SupportingDataContainer>
          <SupportingDataContainer>
            <ImpactedWorkloadsSection
              affectedJobs={supportingData.scaleDownImpact.affectedJobs}
              affectedWorkflows={
                supportingData.scaleDownImpact.affectedWorkflows
              }
              unhealthyServices={
                supportingData.scaleDownImpact.affectedServices
              }
            />
          </SupportingDataContainer>
          <SupportingDataContainer
            aria-label={
              scaleDownImpactDrawerAriaLabels.relatedEventsTable.container
            }
          >
            <ScaleDownImpactRelatedEventsTable
              komodorUid={supportingData.scaleDownImpact.autoscalerKomodorUid}
              fromEpoch={fromEpoch}
              toEpoch={toEpoch}
              correlatedIssues={
                supportingData.scaleDownImpact.terminationEvents
              }
            />
          </SupportingDataContainer>
          <DrawerViolationSection
            type="why"
            content={whyIsThisImportantContent}
          />
          <DrawerViolationSection
            type="how"
            content={howToFixDescription(
              autoScalerType ?? AutoscalerKind.ClusterAutoScaler
            )}
          />
        </>
      }
    />
  );
};
