import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import { ResourceTableModelRow } from "komodor-types";

import { KubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export type InspectionBatchActionsContextState =
  InspectionBatchActionsContextProviderProps & {
    selectedTableRows: ResourceTableModelRow[];
    setSelectedTableRows: (selectedTableRow: ResourceTableModelRow) => void;
    clearSelectedTableRows: () => void;
  };

const initialState: InspectionBatchActionsContextState = {
  selectedTableRows: [],
  setSelectedTableRows: () => undefined,
  clearSelectedTableRows: () => undefined,
  clusterName: "",
  resourceType: {} as KubernetesResource,
  refreshResultsCb: undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const InspectionBatchActionsContext =
  createContext<InspectionBatchActionsContextState>(initialState);

type InspectionBatchActionsContextProviderProps = {
  clusterName: string;
  resourceType: KubernetesResource;
  refreshResultsCb: (() => void) | undefined;
};

export const InspectionBatchActionsContextProvider: React.FC<
  PropsWithChildren<InspectionBatchActionsContextProviderProps>
> = ({ children, clusterName, resourceType, refreshResultsCb }) => {
  const [selectedTableRows, setSelectedTableRows] = useState<
    ResourceTableModelRow[]
  >(initialState.selectedTableRows);

  const onRowSelected = useCallback((row: ResourceTableModelRow) => {
    setSelectedTableRows((prev) => {
      const rowsCopy = [...prev];
      const foundRowIndex = rowsCopy.findIndex((item) => {
        if (item.uid && row.uid) {
          return item.uid === row.uid;
        }
        return item.id === row.id;
      });
      if (foundRowIndex > -1) {
        rowsCopy.splice(foundRowIndex, 1);
      } else {
        rowsCopy.push(row);
      }
      return rowsCopy;
    });
  }, []);

  const clearSelectedTableRows = useCallback(() => {
    setSelectedTableRows([]);
  }, []);

  const contextState: InspectionBatchActionsContextState = {
    selectedTableRows,
    setSelectedTableRows: onRowSelected,
    clearSelectedTableRows,
    clusterName,
    resourceType,
    refreshResultsCb,
  };

  return (
    <InspectionBatchActionsContext.Provider value={contextState}>
      {children}
    </InspectionBatchActionsContext.Provider>
  );
};
