import { ApplicationScopingErrorMessage } from "../appView/sections/AppView/components/ApplicationsScopingErrorPage/ApplicationScopingErrorMessage";
import ResponsiveLayout from "../common/ResponsiveLayout";

import { useNavigateToWorkspace } from "./WorkspacesTopBar/hooks";

export const WorkspaceErrorNotAvailable: React.FC = () => {
  const navigateToWorkspace = useNavigateToWorkspace();
  return (
    <ResponsiveLayout>
      <ApplicationScopingErrorMessage
        cta={
          <>
            You are currently working on a deleted workspace. Please switch to a
            different workspace
          </>
        }
        buttonText="Switch to all clusters"
        onButtonClick={() => {
          navigateToWorkspace(undefined);
        }}
      />
    </ResponsiveLayout>
  );
};
