import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { startCase } from "lodash";

import { TrackedKey } from "@/generated/accounts";
import { ActionsCell } from "@/pages/organization-settings/configurations/TrackedKeysPage/Grid/ActionsCell";

export const getColumnsDefinitions = ({
  onDelete,
}: {
  onDelete: (key: TrackedKey) => void;
}) =>
  [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      valueGetter: (params: GridValueGetterParams) => startCase(params.value),
      flex: 1,
    },
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      headerAlign: "right",
      align: "right",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <ActionsCell params={params} onDelete={onDelete} />;
      },
    },
  ] as GridColDef<TrackedKey>[];
