import React from "react";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { muiTheme } from "@komodorio/design-system";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

type DataFreshnessAlertProps = {
  text: string;
};

export const DataFreshnessAlert: React.FC<DataFreshnessAlertProps> = ({
  text,
}) => {
  return (
    <Container>
      <InfoOutlined
        sx={{
          fontSize: "14px",
          color: muiTheme.palette.text.secondary,
        }}
      />
      <Typography variant={"body3"} color={"text.secondary"}>
        {text}
      </Typography>
    </Container>
  );
};
