import { DeploysApiApiV1DeploysSearchGetRequest } from "@/generated/monitorsApi";

export const latestDeploysInsightsFields: DeploysApiApiV1DeploysSearchGetRequest["fields"] =
  [
    "id",
    "eventTime",
    "endEventTime",
    "updatedAt",
    "status",
    "versionTo",
    "versionFrom",
    "failed_container",
    "gitCompare",
    "strategy",
    "namespace",
    "clusterName",
    "generation",
    "deploymentDiffKeys",
    "services",
    "deploymentName",
    "isEmptyDeployment",
    "statusMessage",
    "statusReason",
    "changeType",
    "trackedFiles",
  ];
