/* eslint-disable max-lines */
import { Typography } from "@komodorio/design-system/deprecated";
import { compact, isEqual } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import {
  useActiveAgent,
  useAgentPropertiesById,
} from "../../../../shared/hooks/useAgents";
import { DateTimeSelector } from "../../../common/DateTimeSelector";
import EventGroup from "../../../common/EventGroup";
import groupEvents from "../../../common/EventGroup/groupEvents";
import { EventsLineChart } from "../../../common/EventsChart/LineChart";
import {
  EventsWindow,
  ServiceViewEventsPagination,
  splitToTimeWindow,
} from "../../../common/EventsPagination/EventsPagination";
import { ProcessList } from "../../../common/ProcessList";
import { SetTimeWindow, Timeframe } from "../../../../shared/types/TimeWindow";
import { useOnReload } from "../../../eventsView/EventsView";
import EventViewLoadingType, {
  MINIMUM_NUMBER_OF_REQUESTS_TO_SHOW_CIRCLE_LOADING_SERVICE as circleFromNumber,
} from "../../../eventsView/EventViewLoadingType";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import Resource from "../../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Job from "../../resources/job";
import {
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
} from "../../../../shared/config/urlSearchParamsKeys";
import { JobsEventsPagination } from "../../../common/EventsPagination/JobsEventsPagination";
import useLimitedEvents from "../../../Freemium/useLimitedEvents";
import { useIsTimewindowInAccountLimit } from "../../../Freemium/useIsTimewindowInAccountLimit";
import { useIsElementInViewport } from "../../../../shared/hooks/useIntersectionObserver";

import EmptyState from "./content/emptyState";
import EventFilters from "./content/filters/EventFilters";
import useFilteredEvents from "./content/filters/useFilteredEvents";
import {
  relatedResources,
  RelatedResourcesSelector,
} from "./content/RelatedResourcesSelector";
import StatefulNewEventsAlert from "./content/StatefulNewEventsAlert";
import useChartCallbacks from "./content/useChartCallbacks";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useRelatedResourcesSelectorRenderConfirmation } from "./content/RelatedResourcesSelector/useRelatedResourcesSelectorRenderConfirmation";

import { EventsTimelineChart } from "@/components/common/EventsChart/TimelineChart/EventsTimelineChart";
import { useStateInMultipleSearchParams } from "@/shared/hooks/state/useStateInMultipleSearchParams";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useTimeWindowFromUrl } from "@/components/ResourceView/tabs/EventsTab/hooks/eventsTabHooks";
import { getTimeWindowLabel } from "@/shared/constants/datadog";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";
import { ProcessListSortKeys } from "@/components/common/ProcessList/processListConstants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: 1rem;
  align-items: center;
`;

const ChartContainer = styled.div`
  position: relative;
`;

const TIMELINE_MAX_EVENTS = 50;

const EventsTabContent: React.FC<{ resource: Resource }> = ({ resource }) => {
  const flags = useOverridableFlags();
  const { eventsTabPaginationHoursChunk } = flags;
  const eventsTimelineMaxNumberOfEvents =
    (flags.eventsTimelineMaxNumberOfEvents as number) || TIMELINE_MAX_EVENTS;

  const agentId = useActiveAgent(resource.cluster);
  const { agentInfo } = useAgentPropertiesById(agentId ?? "");
  const [highlightedEvents, setHighlightedEvents] = useState([] as string[]);
  const [eventId, setEventId] = useDrawerUrlState<string>(
    EVENTS_TAB_PREFIX + EVENT_PARAM_KEY
  );

  const divRef = useRef<HTMLDivElement>(null);
  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();
  const isDisplayed = useIsElementInViewport({ ref: divRef });

  const [intentId, setIntentId] = useState<string | null>();
  const [timeWindow, setTimeWindow] = useTimeWindowFromUrl(resource);

  useOnReload(() =>
    setTimeWindowForEventView({ timeframe: timeWindow.timeframe })
  );
  const timeWindows = useMemo(() => {
    return splitToTimeWindow(
      timeWindow,
      eventsTabPaginationHoursChunk as number
    );
  }, [timeWindow, eventsTabPaginationHoursChunk]);

  const [eventsWindowMap, setEventWindowMap] = useState<
    Record<string, EventsWindow>
  >({});

  const onEventsWindowMapUpdate = useCallback(
    (page: EventsWindow, index: number) => {
      if (!isEqual(eventsWindowMap[index], page)) {
        setEventWindowMap({
          ...eventsWindowMap,
          [index]: page,
        });
      }
    },
    [eventsWindowMap]
  );

  const [allEventGroups, setAllEventGroups] = useState<
    EventGroup[] | undefined
  >(undefined);

  const numTimeWindowsFetched = useMemo(() => {
    return Object.keys(eventsWindowMap).length;
  }, [eventsWindowMap]);

  const isFetchingData = useMemo(() => {
    return numTimeWindowsFetched < timeWindows.length;
  }, [numTimeWindowsFetched, timeWindows.length]);
  const serviceIds = useMemo(() => [resource.id], [resource.id]);

  const isJobOfCronJob = resource instanceof Job && resource.controlledBy;

  const shouldRenderRelatedResourcesSelector =
    useRelatedResourcesSelectorRenderConfirmation(resource, agentInfo);

  useEffect(() => {
    if (numTimeWindowsFetched !== timeWindows.length) return;
    const eventGroups = groupEvents(
      Object.values(eventsWindowMap),
      resource.id,
      flags
    );
    const newEvents = isJobOfCronJob
      ? eventGroups
      : eventGroups.filter((e) => serviceIds.includes(e.serviceId));
    // use JSON.stringify() compare instead of isEqual() to try and fix a production issue
    // in which isEqual() returns false for equal arrays
    if (
      allEventGroups?.length === newEvents.length &&
      JSON.stringify(allEventGroups) === JSON.stringify(newEvents)
    ) {
      return;
    }
    setAllEventGroups(newEvents);
  }, [
    setAllEventGroups,
    eventsWindowMap,
    flags,
    numTimeWindowsFetched,
    resource.id,
    timeWindows.length,
    isJobOfCronJob,
    serviceIds,
    allEventGroups,
  ]);

  const selectedPodNamesSearchParamKey = relatedResources
    ?.get(KubernetesPodsResource)
    ?.searchParamKey(resource.kind);

  const relatedResourcesSearchParamsKeys = Array.from(
    relatedResources.values()
  ).map(({ searchParamKey }) => searchParamKey(resource.kind));

  const {
    searchParams: selectedResourcesInUrl,
    setSearchParams: setSelectedResourcesInUrl,
  } = useStateInMultipleSearchParams(relatedResourcesSearchParamsKeys);

  const removeSwimlane = useCallback(
    (label: string) => {
      setSelectedResourcesInUrl({
        ...selectedResourcesInUrl,
        ...(selectedPodNamesSearchParamKey
          ? {
              [selectedPodNamesSearchParamKey]: selectedResourcesInUrl[
                selectedPodNamesSearchParamKey
              ].filter((podUid) => !podUid.includes(label)),
            }
          : {}),
      });
    },
    [
      selectedPodNamesSearchParamKey,
      selectedResourcesInUrl,
      setSelectedResourcesInUrl,
    ]
  );

  const { eventGroups, reset, categories } = useFilteredEvents(allEventGroups);
  const limitedEvents = useLimitedEvents(eventGroups);
  const isLimitEvents = useIsTimewindowInAccountLimit(timeWindow);

  const { onChartMouseEnter, onChartMouseLeave, onChartClick } =
    useChartCallbacks(setHighlightedEvents, setEventId);

  const EventsChart =
    limitedEvents && limitedEvents.length > eventsTimelineMaxNumberOfEvents
      ? EventsLineChart
      : EventsTimelineChart;

  const setTimeWindowForEventView = useCallback<SetTimeWindow>(
    (updated) => {
      setTimeWindow(updated);
      setEventWindowMap({});
    },
    [setTimeWindow]
  );

  const isFetching = isFetchingData || isLimitEvents === undefined;

  setMainContainerDivRef(divRef);
  useEffect(() => {
    reportLoadingState("eventsData", isFetching);
  }, [isFetching, reportLoadingState]);

  const shouldRenderContent = isDisplayed || isFetchingData;

  const paginationContent = useMemo(
    () =>
      timeWindows.map((tw, index) =>
        isJobOfCronJob ? (
          <JobsEventsPagination
            key={index}
            index={index}
            timeWindow={tw}
            jobUid={resource.id}
            setEventWindowMap={onEventsWindowMapUpdate}
          />
        ) : (
          <ServiceViewEventsPagination
            timeWindow={tw}
            index={index}
            serviceId={resource.id}
            selectedResourceNamesForEvents={selectedResourcesInUrl}
            clusterName={resource.cluster}
            namespace={resource.namespace}
            setEventWindowMap={onEventsWindowMapUpdate}
            key={index}
          />
        )
      ),
    [
      timeWindows,
      isJobOfCronJob,
      resource.id,
      resource.cluster,
      resource.namespace,
      onEventsWindowMapUpdate,
      selectedResourcesInUrl,
    ]
  );

  return (
    <Container ref={divRef}>
      {paginationContent}
      <Header>
        <Typography variant="title" size="large">
          Events {limitedEvents ? `(${limitedEvents.length})` : null}
        </Typography>
        <EventFilters categories={categories} onReset={reset} />
        <DateTimeSelector
          timeWindow={timeWindow}
          setTimeWindow={setTimeWindowForEventView}
          showLabel
        />
        {shouldRenderRelatedResourcesSelector && (
          <RelatedResourcesSelector
            selectedResourceUids={selectedResourcesInUrl}
            setSelectedResourceUids={setSelectedResourcesInUrl}
            timeWindow={timeWindow}
            onApplySelection={() => setTimeWindowForEventView(timeWindow)}
            resource={resource}
          />
        )}
      </Header>
      <>
        {(isFetching || limitedEvents === undefined) && (
          <EventViewLoadingType
            timeWindowsLength={timeWindows.length}
            numTimeWindowsFetched={numTimeWindowsFetched}
            showCircleFromNumber={circleFromNumber}
          />
        )}
        {!isFetching &&
          limitedEvents &&
          !limitedEvents.length &&
          isLimitEvents === false && (
            <EmptyState
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindowForEventView}
            />
          )}
        {!isFetching && (limitedEvents?.length || isLimitEvents === true) ? (
          <>
            <ChartContainer>
              <EventsChart
                onMouseEnter={onChartMouseEnter}
                onMouseLeave={onChartMouseLeave}
                onClick={onChartClick}
                eventGroups={limitedEvents ?? []}
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindowForEventView}
                highlightedId={intentId ?? undefined}
                removableSwimlaneLabels={compact(
                  selectedResourcesInUrl[
                    selectedPodNamesSearchParamKey ?? ""
                  ]?.map((resourceUid: string) => resourceUid.split(";").at(-1))
                )}
                removeSwimlane={removeSwimlane}
              />
              {shouldRenderContent &&
                timeWindow.timeframe !== Timeframe.Custom && (
                  <StatefulNewEventsAlert
                    serviceId={resource.id}
                    timeWindow={timeWindow}
                    setTimeWindow={setTimeWindowForEventView}
                    clusterName={resource.cluster}
                  />
                )}
            </ChartContainer>
            {shouldRenderContent && (
              <ProcessList
                highlightedEvents={highlightedEvents}
                showServiceColumn={serviceIds.length > 1}
                eventGroups={limitedEvents ?? []}
                showMoreEventsPanel={true}
                eventId={eventId}
                setEventId={setEventId}
                setIntentId={setIntentId}
                defaultSortKey={
                  resource.kind === "CronJob"
                    ? ProcessListSortKeys.starttime
                    : ProcessListSortKeys.endtime
                }
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
              />
            )}
          </>
        ) : null}
      </>
    </Container>
  );
};

const EventsTab: React.FC<{ resource: Resource }> = ({ resource }) => {
  const [timeWindow] = useTimeWindowFromUrl(resource);
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Events, {
      filters: {
        timeWindow: getTimeWindowLabel(timeWindow.timeframe),
      },
    });

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <EventsTabContent resource={resource} />
    </DatadogReportLoadingTimeContextProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EventsTab;
