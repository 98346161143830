import { sortBy, uniqBy } from "lodash";
import { useMemo } from "react";

import { useAgentsContext } from "./agents/context/useAgentsContext";

import { agentResponse } from "@/shared/hooks/agents-api/utils";
import { Agent } from "@/generated/agents";
import {
  AgentProperties,
  parseAgentToAgentProperties,
} from "@/shared/hooks/useAgentInfo/useAgentInfo";

export const findActiveAgent = (
  agents: agentResponse | undefined,
  clusterName: string | undefined
): string | undefined => {
  if (!clusterName) {
    return undefined;
  }
  return findActiveAgentByClusterName(agents, clusterName)?.id;
};

export const findActiveAgentByClusterName = (
  agents: agentResponse | undefined,
  clusterName: string
): Agent | undefined => {
  const agentWithTheClusterName = agents?.activeAgents.filter(
    (a: Agent) => a.clusterName === clusterName
  );
  return agentWithTheClusterName?.sort(
    (a: Agent, b: Agent) =>
      new Date(b.lastSeenAlive).getTime() - new Date(a.lastSeenAlive).getTime()
  )?.[0];
};

const useFindActiveAgent = (
  agents: agentResponse | undefined,
  clusterName: string | undefined
): string | undefined => {
  return useMemo(() => {
    return findActiveAgent(agents, clusterName);
  }, [agents, clusterName]);
};

// This returns the AGENT ID!
export const useActiveAgent = (
  clusterName: string | undefined
): string | undefined => {
  const { agents } = useAgentsContext();
  return useFindActiveAgent(agents, clusterName);
};

export const useAgentById = (agentId: string): Agent | undefined => {
  const { agents } = useAgentsContext();
  return useMemo(() => {
    return (
      // Either active or inactive -- to keep the same logic as previous 'ById' from GQL
      agents?.activeAgents.find((a) => a.id === agentId) ||
      agents?.inactiveAgents.find((a) => a.id === agentId)
    );
  }, [agents, agentId]);
};

export const useAgentPropertiesById = (
  agentId: string
): { agentInfo: AgentProperties; isFetching: boolean } => {
  const { agents, isFetching } = useAgentsContext();
  return useMemo(() => {
    const agent =
      agents?.activeAgents.find((a) => a.id === agentId) ||
      agents?.inactiveAgents.find((a) => a.id === agentId);
    return {
      agentInfo: parseAgentToAgentProperties(agent),
      isFetching: !!isFetching,
    };
  }, [agentId, agents?.activeAgents, agents?.inactiveAgents, isFetching]);
};

export const useActiveAgentsByClusters = (
  clustersNames: string[]
): (Agent | undefined)[] => {
  const { agents } = useAgentsContext();
  return useMemo(
    () =>
      clustersNames.map((clusterName) =>
        findActiveAgentByClusterName(agents, clusterName)
      ),
    [agents, clustersNames]
  );
};

export const useActiveAgentInfo = (
  clusterName: string | undefined
): Agent | undefined => {
  const { agents } = useAgentsContext();
  return useMemo(() => {
    if (!clusterName) return undefined;
    return findActiveAgentByClusterName(agents, clusterName);
  }, [agents, clusterName]);
};

export const useActiveAgents = (): agentResponse["activeAgents"] => {
  const { agents } = useAgentsContext();
  return useMemo(() => {
    if (!agents) return [];
    return sortBy(uniqBy(agents.activeAgents, "clusterName"), ["clusterName"]);
  }, [agents]);
};

export const useLatestActiveAgent = (): Agent | undefined => {
  const { agents } = useAgentsContext();
  return useMemo(() => {
    if (!agents) return undefined;
    return agents.activeAgents.sort(
      (a: Agent, b: Agent) =>
        new Date(b.lastSeenAlive).getTime() -
        new Date(a.lastSeenAlive).getTime()
    )?.[0];
  }, [agents]);
};
