import { useCallback } from "react";

import { useInspectionPreferencesInLocalStorage } from "@/components/Inspection/InspectionViews/localStorageHooks";
import { useInspectionStore } from "@/shared/store/inspection/inspectionStore";
import {
  selectedNamespacesPerClusterSelector,
  setNamespacesPerClusterSelector,
} from "@/shared/store/inspection/inspectionSelectors";
import { InspectionViewPreferences } from "@/components/Inspection/InspectionViews/localStorage";

type UseNamespacesFilterOutput = [string[], (namespaces: string[]) => void];

type NamespacesFilterParams = {
  clusterName: string | undefined;
  resourceKeyInLS?: string;
};
/**
 * @param resourceKeyInLS if the param is not provided, the namespaces will be managed in the store
 * @param clusterName the cluster name to filter the namespaces
 * @returns namespaces getter and setter
 */
export const useNamespacesFilter = ({
  clusterName,
  resourceKeyInLS,
}: NamespacesFilterParams): UseNamespacesFilterOutput => {
  const [inspectionViewPreferencesInLs, setInspectionViewPreferencesInLs] =
    useInspectionPreferencesInLocalStorage();

  const selectedNamespacesPerCluster = useInspectionStore(
    selectedNamespacesPerClusterSelector
  );
  const setNamespacesPerCluster = useInspectionStore(
    setNamespacesPerClusterSelector
  );

  const setNamespacesInLs = useCallback(
    (namespaces: string[]) => {
      if (!resourceKeyInLS || !clusterName) return;
      const newPreferences: InspectionViewPreferences = {
        ...inspectionViewPreferencesInLs,
        selectedNamespacesPerCluster: {
          ...inspectionViewPreferencesInLs.selectedNamespacesPerCluster,
          [resourceKeyInLS]: {
            ...inspectionViewPreferencesInLs.selectedNamespacesPerCluster?.[
              resourceKeyInLS
            ],
            [clusterName]: namespaces,
          },
        },
      };
      setInspectionViewPreferencesInLs(JSON.stringify(newPreferences));
    },
    [
      clusterName,
      inspectionViewPreferencesInLs,
      resourceKeyInLS,
      setInspectionViewPreferencesInLs,
    ]
  );

  const setNamespaceInStore = useCallback(
    (namespaces: string[]) => {
      setNamespacesPerCluster(clusterName ?? "", namespaces);
    },
    [clusterName, setNamespacesPerCluster]
  );

  const namespacesInStore =
    selectedNamespacesPerCluster[clusterName ?? ""] ?? [];

  if (!resourceKeyInLS) {
    return [namespacesInStore, setNamespaceInStore];
  }

  const namespacesPerCluster =
    inspectionViewPreferencesInLs.selectedNamespacesPerCluster?.[
      resourceKeyInLS
    ]?.[clusterName ?? ""] ?? namespacesInStore; // fallback to store if not in LS

  return [namespacesPerCluster, setNamespacesInLs];
};
