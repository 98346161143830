import React from "react";

import { KubernetesCRResource } from "../../inspectionConfiguration/supportedResourcesTypes/KubernetesCRResource";

import { CRDsPageLoading } from "./CRDsPageLoading";
import { CRDsErrorOutdatedAgent } from "./CRDsErrorOutdatedAgent";
import { CRDsPageContainer } from "./styles";

interface CRPageErrorProps {
  cluster: string;
  agentId: string;
  isAgentSupported: boolean;
  inspectionFiltersComponent: React.ReactElement;
}

export const getCRPageError = ({
  cluster,
  agentId,
  isAgentSupported,
  inspectionFiltersComponent,
}: CRPageErrorProps): {
  component: React.ReactElement;
  hasError: boolean;
} => {
  if (!cluster || !agentId) {
    return {
      hasError: true,
      component: (
        <CRDsPageContainer>
          <CRDsPageLoading kubernetesResource={KubernetesCRResource} />
        </CRDsPageContainer>
      ),
    };
  }

  if (!isAgentSupported) {
    return {
      hasError: true,
      component: (
        <CRDsPageContainer>
          {inspectionFiltersComponent}
          <CRDsErrorOutdatedAgent unsupportedResource={"Custom Resource"} />
        </CRDsPageContainer>
      ),
    };
  }

  return { hasError: false, component: <></> };
};
