import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EventsApiApiV1NodesEventsIdsGetRequest,
  NodeEventsResponse,
  apiV1NodesEventsIdsGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

export const NODES_EVENTS_IDS_PATH = "/nodes/events/ids";

const fetchNodeEventsByIds = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1NodesEventsIdsGetRequest
): Promise<NodeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1NodesEventsIdsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetNodeEventsByIds = (
  params: EventsApiApiV1NodesEventsIdsGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [NODES_EVENTS_IDS_PATH, params],
    () => fetchNodeEventsByIds(apiClient, params),
    {
      enabled,
    }
  );
};
