import { useState } from "react";
import styled from "styled-components";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ThumbDown from "@mui/icons-material/ThumbDown";
import ThumbDownOutlined from "@mui/icons-material/ThumbDownOutlined";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbUpOutlined from "@mui/icons-material/ThumbUpOutlined";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";

import { muiColors } from "themes";
import { ActionIconButton } from "components/komodor-ui/Chat/MessageBubble/ActionIconButton";
import { chatAriaLabels } from "components/komodor-ui/Chat/ariaLabels";

const ActionIcon = styled(SvgIcon)`
  && {
    font-size: 14px;
    color: ${muiColors.gray[400]};
  }
`;
export interface MessageBubbleActionsProps {
  contentRef: React.RefObject<HTMLDivElement>;
}
export const MessageBubbleActions: React.FC<MessageBubbleActionsProps> = ({
  contentRef,
}) => {
  const [isHelpful, setIsHelpful] = useState<boolean>();

  return (
    <Stack direction="row" position="relative" left={-4}>
      <ActionIconButton
        handleClickAndGetResponse={() => {
          if (contentRef.current) {
            navigator.clipboard.writeText(contentRef.current.textContent);
            return "Copied";
          }
        }}
        tooltipProps={{ title: "Copy" }}
        iconButtonProps={{ "aria-label": chatAriaLabels.chatMessageCopy }}
      >
        <ActionIcon as={ContentCopy} />
      </ActionIconButton>
      <ActionIconButton
        handleClickAndGetResponse={() => {
          if (isHelpful) {
            return setIsHelpful(undefined);
          }
          setIsHelpful(true);
          return "Thank you for your feedback!";
        }}
        tooltipProps={{ title: "Helpful" }}
        iconButtonProps={{ "aria-label": chatAriaLabels.chatMessageHelpful }}
      >
        <ActionIcon as={isHelpful ? ThumbUp : ThumbUpOutlined} />
      </ActionIconButton>
      <ActionIconButton
        handleClickAndGetResponse={() => {
          if (isHelpful === false) {
            return setIsHelpful(undefined);
          }
          setIsHelpful(false);
          return "Thank you for your feedback!";
        }}
        tooltipProps={{ title: "Not helpful" }}
        iconButtonProps={{ "aria-label": chatAriaLabels.chatMessageNotHelpful }}
      >
        <ActionIcon as={isHelpful === false ? ThumbDown : ThumbDownOutlined} />
      </ActionIconButton>
    </Stack>
  );
};
