import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined,
  hasClusterViewPermissions: boolean
): IStep => {
  const output = issue?.results?.nodeIssues?.output;
  const isNodeEventsExists =
    output &&
    ((output.nodeIssues ?? []).length > 0 ||
      (output.nodeTerminations ?? []).length > 0);

  return {
    type: AvailabilityInvestigationCheckType.NodeIssues,
    visible: true,
    visited: false,
    disabled: !isNodeEventsExists || !hasClusterViewPermissions,
    message: hasClusterViewPermissions
      ? undefined
      : "Access denied due to lack of permissions",
  };
};
