import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import pluralize from "pluralize";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import {
  clearBatchActionsRequestSelector,
  selectedBatchActionsRequestSelector,
  setBatchActionsResponseSelector,
} from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { useBatchActionsLabel } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useBatchActionsLabel";
import { useActionsResourceKind } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useActionsResourceKind";
import { useSubmitBatchActions } from "@/shared/hooks/actions-api/useSubmitBatchActions";
import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";

export const ConfirmationActionButtons: React.FC = () => {
  const clearBatchActionsRequest = useBatchActionsStore(
    clearBatchActionsRequestSelector
  );
  const {
    bulkActionsRequest,
    onRequestProcessCompleteCb,
    onRequestProcessInitCb,
  } = useBatchActionsStore(selectedBatchActionsRequestSelector) ?? {};
  const setBatchActionsResponse = useBatchActionsStore(
    setBatchActionsResponseSelector
  );

  const { refreshResultsCb } = useInspectionBatchActionsContext();

  const hasSetSubmitResult = useRef(false);
  const actionLabel = useBatchActionsLabel().toLowerCase();
  const totalResources = bulkActionsRequest?.actions?.length ?? 0;
  const resourceName = pluralize(useActionsResourceKind(), totalResources);
  const confirmButtonLabel = `I understand, ${actionLabel} ${totalResources} ${resourceName}`;

  const { mutate, data, isLoading } = useSubmitBatchActions();

  const handleSubmitActions = () => {
    if (!bulkActionsRequest) return;
    mutate({ submitBulkActionsRequest: bulkActionsRequest });
  };

  useEffect(() => {
    if (data && data.data.bulkId && !hasSetSubmitResult.current) {
      hasSetSubmitResult.current = true;
      clearBatchActionsRequest();
      setBatchActionsResponse(data.data, onRequestProcessCompleteCb);
      if (onRequestProcessInitCb) {
        // wait dialog close animation time before clearing its content
        setTimeout(onRequestProcessInitCb, 500);
      }
    }
  }, [
    clearBatchActionsRequest,
    setBatchActionsResponse,
    data,
    refreshResultsCb,
    onRequestProcessCompleteCb,
    onRequestProcessInitCb,
  ]);

  return (
    <>
      <Button onClick={clearBatchActionsRequest}>Cancel</Button>
      <LoadingButton
        variant={"contained"}
        color={"error"}
        loading={isLoading}
        onClick={handleSubmitActions}
      >
        {confirmButtonLabel}
      </LoadingButton>
    </>
  );
};
