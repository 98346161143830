import { useAppNotificationsStore } from "@/shared/store/appNotificationsStore/appNotificationsStore";
import { selectAppNotifications } from "@/shared/store/appNotificationsStore/appNotificationsStoreSelectors";
import { NotificationStatus } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";

type NotificationStatusSummary = Record<NotificationStatus, number>;

export const useNotificationsStatusSummary = () => {
  const notifications = useAppNotificationsStore(selectAppNotifications);
  return notifications.reduce<NotificationStatusSummary>(
    (acc: NotificationStatusSummary, notification) => {
      acc[notification.status] = (acc[notification.status] || 0) + 1;
      return acc;
    },
    {
      [NotificationStatus.Error]: 0,
      [NotificationStatus.Loading]: 0,
      [NotificationStatus.Success]: 0,
    }
  );
};
