import { LocalStorageItem } from "@/shared/utils/localStorageSettings";

export type DurationOption = "s" | "m" | "h";

type ViewSettings = {
  showTimestamp: boolean;
  showLineNumber: boolean;
  wrapText: boolean;
};

export type LogsParams = {
  tail: number;
  sinceNumber: number;
  sinceUnit: DurationOption;
  sendSince: boolean;
  sendTail: boolean;
};

export type LogsViewerLocalStorageState = ViewSettings & LogsParams;

export const logsViewerSettingsLocalStorageKey = "logsViewerSettings";

export const logsViewerSettingsInLsItem = new LocalStorageItem(
  logsViewerSettingsLocalStorageKey
);

export const defaultLogsViewerSettings: LogsViewerLocalStorageState = {
  showTimestamp: true,
  showLineNumber: true,
  wrapText: true,
  tail: 100,
  sinceNumber: 168,
  sinceUnit: "h" as DurationOption,
  sendSince: false,
  sendTail: true,
};
