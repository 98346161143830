import React, { useState, useCallback, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { SearchField } from "../SearchField/SearchField";

import { filterNodes, findNode, getAllChildIds } from "./utils";
import { TreeNode } from "./TreeNode";
import { FilterableTreeViewProps, Node } from "./types";

export const FilterableTreeView = ({
  treeData,
  initialState,
  fixedNodes,
  onSelect,
}: FilterableTreeViewProps) => {
  const [filter, setFilter] = useState("");
  const [selectedNodes, setSelectedNodes] = useState(
    initialState?.selectedNodes ?? new Set<string>()
  );
  const [expandedNodes, setExpandedNodes] = useState(
    initialState?.expandedNodes ?? new Set<string>()
  );

  useEffect(() => {
    setSelectedNodes((_) => initialState?.selectedNodes ?? new Set());
  }, [initialState?.selectedNodes]);

  useEffect(() => {
    setExpandedNodes((_) => initialState?.expandedNodes ?? new Set());
  }, [initialState?.expandedNodes]);

  const handleNodeCheck = useCallback(
    (node: Node, checked: boolean) => {
      setSelectedNodes((prev) => {
        const newSet = new Set<string>(prev);

        // Handle the clicked node
        if (checked) {
          newSet.add(node.id);
          // Add all children
          if (node.children) {
            getAllChildIds(node).forEach((id) => newSet.add(id));
          }
        } else {
          newSet.delete(node.id);
          // Remove all children
          if (node.children) {
            getAllChildIds(node).forEach((id) => newSet.delete(id));
          }
        }

        onSelect(newSet);
        node.onCheck?.(checked);
        return newSet;
      });
    },
    [onSelect]
  );

  const handleNodeToggle = useCallback(
    (node: Node) => {
      setExpandedNodes((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(node.id)) {
          newSet.delete(node.id);
        } else {
          newSet.add(node.id);
        }
        return newSet;
      });
    },
    [setExpandedNodes]
  );

  const filteredData = useMemo(
    () => (filter ? filterNodes(treeData, filter) : treeData),
    [filter, treeData]
  );

  const findOriginalNode = useCallback(
    (nodeId: string) => {
      return findNode(treeData, nodeId);
    },
    [treeData]
  );

  const renderNode = useCallback(
    (node: Node) => {
      return (
        <TreeNode
          key={node.id}
          node={node}
          level={0}
          selectedNodes={selectedNodes}
          // https://app.clickup.com/t/86c2dqm28
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          expanded={expandedNodes.has(node.id)}
          onCheck={handleNodeCheck}
          onToggle={handleNodeToggle}
          findNode={findOriginalNode}
        />
      );
    },
    [
      selectedNodes,
      expandedNodes,
      handleNodeCheck,
      handleNodeToggle,
      findOriginalNode,
    ]
  );

  return (
    <Stack sx={{ width: "100%", overflowY: "auto", marginBottom: "16px" }}>
      <Box sx={{ padding: "16px 0", marginRight: "16px" }}>
        <SearchField
          value={filter}
          onSearch={setFilter}
          placeholder="Search"
          width={"100%"}
        />
      </Box>
      <Stack
        sx={{
          overflow: "auto",
          "& .MuiAccordion-root:last-of-type": { borderWidth: 0 },
          marginRight: "16px",
        }}
      >
        {fixedNodes?.length > 0 && (
          <>
            {fixedNodes.map(renderNode)}
            <Divider />
          </>
        )}
        {filteredData.map(renderNode)}
      </Stack>
    </Stack>
  );
};
