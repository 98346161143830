import { TextHighlightType } from "../HighlightedText";

export type HighlightedLines = {
  logTime: string;
  parts: TextHighlightType[];
  matchesInLine: number;
  isEvent: boolean | undefined;
};

export enum LogsType {
  Current = "current",
  Issue = "issue",
}

export interface LogEvent {
  eventTime: Date;
  eventMessage: string;
}

export type DisplayOption = {
  name: string;
  enabled: boolean;
  setEnabled: (val: boolean) => void;
  ariaLabel: string;
};
