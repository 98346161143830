import Button from "@mui/material/Button";

import { DriftType } from "../context/types";

type EmptyStateBaselineProps = {
  onButtonClick?: () => void;
  type: DriftType;
  disabled?: boolean;
};

export function EmptyStateBaseline({
  onButtonClick,
  type,
  disabled,
}: EmptyStateBaselineProps) {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{ padding: "5px 8px", fontSize: "12px" }}
      onClick={onButtonClick}
      disabled={disabled}
    >
      Add Baseline {type === DriftType.Services ? "Service" : "Release"}
    </Button>
  );
}
