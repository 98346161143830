import { useMemo } from "react";

import { latestDeploysInsightsFields } from "./consts";

import DeployEventGroup, {
  groupDeployEvents,
} from "@/components/common/EventGroup/deployEvent/DeployEventGroup";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useGetDeploys } from "@/shared/hooks/monitors-api/client/deploys/useGetDeploys";

export const useDeployEventGroupById = (
  eventId: string | undefined
): DeployEventGroup | undefined => {
  const flags = useOverridableFlags();

  const { data: correlatedDeployEventsResponse } = useGetDeploys(
    {
      ids: eventId ? [eventId] : [],
      fields: latestDeploysInsightsFields,
    },
    !!eventId
  );

  return useMemo(() => {
    const correlatedDeployEvent =
      correlatedDeployEventsResponse?.data?.[0] ?? null;
    if (!correlatedDeployEvent) return undefined;
    return groupDeployEvents([correlatedDeployEvent], flags)?.[0];
  }, [correlatedDeployEventsResponse?.data, flags]);
};
