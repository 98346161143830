import React, { useEffect, useMemo, useRef } from "react";
import Skeleton from "@mui/material/Skeleton";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { isEqual, omit } from "lodash";
import { format } from "date-fns";

import {
  BasicViolation,
  ViolationsApiGetViolationsRequest,
  ViolationsFilterGroupByGeneric,
} from "../../../../../../../../generated/reliabilityApi";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import {
  selectSetGroupState,
  selectSetUngroupedTotalViolationsCount,
  selectViolationsState,
} from "../../../../../../store/reliabilityStoreSelectors";
import { getAccumulatedViolations } from "../../../../../../utils/reliabilityUtils";
import { SeverityColumn } from "../ViolationsTable/violationTableColumns/SeverityColumn";
import { ViolationColumn } from "../../../../../shared/table/ReliabilityTitleColumn";
import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { useGetViolationsFilter } from "../../../../../../../../shared/hooks/reliability-api/filters/useGetViolationsFilter";
import { violationRequestParamsAsGenericViolationFilterCategory } from "../../../../../FiltersSection/filtersStateHandler/violationRequestParamsAsGenericViolationFilterCategory";
import { Dictionary } from "../../../../../../../../shared/types/Dictionary";
import { getInitialPaginationModel } from "../violationTableConstants";
import {
  DependentViolationsColumn,
  DependentViolationsColumnHeader,
  DependentViolationsLoader,
} from "../ViolationsTable/violationTableColumns/DependentViolationsColumn";

import { useViolationsRequestParams } from "./useViolationsRequestParams";

import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { getGroupData } from "@/components/reliability/utils/getGroupData";
import { ViolationStatusSelector } from "@/components/reliability/components/shared/ViolationStatusSelector/ViolationStatusSelector";

export const columnsConfig: Dictionary<GridColDef<BasicViolation>> = {
  severity: {
    field: "severity",
    headerName: "Severity",
    width: 100,
    sortable: false,
  },
  dependentViolations: {
    field: "hasDependentViolations",
    width: 100,
    sortable: false,
    renderHeader: () => <DependentViolationsColumnHeader />,
  },
  violation: {
    field: "violation",
    headerName: "Violation",
    sortable: false,
    flex: 1,
    minWidth: 300,
  },
  createdAt: {
    field: "createdAt",
    headerName: "First seen",
    sortable: false,
    width: 120,
  },
  status: {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 150,
  },
};

const bestPracticesColumn = {
  ...columnsConfig.dependentViolations,
  renderCell: (params: GridValueGetterParams<BasicViolation>) => (
    <DependentViolationsColumn hasDependentViolations={params.value} />
  ),
} as GridColDef<BasicViolation>;

const severityColumn = {
  ...columnsConfig.severity,
  renderCell: (params: GridValueGetterParams<BasicViolation>) => {
    return <SeverityColumn severity={params.value} />;
  },
} as GridColDef<BasicViolation>;

type TableColumnsProps = {
  violationTableType: ViolationsTableType;
};

export const useViolationsTableColumns = ({
  violationTableType,
}: TableColumnsProps): GridColDef<BasicViolation>[] => {
  const { customColumnsDefinitions } = useViolationsTableContext();
  if (customColumnsDefinitions?.customColumns)
    return customColumnsDefinitions?.customColumns;

  const columnToUse =
    violationTableType === ViolationsTableType.impactAnalysis
      ? bestPracticesColumn
      : severityColumn;

  return [
    columnToUse,
    {
      ...columnsConfig.violation,
      renderCell: ({ row }) => {
        return (
          <ViolationColumn
            komodorUid={row.komodorUid}
            checkType={row.checkType}
            supportingData={row.supportingData}
          />
        );
      },
    },
    {
      ...columnsConfig.createdAt,
      valueGetter: (params) => {
        return params.value ? format(params.value * 1000, "MMM dd, yyyy") : "";
      },
    },
    {
      ...columnsConfig.status,
      renderCell: ({ row }) => {
        return <ViolationStatusSelector violation={row} />;
      },
    },
  ];
};

const emptyBestPracticesColumn = {
  ...columnsConfig.dependentViolations,
  renderCell: () => <DependentViolationsLoader />,
} as GridColDef<BasicViolation>;

const emptySeverityColumn = {
  ...columnsConfig.severity,
  renderCell: () => <Skeleton variant="rounded" width={72} height={14} />,
} as GridColDef<BasicViolation>;

export const useEmptyColumns = ({ violationTableType }: TableColumnsProps) => {
  const { customColumnsDefinitions } = useViolationsTableContext();
  if (customColumnsDefinitions?.loadingStateColumns)
    return customColumnsDefinitions?.loadingStateColumns;

  const columnToUse =
    violationTableType === ViolationsTableType.impactAnalysis
      ? emptyBestPracticesColumn
      : emptySeverityColumn;
  return [
    columnToUse,
    {
      ...columnsConfig.violation,
      renderCell: () => {
        return (
          <div>
            <Skeleton
              variant="rounded"
              width={242}
              height={14}
              sx={{ marginBottom: "10px" }}
            />
            <Skeleton variant="rounded" width={159} height={10} />
          </div>
        );
      },
    },
    {
      ...columnsConfig.createdAt,
      renderCell: () => {
        return <Skeleton variant="rounded" width={100} height={20} />;
      },
    },
    {
      ...columnsConfig.status,
      renderCell: () => {
        return <Skeleton variant="rounded" width={100} height={20} />;
      },
    },
  ];
};

export const useResetPageOnRequestParamsChange = () => {
  const { uid, violationsGroupBy } = useViolationsTableContext();
  const violationsRequestParams = useViolationsRequestParams();

  const { groups } = useReliabilityStore(selectViolationsState);
  const setGroupsState = useReliabilityStore(selectSetGroupState);

  const lastRequest = useRef<ViolationsApiGetViolationsRequest>(
    violationsRequestParams
  );

  useEffect(() => {
    if (
      !isEqual(
        omit(violationsRequestParams, ["pageSize", "offset"]),
        omit(lastRequest.current, ["pageSize", "offset"])
      )
    ) {
      setGroupsState({
        uid: uid ?? "",
        groupBy: violationsGroupBy,
        newState: {
          paginationModel: {
            ...(getGroupData({
              groupState: groups,
              violationsGroupBy,
              uid,
            })?.paginationModel ??
              getInitialPaginationModel(violationsGroupBy)),
            page: 0,
          },
        },
      });
    }
    lastRequest.current = violationsRequestParams;
  }, [violationsRequestParams, setGroupsState, uid, violationsGroupBy, groups]);
};

export const useGetCountByViolationFilters = ():
  | Dictionary<number>
  | undefined => {
  const violationsRequestParams = useViolationsRequestParams({
    requestAllSeverities: true,
  });
  const filterBy = violationRequestParamsAsGenericViolationFilterCategory(
    violationsRequestParams
  );
  const isBestPracticeTab = useIsBestPracticesTab();

  const { data } = useGetViolationsFilter(
    {
      countBy: isBestPracticeTab ? "hasDependentViolations" : "severity",
      postViolationsCountRequestBody: {
        filterBy: filterBy,
      },
    },
    { keepPreviousData: true }
  );

  return useCount(data?.data);
};

export const useCount = (
  countByFilters: ViolationsFilterGroupByGeneric | undefined
) => {
  return useMemo(() => {
    if (!countByFilters?.items.length) return;
    return countByFilters?.items.reduce<Dictionary<number>>((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
  }, [countByFilters?.items]);
};

export const useSetAllUngroupedSeveritiesCount = (
  severitiesCount: Dictionary<number> | undefined
) => {
  const { impactGroupId } = useViolationsRequestParams();
  const setUngroupedTotalViolationsCount = useReliabilityStore(
    selectSetUngroupedTotalViolationsCount
  );

  useEffect(() => {
    if (!impactGroupId && severitiesCount) {
      setUngroupedTotalViolationsCount(
        getAccumulatedViolations(severitiesCount)
      );
    }
  }, [impactGroupId, setUngroupedTotalViolationsCount, severitiesCount]);
};
