import { HTMLAttributes, useRef } from "react";
import styled from "styled-components";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";

import { muiColors } from "themes";
import { ActionIconButton } from "components/komodor-ui/Chat/MessageBubble/ActionIconButton";
import { chatAriaLabels } from "components/komodor-ui/Chat/ariaLabels";

const StyledPre = styled.pre`
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  max-height: 200px;
  background-color: ${muiColors.gray[800]};
  color: ${muiColors.common.white};
  overflow: auto;
  margin-block: 8px;
`;

export const Pre = (props: HTMLAttributes<HTMLPreElement>) => {
  const contentRef = useRef<HTMLPreElement>(null);

  return (
    <Box position="relative">
      <StyledPre ref={contentRef} {...props} />
      <ActionIconButton
        handleClickAndGetResponse={() => {
          if (contentRef.current) {
            navigator.clipboard.writeText(contentRef.current.textContent);
            return "Copied";
          }
        }}
        iconButtonProps={{
          sx: {
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: muiColors.common.white,
            color: muiColors.gray[800],
            "&:hover": {
              backgroundColor: muiColors.gray[200],
            },
          },
          "aria-label": chatAriaLabels.chatMessageCodeBlockCopy,
        }}
        tooltipVariant="light"
        tooltipProps={{ title: "Copy", placement: "top" }}
      >
        <ContentCopy sx={{ fontSize: "14px" }} />
      </ActionIconButton>
    </Box>
  );
};
