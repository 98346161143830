import { useMemo } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { BaseResourceListTableProps } from "@/components/common/ResourceListTable/ResourceListTableProps";
import { getRowIdentifier } from "@/components/common/ResourceListTable/resourceListTableUtils";

export const useRowsSelectedState = (
  rowSelection: BaseResourceListTableProps["rowSelection"],
  rowsOnPage: ResourceTableModelRow[]
) => {
  return useMemo(() => {
    const allRowsSelected = rowsOnPage.every((row) => {
      const idField = getRowIdentifier(row);
      return (
        rowSelection?.selectedRowNames.includes(idField) ||
        rowSelection?.unselectableRowNames?.includes(idField)
      );
    });

    const someRowsSelected =
      !allRowsSelected &&
      rowsOnPage.some((row) => {
        const idField = getRowIdentifier(row);
        return rowSelection?.selectedRowNames.includes(idField);
      });

    return { allRowsSelected, someRowsSelected };
  }, [
    rowSelection?.selectedRowNames,
    rowSelection?.unselectableRowNames,
    rowsOnPage,
  ]);
};
