import styled from "styled-components";
import React from "react";
import { Select } from "@komodorio/design-system/deprecated";
import { TextInput } from "@komodorio/design-system/komodor-ui";
import { Close16 } from "@komodorio/design-system/icons";
import IconButton from "@mui/material/IconButton";

import { lightStyle } from "../styles";
import { Suggestions } from "../suggestions";

import AvailableIntegrationTile from "@/components/integrations/management/available/AvailableIntegrationTile";
import definitions from "@/components/integrations/management/definitions";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

interface Props {
  loading: boolean;
  token: string | null;
  suggestions: Suggestions;
  channel: string;
  setChannel: (channel: string) => void;
}

const Label = styled.div`
  ${lightStyle}
  padding-bottom: 0.5rem;
`;

const StyledAvailableIntegrationTile = styled(AvailableIntegrationTile)`
  width: 320px;
  min-height: 32px;
  border: 1px solid #dcdddf;
  box-shadow: unset;
  padding: 12px 24px;
`;

export const SlackSinkOutputContents: React.FC<Props> = ({
  loading,
  token,
  suggestions,
  channel,
  setChannel,
}) => {
  const { allowSlackCustomUserChannels } = useOverridableFlags();
  const shouldShowCloseIcon = channel.length > 0;

  if (loading) {
    return null;
  }

  if (token) {
    return allowSlackCustomUserChannels ? (
      <TextInput
        size="medium"
        label="Slack Channel"
        placeholder="Enter channel name"
        value={channel}
        onChange={(e) => setChannel(e.target.value)}
        sx={{ width: "100%" }}
        InputProps={{
          endAdornment: shouldShowCloseIcon && (
            <IconButton onClick={() => setChannel("")}>
              <Close16 />
            </IconButton>
          ),
        }}
      />
    ) : (
      <Select
        size="medium"
        width="100%"
        label="Slack Channel"
        listMaxHeight="240px"
        placeholder="select channel"
        searchable={suggestions.length > 5}
        options={suggestions}
        value={
          suggestions.find((o) => o.value === channel) ?? {
            label: channel,
            value: channel,
          }
        }
        onChange={(selected) => {
          setChannel(selected.value);
        }}
        onClean={() => setChannel("")}
      />
    );
  }

  return (
    <>
      <Label>
        Keep your team up-to-date with the current state and health of your
        system by installing Komodor's Slack integration
      </Label>
      <StyledAvailableIntegrationTile
        integration={definitions.slack}
        newTab={true}
      />
    </>
  );
};
