import React from "react";

import { Section, SectionName } from "../../common/styles";
import Resource from "../../../../../../../ResourceView/resources";
import { EnrichedMessageSection } from "../../../../../../../InvestigationMode/messageEnrichment/EnrichedMessageSection";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

export interface MessageSectionProps {
  errorMessages: string[];
  reasons: string[];
  agentId: string | undefined;
  cluster: string;
  namespace: string;
  onClick?: (resource: Resource) => void;
  checks?: BaseWorkflowCheck[];
}

export const MessageSection: React.FC<MessageSectionProps> = ({
  errorMessages,
  reasons,
  agentId,
  cluster,
  namespace,
  onClick,
  checks,
}) => {
  return (
    <Section>
      <SectionName>reason</SectionName>
      <EnrichedMessageSection
        {...{
          errorMessages,
          reasons,
          agentId,
          cluster,
          namespace,
          onClick,
          checks,
        }}
      />
    </Section>
  );
};
