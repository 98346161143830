import { Node } from "./types";

export const findNode = (nodes: Node[], targetId: string): Node | undefined => {
  for (const node of nodes) {
    if (node.id === targetId) {
      return node;
    }
    if (node.children) {
      const found = findNode(node.children, targetId);
      if (found) {
        return found;
      }
    }
  }
};

export const getAllChildIds = (node) => {
  const ids = [];
  const traverse = (node) => {
    ids.push(node.id);
    if (node.children) {
      node.children.forEach(traverse);
    }
  };
  if (node.children) {
    node.children.forEach(traverse);
  }
  return ids;
};

// https://app.clickup.com/t/86c2dqm28
// eslint-disable-next-line max-params
export const getParentIds = (nodes, targetId, result = []) => {
  for (const node of nodes) {
    if (
      node.children?.some(
        (child) =>
          child.id === targetId ||
          getParentIds(child.children || [], targetId, result)
      )
    ) {
      result.push(node.id);
      return true;
    }
  }
  return false;
};

export const getSelectedChildrenIds = (childIds, selectedNodes) => {
  return [...selectedNodes].filter((id) => childIds.includes(id));
};

export const filterNodes = (nodes: Node[], filterText: string): Node[] => {
  return nodes.reduce<Node[]>((filteredNodes, node) => {
    // Filter children recursively
    const filteredChildren = filterNodes(node.children, filterText);

    // Check if current node matches
    const nodeMatches = node.label
      // https://app.clickup.com/t/86c2dqm28
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore comment
      .toLowerCase()
      .includes(filterText.toLowerCase());

    if (nodeMatches || filteredChildren.length > 0) {
      // Create a new node with only matching children (or self)
      const newNode = {
        ...node,
        children: filteredChildren,
      };

      return [...filteredNodes, newNode];
    }

    return filteredNodes;
  }, []);
};
