import {
  CloseButton,
  DrawerHeader,
  EmptyState,
} from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import { WorkspaceDetails } from "../../types";
import { INITIAL_DEFINITION } from "../../constants";

import { WorkspaceDetailsForm } from "./WorkspaceDetailsForm";
import { resourceScopeToQuery } from "./utils/resourceScopeToQuery";

import { DrawerProps } from "@/shared/store/drawersStackStore/types";
import { useGetWorkspaceById } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaceById";
import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { TypedScopeWorkspace } from "@/shared/hooks/workspaces-api/types";

export type Props = DrawerProps & {
  workspaceId?: string;
};

export const ScopedWorkspaceDrawer: React.FC<Props> = ({
  open,
  onClose,
  workspaceId,
}) => {
  const [hasDataError, setHasDataError] = useState(false);
  const formData = useForm<WorkspaceDetails>({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      definitions: INITIAL_DEFINITION,
    },
  });
  const {
    data: wsData,
    isError: isWsDataError,
    isFetching: isWsDataLoading,
  } = useGetWorkspaceById(
    { id: workspaceId ?? "" },
    { enabled: !!workspaceId }
  );

  useEffect(() => {
    if (workspaceId && wsData) {
      const scopedWs = wsData as unknown as TypedScopeWorkspace;
      const scopes = scopedWs?.value.scopes;

      // TODO: Error handling

      try {
        const queries = scopes?.map((scope) => resourceScopeToQuery(scope));

        formData.reset({
          name: wsData?.name,
          description: wsData?.description,
          definitions: queries,
        });
      } catch (e) {
        // TODO: DD Error reporting
        setHasDataError(true);
      }
    }
  }, [formData, workspaceId, wsData]);

  const title = useMemo(() => {
    if (workspaceId) {
      return "Edit Workspace";
    }

    return "Create Workspace";
  }, [workspaceId]);

  const getContents = useCallback(() => {
    if (isWsDataLoading) {
      return <CenteredLoader />;
    }

    if (isWsDataError || hasDataError) {
      // TODO: Add DD reporting here

      return (
        <Typography variant="h3" color="error">
          <EmptyState title="Error loading workspace data" />
        </Typography>
      );
    }

    return (
      <FormProvider {...formData}>
        <WorkspaceDetailsForm onClose={onClose} workspaceId={workspaceId} />
      </FormProvider>
    );
  }, [
    formData,
    hasDataError,
    isWsDataError,
    isWsDataLoading,
    onClose,
    workspaceId,
  ]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          width: "783px",
        },
      }}
    >
      <Box marginTop="16px">
        <DrawerHeader
          title={<Typography variant="h3">{title}</Typography>}
          leftActions={<CloseButton onClose={onClose} />}
        />
      </Box>

      <Stack direction="column" gap="16px" flex={1}>
        {getContents()}
      </Stack>
    </Drawer>
  );
};
