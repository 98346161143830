import React from "react";

import { NotificationStatus } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { appNotificationsAriaLabels } from "@/shared/components/AppNotificationsCenter/appNotificationsAriaLabels";
import { statusToIcon } from "@/shared/components/AppNotificationsCenter/appNotificationsConstants";

type AppNotificationStatusProps = {
  status: NotificationStatus;
};

export const AppNotificationStatus: React.FC<AppNotificationStatusProps> = ({
  status,
}) => {
  return (
    <div aria-label={`${appNotificationsAriaLabels.item.status} ${status}`}>
      {statusToIcon[status]}
    </div>
  );
};
