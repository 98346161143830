import React, { useMemo } from "react";
import { DEFAULT_PAGE_SIZES } from "@komodorio/design-system/komodor-ui";
import { Outlet } from "react-router-dom";

import { PaginationContext } from "@/shared/context/tableContext/PaginationContext";

export const PaginationProvider: React.FC<{
  children?: React.ReactNode | unknown;
}> = ({ children }): JSX.Element => {
  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZES[0]);

  const state = useMemo(
    () => ({
      pageSize,
      setPageSize,
    }),
    [pageSize, setPageSize]
  );

  return (
    <PaginationContext.Provider value={state}>
      <Outlet />
    </PaginationContext.Provider>
  );
};
