import { useMemo } from "react";
import { groupBy, uniqBy } from "lodash";

import { useGetPodEvents } from "../../../../shared/hooks/resources-api/client/events/useGetPodEvents";
import { PodEventsResponseDataInner } from "../../../../generated/resourcesApi";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { buildKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";

const PODS_BATCH = 20;

export interface PodsUidsPage {
  podIdentifiers: {
    komodorUid: string;
    podUid: string;
  }[];
  firstEventTime: Date;
  lastEventTime: Date;
}

export const useGetPodsBatchesFromOwnerUid = (
  uid: string | undefined,
  cluster: string,
  namespace: string,
  fromEpoch?: Date,
  toEpoch?: Date
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [PodsUidsPage[] | undefined, boolean] => {
  const { data: eventsFromAPI, isLoading } = useGetPodEvents(
    {
      ownerUid: uid,
      fields: ["podName", "podUid", "eventTime"],
      fromEpoch: fromEpoch?.getTime().toString(),
      toEpoch: toEpoch?.getTime().toString(),
      order: "ASC",
    },
    !!uid && !!fromEpoch && !!toEpoch
  );

  return useMemo(
    () => [
      eventsFromAPI
        ? podsBatchesByEventTime(
            eventsFromAPI.data,
            cluster,
            namespace,
            toEpoch
          )
        : undefined,
      isLoading,
    ],
    [cluster, eventsFromAPI, isLoading, namespace, toEpoch]
  );
};

export const podsBatchesByEventTime = (
  pods: PodEventsResponseDataInner[],
  cluster: string,
  namespace: string,
  toEpoch?: Date
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): PodsUidsPage[] => {
  const groupedPodsByUid = groupBy(pods, (pod) => pod.podUid);
  const pages: PodsUidsPage[] = [];
  const podIdentifiers = pods.flatMap((e) => ({
    komodorUid: buildKomodorUid({
      clusterName: cluster,
      namespace: namespace,
      kind: KubernetesPodsResource.Kind,
      resourceName: e.podName as string,
    }),
    podUid: e.podUid as string,
  }));
  const uniquePodIdentifiers = uniqBy(podIdentifiers, (e) => e.komodorUid);

  for (let i = 0; i < uniquePodIdentifiers.length; i += PODS_BATCH) {
    const podIdentifiersForPage = podIdentifiers.slice(i, i + PODS_BATCH);

    const firstEventTime = new Date(
      groupedPodsByUid[podIdentifiersForPage[0].podUid][0].eventTime ?? ""
    );
    const lastEventTime = toEpoch ?? new Date();
    pages.push({
      podIdentifiers: podIdentifiersForPage,
      firstEventTime,
      lastEventTime,
    });
  }
  return pages;
};
