import { getAppConfig } from "../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

interface Options {
  skipWorkspaceHeader?: boolean;
}

export const useWorkspacesApiClient = (options?: Options) => {
  const { skipWorkspaceHeader } = options || {};

  return useCreateAxiosClient(getAppConfig().workspacesAPIServerURL, {
    skipWorkspaceHeader: !!skipWorkspaceHeader,
  });
};
