import React from "react";

import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { CheckIconType } from "../CheckCard";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeEvictedPodsDetails from "./detailsComponents/NodeEvictedPodsDetails";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";
import { StateInSearchParamsType } from "@/shared/hooks/state/useStateInSearchParams";

interface NodeEvictedPodsOutput {
  pods?: PodOutput[];
  hiddenPods?: number;
  isNotReady: boolean;
  pressures: {
    isMemoryPressure: boolean;
    isDiskPressure: boolean;
    isPidPressure: boolean;
  };
}
export interface PodOutput {
  name: string;
  namespace?: string;
  serviceId: string;
  phase: string;
  message: string;
  priority: number;
  priorityClassName: string;
  qos: string;
  containers: {
    name: string;
    resources?: {
      limits?: {
        cpu?: string;
        memory?: string;
      };
      requests?: {
        cpu?: string;
        memory?: string;
      };
    };
  }[];
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeEvictedPodsCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "Pod eviction";
    this.question = "Are pods being evicted?";
    this.description = (
      <>
        If pods are being evicted it might be a consequence of a node being
        under pressure and indicates that an eviction threshold has met, the
        kubelet might evict one or more pods on the node to reclaim resources
        prevent starvation.
        <br /> <br />
        Depending on the type of pressure, the kubelet uses different parameters
        to determine pod eviction order.
      </>
    );
    this.action = (
      <>
        We ran{" "}
        <GrayHighlight>
          kubectl get pods --all-namespaces -o wide --field-selector
          spec.nodeName={`<NODE>`}
        </GrayHighlight>{" "}
        to see if pods are being evicted.
      </>
    );
    this.output = check.output as NodeEvictedPodsOutput;
    this.shortOutput = getEvictedShortOutput(check);
    this.icon = getEvictedIcon(check);
    this.isFinding = true;
  }

  renderCheckDetails(
    setSelectedCheckType: StateInSearchParamsType[1]
  ): JSX.Element {
    return (
      <NodeEvictedPodsDetails
        check={this}
        setSelectedCheckType={setSelectedCheckType}
      />
    );
  }
}

const getEvictedShortOutput = (check: BaseWorkflowCheck): string => {
  const checkOutput = check.output as NodeEvictedPodsOutput;
  if (check.passed) {
    if (checkOutput?.pressures?.isMemoryPressure) {
      return "Pods could be evicted due to Memory pressure";
    }
    if (checkOutput?.pressures?.isDiskPressure) {
      return "Pods could be evicted due to Disk pressure";
    }
    if (checkOutput?.pressures?.isPidPressure) {
      return "Pods could be evicted due to Pid pressure";
    }
    if (checkOutput?.isNotReady) {
      return "The node is not ready and could trigger pod eviction";
    }
    return "There is no Pod Eviction";
  } else {
    if (checkOutput?.pressures?.isMemoryPressure) {
      return "Pods are being evicted due to Memory pressure";
    }
    if (checkOutput?.pressures?.isDiskPressure) {
      return "Pods are being evicted due to Disk pressure";
    }
    if (checkOutput?.pressures?.isPidPressure) {
      return "Pods are being evicted due to PID pressure";
    }
    return "Pods are being evicted";
  }
};
const getEvictedIcon = (check: BaseWorkflowCheck): CheckIconType => {
  const checkOutput = check.output as NodeEvictedPodsOutput;
  const isPressure =
    checkOutput?.pressures?.isMemoryPressure ||
    checkOutput?.pressures?.isDiskPressure ||
    checkOutput?.pressures?.isPidPressure;
  if (check.passed) {
    if (!checkOutput?.isNotReady && !isPressure) {
      return "success" as CheckIconType;
    }
    return "warn" as CheckIconType;
  } else {
    return "fail" as CheckIconType;
  }
};
