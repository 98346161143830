import { NetPol } from "@komodorio/design-system/icons";

import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesNetworkPolicyResource = {
  NameInK8S: "networkpolicies",
  Group: "network",
  NavBarName: "Network Policies",
  PresentName: "Network Policies",
  Namespaced: true,
  UnnecessaryColumns: [],
  SupportedActions: [AvailableActions.Delete],
  Kind: SupportedResourcesKinds.KubernetesNetworkPolicyResource,
  Icon: NetPol,
  hasLabels: true,
};
