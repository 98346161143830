import { RbacRole } from "@/generated/auth";

export const getModalTitle = (
  role: RbacRole | undefined,
  isReadOnly: boolean
) => {
  const action = role ? "Edit" : "Add";
  const suffix = isReadOnly ? "(read only)" : "";

  return `${action} Role ${suffix}`;
};

export const getInitialRole = (role?: RbacRole): RbacRole => {
  if (!role) {
    return {
      name: "",
      rbacPolicies: [],
      isDefault: false,
    } as unknown as RbacRole;
  }

  return role;
};
