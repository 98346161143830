import { ComparisonTable } from "@komodorio/design-system/komodor-ui";
import pluralize from "pluralize";

import { ComparisonTableTitleDriftIndication } from "../common/ComparisonTableTitleDriftIndication";
import { EmptyStateComparedItems } from "../common/EmptyStateComparedItems";
import { DriftType } from "../context/types";
import { MAX_COMPARED_SERVICES } from "../Services/constants";
import { useDriftComparisonContext } from "../context/useDriftComparisonContext";

import { useHelmResourcesTableData } from "./tablesDataHooks";
import { ResourcesDiffModalParams } from "./ResourcesDiffModal";
import { komodorServiceKind } from "./constants";
import { Legend } from "./Legend";

import {
  HelmComparisonResponseTargetAttributes,
  HelmComparisonResponseBaselineAttributes,
  HelmResourcesComparisonForKind,
} from "@/generated/workspacesApi";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { HelmChart } from "@/generated/addonsApi";

interface ResourceKindComparisonTableProps {
  resourceKindComparison: HelmResourcesComparisonForKind;
  baselineAttributesComparisonData: HelmComparisonResponseBaselineAttributes;
  targetAttributesComparisonData: HelmComparisonResponseTargetAttributes[];
  helmReleases: HelmChart[];
  isComparedEmpty: boolean;
  setResourcesDiffModal: (diffModal: ResourcesDiffModalParams | null) => void;
}

export const ResourceKindComparisonTable: React.FC<
  ResourceKindComparisonTableProps
> = ({
  resourceKindComparison,
  baselineAttributesComparisonData,
  targetAttributesComparisonData,
  isComparedEmpty,
  setResourcesDiffModal,
  helmReleases,
}) => {
  const { showOnlyDriftKey } = useDriftComparisonContext();
  const [showOnlyDrift] =
    useStringifiedStateInSearchParams<boolean>(showOnlyDriftKey);

  const { attributes, compared, baseline } = useHelmResourcesTableData({
    resourceKindComparison,
    baselineAttributesComparisonData,
    targetAttributesComparisonData,
    helmReleases,
    setResourcesDiffModal,
  });
  if (showOnlyDrift && !resourceKindComparison.isDiff) return null;
  return (
    <ComparisonTable
      key={resourceKindComparison.kind}
      limit={MAX_COMPARED_SERVICES}
      title={pluralize(resourceKindComparison.kind)}
      titleLeftElement={
        compared.length === 0 ? null : (
          <ComparisonTableTitleDriftIndication
            isDiff={resourceKindComparison.isDiff}
          />
        )
      }
      titleRightElement={
        <Legend
          isKomodorService={komodorServiceKind[resourceKindComparison.kind]}
        />
      }
      isStacked
      initialIsOpen={false}
      collapsible
      isLoading={false}
      noBaselineElement={null}
      noComparedItemsElement={
        isComparedEmpty ? (
          <EmptyStateComparedItems type={DriftType.Helm} />
        ) : null
      }
      attributes={attributes}
      baseline={baseline}
      compared={compared}
    />
  );
};
