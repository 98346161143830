import { muiColors } from "@komodorio/design-system";
import { ComparisonTableProps } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";

import { NameField } from "../common/NameFieldCell";
import { useDriftComparisonContext } from "../context/useDriftComparisonContext";

import { getHelmDrawerData } from "./AttributesTableUtils";
import { buildHelmOptionValue } from "./selectionOptionsUtils";
import {
  ComparisonHelmResourcesWithResources,
  CellRendererResources,
  ComparisonHelmResources,
} from "./types";
import { ResourcesDiffModalParams } from "./ResourcesDiffModal";
import { useResourcesFieldsAttributes } from "./DynamicResourcesAttributesField";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { BaselineHelmResources } from "@/generated/workspacesApi";
import { HelmChart } from "@/generated/addonsApi";

const defaultCellRenderer =
  (field: keyof ComparisonHelmResources): CellRendererResources =>
  (rowData) => {
    const { isBaseline, data } = rowData;
    const value = isBaseline
      ? data[field]
      : data.data?.[field]?.value !== undefined
      ? data.data?.[field]?.value
      : undefined;
    return value ? (
      <Typography
        variant="body2"
        color={isBaseline ? muiColors.gray[600] : "text.primary"}
      >
        {value}
      </Typography>
    ) : null;
  };

export const useHelmResourcesTableAttributes = (params: {
  baselineResourceKindComparison?: ComparisonHelmResourcesWithResources;
  resourceKind?: string;
  isResourceKindSupported?: boolean;
  helmReleases?: HelmChart[];
  setResourcesDiffModal?: (diffModal: ResourcesDiffModalParams | null) => void;
  baselineResourceData?: BaselineHelmResources | undefined;
}): ComparisonTableProps<ComparisonHelmResourcesWithResources>["attributes"] => {
  const {
    baselineResourceKindComparison,
    helmReleases,
    resourceKind,
    isResourceKindSupported,
    baselineResourceData,
    setResourcesDiffModal,
  } = params;
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { showOnlyDriftKey } = useDriftComparisonContext();
  const [showOnlyDrift] =
    useStringifiedStateInSearchParams<boolean>(showOnlyDriftKey);

  const resourcesAttributes = useResourcesFieldsAttributes({
    baselineResourceKindComparison,
    resourceKind,
    isResourceKindSupported: isResourceKindSupported ?? false,
    showOnlyDrift: showOnlyDrift ?? false,
    setResourcesDiffModal,
  });
  return (
    [
      {
        headerName: "Name + Chart",
        field: "nameAndChart",
        showValueAlways: true,
        onClick: ({ isBaseline, data }) => {
          const chart = isBaseline
            ? data.komodorUidValue
            : data.data?.komodorUidValue?.value;
          const selectedRelease = helmReleases?.find(
            (release) => buildHelmOptionValue(release) === chart
          );
          if (selectedRelease) {
            pushDrawer(getHelmDrawerData(selectedRelease));
          }
        },
        cellBodyClassName: "name-cell",
        renderCell: (data) => <NameField rowData={data} field="nameAndChart" />,
      },
      {
        headerName: "Version",
        field: "version",
        hideValueIfDifferent: false,
        renderCell: defaultCellRenderer("version"),
      },
      {
        headerName: "Cluster",
        field: "cluster",
        renderCell: defaultCellRenderer("cluster"),
        showValueAlways: true,
      },
      {
        headerName: `No. of ${pluralize(resourceKind ?? "resource")}`,
        field: "count",
        renderCell: defaultCellRenderer("count"),
      },
      ...resourcesAttributes,
    ] as ComparisonTableProps<ComparisonHelmResourcesWithResources>["attributes"]
  ).filter((attribute) => {
    if (
      !showOnlyDrift ||
      attribute.field === "nameAndChart" ||
      attribute.field === "version" ||
      attribute.field === "cluster"
    ) {
      return true;
    }
    if (attribute.field === "count") {
      return Boolean(baselineResourceData?.count?.isDiff);
    }
    return true;
  });
};
