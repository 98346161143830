import { parseISO } from "date-fns";
import { palette } from "@komodorio/design-system";

import EventGroup, { EventBase, EventGroupTooltipProps } from "..";
import EventNodeShape from "../../../monitorsView/assets/eventNode.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NodeChangeDetails } from "../../ProcessList/details/NodeChangeDetails";
import { INVESTIGATION } from "../../../routes/routes";
import { NodeEventsResponseDataInner } from "../../../../generated/resourcesApi";
import { EventsChartTooltipComponent } from "../../EventsChart/TimelineChart/EventTooltip";

import {
  getFormattedPressure,
  getFormattedReadyStatus,
  getFormmatedCpu,
  parseReadyMessage,
  parseReadyReason,
  ReadyStatus,
} from "./commonNodeEvent";

export enum NodeEventAction {
  Create = "Create",
  Delete = "Delete",
  Update = "Update",
}

export const actionText: { [key in NodeEventAction]: string } = {
  [NodeEventAction.Create]: "created",
  [NodeEventAction.Delete]: "terminated",
  [NodeEventAction.Update]: "changed",
};

export const NodeEventGroupTypes: { [key in NodeEventAction]: string } = {
  [NodeEventAction.Create]: "Node created",
  [NodeEventAction.Delete]: "Node terminated",
  [NodeEventAction.Update]: "Node change",
};

const GKESpotShutdown = "Removed due to spot interruption or manual action";
export enum TerminationReason {
  SpotInterruption = "spot interruption",
  FargateShutdown = "Fargate shutdown",
  AtlassianEscalator = "Atlassian Escalator",
  Shutdown = "shutdown",
  Autoscaling = "autoscaling",
  Manual = "manual",
  MemoryPressure = "memory pressure",
  DiskPressure = "disk pressure",
  PidPressure = "pid pressure",
  NetworkUnavailable = "network is unavailable",
  Unreachable = "unreachable",
  Unschedulable = "unschedulable",
  NotReady = "not ready",
  Unknown = "unknown",
}

enum NodeEnrichmentKind {
  SpotITN = "SPOT_ITN",
}

export const getTerminationReason = (
  event: NodeEventsResponseDataInner
): string | null => {
  if (event.event_node_enrichment?.kind === NodeEnrichmentKind.SpotITN) {
    return TerminationReason.SpotInterruption;
  }
  const reason = event.terminationReason as TerminationReason;
  if (
    [
      TerminationReason.SpotInterruption,
      TerminationReason.Autoscaling,
      TerminationReason.Manual,
      TerminationReason.FargateShutdown,
      TerminationReason.AtlassianEscalator,
    ].includes(reason)
  ) {
    return reason;
  }
  if (
    event.allocationType === "spot" &&
    event.cloudProvider === "cloud.google.com" &&
    event.terminationReason === TerminationReason.Shutdown
  ) {
    return GKESpotShutdown;
  }
  return null;
};

export interface NodeChangeEvent extends EventBase {
  id: string;
  nodeName: string;
  clusterName: string;
  status: string;
  eventTime: Date;
  ready: ReadyStatus;
  readyReason: string;
  readyMessage: string;
  memoryPressure: string;
  diskPressure: string;
  lastHeartbeatTime: string | undefined;
  cpuCapacity: string;
  isDeleted: boolean;
  action: NodeEventAction;
  nodeCreationTime: Date;
  region: string;
  zone: string;
  instanceType: string;
  allocationType: string;
  os: string;
  cloudProvider: string;
  terminationReason: string;
}

const toNodeChangeEvent = (
  e: NodeEventsResponseDataInner,
  serviceId: string
): NodeChangeEvent => ({
  serviceId,
  type: NodeEventGroupTypes[e.action as NodeEventAction],
  id: e.id,
  nodeName: e.nodeName ?? "",
  clusterName: e.clusterName ?? "",
  eventTime: parseISO(e.eventTime ?? ""),
  status: "completed",
  ready: getFormattedReadyStatus(e.ready ?? ""),
  readyReason: parseReadyReason(e),
  readyMessage: parseReadyMessage(e),
  diskPressure: getFormattedPressure(e.diskPressure),
  memoryPressure: getFormattedPressure(e.memoryPressure),
  lastHeartbeatTime: e.lastHeartbeatTime ?? "",
  cpuCapacity: getFormmatedCpu(e),
  isDeleted: e.isDeleted ?? false,
  action: e.action as NodeEventAction,
  nodeCreationTime: parseISO(e.nodeCreationTime ?? ""),
  region: e.region ?? "",
  zone: e.zone ?? "",
  instanceType: e.instanceType ?? "",
  allocationType: e.allocationType ?? "",
  os: e.os ?? "",
  cloudProvider: e.cloudProvider ?? "",
  terminationReason: getTerminationReason(e) ?? "",
});

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeChangeEventGroup implements EventGroup {
  readonly backgroundColor;
  readonly fillColor;
  readonly icon = EventNodeShape;

  readonly events: NodeChangeEvent[];
  readonly nodeName;
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly status;
  readonly serviceId;
  readonly clusterName;
  readonly sendEventWhenClicked = true;
  readonly isCompleted = true;

  constructor(e: NodeChangeEvent) {
    this.events = [e];
    this.nodeName = e.nodeName;
    this.id = `${e.serviceId}:${e.id}`;
    this.type = NodeEventGroupTypes[e.action];
    this.backgroundColor = palette.blue[100];
    this.fillColor = palette.blue[500];
    if (
      e.action === NodeEventAction.Delete &&
      window.location.pathname.includes(`/${INVESTIGATION}/`)
    ) {
      this.backgroundColor = palette.orange[100];
      this.fillColor = palette.orange[600];
    }
    this.summary =
      `${e.nodeName}` + (e.action ? ` - ${e.action}d`.toLowerCase() : "");
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.status = "Completed";
    this.serviceId = e.serviceId;
    this.clusterName = e.clusterName;
  }

  get details(): string {
    const nodeEvent = this.events[0];
    if (nodeEvent.terminationReason) {
      return `Reason: ${nodeEvent.terminationReason}`;
    }
    return "";
  }

  renderEventDetails(onClose?: () => void): JSX.Element {
    return <NodeChangeDetails eventGroup={this} onClose={onClose} />;
  }

  renderTooltipContent(props: EventGroupTooltipProps): JSX.Element {
    if (this.type === NodeEventGroupTypes.Create) {
      return (
        <EventsChartTooltipComponent
          title={"Node Created"}
          data={[
            {
              key: "Creation Time",
              value: props.dateFormatter?.(this.endTime) ?? "",
            },
          ]}
        />
      );
    }
    if (this.type === NodeEventGroupTypes.Delete) {
      const terminationReason = this.events?.[0]?.terminationReason;
      const data = [];
      if (terminationReason) {
        data.push({
          key: "Termination Reason",
          value: terminationReason,
        });
      }
      data.push({
        key: "Termination Time",
        value: props.dateFormatter?.(this.endTime) ?? "",
      });
      return (
        <EventsChartTooltipComponent title={"Node Terminated"} data={data} />
      );
    }
    return <></>;
  }
}

export const groupNodeChangeEvents = (
  events: NodeEventsResponseDataInner[],
  serviceId: string
): NodeChangeEventGroup[] => {
  return mapAndSort(withServices(events, serviceId), toNodeChangeEvent).map(
    (e) => new NodeChangeEventGroup(e)
  );
};
