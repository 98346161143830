import { ReactElement } from "react";
import { Margin } from "recharts/types/util/types";

export type GraphValuesRange = {
  to: number;
  from: number;
};

export enum XAxisDataType {
  number = "number",
  category = "category",
}

export type XAxisConfig = {
  dataKey: string;
  tickComponent?: ReactElement;
  ticks?: number[];
  range?: Partial<GraphValuesRange>;
  dataType: XAxisDataType;
};

export type YAxisConfig = {
  dataKey: string;
  label?: string;
  dynamicWidth?: boolean;
  color?: string;
};

export type GraphConfig = {
  shouldUseTimeTooltipHeader?: boolean;
};

export type ChartBarConfig = GraphConfig & {
  stackBarsData?: {
    values: object[];
  };
};

export type GraphDataGroup = {
  name: string;
  color: string;
  values: object[];
  useSecondaryYAxis?: boolean;
  customDataKey?: string;
};

export enum ReferenceAxis {
  x = "x",
  y = "y",
}

export type ReferenceData = {
  axis: ReferenceAxis;
  value: string | number;
  color?: string;
  label: string | ReactElement<SVGElement>;
  margin?: Margin;
};
