import { CM } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";
import { KubernetesResource } from "./KubernetesResource";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/SupportedResourcesKinds";

export const KubernetesConfigMapResource: KubernetesResource = {
  NameInK8S: "configmaps",
  Group: "configuration",
  NavBarName: "ConfigMaps",
  Namespaced: true,
  UnnecessaryColumns: ["Data"],
  PresentName: "Config Maps",
  SupportedActions: [AvailableActions.Edit, AvailableActions.Delete],
  Kind: SupportedResourcesKinds.KubernetesConfigMapResource,
  Icon: CM,
  Headers: [{ name: "Name" }, { name: "Age" }, { name: "Namespace" }],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  hasLabels: true,
};
