import { AppNotificationsStore } from "@/shared/store/appNotificationsStore/appNotificationsStore";

// getters
export const selectAppNotifications = (state: AppNotificationsStore) =>
  state.notifications;

// setters
export const selectAddNotification = (state: AppNotificationsStore) =>
  state.addNotification;
export const selectUpdateNotification = (state: AppNotificationsStore) =>
  state.updateNotification;
export const selectRemoveNotification = (state: AppNotificationsStore) =>
  state.removeNotification;
