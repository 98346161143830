import { DS } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesDaemonSetResource = {
  NameInK8S: "daemonsets",
  Group: "workloads",
  NavBarName: "DaemonSet",
  PresentName: "Daemon Set",
  Namespaced: true,
  ShowStatusFilter: false,
  UnnecessaryColumns: ["id", "deletedAt"],
  SupportedActions: [AvailableActions.Edit, AvailableActions.Delete],
  Headers: [
    { name: "Name" },
    { name: "Health" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Ready" },
    { name: "Images" },
    { name: "Current" },
    { name: "Desired" },
    { name: "Selector" },
    { name: "Up To Date", hide: true },
    { name: "Available", hide: true },
    { name: "Containers" },
  ],
  DefaultSort: {
    ColumnName: "Health",
    Direction: Direction.down,
  },
  Kind: SupportedResourcesKinds.KubernetesDaemonSetResource,
  Icon: DS,
  ShouldBackgroundTrigger: true,
  hasLabels: true,
};
