import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import { useEffect, useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { usePastWeekEpochs } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/usePastWeekEpochs";
import {
  useNodeTerminationMetricsQuery,
  ValidNodeTerminationIntervals,
} from "@/shared/hooks/metrics-api/client/useNodeTerminationMetricsQuery";
import { LinesGraph } from "@/components/Metrics/graphs/LinesGraph";
import { unixTimeToMilliseconds } from "@/shared/utils/timeUtils";
import {
  calculateTimeXAxis,
  populateMissingTimePoint,
} from "@/components/Metrics/graphs/timeGraphUtils";
import { ErrorBanner } from "@/components/common/Error/ErrorBanner";
import { TerminationReason } from "@/components/common/EventGroup/nodeEvent/NodeChangeGroup";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { GraphDataGroup } from "@/components/Metrics/graphs/graphTypes";

type TerminationImpactGraphContainerProps = {
  clusterName: string;
};

const GraphContainer = styled.div`
  margin-top: 8px;
  height: 15rem;
`;

const GraphCard = styled(Card)`
  padding: 8px 16px 12px;
`;

export const LOADING_ERROR_MESSAGE =
  "We were unable to get the node termination data";

export const TerminationImpactGraphContainer: React.FC<
  TerminationImpactGraphContainerProps
> = ({ clusterName }) => {
  const { fromEpoch, toEpoch } = usePastWeekEpochs();

  const { data, error, refetch } = useNodeTerminationMetricsQuery(
    {
      fromEpoch,
      toEpoch,
      binSeconds: ValidNodeTerminationIntervals.Hour,
      clusterNames: [clusterName],
      terminationReason: [TerminationReason.Autoscaling],
    },
    { enabled: true }
  );

  const convertedData = useMemo(() => {
    const mappedData = {
      affectedJobs: (data?.affectedJobs ?? []).map((job) => ({
        time: unixTimeToMilliseconds(job.timestampMs),
        value: job.value,
      })),
      affectedWorkflows: (data?.affectedWorkflows ?? []).map((workflow) => ({
        time: unixTimeToMilliseconds(workflow.timestampMs),
        value: workflow.value,
      })),
      scaleDowns: (data?.terminations ?? []).map((termination) => ({
        time: unixTimeToMilliseconds(termination.timestampMs),
        value: termination.value,
      })),
      availabilityIssues: (data?.relatedAvailabilityIssues ?? []).map(
        (issue) => ({
          time: unixTimeToMilliseconds(issue.timestampMs),
          value: issue.value,
        })
      ),
    };
    const populatedData = populateMissingTimePoint(
      [
        mappedData.affectedJobs,
        mappedData.affectedWorkflows,
        mappedData.scaleDowns,
        mappedData.availabilityIssues,
      ],
      0
    );
    return {
      affectedJobs: mappedData.affectedJobs.length > 0 ? populatedData[0] : [],
      affectedWorkflows:
        mappedData.affectedWorkflows.length > 0 ? populatedData[1] : [],
      scaleDowns: mappedData.scaleDowns.length > 0 ? populatedData[2] : [],
      availabilityIssues:
        mappedData.availabilityIssues.length > 0 ? populatedData[3] : [],
    };
  }, [data]);

  const timeAxisConfig = useMemo(() => {
    return calculateTimeXAxis(
      Object.values(convertedData).find(
        (dataPoints) => dataPoints.length > 0
      ) ?? []
    );
  }, [convertedData]);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(() => {
    reportLoadingState("terminationImpactGraph", !data);
  });

  if (error) {
    return (
      <ErrorBanner onRetryClicked={refetch} text={LOADING_ERROR_MESSAGE} />
    );
  }

  if (!data) {
    return <LinesLoader />;
  }

  const linesData: GraphDataGroup[] = [
    {
      values: convertedData.affectedJobs,
      name: "Affected jobs",
      color: muiColors.deepPurple[300],
    },
    {
      values: convertedData.affectedWorkflows,
      name: "Affected workflows",
      color: muiColors.orange[600],
    },
    {
      values: convertedData.scaleDowns,
      name: "Scale down",
      color: muiColors.indigo[500],
    },
    {
      values: convertedData.availabilityIssues,
      name: "Availability issues",
      color: "red",
    },
  ].filter((line) => line.values.length > 0);

  return (
    <GraphCard variant="outlined">
      <Typography variant="h5">Scale down impact</Typography>
      <GraphContainer>
        <LinesGraph
          xAxisConfig={timeAxisConfig}
          yAxisConfig={{ dataKey: "value", dynamicWidth: true }}
          data={linesData}
        />
      </GraphContainer>
    </GraphCard>
  );
};
