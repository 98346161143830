import React, { createContext, PropsWithChildren } from "react";

import { Addon, Entity } from "@/generated/addonsApi";

export type LiveStateDrawerContextState = {
  open: boolean;
  onClose: () => void;
  addonType: Addon;
  addonEntity: Entity;
  uid: string;
  name: string;
  clusterName: string;
};

const initialState: LiveStateDrawerContextState = {
  open: false,
  onClose: () => undefined,
  addonType: Addon.CertManager,
  addonEntity: Entity.Controller,
  uid: "",
  name: "",
  clusterName: "",
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const LiveStateDrawerContext =
  createContext<LiveStateDrawerContextState>(initialState);

type LiveStateDrawerContextProps =
  PropsWithChildren<LiveStateDrawerContextState>;

export const LiveStateDrawerContextProvider: React.FC<
  LiveStateDrawerContextProps
> = ({ children, ...props }) => {
  const contextState: LiveStateDrawerContextState = {
    ...props,
  };

  return (
    <LiveStateDrawerContext.Provider value={contextState}>
      {children}
    </LiveStateDrawerContext.Provider>
  );
};
