import React from "react";

type CommandContextValue = {
  selectedValue: string | undefined;
  setSelectedValue: (value: string) => void;
  select: (value: string) => void;
};
export const CommandContext = React.createContext<CommandContextValue>({
  selectedValue: undefined,
  setSelectedValue: () => {
    return;
  },
  select: () => {
    return;
  },
});
