import React, { useRef, useState } from "react";
import { theme } from "@komodorio/design-system";
import { Button, Input, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { useCreateApiKey } from "../../../../shared/hooks/auth-service/client/apiKeys/useCreateApiKey";

import { NavigateToApiKeys } from "./NavigateToApiKeys";
import { MediumBoldTypography } from "./MediumBoldTypography";
import { ApiKeyGenerator } from "./ApiKeyGenerator";

const NO_DESCRIPTION_ERROR_TEXT = "Please enter an API description";

const StyledContent = styled.div`
  background-color: ${theme.background.bgGrayLight};
  padding: 1rem;
  border-radius: 4px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  & > div:first-child {
    flex: 1;
  }
`;

const VerticalPaddedDiv = styled.div<{ padding: number }>`
  padding: ${({ padding }) => padding}px 0;
`;

export const CustomEventsForm: React.FC = () => {
  const [description, setDescription] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [generatedApiKey, setGeneratedApiId] = useState<string>("");
  const [enableGenerateButton, setEnableGenerateButton] =
    useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutateAsync: addApiKey, isLoading: addApiKeyIsLoading } =
    useCreateApiKey();

  const shouldEnableButton = !addApiKeyIsLoading || enableGenerateButton;

  const onSetDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    setErrorText("");
    setEnableGenerateButton(true);
  };

  const onGenerateApiKeyClick = async () => {
    if (!description) {
      setErrorText(NO_DESCRIPTION_ERROR_TEXT);
      inputRef.current?.focus();
      return;
    }
    let result;
    try {
      result = await addApiKey({ name: description });
    } catch (e) {
      setErrorText("There was a problem generating the API key.");
      return;
    }
    if (!result.key) {
      setErrorText("There was a problem generating the API key.");
      return;
    }
    setGeneratedApiId(result.key);
    setEnableGenerateButton(false);
  };

  return (
    <StyledContent>
      <Typography variant={"text"} size={"medium"}>
        The API key will have the same access permissions as your user account.
      </Typography>
      <VerticalPaddedDiv padding={16}>
        <MediumBoldTypography>Enter API description</MediumBoldTypography>
        <VerticalPaddedDiv padding={4}>
          <Input
            size="medium"
            placeholder="For example: custom events api"
            value={description}
            onChange={onSetDescription}
            errorMessage={errorText || undefined}
            ref={inputRef}
          />
        </VerticalPaddedDiv>
      </VerticalPaddedDiv>
      <FlexContainer>
        <div>
          <VerticalPaddedDiv padding={4}>
            <MediumBoldTypography>API key</MediumBoldTypography>
          </VerticalPaddedDiv>
          <ApiKeyGenerator generatedApiKey={generatedApiKey} />
        </div>
        <Button
          variant={"primary"}
          onClick={onGenerateApiKeyClick}
          disabled={!shouldEnableButton}
        >
          Generate
        </Button>
      </FlexContainer>
      <NavigateToApiKeys />
    </StyledContent>
  );
};
