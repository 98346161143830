import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ChatMessageType,
  LightTooltip,
} from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Replay from "@mui/icons-material/Replay";

import { RichSessionData } from "@/components/AiInvestigation/useEnrichedSession";
import { useGetMessagesByRequestId } from "@/shared/hooks/ai-api/client/chat/useGetMessage";
import { usePostMessage } from "@/shared/hooks/ai-api/client/chat/usePostMessage";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { parseMessages } from "@/components/AiInvestigation/AiInvestigationChat/parseMessages";
import { KLAUDIA_CHAT_SESSION_ID } from "@/shared/config/urlSearchParamsKeys";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import { useGetChatSessionById } from "@/shared/hooks/ai-api/client/chat/useGetChatSessionById";
import { useAiInvestigationStore } from "@/shared/store/aiInvestigationStore/aiInvestigationStore";
import { setChatSessionIdToShareSelector } from "@/shared/store/aiInvestigationStore/aiInvestigationSelectors";
import { CHAT_RESPONSE_POLL_INTERVAL } from "@/components/AiInvestigation/AiInvestigationChat/constants";

export const useAiInvestigationChat = ({
  cluster,
  richSession,
}: {
  cluster: string;
  richSession: RichSessionData;
}) => {
  const [input, setInput] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [userMessagePlaceholder, setUserMessagePlaceholder] =
    useState<ChatMessageType>();
  const [isChatReset, setIsChatReset] = useState(false);
  const [sharedChatSessionId] = useDrawerUrlState<string>(
    KLAUDIA_CHAT_SESSION_ID
  );
  const setChatSessionIdToShare = useAiInvestigationStore(
    setChatSessionIdToShareSelector
  );

  const sharedChatSessionRes = useGetChatSessionById(
    {
      id: sharedChatSessionId ?? "",
    },
    {
      enabled: !!sharedChatSessionId && initialLoad,
    }
  );
  const chatSessionRes = useGetMessagesByRequestId(
    {
      requestId: richSession.id,
      cluster,
    },
    {
      enabled: (!sharedChatSessionId && initialLoad) || waitingForResponse,
      refetchInterval: CHAT_RESPONSE_POLL_INTERVAL,
      keepPreviousData: !isChatReset,
    }
  );
  const {
    data,
    isError,
    remove: resetQuery,
  } = chatSessionRes.data ? chatSessionRes : sharedChatSessionRes;

  const chatSessionId = data?.chatData?.id;
  const { mutateAsync: postMessage } = usePostMessage({
    chatInput: {
      requestId: richSession.id,
      cluster,
      parentSessionId: richSession.id,
      userMessage: "",
    },
    sessionId: isChatReset ? undefined : chatSessionId,
  });

  useEffect(() => {
    if (data?.chatData?.isComplete) {
      setWaitingForResponse(false);
      setUserMessagePlaceholder(undefined);
      setIsChatReset(false);
    }
  }, [data?.chatData]);

  useEffect(() => {
    if (initialLoad && (data || isError)) {
      setInitialLoad(false);
    }
  }, [data, initialLoad, isError]);

  useEffect(() => {
    if (chatSessionId) {
      setChatSessionIdToShare(chatSessionId);
    }
  }, [chatSessionId, setChatSessionIdToShare]);

  const [messages, isGroupingMessages] = useMemo(() => {
    const { parsedMessages: messagesFromServer, isGrouping } = parseMessages(
      data?.chatData?.chatMessages ?? []
    );
    if (
      userMessagePlaceholder &&
      messagesFromServer.length <= Number(userMessagePlaceholder.id)
    ) {
      messagesFromServer.push(userMessagePlaceholder);
    }
    return [messagesFromServer, isGrouping];
  }, [data?.chatData?.chatMessages, userMessagePlaceholder]);

  const handleSendMessage = useCallback(async () => {
    if (!input.trim()) return;
    setUserMessagePlaceholder({
      id: messages.length,
      content: input,
      isUser: true,
    });
    dispatchEvent(AnalyticEvents.KlaudiaAi.Chat.MessageSent, { input });
    setInput("");
    try {
      await postMessage(input);
    } catch (e) {
      setUserMessagePlaceholder({
        id: messages.length,
        content: (
          <Box
            display="inherit"
            alignItems="inherit"
            columnGap="inherit"
            paddingY="4px"
          >
            {input}
            <LightTooltip placement="top" title="Retry">
              <IconButton
                size="small"
                onClick={handleSendMessage}
                aria-label={AriaLabels.KlaudiaChat.ChatRetryButton}
              >
                <Replay color="action" fontSize="small" />
              </IconButton>
            </LightTooltip>
          </Box>
        ),
        isUser: true,
        isFailed: true,
      });
      return;
    }
    setWaitingForResponse(true);
  }, [input, messages.length, postMessage]);

  const isResponseFailed =
    isError || data?.chatData?.isFailed || data?.chatData?.isStuck;

  const shouldDisableSend =
    !initialLoad &&
    (isResponseFailed ||
      waitingForResponse ||
      userMessagePlaceholder !== undefined);

  const isChatAllowed = data?.chatData?.isOwnedByUser !== false;

  const resetChat = useCallback(() => {
    setWaitingForResponse(false);
    setUserMessagePlaceholder(undefined);
    setChatSessionIdToShare(undefined);
    setIsChatReset(true);
    resetQuery();
  }, [resetQuery, setChatSessionIdToShare]);

  return {
    messages,
    handleSendMessage,
    waitingForResponse,
    isGroupingMessages,
    isResponseFailed,
    chatSessionId,
    isChatAllowed,
    input,
    setInput,
    resetChat,
    shouldDisableSend,
    shouldAutoScroll: true,
  };
};
