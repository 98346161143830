/* eslint-disable no-restricted-globals */
import { Addon, Entity, Operator } from "@/generated/addonsApi";
import { FilterCategory } from "@/shared/hooks/filtersDataInUrl/filtersStateHandlerTypes";
import {
  K8S_ADD_ONS_FILTERS_PARAM_KEY,
  K8S_ADD_ONS_FILTERS_QS_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { prefix } from "@/shared/utils/localStorageSettings";

export const filterCategoryToGenericFilter = (
  filtersList: FilterCategory[]
) => {
  return filtersList.map((filter) => ({
    value: filter.values,
    operator: filter.operator as Operator,
    name: filter.category,
  }));
};

export const getLocalStorageKey = (addonType: Addon, addonEntity: Entity) => {
  return `${K8S_ADD_ONS_FILTERS_QS_KEY}_${addonType}_${addonEntity}`;
};

export const getLocalStorageItem = (addonType: Addon, addonEntity: Entity) => {
  const localStorageKey = getLocalStorageKey(addonType, addonEntity);
  return localStorage.getItem(`${prefix}${localStorageKey}`);
};

export const getAllK8sAddonsLocalStorageItemKeys = () => {
  return Object.keys(localStorage).filter((key) =>
    key.includes(K8S_ADD_ONS_FILTERS_QS_KEY)
  );
};

export const getLocalStorageItemAsJSON = <T extends object>(
  addonType: Addon,
  addonEntity: Entity
) => {
  const val = getLocalStorageItem(addonType, addonEntity);
  if (!val) return null;
  const urlParams = new URLSearchParams(val).get(K8S_ADD_ONS_FILTERS_PARAM_KEY);
  if (!urlParams) return null;
  try {
    return JSON.parse(urlParams) as T;
  } catch (e) {
    return null;
  }
};

export const onAccountSwitch = () => {
  const localStorageItemsKeys = getAllK8sAddonsLocalStorageItemKeys();
  localStorageItemsKeys.forEach((key) => localStorage.removeItem(key));
};
