import { omit, pick } from "lodash";
import { Location } from "react-router-dom";
import { Job } from "kubernetes-types/batch/v1.d";

import { Resource } from "@/components/ResourceView/resources";
import Node from "@/components/ResourceView/resources/node";

export const OP_ERROR_NO_EFFECT = "no change";
export const OP_ERROR_INVALID = "is invalid";

export const extractPathFromLocation = (location: Location): string => {
  return location.pathname.match(/\/main\/([a-zA-Z-_]+)/)?.[1] ?? "";
};

export const isFaultyResultMessage = (result: string): boolean => {
  const errorRegex = /\berror\b(?=[:\s]|$)/i;
  return errorRegex.test(result);
};

const hasChangeWithNoEffect = (result: string): boolean => {
  return result.includes(OP_ERROR_NO_EFFECT);
};

const didFailToEditResource = (result: string): boolean => {
  return hasChangeWithNoEffect(result) || result.includes(OP_ERROR_INVALID);
};

export const hasResourceEditFailed = (result: string): boolean => {
  if (isFaultyResultMessage(result)) {
    return true;
  }

  if (didFailToEditResource(result)) {
    return true;
  }

  return false;
};

export const getOperationErrorMessage = (result: string): string => {
  if (hasChangeWithNoEffect(result)) {
    return "Failed to apply changes";
  }

  return result;
};

export const omitFields = (jobObj: Job): Partial<Job> => {
  let o = pick(jobObj, ["kind", "spec", "metadata", "apiVersion"]);
  o = omit(o, ["spec.selector"]);
  o = omit(o, ["spec.metadata.labels.controller-uid"]);
  o = omit(o, ["spec.template.metadata.labels.controller-uid"]);
  o = omit(o, [
    "spec.template.metadata.labels['batch.kubernetes.io/controller-uid']",
  ]);
  o = omit(o, ["metadata.labels.controller-uid"]);
  o = omit(o, ["metadata.managedFields"]);
  o = omit(o, [
    "metadata.annotations['kubectl.kubernetes.io/last-applied-configuration']",
  ]);
  return o;
};

export const getIsNodeUnschedulable = (
  resource?: Resource
): boolean | undefined =>
  resource instanceof Node ? resource?.unschedulable : undefined;
