import React, { useRef } from "react";
import Typography from "@mui/material/Typography";

import { LightTooltip } from "components/komodor-ui/LightTooltip/LightTooltip";

type TruncatedCellWithTooltipProps = {
  value: string;
  typographyProps?: React.ComponentProps<typeof Typography>;
};

export const TruncatedCellWithTooltip: React.FC<
  TruncatedCellWithTooltipProps
> = ({ value, typographyProps }) => {
  const cellRef = useRef(null);
  const isTruncated =
    cellRef.current?.scrollWidth > cellRef.current?.clientWidth;
  const parsedValue = value.toString();
  return (
    <LightTooltip title={isTruncated ? parsedValue : ""} placement="top">
      <Typography ref={cellRef} variant="body2" noWrap {...typographyProps}>
        {parsedValue}
      </Typography>
    </LightTooltip>
  );
};
