import { useMemo } from "react";

import { useGetClusters } from "@/shared/hooks/workspaces-api/clusters/useGetClusters";
import { useGetNamespaces } from "@/shared/hooks/resources-api/client/useGetNamespaces";

export const useWorkspacesData = () => {
  const { data: clusters, isFetching: isFetchingClusters } = useGetClusters();
  const { data: namespaces, isFetching: isFetchingNamespaces } =
    useGetNamespaces();

  const isFetching = useMemo(() => {
    return isFetchingClusters || isFetchingNamespaces;
  }, [isFetchingClusters, isFetchingNamespaces]);

  return {
    isFetching,
    namespaces: namespaces?.data,
    clusters: clusters?.data,
  };
};
