import { useMemo, useRef } from "react";

import { useActiveAgents, useActiveAgentsByClusters } from "../useAgents";

export type ClusterData = {
  clusterName: string;
  version?: string;
  daemonSetEnabled?: boolean;
};

export const useGetAllClusters = ({ shouldUpdate = true }): ClusterData[] => {
  const activeAgents = useActiveAgents();
  const hasPopulatedClusters = useRef(false);
  const clustersRef = useRef<ClusterData[]>([]);

  return useMemo(() => {
    if (hasPopulatedClusters.current && !shouldUpdate)
      return clustersRef.current;

    const clusters = activeAgents.map<ClusterData>((agent) => {
      const agentProps = {
        version: agent.configuration.appmetadata?.version,
        daemonSetEnabled: agent.configuration.daemon?.enabled,
      };

      return {
        clusterName: agent.clusterName,
        version: agentProps?.version,
        daemonSetEnabled: agentProps?.daemonSetEnabled,
      };
    });
    hasPopulatedClusters.current = !!clusters.length;
    clustersRef.current = clusters;
    return clustersRef.current;
  }, [activeAgents, shouldUpdate]);
};

export const useGetClusterNames = (clusterOptions: ClusterData[]) =>
  useMemo(
    () => clusterOptions.map((cluster) => cluster.clusterName),
    [clusterOptions]
  );

export const useGetClustersAgentsByNames = (
  clustersNames: string[]
): (ClusterData | undefined)[] => {
  const clustersAgents = useActiveAgentsByClusters(clustersNames);
  return useMemo(() => {
    return clustersAgents.map<ClusterData | undefined>((agent) => {
      if (!agent) {
        return undefined;
      }
      const agentProps = {
        version: agent.configuration.appmetadata?.version,
        daemonSetEnabled: agent.configuration.daemon?.enabled,
      };

      return {
        clusterName: agent.clusterName,
        version: agentProps?.version,
        daemonSetEnabled: agentProps?.daemonSetEnabled,
      };
    });
  }, [clustersAgents]);
};
