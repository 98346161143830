import React from "react";
import styled from "styled-components";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  useLoadingColumns,
  useTableColumns,
} from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/hooks/nodeAutoscalerTableHooks";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import {
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useOpenAutoscalerDrawer } from "@/components/k8sAddons/addons/NodeAutoscalers/useOpenAutoscalerDrawer";
import { instanceOfAutoScaler } from "@/generated/addonsApi";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { AutoscalerRowClickParams } from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersTypes";

const TopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const NodeAutoscalersTable: React.FC = () => {
  const response = useFetchLiveStateList({});

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useLoadingColumns(),
      dataStateColumns: useTableColumns(),
      tableTitle: "Autoscalers",
    });

  const commonLiveStateTableProps =
    useCommonLiveStateTableProps(isFetchingData);

  const ariaLabel = isFetchingData
    ? `${nodeAutoscalersPageAriaLabels.table} loading`
    : `${nodeAutoscalersPageAriaLabels.table} content`;

  const openAutoscalerDrawer = useOpenAutoscalerDrawer();
  return (
    <PageTableContainer>
      <TopSection>
        <Typography variant="h4">{title}</Typography>
      </TopSection>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...commonLiveStateTableProps}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        getRowId={(row) => row.id}
        onRowClick={(row) => {
          const { row: data } = row;
          if (!instanceOfAutoScaler(data)) {
            return;
          }

          dispatchEvent<AutoscalerRowClickParams>(
            AnalyticEvents.NodeAutoscalers.RowClicked,
            {
              autoscalerName: data.name,
              autoscalerType: data.autoscalerType,
              cluster: data.clusterName,
              cpuAllocation: data.cpuResourceData.allocated,
              cpuCapacity: data.cpuResourceData.capacity,
              memoryAllocation: data.memoryResourceData.allocated,
              memoryCapacity: data.memoryResourceData.capacity,
              numOfNodes: data.numberOfNodes,
              numOfPendingPods: data.numberOfPendingPods,
              numOfRisks: data.numberOfRisks,
            }
          );
          openAutoscalerDrawer({
            cluster: data.clusterName,
            name: data.name,
          });
        }}
      />
    </PageTableContainer>
  );
};
