import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import pluralize from "pluralize";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";

import { WorkspaceActionsButton } from "@/components/workspaces/WorkspaceActionsButton/WorkspaceActionsButton";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { WorkspaceKind } from "@/generated/workspacesApi";

const MAX_TOOLTIP_VALUES = 5;
const { Description } = AriaLabels.WorkspaceOverview.Header;

const Container = styled.div`
  display: flex;
  background-color: ${muiColors.common.white};
  padding: 16px 24px;
  justify-content: space-between;
`;

const getWorkspaceValues = (workspace: TypedWorkspace) => {
  switch (workspace.kind) {
    case WorkspaceKind.Namespace:
      return { name: "Namespace", values: workspace.value.namespaces };
    case WorkspaceKind.Label:
      return { name: "Label", values: workspace.value.labels };
    case WorkspaceKind.ServiceId:
      return { name: "Service", values: workspace.value.serviceIds };
  }
  return { name: "", values: [] };
};

const Values: React.FC<{ name: string; values: string[] }> = ({
  name,
  values,
}) => {
  if (!name || values.length === 0) {
    return null;
  }

  const [firstValues, restValues] = [
    values.slice(0, MAX_TOOLTIP_VALUES),
    values.slice(MAX_TOOLTIP_VALUES),
  ];
  const tooltipContent = (
    <Stack>
      {firstValues.map((value) => (
        <Typography variant="body3" key={value}>
          {value}
        </Typography>
      ))}
      {restValues.length > 0 && (
        <Typography variant="body3">+{restValues.length}</Typography>
      )}
    </Stack>
  );

  return (
    <LightTooltip title={tooltipContent} placement="bottom">
      <Typography
        data-e2e-selector={"workspaces-header-values"}
        variant="h4"
        color="text.secondary"
      >
        ({values.length} {pluralize(name, values.length)})
      </Typography>
    </LightTooltip>
  );
};

export const OverviewHeader: React.FC<{
  workspace: TypedWorkspace;
}> = ({ workspace }) => {
  const { canManageWorkspaces } = useHasPermissions();

  const { name, values } = getWorkspaceValues(workspace);

  return (
    <Container>
      <Stack gap="8px">
        <Box display="flex" gap="8px" alignItems="baseline">
          <Typography variant="h3">{workspace.name}</Typography>
          <Values values={values} name={name} />
        </Box>
        {workspace.description && (
          <Typography variant="body3" aria-label={Description}>
            {workspace.description}
          </Typography>
        )}
      </Stack>
      <Box display="flex" alignItems="center" gap="8px">
        {canManageWorkspaces ? (
          <WorkspaceActionsButton kind="workspace" />
        ) : null}
      </Box>
    </Container>
  );
};
