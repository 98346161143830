import {
  DatadogContext,
  DatadogViewNamesEnum,
} from "@/shared/types/datadogReporting";
import { ResourceTab } from "@/components/ResourceView/types";

export const ResourceTabToViewNameMap: Record<
  ResourceTab,
  DatadogViewNamesEnum
> = {
  [ResourceTab.Events]: DatadogViewNamesEnum.eventsTab,
  [ResourceTab.Details]: DatadogViewNamesEnum.detailsTab,
  [ResourceTab.PodDetails]: DatadogViewNamesEnum.podDetailsTab,
  [ResourceTab.Pods]: DatadogViewNamesEnum.podsTab,
  [ResourceTab.Nodes]: DatadogViewNamesEnum.nodesTab,
  [ResourceTab.Info]: DatadogViewNamesEnum.infoTab,
  [ResourceTab.PodLogs]: DatadogViewNamesEnum.podLogsTab,
  [ResourceTab.ServicePodLogs]: DatadogViewNamesEnum.podLogsTab,
  [ResourceTab.Yaml]: DatadogViewNamesEnum.yamlTab,
  [ResourceTab.WorkloadMetrics]: DatadogViewNamesEnum.workloadMetricsTab,
  [ResourceTab.NodeMetrics]: DatadogViewNamesEnum.nodeMetricsTab,
  [ResourceTab.PodMetrics]: DatadogViewNamesEnum.podMetricsTab,
  [ResourceTab.ExternalDnsSummaryTab]:
    DatadogViewNamesEnum.externalDnsDrawerSummeryTab,
  [ResourceTab.AutoscalerTab]: DatadogViewNamesEnum.autoScalersTab,
};

export const serviceViewResourceTabToViewNameMap: Partial<
  Record<ResourceTab, DatadogViewNamesEnum>
> = {
  [ResourceTab.Events]: DatadogViewNamesEnum.serviceViewEventsTab,
  [ResourceTab.Details]: DatadogViewNamesEnum.serviceViewEventsTab,
  [ResourceTab.PodDetails]: DatadogViewNamesEnum.serviceViewPodDetailsTab,
  [ResourceTab.Pods]: DatadogViewNamesEnum.serviceViewPodsTab,
  [ResourceTab.Nodes]: DatadogViewNamesEnum.serviceViewNodesTab,
  [ResourceTab.Info]: DatadogViewNamesEnum.serviceViewInfoTab,
  [ResourceTab.PodLogs]: DatadogViewNamesEnum.serviceViewPodsLogsTab,
  [ResourceTab.ServicePodLogs]: DatadogViewNamesEnum.serviceViewPodsLogsTab,
  [ResourceTab.Yaml]: DatadogViewNamesEnum.serviceViewYamlTab,
  [ResourceTab.WorkloadMetrics]:
    DatadogViewNamesEnum.serviceViewWorkloadMetricsTab,
  [ResourceTab.NodeMetrics]: DatadogViewNamesEnum.serviceViewNodeMetricsTab,
  [ResourceTab.PodMetrics]: DatadogViewNamesEnum.serviceViewPodMetricsTab,
  [ResourceTab.ExternalDnsSummaryTab]:
    DatadogViewNamesEnum.serviceViewExternalDnsTab,
  [ResourceTab.AutoscalerTab]: DatadogViewNamesEnum.autoScalersTab,
};

type AOR = Pick<DatadogContext, "feTeam" | "beTeam">;

export const ResourceTabToTeamAor: Record<ResourceTab, AOR> = {
  [ResourceTab.Details]: {
    feTeam: "troubleshooting",
    beTeam: "troubleshooting",
  },
  [ResourceTab.Events]: {
    feTeam: "troubleshooting",
    beTeam: ["troubleshooting", "barzelim"],
  },
  [ResourceTab.Pods]: {
    feTeam: "operate",
    beTeam: "barzelim",
  },
  [ResourceTab.Nodes]: {
    feTeam: "operate",
    beTeam: "barzelim",
  },
  [ResourceTab.Info]: {
    feTeam: "troubleshooting",
    beTeam: "barzelim",
  },
  [ResourceTab.WorkloadMetrics]: {
    feTeam: "troubleshooting",
    beTeam: "troubleshooting",
  },
  [ResourceTab.NodeMetrics]: {
    feTeam: "troubleshooting",
    beTeam: "troubleshooting",
  },
  [ResourceTab.PodMetrics]: {
    feTeam: "troubleshooting",
    beTeam: "troubleshooting",
  },
  [ResourceTab.PodLogs]: {
    feTeam: "operate",
    beTeam: "operate",
  },
  [ResourceTab.ServicePodLogs]: {
    feTeam: "operate",
    beTeam: "operate",
  },
  [ResourceTab.Yaml]: {
    feTeam: "operate",
    beTeam: "barzelim",
  },
  [ResourceTab.PodDetails]: {
    feTeam: "operate",
    beTeam: "operate",
  },
  [ResourceTab.ExternalDnsSummaryTab]: {
    feTeam: "troubleshooting",
    beTeam: "troubleshooting",
  },
  [ResourceTab.AutoscalerTab]: {
    feTeam: "operate",
    beTeam: "operate",
  },
};
