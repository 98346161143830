import React, {
  createContext,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";

import { Dictionary } from "@/shared/types/Dictionary";
import { useReportLoadingTime } from "@/shared/context/datadogReportLoadingTime/hooks/useReportLoadingTime";
import {
  DatadogReportLoadingTimeContextProviderProps,
  DatadogReportLoadingTimeContextState,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { initialState } from "@/shared/context/datadogReportLoadingTime/initialState";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const DatadogReportLoadingTimeContext =
  createContext<DatadogReportLoadingTimeContextState>(initialState);

export const DatadogReportLoadingTimeContextProvider: React.FC<
  DatadogReportLoadingTimeContextProviderProps
> = ({ children, reportLoadingTimeProps, ...rest }) => {
  const [loadingStateDic, setLoadingStateDic] = useState<Dictionary<boolean>>(
    initialState.loadingState
  );
  const containerRef = useRef<HTMLDivElement | null | undefined>(undefined);

  const reportLoadingState = useCallback(
    (componentName: string, loadingState: boolean) => {
      setLoadingStateDic((prev) => {
        if (prev[componentName] === loadingState) {
          return prev;
        }
        return {
          ...prev,
          [componentName]: loadingState,
        };
      });
    },
    []
  );

  const removeFromLoadingState = useCallback(
    (componentName: string) => {
      if (loadingStateDic[componentName]) {
        setLoadingStateDic((prev) => {
          const newState = { ...prev };
          delete newState[componentName];
          return newState;
        });
      }
    },
    [loadingStateDic]
  );

  const resetLoadingState = useCallback(() => {
    setLoadingStateDic({});
  }, []);

  const getCurrentViewOptions = useCallback(() => {
    if (rest.viewOptions) {
      return rest.viewOptions;
    }

    return rest.getCurrentViewOptions();
  }, [rest]);

  const { triggerStartView } = useReportLoadingTime({
    loadingState: loadingStateDic,
    getCurrentViewOptions,
    containerRef,
    reportLoadingTimeProps,
  });

  const onTriggerStartView = useCallback(
    (checkLoadingTime?: boolean) => {
      triggerStartView(checkLoadingTime);
    },
    [triggerStartView]
  );

  const setMainContainerDivRef = useCallback(
    (ref: RefObject<HTMLDivElement>) => {
      containerRef.current = ref.current;
    },
    []
  );

  const contextState: DatadogReportLoadingTimeContextState = {
    reportLoadingState,
    removeFromLoadingState,
    resetLoadingState,
    triggerStartView: onTriggerStartView,
    loadingState: loadingStateDic,
    getCurrentViewOptions,
    setMainContainerDivRef,
  };

  return (
    <DatadogReportLoadingTimeContext.Provider value={contextState}>
      {children}
    </DatadogReportLoadingTimeContext.Provider>
  );
};
