import { IconProps } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

const Tile = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.75rem;
  padding: 1.25rem;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-height: 110px;
`;

const Details = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-rows: auto 1fr;
`;

const Title = styled.div`
  color: #3d4048;
  font-weight: bold;
  font-size: 1.25rem;
`;

const IntegrationTile: React.FC<{
  logoWidth: string;
  logo: React.FC<IconProps>;
  title: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ logoWidth, logo, title, children, className }) => {
  const Icon = logo;

  return (
    <Tile className={className}>
      <Icon width={logoWidth} height={logoWidth} />
      <Details>
        <Title>{title}</Title>
        {children}
      </Details>
    </Tile>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default IntegrationTile;
