import React, { useCallback, useState } from "react";
import { RuleGroupType } from "react-querybuilder";

import { mapResolvedScope } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import { Cluster } from "@/shared/components/PreviewDefintion/types";
import { AuthzScopedPermission } from "@/generated/auth";

const RESOLVE_ERROR = "There was an error resolving the scope";

interface UsePreviewDefinition {
  query: RuleGroupType;
  resolveScope: (
    query: RuleGroupType
  ) => Promise<{ data: AuthzScopedPermission[] }>;
  initialQueryError?: string;
}

export const usePreviewDefinition = ({
  query,
  resolveScope,
  initialQueryError,
}: UsePreviewDefinition) => {
  const [showPreviewDefinition, setShowPreviewDefinition] =
    React.useState(false);
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [resolveError, setResolveError] = useState<string>(
    initialQueryError ?? ""
  );

  const handlePreviewClick = useCallback(async () => {
    let clusters = [];

    try {
      const { data } = await resolveScope(query);

      clusters = mapResolvedScope(data);
    } catch (e) {
      setResolveError(RESOLVE_ERROR);
      return;
    }

    setClusters(clusters);

    setShowPreviewDefinition(true);
  }, [query, resolveScope]);

  const closePreviewDefinition = useCallback(() => {
    setShowPreviewDefinition(false);
  }, []);

  return {
    showPreviewDefinition,
    closePreviewDefinition,
    handlePreviewClick,
    resolveError,
    clusters,
  };
};
