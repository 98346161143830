import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { DEFAULT_PAGE_SIZE, PAGINATION_SIZE_OPTIONS } from "../../DetailsUtils";
import { APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner } from "../../../../../../../../../../../../generated/reliabilityApi";

import { useGetColumns } from "./useGetColumns";

const Container = styled.div`
  && {
    .MuiDataGrid-root {
      background-color: white;
    }
    .MuiDataGrid-withBorderColor {
      border-color: ${muiColors.gray[200]};
    }
    .MuiDataGrid-columnHeader--moving {
      background-color: white;
    }
  }
`;

interface Props {
  clusterName: string | undefined;
  resources:
    | APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner[]
    | undefined;
  kind: string | undefined;
}

const getRowId = (
  row: APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner
) => {
  if (row?.name) {
    return row.name;
  }
  if (row?.namespace) {
    return row.namespace;
  }
  return "";
};

export const ResourcesTable: React.FC<Props> = ({
  resources,
  kind,
  clusterName,
}) => {
  const columns = useGetColumns(clusterName, kind);

  return (
    <Container>
      <DataGridPro
        rows={resources ?? []}
        columns={columns}
        getRowId={getRowId}
        pageSizeOptions={PAGINATION_SIZE_OPTIONS}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: DEFAULT_PAGE_SIZE,
            },
          },
        }}
        pagination
      />
    </Container>
  );
};
