import { defaultValidator } from "@/shared/components/QueryBuilder/defaultValidator";
import {
  doesntMatchWildcardOperator,
  matchesWildcardOperator,
} from "@/shared/components/QueryBuilder/operators";

export const valueField = {
  name: "value",
  label: "Value",
  disabled: true,
  operators: [matchesWildcardOperator, doesntMatchWildcardOperator],
  validator: defaultValidator,
};
