import React from "react";
import styled from "styled-components";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { DriftMetricsLoadingStateIds } from "../../consts";

import { WorkloadsContainerGraph } from "./WorkloadsContainerGraph";

import { ContainerSelector } from "@/components/ResourceView/tabs/WorkloadMetricsTab/components/MetricsPerContainer/ContainerSelector";
import {
  GraphsContainer,
  MainContainer,
} from "@/components/ResourceView/tabs/MetricsTab/styles";
import { useFetchContainersData } from "@/components/ResourceView/tabs/WorkloadMetricsTab/hooks/useFetchContainersData";
import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ServicesMissingTheSpecifiedContainerInfo } from "@/components/DriftAnalysis/Services/Metrics/graphs/ContainersGraph/ServicesMissingTheSpecifiedContainerInfo";

const Container = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type MetricsPerContainerProps = {
  baselineKomodorUid: KomodorUidParams;
  komodorUids: string[];
  syncId: string;
};

const shouldShowPerContainerGraph = (
  containers: MuiSelectionOption<string>[] | undefined
) => {
  return containers && containers.length > 1;
};

export const WorkloadsMetricsPerContainer: React.FC<
  MetricsPerContainerProps
> = ({ syncId, baselineKomodorUid, komodorUids }) => {
  const {
    wantedContainer,
    setWantedContainer,
    containers: baselineContainers,
  } = useFetchContainersData({ komodorUidParams: baselineKomodorUid });

  if (!shouldShowPerContainerGraph(baselineContainers)) {
    return null;
  }

  return (
    <Container>
      <Title>
        <Typography variant="h5" color="text.primary">
          Container metrics
        </Typography>
        <ContainerSelector
          containers={baselineContainers}
          wantedContainer={wantedContainer}
          onChange={setWantedContainer}
        />
        <ServicesMissingTheSpecifiedContainerInfo
          komodorUids={komodorUids}
          wantedContainer={wantedContainer}
        />
      </Title>
      <GraphsContainer>
        <WorkloadsContainerGraph
          type={"memory"}
          wantedContainer={wantedContainer}
          loadingStateId={
            DriftMetricsLoadingStateIds.driftWorkloadsContainersMemory
          }
          syncId={syncId}
          komodorUids={komodorUids}
          baselineKomodorUid={baselineKomodorUid}
        />
        <WorkloadsContainerGraph
          type={"cpu"}
          wantedContainer={wantedContainer}
          loadingStateId={
            DriftMetricsLoadingStateIds.driftWorkloadsContainersCPU
          }
          syncId={syncId}
          komodorUids={komodorUids}
          baselineKomodorUid={baselineKomodorUid}
        />
      </GraphsContainer>
    </Container>
  );
};
