import { useMemo } from "react";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { doesAgentVersionSupportLivePodLogs } from "../../../../shared/utils/agent/agent";

import { useActiveAgents } from "@/shared/hooks/useAgents";
import { parseAgentToAgentProperties } from "@/shared/hooks/useAgentInfo/useAgentInfo";

export const useShouldShowLogsUpgradeAgentAlert = (clusterName: string) => {
  const { useLivePodLogs } = useOverridableFlags();
  const agents = useActiveAgents();

  const isAgentVersionSupportLiveLogs = useMemo(() => {
    const agent = agents?.find((agent) => agent.clusterName === clusterName);

    return doesAgentVersionSupportLivePodLogs(
      parseAgentToAgentProperties(agent)
    );
  }, [agents, clusterName]);

  return agents && useLivePodLogs && !isAgentVersionSupportLiveLogs;
};
