import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { noop } from "lodash";

type CustomAttributes = {
  cluster?: string;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const CustomAttributesContext = createContext<{
  attributes: CustomAttributes;
  mergeCustomFlagAttributes: (attrs: CustomAttributes) => void;
}>({
  attributes: {},
  mergeCustomFlagAttributes: noop,
});

export const CustomFlagsAttributes: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [attributes, setAttributes] = useState<Record<string, unknown>>({});
  const mergeCustomFlagAttributes = useCallback((attrs: CustomAttributes) => {
    setAttributes((prev) => ({ ...prev, ...attrs }));
  }, []);
  const value = useMemo(
    () => ({
      attributes,
      mergeCustomFlagAttributes,
    }),
    [attributes, mergeCustomFlagAttributes]
  );
  return (
    <CustomAttributesContext.Provider value={value}>
      {children}
    </CustomAttributesContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useCustomFlagsAttributes = (): CustomAttributes =>
  useContext(CustomAttributesContext).attributes;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useMergeCustomFlagsAttributes = (): ((
  attrs: CustomAttributes
) => void) => useContext(CustomAttributesContext).mergeCustomFlagAttributes;
