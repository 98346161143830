import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";

import {
  CanIDoInScopeResponse,
  ApiV1AuthCanIDoAnyInScopePostRequest,
  apiV1AuthCanIDoAnyInScopePostUrl,
} from "@/generated/auth";

export const CAN_I_DO_ANY_IN_SCOPE_PATH = "/can-i-do-any-in-scope";

const canIDoAnyInScope = async (
  apiClient: AxiosInstance,
  params: ApiV1AuthCanIDoAnyInScopePostRequest
): Promise<CanIDoInScopeResponse> => {
  const { data } = await apiClient.post(
    apiV1AuthCanIDoAnyInScopePostUrl(
      {
        apiV1AuthCanIDoAnyInScopePostRequest: params,
      },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCanIDoAnyInScope = (
  params: ApiV1AuthCanIDoAnyInScopePostRequest,
  options?: { enabled: boolean; staleTime?: number }
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [CAN_I_DO_ANY_IN_SCOPE_PATH, params],
    () => canIDoAnyInScope(apiClient, params),
    options
  );
};
