import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { QuestionCircle24 } from "@komodorio/design-system/icons";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { DOCS_LINK, KOMODOR_UPDATES_LINK } from "@/shared/constants/docsLinks";
import { AriaLabels } from "@/shared/config/ariaLabels";

const MENU_ID = "help-menu";
const BUTTON_ID = "help-button";

export const HelpButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { TopBar } = AriaLabels;
  return (
    <>
      <Tooltip title="Help">
        <IconButton
          id={BUTTON_ID}
          aria-controls={open ? MENU_ID : undefined}
          aria-haspopup="true"
          aria-label={TopBar.HelpButton}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <QuestionCircle24 />
        </IconButton>
      </Tooltip>
      <Menu
        id={MENU_ID}
        MenuListProps={{
          "aria-labelledby": BUTTON_ID,
        }}
        aria-label={TopBar.HelpMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          href={DOCS_LINK}
          target="_blank"
          component="a"
          disableRipple
        >
          <Typography variant="body2">Documentation and Support</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          href={KOMODOR_UPDATES_LINK}
          target="_blank"
          component="a"
          disableRipple
          aria-label={AriaLabels.AppBar.WhatsNew}
        >
          <Typography variant="body2">What’s new?</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
