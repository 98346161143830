/* eslint-disable max-lines */
import semver from "semver";

import { AgentProperties } from "../../hooks/useAgentInfo/useAgentInfo";

// AGENT

export const REQUIRED_AGENT_VERSION_FOR_LIVE_VIEWS = "0.1.44";
export const REQUIRED_AGENT_VERSION_LIVE_PODS = "0.1.58";
export const REQUIRED_AGENT_VERSION_SERVICE_DESCRIBE = "0.1.62";
export const REQUIRED_AGENT_VERSION_NODE_LIVE_VIEW = "0.1.77";
export const REQUIRED_AGENT_VERSION_JOBS = "0.1.86";
export const REQUIRED_AGENT_VERSION_RESOURCE_LIST = "0.1.93";
export const REQUIRED_AGENT_VERSION_SUPPORTED_CUSTOM_COLUMNS = "0.1.98";
export const REQUIRED_AGENT_VERSION_RESOURCE_LIST_FROM_DB = "0.1.97";
export const REQUIRED_AGENT_VERSION_SHOW_DELETED_PODS = "0.1.97";
export const REQUIRED_AGENT_VERSION_ACTIONS = "0.1.103";
export const REQUIRED_AGENT_VERSION_METRIC_RANGE = "0.1.107";
export const REQUIRED_AGENT_VERSION_ATM = "0.1.124";
export const REQUIRED_AGENT_VERSION_ATM_LIVE_DATA = "0.1.136";
export const REQUIRED_AGENT_VERSION_POD_EXEC = "0.1.163";
export const REQUIRED_AGENT_VERSION_HELM = "0.1.158";
export const REQUIRED_AGENT_VERSION_CRD = "0.1.183";
export const REQUIRED_AGENT_VERSION_METRICS = "0.1.189";
export const REQUIRED_AGENT_PORT_FORWARDING = "0.1.190";
export const REQUIRED_AGENT_VERSION_ARGO_WORKFLOWS = "0.2.43";
export const REQUIRED_AGENT_VERSION_LIVE_POD_LOGS = "0.2.77";
export const REQUIRED_AGENT_VERSION_SECRET_REDACTION = "0.2.80";
export const REQUIRED_AGENT_VERSION_EXTERNAL_DNS_STATUS = "0.2.113";

// HELM

export const REQUIRED_HELM_VERSION_RBAC_CLUSTER_SYNC = "2.5.0";

export const doesAgentVersionSupportSecretRedaction = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }

  return semver.gte(
    agentProps.version,
    REQUIRED_AGENT_VERSION_SECRET_REDACTION
  );
};

export const doesAgentVersionSupportLivePodLogs = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_LIVE_POD_LOGS);
};

export const doesAgentVersionSupportLiveDataView = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_FOR_LIVE_VIEWS);
};

export const doesAgentVersionSupportCustomResourceInspection = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps || !agentProps.version) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_CRD);
};

export const doesAgentVersionSupportNodeLiveView = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_NODE_LIVE_VIEW);
};

export const doesAgentVersionSupportPodsLiveView = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_LIVE_PODS);
};

export const doesAgentVersionSupportServiceDescribe = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(
    agentProps.version,
    REQUIRED_AGENT_VERSION_SERVICE_DESCRIBE
  );
};

export const doesAgentVersionSupportResourceList = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_RESOURCE_LIST);
};

export const doesAgentVersionSupportCustomColumns = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  return semver.gte(
    agentProps.version,
    REQUIRED_AGENT_VERSION_SUPPORTED_CUSTOM_COLUMNS
  );
};

export const doesAgentVersionSupportMetricsRange = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_METRIC_RANGE);
};

export const doesAgentVersionSupportResourceListFromDB = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(
    agentProps.version,
    REQUIRED_AGENT_VERSION_RESOURCE_LIST_FROM_DB
  );
};

export const doesAgentVersionSupportShowDeletedPods = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }

  return semver.gte(
    agentProps.version,
    REQUIRED_AGENT_VERSION_SHOW_DELETED_PODS
  );
};

export const doesAgentVersionSupportJobs = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.resources?.job || !agentProps?.resources?.cronJob) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_JOBS);
};

export const doesAgentVersionSupportActions = (
  agentProps: AgentProperties | null,
  minimalSupportedVersion?: string,
  action?: keyof AgentProperties["actions"]
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): boolean => {
  if (!agentProps?.version) {
    return false;
  }

  if (!agentProps?.actions?.basic) {
    return false;
  }

  if (action && !agentProps?.actions[action]) {
    return false;
  }

  return semver.gte(
    agentProps.version,
    minimalSupportedVersion || REQUIRED_AGENT_VERSION_ACTIONS
  );
};

export const doesAgentVersionSupportResourceListFromATM = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecutionV2) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_ATM);
};

export const doesAgentVersionSupportResourceListFromAtmAndLiveData = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecution) {
    return false;
  }
  if (!agentProps?.enableAgentTaskExecutionV2) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_ATM_LIVE_DATA);
};

export const doesAgentVersionSupportMetrics = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  if (!agentProps?.daemon?.enabled) {
    return false;
  }

  return semver.gte(agentProps.version, REQUIRED_AGENT_VERSION_METRICS);
};

type AgentAtmSupportedParams = {
  agentId?: string;
  agentInfo: AgentProperties | null;
};
export const isAgentAtmSupported = ({
  agentInfo,
  agentId,
}: AgentAtmSupportedParams): boolean =>
  !!agentId &&
  !!agentInfo &&
  doesAgentVersionSupportResourceListFromATM(agentInfo);

export const doesAgentVersionSupportRbacClusterSync = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.helmChartVersion) {
    return false;
  }
  return semver.gte(
    agentProps.helmChartVersion,
    REQUIRED_HELM_VERSION_RBAC_CLUSTER_SYNC
  );
};

export const doesAgentVersionSupportExternalDNSStatus = (
  agentProps: AgentProperties | null
): boolean => {
  if (!agentProps?.version) {
    return false;
  }
  return semver.gte(
    agentProps.version,
    REQUIRED_AGENT_VERSION_EXTERNAL_DNS_STATUS
  );
};
