import Typography from "@mui/material/Typography";

import { TextContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import { OverProvisionedClusterSupportingData } from "@/generated/reliabilityApi";

export enum ErrorOperator {
  GreaterThan = ">",
  LessThan = "<",
}

export function getOverProvisionedClusterTitle(
  supportingData?: OverProvisionedClusterSupportingData
) {
  const {
    violatingCPUPercentage: cpuUsage,
    violatingMemoryPercentage: memoryUsage,
  } = supportingData ?? {};
  const leadingDetailsText = "by over";
  const percentageDetails: string[] = [];
  if (cpuUsage) {
    percentageDetails.push(`${cpuUsage}% CPU`);
  }
  if (memoryUsage) {
    percentageDetails.push(`${memoryUsage}% memory`);
  }
  const baseText = "The cluster is over-provisioned";
  if (percentageDetails.length === 0) {
    return baseText;
  }
  return `${baseText} ${leadingDetailsText} ${percentageDetails.join(", ")}`;
}

export const overProvisionedWhyIsThisImportantContent = (
  <TextContainer>
    Over-provisioned clusters, where capacity is significantly higher than the
    allocation, leads to wasted resources and increased infrastructure costs
    without delivering corresponding benefits. Over time, this inefficiency can
    escalate operational expenses and reduce the overall cost-effectiveness of
    the Kubernetes cluster.
  </TextContainer>
);

export const overProvisionedHowToFixDescription = (
  <TextContainer>
    <ol>
      <li>
        Set{" "}
        <Typography variant="body2" display="inline" fontWeight="700">
          consolidationPolicy: WhenUnderutilized
        </Typography>{" "}
        to remove wasteful capacity.
      </li>
      <li>
        Make sure consolidation is turned on (
        <Typography variant="body2" display="inline" fontWeight="700">
          consolidation: enabled
        </Typography>
        ) - to allow automatic removal of underutilized nodes.
      </li>
      <li>
        Use smaller, more granular node{" "}
        <Typography variant="body2" display="inline" fontWeight="700">
          instance types
        </Typography>{" "}
        to help the autoscaler right-size your cluster more effectively.
      </li>
      <li>
        Check for blocked{" "}
        <Typography variant="body2" display="inline" fontWeight="700">
          pods preventing scale-down
        </Typography>
        , for example: PDBs, DaemonSets, or workloads with strict node affinity.
      </li>
    </ol>
    This configuration will reduce costs significantly while maintaining
    performance and providing adequate headroom for spikes in resource usage.
  </TextContainer>
);

export const overProvisionedWhatHappenedContent = (
  <TextContainer>
    The CPU and memory allocation in the cluster are significantly less than the
    total nodes’ capacity
  </TextContainer>
);

export const instancesTooltipContent =
  "Average number of nodes in the last 7 days";
