import { AiInvestigationStore } from "./aiInvestigationStore";

// getters
export const chatSessionIdToShareSelector = (
  state: AiInvestigationStore
): string | undefined => state.chatSessionIdToShare;

// setters
export const setChatSessionIdToShareSelector = (
  state: AiInvestigationStore
): ((sessionId: string | undefined) => void) => state.setChatSessionIdToShare;
