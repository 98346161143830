import React, { useCallback, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ValidationError } from "yup";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import { omit } from "lodash";

import { ValidationsProvider } from "@/shared/context/ValidationsProvider";
import { RoleForm } from "@/components/Settings/Roles/RoleForm";
import { RbacRole } from "@/generated/auth";
import { roleValidationSchema } from "@/components/Settings/Roles/roleValidationSchema";
import {
  getInitialRole,
  getModalTitle,
} from "@/components/Settings/Roles/utils";
import { useCreateRbacRole } from "@/shared/hooks/auth-service/client/rbacRoles/useCreateRbacRole";
import { useUpdateRbacRole } from "@/shared/hooks/auth-service/client/rbacRoles/useUpdateRbacRole";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { Dictionary } from "@/shared/types/Dictionary";
import { ariaLabels } from "@/components/Settings/Roles/ariaLabels";

interface EditRoleModalProps {
  role?: RbacRole;
  isReadOnly: boolean;
  onClose: () => void;
  refreshRoles: () => void;
}

type Errors = Dictionary<string>;

export const EditRoleModal: React.FC<EditRoleModalProps> = ({
  role,
  isReadOnly,
  onClose,
  refreshRoles,
}) => {
  const [tempRole, setTempRole] = useState(getInitialRole(role));
  const [errors, setErrors] = useState<Errors>({});

  const { mutateAsync: addRole, isLoading: addLoading } = useCreateRbacRole();
  const { mutateAsync: editRole, isLoading: editLoading } = useUpdateRbacRole();

  const isLoading = useMemo(
    () => addLoading || editLoading,
    [addLoading, editLoading]
  );

  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

  const isEmptyRole = useMemo(() => {
    return !tempRole.name && !tempRole.rbacPolicies?.length;
  }, [tempRole]);

  const isButtonDisabled = useMemo(
    () => isLoading || isReadOnly || hasErrors || isEmptyRole,
    [isLoading, isReadOnly, hasErrors, isEmptyRole]
  );

  const validateForm = useCallback(async (roleForm: RbacRole) => {
    try {
      await roleValidationSchema.validate(roleForm, { abortEarly: false });
      return undefined;
    } catch (err) {
      const validationError = err as ValidationError;
      return validationError.inner.reduce(
        (allErrors: Errors, currentError: ValidationError) => ({
          ...allErrors,
          [currentError.path || ""]: currentError.message,
        }),
        {}
      );
    }
  }, []);

  const handleSetProperty = useCallback(
    async (property: keyof RbacRole, value: RbacRole[keyof RbacRole]) => {
      setTempRole((prev: RbacRole) => ({
        ...prev,
        [property]: value,
      }));

      setErrors((prevState) => {
        return omit(prevState, property);
      });
    },
    [setTempRole, setErrors]
  );

  const handleOnSubmit = useCallback(async () => {
    let result;
    const { name, rbacPolicies, isDefault } = tempRole;
    const rbacPolicyIds = rbacPolicies?.map((r) => r.id);

    try {
      if (role) {
        result = await editRole({
          id: role.id,
          updateRbacRoleRequest: {
            name: name.trim(),
            rbacPolicyIds,
            isDefault,
          },
        });
      } else {
        result = await addRole({
          name: name.trim(),
          rbacPolicyIds,
          isDefault,
        });
      }
    } catch (e) {
      setErrors((prevState) => ({
        ...prevState,
        general:
          "Encountered an issue while saving the role. Please retry or contact support for assistance.",
      }));
      return;
    }
    dispatchEvent(
      role ? AnalyticEvents.Settings.EditRole : AnalyticEvents.Settings.AddRole,
      {
        roleName: result.name,
        policies: result.rbacPolicies?.map((p) => p.name),
      }
    );
    refreshRoles();

    onClose();
  }, [tempRole, addRole, editRole, onClose, refreshRoles, role, setErrors]);

  const handleConfirm = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        const validationErrors = await validateForm(tempRole);
        if (validationErrors) {
          setErrors(validationErrors);
          return;
        }
        return await handleOnSubmit();
      }

      onClose();
    },
    [onClose, handleOnSubmit, tempRole, validateForm]
  );

  return (
    <ValidationsProvider>
      <ConfirmationDialog
        height="unset"
        width="360px"
        title={getModalTitle(role, isReadOnly)}
        content={
          <Stack>
            <RoleForm
              role={tempRole}
              setProperty={handleSetProperty}
              errors={errors}
              isReadOnly={isReadOnly}
            />
            {!!errors.general && (
              <Typography
                variant="body3"
                color="error"
                flexWrap="wrap"
                aria-label={ariaLabels.editModal.generalError}
              >
                {errors.general}
              </Typography>
            )}
          </Stack>
        }
        onClose={handleConfirm}
        okText={isLoading ? "Saving..." : "Save"}
        okDisabled={isButtonDisabled}
      />
    </ValidationsProvider>
  );
};
