import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AriaLabels } from "@/shared/config/ariaLabels";

export type ErrorBannerProps = {
  text: string;
  onRetryClicked: () => void;
};

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  text,
  onRetryClicked,
}) => {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      aria-label={AriaLabels.Common.ErrorBanner.Container}
    >
      <InfoOutlined scale={2} />
      <Typography fontWeight={500} fontSize="14px">
        {text}
      </Typography>
      <Button
        variant="contained"
        size="medium"
        fullWidth={false}
        onClick={onRetryClicked}
      >
        Retry
      </Button>
    </Stack>
  );
};
