import {
  QueryBuilder,
  QueryBuilderProps,
} from "@komodorio/design-system/komodor-ui";

import { useClusterField } from "@/shared/components/QueryBuilder/useClusterField";

export const ConnectedClustersQB = (
  props: QueryBuilderProps & {
    isChangeable?: boolean;
    clustersInitialOptions?: string[];
  }
) => {
  const fieldsWithCluster = useClusterField({
    props,
    isChangeable: props.isChangable,
    initialOptions: props.clustersInitialOptions,
  });
  return <QueryBuilder {...props} fields={fieldsWithCluster} />;
};
