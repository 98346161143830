import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import {
  GetBulkActionsResultsResp,
  SubmitBulkActionsRequest,
} from "@/generated/actionsApi";
import { Dictionary } from "@/shared/types/Dictionary";

export type SubmitRequestWithCb = {
  bulkActionsRequest: SubmitBulkActionsRequest;
  onRequestProcessInitCb?: () => void;
  onRequestProcessCompleteCb?: () => void;
};

export type BatchActionsState = {
  batchActionsRequest: SubmitRequestWithCb | undefined;
  batchActionsResponses: GetBulkActionsResultsResp[] | undefined;
  batchActionsProcessCompleteCbDict: Dictionary<() => void>;
  batchActionResponseToShow: GetBulkActionsResultsResp | undefined;
};

export type BatchActionsSetters = {
  setBatchActionsRequest: (
    bulkActionsRequestWithCb: SubmitRequestWithCb
  ) => void;
  clearBatchActionsRequest: () => void;
  setBatchActionsResponse: (
    bulkActionsResponse: GetBulkActionsResultsResp,
    onSuccessCb: (() => void) | undefined
  ) => void;
  updateBatchActionsResponse: (
    bulkActionsResponse: GetBulkActionsResultsResp
  ) => void;
  removeBatchActionsResponse: (bulkId: string) => void;
  setBatchResponseToShow: (bulkId: string) => void;
  clearBatchResponseToShow: () => void;
  clearBatchActionsProcessCompleteCb: (bulkId: string) => void;
};

export type BatchActionsStoreState = BatchActionsState & BatchActionsSetters;

export const initialState: BatchActionsState = {
  batchActionsRequest: undefined,
  batchActionsResponses: undefined,
  batchActionResponseToShow: undefined,
  batchActionsProcessCompleteCbDict: {},
};

export const useBatchActionsStore = create(
  immer<BatchActionsStoreState>((set) => ({
    ...initialState,
    setBatchActionsRequest: (bulkActionsRequest: SubmitRequestWithCb) => {
      set((state) => {
        state.batchActionsRequest = bulkActionsRequest;
      });
    },
    clearBatchActionsRequest: () => {
      set((state) => {
        state.batchActionsRequest = undefined;
      });
    },
    setBatchActionsResponse: (
      bulkActionsResponse: GetBulkActionsResultsResp,
      onProcessCompleteCb: (() => void) | undefined
    ) => {
      set((state) => {
        if (onProcessCompleteCb) {
          state.batchActionsProcessCompleteCbDict[bulkActionsResponse.bulkId] =
            onProcessCompleteCb;
        }
        if (!state.batchActionsResponses) {
          state.batchActionsResponses = [bulkActionsResponse];
          return;
        }
        const responseExists = state.batchActionsResponses.find(
          (response) => response.bulkId === bulkActionsResponse.bulkId
        );
        !responseExists &&
          state.batchActionsResponses.push(bulkActionsResponse);
      });
    },
    updateBatchActionsResponse: (
      bulkActionsResponse: GetBulkActionsResultsResp
    ) => {
      set((state) => {
        if (!state.batchActionsResponses) {
          return;
        }
        const responseIndex = state.batchActionsResponses.findIndex(
          (response) => response.bulkId === bulkActionsResponse.bulkId
        );
        if (responseIndex > -1) {
          state.batchActionsResponses[responseIndex] = bulkActionsResponse;
        }
      });
    },
    removeBatchActionsResponse: (bulkId: string) => {
      set((state) => {
        if (!state.batchActionsResponses) {
          return;
        }
        state.batchActionsResponses = state.batchActionsResponses.filter(
          (response) => response.bulkId !== bulkId
        );
      });
    },
    setBatchResponseToShow: (bulkId: string) => {
      set((state) => {
        const foundActionResponse = state.batchActionsResponses?.find(
          (response) => response.bulkId === bulkId
        );
        if (foundActionResponse)
          state.batchActionResponseToShow = foundActionResponse;
      });
    },
    clearBatchResponseToShow: () => {
      set((state) => {
        state.batchActionResponseToShow = undefined;
      });
    },
    clearBatchActionsProcessCompleteCb: (bulkId: string) => {
      set((state) => {
        delete state.batchActionsProcessCompleteCbDict[bulkId];
      });
    },
  }))
);

export const resetBatchActionsStore = () => {
  useBatchActionsStore.setState(initialState);
};
