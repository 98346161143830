import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import { sortBy } from "lodash";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { RBAC_ROLE } from "@/shared/config/urlSearchParamsKeys";
import { useGetRbacRoles } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";
import { RbacRole } from "@/generated/auth";
import useIsFreeTier from "@/components/Freemium/useIsFreeTier";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { EditRoleModal } from "@/components/Settings/Roles/EditRoleModal";
import { DeleteRoleModal } from "@/components/Settings/Roles/DeleteRoleModal";
import { RolesGrid } from "@/pages/organization-settings/access-management/RolesPage/Grid/RolesGrid";
import { isRoleEditable } from "@/pages/organization-settings/access-management/RolesPage/utils";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { ariaLabels } from "@/pages/organization-settings/access-management/RolesPage/ariaLabels";

export const RolesPageContent: React.FC = () => {
  const [roleSearch, setRoleSearch] = useStateInSearchParams(RBAC_ROLE);
  const { data: roles, refetch, isFetching } = useGetRbacRoles();

  const [openAddRole, setOpenAddRole] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<RbacRole>();
  const [roleToDelete, setRoleToDelete] = useState<RbacRole>();
  const isFreeTier = useIsFreeTier();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingRoles", isFetching);
  }, [isFetching, reportLoadingState]);

  const { ref, width } = usePageWidth();

  const filteredRoles = useMemo(() => {
    const lowerCaseFilter = (roleSearch || "").toLowerCase();
    return roles
      ? sortBy(
          roles.filter((r) => {
            return [r.name].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          (r) => r.name
        )
      : [];
  }, [roles, roleSearch]);

  return (
    <SettingsViewVerticalLayout title={"Roles"}>
      <Stack ref={ref} direction="row" justifyContent="space-between">
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={roleSearch || ""}
          showCloseIcon={true}
          ariaLabel="Search roles"
          onSearch={setRoleSearch}
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddRole(true)}
          aria-label={ariaLabels.addButton}
        >
          Add role
        </Button>
      </Stack>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <RolesGrid
        width={width}
        filteredRoles={filteredRoles}
        setRoleToEdit={setRoleToEdit}
        setRoleToDelete={setRoleToDelete}
        isLoading={isFetching}
      />
      {openAddRole && (
        <EditRoleModal
          isReadOnly={false}
          onClose={() => setOpenAddRole(false)}
          refreshRoles={refetch}
        />
      )}
      {!!roleToEdit && (
        <EditRoleModal
          role={roleToEdit}
          onClose={() => setRoleToEdit(undefined)}
          isReadOnly={!isRoleEditable(roleToEdit) || isFreeTier}
          refreshRoles={refetch}
        />
      )}
      {roleToDelete && !roleToDelete.isDefault && (
        <DeleteRoleModal
          onClose={() => setRoleToDelete(undefined)}
          role={roleToDelete}
          refreshRoles={refetch}
        />
      )}
    </SettingsViewVerticalLayout>
  );
};
