import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import {
  selectBatchActionResponseToShow,
  selectedBatchActionsRequestSelector,
} from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { useGetDisplayMode } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useGetDisplayMode";

export const useActionsResourceKind = () => {
  const batchActionsRequest = useBatchActionsStore(
    selectedBatchActionsRequestSelector
  );
  const currentResponse = useBatchActionsStore(selectBatchActionResponseToShow);
  const displayMode = useGetDisplayMode();
  const actionToUse =
    displayMode === "confirmation"
      ? batchActionsRequest?.bulkActionsRequest
      : currentResponse;

  return actionToUse?.actions?.[0]?.resource?.kind ?? "";
};
