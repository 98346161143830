/* eslint-disable max-lines */
import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import EventsView from "../eventsView/EventsView";
import GithubInstallation from "../integrations/installation/github/GithubInstallation";
import PagerDutyInstaller from "../integrations/installation/pager-duty/PagerDutyInstaller";
import SlackInstallation from "../integrations/installation/slack/SlackInstallation";
import {
  useIsTrialEnded,
  useUserMetadata,
} from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { PathNotFound } from "../404/PathNotFound";
import ServicesView from "../servicesView";
import AgentsView from "../AgentsView/AgentsView";
import OpsgenieInstallation from "../integrations/installation/opsgenie/OpsgenieInstallation";
import { LogoutScene } from "../Logout/logout";
import General from "../Settings/General/General";
import { Users } from "../Settings/Users/Users";
import AuditPage from "../Audit";
import { ServiceView } from "../ServiceView/ServiceView";
import { AppView } from "../appView/sections/AppView/AppView";
import JobsView from "../jobsView";
import JobView from "../jobsView/JobView";
import ApiKeys from "../Settings/ApiKeys/ApiKeys";
import { useKomodorServices } from "../../shared/hooks/useKomodorServices";
import { CREATE_FREE_ACCOUNT_ROUTE } from "../Freemium/Utils/createFreeAccount";
import {
  DESKTOP_CLUSTERS_ROUTE,
  DesktopClustersView,
} from "../DesktopClustersView/DesktopClustersView";
import isElectronContext from "../../shared/context/electron/isElectronContext";
import { ClustersSelection } from "../DesktopApp/clustersSelector";
import useUpdateAgentConnectionStatus from "../../shared/context/electron/useUpdateAgentConnectionStatus";
import { ChooseAccountNameScene } from "../Signup/ChooseAccountName/chooseAccountName";
import { ConnectionFailureEmptyStateErrorPage } from "../DesktopApp/error/ConnectionFailureEmptyStateError/ConnectionFailureEmptyStateErrorPage";
import { CostOptimizationView } from "../CostOptimizationView/CostOptimizationView";
import { InvestigationMode } from "../InvestigationMode/InvestigationMode";
import { Introduction } from "../InvestigationMode/availability/Introduction/Introduction";
import { UnhealthyPods } from "../InvestigationMode/availability/UnhealthyPods/UnhealthyPods";
import { Logs } from "../InvestigationMode/availability/Logs/Logs";
import { AvailabilityInvestigationCheckType } from "../InvestigationMode/availability/types";
import { CorrelatedDeploys } from "../InvestigationMode/availability/CorrelatedDeploys/CorrelatedDeploys";
import { NodeIssues } from "../InvestigationMode/availability/NodeIssues/NodeIssues";
import { NoisyNeighbors } from "../InvestigationMode/availability/NoisyNeighbors/NoisyNeighbors";
import { MemoryLeak } from "../InvestigationMode/availability/MemoryLeak/MemoryLeak";
import { LimitDecreased } from "../InvestigationMode/availability/LimitDecreased/LimitDecreased";
import { ReviewMemoryAllocation } from "../InvestigationMode/availability/ReviewMemoryAllocation/ReviewMemoryAllocation";
import { ExpertOverviewView } from "../ExpertOverview/ExpertOverviewView";
import { useMarketplaceActivator } from "../../shared/hooks/marketplace/marketplaceActivatorProvider";
import { ReliabilityView } from "../reliability/ReliabilityView";
import { Dependencies } from "../InvestigationMode/availability/Dependencies/Dependencies";
import { clustersDemoRoutes as clustersDemoV3Routes } from "../ClustersDemoViewV3/constants";
import { ClustersPage as DemoClustersPageV3 } from "../ClustersDemoViewV3/components/ClustersPage";
import { ClusterOverview as DemoClustersPageV3ClusterOverview } from "../ClustersDemoViewV3/components/ClusterOverview";
import { WorkflowsPage as DemoClustersPageV3WorkflowsPage } from "../ClustersDemoViewV3/components/addons/WorkflowsPage";
import { LaneExtractorPage as DemoClustersPageV3LaneExtractor } from "../ClustersDemoViewV3/components/addons/LaneExtractor";
import { IcpGeneratorPage as DemoClustersPageV3IcpGenerator } from "../ClustersDemoViewV3/components/addons/IcpGenerator";
import { ClustersView } from "../ClustersView/ClustersView";
import { Actions } from "../Settings/Actions/ActionsTab";
import { DriftAnalysisDemoView } from "../ClustersDemoViewV3/components/driftAnalysis/DriftAnalysisDemoView";
import { useWorkspaces } from "../workspaces/WorkspacesTopBar/hooks";
import { useCanViewClustersList } from "../ClustersView/ClusterOverview/permissionsHooks";
import { useAccountFeatures } from "../Settings/General/hooks/useFeatures";
import { addonsDemoRoutes } from "../ClustersDemoViewV3/components/addons/constants";
import { NodeAutoscalersView } from "../ClustersDemoViewV3/components/addons/NodeAutoscalersView";
import { ExternalDNSView } from "../ClustersDemoViewV3/components/addons/ExternalDNSView";
import { CertManagerView } from "../ClustersDemoViewV3/components/addons/CertManagerView";
import { ClusterAutoscalerView } from "../ClustersDemoViewV3/components/addons/ClusterAutoscalerView";
import { DriftAnalysisView } from "../DriftAnalysis/DriftAnalysisView";
import { WorkloadHealthPage } from "../ClustersDemoViewV3/components/WorkloadHealthPage";
import { InfraHealthPage } from "../ClustersDemoViewV3/components/InfraHealthPage";

import { WorkspaceRedirect } from "./utils";
import {
  AGENTS_ROUTE,
  AGENTS_SETTINGS_ROUTE,
  AUDIT_ROUTE,
  CLUSTERS,
  CLUSTERS_ROUTE,
  COST_OPTIMIZATION,
  CREATE_POLICY_ROUTE,
  DEMO,
  DRIFT_ANALYSIS_DEMO_ROUTE,
  DRIFT_ANALYSIS_ROUTE,
  EDIT_POLICY_ROUTE,
  EVENTS_ROUTE,
  EXPERT_OVERVIEW,
  FEATURES_ROUTE,
  HEALTH_RISKS_DRAWER_REDIRECT_ROUTE,
  INFRA_HEALTH,
  INTEGRATIONS_ROUTE,
  INVESTIGATION,
  JOB_ID_PARAM,
  JOBS,
  K8S_ADDONS_ROUTE,
  MY_PERMISSIONS_ROUTE,
  POLICIES_ROUTE,
  RELIABILITY_SETTINGS_ROUTE,
  ROLES_ROUTE,
  RUN_ID_PARAM,
  SERVICE_ID_PARAM,
  SERVICES,
  TRACKED_KEYS_ROUTE,
  USAGE_ROUTE,
  USER_API_KEYS_ROUTE,
  USER_EMAIL_SETTINGS_ROUTE,
  USER_PROFILE_ROUTE,
  USERS_ROUTE,
  WORKLOAD_HEALTH,
  WORKSPACE_MANAGEMENT_ROUTE,
  WORKSPACE_ROUTE,
} from "./routes";

import { PageLoader } from "@/shared/components/PageLoader";
import { ActivateMarketplaceAccount } from "@/shared/hooks/marketplace/ActivateMarketplaceAccount";
import { useShowNoClustersTracked } from "@/shared/context/electron/hooks/useShowNoClustersTracked";
import { useShouldShowChooseClusters } from "@/shared/context/electron/hooks/useShouldShowChooseClusters";
import { useShowConnectionFailureEmptyState } from "@/shared/context/electron/hooks/useShowConnectionFailureEmptyState";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { LocalStorageItem } from "@/shared/utils/localStorageSettings";
import { K8sAddonsView } from "@/components/k8sAddons/K8sAddonsView";
import { WhitelistTab } from "@/components/Settings/whitelistCIDR/WhitelistTab";
import { ProfilePage } from "@/pages/user-settings/ProfilePage";
import { ChooseAccountLoginScene } from "@/components/Signup/ChooseAccountLogin/chooseAccountLogin";
import { FeaturesPage } from "@/pages/organization-settings/configurations/FeaturesPage";
import { UsagePage } from "@/pages/organization-settings/account/UsagePage/UsagePage";
import { AuditPage as AuditLogPage } from "@/pages/organization-settings/account/AuditPage/AuditPage";
import { IntegrationsPage } from "@/pages/organization-settings/configurations/IntegrationsPage";
import { EmailSettings } from "@/components/Settings/email/EmailSettings";
import { AgentsPage } from "@/pages/organization-settings/account/AgentsPage/AgentsPage";
import { ArgoExternalLink } from "@/components/argoWorkflowsView/ArgoExternalLink";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";
import { HealthSettingPage } from "@/pages/organization-settings/configurations/HealthSettingPage/HealthSettingPage";
import { getInspectionViews } from "@/components/Inspection/utils/InspectionViewsRouter";
import { MyPermissionsPage } from "@/pages/user-settings/MyPermissionsPage";
import { WorkspaceManagementPage } from "@/pages/organization-settings/WorkspaceManagementPage/WorkspaceManagementPage";
import { PoliciesPage } from "@/pages/organization-settings/access-management/PoliciesPage/PoliciesPage";
import { RolesPage } from "@/pages/organization-settings/access-management/RolesPage/RolesPage";
import { TrackedKeysPage } from "@/pages/organization-settings/configurations/TrackedKeysPage/TrackedKeysPage";
import { CreatePolicyPage } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/CreatePolicy";
import { HealthRisksDrawerRedirection } from "@/components/reliability/components/pages/HealthRisksDrawerRedirection/HealthRisksDrawerRedirection";
import { PaginationProvider } from "@/shared/context/tableContext/PaginationProvider";

const MonitorsRedirect: React.FC = () => {
  const location = useLocation();
  return (
    <Navigate
      replace
      to={`${location.pathname.replace(
        "/main/workflows",
        `${RELIABILITY_SETTINGS_ROUTE}`
      )}${location.search}`}
    />
  );
};

const storedLandingPage = new LocalStorageItem("landingPage");
const WorkspaceOverviewRedirect: React.FC = () => {
  const { currentWorkspaceId } = useWorkspaces();
  const [landingPage, setLandingPage] = useStateInLocalStorage(
    storedLandingPage,
    ""
  );
  const isMissingLandingPageInLocalStorage = useMemo(
    () => landingPage === "",
    [landingPage]
  );
  const { data: canViewClustersList, isLoading: isLoadingCanViewClustersList } =
    useCanViewClustersList({
      enabled: isMissingLandingPageInLocalStorage,
    });

  useEffect(() => {
    if (!isLoadingCanViewClustersList && canViewClustersList) {
      setLandingPage(CLUSTERS);
      return;
    }

    if (
      isMissingLandingPageInLocalStorage &&
      !isLoadingCanViewClustersList &&
      !canViewClustersList
    ) {
      setLandingPage(SERVICES);
      return;
    }
  }, [
    isMissingLandingPageInLocalStorage,
    canViewClustersList,
    isLoadingCanViewClustersList,
    landingPage,
    setLandingPage,
  ]);

  if (currentWorkspaceId) {
    return (
      <Navigate
        to={`${WORKSPACE_ROUTE}/${currentWorkspaceId}${location.search}`}
        replace
      />
    );
  }

  if (isLoadingCanViewClustersList) {
    return <PageLoader />;
  }

  if (landingPage === CLUSTERS || canViewClustersList) {
    return <Navigate to={CLUSTERS_ROUTE} replace />;
  }

  return <ServicesRedirect />;
};

const ServicesRedirect: React.FC = () => {
  const { serviceId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const route = searchParams.get("tabType") === "job" ? JOBS : SERVICES;
  const id = serviceId ? `/${serviceId}` : "";

  return <Navigate to={`/${route}${id}${location.search}`} replace />;
};

export const PrivateRoutesMultipleAccounts: React.FC = () => {
  const routeLogout = "/logout";
  const routeWelcomeLoginChooseAccount = "/welcome/my-teams";

  return (
    <Routes>
      <Route path={routeLogout} element={<LogoutScene />} />
      <Route
        path={routeWelcomeLoginChooseAccount}
        element={<ChooseAccountLoginScene />}
      />
      <Route
        path="*"
        element={<Navigate to={routeWelcomeLoginChooseAccount} />}
      />
    </Routes>
  );
};

export const PrivateRoutesAnonymous: React.FC = () => {
  const routeLogout = "/logout";
  const routeWelcomeSignup = "/welcome/team";

  return (
    <Routes>
      <Route path={routeLogout} element={<LogoutScene />} />
      <Route path={routeWelcomeSignup} element={<ChooseAccountNameScene />} />
      <Route path="*" element={<Navigate to={routeWelcomeSignup} />} />
    </Routes>
  );
};

export const PrivateRoutes: React.FC = () => {
  const { accountDisabled, isKomodorAdmin } = useUserMetadata();
  const {
    showApiKeys,
    showCostOptimization,
    showExpertOverview,
    showClustersPageDemoV3,
    showClustersPage,
    showAgentsTab,
    canICheck,
    newAuditLogsPage,
  } = useOverridableFlags();
  const {
    canManageUsers,
    canViewAudit,
    canManageWorkspaces,
    canManageTrackedkeys,
  } = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const showClusters = useShouldShowChooseClusters();
  const { isFetchingWorkspacesServicesData } = useKomodorServices();
  const { isCandidateForMarketplaceActivation } = useMarketplaceActivator();

  //desktop app
  useUpdateAgentConnectionStatus(); // updates the desktop app agent connection

  const showDesktopConnectionFailure = useShowConnectionFailureEmptyState();
  const showDesktopNoClustersTracked = useShowNoClustersTracked();
  const desktopClustersProblem =
    showDesktopConnectionFailure || showDesktopNoClustersTracked;

  const isTrialEnded = useIsTrialEnded();

  if (!isKomodorAdmin && (isTrialEnded || accountDisabled)) {
    return (
      <Routes>
        <Route path="/main/services" element={<ServicesView />} />
        <Route path="*" element={<Navigate to="/main/services" />} />
      </Routes>
    );
  }

  if (isElectronContext() && showClusters) {
    return <ClustersSelection />;
  }

  if (isCandidateForMarketplaceActivation) {
    return <ActivateMarketplaceAccount />;
  }
  if (isFetchingWorkspacesServicesData) return <PageLoader />;

  return (
    <Routes>
      <>
        {!desktopClustersProblem && (
          <>
            <Route path="/main/services" element={<ServicesRedirect />} />
            <Route
              path="/main/deep-dive/:serviceId"
              element={<ServicesRedirect />}
            />
            <Route path={`/${SERVICES}`} element={<ServicesView />} />
            <Route
              path={`${SERVICES}/:${SERVICE_ID_PARAM}`}
              element={<ServiceView />}
            />
            <Route path={`/${JOBS}`} element={<JobsView />} />
            <Route path={`${JOBS}/:${JOB_ID_PARAM}`} element={<JobView />} />
            {showCostOptimization && (
              <Route
                path={COST_OPTIMIZATION}
                element={<CostOptimizationView />}
              />
            )}
            {showClustersPageDemoV3 ? (
              <Route
                path={`${DRIFT_ANALYSIS_DEMO_ROUTE}/*`}
                element={<DriftAnalysisDemoView />}
              />
            ) : (
              <Route
                path={DRIFT_ANALYSIS_ROUTE}
                element={<DriftAnalysisView />}
              />
            )}

            <Route
              path={`/${WORKLOAD_HEALTH}/*`}
              element={<ReliabilityView />}
            />
            <Route path={`/${INFRA_HEALTH}/*`} element={<ReliabilityView />} />
            <Route
              path={`/${HEALTH_RISKS_DRAWER_REDIRECT_ROUTE}/*`}
              element={<HealthRisksDrawerRedirection />}
            />

            <Route path={`${K8S_ADDONS_ROUTE}/*`} element={<K8sAddonsView />} />

            <Route
              path={"/argo-workflows/external-link"}
              element={<ArgoExternalLink />}
            />
            {showClustersPageDemoV3 && (
              <>
                <Route
                  path={`${DEMO}/${addonsDemoRoutes.nodeAutoscalers}/${addonsDemoRoutes.clusterAutoscaler}`}
                  element={<ClusterAutoscalerView />}
                />
                <Route
                  path={`${DEMO}/${addonsDemoRoutes.nodeAutoscalers}`}
                  element={<NodeAutoscalersView />}
                />
                <Route
                  path={`${DEMO}/${addonsDemoRoutes.externalDNS}`}
                  element={<ExternalDNSView />}
                />
                <Route
                  path={`${DEMO}/${addonsDemoRoutes.certManager}`}
                  element={<CertManagerView />}
                />
              </>
            )}
            {showExpertOverview && (
              <Route path={EXPERT_OVERVIEW} element={<ExpertOverviewView />} />
            )}
            <Route path={EVENTS_ROUTE} element={<EventsView />} />
          </>
        )}
        <Route path={INTEGRATIONS_ROUTE} element={<IntegrationsPage />} />
        <Route path={AGENTS_SETTINGS_ROUTE} element={<AgentsPage />} />
        {showAgentsTab && (
          <Route path={AGENTS_ROUTE} element={<AgentsView />} />
        )}
        {showClustersPage && (
          <Route path={CLUSTERS_ROUTE} element={<ClustersView />} />
        )}
        {showClustersPageDemoV3 && (
          <>
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.clusters}`}
              element={<DemoClustersPageV3 />}
            />
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.clusters}/${clustersDemoV3Routes.clusterOverview}`}
              element={<DemoClustersPageV3ClusterOverview />}
            />
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.workloadHealth}`}
              element={<WorkloadHealthPage />}
            />
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.infraHealth}`}
              element={<InfraHealthPage />}
            />
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.workflows}`}
              element={<DemoClustersPageV3WorkflowsPage />}
            />
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.workflows}/${clustersDemoV3Routes.wfLaneExtractorRegression}`}
              element={<DemoClustersPageV3LaneExtractor />}
            />
            <Route
              path={`${DEMO}/v3/${clustersDemoV3Routes.workflows}/${clustersDemoV3Routes.wfIcpMapGenerator}`}
              element={<DemoClustersPageV3IcpGenerator />}
            />
          </>
        )}
        <Route path="/main/workflows/*" element={<MonitorsRedirect />} />
        {isElectronContext() && (
          <Route
            path={DESKTOP_CLUSTERS_ROUTE}
            element={<DesktopClustersView />}
          />
        )}
        {...getInspectionViews({ desktopClustersProblem })}
        <Route
          path={`${INTEGRATIONS_ROUTE}/opsgenie`}
          element={
            <OpsgenieInstallation
              closeModalCallback={() => {
                return;
              }}
            />
          }
        />
        <Route path="/installation/github" element={<GithubInstallation />} />
        <Route
          path="/installation/pager-duty"
          element={<PagerDutyInstaller />}
        />
        <Route path="/installation/slack" element={<SlackInstallation />} />
        <Route path={USER_PROFILE_ROUTE} element={<ProfilePage />} />
        <Route path={USER_API_KEYS_ROUTE} element={<ApiKeys />} />
        <Route path={USER_EMAIL_SETTINGS_ROUTE} element={<EmailSettings />} />
        {canICheck && (
          <Route path={MY_PERMISSIONS_ROUTE} element={<MyPermissionsPage />} />
        )}

        <Route path="/settings/profile" element={<General />} />
        {canManageUsers && (
          <>
            <Route element={<PaginationProvider />}>
              <Route path={USERS_ROUTE} element={<Users />} />
              <Route path={ROLES_ROUTE} element={<RolesPage />} />
              <Route path={POLICIES_ROUTE} element={<PoliciesPage />} />
            </Route>
            <Route path={CREATE_POLICY_ROUTE} element={<CreatePolicyPage />} />
            <Route
              path={`${EDIT_POLICY_ROUTE}`}
              element={<CreatePolicyPage />}
            />
            <Route path="/settings/cidr-allowlist" element={<WhitelistTab />} />
            <Route path={FEATURES_ROUTE} element={<FeaturesPage />} />
            <Route path={USAGE_ROUTE} element={<UsagePage />} />
          </>
        )}
        {canManageUsers && accountFeatures?.rbacClusterSync && (
          <Route path="/settings/actions" element={<Actions />} />
        )}
        {showApiKeys && (
          <Route path="/settings/api-keys" element={<ApiKeys />} />
        )}

        {!newAuditLogsPage && canViewAudit && (
          <Route path={AUDIT_ROUTE} element={<AuditPage />} />
        )}
        {newAuditLogsPage && canViewAudit && (
          <Route path={AUDIT_ROUTE} element={<AuditLogPage />} />
        )}
        <Route
          path={RELIABILITY_SETTINGS_ROUTE}
          element={<HealthSettingPage />}
        />
        {canManageWorkspaces && (
          <Route
            path={WORKSPACE_MANAGEMENT_ROUTE}
            element={<WorkspaceManagementPage />}
          />
        )}
        {canManageTrackedkeys && (
          <Route path={TRACKED_KEYS_ROUTE} element={<TrackedKeysPage />} />
        )}

        <Route path={`${WORKSPACE_ROUTE}/*`} element={<AppView />} />
        <Route path="/app-view/*" element={<WorkspaceRedirect />} />

        <Route
          path={`/${INVESTIGATION}/:${RUN_ID_PARAM}`}
          element={<InvestigationMode />}
        >
          <Route
            index
            element={
              <Navigate
                to={AvailabilityInvestigationCheckType.Introduction}
                replace
              />
            }
          />
          <Route
            path={AvailabilityInvestigationCheckType.Introduction}
            element={<Introduction />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.UnhealthyPods}
            element={<UnhealthyPods />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.Logs}
            element={<Logs />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.CorrelatedDeploys}
            element={<CorrelatedDeploys />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.NodeIssues}
            element={<NodeIssues />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.NoisyNeighbors}
            element={<NoisyNeighbors />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.LimitDecreased}
            element={<LimitDecreased />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.MemoryLeak}
            element={<MemoryLeak />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.ReviewMemoryAllocation}
            element={<ReviewMemoryAllocation />}
          />
          <Route
            path={AvailabilityInvestigationCheckType.Dependencies}
            element={<Dependencies />}
          />
          <Route path="*" element={<PathNotFound />} />
        </Route>
        <Route path="/" element={<WorkspaceOverviewRedirect />} />
        <Route
          path={CREATE_FREE_ACCOUNT_ROUTE}
          element={<ServicesRedirect />}
        />
        <Route path="/welcome/*" element={<ServicesRedirect />} />
        <Route path="/logout" element={<LogoutScene />} />
        {desktopClustersProblem ? (
          <Route
            path="*"
            element={
              <ConnectionFailureEmptyStateErrorPage
                noClustersTracked={showDesktopNoClustersTracked}
              />
            }
          />
        ) : (
          <Route path="*" element={<PathNotFound />} />
        )}
      </>
    </Routes>
  );
};
