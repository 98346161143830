import { AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useWorkspacesApiClient } from "../apiClient";

import { WORKSPACES_PATH } from "./useGetWorkspaces";

import {
  apiV1WorkspacesPostUrl,
  WorkspacesApiApiV1WorkspacesPostRequest,
  WorkspacesResponse,
} from "@/generated/workspacesApi";

const createScopedWorkspace = async (
  apiClient: AxiosInstance,
  params: WorkspacesApiApiV1WorkspacesPostRequest
): Promise<WorkspacesResponse> => {
  const { data } = await apiClient.post<WorkspacesResponse>(
    apiV1WorkspacesPostUrl(params, apiClient.defaults.baseURL ?? ""),
    params.workspace
  );

  return data;
};

export const useCreateScopedWorkspace = () => {
  const queryClient = useQueryClient();
  const apiClient = useWorkspacesApiClient({
    skipWorkspaceHeader: true,
  });

  return useMutation(
    (vars: WorkspacesApiApiV1WorkspacesPostRequest) =>
      createScopedWorkspace(apiClient, vars),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORKSPACES_PATH]);
      },
    }
  );
};
