import { useQuery } from "@tanstack/react-query";

import {
  GetViolationResponse,
  getViolationUrl,
  ViolationsApiGetViolationRequest,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";

import { validateViolation } from "@/shared/hooks/reliability-api/violations/validateViolation";

export const GET_VIOLATION_DATA_PATH = "/api/v1/violations/:id";

type GetViolationDataParams = ViolationsApiGetViolationRequest & {
  enable?: boolean;
};

export const useGetViolationData = (params: GetViolationDataParams) => {
  const apiClient = useReliabilityApiClient();
  return useQuery(
    [GET_VIOLATION_DATA_PATH, params],
    async () => {
      const response = await apiClient.get<GetViolationResponse>(
        getViolationUrl(params, apiClient.defaults.baseURL ?? "")
      );
      validateViolation(response.data.violation);
      return response;
    },
    { enabled: params.enable }
  );
};
