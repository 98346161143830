import { GridColDef } from "@mui/x-data-grid-pro";
import { DataGrid } from "@komodorio/design-system/komodor-ui";

import {
  buildKomodorUid,
  KomodorUidParams,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { connectedWorkloadsTableColumns } from "@/shared/components/ConnectedWorkloadsCell/constants";

export type ConnectedWorkloadsTableProps = {
  workloadsKomodorUids: KomodorUidParams[];
  customTableProps?: {
    customColumns?: GridColDef<KomodorUidParams>[];
  };
};

export const ConnectedWorkloadsTable: React.FC<
  ConnectedWorkloadsTableProps
> = ({ workloadsKomodorUids, customTableProps }) => {
  return (
    <DataGrid
      rows={workloadsKomodorUids}
      columns={
        customTableProps?.customColumns ?? connectedWorkloadsTableColumns
      }
      density="compact"
      getRowId={(row) =>
        buildKomodorUid({
          clusterName: row.cluster,
          namespace: row.namespace,
          kind: row.kind,
          resourceName: row.name,
        })
      }
      sx={{
        "& .MuiDataGrid-footerContainer": {
          minHeight: "unset",
          height: 40,
        },
      }}
    />
  );
};
