import {
  KubernetesResource,
  SupportedResourcesKinds,
} from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const ArgoRolloutResource: KubernetesResource = {
  Kind: SupportedResourcesKinds.ArgoRolloutResource,
  NameInK8S: "argorollouts",
  Group: "workloads",
  NavBarName: "Argo Rollouts",
  PresentName: "Argo Rollouts",
  Namespaced: true,
  ShowStatusFilter: false,
  UnnecessaryColumns: ["id", "deletedAt"],
  hasLabels: true,
  isCustomResource: true,
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Health" },
    { name: "Age" },
    { name: "Available" },
    { name: "Current" },
    { name: "Desired" },
  ],
};
