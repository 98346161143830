import { object, string, ValidationError } from "yup";

export const SCOPED_WS_NAME_MAX_LENGTH = 63;
export const SCOPED_WS_DESCRIPTION_MAX_LENGTH = 256;

export const scopedWorkspaceValidationSchema = object().shape({
  name: string()
    .max(
      SCOPED_WS_NAME_MAX_LENGTH,
      `Workspace name must be shorter than ${SCOPED_WS_NAME_MAX_LENGTH} characters in length`
    )
    .required("Workspace name is required"),
  description: string()
    .max(
      SCOPED_WS_DESCRIPTION_MAX_LENGTH,
      `Workspace description must be shorter than ${SCOPED_WS_DESCRIPTION_MAX_LENGTH} characters in length`
    )
    .optional(),
  // TODO: Add query here
});

export const validateScopedWorkspaceDetails = async (
  data: Partial<{ name: string; description: string }>
): Promise<Record<string, Error>> => {
  try {
    await scopedWorkspaceValidationSchema.validate(data, {
      abortEarly: false,
    });

    return {};
  } catch (err) {
    const validationError = err as ValidationError;
    return validationError.inner.reduce(
      (allErrors, currentError) => ({
        ...allErrors,
        [currentError.path || ""]: {
          type: currentError.type ?? "validation",
          message: currentError.message,
        },
      }),
      {}
    );
  }
};
