import {
  ALL_CLUSTERS,
  NO_SELECTED_WORKSPACE_LABEL,
} from "../../workspaces/WorkspacesTopBar/constants";

import { parseKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { K8S_ADDONS_WORKFLOWS_ROUTE } from "@/components/routes/routes";
import {
  WorkflowEngine,
  WorkflowsRoutes,
} from "@/components/k8sAddons/addons/workflows/types";

export const getLabelTextByWorkspace = (isClusterWorkspace: boolean) => {
  return isClusterWorkspace ? ALL_CLUSTERS : NO_SELECTED_WORKSPACE_LABEL;
};

export const getWorkflowUrl = (komodorWorkflowId: string) => {
  const { engine } = parseKomodorWorkflowId(komodorWorkflowId);
  const tab =
    engine === WorkflowEngine.Airflow
      ? WorkflowsRoutes.airflow
      : engine === WorkflowEngine.Argo
      ? WorkflowsRoutes.argo
      : WorkflowsRoutes.custom;
  return `${K8S_ADDONS_WORKFLOWS_ROUTE}/${tab}/${komodorWorkflowId}`;
};
