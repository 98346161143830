import { useMemo } from "react";

import { calculateTimeXAxis } from "@/components/Metrics/graphs/timeGraphUtils";
import { DataPoint } from "@/components/Metrics/types";
import { unixTimeToMilliseconds } from "@/shared/utils/timeUtils";
import { XAxisConfig } from "@/components/Metrics/graphs/graphTypes";

type UseMemoryAndCPUXAxisConfigProps = {
  memoryData: DataPoint[];
  cpuData: DataPoint[];
  fromEpoch: number;
  numOfTicks?: number;
};

export function useMemoryAndCPUXAxisConfig(
  params: UseMemoryAndCPUXAxisConfigProps
): { memoryXAxisConfig: XAxisConfig; cpuXAxisConfig: XAxisConfig } {
  const { memoryData, cpuData, fromEpoch, numOfTicks } = params;
  const cpuXAxisConfig = useMemo(
    () =>
      calculateTimeXAxis(
        cpuData.map((point) => ({
          time: unixTimeToMilliseconds(point.x),
        })),
        { predefinedStartTime: unixTimeToMilliseconds(fromEpoch), numOfTicks }
      ),
    [cpuData, fromEpoch, numOfTicks]
  );
  const memoryXAxisConfig = useMemo(
    () =>
      calculateTimeXAxis(
        memoryData.map((point) => ({
          time: unixTimeToMilliseconds(point.x),
        })),
        { predefinedStartTime: unixTimeToMilliseconds(fromEpoch), numOfTicks }
      ),
    [fromEpoch, memoryData, numOfTicks]
  );

  return { memoryXAxisConfig, cpuXAxisConfig };
}
