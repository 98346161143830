import {
  GroupWithError,
  Node,
  QueryWithError,
  RuleWithError,
} from "@komodorio/design-system/komodor-ui";
import { isRuleGroupType, RuleGroupType, RuleType } from "react-querybuilder";
import { omit, startCase } from "lodash";
import { object, string, ValidationError } from "yup";

import { AuthzScopedPermission, RbacPolicyAction } from "@/generated/auth";
import {
  doesntMatchWildcardOperator,
  matchesWildcardOperator,
} from "@/shared/components/QueryBuilder/operators";
import { VIEW_ALL_ACTION } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/constants";

export const getActionsByScope = (
  actions: RbacPolicyAction[],
  scope: string
): RbacPolicyAction[] => {
  return actions
    .filter((action) => action.scopingMethods.includes(scope))
    .filter((action) => action.action !== VIEW_ALL_ACTION.id);
};

export const getStandaloneActions = (
  actions: RbacPolicyAction[]
): RbacPolicyAction[] => {
  return actions.filter((action) => !action.displayGroup);
};

export const getActionsGroups = (actions: RbacPolicyAction[]): Set<string> => {
  return new Set(
    actions
      .map((action) => action.displayGroup ?? "")
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))
  );
};

const mapLeaf = (action: RbacPolicyAction): Node => ({
  id: action.action,
  label: action.action,
  children: [],
});

const sortActions = (a: Node, b: Node): number => {
  if (a.label === b.label) {
    return 0;
  }
  if (typeof a.label !== "string" || typeof b.label !== "string") {
    return 0;
  }
  return a.label.localeCompare(b.label);
};

export const actionsToTree = (actions: RbacPolicyAction[]): Node[] => {
  const uniqueGroups = getActionsGroups(actions);
  const standaloneActions = getStandaloneActions(actions);

  return [
    ...standaloneActions.map(mapLeaf),
    ...Array.from(uniqueGroups).map((group) => {
      return {
        id: group,
        label: startCase(group),
        children: actions
          .filter((action) => action.displayGroup === group)
          .map(mapLeaf)
          .sort(sortActions),
      };
    }),
  ];
};

const ruleSchema = object({
  field: string()
    .required("Missing field")
    .oneOf(["cluster", "namespace", "key", "value"], "Invalid field"),
  value: string().required("Value cannot be empty"),
  operator: string(),
})
  .test("no-rule-group", "Rule groups are not supported", (rule) => {
    return rule && !isRuleGroupType(rule);
  })
  .test("wildcard-operator", "Value must include *", (rule) => {
    if (!rule) return false;
    const wildcardOperators = [
      matchesWildcardOperator.name,
      doesntMatchWildcardOperator.name,
    ];
    return (
      !wildcardOperators.includes(rule.operator ?? "") ||
      rule.value.includes("*")
    );
  });

const validateRule = (rule: RuleType | RuleGroupType) => {
  if (isRuleGroupType(rule)) {
    throw new Error("Rule groups are not supported");
  }

  // Use ruleSchema to validate individual rule
  try {
    ruleSchema.validateSync(rule, { abortEarly: false });
    // Success - remove any previous error
    return omit(rule, "error");
  } catch (e) {
    // Extract validation errors
    const errors =
      (e as ValidationError).inner?.length > 0
        ? (e as ValidationError).inner.map((ie: ValidationError) => ie.message)
        : [(e as ValidationError).message];

    return {
      ...rule,
      error: { valid: false, reasons: errors },
    };
  }
};

export const assignErrorsToQuery = (query: QueryWithError): QueryWithError => {
  if (!query || !Array.isArray(query.rules)) {
    return { ...query, error: "Invalid query structure" };
  }

  const validatedRules = query.rules.map((group: RuleGroupType | RuleType) => {
    if (!group || !isRuleGroupType(group)) {
      return {
        ...group,
        error: { valid: false, reasons: ["Invalid group structure"] },
      };
    }

    const validatedGroupRules = group.rules.map(validateRule);
    return { ...group, rules: validatedGroupRules };
  });

  return { ...query, rules: validatedRules };
};

export const doesQueryHaveErrors = (query: RuleGroupType | null): boolean => {
  if (!query) {
    return false;
  }

  const { rules: groups } = query;

  return groups.some((group: RuleType | RuleGroupType) => {
    if (!isRuleGroupType(group)) {
      return;
    }

    return group.rules.some((rule: RuleWithError | GroupWithError) => {
      return !isRuleGroupType(rule) && rule.error?.valid === false;
    });
  });
};

export const getDependsOnActions = (
  selectedActions: Set<string>,
  globalActions: RbacPolicyAction[]
): Set<string> => {
  if (selectedActions.size === 0) {
    return new Set<string>();
  }

  const dependsOnInScopeSet = new Set<string>();

  globalActions.forEach((action) => {
    if (selectedActions.has(action.action)) {
      action.dependsOnInScope?.forEach((dep) => dependsOnInScopeSet.add(dep));
    }
  });

  return dependsOnInScopeSet;
};

export const mapResolvedScope = (data: AuthzScopedPermission[]) => {
  return data
    .map((item) => {
      const namespaces = item.namespaces.map((ns) => ({
        id: ns,
        name: ns,
      }));

      return item.clusters.map((cluster) => ({
        id: cluster,
        name: cluster,
        namespaces,
      }));
    })
    .flat();
};

export const filterActionsBy = (
  actionsToFilter: string[],
  filterFn: (action: string) => boolean
) => {
  return actionsToFilter.filter(filterFn);
};

export const omitGroupsFromSelectedActions = (
  selectedActions: string[],
  globalActions: RbacPolicyAction[]
) => {
  return filterActionsBy(
    selectedActions,
    (selectedAction) =>
      !!globalActions.find(
        (globalAction: RbacPolicyAction) =>
          globalAction.action === selectedAction
      )
  );
};

export const areActionsEqual = (
  statementActions: string[],
  selectedActions: string[]
) => {
  return (
    statementActions.length === selectedActions.length &&
    statementActions.every((action) => selectedActions.includes(action))
  );
};

export const areQueriesEqual = (
  first: RuleType | RuleGroupType,
  second: RuleType | RuleGroupType
): boolean => {
  if (typeof first !== typeof second) {
    return false;
  }

  if (isRuleGroupType(first) && isRuleGroupType(second)) {
    return (
      first.combinator === second.combinator &&
      first.rules.length === second.rules.length &&
      first.rules.every((rule, index) =>
        areQueriesEqual(rule, second.rules[index])
      )
    );
  }

  if (!isRuleGroupType(first) && !isRuleGroupType(second)) {
    return (
      first.field === second.field &&
      first.operator === second.operator &&
      first.value === second.value
    );
  }

  return false;
};
