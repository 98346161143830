import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";
import { AxisDomain } from "recharts/types/util/types";

import {
  GraphBackgroundColor,
  GraphGridColor,
} from "@/components/Metrics/styles";
import {
  GenericLegend,
  LegendItem,
} from "@/components/Metrics/GenericLegend/GenericLegend";
import { yAxisProps } from "@/components/Metrics/graphs/graphProps";
import { sharedStraightLineProps } from "@/components/Metrics/constants";
import {
  GraphDataGroup,
  XAxisConfig,
  XAxisDataType,
  YAxisConfig,
} from "@/components/Metrics/graphs/graphTypes";
import { useDynamicYAxisWidth } from "@/components/Metrics/graphs/hooks/useDynamicYAxisWidth";
import { Y_AXIS_MINIMUM_WIDTH } from "@/components/Metrics/graphs/graphConstants";
import { GraphsAriaLabels } from "@/components/Metrics/graphs/AriaLabels";
import { TooltipItemProps } from "@/components/Metrics/types";
import { GraphDataColoredIndicator } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/constants";
import { useGraphProps } from "@/components/Metrics/hooks/useLineGraphProps";
import { getGraphTooltip } from "@/components/Metrics/graphs/getGraphTooltip";

export type LineGraphAriaLabelConfig = {
  lineLabelTemplate?: string;
};

type LinesGraphProps = {
  data: GraphDataGroup[];
  yAxisConfig: YAxisConfig;
  xAxisConfig: XAxisConfig;
  className?: string;
};

const GraphContainer = styled.div`
  height: 100%;
`;

export const LinesGraph: React.FC<LinesGraphProps> = ({
  data,
  yAxisConfig,
  xAxisConfig,
  className,
}) => {
  const calculatedYAxisProps = yAxisProps(yAxisConfig.label);
  const { containerProps, tooltipProps, chartProps } = useGraphProps();
  const { tickCalculatingFormatter, yAxisCalculatedWidth } =
    useDynamicYAxisWidth({ fontSize: calculatedYAxisProps.tick.fontSize });
  const legendItems: LegendItem[] = data.map((lineData) => ({
    label: lineData.name,
    color: lineData.color,
  }));
  const tooltipItems: TooltipItemProps[] = data.map((lineData) => ({
    dataKey: lineData.customDataKey ?? yAxisConfig.dataKey,
    title: lineData.name,
    tooltipItemKey: lineData.name,
    icon: <GraphDataColoredIndicator color={lineData.color} />,
    name: lineData.name,
  }));

  const xAxisDomain: AxisDomain | undefined =
    xAxisConfig.dataType === XAxisDataType.number
      ? [
          xAxisConfig.range?.from ?? "dataMin",
          xAxisConfig.range?.to ?? "dataMax",
        ]
      : undefined;

  return (
    <GraphContainer className={className} {...containerProps}>
      <ResponsiveContainer width="100%" height="100%">
        <AutoSizer aria-label={GraphsAriaLabels.LINE_GRAPH_CONTAINER}>
          {({ width, height }) => (
            <LineChart width={width} height={height} {...chartProps}>
              <CartesianGrid
                stroke={GraphGridColor}
                strokeWidth="1"
                fill={GraphBackgroundColor}
              />
              <YAxis
                yAxisId="left"
                allowDataOverflow={true}
                stroke={yAxisConfig.color}
                type="number"
                min={0}
                width={
                  yAxisConfig.dynamicWidth
                    ? Math.max(yAxisCalculatedWidth, Y_AXIS_MINIMUM_WIDTH)
                    : undefined
                }
                tickFormatter={tickCalculatingFormatter}
                {...calculatedYAxisProps}
              />
              <XAxis
                dataKey={xAxisConfig.dataKey}
                tick={xAxisConfig.tickComponent}
                ticks={xAxisConfig.ticks}
                interval={0}
                allowDuplicatedCategory={false}
                allowDataOverflow
                domain={xAxisDomain}
                type={xAxisConfig.dataType}
              />
              {data.map((lineData) => (
                <Line
                  {...sharedStraightLineProps}
                  dataKey={lineData.customDataKey ?? yAxisConfig.dataKey}
                  key={lineData.name}
                  data={lineData.values}
                  stroke={lineData.color}
                  name={lineData.name}
                  yAxisId="left"
                />
              ))}
              {getGraphTooltip({
                tooltipItems,
                tooltipProps,
              })}
              <Legend content={<GenericLegend items={legendItems} />} />
            </LineChart>
          )}
        </AutoSizer>
      </ResponsiveContainer>
    </GraphContainer>
  );
};
