import React, { ReactElement } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { muiColors } from "@komodorio/design-system";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Stack from "@mui/material/Stack";

import { NotificationStatus } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { appNotificationsAriaLabels } from "@/shared/components/AppNotificationsCenter/appNotificationsAriaLabels";

const {
  success: successAriaLabel,
  loading: loadingAreaLabel,
  error: ErrorArialLabel,
} = appNotificationsAriaLabels.status;

export const APP_NOTIFICATIONS_TITLE = "Ongoing Actions";

export const statusToIcon: Record<NotificationStatus, ReactElement> = {
  [NotificationStatus.Success]: (
    <CheckCircleOutlineIcon
      aria-label={successAriaLabel}
      sx={{ fontSize: "16px", color: muiColors.green[900] }}
    />
  ),
  [NotificationStatus.Loading]: (
    <Stack aria-label={loadingAreaLabel}>
      <CircularProgress size={16} />
    </Stack>
  ),
  [NotificationStatus.Error]: (
    <ErrorOutline
      sx={{ color: muiColors.red[500], fontSize: "16px" }}
      aria-label={ErrorArialLabel}
    />
  ),
};
