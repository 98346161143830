export enum ServicesComparisonTabs {
  Attributes = "AttributesComparison",
  Metrics = "MetricsComparison",
}

export const SHOW_ONLY_DRIFT_SERVICES_KEY = "showOnlyDriftServices";
export const SHOW_ONLY_DRIFT_SERVICES_DRAWER_KEY =
  "showOnlyDriftServicesDrawer";

export const MAX_COMPARED_SERVICES = 10;
export const MAX_YAML_LINES = 7;
export const MAX_CONTAINER_NAMES = 2;

export const SERVICE_DRIFT_ATTRIBUTES_COMPONENT_NAME =
  "ServiceDriftAnalysisAttributes";
