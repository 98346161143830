import React, { createContext, useCallback, useState } from "react";

import { MetricsFullTimeWindow } from "../../../../Metrics/types";

import { Timeframe } from "@/shared/types/TimeWindow";

export type NodeMetricsTabContextState = {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
};

// eslint-disable-next-line react-refresh/only-export-components
export const initialState: NodeMetricsTabContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 4)),
    end: new Date(),
    timeframe: Timeframe.Last4Hours,
  },
  setTimeWindow: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const NodeMetricsTabContext =
  createContext<NodeMetricsTabContextState>(initialState);

const { timeWindow: initialTimeWindow } = initialState;

export const NodeMetricsTabContextProvider: React.FC<{
  children: React.ReactNode;
  givenTimeWindow?: MetricsFullTimeWindow;
}> = ({ children, givenTimeWindow }) => {
  const [timeWindow, setTimeWindow] = useState<MetricsFullTimeWindow>(
    givenTimeWindow ?? initialTimeWindow
  );

  const onSetTimeWindow = useCallback(
    (tw: Partial<MetricsFullTimeWindow>) => {
      setTimeWindow({
        ...timeWindow,
        ...tw,
      });
    },
    [timeWindow]
  );

  const contextState: NodeMetricsTabContextState = {
    timeWindow: timeWindow,
    setTimeWindow: onSetTimeWindow,
  };

  return (
    <NodeMetricsTabContext.Provider value={contextState}>
      {children}
    </NodeMetricsTabContext.Provider>
  );
};
