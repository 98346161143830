import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import { useAuthApiClient } from "@/shared/hooks/auth-service/client/apiClient";
import {
  AuthzResolveScopeRequest,
  AuthzScopedPermission,
} from "@/generated/auth";

export const RESOLVE_SCOPE_PATH = "/api/v1/rbac-policies/resolve-scope";

const resolveScope = async (
  apiClient: AxiosInstance,
  params: AuthzResolveScopeRequest
): Promise<{ data: AuthzScopedPermission[] }> => {
  return apiClient.post(RESOLVE_SCOPE_PATH, params);
};

export const useResolveScope = () => {
  const apiClient = useAuthApiClient();
  return useMutation([RESOLVE_SCOPE_PATH], (params: AuthzResolveScopeRequest) =>
    resolveScope(apiClient, params)
  );
};
