import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as RechartBarChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain, Margin } from "recharts/types/util/types";

import { TooltipItemProps } from "../types";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { GraphBackgroundColor, GraphGridColor } from "../styles";

import {
  ChartBarConfig,
  GraphDataGroup,
  ReferenceData,
  XAxisConfig,
  XAxisDataType,
  YAxisConfig,
} from "@/components/Metrics/graphs/graphTypes";
import { useGraphProps } from "@/components/Metrics/hooks/useLineGraphProps";
import { Y_AXIS_MINIMUM_WIDTH } from "@/components/Metrics/graphs/graphConstants";
import { yAxisProps } from "@/components/Metrics/graphs/graphProps";
import { useDynamicYAxisWidth } from "@/components/Metrics/graphs/hooks/useDynamicYAxisWidth";
import { GraphDataColoredIndicator } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/constants";
import {
  GenericLegend,
  LegendItem,
} from "@/components/Metrics/GenericLegend/GenericLegend";
import { getGraphTooltip } from "@/components/Metrics/graphs/getGraphTooltip";

const { Graph, Yaxis, Xaxis } = AriaLabels.MetricsAvailability.BarChart;

const Container = styled.div`
  height: 100%;
`;

type BarChartProps = {
  data: GraphDataGroup[];
  xAxisConfig: XAxisConfig;
  yAxisConfig: YAxisConfig;
  secondaryYAxisConfig?: YAxisConfig;
  referenceDataConfig?: ReferenceData[];
  className?: string;
  "aria-label"?: string;
  graphConfig?: ChartBarConfig;
};

export const BarChart: React.FC<BarChartProps> = ({
  data,
  yAxisConfig,
  xAxisConfig,
  className,
  secondaryYAxisConfig,
  referenceDataConfig,
  graphConfig,
  "aria-label": ariaLabel,
}) => {
  const { shouldUseTimeTooltipHeader = false, stackBarsData } =
    graphConfig ?? {};
  const { chartProps, containerProps, tooltipProps } = useGraphProps();
  const calculatedYAxisProps = yAxisProps(yAxisConfig.label);
  const { tickCalculatingFormatter, yAxisCalculatedWidth } =
    useDynamicYAxisWidth({ fontSize: calculatedYAxisProps.tick.fontSize });
  const legendItems: LegendItem[] = data.map((lineData) => ({
    label: lineData.name,
    color: lineData.color,
  }));
  const tooltipItems: TooltipItemProps[] = data.map((barData) => ({
    dataKey: barData.customDataKey ?? yAxisConfig.dataKey,
    title: barData.name,
    tooltipItemKey: barData.name,
    icon: <GraphDataColoredIndicator color={barData.color} />,
    name: barData.name,
  }));

  const xAxisDomain: AxisDomain | undefined =
    xAxisConfig.dataType === XAxisDataType.number
      ? [
          xAxisConfig.range?.from ?? "dataMin",
          xAxisConfig.range?.to ?? "dataMax",
        ]
      : undefined;

  const references: { lines: ReactNode[]; margins: Margin } = useMemo(() => {
    const referenceLines = (referenceDataConfig ?? []).map((referenceData) => {
      const xProp =
        referenceData.axis === "x" ? { x: referenceData.value } : {};
      const yProp =
        referenceData.axis === "y" ? { y: referenceData.value } : {};
      return (
        <ReferenceLine
          key={`${referenceData.axis}_${referenceData.value}`}
          {...xProp}
          {...yProp}
          stroke={referenceData.color ?? "transparent"}
          label={referenceData.label}
          yAxisId="left"
        />
      );
    });

    const margins: Margin = (referenceDataConfig ?? []).reduce(
      (acc, referenceData) => {
        if (referenceData.margin) {
          return {
            top: Math.max(acc.top, referenceData.margin.top ?? 0),
            right: Math.max(acc.right, referenceData.margin.right ?? 0),
            bottom: Math.max(acc.bottom, referenceData.margin.bottom ?? 0),
            left: Math.max(acc.left, referenceData.margin.left ?? 0),
          };
        }
        return acc;
      },
      { top: 0, right: 0, bottom: 0, left: 0 }
    );
    return { lines: referenceLines, margins };
  }, [referenceDataConfig]);

  return (
    <Container
      className={className}
      aria-label={ariaLabel ?? Graph}
      {...containerProps}
    >
      <AutoSizer>
        {({ width, height }) => (
          <RechartBarChart
            maxBarSize={40}
            width={width}
            height={height}
            data={stackBarsData?.values ?? data}
            margin={references.margins}
            {...chartProps}
          >
            <CartesianGrid
              stroke={GraphGridColor}
              strokeWidth="1"
              fill={GraphBackgroundColor}
            />
            <YAxis
              yAxisId="left"
              aria-label={Yaxis}
              allowDataOverflow={true}
              stroke={yAxisConfig.color}
              type="number"
              min={0}
              width={
                yAxisConfig.dynamicWidth
                  ? Math.max(yAxisCalculatedWidth, Y_AXIS_MINIMUM_WIDTH)
                  : undefined
              }
              tickFormatter={tickCalculatingFormatter}
              {...calculatedYAxisProps}
            />
            {secondaryYAxisConfig && (
              <YAxis
                yAxisId="right"
                dataKey={secondaryYAxisConfig.dataKey}
                orientation="right"
                allowDataOverflow={true}
                stroke={secondaryYAxisConfig.color}
                type="number"
                min={0}
                width={
                  secondaryYAxisConfig.dynamicWidth
                    ? Math.max(yAxisCalculatedWidth, Y_AXIS_MINIMUM_WIDTH)
                    : undefined
                }
                tickFormatter={tickCalculatingFormatter}
                {...yAxisProps(secondaryYAxisConfig.label)}
              />
            )}
            <XAxis
              aria-label={Xaxis}
              dataKey={xAxisConfig.dataKey}
              tick={xAxisConfig.tickComponent}
              ticks={xAxisConfig.ticks}
              interval={0}
              allowDuplicatedCategory={!!stackBarsData}
              allowDataOverflow
              domain={xAxisDomain}
              type={xAxisConfig.dataType}
            />
            {getGraphTooltip({
              tooltipProps,
              tooltipItems,
              shouldUseTimeTooltipHeader,
            })}
            {references.lines}
            {data.map((barData) => {
              return (
                <Bar
                  yAxisId={
                    barData.useSecondaryYAxis && !!secondaryYAxisConfig
                      ? "right"
                      : "left"
                  }
                  barSize={15}
                  stackId={stackBarsData ? "a" : undefined}
                  dataKey={barData.customDataKey ?? yAxisConfig.dataKey}
                  key={barData.name}
                  data={!stackBarsData ? barData.values : undefined}
                  fill={barData.color}
                  name={barData.name}
                />
              );
            })}
            <Legend content={<GenericLegend items={legendItems} />} />
          </RechartBarChart>
        )}
      </AutoSizer>
    </Container>
  );
};
