import { Kubernetes } from "@komodorio/design-system/icons";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesEndpointSliceResource = {
  NameInK8S: "endpointslices",
  Group: "network",
  NavBarName: "Endpoint Slices",
  PresentName: "Endpoint Slices",
  Namespaced: true,
  UnnecessaryColumns: ["ports", "AddressType"],
  Kind: SupportedResourcesKinds.KubernetesEndpointSliceResource,
  Icon: Kubernetes,
  hasLabels: true,
};
