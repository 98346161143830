import React from "react";
import {
  LightTooltip,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useWorkloadsContainersMetrics } from "@/components/DriftAnalysis/Services/Metrics/hooks/useWorkloadsContainersMetrics";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";
import { ResourceBreadcrumbs } from "@/components/DriftAnalysis/ResourceBreadcrumbs";

export const ServicesMissingTheSpecifiedContainerInfo: React.FC<{
  komodorUids: string[];
  wantedContainer: MuiSelectionOption<string> | undefined;
}> = ({ komodorUids, wantedContainer }) => {
  const { initialLoadTime } = useWorkloadMetricsTabContext();
  const { komodorUidsWithoutWantedContainer } = useWorkloadsContainersMetrics({
    komodorUids,
    type: "memory",
    wantedContainer,
    endTime: initialLoadTime,
  });

  if (!komodorUidsWithoutWantedContainer.length) {
    return null;
  }

  return (
    <LightTooltip
      placement="top"
      title={
        <Stack gap="8px">
          <Typography variant="body3">
            The following services are missing the specified container:
          </Typography>
          {komodorUidsWithoutWantedContainer.map((komodorUid) => {
            const komodorUidParams = parseKomodorUid(komodorUid);
            if (!komodorUidParams) {
              return null;
            }
            const { cluster, namespace, name } = komodorUidParams;
            return (
              <ResourceBreadcrumbs
                key={komodorUid}
                clusterName={cluster}
                namespace={namespace}
                name={name}
              />
            );
          })}
        </Stack>
      }
    >
      <InfoOutlined
        fontSize="small"
        color="primary"
        sx={{ cursor: "pointer" }}
      />
    </LightTooltip>
  );
};
