import Box from "@mui/material/Box";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { filterKeyToTitle } from "@/pages/organization-settings/account/AuditPage/utils";
import { AuditMultipleValuesFilters } from "@/pages/organization-settings/account/AuditPage/types";
import { useAuditLogsContext } from "@/pages/organization-settings/account/AuditPage/context/useAuditLogsContext";
import { useGetAllFilterOptions } from "@/pages/organization-settings/account/AuditPage/hooks/useGetAllFilterOptions";
export const MultiSelectByKey: React.FC<{
  filterOptionsKey: AuditMultipleValuesFilters;
  ariaLabel?: string;
}> = ({ filterOptionsKey, ariaLabel }) => {
  const { setConfigurationData, ...context } = useAuditLogsContext();

  const allFiltersOptions = useGetAllFilterOptions();

  return (
    <Box flex={1}>
      <AdvancedMultiSelect
        options={allFiltersOptions[filterOptionsKey]}
        value={context[filterOptionsKey]}
        label={filterKeyToTitle[filterOptionsKey]}
        ariaLabel={ariaLabel}
        onChange={(
          selectedOption: MuiSelectionOption<string>[] | undefined
        ) => {
          setConfigurationData({ [filterOptionsKey]: selectedOption });
        }}
        selectPopoverProps={{ sx: { maxHeight: "366px" } }}
      />
    </Box>
  );
};
