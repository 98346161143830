import { useMemo } from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { Dictionary } from "@/shared/types/Dictionary";
import { RowSelectionController } from "@/components/common/ResourceListTable/types";
import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";
import { MAX_BATCH_ACTION_ITEMS } from "@/components/common/ResourceListTable/batchActions/batchActionsConstants";
import { getRowIdentifier } from "@/components/common/ResourceListTable/resourceListTableUtils";

export const useRowSelectionForBatchActions = ():
  | RowSelectionController
  | undefined => {
  const { inspectionTableBatchActions } = useOverridableFlags();
  const { setSelectedTableRows, selectedTableRows, resourceType } =
    useInspectionBatchActionsContext();

  const selectedRowNames = useMemo(() => {
    return selectedTableRows.map((row) => getRowIdentifier(row));
  }, [selectedTableRows]);

  return useMemo(() => {
    if (
      !inspectionTableBatchActions ||
      !(inspectionTableBatchActions as Dictionary<string>)[
        resourceType.NameInK8S
      ]
    ) {
      return undefined;
    }

    return {
      selectedRowNames,
      onRowSelection: setSelectedTableRows,
      includeAllRowsSelection: true,
      maxSelectableRows: MAX_BATCH_ACTION_ITEMS,
    };
  }, [
    inspectionTableBatchActions,
    resourceType.NameInK8S,
    selectedRowNames,
    setSelectedTableRows,
  ]);
};
