import React from "react";

import { LogoIconProps } from "../types";

export const DNS: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="DNS logo"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.88572 4.99999H4.65055C5.54524 3.94075 6.71762 3.12961 8.06016 2.66993L8.62895 2.47518L8.34307 3.00407C7.99943 3.6398 7.70521 4.30803 7.46058 4.99999H8.96311C9.33931 4.0717 9.81552 3.1958 10.378 2.38253L10.5713 2.10305L10.7646 2.38253C11.3272 3.1958 11.8034 4.0717 12.1796 4.99999H13.6821C13.4375 4.30803 13.1433 3.6398 12.7996 3.00407L12.5137 2.47518L13.0825 2.66993C14.4238 3.12918 15.5948 3.94435 16.4888 4.99999H18.2537C16.6166 2.49208 13.7837 0.833328 10.5619 0.833328C7.34632 0.833328 4.51927 2.49198 2.88572 4.99999ZM18.2537 15H16.4888C15.5948 16.0556 14.4238 16.8708 13.0825 17.3301L12.5137 17.5248L12.7996 16.9959C13.1433 16.3602 13.4375 15.692 13.6821 15H12.1796C11.8034 15.9283 11.3272 16.8042 10.7646 17.6175L10.5713 17.8969L10.378 17.6175C9.81552 16.8042 9.33931 15.9283 8.96311 15H7.46058C7.70521 15.692 7.99943 16.3602 8.34307 16.9959L8.62895 17.5248L8.06016 17.3301C6.71762 16.8704 5.54524 16.0592 4.65055 15H2.88572C4.51927 17.508 7.34632 19.1667 10.5619 19.1667C13.7837 19.1667 16.6166 17.5079 18.2537 15Z"
        fill="#737D9E"
      />
      <path
        d="M7.23808 11.8333C7.23808 12.6618 6.5665 13.3333 5.73808 13.3333H3.07141V6.66667H5.73808C6.5665 6.66667 7.23808 7.33824 7.23808 8.16667C7.23808 9.38889 7.23808 10.6111 7.23808 11.8333ZM4.32141 8.00001V12H5.98808V8.00001H4.32141Z"
        fill="#737D9E"
      />
      <path
        d="M16.8214 8.44445H18.0714V7.55556C18.0714 7.06667 17.6964 6.66667 17.2381 6.66667H14.7381C14.2797 6.66667 13.9047 7.06667 13.9047 7.55556L13.9047 9.77778C13.9047 10.2687 14.2778 10.6667 14.7381 10.6667H16.8214V12H15.1547V11.5556H13.9047V12.4444C13.9047 12.9333 14.2797 13.3333 14.7381 13.3333H17.2381C17.6964 13.3333 18.0714 12.9333 18.0714 12.4444V10.2222C18.0714 9.73131 17.6983 9.33334 17.2381 9.33334H15.1547V8.00001H16.8214V8.44445Z"
        fill="#737D9E"
      />
      <path
        d="M8.48808 6.66667H9.4673C9.63196 6.66667 9.78118 6.76364 9.84805 6.91411L11.4047 10.4167V6.66667H12.6547V13.3333H11.7818C11.6209 13.3333 11.4744 13.2407 11.4054 13.0954L9.73808 9.58334V13.3333H8.48808V6.66667Z"
        fill="#737D9E"
      />
    </svg>
  );
};
