import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { MagnifyingGlass16 } from "@komodorio/design-system/icons";
import { ResourceTableModelRow } from "komodor-types";
import { difference, first, isNumber, uniq } from "lodash";
import styled from "styled-components";

import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { updateSelected } from "../../../shared/components/FilterBar/utils/utils";
import Selected from "../../../shared/components/FilterBar/Interfaces/Selected";
import { SelectedRowsOnlySwitch } from "../../../shared/components/SelectedRowsOnlySwitch";
import { ClusterSelector } from "../../eventsView/PodsSelector/ClusterSelector";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { InspectionType } from "../../Inspection";
import { doesAgentVersionSupportResourceListFromATM } from "../../../shared/utils/agent/agent";
import {
  useActiveAgent,
  useAgentPropertiesById,
} from "../../../shared/hooks/useAgents";
import { NamespaceSelector } from "../../eventsView/PodsSelector/namespaceSelector";
import { useSelectedPodsForEvents } from "../../eventsView/PodsSelector/SelectedPodsProvider";
import {
  FILTERS_BAR_FILTERS_PARAM_KEY,
  INSPECTION_NAMESPACES_PARAM_KEY,
  PODS_SELECTION_SEARCH_PARAM_KEY,
  PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";

import PodsDataAtm from "./PodsDataAtm";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

const DEFAULT_MAX_SELECTABLE_ROWS = 30;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 1rem;
  &.namespaced {
    grid-template-columns: 1fr 1fr auto 1fr;
  }
`;

const Searchbox = styled(Input).attrs({
  placeholder: "Pod name, status, label",
  icon: MagnifyingGlass16,
  size: "medium",
  width: "26rem",
})``;
interface PodsSelectionDialogProps {
  close: () => void;
  timeWindow: TimeWindow;
}

export const PodsSelectionDialog: React.FC<PodsSelectionDialogProps> = ({
  close,
  timeWindow,
}) => {
  const [cluster, setCluster] = useState<string | undefined>();
  const [NamespacesParam, setNamespacesParam] =
    useStringifiedStateInSearchParams<string[]>(
      INSPECTION_NAMESPACES_PARAM_KEY
    );
  const namespace = useMemo(() => first(NamespacesParam), [NamespacesParam]);
  const [selectedRowUids, setSelectedRowUids] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useStateInSearchParams(
    PODS_SELECTION_SEARCH_PARAM_KEY
  );
  const [showSelectedRowsOnly, setShowSelectedRowsOnly] = useState(false);
  const [pods, setPods] = useState<ResourceTableModelRow[]>([]);

  const [selectedFilters, setSelectedFilters] =
    useStringifiedStateInSearchParams<Selected>(FILTERS_BAR_FILTERS_PARAM_KEY);

  const [showDeletedPodsParam, setShowDeletedPods] =
    useStringifiedStateInSearchParams<boolean>(
      PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY
    );

  const { selectedPodUidsForEvents, setSelectedPodUidsForEvents } =
    useSelectedPodsForEvents();

  useEffect(() => {
    setShowDeletedPods(true);
  }, [setShowDeletedPods]);
  useEffect(() => {
    setSelectedRowUids(selectedPodUidsForEvents ?? []);
  }, [selectedPodUidsForEvents, showDeletedPodsParam]);

  const onClickApplySelection = useCallback(() => {
    if (selectedRowUids.length > 0) {
      dispatchEvent(AnalyticEvents.PodEvents.ApplySelection_clicked);
    }
    setSelectedPodUidsForEvents(selectedRowUids);
    const uniqClustersSelected = uniq(
      pods
        .filter((p) => selectedRowUids.includes(p.uid))
        .map((p) => p.clusterName)
    );

    let selectedFiltersDup = Object.assign({}, selectedFilters) as Selected;
    const currentSelectedClusters = selectedFiltersDup?.["Clusters"]
      ? Object.keys(selectedFiltersDup["Clusters"])
      : [];
    const clusterToToggleOff = difference(
      currentSelectedClusters,
      uniqClustersSelected
    );
    const clusterToToggleOn = difference(
      uniqClustersSelected,
      currentSelectedClusters
    );
    for (const cluster of [...clusterToToggleOn, ...clusterToToggleOff]) {
      selectedFiltersDup = updateSelected(
        selectedFiltersDup,
        "Clusters",
        cluster
      );
    }
    setSearchTerm(null);
    setSelectedFilters(selectedFiltersDup);
    close();
  }, [
    close,
    pods,
    selectedFilters,
    selectedRowUids,
    setSearchTerm,
    setSelectedFilters,
    setSelectedPodUidsForEvents,
  ]);

  const onClose = useCallback(() => {
    setSearchTerm(null);
    setNamespacesParam(null);
    close();
  }, [close, setNamespacesParam, setSearchTerm]);

  const { eventsViewMaxSelectablePods } = useOverridableFlags();

  const agentId = useActiveAgent(cluster);
  const { agentInfo } = useAgentPropertiesById(agentId ?? "");

  const isAgentAvailable = useMemo(
    () => agentId && agentInfo && cluster,
    [agentId, agentInfo, cluster]
  );
  const inspectionType = useMemo(() => {
    if (!isAgentAvailable) return undefined;
    if (doesAgentVersionSupportResourceListFromATM(agentInfo)) {
      return InspectionType.ATM;
    }
    return InspectionType.DB;
  }, [isAgentAvailable, agentInfo]);

  const showNamespaceSelector =
    agentId && cluster && inspectionType === InspectionType.ATM;

  const handleClusterSelection = useCallback(
    (selectedCluster: string) => {
      if (selectedCluster !== cluster) {
        setCluster(selectedCluster);
        setNamespacesParam(null);
        if (!showNamespaceSelector && selectedPodUidsForEvents)
          setSelectedRowUids(selectedPodUidsForEvents); // Clears all row selections when switching to another cluster.
      }
    },
    [
      cluster,
      selectedPodUidsForEvents,
      setNamespacesParam,
      showNamespaceSelector,
    ]
  );

  const handleNamespaceSelection = useCallback(
    (selectedNamespace: string) => {
      if (selectedNamespace !== namespace) {
        if (selectedPodUidsForEvents)
          setSelectedRowUids(selectedPodUidsForEvents); // Clears all row selections when switching to another cluster.
        setNamespacesParam([selectedNamespace]);
      }
    },
    [namespace, selectedPodUidsForEvents, setNamespacesParam]
  );

  const maxSelectableRows = useMemo(
    () =>
      isNumber(eventsViewMaxSelectablePods)
        ? eventsViewMaxSelectablePods
        : DEFAULT_MAX_SELECTABLE_ROWS,
    [eventsViewMaxSelectablePods]
  );

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      width="min(90%, 72rem)"
      height="max(calc(100% - 200px), 35rem)"
    >
      <ModalHeader>
        <Typography variant="headline">Add pod events to timeline</Typography>
      </ModalHeader>

      <ModalContent>
        <Typography size="medium">
          {`Add up to ${maxSelectableRows} pods to your service timeline and see changes and event
          history over time.`}
        </Typography>

        <Divider spacing="1rem" />

        <Grid className={showNamespaceSelector ? "namespaced" : ""}>
          <ClusterSelector
            selectedCluster={cluster}
            onChange={handleClusterSelection}
          />
          {showNamespaceSelector && (
            <NamespaceSelector
              selectedNamespace={namespace}
              cluster={cluster}
              agentId={agentId}
              onChange={handleNamespaceSelection}
            />
          )}

          <Searchbox
            value={searchTerm ?? ""}
            onChange={(event) => {
              setSearchTerm(event.target.value ?? null);
            }}
            data-testid="search-box"
          />

          <SelectedRowsOnlySwitch
            checked={showSelectedRowsOnly}
            onToggle={() => {
              setShowSelectedRowsOnly(
                (prevShowSelectedRowsOnly) => !prevShowSelectedRowsOnly
              );
            }}
            selectedRowsNumber={selectedRowUids.length ?? 0}
          />
        </Grid>
        {cluster &&
          namespace &&
          agentId &&
          inspectionType === InspectionType.ATM && (
            <PodsDataAtm
              timeWindow={timeWindow}
              setPods={setPods}
              cluster={cluster}
              namespace={namespace}
              selectedRowsData={[selectedRowUids, setSelectedRowUids]}
              maxSelectableRows={maxSelectableRows}
              showSelectedRowsOnly={showSelectedRowsOnly}
              agentId={agentId}
            />
          )}
      </ModalContent>

      <ModalActions style={{ position: "absolute", bottom: "1rem", right: 0 }}>
        <Button
          onClick={onClose}
          variant="secondary"
          data-testid="cancel-button"
        >
          Cancel
        </Button>

        <Button
          onClick={() => onClickApplySelection()}
          variant="primary"
          data-testid="apply-button"
        >
          Apply selection
        </Button>
      </ModalActions>
    </Modal>
  );
};
