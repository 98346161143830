import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { useMemo } from "react";

import {
  buildHelmOptionValue,
  buildOptionForHelmRelease,
} from "./selectionOptionsUtils";

import { HelmChart } from "@/generated/addonsApi";

export const useBaselineHelmReleasesOptions = (
  baselineHelmReleaseId: MuiSelectionOption<string> | null,
  helmReleases: HelmChart[] | undefined
): MuiSelectionOption<string>[] => {
  return useMemo(() => {
    return (helmReleases ?? [])
      .sort((a, b) => {
        const aUid = buildHelmOptionValue(a);
        const bUid = buildHelmOptionValue(b);
        if (aUid === baselineHelmReleaseId?.value) {
          return -1;
        }
        if (bUid === baselineHelmReleaseId?.value) {
          return 1;
        }
        return compareHelmReleaseInfo(a, b);
      })
      .map(buildOptionForHelmRelease);
  }, [baselineHelmReleaseId?.value, helmReleases]);
};

export const compareHelmReleaseInfo = (a: HelmChart, b: HelmChart): number => {
  return (
    a.clusterName.localeCompare(b.clusterName) ||
    a.namespace.localeCompare(b.namespace) ||
    a.name.localeCompare(b.name)
  );
};
