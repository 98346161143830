import { RbacPolicy } from "@/generated/auth";
import { policyTagsToArray } from "@/components/Settings/Policies/policyForm/PolicyTags";

const DEFAULT_ALLOW_ALL_RBAC_POLICY = "default-allow-all";
export const isPolicyEditable = (policy: RbacPolicy) =>
  policy.name !== DEFAULT_ALLOW_ALL_RBAC_POLICY;

export const customSortByTags = (
  aTags: RbacPolicy["tags"],
  bTags: RbacPolicy["tags"]
) => {
  const tagsA = policyTagsToArray(aTags).sort();
  const tagsB = policyTagsToArray(bTags).sort();

  return tagsA.join("").localeCompare(tagsB.join(""));
};
