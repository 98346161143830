import React, { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";

import { policyPopupStorage } from "./constants";

import { AriaLabels } from "@/shared/config/ariaLabels";

interface PolicyCreationPopupProps {
  onClose: () => void;
}

export const PolicyCreationPopup: React.FC<PolicyCreationPopupProps> = ({
  onClose,
}) => {
  const [isAccessRequested, setIsAccessRequested] = useState(false);

  const handleCreatePolicy = useCallback(() => {
    setIsAccessRequested(true);
    setTimeout(() => {
      policyPopupStorage.set("true");
      onClose();
    }, 3000);
  }, [onClose]);

  const handleCloseCallback = useCallback(() => {
    policyPopupStorage.set("true");
    onClose();
  }, [onClose]);

  return (
    <Paper
      elevation={5}
      sx={{
        borderRadius: 1,
        width: "525px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="10px 16px"
      >
        {isAccessRequested ? (
          <Stack direction="row" gap="8px" alignItems="center">
            <CheckIcon sx={{ fontSize: 16, color: muiColors.green[700] }} />
            <Typography variant="button" color={muiColors.green[700]}>
              Thank you! we’ll be in touch soon
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" gap="22px" alignItems="center">
            <Box>
              <Typography variant="body2" fontWeight={700}>
                Help us improve the Drift feature!
              </Typography>
              <Typography variant="body2">
                Want to customize notifications for drifted workloads?
                <br />
                Let's chat — your gift card is waiting! 🎁
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreatePolicy}
              aria-label={AriaLabels.DriftAnalysis.Service.PolicyCreationPopup}
            >
              I'm interested
            </Button>
          </Stack>
        )}
        <IconButton onClick={handleCloseCallback} edge="end">
          <CloseIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
};
