import React, { useEffect, useMemo, useRef, useState } from "react";
import Stack from "@mui/material/Stack";

import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { useResourceAiInvestigation } from "@/components/AiInvestigation/useResourceAiInvestigation";
import {
  FlowType,
  ResourceDataForAiInvestigation,
} from "@/components/AiInvestigation/types";
import { AiInvestigationTitle } from "@/components/AiInvestigation/AiInvestigationTitle";
import { AiInvestigationContent } from "@/components/AiInvestigation/AiInvestigationContent/AiInvestigationContent";

interface AiInvestigationProps {
  requestId: string;
  flowType: FlowType;
  resource: ResourceDataForAiInvestigation;
  isHealthy: boolean;
  sessionId?: string;
  drawerData?: DrawerStatePush;
  disableTrigger?: boolean;
  padding?: string;
  backgroundMode?: boolean;
  maxHeight?: string;
  showDemo?: boolean;
}

export const AiInvestigation: React.FC<AiInvestigationProps> = ({
  requestId,
  flowType,
  resource,
  isHealthy,
  sessionId,
  drawerData,
  disableTrigger,
  padding,
  backgroundMode,
  maxHeight,
  showDemo,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [parentElementHeight, setParentElementHeight] = useState<number>(0);
  const defaultResourceDrawerData = useMemo<DrawerStatePush>(
    () => ({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: resource.cluster,
      namespace: resource.namespace,
      resourceType: resource.kind,
      resourceName: resource.name,
    }),
    [resource]
  );
  useEffect(() => {
    setParentElementHeight(divRef.current?.parentElement?.clientHeight ?? 0);
  }, []);

  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: drawerData || defaultResourceDrawerData,
    requestId,
    resource,
    isHealthy,
    flowType,
    sessionId,
    disableTrigger,
    backgroundMode,
  });

  if (!enabled || (isHealthy && !richSession) || backgroundMode) {
    return null;
  }

  return (
    <Stack
      padding={padding}
      gap={1}
      maxHeight={
        showDemo
          ? undefined
          : parentElementHeight
          ? `${parentElementHeight}px`
          : "100%"
      }
      ref={divRef}
    >
      <AiInvestigationTitle
        richSession={richSession}
        onShareClicked={onShareClicked}
        onRetryCallback={onRetryCallback}
      />
      <AiInvestigationContent
        richSession={richSession}
        cluster={resource.cluster}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
        maxHeight={maxHeight}
        showDemo={showDemo}
      />
    </Stack>
  );
};
