"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IgnoreActionTypes = exports.ActionTypes = exports.TaskEvent = exports.TaskTypeV2 = exports.TaskType = exports.TaskState = void 0;
var TaskState;
(function (TaskState) {
    TaskState["INITIATED"] = "initiated";
    TaskState["IN_PROGRESS"] = "in-progress";
    TaskState["COMPLETED"] = "completed";
    TaskState["TIMEOUT"] = "timeout";
})(TaskState = exports.TaskState || (exports.TaskState = {}));
var TaskType;
(function (TaskType) {
    TaskType["POD_LOGS"] = "pod-logs";
    TaskType["POD_DESCRIBE"] = "pod-describe";
    TaskType["POD_LIST"] = "pod-list";
    TaskType["SERVICE_DESCRIBE"] = "service-describe";
    TaskType["RESOURCE_LIST"] = "resource-list";
    TaskType["POD_METRICS"] = "pod-metrics";
    TaskType["NODE_METRICS"] = "node-metrics";
    TaskType["NODE_STATUS"] = "node-status";
    TaskType["ACTION_COMMAND"] = "action-command";
    TaskType["FREE_COMMAND"] = "free-command";
    TaskType["RESTART_AGENT"] = "restart-agent";
    TaskType["EXTERNAL_DNS"] = "external-dns";
})(TaskType = exports.TaskType || (exports.TaskType = {}));
var TaskTypeV2;
(function (TaskTypeV2) {
    TaskTypeV2["ResourceListV2"] = "resource-list-v2";
    TaskTypeV2["EndSessionV2"] = "end-session-v2";
})(TaskTypeV2 = exports.TaskTypeV2 || (exports.TaskTypeV2 = {}));
var TaskEvent;
(function (TaskEvent) {
    TaskEvent["STARTED"] = "started";
    TaskEvent["COMPLETED"] = "completed";
})(TaskEvent = exports.TaskEvent || (exports.TaskEvent = {}));
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["DeleteResource"] = "deleteResource";
    ActionTypes["Scale"] = "scale";
    ActionTypes["RestartService"] = "restartService";
    ActionTypes["EditResource"] = "editResource";
    ActionTypes["CreateResource"] = "createResource";
    ActionTypes["GetResource"] = "getResource";
    ActionTypes["JobRerun"] = "jobRerun";
    ActionTypes["JobRunNow"] = "jobRunNow";
    ActionTypes["Uncordon"] = "uncordon";
    ActionTypes["Cordon"] = "cordon";
    ActionTypes["Drain"] = "drain";
    ActionTypes["RollbackService"] = "rollbackService";
    ActionTypes["UpdateContainersResources"] = "updateContainersResources";
    ActionTypes["GetHelmRepoSearch"] = "getHelmRepoSearch";
    ActionTypes["GetHelmRepoCharts"] = "getHelmRepoCharts";
    ActionTypes["UpdateHelmRepo"] = "updateHelmRepo";
    ActionTypes["AddHelmRepo"] = "addHelmRepo";
    ActionTypes["RemoveHelmRepo"] = "removeHelmRepo";
    ActionTypes["InstallHelmChart"] = "installHelmChart";
    ActionTypes["UninstallHelmChart"] = "uninstallHelmChart";
    ActionTypes["RevertHelmChart"] = "revertHelmChart";
    ActionTypes["HelmShowValues"] = "helmShowValues";
    ActionTypes["HelmTemplate"] = "helmTemplate";
    ActionTypes["HelmGetCharts"] = "helmGetCharts";
    ActionTypes["HelmGetChartHistory"] = "helmGetChartHistory";
    ActionTypes["Describe"] = "describe";
    ActionTypes["PodShell"] = "podShell";
    ActionTypes["PortForward"] = "portForward";
    ActionTypes["RevertSourceControl"] = "revertSourceControl";
    ActionTypes["SuspendCronJob"] = "suspendCronJob";
    ActionTypes["ResumeCronJob"] = "resumeCronJob";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.IgnoreActionTypes = [
    ActionTypes.GetResource,
    ActionTypes.GetHelmRepoSearch,
    ActionTypes.GetHelmRepoCharts,
    ActionTypes.HelmShowValues,
    ActionTypes.HelmTemplate,
    ActionTypes.AddHelmRepo,
    ActionTypes.RemoveHelmRepo,
    ActionTypes.HelmGetCharts,
    ActionTypes.HelmGetChartHistory,
];
