import React from "react";
import Stack from "@mui/material/Stack";

import { getText } from "../utils";

import { Breadcrumb } from "./Breadcrumb";

export const Path: React.FC<{
  value: string[];
  isLimitedPath?: boolean;
  isHighlighted?: boolean;
}> = ({ value, isLimitedPath = false, isHighlighted = true }) => {
  const pathElements = [...value];
  const mainPathElement = pathElements.pop();

  const pathContent = pathElements.length
    ? pathElements.map((pathElement, index) => (
        <React.Fragment key={pathElement + index}>
          <Breadcrumb
            text={getText(pathElement, isLimitedPath)}
            isHighlighted={isHighlighted}
          />
          <Breadcrumb text=" > " isHighlighted={isHighlighted} />
        </React.Fragment>
      ))
    : null;

  if (!mainPathElement) return null;

  return (
    <Stack alignItems="center" direction="row">
      {pathContent}
      <Breadcrumb isHighlighted={false} text={mainPathElement} />
    </Stack>
  );
};
