import Link from "@mui/material/Link";
import { useMemo } from "react";

type ListOfLinksProps = {
  links: { content: string; onClick: () => void }[];
};

export const ListOfLinks: React.FC<ListOfLinksProps> = ({ links }) => {
  const linksToRender = useMemo(
    () =>
      links.map((item, index) => (
        <>
          <Link
            key={item.content}
            variant="body2"
            underline="hover"
            noWrap
            onClick={item.onClick}
          >
            {item.content}
          </Link>
          {index !== links.length - 1 && ", "}
        </>
      )),
    [links]
  );
  return <div>{linksToRender}</div>;
};
