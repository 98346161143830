import React, { useCallback, useState } from "react";

import { useAppViewsStore } from "@/shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "@/shared/store/appViewsStore/appViewStoreSelectors";
import { dispatchEvent, AnalyticEvents } from "@/shared/hooks/analytics";
import { CommandBarContent } from "@/components/AppBar/Search/CommandBarContent";
import { ResourceKind } from "@/generated/searchApi";

export interface CustomCommandBarProps {
  cluster?: string;
  namespace?: string;
  kinds?: ResourceKind[];
  placeholder?: string;
  button: React.ReactElement;
}

export const CustomCommandBar = ({
  cluster,
  namespace,
  kinds,
  placeholder = "Find a workload, page, workspace...",
  button,
}: CustomCommandBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const shouldSearchInAllWorkspaces = useCallback(() => {
    return !selectedAppViewId && !cluster && !namespace && !kinds;
  }, [selectedAppViewId, cluster, namespace, kinds]);

  const [searchInAllWorkspaces, setSearchInAllWorkspaces] = useState(
    shouldSearchInAllWorkspaces()
  );

  const onOpen = useCallback(() => {
    setQuery("");
    setSearchInAllWorkspaces(shouldSearchInAllWorkspaces());
    dispatchEvent(AnalyticEvents.GlobalSearch.Opened, {
      method: "button",
    });
    setIsOpen(true);
  }, [shouldSearchInAllWorkspaces]);

  return (
    <>
      <div onClick={onOpen}>{button}</div>
      {isOpen && (
        <CommandBarContent
          setIsOpen={setIsOpen}
          query={query}
          setQuery={setQuery}
          searchInAllWorkspaces={searchInAllWorkspaces}
          setSearchInAllWorkspaces={setSearchInAllWorkspaces}
          queryParams={{ cluster, namespace, kinds }}
          placeholder={placeholder}
          forceWorkspacesToggle={true}
        />
      )}
    </>
  );
};
