import { useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import { TextFieldProps } from "@mui/material";
import Send from "@mui/icons-material/Send";

import { TextInput } from "components/komodor-ui/TextInput/TextInput";

export type ChatInputProps = TextFieldProps & {
  onSend: () => void;
  focusOnMount?: boolean;
  disableSend?: boolean;
};

export const ChatInput: React.FC<ChatInputProps> = ({
  onSend,
  disableSend,
  disabled,
  size,
  focusOnMount = true,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus({ preventScroll: true });
    }
  }, [focusOnMount]);

  const handleSend = () => {
    onSend();
    inputRef.current?.focus({ preventScroll: true });
  };

  return (
    <TextInput
      inputRef={inputRef}
      variant="outlined"
      size={size}
      fullWidth
      onKeyDown={(e) => {
        if (!disableSend && e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          handleSend();
        }
      }}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={handleSend}
            disabled={disableSend || disabled}
            color="primary"
            size="small"
          >
            <Send fontSize="small" />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};
