import React from "react";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { PoliciesPageContent } from "@/pages/organization-settings/access-management/PoliciesPage/PoliciesPageContent";
import { LoadTimeReportingHandler } from "@/pages/LoadTimeReportingHandler";
import { AccountUsersContextProvider } from "@/shared/context/users/AccountUsersContext";

export const PoliciesPage: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.accountSettingsPoliciesPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <AccountUsersContextProvider>
        <PoliciesPageContent />
        <LoadTimeReportingHandler />
      </AccountUsersContextProvider>
    </DatadogReportLoadingTimeContextProvider>
  );
};
