import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";
import {
  deleteTrackedKeyUrl,
  TrackedKeysApiDeleteTrackedKeyRequest,
} from "@/generated/accounts";

const deleteTrackedKey = async (
  apiClient: AxiosInstance,
  params: TrackedKeysApiDeleteTrackedKeyRequest
): Promise<void> => {
  await apiClient.delete(
    deleteTrackedKeyUrl(params, apiClient.defaults.baseURL ?? "")
  );
};

export const useDeleteTrackedKey = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    (vars: TrackedKeysApiDeleteTrackedKeyRequest) =>
      deleteTrackedKey(apiClient, vars),
    {}
  );
};
