import { ResourceTableModel } from "komodor-types";
import { useMemo } from "react";

import { INSPECTION_PERMISSIONS_ERROR } from "../../../shared/hooks/ATM/constants/atmResources";

import { ResourceListResponse } from "@/components/Inspection/InspectionViews/types";

export interface InspectionResourceListResults {
  data: string[];
  labels: string[];
}

export const useParseResourceTableModel = (
  table: ResourceTableModel | null
): ResourceListResponse => {
  return useMemo(() => {
    const fetching = !table;
    const rows = table?.rows ?? [];
    return {
      emptyResult: rows.length === 0,
      fetching,
      rows: rows,
      errorMessage: table?.error ? INSPECTION_PERMISSIONS_ERROR : "",
    };
  }, [table]);
};
