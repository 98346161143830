import { subDays } from "date-fns";

import {
  AllocationTableColumns,
  RightSizingTableColumns,
} from "../types/tableTypes";
import { formatFixedNumber } from "../utils/costOptimizationUtils";
import {
  formatCurrency,
  formatCurrencyIfSmallerThanOne,
} from "../../../shared/utils/formatCurrency";
import { formatPercentage } from "../../../shared/utils/formatPercentage";
import { formatNumber } from "../../../shared/utils/formatNumber";
import { CostRightSizingSummary } from "../../../generated/metricsApi";

export const subDaysFromNow = (days: number) =>
  subDays(new Date(), days).toISOString();

export const createSearchString = (
  params: Record<string, string>
): { pathname: undefined; search: string } => {
  const urlSearchParams = new URLSearchParams();
  for (const key in params) {
    urlSearchParams.append(key, params[key]);
  }
  return {
    pathname: undefined,
    search: urlSearchParams.toString(),
  };
};

export const getAllocationTableCellValue = (
  columnName: AllocationTableColumns,
  columnVal: string | number
) => {
  switch (columnName) {
    case "clusterName":
    case "namespace":
    case "komodorServiceName":
    case "komodorServiceKind":
      return columnVal;
    case "cpuRequestCoreHours":
    case "memoryRequestGBHours":
      return formatFixedNumber(columnVal as number);
    case "idleResourcesCost":
    case "totalCost":
      return formatCurrency(columnVal as number);
    case "potentialSaving":
      return `Save ${formatCurrency(columnVal as number)}`;
    case "optimizationScore":
      return formatPercentage(columnVal as number);
    case "shareOfCost":
      return `${parseFloat(formatNumber(columnVal as number))}%`;
    default: {
      return columnVal;
    }
  }
};

export const getRightSizingTableCellValue = (
  columnName: RightSizingTableColumns,
  rowData: CostRightSizingSummary
) => {
  switch (columnName) {
    case "clusterName":
    case "namespace":
    case "service":
    case "komodorServiceKind":
      return rowData[columnName];
    case "cpuRequestCores":
      if (rowData[columnName] <= rowData.cpuRecommendation) return "no change";
      return `${formatNumber(rowData[columnName])}${formatNumber(
        rowData.cpuRecommendation
      )}`;
    case "memoryRequestsMiB":
      if (rowData[columnName] <= rowData.memoryRecommendation)
        return "no change";
      return `${formatNumber(rowData[columnName])}${formatNumber(
        rowData.memoryRecommendation,
        {
          maximumFractionDigits: 0,
        }
      )}`;
    case "potentialSaving":
      return `Save ${formatCurrencyIfSmallerThanOne(rowData[columnName])}`;
    case "optimizationScore":
      return formatPercentage(rowData[columnName]);
    default: {
      return rowData[columnName];
    }
  }
};

const serviceKinds = [
  "Deployment",
  "StatefulSet",
  "DaemonSet",
  "Job",
  "CronJob",
];
export const getServiceKind = (i = 0): string => {
  return serviceKinds[i % serviceKinds.length];
};
