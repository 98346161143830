import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { RuleValidator } from "react-querybuilder";

import { defaultValidator } from "@/shared/components/QueryBuilder/defaultValidator";
import {
  doesntMatchWildcardOperator,
  matchesWildcardOperator,
  multiIsOperator,
} from "@/shared/components/QueryBuilder/operators";

export const namespaceQBField = ({
  options,
  validator = defaultValidator,
  isChangeable = false,
  onCreateNewOptions,
}: {
  options: MuiSelectionOption<string>[];
  isChangeable?: boolean;
  validator?: RuleValidator;
  onCreateNewOptions: (values: MuiSelectionOption<string>[]) => void;
}) => {
  return {
    name: "namespace",
    label: "Namespace",
    disabled: !isChangeable,
    operators: [
      multiIsOperator,
      matchesWildcardOperator,
      doesntMatchWildcardOperator,
    ],
    values: options,
    validator,
    enableFreeSolo: true,
    onCreateNewOptions,
  };
};
