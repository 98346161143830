import React, { useEffect } from "react";

import {
  ImpactGroupType,
  ViolationsApiGetViolationsRequest,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { ViolationTableWithTitleAndFooter } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithHeaderAndTitle/ViolationTableWithTitleAndFooter";
import { useGridPagination } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithHeaderAndTitle/useGridPagination";
import { VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";

const baseViolationsFetchConfig: Pick<
  ViolationsApiGetViolationsRequest,
  "impactGroupType" | "status"
> = {
  impactGroupType: [ImpactGroupType.Dynamic, ImpactGroupType.Static],
  status: [
    ViolationStatus.Open,
    ViolationStatus.Confirmed,
    ViolationStatus.Dismissed,
    ViolationStatus.Ignored,
  ],
};

interface Props {
  title: string;
  ids: string[] | undefined;
  violationTableType: ViolationsTableType;
  displayIfNoResults?: boolean;
}

export const ViolationsTableByIds: React.FC<Props> = ({
  title,
  ids,
  violationTableType,
  displayIfNoResults = false,
}) => {
  const { page, pageSize, addPaginationParams, setPaginationModel } =
    useGridPagination({
      defaultPageSize: VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS[0],
    });
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const hasIdsToFetch = (ids?.length ?? 0) > 0;
  const violationFetchParams = addPaginationParams({
    ...baseViolationsFetchConfig,
    violationId: ids ?? [],
  });
  const violationsRes = useGetViolations(violationFetchParams, hasIdsToFetch);

  const violationsCount = violationsRes?.data?.data?.violations.length ?? 0;

  useEffect(() => {
    reportLoadingState("ViolationsTableByIds", violationsRes?.isFetching);
  }, [reportLoadingState, violationsRes?.isFetching]);

  if ((!displayIfNoResults && violationsCount === 0) || !hasIdsToFetch)
    return null;

  return (
    <ViolationTableWithTitleAndFooter
      violations={violationsRes}
      title={title}
      violationTableType={violationTableType}
      paginationConfig={{ page, pageSize, setPaginationModel }}
    />
  );
};
