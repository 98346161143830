import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

import { EmailDigestConfiguration } from "@/generated/emailDigestService";
import { initialEmailDigestionConfigurationState } from "@/components/Settings/email/context/initialState";

export type EmailSettingsContextState = EmailDigestConfiguration & {
  setConfigurationData: (data: Partial<EmailDigestConfiguration>) => void;
};

const initialState: EmailSettingsContextState = {
  ...initialEmailDigestionConfigurationState,
  setConfigurationData: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const EmailSettingsContext =
  createContext<EmailSettingsContextState>(initialState);

export const EmailSettingsContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const setConfigurationData = useCallback(
    (data: Partial<EmailDigestConfiguration>) => {
      setState((prevState) => ({ ...prevState, ...data }));
    },
    []
  );

  const [state, setState] = useState<EmailSettingsContextState>({
    ...initialState,
    setConfigurationData,
  });

  return (
    <EmailSettingsContext.Provider value={state}>
      {children}
    </EmailSettingsContext.Provider>
  );
};
