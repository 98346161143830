import React from "react";

import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeReadyDetails from "./detailsComponents/NodeReadyDetails";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

interface PodsOutput {
  name: string;
  serviceId: string;
  namespace?: string;
}

interface NodeReadyOutput {
  pods?: PodsOutput[];
  hiddenPods?: number;
  lastReadyTime: string;
  readyMessage: string;
  createdTime: string;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeReadyCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "Node Readiness";
    this.description = (
      <>
        A node in a not ready state points to a problem on the node, can lead to
        an eviction of all pods running on it and prevent pods from being
        scheduled.
        <br />
        <br />
        The node-controller will trigger an API-initiated eviction for all Pods
        assigned to that node. The default eviction timeout duration is five
        minutes. New pods will be scheduled on another node.
        <br />
        <br />
      </>
    );
    this.action = (
      <>
        We ran <GrayHighlight>kubectl describe {`<NODE>`}</GrayHighlight> to see
        if the ready condition != True (node ready condition can be True, False
        or Unknown).
      </>
    );
    this.question = "Is the node ready?";
    this.output = check.output as NodeReadyOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <NodeReadyDetails check={this} />;
  }
}
