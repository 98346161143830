import React, { PropsWithChildren } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { IconProps } from "@komodorio/design-system/icons";
import { muiTheme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// eslint-disable-next-line mui-path-imports/mui-path-imports
import { SvgIconComponent } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";

type Props = PropsWithChildren<{
  Icon: React.FC<IconProps> | SvgIconComponent;
  title: string;
  sx?: SxProps<Theme>;
}>;

export const DrawerSection: React.FC<Props> = ({
  Icon,
  title,
  children,
  sx,
}) => {
  return (
    <Stack sx={{ ...sx, "&:first-of-type hr": { marginTop: 0 } }}>
      <Divider sx={{ margin: "16px 0" }} />
      <Stack
        direction="row"
        alignItems="flex-start"
        columnGap="16px"
        padding={"0 24px"}
        flexGrow={1}
      >
        <Box
          sx={{
            padding: "8px",
            backgroundColor: muiTheme.palette.grey[100],
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            style={{
              width: "16px",
              height: "16px",
              fill: muiTheme.palette.primary.main,
            }}
          />
        </Box>
        <Stack padding="4px" rowGap="16px" flexGrow={1} height="100%">
          <Typography variant="h4">{title}</Typography>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
