import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ClusterOverview } from "../ClustersView/ClusterOverview/ClusterOverview";
import {
  ClusterWorkspace,
  ScopedWorkspace,
  WorkspaceKind,
} from "../../generated/workspacesApi";
import { AppViewUrlParam } from "../appView/types/urlParams";
import { ClustersGroupOverview } from "../ClustersView/ClustersGroupOverview/ClustersGroupOverview";

import { useWorkspaces } from "./WorkspacesTopBar/hooks";

import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { Overview } from "@/components/workspaces/Overview/Overview";
import { PageLoader } from "@/shared/components/PageLoader";
import { PathNotFound } from "@/components/404/PathNotFound";

export const WorkspaceOverview: React.FC = () => {
  const { id: workspaceId } = useParams<AppViewUrlParam>();
  const { workspaces, isLoading, isScopedWorkspace, scopedWorkspaceSubKind } =
    useWorkspaces();
  const workspace = workspaces?.find((w) => w.id === workspaceId);

  useEffect(() => {
    if (workspace?.kind && workspace?.name) {
      dispatchEvent(AnalyticEvents.Workspaces.WorkspaceOverviewViewed, {
        workspaceName: workspace.name,
        workspaceKind: workspace.kind,
      });
    }
  }, [workspace?.kind, workspace?.name]);

  if (isLoading) {
    return <PageLoader />;
  }
  if (!workspace) {
    return <PathNotFound />;
  }

  if (workspace.kind === WorkspaceKind.Cluster) {
    const clusterName = (workspace.value as ClusterWorkspace).clusterName;
    return <ClusterOverview clusterName={clusterName} />;
  }
  if (workspace.kind === WorkspaceKind.ClustersGroup) {
    return <ClustersGroupOverview clustersGroupId={workspace.id} />;
  }

  const isScopedClusterWorkspace =
    isScopedWorkspace && scopedWorkspaceSubKind === "clusters";
  if (isScopedClusterWorkspace) {
    const scopes = (workspace.value as ScopedWorkspace).scopes ?? [];
    const clusterScopesCount = scopes.reduce(
      (acc, { clusters }) => acc + (clusters?.length ?? 0),
      0
    );

    if (clusterScopesCount === 1) {
      // Single cluster = ClusterOverview
      // If this is true, than the reducer returned something, than we've got at least 1 scope
      const clusterName = scopes[0].clusters[0];
      return <ClusterOverview clusterName={clusterName} />;
    } else {
      // Multiple clusters = ClustersGroupOverview
      return <ClustersGroupOverview clustersGroupId={workspace.id} />;
    }
  }

  return (
    <Overview
      workspace={workspace}
      scopedWorkspaceSubKind={scopedWorkspaceSubKind}
    />
  );
};
