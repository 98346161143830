import React from "react";
import { Karpenter24, Kubernetes24 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";

import { AutoScalerEnum } from "@/generated/addonsApi";

type IconProps = {
  color?: string;
  size?: number;
};

export const nodeAutoscalersTypeMapper: Record<
  AutoScalerEnum,
  { title: string; icon: React.FC<IconProps> }
> = {
  [AutoScalerEnum.ClusterAutoScaler]: {
    title: "Cluster Autoscaler",
    icon: (iconProps) => (
      <Kubernetes24
        color={iconProps.color ?? muiColors.indigo[500]}
        height={`${iconProps.size ?? 24}px`}
        width={`${iconProps.size ?? 24}px`}
      />
    ),
  },
  [AutoScalerEnum.Karpenter]: {
    title: "Karpenter",
    icon: (iconProps) => (
      <Karpenter24
        color={iconProps.color}
        height={`${iconProps.size ?? 24}px`}
        width={`${iconProps.size ?? 24}px`}
      />
    ),
  },
};
