import React, { useMemo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@komodorio/design-system/komodor-ui";
import { GridPaginationModel } from "@mui/x-data-grid-pro";

import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";
import { RbacPolicy } from "@/generated/auth";
import {
  getColumnsDefinitions,
  getV2ColumnsDefinitions,
} from "@/pages/organization-settings/access-management/PoliciesPage/Grid/gridHelpers";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { NoRowsOverlay } from "@/pages/organization-settings/account/AuditPage/components/NoRowsOverlay";
import { usePaginationContext } from "@/shared/context/tableContext/usePaginationContext";
import { ariaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/ariaLabels";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { useAccountUsersContext } from "@/shared/context/users/useAccountUsersContext";

interface PoliciesGridProps {
  width?: number;
  filteredPolicies: RbacPolicy[];
  showExtraPolicyTypes: boolean;
  setPolicyToEdit: OnEdit;
  setPolicyToDelete: OnDelete;
  isLoading: boolean;
}

export const PoliciesGrid = ({
  width,
  filteredPolicies,
  showExtraPolicyTypes,
  setPolicyToEdit,
  setPolicyToDelete,
  isLoading,
}: PoliciesGridProps) => {
  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();
  const { rbacV2Policy } = useOverridableFlags();
  const { pageSize, setPageSize } = usePaginationContext();
  const [page, setPage] = React.useState<number>(0);
  const { format } = useDateFormatter({ timeZoneName: undefined });
  const { users } = useAccountUsersContext();

  const rows = useMemo(
    () =>
      filteredPolicies.map((policy: RbacPolicy) => ({
        ...policy,
        actions: policy.id,
        hovered: policy.id === hoveredRowId,
        createdBy: users[policy.createdBy]?.displayName,
      })),
    [filteredPolicies, hoveredRowId, users]
  );

  const columns = useMemo(
    () =>
      rbacV2Policy
        ? getV2ColumnsDefinitions({
            format,
            onEdit: setPolicyToEdit,
            onDelete: setPolicyToDelete,
          })
        : getColumnsDefinitions({
            showExtraActions: showExtraPolicyTypes,
            onEdit: setPolicyToEdit,
            onDelete: setPolicyToDelete,
          }).filter((column) => !column.hide),
    [
      rbacV2Policy,
      format,
      showExtraPolicyTypes,
      setPolicyToEdit,
      setPolicyToDelete,
    ]
  );

  return (
    <Paper
      variant="elevation"
      elevation={1}
      sx={{ width: width || "100%", overflow: "auto" }}
    >
      <DataGrid
        aria-label={ariaLabels.table.container}
        autoHeight
        columns={columns}
        rows={rows}
        loading={isLoading}
        disableVirtualization
        paginationModel={{ pageSize, page }}
        onPaginationModelChange={(newModel: GridPaginationModel) => {
          setPageSize(newModel.pageSize);
          setPage(newModel.page);
        }}
        slotProps={{
          row: {
            onMouseEnter: handleRowHovered,
            onMouseLeave: handleRowLeaved,
          },
        }}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay,
        }}
        sx={{
          "--DataGrid-overlayHeight": "300px",
        }}
      />
    </Paper>
  );
};
