export const defaultAddRuleGroupLabel = "Add Group";

export enum SupportedActions {
  ADD = "add_exclusion",
  REMOVE_RULE = "remove_rule",
  REMOVE_EXCLUSION = "remove_exclusion",
}

export enum EditorTypes {
  TEXT = "text",
  SELECT = "select",
  MULTISELECT = "multiselect",
}

export const ariaLabels = {
  fixed: {
    operator: "Fixed operator",
    combination: "Fixed combination",
    field: "Fixed field",
  },
  switcher: {
    operator: "Switch operator",
    combination: "Switch combination",
    field: "Switch field",
  },
  valueContainer: "Value container",
  contextMenu: {
    addRule: "Add rule",
    addRuleExclusion: "Add exclusion",
    removeRule: "Remove rule",
    removeRuleExclusion: "Remove exclusion",
  },
  addRuleGroup: "Add rule group",
};

const contextMenuAriaLabels = {
  button: "context-menu-button",
  menu: "context-menu",
};

export const generateContextMenuAriaLabels = (id: string) => ({
  button: `${id}-${contextMenuAriaLabels.button}`,
  menu: `${id}-${contextMenuAriaLabels.menu}`,
});
