import React, { useEffect } from "react";

import { SelfSupplyingResourceListTableProps } from "../types";
import { ResourceListTable } from "../../../../../../common/ResourceListTable/ResourceListTable";
import { useNamesExtractionFromService } from "../../../../../../../shared/hooks/useNamesExtractionFromService";
import { SecretNamesExtractor } from "../../../../../../../shared/utils/ExtractNamesFromService/SecretNamesExtractor";
import { generateUid } from "../../../../../../../shared/utils/generateUid";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type SecretsListTableProps = SelfSupplyingResourceListTableProps;

export const SecretsListTable: React.FC<SecretsListTableProps> = ({
  serviceId,
  namespace,
  cluster,
  searchTerm,
  rowSelection,
  showOnlySelectedRows,
  ...props
}) => {
  const [secretNames, refreshSecretNames, isLoading] =
    useNamesExtractionFromService(serviceId, new SecretNamesExtractor());
  const secrets = secretNames.map((secretName) => ({
    uid: generateUid({ name: secretName, namespace: namespace ?? "", cluster }),
    name: secretName,
  }));

  const filteredSecrets = filterResourceTableBySearchTerm(secrets, searchTerm);

  const unselectableRowNames = getUnselectableRowNames(secrets, rowSelection);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("relatedResourcesSecretsData", !!isLoading),
    [isLoading, reportLoadingState]
  );

  return (
    <ResourceListTable
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredSecrets, rowSelection?.selectedRowNames ?? [])
          : filteredSecrets
      }
      cluster={cluster}
      refreshResults={refreshSecretNames}
      overridingColumns={overridingColumns}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      {...props}
    />
  );
};

const overridingColumns = [
  {
    name: "name",
    clickable: false,
  },
];
