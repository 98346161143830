import { useCallback, useRef, useState } from "react";
import { isEmpty } from "lodash";

import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { Dictionary } from "../../../shared/types/Dictionary";

import { FetchServicesOutput, ServiceInfoWithIndex } from "./types";
import { servicesListToDictionary } from "./servicesListToDictionary";

type UpdateServicesByDiffParams = {
  services: ServiceInfo[] | undefined;
  resetCurrentData: boolean;
};

type UpdateServicesByDiffOutput = Omit<FetchServicesOutput, "fetchMode"> & {
  updateServices: (params: UpdateServicesByDiffParams) => void;
};

type ServicesDictionary = Dictionary<ServiceInfoWithIndex>;

export const useUpdateServicesByDiff = (): UpdateServicesByDiffOutput => {
  const servicesAsDictionary = useRef<ServicesDictionary>({});
  const servicesList = useRef<ServiceInfo[] | undefined>(undefined);
  const [, updateState] = useState({});

  const updateServices = useCallback(
    ({ services, resetCurrentData }: UpdateServicesByDiffParams) => {
      if (!services) return;

      if (isEmpty(servicesAsDictionary.current) || resetCurrentData) {
        // initial load
        servicesList.current = services;
        servicesAsDictionary.current = servicesListToDictionary(
          servicesList.current
        );
        updateState({});
        return;
      }

      const deleteServicesById: string[] = [];

      services.forEach((service) => {
        const serviceId = service.id;
        if (!servicesList.current) {
          return;
        }

        const foundServiceRef = servicesAsDictionary.current[serviceId];

        if (!foundServiceRef) {
          if (service.isDeleted) return;
          // add new service
          const lastIndex = servicesList.current.length ?? 0;
          servicesAsDictionary.current[serviceId] = {
            ...service,
            index: lastIndex,
          };
          servicesList.current?.push(service);
        } else {
          if (service.isDeleted) {
            deleteServicesById.push(service.id);
            return;
          }
          // modify service
          servicesList.current[foundServiceRef.index] = service;
          servicesAsDictionary.current[serviceId] = {
            ...service,
            index: foundServiceRef.index,
          };
        }
      });

      if (deleteServicesById.length > 0) {
        servicesList.current = servicesList.current?.filter(
          ({ id }) => !deleteServicesById.includes(id)
        );
        servicesAsDictionary.current = servicesListToDictionary(
          servicesList.current
        );
      } else {
        // change reference to trigger re-render
        servicesAsDictionary.current = { ...servicesAsDictionary.current };
      }
      updateState({});
    },
    [servicesAsDictionary, servicesList]
  );

  return {
    updateServices,
    servicesDictionary: servicesAsDictionary.current,
    servicesList: servicesList.current,
    isFetching: false,
  };
};
