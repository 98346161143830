import { uniq, compact } from "lodash";
import { ResourceTableModelRow } from "komodor-types";

import { ResourceListResponse } from "@/components/Inspection/InspectionViews/types";

interface BuildUniqueValuesResult {
  allNamespaces: string[];
  allStatuses: string[];
  allLabels: string[];
  allGroups?: string[];
  allIps: string[];
}

interface BuildUniqueValuesConfig {
  withGroups: boolean;
}

export const buildUniqueValues = (
  resourceList: ResourceListResponse,
  config: BuildUniqueValuesConfig = { withGroups: false }
): BuildUniqueValuesResult => {
  return {
    allNamespaces: uniqValuesFromTable("namespace", resourceList.rows),
    allStatuses: uniqValuesFromTable("status", resourceList.rows),
    allLabels: uniq(
      uniqValuesFromTable("labels", resourceList.rows).flatMap((l) =>
        l.split(",")
      )
    ),
    allGroups: config.withGroups
      ? uniqValuesFromTable("group", resourceList.rows)
      : undefined,
    allIps: uniqValuesFromTable("ip", resourceList.rows),
  };
};

const uniqValuesFromTable = (
  column: string,
  rows: ResourceTableModelRow[]
): string[] => compact(uniq(rows.flatMap((r) => r[column]))).sort();
