import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Input,
  IconButton,
  Typography,
} from "@komodorio/design-system/deprecated";
import {
  MagnifyingGlass24,
  ChevronDown16,
  ChevronUp16,
} from "@komodorio/design-system/icons";

import { AriaLabels } from "../../../../shared/config/ariaLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const MAX_SEARCH_TERM_LENGTH = 10_000;

interface SearchInLogProps {
  isSearchFocused: boolean;
  setIsSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchResults: number;
  scrollIndex: number;
  setScrollIndex: React.Dispatch<React.SetStateAction<number>>;
  handleSearchClicked?: () => void;
}

const SearchInLog: React.FC<SearchInLogProps> = ({
  isSearchFocused,
  setIsSearchFocused,
  searchTerm,
  setSearchTerm,
  searchResults,
  scrollIndex,
  setScrollIndex,
  handleSearchClicked,
}) => {
  useEffect(() => {
    setScrollIndex(1);
  }, [searchTerm, setScrollIndex]);
  const handleClick = (increment: boolean) => {
    const incrementNumber = increment ? 1 : -1;
    if (
      scrollIndex + incrementNumber >= 1 &&
      scrollIndex + incrementNumber <= searchResults
    ) {
      setScrollIndex(scrollIndex + incrementNumber);
    }
  };
  return (
    <Container>
      <Input
        autoFocus={isSearchFocused || Boolean(searchTerm)}
        value={searchTerm}
        placeholder="Search log text"
        icon={MagnifyingGlass24}
        width="20rem"
        size="medium"
        onChange={(c) => {
          const searchTerm = c.target.value.substring(
            0,
            MAX_SEARCH_TERM_LENGTH
          );
          setSearchTerm(searchTerm);
        }}
        onBlur={() => setIsSearchFocused(false)}
        onKeyDown={(e) => {
          if (e.key === "ArrowUp" || (e.key === "Enter" && e.shiftKey)) {
            handleClick(false);
          } else if (e.key === "ArrowDown" || e.key === "Enter") {
            handleClick(true);
          }
        }}
        onClick={handleSearchClicked}
        trailingElement={
          searchTerm ? (
            <Typography>
              {searchResults ? scrollIndex : 0}/{searchResults}
            </Typography>
          ) : null
        }
        aria-label={AriaLabels.LogsViewer.SearchLogsInput}
      />
      {searchTerm ? (
        <>
          <IconButton
            icon={ChevronDown16}
            onClick={() => handleClick(true)}
            disabled={scrollIndex >= searchResults}
            noBorder
            aria-label={AriaLabels.LogsViewer.NextMatchButton}
          />
          <IconButton
            icon={ChevronUp16}
            onClick={() => handleClick(false)}
            disabled={scrollIndex <= 1}
            noBorder
            aria-label={AriaLabels.LogsViewer.PreviousMatchButton}
          />
        </>
      ) : null}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SearchInLog;
