import React, { FC, MouseEvent } from "react";
import { XAxisProps, YAxisProps } from "recharts";
import { IconProps } from "@komodorio/design-system/icons";

import {
  DatapointsMap,
  MetricsApiApiV1PodContainerNameCpuTypeGetRequest,
} from "../../generated/metricsApi";
import { EndpointsRequestMap } from "../../shared/hooks/metrics-api/requestResponseMaps";
import { Timeframe } from "../../shared/types/TimeWindow";
import { TickComponent } from "../common/EventsChart/ticks";

import { TooltipItem } from "./Tooltip/tooltipTypes";

export type TimeWindow =
  MetricsApiApiV1PodContainerNameCpuTypeGetRequest["timeWindow"];
export interface DataPoint {
  x: number;
  y: number | null;
}
export interface FullMetricsData {
  limit: DataPoint[];
  request: DataPoint[];
  usage: DataPoint[];
  p90: DataPoint[];
  p95: DataPoint[];
  p99: DataPoint[];
  max: DataPoint[];
}

export type MetricType = "cpu" | "memory";

export type MemoryUnit = "MiB" | "GiB" | "TiB";
export type CpuUnit = "MilliCores" | "Cores";
export interface Metrics {
  memoryOneHour: AggregatedMetricsResponse;
  cpuOneHour: AggregatedMetricsResponse;
  memoryFourHours: AggregatedMetricsResponse;
  cpuFourHours: AggregatedMetricsResponse;
  memoryOneDay: AggregatedMetricsResponse;
  memoryOneWeek: AggregatedMetricsResponse;
  cpuOneDay: AggregatedMetricsResponse;
  cpuOneWeek: AggregatedMetricsResponse;
  memoryCustom: AggregatedMetricsResponse;
  cpuCustom: AggregatedMetricsResponse;
  timeframe: MetricsTimeWindow | undefined;
  setTimeframe: (start?: Date, end?: Date) => void;
}

export enum MetricsGraphType {
  CONTAINER = "container",
  NODE = "node",
  POD = "pod",
  WORKLOAD = "workload",
}

export type TimeEpochs = { fromEpoch: number; toEpoch: number };

export interface MetricsGraphsProps {
  metrics: Metrics;
  endTimestamp: number;
  isMetricsSupported: boolean | null;
  events?: EventData[];
  collapsible?: boolean;
  memoryUnit?: MemoryUnit;
  disableZoom?: boolean;
  graphType?: MetricsGraphType;
  aggregationType?: MetricsAggregationType;
  defaultTab?: MetricsKind;
  paddingInMinutes?: number;
}

type PopoverContent = {
  element: React.ReactNode;
  isInteractive?: boolean; // add timeout to allow the user to move the mouse from the icon to the popover
  shouldCloseOnClickCapture?: (e: MouseEvent<HTMLDivElement>) => boolean;
};

export interface EventData {
  time: number;
  highlight: boolean;
  icon?: FC<IconProps>;
  color?: string;
  popoverContent?: PopoverContent;
}

export interface GraphDataPoint {
  time: number;
  usageBytes?: number | null;
  requestBytes?: number | null;
  limitBytes?: number | null;
  capacityBytes?: number | null;
  allocatableBytes?: number | null;
  p90?: number | null;
  p95?: number | null;
  p99?: number | null;
  max?: number | null;
  recommendationBytes?: number | null;
}

export interface CustomGraphDataPoint {
  time: number;
  [key: string]: number | null;
}

type OmitTime<T> = Omit<T, "time">;
export type MetricName = keyof OmitTime<GraphDataPoint>;

export type FilterOutMetricsMapper = { [key in MetricName]?: true };

export interface MetricsData {
  limit: DataPoint[];
  request: DataPoint[];
  usage: DataPoint[];
  capacity?: DataPoint[];
  p90?: DataPoint[];
  p95?: DataPoint[];
  p99?: DataPoint[];
  max?: DataPoint[];
  conservativeRecommendation?: DataPoint[];
  moderateRecommendation?: DataPoint[];
  aggressiveRecommendation?: DataPoint[];
}

export interface AggregatedMetricsResponse {
  metrics: MetricsData;
  loading: boolean;
  error: unknown | null;
  paused: boolean;
  limitContainersNum?: number;
}

export type MetricsRequestParams = {
  clusterName: string;
  namespace: string;
  podName: string;
  containerName: string;
  endpoint: keyof EndpointsRequestMap;
  endTimestamp: number;
  pause?: boolean;
  expectedPodContainersNum?: number;
  dataCompletionType?: MetricsApiApiV1PodContainerNameCpuTypeGetRequest["dataCompletionType"];
} & ({ timeWindow: TimeWindow } | { fromEpoch: number; toEpoch: number });

export type NodeMetricsRequestParams = {
  clusterName: string;
  nodeName: string;
  endpoint: keyof EndpointsRequestMap;
  endTimestamp: number;
  pause?: boolean;
  expectedPodContainersNum?: number;
} & ({ timeWindow: TimeWindow } | { fromEpoch: number; toEpoch: number });

export enum MetricsKind {
  Memory = "Memory",
  CPU = "CPU",
}

export interface ZoomProps {
  zoomed: boolean;
  onZoom: (value: boolean) => void;
}

type SingleGraphPoints = GraphDataPoint[];

export interface MetricsGraphProps {
  data: SingleGraphPoints;
  zoomProps?: ZoomProps;
  disableZoom?: boolean;
  timelineTicksNum?: number;
  graphHeight?: string;
  disableFormatTick?: boolean;
  issueTime?: number;
  events?: EventData[];
  minimized?: boolean;
  memoryUnit?: MemoryUnit;
  showLinesList?: MetricName[];
  getLinesListByAggregationType?: (
    aggregationType?: MetricsAggregationType
  ) => MetricName[];
  aggregationType?: MetricsAggregationType;
  setTimeframe?: (start: Date, end: Date) => void;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  children?: React.ReactNode;
  getCustomTooltipEntries?: (payload: GraphDataPoint) => TooltipItem[];
  syncId?: string;
}

export interface MultiLineItem {
  name: string;
  data: SingleGraphPoints;
  index: number;
}

export type MultiLinesMetricsGraphProps = Omit<MetricsGraphProps, "data"> & {
  items: MultiLineItem[];
};

export interface TooltipItemProps {
  dataKey: string;
  title: string;
  tooltipItemKey: string;
  icon: React.ReactNode;
  name?: string;
}

export type TickFormatterFn = (tick: string) => string;

export type GraphDisplayProps = {
  dynamicYAxisWidth?: boolean;
};

export type MetricsGraphContainerProps = MetricsGraphProps & {
  children: React.ReactNode;
  tooltipContent: React.FC | React.ComponentClass | React.ReactElement;
  issueTime?: number;
  events?: EventData[];
  ariaLabels: Pick<MetricsAriaLabels, "Graph" | "Name" | "Xaxis" | "Yaxis">;
  tickFormatter: undefined | TickFormatterFn;
  label?: string;
  setTimeframe?: (from: Date, to: Date) => void;
  syncId?: string;
  constrictHeightForEvents?: boolean;
  tickComponent?: TickComponent;
  areaChart?: boolean;
  display?: GraphDisplayProps;
};

export interface MetricsAriaLabels {
  Name: string;
  Graph: string;
  UsageLine: string;
  RequestLine: string;
  LimitLine: string;
  Yaxis: string;
  Xaxis: string;
}
export enum EventType {
  Issue = "Issue",
  Deploy = "Deploy",
}

export enum MetricsAggregationType {
  Avg = "Avg",
  Max = "Max",
  P90 = "P90",
  P95 = "P95",
  P99 = "P99",
}

export const STATIC_METRICS: MetricName[] = [
  "limitBytes",
  "requestBytes",
  "recommendationBytes",
];

export const metricsTypeToName: Partial<
  Record<MetricsAggregationType, MetricName>
> = {
  [MetricsAggregationType.P90]: "p90",
  [MetricsAggregationType.P95]: "p95",
  [MetricsAggregationType.P99]: "p99",
  [MetricsAggregationType.Avg]: "usageBytes",
  [MetricsAggregationType.Max]: "max",
};

export const dataKeyToMetricsDataKey: Record<keyof DatapointsMap, MetricName> =
  {
    p90Utilization: "p90",
    p95Utilization: "p95",
    p99Utilization: "p99",
    avgUtilization: "usageBytes",
    maxUtilization: "max",
  };

export const zeroDate = new Date(0);

export interface MetricsTimeWindow {
  start: Date;
  end: Date;
}

export interface MetricsFullTimeWindow {
  start: Date;
  end: Date;
  timeframe: Timeframe;
}

export const DefaultTimeframeOptions: Timeframe[] = [
  Timeframe.LastHour,
  Timeframe.Last4Hours,
  Timeframe.Last24Hours,
  Timeframe.Last7Days,
];
