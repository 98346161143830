import { muiColors, theme } from "@komodorio/design-system";
import { Tag } from "@komodorio/design-system/deprecated";
import { startCase } from "lodash";
import Typography from "@mui/material/Typography";
import { TypographyProps } from "@mui/material";
import styled from "styled-components";

const StyledTypography = styled(Typography)<{ lineHeight: string }>`
  && {
    line-height: ${({ lineHeight }) => lineHeight};
  }
`;

const TagColor: { [key in TagText]: string } = {
  "new!": muiColors.green[400] ?? "",
  beta: muiColors.orange[400] ?? "",
  soon: muiColors.deepPurple[200] ?? "",
};
const TagTextColor: { [key in TagText]: string } = {
  "new!": theme.background.bgDark ?? "",
  beta: theme.background.bgDark ?? "",
  soon: theme.background.bgDark ?? "",
};
export type TagText = "new!" | "beta" | "soon";
type Size = "medium" | "large";

type FeatureTagProps = {
  tag: TagText;
  overrideTagLabel?: string;
  size?: Size;
  className?: string;
};

const typographyConfig: Record<
  Size,
  {
    variant: TypographyProps["variant"];
    lineHeight: string;
  }
> = {
  medium: {
    variant: "h6",
    lineHeight: "1",
  },
  large: {
    variant: "h5",
    lineHeight: "1.5",
  },
};

export function FeatureTag({
  tag,
  overrideTagLabel,
  size = "medium",
  className,
}: FeatureTagProps) {
  const { variant, lineHeight } = typographyConfig[size];
  return (
    <Tag color={TagColor[tag]} className={className}>
      <StyledTypography
        variant={variant}
        color={TagTextColor[tag]}
        lineHeight={lineHeight}
      >
        {overrideTagLabel || startCase(tag)}
      </StyledTypography>
    </Tag>
  );
}
