import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  QueryBuilder,
  QueryBuilderProps,
} from "@komodorio/design-system/komodor-ui";
import { RuleGroupType } from "react-querybuilder";
import { muiTheme } from "@komodorio/design-system";

import { valueField } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/fields";
import { useResolveScope } from "@/shared/hooks/auth-service/client/rbacPolicies/useResolveScope";
import { queryToResourcesScope } from "@/pages/organization-settings/access-management/PoliciesPage/utils/queryToResourcesScope";
import { prepareResourcesScope } from "@/pages/organization-settings/access-management/PoliciesPage/utils/prepareResourcesScope";
import {
  assignErrorsToQuery,
  doesQueryHaveErrors,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";
import { usePreviewDefinition } from "@/shared/components/PreviewDefintion/usePreviewDefinition";
import { PreviewDefinitionContainer } from "@/shared/components/PreviewDefintion/PreviewDefinitionContainer";
import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";

const QUERY_ERRORS = {
  queryContainsErrors: "Query contains errors",
  conversionError: "Error converting query",
  resolveError: "There was an error resolving the scope",
};

const DEFAULT_FIELDS = [valueField];

export type QueryBuilderPanelProps = {
  ruleBuilderQuery: RuleGroupType;
  setRuleBuilderQuery: (query: RuleGroupType) => void;
} & QueryBuilderProps;

export const QueryBuilderPanel = ({
  ruleBuilderQuery,
  setRuleBuilderQuery,
  ...rest
}: QueryBuilderPanelProps) => {
  const [queryError, setQueryError] = useState<string>("");
  const { scope } = useStatementDrawerContext();

  const { mutateAsync: resolveScope, isLoading } = useResolveScope();

  const onResolveScope = useCallback(async () => {
    const resourceScope = queryToResourcesScope(ruleBuilderQuery);

    return await resolveScope({
      scopes: [prepareResourcesScope(resourceScope, scope)],
      resolveEverythingPatterns: true,
    });
  }, [ruleBuilderQuery, resolveScope, scope]);

  const {
    showPreviewDefinition,
    closePreviewDefinition,
    handlePreviewClick,
    clusters,
    resolveError,
  } = usePreviewDefinition({
    query: ruleBuilderQuery as RuleGroupType,
    resolveScope: onResolveScope,
    initialQueryError: queryError,
  });

  // TODO Lior: move the logic to a custom hook / shared place and use it when changing the query
  useEffect(() => {
    try {
      const queryWithErrors = assignErrorsToQuery(ruleBuilderQuery);
      if (doesQueryHaveErrors(queryWithErrors)) {
        return setQueryError(QUERY_ERRORS.queryContainsErrors);
      }
    } catch (e) {
      return setQueryError(QUERY_ERRORS.conversionError);
    }

    return setQueryError("");
  }, [ruleBuilderQuery]);

  return (
    <Stack rowGap="4px">
      <Box
        sx={{
          border: `1px solid ${muiTheme.palette.divider}`,
          borderRadius: "4px",
          padding: "8px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <QueryBuilder
            query={ruleBuilderQuery}
            onQueryChange={setRuleBuilderQuery}
            fields={DEFAULT_FIELDS}
            isChangeable={false}
            {...rest}
          />
          <PreviewDefinitionContainer
            showPreviewDefinition={showPreviewDefinition}
            onClose={closePreviewDefinition}
            clusters={clusters}
            isLoadingResolveScope={isLoading}
            queryError={resolveError || queryError}
            handlePreviewClick={handlePreviewClick}
            ariaLabels={{
              button: createPolicyAriaLabels.statementForm.previewDefinition,
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
