import React from "react";

import { ViewAllLabel } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/ViewAllActionLabel";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";

export const DEFAULT_MATCHES_WILDCARD_VALUE = "*";

export const scopeTitles: { [key: string]: string } = {
  [Scope.cluster]: "Entire cluster",
  [Scope.namespace]: "Namespaced resources",
  [Scope.cwr]: "Cluster wide resources",
  [Scope["komodor-actions"]]: "Komodor admin",
};

export const infoByScope: { [key: string]: React.ReactNode | string } = {
  [Scope.cluster]: (
    <>
      Applies to <b>all</b> the resource kinds in a cluster and Komodor specific
      areas like cost and add-ons
    </>
  ),
  [Scope.namespace]: `Applies to resources that exist within the scope of a specific namespace`,
  [Scope.cwr]:
    "Applies to resources that are not restricted to a specific namespace and apply to the entire cluster",
  [Scope["komodor-actions"]]:
    "Actions and permissions that relate to Komodor platform management",
};

export const SELECT_ALL_ACTION = {
  id: "select:all",
  label: "Select All",
  children: [],
};

export const VIEW_ALL_ACTION = {
  id: "view:all",
  label: <ViewAllLabel />,
  children: [],
};

export const INITIAL_QUERY = {
  id: "root",
  combinator: "and",
  rules: [
    {
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: "matchesWildcard",
          value: "*",
        },
      ],
    },
    {
      combinator: "and",
      rules: [
        {
          field: "namespace",
          operator: "matchesWildcard",
          value: "*",
        },
      ],
    },
  ],
};

export const INITIAL_CLUSTER_QUERY = {
  id: "root",
  combinator: "and",
  rules: [
    {
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: "matchesWildcard",
          value: "*",
        },
      ],
    },
  ],
};

export const DEFAULT_RESOURCES_SCOPE = {
  clusters: [],
  clustersPatterns: [{ include: "*", exclude: "" }],
  namespaces: [],
  namespacesPatterns: [{ include: "*", exclude: "" }],
  selectors: [],
  selectorsPatterns: [],
};
