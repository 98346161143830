import { useMemo } from "react";
import { AccountPlan } from "komodor-types";
import { isEmpty } from "lodash";

import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useUserMetadata } from "../../../shared/hooks/useUserMetadata/useUserMetadata";

import { useGetJobs } from "./useGetJobs";
import { useGetServices } from "./useGetServices";
import { FetchModeState } from "./types";

export interface ServicesObject {
  services: ServiceInfo[] | undefined;
  jobs: ServiceInfo[] | undefined;
  all: ServiceInfo[] | undefined;
}

type FetchAllServicesOutput = {
  servicesLists: ServicesObject;
  servicesAsDictionary: {
    services: Record<string, ServiceInfo>;
    jobs: Record<string, ServiceInfo>;
    all: Record<string, ServiceInfo>;
  };
  fetchState: FetchModeState;
  isFetching: boolean;
};

export const useFetchAllServices = (): FetchAllServicesOutput => {
  const { accountPlan } = useUserMetadata();
  const flags = useOverridableFlags();
  const {
    fetchServicesFromMaterializedView: fetchServicesFromMaterializedViewFF,
    fetchAllJobsFromResourcesApi: fetchAllJobsFromResourcesApiFF,
    fetchAllServicesFromResourcesApi: fetchAllServicesFromResourcesApiFF,
    fetchJobsFromMaterializedView: fetchJobsFromMaterializedViewFF,
    enableAppViewDynamicScopingByLabels: includeLabels,
    enableAppViewDynamicScopingByAnnotations: includeAnnotations,
  } = flags;
  const shouldPauseRequests = isEmpty(flags);

  const fetchServicesFromMaterializedView =
    accountPlan === AccountPlan.free
      ? false
      : fetchServicesFromMaterializedViewFF;

  const fetchJobsFromMaterializedView =
    accountPlan === AccountPlan.free ? false : fetchJobsFromMaterializedViewFF;

  const {
    servicesList: jobsFromSource,
    servicesDictionary: jobsDictionary,
    fetchMode: jobsFetchMode,
    isFetching: isFetchingJobs,
  } = useGetJobs({
    includeLabels,
    includeAnnotations,
    fetchAllJobsFromResourcesApiFF,
    fetchJobsFromMaterializedView,
    pause: shouldPauseRequests,
  });

  const {
    servicesList: servicesFromSource,
    servicesDictionary,
    fetchMode: servicesFetchMode,
    isFetching: isFetchingServices,
  } = useGetServices({
    includeLabels,
    includeAnnotations,
    fetchAllServicesFromResourcesApiFF,
    fetchServicesFromMaterializedView,
    pause: shouldPauseRequests,
  });

  return useMemo(() => {
    const servicesLists = {
      services: servicesFromSource,
      jobs: jobsFromSource,
      all:
        servicesFromSource && jobsFromSource
          ? [...jobsFromSource, ...servicesFromSource]
          : undefined,
    };
    const servicesAsDictionary = {
      services: servicesDictionary,
      jobs: jobsDictionary,
      all: {
        ...servicesDictionary,
        ...jobsDictionary,
      },
    };
    return {
      servicesLists,
      servicesAsDictionary,
      isFetching: isFetchingJobs || isFetchingServices,
      fetchState: {
        services: servicesFetchMode,
        jobs: jobsFetchMode,
      },
    };
  }, [
    isFetchingJobs,
    isFetchingServices,
    jobsDictionary,
    jobsFetchMode,
    jobsFromSource,
    servicesDictionary,
    servicesFetchMode,
    servicesFromSource,
  ]);
};
