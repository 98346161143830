export const PAGE_SIZE_OPTIONS = [5, 10, 15];

export const INITIAL_RULE = {
  id: "1",
  combinator: "and",
  rules: [
    {
      id: "root",
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: "multiIs",
          value: "*",
        },
      ],
    },
  ],
};

export const INITIAL_DEFINITION = [INITIAL_RULE];
