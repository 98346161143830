import React from "react";
import { EmptyState } from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { ALL_CLUSTERS } from "@/components/workspaces/WorkspacesTopBar/constants";
import { useNavigateToWorkspace } from "@/components/workspaces/WorkspacesTopBar/hooks";

export const HelmChartsEmptyState: React.FC = () => {
  const navigateToWorkspace = useNavigateToWorkspace();
  const navigateToAllClusters = () => navigateToWorkspace(undefined);

  return (
    <EmptyState
      title={
        <Stack rowGap="16px" textAlign="center">
          <Typography variant="h4">
            No Helm charts available in this workspace.
            <br />
            To view Helm charts, switch to a different workspace.
          </Typography>
          <Link
            color="primary"
            underline="none"
            sx={{ cursor: "pointer", fontSize: "14px" }}
            onClick={navigateToAllClusters}
          >
            Switch to {ALL_CLUSTERS.toLowerCase()}
          </Link>
        </Stack>
      }
    />
  );
};
