import { useMemo } from "react";
import { QueryBuilderProps } from "@komodorio/design-system/komodor-ui";

import { keyQBField } from "@/shared/components/QueryBuilder/Fields/KeyQBField";
import { useGetTrackedKeys } from "@/shared/hooks/accounts-service/client/trackedKeys/useGetTrackedKeys";
import { TrackedKey } from "@/generated/accounts";

export const generateRuleValue = (key: Pick<TrackedKey, "type" | "name">) =>
  `${key.type}:${key.name}`;

export const useKeyField = (
  props: QueryBuilderProps,
  isChangeable: boolean
) => {
  const { data: keys } = useGetTrackedKeys({ enabled: true });

  const keysOptions = useMemo(
    () =>
      (keys ?? []).map((key: TrackedKey) => ({
        value: generateRuleValue(key),
        label: key.name,
      })),
    [keys]
  );

  const keyField = useMemo(
    () =>
      keyQBField({
        options: keysOptions,
        isChangeable: isChangeable,
      }),
    [keysOptions, isChangeable]
  );

  return useMemo(() => {
    const existingFields = props.fields || [];
    return [keyField, ...existingFields];
  }, [keyField, props.fields]);
};
