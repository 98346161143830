import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";

import { freeSoloItemPrompt } from "./advancedMultiSelectConstants";

export const convertValueToFreeSoloOption = (value: string) =>
  `${freeSoloItemPrompt}${value}`;

export const getUniqueItems = <T extends MuiSelectionOptionValue>(
  items: MuiSelectionOption<T>[]
): MuiSelectionOption<T>[] => {
  const uniqueValues = new Set<T>();
  return items.filter((item) => {
    if (uniqueValues.has(item.value)) {
      return false;
    } else {
      uniqueValues.add(item.value);
      return true;
    }
  });
};
