import { useEffect, useMemo, useRef } from "react";
import { isEqual } from "lodash";

import { useAppNotificationsStore } from "@/shared/store/appNotificationsStore/appNotificationsStore";
import {
  selectAddNotification,
  selectUpdateNotification,
} from "@/shared/store/appNotificationsStore/appNotificationsStoreSelectors";
import {
  Notification,
  NotificationStatus,
} from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { MinimizedRowProps } from "@/components/AiInvestigation/types";
import { useFindNotificationById } from "@/shared/store/appNotificationsStore/appNotificationsStoreHooks";

export const useAiInvestigationInNotificationsCenter = ({
  data,
  onRemove,
  onMaximize,
}: MinimizedRowProps) => {
  const { useAppNotifications } = useOverridableFlags();
  const addAppNotification = useAppNotificationsStore(selectAddNotification);
  const updateAppNotification = useAppNotificationsStore(
    selectUpdateNotification
  );
  const notificationWasAdded = useRef(false);
  const { session, sessionIdentifier } = data;

  const status = useMemo(() => {
    if (session?.isComplete) {
      return NotificationStatus.Success;
    }
    if (session?.isStuck) {
      return NotificationStatus.Error;
    }
    return NotificationStatus.Loading;
  }, [session?.isComplete, session?.isStuck]);

  const title = useMemo(() => {
    if (status === NotificationStatus.Success) {
      return "Analysis Ready";
    }
    if (status === NotificationStatus.Error) {
      return "Analysis Failed";
    }
    return NotificationStatus.Loading;
  }, [status]);

  const notification: Notification = useMemo(
    () => ({
      id: sessionIdentifier.id,
      message: sessionIdentifier.type,
      title,
      status,
      resource: {
        clusterName: sessionIdentifier.cluster,
        namespace: sessionIdentifier.namespace,
        name: sessionIdentifier.name,
      },
      onClick: () => onMaximize(data),
      onClose: () => onRemove(data),
    }),
    [
      sessionIdentifier.id,
      sessionIdentifier.type,
      sessionIdentifier.cluster,
      sessionIdentifier.namespace,
      sessionIdentifier.name,
      title,
      status,
      onMaximize,
      data,
      onRemove,
    ]
  );

  const existingNotification = useFindNotificationById(sessionIdentifier.id);

  useEffect(() => {
    if (!useAppNotifications) return;
    if (!existingNotification && !notificationWasAdded.current) {
      notificationWasAdded.current = true;
      addAppNotification(notification);
      return;
    }
    if (!isEqual(existingNotification, notification)) {
      updateAppNotification(notification);
    }
  }, [
    existingNotification,
    addAppNotification,
    notification,
    notificationWasAdded,
    updateAppNotification,
    useAppNotifications,
  ]);
};
