import { ComponentProps } from "react";

import { DataGrid } from "components/komodor-ui/DataGrid/DataGrid";

export function getInitialPageConfig(
  pageSize: number,
  initialState?: ComponentProps<typeof DataGrid>["initialState"]
): Pick<ComponentProps<typeof DataGrid>, "initialState" | "pageSizeOptions"> {
  return {
    initialState: {
      ...initialState,
      pagination: { paginationModel: { pageSize } },
    },
    pageSizeOptions: [pageSize],
  };
}
