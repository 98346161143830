import React, { createContext, ReactNode, useState } from "react";

import { Dictionary } from "@/shared/types/Dictionary";
import { CheckType } from "@/generated/reliabilityApi";
import { ViolationsGroupBy } from "@/components/reliability/ReliabilityTypes";

export type AllTabType = "all";
export const ALL_TAB_VALUE: AllTabType = "all";

export type TabsType = CheckType | AllTabType;

type PropsAndContext = {
  issuesBreakdown: Dictionary<number> | undefined;
  uid?: string;
  violationsGroupBy: ViolationsGroupBy;
};

type IssuesTableContextState = PropsAndContext & {
  tabValue: TabsType;
  setTabValue: (tabValue: TabsType) => void;
};

const initialState: IssuesTableContextState = {
  tabValue: ALL_TAB_VALUE,
  setTabValue: () => undefined,
  issuesBreakdown: undefined,
  uid: undefined,
  violationsGroupBy: "Impact",
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const IssuesTableContext =
  createContext<IssuesTableContextState>(initialState);

export type IssuesTableContextProviderProps = PropsAndContext & {
  children: ReactNode;
  initialTabValue?: TabsType;
};

export const IssuesTableContextProvider: React.FC<
  IssuesTableContextProviderProps
> = ({
  children,
  issuesBreakdown,
  initialTabValue,
  uid,
  violationsGroupBy,
}) => {
  const [tabValue, setTabValue] = useState<TabsType>(
    initialTabValue ?? initialState.tabValue
  );

  const contextState: IssuesTableContextState = {
    tabValue,
    setTabValue,
    issuesBreakdown,
    uid,
    violationsGroupBy,
  };

  return (
    <IssuesTableContext.Provider value={contextState}>
      {children}
    </IssuesTableContext.Provider>
  );
};
