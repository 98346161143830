import { useEffect } from "react";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import {
  selectBatchActionsProcessCompleteCbDict,
  selectClearBatchActionsProcessCompleteCb,
} from "@/shared/store/batchActionsStore/batchActionsSelectors";

export const useExecuteSuccessCbOnResponseComplete = (
  bulkId: string,
  isResponseComplete: boolean
) => {
  const batchActionsResponsesSuccessCbDict = useBatchActionsStore(
    selectBatchActionsProcessCompleteCbDict
  );
  const clearBatchActionsSuccessCb = useBatchActionsStore(
    selectClearBatchActionsProcessCompleteCb
  );

  useEffect(() => {
    if (isResponseComplete) {
      const successCb = batchActionsResponsesSuccessCbDict[bulkId];
      if (successCb) {
        successCb();
        clearBatchActionsSuccessCb(bulkId);
      }
    }
  }, [
    batchActionsResponsesSuccessCbDict,
    bulkId,
    clearBatchActionsSuccessCb,
    isResponseComplete,
  ]);
};
