import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState } from "react";

import { RichSessionData } from "@/components/AiInvestigation/useEnrichedSession";
import { WhatHappened } from "@/components/AiInvestigation/AiInvestigationContent/WhatHappened";
import { RelatedEvidence } from "@/components/AiInvestigation/AiInvestigationContent/RelatedEvidence";
import { SuggestedRemediation } from "@/components/AiInvestigation/AiInvestigationContent/SuggestedRemediation";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useAiInvestigationChat } from "@/components/AiInvestigation/AiInvestigationChat/useAiInvestigationChat";
import { ChatBody } from "@/components/AiInvestigation/AiInvestigationChat/ChatBody";
import { ChatFooter } from "@/components/AiInvestigation/AiInvestigationChat/ChatFooter";
import { Feedback } from "@/components/AiInvestigation/AiInvestigationContent/Feedback";
import { KlaudiaRCADemoSection } from "@/components/common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/demo/KlaudiaRCADemoSection";

interface AiInvestigationResultsProps {
  richSession: RichSessionData;
  cluster: string;
  maxHeight?: string;
  showDemo?: boolean;
}

export const AiInvestigationResults: React.FC<AiInvestigationResultsProps> = ({
  richSession,
  cluster,
  maxHeight,
  showDemo,
}) => {
  const { klaudiaChatRca } = useOverridableFlags();

  const resultsRef = useRef<HTMLDivElement>(null);
  const [maxCalculatedHeight, setMaxCalculatedHeight] = useState<number>();
  useEffect(() => {
    if (resultsRef.current) {
      setMaxCalculatedHeight(resultsRef.current.clientHeight);
    }
  }, [resultsRef.current?.clientHeight]);

  const {
    messages,
    handleSendMessage,
    waitingForResponse,
    isGroupingMessages,
    isResponseFailed,
    chatSessionId,
    isChatAllowed,
    input,
    setInput,
    resetChat,
    shouldDisableSend,
    shouldAutoScroll,
  } = useAiInvestigationChat({ cluster, richSession });

  return (
    <Stack height={maxHeight ?? maxCalculatedHeight}>
      <Box
        maxHeight={maxHeight ?? maxCalculatedHeight}
        minHeight="300px"
        overflow="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // Color of the scrollbar thumb
            borderRadius: "4px", // Rounded corners for the thumb
          },
        }}
      >
        {showDemo ? (
          <KlaudiaRCADemoSection />
        ) : (
          <Box
            display="grid"
            gridTemplateColumns="max-content auto"
            gap="10px 32px"
            padding="16px 16px 8px 16px"
            ref={resultsRef}
          >
            <WhatHappened session={richSession} />
            <RelatedEvidence session={richSession} />
            <SuggestedRemediation session={richSession} />
            <Feedback />
          </Box>
        )}
        {!!klaudiaChatRca && (
          <ChatBody
            messages={messages}
            waitingForResponse={waitingForResponse}
            isGroupingMessages={isGroupingMessages}
            isResponseFailed={isResponseFailed}
            chatSessionId={chatSessionId}
            shouldAutoScroll={shouldAutoScroll && !showDemo}
            resetChat={resetChat}
          />
        )}
      </Box>
      {!!klaudiaChatRca && (
        <ChatFooter
          input={input}
          setInput={setInput}
          disableSend={shouldDisableSend}
          handleSendMessage={handleSendMessage}
          resetChat={resetChat}
          isChatAllowed={isChatAllowed}
        />
      )}
    </Stack>
  );
};
