import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";

import nodeAutoscalersPreview from "../assets/node-autoscalers-preview.svg";

import { ariaLabels, description, includedFeatures } from "./constants";

import { FeaturePreviewPage } from "@/shared/components/FeaturePreviewPage";
import { TagText } from "@/shared/components/FeatureTag";

type NodeAutoscalersComingSoonProps = {
  joinButton?: boolean;
  tag?: TagText;
};

export const NodeAutoscalersComingSoon: React.FC<
  NodeAutoscalersComingSoonProps
> = ({ joinButton = true, tag }) => {
  return (
    <FeaturePreviewPage
      ariaLabel={ariaLabels.container}
      title="Node Autoscalers"
      bullets={includedFeatures}
      bulletsHeader="Feature Highlights"
      img={
        <img
          src={nodeAutoscalersPreview}
          width="100%"
          alt="Node Autoscalers Preview"
        />
      }
      tag={tag}
      overrideTagLabel="Coming Soon"
      joinButton={joinButton}
    >
      <Stack>
        <Typography variant="body1" color={muiTheme.palette.text.secondary}>
          {description}
        </Typography>
      </Stack>
    </FeaturePreviewPage>
  );
};
