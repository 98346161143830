import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const ClearLoader: React.FC<{ graphHeight: string }> = ({
  graphHeight,
}) => {
  return (
    <Stack height={graphHeight} alignItems="center" justifyContent="center">
      <Typography variant="body1">Loading Data</Typography>
      <LinesLoader />
    </Stack>
  );
};
