import React from "react";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { DataFreshnessAlert } from "@/components/k8sAddons/components/DataFreshnessAlert";
import {
  useCertificateLoadingColumns,
  useCertificateTableColumns,
} from "@/components/k8sAddons/addons/CertManager/CertificatesPage/hooks/certificateTableHooks";
import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { SEARCH_TABLE_PLACEHOLDER } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { useOpenDrawerOnRowClick } from "@/components/k8sAddons/hooks/table/useOpenDrawerOnRowClick";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  LiveListTableTopSection,
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";

const TableTopSectionContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const CertificatePageTable: React.FC = () => {
  const { searchFilter, searchFieldComponent } = useSearchFieldAsGenericFilter({
    searchFieldProps: { placeholder: SEARCH_TABLE_PLACEHOLDER },
  });

  const response = useFetchLiveStateList({
    ...(searchFilter && { additionalRequestFilters: [searchFilter] }),
  });

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useCertificateLoadingColumns(),
      dataStateColumns: useCertificateTableColumns(),
      tableTitle: "Certificates",
    });

  const openDrawerOnRowClick = useOpenDrawerOnRowClick();

  const ariaLabel = isFetchingData
    ? `${certificatesPageAriaLabels.table} loading`
    : `${certificatesPageAriaLabels.table} content`;
  return (
    <PageTableContainer>
      <TableTopSectionContainer>
        <LiveListTableTopSection>
          <Typography variant="h4">{title}</Typography>
          {searchFieldComponent}
        </LiveListTableTopSection>
        <DataFreshnessAlert text="The certificates data may be delayed by ~15 minutes" />
      </TableTopSectionContainer>

      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...useCommonLiveStateTableProps(isFetchingData)}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        onRowClick={openDrawerOnRowClick}
      />
    </PageTableContainer>
  );
};
