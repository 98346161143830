import React from "react";

import { PageFiltersContainer } from "@/components/k8sAddons/styles";
import { AddonFilterHandler } from "@/components/k8sAddons/components/AddonFilterHandler/AddonFilterHandler";
import { sharedFilterProps } from "@/components/k8sAddons/constants/filters";

export const HelmChartsFilters: React.FC = () => {
  return (
    <PageFiltersContainer>
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={"clusterName"}
        label={"Cluster"}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={"namespace"}
        label={"Namespace"}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={"status"}
        label={"Chart Status"}
      />
    </PageFiltersContainer>
  );
};
