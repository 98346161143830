import { useRef } from "react";
import semver from "semver";

import { REQUIRED_AGENT_VERSION_ARGO_WORKFLOWS } from "@/shared/utils/agent/agent";
import { ARGO_WF_ENABLED } from "@/shared/constants/localStorageKeys";
import { AgentProperties } from "@/shared/hooks/useAgentInfo/useAgentInfo";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { LocalStorageItem } from "@/shared/utils/localStorageSettings";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

export const useAgentSupportArgoWorkflows = (
  agentInfo: AgentProperties | null
): boolean => {
  if (agentInfo === null) {
    return false;
  }
  return (
    semver.gte(
      agentInfo?.version ?? "0.0.0",
      REQUIRED_AGENT_VERSION_ARGO_WORKFLOWS
    ) && !!agentInfo?.resources?.argoWorkflows
  );
};

export const useIsArgoWorkflowsEnabledForAccount = () => {
  const { accountId } = useUserMetadata();
  const localStorageItem = useRef(
    new LocalStorageItem(`${ARGO_WF_ENABLED}-${accountId}`)
  );
  return useStateInLocalStorage(localStorageItem.current, "false");
};
