import { Plus16 } from "@komodorio/design-system/icons/system/Plus16";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";

type Props = {
  label: string;
  onClick: () => void;
};

export const ActionFooter: React.FC<Props> = ({ label, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="contained"
        startIcon={<Plus16 />}
        size="small"
        sx={{ padding: "5px 8px" }}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  );
};
