import { useMemo } from "react";

import HelmChart from "../helmChart";

import {
  isHelmChartUnhealthy,
  shouldShowRcaForHelmChart,
} from "@/components/Inspection/Helm/utils";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useInitBackgroundRca = (chartStatus: string): boolean => {
  const flags = useOverridableFlags();
  const { helmChartDrawerReportRcaIfHealthy } = flags;
  const isShowingRca = shouldShowRcaForHelmChart(flags, chartStatus);
  const isUnhealthy = isHelmChartUnhealthy(chartStatus);

  return !isShowingRca && (!!helmChartDrawerReportRcaIfHealthy || isUnhealthy);
};

export const useSelectedTabIndex = (
  selectedChart: HelmChart,
  selectedTab: string | null
) => {
  const featureFlags = useOverridableFlags();

  return useMemo(() => {
    const index = selectedChart.drawerTabs.findIndex(
      (tab) => tab.label === selectedTab
    );
    if (index < 0) {
      const isUnhealthy = isHelmChartUnhealthy(selectedChart.status);
      const shouldShowRca = shouldShowRcaForHelmChart(
        featureFlags,
        selectedChart.status
      );
      const shouldShowRcaAsDefaultTab = shouldShowRca && isUnhealthy;
      return shouldShowRcaAsDefaultTab ? 1 : 0;
    }
    return index;
  }, [
    featureFlags,
    selectedChart.drawerTabs,
    selectedChart.status,
    selectedTab,
  ]);
};
