import React from "react";

import { LinesLoader } from "../../../common/loaders/Line";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { Panel } from "../../../Inspection/styles";
import { useAtmInServiceTabs } from "../useAtmInServiceTabs";
import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";

import PodsContentAtm, { PodsContentOld } from "./PodsData";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";

interface PodsTabProps {
  resource: KomodorServiceResourceKind;
}

const PodsTabContent: React.FC<PodsTabProps> = ({ resource }) => {
  const agentId = useActiveAgent(resource.cluster);
  const shouldUseAtm = useAtmInServiceTabs(resource);
  if (shouldUseAtm === undefined || !agentId || !resource.selector) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return shouldUseAtm ? (
    <PodsContentAtm agentId={agentId} resource={resource} />
  ) : (
    <PodsContentOld agentId={agentId} resource={resource} />
  );
};

const PodsTab: React.FC<PodsTabProps> = ({ resource }) => {
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Pods);

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <PodsTabContent resource={resource} />
    </DatadogReportLoadingTimeContextProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PodsTab;
