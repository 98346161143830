import { useContext } from "react";

import {
  InspectionBatchActionsContext,
  InspectionBatchActionsContextState,
} from "@/components/common/ResourceListTable/batchActions/context/InspectionBatchActionsContext";

export const useInspectionBatchActionsContext =
  (): InspectionBatchActionsContextState =>
    useContext(InspectionBatchActionsContext);
