import {
  ComparisonTable,
  ComparisonTableProps,
} from "@komodorio/design-system/komodor-ui";
import { useMemo } from "react";

import { DriftType } from "../context/types";
import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { ComparisonContainer } from "../Services/Attributes/types";
import { ComparisonHelmResourcesWithResources } from "../Helm/types";

import { EmptyStateComparedItems } from "./EmptyStateComparedItems";

interface TableNoDataProps<
  T extends ComparisonContainer | ComparisonHelmResourcesWithResources
> {
  isLoading: boolean;
  isError: boolean;
  errorStateElement: React.ReactNode;
  maxComparedItems: number;
  driftType: DriftType;
  attributes: ComparisonTableProps<T>["attributes"];
}

export const TableNoData = <
  T extends ComparisonContainer | ComparisonHelmResourcesWithResources
>({
  isLoading,
  isError,
  errorStateElement,
  maxComparedItems,
  driftType,
  attributes,
}: TableNoDataProps<T>) => {
  const { comparedSelections } = useDriftComparisonContext();
  const compared = useMemo(() => {
    return (
      comparedSelections?.map(() => ({
        isMissingData: false,
        isLoading: true,
      })) ?? []
    );
  }, [comparedSelections]);
  return (
    <ComparisonTable
      title={driftType === DriftType.Services ? "Container" : "Resource"}
      isStacked
      limit={maxComparedItems}
      isLoading={isLoading}
      attributes={attributes}
      baseline={undefined}
      compared={compared}
      noBaselineElement={undefined}
      noComparedItemsElement={
        isLoading ? undefined : <EmptyStateComparedItems type={driftType} />
      }
      isError={isError}
      errorStateElement={errorStateElement}
    />
  );
};
