import React, { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";

import {
  CustomGraphDataPoint,
  GraphDisplayProps,
  TooltipItemProps,
} from "../types";
import {
  GraphBackgroundColor,
  GraphChartMargin,
  GraphGridColor,
} from "../styles";
import { Tooltip } from "../Tooltip/Tooltip";
import { defaultFormatTooltipTick } from "../utils";
import { GenericLegend, LegendItem } from "../GenericLegend/GenericLegend";

import { useGraphProps } from "./hooks";
import { timeXAxisProps, yAxisProps } from "./graphProps";

import { SmartPopper } from "@/components/common/EventsChart/SmartPopper";
import { useDynamicYAxisWidth } from "@/components/Metrics/graphs/hooks/useDynamicYAxisWidth";
import { calculateTimeXAxis } from "@/components/Metrics/graphs/timeGraphUtils";

const MIN_WIDTH_WITH_1_INTERVAL = 580;
const MIN_WIDTH_WITH_2_INTERVAL = 400;

const Container = styled.div`
  height: 100%;
`;

const LegendContent = styled(GenericLegend)`
  flex-wrap: wrap;
  margin-left: 0;
  row-gap: 2px;
`;

export type MetricArea = Partial<Area>;

type Props = {
  className?: string;
  data: CustomGraphDataPoint[];
  areas: MetricArea[];
  yAxisLabel?: string;
  tooltipItems: TooltipItemProps[];
  legendItems?: LegendItem[];
  display?: GraphDisplayProps;
  xAxisDomainStart?: number;
};

type TicksOverrideCalculationProps = {
  ticks: number[];
  predefinedStartTime?: number;
  data: CustomGraphDataPoint[];
  interval: number;
  TickComponent: React.ReactElement;
};

function calculateXAxisOverrideForFixedStart(
  props: TicksOverrideCalculationProps
): {
  ticks?: number[];
  interval?: number;
  TickComponent: React.ReactElement;
} {
  const { data, predefinedStartTime, ticks, interval, TickComponent } = props;
  if (predefinedStartTime === undefined) {
    return { ticks, interval, TickComponent };
  }
  const { ticks: overrideTicks, tickComponent: overrideTickComponent } =
    calculateTimeXAxis(data, {
      predefinedStartTime,
    });
  return {
    ticks: overrideTicks,
    TickComponent: overrideTickComponent ?? TickComponent,
  };
}

export const StackedAreaChart: React.FC<Props> = ({
  data,
  areas,
  yAxisLabel,
  tooltipItems,
  className,
  legendItems,
  display,
  xAxisDomainStart,
}) => {
  const [chartInterval, setChartInterval] = useState<number>(1);
  const propsForYAxis = yAxisProps(yAxisLabel);
  const { tickCalculatingFormatter, yAxisCalculatedWidth } =
    useDynamicYAxisWidth({ fontSize: propsForYAxis.tick.fontSize });

  const { containerProps, chartProps, xAxisProps, tooltipProps } =
    useGraphProps(data);

  const onResize = (width: number) => {
    if (width <= MIN_WIDTH_WITH_2_INTERVAL) {
      setChartInterval(2);
    } else if (width <= MIN_WIDTH_WITH_1_INTERVAL) {
      setChartInterval(1);
    } else {
      setChartInterval(0);
    }
  };
  const { ticks, interval, TickComponent } =
    calculateXAxisOverrideForFixedStart({
      data,
      ticks: xAxisProps.ticks,
      interval: chartInterval,
      predefinedStartTime: xAxisDomainStart,
      TickComponent: xAxisProps.tick,
    });

  return (
    <Container className={className} {...containerProps}>
      <ResponsiveContainer width="100%" height="100%" onResize={onResize}>
        <AutoSizer>
          {({ width, height }) => (
            <AreaChart
              width={width}
              height={height}
              data={data}
              margin={GraphChartMargin}
              {...chartProps}
            >
              <CartesianGrid
                stroke={GraphGridColor}
                strokeWidth="1"
                fill={GraphBackgroundColor}
              />
              <XAxis
                {...timeXAxisProps}
                {...xAxisProps}
                interval={interval}
                ticks={ticks}
                tick={TickComponent}
                domain={[xAxisDomainStart ?? "dataMin", "dataMax"]}
              />
              <YAxis
                tickFormatter={
                  display?.dynamicYAxisWidth
                    ? tickCalculatingFormatter
                    : undefined
                }
                width={
                  display?.dynamicYAxisWidth ? yAxisCalculatedWidth : undefined
                }
                {...propsForYAxis}
              />
              <RechartTooltip
                isAnimationActive={false}
                content={(props) => (
                  <SmartPopper
                    {...props}
                    tooltipContent={(tooltipProps) => (
                      <Tooltip
                        tooltipProps={tooltipProps}
                        dataTransformFunction={defaultFormatTooltipTick}
                        tooltipItems={tooltipItems}
                      />
                    )}
                    {...tooltipProps}
                  />
                )}
              />
              {legendItems && (
                <Legend
                  align="center"
                  wrapperStyle={{
                    paddingTop: 12,
                    paddingBottom: 8,
                    bottom: 12,
                  }}
                  content={<LegendContent items={legendItems} />}
                />
              )}
              {areas}
            </AreaChart>
          )}
        </AutoSizer>
      </ResponsiveContainer>
    </Container>
  );
};
