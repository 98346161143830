// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import HelpOutline from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { muiColors } from "themes";

type ColumnHeaderWithTooltipProps = {
  headerName: string;
  tooltipText: string;
};

export const ColumnHeaderWithTooltip: React.FC<
  ColumnHeaderWithTooltipProps
> = ({ headerName, tooltipText }) => {
  return (
    <>
      <Typography variant="h5">{headerName}</Typography>
      <Tooltip title={tooltipText}>
        <HelpOutline
          sx={{ color: muiColors.gray[500], height: "20px", width: "20px" }}
        />
      </Tooltip>
    </>
  );
};
