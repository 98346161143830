import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface Props {
  ariaLabel?: string;
  onClose: () => void;
}

export const CloseButton: React.FC<Props> = ({
  ariaLabel = "close",
  onClose,
}) => {
  return (
    <IconButton aria-label={ariaLabel} size="small" onClick={onClose}>
      <Close fontSize="medium" color="action" />
    </IconButton>
  );
};
