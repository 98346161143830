import { useMemo } from "react";

import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { useMonitorsAPIGet } from "../../../../shared/hooks/monitors-api/client";
import {
  MONITORS_SEARCH_ENDPOINT,
  MONITOR_ISSUE_ID_ENDPOINT,
} from "../../../../shared/hooks/monitors-api/requestResponseMaps";
import {
  MonitorsApiApiV1TypeIssuesGetRequest,
  MonitorType,
  MonitorsResponse,
} from "../../../../generated/monitorsApi";
import { useGetMonitorIssues } from "../../../../shared/hooks/monitors-api/client/issues/useGetMonitorIssues";
import { useGetMonitorIssuesByType } from "../../../../shared/hooks/monitors-api/client/issues/useGetMonitorIssuesByType";
import {
  WorkflowConfigType,
  WorkflowRunWithResults,
} from "../../../monitorsView/common/types";

const defaultMonitorIssuesTypes = [
  MonitorType.Availability,
  MonitorType.Node,
  MonitorType.Pvc,
  MonitorType.Workflow,
];
export type MonitorsResponseData = MonitorsResponse["data"];

const fieldsFromAPI: MonitorsApiApiV1TypeIssuesGetRequest["fields"] = [
  "id",
  "type",
  "shortResourceName",
  "sourceEventId",
  "closeReason",
  "closedAt",
  "services",
  "workflowConfiguration",
  "resultsSummary",
  "cluster",
  "namespace",
  "eventTime",
  "reasons",
];

const useWorkflowIssues = (
  timeWindow?: Pick<TimeWindow, "start" | "end">,
  serviceIds?: string[]
): [MonitorsResponseData | undefined, boolean] => {
  const { data: monitorIssues, loading } = useMonitorsAPIGet(
    MONITORS_SEARCH_ENDPOINT,
    {
      types: defaultMonitorIssuesTypes,
      fields: fieldsFromAPI,
      includeOpen: true,
      includeClose: true,
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      serviceIds: serviceIds,
    }
  );
  return useMemo(
    () => [monitorIssues?.data, loading],
    [loading, monitorIssues?.data]
  );
};

export const useWorkflowIssuesQuery = (
  timeWindow?: Pick<TimeWindow, "start" | "end">,
  serviceIds?: string[],
  monitorIssuesTypes: MonitorType[] = defaultMonitorIssuesTypes
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [MonitorsResponseData | undefined, boolean] => {
  const { data: monitorIssues, isLoading } = useGetMonitorIssues({
    types: monitorIssuesTypes,
    fields: fieldsFromAPI,
    includeOpen: true,
    includeClose: true,
    fromEpoch: timeWindow?.start.getTime().toString(),
    toEpoch: timeWindow?.end.getTime().toString(),
    serviceIds: serviceIds,
  });
  return useMemo(
    () => [monitorIssues?.data, isLoading],
    [isLoading, monitorIssues?.data]
  );
};

export const useNodeIssuesByIdsFromAPI = (
  issueIds: string[]
): [MonitorsResponseData, boolean] => {
  const { data, isFetching } = useGetMonitorIssuesByType(
    {
      ids: issueIds,
      type: WorkflowConfigType.NodeIssue,
      fields: fieldsFromAPI,
    },
    issueIds.length > 0
  );

  return useMemo(
    () => [data?.data || [], isFetching],
    [data?.data, isFetching]
  );
};

export const useMonitorRunDataById = (runId: string, type: string) => {
  return useMonitorsAPIGet(MONITOR_ISSUE_ID_ENDPOINT, {
    id: runId,
    type: type as MonitorType,
    fields: [
      "id",
      "type",
      "results",
      "shortResourceName",
      "sourceEventId",
      "closeReason",
      "closedAt",
      "services",
      "workflowConfiguration",
      "resultsSummary",
      "cluster",
      "namespace",
      "eventTime",
      "reasons",
      "triageData",
    ],
  });
};

export type MonitorRunResponse = {
  loading: boolean;
  data: WorkflowRunWithResults | null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useWorkflowIssues;
