import Stack from "@mui/material/Stack";
import React, { useEffect } from "react";

import { createGraphContainerProps } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/createGraphContainerProps";
import { MetricGraph } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/MetricGraph";
import { useNodesMetrics } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/useNodesMetrics";
import { Title } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import { overProvisionedAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProviosionedAriaLabel";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useMemoryAndCPUXAxisConfig } from "@/components/k8sAddons/addons/NodeAutoscalers/hooks/useMemoryAndCpuXAxisConfig";
import { ClearLoader } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/ClearLoader";

type OverProvisionedNodeUtilizationContainerProps = {
  cluster: string;
};

export const OverProvisionedNodeUtilizationContainer: React.FC<
  OverProvisionedNodeUtilizationContainerProps
> = (props) => {
  const { cluster } = props;
  const { cpuData, error, isFetching, memoryData, timeRange } = useNodesMetrics(
    {
      clusterNames: [cluster],
      isDataFetchEnabled: true,
    }
  );

  const graphContainerProps = {
    ...createGraphContainerProps({
      hasError: !!error,
      cluster,
      isLoading: isFetching,
    }),
    renderEmptyStateCB: ClearLoader,
  };

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(() => {
    reportLoadingState("OverProvisionedNodeUtilizationContainer", isFetching);
  }, [isFetching, reportLoadingState]);

  const { cpuXAxisConfig, memoryXAxisConfig } = useMemoryAndCPUXAxisConfig({
    cpuData: cpuData.allocated,
    memoryData: memoryData.allocated,
    fromEpoch: timeRange.fromEpoch,
    numOfTicks: 4,
  });

  return (
    <div aria-label={overProvisionedAriaLabels.nodeUtilizationGraph.container}>
      <Title>Nodes utilization trend (last 7d)</Title>
      <Stack direction="row" gap={3}>
        <MetricGraph
          graphContainerProps={graphContainerProps}
          metric="cpu"
          metricData={cpuData}
          showLabel={false}
          xAxisConfig={cpuXAxisConfig}
          withBorder
        />
        <MetricGraph
          graphContainerProps={graphContainerProps}
          metric="memory"
          metricData={memoryData}
          showLabel={false}
          xAxisConfig={memoryXAxisConfig}
          withBorder
        />
      </Stack>
    </div>
  );
};
