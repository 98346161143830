import React, { useMemo } from "react";

import { StatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/StatementDrawerContext";
import { RbacPolicyStatementsInner } from "@/generated/auth";
import { getScopeFromStatement } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getScopeFromStatement";
import { useRbacActionsContext } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/useRbacActionsContext";

export const StatementDrawerProvider: React.FC<{
  open?: boolean;
  statement?: RbacPolicyStatementsInner;
  children?: React.ReactNode;
}> = ({ open, statement, children }) => {
  const { globalActions } = useRbacActionsContext();
  const [scope, setScope] = React.useState(
    getScopeFromStatement(globalActions, statement)
  );

  const state = useMemo(
    () => ({
      scope,
      setScope,
      open,
      statement,
    }),
    [open, scope, statement]
  );

  return (
    <StatementDrawerContext.Provider value={state}>
      {children}
    </StatementDrawerContext.Provider>
  );
};
