import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TypographyVariant } from "@mui/material";

import { muiTheme } from "../../../themes";

import { ariaLabels } from "./constants";

const boxTypeToVariant: { [key: string]: TypographyVariant } = {
  field: "h5",
  combination: "overline",
  operator: "body2",
};

export const ReadonlyBox = ({
  label,
  boxType,
  hidden,
}: {
  label: string;
  boxType: "field" | "combination" | "operator";
  hidden?: boolean;
}) => {
  return (
    <Box
      sx={{
        borderRadius: "4px",
        backgroundColor: muiTheme.palette.background.default,
        height: "fit-content",
        padding: "6px 8px",
        visibility: hidden ? "hidden" : "visible",
      }}
      aria-label={`${ariaLabels.fixed[boxType]} ${label}`}
    >
      <Typography variant={boxTypeToVariant[boxType]}>{label}</Typography>
    </Box>
  );
};
