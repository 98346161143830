import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DaemonSet from "../../resources/daemonset";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../../resources/deployment";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import StatefulSet from "../../resources/statefulset";
import { useIsNetworkMapperEnabled } from "../../../ResourceDependenciesPage/hooks/useIsNetworkMapperEnabled";

import { UpdateNetworkMapperAlert } from "./UpdateNetworkMapperAlert/UpdateNetworkMapperAlert";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DependenciesTabContent } from "./DependenciesTabContent";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DeprecatedNetworkMapperAlert } from "@/components/ResourceView/tabs/DependenciesTab/SunsetAlert";

export interface DependenciesTabProps {
  resource: Deployment | StatefulSet | DaemonSet;
}

export type Resource = DependenciesTabProps["resource"];

export const DependenciesTab: React.FC<DependenciesTabProps> = ({
  resource,
}) => {
  const isUpgradeAgentNeeded = !useIsNetworkMapperEnabled(resource.agentId);
  const { shouldShowDeprecatingNetworkMapper } = useOverridableFlags();

  return (
    <>
      {shouldShowDeprecatingNetworkMapper && <DeprecatedNetworkMapperAlert />}
      {isUpgradeAgentNeeded && !shouldShowDeprecatingNetworkMapper && (
        <UpdateNetworkMapperAlert />
      )}
      {!isUpgradeAgentNeeded && <DependenciesTabContent resource={resource} />}
    </>
  );
};
