import { useOverridableFlags } from "../../context/featureFlags/OverridableFlags";
import { doesAgentVersionSupportLivePodLogs } from "../../utils/agent/agent";
import { parseAgentToAgentProperties } from "../useAgentInfo/useAgentInfo";

import { useActiveAgents } from "@/shared/hooks/useAgents";

export const useShouldUseLivePodsLogs = (clusterName: string) => {
  const { useLivePodLogs } = useOverridableFlags();
  const agents = useActiveAgents();
  const agent = agents?.find((agent) => agent.clusterName === clusterName);

  return (
    Boolean(useLivePodLogs) &&
    doesAgentVersionSupportLivePodLogs(parseAgentToAgentProperties(agent))
  );
};
