import { useQuery } from "@tanstack/react-query";

import { useWorkspaceQueryKey } from "../workspaces-api/useWorkspaceQueryKey";

import { useK8sAddonsApiClient } from "./apiClient";

import {
  AddonsApiGetEntityResetFiltersRequest,
  EntityResetFiltersResponse,
  getEntityListUrl,
  getEntityResetFiltersUrl,
} from "@/generated/addonsApi";
import { getReactQueryOptions } from "@/shared/utils/react-query/getReactQueryOptions";
import { QueryOptions } from "@/shared/constants/reactQueryOptions";

export const GET_ADDONS_LIVE_STATE_RESET_FILTERS =
  "/api/v1/addons/:addon/:entity/reset-filters";

export const useGetEntityLiveStateResetFilters = (
  params: AddonsApiGetEntityResetFiltersRequest,
  queryOptions?: QueryOptions
) => {
  const apiClient = useK8sAddonsApiClient();

  let url = "";
  try {
    url = getEntityListUrl(params, apiClient.defaults.baseURL ?? "");
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  const keys = useWorkspaceQueryKey([url, params]);

  return useQuery(
    keys,
    async () =>
      await apiClient.get<EntityResetFiltersResponse>(
        getEntityResetFiltersUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    getReactQueryOptions<EntityResetFiltersResponse>(queryOptions, url)
  );
};
