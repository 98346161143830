import { createContext } from "react";
import noop from "lodash/noop";

export type PaginationContextProps = {
  pageSize: number;
};

export type PaginationContextState = PaginationContextProps & {
  setPageSize: (pageSize: number) => void;
};

export const PaginationContext = createContext<PaginationContextState>({
  pageSize: 0,
  setPageSize: noop,
});
