import { Node } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";
import { KubernetesResource } from "./KubernetesResource";
import { UnschedulableNode } from "./constants";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/SupportedResourcesKinds";

export const KubernetesNodesResource: KubernetesResource = {
  NameInK8S: "nodes",
  Group: "nodes",
  NavBarName: "Nodes",
  PresentName: "Nodes",
  Namespaced: false,
  ShowStatusFilter: true,
  CustomColumns: [UnschedulableNode],
  UnnecessaryColumns: [
    "External-IP",
    "Internal-IP",
    "OS-image",
    "Kernel-version",
    "Container-runtime",
    "Unschedulable",
    "Namespace",
  ],
  DefaultSort: {
    ColumnName: "status",
    Direction: Direction.up,
  },
  CustomSortOrder: {
    status: {
      NetworkUnavailable: 0,
      NotReady: 0,
      DiskPressure: 1,
      MemoryPressure: 1,
      PIDPressure: 1,
      Ready: 2,
    },
  },
  SupportedActions: [AvailableActions.CordonUncordon, AvailableActions.Drain],
  Kind: SupportedResourcesKinds.KubernetesNodesResource,
  Icon: Node,
  Headers: [
    {
      name: "Name",
    },
    {
      name: "Age",
    },
    {
      name: "Roles",
    },
    {
      name: "Status",
    },
    {
      name: "Version",
    },
  ],
  hasLabels: true,
};
