import { AffectedDataRow } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/ImpactedWorkloadsSectionTableBuilder";
import {
  ServiceTerminationImpactData,
  StandardTerminationImpactData,
} from "@/generated/reliabilityApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

export function getUnhealthyServicesRowData(
  unhealthyServices: ServiceTerminationImpactData[]
): AffectedDataRow[] {
  return unhealthyServices.map((unhealthyService) => {
    const { name = "", kind = "" } =
      parseKomodorUid(unhealthyService.komodorUid) ?? {};
    const { availabilityIssuesCount, terminatedPodsCount, komodorUid } =
      unhealthyService;
    return {
      name,
      kind,
      availabilityIssuesCount,
      terminatedPodsCount,
      komodorUid,
    };
  });
}

export function getAffectedJobsRowData(
  affectedJobs: StandardTerminationImpactData[]
): AffectedDataRow[] {
  return affectedJobs.map((affectedJob) => {
    const { name = "", kind = "" } =
      parseKomodorUid(affectedJob.komodorUid) ?? {};
    const { terminatedPodsCount, komodorUid } = affectedJob;
    return {
      name,
      kind,
      terminatedPodsCount,
      komodorUid,
    };
  });
}

export function getAffectedWorkflowsRowData(
  affectedWorkflows: StandardTerminationImpactData[]
): AffectedDataRow[] {
  return affectedWorkflows.map((affectedWorkload) => {
    const { name = "", kind = "" } =
      parseKomodorUid(affectedWorkload.komodorUid) ?? {};
    const { terminatedPodsCount, komodorUid } = affectedWorkload;
    return {
      name,
      kind,
      terminatedPodsCount,
      komodorUid,
    };
  });
}
