// https://app.clickup.com/t/86c2dqm28
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable max-lines */
import styled from "styled-components";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import { ChevronDown16, ChevronRight16 } from "../../../icons";
import { muiColors, muiTheme } from "../../../themes";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { Status } from "../Status/Status";

import { ComparisonAriaLabels } from "./ariaLabels";

const CELL_DIMENSIONS = 174;

export type Compared<T extends Record<string, unknown>> = {
  isLoading: boolean;
  isMissingData: boolean;
  data?: Partial<{
    [K in keyof T]: { value?: T[K]; isDiff: boolean; isEmpty?: boolean };
  }> | null;
};

export type RowData<T extends Record<string, unknown>> =
  | {
      isBaseline: true;
      data: T;
    }
  | {
      isBaseline: false;
      data: Compared<T>;
    };

export type ComparisonTableProps<T extends Record<string, unknown>> = {
  className?: string;
  isStacked?: boolean;
  title: string;
  titleLeftElement?: React.ReactNode;
  titleRightElement?: React.ReactNode;
  attributes: {
    headerName: string;
    field: keyof T;
    renderCell?: (data: RowData<T>) => React.ReactNode;
    tooltipTitleBasedOnData?: (data: Compared<T>) => string;
    enableClickBasedOnData?: (data: RowData<T>) => boolean;
    onClick?: (
      data: RowData<T> & {
        column: number;
      }
    ) => void;
    cellBodyClassName?: string;
    hideValueIfDifferent?: boolean;
    showValueAlways?: boolean;
    isExistanceComparison?: boolean;
    hoverableRightItem?: (data: Compared<T>) => React.ReactNode;
    optional?: boolean;
  }[];
  collapsible?: boolean;
  isLoading?: boolean;
  baseline: T | undefined;
  compared: Compared<T>[];
  noBaselineElement: React.ReactNode;
  noComparedItemsElement: React.ReactNode;
  missingComparedDataElement?: React.ReactNode;
  isError?: boolean;
  errorStateElement?: React.ReactNode;
  limit?: number;
  initialIsOpen?: boolean;
};

const BorderedTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    box-sizing: border-box;
    max-height: ${CELL_DIMENSIONS}px;
    vertical-align: top;
    border-top: 1px solid ${muiTheme.palette.divider};
    background-color: ${muiTheme.palette.common.white};
    word-wrap: break-word;
    padding: 16px 10px;
    border-right: 1px solid ${muiTheme.palette.divider};

    min-width: ${CELL_DIMENSIONS}px;
    max-width: ${CELL_DIMENSIONS}px;

    &:nth-child(2) {
      background-color: ${muiColors.gray[25]};
    }
  }
`;

const GrowTableCell = styled(BorderedTableCell)`
  && {
    border-left: none;
    border-right: none;
  }
`;

const BodyTableCell = styled(BorderedTableCell)<{
  isDiff?: boolean;
  isSame?: boolean;
  isMissing?: boolean;
}>`
  max-height: ${CELL_DIMENSIONS}px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 16px 10px;
  ${({ isDiff, isSame, isMissing }) =>
    isDiff || isSame || isMissing
      ? `
      && {
        vertical-align: middle;
      }`
      : ""}
  ${({ isDiff }) =>
    isDiff
      ? `
      && { 
        background-color: ${muiTheme.palette.error.light};
      }`
      : ""}
  &:hover .hoverable-right-item {
    visibility: visible;
  }
`;

const StickyBodyTableCell = styled(BorderedTableCell)<{
  left?: number;
}>`
  position: sticky;
  left: ${({ left }) => left ?? 0}px;
`;

const Header = styled(Box)`
  background-color: ${muiTheme.palette.common.white};
`;

const Root = styled(Box)<{ isStacked?: boolean }>`
  border: 1px solid ${muiTheme.palette.divider};
  border-radius: 4px;
  overflow-y: hidden;
  ${(props) =>
    props.isStacked &&
    `
    &:not(:last-child) {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
`;

export function ComparisonTable<T>({
  className,
  isStacked = false,
  title,
  titleLeftElement,
  titleRightElement,
  attributes,
  baseline,
  compared,
  noBaselineElement,
  noComparedItemsElement,
  collapsible = false,
  isLoading = false,
  missingComparedDataElement,
  isError = false,
  errorStateElement,
  limit,
  initialIsOpen = true,
}: ComparisonTableProps<T>) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const renderCell = ({
    index,
    isBaseline,
    data,
    attribute,
  }: {
    index: number;
    attribute: ComparisonTableProps<T>["attributes"][number];
  } & RowData<T>) => {
    if (isLoading || (!isBaseline && (data as Compared<T>).isLoading)) {
      return <Skeleton variant="text" />;
    }
    if (
      index > 0 &&
      !isLoading &&
      !isBaseline &&
      (data as Compared<T>).isMissingData
    ) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="8px"
        >
          <CompareArrowsIcon
            sx={{
              color: "action.disabled",
            }}
          />
          {missingComparedDataElement}
        </Box>
      );
    }
    if (isBaseline && !baseline) {
      if (index === 0) {
        return noBaselineElement;
      }
      return "";
    }

    if (
      !isBaseline &&
      (data as Compared<T>).data[attribute.field].isEmpty &&
      (data as Compared<T>).data[attribute.field].isDiff
    ) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          N/A
        </Box>
      );
    }

    if (
      !isBaseline &&
      attribute.hideValueIfDifferent &&
      (data as Compared<T>).data[attribute.field].isDiff
    ) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CompareArrowsIcon
            sx={{
              color: "error.dark",
            }}
            aria-label={ComparisonAriaLabels.DiffCell}
          />
        </Box>
      );
    }

    if (
      !isBaseline &&
      !attribute.showValueAlways &&
      !(data as Compared<T>).data[attribute.field].isDiff
    ) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {attribute?.isExistanceComparison ? (
            <Status label="Exists" color="neutral" />
          ) : (
            <CheckIcon
              sx={{
                color: "action.active",
              }}
            />
          )}
        </Box>
      );
    }

    return attribute.renderCell ? (
      attribute.renderCell(
        isBaseline
          ? { isBaseline: true, data: data as T }
          : { isBaseline: false, data: data as Compared<T> }
      )
    ) : (
      <Typography variant="body2">
        {isBaseline
          ? String(data[attribute.field])
          : String((data as Compared<T>)?.data[attribute.field]?.value)}
      </Typography>
    );
  };

  const renderLastCell = useCallback(
    (index: number) => {
      if (compared.length === 0 && index === 0) {
        return (
          <GrowTableCell
            rowSpan={attributes.length}
            align="center"
            sx={{
              "&&": { verticalAlign: "middle" },
            }}
          >
            {isLoading ? null : noComparedItemsElement}
          </GrowTableCell>
        );
      } else if (compared.length > 0 && (!limit || compared.length < limit)) {
        return <GrowTableCell />;
      }

      return null;
    },
    [
      compared.length,
      limit,
      attributes.length,
      isLoading,
      noComparedItemsElement,
    ]
  );

  return (
    <Root isStacked={isStacked} className={className}>
      <Header
        display="flex"
        gap="8px"
        alignItems="center"
        padding={collapsible ? "8px" : "14px 12px"}
        sx={collapsible ? { cursor: "pointer" } : undefined}
        onClick={() => collapsible && setIsOpen((open) => !open)}
        aria-label={ComparisonAriaLabels.Header}
      >
        {collapsible && (
          <IconButton aria-label="expand row" size="small">
            {isOpen ? (
              <ChevronDown16
                width="20px"
                height="20px"
                color={muiTheme.palette.action.active}
              />
            ) : (
              <ChevronRight16
                width="20px"
                height="20px"
                color={muiTheme.palette.action.active}
              />
            )}
          </IconButton>
        )}
        <Typography variant="h4">{title}</Typography>
        {titleLeftElement}
        <Box sx={{ marginLeft: "auto" }}>
          {isOpen ? titleRightElement : null}
        </Box>
      </Header>
      <Collapse
        in={isOpen}
        timeout="auto"
        sx={{
          overflowX: "auto",
          maxWidth: "100%",
        }}
      >
        {isOpen ? (
          isError ? (
            errorStateElement
          ) : (
            <Table
              sx={{
                borderStyle: "hidden",
                borderCollapse: "separate",
                backgroundColor: muiTheme.palette.common.white,
              }}
            >
              <TableBody>
                <TableRow></TableRow>
                {attributes.map((attribute, attributeIdx) =>
                  !attribute.optional ||
                  baseline?.[attribute.field] !== undefined ? (
                    <TableRow
                      tabIndex={-1}
                      key={String(attribute.field)}
                      aria-label={`${attribute.headerName}-row`}
                    >
                      <StickyBodyTableCell
                        key={`attribute-${String(attribute.field)}`}
                        width={CELL_DIMENSIONS}
                      >
                        <Typography variant="body2" fontWeight={500}>
                          {attribute.headerName}
                        </Typography>
                      </StickyBodyTableCell>

                      <StickyBodyTableCell
                        key={`baseline-${String(attribute.field)}`}
                        sx={{
                          "&&": {
                            verticalAlign: "middle",
                            zIndex: 1,
                          },
                        }}
                        onClick={() => {
                          if (!attribute.onClick || !baseline) {
                            return;
                          }
                          if (attribute.enableClickBasedOnData) {
                            if (
                              !attribute.enableClickBasedOnData({
                                isBaseline: true,
                                data: baseline,
                              })
                            ) {
                              return;
                            }
                          }
                          attribute.onClick({
                            isBaseline: true,
                            data: baseline,
                            column: 0,
                          });
                        }}
                        className={attribute.cellBodyClassName}
                        width={CELL_DIMENSIONS}
                      >
                        {renderCell({
                          isBaseline: true,
                          data: baseline,
                          attribute,
                          index: attributeIdx,
                        })}
                      </StickyBodyTableCell>

                      {compared.map((comparedItem, comparedIdx) => {
                        const isMissingData =
                          !comparedItem.isLoading && comparedItem.isMissingData;
                        if (isMissingData && attributeIdx > 1) {
                          return null;
                        }

                        const isDiff =
                          comparedItem.data?.[attribute.field]?.isDiff ??
                          isMissingData;

                        const isEmpty =
                          comparedItem.data?.[attribute.field]?.isEmpty &&
                          comparedItem.data?.[attribute.field]?.isDiff;
                        const isDiffShown =
                          attribute.hideValueIfDifferent &&
                          comparedItem.data?.[attribute.field]?.isDiff;
                        const tooltipTitle = attribute?.tooltipTitleBasedOnData
                          ? attribute.tooltipTitleBasedOnData(comparedItem)
                          : isEmpty
                          ? "Does not exist"
                          : isDiffShown
                          ? "Click for more details"
                          : "";
                        return (
                          <BodyTableCell
                            key={`compared-${comparedIdx}-${String(
                              attribute.field
                            )}`}
                            isSame={
                              comparedItem.isLoading
                                ? false
                                : !isDiff && !isMissingData
                            }
                            isMissing={isMissingData}
                            isDiff={comparedItem.isLoading ? false : isDiff}
                            rowSpan={
                              attributeIdx > 0 && isMissingData
                                ? attributes.length - 1
                                : 1
                            }
                            sx={{
                              position: "relative",
                              cursor:
                                attribute.onClick &&
                                !comparedItem.isLoading &&
                                (attribute.enableClickBasedOnData
                                  ? attribute.enableClickBasedOnData({
                                      isBaseline: false,
                                      data: comparedItem,
                                    })
                                  : isDiff)
                                  ? "pointer"
                                  : undefined,
                              width:
                                muiTheme.breakpoints.up("xl") ||
                                compared.length == 1
                                  ? "20%"
                                  : CELL_DIMENSIONS,
                            }}
                            className={attribute.cellBodyClassName}
                            onClick={() => {
                              if (
                                !attribute.onClick ||
                                comparedItem.isLoading ||
                                (!isDiff &&
                                  !attribute.showValueAlways &&
                                  !attribute.enableClickBasedOnData) ||
                                (attribute.enableClickBasedOnData &&
                                  !attribute.enableClickBasedOnData({
                                    isBaseline: false,
                                    data: comparedItem,
                                  }))
                              ) {
                                return;
                              }
                              attribute.onClick({
                                isBaseline: false,
                                data: comparedItem,
                                column: comparedIdx,
                              });
                            }}
                          >
                            <LightTooltip title={tooltipTitle} placement="top">
                              <div>
                                {renderCell({
                                  isBaseline: false,
                                  data: comparedItem,
                                  attribute,
                                  index: attributeIdx,
                                })}
                              </div>
                            </LightTooltip>
                            {attribute.hoverableRightItem && (
                              <Stack
                                sx={{
                                  position: "absolute",
                                  right: 16,
                                  top: 6,
                                  visibility: "hidden",
                                }}
                                className="hoverable-right-item"
                              >
                                {attribute.hoverableRightItem(comparedItem)}
                              </Stack>
                            )}
                          </BodyTableCell>
                        );
                      })}

                      {renderLastCell(attributeIdx)}
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          )
        ) : null}
      </Collapse>
    </Root>
  );
}
