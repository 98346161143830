//CU-86c14fqqq: this hook needs to be removed once we remove newLogsSettings FF

import { useMemo } from "react";

import {
  storedLineNumberEnabled,
  storedTextWrappingEnabled,
} from "../logsViewerGlobalSettings";

import { useLogsViewerInLocalStorage } from "@/components/common/LogsViewer/localStorage/useLogsViewerInLocalStorage";

export const useLogsActionsOptions = () => {
  const [{ wrapText, showLineNumber, showTimestamp }, setLogsViewSettings] =
    useLogsViewerInLocalStorage();

  return useMemo(
    () => [
      {
        name: "Timestamps",
        value: showTimestamp,
        setValue: (val: boolean) => setLogsViewSettings({ showTimestamp: val }),
      },
      {
        name: "Line Numbers",
        value: showLineNumber,
        setValue: (val: boolean) => {
          setLogsViewSettings({ showLineNumber: val });
          storedLineNumberEnabled.set(val.toString());
        },
      },
      {
        name: "Text Wrap",
        value: wrapText,
        setValue: (val: boolean) => {
          setLogsViewSettings({ wrapText: val });
          storedTextWrappingEnabled.set(val.toString());
        },
      },
    ],
    [setLogsViewSettings, showLineNumber, showTimestamp, wrapText]
  );
};
