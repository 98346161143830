import { ActionType } from "@/generated/actionsApi";
import { batchApiActionsToLabel } from "@/components/common/ResourceListTable/batchActions/batchActionsConstants";

export const getActionTypeLabel = (
  actions: { type: ActionType }[] | undefined
) => {
  const actionType = actions?.[0].type;
  if (!actionType) {
    return "";
  }
  return batchApiActionsToLabel[actionType];
};
