import { css } from "styled-components";

import { muiTheme } from "../themes";

export const OverflowTextStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const lightMuiTooltipStyle = {
  tooltip: {
    sx: {
      bgcolor: muiTheme.palette.background.paper,
      boxShadow: "0px 2px 8px 1px rgba(22, 24, 31, 0.15)",
      whiteSpace: "pre-line",
      lineHeight: 1.5,
      "& .MuiTooltip-arrow": {
        color: muiTheme.palette.background.paper,
      },
      color: muiTheme.palette.text.primary,
    },
  },
};
