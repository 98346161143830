/* eslint-disable max-lines */
import React, { useState } from "react";
import styled from "styled-components";
import { CollapsableContainer } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { groupBy, map, partition } from "lodash";
import pluralize from "pluralize";
import { KomodorServiceHealthStatus } from "komodor-types";
import { muiColors } from "@komodorio/design-system";

import { DescribeSectionDivider } from "../../../../../ResourceView/tabs/DescribeTab/common/DescribeSectionDivider";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import EventDrawer from "../../../EventDrawer";
import EventGroup from "../../../../EventGroup";
import { NodeTerminationImpactData } from "..";
import { parseKomodorUid } from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import {
  ResourceDrawerByData,
  ResourceDrawerByServiceId,
} from "../../../../../ResourceView/ResourceDrawer";

import {
  ImpactedArgoWorkflowTile,
  ImpactedOrphanPodTile,
  ImpactedKomodorServiceTile,
  ImpactedGeneralWorkflowTile,
} from "./ImpactedResourceTile";

import {
  isArgoWorkflow,
  parseGeneralWorkflowName,
} from "@/components/common/ProcessList/details/NodeChangeDetails/ImpactedResourcesSection/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
`;

const ImpactedResourcesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
`;

const MAX_DISPLAYED_RESOURCES = 4;
const COLLAPSABLE_CONTAINER_HEIGHT = 77 * (MAX_DISPLAYED_RESOURCES / 2);

const SERVICES_BECAME_UNHEALTHY_INFO =
  "Services that became unhealthy within 10 minutes of this Node termination";
const AFFECTED_ORPHAN_PODS_INFO =
  "Pods without any controller which were terminated from this Node";
const AFFECTED_JOBS_INFO =
  "Jobs that were running at the time of the Node termination";
const AFFECTED_ARGO_WORKFLOWS_INFO =
  "Workflows that were running at the time of the Node termination";
const SERVICES_REMAINED_HEALTHY_INFO =
  "Services that remained healthy within 10 minutes of this Node termination";

interface NodeTerminationImpactSectionProps {
  terminationImpact: NodeTerminationImpactData;
}

export const ImpactedResourcesSection: React.FC<
  NodeTerminationImpactSectionProps
> = ({ terminationImpact }) => {
  const [correlatedEvent, setCorrelatedEvent] = useState<EventGroup | null>(
    null
  );
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
    null
  );
  const [selectedKomodorUid, setSelectedKomodorUid] = useState<string | null>(
    null
  );
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = selectedKomodorUid ? parseKomodorUid(selectedKomodorUid) ?? {} : {};

  const {
    affectedServices,
    affectedJobs,
    affectedOrphanPods,
    affectedArgoWorkflows,
    affectedDaemonSets,
  } = terminationImpact;

  const [servicesBecameUnhealthy, servicesRemainedHealthy] = partition(
    affectedServices,
    (service) => service.healthState === KomodorServiceHealthStatus.Unhealthy
  );

  const [affectedOnlyArgoWorkflows, affectedGeneralWorkflows] = partition(
    affectedArgoWorkflows,
    (workflow) => isArgoWorkflow(workflow.komodorUid)
  );

  const affectedGroupedGeneralWorkflows = map(
    groupBy(affectedGeneralWorkflows, (workflow) => {
      const {
        cluster = "",
        namespace = "",
        name = "",
      } = parseKomodorUid(workflow.komodorUid) || {};
      const { engine = "", dag = "" } = parseGeneralWorkflowName(name) || {};
      return `${cluster}/${namespace}/${engine}/${dag}`;
    })
  );

  return (
    <>
      <Container>
        {servicesBecameUnhealthy.length > 0 && (
          <>
            <DescribeSectionDivider
              title="Services Became Unhealthy"
              infoText={SERVICES_BECAME_UNHEALTHY_INFO}
            />
            <CollapsableResources
              resourcesCount={servicesBecameUnhealthy.length}
              resourcesType="services"
            >
              <ImpactedResourcesGrid>
                {servicesBecameUnhealthy.map(
                  ({ komodorUid, terminatedPodsCount, issueId }) => (
                    <ImpactedKomodorServiceTile
                      key={komodorUid}
                      komodorUid={komodorUid}
                      issueId={issueId}
                      terminatedPodsCount={terminatedPodsCount}
                      setCorrelatedEvent={setCorrelatedEvent}
                      setSelectedServiceId={setSelectedServiceId}
                    />
                  )
                )}
              </ImpactedResourcesGrid>
            </CollapsableResources>
          </>
        )}
        {affectedOrphanPods.length > 0 && (
          <>
            <DescribeSectionDivider
              title="Affected Orphan Pods"
              infoText={AFFECTED_ORPHAN_PODS_INFO}
            />
            <CollapsableResources
              resourcesCount={affectedOrphanPods.length}
              resourcesType="pods"
            >
              <ImpactedResourcesGrid>
                {affectedOrphanPods.map((podKomodorUid) => (
                  <ImpactedOrphanPodTile
                    key={podKomodorUid}
                    komodorUid={podKomodorUid}
                  />
                ))}
              </ImpactedResourcesGrid>
            </CollapsableResources>
          </>
        )}
        {affectedJobs.length > 0 && (
          <>
            <DescribeSectionDivider
              title="Affected Jobs"
              infoText={AFFECTED_JOBS_INFO}
            />
            <CollapsableResources
              resourcesCount={affectedJobs.length}
              resourcesType="jobs"
            >
              <ImpactedResourcesGrid>
                {affectedJobs.map(({ komodorUid, terminatedPodsCount }) => (
                  <ImpactedKomodorServiceTile
                    key={komodorUid}
                    komodorUid={komodorUid}
                    terminatedPodsCount={terminatedPodsCount}
                    setSelectedServiceId={setSelectedServiceId}
                    showHealthIndication={false}
                  />
                ))}
              </ImpactedResourcesGrid>
            </CollapsableResources>
          </>
        )}
        {(servicesRemainedHealthy.length > 0 ||
          affectedDaemonSets.length > 0) && (
          <>
            <DescribeSectionDivider
              title="Services Remained Healthy"
              infoText={SERVICES_REMAINED_HEALTHY_INFO}
            />
            <CollapsableResources
              resourcesCount={servicesRemainedHealthy.length}
              resourcesType="services"
            >
              <ImpactedResourcesGrid>
                {servicesRemainedHealthy.map(
                  ({ komodorUid, terminatedPodsCount }) => (
                    <ImpactedKomodorServiceTile
                      key={komodorUid}
                      komodorUid={komodorUid}
                      terminatedPodsCount={terminatedPodsCount}
                      setCorrelatedEvent={setCorrelatedEvent}
                      setSelectedServiceId={setSelectedServiceId}
                    />
                  )
                )}
              </ImpactedResourcesGrid>
            </CollapsableResources>
            {affectedDaemonSets.length > 0 && (
              <CollapsableResourcesWrapper
                title={`${affectedDaemonSets.length} daemonsets impacted`}
              >
                <ImpactedResourcesGrid style={{ width: "100%" }}>
                  {affectedDaemonSets.map(
                    ({ komodorUid, terminatedPodsCount, issueId }) => (
                      <ImpactedKomodorServiceTile
                        key={komodorUid}
                        komodorUid={komodorUid}
                        terminatedPodsCount={terminatedPodsCount}
                        setCorrelatedEvent={setCorrelatedEvent}
                        setSelectedServiceId={setSelectedServiceId}
                        issueId={issueId}
                      />
                    )
                  )}
                </ImpactedResourcesGrid>
              </CollapsableResourcesWrapper>
            )}
          </>
        )}
        {affectedArgoWorkflows.length > 0 && (
          <>
            <DescribeSectionDivider
              title="Affected Workflows"
              infoText={AFFECTED_ARGO_WORKFLOWS_INFO}
            />
            <CollapsableResources
              resourcesCount={affectedArgoWorkflows.length}
              resourcesType="workflows"
            >
              <ImpactedResourcesGrid>
                {affectedOnlyArgoWorkflows
                  .map(({ komodorUid, terminatedPodsCount }) => (
                    <ImpactedArgoWorkflowTile
                      key={komodorUid}
                      komodorUid={komodorUid}
                      terminatedPodsCount={terminatedPodsCount}
                      setSelectedKomodorUid={setSelectedKomodorUid}
                    />
                  ))
                  .concat(
                    affectedGroupedGeneralWorkflows.map((affectedGroup) => (
                      <ImpactedGeneralWorkflowTile
                        key={affectedGroup[0]?.komodorUid || ""}
                        workflowSet={affectedGroup}
                      />
                    ))
                  )}
              </ImpactedResourcesGrid>
            </CollapsableResources>
          </>
        )}
      </Container>
      <EventDrawer
        open={!!correlatedEvent}
        onClose={() => setCorrelatedEvent(null)}
        event={correlatedEvent}
      >
        {correlatedEvent?.renderEventDetails?.(() =>
          setCorrelatedEvent(null)
        ) ?? null}
      </EventDrawer>
      <ResourceDrawerByServiceId
        open={!!selectedServiceId}
        onClose={() => setSelectedServiceId(null)}
        serviceId={selectedServiceId ?? ""}
      />
      <ResourceDrawerByData
        open={!!selectedKomodorUid}
        onClose={() => setSelectedKomodorUid(null)}
        cluster={cluster}
        namespace={namespace}
        resourceName={name}
        resourceType={kind}
      />
    </>
  );
};

const CollapsableResources: React.FC<{
  resourcesCount: number;
  resourcesType: string;
  children: React.ReactElement;
}> = ({ resourcesCount, resourcesType, children }) => {
  if (resourcesCount <= MAX_DISPLAYED_RESOURCES) {
    return children;
  }
  return (
    <CollapsableContainer
      containerHeight={COLLAPSABLE_CONTAINER_HEIGHT}
      labels={{
        showMore: `View all ${pluralize(resourcesType)}`,
        showLess: "View less",
      }}
    >
      {children}
    </CollapsableContainer>
  );
};

const CollapsableResourcesWrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const CollapsableResourcesWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]};
`;

const CollapsableResourcesWrapper: React.FC<{
  title: string;
  children: React.ReactElement;
}> = ({ title, children }) => {
  const [expandContent, setExpandContent] = useState(false);
  return (
    <CollapsableResourcesWrapperContainer>
      <CollapsableResourcesWrapperHeader
        onClick={() => setExpandContent(!expandContent)}
      >
        <Typography variant="h6">{title}</Typography>
        <ChevronRight
          sx={{
            transform: expandContent ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        />
      </CollapsableResourcesWrapperHeader>
      {expandContent && children}
    </CollapsableResourcesWrapperContainer>
  );
};
