import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Metadata } from "@komodorio/design-system/komodor-ui";

import { useGetSummaryData } from "../../../hooks/drawerContentHooks";
import { ViolationResource } from "../../../violationDrawerTypes";
import { useYamlFromKomodorUid } from "../../../hooks/useYamlFromKomodorUid";

import { LazyEditor } from "@/components/common/LazyEditor";
import { ExtendedViolation } from "@/generated/reliabilityApi";
import Resource from "@/components/ResourceView/resources";

const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    gap: 16px;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding-bottom: 16px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 32px;
`;

interface Props {
  komodorUid: string;
  containerName: string;
}

export const ViewContainerSpec: React.FC<Props> = ({
  komodorUid,
  containerName,
}) => {
  const [open, setOpen] = useState(false);

  const summaryDataInput = useMemo(
    (): Partial<ExtendedViolation> => ({ komodorUid }),
    [komodorUid]
  );
  const summaryProps = useGetSummaryData(summaryDataInput);

  const getContainerYaml = (resource: Resource | undefined) => {
    const containers = (resource as ViolationResource)?.containers;
    if (!containers) return;

    const containerYaml = containers.find(
      (container) => container.name === containerName
    );
    return containerYaml;
  };

  const yaml = useYamlFromKomodorUid({
    komodorUid,
    resourceFormatter: getContainerYaml,
  });

  const summaryContent = useMemo(() => {
    return [
      ...(summaryProps?.metadataItems ?? []),
      { title: "container", value: containerName },
    ].map((item) => <Metadata key={item.title} {...item} />);
  }, [containerName, summaryProps]);

  return (
    <>
      {!!yaml && <Button onClick={() => setOpen(true)}>View Spec</Button>}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h3" color="text.primary">
            YAML
          </Typography>
        </DialogTitle>
        <StyledDialogContent>
          <SummaryContainer>{summaryContent}</SummaryContainer>
          <LazyEditor value={yaml} width="auto" readOnly />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </StyledDialogActions>
      </Dialog>
    </>
  );
};
