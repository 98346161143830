import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { CollapsibleContainer } from "../../CollapsibleContainer/CollapsibleContainer";
import {
  useCalculateMaxHeight,
  useGetHeaderActionProps,
  useShouldDisplaySearchField,
} from "../checkboxOptionsHandlerHooks";
import {
  COLLAPSIBLE_CONTAINER_GAP,
  OPTION_MARGIN,
} from "../checkboxHandlerConstants";
import { CheckboxOptionsHandlerProps } from "../checkboxOptionsHandlerTypes";
import { checkboxOptionsAriaLabels } from "../ariaLabels";

import { CollapsibleHeader } from "./CollapsibleHeader";
import { SearchFieldContainer } from "./SearchFieldContainer";
import { CollapsibleHeaderActionButton } from "./CollapsibleHeaderActionButton";
import { HiddenCheckboxOption } from "./HiddenCheckboxOption";
import { CheckboxSelectionOption } from "./CheckboxSelectionOption";
import { LoaderContainer } from "./LoaderContainer";

const Container = styled.div`
  padding: ${COLLAPSIBLE_CONTAINER_GAP}px;
  padding-right: 0;
`;

const StyledCollapsibleContainer = styled(CollapsibleContainer)`
  && {
    border: none;
    padding: 0;
    width: 100%;
    min-width: 0;
    .MuiCollapse-wrapperInner {
      padding: 4px 0;
    }
  }
`;

const OptionsContainer = styled.div<{ $maxHeight?: number }>`
  max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : "none")};
  overflow-y: auto;
  & > div:not(:last-child) {
    margin-bottom: ${OPTION_MARGIN}px;
  }
`;

const {
  mainContainer: containerAriaLabel,
  optionsContainer: optionsContainerAriaLabel,
} = checkboxOptionsAriaLabels;

export const CheckboxOptionsHandler: React.FC<CheckboxOptionsHandlerProps> = ({
  options,
  collapsibleProps,
  onChange,
  maxHeightInPixels,
  maxHeightByOptionsNum,
  totalOptionNum,
  searchTerm,
  onSearchChange,
  loading,
  hasMoreResultsMessage,
  emptyResultsMessage,
  defaultSelectedOptions,
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState<string>("");
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(
    collapsibleProps?.initialOpen ?? false
  );
  const hiddenOptionRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const searchTermToUse =
    searchTerm !== undefined ? searchTerm : localSearchTerm;

  const hasUndisplayedOptions = !!(
    totalOptionNum && totalOptionNum > options.length
  );

  const { shouldCalculateMaxHeight, maxHeight } = useCalculateMaxHeight({
    hiddenOptionRef,
    maxHeightInPixels,
    maxHeightByOptionsNum,
  });

  const onCollapseOpenChange = useCallback((isOpen: boolean) => {
    setIsCollapseOpen(isOpen);
  }, []);

  const onSearch = useCallback(
    (search: string) => {
      if (onSearchChange) {
        onSearchChange(search);
      }
      searchTerm === undefined && setLocalSearchTerm(search);
    },
    [onSearchChange, searchTerm]
  );

  const onCheckBoxClick = useCallback(
    (value: string, checked: boolean) => {
      const updatedOptions = options.map((option) => {
        if (option.value === value) {
          return { ...option, checked };
        }
        return option;
      });
      onChange(updatedOptions);
    },
    [onChange, options]
  );

  const filteredOptions = useMemo(() => {
    return searchTermToUse
      ? options.filter((option) =>
          option.label.toLowerCase().includes(searchTermToUse.toLowerCase())
        )
      : options;
  }, [options, searchTermToUse]);

  const checkBoxOptions = useMemo(() => {
    return filteredOptions.map((option) => {
      return (
        <CheckboxSelectionOption
          {...option}
          onChange={onCheckBoxClick}
          key={option.value}
        />
      );
    });
  }, [filteredOptions, onCheckBoxClick]);

  const { hasChangedOptions, onSelectAll, onReset, filteredOptionsNum } =
    useGetHeaderActionProps({
      options,
      defaultSelectedOptions,
      filteredOptions,
      onChange,
    });

  const hiddenOption = shouldCalculateMaxHeight && (
    <div ref={hiddenOptionRef} style={{ visibility: "hidden" }}>
      <HiddenCheckboxOption />
    </div>
  );

  const shouldShowSearchField = useShouldDisplaySearchField({
    optionsContainerRef,
    checkBoxOptions,
  });

  const isEmptyResults = !loading && !checkBoxOptions.length;
  const content = (
    <>
      {(shouldShowSearchField || !!searchTermToUse) && (
        <SearchFieldContainer
          onSearch={onSearch}
          searchTerm={searchTermToUse}
        />
      )}
      {loading && (
        <LoaderContainer height={optionsContainerRef.current?.clientHeight} />
      )}
      {!loading && (
        <OptionsContainer
          $maxHeight={maxHeight}
          ref={optionsContainerRef}
          aria-label={optionsContainerAriaLabel}
        >
          {checkBoxOptions}
          {hasUndisplayedOptions && hasMoreResultsMessage}
          {isEmptyResults && emptyResultsMessage}
        </OptionsContainer>
      )}
    </>
  );

  if (collapsibleProps) {
    return (
      <Container>
        {hiddenOption}
        <StyledCollapsibleContainer
          title={
            <CollapsibleHeader
              title={collapsibleProps.title}
              count={loading ? undefined : totalOptionNum ?? options.length}
            />
          }
          actionSection={
            <CollapsibleHeaderActionButton
              hasChangedOptions={hasChangedOptions}
              filteredOptionsNum={filteredOptionsNum}
              onReset={onReset}
              onSelectAll={onSelectAll}
              searchTerm={searchTermToUse}
              isCollapseOpen={isCollapseOpen}
            />
          }
          content={content}
          initialOpen={collapsibleProps.initialOpen}
          onOpenChange={onCollapseOpenChange}
          ariaLabel={`${collapsibleProps.title} selector`}
          padding={4}
        />
      </Container>
    );
  }

  return (
    <Container aria-label={containerAriaLabel}>
      {hiddenOption}
      {content}
    </Container>
  );
};
