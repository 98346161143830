import React, { useEffect, useMemo, useState } from "react";
import { Record, String } from "runtypes";
import { IntegrationType } from "komodor-types";
import { compact } from "lodash";

import useInstallationSubscription from "../../../../integrations/management/installed/useInstallationSubscription";
import { useFetchAllSlackChannelsMutation } from "../../../../../generated/graphql";
import { Suggestions } from "../suggestions";

import { SlackSinkOutputContents } from "./SlackSinkOutputContents";

import { MonitorConfiguration } from "@/generated/monitorsApi";

const IntegrationConfig = Record({ botAccessToken: String });

const SlackSinkOutput: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule: configData, setRule: setConfigData }) => {
  const installations = useInstallationSubscription().data;

  const loading = useMemo(() => {
    return installations === undefined;
  }, [installations]);

  const token = useMemo(() => {
    const configurations = installations
      ?.filter((i) => i.integration === IntegrationType.SLACK)
      .find((i) => IntegrationConfig.guard(i.configuration));
    return IntegrationConfig.guard(configurations?.configuration)
      ? configurations?.configuration.botAccessToken
      : null;
  }, [installations]);

  const [channels, setChannels] = useState<string[]>([]);
  const fetchChannels = useFetchAllSlackChannelsMutation()[1];
  useEffect(() => {
    (async () => {
      if (!token) return;
      const { data } = await fetchChannels({ token: token });
      setChannels(compact(data?.fetchAllSlackChannels?.channels).sort());
    })();
  }, [fetchChannels, token]);

  const [suggestions, setSuggestions] = useState<Suggestions>([]);
  useEffect(() => {
    if (channels.length === 0) return;
    setSuggestions(channels?.map((c) => ({ label: c, value: c })));
  }, [channels]);

  const [channel, setChannel] = useState(configData.sinks?.slack?.[0] ?? "");
  useEffect(() => {
    setConfigData({
      ...configData,
      sinks: {
        ...configData.sinks,
        slack: channel === "" ? undefined : [channel],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <SlackSinkOutputContents
      loading={loading}
      token={token}
      suggestions={suggestions}
      channel={channel}
      setChannel={setChannel}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SlackSinkOutput;
