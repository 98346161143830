import React, { memo, useEffect } from "react";

import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";

import { useFetchResourceControlledBy } from "./fetchResourceHooks";
import { DescribeTextItemWithResourceDrawer } from "./DescribeTextItemWithResourceDrawer";
import { OwnerResourcesParams } from "./types";
import { DescribeTextWithHelmChartDrawer } from "./DescribeTextWithHelmChartDrawer";

export const OwnerResourcesLinks: React.FC<OwnerResourcesParams> = memo(
  ({ controlledResource, ownerMetadata, onFetchingStateChange }) => {
    const {
      ownerResource,
      parentResource,
      helmChartData,
      isFetchingResources,
    } = useFetchResourceControlledBy({
      controlledResource,
      ownerMetadata,
    });

    const { showHelm } = useOverridableFlags();

    useEffect(() => {
      onFetchingStateChange?.(isFetchingResources);
    }, [isFetchingResources, onFetchingStateChange]);

    return (
      <>
        <DescribeTextItemWithResourceDrawer
          resource={ownerResource}
          ownerMetadata={ownerMetadata}
          label="Controlled By"
        />
        <DescribeTextItemWithResourceDrawer
          resource={parentResource}
          label="Parent Controller"
        />
        {showHelm && (
          <DescribeTextWithHelmChartDrawer helmChartSummary={helmChartData} />
        )}
      </>
    );
  }
);
