import { getAppConfig } from "../../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

export const useResourcesApiClient = (
  skipAppViewInHeaders?: boolean,
  skipWorkspaceHeader?: boolean
) => {
  return useCreateAxiosClient(getAppConfig().resourcesAPIServerURL, {
    skipAppViewInHeaders,
    skipWorkspaceHeader,
  });
};
