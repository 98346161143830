import React, {
  HTMLAttributes,
  ReactElement,
  useState,
  MouseEvent,
  FC,
} from "react";
import styled from "styled-components";
import { switchProp } from "styled-tools";
import { isString } from "lodash";
import Typography from "@mui/material/Typography";
import type { TooltipProps } from "@mui/material/Tooltip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import { theme } from "../../../theme";
import { Size } from "../../../types";

import { MultiOptionsTag } from "./MultiOptionsTag";

export type MetadataAlign = "left" | "center" | "right";
export type MetadataTooltipProps = Omit<TooltipProps, "children">;

export interface MetadataBaseProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  align?: MetadataAlign;
  size?: Size;
  maxWidth?: number;
  tooltipProps?: MetadataTooltipProps;
  onValueClick?: () => void;
  leftIcon?: FC;
}

export type MetadataValueType =
  | { value: string | ReactElement | undefined; values?: never }
  | { values: string[]; value?: never };

export type MetadataProps = MetadataValueType & MetadataBaseProps;

const Container = styled.div<{ align: MetadataAlign }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  row-gap: 4px;
  ${switchProp("align", {
    left: "align-items: start;",
    center: "align-items: center;",
    right: "align-items: end;",
  })}
`;

const EllipsisText = styled(Typography)<{ maxWidth?: number }>`
  && {
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ onClick }) => onClick && `cursor: pointer; text-decoration: underline`}
  }
`;

const ValueText: React.FC<{
  onClick?: () => void;
  size: Size;
  maxWidth?: number;
  children: React.ReactNode;
  tooltipProps?: MetadataTooltipProps;
}> = ({ onClick, size, maxWidth, children, tooltipProps }) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShowTooltip = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.clientWidth === maxWidth) {
      setTooltipEnabled(true);
    }
  };

  const variantType = size === "small" ? "h5" : size === "medium" ? "h4" : "h3";
  const content = (
    <EllipsisText
      maxWidth={maxWidth}
      variant={variantType}
      onClick={onClick}
      onMouseEnter={handleShouldShowTooltip}
    >
      {children}
    </EllipsisText>
  );

  return tooltipProps ? (
    <Tooltip
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      {...tooltipProps}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const TitleText: React.FC<{
  size: Size;
  children: React.ReactNode;
}> = ({ size, children }) => {
  const variantType =
    size === "small"
      ? "overline2"
      : size === "medium"
      ? "overline"
      : "overline0";

  return (
    <Typography
      variant={variantType}
      textTransform="uppercase"
      color={theme.foreground.fgSubtle}
    >
      {children}
    </Typography>
  );
};

export const Metadata: React.FC<MetadataProps> = ({
  title,
  value,
  values,
  leftIcon: LeftIcon,
  align = "left",
  size = "small",
  maxWidth,
  tooltipProps,
  onValueClick,
  ...rest
}) => {
  const getContentToRender = (): JSX.Element | undefined => {
    if ((value && isString(value)) || values?.length === 1) {
      return (
        <ValueText
          onClick={onValueClick}
          size={size}
          maxWidth={maxWidth}
          tooltipProps={tooltipProps}
        >
          {value ?? [values[0]]}
        </ValueText>
      );
    } else if (values) {
      return (
        <MultiOptionsTag
          values={values}
          onValueClick={onValueClick}
          size={size}
        />
      );
    } else if (!value) {
      return (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", paddingTop: "1px", paddingBottom: "1px" }}
          width={"100%"}
        />
      );
    }

    return;
  };

  return (
    <Container align={align} role="rowheader" {...rest}>
      <TitleText size={size}>{title}</TitleText>
      <Stack flexDirection={"row"} gap={1}>
        {LeftIcon && <LeftIcon />}
        {getContentToRender() ?? value}
      </Stack>
    </Container>
  );
};

Metadata.defaultProps = {
  align: "left",
  size: "small",
};
