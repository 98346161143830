import { CronJob } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesCronJobResource = {
  NameInK8S: "cronjobs",
  Group: "workloads",
  NavBarName: "CronJobs",
  PresentName: "Cron Jobs",
  Namespaced: true,
  ShowStatusFilter: false,
  UnnecessaryColumns: [],
  SupportedActions: [
    AvailableActions.Edit,
    AvailableActions.Delete,
    AvailableActions.RunNow,
    AvailableActions.SuspendCronJob,
    AvailableActions.ResumeCronJob,
  ],
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Active" },
    { name: "Images" },
    { name: "Suspend" },
    { name: "Schedule" },
    { name: "Selector" },
    { name: "Containers" },
    { name: "Last Schedule" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  Kind: SupportedResourcesKinds.KubernetesCronJobResource,
  Icon: CronJob,
  hasLabels: true,
};
