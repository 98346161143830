import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { Dictionary } from "lodash";
import { useMemo } from "react";

import { buildKomodorUidFromServiceInfo } from "./selectionOptionsUtils";

import { ServiceInfo } from "@/shared/types/ServiceInfo";

const compareServiceInfo = (a: ServiceInfo, b: ServiceInfo): number => {
  return (
    a.k8sCluster.localeCompare(b.k8sCluster) ||
    a.env.localeCompare(b.env) ||
    a.title.localeCompare(b.title)
  );
};

export const useBaselineServiceOptions = (
  baselineServiceId: MuiSelectionOption<string> | null,
  services: Dictionary<ServiceInfo>
): MuiSelectionOption<string>[] => {
  return useMemo(() => {
    return Object.values(services)
      .sort((a, b) => {
        const aUid = buildKomodorUidFromServiceInfo(a);
        const bUid = buildKomodorUidFromServiceInfo(b);
        if (aUid === baselineServiceId?.value) {
          return -1;
        }
        if (bUid === baselineServiceId?.value) {
          return 1;
        }
        return compareServiceInfo(a, b);
      })
      .map((serviceInfo) => {
        const komodorUid = buildKomodorUidFromServiceInfo(serviceInfo);
        return {
          label: komodorUid,
          value: komodorUid,
        };
      });
  }, [baselineServiceId?.value, services]);
};
