import {
  QueryBuilder,
  QueryBuilderProps,
} from "@komodorio/design-system/komodor-ui";
import { uniqBy } from "lodash";

import { useClusterField } from "@/shared/components/QueryBuilder/useClusterField";
import { useNamespaceField } from "@/shared/components/QueryBuilder/useNamespaceField";

export const ConnectedClustersNamespacesQB = (
  props: QueryBuilderProps & {
    isChangeable?: boolean;
    clustersInitialOptions?: string[];
    namespaceInitialOptions?: string[];
  }
) => {
  const fieldsWithCluster = useClusterField({
    props,
    isChangeable: props.isChangable,
    initialOptions: props.clustersInitialOptions,
  });
  const fieldsWithNamespace = useNamespaceField({
    props,
    isChangeable: props.isChangable,
    initialOptions: props.namespaceInitialOptions,
  });

  const uniqueFields = uniqBy(
    [...fieldsWithCluster, ...fieldsWithNamespace],
    "name"
  );

  return <QueryBuilder {...props} fields={uniqueFields} />;
};
