import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import workflowsPage from "../../assets/Screen - Workflows.png";
import { clustersDemoRoutes } from "../../constants";
import { ClickableArea, RelativeContainer } from "../../styles";
import { TopBar } from "../TopBar";

const SelectableRow = styled(ClickableArea)<{ top: string }>`
  width: 96.4%;
  height: 8%;
  left: 1.7%;
  top: ${({ top }) => top};
`;

export const WorkflowsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RelativeContainer>
      <TopBar page="workflows" />
      <img src={workflowsPage} alt={"workflows page"} />
      <SelectableRow
        top={"30.5%"}
        onClick={() => navigate(clustersDemoRoutes.wfLaneExtractorRegression)}
      />
      <SelectableRow
        top={"38.5%"}
        onClick={() => navigate(clustersDemoRoutes.wfIcpMapGenerator)}
      />
    </RelativeContainer>
  );
};
