import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { startCase } from "lodash";

import { Trash24 } from "../../../icons";
import { muiColors, muiTheme } from "../../../themes";

import { InstallationDetailsProps } from "./types";

type DeleteMenuItemProps = {
  onUninstall: () => void;
  onClose: () => void;
};

export const DeleteMenuItem = ({
  onUninstall,
  onClose,
}: DeleteMenuItemProps) => {
  const handleUninstall = () => {
    onUninstall();
    onClose();
  };

  return (
    <MenuItem onClick={handleUninstall}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Trash24 color={muiTheme.palette.error.main} />
        <Typography color={muiTheme.palette.error.main}>Uninstall</Typography>
      </Stack>
    </MenuItem>
  );
};

export const InstalledBy = ({
  installedBy,
}: {
  installedBy: InstallationDetailsProps["installedBy"];
}) => (
  <Stack direction="column" alignItems="start" width="85px">
    <Typography variant="overline2" color={muiColors.gray[600]}>
      installed by
    </Typography>
    <Typography variant="body3">{installedBy}</Typography>
  </Stack>
);

const INFO_VALUE_SX = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  wordWrap: "break-word",
  whiteSpace: "break-spaces",

  // This is for having the overflow ellipsis on the second line
  // https://stackoverflow.com/questions/15909489/text-overflow-ellipsis-on-two-lines
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  display: "-webkit-box",
};

type InstallationInfoProps = {
  info: InstallationDetailsProps["info"];
};

export const InstallationInfo = ({ info }: InstallationInfoProps) => {
  if (!info) {
    return null;
  }
  return (
    <Grid container columns={Object.keys(info).length}>
      {Object.entries(info).map(([key, value]) => (
        <Grid
          item
          key={key}
          direction="column"
          overflow="auto"
          paddingLeft="16px"
        >
          <Typography variant="overline2" color={muiColors.gray[600]}>
            {startCase(key)}
          </Typography>
          <Typography
            variant="body3"
            color={muiColors.gray[600]}
            sx={INFO_VALUE_SX}
          >
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
