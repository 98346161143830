import React from "react";

import { NodeMetrics } from "./NodeMetrics/NodeMetrics";

import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity } from "@/generated/addonsApi";
import { useAddOnsRefetchInterval } from "@/components/k8sAddons/hooks/useAddOnsRefetchInterval";
import { Filters } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/Filters";
import { NodeAutoscalersTable } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/NodeAutoscalersTable";
import {
  DEFAULT_AUTOSCALERS_SORT_BY_FIELD,
  DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION,
} from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersConstants";
import { AddonPageContainer } from "@/components/k8sAddons/components/AddonPageContainer";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { WorkspaceGenericEmptyState } from "@/components/workspaces/WorkspaceGenericEmptyState";
import { NodeAutoscalersPageConstants } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/nodeAutoscalersConstants";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import { NoAutoscalersFoundPage } from "@/components/k8sAddons/addons/NodeAutoscalers/NoAutoscalersFoundPage/NoAutoscalersFoundPage";

export const NodeAutoscalersPageContent: React.FC = () => {
  const {
    isClusterWorkspace,
    isClustersGroupWorkspace,
    isScopedWorkspace,
    scopedWorkspaceSubKind,
    currentWorkspace,
  } = useWorkspaces();
  const { data, isLoading } = useFetchLiveStateList({});

  const isSupportedWS =
    isClusterWorkspace ||
    isClustersGroupWorkspace ||
    (isScopedWorkspace && scopedWorkspaceSubKind === "clusters");
  if (currentWorkspace !== undefined && !isSupportedWS) {
    return (
      <WorkspaceGenericEmptyState
        title={NodeAutoscalersPageConstants.emptyStateBecauseOfWorkspaceTitle}
      />
    );
  }

  if (!isLoading && data?.data.data.length === 0) {
    return <NoAutoscalersFoundPage />;
  }

  return (
    <AddonPageContainer>
      <Filters />
      <NodeMetrics />
      <NodeAutoscalersTable />
    </AddonPageContainer>
  );
};

export const NodeAutoscalersPage: React.FC = () => {
  const refetchInterval = useAddOnsRefetchInterval();

  return (
    <AddonContextProvider
      addonType={Addon.AutoScalers}
      addonEntity={Entity.AutoScaler}
      refetchInterval={refetchInterval}
      sortingOrder={["desc", "asc", null]}
      defaultSortModel={{
        field: DEFAULT_AUTOSCALERS_SORT_BY_FIELD,
        sort: DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION,
      }}
    >
      <NodeAutoscalersPageContent />
    </AddonContextProvider>
  );
};
