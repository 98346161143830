import React from "react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { TextInput } from "@komodorio/design-system/komodor-ui";

export const ButtonPanel = styled(Paper).attrs({ elevation: 0 })`
  padding: 16px;
  margin: auto -32px -40px -16px;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  && {
    border-radius: 0;
  }
`;

export const H5LabeledTextInput = styled(TextInput)`
  input {
    box-sizing: border-box;
    width: 600px;
  }

  .MuiTypography-root {
    font-weight: 500;
  }
`;

export const PageTitle = ({
  title,
  onCancelClick,
}: {
  title: string;
  onCancelClick: () => void;
}) => (
  <Stack direction="row" alignItems="center">
    <IconButton onClick={onCancelClick} aria-label={"Go back to policies"}>
      <ArrowBack />
    </IconButton>
    <Typography variant="h3">{title}</Typography>
  </Stack>
);
export const PolicySkeleton = () => {
  return (
    <Stack rowGap="32px">
      <Skeleton
        variant="rectangular"
        height={48}
        width={600}
        sx={{ borderRadius: 1 }}
      />
      <Skeleton
        variant="rectangular"
        height={48}
        width={600}
        sx={{ borderRadius: 1 }}
      />
      <Skeleton variant="rectangular" height={72} sx={{ borderRadius: 1 }} />
      <Stack direction="row" columnGap="16px">
        <Skeleton
          variant="rectangular"
          height={32}
          width={120}
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          height={32}
          width={120}
          sx={{ borderRadius: 1 }}
        />
      </Stack>
    </Stack>
  );
};
