import React, { useMemo } from "react";
import { DataGrid } from "@komodorio/design-system/komodor-ui";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import {
  selectBatchActionResponseToShow,
  selectedBatchActionsRequestSelector,
} from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { useActionsResourceKind } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useActionsResourceKind";
import {
  getRequestColumns,
  getResponseColumns,
} from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/tableUtils";
import { useGetDisplayMode } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useGetDisplayMode";

export const ResourcesTable: React.FC = () => {
  const { bulkActionsRequest } =
    useBatchActionsStore(selectedBatchActionsRequestSelector) ?? {};
  const currentResponse = useBatchActionsStore(selectBatchActionResponseToShow);
  const resourceName = useActionsResourceKind();
  const displayMode = useGetDisplayMode();

  const columns = useMemo(() => {
    return displayMode === "confirmation"
      ? getRequestColumns(resourceName)
      : getResponseColumns(resourceName);
  }, [displayMode, resourceName]);

  const rows = useMemo(() => {
    return displayMode === "confirmation"
      ? bulkActionsRequest?.actions ?? []
      : currentResponse?.actions.filter((action) => !!action.failureMessage) ??
          [];
  }, [bulkActionsRequest?.actions, currentResponse?.actions, displayMode]);

  return (
    <DataGrid
      columns={columns as GridColDef<GridValidRowModel>[]}
      rows={rows}
      getRowId={(action) =>
        `${action.resource.kind}-${action.resource.clusterName}-${action.resource.namespace}-${action.resource.name}`
      }
      density="compact"
    />
  );
};
