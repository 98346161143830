import { useCallback, useMemo } from "react";

import {
  ActionResult,
  ActionState,
  GetBulkActionsResultsResp,
} from "@/generated/actionsApi";
import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { setBatchResponseToShowSelector } from "@/shared/store/batchActionsStore/batchActionsSelectors";

type UseProcessActionsOutput = {
  inProgressCount: number;
  failuresCount: number;
  totalActions: number;
};

export const useProcessActions = (actions: ActionResult[]) => {
  return useMemo(() => {
    return actions.reduce<UseProcessActionsOutput>(
      (acc, curr) => {
        if (
          curr.state === ActionState.InProgress ||
          curr.state === ActionState.Initiated
        ) {
          acc.inProgressCount += 1;
        }
        if (curr.failureMessage) {
          acc.failuresCount += 1;
        }
        return acc;
      },
      {
        inProgressCount: 0,
        failuresCount: 0,
        totalActions: actions.length,
      }
    );
  }, [actions]);
};

export const useOpenBatchActionsDialog = (
  bulkActionRes: GetBulkActionsResultsResp
) => {
  const showBatchActionsDialog = useBatchActionsStore(
    setBatchResponseToShowSelector
  );

  return useCallback(() => {
    return showBatchActionsDialog(bulkActionRes.bulkId);
  }, [bulkActionRes.bulkId, showBatchActionsDialog]);
};
