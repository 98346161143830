import {
  Compared,
  ComparisonTableProps,
} from "@komodorio/design-system/komodor-ui";

export type ComparisonHelm = {
  komodorUidLabel?: string;
  komodorUidValue?: string;
  version: string;
  inventory: string[];
  cluster: string;
  namespace: string;
  name: string;
  chart: string;
};

export type ComparisonHelmResources = {
  komodorUidLabel?: string;
  komodorUidValue?: string;
  nameAndChart: string;
  version: string;
  cluster: string;
  count: string;
};
export type Resource = {
  value: string;
  namespace?: string;
  isDiff: boolean;
};
export type ComparisonHelmResourcesWithResources = ComparisonHelmResources &
  ResourcesRecords;

export type ResourcesRecords = Record<`resource-${string}`, Resource>;

export type AttributesTableData = {
  attributes: ComparisonTableProps<ComparisonHelm>["attributes"];
  compared: Compared<ComparisonHelm>[];
  baseline: ComparisonHelm | undefined;
};

export type ResourcesTableData = {
  attributes: ComparisonTableProps<ComparisonHelmResources>["attributes"];
  compared: Compared<ComparisonHelmResources>[];
  baseline: ComparisonHelmResources | undefined;
};

export type CellRenderer =
  ComparisonTableProps<ComparisonHelm>["attributes"][number]["renderCell"];

export type CellRendererResources =
  ComparisonTableProps<ComparisonHelmResources>["attributes"][number]["renderCell"];

export type CellRendererDynamicResources =
  ComparisonTableProps<ResourcesRecords>["attributes"][number]["renderCell"];

export enum DiffType {
  CompareYamls = "CompareYamls",
  CompareServicesDrift = "CompareServicesDrift",
  ResourceDoesNotExist = "ResourceDoesNotExist",
  ResourceKindNotSupported = "ResourceKindNotSupported",
}
