import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Select,
  Typography,
} from "@komodorio/design-system/deprecated";
import { theme, palette } from "@komodorio/design-system";
import styled from "styled-components";

import { ErrorMessage } from "../../common/ErrorMessage";
import { Result } from "../../common/styles";
import { LinesLoader } from "../../../common/loaders/Line";
import InfoSection from "../../common/InfoSection";
import { ActionSentMessage } from "../../common/useActionSent";

import {
  ConfigureModalContentProps,
  ResourceConfigurationType,
  ResourceType,
} from "./types";
import { ResourceInput } from "./ResourceInput";

const Resources = styled.div`
  margin: 1rem 0;
  display: flex;
  gap: 2rem;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  height: 15.4rem;
`;

const KUBERNETES_RESOURCES_URL =
  "https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/";

export const ConfigureModalContent: React.FC<ConfigureModalContentProps> = ({
  showMessageActionSent,
  setShowMessageActionSent,
  handleClose,
  isModalOpen,
  resourceName,
  containers,
  options,
  selectedContainer,
  setSelectedContainer,
  handleResourceChange,
  containersInputValidation,
  errorOutput,
  getContainersDeniedObject,
  updateContainersDeniedObject,
  handleResetContainer,
  actionSent,
  isError,
  isResourcesChanged,
  executeAndReport,
  setActionSent,
}) => {
  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }

  return (
    <>
      <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
        <ModalHeader>
          <Typography variant="headline">
            Update containers resources for Service: {resourceName}
          </Typography>
        </ModalHeader>
        <ModalContent>
          {containers.length === 0 ? (
            <Loader>
              <LinesLoader marginTop="0" />
              <Typography variant="title" size="medium">
                Fetching resource information from the cluster...
              </Typography>
            </Loader>
          ) : (
            <>
              <Select
                size="medium"
                width="13rem"
                label="Containers"
                listZIndex="20000"
                listMaxHeight="20rem"
                options={options}
                value={options.find((o) => o.value === selectedContainer?.name)}
                onChange={(selected) => {
                  setSelectedContainer(
                    containers.find((c) => c.name === selected.value)
                  );
                }}
              />
              <Resources>
                <div>
                  {" "}
                  <Typography
                    size="medium"
                    variant="title"
                    color={palette.gray["800"]}
                  >
                    Requests
                  </Typography>
                  <ResourceInput
                    selectedContainer={selectedContainer}
                    resourceConfigType={ResourceConfigurationType.Requests}
                    resourceType={ResourceType.Cpu}
                    handleResourceChange={handleResourceChange}
                    selectedContainerValidation={containersInputValidation?.find(
                      (c) => c.name === selectedContainer?.name
                    )}
                  />
                  <br />
                  <ResourceInput
                    selectedContainer={selectedContainer}
                    resourceConfigType={ResourceConfigurationType.Requests}
                    resourceType={ResourceType.Memory}
                    handleResourceChange={handleResourceChange}
                    selectedContainerValidation={containersInputValidation?.find(
                      (c) => c.name === selectedContainer?.name
                    )}
                  />
                </div>
                <div>
                  {" "}
                  <Typography
                    size="medium"
                    variant="title"
                    color={palette.gray["800"]}
                  >
                    Limits
                  </Typography>
                  <ResourceInput
                    selectedContainer={selectedContainer}
                    resourceConfigType={ResourceConfigurationType.Limits}
                    resourceType={ResourceType.Cpu}
                    handleResourceChange={handleResourceChange}
                    selectedContainerValidation={containersInputValidation?.find(
                      (c) => c.name === selectedContainer?.name
                    )}
                  />
                  <br />
                  <ResourceInput
                    selectedContainer={selectedContainer}
                    resourceConfigType={ResourceConfigurationType.Limits}
                    resourceType={ResourceType.Memory}
                    handleResourceChange={handleResourceChange}
                    selectedContainerValidation={containersInputValidation?.find(
                      (c) => c.name === selectedContainer?.name
                    )}
                  />
                </div>
              </Resources>
            </>
          )}

          <InfoSection
            details={
              <>
                Learn more about pods and containers resources management{" "}
                <a
                  rel="noreferrer"
                  href={KUBERNETES_RESOURCES_URL}
                  target="_blank"
                >
                  here
                </a>
              </>
            }
          />
          <br />
          <Typography size="medium">
            This action will <b>update</b> all modified containers resources
            <br />
            <br />
          </Typography>
          {errorOutput && (
            <Result>
              <Typography color={theme.foreground.fgPink}>
                {errorOutput}
              </Typography>
            </Result>
          )}
          {getContainersDeniedObject !== undefined && (
            <ErrorMessage reason={getContainersDeniedObject} />
          )}
          {updateContainersDeniedObject !== undefined && (
            <ErrorMessage reason={updateContainersDeniedObject} />
          )}
        </ModalContent>

        <ModalActions>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={() => handleResetContainer()}
          >
            Reset Container Changes
          </Button>
          <Button
            variant="primary"
            size="small"
            disabled={actionSent || isError || !isResourcesChanged}
            onClick={() => {
              executeAndReport();
              setActionSent(true);
            }}
          >
            {actionSent ? "updating..." : "Update Resources"}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};
