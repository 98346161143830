import {
  SecretFragment,
  Kubernetes_Service_ResourceFragment,
  IssueFragment,
  IngressFragment,
} from "../../../generated/graphql";
import {
  ConfigMapEventsResponseDataInner,
  HpaEventsResponseDataInner,
} from "../../../generated/resourcesApi";

const isHpaEventsResponseDataInner = (
  object: unknown
): object is HpaEventsResponseDataInner =>
  typeof (object as HpaEventsResponseDataInner).id === "string" &&
  typeof (object as HpaEventsResponseDataInner).name === "string" &&
  typeof (object as HpaEventsResponseDataInner).namespace === "string" &&
  typeof (object as HpaEventsResponseDataInner).clusterName === "string" &&
  typeof (object as HpaEventsResponseDataInner).eventTime === "string" &&
  (object as HpaEventsResponseDataInner).services !== undefined &&
  typeof (object as HpaEventsResponseDataInner).action === "string" &&
  typeof (object as HpaEventsResponseDataInner).minReplicas === "number" &&
  typeof (object as HpaEventsResponseDataInner).maxReplicas === "number";

export const isHpaEventsResponseDataInners = (
  objects: unknown[]
): objects is HpaEventsResponseDataInner[] =>
  objects.every((object) => isHpaEventsResponseDataInner(object));

const isConfig_MapFragment = (
  object: unknown
): object is ConfigMapEventsResponseDataInner =>
  typeof (object as ConfigMapEventsResponseDataInner).id === "string" &&
  typeof (object as ConfigMapEventsResponseDataInner).configMapName ===
    "string" &&
  typeof (object as ConfigMapEventsResponseDataInner).namespace === "string" &&
  typeof (object as ConfigMapEventsResponseDataInner).clusterName ===
    "string" &&
  typeof (object as ConfigMapEventsResponseDataInner).eventTime === "string" &&
  (object as ConfigMapEventsResponseDataInner).services !== undefined &&
  typeof (object as ConfigMapEventsResponseDataInner).action === "string" &&
  typeof (object as ConfigMapEventsResponseDataInner).k8sDefault === "boolean";

export const isConfig_MapFragments = (
  objects: unknown[]
): objects is ConfigMapEventsResponseDataInner[] =>
  objects.every((object) => isConfig_MapFragment(object));

const isSKubernetes_Service_ResourceFragment = (
  object: unknown
): object is Kubernetes_Service_ResourceFragment =>
  typeof (object as Kubernetes_Service_ResourceFragment).id === "string" &&
  typeof (object as Kubernetes_Service_ResourceFragment).name === "string" &&
  typeof (object as Kubernetes_Service_ResourceFragment).clusterName ===
    "string" &&
  typeof (object as Kubernetes_Service_ResourceFragment).eventTime ===
    "string" &&
  (object as Kubernetes_Service_ResourceFragment).services !== undefined &&
  typeof (object as Kubernetes_Service_ResourceFragment).action === "string";

export const isKubernetes_Service_ResourceFragments = (
  objects: unknown[]
): objects is Kubernetes_Service_ResourceFragment[] =>
  objects.every((object) => isSKubernetes_Service_ResourceFragment(object));

export const isSecretFragment = (object: unknown): object is SecretFragment =>
  typeof (object as SecretFragment).id === "string" &&
  typeof (object as SecretFragment).secretName === "string" &&
  typeof (object as SecretFragment).namespace === "string" &&
  typeof (object as SecretFragment).clusterName === "string" &&
  typeof (object as SecretFragment).eventTime === "string" &&
  typeof (object as SecretFragment).action === "string" &&
  typeof (object as SecretFragment).k8sDefault === "boolean";

export const isSecretFragments = (
  objects: unknown[]
): objects is SecretFragment[] =>
  objects.every((object) => isSecretFragment(object));

export const isIssueFragment = (object: unknown): object is IssueFragment =>
  typeof (object as IssueFragment).id === "string" &&
  typeof (object as IssueFragment).type === "string" &&
  typeof (object as IssueFragment).sourceEventId === "string" &&
  typeof (object as IssueFragment).cluster === "string" &&
  typeof (object as IssueFragment).namespace === "string" &&
  typeof (object as IssueFragment).createdAt === "string" &&
  typeof (object as IssueFragment).closeReason === "string";

export const isIssueFragments = (
  objects: unknown[]
): objects is IssueFragment[] =>
  objects.every((object) => isIssueFragment(object));

export const isIngressFragment = (object: unknown): object is IssueFragment =>
  typeof (object as IngressFragment).id === "string" &&
  typeof (object as IngressFragment).name === "string" &&
  typeof (object as IngressFragment).namespace === "string" &&
  typeof (object as IngressFragment).action === "string" &&
  typeof (object as IngressFragment).clusterName === "string" &&
  typeof (object as IngressFragment).eventTime === "string" &&
  (object as IngressFragment).services !== undefined;

export const isIngressFragments = (
  objects: unknown[]
): objects is IngressFragment[] =>
  objects.every((object) => isIngressFragment(object));
