import { useCallback, useEffect, useMemo, useState } from "react";
import { ResourceTableModelRow } from "komodor-types";
import { Pod as PodType } from "kubernetes-types/core/v1.d";
import { useLocation } from "react-router-dom";

import useAtmGetResource, {
  AtmGetResourceResponseType,
} from "../../../shared/hooks/ATM/requests/useAtmGetResource";
import {
  ContainerOption,
  ContainerType,
} from "../../Actions/buttons/ExecPodShell/types";
import {
  KubernetesPodsResource,
  KubernetesResource,
} from "../inspectionConfiguration/SupportedResourcesTypes";
import { AtmOutputType } from "../../../shared/hooks/ATM/useAtmRequest";
import { mapContainerToOption } from "../../Actions/buttons/ExecPodShell/utils/mapPodContainerToContainerOptions";
import { usePodExecSessionsContext } from "../../../shared/context/PodExecSessionsProvider";
import useAnalyticsApi from "../../../shared/context/analyticsProvider";
import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../shared/config/analyticsEvents";
import { extractPathFromLocation } from "../../Actions/common/utils";

interface UseGetPodExecInfoArgs {
  agentId: string;
  clusterName: string;
  resource: ResourceTableModelRow;
  resourceType: KubernetesResource;
}

interface UseGetPodExecInfoResult {
  getPodFromAtm: () => void;
  containersOptions: ContainerOption[];
  initContainersOptions: ContainerOption[];
  showPodExecContainerSelection: boolean;
  setShowPodExecContainerSelection: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onContainerSelected: (containerName: string) => void;
}

export const useGetPodExecInfo = ({
  agentId,
  clusterName,
  resource,
  resourceType,
}: UseGetPodExecInfoArgs): UseGetPodExecInfoResult => {
  const location = useLocation();
  const [atmResult, setAtmResult] = useState<AtmGetResourceResponseType>();
  const [showPodExecContainerSelection, setShowPodExecContainerSelection] =
    useState(false);
  const [selectedPodExecContainerName, setSelectedPodExecContainerName] =
    useState("");

  const { dispatchEventViaBackend } = useAnalyticsApi();

  const {
    addDrawerSessionId,
    setSessionProps,
    createSessionIdentifier,
    getSessionProps,
  } = usePodExecSessionsContext();

  const { execute: getPodFromAtm } = useAtmGetResource(
    agentId,
    clusterName,
    resource.namespace,
    resourceType.Kind,
    resource.name,
    AtmOutputType.Json,
    (response: AtmGetResourceResponseType) => {
      setAtmResult(response);
    }
  );

  const podStatus = useMemo(() => {
    if (!atmResult?.data || resourceType.Kind !== KubernetesPodsResource.Kind) {
      return undefined;
    }

    const pod = atmResult.data as PodType;

    return pod.status;
  }, [atmResult, resourceType.Kind]);

  const containersOptions: ContainerOption[] = useMemo(() => {
    if (!podStatus) {
      return [];
    }

    return (podStatus.containerStatuses || []).map((c) =>
      mapContainerToOption(ContainerType.Regular, c)
    );
  }, [podStatus]);

  const initContainersOptions: ContainerOption[] = useMemo(() => {
    if (!podStatus) {
      return [];
    }

    return (podStatus.initContainerStatuses || []).map((c) =>
      mapContainerToOption(ContainerType.Init, c)
    );
  }, [podStatus]);

  const podExecPodId = useMemo(() => {
    if (!atmResult?.data || resourceType.Kind !== KubernetesPodsResource.Kind) {
      return "";
    }

    const pod = atmResult.data as PodType;

    return pod.metadata?.uid || "";
  }, [atmResult, resourceType.Kind]);

  const onContainerSelected = useCallback(
    (containerName: string) => {
      const sessionName = createSessionIdentifier(podExecPodId, containerName);

      if (containerName === getSessionProps(sessionName)?.containerName) {
        dispatchEventViaBackend(
          AnalyticEvents.PodExec.Same_Container_Selected_Twice,
          { containerName },
          true,
          false,
          [SegmentIntegrations.Hubspot]
        );
      }

      dispatchEventViaBackend(
        AnalyticEvents.Actions.Actions_fired,
        {
          type: "openPodExecSession",
          path: extractPathFromLocation(location),
          resourceType: "pod",
        },
        true,
        false,
        [SegmentIntegrations.Hubspot]
      );

      setShowPodExecContainerSelection(false);
      setSelectedPodExecContainerName(containerName);
      addDrawerSessionId(sessionName);
      setSessionProps(sessionName, {
        containerName,
        agentId,
        cluster: clusterName,
        namespace: resource.namespace,
        podName: resource.name,
      });
    },
    [
      createSessionIdentifier,
      podExecPodId,
      getSessionProps,
      dispatchEventViaBackend,
      location,
      addDrawerSessionId,
      setSessionProps,
      agentId,
      clusterName,
      resource.namespace,
      resource.name,
    ]
  );

  // auto select pod's container name
  useEffect(() => {
    const totalOptions =
      containersOptions.length + initContainersOptions.length;

    if (totalOptions === 0) {
      return;
    }

    if (totalOptions === 1 && !selectedPodExecContainerName) {
      const containerName = containersOptions[0].value;
      onContainerSelected(containerName);
    }
  }, [
    addDrawerSessionId,
    agentId,
    clusterName,
    containersOptions,
    initContainersOptions,
    onContainerSelected,
    podExecPodId,
    resource.name,
    resource.namespace,
    selectedPodExecContainerName,
    setSessionProps,
  ]);

  return {
    getPodFromAtm,
    containersOptions,
    initContainersOptions,
    showPodExecContainerSelection,
    setShowPodExecContainerSelection,
    onContainerSelected,
  };
};
