import { RuleType, RuleValidator, ValidationResult } from "react-querybuilder";

import {
  doesntMatchWildcardOperator,
  isOperator,
  matchesWildcardOperator,
  multiIsOperator,
} from "./operators";

const validators = {
  [multiIsOperator.name]: (value: string[] | undefined) => {
    if (!value?.length) {
      return "This field cannot be left empty";
    }
    return undefined;
  },
  [isOperator.name]: (value: string | undefined) => {
    if (!value) {
      return "This field cannot be left empty";
    }
    return undefined;
  },
  [matchesWildcardOperator.name]: (value: string | undefined) => {
    if (!value) {
      return "This field cannot be left empty";
    }
    if (!value.includes("*")) {
      return "Value must include *";
    }
    return undefined;
  },
  [doesntMatchWildcardOperator.name]: (value: string | undefined) => {
    if (!value) {
      return "This field cannot be left empty";
    }
    if (!value.includes("*")) {
      return "Value must include *";
    }
    return undefined;
  },
};

export const defaultValidator: RuleValidator = (
  rule: RuleType
): ValidationResult => {
  const validator = validators[rule.operator];
  if (!validator) {
    return { valid: true };
  }

  const error = validators[rule.operator](rule.value);
  if (!error) {
    return { valid: true };
  }
  return { valid: false, reasons: [error] };
};
