import React from "react";
import styled from "styled-components";
import { Breadcrumbs } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { muiColors, muiTheme } from "@komodorio/design-system";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useAiInvestigationInNotificationsCenter } from "@/components/AiInvestigation/useAiInvestigationInNotificationsCenter";
import { MinimizedRowProps } from "@/components/AiInvestigation/types";

const Row = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Root = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  padding: 10px 14px 10px 24px;
  &:hover {
    background-color: ${muiTheme.palette.action.hover};
  }
`;

export const MinimizedRow: React.FC<MinimizedRowProps> = (props) => {
  const { useAppNotifications } = useOverridableFlags();
  const { data, onMaximize, onRemove } = props;
  const { session, sessionIdentifier } = data;

  // While the FF useAppNotifications is not GA, this component supports both modes
  useAiInvestigationInNotificationsCenter(props);

  if (useAppNotifications || !session) {
    return null;
  }

  return (
    <Root
      onClick={() => onMaximize(data)}
      aria-label={AriaLabels.AiInvestigator.Drawer.Row}
    >
      <AiInvestigationStatus
        status={
          session.isComplete
            ? "complete"
            : session.isStuck
            ? "stuck"
            : "in_progress"
        }
      />
      <Row>
        <Breadcrumbs
          allowOverflow={false}
          breadcrumbs={[
            sessionIdentifier.cluster,
            sessionIdentifier.namespace,
            sessionIdentifier.name,
          ].filter((r): r is string => Boolean(r))}
        />
        <Typography variant="body2" fontWeight={500}>
          {sessionIdentifier.type}
        </Typography>
      </Row>
      <Box marginLeft="24px">
        <IconButton size="small">
          <Close
            sx={{ fontSize: "16px", color: muiTheme.palette.text.secondary }}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(data);
            }}
            aria-label={AriaLabels.AiInvestigator.Drawer.RowRemove}
          />
        </IconButton>
      </Box>
    </Root>
  );
};

function AiInvestigationStatus({
  status,
}: {
  status: "in_progress" | "complete" | "stuck";
}) {
  return (
    <Box
      display="flex"
      paddingY="4px"
      flexDirection="row"
      gap="8px"
      width="104px"
      alignItems="center"
      flexShrink={0}
    >
      {status === "complete" ? (
        <CheckCircleOutline
          sx={{ fontSize: "16px", color: muiColors.green[500] }}
        />
      ) : status === "stuck" ? (
        <ErrorOutline sx={{ color: muiColors.red[500] }} />
      ) : (
        <Box
          width={16}
          height={16}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={16} />
        </Box>
      )}
      <Typography
        variant="overline2"
        color={muiTheme.palette.text.secondary}
        fontWeight={500}
      >
        {status === "complete"
          ? "Analysis Ready"
          : status === "stuck"
          ? "Analysis Failed"
          : "Analysis In Progress"}
      </Typography>
    </Box>
  );
}
