import { PodLogsInfoRequestProps, PodLogsInfoResponseProps } from "./types";
import { useGetLivePodLogs } from "./useGetLivePodLogs";
import { useGetPodLogs } from "./useGetPodLogs";
import { useShouldUseLivePodsLogs } from "./useShouldUseLivePodsLogs";

export const usePodLogsInfo = (
  props: PodLogsInfoRequestProps
): PodLogsInfoResponseProps => {
  const shouldUseLivePodLogs = useShouldUseLivePodsLogs(props.clusterName);
  const logsByDemand = useGetPodLogs({
    ...props,
    shouldExecuteFetchLogs: !shouldUseLivePodLogs,
  });

  const liveLogs = useGetLivePodLogs({
    ...props,
    shouldExecuteFetchLogs: shouldUseLivePodLogs,
  });

  return shouldUseLivePodLogs ? liveLogs : logsByDemand;
};
