import {
  ClusterWorkspace,
  ClustersGroupWorkspace,
  LabelsWorkspace,
  NamespacesWorkspace,
  ScopedWorkspace,
  ServiceIdWorkspace,
  Workspace,
  WorkspaceKind,
} from "../../../generated/workspacesApi";

import { TypedWorkspace } from "./types";

export const convertWorkspaceToTypedWorkspace = (
  workspace: Workspace
): TypedWorkspace => {
  switch (workspace.kind) {
    case WorkspaceKind.Cluster:
      return {
        ...workspace,
        kind: WorkspaceKind.Cluster,
        readableKind: "Cluster",
        value: workspace.value as ClusterWorkspace,
      };
    case WorkspaceKind.ClustersGroup:
      return {
        ...workspace,
        kind: WorkspaceKind.ClustersGroup,
        readableKind: "Cluster Group",
        value: workspace.value as ClustersGroupWorkspace,
      };
    case WorkspaceKind.Namespace:
      return {
        ...workspace,
        kind: WorkspaceKind.Namespace,
        readableKind: "Workspace",
        value: workspace.value as NamespacesWorkspace,
      };
    case WorkspaceKind.Label:
      return {
        ...workspace,
        kind: WorkspaceKind.Label,
        readableKind: "Workspace",
        value: workspace.value as LabelsWorkspace,
      };
    case WorkspaceKind.ServiceId:
      return {
        ...workspace,
        kind: WorkspaceKind.ServiceId,
        readableKind: "Workspace",
        value: workspace.value as ServiceIdWorkspace,
      };
    case WorkspaceKind.Scoped:
      return {
        ...workspace,
        kind: WorkspaceKind.Scoped,
        readableKind: "Workspace",
        value: workspace.value as ScopedWorkspace,
      };
    default:
      throw new Error(`Unknown workspace kind: ${workspace.kind}`);
  }
};
