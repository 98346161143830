// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import React, { useMemo } from "react";
import { RuleProps, useRule } from "react-querybuilder";
import Stack from "@mui/material/Stack";

import { CustomFieldSelector } from "./CustomFieldSelector";
import { ReadonlyBox } from "./ReadonlyBox";
import { CustomOperatorSelector } from "./CustomOperatorSelector";
import { CustomValue } from "./CustomValue";
import { getRuleContextMenu } from "./RulesContextMenuItems";

export const CustomRule = (
  props: RuleProps & {
    index: number;
    prevField: any;
    canAddRuleExclusion: boolean;
    onAddRuleExclusion: () => void;
    canRemoveRule: boolean;
    onRemoveRule: () => void;
    canRemoveRuleExclusion: boolean;
    onRemoveRuleExclusion: () => void;
    combinator?: string;
  }
) => {
  const fullRuleProps = { ...props, ...useRule(props) };
  const {
    index,
    canAddRuleExclusion,
    onAddRuleExclusion,
    canRemoveRule,
    onRemoveRule,
    canRemoveRuleExclusion,
    onRemoveRuleExclusion,
    prevField,
    combinator,
  } = props;

  const isSameFieldAsPrev = prevField?.field === fullRuleProps.fieldData.value;

  const indentation = useMemo(() => {
    if (!isSameFieldAsPrev) {
      return;
    }
    return (
      <ReadonlyBox
        label={fullRuleProps.fieldData.label}
        boxType={"property"}
        hidden
      />
    );
  }, [isSameFieldAsPrev]);

  const RuleContextMenu = useMemo(() => {
    return getRuleContextMenu({
      id: fullRuleProps.fieldData.name,
      canAddRuleExclusion,
      canRemoveRule,
      onAddRuleExclusion: () => onAddRuleExclusion(fullRuleProps),
      onRemoveRuleExclusion: () => onRemoveRuleExclusion(fullRuleProps.path),
      onRemoveRule: () => onRemoveRule(fullRuleProps.path),
      canRemoveRuleExclusion: canRemoveRuleExclusion,
    });
  }, [
    canAddRuleExclusion,
    canRemoveRule,
    canRemoveRuleExclusion,
    onRemoveRuleExclusion,
    onRemoveRule,
    onAddRuleExclusion,
    fullRuleProps,
  ]);

  return (
    <Stack direction="row" columnGap={1} alignItems="flex-start">
      {indentation}
      {index > 0 && !!combinator && (
        <ReadonlyBox label={combinator} boxType={"combination"} />
      )}
      {!isSameFieldAsPrev && <CustomFieldSelector {...fullRuleProps} />}
      <CustomOperatorSelector {...fullRuleProps} />
      <CustomValue {...fullRuleProps} />
      {RuleContextMenu}
    </Stack>
  );
};
