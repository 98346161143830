import React from "react";
import Button from "@mui/material/Button";

import { ActionType } from "@/generated/actionsApi";
import { batchApiActionsToLabel } from "@/components/common/ResourceListTable/batchActions/batchActionsConstants";
import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";
import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { setBatchActionsRequestSelector } from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { batchActionsAriaLabels } from "@/components/common/ResourceListTable/batchActions/batchActionsAriaLabels";
import { useRefreshResultsByRoute } from "@/components/common/ResourceListTable/batchActions/hooks/useRefreshResultsByRoute";

type BatchActionButtonProps = {
  action: ActionType;
};

export const BatchActionButton: React.FC<BatchActionButtonProps> = ({
  action,
}) => {
  const {
    selectedTableRows,
    clusterName,
    resourceType,
    clearSelectedTableRows,
  } = useInspectionBatchActionsContext();
  const setBatchActionsRequest = useBatchActionsStore(
    setBatchActionsRequestSelector
  );

  const refreshResultsCbByRoute = useRefreshResultsByRoute();

  const onClick = () => {
    setBatchActionsRequest({
      bulkActionsRequest: {
        actions: selectedTableRows.map((row) => ({
          type: action,
          resource: {
            clusterName,
            kind: resourceType.Kind,
            namespace: row.namespace,
            name: row.name,
          },
        })),
      },
      onRequestProcessCompleteCb: refreshResultsCbByRoute,
      onRequestProcessInitCb: clearSelectedTableRows,
    });
  };
  const ariaLabel = `${batchActionsAriaLabels.actionButton} for ${action}`;
  const label = batchApiActionsToLabel[action];
  return (
    <Button onClick={onClick} variant={"contained"} aria-label={ariaLabel}>
      {label}
    </Button>
  );
};
