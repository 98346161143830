import styled from "styled-components";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import React from "react";

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "10px 28px 10px 0",
  fontWeight: "bold",
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: "sticky",
  top: 0,
  zIndex: 1, // Above the rest of the content
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "48px",
    padding: "8px 16px 8px 0",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const RowSpaceFiller = () => <Box sx={{ width: "48px" }} />;
