import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  apiV1ChatGetUrl,
  ChatGetResponse,
  DefaultApiApiV1ChatGetRequest,
} from "@/generated/aiApi";
import { useAiApiClient } from "@/shared/hooks/ai-api/client/apiClient";

export const CHAT_PATH = "/chat";

const fetchMessagesByRequestId = async (
  apiClient: AxiosInstance,
  params: DefaultApiApiV1ChatGetRequest
) => {
  const { data } = await apiClient.get<ChatGetResponse>(
    apiV1ChatGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetMessagesByRequestId = (
  params: DefaultApiApiV1ChatGetRequest,
  options?: {
    enabled?: boolean;
    refetchInterval?: number;
    keepPreviousData?: boolean;
  }
) => {
  const apiClient = useAiApiClient();
  return useQuery(
    [CHAT_PATH, params],
    () => fetchMessagesByRequestId(apiClient, params),
    options
  );
};
