import { useMemo } from "react";
import { QueryBuilderProps } from "@komodorio/design-system/komodor-ui";

import { useAgentsContext } from "@/shared/hooks/agents/context/useAgentsContext";
import { clusterQBField } from "@/shared/components/QueryBuilder/Fields/ClusterQBField";
import { Agent } from "@/generated/agents";
import { useOptions } from "@/shared/components/QueryBuilder/useOptions";

export const useClusterField = ({
  props,
  isChangeable,
  initialOptions,
}: {
  props: QueryBuilderProps;
  isChangeable: boolean;
  initialOptions?: string[];
}) => {
  const { agents } = useAgentsContext();

  const clustersOptions = useMemo(
    () =>
      agents?.activeAgents
        ?.filter((agent: Agent) => !agent.inactive)
        .filter(
          // eslint-disable-next-line max-params
          (agent: Agent, index: number, array: Agent[]) =>
            index ===
            array.findIndex((a) => a.clusterName === agent.clusterName)
        )
        .map((agent) => ({
          value: agent.clusterName,
          label: agent.clusterName,
        })) || [],
    [agents]
  );

  const { optionsToUse, onCreateNewOptions } = useOptions({
    initialOptions: initialOptions,
    storeOptions: clustersOptions,
  });

  const clusterField = useMemo(
    () =>
      clusterQBField({
        options: optionsToUse,
        isChangeable,
        onCreateNewOptions,
      }),
    [isChangeable, onCreateNewOptions, optionsToUse]
  );

  return useMemo(() => {
    const existingFields = props.fields || [];
    return [clusterField, ...existingFields];
  }, [clusterField, props.fields]);
};
