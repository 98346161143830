import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacPolicyActionsGetUrl,
  RbacPolicyAction,
  RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest,
} from "../../../../../generated/auth";

export const RBAC_POLICY_ACTIONS_PATH = "/rbac-policy-actions";

const fetchRbacPolicyActions = async (
  apiClient: AxiosInstance,
  params: RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest = {}
): Promise<RbacPolicyAction[]> => {
  const { data } = await apiClient.get(
    apiV1RbacPolicyActionsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacPolicyActions = (
  params: RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest = {},
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_POLICY_ACTIONS_PATH],
    () => fetchRbacPolicyActions(apiClient, params),
    {
      enabled,
    }
  );
};
