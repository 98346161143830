export const ariaLabels = {
  addButton: "Add Role",
  table: {
    container: "Roles Table",
    actionsCell: {
      container: "Actions",
      view: "View Role",
      edit: "Edit Role",
      delete: "Delete Role",
    },
  },
};
