import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  muiTheme,
} from "@komodorio/design-system";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

type CustomTheme = typeof muiTheme;

// support strongly-typed theme in styled-components
declare module "styled-components" {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  export interface DefaultTheme extends CustomTheme {}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    code1: true;
    code1bold: true;
    body3: true;
    body0: true;
    overline2: true;
    overline0: true;
  }
}

// Add "running" alert color option
declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    running: true;
  }
}

export const ThemeProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <MuiThemeProvider>
      <StyledThemeProvider theme={muiTheme}>{children}</StyledThemeProvider>
    </MuiThemeProvider>
  );
};

/** Include both Mui and Styled ThemeProviders to allow styling MUI components by both libraries:
 *  // MUI example:
 *  const StyledContainer = styled("div")(({ theme }) => ({
 *   display: "flex",
 *   gap: "1rem",
 *   flexWrap: "wrap",
 *   backgroundColor: theme.palette.grey[200],
 * }));
 *
 * // Styled example:
 * export const StyledContainer = styled.div`
 *   ${({ theme }) => css`
 *     display: flex;
 *     gap: 1rem;
 *     flex-wrap: wrap;
 *     background-color: ${theme.palette.grey[200]};
 *   `};
 * `;
 *
 * */
