import { ComparisonTable } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { MAX_COMPARED_SERVICES } from "../constants";
import { ComparisonTableTitleDriftIndication } from "../../common/ComparisonTableTitleDriftIndication";
import { EmptyStateComparedItems } from "../../common/EmptyStateComparedItems";
import { DriftType } from "../../context/types";
import { SeeAllModalParams } from "../../common/DiffModal";
import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";

import { DiffModal } from "./types";
import { useContainersTableData } from "./tablesDataHooks";

import {
  ServiceComparisonResponseContainer,
  ServiceComparisonResponseTargetServices,
} from "@/generated/workspacesApi";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

interface ContainerComparisonTableProps {
  targetContainer: ServiceComparisonResponseContainer;
  targetServices: ServiceComparisonResponseTargetServices[];
  isSingleContainer: boolean;
  isComparedEmpty: boolean;
  isLoading: boolean;
  setDiffModal: (diffModal: DiffModal | null) => void;
  setSeeAllModal: (seeAllModal: SeeAllModalParams) => void;
}

export const ContainerComparisonTable: React.FC<
  ContainerComparisonTableProps
> = ({
  targetContainer,
  targetServices,
  isSingleContainer,
  isComparedEmpty,
  isLoading,
  setDiffModal,
  setSeeAllModal,
}) => {
  const { showOnlyDriftKey } = useDriftComparisonContext();
  const [showOnlyDrift] =
    useStringifiedStateInSearchParams<boolean>(showOnlyDriftKey);

  const { attributes, compared, baseline } = useContainersTableData({
    targetContainer,
    targetServices,
    isLoading,
    setDiffModal,
    setSeeAllModal,
  });
  if (showOnlyDrift && !targetContainer.isDiff) return null;
  return (
    <ComparisonTable
      key={targetContainer.name}
      limit={MAX_COMPARED_SERVICES}
      title={targetContainer.name}
      titleLeftElement={
        compared.length === 0 ? null : (
          <ComparisonTableTitleDriftIndication
            isDiff={targetContainer.isDiff}
          />
        )
      }
      isStacked
      initialIsOpen={isSingleContainer && targetContainer.isDiff}
      collapsible
      isLoading={false}
      noBaselineElement={null}
      noComparedItemsElement={
        isComparedEmpty ? (
          <EmptyStateComparedItems type={DriftType.Services} />
        ) : null
      }
      missingComparedDataElement={
        <Typography variant="h5" textAlign="center">
          Container
          <br /> does not exist
        </Typography>
      }
      attributes={attributes}
      baseline={baseline}
      compared={compared}
    />
  );
};
