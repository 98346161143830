import { SC } from "@komodorio/design-system/icons";

import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesStorageClassResource = {
  NameInK8S: "storageclasses",
  Group: "storage",
  NavBarName: "Storage Classes",
  PresentName: "Storage Classes",
  Namespaced: false,
  UnnecessaryColumns: ["volumebindingmode", "allowvolumeexpansion"],
  SupportedActions: [AvailableActions.Delete],
  Kind: SupportedResourcesKinds.KubernetesStorageClassResource,
  Icon: SC,
  hasLabels: true,
};
