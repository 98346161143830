import React from "react";
import { capitalize } from "lodash";

import { useGetDescribeButtonProps } from "./hooks/useGetDescribeButtonProps";

import { DrawerTopSection } from "@/components/k8sAddons/LiveStateDrawer/components/DrawerTopSection";
import { MetadataSection } from "@/components/k8sAddons/LiveStateDrawer/components/MetadataSection";
import {
  useMetadataItems,
  useTopSectionProps,
} from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useHeaderProps";
import { DrawerTabs } from "@/components/k8sAddons/LiveStateDrawer/components/DrawerTabs";
import { CertificateDetails } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/CertificateDetails";
import { CertificateYAML } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateYAML";
import {
  DrawerMainContainer,
  DrawerTopSectionLine,
} from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { DescribeButton } from "@/components/Actions/buttons/DescribeButton";
import { FailureMessage } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/FailureMessage/FailureMessage";
import { useCertificateDataAsResource } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateDataAsResource";
import { StatusChipWithValidation } from "@/components/k8sAddons/addons/CertManager/shared/StatusChipWithValidation";
import { CertificateStatusEnum } from "@/generated/addonsApi";

export const CertificateDrawerContent: React.FC = () => {
  const metadataItems = useMetadataItems();
  const { status, color, title, expiration } = useTopSectionProps();
  const resource = useCertificateDataAsResource();
  const { actionItem } = useGetDescribeButtonProps(resource);

  return (
    <DrawerMainContainer
      aria-label={certificatesPageAriaLabels.drawer.contentContainer}
    >
      <DrawerTopSection title={title}>
        <DrawerTopSectionLine>
          <StatusChipWithValidation
            color={color}
            label={capitalize(status)}
            size={"large"}
            expiration={expiration}
            status={status as CertificateStatusEnum}
          />
          <DescribeButton Button={actionItem} resource={resource} />
        </DrawerTopSectionLine>
      </DrawerTopSection>
      <MetadataSection items={metadataItems} />
      <FailureMessage />
      <DrawerTabs
        tabInfo={[
          { label: "Details", value: "Details", page: <CertificateDetails /> },
          { label: "YAML", value: "YAML", page: <CertificateYAML /> },
        ]}
      />
    </DrawerMainContainer>
  );
};
