import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import React, { useMemo, useState } from "react";
import {
  ActionCell,
  ManageColumnsButton,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import { parseISO } from "date-fns";

import { useDataList } from "./hooks/useDataList";
import { DeleteScopedWorkspaceDialog } from "./components/drawer/dialogs/DeleteScopedWorkspaceDialog";

import {
  TypedScopeWorkspace,
  TypedWorkspace,
} from "@/shared/hooks/workspaces-api/types";
import { getDisplayingItemsText } from "@/shared/utils/tableUtils";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { useNavigateToWorkspace } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";

const Space = styled.div`
  height: 21px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

interface Props {
  data: TypedScopeWorkspace[];
  isLoading: boolean;
  searchTerm: string;
}

type PushDrawerFnType = (
  drawersState: DrawerStatePush,
  replaceUrl?: boolean | undefined
) => void;

const getColumns = (
  workspaces: TypedWorkspace[],
  callbacks: {
    format: (date?: Date | number) => string;
    showDeletionDialog: (workspaceId: string) => void;
    navigateToWorkspace: (workspace: TypedWorkspace | undefined) => void;
    pushDrawer: PushDrawerFnType;
  }
): GridColDef<TypedScopeWorkspace>[] => {
  const { format, pushDrawer, showDeletionDialog, navigateToWorkspace } =
    callbacks;

  return [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      width: 200,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      sortable: false,
      width: 190,
      valueGetter: ({ row }) => row.AuthorEmail ?? "",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      sortable: true,
      width: 140,
      valueGetter: ({ row }) => format(parseISO(row.createdAt)) ?? "",
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      sortable: true,
      width: 140,
      valueGetter: ({ row }) => format(parseISO(row.lastUpdated)) ?? "",
    },
    {
      field: "updatedByEmail",
      headerName: "Last Updated By",
      sortable: true,
      width: 194,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <ActionCell
            params={params}
            actions={[
              {
                key: "go-to-workspace",
                title: "Go to Workspace",
                enabled: true,
                icon: <OpenInNewOutlined fontSize="small" />,
                onAction: ({ id }) => {
                  const selectedWs = workspaces.find((w) => w.id === id);
                  navigateToWorkspace(selectedWs);
                },
              },
              {
                key: "edit-workspace",
                title: "Edit",
                enabled: true,
                icon: <EditOutlined fontSize="small" />,
                onAction: ({ id }) => {
                  pushDrawer({
                    drawerType: DrawerType.ScopedWorkspaceDrawer,
                    workspaceId: id.toString(),
                  });
                },
              },
              {
                key: "delete-workspace",
                title: "Delete",
                enabled: true,
                icon: <DeleteOutlined fontSize="small" />,
                onAction: ({ id }) => {
                  showDeletionDialog(id.toString());
                },
              },
            ]}
          />
        );
      },
    },
  ];
};
export const ScopedWorkspacesList: React.FC<Props> = ({
  data,
  isLoading,
  searchTerm,
}) => {
  const { format } = useDateFormatter({ timeZoneName: undefined });
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    null
  );
  const navigateToWorkspace = useNavigateToWorkspace();
  const [columnsVisibility, setColumnsVisibility] =
    useState<GridColumnVisibilityModel>({
      createdAt: false,
      updatedByEmail: false,
    });

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const columns = useMemo(
    () =>
      getColumns(data, {
        format,
        pushDrawer,
        showDeletionDialog: (wId) => setSelectedWorkspaceId(wId),
        navigateToWorkspace,
      }),
    [data, navigateToWorkspace, pushDrawer, format]
  );

  const { table, paginationModel } = useDataList({
    data,
    columns,
    columnsVisibility,
    search: searchTerm,
  });

  return (
    <Container>
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom="3px"
        >
          {isLoading || data.length === 0 ? (
            <Space />
          ) : (
            <Typography variant="h5" color="text.secondary">
              {getDisplayingItemsText(
                paginationModel.page,
                paginationModel.pageSize,
                data.length
              )}
            </Typography>
          )}

          <ManageColumnsButton
            columns={columns}
            columnsVisibility={columnsVisibility}
            setColumnsVisibility={setColumnsVisibility}
            sx={{ width: "fit-content", justifySelf: "end" }}
            untouchableColumns={["actions", "name"]}
          />
        </Stack>

        <Paper variant="elevation" elevation={1}>
          {table}
        </Paper>

        <DeleteScopedWorkspaceDialog
          open={!!selectedWorkspaceId}
          onClose={() => setSelectedWorkspaceId(null)}
          workspaceId={selectedWorkspaceId ?? ""}
        />
      </Stack>
    </Container>
  );
};
