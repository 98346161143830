import styled from "styled-components";

import { Title } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import {
  CountedDataContent,
  CountedDataValue,
} from "@/components/reliability/components/shared/CountedData/CountedDataContent";
import { ScaleDownImpactSupportingData } from "@/generated/reliabilityApi";
import { TerminationImpactGraphContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/TerminationImpactGraphContainer";

const MarginedCountedDataContent = styled(CountedDataContent)`
  margin: 16px 0;
`;

type TerminationImpactGraphSectionProps = {
  scaleDownImpactViolationData: ScaleDownImpactSupportingData;
  clusterName: string;
};

export const TerminationImpactGraphSection: React.FC<
  TerminationImpactGraphSectionProps
> = ({ scaleDownImpactViolationData, clusterName }) => {
  const items: CountedDataValue[] = [
    {
      title: "Availability issues",
      value: scaleDownImpactViolationData.availabilityIssuesCount,
    },
    {
      title: "Affected jobs",
      value: scaleDownImpactViolationData.affectedJobsCount ?? 0,
    },
    {
      title: "Affected workflows",
      value: scaleDownImpactViolationData.affectedWorkflowsCount ?? 0,
    },
    {
      title: "Node scale downs",
      value: scaleDownImpactViolationData.scaleDownsCount,
    },
    {
      title: "Pod evictions",
      value: scaleDownImpactViolationData.podEvictionsCount,
    },
  ];

  return (
    <div>
      <Title>What happened</Title>
      <MarginedCountedDataContent items={items} />
      <TerminationImpactGraphContainer clusterName={clusterName} />
    </div>
  );
};
