import React, { useCallback, useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  TextInput,
} from "@komodorio/design-system/komodor-ui";

import { Form, Z_INDEX_ABOVE_MODAL } from "@/components/Settings/styles";
import { RbacPolicy, RbacRole } from "@/generated/auth";
import { useGetRbacPolicies } from "@/shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicies";
import { ariaLabels } from "@/components/Settings/Roles/ariaLabels";
import { Dictionary } from "@/shared/types/Dictionary";
import { StyledFormControlLabel } from "@/components/Settings/Roles/styles";

export const RoleForm = ({
  role,
  setProperty,
  errors,
  isReadOnly,
}: {
  role: Partial<RbacRole>;
  setProperty: (
    key: keyof RbacRole,
    newValue: RbacRole[keyof RbacRole]
  ) => void;
  errors: Dictionary<string>;
  isReadOnly?: boolean;
}) => {
  const { data: allPolicies = [] } = useGetRbacPolicies();
  const policyOptions = useMemo(
    () => allPolicies.map((p) => ({ label: p.name, value: p.id })),
    [allPolicies]
  );

  const policiesValue = useMemo(() => {
    const rbacPolicies = role.rbacPolicies || [];

    return policyOptions.filter(
      (policy) => !!rbacPolicies.find((rp) => rp.id === policy.value)
    );
  }, [role.rbacPolicies, policyOptions]);

  const handleSetName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProperty("name", e.target.value);
    },
    [setProperty]
  );

  const handleSetPolicies = useCallback(
    (selected: MuiSelectionOption<string>[] | null) => {
      setProperty(
        "rbacPolicies",
        allPolicies.filter((p: RbacPolicy) =>
          selected?.find((s: MuiSelectionOption<string>) => s.value === p.id)
        )
      );
    },
    [setProperty, allPolicies]
  );

  const handleSetIsDefault = useCallback(() => {
    setProperty("isDefault", !role.isDefault);
  }, [role, setProperty]);

  return (
    <Form width="320px">
      <TextInput
        size="medium"
        label="Role name"
        value={role.name}
        onChange={handleSetName}
        fullWidth
        error={!!errors.name}
        helperText={errors.name}
        aria-label={ariaLabels.editModal.name}
        disabled={isReadOnly}
      />
      <AdvancedMultiSelect
        size="medium"
        label="Policies"
        selectPopoverProps={{
          sx: {
            height: "286px",
            zIndex: Z_INDEX_ABOVE_MODAL,
          },
        }}
        options={policyOptions}
        value={policiesValue}
        onChange={handleSetPolicies}
        error={errors.rbacPolicies}
        ariaLabel={ariaLabels.editModal.policies}
        disabled={isReadOnly}
      />
      <Stack>
        <Typography variant="body2">
          Set as default role for new users
        </Typography>
        <StyledFormControlLabel
          control={
            <Checkbox
              id="default-role"
              checked={role.isDefault}
              onChange={handleSetIsDefault}
              size="small"
              readOnly={isReadOnly}
            />
          }
          label="Default role"
          aria-label={ariaLabels.editModal.defaultRole}
          disabled={isReadOnly}
        />
      </Stack>
    </Form>
  );
};
