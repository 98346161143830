import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeUnderPressureDetails from "./detailsComponents/NodeUnderPressureDetails";
import { PodOutput } from "./NodeEvictedPodsCheck";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

interface NodeUnderPressureOutput {
  pods?: PodOutput[];
  hiddenPods?: number;
  memoryPressureMessage: string;
  diskPressureMessage: string;
  pidPressureMessage: string;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeUnderPressureCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "Node pressure";
    this.question = "Is the node under pressure?";
    this.description = getPressureDescription(check);
    this.action = (
      <>
        We ran{" "}
        <GrayHighlight>
          kubectl get pods --all-namespaces -o wide --field-selector
          spec.nodeName={`<NODE>`}
        </GrayHighlight>{" "}
        to check for Memory, Disk or PID pressure.
      </>
    );
    this.output = check.output as NodeUnderPressureOutput;
    this.shortOutput = getPressureShortOutput(check);
  }

  renderCheckDetails(): JSX.Element {
    return <NodeUnderPressureDetails check={this} />;
  }
}

const getPressureShortOutput = (check: BaseWorkflowCheck): string => {
  const checkOutput = check.output as NodeUnderPressureOutput;
  if (check.passed) {
    return "The node is not under pressure";
  } else {
    if (checkOutput?.memoryPressureMessage) {
      return "The node is under Memory pressure";
    }
    if (checkOutput?.diskPressureMessage) {
      return "The node is under Disk pressure";
    }
    if (checkOutput?.pidPressureMessage) {
      return "The node is under PID pressure";
    }
    return "The node is not under pressure";
  }
};

const getPressureDescription = (check: BaseWorkflowCheck): JSX.Element => {
  const checkOutput = check.output as NodeUnderPressureOutput;
  if (checkOutput?.memoryPressureMessage) {
    return (
      <>
        Memory pressure is a resourcing condition indicating that your node is
        running out of memory, it could mean there is a memory leak in one of
        your applications or due to a resource misconfiguration.
        <br /> <br />
        Pods with QOS other than Guaranteed can be a possible cause for the
        memory pressure and are also candidates to be evicted once there is one.
        If the node experiences an out-of-memory (OOM) event prior to the
        kubelet being able to reclaim memory, the node depends on the oom_killer
        to respond. Eventually, containers in low QoS pods that consume a large
        amount of memory relative to their scheduling requests will be killed
        first.
        <br /> <br />
      </>
    );
  }
  if (checkOutput?.diskPressureMessage) {
    return (
      <>
        Disk pressure is a condition indicating that a node is using too much
        disk space or is using disk space too fast, according to the thresholds
        you have set in your configuration and pods might be evicted. It might
        mean that you need to add more disk space or that an application is
        filling the disk in an unanticipated manner.
      </>
    );
  }
  if (checkOutput?.pidPressureMessage) {
    return (
      <>
        PID pressure is a rare condition where a pod or container spawns too
        many processes and starves the node of available process IDs.
        <br /> <br />
        Each node has a limited number of process IDs to distribute amongst
        running processes; and if it runs out of IDs, no other processes can be
        started. Kubernetes lets you set PID thresholds for pods to limit their
        ability to perform runaway process-spawning, and a PID pressure
        condition means that one or more pods are using up their allocated PIDs
        and need to be examined. Pods might be evicted.
        <br />
      </>
    );
  }
  return (
    <>
      Node-pressure (memory, Disk, or PID) could lead to a pod eviction by the
      kublet in order to reclaim resources on the node.
      <br /> <br />
      When one or more of these resources reaches a certain consumption level,
      pods can be terminated and failed by the kubelet to prevent starvation.
    </>
  );
};
