import React from "react";
import { parseISO } from "date-fns";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import { DescribeTextItem } from "../DescribeTab/common/DescribeItem";
import { DescribePanel } from "../DescribeTab/common/DescribeSection";
import { DescribeSectionDivider } from "../DescribeTab/common/DescribeSectionDivider";
import { DescribeKeyValueItem } from "../DescribeTab/common/DescribeTagListItem";
import {
  useDateFormatter,
  useDurationFormatter,
} from "../../../../shared/hooks/useDateFormatter";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import useResourceWithInterval from "../../useResource";
import ArgoWorkflow from "../../resources/argoWorkflow";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { FetchError } from "../../../argoWorkflowsView/EmptyStates";
import { ResourceResultType } from "../../types";
import { DescribeTabContentWrapper } from "../DescribeTab/common/DescribeTabContentWrapper";

import { formatDuration } from "@/shared/utils/dateUtils";

interface ArgoWorkflowDetailsTabProps {
  resource: ArgoWorkflow;
}

const ConditionsContainer = styled.div`
  display: grid;
  grid-template-columns: 5rem 5rem;
  gap: 0.5rem;
`;

const ResourcesContainer = styled.div`
  gap: 0.5rem;
`;

export interface ArgoWorkflowFullObject {
  apiVersion: string;
  kind: string;
  spec: {
    serviceAccountName: string;
    workflowTemplateRef?: {
      name: string;
      clusterScope?: boolean;
    };
  };
  metadata: {
    annotations: { [key: string]: string };
    labels: { [key: string]: string };
  };
  status?: {
    message?: string;
    conditions: [
      {
        status: string;
        type: string;
      }
    ];
    startedAt: string;
    finishedAt: string;
    resourcesDuration?: {
      cpu: number;
      memory: number;
      "nvidia.com/gpu": number;
    };
    progress: string;
  };
}

export const ArgoWorkflowDetailsTab: React.FC<ArgoWorkflowDetailsTabProps> = ({
  resource,
}) => {
  const { formatShortDuration } = useDurationFormatter();
  const { format } = useDateFormatter();

  const agentId = useActiveAgent(resource.cluster) ?? "";

  const { resource: workflowObj, failureMessage } = useResourceWithInterval({
    agentId,
    cluster: resource.cluster,
    namespace: resource.namespace,
    resourceName: resource.name,
    resourceType: resource.kind,
    preferredResultType: ResourceResultType.RawResource,
  });

  const fullObj = workflowObj?.fullObj as ArgoWorkflowFullObject;

  return (
    <DescribeTabContentWrapper resource={resource}>
      {failureMessage ? (
        <FetchError />
      ) : (
        fullObj && (
          <DescribePanel>
            {fullObj?.status?.message && (
              <>
                <DescribeTextItem
                  name="Message"
                  value={fullObj.status.message}
                />
                <DescribeSectionDivider />
              </>
            )}
            <DescribeTextItem
              name="Started"
              value={format(parseISO(fullObj.status?.startedAt ?? ""))}
            />
            {fullObj?.status?.finishedAt ? (
              <DescribeTextItem
                name="Finished"
                value={format(parseISO(fullObj.status?.finishedAt ?? ""))}
              />
            ) : (
              <DescribeTextItem name="Finished" />
            )}
            <DescribeTextItem
              name="Duration"
              value={
                formatDuration(
                  formatShortDuration,
                  fullObj.status?.startedAt ?? "",
                  fullObj.status?.finishedAt ?? ""
                ) ?? 0
              }
            />
            <DescribeTextItem
              name="Progress"
              value={fullObj.status?.progress}
            />
            <DescribeSectionDivider />
            {resource.labels ? (
              <DescribeKeyValueItem name="Labels" value={resource.labels} />
            ) : (
              <DescribeTextItem name="Labels" />
            )}
            <DescribeSectionDivider />
            <DescribeTextItem
              name="Resource Duration"
              value={
                <ResourcesContainer>
                  {fullObj.status?.resourcesDuration?.cpu && (
                    <div>
                      {fullObj.status?.resourcesDuration?.cpu} seconds of 1 CPU
                      cores{" "}
                    </div>
                  )}
                  {fullObj.status?.resourcesDuration?.memory && (
                    <div>
                      {fullObj.status.resourcesDuration?.memory} seconds of
                      100Mi of memory{" "}
                    </div>
                  )}
                  {fullObj.status?.resourcesDuration?.["nvidia.com/gpu"] && (
                    <div>
                      {fullObj.status.resourcesDuration?.["nvidia.com/gpu"]}{" "}
                      seconds of 1 nvidia.com/gpu cores{" "}
                    </div>
                  )}
                </ResourcesContainer>
              }
            />
            <DescribeTextItem
              name="Service Account"
              value={fullObj.spec.serviceAccountName}
            />
            <DescribeSectionDivider />
            <DescribeTextItem
              name="Conditions"
              value={
                <div>
                  {fullObj.status?.conditions?.map((condition) => (
                    <ConditionsContainer key={condition.type}>
                      <div>{condition.type}:</div>
                      <div
                        style={{
                          color: conditionColor(condition.status),
                        }}
                      >
                        {condition.status}
                      </div>{" "}
                    </ConditionsContainer>
                  ))}
                </div>
              }
            />
          </DescribePanel>
        )
      )}
    </DescribeTabContentWrapper>
  );
};

const conditionColor = (status: string): string | undefined => {
  return status === "True"
    ? muiColors.green[900]
    : status === "False"
    ? muiColors.red[900]
    : muiColors.yellow[500];
};
