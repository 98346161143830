import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";
import { uniq } from "lodash";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { ResourceBreadcrumbs } from "../../../ResourceBreadcrumbs";
import { useParseKomodorUids } from "../hooks/metricsRequestsHooks";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { useIsMetricsSupportedForMultipleClusters } from "@/components/Metrics/metricsHooks";
import { InfoSection } from "@/components/DriftAnalysis/common/InfoSection";

type InfoSectionProps = {
  komodorUids: string[];
};

export const MetricsNotSupportedSection: React.FC<InfoSectionProps> = ({
  komodorUids,
}) => {
  const parsedKomodorUids = useParseKomodorUids(komodorUids);
  const enabledClusters = useIsMetricsSupportedForMultipleClusters(
    uniq(parsedKomodorUids.map((k) => k.cluster))
  );

  const notSupportedServices = useMemo(
    () =>
      parsedKomodorUids.filter(
        (komodorUid) => !enabledClusters[komodorUid.cluster]
      ),
    [enabledClusters, parsedKomodorUids]
  );

  if (notSupportedServices.length === 0) {
    return null;
  }
  return (
    <InfoSection
      icon={
        <InfoOutlined
          sx={{
            color: muiColors.blue[600],
            fontSize: "14px",
            backgroundColor: muiColors.blue[50],
          }}
        />
      }
      content={
        <>
          <Typography variant="body2" color={muiColors.blue[600]}>
            Metrics are unavailable for{" "}
          </Typography>
          <LightTooltip
            placement={"top"}
            title={notSupportedServices.map((komodorUid, index) => (
              <ResourceBreadcrumbs
                key={index}
                clusterName={komodorUid.cluster}
                namespace={komodorUid.namespace}
                name={komodorUid.name}
              />
            ))}
          >
            <Typography
              variant="body2"
              color={muiColors.blue[600]}
              sx={{
                display: "flex",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {pluralize("service", notSupportedServices.length, true)}
            </Typography>
          </LightTooltip>
        </>
      }
      ariaLabel={AriaLabels.Metrics.Label}
    />
  );
};
