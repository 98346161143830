import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { SeverityColor } from "@/components/k8sAddons/types";
import { ColorScheme } from "@/components/k8sAddons/constants/colorScheme";
import { TABLE_ROW_HEIGHT } from "@/components/k8sAddons/constants/table";
import { GenericErrorMessage } from "@/shared/components/GenericErrorMessage";

export const ColoredTypography = styled(Typography)<{
  severity: SeverityColor;
}>`
  && {
    color: ${({ severity }) => ColorScheme[severity]};
  }
`;

export const StyledLiveStateListDataGrid = styled(DataGridPro)<{
  isFetchingData?: boolean;
  pageSize?: number;
}>`
  && {
    background-color: white;
    .MuiDataGrid-row {
      cursor: pointer;
      pointer-events: ${({ isFetchingData }) =>
        isFetchingData ? "none" : "auto"};
    }
    .MuiDataGrid-overlayWrapper {
      height: ${({ pageSize }) =>
        pageSize ? `${pageSize * TABLE_ROW_HEIGHT}px` : "100%"};
      max-height: 600px;
      width: 100%;
      overflow: hidden;
    }
  }
`;

export const LiveListTableTopSection = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 24px;
`;

export const PageFiltersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`;

export const PageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  margin-top: 16px;
`;

export const CenteredGenericErrorMessage = styled(GenericErrorMessage)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
