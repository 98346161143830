import React from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { FilterBarHeaderProps } from "../FilterBar";
import { H2 } from "../../../../components/common/typography";

const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 0.5rem;
  align-items: center;
`;

const Header: React.FC<FilterBarHeaderProps> = ({ title }) => {
  return (
    <Head data-e2e-selector="filtersBar-header">
      {title && <H2>{title}</H2>}
    </Head>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Header;
