import React, { useCallback } from "react";
import { QueryWithError } from "@komodorio/design-system/komodor-ui";

import { queryByScope } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/queries";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { queryToResourcesScope } from "@/pages/organization-settings/access-management/PoliciesPage/utils/queryToResourcesScope";
import { RbacPolicyAction, RbacPolicyStatementsInner } from "@/generated/auth";
import { statementToQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/statementToQuery";
import { prepareResourcesScope } from "@/pages/organization-settings/access-management/PoliciesPage/utils/prepareResourcesScope";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";
import { omitGroupsFromSelectedActions } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import { DEFAULT_RESOURCES_SCOPE } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/constants";
import { useRbacActionsContext } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/useRbacActionsContext";

const getQueryFromStatement = ({
  globalActions,
  statement,
}: {
  globalActions: RbacPolicyAction[];
  statement?: RbacPolicyStatementsInner;
}): QueryWithError | string | null => {
  if (!statement?.resourcesScope) {
    return queryByScope[Scope.cluster];
  }

  return statementToQuery(statement, globalActions);
};

export const useCreateStatement = ({
  statement,
}: {
  statement?: RbacPolicyStatementsInner;
}) => {
  const { scope: selectedScope } = useStatementDrawerContext();
  const { globalActions } = useRbacActionsContext();
  const [selectedActions, setSelectedActions] = React.useState<string[]>(
    statement?.actions ?? []
  );
  const [ruleBuilderQuery, setRuleBuilderQuery] = React.useState<
    QueryWithError | string | null
  >(getQueryFromStatement({ globalActions, statement }));

  // There can be group titles in the selected actions, because they are selectable by the UI, but they are not valid actions
  // So we need to filter them out
  const getAcceptableActions = useCallback(
    () => omitGroupsFromSelectedActions(selectedActions, globalActions),
    [selectedActions, globalActions]
  );

  const createStatementObject = useCallback(() => {
    const acceptableActions = getAcceptableActions();

    if (!ruleBuilderQuery || typeof ruleBuilderQuery === "string") {
      return {
        actions: acceptableActions,
        resourcesScope: DEFAULT_RESOURCES_SCOPE,
      };
    }

    const resourcesScope = queryToResourcesScope(ruleBuilderQuery);

    return {
      actions: acceptableActions,
      resourcesScope: prepareResourcesScope(resourcesScope, selectedScope),
    };
  }, [getAcceptableActions, ruleBuilderQuery, selectedScope]);

  return {
    selectedActions,
    setSelectedActions,
    ruleBuilderQuery,
    setRuleBuilderQuery,
    createStatementObject,
  };
};
