import { useMemo } from "react";
import { QueryBuilderProps } from "@komodorio/design-system/komodor-ui";
import { isRuleGroupType } from "react-querybuilder";

import { namespaceQBField } from "@/shared/components/QueryBuilder/Fields/NamespaceQBField";
import { useGetNamespaces } from "@/shared/hooks/resources-api/client/useGetNamespaces";
import { NamespacesResponseDataInner } from "@/generated/resourcesApi/api";
import { getClusterRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getClusterRulesFromQuery";
import { Pattern } from "@/generated/auth";
import { useOptions } from "@/shared/components/QueryBuilder/useOptions";

function isPattern(clusters: string[] | Pattern): clusters is Pattern {
  return "include" in clusters;
}

const DEFAULT_REGEX = /.*/;

function isRegex(pattern: string): RegExp | false {
  try {
    return !!pattern && RegExp(pattern);
  } catch (e) {
    return false;
  }
}

const isNamespaceFitClusterFilters = (
  clusterName: string,
  clustersFilters?: string[] | Pattern
) => {
  if (!clustersFilters) {
    return true;
  }

  if (!isPattern(clustersFilters)) {
    return clustersFilters.includes(clusterName);
  }

  const includeRegex = isRegex(clustersFilters.include) || DEFAULT_REGEX;
  const excludeRegex = isRegex(clustersFilters.exclude) ?? null;

  if (!includeRegex) {
    return true;
  }

  if (!excludeRegex) {
    return includeRegex.test(clusterName);
  }

  return includeRegex.test(clusterName) && !excludeRegex.test(clusterName);
};

export const useNamespaceField = ({
  props,
  isChangeable,
  initialOptions,
}: {
  props: QueryBuilderProps;
  isChangeable: boolean;
  initialOptions?: string[];
}) => {
  const { data: namespaces } = useGetNamespaces();

  const clusterRule = useMemo(() => {
    if (!isRuleGroupType(props.query)) {
      return undefined;
    }

    try {
      const resolvedQuery = getClusterRulesFromQuery(props.query);

      if (!resolvedQuery?.clustersPatterns && !resolvedQuery?.clusters) {
        return undefined;
      }

      if (resolvedQuery.clustersPatterns?.length) {
        return resolvedQuery.clustersPatterns[0];
      }

      if (resolvedQuery.clusters?.length) {
        return resolvedQuery.clusters;
      }

      return undefined;
    } catch (e) {
      return undefined;
    }
  }, [props.query]);

  const namespacesOptions = useMemo(() => {
    const namespacesFilteredByCluster = namespaces?.data.filter(
      (namespace: NamespacesResponseDataInner) => {
        return isNamespaceFitClusterFilters(namespace.clusterName, clusterRule);
      }
    );

    const uniqueSet = new Set(
      (namespacesFilteredByCluster ?? []).map((namespace) => namespace.name)
    );
    return Array.from(uniqueSet.values()).map((namespace) => ({
      label: namespace,
      value: namespace,
    }));
  }, [clusterRule, namespaces?.data]);

  const { optionsToUse, onCreateNewOptions } = useOptions({
    initialOptions: initialOptions,
    storeOptions: namespacesOptions,
  });

  const namespaceField = useMemo(
    () =>
      namespaceQBField({
        options: optionsToUse,
        isChangeable: isChangeable,
        onCreateNewOptions,
      }),
    [isChangeable, onCreateNewOptions, optionsToUse]
  );

  return useMemo(() => {
    const existingFields = props.fields || [];
    return [namespaceField, ...existingFields];
  }, [namespaceField, props.fields]);
};
