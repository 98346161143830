import { useMemo } from "react";

import { GeneralEventsResponse } from "../../../generated/resourcesApi";
import { ImpactfulEventsQuery } from "../../../generated/graphql";

export const mapGeneralResponseToImpactfulEvents = (
  events?: GeneralEventsResponse
): ImpactfulEventsQuery | undefined => {
  if (!events) return undefined;

  return Object.entries(events).reduce<ImpactfulEventsQuery>(
    (acc, [key, value]) => {
      acc[key as keyof ImpactfulEventsQuery] = value ?? [];
      return acc;
    },
    {
      event_komodor_alert: [],
      event_sentry_issue: [],
      event_pagerduty: [],
      event_deploy: [],
      event_topology: [],
      event_secret: [],
      event_actions: [],
      event_limit_ranges: [],
      event_network_policies: [],
      event_kubernetes_service_resource: [],
      event_ingress: [],
      event_resource_quota: [],
    }
  );
};

export const useGeneralResponseToImpactfulEvents = (
  events?: GeneralEventsResponse
): ImpactfulEventsQuery | undefined => {
  return useMemo(() => mapGeneralResponseToImpactfulEvents(events), [events]);
};
