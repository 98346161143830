import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import React, { createContext, useEffect, useState } from "react";

import { COMPARED_DRIFT_OPTION_KEY } from "../common/constants";

import { initialDriftComparisonState } from "./initialState";
import { DriftComparisonState, DriftType } from "./types";
import { getDriftBaselineSelectionKey } from "./utils";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const DriftComparisonContext = createContext<DriftComparisonState>(
  initialDriftComparisonState
);

export const DriftComparisonContextProvider: React.FC<{
  driftType: DriftType;
  children: React.ReactNode;
  baselineSelectionDefault?: MuiSelectionOption<string> | null;
  comparedSelectionsDefault?: MuiSelectionOption<string>[] | null;
  showOnlyDriftKey: string;
}> = ({
  children,
  driftType,
  baselineSelectionDefault,
  comparedSelectionsDefault,
  showOnlyDriftKey,
}) => {
  const [baselineSelection, setBaselineSelection] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>>(
      getDriftBaselineSelectionKey(driftType)
    );
  const [comparedSelections, setComparedSelections] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>[]>(
      COMPARED_DRIFT_OPTION_KEY + driftType
    );

  useEffect(() => {
    if (baselineSelectionDefault) {
      setBaselineSelection(baselineSelectionDefault);
    }
    if (comparedSelectionsDefault) {
      setComparedSelections(comparedSelectionsDefault);
    }
  }, [
    baselineSelectionDefault,
    comparedSelectionsDefault,
    setBaselineSelection,
    setComparedSelections,
  ]);

  const [isBaselineSelectorOpen, setIsBaselineSelectorOpen] =
    useState<boolean>(false);
  const [isComparedSelectorOpen, setIsComparedSelectorOpen] =
    useState<boolean>(false);

  const contextState: DriftComparisonState = {
    baselineSelection,
    comparedSelections,
    showOnlyDriftKey,
    isBaselineSelectorOpen,
    isComparedSelectorOpen,
    setBaselineSelection,
    setComparedSelections,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  };

  return (
    <DriftComparisonContext.Provider value={contextState}>
      {children}
    </DriftComparisonContext.Provider>
  );
};
