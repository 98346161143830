import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import clusterViewContent from "../assets/Screen - Overview.png";
import drawerReviewAndApplyRecs from "../assets/Drawer - Overview - Cost - Apply reommendations.png";
import drawerCostAutopilot from "../assets/Drawer - Overview - Cost - AutoPilot.png";
import { ClickableArea, RelativeContainer, CloseButtonArea } from "../styles";
import { clustersDemoRoutes } from "../constants";

import { TopBar } from "./TopBar";
import { MockDrawer } from "./MockDrawer";
import { addonsDemoRoutes } from "./addons/constants";

import { DEMO } from "@/components/routes/routes";
import { SELECTED_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";

const WorkloadHealthViewAll = styled(ClickableArea)`
  width: 4.8%;
  height: 1.4%;
  top: 12%;
  left: 18.5%;
  border-radius: 4px;
`;

const InfraHealthViewAll = styled(ClickableArea)`
  width: 4.8%;
  height: 1.4%;
  top: 27.1%;
  left: 21.3%;
  border-radius: 4px;
`;

const AddonCardCert = styled(ClickableArea)`
  width: 14.4%;
  height: 3.7%;
  top: 43.9%;
  left: 3.7%;
  border-radius: 4px;
`;

const AddonCardDNS = styled(ClickableArea)`
  width: 14.4%;
  height: 3.7%;
  top: 43.9%;
  left: 19.3%;
  border-radius: 4px;
`;

const AddonCardAutoScalers = styled(ClickableArea)`
  width: 14.4%;
  height: 3.7%;
  top: 43.9%;
  left: 34.9%;
  border-radius: 4px;
`;

const AddonCardWorkflows = styled(ClickableArea)`
  width: 14.4%;
  height: 3.7%;
  top: 43.9%;
  left: 50.4%;
  border-radius: 4px;
`;

const AddonCardIstio = styled(ClickableArea)`
  width: 14.4%;
  height: 3.7%;
  top: 43.9%;
  left: 66%;
  border-radius: 4px;
`;

const SaveButton = styled(ClickableArea)`
  width: 8.8%;
  height: 1.6%;
  top: 56.6%;
  left: 85%;
  border-radius: 4px;
`;

const AutopilotButton = styled(ClickableArea)`
  width: 12.1%;
  height: 1.6%;
  top: 67.2%;
  left: 67.6%;
  border-radius: 4px;
`;

const ViewSavingsButton = styled(ClickableArea)`
  width: 15.2%;
  height: 1.6%;
  top: 67.2%;
  left: 80.9%;
  border-radius: 4px;
`;

const InventoryPodsButton = styled(ClickableArea)`
  width: 21.5%;
  height: 4.1%;
  top: 75.7%;
  left: 74.6%;
  border-radius: 4px;
`;

const CloseButtonVariant = styled(CloseButtonArea)<{
  top: string;
  left: string;
}>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

const drawerButtonPlacemenet: Record<string, { top: string; left: string }> = {
  [drawerReviewAndApplyRecs]: { top: "18px", left: "22px" },
  [drawerCostAutopilot]: { top: "18px", left: "18px" },
};

export const ClusterOverview: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  const [secondaryDrawerImgSrc, setSecondaryImageSrc] = useState<string | null>(
    null
  );
  return (
    <RelativeContainer>
      <TopBar page="overview" />
      <img src={clusterViewContent} alt={"cluster view content"} />
      <WorkloadHealthViewAll
        onClick={() =>
          navigate(`/${DEMO}/v3/${clustersDemoRoutes.workloadHealth}`)
        }
      />
      <InfraHealthViewAll
        onClick={() =>
          navigate(`/${DEMO}/v3/${clustersDemoRoutes.infraHealth}`)
        }
      />
      <AddonCardCert
        onClick={() => navigate(`/${DEMO}/${addonsDemoRoutes.certManager}`)}
      />
      <AddonCardDNS
        onClick={() => navigate(`/${DEMO}/${addonsDemoRoutes.externalDNS}`)}
      />
      <AddonCardAutoScalers
        onClick={() => navigate(`/${DEMO}/${addonsDemoRoutes.nodeAutoscalers}`)}
      />
      <AddonCardWorkflows
        onClick={() => navigate(`/${DEMO}/v3/${clustersDemoRoutes.workflows}`)}
      />
      <AddonCardIstio
        onClick={() =>
          navigate(
            `/${addonsDemoRoutes.istioService}?${SELECTED_TAB_PARAM_KEY}="Istio"`
          )
        }
      />
      <SaveButton onClick={() => setDrawerImgSrc(drawerReviewAndApplyRecs)} />
      <AutopilotButton onClick={() => setDrawerImgSrc(drawerCostAutopilot)} />
      <ViewSavingsButton onClick={() => navigate(clustersDemoRoutes.cost)} />
      <InventoryPodsButton onClick={() => navigate(clustersDemoRoutes.atm)} />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...drawerButtonPlacemenet[drawerImgSrc!]}
            onClick={() => setDrawerImgSrc(null)}
          />
        }
      />
      <MockDrawer
        open={secondaryDrawerImgSrc !== null}
        imgSrc={secondaryDrawerImgSrc}
        onClose={() => setSecondaryImageSrc(null)}
        closeButton={
          <CloseButtonVariant
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...drawerButtonPlacemenet[secondaryDrawerImgSrc!]}
            onClick={() => setSecondaryImageSrc(null)}
          />
        }
      />
    </RelativeContainer>
  );
};
