import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TextInput } from "@komodorio/design-system/komodor-ui";

import {
  TrackedKeyCreateRequest,
  TrackedKeyCreateRequestTypeEnum,
} from "@/generated/accounts";
import { Errors } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";
import { createTrackedKeyModalAriaLabels } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/ariaLabels";

const ErrorText = ({ error }: { error: string }) => (
  <Typography variant={"body3"} color="error">
    {error}
  </Typography>
);

export const TrackedModalForm = ({
  onValueChange,
  value,
  errors = {} as Errors,
}: {
  onValueChange: (
    key: keyof TrackedKeyCreateRequest,
    value: TrackedKeyCreateRequest[keyof TrackedKeyCreateRequest]
  ) => void;
  value: Partial<TrackedKeyCreateRequest>;
  errors?: Errors;
}) => {
  return (
    <Stack rowGap={1}>
      <Stack>
        <FormControl error={!!errors.type}>
          <Typography variant="body2">
            What type of selector is this?
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value.type ?? ""}
            onChange={(e) => onValueChange("type", e.target.value)}
          >
            <FormControlLabel
              value={TrackedKeyCreateRequestTypeEnum.Label}
              control={<Radio />}
              label={<Typography variant="body2">Label</Typography>}
              aria-label={createTrackedKeyModalAriaLabels.type.label}
            />
            <FormControlLabel
              value={TrackedKeyCreateRequestTypeEnum.Annotation}
              control={<Radio />}
              label={<Typography variant="body2">Annotation</Typography>}
              aria-label={createTrackedKeyModalAriaLabels.type.annotation}
            />
          </RadioGroup>
          {!!errors.type && <ErrorText error={errors.type} />}
        </FormControl>
      </Stack>

      <TextInput
        label="Enter name"
        value={value.name ?? ""}
        onChange={(e) => onValueChange("name", e.target.value)}
        sx={{ width: "360px" }}
        error={!!errors.name}
        helperText={<ErrorText error={errors.name} />}
        aria-label={createTrackedKeyModalAriaLabels.name}
      />

      {!!errors.general && <ErrorText error={errors.general} />}
    </Stack>
  );
};
