import React from "react";
import Box from "@mui/material/Box";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";

export const ShowJSONDrawer = ({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      aria-label={createPolicyAriaLabels.showJSONDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          overflowY: "hidden",
          minWidth: "800px",
          width: "60%",
          padding: "16px",
          flexGrow: 1,
          boxSizing: "border-box",
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        columnGap="16px"
        sx={{
          paddingRight: "16px",
          overflow: "auto",
          flexGrow: 1,
        }}
      >
        <IconButton onClick={onClose} sx={{ marginTop: "-4px" }}>
          <Close />
        </IconButton>
        <Stack rowGap="16px" flexGrow={1} height={"100%"}>
          <Typography variant="h3">Policy JSON</Typography>
          <Box sx={{ height: "100%", "& > div": { height: "100%" } }}>
            {children}
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  );
};
