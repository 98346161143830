import Button from "@mui/material/Button";
import React from "react";
import SearchFilled from "@mui/icons-material/Search";

export const DeletedPodsSearchButton: React.FC = () => {
  return (
    <Button
      startIcon={<SearchFilled fontSize="medium" color="primary" />}
      color="primary"
      size="medium"
      sx={{
        padding: "2px",
      }}
    >
      Search Deleted Pods
    </Button>
  );
};
