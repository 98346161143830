import React from "react";
import {
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { compact } from "lodash";

import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import {
  columnsConfig,
  useLoadingColumns,
  useTableColumns,
} from "@/components/k8sAddons/addons/externalDns/hooks/tableHooks";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import {
  Addon,
  Entity,
  ExternalDnsRecord,
  ExternalDnsRecordFullFromJSON,
} from "@/generated/addonsApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { buildServiceId } from "@/shared/utils/serviceHelpers";
import { useAccountName } from "@/shared/hooks/useAccountName";
import {
  K8S_ADD_ONS_AVOID_SHOWING_STATUSES,
  K8S_ADD_ONS_IS_EXTERNAL_DNS_REFERRER,
} from "@/shared/config/urlSearchParamsKeys";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { useGetAddonsFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const Table: React.FC = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const accountName = useAccountName();

  const { searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
  });

  const response = useFetchLiveStateList({
    additionalRequestFilters: compact([searchFilter]),
  });

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useLoadingColumns(),
      dataStateColumns: useTableColumns(),
      tableTitle: "External DNS",
    });

  const commonLiveStateTableProps =
    useCommonLiveStateTableProps(isFetchingData);

  const ariaLabel = isFetchingData
    ? `${externalDnsListPageAriaLabels.table} loading`
    : `${externalDnsListPageAriaLabels.table} content`;

  useGetAddonsFullLiveState(
    (rowsToUse as ExternalDnsRecord[])
      ?.filter((row) => row.clusterName && row.namespace && row.serviceName)
      ?.map((row) => ({
        addon: Addon.ExternalDns,
        entity: Entity.ExternalDnsOperator,
        uid: buildKomodorUid({
          kind: row.kind,
          clusterName: row.clusterName,
          namespace: row.namespace,
          resourceName: row.serviceName,
        }),
        name: row.serviceName,
        getEntityRequest: {
          clusterName: row.clusterName,
        },
      })),
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: ExternalDnsRecordFullFromJSON(json?.data?.data),
    }),
    {
      refetchInterval: 2 * 60000, // 2 minutes,
    }
  );

  return (
    <PageTableContainer>
      <Typography variant="h4">{title}</Typography>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...commonLiveStateTableProps}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        onRowClick={({ row }: GridRowParams<ExternalDnsRecord>) => {
          pushDrawer({
            drawerType: DrawerType.ResourceDrawerByServiceId,
            serviceId: buildServiceId(
              accountName,
              row.clusterName,
              row.namespace,
              row.serviceName
            ),
            urlStates: {
              [K8S_ADD_ONS_IS_EXTERNAL_DNS_REFERRER]: true,
              [K8S_ADD_ONS_AVOID_SHOWING_STATUSES]: true,
            },
          });
          dispatchEvent(AnalyticEvents.ExternalDNS.RowClicked, {
            name: row.serviceName,
            namespace: row.namespace,
            cluster: row.clusterName,
            dnsProvider: row.dnsProvider,
            numberManagedRecords: row.numberManagedRecords,
            status: row.syncStatus,
            lastSynced: row.lastSuccessfulSyncTime,
          });
        }}
        getRowId={(row) => row.id}
      />
    </PageTableContainer>
  );
};
