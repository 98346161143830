import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { KomoBot24 } from "@komodorio/design-system/icons";

import { RichSessionData } from "../useEnrichedSession";

const ProblemShortContainer = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${muiColors.indigo[25]};
`;

export const WhatHappened: React.FC<{ session: RichSessionData }> = ({
  session,
}) => {
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        What Happened:
      </Typography>
      <div>
        {session.problemShort && (
          <ProblemShortContainer>
            <KomoBot24 color={muiColors.indigo[500]} />
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {session.problemShort}
            </Typography>
          </ProblemShortContainer>
        )}
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          <ol
            style={{
              margin: "0",
              listStylePosition: "inside",
              padding: "0",
            }}
          >
            {session.whatHappened?.map((line, i) => (
              <li
                style={{
                  padding: "5.5px 0",
                }}
                key={i}
              >
                {line}
              </li>
            ))}
          </ol>
        </Typography>
      </div>
    </>
  );
};
