import { KubernetesResource } from "./KubernetesResource";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesRoleBindingsResource: KubernetesResource = {
  NameInK8S: "rolebindings",
  Group: "access-control",
  NavBarName: "Role Bindings",
  PresentName: "Role Bindings",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: SupportedResourcesKinds.KubernetesRoleBindingsResource,
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Bindings" },
    { name: "Age" },
    { name: "Labels", hide: true },
    { name: "Annotations", hide: true },
  ],
  hasLabels: false,
};
