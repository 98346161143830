import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMemo, useState } from "react";
import {
  EmptyState,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Button from "@mui/material/Button";
import { uniqBy } from "lodash";

import { TypedScopeWorkspace } from "../../../shared/hooks/workspaces-api/types";

import { WorkspaceManagementControls } from "./WorkspaceManagementControls";
import { ScopedWorkspacesList } from "./ScopedWorkspacesList";
import { ScopedWorkspaceDrawer } from "./components/drawer/ScopedWorkspaceDrawer";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useGetWorkspaces } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaces";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import {
  WORKSPACE_MANAGEMENT_CREATED_BY_KEY,
  WORKSPACE_MANAGEMENT_SEARCH_NAME_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const WorkspaceManagementPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useStateInSearchParams(
    WORKSPACE_MANAGEMENT_SEARCH_NAME_KEY
  );
  const [createdBy, setCreatedBy] = useStateInSearchParams(
    WORKSPACE_MANAGEMENT_CREATED_BY_KEY
  );
  const { canManageWorkspaces } = useHasPermissions();
  const { showWorkspaceManagementPage } = useOverridableFlags();
  const { isFetching, data: filteredWs } = useGetWorkspaces({
    enabled: canManageWorkspaces,
    params: {
      management: true,
      kinds: ["scoped"],
    },
  });

  const onAddWorkspaceClicked = () => {
    setIsDrawerOpen(true);
  };

  const hasItems = filteredWs?.length !== 0;
  const createdByValues = useMemo(
    () =>
      uniqBy(
        filteredWs?.map(
          (ws) =>
            ({
              value: ws.id,
              label: ws.AuthorEmail ?? "None",
            } as MuiSelectionOption<string>)
        ),
        "label"
      ) ?? [],
    [filteredWs]
  );

  const wsList = useMemo(() => {
    if (isFetching) {
      return <CenteredLoader />;
    }

    return (
      <Stack rowGap="16px">
        {hasItems ? (
          <ScopedWorkspacesList
            isLoading={isFetching}
            data={(filteredWs as TypedScopeWorkspace[]) ?? []}
            searchTerm={searchTerm ?? ""}
          />
        ) : (
          <EmptyState
            title={
              <Stack rowGap="16px">
                <Typography variant="h4">No workspaces created</Typography>
                <Button variant="text" onClick={onAddWorkspaceClicked}>
                  Add new workspace
                </Button>
              </Stack>
            }
          />
        )}
      </Stack>
    );
  }, [filteredWs, hasItems, isFetching, searchTerm]);

  return (
    <SettingsViewVerticalLayout title="Workspace Management">
      {canManageWorkspaces && showWorkspaceManagementPage ? (
        <Stack rowGap="16px">
          <WorkspaceManagementControls
            searchTerm={searchTerm ?? ""}
            setSearchTerm={setSearchTerm}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
            controlsDisabled={filteredWs?.length === 0}
            createdByValues={createdByValues}
            onAddWorkspaceClicked={onAddWorkspaceClicked}
          />

          {wsList}

          <ScopedWorkspaceDrawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          />
        </Stack>
      ) : (
        <Typography>Access denied due to lack of permissions.</Typography>
      )}
    </SettingsViewVerticalLayout>
  );
};
