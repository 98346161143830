import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { ErrorOperator } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedClusterConstants";

export const FALLBACK_VALUE = "--";

type ColoredPercentageCellProps = {
  value?: number;
  errorThreshold: number;
  errorOperator: ErrorOperator;
};

const errorOperatorToLogic: Record<
  ErrorOperator,
  (value: number, threshold: number) => boolean
> = {
  [ErrorOperator.GreaterThan]: (value, threshold) => value > threshold,
  [ErrorOperator.LessThan]: (value, threshold) => value < threshold,
};

export const ColoredPercentageCell: React.FC<ColoredPercentageCellProps> = ({
  value,
  errorThreshold,
  errorOperator,
}) => {
  if (value == null) {
    return FALLBACK_VALUE;
  }
  const hasError = errorOperatorToLogic[errorOperator](value, errorThreshold);
  return (
    <Typography color={hasError ? muiColors.red[800] : muiColors.green[800]}>
      {value}%
    </Typography>
  );
};
