import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Typography from "@mui/material/Typography";

import { Alert } from "@/components/common/Alert/Alert";

export const DeprecatedNetworkMapperAlert: React.FC = () => {
  const alertTitle = (
    <>
      <Typography variant={"body2"} color={"inherit"}>
        We are sunsetting the <b>Dependencies Mapping</b> feature on
        <b> March 3rd, 2025</b> as part of our effort to deliver a more robust
        and scalable solution in the future. This change will enable us to focus
        on integrating advanced networking tools to enhance service dependency
        mapping.
      </Typography>
    </>
  );

  const alertText = (
    <>
      <Typography
        variant={"h5"}
        color={"inherit"}
        sx={{ mb: "-8px", mt: "16px" }}
        fontWeight={"bold"}
      >
        What’s Next?
      </Typography>
      <ul>
        <li> The feature will be fully removed in the next agent update. </li>
        <li>
          Stay tuned for updates on our future plans for improved dependency
          mapping solutions.
        </li>
      </ul>
    </>
  );

  return (
    <div style={{ marginBottom: "16px" }}>
      <Alert
        severity={"warning"}
        title={alertTitle}
        text={alertText}
        icon={WarningAmberOutlined as React.FC}
        padding={"16px"}
      />
    </div>
  );
};
