import {
  ApiV1KomodorServicesRelatedResourcesPostRequest,
  ConfigMapEventsResponse,
  CronJobEventsResponse,
  CustomEventResponse,
  DaemonSetsStateResponse,
  DeletedPodsResponse,
  DeployEventsResponse,
  DeploymentsStateResponse,
  EventsApiApiV1ConfigurationsConfigMapsEventsIdGetRequest,
  EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest,
  EventsApiApiV1ConfigurationsHpasEventsIdGetRequest,
  EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest,
  EventsApiApiV1ConfigurationsLimitRangesEventsIdGetRequest,
  EventsApiApiV1ConfigurationsPdbsEventsIdGetRequest,
  EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest,
  EventsApiApiV1ConfigurationsResourceQuotasEventsIdGetRequest,
  EventsApiApiV1ConfigurationsSecretsEventsIdGetRequest,
  EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest,
  EventsApiApiV1CustomEventsSearchGetRequest,
  EventsApiApiV1CustomServiceEventsSearchGetRequest,
  EventsApiApiV1DeploysEventsSearchGetRequest,
  EventsApiApiV1NetworkIngressesEventsIdGetRequest,
  EventsApiApiV1NetworkIngressesEventsSearchGetRequest,
  EventsApiApiV1NetworkNetworkPoliciesEventsIdGetRequest,
  EventsApiApiV1NetworkServicesEventsIdGetRequest,
  EventsApiApiV1NetworkServicesEventsSearchGetRequest,
  EventsApiApiV1NodesEventsIdGetRequest,
  EventsApiApiV1NodesEventsIdsGetRequest,
  EventsApiApiV1NodesEventsSearchGetRequest,
  EventsApiApiV1WorkloadsCronJobsEventsSearchGetRequest,
  EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest,
  EventsApiApiV1WorkloadsJobsEventsSearchGetRequest,
  EventsApiApiV1WorkloadsPodsEventsSearchGetRequest,
  HorizontalPodAutoscalerStateResponse,
  HpaEventsResponse,
  IngressEventsResponse,
  IngressesStateResponse,
  JobEventsResponse,
  JobsStateResponse,
  K8sServiceEventsResponse,
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  KomodorServicesResponse,
  LimitRangeEventsResponse,
  NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest,
  NetworkPolicyEventsResponse,
  NodeEventsResponse,
  NetworkMapApiApiV1NetworkMapsGraphGetRequest,
  PdbEventsResponse,
  PodEventsResponse,
  PodNativeEventsResponse,
  NetworkMapGraphResponse,
  PodStateRequest,
  PodsStateResponse,
  RelatedResourcesResponse,
  ResourceQuotaEventsResponse,
  SecretEventsResponse,
  StateRequest,
  StatefulSetsStateResponse,
  KomodorServicesApiApiV1KomodorServicesIdGetRequest,
  KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGetRequest,
  EventsApiApiV1NodesByAffectedResourcesEventsGetRequest,
  NodesStateResponse,
  ArgoWorkflowsWorkflowEventResponse,
  EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGetRequest,
  EventsApiApiV1WorkloadsDeletedPodsEventsIdGetRequest,
  GeneralEventsResponse,
  EventsApiApiV1EventsGeneralGetRequest,
  GroupedPodEventsResponse,
  GroupedPodEventsRequest,
  ArgoRolloutsStateResponse,
  EventsApiApiV1DeploysEventsIdGetRequest,
  NamespacesResponse,
  AccountNamespacesApiApiV1NamespacesGetRequest,
} from "../../../generated/resourcesApi";

export const CONFIGURATION_CONFIGMAPS_EVENT_ID =
  "/configurations/config-maps/events/:id";
export const CONFIGURATION_CONFIGMAPS_EVENT_SEARCH =
  "/configurations/config-maps/events/search";
export const CONFIGURATION_HPAS_EVENT_ID = "/configurations/hpas/events/:id";
export const CONFIGURATION_HPAS_EVENT_SEARCH =
  "/configurations/hpas/events/search";
export const CONFIGURATION_LIMIT_RANGES_EVENT_ID =
  "/configurations/limit-ranges/events/:id";
export const CONFIGURATION_PDBS_EVENT_ID = "/configurations/pdbs/events/:id";
export const CONFIGURATION_PDBS_EVENT_SEARCH =
  "/configurations/pdbs/events/search";
export const CONFIGURATION_RESOURCE_QUOTAS_EVENT_ID =
  "/configurations/resource-quotas/events/:id";
export const CONFIGURATION_SECRETS_EVENT_ID =
  "/configurations/secrets/events/:id";
export const CONFIGURATION_SECRETS_EVENT_SEARCH =
  "/configurations/secrets/events/search";

export const CUSTOM_EVENT_SEARCH = "/custom/events/search";
export const CUSTOM_SERVICE_EVENT_SEARCH = "/custom/service/events/search";
export const DEPLOY_EVENTS_SEARCH = "/deploys/events/search";
export const DEPLOY_EVENTS_ID = "/deploys/events/:id";

export const JOB_STATE = "/workloads/jobs";
export const DEPLOYMENT_STATE = "/workloads/deployments";
export const ROLLOUT_STATE = "/workloads/argo-rollouts";
export const DAEMONSET_STATE = "/workloads/daemon-sets";
export const STATEFULSET_STATE = "/workloads/stateful-sets";
export const POD_STATE = "/workloads/pods";
export const NODE_STATE = "/nodes";
export const HPA_STATE = "/configurations/hpas";
export const INGRESS_STATE = "/network/ingresses";

export const NETWORK_INGRESS_EVENT_ID = "/network/ingresses/events/:id";
export const NETWORK_INGRESS_EVENT_SEARCH = "/network/ingresses/events/search";
export const NETWORK_NETWORK_POLICIES_EVENT_ID =
  "/network/network-policies/events/:id";
export const NETWORK_SERVICE_EVENT_SEARCH = "/network/services/events/search";
export const NETWORK_SERVICE_EVENT_ID = "/network/services/events/:id";
export const NODES_EVENTS_SEARCH = "/nodes/events/search";
export const NODES_EVENTS_ID = "/nodes/events/:id";
export const NODES_EVENTS_IDS = "/nodes/events/ids";
export const NODES_EVENTS_BY_AFFECTED_RESOURCES =
  "/nodes/by-affected-resources/events";

export const KOMODOR_SERVICES_RELATED_RESOURCES =
  "/komodor-services/related-resources";
export const KOMODOR_SERVICES_ID = "/komodor-services/:id";
export const KOMODOR_SERVICES_SEARCH = "/komodor-services/search";
export const KOMODOR_SERVICES_BY_APP_VIEW_ID =
  "/komodor-services/app-view/:id/services";
export const WORKLOADS_CRON_JOBS_EVENT_SEARCH =
  "/workloads/cron-jobs/events/search";
export const WORKLOADS_DELETED_PODS_EVENT_SEARCH =
  "/workloads/deleted-pods/events/search";
export const WORKLOADS_DELETED_PODS_EVENT_ID =
  "/workloads/deleted-pods/events/:id";
export const WORKLOADS_JOBS_EVENTS_SEARCH = "/workloads/jobs/events/search";
export const WORKLOADS_PODS_EVENT_SEARCH = "/workloads/pods/events/search";
export const WORKLOADS_PODS_NATIVE_EVENT_SEARCH =
  "/workloads/pods/native-events/search";

export const NETWORK_MAP = "/network-maps/graph";

export const ARGO_WORKFLOW_EVENT_SEARCH =
  "/crd/argoworkflows/workflows/events/search";

export const WORKLOADS_GROUPED_POD_EVENTS_SEARCH =
  "/workloads/pods/grouped-native-events/search";

export const GENERAL_EVENTS = "/events/general";

export const NAMESPACES = "/namespaces";

export type KomodorServicesByAppViewResponse = string[];

export type EndpointsResponseMap = {
  [CONFIGURATION_CONFIGMAPS_EVENT_ID]: ConfigMapEventsResponse;
  [CONFIGURATION_CONFIGMAPS_EVENT_SEARCH]: ConfigMapEventsResponse;
  [CONFIGURATION_HPAS_EVENT_ID]: HpaEventsResponse;
  [CONFIGURATION_HPAS_EVENT_SEARCH]: HpaEventsResponse;
  [CONFIGURATION_LIMIT_RANGES_EVENT_ID]: LimitRangeEventsResponse;
  [CONFIGURATION_PDBS_EVENT_ID]: PdbEventsResponse;
  [CONFIGURATION_PDBS_EVENT_SEARCH]: PdbEventsResponse;
  [CONFIGURATION_RESOURCE_QUOTAS_EVENT_ID]: ResourceQuotaEventsResponse;
  [CONFIGURATION_SECRETS_EVENT_ID]: SecretEventsResponse;
  [CONFIGURATION_SECRETS_EVENT_SEARCH]: SecretEventsResponse;

  [CUSTOM_EVENT_SEARCH]: CustomEventResponse;
  [CUSTOM_SERVICE_EVENT_SEARCH]: CustomEventResponse;
  [DEPLOY_EVENTS_SEARCH]: DeployEventsResponse;
  [DEPLOY_EVENTS_ID]: DeployEventsResponse;

  [HPA_STATE]: HorizontalPodAutoscalerStateResponse;
  [JOB_STATE]: JobsStateResponse;
  [DEPLOYMENT_STATE]: DeploymentsStateResponse;
  [ROLLOUT_STATE]: ArgoRolloutsStateResponse;
  [DAEMONSET_STATE]: DaemonSetsStateResponse;
  [STATEFULSET_STATE]: StatefulSetsStateResponse;
  [POD_STATE]: PodsStateResponse;
  [NODE_STATE]: NodesStateResponse;
  [INGRESS_STATE]: IngressesStateResponse;

  [NETWORK_INGRESS_EVENT_ID]: IngressEventsResponse;
  [NETWORK_INGRESS_EVENT_SEARCH]: IngressEventsResponse;
  [NETWORK_NETWORK_POLICIES_EVENT_ID]: NetworkPolicyEventsResponse;
  [NETWORK_SERVICE_EVENT_SEARCH]: K8sServiceEventsResponse;
  [NETWORK_SERVICE_EVENT_ID]: K8sServiceEventsResponse;
  [NODES_EVENTS_SEARCH]: NodeEventsResponse;
  [NODES_EVENTS_ID]: NodeEventsResponse;
  [NODES_EVENTS_IDS]: NodeEventsResponse;
  [NODES_EVENTS_BY_AFFECTED_RESOURCES]: NodeEventsResponse;

  [KOMODOR_SERVICES_RELATED_RESOURCES]: RelatedResourcesResponse;
  [KOMODOR_SERVICES_ID]: KomodorServicesResponse;
  [KOMODOR_SERVICES_SEARCH]: KomodorServicesResponse;
  [KOMODOR_SERVICES_BY_APP_VIEW_ID]: KomodorServicesByAppViewResponse;

  [WORKLOADS_CRON_JOBS_EVENT_SEARCH]: CronJobEventsResponse;
  [WORKLOADS_DELETED_PODS_EVENT_SEARCH]: DeletedPodsResponse;
  [WORKLOADS_DELETED_PODS_EVENT_ID]: DeletedPodsResponse;
  [WORKLOADS_JOBS_EVENTS_SEARCH]: JobEventsResponse;
  [WORKLOADS_PODS_EVENT_SEARCH]: PodEventsResponse;
  [WORKLOADS_PODS_NATIVE_EVENT_SEARCH]: PodNativeEventsResponse;
  [WORKLOADS_GROUPED_POD_EVENTS_SEARCH]: GroupedPodEventsResponse;

  [NETWORK_MAP]: NetworkMapGraphResponse;

  [ARGO_WORKFLOW_EVENT_SEARCH]: ArgoWorkflowsWorkflowEventResponse;

  [GENERAL_EVENTS]: GeneralEventsResponse;

  [NAMESPACES]: NamespacesResponse;
};

export type EndpointsRequestMap = {
  [CONFIGURATION_CONFIGMAPS_EVENT_ID]: EventsApiApiV1ConfigurationsConfigMapsEventsIdGetRequest;
  [CONFIGURATION_CONFIGMAPS_EVENT_SEARCH]: EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest;
  [CONFIGURATION_HPAS_EVENT_ID]: EventsApiApiV1ConfigurationsHpasEventsIdGetRequest;
  [CONFIGURATION_HPAS_EVENT_SEARCH]: EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest;
  [CONFIGURATION_LIMIT_RANGES_EVENT_ID]: EventsApiApiV1ConfigurationsLimitRangesEventsIdGetRequest;
  [CONFIGURATION_PDBS_EVENT_ID]: EventsApiApiV1ConfigurationsPdbsEventsIdGetRequest;
  [CONFIGURATION_PDBS_EVENT_SEARCH]: EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest;
  [CONFIGURATION_RESOURCE_QUOTAS_EVENT_ID]: EventsApiApiV1ConfigurationsResourceQuotasEventsIdGetRequest;
  [CONFIGURATION_SECRETS_EVENT_ID]: EventsApiApiV1ConfigurationsSecretsEventsIdGetRequest;
  [CONFIGURATION_SECRETS_EVENT_SEARCH]: EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest;
  [CUSTOM_EVENT_SEARCH]: EventsApiApiV1CustomEventsSearchGetRequest;
  [CUSTOM_SERVICE_EVENT_SEARCH]: EventsApiApiV1CustomServiceEventsSearchGetRequest;
  [DEPLOY_EVENTS_SEARCH]: EventsApiApiV1DeploysEventsSearchGetRequest;
  [DEPLOY_EVENTS_ID]: EventsApiApiV1DeploysEventsIdGetRequest;

  [HPA_STATE]: StateRequest;
  [JOB_STATE]: StateRequest;
  [DEPLOYMENT_STATE]: StateRequest;
  [ROLLOUT_STATE]: StateRequest;
  [DAEMONSET_STATE]: StateRequest;
  [STATEFULSET_STATE]: StateRequest;
  [POD_STATE]: PodStateRequest;
  [NODE_STATE]: StateRequest;
  [INGRESS_STATE]: StateRequest;

  [KOMODOR_SERVICES_RELATED_RESOURCES]: ApiV1KomodorServicesRelatedResourcesPostRequest;
  [KOMODOR_SERVICES_SEARCH]: KomodorServicesApiApiV1KomodorServicesSearchGetRequest;
  [KOMODOR_SERVICES_ID]: KomodorServicesApiApiV1KomodorServicesIdGetRequest;
  [KOMODOR_SERVICES_BY_APP_VIEW_ID]: KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGetRequest;

  [NETWORK_INGRESS_EVENT_ID]: EventsApiApiV1NetworkIngressesEventsIdGetRequest;
  [NETWORK_INGRESS_EVENT_SEARCH]: EventsApiApiV1NetworkIngressesEventsSearchGetRequest;
  [NETWORK_NETWORK_POLICIES_EVENT_ID]: EventsApiApiV1NetworkNetworkPoliciesEventsIdGetRequest;
  [NETWORK_SERVICE_EVENT_SEARCH]: EventsApiApiV1NetworkServicesEventsSearchGetRequest;
  [NETWORK_SERVICE_EVENT_ID]: EventsApiApiV1NetworkServicesEventsIdGetRequest;
  [NODES_EVENTS_SEARCH]: EventsApiApiV1NodesEventsSearchGetRequest;
  [NODES_EVENTS_ID]: EventsApiApiV1NodesEventsIdGetRequest;
  [NODES_EVENTS_IDS]: EventsApiApiV1NodesEventsIdsGetRequest;
  [NODES_EVENTS_BY_AFFECTED_RESOURCES]: EventsApiApiV1NodesByAffectedResourcesEventsGetRequest;

  [WORKLOADS_CRON_JOBS_EVENT_SEARCH]: EventsApiApiV1WorkloadsCronJobsEventsSearchGetRequest;
  [WORKLOADS_DELETED_PODS_EVENT_SEARCH]: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest;
  [WORKLOADS_DELETED_PODS_EVENT_ID]: EventsApiApiV1WorkloadsDeletedPodsEventsIdGetRequest;
  [WORKLOADS_JOBS_EVENTS_SEARCH]: EventsApiApiV1WorkloadsJobsEventsSearchGetRequest;
  [WORKLOADS_PODS_EVENT_SEARCH]: EventsApiApiV1WorkloadsPodsEventsSearchGetRequest;
  [WORKLOADS_PODS_NATIVE_EVENT_SEARCH]: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest;
  [WORKLOADS_GROUPED_POD_EVENTS_SEARCH]: GroupedPodEventsRequest;

  [NETWORK_MAP]: NetworkMapApiApiV1NetworkMapsGraphGetRequest;

  [ARGO_WORKFLOW_EVENT_SEARCH]: EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGetRequest;

  [GENERAL_EVENTS]: EventsApiApiV1EventsGeneralGetRequest;

  [NAMESPACES]: AccountNamespacesApiApiV1NamespacesGetRequest;
};
