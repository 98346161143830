/* eslint-disable max-lines */
import {
  InstallationScriptStepsNumber,
  StepsNumber,
} from "../../components/integrations/installation/kubernetes/steps/types";

export const SegmentIntegrations = { Hubspot: "Hubspot (Classic)" };

export const AnalyticEvents = {
  App: {
    Loaded: "App_Loaded",
    New_Tab: "New_Tab",
    Close_Tab: "Close_Tab",
    Close_All_Tabs: "Close_All_Tabs",
    Account_Switcher_Open: "Account_Switcher_Open",
    Account_Switcher_Close: "Account_Switcher_Close",
    Account_Switcher_Select: "Account_Switcher_Select",
    Refresh_JWT_Expired: "Refresh_JWT_Expired",
  },
  AddMember: {
    OpenAddMemberModal: "Open_Add_Member_Modal",
    OpenAddMemberBulkModal: "Open_Add_Member_Bulk_Modal",
    FinishAddingMember: "Finish_Adding_Member",
    FinishAddingBulkMembers: "Finish_Adding_Bulk_Members",
  },
  Settings: {
    EditProfileName: "Settings_Edit_Profile_Name",
    AddUser: "Settings_Add_User",
    EditUser: "Settings_Edit_User",
    DeleteUser: "Settings_Delete_User",
    AddBulkUsers: "Settings_Add_Bulk_Users",
    AddRole: "Settings_Add_Role",
    EditRole: "Settings_Edit_Role",
    DeleteRole: "Settings_Delete_Role",
    AddPolicy: "Settings_Add_Policy",
    EditPolicy: "Settings_Edit_Policy",
    DeletePolicy: "Settings_Delete_Policy",
    AddAction: "Settings_Add_Action",
    EditAction: "Settings_Edit_Action",
    DeleteAction: "Settings_Delete_Action",
  },
  Signup: {
    NewUserAccount: "Signup_NewUser_Authenticated",
    FirstTimeLogin: "Signin_NewUser_Login",
    ChooseAccountName: {
      Click_Continue_Button: "Signup_ChooseAccountName_Continue_Click",
      Click_Suggestion: "Signup_ChooseAccountName_Suggestion_Click",
    },
  },
  GettingStarted: {
    OpenMissions: "Getting_Started_Missions_Card_Open",
    ConfettiShow: "Getting_Started_Confetti_Show",
    InstallAgentMissionClick: "Getting_Started_Install_Agent_Mission_Click",
    InviteUserMissionClick: "Getting_Started_Invite_User_Mission_Click",
    SlackIntegrationMissionClick:
      "Getting_Started_Slack_Integration_Mission_Click",
    ExternalLinkMissionClick: "Getting_Started_External_Link_Mission_Click",
    MonitorSinkMissionClick: "Getting_Started_Monitor_Sink_Mission_Click",
    GitIntegrationMissionClick: "Getting_Started_Git_Integration_Mission_Click",
  },
  Data: {
    KomodorServices_Loaded: "KomodorServices_Loaded",
  },
  Integrations: {
    RemoveTile: "RemoveIntegrationTile",
    Kubernetes: {
      KomodorAgentConnected: "Kubernetes_Installation_Agent_Connected",
      ChangedInstallTab: "Changed_Install_Tab",
      ClickedNextButton: "Kubernetes_Installation_Agent_Clicked_Next",
      ClickedBackResetSetup: "Kubernetes_Installation_Agent_Setup_Reset",
      CopyInstallScript: "Kubernetes_Installation_Copy_Install_Script",
      Steps: StepsNumber ? Object.keys(StepsNumber) : [],
      ScriptSteps: InstallationScriptStepsNumber
        ? Object.values(InstallationScriptStepsNumber)
        : [],
      SandboxCta: "Kubernetes_Installation_Sandbox_Cta",
    },
    PrometheusMetricServer: {
      OpenDocs: "PrometheusMetricServer_OpenDocsToInstallPrometheus",
    },
  },
  Searchbar: {
    OpenSearchBar: "SearchBar_Opened",
    ClickedSearchResult: "SearchBar_Result_Clicked",
  },
  UserManagement: {
    TrialEndedModalScheduleMeeting: "Trial_Ended_Modal_Schedule_Meeting",
    TrialEndedModalOpened: "Trial_Ended_Modal_Opened",
    TrialEndedModalContactUsButtonClick:
      "Trial_Ended_Modal_Contact_Us_Button_Click",
    TrialEndCounterInviteUserButtonClick:
      "Trial_End_Counter_Invite_User_Button_Click",
    TrialEndCounterUpgradeAccountButtonClick:
      "Trial_End_Counter_Upgrade_Account_Button_Click",
    PricingPlansUpgradeRquest: "PricingPlans-UpgradeRquest",
    PricingPlansLinkOpend: "PricingPlans-LinkOpend",
    SandboxCTAS: {
      SandboxCTASignupClick: "Sandbox_CTA_Signup_Click",
      SandboxCTAInstallAgentClick: "Sandbox_CTA_Install_Agent_Click",
      SandboxCTABackToKomodorClick: "Sandbox_CTA_Back_To_Komodor_click",
    },
  },
  TimePicker: {
    Select: "TimePicker_Select",
    Default: "TimePicker_Default",
  },
  TimezoneSelector: {
    Close: "Timezone_Selector_Close",
    Select: "Timezone_Select",
    Open: "Timezone_Selector_Open",
  },
  Charts: {
    Timeline: {
      EventGroup_Click: "TimelineChart_EventGroup_Click",
      Zoom_Change: "TimelineChart_Zoom_Change",
    },
    Barchart: {
      Zoom_Change: "BarChart_Zoom_Change",
      Bar_Click: "BarChart_Bar_Click",
    },
    Linechart: {
      Zoom_Change: "LineChart_Zoom_Change",
    },
  },
  ProcessList: {
    Drawer_Open: "Drawer_Open",
    Drawer_Close: "Drawer_Close",
    ShowMoreEvents_Click: "EventsList_ShowMoreEvents_Click",
    EventLink_Click: "EventsList_EventLink_Click",
  },
  ExploreServicesView: {
    ServiceTile_Click: "ServiceTile_Click",
    Filter_Uncheck: "ServiceExplorer_Filter_Uncheck",
    Filter_Check: "ServiceExplorer_Filter_Check",
    Filter_ClearAll: "ServiceExplorer_Filter_ClearAll",
    CustomFilter_Click: "ServiceExplorer_CustomFilter_Click",
    SortBy_Dropdown_Clicked: "Sort_by_dropdown_clicked",
    ViewedServiceExplorer: "ServiceExplorer_View",
    ViewedJobExplorer: "JobExplorer_View",
  },
  WorkflowsView: {
    Add_Clicked: "Workflows_Add_Workflow_Clicked",
    Kubernetes_Documentation_Clicked:
      "Workflows_Kubernetes_Documentation_Clicked",
    View_Pods_And_Logs_Clicked: "Workflows_View_Pods_And_Logs_Clicked",
    LatestRuns_Filter_Selected: "Workflows_LatestRuns_Filter_Selected",
    Configuration_Exit_Clicked: "Workflows_configuration_exit_clicked",
    Workflow_Activated: "Workflows_Workflow_Activated",
    Impacted_Services_Clicked: "Workflows_impacted_services_clicked",
    Distinct_Check_More_Info_Clicked:
      "Workflows_Distinct_Check_More_Info_Clicked",
    Run_Row_Clicked_With_Source: "Workflows_Run_Row_Clicked_With_Source",
    Availability: {
      InfoCheck: {
        ViewAllDiff_Clicked:
          "Workflows_Availability_InfoCheck_ViewAllDiff_Clicked",
        AddDeployLink_Click:
          "Workflows_Availability_InfoCheck_AddDeployLink_Click",
      },
      Investigation: {
        Investigate_Clicked:
          "Workflows_Availability_Investigation_Investigate_Clicked",
      },
    },
  },
  MonitorsView: {
    Filter_Selected: "Monitors_Filter_Selected",
    Page_Clicked: "Monitors_Page_Clicked",
    Rule_Created: "Monitors_Rule_Created",
    Rule_Modified: "Monitors_Rule_Modified",
    Rule_Deleted: "Monitors_Rule_Deleted",
    Rule_Disabled: "Monitors_Rule_Disabled",
    Rule_Enabled: "Monitors_Rule_Enabled",
    Sink_Configured: "Monitors_Sink_Configured",
    Sink_Removed: "Monitors_Sink_Removed",
    Run_Opened: "Monitors_Run_Opened",
    Event_Drawer_Opened: "Monitors_Event_Drawer_Opened",
    Any_Result_Opened: "Monitors_Any_Result_Opened",
  },
  JobsView: {
    JobTile_Click: "JobTile_Click",
    JobsView_NotAvailable: "JobsView_NotAvailable",
    CronJobs_PodsLogsClicked: "CronJobs_PodsLogsClicked",
    CronJobs_PodsLogsFetchFailed: "CronJobs_PodsLogsFetchFailed",
  },
  ServiceView: {
    ShareEventButton_Click: "ServiceView_ShareEventButton_Click",
    View: "ServiceView_View",
    Filters_Reset: "ServiceView_Filters_Reset",
    Filters_Change: "ServiceView_Filters_Change",
    ServiceDescribeButton_Clicked: "ServiceDescribeButton_Clicked",
    RelatedService_Title_Click: "RelatedService_Title_Click",
    ViewNodes_Button_Clicked: "Service_ViewNodesButton_Clicked",
    RelatedResources: {
      RelatedResource_Toggle: "RelatedResource_Toggle",
      RelatedResource_AddResources: "RelatedResource_Add",
      RelatedResource_RemoveResources: "RelatedResource_Remove",
    },
    RelatedService_Check: "RelatedService_Check",
    RelatedService_Uncheck: "RelatedService_Uncheck",
    ServiceDescribe_Failed: "ServiceDescribe_Failed",
    ServiceDescribe_Success: "ServiceDescribe_Success",
    ServiceDescribe_Refresh: "ServiceDescribe_Refresh",
    StaticPrevention: {
      Ignore: "StaticPrevention_Ignore",
      Unignore: "StaticPrevention_Unignore",
      Modal_Close: "StaticPrevention_Modal_Close",
      Modal_Open: "StaticPrevention_Modal_Open",
      Extend_Check_Content: "StaticPrevention_Extend_Check_Content",
    },
    Compare_button_clicked: "Compare_button_clicked",
  },
  PodEvents: {
    AddPodEvent_clicked: "PodEvents_AddPodEvent_Clicked",
    ApplySelection_clicked: "PodEvents_ApplySelection_clicked",
  },
  PodPhases: {
    PodPhase_clicked: "PodPhases_PodPhase_Clicked",
  },
  EventsView: {
    Loaded: "Events_View",
    SavedFilter_Add: "EventsView_SavedFilter_Add",
    SavedFilter_Delete: "EventsView_SavedFilter_Delete",
    Filter_Uncheck: "EventsView_Filter_Uncheck",
    Filter_Check: "EventsView_Filter_Check",
    SavedFilter_Apply: "EventsView_SavedFilter_Apply",
    Filter_ClearAll: "EventsView_Filter_ClearAll",
    Exclude_Filter_Clicked: "EventsView_Exclude_Filter_Clicked",
    ChangeEvent_Row_Clicked: "EventsView_Change_Event_Raw_Clicked",
  },
  PodView: {
    Back_To_Service_Clicked: "Pod_View_Back_To_Service_Clicked",
    Refresh_Clicked: "Pod_View_Refresh_Clicked",
    Logs_Previous_Requested: "Pod_Logs_Previous_Requested",
    Logs_Container_Selected: "Pod_Logs_Container_Selected",
    Logs_Close_Modal: "Pod_Logs_Close_Modal",
    Logs_Search_Clicked: "Pod_Logs_Search_Clicked",
    Logs_Action_Selected: "Pod_Logs_Action_Selected",
    Logs_Search_Icon_Clicked: "Pod_Logs_Search_Icon_Clicked",
    Logs_Open_Extended_Window: "Pod_Logs_Open_Extented_Window",
    Extended_Window_Download: "Pod_Logs_Extented_Window_Download",
    Extended_Window_CopyLogs: "Pod_Logs_Extented_Window_CopyLogs",
    ReplicaSet_Related_Service: "ReplicaSet_Related_Service",
    View_Pod_Clicked: "Pod_View_Pod_Clicked",
    ReplicaSet_Tile_Clicked: "ReplicaSet_Tile_Clicked",
    Tab_Clicked: "Pod_Tab_Clicked",
    Pod_Logs_Timeout: "Pod_Logs_Timeout",
    Pod_Logs_Failure: "Pod_Logs_Failure",
    Pod_Logs_E2E_Latency: "Pod_Logs_E2E_Latency",
    Pod_Describe_Loaded: "Pod_Describe_Loaded",
    Pod_Describe_Failed: "Pod_Describe_failure",
    Pod_Describe_No_data: "Pod_Describe_No_data",
  },
  PodViewV2: {
    Back_To_Service_Clicked: "Pods_View_V2_Pod_View_Back_To_Service_Clicked",
    Refresh_Clicked: "Pods_View_V2_Pod_View_Refresh_Clicked",
    Logs_Previous_Requested: "Pods_View_V2_Pod_Logs_Previous_Requested",
    Logs_Container_Selected: "Pods_View_V2_Pod_Logs_Container_Selected",
    Logs_Close_Modal: "Pods_View_V2_Pod_Logs_Close_Modal",
    Logs_Search_Clicked: "Pods_View_V2_Pod_Logs_Search_Clicked",
    Logs_Action_Selected: "Pods_View_V2_Pod_Logs_Action_Selected",
    Logs_Search_Icon_Clicked: "Pods_View_V2_Pod_Logs_Search_Icon_Clicked",
    Logs_Open_Extended_Window: "Pods_View_V2_Pod_Logs_Open_Extented_Window",
    Extended_Window_Download: "Pods_View_V2_Pod_Logs_Extented_Window_Download",
    Extended_Window_CopyLogs: "Pods_View_V2_Pod_Logs_Extented_Window_CopyLogs",
    ReplicaSet_Related_Service: "Pods_View_V2_ReplicaSet_Related_Service",
    View_Pod_Clicked: "Pods_View_V2_Pod_View_Pod_Clicked",
    ReplicaSet_Tile_Clicked: "Pods_View_V2_ReplicaSet_Tile_Clicked",
    Tab_Clicked: "Pods_View_V2_Pod_Tab_Clicked",
    Pod_Logs_Timeout: "Pods_View_V2_Pod_Logs_Timeout",
    Pod_Logs_Failure: "Pods_View_V2_Pod_Logs_Failure",
    Pod_Logs_E2E_Latency: "Pods_View_V2_Pod_Logs_E2E_Latency",
    Pod_More_Logs_Clicked: "Pods_View_V2_More_Logs_Clicked",
  },
  AutomatedChecks: {
    View_Analysis_Event_Button_Clicked:
      "AutomatedChecks_View_Analysis_Event_Button_Clicked",
    Check_PodsHealth_ViewPods_Clicked:
      "AutomatedChecks_Check_PodsHealth_ViewPods_Clicked",
    Check_PodsHealth_ViewNodes_Clicked:
      "AutomatedChecks_Check_PodsHealth_ViewNodes_Clicked",
    Check_MoreInfo_Toggled: "AutomatedChecks_Check_MoreInfo_Toggled",
    SpecChanged_ViewDiff: "AutomatedChecks_SpecChanged_ViewDiff",
  },
  CTA: {
    Teams_Pressed: "Teams_CTA_Pressed",
    Github_Pressed: "Github_CTA_Pressed",
    Slack_Pressed: "Slack_CTA_Pressed",
  },
  KubernetesDiffs: {
    Modal_Close: "KubernetsDiffModal_Close",
    Modal_View: "KubernetsDiffModal_View",
  },
  ToastHeaders: {
    NewEventAlert_Click: "NewEventAlert_Click",
    NewEventAlert_View: "NewEventAlert_View",
  },
  DetailsDrawer: {
    HealthEvent_DeployEvent_Click: "HealthEventDrawer_DeployEvent_Click",
    HealthEvent_Pod_View_Opened: "HealthEventDrawer_Pod_View_Opened",
    Tab_Clicked: "Drawer_Tab_Clicked",
  },
  PodEventDetailsDrawer: {
    View_Logs_Clicked: "View_Logs_Clicked",
  },
  FilterBar: {
    Category_Search: "Category_Search",
    Clear_Category_Filters: "Clear_Category_Filters",
    Scroll_In_Category: "Scroll_In_Category",
    Save_Filter: "Save_Filter",
    Delete_Filter: "Delete_Filter",
  },
  Inspection: {
    E2E_Latency: "Inspection_E2E_Latency",
    E2E_Resource_Description_Drawer_Latency:
      "E2E_Resource_Description_Drawer_Latency",
    Navbar_link_clicked: "Inspection_Navbar_link_clicked",
    Inspection_resource_table_row_clicked:
      "Inspection_resource_table_row_clicked",
    Inspection_pods_node_describe_clicked:
      "Inspection_pods_node_describe_clicked",
    Inspection_resource_list_start: "Inspection_resource_list_start",
    Inspection_resource_list_error: "Inspection_resource_list_error",
    Inspection_resource_list_upgrade_agent:
      "Inspection_resource_list_upgrade_agent",
    Inspection_resource_list_controlled_by_clicked:
      "Inspection_resource_list_controlled_by_clicked",
    Inspection_resource_list_name_clicked:
      "Inspection_resource_list_name_clicked",
    Inspection_show_deleted_pods_toggled:
      "Inspection_show_deleted_pods_toggled",
    Inspection_resource_list_sort_column:
      "Inspection_resource_list_sort_column",
    Inspection_resource_list_toggle_column:
      "Inspection_resource_list_toggle_column",
    Inspection_show_deleted_pods_upgrade_agent:
      "Inspection_show_deleted_pods_upgrade_agent",
  },
  DeepDive: {
    DeepDive_Agent_Pod_Error: "DeepDive_Agent_Pod_Error",
    Pod_Events_Select_Pod_Clicked: "Pod_Events_Select_Pod_Clicked",
  },
  Links: {
    Add_External_Link_Clicked: "Add_External_Link_Clicked",
    Add_External_Link_Submit: "Add_External_Link_Submit",
    Edit_External_Link_Clicked: "Edit_External_Link_Clicked",
    Delete_External_Link_Clicked: "Delete_External_Link_Clicked",
    External_Link_Clicked: "External_Link_Clicked",
    LinkType: {
      ExternalLink: "ExternalLink",
      AnnotationLink: "AnnotationLink",
    },
  },
  Actions: {
    Actions_upgrade_agent: "Actions_upgrade_agent",
    Actions_fired: "Actions_fired",
    Actions_failed: "Actions_failed",
    Actions_inspection_button: "Actions_inspection_clicked",
  },
  AppBar: {
    AppBar_Item_Clicked: "AppBar_Item_Clicked",
    AppBar_Locked: "AppBar_Locked",
  },
  UserActions: {
    User_Login: "User_Login",
  },
  PodExec: {
    Same_Container_Selected_Twice: "Same_Container_Selected_Twice",
  },
  Desktop: {
    Opend: "Desktop_App_Open_App",
    Entered_User_ID: "Desktop_App_Enter_User_ID",
    Identified_Clusters: "Desktop_App_Identify_Clusters_Success",
    Choose_Clusters: "Desktop_App_Choose_Initial_Clusters",
  },
  Metrics: {
    MemoryGraph: {
      Opened: "Opened_availability_issue_metrics_MemoryGraph",
    },
    CPUGraph: {
      Opened: "Opened_availability_issue metrics_CPUGraph",
    },
    Tooltip: {
      Opened: "Opened_availability_issue_metrics_Tooltip",
    },
    EnlargeGraph: "Enlarge_Metrics_Graph",
  },
  Scroll: {
    ScrollDepth: "Scroll_Depth",
    IsScrolled: "Is_Scrolled",
  },
  Logs: {
    Live: {
      ResourceLogsNewLogsAvailableClicked:
        "ResourceLogs_newLogsAvailable_clicked",
    },
  },
  GlobalSearch: {
    Opened: "Opened_GlobalSearch",
    SelectedItem: "Selected_item_GlobalSearch",
    NoResults: "No_results_GlobalSearch",
    Closed: "Closed_GlobalSearch",
  },
  Workspaces: {
    WorkspaceSwitcherClicked: "Workspace_Switcher_Clicked",
    WorkspaceSwitcherEditWorkspaceClicked:
      "Workspace_Switcher_Edit_Workspace_Clicked",
    WorkspaceSwitcherAddClusterGroupClicked:
      "Workspace_Switcher_Add_Cluster_Group_Clicked",
    WorkspaceSelected: "Workspace_Selected",
    WorkspaceOverviewViewed: "WorkspaceOverview_Viewed",
  },
  ClusterOverview: {
    HealthSection: {
      TileClicked: "ClusterOverview_HealthSection_Tile_Clicked",
    },
  },
  ClustersList: {
    ClusterSelected: "Cluster_Selected",
  },
  KlaudiaAi: {
    Investigation: "klaudia investigation",
    InvestigationResourceLinkClicked:
      "klaudia investigation resource link clicked",
    MinimizedInvestigationExpanded: "klaudia minimized investigation expanded",
    MinimizedInvestigationRemoved: "klaudia minimized investigation removed",
    MinimizedInvestigationsToggled: "klaudia minimized investigations toggled",
    Chat: {
      MessageSent: "klaudia chat message sent",
    },
  },
  ComingSoon: {
    JoinPrivateBeta: "Coming Soon Join Private Beta clicked",
  },
  Reliability: {
    IssuesRowClicked: "Reliability_Issues_Row_Clicked",
    DrawerOpened: "Reliability_Drawer_Opened",
    ScaleDownImpact: {
      ImpactedResourceClicked:
        "Reliability_Scale_Down_Impact_ImpactedResource_Clicked",
    },
  },
  RbacClusterSync: {
    GetKubeconfig: "Rbac_Cluster_Sync_Get_Kubeconfig_Menu_Clicked",
    DownloadKubeconfig: "Rbac_Cluster_Sync_Download_Kubeconfig_Clicked",
    FeatureConfigurationMenu: "Rbac_Cluster_Sync_Feature_Configuration_Menu",
    FeatureManualForceSync: "Rbac_Cluster_Sync_Feature_Manual_Force_Sync",
  },
  ExternalDNS: {
    RowClicked: "ExternalDNS_Row_Clicked",
  },
  NodeAutoscalers: {
    RowClicked: "Node_Autoscalers_Row_Clicked",
    DrawerRiskClicked: "Node_Autoscalers_Drawer_Risk_Clicked",
    DrawerKarpenterNodePoolClicked:
      "Node_Autoscalers_Drawer_Karpenter_Node_Pool_Clicked",
  },
  HelmChartAddon: {
    RowClicked: "Helm_Chart_Row_Clicked",
  },
  DriftAnalysis: {
    Service: {
      BaselineSelected: "Drift_Analysis_Baseline_Service_Selected",
      ComparedServicesSelected: "Drift_Analysis_Compared_Service_Selected",
      ShowOnlyDriftSwitcher: "Drift_Analysis_Show_Only_Drift_Switcher_Service",
    },
    Helm: {
      BaselineSelected: "Drift_Analysis_Baseline_Helm_Selected",
      ComparedHelmSelected: "Drift_Analysis_Compared_Helm_Selected",
      ShowOnlyDriftSwitcher: "Drift_Analysis_Show_Only_Drift_Switcher_Helm",
      YamlComparison: "Drift_Analysis_Yaml_Comparison",
      ServicesDriftComparisonDrawer:
        "Drift_Analysis_Helm_Services_Drift_Comparison_Drawer",
    },
  },
};
