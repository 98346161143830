import React from "react";

import { useClusterNames } from "../useClusterNames";

import { useNodesMetrics } from "./useNodesMetrics";
import { MetricGraph, MetricGraphProps } from "./MetricGraph";

import { useMemoryAndCPUXAxisConfig } from "@/components/k8sAddons/addons/NodeAutoscalers/hooks/useMemoryAndCpuXAxisConfig";
import { ClearLoader } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/ClearLoader";

export const AutoscalersMetricsGraph: React.FC = () => {
  const { clusterNames, hasClusters } = useClusterNames();
  const { error, memoryData, cpuData, isLoading, timeRange } = useNodesMetrics({
    clusterNames,
    isDataFetchEnabled: hasClusters,
  });

  const graphContainerProps: MetricGraphProps["graphContainerProps"] = {
    uid: undefined,
    hasGraphData: !error && !isLoading,
    error: error ? "Something went wrong" : null,
    loading: !hasClusters || isLoading,
    renderEmptyStateCB: ClearLoader,
  };

  const { cpuXAxisConfig, memoryXAxisConfig } = useMemoryAndCPUXAxisConfig({
    cpuData: cpuData.allocated,
    memoryData: memoryData.allocated,
    fromEpoch: timeRange.fromEpoch,
  });

  return (
    <>
      <MetricGraph
        graphContainerProps={graphContainerProps}
        metric="cpu"
        metricData={cpuData}
        showLabel={false}
        xAxisConfig={cpuXAxisConfig}
        withBorder
      />
      <MetricGraph
        graphContainerProps={graphContainerProps}
        metric="memory"
        metricData={memoryData}
        showLabel={false}
        xAxisConfig={memoryXAxisConfig}
        withBorder
      />
    </>
  );
};
