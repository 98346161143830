import { useState, useEffect, createContext } from "react";

import { useAgentServicePolling } from "../../agents-api/agents/useAgentsPolling";
import { agentResponse } from "../../agents-api/utils";
import { useIsAccountSetUp } from "../../useUserMetadata/utils";
import { useStateInLocalStorage } from "../../state/useStateInLocalStorage";

import { isAgentsInstalledLocalStorage } from "./consts";

import { isSandbox } from "@/shared/utils/sandbox";

export type AgentsContextData = {
  agents?: agentResponse;

  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
};

const defaultAgentContextData: AgentsContextData = {
  agents: undefined,
  isLoading: true,
  isFetching: false,
  isError: false,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const AgentsContext = createContext<AgentsContextData>(
  defaultAgentContextData
);

const useSaveLocalStorageAgentsState = (
  agents: agentResponse | undefined
): void => {
  const [isAgentsInstalled, setIsAgentsInstalled] = useStateInLocalStorage(
    isAgentsInstalledLocalStorage,
    ""
  );
  useEffect(() => {
    if (isSandbox()) return;
    if (isAgentsInstalled === "true") return;
    const isActiveAgents = agents?.activeAgents?.length ? "true" : "";
    setIsAgentsInstalled(isActiveAgents);
  }, [agents, isAgentsInstalled, setIsAgentsInstalled]);
};

export const AgentsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isAccountSetUp = useIsAccountSetUp();
  const [value, setValue] = useState<AgentsContextData>(
    defaultAgentContextData
  );
  const [agentsData, setAgentsData] = useState<agentResponse | undefined>();
  const { data, isFetching, isError, isLoading } = useAgentServicePolling(
    1000 * 15,
    !isAccountSetUp
  ); // 15 seconds
  // avoid rerendering when data is the same
  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(agentsData)) {
      return;
    }
    setAgentsData(data);
  }, [agentsData, data]);
  useSaveLocalStorageAgentsState(data);

  useEffect(() => {
    setValue({
      agents: agentsData,
      isLoading: !!isLoading,
      isFetching: !!isFetching,
      isError: !!isError,
    });
  }, [agentsData, isError, isFetching, isLoading]);
  return (
    <AgentsContext.Provider value={value}>{children}</AgentsContext.Provider>
  );
};
