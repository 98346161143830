import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "qs";

import { DRAWER_STACK_STATE_URL_PARAM } from "@/shared/config/urlSearchParamsKeys";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { ReliabilityRoutes } from "@/components/reliability/constants/reliabilityConstants";

export const HealthRisksDrawerRedirection: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const healthType = searchParams.get("checkCategory");
    const checkType = searchParams.get("checkType");
    const violationId = searchParams.get("violationId");

    const redirectionObj = {
      [DRAWER_STACK_STATE_URL_PARAM]: JSON.stringify([
        {
          index: 0,
          drawerType: DrawerType.ViolationDrawer,
          violationId: violationId,
          checkType: checkType,
        },
      ]),
    };

    const stringified = qs.stringify(redirectionObj, { encode: false });

    const redirectUrl = `/${healthType}/${ReliabilityRoutes.violations}?${stringified}`;
    navigate(redirectUrl, { replace: true });
  }, [navigate, searchParams]);

  return null;
};
