import { useCallback } from "react";
import {
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { RuleGroupType } from "react-querybuilder";
import { v4 } from "uuid";

import { WorkspaceDetails } from "../types";
import { validateScopedWorkspaceDetails } from "../components/drawer/scopedWorkspaceValidationSchema";
import { INITIAL_RULE } from "../constants";
import { queryToResourcesScope } from "../../access-management/PoliciesPage/utils/queryToResourcesScope";

import { useCreateScopedWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useCreateScopedWorkspace";
import { useEditWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useEditWorkspaces";
import {
  ScopedWorkspace,
  Workspace,
  WorkspaceKind,
} from "@/generated/workspacesApi";

export const useScopedWorkspacesFormActions = (
  formCallbacks: {
    getFormValues: UseFormGetValues<WorkspaceDetails>;
    setError: UseFormSetError<WorkspaceDetails>;
    setValue: UseFormSetValue<WorkspaceDetails>;
    onCloseDrawer: () => void;
  },
  workspaceId: string | undefined
) => {
  const {
    mutateAsync: createScopedWorkspace,
    isError: isErrorCreatingWorkspace,
    isLoading: isCreatingWorkspace,
  } = useCreateScopedWorkspace();
  const {
    mutateAsync: editWorkspace,
    isError: isErrorEditingWorkspace,
    isLoading: isEditingWorkspace,
  } = useEditWorkspace();

  const onSubmit = useCallback(async () => {
    const { getFormValues, setError, onCloseDrawer } = formCallbacks;
    const error = await validateScopedWorkspaceDetails({
      name: getFormValues("name"),
      description: getFormValues("description"),
    });

    Object.keys(error).forEach((key) =>
      setError(key as keyof WorkspaceDetails, {
        type: "validate",
        message: error[key].message,
      })
    );

    if (error.length) {
      return;
    }

    const definitions = getFormValues("definitions");
    const ruleGroups = definitions.map((def) => queryToResourcesScope(def));

    // TODO: Error handling here?

    if (!workspaceId) {
      await createScopedWorkspace({
        workspace: {
          id: "",
          name: getFormValues("name"),
          description: getFormValues("description"),
          kind: WorkspaceKind.Scoped,
          value: {
            kind: "scoped",
            scopes: ruleGroups,
          } as ScopedWorkspace,
        } as Workspace,
      });
    } else {
      await editWorkspace({
        id: workspaceId,
        name: getFormValues("name"),
        description: getFormValues("description"),
        kind: WorkspaceKind.Scoped,
        value: {
          kind: "scoped",
          scopes: ruleGroups,
        } as ScopedWorkspace,
      } as Workspace);
    }

    if (isErrorCreatingWorkspace || isErrorEditingWorkspace) {
      // TODO: Better error handling here.
    }

    onCloseDrawer();
  }, [
    createScopedWorkspace,
    editWorkspace,
    formCallbacks,
    isErrorCreatingWorkspace,
    isErrorEditingWorkspace,
    workspaceId,
  ]);

  const onSetDefinition = useCallback(
    (definition: RuleGroupType, index: number) => {
      const { getFormValues, setValue } = formCallbacks;
      const workspaceDefinitionValue = getFormValues("definitions");

      if (index < 0 || index >= workspaceDefinitionValue.length) {
        // TODO: Add DD reporting here
        return;
      }

      const newDef = [...workspaceDefinitionValue];
      newDef[index] = definition;

      setValue("definitions", newDef);
    },
    [formCallbacks]
  );

  const onAddDefinition = useCallback(() => {
    const { getFormValues, setValue } = formCallbacks;
    const workspaceDefinitionValue = getFormValues("definitions");

    const newDefs = [...workspaceDefinitionValue];
    const newDef = { ...INITIAL_RULE, id: v4() };
    newDefs.push(newDef);

    setValue("definitions", newDefs);
  }, [formCallbacks]);

  return {
    isCreatingWorkspace,
    isEditingWorkspace,
    onSubmit,
    onSetDefinition,
    onAddDefinition,
  };
};
