import { useQuery } from "@tanstack/react-query";

import { useSearchApiClient } from "./client/apiClient";

import { dispatchEvent, AnalyticEvents } from "@/shared/hooks/analytics";
import {
  apiV1SearchGetUrl,
  ResourceKind,
  SearchResponse,
} from "@/generated/searchApi";

const ROOT_URL = "/";

export interface CommandPopperContentQueryParams {
  cluster?: string;
  namespace?: string;
  kinds?: ResourceKind[];
}

type SearchAPIGetParams = {
  query: string;
  workspaceId: string | undefined;
  options: SearchAPIGetParamsOptions;
  queryParams?: CommandPopperContentQueryParams;
};

type SearchAPIGetParamsOptions = {
  pause: boolean;
  staleTime: number;
  skipAppViewInHeaders?: boolean;
  skipWorkspaceHeader?: boolean;
};

export const useSearchAPIGet = ({
  query,
  workspaceId,
  options,
  queryParams,
}: SearchAPIGetParams) => {
  const { staleTime, skipAppViewInHeaders, skipWorkspaceHeader, pause } =
    options;
  const apiClient = useSearchApiClient(
    skipAppViewInHeaders,
    skipWorkspaceHeader
  );
  const res = useQuery(
    [ROOT_URL, query, workspaceId, skipAppViewInHeaders, skipWorkspaceHeader],
    async () => {
      const res = await apiClient.get<SearchResponse>(
        apiV1SearchGetUrl(
          {
            query,
            cluster: queryParams?.cluster,
            namespace: queryParams?.namespace,
            kinds: queryParams?.kinds,
          },
          apiClient.defaults.baseURL ?? ""
        )
      );
      if (res.data.results.length === 0) {
        dispatchEvent(AnalyticEvents.GlobalSearch.NoResults, { query });
      }
      return res;
    },
    {
      enabled: !pause,
      staleTime: staleTime,
    }
  );
  return {
    loading: res.isLoading,
    error: res.error as string,
    data: res.data?.data,
  };
};
