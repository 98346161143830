import {
  AdvancedMultiSelect,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import styled, { css } from "styled-components";
import Box from "@mui/material/Box";
import { muiColors } from "@komodorio/design-system";

import { ResourceBreadcrumbs } from "../ResourceBreadcrumbs";

import { CELL_WIDTH, CELL_HEIGHT, CELL_MAX_WIDTH } from "./constants";

export const Container = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
`;

export const StyledAdvancedMultiSelect: typeof AdvancedMultiSelect<string> = styled(
  AdvancedMultiSelect
)`
  & .narrowMenuList {
    padding: 0;
  }
  & .narrowMenuListContainer {
    padding: 0;
  }
  & .narrowMenuItem {
    padding: 0.5px 0;
  }
  & .narrowSearchBar {
    padding: 1px 12px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  & .stickyContainer {
    padding: 0;
  }

  & .narrowItemSelectionMenu {
    padding: 6px 20px;
    margin-top: 0px;
    & .MuiButton-root {
      padding: 0;
    }
  }
`;

export const StyledSingleSelect: typeof SingleSelect<string> = styled(
  SingleSelect
)`
  & .narrowMenuList {
    padding: 0;
  }
  & .narrowMenuItem {
    padding: 5px 16px;
  }
  & .narrowSearchBar {
    padding: 1px 12px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const FullWidthCustomServiceSelection = styled(ResourceBreadcrumbs)`
  width: 100%;
`;

export const CellContainer = styled(Box).attrs({
  width: CELL_WIDTH,
  height: CELL_HEIGHT,
  maxHeight: CELL_HEIGHT,
  maxWidth: CELL_MAX_WIDTH,
})``;

const cellsStyleClasses = css`
  .name-cell {
    padding: 10px 4px 10px 10px;
    vertical-align: middle;
    .remove-service-button {
      visibility: hidden;
      height: fit-content;
    }
    :hover {
      background-color: ${muiColors.gray[50]};
      text-decoration: underline;
      cursor: pointer;
      .remove-service-button {
        visibility: visible;
      }
    }
  }

  .containers-cell {
    vertical-align: top;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${cellsStyleClasses}
`;
