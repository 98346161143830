import { useEffect, useRef } from "react";

import { useInspectionLiveDataInUrl } from "@/components/common/ResourceListTable/hooks/useInspectionLiveDataInUrl";

export const useDisableLiveAtmData = () => {
  const [isAtmLiveData, setStoredFetchAtmLiveData] =
    useInspectionLiveDataInUrl();

  const lastIsAtmLiveData = useRef(isAtmLiveData ?? true);

  useEffect(() => {
    const isAtmLiveData = lastIsAtmLiveData.current;

    setStoredFetchAtmLiveData(false);

    return () => {
      if (isAtmLiveData) {
        setStoredFetchAtmLiveData(true);
      }
    };
  }, [setStoredFetchAtmLiveData]);
};
