import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const NoResults: React.FC = ({
  text = "No results found.",
  onClearFilters,
}: {
  text?: string;
  onClearFilters: () => void;
}) => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      aria-label="No results overlay"
    >
      <Typography variant={"h4"} color={"text.secondary"}>
        {text}
      </Typography>
      {onClearFilters && (
        <Button onClick={onClearFilters}>Clear filters</Button>
      )}
    </Stack>
  );
};
