import yaml from "js-yaml";

import {
  AuthorizationDenied,
  BadRequestError,
} from "../../../shared/hooks/useAgentTask/useAgentTask";
import { ErrorMessage } from "../../Actions/common/ErrorMessage";
import { sessionStorageRemoveKeysByRegex } from "../../common/useSessionStorage";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HelmChart, { getResourceStatusByChartStatus } from "./helmChart";
import { GenericResource } from "./tabs/ResourceTab/ResourcesTab";

import { Dictionary } from "@/shared/types/Dictionary";
import { ResourceStatusEnum } from "@/components/ResourceView/headers/common";

export interface ChartNameAndVersion {
  name: string;
  version: string;
}

export const MAX_ELEMENTS_WITH_SCROLL = 20;
export const SESSION_STORAGE_HELM_CHART_PREFIX = "helm_chart_repos";

export type ManifestsMap = Map<
  string,
  {
    name: string;
    kind: string;
    manifest: string;
  }
>;

export const getChartNameAndVersion = (
  version: string
): ChartNameAndVersion => {
  const strs = version.split("-");

  if (strs.length < 2) {
    return { name: "Undefined", version: "Undefined" };
  } else if (strs.length === 2) {
    return { name: strs[0], version: strs[1] };
  } else {
    // semver2 regex , add optional v prefix
    const position = version.search(
      /v?(0|[1-9]\d*).(0|[1-9]\d*).(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|d*[a-zA-Z-][0-9a-zA-Z-]*)(?:.(?:0|[1-9]\d*|d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+([0-9a-zA-Z-]+(?:.[0-9a-zA-Z-]+)*))?/
    );
    return {
      name: version.substring(0, position - 1),
      version: version.substring(position),
    };
  }
};

export const taskResultToMessage = (
  successMessage: string | undefined,
  failureMessage: string | undefined,
  deniedObject: AuthorizationDenied | BadRequestError | undefined
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): string | JSX.Element => {
  if (deniedObject) {
    return <ErrorMessage reason={deniedObject} />;
  } else if (failureMessage) {
    return failureMessage;
  } else if (successMessage) {
    return successMessage;
  }
  return "";
};

export const removeCommentLines = (input: string): string => {
  return input
    .split("\n")
    .filter((line) => !line.match(/^\s*#/) && line.trim().length > 0)
    .join("\n");
};

export const chartToSessionStorageKey = (chart: HelmChart): string => {
  return `${SESSION_STORAGE_HELM_CHART_PREFIX}.${chart.cluster}.${chart.namespace}.${chart.name}`;
};

export const invalidateChartRevisionsCache = (): void => {
  sessionStorageRemoveKeysByRegex(
    new RegExp(`^${SESSION_STORAGE_HELM_CHART_PREFIX}`)
  );
};

export const manifestToMap = (
  input: unknown[] | undefined,
  shouldDecodeManifest = false
): ManifestsMap => {
  return (
    // eslint-disable-next-line no-constant-binary-expression
    new Map(
      input?.map((m) => {
        const manifestTyped = m as GenericResource;
        return [
          manifestTyped.kind + "/" + manifestTyped.metadata.name,
          {
            name: manifestTyped?.metadata?.name ?? "",
            kind: manifestTyped?.kind ?? "",
            manifest: shouldDecodeManifest
              ? decodeDataManifestValues(manifestTyped)
              : yaml.dump(m, { sortKeys: true }),
          },
        ];
      })
    ) ?? []
  );
};

export const decodeDataManifestValues = (manifest: GenericResource): string => {
  const newManifest = structuredClone(manifest);
  if (newManifest?.kind == "Secret" && newManifest?.data) {
    const data = newManifest?.data;
    Object.keys(data).forEach((key) => {
      const value = data[key];
      data[key] = atob(value as string);
    });
  }
  return yaml.dump(newManifest, { sortKeys: true });
};

export const shouldShowRcaForHelmChart = (
  featureFlags: Dictionary<unknown>,
  chartStatus: string
): boolean => {
  const { helmChartDrawerShowRcaTab, helmChartDrawerReportRcaIfHealthy } =
    featureFlags;

  const isUnhealthy = isHelmChartUnhealthy(chartStatus);

  return (
    !!helmChartDrawerShowRcaTab &&
    (isUnhealthy || !!helmChartDrawerReportRcaIfHealthy)
  );
};

export const isHelmChartUnhealthy = (status: string): boolean =>
  getResourceStatusByChartStatus(status) === ResourceStatusEnum.Failed;
