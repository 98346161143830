import { DisplayMode } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/batchActionsTypes";
import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { selectedBatchActionsRequestSelector } from "@/shared/store/batchActionsStore/batchActionsSelectors";

export const useGetDisplayMode = (): DisplayMode => {
  const batchActionsRequest = useBatchActionsStore(
    selectedBatchActionsRequestSelector
  );

  return batchActionsRequest ? "confirmation" : "results";
};
