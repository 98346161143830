import { CronJob as CronJobType } from "kubernetes-types/batch/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { CronJob as CronJobIcon } from "@komodorio/design-system/icons";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { parseISO } from "date-fns";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import { Header } from "../common/Header/Header";
import { extractLastModifyFromService } from "../../../shared/utils/postgresMigrationUtils";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import EventsTab from "../tabs/EventsTab/EventsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import InfoTab from "../tabs/InfoTab/InfoTab";
import NodesTab from "../tabs/NodesTab/NodesTab";
import { extractJobsStatusandLastRunTime } from "../../../shared/utils/extractJobsStatusandLastRunTime";
import { convertToResourceStatusEnum } from "../../../shared/types/job";
import { StatusTag } from "../headers/common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import { isCurrentlyDeploying } from "./utils";

import Resource, { KomodorServiceType, ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class CronJob implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly icon = CronJobIcon;
  readonly drawerTabs;
  readonly actions = [
    AvailableActions.RunNow,
    AvailableActions.SuspendCronJob,
    AvailableActions.ResumeCronJob,
    AvailableActions.Describe,
    AvailableActions.Edit,
    AvailableActions.Delete,
  ];

  readonly selector;
  readonly k8sUid;
  readonly currentlyDeploying;
  readonly lastModified;
  readonly lastDeployStartTime;
  readonly lastDeployEndDate;
  readonly datadogData;
  readonly sentryData;
  readonly jobState;
  readonly jobLastRunTime;
  readonly schedule;
  readonly isDeleted: boolean | undefined = false;
  readonly inactive: boolean | undefined = false;
  readonly fullObj;
  readonly defaultTab;
  readonly suspend;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    cluster?: string,
    cronJob?: CronJobType,
    agentId?: string,
    komodorService?: KomodorServiceType
  ) {
    this.suspend = cronJob?.spec?.suspend;
    if (komodorService) {
      this.agentId = komodorService.agentId ?? "";
      this.id = komodorService.id ?? "";
      this.cluster = komodorService.k8sClusterName ?? "";
      this.namespace = komodorService.namespace ?? "";
      this.name = komodorService.displayName ?? "";
      this.kind = komodorService.kind ?? "";
      this.labels = (komodorService.k8s_metadata?.labels ?? {}) as {
        [key: string]: string;
      };
      this.annotations = (komodorService.k8s_metadata?.annotations ?? {}) as {
        [key: string]: string;
      };
      this.k8sUid = komodorService?.k8s_metadata?.k8sUid;
      this.selector = komodorService.k8s_metadata?.selector as
        | LabelSelector
        | undefined;
      this.datadogData = komodorService.datadog_data;
      this.sentryData = komodorService.sentry_data;

      const lastDeployEndTime = komodorService.deploy_state?.lastDeployEndTime;
      const lastDeployStartTime =
        komodorService.deploy_state?.lastDeployStartTime;
      const currentlyDeploying = isCurrentlyDeploying(
        lastDeployStartTime,
        lastDeployEndTime
      );
      this.currentlyDeploying = currentlyDeploying;
      this.lastModified = extractLastModifyFromService(komodorService);
      this.lastDeployStartTime = lastDeployStartTime;
      this.lastDeployEndDate = lastDeployEndTime
        ? parseISO(lastDeployEndTime)
        : undefined;

      const { jobLastRunTime, jobState } = extractJobsStatusandLastRunTime(
        komodorService.k8s_metadata?.status ?? ""
      );
      this.jobState = komodorService.k8s_metadata?.jobState ?? jobState;
      this.jobLastRunTime = jobLastRunTime;

      this.schedule = komodorService.k8s_metadata?.schedule;
      this.isDeleted = komodorService.isDeleted;
      this.inactive = komodorService.inactive;
    } else {
      this.agentId = agentId ?? "";
      this.id = cronJob?.metadata?.uid ?? "";
      this.cluster = cluster ?? "";
      this.namespace = cronJob?.metadata?.namespace ?? "";
      this.name = cronJob?.metadata?.name ?? "";
      this.kind = cronJob?.kind ?? "";
      this.labels = cronJob?.metadata?.labels ?? {};
      this.annotations = cronJob?.metadata?.annotations ?? {};
      this.fullObj = cronJob;
    }
    this.defaultTab = 0;
    this.drawerTabs = [
      { label: ResourceTabName.Events },
      {
        label: ResourceTabName.Nodes,
        disabled: this.isDeleted || this.inactive,
      },
      { label: ResourceTabName.Info },
      { label: ResourceTabName.Yaml },
    ];
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <EventsTab resource={this} key={this.id} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <NodesTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={2}>
          <InfoTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={3}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        status={
          this.jobState ? (
            <StatusTag status={convertToResourceStatusEnum(this.jobState)}>
              {this.jobState.toUpperCase()}
            </StatusTag>
          ) : undefined
        }
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
