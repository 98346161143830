import { useMemo } from "react";
import semver from "semver";

import {
  doesAgentVersionSupportMetrics,
  REQUIRED_AGENT_VERSION_METRICS,
} from "@/shared/utils/agent/agent";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  useActiveAgent,
  useAgentPropertiesById,
} from "@/shared/hooks/useAgents";
import { Dictionary } from "@/shared/types/Dictionary";
import { useGetClustersAgentsByNames } from "@/shared/hooks/useGetAllClusters/useGetAllClusters";

export const useIsMetricsDrawerSupported = (
  clusterName: string | undefined
): boolean | null => {
  const agentId = useActiveAgent(clusterName);
  const { agentInfo } = useAgentPropertiesById(agentId ?? "");
  const { metricsAvailabilityDrawerIgnoreAgentVersion } = useOverridableFlags();

  return useMemo(() => {
    if (
      !clusterName ||
      !agentInfo ||
      metricsAvailabilityDrawerIgnoreAgentVersion === null ||
      metricsAvailabilityDrawerIgnoreAgentVersion === undefined
    ) {
      return null;
    }
    return !!(
      doesAgentVersionSupportMetrics(agentInfo) ||
      metricsAvailabilityDrawerIgnoreAgentVersion
    );
  }, [agentInfo, clusterName, metricsAvailabilityDrawerIgnoreAgentVersion]);
};

export const useIsMetricsSupportedForMultipleClusters = (
  clusterNames: string[]
): Dictionary<boolean> => {
  const agentsMetricsData = useGetClustersAgentsByNames(clusterNames);

  return useMemo(() => {
    return agentsMetricsData.reduce((acc, agent) => {
      if (!agent) {
        return acc;
      }
      if (!agent?.version) {
        acc[agent.clusterName] = false;
        return acc;
      }
      if (!agent.daemonSetEnabled) {
        acc[agent.clusterName] = false;
        return acc;
      }
      if (agent?.version) {
        acc[agent.clusterName] = semver.gte(
          agent.version,
          REQUIRED_AGENT_VERSION_METRICS
        );
      }
      return acc;
    }, {} as Dictionary<boolean>);
  }, [agentsMetricsData]);
};
