import { GridColDef } from "@mui/x-data-grid-pro";
import { TruncatedCellWithTooltip } from "@komodorio/design-system/komodor-ui";

import { Action, ActionResult } from "@/generated/actionsApi";

const baseColumnDefinition = {
  sortable: false,
};

const columnsConfig: Record<string, GridColDef> = {
  clusterName: {
    ...baseColumnDefinition,
    field: "clusterName",
    headerName: "Cluster",
    flex: 1,
    valueGetter: ({ row }) => row.resource.clusterName,
  },
  namespace: {
    ...baseColumnDefinition,
    field: "namespace",
    headerName: "Namespace",
    flex: 1,
    valueGetter: ({ row }) => row.resource.namespace,
  },
  name: {
    ...baseColumnDefinition,
    field: "name",
    flex: 3,
    valueGetter: ({ row }) => row.resource.name,
  },
};

export const getRequestColumns = (
  resourceName: string
): GridColDef<Action>[] => {
  return [
    columnsConfig.clusterName,
    columnsConfig.namespace,
    {
      ...columnsConfig.name,
      headerName: resourceName,
    },
  ];
};

export const getResponseColumns = (
  resourceName: string
): GridColDef<ActionResult>[] => {
  return [
    columnsConfig.clusterName,
    columnsConfig.namespace,
    {
      ...columnsConfig.name,
      flex: 2,
      headerName: resourceName,
    },
    {
      ...baseColumnDefinition,
      field: "error",
      headerName: "Error message",
      flex: 2,
      valueGetter: ({ row }) => row.failureMessage,
      renderCell: ({ value }) => {
        return (
          <TruncatedCellWithTooltip
            value={value}
            typographyProps={{
              color: "error",
            }}
          />
        );
      },
    },
  ];
};
