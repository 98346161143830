import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import CircleCheckIcon from "@mui/icons-material/CheckCircle";
import { muiColors } from "@komodorio/design-system";

type ComparisonTableTitleDriftIndicationProps = {
  isDiff: boolean;
};

export const ComparisonTableTitleDriftIndication: React.FC<
  ComparisonTableTitleDriftIndicationProps
> = ({ isDiff }) => {
  return isDiff ? (
    <CompareArrowsIcon
      sx={{
        color: "error.dark",
      }}
    />
  ) : (
    <Box display="flex" gap="4px" alignItems="center">
      <Divider
        orientation="vertical"
        sx={{
          height: "22px",
          width: "1px",
          marginLeft: "4px",
          marginRight: "20px",
        }}
      />
      <CircleCheckIcon
        sx={{
          fontSize: "20px",
          color: muiColors.green[500],
        }}
      />
      <Typography variant="body2">No drift detected</Typography>
    </Box>
  );
};
