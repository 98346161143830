import {
  ChatMessagesList,
  ChatMessageType,
} from "@komodorio/design-system/komodor-ui";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Loop from "@mui/icons-material/Loop";
import { useMemo } from "react";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { AriaLabels } from "@/shared/config/ariaLabels";

interface ChatBodyProps {
  messages: ChatMessageType[];
  chatSessionId: string | undefined;
  waitingForResponse: boolean;
  isGroupingMessages: boolean;
  isResponseFailed: boolean | undefined;
  shouldAutoScroll: boolean;
  resetChat: () => void;
}

export const ChatBody: React.FC<ChatBodyProps> = ({
  messages,
  waitingForResponse,
  isGroupingMessages,
  isResponseFailed,
  chatSessionId,
  shouldAutoScroll,
  resetChat,
}) => {
  const { isKomodorAdmin } = useUserMetadata();

  const failedMessageContent = useMemo(
    () => <FailedMessageContent resetChat={resetChat} />,
    [resetChat]
  );

  if (messages.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <Typography
        variant="h5"
        display="flex"
        gap="8px"
        padding="8px 16px"
        alignItems="center"
      >
        <ChatOutlined color="action" /> Klaudia Chat
        {isKomodorAdmin && chatSessionId && (
          <Link
            variant="body3"
            component="a"
            href={`/ai-investigator/?sessionId=${chatSessionId}`}
            target="_blank"
          >
            View session (internal)
          </Link>
        )}
      </Typography>
      <Divider />
      <ChatMessagesList
        messages={messages}
        isLoading={waitingForResponse && !isGroupingMessages}
        isFailed={isResponseFailed}
        failedMessageContent={failedMessageContent}
        autoScroll={shouldAutoScroll}
      />
    </>
  );
};

const FailedMessageContent = ({ resetChat }: { resetChat: () => void }) => {
  return (
    <Box
      display="inherit"
      alignItems="inherit"
      columnGap="inherit"
      paddingY="7px"
    >
      Something went wrong
      <Button
        variant="outlined"
        size="small"
        startIcon={<Loop />}
        onClick={resetChat}
        aria-label={AriaLabels.KlaudiaChat.ChatErrorResetButton}
      >
        Start Over
      </Button>
    </Box>
  );
};
