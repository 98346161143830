export type PaletteName =
  | "gray"
  | "indigo"
  | "deepPurple"
  | "orange"
  | "pink"
  | "green"
  | "red"
  | "yellow"
  | "teal"
  | "blue"
  | "lightBlue"
  | "common";

type RequiredColorsNames =
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";

type OptionalColorNames =
  | "25"
  | "750"
  | "850"
  | "A100"
  | "A200"
  | "A400"
  | "A700";

export type ColorName =
  | RequiredColorsNames
  | OptionalColorNames
  | "white"
  | "black";

type Palette = Record<RequiredColorsNames, string> &
  Partial<Record<OptionalColorNames, string>>;

export const gray: Palette = {
  25: "#FBFCFF",
  50: "#F3F5FB",
  100: "#EBEDF4",
  200: "#D8DBE3",
  300: "#C3C7D5",
  400: "#AAB1C5",
  500: "#737D9E",
  600: "#616D92",
  700: "#444B64",
  800: "#31374F",
  850: "#1E2E4E",
  900: "#061431",
  A100: "#d2ddff",
  A200: "#b9c5ed",
  A400: "#576db4",
  A700: "#262f57",
};
export const indigo: Palette = {
  25: "#F3F6FF",
  50: "#EBEFFF",
  100: "#C2CFFF",
  200: "#99AFFF",
  300: "#708FFF",
  400: "#476FFF",
  500: "#1347FF",
  600: "#0031E0",
  700: "#0023A3",
  800: "#001B7A",
  900: "#061431",
};

export const deepPurple: Palette = {
  50: "#E6D9FA",
  100: "#D2B9FA",
  200: "#B893F5",
  300: "#9D69F0",
  400: "#8545ED",
  500: "#681AE8",
  600: "#4E00CC",
  700: "#4000A6",
  800: "#320082",
  900: "#311b92",
  A100: "#b388ff",
  A200: "#7c4dff",
  A400: "#651fff",
  A700: "#6200ea",
};

export const orange: Palette = {
  25: "#FFFBF5",
  50: "#FFF7EB",
  100: "#FFE7C2",
  200: "#FFCE85",
  300: "#FFBE5C",
  400: "#FFAD33",
  500: "#FD9800",
  600: "#E08700",
  700: "#A36200",
  800: "#663D00",
  900: "#3D2500",
  A100: "#ffd180",
  A200: "#ffab40",
  A400: "#ff9100",
  A700: "#ff6d00",
};

export const pink: Palette = {
  25: "#FFF5FA",
  50: "#FFEBF4",
  100: "#FFD2E7",
  200: "#FEA8D0",
  300: "#FD6AAF",
  400: "#FC4A9D",
  500: "#FC1683",
  600: "#F10472",
  700: "#B50356",
  800: "#8D0243",
  900: "#500126",
  A100: "#ff80ab",
  A200: "#ff4081",
  A400: "#f50057",
  A700: "#c51162",
};

export const green: Palette = {
  25: "#EFFFF9",
  50: "#D9FFF1",
  100: "#BAFFE4",
  200: "#90F4CE",
  300: "#6AF0BD",
  400: "#45EDAD",
  500: "#1BE99A",
  600: "#14CC86",
  700: "#1FA470",
  800: "#0D8255",
  900: "#095D3D",
  A100: "#a4ffdc",
  A200: "#74ffca",
  A400: "#0be994",
  A700: "#08c87e",
};

export const red: Palette = {
  25: "#FFEEF1",
  50: "#FEE0E5",
  100: "#FEBDC9",
  200: "#FE9AAC",
  300: "#FD6D87",
  400: "#FD506F",
  500: "#FC3357",
  600: "#FC163F",
  700: "#D80329",
  800: "#A2021F",
  900: "#6C0114",
  A100: "#ff8a80",
  A200: "#ff5252",
  A400: "#ff1744",
  A700: "#d50000",
};

export const yellow: Palette = {
  25: "#FCFBF2",
  50: "#FFFEE8",
  100: "#FFF3BD",
  200: "#FCE492",
  300: "#FADE4B",
  400: "#F6D427",
  500: "#F3CB00",
  600: "#E6BF00",
  700: "#CCAA00",
  800: "#997F00",
  900: "#6F5C01",
  A100: "#ffff8d",
  A200: "#ffff00",
  A400: "#ffea00",
  A700: "#ffd600",
};

export const teal: Palette = {
  25: "#F1FFFE",
  50: "#DCFAF8",
  100: "#C1F7F2",
  200: "#A2F6EE",
  300: "#7DF2E6",
  400: "#54EEDF",
  500: "#37E8D5",
  600: "#18CCBA",
  700: "#12BEAC",
  750: "#00A090",
  800: "#0D877B",
  900: "#0A5C54",
  A100: "#a7ffeb",
  A200: "#64ffda",
  A400: "#1de9b6",
  A700: "#00bfa5",
};

export const blue: Palette = {
  25: "#F4FBFF",
  50: "#E9F7FF",
  100: "#D6EFFE",
  200: "#B6E1FF",
  300: "#82C8FF",
  400: "#5AB0FF",
  500: "#007AFF",
  600: "#0062DF",
  700: "#0048AC",
  800: "#003381",
  900: "#00265E",
  A100: "#82b1ff",
  A200: "#448aff",
  A400: "#2979ff",
  A700: "#2962ff",
};

export const lightBlue: Palette = {
  50: "#f6fdff",
  100: "#e5f8ff",
  200: "#cef2ff",
  300: "#b4ebff",
  400: "#94e2ff",
  500: "#7ddcff",
  600: "#55d2ff",
  700: "#39caff",
  800: "#26c5ff",
  900: "#00bcff",
  A100: "#c5d5db",
  A200: "#aeccd7",
  A400: "#7fc2db",
  A700: "#2da7d3",
};

export const common = {
  black: "#000000",
  white: "#ffffff",
};
