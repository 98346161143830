import React, { useCallback, useMemo, useState } from "react";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import { HttpStatusCode, isAxiosError } from "axios";

import { TrackedModalForm } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/TrackedModalForm";
import { TrackedKeyCreateRequest } from "@/generated/accounts";
import { validateCreateKeySchema } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/validateCreateKeySchema";
import {
  Errors,
  Refetch,
} from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";
import { useCreateTrackedKey } from "@/shared/hooks/accounts-service/client/trackedKeys/useCreateTrackedKey";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";

function isValidForm(
  newErrors: Errors | undefined,
  newKey: Partial<TrackedKeyCreateRequest>
): newKey is TrackedKeyCreateRequest {
  return Object.keys(newErrors ?? {}).length === 0;
}

export const SELECTOR_ALREADY_EXISTS_ERROR = "Selector already exists";

export const CreateTrackedKeyModal = ({
  onClose,
  onRefetch,
}: {
  onClose: () => void;
  onRefetch: Refetch | null;
}) => {
  const [newKey, setNewKey] = useState<Partial<TrackedKeyCreateRequest>>({});
  const [errors, setErrors] = useState<Errors | undefined>(undefined);

  const { mutateAsync: createTrackedKey, isLoading } = useCreateTrackedKey();

  const handleValueChange = useCallback(
    (
      key: keyof TrackedKeyCreateRequest,
      value: TrackedKeyCreateRequest[keyof TrackedKeyCreateRequest]
    ) => {
      setNewKey((prev) => ({ ...prev, [key]: value }));
    },
    [setNewKey]
  );

  const content = useMemo(() => {
    return (
      <TrackedModalForm
        value={newKey}
        onValueChange={handleValueChange}
        errors={errors}
      />
    );
  }, [handleValueChange, errors, newKey]);

  const handleSubmit = useCallback(async () => {
    const newErrors = await validateCreateKeySchema(newKey);

    setErrors(newErrors);

    if (!isValidForm(newErrors, newKey)) {
      return;
    }

    try {
      await createTrackedKey(newKey);
      onClose();
      await onRefetch?.();
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === HttpStatusCode.Conflict) {
        return setErrors({
          general: SELECTOR_ALREADY_EXISTS_ERROR,
        } as Errors);
      }
      return setErrors({ general: "Something went wrong" } as Errors);
    }
  }, [newKey, createTrackedKey, setErrors, onClose, onRefetch]);

  const handleClose = useCallback(
    async (confirm: boolean) => {
      if (!confirm) {
        return onClose();
      }

      try {
        await handleSubmit();
      } catch (e) {
        notifyDDError(e as Error, {
          name: "CreateTrackedKeyModal",
          message: `Error creating tracking key: ${e}`,
        });
      }
    },
    [onClose, handleSubmit]
  );

  return (
    <ConfirmationDialog
      title="New selector"
      content={content}
      onClose={handleClose}
      okText={isLoading ? "Creating" : "Create"}
      okDisabled={isLoading}
      height="max-content"
      width="unset"
    />
  );
};
