import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { Notification } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { getAppConfig } from "@/shared/config/appConfig";

export type AppNotificationsStoreState = {
  notifications: Notification[];
};

export type AppNotificationsStoreActions = {
  addNotification: (notification: Notification) => string;
  updateNotification: (
    notification: Partial<Notification> & { id: string }
  ) => Notification | undefined;
  removeNotification: (notificationId: string) => Notification | undefined;
};

export type AppNotificationsStore = AppNotificationsStoreState &
  AppNotificationsStoreActions;

const initialState: AppNotificationsStoreState = {
  notifications: [],
};

export const useAppNotificationsStore = create(
  immer<AppNotificationsStore>((set) => ({
    ...initialState,
    addNotification: (notification: Notification) => {
      set((state) => {
        if (
          state.notifications.findIndex((n) => n.id === notification.id) > -1
        ) {
          if (getAppConfig().env !== "production") {
            throw new Error(
              `Notification with id ${notification.id} already exists`
            );
          }
          return;
        }
        state.notifications.push(notification);
      });
      return notification.id;
    },
    updateNotification: (
      notification: Partial<Notification> & { id: string }
    ) => {
      let notificationToUpdate;
      set((state) => {
        const notificationIndex = state.notifications.findIndex(
          (n) => n.id === notification.id
        );
        if (notificationIndex > -1) {
          state.notifications[notificationIndex] = {
            ...state.notifications[notificationIndex],
            ...notification,
          };
          notificationToUpdate = state.notifications[notificationIndex];
        }
      });
      return notificationToUpdate;
    },
    removeNotification: (notificationId: string) => {
      let notificationToRemove;
      set((state) => {
        notificationToRemove = state.notifications.find(
          (n) => n.id === notificationId
        );
        state.notifications = state.notifications.filter(
          (n) => n.id !== notificationId
        );
      });
      return notificationToRemove;
    },
  }))
);

export const resetAppNotificationsStore = () => {
  useAppNotificationsStore.setState(initialState);
};
