import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

import ResponsiveLayout from "../../common/ResponsiveLayout";
import { OverviewContainer } from "../ClusterOverview/styles";
import { useGetWorkspaceById } from "../../../shared/hooks/workspaces-api/workspaces/useGetWorkspaceById";
import { PageLoader } from "../../../shared/components/PageLoader";
import { CostSection } from "../ClusterOverview/components/CostSection/CostSection";
import { AddonsSection } from "../ClusterOverview/components/AddonsSection/AddonsSection";
import { HealthSection } from "../ClusterOverview/components/HealthSection/HealthSection";

import { ClustersGroupOverviewHeader } from "./components/ClustersGroupOverviewHeader";
import { partialClustersMessage } from "./constants";

import { ClustersGroupInventorySection } from "@/components/ClustersView/ClustersGroupOverview/components/ClustersGroupInventorySection/ClustersGroupInventorySection";
import {
  ClustersGroupWorkspace,
  WorkspaceKind,
} from "@/generated/workspacesApi";
import { InventorySectionByClusterName } from "@/components/ClustersView/ClustersGroupOverview/components/ClustersGroupInventorySection/InventorySectionByClusterName";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

interface ClustersGroupOverviewProps {
  clustersGroupId: string;
}
const ClustersGroupOverviewComponent: React.FC<ClustersGroupOverviewProps> = ({
  clustersGroupId,
}) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const [selectedClusters, setSelectedClusters] =
    useStringifiedStateInSearchParams<string[]>("selectedClusters");

  const clusters = selectedClusters ?? [];

  const { data: workspace, isLoading } = useGetWorkspaceById({
    id: clustersGroupId,
  });

  useEffect(() => {
    reportLoadingState("isFetchingWorkspace", isLoading);
  }, [reportLoadingState, isLoading]);

  if (!workspace) {
    return <PageLoader />;
  }

  const { totalClusters, authorizedClusters } =
    workspace.value as ClustersGroupWorkspace;

  return (
    <>
      <ClustersGroupOverviewHeader
        workspace={workspace}
        selectedClusters={selectedClusters}
        setSelectedClusters={setSelectedClusters}
      />
      <ResponsiveLayout>
        <OverviewContainer>
          {totalClusters !== authorizedClusters && (
            <Alert variant="outlined" severity="info" color="running">
              {partialClustersMessage(totalClusters, authorizedClusters)}
            </Alert>
          )}
          <HealthSection clusters={clusters} />
          <AddonsSection clusters={clusters} />
          <CostSection clusters={clusters} />
          {clusters.length === 1 ? (
            <InventorySectionByClusterName clusterName={clusters[0]} />
          ) : (
            <ClustersGroupInventorySection clusters={clusters} />
          )}
        </OverviewContainer>
      </ResponsiveLayout>
    </>
  );
};

export const ClustersGroupOverview: React.FC<ClustersGroupOverviewProps> = (
  props
) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.workspaceOverview,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
          workspaceKind: WorkspaceKind.ClustersGroup,
        },
      }}
    >
      <ClustersGroupOverviewComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
