import React from "react";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";

import { useAppViewsStore } from "../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  appViewIdSelector,
  fetchAppViewServicesStateSelector,
} from "../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { getEditAppViewRoute } from "../../utils/getRoutes";
import {
  CLUSTERS_ROUTE,
  CREATE_WORKSPACE_ROUTE,
} from "../../../../../routes/routes";

import { FetchErrorMessage } from "./FetchErrorMessage";
import { NoServicesFoundMessage } from "./NoServicesFoundMessage";

import { WorkspaceErrorNotAvailable } from "@/components/workspaces/WorkspaceErrorNotAvailable";
import { useGetWorkspaceById } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaceById";
import { WorkspaceKind } from "@/generated/workspacesApi";

type ApplicationsScopingErrorPageProps = {
  children: React.ReactNode;
};

export const ApplicationsScopingErrorPage: React.FC<
  ApplicationsScopingErrorPageProps
> = ({ children }) => {
  const location = useLocation();

  const { noServicesFound, hasFetchError } = useAppViewsStore(
    fetchAppViewServicesStateSelector
  );

  const appViewId = useAppViewsStore(appViewIdSelector);
  const {
    data: extraWorkspaceData,
    isLoading,
    isError,
    error,
  } = useGetWorkspaceById(
    { id: appViewId ?? "" },
    {
      enabled: appViewId !== undefined,
      refetchInterval: 30000 /* 30 seconds */,
    }
  );

  if (
    appViewId != undefined &&
    extraWorkspaceData != undefined &&
    !isLoading &&
    isError
  ) {
    const status = (error as AxiosError).response?.status;
    if (status === 404) {
      return <WorkspaceErrorNotAvailable />;
    }
  }
  const isCreationPageRoute = location.pathname.includes(
    CREATE_WORKSPACE_ROUTE
  );
  const isEditPageRoute = location.pathname.includes(
    getEditAppViewRoute(appViewId ?? "")
  );
  const isClustersPageRoute = location.pathname.includes(CLUSTERS_ROUTE);

  const shouldShowContent =
    !isCreationPageRoute && !isEditPageRoute && !isClustersPageRoute;

  if (shouldShowContent) {
    // Error should only be considered if the workspace is not a scoped workspace
    if (hasFetchError && extraWorkspaceData?.kind !== WorkspaceKind.Scoped)
      return <FetchErrorMessage />;
    if (noServicesFound) return <NoServicesFoundMessage />;
  }

  return <>{children}</>;
};
