/* eslint-disable max-lines */

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import { default as MuiTypography } from "@mui/material/Typography";
import { PodsAndLogs16 } from "@komodorio/design-system/icons";
import { get } from "lodash";
import differenceInSeconds from "date-fns/differenceInSeconds";
import {
  CloseButton,
  CopyButton,
  DrawerHeader,
  Metadata,
  MetadataView,
  Status,
} from "@komodorio/design-system/komodor-ui";

import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { Divider, EventDetailsContainer } from "../common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowIssueEventGroup from "../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import {
  LinkButton,
  NoUnderlinedLink,
} from "../../../../monitorsView/common/styles";
import { typeTriggers } from "../../../../monitorsView/workflowRunView/IssueDetailsDrawer";
import { grayAppBackground } from "../../../../../Colors";
import useWorkflowsChecks from "../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import {
  CheckDetails,
  WorkflowConfigType,
  WorkflowRunWithResults,
} from "../../../../monitorsView/common/types";
import { FlexRows } from "../../../FlexContainers";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { useHasPermissions } from "../../../../../shared/hooks/useUserMetadata/rbac";
import NodeSummary from "../../../../monitorsView/workflowRunView/checks/node/detailsComponents/NodeSummary";
import { ResourceTabName } from "../../../../ResourceView/resources";
import { useKomodorServiceAsResourceWithInterval } from "../../../../ResourceView/useKomodorServiceAsResource";
import { getResourceViewPodsTabUrlKey } from "../../../../ResourceView/resourceViewNavigate";
import { MONITORS_RULE_PARAM_KEY } from "../../../../../shared/config/urlSearchParamsKeys";
import { useWorkflowRunById } from "../../../EventGroup/workflowIssues/useWorkflowRunById";
import WorkflowCheck from "../../../../monitorsView/workflowRunView/checks";
import { useIssueLiveLoading } from "../../../../InvestigationMode/IssueSummary/ReplicasTile";
import { AvailabilityResource } from "../../../../../shared/store/investigationModeStore/investigationModeStore";
import { RELIABILITY_SETTINGS_ROUTE } from "../../../../routes/routes";

import EventDetailsCheckCard from "./EventDetailsCheckCard";
import { ShouldOpenPodsHealthProvider } from "./useShouldOpenPodsHealth";
import { AvailabilityTriage } from "./triage/AvailabilityTriage/AvailabilityTriage";
import { IssueSkeleton } from "./IssueDrawerSkeleton";
import { PodIssueDrawer } from "./triage/pod/PodDrawerData";
import { ResourceLink } from "./ResourceLink";
import { getViewOptionsName } from "./utils";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const WherePrefix = styled.div`
  margin-right: 18px;
`;

export const SimpleSection = styled.div`
  margin: 1rem;
  font-size: 14px;
`;

export const ChecksTitle = styled(Typography).attrs({
  variant: "title",
  size: "medium",
})`
  background-color: ${grayAppBackground};
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e7e9f0;
`;

export const SimpleButtonsContainer = styled(FlexRows)`
  gap: 1.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface WorkflowIssueEventDetailsProps {
  eventGroup: WorkflowIssueEventGroup;
  onClose?: () => void;
  violationId?: string;
}

const getStatusTag = (type: WorkflowConfigType | null) => {
  if (type === WorkflowConfigType.PVC) {
    return <Status label="Pending" size="large" color="warning" />;
  }

  return <Status label="Unhealthy" size="large" color="error" />;
};

const WorkflowIssueEventDetails: React.FC<WorkflowIssueEventDetailsProps> = ({
  eventGroup,
  onClose,
  violationId,
}) => {
  const navigate = useNavigate();
  const [memoizedEventGroup, setMemoizedEventGroup] = useState(eventGroup);
  useEffect(() => {
    if (JSON.stringify(memoizedEventGroup) === JSON.stringify(eventGroup))
      return;
    setMemoizedEventGroup(eventGroup);
  }, [eventGroup, memoizedEventGroup]);
  const { canManageMonitors } = useHasPermissions();

  // PVC and Node Issues are "cross service events", thus, do not have a komodor service
  // We use a fake service id named "common.common.system-wide" and a komodor service does not exist for this service id
  // we set the serviceIdToFetchKomodorService to "undefined" to prevent fetching the komodor service
  const isCrossServiceEvent = [
    WorkflowConfigType.PVC,
    WorkflowConfigType.NodeIssue,
  ].includes(memoizedEventGroup.workflowType);

  const shouldNotFetchService =
    isCrossServiceEvent ||
    memoizedEventGroup.workflowType === WorkflowConfigType.Workflow;

  const serviceIdToFetchKomodorService = shouldNotFetchService
    ? undefined
    : memoizedEventGroup.serviceId;
  const [resource, isFetching] = useKomodorServiceAsResourceWithInterval(
    serviceIdToFetchKomodorService
  );

  const { data: wfRun } = useWorkflowRunById(
    memoizedEventGroup.id,
    memoizedEventGroup.workflowType
  );

  const isLoading = useIssueLiveLoading(isFetching, wfRun?.closedAt);

  const checkDetails: CheckDetails = useMemo(
    () => ({
      workflowType: memoizedEventGroup.workflowType,
      results: wfRun?.results ?? [],
      eventTime: wfRun?.eventTime,
      closedAt: wfRun?.closedAt,
      services: wfRun?.services,
      clusterName: wfRun?.cluster,
      namespace: wfRun?.namespace,
    }),
    [
      memoizedEventGroup.workflowType,
      wfRun?.closedAt,
      wfRun?.cluster,
      wfRun?.eventTime,
      wfRun?.namespace,
      wfRun?.results,
      wfRun?.services,
    ]
  );
  const allChecks = useWorkflowsChecks(checkDetails);
  const isDeletedResource =
    get(resource, "isDeleted") || get(resource, "inactive") || false;
  const queryParams = queryString.parse(useLocation().search);
  useEffect(() => {
    dispatchEvent(AnalyticEvents.MonitorsView.Event_Drawer_Opened, {
      type: memoizedEventGroup.workflowType,
      referer: queryParams?.referer,
      reasons: memoizedEventGroup.reasons,
    });

    dispatchEvent(AnalyticEvents.MonitorsView.Any_Result_Opened, {
      type: memoizedEventGroup.workflowType,
      referer: queryParams?.referer,
      eventTime: memoizedEventGroup.startTime,
      status: memoizedEventGroup.status,
      secondsTillOpen: differenceInSeconds(
        new Date(),
        memoizedEventGroup.startTime
      ),
    });
  }, [
    memoizedEventGroup.startTime,
    memoizedEventGroup.reasons,
    memoizedEventGroup.workflowType,
    queryParams?.referer,
    memoizedEventGroup.status,
  ]);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("issueData", wfRun === null || isLoading);
  }, [wfRun, reportLoadingState, isLoading]);

  if (wfRun === null) return <IssueSkeleton />;

  if (wfRun?.type === WorkflowConfigType.Availability)
    return (
      <AvailabilityTriage
        allChecks={allChecks}
        wfRun={wfRun}
        eventGroup={memoizedEventGroup}
        resource={resource as AvailabilityResource}
        onClose={onClose}
        isFetchingResource={isLoading}
        violationId={violationId}
      />
    );

  if (wfRun?.type === WorkflowConfigType.Workflow)
    return (
      <PodIssueDrawer allChecks={allChecks} wfRun={wfRun} onClose={onClose} />
    );

  const resourceKindTitle =
    memoizedEventGroup?.workflowType || resource?.kind || "";

  return (
    <EventDetailsContainer data-testid={"event-details-container"}>
      <DrawerHeader
        leftActions={
          onClose && (
            <CloseButton
              onClose={onClose}
              ariaLabel={AriaLabels.EventDrawer.CloseButton}
            />
          )
        }
        title={
          <TitleContainer>
            <MuiTypography
              fontSize="1.25rem"
              fontWeight={500}
              sx={{ textTransform: "capitalize" }}
            >
              {`${resourceKindTitle} Issue`}
            </MuiTypography>
            {getStatusTag(wfRun?.type)}
          </TitleContainer>
        }
        rightActions={
          <CopyButton ariaLabel={AriaLabels.EventDrawer.CopyButton} />
        }
      />

      <MetadataView>
        <WherePrefix />
        <Metadata title={resourceKindTitle} value={wfRun.shortResourceName} />
        <Metadata
          title="Cluster"
          value={memoizedEventGroup?.events[0]?.clusterName || wfRun?.cluster}
        />
        {wfRun?.type !== WorkflowConfigType.NodeIssue && (
          <Metadata
            title="Namespace"
            value={memoizedEventGroup?.events[0]?.namespace || wfRun?.namespace}
          />
        )}
      </MetadataView>

      <Divider />

      <MuiTypography variant={"body1"} fontSize={"14px"} margin={"1rem"}>
        {canManageMonitors ? (
          <LinkButton
            fontSize="14px"
            onClick={() =>
              navigate(
                `${RELIABILITY_SETTINGS_ROUTE}?${MONITORS_RULE_PARAM_KEY}=${memoizedEventGroup.workflowConfig?.id}`
              )
            }
          >
            {"Realtime Monitor"}
          </LinkButton>
        ) : (
          "Realtime Monitor"
        )}{" "}
        has detected that{" "}
        <ResourceLink
          namespace={memoizedEventGroup.namespace ?? ""}
          clusterName={memoizedEventGroup.clusterName ?? ""}
          resourceName={memoizedEventGroup.resourceName}
          type={memoizedEventGroup.workflowType}
        />{" "}
        {typeTriggers[memoizedEventGroup.workflowType]}
      </MuiTypography>

      <SimpleButtonsContainer>
        {memoizedEventGroup.serviceId &&
          memoizedEventGroup.workflowType ===
            WorkflowConfigType.Availability && (
            <NoUnderlinedLink
              to={`/main/deep-dive/${
                memoizedEventGroup.serviceId
              }?podId=notselected&${getResourceViewPodsTabUrlKey(
                resource?.kind || ""
              )}=${ResourceTabName.Pods}`}
            >
              <Button
                variant="secondary"
                icon={PodsAndLogs16}
                disabled={isDeletedResource}
              >
                Live Pods & Logs
              </Button>
            </NoUnderlinedLink>
          )}
      </SimpleButtonsContainer>
      {wfRun ? (
        <WorkflowIssueEventDetailsContent
          wfRun={wfRun}
          allChecks={allChecks}
          eventGroup={memoizedEventGroup}
        />
      ) : (
        <IssueSkeleton />
      )}
    </EventDetailsContainer>
  );
};

const WorkflowIssueEventDetailsContent: React.FC<{
  wfRun: WorkflowRunWithResults;
  allChecks: WorkflowCheck[];
  eventGroup: WorkflowIssueEventGroup;
}> = ({ wfRun, allChecks, eventGroup }) => {
  return (
    <>
      <Divider />
      <ChecksTitle>Checks & Findings</ChecksTitle>
      <div>
        {wfRun.type === WorkflowConfigType.NodeIssue &&
          wfRun.closedAt === undefined && (
            <NodeSummary
              clusterName={eventGroup.clusterName ?? ""}
              nodeName={eventGroup.resourceName}
            />
          )}
        {allChecks.map((c, i) => (
          <EventDetailsCheckCard check={c} key={i} />
        ))}
      </div>
    </>
  );
};

const WorkflowIssueEventDetailsProvider: React.FC<
  WorkflowIssueEventDetailsProps
> = (props) => {
  return (
    <ShouldOpenPodsHealthProvider>
      <DatadogReportLoadingTimeContextProvider
        viewOptions={{
          name: getViewOptionsName(props.eventGroup.workflowType),
          context: {
            feTeam: "troubleshooting",
            beTeam: "troubleshooting",
          },
        }}
      >
        <WorkflowIssueEventDetails {...props} />
      </DatadogReportLoadingTimeContextProvider>
    </ShouldOpenPodsHealthProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default WorkflowIssueEventDetailsProvider;
