import { capitalize } from "lodash";

import { ResourceListResponse } from "@/components/Inspection/InspectionViews/types";

interface Column {
  header: string;
  accessor: string;
}

export const EXCLUDED_HEADERS = ["id", "deletedAt", "name"];
export const getColumnNamesWithNameAsFirst = (
  rows: ResourceListResponse["rows"]
): Column[] => {
  const result: Column[] = [];

  if (!rows.length) return result;

  if ("name" in rows[0]) {
    result.push({ header: "Name", accessor: "name" });
  }

  return [
    ...result,
    ...Object.keys(rows[0])
      .filter((key) => !EXCLUDED_HEADERS.includes(key))
      .map((key) => ({
        header: capitalize(key),
        accessor: key,
      })),
  ];
};
