import { Divider } from "@komodorio/design-system/deprecated";
import React, { useMemo } from "react";

import WorkflowCheck from "..";
import { useDeployActions } from "../../../../Actions/common/monitorsActions";
import { Details } from "../../styles";
import { PodsSummary } from "../availability/detailsComponents/AvailabilitySummaryUtils";
import { WorkloadHealthyPodsOutput } from "../availability/types";
import { useKomodorServiceAsResourceWithInterval } from "../../../../ResourceView/useKomodorServiceAsResource";
import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
import { useOpinionatedSummary } from "../availability/detailsComponents/hooks";

import { DeployCheckType } from "@/components/monitorsView/common/types";

const DeploySummary: React.FC<{
  reasons: string[];
  checks: WorkflowCheck[];
  serviceId: string;
  deployStatus: string;
  deployGeneration: number | null | undefined;
  endTime: Date;
}> = ({
  checks,
  deployStatus,
  serviceId,
  reasons,
  deployGeneration,
  endTime,
}) => {
  const podsCheckOutputToShow = useMemo(() => {
    const podsHealthyCheck = checks.find(
      (c) => c.type === DeployCheckType.DeployPodsHealthy
    );
    return podsHealthyCheck?.output
      ? (podsHealthyCheck?.output as WorkloadHealthyPodsOutput)
      : undefined;
  }, [checks]);
  const opinionatedSummary = useOpinionatedSummary(podsCheckOutputToShow);
  const [resource] = useKomodorServiceAsResourceWithInterval(serviceId);

  const { newSuggestedActions } = useOverridableFlags();
  const { shouldShowRollback, shouldShowChangeResources } = useDeployActions(
    deployStatus,
    reasons,
    !!newSuggestedActions,
    deployGeneration,
    resource?.kind
  );
  const shouldShowAction = shouldShowRollback || shouldShowChangeResources;

  return opinionatedSummary?.length > 0 ? (
    <>
      <Details>
        <PodsSummary
          opinionatedSummary={opinionatedSummary}
          failureMessage={podsCheckOutputToShow?.failureMessage}
          resource={resource}
          shouldShowAction={shouldShowAction}
          shouldShowRollback={shouldShowRollback}
          shouldShowChangeResources={shouldShowChangeResources}
          endTime={endTime}
          clusterName={resource?.cluster ?? ""}
          namespace={resource?.namespace ?? ""}
        />
      </Details>
      <Divider />
    </>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeploySummary;
