import React from "react";
import Typography from "@mui/material/Typography";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { parseISO } from "date-fns";

import { CopyText } from "@/components/Settings/styles";
import {
  ActionsCell,
  ActionsCellProps,
} from "@/pages/organization-settings/access-management/PoliciesPage/Grid/ActionsCell";
import { getTagsCell } from "@/pages/organization-settings/access-management/PoliciesPage/Grid/TagsCell";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { customSortByTags } from "@/pages/organization-settings/access-management/PoliciesPage/utils";
import { RbacPolicy } from "@/generated/auth";

const TAGS_CELL_WIDTH = 288;

interface PoliciesColumnsDefinitionsProps {
  showExtraActions: boolean;
  onEdit: OnEdit;
  onDelete: OnDelete;
}

export const getV2ColumnsDefinitions = ({
  onEdit,
  onDelete,
  format,
}: {
  onEdit: OnEdit;
  onDelete: OnDelete;
  format: (d: Date) => string;
}) => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Last modified",
      valueGetter: (params: GridValueGetterParams) => {
        return format(parseISO(params.value ?? ""));
      },
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) =>
        getActionsCell({
          params,
          onEdit,
          onDelete,
        }),
      width: 70,
      resizable: false,
      sortable: false,
      align: "center",
    },
  ] as GridColDef<RbacPolicy>[];
};

export const getColumnsDefinitions = ({
  showExtraActions,
  onEdit,
  onDelete,
}: PoliciesColumnsDefinitionsProps) => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="overline2">
          {params.value.replace("_", " ")}
        </Typography>
      ),
      flex: 1,
      hide: !showExtraActions,
    },
    {
      field: "id",
      headerName: "ID",
      renderCell: (params: GridRenderCellParams) => (
        <CopyText text={params.value} />
      ),
      flex: 1.5,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: TAGS_CELL_WIDTH,
      resizable: false,
      renderCell: (params: GridRenderCellParams) =>
        getTagsCell({
          params,
          width: TAGS_CELL_WIDTH,
        }),
      hide: !showExtraActions,
      sortComparator: customSortByTags,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        getActionsCell({
          params,
          onEdit,
          onDelete,
        }),
    },
  ];
};

const getActionsCell = ({ params, onEdit, onDelete }: ActionsCellProps) => {
  return <ActionsCell params={params} onEdit={onEdit} onDelete={onDelete} />;
};
