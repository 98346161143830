import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { Lock16 } from "../../../../icons";

import { muiTheme } from "index";

const StyledMenuItem = styled(MenuItem)`
  && {
    opacity: 1 !important;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const OVERRIDE_DIVIDER_STYLE = {
  "&.MuiMenuItem-divider": {
    marginBottom: "8px",
  },
};

export const LockedItem = ({
  title,
  divider,
  ariaLabel,
}: {
  title: React.ReactNode;
  divider?: boolean;
  ariaLabel?: string;
}) => {
  return (
    <StyledMenuItem
      disabled
      divider={divider}
      sx={OVERRIDE_DIVIDER_STYLE}
      aria-label={ariaLabel}
    >
      <Typography variant="body2" color={muiTheme.palette.text.secondary}>
        {title}
      </Typography>
      <Lock16 color={muiTheme.palette.action.active} />
    </StyledMenuItem>
  );
};

interface LinkedItemProps {
  LinkComponent: React.ElementType;
  title: React.ReactNode;
  route: string;
  onClick: () => void;
  divider: boolean;
  ariaLabel?: string;
}

export const LinkedItem: React.FC<LinkedItemProps> = ({
  LinkComponent,
  title,
  route,
  divider,
  onClick,
  ariaLabel,
}) => {
  return (
    <LinkComponent
      style={{ textDecoration: "none" }}
      key={route}
      to={route}
      aria-label={ariaLabel || title}
    >
      <MenuItem
        divider={divider}
        disableRipple
        onClick={onClick}
        sx={OVERRIDE_DIVIDER_STYLE}
      >
        <Typography variant="body2">{title}</Typography>
      </MenuItem>
    </LinkComponent>
  );
};
