import Typography from "@mui/material/Typography";
import styled from "styled-components";

type DefaultHeaderProps = {
  title: string;
};

const DefaultHeaderContainer = styled.div`
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.5;
`;

export const DefaultHeader: React.FC<DefaultHeaderProps> = ({ title }) => {
  return (
    <DefaultHeaderContainer>
      <Typography variant="h5">{title}</Typography>
    </DefaultHeaderContainer>
  );
};
