import { muiColors } from "@komodorio/design-system";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Metadata } from "@komodorio/design-system/komodor-ui";

import { GetSummaryDataOutput } from "../../../violationDrawerTypes";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";

import { horizontalPadding } from "./styles";
import { SummaryLink } from "./SummaryLink";

const Container = styled.div<{ bgcolor: string }>`
  display: flex;
  gap: 32px;
  padding: 16px ${horizontalPadding}px;
  background-color: ${({ bgcolor }) => bgcolor};
`;

const StyledTextLink = styled(SummaryLink)`
  && {
    font-size: 14px;
  }
`;

const {
  violationDrawer: { metadataContainer: metadataContainerAriaLabel },
} = reliabilityArialLabels;

export interface SummaryProps {
  data?: GetSummaryDataOutput;
  backgroundColor?: string;
}

export const Summary: React.FC<SummaryProps> = ({
  data,
  backgroundColor = muiColors.gray[50],
}) => {
  const content = useMemo(
    () =>
      data?.metadataItems
        ?.filter((item) => {
          return item.value !== "";
        })
        .map(({ title, value, onValueClick, leftIcon }) => {
          const itemValue: string = typeof value === "string" ? value : "";

          const valueToUse =
            onValueClick != undefined ? (
              <StyledTextLink text={itemValue} onClick={onValueClick} />
            ) : (
              value
            );

          return (
            <Metadata
              key={title}
              title={title}
              value={valueToUse}
              leftIcon={leftIcon}
            />
          );
        }),
    [data?.metadataItems]
  );

  return (
    <Container
      bgcolor={backgroundColor ?? ""}
      aria-label={metadataContainerAriaLabel}
    >
      {content}
    </Container>
  );
};
