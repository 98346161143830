import { RuleGroupType, RuleType } from "react-querybuilder";

import { ResourcesScope, SelectorPattern } from "@/generated/auth/api";
import {
  doesntMatchWildcardOperator,
  isOperator,
  matchesWildcardOperator,
  multiIsOperator,
} from "@/shared/components/QueryBuilder/operators";
import { generateRuleValue } from "@/shared/components/QueryBuilder/useKeyField";

export const handleClusters = (
  resourcesScope: ResourcesScope
): RuleGroupType | false => {
  if (resourcesScope.clusters?.length) {
    return {
      combinator: "and",
      rules: [
        {
          field: "cluster",
          operator: multiIsOperator.name,
          value: resourcesScope.clusters.join(","),
        },
      ] as RuleType[],
    };
  }

  return false;
};

export const handleClustersPatterns = (
  resourcesScope: ResourcesScope
): RuleGroupType | false => {
  if (resourcesScope.clustersPatterns?.length) {
    const rules: RuleType[] = [];

    resourcesScope.clustersPatterns.forEach((clusterPattern) => {
      rules.push({
        field: "cluster",
        operator: matchesWildcardOperator.name,
        value: clusterPattern.include ?? "",
      });

      if (clusterPattern.exclude) {
        rules.push({
          field: "cluster",
          operator: doesntMatchWildcardOperator.name,
          value: clusterPattern.exclude,
        });
      }
    });

    return {
      combinator: "and",
      rules,
    };
  }

  return false;
};

export const handleNamespaces = (
  resourcesScope: ResourcesScope
): RuleGroupType | false => {
  if (resourcesScope.namespaces?.length) {
    return {
      combinator: "and",
      rules: [
        {
          field: "namespace",
          operator: multiIsOperator.name,
          value: resourcesScope.namespaces.join(","),
        },
      ] as RuleType[],
    };
  }

  return false;
};

export const handleNamespacesPatterns = (
  resourcesScope: ResourcesScope
): RuleGroupType | false => {
  if (resourcesScope.namespacesPatterns?.length) {
    const rules: RuleType[] = [];

    resourcesScope.namespacesPatterns.forEach((namespacePattern) => {
      rules.push({
        field: "namespace",
        operator: matchesWildcardOperator.name,
        value: namespacePattern.include ?? "",
      });

      if (namespacePattern.exclude) {
        rules.push({
          field: "namespace",
          operator: doesntMatchWildcardOperator.name,
          value: namespacePattern.exclude,
        });
      }
    });

    return {
      combinator: "and",
      rules,
    };
  }

  return false;
};

export const handleSelectorsPatterns = (
  resourcesScope: ResourcesScope
): RuleGroupType | false => {
  if (resourcesScope.selectorsPatterns?.length) {
    const rules: RuleType[] = [];

    resourcesScope.selectorsPatterns.forEach(
      (selectorPattern: SelectorPattern) => {
        rules.push({
          field: "key",
          operator: isOperator.name,
          value: generateRuleValue({
            name: selectorPattern.key,
            type: selectorPattern.type,
          }),
        });

        if (selectorPattern.value.include) {
          rules.push({
            field: "value",
            operator: matchesWildcardOperator.name,
            value: selectorPattern.value.include,
          });
        }

        if (selectorPattern.value.exclude) {
          rules.push({
            field: "value",
            operator: doesntMatchWildcardOperator.name,
            value: selectorPattern.value.exclude,
          });
        }
      }
    );

    return {
      combinator: "and",
      rules,
    };
  }

  return false;
};
