import React from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { RuleValidator } from "react-querybuilder";

import { defaultValidator } from "@/shared/components/QueryBuilder/defaultValidator";
import { isOperator } from "@/shared/components/QueryBuilder/operators";
import { customKeyOptionElement } from "@/shared/components/QueryBuilder/Fields/CustomKeyOptionElement";
import { MenuListTrailingElement } from "@/shared/components/QueryBuilder/Fields/MenuListTrailingElement";

export const keyQBField = ({
  options,
  validator = defaultValidator,
  isChangeable = false,
}: {
  options: MuiSelectionOption<string>[];
  isChangeable?: boolean;
  validator?: RuleValidator;
}) => {
  return {
    name: "key",
    label: "Key",
    disabled: !isChangeable,
    operators: [isOperator],
    values: options,
    validator,
    customOptionElement: customKeyOptionElement,
    menuListTrailingElement: <MenuListTrailingElement />,
    selectPopoverProps: {
      maxHeight: "unset",
    },
  };
};
