import React, { useCallback } from "react";
import { Legend } from "recharts";

import { WorkloadsGraphTooltipWrapper } from "../WorkloadsGraphTooltip";

import { LegendContent } from "./LegendContent";

import {
  MetricsGraphContainerProps,
  MetricType,
} from "@/components/Metrics/types";
import {
  formatGraphCPUTick,
  formatGraphMemoryTick,
} from "@/components/Metrics/utils";
import { MetricsGraphContainer } from "@/components/Metrics/graphs/MetricsGraphContainer";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";

type PartialMetricsGraphContainerProps = Omit<
  MetricsGraphContainerProps,
  | "tickFormatter"
  | "label"
  | "ariaLabels"
  | "children"
  | "memoryUnit"
  | "tooltipContent"
>;
type ResourceGraphProps = PartialMetricsGraphContainerProps & {
  type: MetricType;
  linesContent?: React.ReactNode[];
  komodorUids: string[];
};
const { WorkloadsCpuGraph, WorkloadsMemoryGraph } =
  AriaLabels.DriftAnalysis.Service.MetricsTab;
export const WorkloadsMetricsGraph: React.FC<ResourceGraphProps> = ({
  type,
  linesContent,
  komodorUids,
  ...props
}) => {
  const isCpu = type === "cpu";

  const tickFormatter = useCallback(
    (tick: string) => {
      return isCpu ? formatGraphCPUTick(tick) : formatGraphMemoryTick(tick);
    },
    [isCpu]
  );
  const { selectedAggregationMetric } = useWorkloadMetricsTabContext();

  const label = isCpu ? "MilliCores" : "MiB";
  const ariaLabels = isCpu ? WorkloadsCpuGraph : WorkloadsMemoryGraph;

  return (
    <MetricsGraphContainer
      {...props}
      tickFormatter={tickFormatter}
      ariaLabels={ariaLabels}
      label={label}
      tooltipContent={
        <WorkloadsGraphTooltipWrapper
          isCpu={isCpu}
          komodorUids={komodorUids}
          aggregationType={selectedAggregationMetric}
        />
      }
    >
      {komodorUids.length > 0 && (
        <Legend
          content={<LegendContent baselineKomodorUid={komodorUids[0]} />}
        />
      )}
      {linesContent}
    </MetricsGraphContainer>
  );
};
