import { useMemo } from "react";

import {
  FetchAllKomodorServiceAttributesByKeysQuery,
  ListServiceAttributesForAccountQuery,
  useFetchAllKomodorServiceAttributesByKeysQuery,
  useListServiceAttributesForAccountQuery,
} from "../../../generated/graphql";
import { useQueryPolling } from "../../../shared/hooks/useQueryPolling";
import { ServiceAttribute } from "../../../shared/types/ServiceInfo";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import {
  AccountServiceAttributes,
  KomodorServiceAttributesParamsFieldsEnum,
} from "../../../generated/resourcesApi";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  convertToKomodorServiceAttributes,
  useGetKomodorServiceAttributes,
} from "@/shared/hooks/resources-api/client/komodor-services/useGetKomodorServiceAttributes";
import {
  FetchAllKomodorServiceAttributesByKeys,
  IHasuraMigrations,
  ListServiceAttributesForAccount,
} from "@/shared/constants/hasuraMigrationFeatureFlag";
import { useGetServiceAttributesForAccountRest } from "@/components/servicesView/servicesAttributes/useServiceAttributesForAccountRest";

const SERVICE_ATTRIBUTES_POLLING_INTERVAL = 15000;

interface ServiceFilters {
  [index: string]: Set<string>;
}

const asStringArray = (v: unknown) => {
  if (!Array.isArray(v)) return [];
  return v.filter((e): e is string => typeof e === "string");
};

export const useServiceAttributesForAccount = (
  serviceAttsConfigs:
    | ListServiceAttributesForAccountQuery["service_attributes_for_account"]
    | AccountServiceAttributes[]
    | undefined
): ServiceFilters | null => {
  return useMemo(() => {
    if (!serviceAttsConfigs) return null;
    const serviceFilters: ServiceFilters = {};
    serviceAttsConfigs.forEach(({ displayName, mappings }) => {
      if (displayName) {
        if (!serviceFilters[displayName]) {
          serviceFilters[displayName] = new Set();
        }
        asStringArray(mappings).forEach((mapping) =>
          serviceFilters[displayName].add(mapping)
        );
      }
    });

    return serviceFilters;
  }, [serviceAttsConfigs]);
};

export type AllServicesAttributes =
  | Record<string, ServiceAttribute[]>
  | undefined;

export const useServiceAttributesConfiguredKeys = (
  pause?: boolean
): string[] => {
  const { hasuraMigrations } = useOverridableFlags();
  const hasuraMigrationFF = hasuraMigrations as IHasuraMigrations;
  const shouldUseRestApi = hasuraMigrationFF?.endpoints.includes(
    ListServiceAttributesForAccount
  );

  const [{ data: attributesKeysForAccountGql }] =
    useListServiceAttributesForAccountQuery({
      pause: pause || shouldUseRestApi,
    });

  const { data: attributesKeysForAccountRest } =
    useGetServiceAttributesForAccountRest(shouldUseRestApi);

  const attributesKeysForAccount = shouldUseRestApi
    ? attributesKeysForAccountRest
    : attributesKeysForAccountGql;

  return useMemo(() => {
    if (
      attributesKeysForAccount?.service_attributes_for_account &&
      Array.isArray(attributesKeysForAccount.service_attributes_for_account)
    ) {
      const attributesKeys =
        attributesKeysForAccount.service_attributes_for_account.flatMap(
          (attr) => {
            return asStringArray(attr.mappings);
          }
        );
      return Array.from(new Set(attributesKeys));
    }
    return [];
  }, [attributesKeysForAccount?.service_attributes_for_account]);
};

export const useServiceAttributesConfiguredVars = (
  servicesIds: string[]
): {
  keys: string[];
  servicesIds: string[];
} => {
  const serviceAttributesKeys = useServiceAttributesConfiguredKeys();
  return useMemo(
    () => ({
      keys: serviceAttributesKeys,
      servicesIds: servicesIds,
    }),
    [serviceAttributesKeys, servicesIds]
  );
};

export type KomodorServiceAttributes =
  FetchAllKomodorServiceAttributesByKeysQuery["komodor_service_attributes"];

export const useAllServiceAttributesPolling = (
  servicesIds: string[]
): KomodorServiceAttributes | undefined => {
  const attributesVars = useServiceAttributesConfiguredVars(servicesIds);
  const { hasuraMigrations } = useOverridableFlags();
  const hasuraMigrationFF = hasuraMigrations as IHasuraMigrations;
  const shouldUseRestApi = hasuraMigrationFF?.endpoints.includes(
    FetchAllKomodorServiceAttributesByKeys
  );
  const resourcesApiAttributesVars = useMemo(
    () => ({
      keys: attributesVars.keys,
      servicesIds: attributesVars.servicesIds,
      fields: [
        KomodorServiceAttributesParamsFieldsEnum.Key,
        KomodorServiceAttributesParamsFieldsEnum.Value,
        KomodorServiceAttributesParamsFieldsEnum.ServiceId,
      ],
    }),
    [attributesVars.keys, attributesVars.servicesIds]
  );

  const shouldEnableResourcesApi =
    !!(attributesVars.keys.length && attributesVars.servicesIds.length) &&
    shouldUseRestApi;

  const { data: resourcesApiData } = useGetKomodorServiceAttributes(
    {
      komodorServiceAttributesParams: resourcesApiAttributesVars,
    },
    {
      enabled: shouldEnableResourcesApi,
      refetchInterval: SERVICE_ATTRIBUTES_POLLING_INTERVAL,
    }
  );

  const shouldPauseQuery =
    !(attributesVars.keys.length && attributesVars.servicesIds.length) ||
    shouldUseRestApi;

  const [dataGql] = useQueryPolling(
    useFetchAllKomodorServiceAttributesByKeysQuery({
      variables: attributesVars,
      pause: shouldPauseQuery,
    }),
    SERVICE_ATTRIBUTES_POLLING_INTERVAL,
    shouldPauseQuery
  );

  return useMemo(() => {
    if (!dataGql && !resourcesApiData) return undefined;
    return shouldUseRestApi
      ? convertToKomodorServiceAttributes(resourcesApiData)
      : dataGql?.komodor_service_attributes;
  }, [dataGql, resourcesApiData, shouldUseRestApi]);
};
