import { Limits } from "@komodorio/design-system/icons";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesLimitRangeResource = {
  NameInK8S: "limitranges",
  Group: "configuration",
  NavBarName: "Limit Ranges",
  PresentName: "Limit Ranges",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: SupportedResourcesKinds.KubernetesLimitRangeResource,
  Icon: Limits,
  hasLabels: true,
};
