import { isEqual } from "lodash";

import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import {
  Pattern,
  RbacPolicyAction,
  RbacPolicyStatementsInner,
  ResourcesScope,
  Selector,
  SelectorPattern,
} from "@/generated/auth";

type ResourceScopeSet = (string | Pattern | Selector | SelectorPattern)[];

const isEmptySet = (set: ResourceScopeSet) => {
  return !set.length;
};

const hasValidSets = ({
  firstSet,
  secondSet,
  allowEmpty,
}: {
  firstSet: ResourceScopeSet;
  secondSet: ResourceScopeSet;
  allowEmpty?: boolean;
}) => {
  if (!allowEmpty) {
    return !!firstSet.length !== !!secondSet.length;
  }

  if (isEmptySet(firstSet) && isEmptySet(secondSet)) {
    return true;
  }

  return !!firstSet.length !== !!secondSet.length;
};

const hasDefaultNamespaces = (resourceScope: ResourcesScope) => {
  return isEqual(resourceScope.namespacesPatterns, [
    { include: "*", exclude: "" },
  ]);
};

const isClusterScope = (resourceScope: ResourcesScope) => {
  return (
    hasValidSets({
      firstSet: resourceScope.clusters,
      secondSet: resourceScope.clustersPatterns,
    }) &&
    hasDefaultNamespaces(resourceScope) &&
    isEmptySet(resourceScope.selectors) &&
    isEmptySet(resourceScope.selectorsPatterns) &&
    isEmptySet(resourceScope.namespaces)
  );
};

const hasNamespaceScope = (resourceScope: ResourcesScope) => {
  if (resourceScope.namespaces.length) {
    return true;
  }

  return (
    !hasDefaultNamespaces(resourceScope) &&
    !isEmptySet(resourceScope.namespacesPatterns)
  );
};

const hasSelectorScope = (resourceScope: ResourcesScope) => {
  return (
    resourceScope.selectors.length || resourceScope.selectorsPatterns.length
  );
};

export const assumeScopeFromStatement = (
  statement: RbacPolicyStatementsInner,
  globalActions: RbacPolicyAction[]
): Scope | "error" => {
  const actionsInUse =
    globalActions.filter((ga: RbacPolicyAction) => {
      return statement.actions?.some((action) => action === ga.action);
    }) ?? [];

  // If there is an action with scopingMethod "komodor-actions", we assume the scope is "komodor-actions"
  if (
    actionsInUse.some((action) =>
      action.scopingMethods.includes("komodor-actions")
    )
  ) {
    return Scope["komodor-actions"];
  }

  const { resourcesScope } = statement;

  // This state cannot be reached, but if it does, we assume the scope is "cluster"
  if (!resourcesScope) {
    return Scope.cluster;
  }

  // Any scope (other than "komodor-actions") must have at least one cluster or cluster pattern
  if (
    !hasValidSets({
      firstSet: resourcesScope.clusters,
      secondSet: resourcesScope.clustersPatterns,
    })
  ) {
    return "error";
  }

  if (
    !hasValidSets({
      firstSet: resourcesScope.namespaces,
      secondSet: resourcesScope.namespacesPatterns,
      allowEmpty: true,
    })
  ) {
    return "error";
  }

  if (
    !hasValidSets({
      firstSet: resourcesScope.selectors,
      secondSet: resourcesScope.selectorsPatterns,
      allowEmpty: true,
    })
  ) {
    return "error";
  }

  const hasNamespace = hasNamespaceScope(resourcesScope);
  const hasSelector = hasSelectorScope(resourcesScope);

  if (hasSelector) {
    return Scope.cwr;
  }

  if (hasNamespace) {
    return Scope.namespace;
  }

  if (isClusterScope(resourcesScope)) {
    return Scope.cluster;
  }

  return "error";
};
