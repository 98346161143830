import { RuleGroupType } from "react-querybuilder";

import { ResourcesScope } from "@/generated/auth";
import { getFieldsRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getFieldsRulesFromQuery";

export const getKeyValueRulesFromQuery = (
  query: RuleGroupType | string | null
): Partial<ResourcesScope> | undefined => {
  return getFieldsRulesFromQuery(query, ["key", "value"]);
};
