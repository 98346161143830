import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import {
  StyledOrderedList,
  TextContainer,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import { AutoscalerKind } from "@/generated/reliabilityApi";
import { DiscList } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/scaleDownImpactParts";

export const titleTemplate = "node scale downs cause a reliability impact";

export const whyIsThisImportantContent = (
  <TextContainer>
    Understanding and addressing scale-down events is vital for maintaining
    cluster stability and performance. Node termination from autoscaling can
    evict pods, disrupting critical services, causing job failures, and
    impacting application reliability, which may lead to downtime, poor user
    experience, and potential revenue loss.
  </TextContainer>
);

export const howToFixDescription = (autoscalerKind: AutoscalerKind) => (
  <TextContainer>
    <StyledOrderedList>
      <li>
        Make sure{" "}
        <Typography display="inline" fontWeight="bold" fontSize={14}>
          GracefulNodeShutdown
        </Typography>{" "}
        feature gate in the autoscaler configuration from is set to{" "}
        <Typography display="inline" color={muiColors.green[800]}>
          true
        </Typography>{" "}
        to reduce workload disruption.
      </li>
      <li>
        If not defined,{" "}
        <Typography display="inline" fontWeight="bold" fontSize={14}>
          Adding PDB to mission critical workloads
        </Typography>{" "}
        can improve the reliability of your application.
      </li>
      <li>
        Consider increasing{" "}
        <Typography display="inline" fontWeight="bold" fontSize={14}>
          nodeDeleteDelay / terminationGracePeriod
        </Typography>{" "}
        to allow smoother scale down.
      </li>
      {autoscalerKind === AutoscalerKind.Karpenter && (
        <li>
          Configure consolidation with caution:
          <DiscList>
            <li>
              Disabling consolidation for certain time windows (nighttime only,
              etc.)
            </li>
            <li>
              Using{" "}
              <Typography variant="code1">
                consolidation.policy: WhenEmpty
              </Typography>{" "}
              instead of <Typography variant="code1">Always</Typography>
            </li>
          </DiscList>
        </li>
      )}
    </StyledOrderedList>
  </TextContainer>
);

export const scaleDownImpactDrawerAriaLabels = {
  impactedWorkloadsSection: {
    title: "Impacted workloads section - title",
    unhealthyServicesTabHeader:
      "Impacted workloads section - Unhealthy services tab header",
    unhealthyServicesTable:
      "Impacted workloads section - Unhealthy services table",
    affectedJobsTabHeader:
      "Impacted workloads section - Affected jobs tab header",
    affectedJobsTable: "Impacted workloads section - Affected jobs table",
    workflowsTabHeader: "Impacted workloads section - Workflows tab header",
    workflowsTable: "Impacted workloads section - Workflows table",
  },
  relatedEventsTable: {
    title: "Related events table - title",
    container: "Related events table - container",
  },
};
