import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import styled from "styled-components";

import { StrictExternalLink } from "@/components/common/Link/ExternalLink";

const TitlePlaceholder = styled.div``;

export const Feedback: React.FC = () => {
  return (
    <>
      <TitlePlaceholder />
      <Stack spacing={1}>
        <Divider />
        <Typography fontSize="14px">
          Was this helpful? Share your{" "}
          <StrictExternalLink
            href="https://forms.gle/CX5SkttsH5rj2RJV8"
            color={muiColors.blue[600]}
            style={{ textDecoration: "none" }}
          >
            feedback
          </StrictExternalLink>
        </Typography>
      </Stack>
    </>
  );
};
