/* eslint-disable max-lines */
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import differenceInSeconds from "date-fns/differenceInSeconds";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { KubernetesServiceTypeEnum } from "../../../../../shared/types/ServiceVertex";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DeployEventGroup from "../../../EventGroup/deployEvent/DeployEventGroup";
import { EventDetailsContainer } from "../common";
import EventDetailsTitle from "../components/EventDetailsTitle";
import SlackSection from "../components/SlackSection";
import TeamsSection from "../components/TeamsSection";
import reasonIcon from "../../assets/reason.svg";
import EventDetailsSection from "../components/EventDetailsSection";
import useInstallationSubscription from "../../../../integrations/management/installed/useInstallationSubscription";
import { useDeployRelatedLinks } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import { getServiceInfo } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/utils";
import {
  BaseWorkflowCheck,
  DeployCheckType,
  WorkflowConfigType,
} from "../../../../monitorsView/common/types";
import EventDetailsCheckCard from "../WorkflowIssueEventDetails/EventDetailsCheckCard";
import WorkloadHealthyPodsCheck from "../../../../monitorsView/workflowRunView/checks/availability/WorkloadHealthyPodsCheck";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import DeploySummary from "../../../../monitorsView/workflowRunView/checks/deployComponents/DeploySummary";
import { useKomodorServiceAsResourceWithInterval } from "../../../../ResourceView/useKomodorServiceAsResource";
import { MonitorType } from "../../../../../generated/monitorsApi";

import EventsSection, { addWbrForStatus } from "./EventsSection";
import K8sSection from "./K8sSection";
import ConfigChangesSection from "./ConfigChangesSection";
import DrawerEventLinksSection from "./DrawerEventLinksSection";
import TrackedFilesSectionFromGitComp, {
  TrackedFilesSection,
} from "./TrackedFilesSection";
import useK8sData from "./useK8sData";
import useFilteredGitCompares from "./useFilteredGitCompares";
import useFilteredTrackedFiles from "./useFilteredTrackedFiles";
import GitSection from "./Git/GitSection";
import {
  getGitProviderData,
  getGitTypeFinder,
} from "./Git/GetGitTypeAndProviderData";
import StrategySection from "./StrategySection";
import { HeaderSummary } from "./HeaderSummary";
import { getAnnotations } from "./utils";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetMonitorRunById } from "@/shared/hooks/monitors-api/client/monitors/useGetMonitorRunById";
import useWorkflowsChecks from "@/components/monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { FlowType } from "@/components/AiInvestigation/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const Value = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
`;

const ContainerNameTitle = styled.span`
  font-weight: normal;
`;

interface DeployEventDetailsProps {
  eventGroup: DeployEventGroup;
}

const DeployEventDetailsComponent: React.FC<DeployEventDetailsProps> = ({
  eventGroup,
}) => {
  const { klaudiaAiFailedDeployDrawer } = useOverridableFlags();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const firstEvent = eventGroup.events[0];
  const lastEvent = eventGroup.events[eventGroup.events.length - 1];

  const { data, isLoading: isLoadingWfRun } = useGetMonitorRunById(
    {
      id: lastEvent.id,
      type: MonitorType.Deploy,
    },
    {
      enabled: lastEvent._event.status === "Failure",
    }
  );
  const wfRun = useMemo(() => data?.data, [data]);
  const allChecks = useWorkflowsChecks({
    workflowType: WorkflowConfigType.Deploy,
    results: Object.values(wfRun?.results ?? {}) as BaseWorkflowCheck[],
    eventTime: wfRun?.eventTime ? new Date(wfRun?.eventTime) : undefined,
    closedAt: wfRun?.closedAt ? new Date(wfRun?.closedAt) : undefined,
    services: wfRun?.services,
    clusterName: wfRun?.cluster,
    namespace: wfRun?.namespace,
  });
  const queryParams = queryString.parse(useLocation().search);

  useEffect(() => {
    if (allChecks.length > 0) {
      dispatchEvent(AnalyticEvents.MonitorsView.Event_Drawer_Opened, {
        type: WorkflowConfigType.Deploy,
        referer: queryParams?.referer,
      });

      dispatchEvent(AnalyticEvents.MonitorsView.Any_Result_Opened, {
        type: WorkflowConfigType.Deploy,
        referer: queryParams?.referer,
        eventTime: firstEvent.eventTime,
        status: firstEvent.status,
        secondsTillOpen: differenceInSeconds(new Date(), firstEvent.eventTime),
      });
    }
  }, [
    firstEvent.status,
    firstEvent.eventTime,
    queryParams?.referer,
    allChecks.length,
  ]);

  const isNotifiedOn =
    (wfRun?.workflowConfiguration?.sinksOptions?.shouldSend ?? true) &&
    wfRun?.workflowConfiguration?.sinksOptions?.notifyOn &&
    (wfRun?.workflowConfiguration.sinksOptions.notifyOn[0] ===
      lastEvent._event.status ||
      wfRun?.workflowConfiguration.sinksOptions.notifyOn[0] === "All");

  const slackConfigured =
    !!wfRun?.workflowConfiguration?.sinks?.slack?.[0] && isNotifiedOn;

  const teamsConfigured =
    !!wfRun?.workflowConfiguration?.sinks?.teams?.[0] && isNotifiedOn;

  const failedPod = useMemo(() => {
    const podsHealthCheck = allChecks.find(
      (c) => c.type === DeployCheckType.DeployPodsHealthy
    ) as WorkloadHealthyPodsCheck;
    const pods = podsHealthCheck?.output?.pods ?? [];
    return pods.filter((p) => !p.isHealthy)?.[0]?.name ?? undefined;
  }, [allChecks]);

  const {
    specsForDiff,
    k8sDiff,
    k8sConfig,
    isOldSpecEmpty,
    isFetching: isFetchingK8sData,
  } = useK8sData(lastEvent.id);

  const [resource, isFetchingResource] =
    useKomodorServiceAsResourceWithInterval(eventGroup.serviceId);
  const deployRelatedLinks = useDeployRelatedLinks(
    getAnnotations(specsForDiff?.newSpec),
    getServiceInfo(resource)
  );
  const gitCompares = useFilteredGitCompares(firstEvent.getGitCompare());
  const trackedFiles = useFilteredTrackedFiles(firstEvent.getTrackedFiles());
  const installations = useInstallationSubscription();
  const annotations = resource?.annotations as
    | Record<string, string>
    | undefined;

  const gitTypeFinder = useMemo(
    () => getGitTypeFinder(gitCompares, installations, annotations),
    [annotations, gitCompares, installations]
  );

  const isFetching =
    isLoadingWfRun ||
    isFetchingResource ||
    isFetchingK8sData ||
    installations.fetching;

  useEffect(() => {
    reportLoadingState("deployEventDetails", isFetching);
  }, [reportLoadingState, isFetching]);

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={`Deploy ${eventGroup.status}`}>
        <HeaderSummary eventGroup={eventGroup} spec={specsForDiff?.newSpec} />
      </EventDetailsTitle>
      {lastEvent._event.status === "Failure" && (
        <>
          {isLoadingWfRun ? (
            <Box sx={{ padding: "2rem" }}>
              <Skeleton animation="wave" height="2rem" />
              <Skeleton animation="wave" height="2rem" />
              <Skeleton animation="wave" height="2rem" />
            </Box>
          ) : allChecks.length > 0 ? (
            <>
              <DeploySummary
                reasons={wfRun?.reasons ?? []}
                checks={allChecks}
                serviceId={eventGroup.serviceId}
                deployStatus={eventGroup.status}
                deployGeneration={eventGroup.generation}
                endTime={eventGroup.endTime}
              />
            </>
          ) : (
            <EventDetailsSection
              icon={reasonIcon}
              iconDimensions={{ height: "50%", width: "50%" }}
              title="Reason"
            >
              <Value>
                {lastEvent._event.statusMessage
                  ? addWbrForStatus(lastEvent._event.statusMessage)
                  : lastEvent._event.statusReason}
                {lastEvent._event.failed_container && (
                  <>
                    <br />
                    <br />
                    <ContainerNameTitle>Failed container: </ContainerNameTitle>
                    {lastEvent._event.failed_container}
                  </>
                )}
              </Value>
            </EventDetailsSection>
          )}
        </>
      )}
      {klaudiaAiFailedDeployDrawer && resource ? (
        <>
          <AiInvestigation
            drawerData={{
              drawerType: DrawerType.EventDrawerById,
              eventIds: eventGroup.events.map((event) => event.id),
              eventType: MonitorType.Deploy,
              serviceId: eventGroup.serviceId,
            }}
            requestId={eventGroup.id}
            resource={resource}
            isHealthy={eventGroup.status !== "failed"}
            flowType={FlowType.FailedDeploy}
            padding="16px"
          />
          <Divider />
        </>
      ) : null}
      {lastEvent._event.status === "Failure" && !isLoadingWfRun
        ? allChecks.map((c) => (
            <EventDetailsCheckCard key={c.title} check={c} />
          ))
        : null}
      <EventsSection events={eventGroup.events} />
      <K8sSection
        firstEvent={firstEvent}
        k8sDiff={k8sDiff}
        specsForDiff={specsForDiff}
        isFirstDeployment={isOldSpecEmpty || eventGroup.generation === 1}
        isFetchingK8sData={isFetchingK8sData}
      />
      {!!k8sConfig?.length && <ConfigChangesSection k8sConfig={k8sConfig} />}
      {gitCompares?.length ? (
        gitCompares?.map((gitCompare, i) => {
          const gitProvider = getGitProviderData(gitCompare);
          return (
            <GitSection
              key={i}
              gitTypeFinder={gitTypeFinder}
              gitProvider={gitProvider}
            />
          );
        })
      ) : (
        <GitSection gitTypeFinder={gitTypeFinder} />
      )}
      {trackedFiles !== null && trackedFiles?.length ? (
        <TrackedFilesSection trackedFiles={trackedFiles} />
      ) : (
        gitCompares?.map((gitCompare) => (
          <TrackedFilesSectionFromGitComp
            key={gitCompare.url}
            commitDiff={gitCompare.commitDiff}
          />
        ))
      )}
      {resource?.kind === KubernetesServiceTypeEnum.Rollout && ( // remove when open to all kinds
        <StrategySection event={lastEvent} kubernetesKind={resource?.kind} />
      )}
      <>
        {teamsConfigured && (
          <TeamsSection
            sentToChannels={wfRun?.workflowConfiguration?.sinks?.teams ?? null}
          />
        )}
        {slackConfigured && (
          <SlackSection
            sentToChannels={wfRun?.workflowConfiguration?.sinks?.slack}
          />
        )}
      </>
      <DrawerEventLinksSection
        linksTemplates={deployRelatedLinks}
        resource={resource}
        deploySpec={specsForDiff?.newSpec}
        startTime={eventGroup.startTime}
        endTime={eventGroup.endTime}
        isCompleted={eventGroup.isCompleted}
        failedPod={failedPod}
      />
    </EventDetailsContainer>
  );
};

export const DeployEventDetails: React.FC<DeployEventDetailsProps> = ({
  eventGroup,
}) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.eventDeploy,
        context: {
          feTeam: "troubleshooting",
          beTeam: ["troubleshooting", "barzelim"],
        },
      }}
    >
      <DeployEventDetailsComponent eventGroup={eventGroup} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
