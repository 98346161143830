import { ComponentProps, useCallback } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";

import { LoaderSkeleton } from "@/components/k8sAddons/components/table/LoaderSkeleton";

type GenerateSkeletonParams<T> = {
  gridRenderCellParams: GridRenderCellParams;
  columnName: keyof T;
  skeletonProps?: ComponentProps<typeof Skeleton>;
};

export const useGenerateSkeleton = <T,>(
  randRangeColumns: Partial<Record<keyof T, number[]>>
) => {
  return useCallback(
    ({
      gridRenderCellParams,
      columnName,
      skeletonProps,
    }: GenerateSkeletonParams<T>) => {
      const { api, id } = gridRenderCellParams;
      const rowIndexId = api.getAllRowIds().indexOf(id) + 1;
      return (
        <LoaderSkeleton
          id={rowIndexId}
          columnName={columnName}
          randRangeColumns={randRangeColumns}
          skeletonProps={skeletonProps}
        />
      );
    },
    [randRangeColumns]
  );
};
