import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import React, { useMemo } from "react";

import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";

import { getLabelTextByWorkspace } from "./utils";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { selectedAppViewIdSelector } from "@/shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "@/shared/store/appViewsStore/appViewsStore";

type AllWorkspacesToggleProps = {
  searchInAllWorkspaces: boolean;
  onClick: () => void;
  overrideClusterName?: string;
};

const Root = styled.div`
  padding: 11px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / 3;
`;

export function AllWorkspacesToggle({
  searchInAllWorkspaces,
  onClick,
  overrideClusterName,
}: AllWorkspacesToggleProps) {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const { isClusterWorkspace, isClustersGroupWorkspace, currentWorkspace } =
    useWorkspaces();

  const currentWorkspaceName = useMemo(
    () =>
      overrideClusterName && !searchInAllWorkspaces
        ? overrideClusterName
        : searchInAllWorkspaces
        ? getLabelTextByWorkspace(!!overrideClusterName || isClusterWorkspace)
        : currentWorkspace?.name,
    [
      overrideClusterName,
      searchInAllWorkspaces,
      isClusterWorkspace,
      currentWorkspace?.name,
    ]
  );

  if (!overrideClusterName && !selectedAppViewId) {
    return null;
  }

  return (
    <Root>
      <Typography variant="body3" color="text.secondary">
        Showing results for{" "}
        {isClusterWorkspace || overrideClusterName
          ? "Cluster"
          : isClustersGroupWorkspace
          ? "Cluster Group"
          : "Workspace"}
        :{" "}
        <Box color="text.primary" fontWeight="bold" display="inline">
          {currentWorkspaceName}
        </Box>
      </Typography>
      <FormControlLabel
        tabIndex={-1}
        control={
          <Switch
            checked={searchInAllWorkspaces}
            onChange={() => onClick()}
            sx={{ marginLeft: "8px" }}
            size="small"
          />
        }
        aria-label={AriaLabels.GlobalSearch.AllWorkspacesToggle}
        labelPlacement="start"
        label={
          <Typography variant="body3" color="text.secondary">
            Search all{" "}
            {isClusterWorkspace ||
            isClustersGroupWorkspace ||
            overrideClusterName
              ? "clusters"
              : "workspaces"}
          </Typography>
        }
      />
    </Root>
  );
}
