import { useCallback, useEffect, useMemo, useState } from "react";
import { memoize } from "lodash";

import { useAuthorization } from "../roles/useAuthorization";
import { sleep } from "../../utils/sleep";
import { useAppViewsStore } from "../../store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../store/appViewsStore/appViewStoreSelectors";
import { CommonApiResponse } from "../../types/commonApiResponse";

import { createPathWithParameters, roundToNearestSeconds } from "./utils";
import {
  CLIENT_CACHE_EXPIRY_IN_SECONDS,
  CLIENT_ERROR_PREFIX,
  EndpointRequestTypeNames,
  EndpointsRequestType,
  EndpointsResponseType,
  MAX_RETRIES,
  SERVER_ERROR_PREFIX,
} from "./types";
import { executeAPIGet, executeAPIPost } from "./fetch";
import { cancelFetchState, initialFetchState } from "./initialState";

import { notifyDDError } from "@/shared/hooks/exceptionManagement";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useAPIGet = <T extends EndpointsResponseType>(
  relativePath: EndpointRequestTypeNames,
  parameters: EndpointsRequestType,
  pause = false,
  basePath: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<T> => {
  const path = useMemo(
    () =>
      createPathWithParameters(
        relativePath,
        parameters as Record<string, unknown>,
        basePath
      ),
    [basePath, parameters, relativePath]
  );
  return useAPIInternal(path, pause);
};

export const useAPIPost = <T extends EndpointsResponseType>(
  relativePath: EndpointRequestTypeNames,
  reqBody: EndpointsRequestType,
  pause = false,
  basePath: string,
  skipAppViewInHeader?: boolean,
  skipWorkspaceHeader?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<T> => {
  const path = useMemo(
    () => createPathWithParameters(relativePath, {}, basePath),
    [basePath, relativePath]
  );
  const body = useMemo(() => JSON.stringify(reqBody), [reqBody]);
  return useAPIInternal(
    path,
    pause,
    body,
    skipAppViewInHeader,
    skipWorkspaceHeader
  );
};

export const useApiGetWithUrl = <T extends EndpointsResponseType>(
  url: string,
  pause = false,
  skipAppViewInHeader?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): CommonApiResponse<T> => {
  return useAPIInternal(url, pause, undefined, skipAppViewInHeader);
};

const useAPIInternal = <T extends EndpointsResponseType>(
  path: string,
  pause = false,
  body?: string,
  skipAppViewInHeader?: boolean,
  skipWorkspaceHeader?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const { loading, error, data, executeCb, clearState, dataRequestParams } =
    useAPIGetStrict<T>(pause, skipAppViewInHeader, skipWorkspaceHeader);
  const [retryCount, setRetryCount] = useState(0);

  const authorization = useAuthorization();

  useEffect(() => {
    clearState();
    setRetryCount(0);
  }, [path, authorization, pause, clearState]);

  useEffect(() => {
    (async () => {
      if (pause) return;
      if (loading) return;
      if (retryCount > MAX_RETRIES) return;
      if (error?.startsWith(CLIENT_ERROR_PREFIX)) return;

      const execution = async () => {
        setRetryCount((count) => count + 1);
        await executeCb(path, authorization, body);
      };

      const retry = async () => {
        await sleep(1500);
        await execution();
      };

      if (retryCount === 0) {
        await execution();
      } else if (error?.startsWith(SERVER_ERROR_PREFIX)) {
        await retry();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization, body, error, loading, path, pause, retryCount]);

  const refresh = useCallback(() => {
    executeCb(path, authorization, body);
  }, [authorization, body, executeCb, path]);

  return { loading, error, data, dataRequestParams, refresh };
};

const {
  loading: initialLoading,
  error: initialError,
  data: initialData,
  dataRequestParams: initialRequestParams,
} = initialFetchState;

const useAPIGetStrict = <T>(
  pause = false,
  skipAppViewInHeader?: boolean,
  skipWorkspaceHeader?: boolean
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const [loading, setLoading] = useState(initialLoading);
  const [data, setData] = useState(initialData);
  const [dataRequestParams, setDataRequestParams] =
    useState(initialRequestParams);
  const [error, setError] = useState<string | null>(initialError);
  const workspaceId = useAppViewsStore(selectedAppViewIdSelector) ?? "";
  const { currentWorkspace } = useWorkspaces();
  const workspaceIdUsed =
    skipAppViewInHeader || skipWorkspaceHeader ? "" : workspaceId;
  const { useClusterWorkspaceAsScopedWorkspace } = useOverridableFlags();

  const wsKind = currentWorkspace?.kind;

  const executeCb = useCallback(
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
    async (path: string, authorization: string | null, body?: string) => {
      const options = {
        workspaceId: workspaceIdUsed,
        workspaceKind: wsKind,
        skipWorkspaceHeader,
        useClusterWorkspaceAsScopedWorkspace:
          !!useClusterWorkspaceAsScopedWorkspace,
      };

      setLoading(true);
      if (authorization && !pause) {
        try {
          const res = body
            ? await executeAPIPost(path, authorization, body, options)
            : await executeAPIGet(path, authorization, options);
          if (res.status >= 300) {
            setError(SERVER_ERROR_PREFIX + res.statusText);
            const err = new Error("API Request Error");
            notifyDDError(err, {
              message: `${SERVER_ERROR_PREFIX} ${res.statusText}`,
              url: path,
            });
            setLoading(false);
            return;
          }
          const data = await res.json();
          setData(data);
          setDataRequestParams(body ? body : path);
        } catch (err) {
          const errorMessageDetails =
            err != null ? String(err) : "unknown error";
          const errMsg = CLIENT_ERROR_PREFIX + errorMessageDetails;
          setError(errMsg);
          notifyDDError(err as Error, {
            message: errMsg,
            path,
          });
        }
      }
      setLoading(false);
    },
    [
      workspaceIdUsed,
      wsKind,
      skipWorkspaceHeader,
      useClusterWorkspaceAsScopedWorkspace,
      pause,
    ]
  );

  const executeCbMemo = memoize(executeCb, memoResolver);

  const clearState = useCallback(() => {
    setLoading(cancelFetchState.loading);
    setData(cancelFetchState.data);
    setDataRequestParams(cancelFetchState.dataRequestParams);
    setError(cancelFetchState.error);
  }, []);

  return {
    loading,
    error,
    data: data ? (data as T) : undefined,
    executeCb: executeCbMemo,
    clearState,
    dataRequestParams,
  };
};

const memoResolver = (path: string, authorization: string | null) =>
  `${path}-${authorization}-${roundToNearestSeconds(
    CLIENT_CACHE_EXPIRY_IN_SECONDS
  )}`;
