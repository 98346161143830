import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useCallback } from "react";

import { useResourcesApiClient } from "../apiClient";
import { NODES_EVENTS_SEARCH } from "../../requestResponseMaps";

import {
  apiV1NodesEventsSearchGetUrl,
  EventsApiApiV1NodesEventsSearchGetRequest,
  NodeEventsResponse,
} from "@/generated/resourcesApi";

const fetchNodeEventsSearch = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1NodesEventsSearchGetRequest
): Promise<NodeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1NodesEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetNodeEventsSearch = (
  params: EventsApiApiV1NodesEventsSearchGetRequest,
  options: { enabled?: boolean } = { enabled: true }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [NODES_EVENTS_SEARCH, params],
    () => fetchNodeEventsSearch(apiClient, params),
    options
  );
};

/**
 * The type of the function that is returned from useGetNodeEventsSearchMutation
 */
export type NodeEventsSearchHandler = (
  params: EventsApiApiV1NodesEventsSearchGetRequest
) => Promise<NodeEventsResponse>;

export const useGetNodeEventsSearchQuery: () => NodeEventsSearchHandler =
  () => {
    const apiClient = useResourcesApiClient();
    const queryClient = useQueryClient();

    return useCallback(
      (params: EventsApiApiV1NodesEventsSearchGetRequest) => {
        return queryClient.fetchQuery({
          queryKey: [NODES_EVENTS_SEARCH, params],
          queryFn: () => fetchNodeEventsSearch(apiClient, params),
        });
      },
      [apiClient, queryClient]
    );
  };
