import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const LoadTimeReportingHandler: React.FC = () => {
  const { triggerStartView } = useDatadogReportLoadingTimeContext();
  const location = useLocation();
  const lastUrl = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (lastUrl.current !== location.pathname) {
      triggerStartView(true);
    }

    lastUrl.current = location.pathname;
  }, [triggerStartView, location.pathname]);

  return null;
};
