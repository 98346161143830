import { useCallback, useEffect, useMemo, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

import { useSetIsPaginating } from "../useSetIsPaginating";

import { GenericFilter, GetEntityListRequest } from "@/generated/addonsApi";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";
import { useTablePaginationInUrl } from "@/components/k8sAddons/hooks/table/useTablePaginationInUrl";
import { useRefetchIntervalFromContext } from "@/components/k8sAddons/hooks/useRefetchIntervalFromContext";
import { useGetLiveStateResetFilters } from "@/components/k8sAddons/hooks/filters/useGetLiveStateResetFilters";
import { useResetCurrentPageOnFiltersChange } from "@/components/k8sAddons/hooks/table/useResetCurrentPageOnFiltersChange";
import { useMostRecentUpdateRequestParam } from "@/components/k8sAddons/hooks/filters/useMostRecentUpdateRequestParam";
import { useGetAddonLiveStateList } from "@/shared/hooks/k8s-add-ons/useGetAddonLiveStateList";
import { useShouldRequestBeEnabled } from "@/components/k8sAddons/hooks/useShouldRequestBeEnabled";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

type FetchLiveStateListParams = {
  additionalRequestFilters?: GenericFilter[] | undefined;
};

export const useFetchLiveStateList = ({
  additionalRequestFilters,
}: FetchLiveStateListParams) => {
  const [isPaginating, setIsPaginating] = useState(true);

  const { addonType, addonEntity, disableServerSidePagination, staleTime } =
    useAddonContext();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const filtersListFromUrl = useFilterListFromUrlAsGenericFilters();
  const { sortModel, paginationModel, setPaginationModel } =
    useTablePaginationInUrl();
  const refetchInterval = useRefetchIntervalFromContext(isPaginating);
  const liveStateResetFilters = useGetLiveStateResetFilters();

  const filtersToUse = useMemo<GenericFilter[]>(
    () => [...filtersListFromUrl, ...(additionalRequestFilters ?? [])],

    [filtersListFromUrl, additionalRequestFilters]
  );

  const onResetCurrentPageFilters = useCallback(
    (value: Partial<GridPaginationModel>) => {
      setPaginationModel(value, true);
    },
    [setPaginationModel]
  );

  const { pageToUse } = useResetCurrentPageOnFiltersChange({
    filtersList: filtersToUse,
    setPaginationModel: onResetCurrentPageFilters,
    currentPage: paginationModel.page,
  });

  const { updateMostRecentDataFrom, getMostRecentDataFromToUse } =
    useMostRecentUpdateRequestParam(liveStateResetFilters ?? []);

  const pageSize = disableServerSidePagination ? 0 : paginationModel.pageSize;
  const page = disableServerSidePagination ? 0 : pageToUse;

  const requestParams = useMemo<GetEntityListRequest>(() => {
    return {
      mostRecentDataFrom: getMostRecentDataFromToUse(),
      filter: filtersToUse,
      page,
      pageSize,
      sort: sortModel[0].field,
      order: sortModel[0].sort === "asc" ? "asc" : "desc",
    };
  }, [filtersToUse, getMostRecentDataFromToUse, page, pageSize, sortModel]);

  const queryResult = useGetAddonLiveStateList(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityListRequest: requestParams,
    },
    {
      keepPreviousData: true,
      refetchInterval,
      enabled: useShouldRequestBeEnabled(),
      ...(staleTime && { staleTime }),
    }
  );

  useSetIsPaginating({
    fetchedIsPaginating: queryResult.data?.data.isPaginating,
    setIsPaginating,
  });

  useEffect(() => {
    reportLoadingState(
      `live-list-${addonType}-${addonEntity}`,
      !queryResult.isFetched
    );
  }, [addonEntity, addonType, queryResult.isFetched, reportLoadingState]);

  updateMostRecentDataFrom(queryResult.data?.data.mostRecentUpdate);

  return queryResult;
};
