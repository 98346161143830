import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { K8S_ADD_ONS_AVOID_SHOWING_STATUSES } from "@/shared/config/urlSearchParamsKeys";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export function useOpenAutoscalerDrawer() {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  return ({ cluster, name }: { cluster: string; name: string }) => {
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: cluster,
      resourceName: name,
      resourceType: SupportedResourcesKinds.KubernetesDeploymentResource,
      urlStates: {
        [K8S_ADD_ONS_AVOID_SHOWING_STATUSES]: true,
      },
    });
  };
}
