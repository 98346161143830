import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  createTrackedKeyUrl,
  TrackedKey,
  TrackedKeyCreateRequest,
  TrackedKeysApiCreateTrackedKeyRequest,
} from "@/generated/accounts";
import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";

const createTrackedKey = async (
  apiClient: AxiosInstance,
  params: TrackedKeysApiCreateTrackedKeyRequest
): Promise<TrackedKey> => {
  const { data } = await apiClient.post(
    createTrackedKeyUrl(params, apiClient.defaults.baseURL ?? ""),
    params.trackedKeyCreateRequest
  );
  return data;
};

export const useCreateTrackedKey = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    (vars: TrackedKeyCreateRequest) =>
      createTrackedKey(apiClient, { trackedKeyCreateRequest: vars }),
    {}
  );
};
