// This is a temp file, that will hold all the possible feature flag values for the hasura migration ff
// link https://app.launchdarkly.com/projects/default/flags/hasuraMigrations/

export interface IHasuraMigrations {
  endpoints: string[];
}
export const LatestServiceDeploy = "LatestServiceDeploy";
export const GetPlansData = "getPlansData";
export const GetSinglePlansData = "getPlanData";
export const ListServiceAttributesForAccount =
  "listServiceAttributesForAccount";
export const OpenServiceAvailabilityIssue = "openServiceAvailabilityIssue";
export const DeployInsightsStatuses = "DeployInsightsStatuses";
export const FetchAllKomodorServiceAttributesByKeys =
  "fetchAllKomodorServiceAttributesByKeys";
export const InitAttributesConfiguration = "InitAttributesConfiguration";
export const FetchEventsLimits = "FetchEventsLimits";
