import styled from "styled-components";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Tooltip from "@mui/material/Tooltip";

import { LabeledData } from "@/components/common/LabeledData/LabeledData";
import { TooltipType } from "@/components/common/LabeledData/LabeledContent.types";

type LabeledDataWithTooltipProps = {
  title: string;
  tooltip: { title: string; type: TooltipType; ariaLabel?: string };
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const getTooltipIcon = (type: TooltipType, ariaLabel?: string) => {
  switch (type) {
    case TooltipType.Info:
      return (
        <InfoOutlined
          fontSize={"small"}
          color={"action"}
          aria-label={ariaLabel}
        />
      );
    case TooltipType.Warning:
      return (
        <WarningAmberOutlinedIcon
          fontSize={"small"}
          color={"warning"}
          aria-label={ariaLabel}
        />
      );
    case TooltipType.Error:
      return (
        <ErrorOutlinedIcon
          fontSize={"small"}
          color={"error"}
          aria-label={ariaLabel}
        />
      );
  }
};

export const LabeledDataWithTooltip: React.FC<LabeledDataWithTooltipProps> = ({
  title,
  tooltip,
}) => {
  const tooltipIcon = getTooltipIcon(tooltip.type, tooltip.ariaLabel);
  return (
    <Container>
      <LabeledData title={title} />
      <Tooltip title={tooltip.title} placement={"top"}>
        {tooltipIcon}
      </Tooltip>
    </Container>
  );
};
