import { isRuleGroupType, RuleGroupType, RuleType } from "react-querybuilder";

import { ResourcesScope } from "@/generated/auth";

const sortOrder = ["cluster", "namespace", "key", "value"];

const sortByField = (a: RuleType, b: RuleType) => {
  return sortOrder.indexOf(a.field) - sortOrder.indexOf(b.field);
};

function isRuleType(rule: RuleType | RuleGroupType): rule is RuleType {
  return !isRuleGroupType(rule);
}

interface HandleFieldParams {
  rule: RuleType;
  resourcesScope: ResourcesScope;
  ruleGroup?: RuleGroupType;
}

export const handleClusterField = ({
  rule,
  resourcesScope,
}: HandleFieldParams) => {
  const updateClusterPatterns = (key: "include" | "exclude", value: string) => {
    const currentPatterns = resourcesScope.clustersPatterns[0] ?? {
      include: "",
      exclude: "",
    };
    resourcesScope.clustersPatterns[0] = {
      ...currentPatterns,
      [key]: value,
    };
  };

  switch (rule.operator) {
    case "matchesWildcard":
      updateClusterPatterns("include", rule.value);
      return resourcesScope;
    case "doesntMatchWildcard":
      updateClusterPatterns("exclude", rule.value);
      return resourcesScope;
    case "multiIs":
      resourcesScope.clusters.push(...(rule.value ?? "").split(","));
      return resourcesScope;
  }

  return resourcesScope;
};

export const handleNamespaceField = ({
  rule,
  resourcesScope,
}: HandleFieldParams) => {
  const updateNamespacePatterns = (
    key: "include" | "exclude",
    value: string
  ) => {
    const currentPatterns = resourcesScope.namespacesPatterns[0] ?? {
      include: "",
      exclude: "",
    };
    resourcesScope.namespacesPatterns[0] = {
      ...currentPatterns,
      [key]: value,
    };
  };

  switch (rule.operator) {
    case "matchesWildcard":
      updateNamespacePatterns("include", rule.value);
      return resourcesScope;
    case "doesntMatchWildcard":
      updateNamespacePatterns("exclude", rule.value);
      return resourcesScope;
    case "multiIs":
      resourcesScope.namespaces.push(...(rule.value ?? "").split(","));
      return resourcesScope;
  }

  return resourcesScope;
};

export const handleValueField = ({
  rule,
  resourcesScope,
}: HandleFieldParams) => {
  const updateSelectorsPatterns = (
    key: "include" | "exclude",
    value: string
  ) => {
    const index = resourcesScope.selectorsPatterns.length - 1;
    if (index < 0) {
      return;
    }

    const currentPatterns = resourcesScope.selectorsPatterns[index].value ?? {
      include: "",
      exclude: "",
    };
    resourcesScope.selectorsPatterns[index].value = {
      ...currentPatterns,
      [key]: value,
    };
  };

  switch (rule.operator) {
    case "matchesWildcard":
      updateSelectorsPatterns("include", rule.value);
      return resourcesScope;
    case "doesntMatchWildcard":
      updateSelectorsPatterns("exclude", rule.value);
      return resourcesScope;
  }

  return resourcesScope;
};

export const handleKeyField = ({
  rule,
  resourcesScope,
  ruleGroup,
}: HandleFieldParams) => {
  if (!ruleGroup) {
    throw new Error("Group is missing");
  }

  if (!isValueRuleExist(ruleGroup)) {
    throw new Error("Value rule is missing");
  }

  const [type, key] = rule.value.split(":");
  resourcesScope.selectorsPatterns.push({
    type,
    key,
    value: {
      include: "",
      exclude: "",
    },
  });
  return resourcesScope;
};

export const fieldMapper: {
  [key: string]: (params: HandleFieldParams) => ResourcesScope;
} = {
  cluster: handleClusterField,
  namespace: handleNamespaceField,
  key: handleKeyField,
  value: handleValueField,
  selector: handleValueField, // selector is the scoped workspace of value
};

const isValueRuleExist = (group: RuleGroupType) => {
  return group.rules.some((rule) => (rule as RuleType).field === "value");
};

export const createEmptyResourcesScope = (): ResourcesScope => ({
  clusters: [],
  clustersPatterns: [],
  namespaces: [],
  namespacesPatterns: [],
  selectors: [],
  selectorsPatterns: [],
});

export const queryToResourcesScope = (query: RuleGroupType): ResourcesScope => {
  const result = createEmptyResourcesScope();

  const { rules: groups } = query;
  if (groups.length === 0) {
    throw new Error("Query is empty");
  }

  groups.forEach((group: RuleType | RuleGroupType) => {
    if (!isRuleGroupType(group)) {
      throw new Error("Instance is not a group");
    }

    // initialize a temp structure to hold the resource scope
    let currentIterationStructure = createEmptyResourcesScope();

    group.rules
      .filter(isRuleType)
      .sort(sortByField)
      .forEach((rule: RuleType) => {
        currentIterationStructure = fieldMapper[rule.field]({
          rule,
          resourcesScope: currentIterationStructure,
          ruleGroup: group,
        });
      });

    // pass from temp structure to result
    if (currentIterationStructure.clusters.length > 0) {
      result.clusters.push(...currentIterationStructure.clusters);
    }
    if (currentIterationStructure.clustersPatterns.length > 0) {
      result.clustersPatterns.push(
        ...currentIterationStructure.clustersPatterns
      );
    }
    if (currentIterationStructure.namespaces.length > 0) {
      result.namespaces.push(...currentIterationStructure.namespaces);
    }
    if (currentIterationStructure.namespacesPatterns.length > 0) {
      result.namespacesPatterns.push(
        ...currentIterationStructure.namespacesPatterns
      );
    }
    if (currentIterationStructure.selectorsPatterns.length > 0) {
      result.selectorsPatterns.push(
        ...currentIterationStructure.selectorsPatterns
      );
    }

    // selectors are being ignored at the moment, because there is no UI for that
  });

  return result;
};
