import {
  DataGrid,
  ColumnHeaderWithTooltip,
  getInitialPageConfig,
} from "@komodorio/design-system/komodor-ui";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import styled from "styled-components";

import { OverProvisionedClusterGroupedData } from "@/generated/reliabilityApi";
import { ColoredPercentageCell } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/ColoredPercentageCell";
import {
  ErrorOperator,
  instancesTooltipContent,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedClusterConstants";
import { overProvisionedAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProviosionedAriaLabel";
import { compactOptions, formatNumber } from "@/shared/utils/formatNumber";
import { VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE } from "@/components/reliability/components/pages/violations/violationsConstants";

const NodesDataGrid = styled(DataGrid<OverProvisionedNodeRow>)`
  && {
    & .MuiDataGrid-columnHeaderTitleContainer {
      overflow: visible;
    }
  }
`;

type OverProvisionNodesDataTableProps = {
  overProvisionedNodes: Record<string, OverProvisionedClusterGroupedData>;
};

type OverProvisionedNodeRow = {
  id: string;
  nodeType: string;
} & OverProvisionedClusterGroupedData;

const renderPercentageCell = ({ value }: { value?: number }) => {
  return (
    <ColoredPercentageCell
      errorOperator={ErrorOperator.LessThan}
      value={value}
      errorThreshold={80}
    />
  );
};

export const OverProvisionNodesDataTable: React.FC<
  OverProvisionNodesDataTableProps
> = ({ overProvisionedNodes }) => {
  const columns: GridColDef<OverProvisionedNodeRow>[] = [
    {
      field: "nodeType",
      headerName: "Node type",
    },
    {
      field: "instances",
      headerName: "Instances",
      flex: 1,
      renderHeader: () => (
        <ColumnHeaderWithTooltip
          headerName="Instances"
          tooltipText={instancesTooltipContent}
        />
      ),
    },
    {
      field: "avgCpuAllocatedPct",
      headerName: "Avg. CPU allocation",
      flex: 1,
      renderCell: renderPercentageCell,
    },
    {
      field: "avgMemoryAllocatedPct",
      headerName: "Avg. Memory allocation",
      flex: 1,
      renderCell: renderPercentageCell,
    },
    {
      field: "potentialCPUSavings",
      headerName: "Potential CPU waste (Core hours)",
      flex: 1,
      valueFormatter: ({ value }) => `${formatNumber(value, compactOptions)}`,
    },
    {
      field: "potentialMemorySavings",
      headerName: "Potential memory waste (Gib hours)",
      flex: 1,
      valueFormatter: ({ value }) => `${formatNumber(value, compactOptions)}`,
    },
  ];
  const rowsToRender: OverProvisionedNodeRow[] = useMemo(() => {
    return Object.entries(overProvisionedNodes).map(([nodeType, data]) => {
      return {
        nodeType,
        ...data,
        id: `${nodeType}-${data.instances}-${data.avgCpuAllocatedPct}-${data.avgMemoryAllocatedPct}`,
      };
    });
  }, [overProvisionedNodes]);

  return (
    <NodesDataGrid
      aria-label={overProvisionedAriaLabels.dataByNodesTable.table}
      {...getInitialPageConfig(VIOLATION_RESOURCES_DEFAULT_PAGE_SIZE, {
        sorting: {
          sortModel: [
            { field: "potentialCPUSavings", sort: "desc" },
            { field: "potentialMemorySavings", sort: "desc" },
          ],
        },
      })}
      renderFalsyValues
      columns={columns}
      rows={rowsToRender}
      density="compact"
    />
  );
};
