import { useCallback, useMemo } from "react";
import { QueryWithError } from "@komodorio/design-system/komodor-ui";
import { isRuleGroupType, RuleGroupType, RuleType } from "react-querybuilder";

import { areQueriesEqual } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import { getClusterRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getClusterRulesFromQuery";
import { getNamespaceRulesFromQuery } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getNamespaceRulesFromQuery";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { useGetNamespaces } from "@/shared/hooks/resources-api/client/useGetNamespaces";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";

const identity = (query: QueryWithError) => query;

type UseChangeQuery = {
  isRuleBuilderDirty: boolean;
  ruleBuilderQuery: QueryWithError | string | null;
  setRuleBuilderQuery: (query: QueryWithError) => void;
  setIsRuleBuilderDirty: (isDirty: boolean) => void;
};

// TODO Lior: this should change after onClose changes will be merged
export const useChangeQuery = ({
  isRuleBuilderDirty,
  ruleBuilderQuery,
  setRuleBuilderQuery,
  setIsRuleBuilderDirty,
}: UseChangeQuery) => {
  const { data } = useGetNamespaces();
  const namespaces = useMemo(() => data?.data ?? [], [data]);
  const { scope } = useStatementDrawerContext();

  const getNamespacesNamesByClusters = useCallback(
    (clusters: string[]) => {
      return namespaces
        .filter((namespace) => clusters.includes(namespace.clusterName))
        .map((namespace) => namespace.name);
    },
    [namespaces]
  );

  const interceptNamespacesRules = useCallback(
    (query: QueryWithError) => {
      const namespacesRules = getNamespaceRulesFromQuery(query);
      const clusterRules = getClusterRulesFromQuery(query);
      if (!namespacesRules?.namespaces?.length) {
        return query;
      }
      if (!clusterRules?.clusters?.length) {
        return query;
      }

      // create a new instance of ruleBuilderQuery, only now, change the value of the rule that has the field: 'namespace'
      // to be the namespaces that are in the cluster (via getNamespacesByClusters)
      const namespacesNamesByClusters = getNamespacesNamesByClusters(
        clusterRules.clusters
      );

      const groups = query.rules;
      const newGroups = groups.map((group: RuleType | RuleGroupType) => {
        if (!isRuleGroupType(group)) {
          return group;
        }

        const rules = group.rules.map((rule: RuleType | RuleGroupType) => {
          if (isRuleGroupType(rule)) {
            return rule;
          }

          if (rule.field === "namespace") {
            return {
              ...rule,
              value: namespacesRules.namespaces
                ?.filter((namespace) =>
                  namespacesNamesByClusters.includes(namespace)
                )
                .join(","),
            };
          }

          return rule;
        });

        return { ...group, rules };
      });

      return { ...query, rules: newGroups };
    },
    [getNamespacesNamesByClusters]
  );

  const interceptors = useMemo(
    () => ({
      [Scope.cluster]: identity,
      [Scope.namespace]: interceptNamespacesRules,
      // TODO Lior: intercept the query and make sure the that if there is one key-value role, it should not be removed
      [Scope.cwr]: identity,
      [Scope["komodor-actions"]]: identity,
    }),
    [interceptNamespacesRules]
  );

  const handleChangeRuleQuery = useCallback(
    (query: QueryWithError) => {
      if (!isRuleGroupType(ruleBuilderQuery)) {
        return;
      }
      setRuleBuilderQuery(interceptors[scope as Scope](query));
      if (!isRuleBuilderDirty && !areQueriesEqual(query, ruleBuilderQuery)) {
        setIsRuleBuilderDirty(true);
      }
    },
    [
      interceptors,
      isRuleBuilderDirty,
      ruleBuilderQuery,
      scope,
      setIsRuleBuilderDirty,
      setRuleBuilderQuery,
    ]
  );

  return { handleChangeRuleQuery };
};
