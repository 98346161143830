import Typography from "@mui/material/Typography";

import { UnschedulablePodsSupportingData } from "@/generated/reliabilityApi";
import { TextContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";

export function getUnschedulablePodsTitle(
  supportingData?: UnschedulablePodsSupportingData
) {
  if (!supportingData) {
    return "Unschedulable Pods";
  }
  return `${supportingData.unschedulablePods} Pods were unschedulable for longer than ${supportingData.totalUnschedulableTime} time`;
}

type UnschedulablePodsWhatHappenedParams = {
  clusterName: string;
  time: string;
};

export const unschedulablePodsWhatHappened = (
  params: UnschedulablePodsWhatHappenedParams
) => (
  <TextContainer>
    Pods in the{" "}
    <Typography display="inline" fontWeight="bold">
      {params.clusterName}
    </Typography>{" "}
    cluster have been unschedulable for a total of{" "}
    <Typography display="inline" fontWeight="bold">
      {params.time}
    </Typography>{" "}
    in the last 7 days
  </TextContainer>
);

// TODO: We still don't have texts for this. A fix will be provided soon.

export const unschedulablePodsWhyIsThisImportant = (
  <TextContainer>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus
    feugiat, molestie ipsum et, consequat nibh. Etiam non elit dui. Nullam vel
    eros sit amet arcu vestibulum accumsan in in leo. Fusce nec aliquet nisl.
    Curabitur quis sapien eu mi feugiat facilisis. Proin auctor, quam ac
    consectetur accumsan, nisl lorem ultrices tortor, eu feugiat nulla turpis
    nec orci. Mauris nec maximus magna. Nullam rhoncus, nunc vel blandit
  </TextContainer>
);

export const unschedulablePodsHowToFix = (
  <TextContainer>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus
    feugiat, molestie ipsum et, consequat nibh. Etiam non elit dui. Nullam vel
    eros sit amet arcu vestibulum accumsan in in leo. Fusce nec aliquet nisl.
    Curabitur quis sapien eu mi feugiat facilisis. Proin auctor, quam ac
    consectetur accumsan, nisl lorem ultrices tortor, eu feugiat nulla turpis
    nec orci. Mauris nec maximus magna. Nullam rhoncus, nunc vel blandit
  </TextContainer>
);
