import ShareOutlined from "@mui/icons-material/ShareOutlined";
import { MagicAi24 } from "@komodorio/design-system/icons";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Loop from "@mui/icons-material/Loop";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { RichSessionData } from "@/components/AiInvestigation/useEnrichedSession";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

interface AiInvestigationTitleProps {
  richSession: RichSessionData | null;
  onShareClicked?: () => void;
  onRetryCallback?: () => void;
}

export const AiInvestigationTitle: React.FC<AiInvestigationTitleProps> = ({
  richSession,
  onShareClicked,
  onRetryCallback,
}) => {
  const { isKomodorAdmin } = useUserMetadata();
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const { aiInvestigationRestartButton } = useOverridableFlags();

  return (
    <Stack direction="row" columnGap="4px" alignItems="center" width="100%">
      <MagicAi24 />
      <Typography variant="h5">Klaudia AI Investigation</Typography>
      <Typography variant="body3" color="text.secondary">
        (Powered by AI)
      </Typography>
      {richSession?.isComplete && richSession.isNoProblemFound && (
        <Typography variant="h6" fontWeight={500}>
          | No Findings
        </Typography>
      )}
      {isKomodorAdmin && richSession?.id && (
        <Link
          component="a"
          variant="body3"
          target="_blank"
          href={`/ai-investigator/?sessionId=${richSession.id}`}
        >
          View session (internal)
        </Link>
      )}
      {richSession?.isComplete ? (
        <Box flexGrow={1} display="flex" justifyContent="flex-end">
          {!!aiInvestigationRestartButton && onRetryCallback && (
            <Button
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onRetryCallback();
              }}
              startIcon={<Loop />}
              aria-label={AriaLabels.AiInvestigator.RestartButton}
            >
              Restart Investigation
            </Button>
          )}
          {!richSession.isNoProblemFound && onShareClicked && (
            <>
              <Button
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShareDialogOpen(true);
                }}
                startIcon={<ShareOutlined />}
                aria-label={AriaLabels.AiInvestigator.ShareButton}
              >
                Share Link
              </Button>
              {isShareDialogOpen && (
                <ConfirmationDialog
                  title="Share Link"
                  okText="Copy Link"
                  width="446px"
                  content={
                    <Typography variant="body2">
                      Please note that the person receiving the link will be
                      able to see all resources regardless of their permissions.
                    </Typography>
                  }
                  onClose={(confirmed) => {
                    setIsShareDialogOpen(false);
                    confirmed && onShareClicked();
                  }}
                />
              )}
            </>
          )}
        </Box>
      ) : null}
    </Stack>
  );
};
