import ListItem from "@mui/material/ListItem";
import { Options } from "react-markdown";

import { Markdown } from "components/komodor-ui/Markdown/Markdown";
import { ChatMessageType } from "components/komodor-ui/Chat/types";
import { FailedMessageBubble } from "components/komodor-ui/Chat/MessageBubble/FailedMessageBubble";
import { MessageBubble } from "components/komodor-ui/Chat/MessageBubble/MessageBubble";

export interface ChatMessageProps {
  message: ChatMessageType;
  markdownProps?: Options;
}
export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  markdownProps,
}) => {
  const { id, isUser, isFailed, content } = message;

  return (
    <ListItem
      key={id}
      alignItems="flex-start"
      sx={{
        justifyContent: isUser ? "flex-end" : "flex-start",
        paddingInline: "24px",
      }}
    >
      {isFailed ? (
        <FailedMessageBubble id={id} isUser={isUser}>
          {content}
        </FailedMessageBubble>
      ) : (
        <MessageBubble id={id} isUser={isUser}>
          {typeof content === "string" ? (
            <Markdown {...markdownProps}>{content}</Markdown>
          ) : (
            content
          )}
        </MessageBubble>
      )}
    </ListItem>
  );
};
