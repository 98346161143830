import { parseGeneralWorkflowName } from "@/components/common/ProcessList/details/NodeChangeDetails/ImpactedResourcesSection/utils";
import { buildKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { OpenResourceDrawerParams } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/hooks/scaleDownImpactHooksTypes";
import { Addon, Entity } from "@/generated/addonsApi";
import {
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
  WORKFLOW_RUN_PARAM_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export function useOpenWorkflowDrawer(): (
  params: OpenResourceDrawerParams
) => void {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  return (params: OpenResourceDrawerParams) => {
    const { komodorUid, urlTimeWindow, urlTimeframe } = params;
    const {
      name,
      kind,
      cluster,
      namespace = "",
    } = parseKomodorUid(komodorUid) ?? {};
    if (!cluster || !name || !kind) {
      return;
    }
    const {
      dag = "",
      engine = "",
      run = "",
    } = parseGeneralWorkflowName(name) ?? {};
    const workflowId = buildKomodorWorkflowId({
      cluster,
      namespace,
      engine: engine,
      dagId: dag,
    });
    pushDrawer({
      drawerType: DrawerType.K8sAddonLiveStateDrawer,
      clusterName: cluster,
      name,
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      uid: workflowId,
      urlStates: {
        [EVENTS_TAB_TIMEFRAME]: urlTimeframe,
        [EVENTS_TAB_TIME_WINDOW]: urlTimeWindow,
        [WORKFLOW_RUN_PARAM_KEY]: run,
      },
    });
  };
}
