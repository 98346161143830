import { muiColors } from "@komodorio/design-system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Icon = styled(CheckCircleIcon)`
  && {
    color: ${muiColors.green[500]};
  }
`;

type MessageWithCheckProps = {
  title: string;
  message: string;
};

export const NoViolationMessage: React.FC<MessageWithCheckProps> = ({
  title,
  message,
}) => {
  return (
    <Container
      aria-label={
        nodeAutoscalersPageAriaLabels.autoscalerTab.noViolationsMessage
      }
    >
      <Typography variant="h5"> {title} </Typography>
      <MessageContainer>
        <Icon />
        <Typography variant="body2">{message}</Typography>
      </MessageContainer>
    </Container>
  );
};
