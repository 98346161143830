import React from "react";
import { XAxis } from "recharts";

import { getTickComponent } from "@/components/common/EventsChart/ticks";
import {
  GraphValuesRange,
  XAxisConfig,
  XAxisDataType,
} from "@/components/Metrics/graphs/graphTypes";

export type TimedData = {
  time: number;
};

export type TimeValueData<T> = TimedData & {
  value: T;
};

export function getTimeTickComponent(
  startDateTime: number,
  endDateTime: number
): React.FC {
  return getTickComponent(new Date(startDateTime), new Date(endDateTime));
}

type TickConfig = {
  numOfTicks?: number;
};

export const evenlyDistributedTicks = (
  range: GraphValuesRange,
  config?: TickConfig
): number[] => {
  const { numOfTicks = 5 } = config || {};
  const rangeLength = range.to - range.from;
  const tickInterval = rangeLength / (numOfTicks - 1);
  const ticks = Array.from(
    { length: numOfTicks },
    (_, i) => range.from + i * tickInterval
  );
  return ticks;
};

type TimedXAxisCalculationConfig = {
  predefinedStartTime?: number;
  numOfTicks?: number;
};

export function calculateTimeXAxis(
  timedData: TimedData[],
  config?: TimedXAxisCalculationConfig
): XAxisConfig {
  const dataPointTimes = timedData.map((dataPoint) => dataPoint.time);
  const range = {
    from: config?.predefinedStartTime ?? Math.min(...dataPointTimes),
    to: Math.max(...dataPointTimes),
  };
  const TickComponent = getTimeTickComponent(range.from, range.to);
  const ticks = evenlyDistributedTicks(range, {
    numOfTicks: config?.numOfTicks,
  });
  return {
    dataKey: "time",
    tickComponent: <TickComponent />,
    ticks,
    range,
    dataType: XAxisDataType.number,
  };
}

export function convertXAxisConfigToXAxisProps(
  xAxisConfig: XAxisConfig
): React.ComponentProps<typeof XAxis> {
  return {
    dataKey: xAxisConfig.dataKey,
    tick: xAxisConfig.tickComponent,
    ticks: xAxisConfig.ticks,
    domain: [
      xAxisConfig.range?.from ?? "dataMin",
      xAxisConfig.range?.to ?? "dataMax",
    ],
  };
}

export function populateMissingTimePoint<T = number>(
  data: TimeValueData<T>[][],
  defaultValue: T
): TimeValueData<T>[][] {
  const allTimes = new Set<number>();
  for (const dataPoints of data) {
    for (const dataPoint of dataPoints) {
      allTimes.add(dataPoint.time);
    }
  }

  return data.map((dataPoints) => {
    const dataPointsSet = new Set(
      dataPoints.map((dataPoint) => dataPoint.time)
    );
    const missingTimes = Array.from(allTimes).filter(
      (time) => !dataPointsSet.has(time)
    );
    const sortedDataPoints = [
      ...dataPoints,
      ...missingTimes.map((time) => ({ time, value: defaultValue })),
    ].sort((a, b) => a.time - b.time);
    return sortedDataPoints;
  });
}
