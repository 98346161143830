import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import React from "react";
import Typography from "@mui/material/Typography";

import { useDeleteWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useDeleteWorkspace";
import {
  useNavigateToWorkspace,
  useWorkspaces,
} from "@/components/workspaces/WorkspacesTopBar/hooks";

interface Props {
  open: boolean;
  onClose: () => void;
  workspaceId: string;
}

export const DeleteScopedWorkspaceDialog: React.FC<Props> = ({
  open,
  onClose,
  workspaceId,
}) => {
  const { currentWorkspaceId } = useWorkspaces();
  const navigateToWorkspace = useNavigateToWorkspace();
  const {
    mutateAsync: deleteWorkspace,
    isIdle,
    isError,
  } = useDeleteWorkspace();

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await deleteWorkspace(
        { id: workspaceId },
        {
          onSettled: () => {
            onClose();
          },
          onSuccess: () => {
            if (currentWorkspaceId === workspaceId && !isError) {
              navigateToWorkspace(undefined);
            }
          },
        }
      );
    }

    onClose();
  };

  return (
    open && (
      <ConfirmationDialog
        title="Delete Workspace"
        okText="Delete"
        okDisabled={!isIdle}
        cancelText="Cancel"
        type="error"
        onClose={handleClose}
        content={
          <Typography variant="body2">
            Are you sure you would like to remove this workspace?
          </Typography>
        }
      />
    )
  );
};
