import React, { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { useResourceByData } from "@/components/ResourceView/useResourceByData";
import { AddonRelatedResource } from "@/components/k8sAddons/types";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";

const StyleLink = styled(Typography).attrs({
  variant: "body2",
  color: "primary",
})`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const { resourceLink: resourceLinkAriaLabel } =
  certificatesPageAriaLabels.drawer;

type ResourceLinkProps = {
  relatedResource: AddonRelatedResource;
};

/** show link to resource drawer only if resource was fetched successfully */
export const ResourceLink: React.FC<ResourceLinkProps> = ({
  relatedResource,
}) => {
  const hasResourceResponse = useRef<boolean>(false);
  const fetchResourceParams = useMemo(() => {
    const {
      kind = "",
      name = "",
      cluster = "",
      namespace = "",
    } = parseKomodorUid(relatedResource.komodorUid) ?? {};

    return {
      cluster,
      namespace,
      resourceType: kind,
      resourceName: name,
      buildPreloadResource: true,
      additionalData: {
        isCustomResource: relatedResource.type === "CustomResource",
      },
    };
  }, [relatedResource]);

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onResourceClick = useCallback(() => {
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      ...fetchResourceParams,
    });
  }, [fetchResourceParams, pushDrawer]);

  const disableAtmRequest = hasResourceResponse.current;
  const { resource, failureMessage } = useResourceByData({
    ...fetchResourceParams,
    // if cluster is undefined, the request will be disabled
    cluster: disableAtmRequest ? "" : fetchResourceParams.cluster,
    fetchFromDb: false,
  });

  useEffect(() => {
    hasResourceResponse.current = !!resource || !!failureMessage;
  }, [failureMessage, resource]);

  if (!resource) {
    return (
      <Typography variant={"body2"}>
        {fetchResourceParams.resourceName}
      </Typography>
    );
  }

  return (
    <StyleLink
      onClick={() => onResourceClick()}
      aria-label={resourceLinkAriaLabel}
    >
      {fetchResourceParams.resourceName}
    </StyleLink>
  );
};
