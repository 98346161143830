import { ImpactfulEventsQuery } from "../../../generated/graphql";
import { EventsWindow } from "../EventsPagination/EventsPagination";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupDeployEvents } from "./deployEvent/DeployEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupKomodorAlertEvents } from "./KomodorAlertEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupPagerDutyEvents } from "./PagerDutyEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupSentryEvents } from "./SentryEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupTopologyEvents } from "./TopologyEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupSecrets } from "./secret/SecretEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupConfigMapEvents } from "./configMap/ConfigMapEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupWorkflowIssuesEvents } from "./workflowIssues/WorkflowIssueEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupJobEvents } from "./jobEvent/JobEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupLimitRangeEvents } from "./commonEventGroup/LimitRangeEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupActionEvents } from "./actionsEvent/ActionsEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupNetworkPolicyEvents } from "./commonEventGroup/NetworkPolicyEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupServiceResourceEvents } from "./commonEventGroup/ServiceResourceEvents/ServiceResourceEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupIngressEvents } from "./commonEventGroup/IngressEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupHpaEvents } from "./commonEventGroup/HpaEvents/HpaEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupResourceQuotaEvents } from "./commonEventGroup/ResourceQuotaEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupPdbEvents } from "./commonEventGroup/PdbEvents/PdbEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupNodeChangeEvents } from "./nodeEvent/NodeChangeGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupCustomEvents } from "./customEvent/CustomEventGroup";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupAgentDowntimeEvents } from "./agentDowntimeEvent/AgentDowntimeEventGroup";
import { groupGroupedPodEvents } from "./groupedPodEvent/PodPhaseGroup";

import EventGroup from ".";

import { Deploy } from "@/generated/monitorsApi/api";

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export function pushToMap<T>(map: Map<string, T[]>, key: string, e: T): void {
  (map.get(key) ?? map.set(key, []).get(key))?.push(e);
}

const nonEmptyString = (sid: unknown): sid is string =>
  typeof sid === "string" && sid.length > 0;

const getServices = (e: { services?: unknown }): string[] =>
  Array.isArray(e.services) ? e.services.filter(nonEmptyString) : [];

export const mapAndSort = <
  T extends { services?: unknown },
  U extends { eventTime: Date }
>(
  events: T[],
  fn: (e: T, serviceId: string) => U
): U[] =>
  events
    .flatMap((e) => getServices(e).map((serviceId) => fn(e, serviceId)))
    .sort((e1, e2) => e1.eventTime.getTime() - e2.eventTime.getTime());

export function groupEventsByKey<T>(
  events: T[],
  getKey: (e: T) => string
): T[][] {
  /**
   * Group events if they have the same key .
   * eg: `[e1:keyA, e2:keyB, e3:keyA] -> [[e1, e3], [e2]]`
   */
  const map = new Map<string, T[]>();
  events.forEach((e) => pushToMap(map, getKey(e), e));
  return Array.from(map.values());
}

export function groupAdjacentByKey<T>(
  events: T[],
  getKey: (e: T) => string
): T[][] {
  /**
   * Group events if they have the same key AND the are next to each other in the array.
   * eg: `[e1:keyA, e2:keyA, e3:keyB, e4:keyA] -> [[e1, e2], [e3], [e4]]`
   */
  let lastKey = "";
  const events_groups = events.reduce<T[][]>(
    // [CU-86c1gn74n] fix max-params
    // eslint-disable-next-line max-params
    (groups: T[][], e: T, index: number) => {
      const key = getKey(e);
      if (key === lastKey) {
        groups[groups.length - 1].push(e);
      } else {
        groups.push([e]);
        lastKey = key;
      }
      return groups;
    },
    []
  );
  return events_groups;
}

export const withServices = <T extends { services?: unknown }>(
  events: T[],
  serviceId: string
): T[] =>
  events.map((e) => {
    return Array.isArray(e?.services) && (e?.services?.length ?? 0) > 0
      ? e
      : { ...e, services: [serviceId] };
  });

const flatImpactfulEvents = <T>(
  eventsWindows: EventsWindow[],
  fn: (q: ImpactfulEventsQuery) => T[]
) =>
  eventsWindows.flatMap((eventsWindow) =>
    Object.keys(eventsWindow.impactfulEvents).length
      ? fn(eventsWindow.impactfulEvents)
      : []
  );

const groupEvents = (
  eventsWindows: EventsWindow[],
  serviceId: string,
  flags: Record<string, unknown>
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): EventGroup[] => {
  const workflowIssuesGroup = groupWorkflowIssuesEvents(
    eventsWindows.flatMap((ew) => ew.workflowResults),
    serviceId
  );
  return [
    ...groupDeployEvents(
      flatImpactfulEvents(
        eventsWindows,
        (events) => events.event_deploy as Deploy[] //TODO: migrate all deploy events to use monitors api deploys endpoint
      ),
      flags
    ),
    ...groupKomodorAlertEvents(
      flatImpactfulEvents(
        eventsWindows,
        (events) => events.event_komodor_alert
      ),
      serviceId
    ),
    ...groupPagerDutyEvents(
      flatImpactfulEvents(eventsWindows, (events) => events.event_pagerduty),
      serviceId
    ),
    ...groupSentryEvents(
      flatImpactfulEvents(eventsWindows, (events) => events.event_sentry_issue),
      serviceId
    ),
    ...groupTopologyEvents(
      flatImpactfulEvents(eventsWindows, (events) => events.event_topology)
    ),
    ...groupSecrets(
      flatImpactfulEvents(eventsWindows, (events) => events.event_secret),
      serviceId,
      flags
    ),
    ...groupConfigMapEvents(
      serviceId,
      eventsWindows.flatMap((ew) => ew.configMapEvents)
    ),
    ...workflowIssuesGroup,
    ...groupJobEvents(eventsWindows.flatMap((ew) => ew?.jobEvents || [])),
    ...groupLimitRangeEvents(
      flatImpactfulEvents(eventsWindows, (events) => events.event_limit_ranges),
      serviceId
    ),
    ...groupActionEvents(
      flatImpactfulEvents(eventsWindows, (events) => events.event_actions)
    ),
    ...groupNetworkPolicyEvents(
      flatImpactfulEvents(
        eventsWindows,
        (events) => events.event_network_policies
      ),
      serviceId
    ),
    ...groupServiceResourceEvents(
      flatImpactfulEvents(
        eventsWindows,
        (events) => events.event_kubernetes_service_resource
      ),
      serviceId
    ),
    ...groupIngressEvents(
      flatImpactfulEvents(eventsWindows, (events) => events.event_ingress),
      serviceId
    ),
    ...groupHpaEvents(
      eventsWindows.flatMap((ew) => ew.hpaEvents),
      serviceId
    ),
    ...groupResourceQuotaEvents(
      flatImpactfulEvents(
        eventsWindows,
        (events) => events.event_resource_quota
      ),
      serviceId
    ),
    ...groupPdbEvents(
      eventsWindows.flatMap((ew) => ew.pdbEvents),
      serviceId
    ),
    ...groupAgentDowntimeEvents(
      eventsWindows.flatMap((ew) => ew?.agentDowntimeEvents || []),
      serviceId
    ),
    ...groupNodeChangeEvents(
      eventsWindows.flatMap((ew) => ew.nodeCreatedEvents),
      serviceId
    ),
    ...groupNodeChangeEvents(
      eventsWindows.flatMap((ew) => ew.nodeTerminatedEvents),
      serviceId
    ),
    ...groupCustomEvents(
      eventsWindows.flatMap((ew) => ew.customEvents),
      serviceId
    ),
    ...groupGroupedPodEvents(
      eventsWindows.flatMap((ew) => ew.groupedPodEvents),
      serviceId
    ),
  ];
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default groupEvents;
