import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useUpdateAccountFeatures } from "@/shared/hooks/accounts-service/client/features/useUpdateFeatures";
import { KlaudiaAiConsentForm } from "@/components/Settings/General/BetaFeatures/KlaudiaAi/KlaudiaAiConsentForm";
import { AiBanner } from "@/components/AiInvestigation/AiBanner/AiBanner";
import { StyledButton } from "@/components/AiInvestigation/AiBanner/styles";
import { AskAdminTooltip } from "@/components/AiInvestigation/AiBanner/AskAdminTooltip";

interface EnableAiBannerProps {
  title: string;
}

export const EnableAiBanner: React.FC<EnableAiBannerProps> = ({ title }) => {
  const [isEnableModalOpen, setIsEnableModalOpen] = useState(false);
  const { canManageFeatures } = useHasPermissions();
  const { data: features, refresh: refreshFeatures } = useAccountFeatures();
  const klaudiaEnabled = features.klaudiaAi;

  const {
    isLoading: isUpdatingAccountFeatures,
    data: accountFeaturesData,
    mutate: updateAccountFeatures,
  } = useUpdateAccountFeatures({
    features: {
      ...features,
      ...{ klaudiaAi: !klaudiaEnabled as unknown as object },
    },
  });
  const onEnableModalClose = useCallback(() => {
    setIsEnableModalOpen(false);
  }, []);

  const onEnableModalButtonClick = useCallback(() => {
    setIsEnableModalOpen(true);
  }, []);

  const onEnableClick = useCallback(() => {
    updateAccountFeatures();
    setIsEnableModalOpen(false);
  }, [updateAccountFeatures]);

  useEffect(() => {
    if (accountFeaturesData) {
      setIsEnableModalOpen(false);
      refreshFeatures();
    }
  }, [accountFeaturesData, refreshFeatures]);
  return (
    <>
      <AiBanner
        title={title}
        rightHeader={
          canManageFeatures ? (
            <>
              <Typography variant={"body2"}>
                Enable this feature for your account
              </Typography>
              <StyledButton
                variant={"contained"}
                size={"medium"}
                onClick={onEnableModalButtonClick}
              >
                Enable now
              </StyledButton>
            </>
          ) : (
            <AskAdminTooltip placement={"top"}>
              <span>
                <StyledButton
                  variant={"outlined"}
                  size={"medium"}
                  disabled
                  data-testid={"disabled-analyze-now"}
                >
                  Analyze now
                </StyledButton>
              </span>
            </AskAdminTooltip>
          )
        }
      />
      <Dialog open={isEnableModalOpen} onClose={onEnableModalClose}>
        <KlaudiaAiConsentForm
          isLoading={isUpdatingAccountFeatures}
          onClose={onEnableModalClose}
          onEnable={onEnableClick}
        />
      </Dialog>
    </>
  );
};
