import React, { useMemo } from "react";
import styled from "styled-components";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { muiColors } from "@komodorio/design-system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { appNotificationsAriaLabels } from "@/shared/components/AppNotificationsCenter/appNotificationsAriaLabels";
import { NotificationStatus } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import {
  APP_NOTIFICATIONS_TITLE,
  statusToIcon,
} from "@/shared/components/AppNotificationsCenter/appNotificationsConstants";
import { useNotificationsStatusSummary } from "@/shared/components/AppNotificationsCenter/appNotificationsHooks";

const Header = styled.div`
  padding: 0 12px;
  align-items: center;
  border-bottom: 1px solid ${muiColors.gray[200]};
  position: relative;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledKeyboardArrowIcon = styled(KeyboardArrowDownIcon)<{
  $open: boolean;
}>`
  && {
    width: 16px;
    height: 16px;
    transition: transform 0.2s;
    transform: ${({ $open }) => ($open ? "rotate(0deg)" : "rotate(180deg)")};
    pointer-events: none;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClickArea = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`;

type AppNotificationsHeaderProps = {
  notificationsNum: number;
  open: boolean;
  setIsOpen: (open: boolean) => void;
  onClose: () => void;
};

const { container: containerAriaLabel } = appNotificationsAriaLabels.header;

export const AppNotificationsHeader: React.FC<AppNotificationsHeaderProps> = ({
  notificationsNum,
  setIsOpen,
  open,
  onClose,
}) => {
  const { loading, error } = useNotificationsStatusSummary();
  const status = useMemo(() => {
    if (loading > 0) {
      return statusToIcon[NotificationStatus.Loading];
    }
    if (error > 0) {
      return statusToIcon[NotificationStatus.Error];
    }
    return statusToIcon[NotificationStatus.Success];
  }, [error, loading]);

  return (
    <Header aria-label={containerAriaLabel}>
      <ClickArea onClick={() => setIsOpen(!open)} />
      <FlexContainer>
        <InfoContainer>
          <ManageHistoryIcon color={"action"} />
          <Typography variant={"h5"}>{APP_NOTIFICATIONS_TITLE}</Typography>
          <Typography
            variant={"h5"}
            color={"text.secondary"}
          >{`(${notificationsNum})`}</Typography>
          {status}
        </InfoContainer>
        <ActionsContainer>
          <StyledKeyboardArrowIcon $open={open} />
          <IconButton
            onClick={onClose}
            aria-label={appNotificationsAriaLabels.header.close}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
        </ActionsContainer>
      </FlexContainer>
    </Header>
  );
};
