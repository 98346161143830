import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "@komodorio/design-system/deprecated";

import { StyledBlackContainer } from "../../../../../../../../monitorsView/common/styles";
import {
  AvailabilityCheckType,
  CheckDetails,
  WorkflowRunWithResults,
} from "../../../../../../../../monitorsView/common/types";
import {
  ContainerLog,
  PREVIOUS_SUFFIX,
  WorkloadHealthyPodsOutput,
} from "../../../../../../../../monitorsView/workflowRunView/checks/availability/types";
import useWorkflowsChecks from "../../../../../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { padFollowingZerosToLogTime } from "../../../../../../../LogsViewer/PodLogsBar/PodLogsActions";
import WorkflowIssueEventGroup from "../../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import {
  useOpinionatedSummary,
  usePodsSummary,
} from "../../../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/hooks";

export const SUSPICIOUS_LOGS_TOOLTIP = "suspicious-logs-tooltip";

const FixedHeightBlackContainer = styled(StyledBlackContainer)`
  max-height: 9rem;
  overflow: auto;
`;

export const SuspiciousLogsTooltip: React.FC<{
  wfRun: WorkflowRunWithResults;
  eventGroup: WorkflowIssueEventGroup;
}> = ({ wfRun, eventGroup }) => {
  const [container, setContainer] = useState<ContainerLog | null>(null);
  const [containerIndex, setContainerIndex] = useState(0);

  const checkDetails: CheckDetails = useMemo(
    () => ({
      workflowType: eventGroup.workflowType,
      results: wfRun?.results ?? [],
      eventTime: wfRun?.eventTime,
      closedAt: wfRun?.closedAt,
      services: wfRun?.services,
    }),
    [
      eventGroup.workflowType,
      wfRun?.closedAt,
      wfRun?.eventTime,
      wfRun?.results,
      wfRun?.services,
    ]
  );
  const allChecks = useWorkflowsChecks(checkDetails);

  const currentAvailabilityChecks = useMemo(() => {
    return allChecks.filter((c) => !c.type.includes(PREVIOUS_SUFFIX));
  }, [allChecks]);
  const previousAvailabilityChecks = useMemo(() => {
    return allChecks.filter((c) => c.type.includes(PREVIOUS_SUFFIX));
  }, [allChecks]);

  const [showPreviousOccurrence] = useState(false);

  const checksToShow = useMemo(
    () =>
      showPreviousOccurrence
        ? previousAvailabilityChecks
        : currentAvailabilityChecks,
    [
      currentAvailabilityChecks,
      previousAvailabilityChecks,
      showPreviousOccurrence,
    ]
  );

  const podsCheckOutputToShow = useMemo(() => {
    const previousPodsHealthyCheck = checksToShow.find(
      (c) => c.type === AvailabilityCheckType.WorkloadPodsHealthyPrevious
    );
    if (showPreviousOccurrence && previousPodsHealthyCheck) {
      return previousPodsHealthyCheck?.output
        ? (previousPodsHealthyCheck?.output as WorkloadHealthyPodsOutput)
        : undefined;
    }
    const currentPodsHealthyCheck = checksToShow.find(
      (c) => c.type === AvailabilityCheckType.WorkloadPodsHealthy
    );
    return currentPodsHealthyCheck?.output
      ? (currentPodsHealthyCheck?.output as WorkloadHealthyPodsOutput)
      : undefined;
  }, [checksToShow, showPreviousOccurrence]);

  const opinionatedSummary = useOpinionatedSummary(podsCheckOutputToShow);
  const { logsSummary, data, fetching } = usePodsSummary(
    opinionatedSummary,
    container
  );

  const logs = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pod_logs.slice(0, 3);
  }, [data]);

  useEffect(() => {
    if (
      (!container || !logs.length) &&
      containerIndex < logsSummary?.length &&
      !fetching
    ) {
      if (container !== null) {
        setContainerIndex(containerIndex + 1);
      }

      setContainer(logsSummary?.[containerIndex]);
    }
  }, [container, containerIndex, fetching, logs.length, logsSummary]);

  return (
    <Tooltip id={SUSPICIOUS_LOGS_TOOLTIP} variant="light">
      <FixedHeightBlackContainer disableTextWrapping>
        {logs.map(({ logTime, message }) => (
          <>
            {padFollowingZerosToLogTime(logTime)}
            {"  "}
            <span>{message}</span>
            <br />
          </>
        ))}
      </FixedHeightBlackContainer>
    </Tooltip>
  );
};
