import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useDebounce } from "use-debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { useGetRbacClusterStatuses } from "@/shared/hooks/auth-service/client/rbacClusterStatus/useGetRbacClusterStatuses";
import { RbacClusterStatusDataTable } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/RbacClusterStatusDataTable";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useUpdateRbacClusterStatusApiServerUrl } from "@/shared/hooks/auth-service/client/rbacClusterStatus/useUpdateRbacClusterStatusApiServerUrl";
import {
  ApiServerConfigClusterErrorResponse,
  ApiServerConfigClusterRequest,
} from "@/generated/auth";

const notificationDuration = 7000;
const notificationErrorMessage =
  "Failed to update the cluster API server URLs, please try again or contact our support.";
const notificationSuccessMessage = "Update successful!";

const ConfigureFormDialogActions: React.FC<{
  onClose: () => void;
  onSave: () => void;
  isLoading: boolean;
  disableSave?: boolean;
}> = ({ onClose, onSave, isLoading, disableSave }) => {
  return (
    <DialogActions>
      <Button
        variant={"outlined"}
        size={"large"}
        sx={{
          width: "80px",
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
      <LoadingButton
        variant={"contained"}
        size={"large"}
        loading={isLoading}
        onClick={onSave}
        aria-label={
          AriaLabels.SettingsPage.AccountProfile.BetaFeatures
            .RbacClusterSyncConfigureSaveButton
        }
        disabled={disableSave}
        sx={{
          width: "80px",
        }}
      >
        Save
      </LoadingButton>
    </DialogActions>
  );
};

export const ConfigureFormDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { accountId } = useUserMetadata();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [clustersThatFailedToUpdate, setClustersThatFailedToUpdate] = useState<
    ApiServerConfigClusterErrorResponse[]
  >([]); // clusterName -> error message

  const {
    mutateAsync: updateClusterUrlsAsync,
    error,
    isLoading,
  } = useUpdateRbacClusterStatusApiServerUrl();

  const [apiServerUrlFieldsValues, setApiServerUrlFieldsValues] = useState<{
    [key: string]: string;
  }>({});

  const {
    data: rbacClusterStatuses,
    isLoading: isLoadingClusterStatuses,
    refetch: refetchClusterStatuses,
  } = useGetRbacClusterStatuses();

  const [onApiServerUrlChange] = useDebounce(
    (clusterName: string, newValue: string) => {
      setApiServerUrlFieldsValues({
        ...apiServerUrlFieldsValues,
        [clusterName]: newValue,
      });
    },
    300
  );

  useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
    }
  }, [error]);

  const onCloseSnackbar = useCallback(() => {
    setSnackbarOpen(false);
    setSnackbarMessage(notificationSuccessMessage); // default
  }, [setSnackbarOpen]);

  const snackbarAction = useMemo(() => {
    return (
      <IconButton
        size={"small"}
        aria-label={"close"}
        onClick={onCloseSnackbar}
        color={"inherit"}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  }, [onCloseSnackbar]);

  const onSave = async () => {
    const updateRequests: ApiServerConfigClusterRequest[] = Object.entries(
      apiServerUrlFieldsValues
    )
      .filter(([_, value]) => value !== "") // Empty server URLs are not modified, we currently do not support removing a server URL via UI
      .map(([clusterName, apiServerUrl]) => {
        return {
          clusterName,
          apiServerUrl,
        };
      });

    try {
      const resp = await updateClusterUrlsAsync({
        apiServerConfigUpdateRequest: {
          accountId: accountId,
          data: updateRequests,
        },
      });
      await refetchClusterStatuses();
      setClustersThatFailedToUpdate(resp.errors ?? []);
    } catch (err) {
      setUpdateError(notificationErrorMessage);
    }
    setSnackbarOpen(true);
  };

  const pushToastMessage = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const hasAnyError = useMemo(() => {
    return !!updateError || !!error || clustersThatFailedToUpdate.length > 0;
  }, [updateError, error, clustersThatFailedToUpdate]);

  useEffect(() => {
    if (hasAnyError) {
      setSnackbarMessage(notificationErrorMessage);
    } else {
      setSnackbarMessage(notificationSuccessMessage);
    }
  }, [hasAnyError]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          maxHeight: "70%",
          height: "70%",
        },
      }}
    >
      <DialogTitle>
        <Typography variant={"h3"}>Configure Clusters for RBAC Sync</Typography>
      </DialogTitle>
      <DialogContent>
        <RbacClusterStatusDataTable
          isLoading={isLoadingClusterStatuses}
          data={rbacClusterStatuses}
          onApiServerUrlChange={onApiServerUrlChange}
          setHasValidationError={setHasValidationError}
          clustersThatFailedToUpdate={clustersThatFailedToUpdate}
          pushToastMessage={pushToastMessage}
        />
      </DialogContent>
      <ConfigureFormDialogActions
        onClose={onClose}
        onSave={onSave}
        isLoading={isLoading}
        disableSave={hasValidationError}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={notificationDuration}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={snackbarAction}
      >
        <Alert
          onClose={onCloseSnackbar}
          severity={hasAnyError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
