import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import {
  BuildTextHighlights,
  TextHighlightType,
  getMainLineHighlight,
} from "../../HighlightedText";
import {
  formatLogTime,
  padFollowingZerosToLogTime,
} from "../PodLogsBar/PodLogsActions";
import { LogEvent } from "../logsTypes";

import LogLine from "./LogLine";
import { numLength, padLeadingZeros } from "./utils";
import { LogsDivider } from "./LogsDivider";

import { PodLogsDirection } from "@/shared/hooks/podLogs/store/types";
import { useLogsViewerInLocalStorage } from "@/components/common/LogsViewer/localStorage/useLogsViewerInLocalStorage";

const moreLogsSeparator = "---------------------\n";

const LogText = styled(Typography).attrs({
  variant: "code1",
  color: "white",
  lineHeight: "130%",
})``;

const LogContainer = styled.span<{ disableTextWrapping: boolean }>`
  ${({ disableTextWrapping }) =>
    disableTextWrapping ? "white-space: nowrap;" : ""}
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export type ListLogLine = {
  logTime: string;
  parts: TextHighlightType[];
  matchesInLine: number;
  isEvent: boolean | undefined;
};

export interface ListItemProps {
  logs: ListLogLine[];
  newLogsIndicator: number;
  eventDictionary: Record<number, LogEvent>;
  containerWidth: number;
}

export const LogsListLine: React.FC<
  ListItemProps & { style: React.CSSProperties; index: number } & {
    direction: PodLogsDirection;
  }
> = ({
  logs: fullyHighlightedLines,
  eventDictionary,
  newLogsIndicator,
  style,
  index,
  containerWidth,
  direction,
}) => {
  const [{ wrapText, showLineNumber, showTimestamp }] =
    useLogsViewerInLocalStorage();

  if (index >= fullyHighlightedLines.length) {
    return null;
  }

  const { logTime, parts, isEvent } = fullyHighlightedLines[index];
  const showIndicator = index !== 0 && index === newLogsIndicator;
  const mainHighlightColor = getMainLineHighlight(parts);
  const lineNumber = padLeadingZeros(
    direction === PodLogsDirection.EarliestFirst
      ? index
      : fullyHighlightedLines.length - index,
    numLength(fullyHighlightedLines.length)
  );
  const logEvent = eventDictionary[index];

  return (
    <Container key={index} style={style}>
      {logEvent && (
        <LogLine
          showTimestamp={showTimestamp}
          showLineNumber={showLineNumber}
          mainHighlightColor={mainHighlightColor}
          lineNumber={null}
          logTime={formatLogTime(logEvent.eventTime)}
          enableTextWrapping={wrapText}
          containerWidth={containerWidth}
        >
          <LogsDivider message={logEvent.eventMessage}></LogsDivider>
        </LogLine>
      )}

      {isEvent ? (
        <LogLine
          showTimestamp={showTimestamp}
          showLineNumber={showLineNumber}
          mainHighlightColor={mainHighlightColor}
          lineNumber={null}
          logTime={padFollowingZerosToLogTime(logTime)}
          enableTextWrapping={wrapText}
          containerWidth={containerWidth}
        >
          <LogsDivider
            message={parts.map((p) => p.value).join(" ")}
          ></LogsDivider>
        </LogLine>
      ) : (
        <LogText>
          {showIndicator && moreLogsSeparator}
          <LogLine
            showTimestamp={showTimestamp}
            showLineNumber={showLineNumber}
            mainHighlightColor={mainHighlightColor}
            lineNumber={lineNumber}
            logTime={padFollowingZerosToLogTime(logTime)}
            enableTextWrapping={wrapText}
            containerWidth={containerWidth}
          >
            <LogContainer disableTextWrapping={!wrapText}>
              <BuildTextHighlights
                parts={parts}
                overrideHighlightColor={mainHighlightColor}
              />
            </LogContainer>
          </LogLine>
        </LogText>
      )}
    </Container>
  );
};
