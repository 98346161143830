import { RuleGroupType } from "react-querybuilder";

import { ResourceScope } from "@/generated/workspacesApi";
import {
  handleClusters,
  handleClustersPatterns,
  handleNamespaces,
  handleNamespacesPatterns,
  handleSelectorsPatterns,
} from "@/pages/organization-settings/access-management/PoliciesPage/handlers";
import { assumeScopeFromStatement } from "@/pages/organization-settings/access-management/PoliciesPage/utils/assumeScopeFromStatement";

export const resourceScopeToQuery = (
  resourceScope: ResourceScope
): RuleGroupType => {
  const scope = assumeScopeFromStatement({ resourcesScope: resourceScope }, []);

  const clustersRules = [
    handleClusters(resourceScope),
    handleClustersPatterns(resourceScope),
  ];

  // A resource scope must have some kind of clusters + namespaces or clusters + selectors
  // but, we can define a "cluster" scope, which is a combination of clusters and namespacesPatterns
  // of { include: '*', exclude: '' }
  // So if we have a cluster scope, we can ignore the rest of the rules
  if (scope === "cluster") {
    return {
      combinator: "and",
      rules: clustersRules.filter(Boolean) as RuleGroupType[],
    };
  }

  const rules = [
    ...clustersRules,
    handleNamespaces(resourceScope),
    handleNamespacesPatterns(resourceScope),
    handleSelectorsPatterns(resourceScope),
  ].filter(Boolean) as RuleGroupType[];
  return {
    combinator: "and",
    rules,
  };
};
