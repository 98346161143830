import { useMemo } from "react";

import { Uid } from "../../../../shared/utils/generateUid";
import { buildKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";

export const useBuildKomodorUidsFromPodUids = (podUids: Uid[] | null) => {
  return useMemo(() => {
    if (!podUids) return [];
    return podUids.map((podUid) => {
      return buildKomodorUid({
        kind: "Pod",
        clusterName: podUid.cluster,
        namespace: podUid.namespace,
        resourceName: podUid.name,
      });
    });
  }, [podUids]);
};
