import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacPoliciesApiApiV1RbacPoliciesIdGetRequest,
  RbacPolicy,
  apiV1RbacPoliciesGetUrl,
} from "../../../../../generated/auth";

export const RBAC_POLICY_BY_ID_PATH = "/rbac-policies/:id";

const fetchRbacPolicyById = async (
  apiClient: AxiosInstance,
  params: RbacPoliciesApiApiV1RbacPoliciesIdGetRequest
): Promise<RbacPolicy[]> => {
  const { data } = await apiClient.get(
    apiV1RbacPoliciesGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacPolicyById = (
  params: RbacPoliciesApiApiV1RbacPoliciesIdGetRequest,
  options?: {
    enabled?: boolean;
    refetchOnMount?: boolean | "always";
  }
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_POLICY_BY_ID_PATH, params],
    () => fetchRbacPolicyById(apiClient, params),
    options
  );
};
