import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import _ from "lodash";

import {
  extractServiceCluster,
  extractServiceName,
} from "../../../shared/utils/serviceHelpers";
import { useOnDelayedHover } from "../../../shared/hooks/useOnDelayedHover";
import { useDateFormatter } from "../../../shared/hooks/useDateFormatter";
import DeployEventGroup from "../EventGroup/deployEvent/DeployEventGroup";
import EventGroup from "../EventGroup";
import { KubernetesServiceTypeEnum } from "../../../shared/types/ServiceVertex";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import TextButton from "../TextButton";
import { WorkflowConfigType } from "../../monitorsView/common/types";
import { WORKFLOW_CONFIG_TYPES } from "../EventGroup/workflowIssues/constants";
import { DrawerType } from "../../../shared/store/drawersStackStore/types";
import { useDrawersStackStore } from "../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../shared/store/drawersStackStore/drawersStackSelectors";
import {
  EVENTS_TAB_TIMEFRAME,
  EVENTS_TAB_TIME_WINDOW,
} from "../../../shared/config/urlSearchParamsKeys";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { parseTimeWindowDataToUrlData } from "../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";

import {
  getDefaultColumnTypes,
  ProcessListColumnDefinition,
} from "@/components/common/ProcessList/processListConstants";
import {
  EventsExtraDataMap,
  getCellForColumn,
} from "@/components/common/ProcessList/processListBuilder";

const BORDER_RADIUS = "0.25rem";
export const CROSS_SERVICE_TITLE = "Cross-service event";

type DataRowProps = { highlighted: boolean; selected: boolean };
export const DataRow = styled.tr<DataRowProps>`
  background-color: ${({ highlighted }) => (highlighted ? "#ebf3fc" : "white")};
  &.clickableDataRow {
    cursor: pointer;
    &:hover {
      background-color: #ebf3fc;
    }
  }
  > td {
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: ${({ selected }) => (selected ? "#007aff" : "transparent")};
    padding: 0.5rem;
  }
  > td:first-child {
    border-left-width: 1px;
    border-top-left-radius: ${BORDER_RADIUS};
    border-bottom-left-radius: ${BORDER_RADIUS};
  }
  > td:last-child {
    border-right-width: 1px;
    border-top-right-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
  }
`;

type RowProps = {
  eventGroup: EventGroup;
  service: ServiceInfo | undefined;
  timeWindow: TimeWindow;
  showServiceColumn: boolean;
  highlighted: boolean;
  selected: boolean;
  onClick?: (eventId: string | null) => void;
  onClickIntent: (eventGroup: EventGroup) => void;
  columns?: ProcessListColumnDefinition[];
  eventsExtraDataMap?: EventsExtraDataMap;
};

export const Row: React.FC<RowProps> = memo(
  ({
    eventGroup,
    service,
    timeWindow,
    showServiceColumn,
    highlighted,
    selected,
    onClick,
    onClickIntent,
    columns,
    eventsExtraDataMap,
  }) => {
    const columnsToRender = columns ?? getDefaultColumnTypes(showServiceColumn);
    const pushDrawer = useDrawersStackStore(pushDrawerSelector);
    const { format } = useDateFormatter({ timeZoneName: undefined });
    const onRowClick = useMemo(
      () => onClick && (() => onClick(selected ? null : eventGroup.id)),
      [onClick, selected, eventGroup.id]
    );

    const onHover = useCallback(() => {
      onClickIntent(eventGroup);
    }, [eventGroup, onClickIntent]);
    const { onMouseEnter, onMouseLeave } = useOnDelayedHover(onHover, 100);

    const serviceFullName = service
      ? `${service.k8sCluster}/${service.title}`
      : `${extractServiceCluster(eventGroup.serviceId)}/${extractServiceName(
          eventGroup.serviceId
        )}`;

    const isStandalonePodEvent =
      eventGroup.type === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Workflow];
    const serviceLink = useMemo(() => {
      const { urlTimeWindow, urlTimeframe } =
        parseTimeWindowDataToUrlData(timeWindow);
      return isStandalonePodEvent ? (
        ""
      ) : serviceFullName === "common.system/system-wide" ? (
        CROSS_SERVICE_TITLE
      ) : (
        <TextButton
          onClick={(e) => {
            e.stopPropagation();
            pushDrawer({
              drawerType: DrawerType.ResourceDrawerByServiceId,
              serviceId: eventGroup.serviceId,
              urlStates: {
                [EVENTS_TAB_TIMEFRAME]: urlTimeframe,
                [EVENTS_TAB_TIME_WINDOW]: urlTimeWindow,
              },
            });
          }}
        >
          {serviceFullName}
        </TextButton>
      );
    }, [
      timeWindow,
      isStandalonePodEvent,
      serviceFullName,
      pushDrawer,
      eventGroup.serviceId,
    ]);

    const isRolloutStarted =
      service?.kind === KubernetesServiceTypeEnum.Rollout &&
      eventGroup.status === "started";

    const getRolloutExpectedPromotionTime = (
      eventGroup: EventGroup
    ): string | null => {
      let res = null;
      eventGroup.events.forEach((e) => {
        const blueGreen = _.get(e, "strategy.blueGreen", null);
        if (blueGreen) {
          const eventTime = new Date(eventGroup.startTime);
          const autoPromotionSeconds = blueGreen.autoPromotionSeconds ?? 0;
          eventTime.setSeconds(eventTime.getSeconds() + autoPromotionSeconds);
          res = "Expected promotion time:\n" + format(eventTime);
        }
      });
      return res;
    };

    const getDetails = () => {
      const expectedPromotionTime = getRolloutExpectedPromotionTime(eventGroup);
      if (isRolloutStarted && expectedPromotionTime) {
        return expectedPromotionTime;
      }
      if (
        eventGroup instanceof DeployEventGroup &&
        eventGroup.generation === 1
      ) {
        return "First Generation";
      }
      return eventGroup.details;
    };

    const renderedColumns = columnsToRender.map((columnDefinition) =>
      getCellForColumn({
        cell: { columnDefinition, eventGroup, service, serviceLink },
        config: {
          format,
          getDetails,
          shouldRenderArrow: !!onClick,
          eventsExtraDataMap,
        },
      })
    );
    return (
      <DataRow
        className={onClick ? "clickableDataRow" : ""}
        highlighted={highlighted}
        selected={selected}
        onClick={onRowClick}
        data-e2e-selector={"tableRow"}
        data-e2e-selector-row-type={eventGroup.type}
        aria-label={`table row for ${eventGroup.type} with status ${eventGroup.status}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {renderedColumns}
      </DataRow>
    );
  }
);
