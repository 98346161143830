import React, { useMemo } from "react";
import styled from "styled-components";

import { GenericErrorMessage } from "@/shared/components/GenericErrorMessage";
import { ResourceNotFound } from "@/components/ResourceView/ResourceNotFound";
import { isNotFoundError } from "@/shared/utils/httpUtils";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ErrorMessageProps = {
  error: unknown;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const content = useMemo(() => {
    try {
      if (isNotFoundError(error)) {
        return <ResourceNotFound />;
      }
      return <GenericErrorMessage />;
    } catch (e) {
      return <GenericErrorMessage />;
    }
  }, [error]);

  return <Container>{content}</Container>;
};
