import { createContext } from "react";
import noop from "lodash/noop";

import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { RbacPolicyStatementsInner } from "@/generated/auth";

export type StatementContextProps = {
  scope: Scope;
  open?: boolean;
  statement?: RbacPolicyStatementsInner;
};

export type StatementContextState = StatementContextProps & {
  setScope: (scope: Scope) => void;
};

export const StatementDrawerContext = createContext<StatementContextState>({
  scope: Scope.cluster,
  setScope: noop,
  open: false,
  statement: undefined,
});
