import { KubernetesResource } from "./KubernetesResource";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesServiceAccountsResource: KubernetesResource = {
  NameInK8S: "serviceaccounts",
  Group: "access-control",
  NavBarName: "Service Accounts",
  PresentName: "Service Accounts",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: SupportedResourcesKinds.KubernetesServiceAccountsResource,
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Labels", hide: true },
    { name: "Annotations", hide: true },
  ],
  hasLabels: false,
};
