import pluralize from "pluralize";

type GetActionCompleteMessageParams = {
  actionType: string;
  resource: string;
  failuresCount: number;
  hasErrorInAction: boolean;
};
export const getActionCompleteMessage = ({
  actionType,
  resource,
  failuresCount,
  hasErrorInAction,
}: GetActionCompleteMessageParams) => {
  return hasErrorInAction
    ? `Batch ${actionType} completed - ${failuresCount} ${resource} ${actionType} ${pluralize(
        "requests",
        failuresCount
      )} failed.`
    : `Batch ${actionType} successfully completed`;
};
