import { TagsCell } from "@komodorio/design-system/komodor-ui";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { RbacRole } from "@/generated/auth";

const LABEL_PREFIX = "Roles";

const getSortedItems = (items: RbacRole[]) => {
  if (!items) {
    return [];
  }

  return items.sort(
    (a: RbacRole, b: RbacRole) => a.name.length - b.name.length
  );
};

export const getTagsCell = ({
  params,
  width,
}: {
  params: GridRenderCellParams;
  width: number;
}) => {
  const itemMapper = (role: RbacRole) => ({
    key: role.id,
    label: role.name,
  });

  return (
    <TagsCell
      items={getSortedItems(params.value as RbacRole[])}
      textPath="name"
      itemMapper={itemMapper}
      labelPrefix={LABEL_PREFIX}
      cellWidth={width}
    />
  );
};
