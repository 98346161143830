import React from "react";

import { LazyEditor } from "@/components/common/LazyEditor";
import { RbacPolicy } from "@/generated/auth";

export const ShowJSONDrawerContent = ({
  policy,
}: {
  policy: Partial<RbacPolicy>;
}) => {
  return (
    <LazyEditor
      width="100%"
      height="100%"
      mode="json"
      value={JSON.stringify(policy, null, 2)}
      readOnly
      style={{
        marginTop: "-1px",
      }}
    />
  );
};
