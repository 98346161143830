import { EP } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesEndpointResource = {
  NameInK8S: "endpoints",
  Group: "network",
  NavBarName: "Endpoints",
  PresentName: "Endpoints",
  Namespaced: true,
  UnnecessaryColumns: ["Ports", "AddressType"],
  Kind: SupportedResourcesKinds.KubernetesEndpointResource,
  Icon: EP,
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Endpoints" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  hasLabels: true,
};
