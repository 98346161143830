import React from "react";
import { TooltipWrapper } from "react-tooltip";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  DEMO_IMPACTED_SERVICES_TOOLTIP,
  ImpactedServicesTooltip,
} from "../Tooltips/ImpactedServicesTooltip";
import { DemoImpactedServicesData } from "../DemoInsights";
import { BlastDemoInsight, BoldText } from "../common";
import {
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
  RESOURCE_PREFIX,
  RESOURCE_TAB_POSTFIX,
} from "../../../../../../../../../shared/config/urlSearchParamsKeys";
import { ResourceTabName } from "../../../../../../../../ResourceView/resources";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

export const ImpactedServicesInsight: React.FC<{
  demoImpactedServicesData: DemoImpactedServicesData[];
}> = ({ demoImpactedServicesData }) => {
  const [, setEventId] = useStateInSearchParams(
    RESOURCE_PREFIX + EVENTS_TAB_PREFIX + EVENT_PARAM_KEY
  );

  const [, setSelectedTab] = useStateInSearchParams(
    `${RESOURCE_PREFIX}Deployment${RESOURCE_TAB_POSTFIX}`
  );
  return (
    <>
      <TooltipWrapper tooltipId={DEMO_IMPACTED_SERVICES_TOOLTIP}>
        <BlastDemoInsight
          onClick={() => {
            setEventId(null); // close the drawer
            setSelectedTab(ResourceTabName.Dependencies);
          }}
        >
          Blast radius: This issue impacted 3 other
          <BoldText> dependent services</BoldText>
        </BlastDemoInsight>
      </TooltipWrapper>
      <ImpactedServicesTooltip
        demoImpactedServicesData={demoImpactedServicesData}
      />
    </>
  );
};
