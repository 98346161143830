import React from "react";
import styled from "styled-components";

import { AppNotifications } from "@/shared/components/AppNotificationsCenter/AppNotifications";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { PodExecSessions } from "@/components/PodExec/PodExecSessions";
import { ZIndex } from "@/constants/zIndex";

const AppNotificationsContainer = styled.div`
  position: fixed;
  z-index: ${ZIndex.AboveMuiDrawer};
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
`;
export const AppNotificationsCenter: React.FC = () => {
  const { useAppNotifications } = useOverridableFlags();

  return (
    <AppNotificationsContainer>
      {!!useAppNotifications && <PodExecSessions />}
      <AppNotifications />
    </AppNotificationsContainer>
  );
};
