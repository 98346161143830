import { sub } from "date-fns";

import {
  DEFAULT_LOGS_TAIL_WHEN_USER_DOESNT_SPECIFY,
  MAX_RETRIES,
} from "./constants";

import { DurationOption } from "@/components/common/LogsViewer/localStorage/logsViewerLocalStorage";

export const isBailout = (retries: number | undefined) =>
  (retries || 0) >= MAX_RETRIES;

export const getSinceTime = (
  sinceNumber: number,
  sinceUnit: DurationOption
) => {
  switch (sinceUnit) {
    case "s": // seconds
      return sub(new Date(), { seconds: sinceNumber });
    case "m": // minutes
      return sub(new Date(), { minutes: sinceNumber });
    case "h": // hours
      return sub(new Date(), { hours: sinceNumber });
    default:
      return new Date();
  }
};

export const getTail = (tail: number, shouldUseUserTail: boolean) => {
  return shouldUseUserTail ? tail : DEFAULT_LOGS_TAIL_WHEN_USER_DOESNT_SPECIFY;
};
