import { ResourceData } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageTypes";
import { formatPercentage } from "@/shared/utils/formatPercentage";

export const getUsagePercentage = ({ usage, capacity }: ResourceData) =>
  (usage / capacity) * 100;

export const getAllocatedPercentage = ({ allocated, capacity }: ResourceData) =>
  (allocated / capacity) * 100;

export const formatResourcePercentage = (percent: number) =>
  formatPercentage(percent, {
    maximumFractionDigits: 0,
    minimumIntegerDigits: 1,
  });
