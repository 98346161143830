export enum ContentType {
  String = "String",
  Chips = "Chips",
  ButtonsList = "ButtonsList",
}

type StringContent = {
  type: ContentType.String;
  value: string;
};

type ChipsContent = {
  type: ContentType.Chips;
  value: string[];
};

type ButtonsListContent = {
  type: ContentType.ButtonsList;
  value: { content: string; onClick: () => void }[];
};

export type LabeledContent = StringContent | ChipsContent | ButtonsListContent;

export enum TooltipType {
  Info = "info",
  Warning = "warning",
  Error = "error",
}
