import {
  QueryBuilder,
  QueryBuilderProps,
} from "@komodorio/design-system/komodor-ui";
import { uniqBy } from "lodash";

import { useClusterField } from "@/shared/components/QueryBuilder/useClusterField";
import { useKeyField } from "@/shared/components/QueryBuilder/useKeyField";

export const ConnectedClustersKeysQB = (
  props: QueryBuilderProps & {
    isChangeable?: boolean;
    clustersInitialOptions?: string[];
  }
) => {
  const fieldsWithCluster = useClusterField({
    props,
    isChangeable: props.isChangable,
    initialOptions: props.clustersInitialOptions,
  });
  const fieldsWithKeys = useKeyField(props, props.isChangable);

  const uniqueFields = uniqBy(
    [...fieldsWithCluster, ...fieldsWithKeys],
    "name"
  );

  return <QueryBuilder {...props} fields={uniqueFields} />;
};
