// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import React, { Fragment, useMemo, useCallback } from "react";
import {
  findPath,
  isRuleGroupType,
  Path,
  RuleGroupProps,
  useRuleGroup,
  update,
  remove,
} from "react-querybuilder";
import Stack from "@mui/material/Stack";

import { CustomRule } from "./CustomRule";
import { ReadonlyBox } from "./ReadonlyBox";
import {
  getPredecessorSiblings,
  shouldShowGroupCombinator,
  getAllowedActions,
} from "./utils/ruleGroup";

export const CustomGroup = (props: RuleGroupProps) => {
  const fullGroupProps = { ...props, ...useRuleGroup(props) };

  const handleRemoveGroup = useCallback(() => {
    const newQuery = remove(
      fullGroupProps.schema.getQuery(),
      fullGroupProps.path
    );
    fullGroupProps.schema.dispatchQuery(newQuery);
  }, [fullGroupProps.path, fullGroupProps.schema]);

  const handleAddRuleExclusion = useCallback(
    (r) => {
      const parentPath = fullGroupProps.path;
      const currentRuleIndex = r.path[r.path.length - 1];

      // if currentRuleIndex is not a number, we can't add an exclusion
      if (typeof currentRuleIndex !== "number") {
        return;
      }

      const parentRules = fullGroupProps.ruleGroup.rules;

      // Get the allowed operators for the field
      const field = fullGroupProps.schema.fields.find(
        (f) => f.value === r.field
      );
      const allowedOperators =
        field?.operators?.filter((op) =>
          op.predecessors?.includes(r.operator)
        ) ?? [];

      if (allowedOperators.length === 0) {
        return;
      }

      const newRule = {
        field: r.field,
        operator: allowedOperators[0].name,
        value: allowedOperators[0]?.defaultValue ?? "",
        allowRemove: r.allowRemove,
      };

      const newRules = [
        ...parentRules.slice(0, currentRuleIndex + 1),
        newRule,
        ...parentRules.slice(currentRuleIndex + 1),
      ];

      const newQuery = update(
        fullGroupProps.schema.getQuery(),
        "rules",
        newRules,
        parentPath
      );

      fullGroupProps.schema.dispatchQuery(newQuery);
    },
    [fullGroupProps.schema, fullGroupProps.ruleGroup.rules]
  );

  const onRemoveRule = useCallback(
    (path: Path) => {
      const newQuery = remove(fullGroupProps.schema.getQuery(), path);
      fullGroupProps.schema.dispatchQuery(newQuery);
    },
    [fullGroupProps.schema]
  );

  const schema = fullGroupProps.schema;

  // Intercept the onPropChange to add custom logic
  const onPropChange = useCallback(
    (prop, value, path, context) => {
      const rule = findPath(path, schema.getQuery());
      let newQuery = update(schema.getQuery(), prop, value, path);

      // remove exclusion if exist and the operator is changed
      if (prop === "operator" || prop === "field") {
        const operators = schema.fields.find(
          (f) => f.value === rule.field
        ).operators;
        const siblings = getPredecessorSiblings({
          rule,
          rules: fullGroupProps.ruleGroup.rules,
          path,
          operators,
        });

        // Remove siblings
        siblings?.forEach((sibling) => {
          newQuery = remove(newQuery, sibling.path);
        });

        const ruleOperator = operators.find(
          (op: FullOperator) =>
            op.name ===
            (prop === "operator" ? value : (rule as RuleType).operator)
        );

        // Reset value on operator / field change
        newQuery = update(
          newQuery,
          "value",
          ruleOperator?.defaultValue ?? "",
          path
        );

        if (prop === "field") {
          const newFieldOperators = schema.fields.find(
            (f) => f.value === value
          ).operators;
          newQuery = update(
            newQuery,
            "operator",
            newFieldOperators[0].name,
            path
          );
        }
      }

      // Remove error when changing the value
      newQuery = update(newQuery, "error", undefined, path);
      schema.dispatchQuery(newQuery);
    },
    [fullGroupProps.actions, fullGroupProps.ruleGroup.rules, schema]
  );

  const handleChangeValue = useCallback(
    (path, newValue?: string) => {
      const newQuery = update(schema.getQuery(), "value", newValue, path);

      schema.dispatchQuery(newQuery);
    },
    [schema]
  );

  const content = useMemo(() => {
    return (
      <>
        {fullGroupProps.ruleGroup.rules.map((r, idx) => {
          const thisPathMemo = fullGroupProps.pathsMemo[idx];
          const thisPath = thisPathMemo.path;
          const thisPathDisabled =
            thisPathMemo.disabled || (typeof r !== "string" && r.disabled);
          const key =
            typeof r === "string"
              ? [...thisPath, r].join("-")
              : r.id ?? [...thisPath, idx].join("-");
          const isGroup = isRuleGroupType(r);
          const showGroupCombinator = shouldShowGroupCombinator(r, idx);

          const allowedActions = getAllowedActions({
            ruleOrGroup: r,
            index: idx,
            schema,
            rules: fullGroupProps.ruleGroup.rules,
            path: thisPath,
          });

          // In case this is the only rule, we need to disable the remove button
          if (!fullGroupProps.context?.canRemoveRules) {
            allowedActions.isRemoveRuleAllowed = false;
          }

          return (
            <Fragment key={key}>
              {isGroup ? (
                <Stack direction={"row"} columnGap={1}>
                  {showGroupCombinator && (
                    <ReadonlyBox
                      label={fullGroupProps.ruleGroup.combinator}
                      boxType="combination"
                    />
                  )}
                  <CustomGroup
                    {...r}
                    schema={schema}
                    actions={fullGroupProps.actions}
                    path={thisPath}
                    ruleGroup={r}
                    disabled={thisPathDisabled}
                    parentDisabled={
                      fullGroupProps.parentDisabled || fullGroupProps.disabled
                    }
                    context={fullGroupProps.context}
                  />
                </Stack>
              ) : (
                <CustomRule
                  {...r}
                  schema={schema}
                  actions={{
                    ...fullGroupProps.actions,
                    onPropChange,
                    handleChangeValue,
                  }}
                  path={thisPath}
                  combinator={fullGroupProps.ruleGroup.combinator}
                  disabled={thisPathDisabled}
                  context={fullGroupProps.context}
                  index={idx}
                  prevField={
                    idx > 0
                      ? fullGroupProps.ruleGroup.rules[idx - 1]
                      : undefined
                  }
                  canAddRuleExclusion={allowedActions.isAddRuleAllowed}
                  onAddRuleExclusion={handleAddRuleExclusion}
                  canRemoveRule={allowedActions.isRemoveRuleAllowed}
                  onRemoveRule={handleRemoveGroup}
                  onRemoveRuleExclusion={onRemoveRule}
                  canRemoveRuleExclusion={allowedActions.removeRuleExclusion}
                />
              )}
            </Fragment>
          );
        })}
      </>
    );
  }, [
    fullGroupProps,
    fullGroupProps.ruleGroup.rules,
    fullGroupProps.actions,
    fullGroupProps.path,
    fullGroupProps.disabled,
    fullGroupProps.parentDisabled,
    fullGroupProps.pathsMemo,
    fullGroupProps.context,
    schema,
  ]);

  return <Stack rowGap={1}>{content}</Stack>;
};
