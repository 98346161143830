import React, { useMemo } from "react";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import {
  selectBatchActionsResponses,
  setRemoveBatchActionsResponseSelector,
} from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { BatchActionPollerItem } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsResponsePoller/BatchActionPollerItem";

export const BatchActionsPoller: React.FC = () => {
  const batchActionsResponses = useBatchActionsStore(
    selectBatchActionsResponses
  );

  const removeBatchActionsResponses = useBatchActionsStore(
    setRemoveBatchActionsResponseSelector
  );

  const content = useMemo(() => {
    return batchActionsResponses?.map((resp) => {
      return (
        <BatchActionPollerItem
          bulkActionRes={resp}
          key={resp.bulkId}
          onClose={() => {
            removeBatchActionsResponses(resp.bulkId);
          }}
        />
      );
    });
  }, [batchActionsResponses, removeBatchActionsResponses]);

  return <>{content}</>;
};
