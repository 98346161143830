import type Resource from "@/components/ResourceView/resources";
import { SessionGetResponse } from "@/generated/aiApi";
import { DrawerStatePush } from "@/shared/store/drawersStackStore/types";

export enum FlowType {
  UnhealthyPod = "Unhealthy Pod",
  UnhealthyNode = "Unhealthy Node",
  FailedDeploy = "Failed Deploy",
  FailedJobRun = "Failed Job Run",
  AvailabilityIssue = "Availability Issue",
  AvailabilityIssueIntroduction = "Availability Issue Introduction",
  UnsyncedExternalDns = "Not synced External DNS",
  CertificateIssue = "Certificate Issue",
  HelmReleaseIssue = "Helm Release Issue",
}

export type ResourceDataForAiInvestigation =
  | Resource
  | {
      kind: string;
      cluster: string;
      namespace: string;
      name: string;
    };

export interface MinimizedRowProps {
  data: MinimizedSession;
  onMaximize: (data: MinimizedSession) => void;
  onRemove: (data: MinimizedSession) => void;
}

export type SessionIdentifier = {
  id: string;
  requestId: string;
  kind: string;
  cluster: string;
  namespace?: string;
  name: string;
  type: FlowType;
};

export type MinimizedSession = {
  session: SessionGetResponse["session"];
  sessionIdentifier: SessionIdentifier;
} & DrawerStatePush;
