import { useMutation } from "@tanstack/react-query";

import {
  ActionsApiSubmitBulkActionsRequest,
  GetBulkActionsResultsResp,
  submitBulkActionsUrl,
} from "@/generated/actionsApi";
import { useActionsApiClient } from "@/shared/hooks/actions-api/apiClient";

export const ACTIONS_API_SUBMIT_BATCH_ACTIONS_PATH = "/actions/api/v1/bulk";

export const useSubmitBatchActions = () => {
  const apiClient = useActionsApiClient();

  return useMutation(
    [ACTIONS_API_SUBMIT_BATCH_ACTIONS_PATH],
    async (requestBody: ActionsApiSubmitBulkActionsRequest) =>
      await apiClient.post<GetBulkActionsResultsResp>(
        submitBulkActionsUrl(requestBody, apiClient.defaults.baseURL ?? ""),
        { ...requestBody.submitBulkActionsRequest }
      )
  );
};
