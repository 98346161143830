import { datadogRum } from "@datadog/browser-rum";

import { dispatchEvent } from "@/shared/hooks/analytics";
import { ResourceIdentifier } from "@/components/AiInvestigation/analytics";
import { AnalyzeLogResponse } from "@/generated/aiApi";

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export function sendLogAnalysisStateEvent(
  event: string,
  resource: ResourceIdentifier,
  logResponse: AnalyzeLogResponse | undefined,
  error: unknown
) {
  const state = error
    ? "error"
    : logResponse === undefined
    ? "loading"
    : "completed_successfuly";
  const data = {
    type: "logs",
    cluster: resource.cluster,
    namespace: resource.namespace,
    name: resource.name,
    kind: resource.kind,
    container: resource.container,
    state,
    isProblemFound: logResponse?.isProblemFound,
    isAnalysisCompletedWithError: error,
    isComplete: logResponse !== undefined,
  };

  datadogRum.addAction(event, data);
  dispatchEvent(event, data);
}
