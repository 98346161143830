import { NodeEventGroupTypes } from "@/components/common/EventGroup/nodeEvent/NodeChangeGroup";
import { WORKFLOW_CONFIG_TYPES } from "@/components/common/EventGroup/workflowIssues/constants";
import { OpenResourceDrawerParams } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ScaleDownImpact/hooks/scaleDownImpactHooksTypes";
import {
  EVENTS_TAB_PREFIX,
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
  FILTERS_PARAM_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export function useOpenResourceDrawer(): (
  params: OpenResourceDrawerParams
) => void {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  return (params: OpenResourceDrawerParams) => {
    const { komodorUid, urlTimeWindow, urlTimeframe } = params;
    const { name, kind, cluster, namespace } =
      parseKomodorUid(komodorUid) ?? {};
    if (!cluster || !name || !kind) {
      return;
    }
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster,
      namespace,
      resourceName: name,
      resourceType: kind,
      buildPreloadResource: true,
      urlStates: {
        [EVENTS_TAB_TIMEFRAME]: urlTimeframe,
        [EVENTS_TAB_TIME_WINDOW]: urlTimeWindow,
        [EVENTS_TAB_PREFIX + FILTERS_PARAM_KEY]: {
          t: [NodeEventGroupTypes.Delete, WORKFLOW_CONFIG_TYPES.availability],
        },
      },
    });
  };
}
