import { useMemo } from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
  RightSizingCostSummaryByServiceResponse,
} from "../../../../../generated/metricsApi";
import { RecommendationsModalFetchError } from "../errors/RecommendationsModalFetchError";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";

import { RecommendationContainer } from "./components/RecommendationContainer/RecommendationContainer";

const {
  rightSizingPage: {
    recommendationModal: { containersLoader: containersLoaderAriaLabel },
  },
} = costOptimizationAriaLabels;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 4px;
`;

const sortRows = (
  a: RightSizingCostSummaryByService,
  b: RightSizingCostSummaryByService
) => {
  if (b.cpuRequestMiliCore !== a.cpuRequestMiliCore) {
    return b.cpuRequestMiliCore - a.cpuRequestMiliCore;
  }
  if (b.memoryRequestMiB !== a.memoryRequestMiB) {
    return b.memoryRequestMiB - a.memoryRequestMiB;
  }
  return a.containerName.localeCompare(b.containerName);
};

interface Props {
  loading: boolean;
  error: string | null;
  strategy: OptimizationStrategy;
  data: RightSizingCostSummaryByServiceResponse | undefined;
  refresh: (() => void) | undefined;
}

export const useRowsContent = ({
  loading,
  error,
  strategy,
  data,
  refresh,
}: Props) => {
  return useMemo(() => {
    if (loading) {
      return (
        <StyledSkeleton
          aria-label={containersLoaderAriaLabel}
          variant="rectangular"
          width="100%"
          height="100%"
        />
      );
    }
    if (error) {
      return <RecommendationsModalFetchError refreshCb={refresh} />;
    }
    if (!data?.rows) {
      return null;
    }

    return [...(data?.rows ?? [])]
      .sort(sortRows)
      .map((container, i) => (
        <RecommendationContainer
          key={i}
          initialOpen={data.rows.length === 1}
          container={container}
          strategy={strategy}
        />
      ));
  }, [data?.rows, error, loading, refresh, strategy]);
};
