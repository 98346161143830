import { useState } from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

import { LightTooltip } from "components/komodor-ui/LightTooltip/LightTooltip";

export interface ActionIconButtonProps {
  handleClickAndGetResponse: () => string | void;
  children: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, "children">;
  tooltipVariant?: "default" | "light";
  iconButtonProps?: IconButtonProps;
}
export const ActionIconButton: React.FC<ActionIconButtonProps> = ({
  handleClickAndGetResponse,
  children,
  tooltipProps,
  tooltipVariant = "default",
  iconButtonProps,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState<string>();

  const handleClick = () => {
    const response = handleClickAndGetResponse();
    if (response) {
      setFeedbackText(response);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1900);
      setTimeout(() => {
        setFeedbackText(undefined);
      }, 2000);
    }
  };

  const TooltipComponent = tooltipVariant === "light" ? LightTooltip : Tooltip;

  return (
    <TooltipComponent
      {...tooltipProps}
      open={tooltipOpen}
      title={feedbackText ?? tooltipProps.title}
      onMouseEnter={feedbackText ? undefined : () => setTooltipOpen(true)}
      onMouseLeave={feedbackText ? undefined : () => setTooltipOpen(false)}
    >
      <IconButton onClick={handleClick} size="small" {...iconButtonProps}>
        {children}
      </IconButton>
    </TooltipComponent>
  );
};
