import React from "react";
import { parseISO } from "date-fns";
import { filter } from "lodash";

import dockerImageNameParser from "../../../../shared/utils/parseDockerImageName/parseDockerImageName";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DeployEventDetails } from "../../ProcessList/details/deployEvent/DeployEventDetails";
import { GitCompare, TrackedFile } from "../../../../shared/types/git";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupEventsByKey, mapAndSort } from "../groupEvents";
import {
  greenForUI,
  greenForUIText,
  pinkBrand,
  pinkForUI,
} from "../../../../Colors";
import DeployScatterShape from "../shapes/deploy.svg?react";
import EventGroup, { EventGroupTooltipProps } from "..";

import getDeployStatus from "./getDeployStatus";
import getChangesSummary from "./getChangesSummary";
import { excludedDiffKeys } from "./getSpecsForDiff";
import resolveStringPath from "./resolveStringPath";
import { getDeployCardChangesText } from "./getDeployCardChangesText";

import { Deploy } from "@/generated/monitorsApi/api";

export const deployType = "Deploy";

const REPLICAS_ONLY = "replicas_only";

export const isAutomaticDeployByChangeType = (
  changeType: string,
  replicasOnlyAsAutomaticDeploys: unknown
): boolean => {
  return (
    changeType === "empty_deployment" ||
    (changeType === REPLICAS_ONLY && replicasOnlyAsAutomaticDeploys === true)
  );
};

const getType = (
  { isEmptyDeployment, changeType }: Deploy,
  replicasOnlyAsAutomaticDeploys: unknown
) => {
  if (!changeType) return deployType;
  const isAutomatic =
    isAutomaticDeployByChangeType(changeType, replicasOnlyAsAutomaticDeploys) ||
    isEmptyDeployment;

  return isAutomatic ? "Automatic Deploy" : deployType;
};

export class DeployEvent {
  readonly id;
  readonly eventTime;
  readonly endEventTime;
  readonly updatedAt;
  readonly type;
  readonly status;
  readonly serviceId;
  readonly versionTo;
  readonly versionFrom;
  readonly diffKeys;
  readonly failed_container;
  readonly strategy;
  readonly namespace;
  readonly clusterName;
  readonly deploymentName;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    readonly _event: Deploy,
    serviceId: string,
    replicasOnlyAsAutomaticDeploys: unknown
  ) {
    this.id = _event.id;
    this.eventTime = _event.eventTime ? parseISO(_event.eventTime) : new Date();
    this.endEventTime = _event.endEventTime
      ? parseISO(_event.endEventTime)
      : undefined;
    this.updatedAt = _event.updatedAt ? parseISO(_event.updatedAt) : undefined;
    this.type = getType(_event, replicasOnlyAsAutomaticDeploys);
    this.status = getDeployStatus(_event.status);
    this.serviceId = serviceId;
    this.versionTo = _event.versionTo;
    this.versionFrom = _event.versionFrom;
    this.diffKeys = _event.deploymentDiffKeys as string[] | null | undefined;
    this.failed_container = _event.failed_container;
    this.strategy = _event.strategy;
    this.namespace = _event.namespace;
    this.clusterName = _event.clusterName;
    this.deploymentName = _event.deploymentName;
  }

  getGitCompare(): GitCompare[] | undefined {
    return this._event.gitCompare as GitCompare[] | undefined;
  }

  getTrackedFiles(): TrackedFile[] | null | undefined {
    return this._event.trackedFiles as TrackedFile[] | null | undefined;
  }

  getGeneration(): number | null | undefined {
    return this._event.generation;
  }

  getResourceUid(): string {
    const id = this._event.id;
    return id.substring(id.lastIndexOf("-") + 1);
  }
}

const isGitRev = (maybeHash: string): boolean =>
  /^[0-9a-f]{40}$/i.test(maybeHash);

export const buildSummaryFromImageTag = (
  versionFrom: string,
  versionTo: string,
  generation?: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): string => {
  if (generation === 1) {
    return `deployed new service`;
  }
  if (!versionFrom && !versionTo) {
    return "";
  }
  const versionFromParts = dockerImageNameParser(versionFrom);
  const versionToParts = dockerImageNameParser(versionTo);
  if (versionFromParts.path !== versionToParts.path) {
    return `image updated to name:${versionToParts.path}`;
  }

  if (versionFromParts.domain !== versionToParts.domain) {
    return `image updated to repository:${versionTo}`;
  }

  if (
    versionToParts.tag &&
    versionFromParts.tag &&
    versionFromParts.tag !== versionToParts.tag
  ) {
    const newImageShortTag = isGitRev(versionToParts.tag)
      ? versionToParts.tag.substring(0, 7)
      : versionToParts.tag;

    return `image updated to tag:${newImageShortTag}`;
  }

  if (
    versionToParts.digest &&
    versionFromParts.digest &&
    versionFromParts.digest !== versionToParts.digest
  ) {
    return `image updated to digest:${versionToParts.digest.substring(
      0,
      12
    )}...`;
  }
  return `no image change`;
};

const hasTrackedFiles = (event: DeployEvent): boolean => {
  const gitCompare = event.getGitCompare();
  if (!gitCompare) {
    return false;
  }

  return gitCompare.some(({ commitDiff }) => {
    return commitDiff?.some(({ files }) => {
      return files && files?.length > 0;
    });
  });
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class DeployEventGroup implements EventGroup {
  readonly icon = DeployScatterShape;

  readonly backgroundColor;
  readonly fillColor;
  readonly serviceId;
  readonly generation;

  readonly id;
  readonly type;
  readonly startTime;
  readonly endTime;
  readonly status;

  readonly isCompleted;

  static getStatusColor = (status: string): string => {
    return status === "failed" ? pinkBrand : greenForUIText;
  };

  constructor(public readonly events: DeployEvent[]) {
    const lastEvent = events[events.length - 1];

    this.generation = lastEvent.getGeneration();
    this.serviceId = lastEvent.serviceId;

    this.id = events[0]._event.id;
    this.type = lastEvent.type;
    this.startTime = events[0].eventTime ?? new Date();
    this.endTime = lastEvent.endEventTime ?? lastEvent.eventTime ?? new Date();

    this.status = lastEvent.status;
    this.isCompleted = lastEvent.status !== "started";
    this.backgroundColor = this.status === "failed" ? pinkForUI : greenForUI;
    this.fillColor = DeployEventGroup.getStatusColor(this.status);
  }
  title?: string | undefined;
  sendEventWhenClicked?: boolean | undefined;
  renderTooltipContent?:
    | ((props: EventGroupTooltipProps) => JSX.Element)
    | undefined;
  renderLineLabel?: (() => string) | undefined;

  getEventDiffKeys(): (string | null)[] {
    const lastEvent = this.events[this.events.length - 1];
    const filteredKeys = this.filterKeys(lastEvent.diffKeys ?? []) ?? [];
    if (hasTrackedFiles(lastEvent)) {
      filteredKeys.push("Tracked files changes");
    }
    return filteredKeys;
  }
  get summary(): string {
    const { versionFrom, versionTo } = this.events[this.events.length - 1];
    return buildSummaryFromImageTag(
      versionFrom ?? "",
      versionTo ?? "",
      this.generation ?? undefined
    );
  }

  get details(): string {
    return getChangesSummary(this.getEventDiffKeys());
  }

  get deployCardDetails(): string {
    return getDeployCardChangesText(this.getEventDiffKeys());
  }

  filterKeys(diffKeys: string[]): (string | null)[] {
    return diffKeys
      ?.map((k) => resolveStringPath(k))
      ?.filter(
        (k) => k && !k.startsWith("status.") && !excludedDiffKeys.has(k)
      );
  }

  renderEventDetails(): JSX.Element {
    return <DeployEventDetails eventGroup={this} />;
  }
}

export const filterRullIfHpaInRelatedResources = (
  changeType?: string | null
): boolean => changeType !== REPLICAS_ONLY;

export const groupDeployEvents = (
  events: Deploy[],
  {
    replicasOnlyAsAutomaticDeploys,
    hpaEventsInRelatedResources,
  }: Record<string, unknown>
): DeployEventGroup[] => {
  const singleRowDeployEvents = events
    .map((e) => (e.endEventTime !== null ? e : undefined))
    .filter((e): e is Deploy => e !== undefined);
  const startAndEndEvents = [...events, ...singleRowDeployEvents];
  const filteredEvents = hpaEventsInRelatedResources
    ? filter(startAndEndEvents, (event) =>
        filterRullIfHpaInRelatedResources(event.changeType)
      )
    : startAndEndEvents;

  return groupEventsByKey(
    mapAndSort(
      filteredEvents,
      (e: Deploy, sid: string) =>
        new DeployEvent(e, sid, replicasOnlyAsAutomaticDeploys)
    ),
    (e) => `${e.serviceId}:${e.getResourceUid()}:${e.getGeneration()}`
  ).map((events) => new DeployEventGroup(events));
};
