import { useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { LightTooltip, RowData } from "@komodorio/design-system/komodor-ui";
import Close from "@mui/icons-material/Close";
import styled from "styled-components";
import Box from "@mui/material/Box";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";

interface NameFieldCellProps<T extends Record<string, unknown>> {
  rowData: RowData<T>;
  field: keyof T;
  removeTargetIdentifierField?: keyof T;
}
export const NameFieldCell = styled(Typography).attrs({
  variant: "body2",
  color: "text.primary",
  fontWeight: 500,
})`
  margin-block-end: 4px;
`;

export function NameField<T extends Record<string, unknown>>({
  rowData: { isBaseline, data },
  field,
  removeTargetIdentifierField,
}: NameFieldCellProps<T>) {
  const { comparedSelections, setComparedSelections } =
    useDriftComparisonContext();
  const removeTargetHandler = useCallback(
    (toRemoveValue: string) =>
      comparedSelections
        ? setComparedSelections(
            comparedSelections.filter((s) => s.value !== toRemoveValue)
          )
        : null,
    [comparedSelections, setComparedSelections]
  );

  const valueToRemove =
    removeTargetIdentifierField && !isBaseline
      ? String(data.data?.[removeTargetIdentifierField]?.value)
      : undefined;
  return isBaseline ? (
    <NameFieldCell>{String(data[field])}</NameFieldCell>
  ) : (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <NameFieldCell>{String(data.data?.[field]?.value)}</NameFieldCell>
      {removeTargetIdentifierField && (
        <LightTooltip placement="top" title="Remove from table">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (valueToRemove) {
                removeTargetHandler(valueToRemove);
              }
            }}
            size="small"
            className="remove-service-button"
          >
            <Close fontSize="small" />
          </IconButton>
        </LightTooltip>
      )}
    </Box>
  );
}
