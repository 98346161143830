import styled from "styled-components";
import Divider from "@mui/material/Divider";

import klaudiaAIRootCauseAnalysisSection from "./assets/klaudiaAIRootCauseAnalysisSection.png";

import { ClickableArea } from "@/components/ClustersDemoViewV3/styles";
import { GITHUB_DIFF_URL } from "@/components/common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/demo/constants";

const Container = styled.div`
  position: relative;
`;
const GithubCardArea = styled(ClickableArea)`
  width: 85%;
  height: 12.5%;
  left: 13%;
  top: 38.5%;
`;

export const KlaudiaRCADemoSection: React.FC = () => {
  return (
    <Container>
      <img
        src={klaudiaAIRootCauseAnalysisSection}
        alt={"Klaudia AI Root Cause Analysis Section"}
        width="100%"
      />
      <GithubCardArea onClick={() => window.open(GITHUB_DIFF_URL, "_blank")} />
      <Divider />
    </Container>
  );
};
