import { useMemo } from "react";

import { AgentProperties } from "@/shared/hooks/useAgentInfo/useAgentInfo";
import { useAgentPropertiesById } from "@/shared/hooks/useAgents";

export const useNeedUpgradeAgent = (
  agentId: string | undefined,
  agentSupportCheck: (agentProps: AgentProperties | null) => boolean
): boolean => {
  const { agentInfo } = useAgentPropertiesById(agentId ?? "");
  return useMemo(() => {
    return !agentSupportCheck(agentInfo);
  }, [agentInfo, agentSupportCheck]);
};
