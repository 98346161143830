import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";

import { PAGE_SIZE_OPTIONS } from "../constants";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";
import { useDataGridPagination } from "@/shared/hooks/dataGrid/useDataGridPagination";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";

export const useDataList = ({
  data,
  columns,
  columnsVisibility,
  search,
}: {
  data: TypedWorkspace[];
  columns: GridColDef[];
  columnsVisibility: GridColumnVisibilityModel;
  search: string;
}) => {
  const { paginationModel, handlePaginationModelChange } =
    useDataGridPagination();
  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();

  const filteredData = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return data
      ? data.filter((ws) => {
          return [ws.name, ws.description, ws.AuthorEmail].some((s) => {
            return s?.toLowerCase().includes(lowerCaseFilter);
          });
        })
      : [];
  }, [data, search]);

  const table = useMemo(() => {
    return (
      <DataGridPro
        aria-label={AriaLabels.Workspaces.Management.WorkspacesTable}
        rows={filteredData.map((ws) => ({
          ...ws,
          hovered: hoveredRowId === ws.id,
        }))}
        disableColumnMenu
        disableVirtualization
        pagination
        columns={columns}
        rowCount={filteredData.length}
        paginationMode="client"
        paginationModel={paginationModel}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnsVisibility}
        slotProps={{
          row: {
            onMouseEnter: handleRowHovered,
            onMouseLeave: handleRowLeaved,
          },
        }}
        sx={{
          display: "grid", // https://github.com/mui/mui-x/issues/8447
        }}
      />
    );
  }, [
    columns,
    columnsVisibility,
    filteredData,
    handlePaginationModelChange,
    handleRowHovered,
    handleRowLeaved,
    hoveredRowId,
    paginationModel,
  ]);

  return { table, paginationModel };
};
