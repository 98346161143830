import { noop } from "lodash";
import { useCallback, useRef } from "react";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";

import useZoom from "@/components/common/EventsChart/useZoom";
import { useMouseHover } from "@/components/common/EventsChart/useMouseHover";

export function useGraphProps() {
  const componentRef = useRef<HTMLDivElement>(null);

  const { onMouseMove } = useZoom(noop);
  const { mouseOver, setMouseOver, mousePos, setMousePos } = useMouseHover();

  const boundingRect = componentRef.current?.getBoundingClientRect();

  const onMouseMoveCb = useCallback(
    (state: CategoricalChartState, event: React.MouseEvent<SVGElement>) => {
      setMousePos({ x: event.clientX, y: event.clientY });
      onMouseMove(state);
    },
    [onMouseMove, setMousePos]
  );

  return {
    containerProps: {
      ref: componentRef,
      onMouseOver: () => setMouseOver(true),
      onMouseOut: () => setMouseOver(false),
    },
    chartProps: {
      onMouseMove: onMouseMoveCb,
    },
    tooltipProps: {
      boundingRect,
      open: mouseOver,
      mousePos,
    },
  };
}
