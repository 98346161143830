import EventGroup from "@/components/common/EventGroup";
import {
  INFRA_HEALTH,
  K8S_ADDONS_WORKFLOWS,
  WORKLOAD_HEALTH,
} from "@/components/routes/routes";

export const RELATED_EVENTS_HAS_MORE_EVENTS_PANEL_EXCLUDED_ROUTES = [
  K8S_ADDONS_WORKFLOWS,
  WORKLOAD_HEALTH,
  INFRA_HEALTH,
];

export enum ProcessListSortKeys {
  starttime = "starttime",
  endtime = "endtime",
}

export enum ProcessListColumnTypes {
  Type = "type",
  Summary = "summary",
  StartDate = "startDate",
  EndDate = "endDate",
  Details = "details",
  Service = "service",
  Status = "status",
  ClickableArrow = "clickable-arrow",
  Custom = "custom",
}

type PredefinedColumnTypes =
  | ProcessListColumnTypes.Type
  | ProcessListColumnTypes.Summary
  | ProcessListColumnTypes.StartDate
  | ProcessListColumnTypes.EndDate
  | ProcessListColumnTypes.Details
  | ProcessListColumnTypes.Service
  | ProcessListColumnTypes.Status
  | ProcessListColumnTypes.ClickableArrow;

export type CustomColumnDefinition = {
  type: ProcessListColumnTypes.Custom;
  customDataKey: string;
  columnHeader: string;
  getEventGroupIdForCustomData: (eventGroup: EventGroup) => string;
  fallbackValue?: string;
};

export type ProcessListColumnDefinition =
  | PredefinedColumnTypes
  | CustomColumnDefinition;

export function getDefaultColumnTypes(
  showService: boolean
): ProcessListColumnDefinition[] {
  return [
    ProcessListColumnTypes.Type,
    ProcessListColumnTypes.Summary,
    ProcessListColumnTypes.StartDate,
    ProcessListColumnTypes.EndDate,
    ProcessListColumnTypes.Details,
    ...(showService
      ? [ProcessListColumnTypes.Service as PredefinedColumnTypes]
      : []),
    ProcessListColumnTypes.Status,
    ProcessListColumnTypes.ClickableArrow,
  ];
}
