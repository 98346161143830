import { ValidationError } from "yup";

import { TrackedKey } from "@/generated/accounts";
import { validationSchema } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/validationSchema";
import {
  Errors,
  ErrorsKey,
} from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";

export const validateCreateKeySchema = async (
  data: Partial<TrackedKey>
): Promise<Errors | undefined> => {
  try {
    await validationSchema.validate(data, {
      abortEarly: false,
    });

    return undefined;
  } catch (err) {
    const error = (err as ValidationError).inner[0];
    if (!error) {
      return undefined;
    }
    return { [error.path as ErrorsKey]: error.message } as Errors;
  }
};
