import { useMemo } from "react";

import {
  supportedDynamicCheckTypes,
  supportedStaticCheckTypes,
} from "../policyDrawerConstants";

import { useIsDynamicChecksTab } from "./useIsDynamicChecksTab";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { CheckType } from "@/generated/reliabilityApi";

export const useIgnoredCheckTypes = () => {
  const { showCertManagerAddon, showK8SAddonAutoscaler } =
    useOverridableFlags();

  return useMemo(() => {
    const ignoredCheckTypes: CheckType[] = [];

    if (!showCertManagerAddon) {
      ignoredCheckTypes.push(CheckType.CertificateExpiration);
    }

    if (!showK8SAddonAutoscaler) {
      ignoredCheckTypes.push(CheckType.ScaleDownImpact);
      ignoredCheckTypes.push(CheckType.OverProvisionedCluster);
    }

    return ignoredCheckTypes;
  }, [showCertManagerAddon, showK8SAddonAutoscaler]);
};

export const useSupportedCheckTypesByTab = () => {
  const isDynamicChecksTab = useIsDynamicChecksTab();

  const ignoredCheckTypes = useIgnoredCheckTypes();

  return useMemo(() => {
    return (
      isDynamicChecksTab
        ? supportedDynamicCheckTypes
        : supportedStaticCheckTypes
    ).filter((checkType) => !ignoredCheckTypes.includes(checkType));
  }, [ignoredCheckTypes, isDynamicChecksTab]);
};
