import React, { createContext, useCallback, useState } from "react";

import { MetricsApiGetWorkloadContainersMetricsRequest } from "../../../../../../generated/metricsApi";
import { ContainersValues } from "../types";

type UpdateContainerProps = {
  containerName: string;
  variant: keyof ContainersValues["resources"];
  resource: "memory" | "cpu";
  value: number | null;
};

export type MetricsParamsWithoutkind = Omit<
  MetricsApiGetWorkloadContainersMetricsRequest,
  "kind"
>;

export type RecommendationsModalContextState = {
  containers: ContainersValues[];
  originalContainers: ContainersValues[];
  isContainersLoaded: boolean;
  metricsParams: MetricsParamsWithoutkind | undefined;
  setInitialContainers: (containers: ContainersValues[]) => void;
  resetContainers: () => void;
  updateContainer: (data: UpdateContainerProps) => void;
  getContainer: (containerName: string) => ContainersValues | undefined;
  setMetricsParams: (params: MetricsParamsWithoutkind) => void;
  resetContext: () => void;
  setContainers: (containers: ContainersValues[]) => void;
};

const initialState: RecommendationsModalContextState = {
  containers: [],
  originalContainers: [],
  isContainersLoaded: false,
  metricsParams: undefined,
  setInitialContainers: () => undefined,
  resetContainers: () => undefined,
  updateContainer: () => undefined,
  getContainer: () => undefined,
  setMetricsParams: () => undefined,
  resetContext: () => undefined,
  setContainers: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const RecommendationsModalContext =
  createContext<RecommendationsModalContextState>(initialState);

export const RecommendationsModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [containers, setContainers] = useState(initialState.containers);
  const [originalContainers, setOriginalContainers] = useState(
    initialState.containers
  );
  const [metricsParams, setMetricsParams] =
    useState<MetricsParamsWithoutkind>();

  const setInitialContainers = (containers: ContainersValues[]) => {
    setContainers(containers);
    setOriginalContainers(containers);
  };

  const updateContainer = ({
    containerName,
    variant,
    resource,
    value,
  }: UpdateContainerProps) => {
    const updatedContainers = containers.map((c) => {
      if (c.name === containerName) {
        return {
          ...c,
          resources: {
            ...c.resources,
            [variant]: {
              ...c.resources[variant],
              [resource]: value,
            },
          },
        };
      }
      return c;
    });
    setContainers(updatedContainers);
  };

  const getContainer = (containerName: string) =>
    containers.find((c) => c.name === containerName);

  const resetContainers = useCallback(
    () => setContainers(originalContainers),
    [originalContainers]
  );

  const resetContext = () => {
    setContainers(initialState.containers);
    setOriginalContainers(initialState.containers);
    setMetricsParams(initialState.metricsParams);
  };

  const isContainersLoaded = originalContainers.length > 0;

  const state: RecommendationsModalContextState = {
    containers,
    originalContainers,
    isContainersLoaded,
    metricsParams,
    setInitialContainers,
    setContainers,
    resetContainers,
    updateContainer,
    getContainer,
    setMetricsParams,
    resetContext,
  };

  return (
    <RecommendationsModalContext.Provider value={state}>
      {children}
    </RecommendationsModalContext.Provider>
  );
};
