import React, { useMemo } from "react";

import { RbacActionsContext } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/RbacActionsContext";
import { useActions } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/useActions";

export const RbacActionsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isLoading: isLoadingActions, globalActions } = useActions();

  const state = useMemo(
    () => ({
      isLoadingActions,
      globalActions: globalActions ?? [],
    }),
    [isLoadingActions, globalActions]
  );

  return (
    <RbacActionsContext.Provider value={state}>
      {children}
    </RbacActionsContext.Provider>
  );
};
