import { useNamespacesFilter } from "@/components/Inspection/InspectionViews/inpectionViewsHooks";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import {
  INSPECTION_GROUP_PARAM_KEY,
  INSPECTION_LABELS_PARAM_KEY,
  INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY,
  INSPECTION_POD_IP_PARAM_KEY,
  INSPECTION_SEARCH_TERM_PARAM_KEY,
  INSPECTION_STATUS_PARAM_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

export const useInspectionFilters = (
  clusterName: string,
  resourceKeyInLS?: string
) => {
  const [filterNamespaces] = useNamespacesFilter({
    clusterName,
    resourceKeyInLS,
  });

  const [filterStatus] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_STATUS_PARAM_KEY
  );
  const [filterLabels] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_LABELS_PARAM_KEY
  );
  const [filterLabelsExactMatch] = useStringifiedStateInSearchParams<boolean>(
    INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY
  );
  const [filterSearchTerm] = useStateInSearchParams(
    INSPECTION_SEARCH_TERM_PARAM_KEY
  );
  const [filterGroup] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_GROUP_PARAM_KEY
  );
  const [filterIps] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_POD_IP_PARAM_KEY
  );

  return {
    filterNamespaces,
    filterStatus,
    filterLabels,
    filterLabelsExactMatch,
    filterSearchTerm,
    filterGroup,
    filterIps,
  };
};
