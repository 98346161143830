import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";
import { OverflowTextStyle } from "../../../../styles/styles";

const StyledTypography = styled(Typography)`
  && {
    margin-left: 6px;
    ${OverflowTextStyle};
  }
`;

type DropdownContentProps<T extends MuiSelectionOptionValue> = {
  selectedOption?: MuiSelectionOption<T>;
  placeholder?: string;
  customValueElement?: (value: MuiSelectionOption<T>) => React.ReactNode;
};

export const DropdownFieldContent = <T extends MuiSelectionOptionValue>({
  selectedOption,
  placeholder,
  customValueElement,
}: DropdownContentProps<T>) => {
  return !selectedOption && placeholder ? (
    <StyledTypography variant={"body2"} color="text.secondary">
      {placeholder}
    </StyledTypography>
  ) : customValueElement ? (
    customValueElement(selectedOption)
  ) : (
    <StyledTypography variant={"body2"} color="text.primary">
      {selectedOption?.label}
    </StyledTypography>
  );
};
