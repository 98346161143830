import { WorkspaceKind } from "@/generated/workspacesApi";

export type Team = "troubleshooting" | "barzelim" | "operate";

// how-to doc: https://app.clickup.com/3690747/v/dc/3gm7v-32995/3gm7v-18915
export enum DatadogViewNamesEnum {
  // inspection view pages
  nodesPage = "native_resources\\nodes",
  podsPage = "native_resources\\workloads\\pods",
  deletedPodsPage = "native_resources\\workloads\\deleted_pods",
  replicaSetsPage = "native_resources\\workloads\\replicasets",
  deploymentsPage = "native_resources\\workloads\\deployments",
  jobsPage = "native_resources\\workloads\\jobs",
  cronJobsPage = "native_resources\\workloads\\cronjobs",
  statefulSetsPage = "native_resources\\workloads\\statefulsets",
  daemonSetsPage = "native_resources\\workloads\\daemonsets",
  pvcsPage = "native_resources\\storage\\pvcs",
  pvsPage = "native_resources\\storage\\pvs",
  storageClassesPage = "native_resources\\storage\\storage_classes",
  configMapsPage = "native_resources\\configuration\\configmaps",
  secretsPage = "native_resources\\configuration\\secrets",
  resourceQuotasPage = "native_resources\\configuration\\resource_quotas",
  limitRangesPage = "native_resources\\configuration\\limit_ranges",
  hpasPage = "native_resources\\configuration\\hpas",
  pdbsPage = "native_resources\\configuration\\pdbs",
  kubernetesServicesPage = "native_resources\\network\\kubernetes_services",
  endpointsPage = "native_resources\\network\\endpoints",
  ingressesPage = "native_resources\\network\\ingresses",
  networkPoliciesPage = "native_resources\\network\\network_policies",
  endpointSlicesPage = "native_resources\\network\\endpoint_slices",
  serviceAccountsPage = "native_resources\\access_control\\service_accounts",
  clusterRolesPage = "native_resources\\access_control\\cluster_roles",
  rolesPage = "native_resources\\access_control\\roles",
  clusterRoleBindingsPage = "native_resources\\access_control\\cluster_role_bindings",
  roleBindingsPage = "native_resources\\access_control\\role_bindings",
  namespacesPage = "native_resources\\namespaces",
  customResourceDefinitionsPage = "native_resources\\custom_resources",
  // native resources drawer tabs
  detailsTab = "native_resources\\resource_tab\\details",
  podDetailsTab = "native_resources\\resource_tab\\pod_details",
  eventsTab = "native_resources\\resource_tab\\events",
  podsTab = "native_resources\\resource_tab\\pods",
  nodesTab = "native_resources\\resource_tab\\nodes",
  infoTab = "native_resources\\resource_tab\\info",
  workloadMetricsTab = "native_resources\\resource_tab\\workload_metrics",
  podMetricsTab = "native_resources\\resource_tab\\pod_metrics",
  nodeMetricsTab = "native_resources\\resource_tab\\node_metrics",
  podLogsTab = "native_resources\\resource_tab\\pod_logs",
  podEventsTab = "native_resources\\resource_tab\\pod_phases",
  yamlTab = "native_resources\\resource_tab\\yaml",
  describeAction = "native_resources\\resource_tab\\describe_action",

  // pod tabs
  podDescribeTab = "native_resources\\pod\\describe_action",
  podExecAction = "native_resources\\pod\\pod_shell",

  // K8s add-ons
  helmPage = "kubernetes_add-ons\\helm",
  helmDrawerResourcesTab = "kubernetes_add-ons\\helm\\resources",
  helmDrawerManifestsTab = "kubernetes_add-ons\\helm\\manifests",
  helmDrawerValuesTab = "kubernetes_add-ons\\helm\\values",
  helmDrawerNotesTab = "kubernetes_add-ons\\helm\\notes",

  certManagerCertificatesPage = "kubernetes_add-ons\\cert_manager\\certificates",
  certManagerCertificateDrawer = "kubernetes_add-ons\\cert_manager\\certificate",
  certManagerControllersPage = "kubernetes_add-ons\\cert_manager\\controllers",
  autoScalersPage = "kubernetes_add-ons\\node_autoscalers",
  externalDnsPage = "kubernetes_add-ons\\external_dns",
  externalDnsDrawerSummeryTab = "kubernetes_add-ons\\external_dns\\summary",
  autoScalersTab = "service_view\\resource_tab\\autoscalers",

  argoWorkflowPage = "kubernetes_add-ons\\workflows\\argo_workflow",
  argoWorkflowEventsTab = "kubernetes_add-ons\\workflows\\argo_workflow\\events",

  workflowEventsTab = "kubernetes_add-ons\\workflows\\events",
  workflowPage = "kubernetes_add-ons\\workflows",

  sparkWorkflowsPage = "kubernetes_add-ons\\workflows\\spark",
  airflowWorkflowsPage = "kubernetes_add-ons\\workflows\\airflow",
  // service view tabs
  serviceViewEventsTab = "service_view\\resource_tab\\events",
  serviceViewInfoTab = "service_view\\resource_tab\\info",
  serviceViewPodsTab = "service_view\\resource_tab\\pods",
  serviceViewNodesTab = "service_view\\resource_tab\\nodes",
  serviceViewPodsLogsTab = "service_view\\resource_tab\\pod_logs",
  serviceViewYamlTab = "service_view\\resource_tab\\yaml",
  serviceViewWorkloadMetricsTab = "service_view\\tabs\\workload_metrics",
  serviceViewPodMetricsTab = "service_view\\tabs\\pod_metrics",
  serviceViewNodeMetricsTab = "service_view\\tabs\\node_metrics",
  serviceViewPodDetailsTab = "service_view\\resource_tab\\pod_details",
  serviceViewExternalDnsTab = "service_view\\resource_tab\\external_dns",
  serviceViewRelatedResourcesTab = "service_view\\resource_tab\\related_resources",
  // Cost optimization
  costOptimizationAllocation = "application\\cost_optimization\\allocation",
  costOptimizationRightSizing = "application\\cost_optimization\\right-sizing",
  costOptimizationRecommendationsModal = "application\\cost_optimization\\recommendations",
  // Reliability overview
  reliabilityWorkloadHealth = "application\\workload_health\\issues",
  reliabilityBestPractices = "application\\workload_health\\standards",
  reliabilityInfrastructureHealth = "application\\infrastructure_health",
  // Reliability Violations drawer
  reliabilityDrawerCertificateExpiration = "workload_health\\violation\\expired_certificate",
  reliabilityDrawerExpiredHpaReachedMax = "workload_health\\violation\\hpa",
  reliabilityDrawerSinglePointOfFailure = "workload_health\\violation\\spof",
  reliabilityDrawerCpuThrottled = "workload_health\\violation\\cpu_throttled",
  reliabilityDrawerContainerRestarts = "workload_health\\violation\\container_restarts",
  reliabilityDrawerDegradedService = "workload_health\\violation\\degraded_service",
  reliabilityDrawerUnderProvisionedWorkloads = "workload_health\\violation\\under_provisioned",
  reliabilityDrawerHighRequestsToLimitRatio = "workload_health\\violation\\high_request_to_limit_ratio",
  reliabilityDrawerNodePressure = "workload_health\\violation\\node_pressure",
  reliabilityDrawerNoisyNeighbor = "workload_health\\violation\\noisy_neighbour",
  reliabilityDrawerEolDeprecationOutdated = "infrastructure_health\\violation\\eol_deprecation",
  reliabilityDrawerDeprecatedApi = "infrastructure_health\\violation\\api_deprecation",
  reliabilityDrawerKubernetesEndOfLife = "infrastructure_health\\violation\\k8s_end_of_life",
  reliabilityDrawerDeploymentMissingReplicas = "workload_health\\violation\\best_practices\\deployment_missing_replicas",
  reliabilityDrawerExternalDnsNotSynced = "workload_health\\violation\\best_practices\\external_dns_not_synced",
  reliabilityDrawerHpaMinAvailability = "workload_health\\violation\\best_practices\\hpa_min_availability",
  reliabilityDrawerLivenessProbeMissing = "workload_health\\violation\\best_practices\\liveness_probe_missing",
  reliabilityDrawerCpuLimitsMissing = "workload_health\\violation\\best_practices\\cpu_limits_missing",
  reliabilityDrawerCpuRequestsMissing = "workload_health\\violation\\best_practices\\cpu_requests_missing",
  reliabilityDrawerMissingHpa = "workload_health\\violation\\best_practices\\missing_hpa",
  reliabilityDrawerMemoryLimitsMissing = "workload_health\\violation\\best_practices\\memory_limits_missing",
  reliabilityDrawerMemoryRequestsMissing = "workload_health\\violation\\best_practices\\memory_requests_missing",
  reliabilityDrawerMissingPdb = "workload_health\\violation\\best_practices\\missing_pdb",
  reliabilityDrawerMissingTopologySpreadConstraint = "workload_health\\violation\\best_practices\\missing_topology_spread_constraint",
  reliabilityDrawerPriorityClassNotSet = "workload_health\\violation\\best_practices\\priority_class_not_set",
  reliabilityDrawerReadinessProbeMissing = "workload_health\\violation\\best_practices\\readiness_probe_missing",
  reliabilityDrawerScaleDownImpact = "workload_health\\violation\\scale_down_impact",
  reliabilityDrawerOverProvisionedCluster = "workload_health\\violation\\over_provisioned_cluster",
  // Reliability Violations drawer history
  reliabilityHistoryInWorkloadHealth = "workload_health\\violation\\history",
  reliabilityHistoryInInfrastructure = "infrastructure_health\\violation\\history",
  // Drift Analysis
  serviceDriftAnalysisAttributesTab = "application\\drift_analysis\\service\\attributes",
  serviceDriftAnalysisMetricsTab = "application\\drift_analysis\\service\\metrics",
  helmDriftAnalysisAttributes = "application\\drift_analysis\\helm\\attributes",
  //Workspaces
  workspaceOverview = "application\\overview",
  // Clusters View
  clusterList = "application\\clusters",

  // Search bar
  searchBar = "application\\search",

  // User Settings
  userSettingsProfilePage = "user_settings\\profile",
  userSettingsEffectivePermissionsPage = "user_settings\\my_permissions\\effective_permissions",
  userSettingsCanIPage = "user_settings\\my_permissions\\can-i",
  userSettingsEmailDigestPage = "user_settings\\email_digest_settings",
  userSettingsApiKeys = "user_settings\\api_keys",

  // Account Settings
  accountSettingsUsersPage = "organization_settings\\access_management\\users",
  accountSettingsRolesPage = "organization_settings\\access_management\\roles",
  accountSettingsPoliciesPage = "organization_settings\\access_management\\policies",
  accountSettingsIPWhitelistPage = "organization_settings\\access_management\\ip_whitelist",

  accountSettingsUsagePage = "organization_settings\\account\\usage",
  accountSettingsAuditPage = "organization_settings\\account\\audit",
  accountSettingsAgentsPage = "organization_settings\\account\\agents",

  // Configurations
  reliabilityPoliciesPage = "organization_settings\\configurations\\health_policies\\reliability",
  realtimePoliciesPage = "organization_settings\\configurations\\health_policies\\realtime",
  integrationsPage = "organization_settings\\configurations\\integrations",
  trackedKeysPage = "organization_settings\\configurations\\tracked_keys",

  // Service Explorer
  serviceExplorer = "application\\services",
  jobExplorer = "application\\jobs",

  // Event Drawers
  eventsView = "application\\events",
  eventJob = "event\\job",
  eventPodPhase = "event\\pod_phase",
  eventDeploy = "event\\deploy",
  eventConfigMap = "event\\configmap",
  eventHPA = "event\\hpa",
  eventNodeTerminated = "event\\node_terminated",
  eventNodeCreated = "event\\node_created",
  eventNodeUpdated = "event\\node_updated",
  eventAvailabilityIssue = "event\\availability_issue",
  pvcIssue = "event\\pvc_issue",
  nodeIssue = "event\\node_issue",
  jobIssue = "event\\job_issue",
  cronJobIssue = "event\\cron_job_issue",
  workflowIssue = "event\\workflow_issue",
  undefinedIssue = "event\\undefined_issue",

  // Investigation
  investigationModeIntroduction = "investigation_guide\\introduction",
  investigationModeCorrelatedDeploys = "investigation_guide\\correlated_deploys",
  investigationModeDependencies = "investigation_guide\\dependencies",
  investigationModeLimitDecreased = "investigation_guide\\limit_decreased",
  investigationModeMemoryLeak = "investigation_guide\\memory_leak",
  investigationModeNodeIssues = "investigation_guide\\node_events",
  investigationModeNoisyNeighbors = "investigation_guide\\noisy_neighbours",
  investigationModeUnhealthyPods = "investigation_guide\\unhealthy_pods",
  investigationModeContainerLogs = "investigation_guide\\container_logs",
  investigationModeMemoryAllocation = "investigation_guide\\memory_allocation",

  // Pop-ups
  accountSwitcher = "application\\account_switcher",
}

export type ViewLoadingKind = "initial" | "non_initial";

// https://docs.google.com/spreadsheets/d/1MYaQ4JjCuzkdjCkdjRnN7hE0dLOv8_BBts23wQS4ZnI/
export type DatadogContext = {
  feTeam: Team | Team[];
  beTeam: Team | Team[];
  filters?: {
    timeWindow?: string;
  };
  aggregation?: {
    groupBy: string;
  };
  resourceKind?: string;
  workspaceKind?: WorkspaceKind;
  viewLoadingKind?: ViewLoadingKind;
};
