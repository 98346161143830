import { KomodorServiceHealthStatus } from "komodor-types";
import pluralize from "pluralize";

import { ServiceInfo } from "../types/ServiceInfo";
import { KubernetesServiceTypeEnum } from "../types/ServiceVertex";
import { Dictionary } from "../types/Dictionary";
import { KubernetesCronJobResource } from "../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";
import { KubernetesDaemonSetResource } from "../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesStatefulSetResource } from "../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesStatefulSetResource";
import { KubernetesJobResource } from "../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";

export function ucFirstWord(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function extractServiceCluster(
  serviceId?: string,
  currentNamespace?: string
): string {
  if (!serviceId) return "unknown";
  const middlePart = serviceId.substring(serviceId.indexOf(".") + 1);
  return middlePart.substring(
    0,
    middlePart.indexOf("-" + (currentNamespace ?? ""))
  );
}

export function extractServiceName(serviceId?: string): string {
  if (!serviceId) return "unknown";
  return serviceId.split(".").slice(2).join(".");
}

export const isServiceHealthy = (
  healthStateStatus: string | undefined | null
): boolean => {
  if (healthStateStatus) {
    return healthStateStatus === KomodorServiceHealthStatus.Healthy;
  }
  return true;
};

export const isDataNone = (cellData: string): boolean => cellData === "<none>";

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export function findService(
  services: ServiceInfo[] | undefined,
  value: string,
  clusterName: string,
  pageName: string,
  namespace?: string
): ServiceInfo | undefined {
  return services?.find((service) => {
    const serviceName =
      (service?.kind?.toLowerCase() === "deployment" ||
        service?.kind?.toLowerCase() === "rollout" ||
        service?.kind?.toLowerCase() === "cronjob") &&
      (pageName.toLowerCase() === "pods" || pageName.toLowerCase() === "jobs")
        ? value.split("-").slice(0, -1).join("-")
        : value;

    return service.id.endsWith(
      `${clusterName}-${namespace || ""}.${serviceName}`
    );
  });
}

const resourcesWithNoOwner = [
  "deployments",
  "jobs",
  "cronjobs",
  "daemonsets",
  "statefulsets",
];

type FindServiceParams = ComposeServiceIdParams & {
  servicesAsDictionary: Dictionary<ServiceInfo>;
  pageName: string;
};

type ComposeServiceIdParams = {
  accountName: string;
  clusterName: string;
  namespace?: string;
  value: string;
};

export const findServiceByDictionary = (
  params: FindServiceParams
): ServiceInfo | undefined => {
  const { servicesAsDictionary, pageName } = params;
  const pageNameAligned = pluralize(pageName.toLowerCase());
  const serviceId = composeServiceIdByPageName({
    ...params,
    pageName: pageNameAligned,
  });
  const service = servicesAsDictionary[serviceId];
  if (!service) {
    if (pageNameAligned === "pods") {
      const podsWithSingleOwner = composeServiceIdByPageName({
        ...params,
        pageName: "podsWithSingleOwner",
      });
      const foundService = servicesAsDictionary[podsWithSingleOwner];
      if (!foundService) return undefined;
      if (
        foundService.kind === KubernetesDaemonSetResource.Kind ||
        foundService.kind === KubernetesStatefulSetResource.Kind ||
        foundService.kind === KubernetesJobResource.Kind
      ) {
        return foundService;
      }
    } else if (pageNameAligned === "jobs") {
      const serviceId = composeServiceIdByPageName({
        ...params,
        pageName: "jobsOfCronJobs",
      });
      const foundService = servicesAsDictionary[serviceId];
      if (foundService?.kind === KubernetesCronJobResource.Kind) {
        return foundService;
      }
    }
  }

  return service;
};

const composeServiceIdByPageName = ({
  accountName,
  clusterName,
  namespace,
  value,
  pageName,
}: FindServiceParams) => {
  const slicingNum = pageName === "pods" ? -2 : -1;
  const needsSlicing = !resourcesWithNoOwner.includes(pageName);
  const name = needsSlicing
    ? value.split("-").slice(0, slicingNum).join("-")
    : value;

  return composeServiceId({
    accountName,
    clusterName,
    namespace,
    value: name,
  });
};

export const composeServiceId = ({
  accountName,
  clusterName,
  namespace,
  value,
}: ComposeServiceIdParams) => {
  return `${accountName}.${clusterName}-${namespace || ""}.${value}`;
};

type FindServiceForPodParams = {
  services?: ServiceInfo[];
  podControlledBy: string;
  clusterName: string;
  namespace: string;
};

export const findServiceForPod = ({
  services,
  podControlledBy,
  namespace,
  clusterName,
}: FindServiceForPodParams): ServiceInfo | undefined => {
  if (!services || isDataNone(podControlledBy) || !podControlledBy)
    return undefined;

  return services.find((service) => {
    let podIdentifier;
    switch (service.kind) {
      case "DaemonSet":
      case "StatefulSet":
      case "Job": {
        podIdentifier = podControlledBy;
        break;
      }
      default: {
        podIdentifier = podControlledBy.slice(
          0,
          podControlledBy.lastIndexOf("-")
        );
      }
    }

    return service.id.endsWith(`${clusterName}-${namespace}.${podIdentifier}`);
  });
};

const servicesKinds = [
  KubernetesServiceTypeEnum.Deployment.toString().toLowerCase(),
  KubernetesServiceTypeEnum.StatefulSet.toString().toLowerCase(),
  KubernetesServiceTypeEnum.DaemonSet.toString().toLowerCase(),
  KubernetesServiceTypeEnum.Rollout.toString().toLowerCase(),
];

export const isServiceKind = (kind: string): boolean =>
  servicesKinds.includes(kind.toLowerCase());

// [CU-86c1gn74n] fix max-params
// eslint-disable-next-line max-params
export function buildServiceId(
  accountName: string,
  cluster: string,
  namespace: string,
  resourceName: string
): string {
  return `${accountName}.${cluster}-${namespace}.${resourceName}`;
}

export const isServiceTypeJob = (service: ServiceInfo): boolean =>
  service.kind === "Job" || service.kind === "CronJob";
