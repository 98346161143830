import { useMemo } from "react";

import {
  NodeIssueDataParsed,
  NodeTerminationDataParsed,
} from "../../common/types";
import { WorkflowIssueStatus } from "../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";

import { getPluralEnabledMessage } from "@/shared/utils/textUtils";
import { MonitorType } from "@/generated/monitorsApi";
import { useGetMonitorIssuesByType } from "@/shared/hooks/monitors-api/client/issues/useGetMonitorIssuesByType";

export const useUpdatedNodeIssuesInsight = (
  issuesInsights: NodeIssueDataParsed[]
): NodeIssueDataParsed[] => {
  const ids = useMemo(() => issuesInsights.map((e) => e.id), [issuesInsights]);
  const { data: monitorIssuesResponse } = useGetMonitorIssuesByType(
    {
      ids,
      type: MonitorType.Availability,
      fields: ["id", "closedAt"],
    },
    ids.length > 0
  );

  return useMemo(() => {
    return issuesInsights.map((issue) => {
      const issueClosedAt = monitorIssuesResponse?.data?.find(
        (issueUpdated) => issue.id === issueUpdated.id
      )?.closedAt;
      return {
        ...issue,
        status: issueClosedAt
          ? WorkflowIssueStatus.closed
          : issueClosedAt === null
          ? WorkflowIssueStatus.open
          : issue.status,
      };
    });
  }, [monitorIssuesResponse?.data, issuesInsights]);
};

export const useCorrelatedNodeEventsInsight = (
  latestNodeIssuesInsights: NodeIssueDataParsed[],
  latestNodeTerminationsInsights: NodeTerminationDataParsed[]
) => {
  const latestNodeIssuesInsightsUpdated = useUpdatedNodeIssuesInsight(
    latestNodeIssuesInsights
  );
  const nodeIssuesLength = latestNodeIssuesInsightsUpdated.length;
  const nodeTerminationsLength = latestNodeTerminationsInsights.length;

  return {
    shouldShow: nodeIssuesLength + nodeTerminationsLength > 0,
    text: `Correlated node events detected - ${
      nodeIssuesLength > 0
        ? getPluralEnabledMessage(nodeIssuesLength, "node issue")
        : null
    }
    ${nodeTerminationsLength > 0 && nodeIssuesLength > 0 ? ", " : null}
    ${
      nodeTerminationsLength > 0
        ? getPluralEnabledMessage(nodeTerminationsLength, "node termination")
        : null
    }`,
    latestNodeIssuesInsightsUpdated,
  };
};
