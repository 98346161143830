import styled from "styled-components";

export const ShortPanel = styled.div<{
  center?: boolean;
  spaceBetween?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 16px;
  gap: 10px;
  ${({ center }) => center && "align-items: center; justify-content: center;"}
  ${({ spaceBetween }) => spaceBetween && "justify-content: space-between;"}
`;
