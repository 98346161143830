/* tslint:disable */
/* eslint-disable */
/**
 * Accounts Service API
 * This is an API that exposes account-related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'disabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'identityProvider': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'trialEndAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'origin': string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isRbacEnabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'logsRetentionDays': number | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'plan': string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'purchaseDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'acquisitionChannel': string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'salesforceAccountId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'inPoc': boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'marketplaceConnectionId'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Account
     */
    'features': { [key: string]: any; };
}


/**
 * Check if a given object implements the Account interface.
 */
export function instanceOfAccount(value: object): value is Account {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('disabled' in value) || value['disabled'] === undefined) return false;
    if (!('identityProvider' in value) || value['identityProvider'] === undefined) return false;
    if (!('trialEndAt' in value) || value['trialEndAt'] === undefined) return false;
    if (!('origin' in value) || value['origin'] === undefined) return false;
    if (!('isRbacEnabled' in value) || value['isRbacEnabled'] === undefined) return false;
    if (!('logsRetentionDays' in value) || value['logsRetentionDays'] === undefined) return false;
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('purchaseDate' in value) || value['purchaseDate'] === undefined) return false;
    if (!('acquisitionChannel' in value) || value['acquisitionChannel'] === undefined) return false;
    if (!('salesforceAccountId' in value) || value['salesforceAccountId'] === undefined) return false;
    if (!('inPoc' in value) || value['inPoc'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    return true;
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'identityProvider': json['identityProvider'],
        'trialEndAt': json['trialEndAt'],
        'origin': json['origin'],
        'isRbacEnabled': json['isRbacEnabled'],
        'logsRetentionDays': json['logsRetentionDays'],
        'plan': json['plan'],
        'purchaseDate': json['purchaseDate'],
        'acquisitionChannel': json['acquisitionChannel'],
        'salesforceAccountId': json['salesforceAccountId'],
        'inPoc': json['inPoc'],
        'marketplaceConnectionId': json['marketplaceConnectionId'] == null ? undefined : json['marketplaceConnectionId'],
        'features': json['features'],
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'displayName': value['displayName'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'disabled': value['disabled'],
        'identityProvider': value['identityProvider'],
        'trialEndAt': value['trialEndAt'],
        'origin': value['origin'],
        'isRbacEnabled': value['isRbacEnabled'],
        'logsRetentionDays': value['logsRetentionDays'],
        'plan': value['plan'],
        'purchaseDate': value['purchaseDate'],
        'acquisitionChannel': value['acquisitionChannel'],
        'salesforceAccountId': value['salesforceAccountId'],
        'inPoc': value['inPoc'],
        'marketplaceConnectionId': value['marketplaceConnectionId'],
        'features': value['features'],
    };
}

        /**
 * 
 * @export
 * @interface AccountDomainMapping
 */
export interface AccountDomainMapping {
    /**
     * 
     * @type {number}
     * @memberof AccountDomainMapping
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountDomainMapping
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDomainMapping
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDomainMapping
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDomainMapping
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDomainMapping
     */
    'connectionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDomainMapping
     */
    'idpType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDomainMapping
     */
    'enforceIdpOnly'?: boolean;
}


/**
 * Check if a given object implements the AccountDomainMapping interface.
 */
export function instanceOfAccountDomainMapping(value: object): value is AccountDomainMapping {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    return true;
}

export function AccountDomainMappingFromJSON(json: any): AccountDomainMapping {
    return AccountDomainMappingFromJSONTyped(json, false);
}

export function AccountDomainMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDomainMapping {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'domain': json['domain'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'accountId': json['accountId'],
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'idpType': json['idpType'] == null ? undefined : json['idpType'],
        'enforceIdpOnly': json['enforceIdpOnly'] == null ? undefined : json['enforceIdpOnly'],
    };
}

export function AccountDomainMappingToJSON(value?: AccountDomainMapping | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'domain': value['domain'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'accountId': value['accountId'],
        'connectionName': value['connectionName'],
        'idpType': value['idpType'],
        'enforceIdpOnly': value['enforceIdpOnly'],
    };
}

        /**
 * 
 * @export
 * @interface AccountFeatureApiKeysTTL
 */
export interface AccountFeatureApiKeysTTL {
    /**
     * 
     * @type {ApiKeysTTL}
     * @memberof AccountFeatureApiKeysTTL
     */
    'apiKeysTTL': ApiKeysTTL;
}


/**
 * Check if a given object implements the AccountFeatureApiKeysTTL interface.
 */
export function instanceOfAccountFeatureApiKeysTTL(value: object): value is AccountFeatureApiKeysTTL {
    if (!('apiKeysTTL' in value) || value['apiKeysTTL'] === undefined) return false;
    return true;
}

export function AccountFeatureApiKeysTTLFromJSON(json: any): AccountFeatureApiKeysTTL {
    return AccountFeatureApiKeysTTLFromJSONTyped(json, false);
}

export function AccountFeatureApiKeysTTLFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountFeatureApiKeysTTL {
    if (json == null) {
        return json;
    }
    return {
        
        'apiKeysTTL': ApiKeysTTLFromJSON(json['apiKeysTTL']),
    };
}

export function AccountFeatureApiKeysTTLToJSON(value?: AccountFeatureApiKeysTTL | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'apiKeysTTL': ApiKeysTTLToJSON(value['apiKeysTTL']),
    };
}

        /**
 * 
 * @export
 * @interface AccountFeaturesResponse
 */
export interface AccountFeaturesResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountFeaturesResponse
     */
    'id': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AccountFeaturesResponse
     */
    'features': { [key: string]: any; };
}


/**
 * Check if a given object implements the AccountFeaturesResponse interface.
 */
export function instanceOfAccountFeaturesResponse(value: object): value is AccountFeaturesResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    return true;
}

export function AccountFeaturesResponseFromJSON(json: any): AccountFeaturesResponse {
    return AccountFeaturesResponseFromJSONTyped(json, false);
}

export function AccountFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountFeaturesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'features': json['features'],
    };
}

export function AccountFeaturesResponseToJSON(value?: AccountFeaturesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'features': value['features'],
    };
}

        /**
 * 
 * @export
 * @interface AccountStats
 */
export interface AccountStats {
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'plan': string;
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'trialEndAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'accountCreatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountStats
     */
    'lastUserCreatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof AccountStats
     */
    'usersCreatedLastFullMonth': number;
    /**
     * 
     * @type {number}
     * @memberof AccountStats
     */
    'WeeklyUniqueActiveUsers': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountStats
     */
    'emailDomains': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccountStats
     */
    'monthlyNodesCount': number;
}


/**
 * Check if a given object implements the AccountStats interface.
 */
export function instanceOfAccountStats(value: object): value is AccountStats {
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('accountCreatedAt' in value) || value['accountCreatedAt'] === undefined) return false;
    if (!('lastUserCreatedAt' in value) || value['lastUserCreatedAt'] === undefined) return false;
    if (!('usersCreatedLastFullMonth' in value) || value['usersCreatedLastFullMonth'] === undefined) return false;
    if (!('WeeklyUniqueActiveUsers' in value) || value['WeeklyUniqueActiveUsers'] === undefined) return false;
    if (!('emailDomains' in value) || value['emailDomains'] === undefined) return false;
    if (!('monthlyNodesCount' in value) || value['monthlyNodesCount'] === undefined) return false;
    return true;
}

export function AccountStatsFromJSON(json: any): AccountStats {
    return AccountStatsFromJSONTyped(json, false);
}

export function AccountStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountStats {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'],
        'name': json['name'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'plan': json['plan'],
        'trialEndAt': json['trialEndAt'] == null ? undefined : json['trialEndAt'],
        'accountCreatedAt': json['accountCreatedAt'],
        'lastUserCreatedAt': json['lastUserCreatedAt'],
        'usersCreatedLastFullMonth': json['usersCreatedLastFullMonth'],
        'WeeklyUniqueActiveUsers': json['WeeklyUniqueActiveUsers'],
        'emailDomains': json['emailDomains'],
        'monthlyNodesCount': json['monthlyNodesCount'],
    };
}

export function AccountStatsToJSON(value?: AccountStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'name': value['name'],
        'displayName': value['displayName'],
        'plan': value['plan'],
        'trialEndAt': value['trialEndAt'],
        'accountCreatedAt': value['accountCreatedAt'],
        'lastUserCreatedAt': value['lastUserCreatedAt'],
        'usersCreatedLastFullMonth': value['usersCreatedLastFullMonth'],
        'WeeklyUniqueActiveUsers': value['WeeklyUniqueActiveUsers'],
        'emailDomains': value['emailDomains'],
        'monthlyNodesCount': value['monthlyNodesCount'],
    };
}

        /**
 * 
 * @export
 * @interface ApiKeysTTL
 */
export interface ApiKeysTTL {
    /**
     * 
     * @type {boolean}
     * @memberof ApiKeysTTL
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiKeysTTL
     */
    'TTLInDays': number;
}


/**
 * Check if a given object implements the ApiKeysTTL interface.
 */
export function instanceOfApiKeysTTL(value: object): value is ApiKeysTTL {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    if (!('TTLInDays' in value) || value['TTLInDays'] === undefined) return false;
    return true;
}

export function ApiKeysTTLFromJSON(json: any): ApiKeysTTL {
    return ApiKeysTTLFromJSONTyped(json, false);
}

export function ApiKeysTTLFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeysTTL {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'TTLInDays': json['TTLInDays'],
    };
}

export function ApiKeysTTLToJSON(value?: ApiKeysTTL | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
        'TTLInDays': value['TTLInDays'],
    };
}

        /**
 * 
 * @export
 * @interface ApiV1AccountsIdFeaturesPutRequest
 */
export interface ApiV1AccountsIdFeaturesPutRequest {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ApiV1AccountsIdFeaturesPutRequest
     */
    'features'?: { [key: string]: any; };
}


/**
 * Check if a given object implements the ApiV1AccountsIdFeaturesPutRequest interface.
 */
export function instanceOfApiV1AccountsIdFeaturesPutRequest(value: object): value is ApiV1AccountsIdFeaturesPutRequest {
    return true;
}

export function ApiV1AccountsIdFeaturesPutRequestFromJSON(json: any): ApiV1AccountsIdFeaturesPutRequest {
    return ApiV1AccountsIdFeaturesPutRequestFromJSONTyped(json, false);
}

export function ApiV1AccountsIdFeaturesPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1AccountsIdFeaturesPutRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'features': json['features'] == null ? undefined : json['features'],
    };
}

export function ApiV1AccountsIdFeaturesPutRequestToJSON(value?: ApiV1AccountsIdFeaturesPutRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'features': value['features'],
    };
}

        /**
 * 
 * @export
 * @interface ApiV1WhitelistCidrIdPutRequest
 */
export interface ApiV1WhitelistCidrIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1WhitelistCidrIdPutRequest
     */
    'cidr'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1WhitelistCidrIdPutRequest
     */
    'description'?: string;
}


/**
 * Check if a given object implements the ApiV1WhitelistCidrIdPutRequest interface.
 */
export function instanceOfApiV1WhitelistCidrIdPutRequest(value: object): value is ApiV1WhitelistCidrIdPutRequest {
    return true;
}

export function ApiV1WhitelistCidrIdPutRequestFromJSON(json: any): ApiV1WhitelistCidrIdPutRequest {
    return ApiV1WhitelistCidrIdPutRequestFromJSONTyped(json, false);
}

export function ApiV1WhitelistCidrIdPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1WhitelistCidrIdPutRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'cidr': json['cidr'] == null ? undefined : json['cidr'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function ApiV1WhitelistCidrIdPutRequestToJSON(value?: ApiV1WhitelistCidrIdPutRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cidr': value['cidr'],
        'description': value['description'],
    };
}

        /**
 * 
 * @export
 * @interface ApiV1WhitelistCidrPostRequest
 */
export interface ApiV1WhitelistCidrPostRequest {
    /**
     * 
     * @type {Array<WhitelistCIDRCreateOneRequest>}
     * @memberof ApiV1WhitelistCidrPostRequest
     */
    'cidrs'?: Array<WhitelistCIDRCreateOneRequest>;
}


/**
 * Check if a given object implements the ApiV1WhitelistCidrPostRequest interface.
 */
export function instanceOfApiV1WhitelistCidrPostRequest(value: object): value is ApiV1WhitelistCidrPostRequest {
    return true;
}

export function ApiV1WhitelistCidrPostRequestFromJSON(json: any): ApiV1WhitelistCidrPostRequest {
    return ApiV1WhitelistCidrPostRequestFromJSONTyped(json, false);
}

export function ApiV1WhitelistCidrPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1WhitelistCidrPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'cidrs': json['cidrs'] == null ? undefined : ((json['cidrs'] as Array<any>).map(WhitelistCIDRCreateOneRequestFromJSON)),
    };
}

export function ApiV1WhitelistCidrPostRequestToJSON(value?: ApiV1WhitelistCidrPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cidrs': value['cidrs'] == null ? undefined : ((value['cidrs'] as Array<any>).map(WhitelistCIDRCreateOneRequestToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface Audit
 */
export interface Audit {
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'crudType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'eventTimestamp'?: string;
    /**
     * 
     * @type {object}
     * @memberof Audit
     */
    'additionalDetails'?: object;
    /**
     * 
     * @type {object}
     * @memberof Audit
     */
    'details'?: object;
}


/**
 * Check if a given object implements the Audit interface.
 */
export function instanceOfAudit(value: object): value is Audit {
    return true;
}

export function AuditFromJSON(json: any): Audit {
    return AuditFromJSONTyped(json, false);
}

export function AuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Audit {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'category': json['category'] == null ? undefined : json['category'],
        'crudType': json['crudType'] == null ? undefined : json['crudType'],
        'status': json['status'] == null ? undefined : json['status'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'updatedAt': json['updatedAt'] == null ? undefined : json['updatedAt'],
        'eventTimestamp': json['eventTimestamp'] == null ? undefined : json['eventTimestamp'],
        'additionalDetails': json['additionalDetails'] == null ? undefined : json['additionalDetails'],
        'details': json['details'] == null ? undefined : json['details'],
    };
}

export function AuditToJSON(value?: Audit | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'accountId': value['accountId'],
        'userId': value['userId'],
        'category': value['category'],
        'crudType': value['crudType'],
        'status': value['status'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'eventTimestamp': value['eventTimestamp'],
        'additionalDetails': value['additionalDetails'],
        'details': value['details'],
    };
}

        /**
 * 
 * @export
 * @interface AuditCreateRequest
 */
export interface AuditCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditCreateRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateRequest
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateRequest
     */
    'crudType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateRequest
     */
    'status'?: string;
    /**
     * 
     * @type {object}
     * @memberof AuditCreateRequest
     */
    'additionalDetails'?: object;
    /**
     * 
     * @type {object}
     * @memberof AuditCreateRequest
     */
    'details'?: object;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateRequest
     */
    'eventTimestamp'?: string;
}


/**
 * Check if a given object implements the AuditCreateRequest interface.
 */
export function instanceOfAuditCreateRequest(value: object): value is AuditCreateRequest {
    return true;
}

export function AuditCreateRequestFromJSON(json: any): AuditCreateRequest {
    return AuditCreateRequestFromJSONTyped(json, false);
}

export function AuditCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'category': json['category'] == null ? undefined : json['category'],
        'crudType': json['crudType'] == null ? undefined : json['crudType'],
        'status': json['status'] == null ? undefined : json['status'],
        'additionalDetails': json['additionalDetails'] == null ? undefined : json['additionalDetails'],
        'details': json['details'] == null ? undefined : json['details'],
        'eventTimestamp': json['eventTimestamp'] == null ? undefined : json['eventTimestamp'],
    };
}

export function AuditCreateRequestToJSON(value?: AuditCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'userId': value['userId'],
        'category': value['category'],
        'crudType': value['crudType'],
        'status': value['status'],
        'additionalDetails': value['additionalDetails'],
        'details': value['details'],
        'eventTimestamp': value['eventTimestamp'],
    };
}

        /**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'userId': string;
    /**
     * 
     * @type {AuditLogCategory}
     * @memberof AuditLog
     */
    'category': AuditLogCategory;
    /**
     * 
     * @type {AuditLogOperation}
     * @memberof AuditLog
     */
    'operation': AuditLogOperation;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'entityType': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'entityName'?: string;
    /**
     * 
     * @type {AuditLogStatus}
     * @memberof AuditLog
     */
    'status': AuditLogStatus;
    /**
     * 
     * @type {object}
     * @memberof AuditLog
     */
    'details': object;
    /**
     * 
     * @type {boolean}
     * @memberof AuditLog
     */
    'isImpersonated'?: boolean;
}




/**
 * Check if a given object implements the AuditLog interface.
 */
export function instanceOfAuditLog(value: object): value is AuditLog {
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    if (!('operation' in value) || value['operation'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('details' in value) || value['details'] === undefined) return false;
    return true;
}

export function AuditLogFromJSON(json: any): AuditLog {
    return AuditLogFromJSONTyped(json, false);
}

export function AuditLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLog {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'eventTime': json['eventTime'] == null ? undefined : json['eventTime'],
        'accountId': json['accountId'],
        'userId': json['userId'],
        'category': AuditLogCategoryFromJSON(json['category']),
        'operation': AuditLogOperationFromJSON(json['operation']),
        'action': json['action'],
        'entityType': json['entityType'],
        'entityId': json['entityId'] == null ? undefined : json['entityId'],
        'entityName': json['entityName'] == null ? undefined : json['entityName'],
        'status': AuditLogStatusFromJSON(json['status']),
        'details': json['details'],
        'isImpersonated': json['isImpersonated'] == null ? undefined : json['isImpersonated'],
    };
}

export function AuditLogToJSON(value?: AuditLog | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdAt': value['createdAt'],
        'eventTime': value['eventTime'],
        'accountId': value['accountId'],
        'userId': value['userId'],
        'category': AuditLogCategoryToJSON(value['category']),
        'operation': AuditLogOperationToJSON(value['operation']),
        'action': value['action'],
        'entityType': value['entityType'],
        'entityId': value['entityId'],
        'entityName': value['entityName'],
        'status': AuditLogStatusToJSON(value['status']),
        'details': value['details'],
        'isImpersonated': value['isImpersonated'],
    };
}

        
/**
 * 
 * @export
 */
export const AuditLogCategory = {
    Account: 'account',
    User: 'user',
    Resource: 'resource',
    Rbac: 'rbac',
    Authentication: 'authentication'
} as const;
export type AuditLogCategory = typeof AuditLogCategory[keyof typeof AuditLogCategory];


export function instanceOfAuditLogCategory(value: any): boolean {
    for (const key in AuditLogCategory) {
        if (Object.prototype.hasOwnProperty.call(AuditLogCategory, key)) {
            if (AuditLogCategory[key as keyof typeof AuditLogCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AuditLogCategoryFromJSON(json: any): AuditLogCategory {
    return AuditLogCategoryFromJSONTyped(json, false);
}

export function AuditLogCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogCategory {
    return json as AuditLogCategory;
}

export function AuditLogCategoryToJSON(value?: AuditLogCategory | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface AuditLogFilters
 */
export interface AuditLogFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditLogFilters
     */
    'actions': Array<string>;
    /**
     * 
     * @type {Array<AuditLogOperation>}
     * @memberof AuditLogFilters
     */
    'operations': Array<AuditLogOperation>;
    /**
     * 
     * @type {Array<AuditLogCategory>}
     * @memberof AuditLogFilters
     */
    'categories': Array<AuditLogCategory>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditLogFilters
     */
    'entityTypes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditLogFilters
     */
    'userIds': Array<string>;
    /**
     * 
     * @type {Array<AuditLogStatus>}
     * @memberof AuditLogFilters
     */
    'status': Array<AuditLogStatus>;
}


/**
 * Check if a given object implements the AuditLogFilters interface.
 */
export function instanceOfAuditLogFilters(value: object): value is AuditLogFilters {
    if (!('actions' in value) || value['actions'] === undefined) return false;
    if (!('operations' in value) || value['operations'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('entityTypes' in value) || value['entityTypes'] === undefined) return false;
    if (!('userIds' in value) || value['userIds'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function AuditLogFiltersFromJSON(json: any): AuditLogFilters {
    return AuditLogFiltersFromJSONTyped(json, false);
}

export function AuditLogFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'actions': json['actions'],
        'operations': ((json['operations'] as Array<any>).map(AuditLogOperationFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(AuditLogCategoryFromJSON)),
        'entityTypes': json['entityTypes'],
        'userIds': json['userIds'],
        'status': ((json['status'] as Array<any>).map(AuditLogStatusFromJSON)),
    };
}

export function AuditLogFiltersToJSON(value?: AuditLogFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actions': value['actions'],
        'operations': ((value['operations'] as Array<any>).map(AuditLogOperationToJSON)),
        'categories': ((value['categories'] as Array<any>).map(AuditLogCategoryToJSON)),
        'entityTypes': value['entityTypes'],
        'userIds': value['userIds'],
        'status': ((value['status'] as Array<any>).map(AuditLogStatusToJSON)),
    };
}

        
/**
 * 
 * @export
 */
export const AuditLogOperation = {
    Create: 'create',
    Read: 'read',
    Update: 'update',
    Delete: 'delete',
    Execute: 'execute'
} as const;
export type AuditLogOperation = typeof AuditLogOperation[keyof typeof AuditLogOperation];


export function instanceOfAuditLogOperation(value: any): boolean {
    for (const key in AuditLogOperation) {
        if (Object.prototype.hasOwnProperty.call(AuditLogOperation, key)) {
            if (AuditLogOperation[key as keyof typeof AuditLogOperation] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AuditLogOperationFromJSON(json: any): AuditLogOperation {
    return AuditLogOperationFromJSONTyped(json, false);
}

export function AuditLogOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogOperation {
    return json as AuditLogOperation;
}

export function AuditLogOperationToJSON(value?: AuditLogOperation | null): any {
    return value as any;
}

        
/**
 * 
 * @export
 */
export const AuditLogStatus = {
    Success: 'success',
    Failure: 'failure'
} as const;
export type AuditLogStatus = typeof AuditLogStatus[keyof typeof AuditLogStatus];


export function instanceOfAuditLogStatus(value: any): boolean {
    for (const key in AuditLogStatus) {
        if (Object.prototype.hasOwnProperty.call(AuditLogStatus, key)) {
            if (AuditLogStatus[key as keyof typeof AuditLogStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AuditLogStatusFromJSON(json: any): AuditLogStatus {
    return AuditLogStatusFromJSONTyped(json, false);
}

export function AuditLogStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogStatus {
    return json as AuditLogStatus;
}

export function AuditLogStatusToJSON(value?: AuditLogStatus | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface CIDRWhitelist
 */
export interface CIDRWhitelist {
    /**
     * 
     * @type {string}
     * @memberof CIDRWhitelist
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CIDRWhitelist
     */
    'cidr': string;
    /**
     * 
     * @type {string}
     * @memberof CIDRWhitelist
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CIDRWhitelist
     */
    'accountId': string;
}


/**
 * Check if a given object implements the CIDRWhitelist interface.
 */
export function instanceOfCIDRWhitelist(value: object): value is CIDRWhitelist {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('cidr' in value) || value['cidr'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    return true;
}

export function CIDRWhitelistFromJSON(json: any): CIDRWhitelist {
    return CIDRWhitelistFromJSONTyped(json, false);
}

export function CIDRWhitelistFromJSONTyped(json: any, ignoreDiscriminator: boolean): CIDRWhitelist {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cidr': json['cidr'],
        'description': json['description'] == null ? undefined : json['description'],
        'accountId': json['accountId'],
    };
}

export function CIDRWhitelistToJSON(value?: CIDRWhitelist | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'cidr': value['cidr'],
        'description': value['description'],
        'accountId': value['accountId'],
    };
}

        /**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'identityProvider'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'trialEndAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'origin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    'isRbacEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountRequest
     */
    'logsRetentionDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'purchaseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'acquisitionChannel'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'salesforceAccountId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    'inPoc'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateAccountRequest
     */
    'features'?: { [key: string]: any; };
}


/**
 * Check if a given object implements the CreateAccountRequest interface.
 */
export function instanceOfCreateAccountRequest(value: object): value is CreateAccountRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CreateAccountRequestFromJSON(json: any): CreateAccountRequest {
    return CreateAccountRequestFromJSONTyped(json, false);
}

export function CreateAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'disabled': json['disabled'] == null ? undefined : json['disabled'],
        'identityProvider': json['identityProvider'] == null ? undefined : json['identityProvider'],
        'trialEndAt': json['trialEndAt'] == null ? undefined : json['trialEndAt'],
        'origin': json['origin'] == null ? undefined : json['origin'],
        'isRbacEnabled': json['isRbacEnabled'] == null ? undefined : json['isRbacEnabled'],
        'logsRetentionDays': json['logsRetentionDays'] == null ? undefined : json['logsRetentionDays'],
        'plan': json['plan'] == null ? undefined : json['plan'],
        'purchaseDate': json['purchaseDate'] == null ? undefined : json['purchaseDate'],
        'acquisitionChannel': json['acquisitionChannel'] == null ? undefined : json['acquisitionChannel'],
        'salesforceAccountId': json['salesforceAccountId'] == null ? undefined : json['salesforceAccountId'],
        'inPoc': json['inPoc'] == null ? undefined : json['inPoc'],
        'features': json['features'] == null ? undefined : json['features'],
    };
}

export function CreateAccountRequestToJSON(value?: CreateAccountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'disabled': value['disabled'],
        'identityProvider': value['identityProvider'],
        'trialEndAt': value['trialEndAt'],
        'origin': value['origin'],
        'isRbacEnabled': value['isRbacEnabled'],
        'logsRetentionDays': value['logsRetentionDays'],
        'plan': value['plan'],
        'purchaseDate': value['purchaseDate'],
        'acquisitionChannel': value['acquisitionChannel'],
        'salesforceAccountId': value['salesforceAccountId'],
        'inPoc': value['inPoc'],
        'features': value['features'],
    };
}

        /**
 * 
 * @export
 * @interface EventCount
 */
export interface EventCount {
    /**
     * 
     * @type {string}
     * @memberof EventCount
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCount
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCount
     */
    'collectedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCount
     */
    'collectedTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventCount
     */
    'sumEvents'?: number;
}


/**
 * Check if a given object implements the EventCount interface.
 */
export function instanceOfEventCount(value: object): value is EventCount {
    return true;
}

export function EventCountFromJSON(json: any): EventCount {
    return EventCountFromJSONTyped(json, false);
}

export function EventCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventCount {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'collectedFrom': json['collectedFrom'] == null ? undefined : json['collectedFrom'],
        'collectedTo': json['collectedTo'] == null ? undefined : json['collectedTo'],
        'sumEvents': json['sumEvents'] == null ? undefined : json['sumEvents'],
    };
}

export function EventCountToJSON(value?: EventCount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'accountId': value['accountId'],
        'collectedFrom': value['collectedFrom'],
        'collectedTo': value['collectedTo'],
        'sumEvents': value['sumEvents'],
    };
}

        /**
 * 
 * @export
 * @interface EventCountAggregate
 */
export interface EventCountAggregate {
    /**
     * 
     * @type {string}
     * @memberof EventCountAggregate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventCountAggregate
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof EventCountAggregate
     */
    'plan': string;
    /**
     * 
     * @type {number}
     * @memberof EventCountAggregate
     */
    'eventsCount': number;
}


/**
 * Check if a given object implements the EventCountAggregate interface.
 */
export function instanceOfEventCountAggregate(value: object): value is EventCountAggregate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('accountName' in value) || value['accountName'] === undefined) return false;
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('eventsCount' in value) || value['eventsCount'] === undefined) return false;
    return true;
}

export function EventCountAggregateFromJSON(json: any): EventCountAggregate {
    return EventCountAggregateFromJSONTyped(json, false);
}

export function EventCountAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventCountAggregate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accountName': json['accountName'],
        'plan': json['plan'],
        'eventsCount': json['eventsCount'],
    };
}

export function EventCountAggregateToJSON(value?: EventCountAggregate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'accountName': value['accountName'],
        'plan': value['plan'],
        'eventsCount': value['eventsCount'],
    };
}

        /**
 * 
 * @export
 * @interface EventCountLimits
 */
export interface EventCountLimits {
    /**
     * 
     * @type {number}
     * @memberof EventCountLimits
     */
    'collectedFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCountLimits
     */
    'collectedTo'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCountLimits
     */
    'sumEvents'?: number;
}


/**
 * Check if a given object implements the EventCountLimits interface.
 */
export function instanceOfEventCountLimits(value: object): value is EventCountLimits {
    return true;
}

export function EventCountLimitsFromJSON(json: any): EventCountLimits {
    return EventCountLimitsFromJSONTyped(json, false);
}

export function EventCountLimitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventCountLimits {
    if (json == null) {
        return json;
    }
    return {
        
        'collectedFrom': json['collectedFrom'] == null ? undefined : json['collectedFrom'],
        'collectedTo': json['collectedTo'] == null ? undefined : json['collectedTo'],
        'sumEvents': json['sumEvents'] == null ? undefined : json['sumEvents'],
    };
}

export function EventCountLimitsToJSON(value?: EventCountLimits | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collectedFrom': value['collectedFrom'],
        'collectedTo': value['collectedTo'],
        'sumEvents': value['sumEvents'],
    };
}

        /**
 * 
 * @export
 * @interface GithubInstallationCreateRequest
 */
export interface GithubInstallationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof GithubInstallationCreateRequest
     */
    'installationId': string;
}


/**
 * Check if a given object implements the GithubInstallationCreateRequest interface.
 */
export function instanceOfGithubInstallationCreateRequest(value: object): value is GithubInstallationCreateRequest {
    if (!('installationId' in value) || value['installationId'] === undefined) return false;
    return true;
}

export function GithubInstallationCreateRequestFromJSON(json: any): GithubInstallationCreateRequest {
    return GithubInstallationCreateRequestFromJSONTyped(json, false);
}

export function GithubInstallationCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubInstallationCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'installationId': json['installationId'],
    };
}

export function GithubInstallationCreateRequestToJSON(value?: GithubInstallationCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'installationId': value['installationId'],
    };
}

        /**
 * 
 * @export
 * @interface Installation
 */
export interface Installation {
    /**
     * 
     * @type {InstallationAccount}
     * @memberof Installation
     */
    'account': InstallationAccount;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'integration': string;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'user'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof Installation
     */
    'configuration': object | null;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'lookupId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Installation
     */
    'showInWeb': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Installation
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'deletedAt'?: string | null;
}


/**
 * Check if a given object implements the Installation interface.
 */
export function instanceOfInstallation(value: object): value is Installation {
    if (!('account' in value) || value['account'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('integration' in value) || value['integration'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('configuration' in value) || value['configuration'] === undefined) return false;
    if (!('lookupId' in value) || value['lookupId'] === undefined) return false;
    if (!('showInWeb' in value) || value['showInWeb'] === undefined) return false;
    if (!('isDeleted' in value) || value['isDeleted'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function InstallationFromJSON(json: any): Installation {
    return InstallationFromJSONTyped(json, false);
}

export function InstallationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Installation {
    if (json == null) {
        return json;
    }
    return {
        
        'account': InstallationAccountFromJSON(json['account']),
        'id': json['id'],
        'integration': json['integration'],
        'accountId': json['accountId'],
        'user': json['user'] == null ? undefined : json['user'],
        'configuration': json['configuration'],
        'lookupId': json['lookupId'],
        'showInWeb': json['showInWeb'],
        'isDeleted': json['isDeleted'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'deletedAt': json['deletedAt'] == null ? undefined : json['deletedAt'],
    };
}

export function InstallationToJSON(value?: Installation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'account': InstallationAccountToJSON(value['account']),
        'id': value['id'],
        'integration': value['integration'],
        'accountId': value['accountId'],
        'user': value['user'],
        'configuration': value['configuration'],
        'lookupId': value['lookupId'],
        'showInWeb': value['showInWeb'],
        'isDeleted': value['isDeleted'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'deletedAt': value['deletedAt'],
    };
}

        /**
 * 
 * @export
 * @interface InstallationAccount
 */
export interface InstallationAccount {
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationAccount
     */
    'disabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'identityProvider': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'trialEndAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'origin': string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationAccount
     */
    'isRbacEnabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof InstallationAccount
     */
    'logsRetentionDays': number | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'plan': string | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'purchaseDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'acquisitionChannel': string | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'salesforceAccountId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationAccount
     */
    'inPoc': boolean;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccount
     */
    'marketplaceConnectionId'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof InstallationAccount
     */
    'features': { [key: string]: any; };
}


/**
 * Check if a given object implements the InstallationAccount interface.
 */
export function instanceOfInstallationAccount(value: object): value is InstallationAccount {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('disabled' in value) || value['disabled'] === undefined) return false;
    if (!('identityProvider' in value) || value['identityProvider'] === undefined) return false;
    if (!('trialEndAt' in value) || value['trialEndAt'] === undefined) return false;
    if (!('origin' in value) || value['origin'] === undefined) return false;
    if (!('isRbacEnabled' in value) || value['isRbacEnabled'] === undefined) return false;
    if (!('logsRetentionDays' in value) || value['logsRetentionDays'] === undefined) return false;
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('purchaseDate' in value) || value['purchaseDate'] === undefined) return false;
    if (!('acquisitionChannel' in value) || value['acquisitionChannel'] === undefined) return false;
    if (!('salesforceAccountId' in value) || value['salesforceAccountId'] === undefined) return false;
    if (!('inPoc' in value) || value['inPoc'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    return true;
}

export function InstallationAccountFromJSON(json: any): InstallationAccount {
    return InstallationAccountFromJSONTyped(json, false);
}

export function InstallationAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'identityProvider': json['identityProvider'],
        'trialEndAt': json['trialEndAt'],
        'origin': json['origin'],
        'isRbacEnabled': json['isRbacEnabled'],
        'logsRetentionDays': json['logsRetentionDays'],
        'plan': json['plan'],
        'purchaseDate': json['purchaseDate'],
        'acquisitionChannel': json['acquisitionChannel'],
        'salesforceAccountId': json['salesforceAccountId'],
        'inPoc': json['inPoc'],
        'marketplaceConnectionId': json['marketplaceConnectionId'] == null ? undefined : json['marketplaceConnectionId'],
        'features': json['features'],
    };
}

export function InstallationAccountToJSON(value?: InstallationAccount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'displayName': value['displayName'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
        'disabled': value['disabled'],
        'identityProvider': value['identityProvider'],
        'trialEndAt': value['trialEndAt'],
        'origin': value['origin'],
        'isRbacEnabled': value['isRbacEnabled'],
        'logsRetentionDays': value['logsRetentionDays'],
        'plan': value['plan'],
        'purchaseDate': value['purchaseDate'],
        'acquisitionChannel': value['acquisitionChannel'],
        'salesforceAccountId': value['salesforceAccountId'],
        'inPoc': value['inPoc'],
        'marketplaceConnectionId': value['marketplaceConnectionId'],
        'features': value['features'],
    };
}

        /**
 * 
 * @export
 * @interface InstallationCreateRequest
 */
export interface InstallationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof InstallationCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCreateRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCreateRequest
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCreateRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {object}
     * @memberof InstallationCreateRequest
     */
    'configuration'?: object;
    /**
     * 
     * @type {string}
     * @memberof InstallationCreateRequest
     */
    'lookupId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationCreateRequest
     */
    'showInWeb'?: boolean;
}


/**
 * Check if a given object implements the InstallationCreateRequest interface.
 */
export function instanceOfInstallationCreateRequest(value: object): value is InstallationCreateRequest {
    return true;
}

export function InstallationCreateRequestFromJSON(json: any): InstallationCreateRequest {
    return InstallationCreateRequestFromJSONTyped(json, false);
}

export function InstallationCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'integration': json['integration'] == null ? undefined : json['integration'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'configuration': json['configuration'] == null ? undefined : json['configuration'],
        'lookupId': json['lookupId'] == null ? undefined : json['lookupId'],
        'showInWeb': json['showInWeb'] == null ? undefined : json['showInWeb'],
    };
}

export function InstallationCreateRequestToJSON(value?: InstallationCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'integration': value['integration'],
        'accountId': value['accountId'],
        'userId': value['userId'],
        'configuration': value['configuration'],
        'lookupId': value['lookupId'],
        'showInWeb': value['showInWeb'],
    };
}

        /**
 * 
 * @export
 * @interface InstallationUpdateRequest
 */
export interface InstallationUpdateRequest {
    /**
     * 
     * @type {object}
     * @memberof InstallationUpdateRequest
     */
    'configuration'?: object;
    /**
     * 
     * @type {string}
     * @memberof InstallationUpdateRequest
     */
    'lookupId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationUpdateRequest
     */
    'showInWeb'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationUpdateRequest
     */
    'isDeleted'?: boolean;
}


/**
 * Check if a given object implements the InstallationUpdateRequest interface.
 */
export function instanceOfInstallationUpdateRequest(value: object): value is InstallationUpdateRequest {
    return true;
}

export function InstallationUpdateRequestFromJSON(json: any): InstallationUpdateRequest {
    return InstallationUpdateRequestFromJSONTyped(json, false);
}

export function InstallationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'configuration': json['configuration'] == null ? undefined : json['configuration'],
        'lookupId': json['lookupId'] == null ? undefined : json['lookupId'],
        'showInWeb': json['showInWeb'] == null ? undefined : json['showInWeb'],
        'isDeleted': json['isDeleted'] == null ? undefined : json['isDeleted'],
    };
}

export function InstallationUpdateRequestToJSON(value?: InstallationUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'configuration': value['configuration'],
        'lookupId': value['lookupId'],
        'showInWeb': value['showInWeb'],
        'isDeleted': value['isDeleted'],
    };
}

        /**
 * 
 * @export
 * @interface NodeCount
 */
export interface NodeCount {
    /**
     * 
     * @type {string}
     * @memberof NodeCount
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeCount
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeCount
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodeCount
     */
    'nodeCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeCount
     */
    'clusterName'?: string;
}


/**
 * Check if a given object implements the NodeCount interface.
 */
export function instanceOfNodeCount(value: object): value is NodeCount {
    return true;
}

export function NodeCountFromJSON(json: any): NodeCount {
    return NodeCountFromJSONTyped(json, false);
}

export function NodeCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeCount {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'nodeCount': json['nodeCount'] == null ? undefined : json['nodeCount'],
        'clusterName': json['clusterName'] == null ? undefined : json['clusterName'],
    };
}

export function NodeCountToJSON(value?: NodeCount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'accountId': value['accountId'],
        'createdAt': value['createdAt'],
        'nodeCount': value['nodeCount'],
        'clusterName': value['clusterName'],
    };
}

        /**
 * 
 * @export
 * @interface PaginatedAuditLogs
 */
export interface PaginatedAuditLogs {
    /**
     * 
     * @type {Array<AuditLog>}
     * @memberof PaginatedAuditLogs
     */
    'logs': Array<AuditLog>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuditLogs
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuditLogs
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAuditLogs
     */
    'pageSize': number;
}


/**
 * Check if a given object implements the PaginatedAuditLogs interface.
 */
export function instanceOfPaginatedAuditLogs(value: object): value is PaginatedAuditLogs {
    if (!('logs' in value) || value['logs'] === undefined) return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('pageSize' in value) || value['pageSize'] === undefined) return false;
    return true;
}

export function PaginatedAuditLogsFromJSON(json: any): PaginatedAuditLogs {
    return PaginatedAuditLogsFromJSONTyped(json, false);
}

export function PaginatedAuditLogsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedAuditLogs {
    if (json == null) {
        return json;
    }
    return {
        
        'logs': ((json['logs'] as Array<any>).map(AuditLogFromJSON)),
        'totalCount': json['totalCount'],
        'page': json['page'],
        'pageSize': json['pageSize'],
    };
}

export function PaginatedAuditLogsToJSON(value?: PaginatedAuditLogs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'logs': ((value['logs'] as Array<any>).map(AuditLogToJSON)),
        'totalCount': value['totalCount'],
        'page': value['page'],
        'pageSize': value['pageSize'],
    };
}

        /**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'accountPlan': string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    'eventsLimit': number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    'usersLimit': number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    'clustersLimit': number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    'nodesLimit': number;
}


/**
 * Check if a given object implements the Plan interface.
 */
export function instanceOfPlan(value: object): value is Plan {
    if (!('accountPlan' in value) || value['accountPlan'] === undefined) return false;
    if (!('eventsLimit' in value) || value['eventsLimit'] === undefined) return false;
    if (!('usersLimit' in value) || value['usersLimit'] === undefined) return false;
    if (!('clustersLimit' in value) || value['clustersLimit'] === undefined) return false;
    if (!('nodesLimit' in value) || value['nodesLimit'] === undefined) return false;
    return true;
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if (json == null) {
        return json;
    }
    return {
        
        'accountPlan': json['accountPlan'],
        'eventsLimit': json['eventsLimit'],
        'usersLimit': json['usersLimit'],
        'clustersLimit': json['clustersLimit'],
        'nodesLimit': json['nodesLimit'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountPlan': value['accountPlan'],
        'eventsLimit': value['eventsLimit'],
        'usersLimit': value['usersLimit'],
        'clustersLimit': value['clustersLimit'],
        'nodesLimit': value['nodesLimit'],
    };
}

        /**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponse
     */
    'success': boolean;
}


/**
 * Check if a given object implements the SuccessResponse interface.
 */
export function instanceOfSuccessResponse(value: object): value is SuccessResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    return true;
}

export function SuccessResponseFromJSON(json: any): SuccessResponse {
    return SuccessResponseFromJSONTyped(json, false);
}

export function SuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function SuccessResponseToJSON(value?: SuccessResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
    };
}

        /**
 * 
 * @export
 * @interface TrackedKey
 */
export interface TrackedKey {
    /**
     * 
     * @type {string}
     * @memberof TrackedKey
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TrackedKey
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TrackedKey
     */
    'type': TrackedKeyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TrackedKey
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackedKey
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackedKey
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrackedKey
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof TrackedKey
     */
    'index': number;
}


/**
 * @export
 */
export const TrackedKeyTypeEnum = {
    Label: 'label',
    Annotation: 'annotation'
} as const;
export type TrackedKeyTypeEnum = typeof TrackedKeyTypeEnum[keyof typeof TrackedKeyTypeEnum];



/**
 * Check if a given object implements the TrackedKey interface.
 */
export function instanceOfTrackedKey(value: object): value is TrackedKey {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('isDeleted' in value) || value['isDeleted'] === undefined) return false;
    if (!('index' in value) || value['index'] === undefined) return false;
    return true;
}

export function TrackedKeyFromJSON(json: any): TrackedKey {
    return TrackedKeyFromJSONTyped(json, false);
}

export function TrackedKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackedKey {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'accountId': json['accountId'],
        'userId': json['userId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'isDeleted': json['isDeleted'],
        'index': json['index'],
    };
}

export function TrackedKeyToJSON(value?: TrackedKey | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': value['type'],
        'accountId': value['accountId'],
        'userId': value['userId'],
        'createdAt': value['createdAt'],
        'isDeleted': value['isDeleted'],
        'index': value['index'],
    };
}

        /**
 * 
 * @export
 * @interface TrackedKeyCreateRequest
 */
export interface TrackedKeyCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackedKeyCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TrackedKeyCreateRequest
     */
    'type': TrackedKeyCreateRequestTypeEnum;
}


/**
 * @export
 */
export const TrackedKeyCreateRequestTypeEnum = {
    Label: 'label',
    Annotation: 'annotation'
} as const;
export type TrackedKeyCreateRequestTypeEnum = typeof TrackedKeyCreateRequestTypeEnum[keyof typeof TrackedKeyCreateRequestTypeEnum];



/**
 * Check if a given object implements the TrackedKeyCreateRequest interface.
 */
export function instanceOfTrackedKeyCreateRequest(value: object): value is TrackedKeyCreateRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function TrackedKeyCreateRequestFromJSON(json: any): TrackedKeyCreateRequest {
    return TrackedKeyCreateRequestFromJSONTyped(json, false);
}

export function TrackedKeyCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackedKeyCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
    };
}

export function TrackedKeyCreateRequestToJSON(value?: TrackedKeyCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'type': value['type'],
    };
}

        /**
 * 
 * @export
 * @interface WhitelistCIDRCreateOneRequest
 */
export interface WhitelistCIDRCreateOneRequest {
    /**
     * 
     * @type {string}
     * @memberof WhitelistCIDRCreateOneRequest
     */
    'cidr': string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistCIDRCreateOneRequest
     */
    'description'?: string;
}


/**
 * Check if a given object implements the WhitelistCIDRCreateOneRequest interface.
 */
export function instanceOfWhitelistCIDRCreateOneRequest(value: object): value is WhitelistCIDRCreateOneRequest {
    if (!('cidr' in value) || value['cidr'] === undefined) return false;
    return true;
}

export function WhitelistCIDRCreateOneRequestFromJSON(json: any): WhitelistCIDRCreateOneRequest {
    return WhitelistCIDRCreateOneRequestFromJSONTyped(json, false);
}

export function WhitelistCIDRCreateOneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WhitelistCIDRCreateOneRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'cidr': json['cidr'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function WhitelistCIDRCreateOneRequestToJSON(value?: WhitelistCIDRCreateOneRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cidr': value['cidr'],
        'description': value['description'],
    };
}

    
        /**
* Get account domain mappings
* @param params AccountDomainMappingsApiApiV1AccountDomainMappingsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountDomainMappingsGetUrl = (params: AccountDomainMappingsApiApiV1AccountDomainMappingsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/account-domain-mappings`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId) {
        localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AccountDomainMappingsGet operation in AccountDomainMappingsApi.
* @export
* @interface AccountDomainMappingsApiApiV1AccountDomainMappingsGetRequest
*/
export interface AccountDomainMappingsApiApiV1AccountDomainMappingsGetRequest {
    /**
    * accountId
    * @type {Array<string>}
    * @memberof AccountDomainMappingsApiApiV1AccountDomainMappingsGet
    */
    readonly accountId?: Array<string>
}


    
        /**
* Get all active accounts
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountsActiveGetUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/accounts/active`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Modify account public api ttl feature
* @param params AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountsFeaturesPublicApiTtlPutUrl = (params: AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/accounts/features/public-api-ttl`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get all accounts
* @param params AccountsApiApiV1AccountsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountsGetUrl = (params: AccountsApiApiV1AccountsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/accounts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.ids) {
        localVarQueryParameter['ids'] = params.ids;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    if (params.disabled !== undefined) {
            localVarQueryParameter['disabled'] = params.disabled;
    }
    if (params.isRbacEnabled !== undefined) {
            localVarQueryParameter['isRbacEnabled'] = params.isRbacEnabled;
    }
    if (params.plan) {
        localVarQueryParameter['plan'] = params.plan;
    }
    if (params.trialEndAt !== undefined) {
        localVarQueryParameter['trialEndAt'] = (params.trialEndAt as any instanceof Date) ?
                (params.trialEndAt as any).toISOString() :
                params.trialEndAt;
    }
    if (params.createdAt !== undefined) {
        localVarQueryParameter['createdAt'] = (params.createdAt as any instanceof Date) ?
                (params.createdAt as any).toISOString() :
                params.createdAt;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Modify account features
* @param params AccountsApiApiV1AccountsIdFeaturesPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountsIdFeaturesPutUrl = (params: AccountsApiApiV1AccountsIdFeaturesPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1AccountsIdFeaturesPut', 'id', params.id)
    const localVarPath = `/api/v1/accounts/{id}/features`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get account by id
* @param params AccountsApiApiV1AccountsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountsIdGetUrl = (params: AccountsApiApiV1AccountsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1AccountsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/accounts/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create account
* @param params AccountsApiApiV1AccountsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AccountsPostUrl = (params: AccountsApiApiV1AccountsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/accounts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AccountsFeaturesPublicApiTtlPut operation in AccountsApi.
* @export
* @interface AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest
*/
export interface AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest {
    /**
    * 
    * @type {AccountFeatureApiKeysTTL}
    * @memberof AccountsApiApiV1AccountsFeaturesPublicApiTtlPut
    */
    readonly accountFeatureApiKeysTTL?: AccountFeatureApiKeysTTL
}

/**
* Request parameters for apiV1AccountsGet operation in AccountsApi.
* @export
* @interface AccountsApiApiV1AccountsGetRequest
*/
export interface AccountsApiApiV1AccountsGetRequest {
    /**
    * Account id
    * @type {Array<string>}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly ids?: Array<string>

    /**
    * Account name
    * @type {string}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly name?: string

    /**
    * Account disabled
    * @type {boolean}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly disabled?: boolean

    /**
    * Account RBAC enabled
    * @type {boolean}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly isRbacEnabled?: boolean

    /**
    * Account plan
    * @type {Array<string>}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly plan?: Array<string>

    /**
    * Account trial end date
    * @type {string}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly trialEndAt?: string

    /**
    * Account created date
    * @type {string}
    * @memberof AccountsApiApiV1AccountsGet
    */
    readonly createdAt?: string
}

/**
* Request parameters for apiV1AccountsIdFeaturesPut operation in AccountsApi.
* @export
* @interface AccountsApiApiV1AccountsIdFeaturesPutRequest
*/
export interface AccountsApiApiV1AccountsIdFeaturesPutRequest {
    /**
    * Account id
    * @type {string}
    * @memberof AccountsApiApiV1AccountsIdFeaturesPut
    */
    readonly id: string

    /**
    * 
    * @type {ApiV1AccountsIdFeaturesPutRequest}
    * @memberof AccountsApiApiV1AccountsIdFeaturesPut
    */
    readonly apiV1AccountsIdFeaturesPutRequest?: ApiV1AccountsIdFeaturesPutRequest
}

/**
* Request parameters for apiV1AccountsIdGet operation in AccountsApi.
* @export
* @interface AccountsApiApiV1AccountsIdGetRequest
*/
export interface AccountsApiApiV1AccountsIdGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof AccountsApiApiV1AccountsIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1AccountsPost operation in AccountsApi.
* @export
* @interface AccountsApiApiV1AccountsPostRequest
*/
export interface AccountsApiApiV1AccountsPostRequest {
    /**
    * 
    * @type {CreateAccountRequest}
    * @memberof AccountsApiApiV1AccountsPost
    */
    readonly createAccountRequest?: CreateAccountRequest
}


    
        /**
* Get audit logs
* @param params AuditApiApiV1AuditGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuditGetUrl = (params: AuditApiApiV1AuditGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/audit`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.category !== undefined) {
            localVarQueryParameter['category'] = params.category;
    }
    if (params.crudType !== undefined) {
            localVarQueryParameter['crudType'] = params.crudType;
    }
    if (params.status !== undefined) {
            localVarQueryParameter['status'] = params.status;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.offset !== undefined) {
            localVarQueryParameter['offset'] = params.offset;
    }
    if (params.sort) {
        localVarQueryParameter['sort'] = params.sort.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Create audit log
* @param params AuditApiApiV1AuditPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuditPostUrl = (params: AuditApiApiV1AuditPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/audit`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AuditGet operation in AuditApi.
* @export
* @interface AuditApiApiV1AuditGetRequest
*/
export interface AuditApiApiV1AuditGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly accountId?: string

    /**
    * User id
    * @type {string}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly userId?: string

    /**
    * Category
    * @type {string}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly category?: string

    /**
    * CRUD type
    * @type {string}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly crudType?: string

    /**
    * Status
    * @type {string}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly status?: string

    /**
    * Limit
    * @type {number}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly limit?: number

    /**
    * Offset
    * @type {number}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly offset?: number

    /**
    * if no sort order was specified, the order will be random from the database
    * @type {Array<string>}
    * @memberof AuditApiApiV1AuditGet
    */
    readonly sort?: Array<string>
}

/**
* Request parameters for apiV1AuditPost operation in AuditApi.
* @export
* @interface AuditApiApiV1AuditPostRequest
*/
export interface AuditApiApiV1AuditPostRequest {
    /**
    * 
    * @type {AuditCreateRequest}
    * @memberof AuditApiApiV1AuditPost
    */
    readonly auditCreateRequest?: AuditCreateRequest
}


    
        /**
* 
* @summary Get available filter values for Query Audit Logs
* @param params AuditLogApiApiV1AuditLogFiltersGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuditLogFiltersGetUrl = (params: AuditLogApiApiV1AuditLogFiltersGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/audit-log/filters`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.startTime !== undefined) {
        localVarQueryParameter['startTime'] = (params.startTime as any instanceof Date) ?
                (params.startTime as any).toISOString() :
                params.startTime;
    }
    if (params.endTime !== undefined) {
        localVarQueryParameter['endTime'] = (params.endTime as any instanceof Date) ?
                (params.endTime as any).toISOString() :
                params.endTime;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Query audit logs with filters, sort and pagination. Use `Accept: text/csv` header to get the response as CSV file. 
* @summary Query Audit Logs
* @param params AuditLogApiApiV1AuditLogGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AuditLogGetUrl = (params: AuditLogApiApiV1AuditLogGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/audit-log`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.userIds) {
        localVarQueryParameter['userIds'] = params.userIds;
    }
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions;
    }
    if (params.categories) {
        localVarQueryParameter['categories'] = params.categories;
    }
    if (params.operations) {
        localVarQueryParameter['operations'] = params.operations;
    }
    if (params.entityTypes) {
        localVarQueryParameter['entityTypes'] = params.entityTypes;
    }
    if (params.entityName !== undefined) {
            localVarQueryParameter['entityName'] = params.entityName;
    }
    if (params.startTime !== undefined) {
        localVarQueryParameter['startTime'] = (params.startTime as any instanceof Date) ?
                (params.startTime as any).toISOString() :
                params.startTime;
    }
    if (params.endTime !== undefined) {
        localVarQueryParameter['endTime'] = (params.endTime as any instanceof Date) ?
                (params.endTime as any).toISOString() :
                params.endTime;
    }
    if (params.status !== undefined) {
            localVarQueryParameter['status'] = params.status;
    }
    if (params.page !== undefined) {
            localVarQueryParameter['page'] = params.page;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.sort !== undefined) {
            localVarQueryParameter['sort'] = params.sort;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AuditLogFiltersGet operation in AuditLogApi.
* @export
* @interface AuditLogApiApiV1AuditLogFiltersGetRequest
*/
export interface AuditLogApiApiV1AuditLogFiltersGetRequest {
    /**
    * Start time of the audit logs filters. If not provided, the default is 8 hours ago. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogFiltersGet
    */
    readonly startTime?: string

    /**
    * End time of the audit logs filters. If not provided, the default is now. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogFiltersGet
    */
    readonly endTime?: string
}

/**
* Request parameters for apiV1AuditLogGet operation in AuditLogApi.
* @export
* @interface AuditLogApiApiV1AuditLogGetRequest
*/
export interface AuditLogApiApiV1AuditLogGetRequest {
    /**
    * Response format. Use \&#39;application/json\&#39; to get the response as JSON. Use \&#39;text/csv\&#39; to get the response as CSV file. Notice that paging is ignored in CSV response, so all logs matched the query are returned. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly accept?: string

    /**
    * Audit log id. If not provided, the default is all ids. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly id?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly userIds?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly actions?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly categories?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly operations?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly entityTypes?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly entityName?: string

    /**
    * Start time of the audit logs query. If not provided, the default is 8 hours ago. In case of export to CSV, this will be ignored and the whole retention period will be fetched. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly startTime?: string

    /**
    * End time of the audit logs query. If not provided, the default is now. In case of export to CSV, this will be ignored and the whole retention period will be fetched. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly endTime?: string

    /**
    * Status of the audit logs query. If not provided, the default is all statuses. 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly status?: string

    /**
    * Page number. If not provided, the default is 1. Page parameter is ignored if the response is CSV. 
    * @type {number}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly page?: number

    /**
    * Page size. If not provided, the default is 20. Page size parameter is ignored if the response is CSV. 
    * @type {number}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly pageSize?: number

    /**
    * 
    * @type {string}
    * @memberof AuditLogApiApiV1AuditLogGet
    */
    readonly sort?: string
}


    
        /**
* Get CIDR whitelist
* @param params CIDRWhitelistApiApiV1WhitelistCidrGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WhitelistCidrGetUrl = (params: CIDRWhitelistApiApiV1WhitelistCidrGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/whitelist-cidr`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Delete CIDR from whitelist
* @param params CIDRWhitelistApiApiV1WhitelistCidrIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WhitelistCidrIdDeleteUrl = (params: CIDRWhitelistApiApiV1WhitelistCidrIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WhitelistCidrIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/whitelist-cidr/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update CIDR from whitelist
* @param params CIDRWhitelistApiApiV1WhitelistCidrIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WhitelistCidrIdPutUrl = (params: CIDRWhitelistApiApiV1WhitelistCidrIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WhitelistCidrIdPut', 'id', params.id)
    // verify required parameter 'apiV1WhitelistCidrIdPutRequest' is not null or undefined
    assertParamExists('apiV1WhitelistCidrIdPut', 'apiV1WhitelistCidrIdPutRequest', params.apiV1WhitelistCidrIdPutRequest)
    const localVarPath = `/api/v1/whitelist-cidr/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Add CIDR to whitelist
* @param params CIDRWhitelistApiApiV1WhitelistCidrPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WhitelistCidrPostUrl = (params: CIDRWhitelistApiApiV1WhitelistCidrPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/whitelist-cidr`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1WhitelistCidrGet operation in CIDRWhitelistApi.
* @export
* @interface CIDRWhitelistApiApiV1WhitelistCidrGetRequest
*/
export interface CIDRWhitelistApiApiV1WhitelistCidrGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof CIDRWhitelistApiApiV1WhitelistCidrGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WhitelistCidrIdDelete operation in CIDRWhitelistApi.
* @export
* @interface CIDRWhitelistApiApiV1WhitelistCidrIdDeleteRequest
*/
export interface CIDRWhitelistApiApiV1WhitelistCidrIdDeleteRequest {
    /**
    * CIDR id
    * @type {string}
    * @memberof CIDRWhitelistApiApiV1WhitelistCidrIdDelete
    */
    readonly id: string
}

/**
* Request parameters for apiV1WhitelistCidrIdPut operation in CIDRWhitelistApi.
* @export
* @interface CIDRWhitelistApiApiV1WhitelistCidrIdPutRequest
*/
export interface CIDRWhitelistApiApiV1WhitelistCidrIdPutRequest {
    /**
    * CIDR ID
    * @type {string}
    * @memberof CIDRWhitelistApiApiV1WhitelistCidrIdPut
    */
    readonly id: string

    /**
    * 
    * @type {ApiV1WhitelistCidrIdPutRequest}
    * @memberof CIDRWhitelistApiApiV1WhitelistCidrIdPut
    */
    readonly apiV1WhitelistCidrIdPutRequest: ApiV1WhitelistCidrIdPutRequest
}

/**
* Request parameters for apiV1WhitelistCidrPost operation in CIDRWhitelistApi.
* @export
* @interface CIDRWhitelistApiApiV1WhitelistCidrPostRequest
*/
export interface CIDRWhitelistApiApiV1WhitelistCidrPostRequest {
    /**
    * 
    * @type {ApiV1WhitelistCidrPostRequest}
    * @memberof CIDRWhitelistApiApiV1WhitelistCidrPost
    */
    readonly apiV1WhitelistCidrPostRequest?: ApiV1WhitelistCidrPostRequest
}


    
        /**
* Get event counts aggregate
* @param params EventsCountsApiApiV1EventCountsAggregateGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1EventCountsAggregateGetUrl = (params: EventsCountsApiApiV1EventCountsAggregateGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/event-counts/aggregate`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get event counts
* @param params EventsCountsApiApiV1EventCountsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1EventCountsGetUrl = (params: EventsCountsApiApiV1EventCountsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/event-counts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.collectedFrom !== undefined) {
        localVarQueryParameter['collectedFrom'] = (params.collectedFrom as any instanceof Date) ?
                (params.collectedFrom as any).toISOString() :
                params.collectedFrom;
    }
    if (params.collectedTo !== undefined) {
        localVarQueryParameter['collectedTo'] = (params.collectedTo as any instanceof Date) ?
                (params.collectedTo as any).toISOString() :
                params.collectedTo;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get event counts
* @param params EventsCountsApiApiV1EventCountsLimitsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1EventCountsLimitsGetUrl = (params: EventsCountsApiApiV1EventCountsLimitsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/event-counts/limits`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1EventCountsAggregateGet operation in EventsCountsApi.
* @export
* @interface EventsCountsApiApiV1EventCountsAggregateGetRequest
*/
export interface EventsCountsApiApiV1EventCountsAggregateGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof EventsCountsApiApiV1EventCountsAggregateGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1EventCountsGet operation in EventsCountsApi.
* @export
* @interface EventsCountsApiApiV1EventCountsGetRequest
*/
export interface EventsCountsApiApiV1EventCountsGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof EventsCountsApiApiV1EventCountsGet
    */
    readonly accountId?: string

    /**
    * Collected from date
    * @type {string}
    * @memberof EventsCountsApiApiV1EventCountsGet
    */
    readonly collectedFrom?: string

    /**
    * Collected to date
    * @type {string}
    * @memberof EventsCountsApiApiV1EventCountsGet
    */
    readonly collectedTo?: string

    /**
    * Limit
    * @type {number}
    * @memberof EventsCountsApiApiV1EventCountsGet
    */
    readonly limit?: number
}

/**
* Request parameters for apiV1EventCountsLimitsGet operation in EventsCountsApi.
* @export
* @interface EventsCountsApiApiV1EventCountsLimitsGetRequest
*/
export interface EventsCountsApiApiV1EventCountsLimitsGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof EventsCountsApiApiV1EventCountsLimitsGet
    */
    readonly accountId?: string
}


    
        /**
* Get installations
* @param params InstallationsApiApiV1InstallationsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1InstallationsGetUrl = (params: InstallationsApiApiV1InstallationsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/installations`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.id !== undefined) {
            localVarQueryParameter['id'] = params.id;
    }
    if (params.integration) {
        localVarQueryParameter['integration'] = params.integration;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.config !== undefined) {
            localVarQueryParameter['config'] = params.config;
    }
    if (params.lookupId !== undefined) {
            localVarQueryParameter['lookupId'] = params.lookupId;
    }
    if (params.showInWeb !== undefined) {
            localVarQueryParameter['showInWeb'] = params.showInWeb;
    }
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    if (params.excludeTrials !== undefined) {
            localVarQueryParameter['excludeTrials'] = params.excludeTrials;
    }
    if (params.disabled !== undefined) {
            localVarQueryParameter['disabled'] = params.disabled;
    }
    if (params.activeOnly !== undefined) {
            localVarQueryParameter['activeOnly'] = params.activeOnly;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.sort) {
        localVarQueryParameter['sort'] = params.sort.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Create github installation
* @param params InstallationsApiApiV1InstallationsGithubPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1InstallationsGithubPostUrl = (params: InstallationsApiApiV1InstallationsGithubPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/installations/github`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get installation
* @param params InstallationsApiApiV1InstallationsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1InstallationsIdGetUrl = (params: InstallationsApiApiV1InstallationsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1InstallationsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/installations/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update installation
* @param params InstallationsApiApiV1InstallationsIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1InstallationsIdPutUrl = (params: InstallationsApiApiV1InstallationsIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1InstallationsIdPut', 'id', params.id)
    const localVarPath = `/api/v1/installations/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create installation
* @param params InstallationsApiApiV1InstallationsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1InstallationsPostUrl = (params: InstallationsApiApiV1InstallationsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/installations`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1InstallationsGet operation in InstallationsApi.
* @export
* @interface InstallationsApiApiV1InstallationsGetRequest
*/
export interface InstallationsApiApiV1InstallationsGetRequest {
    /**
    * Installation id
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly id?: string

    /**
    * Integration
    * @type {Array<string>}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly integration?: Array<string>

    /**
    * Account id
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly accountId?: string

    /**
    * User id
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly userId?: string

    /**
    * Configuration
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly config?: string

    /**
    * Lookup id
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly lookupId?: string

    /**
    * Show in web
    * @type {boolean}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly showInWeb?: boolean

    /**
    * Is deleted
    * @type {boolean}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly isDeleted?: boolean

    /**
    * Exclude trials accounts (plan &#x3D; trial or trialEndAt is in the past)
    * @type {boolean}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly excludeTrials?: boolean

    /**
    * Account disabled (account.disabled &#x3D; false)
    * @type {boolean}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly disabled?: boolean

    /**
    * Active agents only (Limit to active agents only)
    * @type {boolean}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly activeOnly?: boolean

    /**
    * Limit
    * @type {number}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly limit?: number

    /**
    * Sort
    * @type {Array<string>}
    * @memberof InstallationsApiApiV1InstallationsGet
    */
    readonly sort?: Array<string>
}

/**
* Request parameters for apiV1InstallationsGithubPost operation in InstallationsApi.
* @export
* @interface InstallationsApiApiV1InstallationsGithubPostRequest
*/
export interface InstallationsApiApiV1InstallationsGithubPostRequest {
    /**
    * 
    * @type {GithubInstallationCreateRequest}
    * @memberof InstallationsApiApiV1InstallationsGithubPost
    */
    readonly githubInstallationCreateRequest?: GithubInstallationCreateRequest
}

/**
* Request parameters for apiV1InstallationsIdGet operation in InstallationsApi.
* @export
* @interface InstallationsApiApiV1InstallationsIdGetRequest
*/
export interface InstallationsApiApiV1InstallationsIdGetRequest {
    /**
    * Installation id
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1InstallationsIdPut operation in InstallationsApi.
* @export
* @interface InstallationsApiApiV1InstallationsIdPutRequest
*/
export interface InstallationsApiApiV1InstallationsIdPutRequest {
    /**
    * Installation id
    * @type {string}
    * @memberof InstallationsApiApiV1InstallationsIdPut
    */
    readonly id: string

    /**
    * 
    * @type {InstallationUpdateRequest}
    * @memberof InstallationsApiApiV1InstallationsIdPut
    */
    readonly installationUpdateRequest?: InstallationUpdateRequest
}

/**
* Request parameters for apiV1InstallationsPost operation in InstallationsApi.
* @export
* @interface InstallationsApiApiV1InstallationsPostRequest
*/
export interface InstallationsApiApiV1InstallationsPostRequest {
    /**
    * 
    * @type {InstallationCreateRequest}
    * @memberof InstallationsApiApiV1InstallationsPost
    */
    readonly installationCreateRequest?: InstallationCreateRequest
}


    
        /**
* 
* @summary Retrieve a list of accounts with their user statistics
* @param baseUrl string
* @throws {RequiredError}
*/
export const getAccountsStatsUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/marketing/accounts/stats`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};


    
        /**
* Get nodes counts
* @param params NodesCountsApiApiV1NodesCountsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesCountsGetUrl = (params: NodesCountsApiApiV1NodesCountsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/nodes-counts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.collectedFrom !== undefined) {
        localVarQueryParameter['collectedFrom'] = (params.collectedFrom as any instanceof Date) ?
                (params.collectedFrom as any).toISOString() :
                params.collectedFrom;
    }
    if (params.collectedTo !== undefined) {
        localVarQueryParameter['collectedTo'] = (params.collectedTo as any instanceof Date) ?
                (params.collectedTo as any).toISOString() :
                params.collectedTo;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1NodesCountsGet operation in NodesCountsApi.
* @export
* @interface NodesCountsApiApiV1NodesCountsGetRequest
*/
export interface NodesCountsApiApiV1NodesCountsGetRequest {
    /**
    * Account id
    * @type {string}
    * @memberof NodesCountsApiApiV1NodesCountsGet
    */
    readonly accountId?: string

    /**
    * Collected from date
    * @type {string}
    * @memberof NodesCountsApiApiV1NodesCountsGet
    */
    readonly collectedFrom?: string

    /**
    * Collected to date
    * @type {string}
    * @memberof NodesCountsApiApiV1NodesCountsGet
    */
    readonly collectedTo?: string

    /**
    * Cluster name
    * @type {string}
    * @memberof NodesCountsApiApiV1NodesCountsGet
    */
    readonly clusterName?: string
}


    
        /**
* Get plans
* @param params PlansApiApiV1PlansGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PlansGetUrl = (params: PlansApiApiV1PlansGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/plans`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountPlan !== undefined) {
            localVarQueryParameter['accountPlan'] = params.accountPlan;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1PlansGet operation in PlansApi.
* @export
* @interface PlansApiApiV1PlansGetRequest
*/
export interface PlansApiApiV1PlansGetRequest {
    /**
    * specific plan
    * @type {string}
    * @memberof PlansApiApiV1PlansGet
    */
    readonly accountPlan?: string
}


    
        /**
* 
* @summary Create a new tracked key for a specific account
* @param params TrackedKeysApiCreateTrackedKeyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const createTrackedKeyUrl = (params: TrackedKeysApiCreateTrackedKeyRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/accounts/tracked-keys`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @summary Delete a tracked key for a specific account
* @param params TrackedKeysApiDeleteTrackedKeyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const deleteTrackedKeyUrl = (params: TrackedKeysApiDeleteTrackedKeyRequest, baseUrl: string): string => {
    // verify required parameter 'keyId' is not null or undefined
    assertParamExists('deleteTrackedKey', 'keyId', params.keyId)
    const localVarPath = `/api/v1/accounts/tracked-keys`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.keyId !== undefined) {
            localVarQueryParameter['keyId'] = params.keyId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @summary Retrieve a list of tracked keys for a specific account
* @param params TrackedKeysApiGetTrackedKeysRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getTrackedKeysUrl = (params: TrackedKeysApiGetTrackedKeysRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/accounts/tracked-keys`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.withDeleted !== undefined) {
            localVarQueryParameter['withDeleted'] = params.withDeleted;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @summary Retrieve a list of tracked keys for a all accounts. Require Admin access using API Key.
* @param params TrackedKeysApiInternalGetTrackedKeysRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const internalGetTrackedKeysUrl = (params: TrackedKeysApiInternalGetTrackedKeysRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/internal/accounts/tracked-keys`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.withDeleted !== undefined) {
            localVarQueryParameter['withDeleted'] = params.withDeleted;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for createTrackedKey operation in TrackedKeysApi.
* @export
* @interface TrackedKeysApiCreateTrackedKeyRequest
*/
export interface TrackedKeysApiCreateTrackedKeyRequest {
    /**
    * 
    * @type {TrackedKeyCreateRequest}
    * @memberof TrackedKeysApiCreateTrackedKey
    */
    readonly trackedKeyCreateRequest?: TrackedKeyCreateRequest
}

/**
* Request parameters for deleteTrackedKey operation in TrackedKeysApi.
* @export
* @interface TrackedKeysApiDeleteTrackedKeyRequest
*/
export interface TrackedKeysApiDeleteTrackedKeyRequest {
    /**
    * Tracked Key Id
    * @type {string}
    * @memberof TrackedKeysApiDeleteTrackedKey
    */
    readonly keyId: string
}

/**
* Request parameters for getTrackedKeys operation in TrackedKeysApi.
* @export
* @interface TrackedKeysApiGetTrackedKeysRequest
*/
export interface TrackedKeysApiGetTrackedKeysRequest {
    /**
    * Retrieve also deleted tracked keys
    * @type {boolean}
    * @memberof TrackedKeysApiGetTrackedKeys
    */
    readonly withDeleted?: boolean
}

/**
* Request parameters for internalGetTrackedKeys operation in TrackedKeysApi.
* @export
* @interface TrackedKeysApiInternalGetTrackedKeysRequest
*/
export interface TrackedKeysApiInternalGetTrackedKeysRequest {
    /**
    * Retrieve also deleted tracked keys
    * @type {boolean}
    * @memberof TrackedKeysApiInternalGetTrackedKeys
    */
    readonly withDeleted?: boolean
}


    
