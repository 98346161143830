import React from "react";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import { muiTheme } from "@komodorio/design-system";

export const ViewAllLabel = () => (
  <Stack direction="row" columnGap="8px" alignItems="center">
    <Typography variant={"body2"}>View all resources</Typography>
    <LightTooltip title={"Required action"}>
      <ErrorOutline
        sx={{
          color: muiTheme.palette.warning.dark,
          width: "16px",
          height: "16px",
        }}
      />
    </LightTooltip>
  </Stack>
);
