import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { AlertTriangle16 } from "@komodorio/design-system/icons";
import { formatDistanceToNow, parseISO } from "date-fns";
import * as cronParser from "cron-parser";
import cronstrue from "cronstrue";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../../resources/deployment";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import StatefulSet from "../../resources/statefulset";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DaemonSet from "../../resources/daemonset";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Job from "../../resources/job";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CronJob from "../../resources/cronjob";
import { getMinTime } from "../../../../shared/utils/extractJobsStatusandLastRunTime";
import { useDateFormatter } from "../../../../shared/hooks/useDateFormatter";
import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";

import ServiceAdditionalLinks from "./AdditionalLinks";
import KubernetesData from "./KubernetesData";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.5fr 1fr;
  gap: 1rem;
`;
const Card = styled.div`
  background-color: white;
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  padding: 1rem;
  height: fit-content;
`;
const DataCard = styled(Card)`
  grid-column: 1;
  grid-row: 1 / 4;
`;
const DataField = styled(Typography).attrs({
  bold: true,
  color: theme.foreground.fgSubtle,
})``;
const Replicas = styled(Typography)`
  display: flex;
  column-gap: 0.3rem;
  align-items: center;
`;

interface InfoTabProps {
  resource: KomodorServiceResourceKind;
}
const InfoTabContent: React.FC<InfoTabProps> = ({ resource }) => {
  const { format } = useDateFormatter();
  const divRef = useRef<HTMLDivElement>(null);
  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();

  const { replicas = undefined, lastDeployEndDate = undefined } =
    resource instanceof Deployment ||
    resource instanceof StatefulSet ||
    resource instanceof DaemonSet
      ? resource
      : {};

  const isJob = resource instanceof Job;
  const isCronJob = resource instanceof CronJob;
  const { jobLastRunTime = undefined, lastDeployStartTime = undefined } =
    isJob || isCronJob ? resource : {};
  const { schedule = undefined } = isCronJob ? resource : {};

  const jobCreatedTime = isJob
    ? getMinTime(
        lastDeployStartTime,
        jobLastRunTime ? jobLastRunTime.toString() : ""
      )
    : lastDeployStartTime
    ? parseISO(lastDeployStartTime)
    : undefined;

  const nextRunTime = useMemo(() => {
    let nextRunDateStr = "N/A";
    if (!schedule) {
      return nextRunDateStr;
    }

    try {
      const cronExpression = cronParser.parseExpression(schedule);
      nextRunDateStr = format(cronExpression.next().toDate());
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("failed to parse cron expression", err, { schedule });
    }

    return nextRunDateStr;
  }, [format, schedule]);

  const humanReadableSchedule = useMemo(() => {
    if (!schedule) {
      return "";
    }

    try {
      return cronstrue.toString(schedule);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("failed to parse cron expression", err, { schedule });
    }

    return "";
  }, [schedule]);

  setMainContainerDivRef(divRef);
  useEffect(() => {
    reportLoadingState("info", false);
  }, [reportLoadingState]);

  return (
    <Container ref={divRef}>
      <DataCard>
        <KubernetesData resource={resource}>
          {jobCreatedTime && (
            <>
              <DataField>Created:</DataField>
              <Typography>{format(jobCreatedTime)}</Typography>
            </>
          )}
          {jobLastRunTime && (
            <>
              <DataField>Last Run:</DataField>
              <Typography>
                {formatDistanceToNow(jobLastRunTime, {
                  addSuffix: true,
                })}
              </Typography>
            </>
          )}
          {schedule && (
            <>
              <DataField>Next run:</DataField>
              <Typography>{nextRunTime}</Typography>
              <DataField>Schedule</DataField>
              <Typography>
                <b>{schedule}</b>{" "}
                {humanReadableSchedule ? `(${humanReadableSchedule})` : ""}
              </Typography>
            </>
          )}
          {lastDeployEndDate && (
            <>
              <DataField>Last Deploy:</DataField>
              <Typography>
                {formatDistanceToNow(lastDeployEndDate, {
                  addSuffix: true,
                })}
              </Typography>
            </>
          )}
          {replicas && (
            <>
              <DataField>Replicas:</DataField>
              <Replicas
                color={
                  replicas.ready < replicas.desired
                    ? theme.foreground.fgPink
                    : undefined
                }
              >
                {replicas.ready < replicas.desired && <AlertTriangle16 />}
                {replicas.ready}/{replicas.desired}
              </Replicas>
            </>
          )}
        </KubernetesData>
      </DataCard>
      <Card>
        <ServiceAdditionalLinks resource={resource} />
      </Card>
    </Container>
  );
};

const InfoTab: React.FC<InfoTabProps> = (props: InfoTabProps) => {
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.Info);

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <InfoTabContent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InfoTab;
