import WorkflowCheck from "..";
import { extractServiceName } from "../../../../../shared/utils/serviceHelpers";
import { CheckIconType } from "../CheckCard";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadDescribeDetails from "./detailsComponents/WorkloadDescribeDetails";
import { WorkloadDescribeOutput } from "./types";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class WorkloadDescribeCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck, eventTime?: Date) {
    super(check);
    this.title = "Workload Describe";
    this.output = check.output as WorkloadDescribeOutput;
    this.question = `View ${extractServiceName(
      this.output.serviceId
    )} describe`;
    this.shortOutput = `${extractServiceName(this.output.serviceId)} describe`;
    this.icon = "info" as CheckIconType;
    this.isFinding = true;
    this.output.eventTime = eventTime;
  }

  renderCheckDetails(): JSX.Element {
    return <WorkloadDescribeDetails check={this} />;
  }
}
