import React, { useMemo, useState } from "react";
import { StackedComparisonTable } from "@komodorio/design-system/komodor-ui";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { TableNoData } from "../common/TableNoData";
import { DriftType } from "../context/types";

import { ResourceKindComparisonTable } from "./ResourceKindComparisonTable";
import { MAX_COMPARED_RELEASES } from "./constants";
import { useHelmResourcesTableAttributes } from "./ResourcesTableAttributesUtils";
import {
  ResourcesDiffModal,
  ResourcesDiffModalParams,
} from "./ResourcesDiffModal";

import { HelmComparisonResponse } from "@/generated/workspacesApi";
import { HelmChart } from "@/generated/addonsApi";

export type HelmResourcesComparisonProps = {
  data: HelmComparisonResponse | undefined;
  helmReleases: HelmChart[];
  showOnlyDrift: boolean;
  isLoading: boolean;
  isError: boolean;
  errorStateElement: React.ReactNode;
};

export const HelmResourcesComparison: React.FC<
  HelmResourcesComparisonProps
> = ({
  data,
  showOnlyDrift,
  isLoading,
  helmReleases,
  isError,
  errorStateElement,
}) => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();

  const [resourcesDiffModal, setResourcesDiffModal] =
    useState<ResourcesDiffModalParams | null>(null);
  const isResourcesDriftDetected = useMemo(() => {
    return data?.resourcesByKind?.some((resource) => resource.isDiff) ?? false;
  }, [data?.resourcesByKind]);

  const emptyAttributes = useHelmResourcesTableAttributes({});

  const shouldShowResourcesTable = !showOnlyDrift || isResourcesDriftDetected;
  if (!shouldShowResourcesTable) {
    return null;
  }
  const isResourcesDataExists = data?.resourcesByKind !== undefined;
  return (
    <>
      <StackedComparisonTable title="Resources">
        {isResourcesDataExists ? (
          data.resourcesByKind.map((resource) => {
            return (
              <ResourceKindComparisonTable
                key={resource.kind}
                baselineAttributesComparisonData={data.baselineHelmAttributes}
                targetAttributesComparisonData={data.targetHelmAttributes}
                resourceKindComparison={resource}
                helmReleases={helmReleases}
                isComparedEmpty={!comparedSelections?.length}
                setResourcesDiffModal={setResourcesDiffModal}
              />
            );
          })
        ) : (
          <TableNoData
            isLoading={isLoading}
            isError={isError}
            errorStateElement={errorStateElement}
            maxComparedItems={MAX_COMPARED_RELEASES}
            driftType={DriftType.Helm}
            attributes={emptyAttributes}
          />
        )}
      </StackedComparisonTable>

      {baselineSelection !== null && resourcesDiffModal !== null ? (
        <ResourcesDiffModal
          baselineKomodorUidParams={resourcesDiffModal.baselineKomodorUidParams}
          comparedKomodorUidParams={resourcesDiffModal.comparedKomodorUidParams}
          onClose={() => setResourcesDiffModal(null)}
        />
      ) : null}
    </>
  );
};
