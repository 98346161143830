import { useMemo, useEffect, RefObject, useRef, useLayoutEffect } from "react";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { DriftAnalysisTabType } from "../types";

import { ServicesComparisonTabs } from "./constants";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import {
  DRIFT_ANALYSIS_TAB_PARAM_KEY,
  SERVICES_DRIFT_TYPE_TAB_PARAM_KEY,
} from "@/shared/config/urlSearchParamsKeys";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

interface DriftViews {
  driftTab: DriftAnalysisTabType | null;
  serviceDriftType: ServicesComparisonTabs | null;
}
interface DriftDatadogReportTimeProps {
  component: string;
  isLoading: boolean;
  divRef: RefObject<HTMLDivElement>;
}
export const useDriftDatadogReportTime = ({
  component,
  isLoading,
  divRef,
}: DriftDatadogReportTimeProps) => {
  const {
    reportLoadingState,
    setMainContainerDivRef,
    triggerStartView,
    resetLoadingState,
  } = useDatadogReportLoadingTimeContext();

  setMainContainerDivRef(divRef);

  const { baselineSelection } = useDriftComparisonContext();
  const isFetchingEnabled = useMemo(
    () => baselineSelection !== null,
    [baselineSelection]
  );
  const [driftTab] = useStringifiedStateInSearchParams<DriftAnalysisTabType>(
    DRIFT_ANALYSIS_TAB_PARAM_KEY
  );

  const [serviceDriftType] =
    useStringifiedStateInSearchParams<ServicesComparisonTabs>(
      SERVICES_DRIFT_TYPE_TAB_PARAM_KEY
    );

  const lastTabReported = useRef<DriftViews | undefined>(undefined);

  const currentTab = useMemo(
    () => ({
      driftTab,
      serviceDriftType,
    }),
    [driftTab, serviceDriftType]
  );
  useLayoutEffect(() => {
    if (currentTab !== lastTabReported.current) {
      lastTabReported.current && resetLoadingState();
      triggerStartView();
      lastTabReported.current = currentTab;
    }
  }, [
    triggerStartView,
    resetLoadingState,
    driftTab,
    serviceDriftType,
    currentTab,
  ]);
  useEffect(() => {
    if (!isFetchingEnabled) return;
    reportLoadingState(component, isLoading);
  }, [reportLoadingState, isFetchingEnabled, isLoading, component]);
};
