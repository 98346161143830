import { useAddonContext } from "../context/useAddonContext";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { Entity } from "@/generated/addonsApi";
import { WorkspaceKind } from "@/generated/workspacesApi";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";

function isWorkspaceLabelOrServiceId(
  workspace: TypedWorkspace | undefined
): boolean {
  return (
    workspace?.kind === WorkspaceKind.Label ||
    workspace?.kind === WorkspaceKind.ServiceId
  );
}

export function useIsSupportedWorkspaceKind(): boolean {
  const { currentWorkspace, scopedWorkspaceSubKind } = useWorkspaces();
  const { addonEntity } = useAddonContext();
  switch (addonEntity) {
    case Entity.Controller:
      return !isWorkspaceLabelOrServiceId(currentWorkspace);
    case Entity.WorkflowDag:
    case Entity.Certificate: {
      if (currentWorkspace?.kind === "scoped") {
        return (
          scopedWorkspaceSubKind === "clusters" ||
          scopedWorkspaceSubKind === "namespaces"
        );
      }

      return !isWorkspaceLabelOrServiceId(currentWorkspace);
    }
    case Entity.AutoScaler: {
      if (
        currentWorkspace?.kind === "scoped" &&
        scopedWorkspaceSubKind !== "clusters"
      ) {
        return false;
      }

      return true;
    }
    default:
      return true;
  }
}
