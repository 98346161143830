import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import { NAMESPACES } from "../requestResponseMaps";

import { useResourcesApiClient } from "./apiClient";

import {
  AccountNamespacesApiApiV1NamespacesGetRequest,
  apiV1NamespacesGetUrl,
  NamespacesResponse,
} from "@/generated/resourcesApi";

const fetchNamespaces = async (
  apiClient: AxiosInstance,
  params: AccountNamespacesApiApiV1NamespacesGetRequest
): Promise<NamespacesResponse> => {
  const { data } = await apiClient.get(
    apiV1NamespacesGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetNamespaces = (
  params?: AccountNamespacesApiApiV1NamespacesGetRequest,
  options?: {
    enabled?: boolean;
  }
) => {
  const apiClient = useResourcesApiClient();

  return useQuery(
    [NAMESPACES, params],
    () => fetchNamespaces(apiClient, params || {}),
    options
  );
};
