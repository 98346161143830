import React from "react";
import { useParams } from "react-router-dom";

import { HelmCharts } from "@/components/Inspection/Helm";
import InspectionView from "@/components/Inspection";
import { CRDsPage } from "@/components/Inspection/CRDs/pages/CRDsPage";
import { InspectionViewProvider } from "@/components/Inspection/InspectionViewContext";
import { KubernetesCRDResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";
import { KubernetesHelmResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import { RedirectToResourceInspectionPage } from "@/components/Inspection/InspectionViewsRouter/RedirectToResourceInspectionPage";
import { SecretsPage } from "@/components/Inspection/Secret/SecretsPage";
import { useIsClusterValidInCurrentWorkspace } from "@/components/workspaces/WorkspacesTopBar/hooks";
import {
  KubernetesResource,
  KubernetesSecretsResource,
} from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { PathNotFound } from "@/components/404/PathNotFound";

export const ResourceToInspectionPage: React.FC<{
  resource: KubernetesResource;
}> = ({ resource }) => {
  const { cluster } = useParams();
  const { helmChartsPageInK8SAddons } = useOverridableFlags();
  const isClusterValid = useIsClusterValidInCurrentWorkspace(cluster);
  if (!isClusterValid) {
    return <RedirectToResourceInspectionPage resource={resource} />;
  }

  const getElement = () => {
    switch (resource.Kind) {
      case KubernetesHelmResource.Kind:
        if (helmChartsPageInK8SAddons) {
          return <PathNotFound />;
        }
        return <HelmCharts kubernetesResource={resource} />;
      case KubernetesCRDResource.Kind:
        return <CRDsPage />;
      case KubernetesSecretsResource.Kind:
        return <SecretsPage />;
      default:
        return <InspectionView kubernetesResource={resource} />;
    }
  };

  return <InspectionViewProvider>{getElement()}</InspectionViewProvider>;
};
