import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";

export const DeleteStatementConfirmationDialog = ({
  onConfirm,
}: {
  onConfirm: (confirm: boolean) => void;
}) => (
  <ConfirmationDialog
    title="Delete Statement?"
    content="This will delete the statement from the policy."
    onClose={onConfirm}
    okText="Delete Statement"
  />
);
