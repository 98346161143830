import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

import { AutoScaler } from "@/generated/addonsApi";
import { disableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import { generateRandRangeList } from "@/components/k8sAddons/utils/tableUtils";
import { useGenerateSkeleton } from "@/components/k8sAddons/hooks/table/useGenerateSkeleton";
import { AutoscalerTypeCell } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/AutoscalerTypeCell";
import { ResourceUsageCell } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/ResourceUsageCell";

export const columnsConfig = disableLocalSortingInTableColumns<AutoScaler>({
  name: {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  cluster: {
    field: "clusterName",
    headerName: "Cluster",
    flex: 1,
  },
  autoscalerType: {
    field: "autoscalerType",
    headerName: "Type",
    flex: 1,
  },
  numberOfRisks: {
    field: "numberOfRisks",
    headerName: "Risks",
    maxWidth: 120,
  },
  numberOfNodes: {
    field: "numberOfNodes",
    headerName: "Nodes",
    maxWidth: 120,
  },
  numberOfPendingPods: {
    field: "numberOfPendingPods",
    headerName: "Pending Pods",
    minWidth: 120,
  },
  cpuResourceData: {
    field: "cpuAllocatedPct",
    headerName: "CPU usage (core)",
    flex: 1,
    minWidth: 190,
  },
  memoryUsageData: {
    field: "memoryAllocatedPct",
    headerName: "Memory usage (GiB)",
    flex: 1,
    minWidth: 190,
  },
});

export const useTableColumns = (): GridColDef<AutoScaler>[] => {
  return useMemo(() => {
    return [
      columnsConfig.cluster,
      columnsConfig.name,
      {
        ...columnsConfig.autoscalerType,
        renderCell: ({ value }) => {
          return <AutoscalerTypeCell type={value} />;
        },
      },
      columnsConfig.numberOfRisks,
      columnsConfig.numberOfNodes,
      columnsConfig.numberOfPendingPods,
      {
        ...columnsConfig.cpuResourceData,
        renderCell: ({ row }) => {
          return (
            <ResourceUsageCell
              resourceUsageData={row.cpuResourceData}
              percentage={row.cpuAllocatedPct}
              title="CPU"
              type="cpu"
            />
          );
        },
      },
      {
        ...columnsConfig.memoryUsageData,
        renderCell: ({ row }) => (
          <ResourceUsageCell
            resourceUsageData={row.memoryResourceData}
            percentage={row.memoryAllocatedPct}
            title="Mem"
            type="memory"
          />
        ),
      },
    ];
  }, []);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof AutoScaler, number[]>> = {
  name: generateRandRangeList(50, 100),
  clusterName: generateRandRangeList(50, 100),
  autoscalerType: generateRandRangeList(50, 100),
  numberOfRisks: generateRandRangeList(25, 35),
  numberOfNodes: generateRandRangeList(25, 35),
  numberOfPendingPods: generateRandRangeList(25, 35),
  memoryResourceData: generateRandRangeList(100, 100),
  cpuResourceData: generateRandRangeList(100, 100),
};

export const useLoadingColumns = (): GridColDef<AutoScaler>[] => {
  const generateSkeleton = useGenerateSkeleton(randRangeColumns);
  return useMemo(
    () => [
      {
        ...columnsConfig.name,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "name" }),
      },
      {
        ...columnsConfig.cluster,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "clusterName" }),
      },
      {
        ...columnsConfig.autoscalerType,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "autoscalerType",
          }),
      },
      {
        ...columnsConfig.numberOfRisks,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "numberOfRisks",
          }),
      },
      {
        ...columnsConfig.numberOfNodes,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "numberOfNodes",
          }),
      },
      {
        ...columnsConfig.numberOfPendingPods,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "numberOfPendingPods",
            skeletonProps: { sx: { borderRadius: "16px" } },
          }),
      },
      {
        ...columnsConfig.cpuResourceData,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "cpuResourceData",
          }),
      },
      {
        ...columnsConfig.memoryUsageData,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "memoryResourceData",
          }),
      },
    ],
    [generateSkeleton]
  );
};
