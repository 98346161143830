import React, { useCallback, useEffect, useMemo, useState } from "react";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { muiPalette } from "../../../../themes";

import { selectAriaLabels } from "components/komodor-ui/Select/shared/ariaLabels";
import { SearchBar } from "components/komodor-ui/Select/shared/components/SearchBar/SearchBar";
import { SelectPopover } from "components/komodor-ui/Select/shared/components/SelectPopOver";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { placeholderOptionsValue } from "components/komodor-ui/Select/SingleSelect/singleSelectConstants";
import { SingleSelectPopoverProps } from "components/komodor-ui/Select/SingleSelect/singleSelectTypes";
import { SelectItem } from "components/komodor-ui/Select/SingleSelectPopover/SingleSelectItem";
import { VirtualizedMenuList } from "components/komodor-ui/Select/SingleSelectPopover/VirtualizedMenuList";

const MENU_LIST_PADDING = "16px";
const SEARCH_BAR_HEIGHT = "42px";

export const SingleSelectPopover = <T extends MuiSelectionOptionValue>({
  anchorEl,
  options,
  value,
  defaultValue,
  onChange,
  onClose,
  texts,
  classNames,
  enableSearch = false,
  enableFreeSolo = false,
  customOptionElement,
  menuListTrailingElement,
  selectPopoverProps,
  enableVirtualization = false,
  virtualizerProps,
  customSearch,
}: SingleSelectPopoverProps<T>): React.ReactNode => {
  const [localValue, setLocalValue] = useState(defaultValue || value);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const _selectOption = useCallback(
    (option: MuiSelectionOption<T>) => {
      setLocalValue(option);
      onChange?.(option);
      setSearchTerm("");
      onClose?.();
    },
    [onChange, onClose]
  );

  const onItemSelect = useCallback(
    (value: T) => {
      let selectedOption: MuiSelectionOption<T> | undefined = undefined;
      if (value !== placeholderOptionsValue) {
        selectedOption = options.find((option) => option.value === value);
      }
      _selectOption(selectedOption);
    },
    [options, _selectOption]
  );

  const onNewItemSelect = useCallback(
    (value: T) => {
      const newOption: MuiSelectionOption<T> | undefined = {
        value,
        label: value.toString(),
      };
      _selectOption(newOption);
    },
    [_selectOption]
  );

  const handleClose = useCallback(() => {
    setSearchTerm("");
    onClose?.();
  }, [onClose]);

  const filteredOptions = useMemo(() => {
    if (customSearch) {
      return customSearch(searchTerm, options);
    }
    return customSearch
      ? customSearch(searchTerm, options)
      : options
          .filter((option) =>
            option.label?.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .sort(
            (a, b) =>
              a.label?.toLowerCase().indexOf(searchTerm.toLowerCase()) -
              b.label?.toLowerCase().indexOf(searchTerm.toLowerCase())
          );
  }, [customSearch, options, searchTerm]);

  const onSearchTermChange = (searchString: string) =>
    setSearchTerm(searchString);

  const trailingElements = useMemo(() => {
    if (!(enableFreeSolo && searchTerm)) {
      return menuListTrailingElement;
    }
    if (
      options.find(
        (option) =>
          (option.label || "").toLowerCase() === searchTerm.toLowerCase()
      )
    ) {
      return menuListTrailingElement;
    }

    return [
      menuListTrailingElement,
      <SelectItem<string>
        key={"add"}
        aria-label={selectAriaLabels.addOption}
        option={{ value: searchTerm, label: searchTerm }}
        onSelect={(val: MuiSelectionOptionValue) => onNewItemSelect(val as T)}
        customOptionElement={({ label }) => (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" color={muiPalette.text.secondary}>
              Add
            </Typography>
            <Typography variant="body2">{label}</Typography>
          </Stack>
        )}
      />,
    ];
  }, [
    enableFreeSolo,
    searchTerm,
    options,
    menuListTrailingElement,
    onNewItemSelect,
  ]);

  return (
    <SelectPopover
      anchorEl={anchorEl}
      onClose={handleClose}
      {...selectPopoverProps}
    >
      <div>
        {enableSearch && (
          <SearchBar
            searchValue={searchTerm}
            onSearch={onSearchTermChange}
            className={classNames?.searchBar}
            searchPlaceholder={texts?.searchPlaceholder}
          />
        )}
        {enableVirtualization ? (
          <VirtualizedMenuList
            options={filteredOptions}
            selectedOption={localValue}
            onItemSelect={onItemSelect}
            className={classNames?.menuList}
            classes={classNames}
            customOptionElement={customOptionElement}
            menuListTrailingElement={trailingElements}
            maxHeight={
              selectPopoverProps.maxHeight
                ? `calc(${selectPopoverProps.maxHeight} - ${MENU_LIST_PADDING}${
                    enableSearch ? ` - ${SEARCH_BAR_HEIGHT}` : ""
                  })`
                : undefined
            }
            virtualizerProps={virtualizerProps}
          />
        ) : (
          <MenuList
            className={classNames?.menuList}
            aria-label={selectAriaLabels.menuList}
          >
            {filteredOptions.map((option) => {
              return (
                <SelectItem
                  key={option.value}
                  option={option}
                  selectedOption={localValue}
                  onSelect={onItemSelect}
                  customOptionElement={customOptionElement}
                />
              );
            })}
            {trailingElements}
          </MenuList>
        )}
      </div>
    </SelectPopover>
  );
};
