import Typography from "@mui/material/Typography";

import { muiColors } from "../../../../themes";

export const Breadcrumb: React.FC<{ text: string; isHighlighted: boolean }> = ({
  text,
  isHighlighted,
}) => {
  return (
    <Typography
      variant="body2"
      color={isHighlighted ? muiColors.gray[500] : undefined}
      sx={{ marginRight: "5px" }}
    >
      {text}
    </Typography>
  );
};
