import { object, string } from "yup";

import { TrackedKeyTypeEnum } from "@/generated/accounts";

export const validationSchema = object().shape({
  type: string().required().oneOf(Object.values(TrackedKeyTypeEnum)),
  name: string()
    .required("Name is required")
    .min(1, "Name cannot be empty")
    .matches(/^\S+$/, "Name cannot contain any whitespace"),
});
