import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";
import { isRuleGroupType } from "react-querybuilder";

import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { ScopePicker } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/ScopePicker";
import { ChangeScopeDialog } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/ChangeScopeDialog";
import { ActionsPanel } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/ActionsPanel";
import { QueryBuilderPanel } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/QueryBuilderPanel";
import { queryByScope } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/QueryBuilderPanel/queries";
import {
  doesQueryHaveErrors,
  assignErrorsToQuery,
  areActionsEqual,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/utils";
import { useCreateStatement } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/useCreateStatement";
import { RbacPolicyStatementsInner } from "@/generated/auth";
import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";
import { UnsavedChangesDialog } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/UnsavedChangesDialog";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";
import { getScopeFromStatement } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getScopeFromStatement";
import { useRbacActionsContext } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/useRbacActionsContext";
import { useChangeQuery } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/useChangeQuery";

// Because we have a default of '*' in the cluster, we don't need to check for dirty in the cluster scope
const SCOPES_WITHOUT_DIRTY_CHECK = [Scope.cluster, Scope["komodor-actions"]];

type AddStatementDrawerProps = PropsWithChildren<{
  onAddStatement?: (newStatement: RbacPolicyStatementsInner) => void;
  onEditStatement?: (newStatement: RbacPolicyStatementsInner) => void;
  onClose: () => void;
}>;

export const AddStatementDrawerContent = ({
  onAddStatement,
  onEditStatement,
  onClose,
}: AddStatementDrawerProps) => {
  const {
    scope: selectedScope,
    setScope: setSelectedScope,
    statement,
    open,
  } = useStatementDrawerContext();
  const { globalActions } = useRbacActionsContext();

  // When the drawer is opened, we need to set the scope based on the statement
  useEffect(() => {
    if (open) {
      setSelectedScope(getScopeFromStatement(globalActions, statement));
    }
  }, [globalActions, statement, open, setSelectedScope]);

  const [nextScope, setNextScope] = React.useState<Scope | null>(null);
  const [isRuleBuilderDirty, setIsRuleBuilderDirty] = React.useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    React.useState(false);

  const {
    selectedActions,
    setSelectedActions,
    ruleBuilderQuery,
    setRuleBuilderQuery,
    createStatementObject,
  } = useCreateStatement({
    statement,
  });

  const { handleChangeRuleQuery } = useChangeQuery({
    isRuleBuilderDirty,
    ruleBuilderQuery,
    setRuleBuilderQuery,
    setIsRuleBuilderDirty,
  });

  const changeScope = useCallback(
    (nextScope: Scope) => {
      setSelectedScope(nextScope);
      setSelectedActions([]);
      setRuleBuilderQuery(queryByScope[nextScope]);
      setIsRuleBuilderDirty(false);
    },
    [setRuleBuilderQuery, setSelectedActions, setSelectedScope]
  );

  const handleConfirmChangeScope = useCallback(() => {
    if (nextScope === null) return;
    changeScope(nextScope);
  }, [nextScope, changeScope]);

  const handleOnChangeScope = useCallback(
    (nextScope: Scope) => {
      if (selectedActions.length === 0 && !isRuleBuilderDirty) {
        return changeScope(nextScope);
      }
      return setNextScope(nextScope);
    },
    [selectedActions.length, isRuleBuilderDirty, changeScope]
  );

  const handleSubmit = useCallback(() => {
    if (isRuleGroupType(ruleBuilderQuery)) {
      try {
        const queryWithErrors = assignErrorsToQuery(ruleBuilderQuery);
        if (doesQueryHaveErrors(queryWithErrors)) {
          // Inorder to see the errors, we need to set the query with the errors
          setRuleBuilderQuery(queryWithErrors);
          return;
        }
      } catch (e) {
        // TODO Lior: how to present the error?
        return;
      }
    }

    try {
      const action = statement ? onEditStatement : onAddStatement;
      action?.(createStatementObject());
    } catch (e) {
      // TODO Lior: how to present the error?
      return;
    }

    onClose();
  }, [
    ruleBuilderQuery,
    onAddStatement,
    onEditStatement,
    statement,
    createStatementObject,
    onClose,
    setRuleBuilderQuery,
  ]);

  const onCancelClick = useCallback(() => {
    if (
      !areActionsEqual(statement?.actions ?? [], selectedActions) ||
      isRuleBuilderDirty
    ) {
      setShowUnsavedChangesDialog(true);
      return;
    }
    onClose();
  }, [isRuleBuilderDirty, onClose, selectedActions, statement?.actions]);

  const handleCloseUnsavedChangesDialog = useCallback(
    (confirm: boolean) => {
      setShowUnsavedChangesDialog(false);
      if (confirm) {
        onClose();
      }
    },
    [onClose]
  );

  const shouldEnableAddButton = useMemo(() => {
    if (statement) return true;
    return (
      ((isRuleBuilderDirty &&
        !SCOPES_WITHOUT_DIRTY_CHECK.includes(selectedScope)) ||
        SCOPES_WITHOUT_DIRTY_CHECK.includes(selectedScope)) &&
      selectedActions.length > 0
    );
  }, [isRuleBuilderDirty, selectedActions.length, selectedScope, statement]);

  return (
    <>
      <Stack height={"100%"}>
        <Stack
          direction="row"
          alignItems="flex-start"
          columnGap="16px"
          sx={{ paddingRight: "48px", overflow: "auto" }}
        >
          <IconButton
            onClick={onCancelClick}
            sx={{ marginTop: "-4px" }}
            aria-label={createPolicyAriaLabels.statementForm.closeButton}
          >
            <Close />
          </IconButton>
          <Stack rowGap="16px" height="100%" flexGrow={1}>
            <Typography
              variant="h3"
              aria-label={createPolicyAriaLabels.statementForm.title}
            >
              {statement ? "Edit" : "Add"} statement
            </Typography>
            <ScopePicker onSelectScope={handleOnChangeScope} />
            <QueryBuilderPanel
              ruleBuilderQuery={ruleBuilderQuery}
              setRuleBuilderQuery={handleChangeRuleQuery}
            />
            <ActionsPanel
              selectedActions={selectedActions}
              setSelectedActions={setSelectedActions}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          columnGap="8px"
          sx={{
            margin: "auto -16px 16px -16px",
            padding: "16px 24px",
            borderTop: `1px solid ${muiTheme.palette.divider}`,
          }}
        >
          <Button
            onClick={onCancelClick}
            aria-label={createPolicyAriaLabels.statementForm.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!shouldEnableAddButton}
            aria-label={createPolicyAriaLabels.statementForm.submitButton}
          >
            {statement ? "Edit" : "Add"} statement
          </Button>
        </Stack>
      </Stack>
      {nextScope && (
        <ChangeScopeDialog
          onClose={() => setNextScope(null)}
          onConfirm={handleConfirmChangeScope}
        />
      )}
      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={handleCloseUnsavedChangesDialog}
          entityName={"statement"}
        />
      )}
    </>
  );
};
