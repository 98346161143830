import { useMemo } from "react";

import { ColumnProperties } from "@/components/common/ResourceListTable/TableProperties";
import { BaseResourceListTableProps } from "@/components/common/ResourceListTable/ResourceListTableProps";

export const useTableBodyProperties = (
  overridingColumns: ColumnProperties[] | undefined,
  overrideRowClick: BaseResourceListTableProps["overrideRowClick"]
) => {
  return useMemo(
    () => ({
      columns: {
        data: overridingColumns ?? [],
      },
      overrideRowClick,
    }),
    [overridingColumns, overrideRowClick]
  );
};
