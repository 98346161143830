import { GridColDef } from "@mui/x-data-grid-pro";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { WorkloadNameCell } from "@/shared/components/ConnectedWorkloadsCell/WorkloadNameCell";

export const connectedWorkloadsTableColumns: GridColDef<KomodorUidParams>[] = [
  {
    field: "name",
    headerName: "Workload Name",
    flex: 1,
    renderCell: ({ row }) => <WorkloadNameCell {...row} />,
  },
  {
    field: "namespace",
    headerName: "Namespace",
    flex: 1,
  },
  {
    field: "kind",
    headerName: "Type",
    flex: 1,
  },
];
