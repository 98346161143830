import { useEffect, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Options } from "react-markdown";

import { ChatMessage } from "components/komodor-ui/Chat/ChatMessage";
import { ChatMessageType } from "components/komodor-ui/Chat/types";
import { TypingIndicator } from "components/komodor-ui/Chat/TypingIndicator";

export interface ChatMessagesListProps {
  messages: ChatMessageType[];
  isLoading?: boolean;
  isFailed?: boolean;
  failedMessageContent?: ChatMessageType["content"];
  autoScroll?: boolean;
  markdownProps?: Options;
}

export const ChatMessagesList: React.FC<ChatMessagesListProps> = ({
  messages,
  isLoading,
  isFailed,
  failedMessageContent,
  autoScroll = true,
  markdownProps,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const chatMessages = useMemo(() => {
    const elements = messages.map((msg) => (
      <ChatMessage key={msg.id} message={msg} markdownProps={markdownProps} />
    ));
    if (isFailed) {
      elements.push(
        <ChatMessage
          message={{
            id: "failed",
            content: failedMessageContent,
            isFailed: true,
            isUser: false,
          }}
        />
      );
    } else if (isLoading) {
      elements.push(
        <ChatMessage
          message={{
            id: "typing",
            content: <TypingIndicator />,
            isUser: false,
          }}
        />
      );
    }
    return elements;
  }, [messages, isFailed, isLoading, markdownProps, failedMessageContent]);

  // Auto-scroll to the bottom when new messages are added
  useEffect(() => {
    if (autoScroll) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [autoScroll, chatMessages]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <List>
        {chatMessages}
        {/* Reference for auto-scrolling */}
        <div ref={messagesEndRef} />
      </List>
    </Box>
  );
};
