import { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { muiTheme } from "@komodorio/design-system";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { TRACKED_KEYS_ROUTE } from "@/components/routes/routes";
import { useTrackedKeysContext } from "@/shared/context/trackedKeys/useTrackedKeysContext";
import { useGetTrackedKeys } from "@/shared/hooks/accounts-service/client/trackedKeys/useGetTrackedKeys";
import { Refetch } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";

export const MenuListTrailingElement = () => {
  const navigate = useNavigate();
  const { addNewKey, maxAccountTrackedKeys } = useTrackedKeysContext();
  const { data, refetch } = useGetTrackedKeys({});
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const shouldEnableAddButton = useMemo(
    () => (data?.length ?? 0) < maxAccountTrackedKeys,
    [data, maxAccountTrackedKeys]
  );

  const handleDialogClose = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        navigate(TRACKED_KEYS_ROUTE);
      }
      setShowConfirmationDialog(false);
    },
    [navigate]
  );

  return (
    <Stack
      direction="row"
      columnGap="16px"
      sx={{ borderTop: `1px solid ${muiTheme.palette.divider}` }}
    >
      <Button
        size="large"
        onClick={() => addNewKey(refetch as Refetch)}
        disabled={!shouldEnableAddButton}
        aria-label="Create new tracked key"
      >
        Create new
      </Button>
      <Button
        size="large"
        onClick={() => setShowConfirmationDialog(true)}
        aria-label="Manage selectors"
      >
        Manage selectors
      </Button>
      {showConfirmationDialog && (
        <ConfirmationDialog
          onClose={handleDialogClose}
          title={"Unsaved changes"}
          type="error"
          width="max-content"
          content={
            <Typography variant="body2">
              You have unsaved changes that will be lost if you leave this page.
              Are you sure you want to proceed?
            </Typography>
          }
        />
      )}
    </Stack>
  );
};
