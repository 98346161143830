import React, { useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid-pro";

import { lightMuiTooltipStyle } from "styles/styles";

interface HoveredGridRowModel extends GridValidRowModel {
  hovered?: boolean;
}

type Action<R extends HoveredGridRowModel> = {
  title?: string;
  enabled: boolean;
  key: string;
  ariaLabel?: string;
  icon: React.ReactElement;
  onAction?: (params: GridRenderCellParams<R>) => void;
};

interface Props<R extends HoveredGridRowModel> {
  actions: Action<R>[];
  params: GridRenderCellParams<R>;
}

export const ActionCell = <R extends HoveredGridRowModel>({
  actions,
  params,
}: Props<R>): React.ReactElement => {
  // https://app.clickup.com/t/86c2dqm28
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const actionButtons = useMemo(() => {
    return actions.map((action) => (
      <Tooltip
        title={action.title}
        key={action.key}
        componentsProps={lightMuiTooltipStyle}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <span>
          <IconButton
            onClick={() => (action.onAction ? action.onAction(params) : null)}
            disabled={!action.enabled}
          >
            {action.icon}
          </IconButton>
        </span>
      </Tooltip>
    ));
  }, [actions, params]);

  return (
    <Stack
      direction="row"
      gap="1px"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      {params.row.hovered && actionButtons}
    </Stack>
  );
};
