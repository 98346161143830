import React from "react";
import Drawer from "@mui/material/Drawer";

import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";

export const AddStatementDrawer = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) => {
  const { open } = useStatementDrawerContext();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      aria-label={createPolicyAriaLabels.addStatementDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          overflowY: "hidden",
          minWidth: "800px",
          width: "60%",
          padding: "16px",
        },
      }}
    >
      {children}
    </Drawer>
  );
};
