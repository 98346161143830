/* eslint-disable max-params */

import { APP_VIEW_ID_HEADER, WORKSPACE_ID_HEADER } from "./constants";
import { WorkspaceOptions } from "./types";

const getWorkspaceHeader = (
  options?: WorkspaceOptions
): Record<string, string> => {
  if (!options) {
    return {};
  }

  const {
    workspaceId,
    workspaceKind,
    skipWorkspaceHeader,
    useClusterWorkspaceAsScopedWorkspace,
  } = options;
  if (!workspaceId || skipWorkspaceHeader) {
    return {};
  }

  if (
    workspaceKind === "scoped" ||
    (workspaceKind === "cluster" && useClusterWorkspaceAsScopedWorkspace)
  ) {
    return { [WORKSPACE_ID_HEADER]: workspaceId };
  }

  return { [APP_VIEW_ID_HEADER]: workspaceId };
};

export async function executeAPIGet(
  path: string,
  authorization: string,
  options?: WorkspaceOptions
): Promise<Response> {
  const wsHeader = getWorkspaceHeader(options);

  return fetch(path, {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...wsHeader,
    },
  });
}

export async function executeAPIPost(
  path: string,
  authorization: string,
  body: string,
  options?: WorkspaceOptions
): Promise<Response> {
  const wsHeader = getWorkspaceHeader(options);

  return fetch(path, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...wsHeader,
    },
    body,
  });
}
