import { Divider } from "@komodorio/design-system/deprecated";
import { subHours } from "date-fns";
import { get } from "lodash";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { TooltipWrapper } from "react-tooltip";

import { formatTimeIntervalFromDates } from "../../../../../../../../shared/utils/dateUtils";
import { WorkloadHealthyPodsOutput } from "../../../../../../../monitorsView/workflowRunView/checks/availability/types";
import Resource from "../../../../../../../ResourceView/resources";
import { useInterval } from "../../../../../../useInterval";
import { ImpactTile } from "../../common/ImpactTile";
import { Section, SectionName, Tiles } from "../../common/styles";
import { OccurencesModel, TriageIssueDataParsed } from "../../common/types";
import {
  calcTrend,
  ISSUE_DURATION_TOOLTIP,
  IssueDurationTooltip,
} from "../../tooltips/IssueDurationTooltip";
import {
  OccurencesTooltip,
  PAST_24_HOURS_TOOLTIP,
  PAST_WEEK_HOURS_TOOLTIP,
} from "../../tooltips/OccurrencesTooltip";

import { WorkflowRunWithResults } from "@/components/monitorsView/common/types";

const useOccurrencesTrends = (occurrences: OccurencesModel) => {
  return useMemo(() => {
    return {
      percentageWeekChange: calcTrend(
        occurrences.issuesCountLastWeek,
        occurrences.issuesCountWeekBeforeLastOne
      ),
      percentageDayChange: calcTrend(
        occurrences.issuesCountLastDay,
        occurrences.issuesCountDayBeforeLastOne
      ),
    };
  }, [
    occurrences.issuesCountDayBeforeLastOne,
    occurrences.issuesCountLastDay,
    occurrences.issuesCountLastWeek,
    occurrences.issuesCountWeekBeforeLastOne,
  ]);
};

export const ImpactSection: React.FC<{
  wfRun: WorkflowRunWithResults;
  resource: Resource | undefined;
  podsHealthCheckOutput: WorkloadHealthyPodsOutput | undefined;
  pastIssuesIncludingCurrent: TriageIssueDataParsed[];
  isFetchingResource: boolean;
}> = ({
  wfRun,
  resource,
  podsHealthCheckOutput,
  pastIssuesIncludingCurrent,
  isFetchingResource,
}) => {
  const closed = !!wfRun?.closedAt;
  const { readyReplicas, desiredReplicas } = useMemo(() => {
    const { ready, desired } = get(resource, "replicas", {});
    if (closed) {
      return {
        readyReplicas: podsHealthCheckOutput?.readyReplicas ?? 0,
        desiredReplicas: podsHealthCheckOutput?.desiredReplicas ?? 0,
      };
    }
    return {
      readyReplicas: ready ?? 0,
      desiredReplicas: desired ?? 0,
    };
  }, [
    closed,
    podsHealthCheckOutput?.desiredReplicas,
    podsHealthCheckOutput?.readyReplicas,
    resource,
  ]);

  const issuesPast24h = pastIssuesIncludingCurrent?.filter(
    (issue) => issue.startTime > subHours(wfRun.eventTime, 24)
  );

  const { percentageDayChange, percentageWeekChange } = useOccurrencesTrends(
    wfRun.triageData.occurrences
  );

  const lastDayTrend =
    isFinite(percentageDayChange) && percentageDayChange !== 0
      ? percentageDayChange
      : undefined;
  const lastWeekTrend =
    isFinite(percentageWeekChange) && percentageWeekChange !== 0
      ? percentageWeekChange
      : undefined;

  return (
    <Section>
      <SectionName>impact</SectionName>
      <Tiles>
        <ImpactTile
          title="available replicas"
          value={`${readyReplicas}/${desiredReplicas}`}
          loading={isFetchingResource || !resource}
        />
        <Divider variant="vertical" />
        <LiveDurationTile
          wfStartTime={wfRun.eventTime}
          wfEndTime={wfRun.closedAt}
        />
        <Divider variant="vertical" />
        <TooltipWrapper tooltipId={PAST_24_HOURS_TOOLTIP}>
          <ImpactTile
            title="occurrences - past 24h"
            value={issuesPast24h.length.toString()}
            changeValue={lastDayTrend}
          />
        </TooltipWrapper>
        <Divider variant="vertical" />
        <TooltipWrapper tooltipId={PAST_WEEK_HOURS_TOOLTIP}>
          <ImpactTile
            title="occurrences - past 7d"
            value={pastIssuesIncludingCurrent.length.toString()}
            changeValue={lastWeekTrend}
          />
        </TooltipWrapper>
      </Tiles>
      {pastIssuesIncludingCurrent && (
        <IssueDurationTooltip sevenDaysIssues={pastIssuesIncludingCurrent} />
      )}
      {lastDayTrend && (
        <OccurencesTooltip
          percentage={lastDayTrend}
          text="24 hours"
          tooltipId={PAST_24_HOURS_TOOLTIP}
        />
      )}
      {lastWeekTrend && (
        <OccurencesTooltip
          percentage={lastWeekTrend}
          text="7 days"
          tooltipId={PAST_WEEK_HOURS_TOOLTIP}
        />
      )}
    </Section>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getIssueEndTime = (wfEndTime?: Date | null) =>
  wfEndTime ? wfEndTime : new Date();

export const DURATION_REFRESH_TIME_MILLISECONDS = 1000;

const LiveDurationTile: React.FC<{
  wfStartTime: Date;
  wfEndTime: Date | null;
}> = ({ wfStartTime, wfEndTime }) => {
  const issueDuration = useIssueDuration(wfStartTime, wfEndTime);

  return (
    <TooltipWrapper tooltipId={ISSUE_DURATION_TOOLTIP}>
      <ImpactTile title="current issue duration" value={issueDuration} />
    </TooltipWrapper>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useIssueDuration = (startTime: Date, endTime: Date | null) => {
  const [updatedEndTime, setIssueEndTime] = useState<Date>();
  const refreshEndTime = useCallback(() => {
    if (endTime) {
      return;
    }
    setIssueEndTime(new Date());
  }, [endTime]);

  useEffect(() => {
    setIssueEndTime(getIssueEndTime(endTime));
  }, [endTime]);
  useInterval(refreshEndTime, DURATION_REFRESH_TIME_MILLISECONDS);
  return useMemo(() => {
    if (!startTime || !updatedEndTime) {
      return "";
    }
    return formatTimeIntervalFromDates(startTime, updatedEndTime);
  }, [startTime, updatedEndTime]);
};
