import { useCallback } from "react";
import { isEqual, omit } from "lodash";

import {
  TIMEFRAME_PARAM_KEY,
  TIME_WINDOW_PARAM_KEY,
} from "../../config/urlSearchParamsKeys";
import {
  drawerAtSelector,
  drawersStackSelector,
} from "../../store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "../../store/drawersStackStore/drawersStackStore";
import {
  DrawerState,
  DrawerStatePush,
  UrlStates,
} from "../../store/drawersStackStore/types";

import { useDrawerStackIndex } from "./useDrawerStackIndex";
import { useIsOldDrawerStateMechanism } from "./useIsOldDrawerStateMechanism";

export const useIsInsideDrawer = () => {
  const drawerIndex = useDrawerStackIndex();
  const isOldDrawerStateMechanism = useIsOldDrawerStateMechanism();

  return drawerIndex !== undefined || isOldDrawerStateMechanism;
};

export const useCurrentDrawerState = <T extends DrawerState>() => {
  const drawerIndex = useDrawerStackIndex();
  const drawerState = useDrawersStackStore(drawerAtSelector<T>(drawerIndex));

  return drawerState;
};

export const useIsInCurrentlyDisplayedDrawer = () => {
  const drawersStack = useDrawersStackStore(drawersStackSelector);
  const drawerIndex = useDrawerStackIndex();
  return {
    hasDrawersOpen: drawersStack.length > 0,
    isInCurrentlyDisplayedDrawer: drawerIndex === drawersStack.length - 1,
  };
};

// The time window and timeframe URL states are handled by a dedicated hook that does not stringify the values.
const excludeFromStriginfying = [TIME_WINDOW_PARAM_KEY, TIMEFRAME_PARAM_KEY];
export const parseDrawerUrlStatesToSearchParams = (
  urlStates: UrlStates | undefined
) => {
  return new URLSearchParams(
    Object.entries(urlStates ?? {}).map(([key, value]) => [
      key,
      excludeFromStriginfying.some((exKey) => key.includes(exKey))
        ? (value as string)
        : JSON.stringify(value),
    ])
  );
};

export const useIsOpenInDrawerStack = () => {
  const drawersStack = useDrawersStackStore(drawersStackSelector);
  return useCallback(
    (newDrawerState: DrawerStatePush | undefined) => {
      return drawersStack.some((drawerState) => {
        if (!newDrawerState) {
          return false;
        }
        return isEqual(
          omit(drawerState, "index"),
          omit(newDrawerState, "index")
        );
      });
    },
    [drawersStack]
  );
};
