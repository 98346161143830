import { Dictionary } from "lodash";

import { InspectionStore, ReportedAction } from "./inspectionStore";

import { ResourceActions } from "@/shared/constants/resourceActions";

//getters
export const selectedNamespacesPerClusterSelector = (
  state: InspectionStore
): Dictionary<string[]> => state.selectedNamespacesPerCluster;
export const selectReportedActionsSelector = (
  state: InspectionStore
): Dictionary<ResourceActions> => state.reportedActions;

//setters
export const setNamespacesPerClusterSelector = (
  state: InspectionStore
): ((clusterName: string, namespaces: string[]) => void) =>
  state.setSelectedNamespacesPerCluster;
export const setReportedActionSelector = (
  state: InspectionStore
): ((reportedAction: ReportedAction) => void) => state.setReportedAction;
export const removeReportedActionSelector = (
  state: InspectionStore
): ((reportedActionId: string) => void) => state.removeReportedAction;
