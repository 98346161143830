import { array, InferType, object, string, ValidationError } from "yup";

import { RbacPolicy } from "@/generated/auth";
import { validationSchema } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/validationSchema";

export type ErrorsKey = keyof InferType<typeof validationSchema> | "general";
export type Errors = Record<ErrorsKey, string>;

const patternSchema = object({
  include: string().required("Include is required"),
  exclude: string(),
});

// Selector validation schema
const selectorSchema = object({
  type: string().required("Selector type is required"),
  key: string().required("Selector key is required"),
  value: string().required("Selector value is required"),
});

// SelectorPattern validation schema
const selectorPatternSchema = object({
  key: string().required("Selector key is required"),
  value: patternSchema.required("Selector pattern value is required"),
});

const resourceScopeSchema = object({
  clusters: array().of(string()).default([]),

  clustersPatterns: array().of(patternSchema).default([]),

  namespaces: array().of(string()).default([]),

  namespacesPatterns: array().of(patternSchema).default([]),

  selectors: array().of(selectorSchema).default([]),

  selectorsPatterns: array().of(selectorPatternSchema).default([]),
});

const statementSchema = object({
  actions: array().of(string().required()).required("Actions are required"),

  resourcesScope: resourceScopeSchema,
}).required();

export const policyValidationSchema = object().shape({
  name: string()
    .required("Policy name is required")
    .min(1, "Policy name cannot be empty"),

  statements: array()
    .of(statementSchema)
    .required("Statements are required")
    .min(1, "At least one statement is required"),
});

export const validatePolicySchema = async (
  data: Partial<RbacPolicy>
): Promise<Errors | undefined> => {
  try {
    await policyValidationSchema.validate(data, {
      abortEarly: false,
    });

    return undefined;
  } catch (err) {
    const error = (err as ValidationError).inner[0];
    if (!error) {
      return undefined;
    }
    return { [error.path as ErrorsKey]: error.message } as Errors;
  }
};
