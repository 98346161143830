import {
  isRuleGroupType,
  Path,
  RuleGroupType,
  Schema,
  RuleType,
} from "react-querybuilder";

import { ExtendedOperator, FieldWithOperators, UIRuleType } from "../types";

export const getPredecessorSiblings = ({
  rule,
  rules,
  operators,
  path,
}: {
  rule: RuleType;
  rules: RuleType[];
  operators: ExtendedOperator[];
  path: Path;
}): RuleType[] => {
  const operator = operators?.find((op) => op.name === rule.operator);
  if (!operator) {
    return [];
  }

  // path is an array of numbers.  I want to replace the last number with the index of the rule
  const ruleIndex = path[path.length - 1];

  return rules
    .map((r, index) => {
      if (index === ruleIndex) {
        return false;
      }

      const ruleOperator = operators?.find((op) => op.name === r.operator);
      if (
        r.field === rule.field &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ruleOperator?.predecessors?.includes(rule.operator)
      ) {
        return { ...rule, path: [...path.slice(0, -1), index] };
      }

      return false;
    })
    .filter(Boolean) as RuleType[];
};

export const canAddRuleExclusion = ({
  rule,
  rules,
  operators,
  path,
}: {
  rule: UIRuleType;
  rules: RuleType[];
  operators: ExtendedOperator[];
  path: Path;
}) => {
  if (rule.canAddRule === false) {
    return false;
  }

  const operator = operators?.find((op) => op.name === rule.operator);

  const hasSuccessor = operator?.successors?.length > 0;
  if (!hasSuccessor) {
    return false;
  }

  return !getPredecessorSiblings({ rule, rules, operators, path }).length;
};

export const calcCanRemoveRuleExclusion = ({
  rule,
  rules,
  operators,
}: {
  rule: UIRuleType | RuleGroupType;
  rules: RuleType[];
  operators: ExtendedOperator[];
}) => {
  if (isRuleGroupType(rule)) {
    return false;
  }
  const operator = operators?.find((op) => op.name === rule.operator);

  const hasPredecessor = operator?.predecessors?.length > 0;
  if (!hasPredecessor) {
    return false;
  }

  return rules.some((otherRule) => {
    const otherOperator = operators?.find(
      (op) => op.name === otherRule.operator
    );
    return (
      otherRule.field === rule.field &&
      otherOperator.successors?.includes(rule.operator)
    );
  });
};

export const getGroupOperators = (
  ruleOrGroup: RuleType | RuleGroupType,
  schema: Schema<FieldWithOperators, string>
) => {
  if (isRuleGroupType(ruleOrGroup)) {
    return [];
  }
  const field = (schema.fields as FieldWithOperators[]).find(
    (f) => f.value === ruleOrGroup.field
  );

  return field?.operators ?? [];
};

export const shouldShowGroupCombinator = (
  ruleOrGroup: RuleType | RuleGroupType,
  index: number
) => {
  const isGroup = isRuleGroupType(ruleOrGroup);
  return index > 0 && isGroup;
};

export const getAllowedActions = ({
  ruleOrGroup,
  index,
  schema,
  rules,
  path,
}: {
  ruleOrGroup: UIRuleType | RuleGroupType;
  index: number;
  schema: Schema<FieldWithOperators, string>;
  rules: RuleType[];
  path: Path;
}) => {
  const isGroup = isRuleGroupType(ruleOrGroup);
  const isRemoveRuleAllowed =
    !isGroup && index === rules.length - 1 && ruleOrGroup.allowRemove !== false;

  // canAddRule will be equal to true if the rule has an operator that has a successor
  // and there is no rule after it that has the same field and an operator that has a predecessor
  const operators = getGroupOperators(ruleOrGroup, schema);

  const isAddRuleAllowed = isGroup
    ? false
    : canAddRuleExclusion({
        rule: ruleOrGroup,
        rules,
        operators,
        path,
      });

  const removeRuleExclusion = calcCanRemoveRuleExclusion({
    rule: ruleOrGroup,
    rules,
    operators: operators,
  });

  return {
    isRemoveRuleAllowed,
    isAddRuleAllowed,
    removeRuleExclusion,
  };
};
