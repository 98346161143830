import { createContext } from "react";

import { RbacPolicyAction } from "@/generated/auth";

export type ActionsContextState = {
  isLoadingActions: boolean;
  globalActions: RbacPolicyAction[];
};

export const RbacActionsContext = createContext<ActionsContextState>({
  isLoadingActions: false,
  globalActions: [],
});
