export const ProcessListAriaLabel = {
  table: {
    headerCellTemplate: "Process list header cell",
  },
};

export function createHeaderCellAreaLabel(cellName: string) {
  return `${ProcessListAriaLabel.table.headerCellTemplate} ${cellName}`;
}

export function createCustomHeaderCellAreaLabel(cellName: string) {
  return `${ProcessListAriaLabel.table.headerCellTemplate} Custom: ${cellName}`;
}
