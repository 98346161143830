import { useCallback } from "react";
import { Field, RuleGroupType } from "react-querybuilder";

import { QueryBuilderPanel } from "@/pages/organization-settings/WorkspaceManagementPage/components/drawer/QueryBuilderPanel";

interface Props {
  definition: RuleGroupType;
  index: number;
  fields: Field[];
  onQueryChange: (query: RuleGroupType, index: number) => void;
}

const newRuleStructure = [
  {
    field: "cluster",
    operator: "multiIs",
  },
];

export const StatementPanel: React.FC<Props> = ({
  definition,
  index,
  fields,
  onQueryChange,
}) => {
  const onQueryChangeWrapper = useCallback(
    (query: RuleGroupType) => {
      onQueryChange(query, index);
    },
    [index, onQueryChange]
  );

  return (
    <QueryBuilderPanel
      ruleBuilderQuery={definition}
      setRuleBuilderQuery={onQueryChangeWrapper}
      options={{
        showTitle: false,
        generic: true,
      }}
      showAddRuleGroup
      addRuleGroupLabel="Add"
      newRuleGroupStructure={newRuleStructure}
      fields={fields}
    />
  );
};
