import {
  ComparisonTable,
  LabeledSwitch,
  LightTooltip,
} from "@komodorio/design-system/komodor-ui";
import { useMemo, useRef, useState } from "react";

import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";
import {
  MAX_COMPARED_SERVICES,
  SERVICE_DRIFT_ATTRIBUTES_COMPONENT_NAME,
} from "../constants";
import { useDriftDatadogReportTime } from "../useDriftDatadogReportTime";
import { useShowOnlyDrift } from "../../common/hooks";
import { EmptyStateComparedItems } from "../../common/EmptyStateComparedItems";
import { DriftType } from "../../context/types";
import { EmptyStateBaseline } from "../../common/EmptyStateBaseline";
import { TableContainer } from "../../common/styles";
import { DiffModal, DiffModalParams } from "../../common/DiffModal";

import { ContainersAttributesComparison } from "./ContainersAttributesComparison";
import { useAttributesTableData } from "./tablesDataHooks";

import { useServiceDriftComparison } from "@/shared/hooks/workspaces-api/drift/useServiceDriftComparison";
import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import { ErrorState } from "@/components/DriftAnalysis/Services/Attributes/ErrorState";

const drawerErrorStateMessage =
  "An error occurred or you might not have the right permissions to view these resources";
export const AttributesComparison: React.FC<{
  isInDrawer?: boolean;
}> = ({ isInDrawer = false }) => {
  const {
    baselineSelection,
    comparedSelections,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  } = useDriftComparisonContext();
  const services = useKomodorServices().servicesAsDictionary.services;

  const { data, isLoading, isFetching, isError, refetch } =
    useServiceDriftComparison(
      {
        baselineServiceKomodorUid: baselineSelection?.value ?? "",
        targetServicesKomodorUid:
          comparedSelections?.map((service) => service.value) ?? [],
      },
      {
        enabled: baselineSelection !== null,
      }
    );

  const {
    showOnlyDriftTooltipTitle,
    onShowOnlyDriftChange,
    isDriftDetected,
    showOnlyDrift,
  } = useShowOnlyDrift({
    targetElements: data?.targetServices,
  });
  const divRef = useRef<HTMLDivElement>(null);
  useDriftDatadogReportTime({
    component: SERVICE_DRIFT_ATTRIBUTES_COMPONENT_NAME,
    isLoading: isFetching,
    divRef,
  });

  const [diffModal, setDiffModal] = useState<DiffModalParams | null>(null);

  const { attributes, baseline, compared } = useAttributesTableData({
    showOnlyDrift: showOnlyDrift ?? false,
    data,
    setDiffModal,
  });

  const servicesLength = useMemo(
    () => Object.keys(services).length,
    [services]
  );

  return (
    <TableContainer ref={divRef}>
      <ComparisonTable
        title="Service Attributes"
        titleRightElement={
          <LightTooltip title={showOnlyDriftTooltipTitle}>
            <div>
              <LabeledSwitch
                label="Show only drift"
                disabled={!isDriftDetected}
                sx={{
                  typography: {
                    color: isDriftDetected ? "primary.main" : "text.secondary",
                    fontSize: "12px",
                    fontWeight: 500,
                  },
                }}
                checked={showOnlyDrift ?? false}
                onCheck={onShowOnlyDriftChange}
              />
            </div>
          </LightTooltip>
        }
        limit={MAX_COMPARED_SERVICES}
        isLoading={isLoading && baselineSelection !== null}
        isError={isError}
        attributes={attributes}
        baseline={baseline}
        compared={compared}
        noBaselineElement={
          <EmptyStateBaseline
            type={DriftType.Services}
            disabled={servicesLength === 0}
            onButtonClick={() => setIsBaselineSelectorOpen(true)}
          />
        }
        noComparedItemsElement={
          comparedSelections != null && comparedSelections.length > 0 ? null : (
            <EmptyStateComparedItems
              type={DriftType.Services}
              withButton={baselineSelection !== null}
              onButtonClick={() => setIsComparedSelectorOpen(true)}
            />
          )
        }
        errorStateElement={
          <ErrorState
            onRetryClick={isInDrawer ? undefined : refetch}
            title={isInDrawer ? drawerErrorStateMessage : undefined}
          />
        }
      />
      <ContainersAttributesComparison
        data={data}
        isLoading={isLoading && baselineSelection !== null}
        isError={isError}
        errorStateElement={
          <ErrorState
            onRetryClick={isInDrawer ? undefined : refetch}
            title={isInDrawer ? drawerErrorStateMessage : undefined}
          />
        }
        showOnlyDrift={showOnlyDrift ?? false}
      />
      {diffModal ? (
        <DiffModal
          title={diffModal?.title}
          baselineUid={baselineSelection?.value ?? ""}
          comparedUid={diffModal.comparedUid}
          oldObj={diffModal?.oldObj}
          newObj={diffModal?.newObj}
          onClose={() => setDiffModal(null)}
        />
      ) : null}
    </TableContainer>
  );
};
