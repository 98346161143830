import { useCallback, useMemo, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import { DEFAULT_PAGE_SIZES } from "@komodorio/design-system/komodor-ui";

import { getPaginationModel } from "@/components/ClustersView/utils";
import { DEFAULT_PAGINATION_MODEL } from "@/pages/organization-settings/account/AgentsPage/constants";
import { usePaginationContext } from "@/shared/context/tableContext/usePaginationContext";

export const PAGE_SIZE_OPTIONS = [5, 10, 15];

export const useDataGridPagination = (params?: {
  limit?: number;
  offset?: number;
}) => {
  const { pageSize, setPageSize } = usePaginationContext();
  const [filters, setFilters] = useState<{ limit: number; offset: number }>({
    limit: params?.limit || pageSize || DEFAULT_PAGE_SIZES[0],
    offset: params?.offset || DEFAULT_PAGINATION_MODEL.page,
  });

  const paginationModel = useMemo<GridPaginationModel>(
    () => getPaginationModel(filters.limit, filters.offset),
    [filters]
  );

  const handlePaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setPageSize(newPaginationModel.pageSize);
      setFilters({
        ...filters,
        limit: newPaginationModel.pageSize,
        offset: newPaginationModel.page * newPaginationModel.pageSize,
      });
    },
    [filters, setPageSize]
  );

  return {
    paginationModel,
    handlePaginationModelChange,
  };
};
