import React from "react";

import { PageName, usePages } from "./pages";
import { AppBarLink } from "./AppBarLink";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const pageNameInInspection = PageName.Helm;
const pageNameInAddOns = PageName.k8sAddonsHelmCharts;

export const HelmLink: React.FC = () => {
  const { helmChartsPageInK8SAddons } = useOverridableFlags();

  const name = helmChartsPageInK8SAddons
    ? pageNameInAddOns
    : pageNameInInspection;

  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
    />
  ) : null;
};
