import React from "react";
import styled from "styled-components";

import CopyCode from "../../integrations/installation/kubernetes/CopyCode";
import LinkToDocs from "../LinkToDocs";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";
import { UPGRADE_DOCS_LINK } from "../../../shared/constants/docsLinks";

import { UpgradeCommands } from "./UpgradeAgentCTA";

const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-end: 2rem;
`;

const UpgradeAgentInstruction: React.FC = () => {
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND
  );
  return (
    <InstructionContainer>
      <CopyCode value={commandStr} width="44rem" height="4.5rem" />
      <LinkToDocs docsUrl={UPGRADE_DOCS_LINK} />
    </InstructionContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default UpgradeAgentInstruction;
