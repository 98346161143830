import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

import { auditLogsInitialState } from "./initialState";

import { startTimeToApiFormat } from "@/pages/organization-settings/account/AuditPage/utils";
import { Context } from "@/pages/organization-settings/account/AuditPage/types";

export type AuditLogsContextState = Context & {
  setConfigurationData: (data: Partial<Context>) => void;
  clearFilters?: () => void;
  apiStartTime?: string;
};

const initialState: AuditLogsContextState = {
  ...auditLogsInitialState,
  setConfigurationData: () => undefined,
  clearFilters: () => undefined,
  apiStartTime: startTimeToApiFormat(auditLogsInitialState.dateRange),
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const AuditLogsContext =
  createContext<AuditLogsContextState>(initialState);

export const AuditLogsContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const setConfigurationData = useCallback((data: Partial<Context>) => {
    setState((prevState: AuditLogsContextState) => ({ ...prevState, ...data }));
  }, []);

  const clearFilters = useCallback(() => {
    setState(initialState);
  }, []);

  const [state, setState] = useState<AuditLogsContextState>({
    ...initialState,
    setConfigurationData,
    clearFilters,
  });

  const apiStartTime = useMemo(() => {
    return startTimeToApiFormat(state.dateRange);
  }, [state.dateRange]);

  return (
    <AuditLogsContext.Provider value={{ ...state, apiStartTime }}>
      {children}
    </AuditLogsContext.Provider>
  );
};
