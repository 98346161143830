import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { unitOptions } from "../../logsViewConstants";
import {
  storedLineNumberEnabled,
  storedTextWrappingEnabled,
} from "../../logsViewerGlobalSettings";
import { logsViewerAriaLabels } from "../../logsViewerAriaLabels";

import { MaxLinesNum } from "./inputs/MaxLinesNum";
import { SinceInputs } from "./inputs/SinceInputs/SinceInputs";
import { MaxNumOfLinesValue } from "./inputs/OnlyNumbersInput";
import { useCheckboxData } from "./useCheckboxData";
import { ErrorsMap, validateLogsOptions } from "./logsOptionsSchema";
import { CheckboxOptions } from "./inputs/CheckboxOptions";

import { PodLogsMode } from "@/shared/hooks/podLogs/types";
import { useLogsViewerInLocalStorage } from "@/components/common/LogsViewer/localStorage/useLogsViewerInLocalStorage";
import { DurationOption } from "@/components/common/LogsViewer/localStorage/logsViewerLocalStorage";

const { close: closeButtonAriaLabel, save: saveButtonAriaLabel } =
  logsViewerAriaLabels.dialog.button;

const Container = styled(Dialog)`
  .MuiDialog-paper {
    width: 450px;
    padding: 16px 24px;
    gap: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  mode: PodLogsMode;
};

export const LogsSettingsDialog: React.FC<Props> = ({
  isOpen,
  handleClose,
  mode,
}) => {
  const [, setLogsViewSettings] = useLogsViewerInLocalStorage();

  const [{ tail, sinceNumber, sinceUnit, sendSince, sendTail }, setLogsParams] =
    useLogsViewerInLocalStorage();

  const [linesNum, setLinesNum] = useState<MaxNumOfLinesValue>(tail);

  const [tailEnabled, setTailEnabled] = useState(sendTail);
  const [sinceEnabled, setSinceEnabled] = useState(sendSince);

  const [sinceNumberValue, setSinceNumberValue] =
    useState<MaxNumOfLinesValue>(sinceNumber);
  const [sinceNumberUnit, setSinceNumberUnit] = useState<
    MuiSelectionOption<string> | undefined
  >(unitOptions[sinceUnit as DurationOption]);

  const [errors, setErrors] = useState<Partial<ErrorsMap>>({} as ErrorsMap);

  const { values, checkboxData } = useCheckboxData();

  const handleSave = async () => {
    const errors = await validateLogsOptions({
      tail: Number(linesNum),
      sinceNumber: Number(sinceNumberValue),
      sinceUnit: sinceNumberUnit?.value as DurationOption,
    });

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const { showLineNumber, showTimestamp, wrapText } = values;
    setLogsViewSettings({
      showTimestamp,
      showLineNumber,
      wrapText,
    });
    storedTextWrappingEnabled.set(wrapText.toString());
    storedLineNumberEnabled.set(showLineNumber.toString());

    setLogsParams({
      tail: Number(linesNum),
      sinceNumber: Number(sinceNumberValue),
      sinceUnit: sinceNumberUnit?.value as DurationOption,
      sendSince: sinceEnabled,
      sendTail: tailEnabled,
    });

    handleClose();
  };

  return (
    <Container
      open={isOpen}
      onClose={handleClose}
      aria-label="logs setting dialog"
    >
      <Title>
        <Typography variant="h3"> Logs Options </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Title>
      <InputsContainer>
        {mode === PodLogsMode.Live && (
          <>
            <MaxLinesNum
              linesNum={linesNum}
              setNumOfLines={setLinesNum}
              enabled={tailEnabled}
              setEnabled={setTailEnabled}
              error={errors.tail}
            />
            <SinceInputs
              sinceNumberValue={sinceNumberValue}
              setSinceNumberValue={setSinceNumberValue}
              unit={sinceNumberUnit}
              setUnit={setSinceNumberUnit}
              enabled={sinceEnabled}
              setEnabled={setSinceEnabled}
              error={errors.sinceNumber}
            />
          </>
        )}
      </InputsContainer>
      <CheckboxOptions displayOptions={checkboxData} />
      <Actions>
        <Button aria-label={closeButtonAriaLabel} onClick={handleClose}>
          Close
        </Button>
        <Button
          aria-label={saveButtonAriaLabel}
          onClick={handleSave}
          variant="contained"
        >
          Save
        </Button>
      </Actions>
    </Container>
  );
};
