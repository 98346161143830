import { RS } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { OwnerReferenceNameJsonPath } from "./constants";
import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesReplicaSetResource = {
  NameInK8S: "replicasets",
  Group: "workloads",
  NavBarName: "ReplicaSets",
  PresentName: "Replica Sets",
  Namespaced: true,
  CustomColumns: [OwnerReferenceNameJsonPath],
  UnnecessaryColumns: ["Selector", "Containers", "Images"],
  SupportedActions: [AvailableActions.Edit, AvailableActions.Delete],
  Kind: SupportedResourcesKinds.KubernetesReplicaSetResource,
  Icon: RS,
  ShouldBackgroundTrigger: true,
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Ready" },
    { name: "Current" },
    { name: "Desired" },
    { name: "Controlled By" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  hasLabels: true,
};
