import React from "react";

import { AuditData } from "@/components/Audit/types";
import { useGetRbacRoleById } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoleById";
import { useGetRbacPolicyById } from "@/shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicyById";
import { DefaultAuditDetails } from "@/components/Audit/AuditDetailsCommon";

const rejectPolicy = (policyNames: string[], policyName?: string) =>
  policyNames.filter((p) => p !== policyName);

const addPolicy = (policyNames: string[], policyName?: string) => {
  if (!policyName) {
    return policyNames;
  }
  return [...policyNames, policyName];
};

const addDetailsToData = ({
  data,
  roleName,
  currentPolicy,
  policyNames,
}: {
  data: AuditData;
  roleName?: string;
  policyNames: string[];
  currentPolicy?: string;
}) => {
  const newDetails = {
    role: roleName,
    policies: policyNames,
  };

  const oldDetails = {
    role: roleName,
    policies: data?.details?.new
      ? rejectPolicy(policyNames, currentPolicy)
      : addPolicy(policyNames, currentPolicy),
  };

  return {
    ...data,
    details: { new: newDetails, old: oldDetails },
  } as unknown as AuditData;
};

const Base: React.FC<{ data: AuditData }> = ({ data }) => {
  const roleId = (data?.details?.new?.roleId ||
    data?.details?.old?.roleId) as string;
  const { data: role } = useGetRbacRoleById({ id: roleId });
  const { name: roleName, rbacPolicies: roleRbacPolicies } = role ?? {};

  const policyNames = (roleRbacPolicies || []).map((p) => p.name);

  const policyId = (data?.details?.new?.policyId ||
    data?.details?.old?.policyId) as string;
  const { data: policy } = useGetRbacPolicyById({ id: policyId });
  const { name: policyName } = policy?.[0] ?? {};

  const tmpData = addDetailsToData({
    data,
    roleName,
    policyNames,
    currentPolicy: policyName,
  });

  return <DefaultAuditDetails data={tmpData} />;
};

export const RbacPolicyAuditDetails = React.memo(Base);
