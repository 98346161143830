import { KubernetesResource } from "./KubernetesResource";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesClusterRoleBindingsResource: KubernetesResource = {
  NameInK8S: "clusterrolebindings",
  Group: "access-control",
  NavBarName: "Cluster Role Bindings",
  PresentName: "Cluster Role Bindings",
  Namespaced: false,
  UnnecessaryColumns: [],
  Kind: SupportedResourcesKinds.KubernetesClusterRoleBindingsResource,
  Headers: [
    { name: "Name" },
    { name: "Bindings" },
    { name: "Age" },
    { name: "Labels", hide: true },
    { name: "Annotations", hide: true },
  ],
  hasLabels: false,
};
