import { CSSProperties, useMemo, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { muiTheme } from "@komodorio/design-system";
import { Pod } from "kubernetes-types/core/v1";
import { SingleSelect } from "@komodorio/design-system/komodor-ui";

import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";
import { Panel } from "../../../Inspection/styles";
import { LinesLoader } from "../../../common/loaders/Line";
import { LogsUpgradeAgentAlert } from "../../../common/LogsViewer/LogsUpgradeAgentAlert/LogsUpgradeAgentAlert";
import { usePodLogsStore } from "../../../../shared/hooks/podLogs/store/podLogsStore";
import { resetPodLogs } from "../../../../shared/hooks/podLogs/store/podLogsStoreSelectors";
import { NoLivePodsToDisplay } from "../NoLivePodsToDisplay/NoLivePodsToDisplay";

import { PodLogs } from "./PodLogs";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useResourcePodsState } from "./useResourcePodsState";

import { AriaLabels } from "@/shared/config/ariaLabels";
import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { ResourceTab } from "@/components/ResourceView/types";

interface ServiceLogsTabProps {
  resource: KomodorServiceResourceKind | WorkflowRun;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Card = styled.div`
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const containerStyle: CSSProperties = {
  width: "240px",
  backgroundColor: muiTheme.palette.common.white,
};

const SelectLabel = ({ label }: { label: string }) => {
  return (
    <Typography
      variant="body3"
      color={"text.secondary"}
      sx={{ textTransform: "uppercase", fontWeight: 500 }}
    >
      {label}:
    </Typography>
  );
};

const SelectsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ServiceLogsTab = ({ resource }: ServiceLogsTabProps): JSX.Element => {
  const [selectedPod, setSelectedPod] = useState<Pod | undefined>(undefined);
  const resetLogsInStore = usePodLogsStore(resetPodLogs);
  const [selectedContainerName, setSelectedContainerName] = useState<
    string | undefined
  >(undefined);

  const { data, isLoading } = useResourcePodsState(resource);
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.ServicePodLogs);

  const fetchedPods = data?.data?.json as Pod[] | undefined;
  const pod = useMemo(
    () => selectedPod ?? fetchedPods?.[0],
    [selectedPod, fetchedPods]
  );

  const podsOptions = useMemo(() => {
    return (
      fetchedPods?.map((pod) => ({
        label: pod.metadata?.name ?? "",
        value: pod.metadata?.name ?? "",
      })) ?? []
    );
  }, [fetchedPods]);

  const allStatuses = useMemo(() => {
    return [
      ...(pod?.status?.containerStatuses ?? []),
      ...(pod?.status?.initContainerStatuses ?? []),
    ];
  }, [pod]);

  const selectedContainer = useMemo(() => {
    const containerName = selectedContainerName ?? allStatuses?.[0]?.name;
    if (!containerName) {
      return undefined;
    }
    return {
      label: `Container: ${containerName}`,
      value: containerName,
    };
  }, [selectedContainerName, allStatuses]);

  const selectContainerRef = useRef<HTMLDivElement>(null);

  if (!pod && !isLoading) {
    return <NoLivePodsToDisplay />;
  }

  if (!pod || isLoading) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <Container aria-label={AriaLabels.ResourceView.ServiceLogsTab.TabPanel}>
        <LogsUpgradeAgentAlert clusterName={resource.cluster} />
        <Card>
          <SelectsContainer ref={selectContainerRef}>
            <SelectContainer>
              <SelectLabel label="Pod" />
              <SingleSelect
                ariaLabel={AriaLabels.ResourceView.ServiceLogsTab.SelectPod}
                value={{
                  label: pod?.metadata?.name ?? "",
                  value: pod?.metadata?.name,
                }}
                size="large"
                width="240px"
                onChange={(option) => {
                  if (option?.value) {
                    setSelectedPod(
                      fetchedPods?.find(
                        (p) => option?.value === p.metadata?.name
                      )
                    );
                    resetLogsInStore();
                  }
                }}
                options={podsOptions}
                enableSearch
                selectPopoverProps={{
                  maxHeight: "300px",
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                }}
              />
            </SelectContainer>

            <SelectContainer>
              <SelectLabel label="Container" />
              <Select
                value={selectedContainer?.value}
                size="small"
                sx={containerStyle}
                onChange={(e) => {
                  setSelectedContainerName(e.target.value);
                  resetLogsInStore();
                }}
              >
                {allStatuses.map((container) => (
                  <MenuItem key={container.containerID} value={container.name}>
                    {container.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectContainer>
          </SelectsContainer>
          <PodLogs
            id={pod.metadata?.uid ?? ""}
            cluster={resource.cluster}
            namespace={pod.metadata?.namespace ?? ""}
            name={pod.metadata?.name ?? ""}
            containerStatuses={allStatuses}
            selectedContainer={selectedContainer}
          ></PodLogs>
        </Card>
      </Container>
    </DatadogReportLoadingTimeContextProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ServiceLogsTab;
