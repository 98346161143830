import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import styled from "styled-components";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { ZIndex } from "@/constants/zIndex";
import { OverflowTextStyle } from "@/shared/styles/styles";

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  max-width: 200px;
`;

const TooltipContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  gap: 8px;
`;

const TypographyWithEllipsis = styled(Typography)`
  && {
    ${OverflowTextStyle}
  }
`;

const TooltipContent: React.FC<{ images: string[] }> = ({ images }) => {
  const content = useMemo(
    () =>
      images.map((image) => (
        <Typography key={image} variant="body3">
          {image}
        </Typography>
      )),
    [images]
  );
  return <TooltipContentContainer>{content}</TooltipContentContainer>;
};

export const ImagesValue: React.FC<{ images: string[] }> = ({ images }) => {
  const [firstImage, ...restOfImages] = images;

  return (
    <Container>
      <TypographyWithEllipsis
        variant="h5"
        color="text.primary"
        aria-label={AriaLabels.ResourceView.Header.Metadata.Image}
      >
        {firstImage}
      </TypographyWithEllipsis>
      {images.length > 1 && (
        <Tooltip
          PopperProps={{
            style: { zIndex: ZIndex.AboveModal },
          }}
          title={<TooltipContent images={restOfImages} />}
          componentsProps={lightMuiTooltipStyle}
          placement="bottom"
          arrow={false}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            aria-label={AriaLabels.ResourceView.Header.Metadata.AdditionalImage}
          >
            +{restOfImages.length}
          </Typography>
        </Tooltip>
      )}
    </Container>
  );
};
