export const nodeAutoscalersPageAriaLabels = {
  table: "Node autoscalers list page table",
  summaryTab: "Autoscalers summary tab",
  nodeOverTimeGraph: "Autoscalers node over time graph",
  autoscalerTab: {
    configLine: "autoscaler config line",
    noViolationsMessage: "No violations message",
  },
  notFound: {
    banner: "No autoscalers found banner",
  },
};
