import { useRef } from "react";
import { SxProps, Theme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Stack from "@mui/system/Stack";

import { muiColors, muiPalette } from "themes";
import { MagicAi24 } from "icons";
import { MessageBubbleActions } from "components/komodor-ui/Chat/MessageBubble/MessageBubbleActions";
import { ChatMessageId } from "components/komodor-ui/Chat/types";

export interface MessageBubbleProps {
  id: ChatMessageId;
  isUser: boolean;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}
export const MessageBubble: React.FC<MessageBubbleProps> = ({
  id,
  isUser,
  sx = {},
  children,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {!isUser && (
        <Box paddingRight="8px">
          <MagicAi24 color={muiPalette.action?.active} />
        </Box>
      )}
      <Stack maxWidth="90%">
        <Paper
          ref={contentRef}
          elevation={0}
          sx={{
            padding: isUser ? "9px 16px" : 0,
            borderRadius: isUser ? "8px 8px 0 8px" : "0 8px 8px 8px",
            backgroundColor: isUser ? muiColors.gray[50] : "transparent",
            typography: "body2",
            ...sx,
          }}
        >
          {children}
        </Paper>
        {!isUser && id !== "typing" && id !== "failed" && (
          <MessageBubbleActions contentRef={contentRef} />
        )}
      </Stack>
    </>
  );
};
