import React, { useCallback } from "react";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { muiTheme } from "@komodorio/design-system";

export const DialogContent = () => (
  <Stack direction="row" columnGap="16px">
    <Avatar
      sx={{
        backgroundColor: muiTheme.palette.warning.light,
        color: muiTheme.palette.warning.dark,
      }}
    >
      <WarningAmberOutlined />
    </Avatar>
    <Stack rowGap="24px">
      <Typography variant="body2">
        Changing the scope of the statement will <b>reset</b> all the{" "}
        <b>selections</b> and <b>definitions</b>
      </Typography>
      <Typography variant="body2">Do you want to change the scope?</Typography>
    </Stack>
  </Stack>
);

export const ChangeScopeDialog = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const handleClose = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        onConfirm();
      }
      onClose();
    },
    [onClose, onConfirm]
  );

  return (
    <ConfirmationDialog
      title="Change scope?"
      content={<DialogContent />}
      onClose={handleClose}
      okText="Change scope"
      width="360px"
      height="unset"
    />
  );
};
