import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React from "react";

export const TooltipSimpleTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Typography fontWeight="bold" color={muiColors.gray[600]} fontSize={12}>
      {title}
    </Typography>
  );
};
