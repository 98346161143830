// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useMemo } from "react";
import { useValueSelector, UseRule } from "react-querybuilder";

import { ReadonlyBox } from "./ReadonlyBox";

import { SingleSelect } from "../Select";
import { ariaLabels } from "./constants";

export const CustomOperatorSelector = (props: UseRule) => {
  const selector = { ...props, ...useValueSelector(props) };

  const { onPropChange } = props.actions;
  const onChangeOperator = useCallback(
    (selected) =>
      onPropChange("operator", selected.value, selector.path, props.context),
    [onPropChange, props, selector]
  );

  const selectedOperator = useMemo(() => {
    return (
      selector.operators.find(
        (operator) => operator.value === selector.operator
      ) ?? {}
    );
  }, [selector]);

  const options = useMemo(() => {
    if (!props.prevField || props.prevField?.field !== selector.field) {
      return selector.operators.filter(
        (operator) => !operator.predecessors?.length
      );
    }

    return selector.operators.filter(
      (operator) => !!operator.predecessors?.length
    );
  }, [selector.operators]);

  if (options.length === 1) {
    return <ReadonlyBox label={selectedOperator.label} boxType="operator" />;
  }

  return (
    <SingleSelect
      options={options}
      value={selectedOperator}
      onChange={onChangeOperator}
      width="fit-content"
      selectPopoverProps={{
        width: "max-content",
      }}
      ariaLabel={ariaLabels.switcher.operator}
    />
  );
};
