import { useCallback } from "react";
import { GridRowParams } from "@mui/x-data-grid-pro";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { HelmChart } from "@/generated/addonsApi";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { helmChartToHelmChartSummary } from "@/components/k8sAddons/addons/HelmCharts/helmChartsUtils";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

export const useOpenHelmReleaseDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    (params: GridRowParams<HelmChart>) => {
      const { row } = params;
      pushDrawer({
        drawerType: DrawerType.HelmReleaseDrawer,
        chartSummary: helmChartToHelmChartSummary(row),
      });
      reportRowClickedToAnalytics(row);
    },
    [pushDrawer]
  );
};

const reportRowClickedToAnalytics = (chart: HelmChart) => {
  dispatchEvent(AnalyticEvents.HelmChartAddon.RowClicked, {
    id: chart.id,
    name: chart.name,
    namespace: chart.namespace,
    clusterName: chart.clusterName,
    revision: chart.revision,
    status: chart.status,
    chart: chart.chart,
    appVersion: chart.appVersion,
    updatedAt: chart.updatedAt,
  });
};
