import React, { ReactNode } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography/Typography";
import { muiColors } from "@komodorio/design-system";

const Container = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 16px 0 16px;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  ${($props) =>
    $props.withBorder &&
    `border: solid 1px ${muiColors.gray[200]};
    border-radius: 4px;
    `}
`;

type Props = {
  title: string;
  children: ReactNode;
  ariaLabel?: string;
  withBorder?: boolean;
};

export const MetricsContainer: React.FC<Props> = ({
  title,
  children,
  ariaLabel,
  withBorder,
}) => {
  return (
    <Container aria-label={ariaLabel} withBorder={withBorder}>
      <Typography variant="h6"> {title} </Typography>
      {children}
    </Container>
  );
};
