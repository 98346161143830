import { PV } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesPVResource = {
  NameInK8S: "persistentvolumes",
  Group: "storage",
  NavBarName: "PV",
  PresentName: "Persistent Volumes",
  Namespaced: false,
  ShowStatusFilter: true,
  UnnecessaryColumns: [
    "access modes",
    "reclaim policy",
    "reason",
    "volumemode",
  ],
  SupportedActions: [AvailableActions.Delete],
  Headers: [
    { name: "Name" },
    { name: "Age" },
    { name: "Claim" },
    { name: "Status" },
    { name: "Capacity" },
    { name: "Storage Class" },
  ],
  DefaultSort: {
    ColumnName: "status",
    Direction: Direction.up,
  },
  CustomSortOrder: {
    status: {
      Failed: 0,
      Released: 1,
      Bound: 2,
      Available: 3,
    },
  },
  Kind: SupportedResourcesKinds.KubernetesPVResource,
  Icon: PV,
  hasLabels: true,
};
