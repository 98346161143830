import styled from "styled-components";
import Divider from "@mui/material/Divider";

import { CountedDataItem } from "@/components/reliability/components/shared/CountedData/CountedDataItem";

const ImpactContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 0 16px;
  }
`;

export type CountedDataValue = {
  title: string;
  value: number | string;
  valueAnnotation?: string;
};

type ImpactContentProps = {
  items: CountedDataValue[];
  className?: string;
};

export const CountedDataContent: React.FC<ImpactContentProps> = ({
  items,
  className,
}) => {
  return (
    <ImpactContentContainer className={className}>
      {items.map((item, idx) => (
        <>
          <CountedDataItem
            key={item.title}
            title={item.title}
            value={item.value}
            valueAnnotation={item.valueAnnotation}
          />
          {idx < items.length - 1 && (
            <StyledDivider orientation={"vertical"} flexItem />
          )}
        </>
      ))}
    </ImpactContentContainer>
  );
};
