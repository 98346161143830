import { compact } from "lodash";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { useGetGroupedPodEvents } from "@/shared/hooks/resources-api/client/events/useGetGroupedPodEvents";
import { PodGroups } from "@/generated/resourcesApi";

export const useGroupedPodEventsFetch = (
  customPodUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined,
  disableFetch = false
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): [PodGroups[] | undefined, boolean] => {
  const podNames = compact(
    customPodUids?.map((customPodUid) => customPodUid.split(";").at(-1))
  );

  const {
    data: groupedPodEventsData,
    isFetching: isFetchingGroupedNativePodEvents,
  } = useGetGroupedPodEvents(
    {
      podIdentifiers:
        podNames?.map((name) => {
          return {
            komodorUid: buildKomodorUid({
              kind: "Pod",
              clusterName: clusterName ?? "",
              namespace: namespace ?? "",
              resourceName: name ?? "",
            }),
          };
        }) ?? [],
      fromEpoch: timeWindow?.start.valueOf(),
      toEpoch: timeWindow?.end.valueOf(),
    },
    !!clusterName && !!namespace && !!customPodUids?.length
  );

  return [groupedPodEventsData?.data, isFetchingGroupedNativePodEvents];
};
