import { ResourceTableModelRow } from "komodor-types";
import { compact, get, uniq } from "lodash";
import { useMemo } from "react";

import useAtmResourcesListLiveResult from "../ATM/useAtmResourcesListLiveResult";
import { useParseResultToInspectionList } from "../ATM/useParseInspectionResult";
import { KubernetesNodesResource } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { usePodsAtmByResource } from "../../../components/ResourceView/tabs/PodsTab/usePodsAtmByResource";
import { KomodorServiceResourceKind } from "../../types/Resource";

export const useNodesATM = (
  resource: KomodorServiceResourceKind,
  agentId: string
): { rows: ResourceTableModelRow[]; fetching: boolean } => {
  const {
    data: podsData,
    fetching,
    isPaginating,
    errorMessage,
  } = usePodsAtmByResource({ resource, agentId, fetchDeletedPods: false });

  const { result: nodesResult, status } = useAtmResourcesListLiveResult({
    resourceKind: KubernetesNodesResource.Kind,
    agentId,
    cluster: resource.cluster,
    isLive: true,
  });

  const podsRelatedNodes = useMemo(() => {
    if (fetching || errorMessage || isPaginating) {
      return null;
    }
    return uniq(compact(podsData?.map((sp) => get(sp, "node", ""))));
  }, [errorMessage, fetching, isPaginating, podsData]);

  const parsedResourceList = useParseResultToInspectionList(
    status,
    errorMessage,
    nodesResult?.data,
    nodesResult?.isPaginating
  );

  return useMemo(() => {
    if (parsedResourceList.fetching) {
      return {
        rows: [],
        fetching: true,
      };
    }

    if (!podsRelatedNodes)
      return {
        rows: [],
        fetching: false,
      };

    return {
      fetching: false,
      rows: parsedResourceList.rows.filter((node) =>
        podsRelatedNodes.includes(get(node, "name", ""))
      ),
    };
  }, [parsedResourceList.fetching, parsedResourceList.rows, podsRelatedNodes]);
};
