import { useMemo } from "react";

import {
  processDataFromHasuraAction,
  useGetJobsFields,
} from "../ProcessServiceFetchRequests";
import { useQueryPolling } from "../../../shared/hooks/useQueryPolling";
import { useFetchAllJobsQuery } from "../../../generated/graphql";

import { FetchServicesOutput, GetJobsParams } from "./types";
import { useGetServicePollingIntervalInSeconds } from "./useGetServicePollingIntervalInSeconds";
import { servicesListToDictionary } from "./servicesListToDictionary";
import { useProcessServicesFromResourcesApi } from "./useProcessServicesFromResourcesApi";

export const useGetJobs = ({
  includeLabels,
  includeAnnotations,
  fetchAllJobsFromResourcesApiFF,
  fetchJobsFromMaterializedView,
  pause,
}: GetJobsParams): FetchServicesOutput => {
  const pollingInterval = useGetServicePollingIntervalInSeconds();
  const enableResourcesApi = !pause && !!fetchAllJobsFromResourcesApiFF;
  const pauseGraphQl = pause || !!fetchAllJobsFromResourcesApiFF;
  const { servicesDictionary, servicesList, fetchMode, isFetching } =
    useProcessServicesFromResourcesApi({
      type: "jobs",
      requestFields: useGetJobsFields(!!includeLabels, !!includeAnnotations),
      enableResourcesApi,
    });

  const [jobs] = useQueryPolling(
    useFetchAllJobsQuery({
      variables: {
        fetchJobsFromMaterializedView: !!fetchJobsFromMaterializedView,
        includeLabels: !!includeLabels,
        includeAnnotations: !!includeAnnotations,
      },
      pause: pauseGraphQl,
    }),
    pollingInterval * 1000,
    pauseGraphQl
  );

  const processedJobs = useMemo(
    () => processDataFromHasuraAction(jobs?.fetchAllJobs),
    [jobs?.fetchAllJobs]
  );

  return useMemo(() => {
    if (fetchAllJobsFromResourcesApiFF) {
      return {
        servicesDictionary,
        servicesList,
        fetchMode,
        isFetching,
      };
    }

    return {
      servicesList: processedJobs,
      servicesDictionary: servicesListToDictionary(processedJobs),
      fetchMode: "full",
      isFetching,
    };
  }, [
    fetchAllJobsFromResourcesApiFF,
    fetchMode,
    isFetching,
    processedJobs,
    servicesDictionary,
    servicesList,
  ]);
};
