import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { muiColors } from "@komodorio/design-system";
import CheckIcon from "@mui/icons-material/Check";
import CompareArrows from "@mui/icons-material/CompareArrows";
import { Status } from "@komodorio/design-system/komodor-ui";

export const Legend: React.FC<{ isKomodorService: boolean }> = ({
  isKomodorService,
}) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      bgcolor={muiColors.gray[50]}
      borderRadius="8px"
      padding="0 16px"
      gap="16px"
      height="32px"
    >
      <Typography variant="h6">Legend:</Typography>
      <Stack direction="row" alignItems="center" paddingRight="10px">
        {isKomodorService ? (
          <CheckIcon
            sx={{
              color: "action.active",
              padding: "0 10px",
            }}
          />
        ) : (
          <Box sx={{ padding: "0 10px" }}>
            <Status label="Exists" color="neutral" />
          </Box>
        )}
        <Typography variant="body3" color="text.secondary">
          {isKomodorService ? "Exists and similar" : "Exists"}
        </Typography>
      </Stack>
      {isKomodorService && (
        <>
          <Divider orientation="vertical" sx={{ height: "16px" }} />
          <Stack direction="row" alignItems="center" paddingRight="10px">
            <CompareArrows
              sx={{
                color: "error.dark",
                padding: "0 10px",
              }}
            />
            <Typography variant="body3" color="text.secondary">
              Exists but Drifted
            </Typography>
          </Stack>
        </>
      )}
      <Divider orientation="vertical" sx={{ height: "16px" }} />
      <Stack direction="row" alignItems="center" paddingRight="10px">
        <Typography variant="body2" padding="0 10px">
          N/A
        </Typography>
        <Typography variant="body3" color="text.secondary">
          Does not exist
        </Typography>
      </Stack>
    </Stack>
  );
};
