import React from "react";

import { CertificateFullStatus } from "@/generated/addonsApi";
import { tooltipStatusTexts } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/tooltipStatusTexts";
import { CertificateFullStatusToTitle } from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { LabeledDataWithTooltip } from "@/components/common/LabeledData/LabeledDataWithTooltip";
import { TooltipType } from "@/components/common/LabeledData/LabeledContent.types";

const {
  status: { info: infoAriaLabel },
} = certificatesPageAriaLabels.drawer;

type StatusTitleWithTooltipProps = {
  status: keyof CertificateFullStatus;
};

export const StatusTitleWithTooltip: React.FC<StatusTitleWithTooltipProps> = ({
  status,
}) => {
  return (
    <LabeledDataWithTooltip
      title={CertificateFullStatusToTitle[status]}
      tooltip={{
        title: tooltipStatusTexts[status],
        type: TooltipType.Info,
        ariaLabel: infoAriaLabel,
      }}
    />
  );
};
