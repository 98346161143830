import { RbacPolicyAction, RbacPolicyStatementsInner } from "@/generated/auth";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { assumeScopeFromStatement } from "@/pages/organization-settings/access-management/PoliciesPage/utils/assumeScopeFromStatement";

export const getScopeFromStatement = (
  actions: RbacPolicyAction[],
  statement?: RbacPolicyStatementsInner
): Scope => {
  if (!statement) {
    return Scope.cluster;
  }

  const assumedScope = assumeScopeFromStatement(statement, actions);
  if (assumedScope === "error") {
    return Scope.cluster;
  }

  return assumedScope;
};
