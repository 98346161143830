import React from "react";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import {
  AiBannerContainer,
  Header,
  HeaderLeft,
  HeaderRight,
  MagicAiAnimated,
  Title,
} from "./styles";

import { Body } from "@/components/AppBar/internal/Body";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { StrictExternalLink } from "@/components/common/Link/ExternalLink";

interface AiBannerProps {
  title: string;
  rightHeader?: React.ReactNode;
  body?: React.ReactNode;
  isLoading?: boolean;
}

export const AiBanner: React.FC<AiBannerProps> = ({
  rightHeader,
  body,
  isLoading,
  title,
}) => {
  return (
    <AiBannerContainer
      aria-label={AriaLabels.AiInvestigator.Banner}
      loading={isLoading}
    >
      <Header>
        <HeaderLeft>
          <MagicAiAnimated loading={isLoading} />
          <Title>
            <Typography
              variant={"body2"}
              sx={{ fontStyle: "normal", fontWeight: 700 }}
            >
              {title}
            </Typography>
            <Typography
              variant="caption"
              display="inline"
              sx={{ fontSize: "0.5rem" }}
            >
              BETA
            </Typography>
          </Title>
          <Typography
            variant={"body2"}
            sx={{ fontStyle: "italic" }}
            color={muiColors.gray[500]}
          >
            Powered by Klaudia AI
          </Typography>
          <Tooltip
            placement={"top"}
            arrow={false}
            title={
              <>
                Responses are generated by Klaudia AI based on the logs shown
                below, and have not been vetted by humans.{" "}
                <StrictExternalLink
                  href="https://help.komodor.com/hc/en-us/articles/22447540595218-Klaudia-AI-Powered-Kubernetes-Troubleshooting"
                  color={muiColors.common.white}
                >
                  Learn more about Klaudia AI
                </StrictExternalLink>
              </>
            }
          >
            <InfoOutlined
              sx={{
                color: muiColors.gray[500],
                fontSize: "16px",
              }}
            />
          </Tooltip>
        </HeaderLeft>
        {rightHeader && <HeaderRight>{rightHeader}</HeaderRight>}
      </Header>
      {body && <Body>{body}</Body>}
    </AiBannerContainer>
  );
};
