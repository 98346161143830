import { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { DiffModal } from "../common/DiffModal";

import useResource from "@/components/ResourceView/useResource";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import {
  buildKomodorUid,
  BuildKomodorUidParams,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";

export type ResourcesDiffModalParams = {
  baselineKomodorUidParams: BuildKomodorUidParams;
  comparedKomodorUidParams: BuildKomodorUidParams;
};

export type ResourcesDiffModalProps = ResourcesDiffModalParams & {
  onClose: () => void;
};

export const ResourcesDiffModal: React.FC<ResourcesDiffModalProps> = ({
  onClose,
  baselineKomodorUidParams,
  comparedKomodorUidParams,
}) => {
  const baselineAgentId = useActiveAgent(baselineKomodorUidParams.clusterName);
  const comparedAgentId = useActiveAgent(comparedKomodorUidParams.clusterName);

  const { resource: baselineResource, isFetching: isOldObjFetching } =
    useResource({
      resourceType: baselineKomodorUidParams.kind,
      resourceName: baselineKomodorUidParams.resourceName,
      agentId: baselineAgentId ?? "",
      cluster: baselineKomodorUidParams.clusterName,
      namespace: baselineKomodorUidParams.namespace,
      executeMutation: true,
    });

  const { resource: comparedResource, isFetching: isComparedObjFetching } =
    useResource({
      resourceType: comparedKomodorUidParams.kind,
      resourceName: comparedKomodorUidParams.resourceName,
      agentId: comparedAgentId ?? "",
      cluster: comparedKomodorUidParams.clusterName,
      namespace: comparedKomodorUidParams.namespace,
      executeMutation: true,
    });

  const isLoading = useMemo(
    () => isOldObjFetching || isComparedObjFetching,
    [isOldObjFetching, isComparedObjFetching]
  );

  const emptyStateTitle = useMemo(() => {
    if (!isLoading) {
      if (
        (!baselineResource?.fullObj && baselineKomodorUidParams) ||
        (!comparedResource?.fullObj && comparedKomodorUidParams)
      ) {
        const title = baselineResource?.fullObj
          ? `Compared ${comparedKomodorUidParams.kind} unavailable`
          : `Baseline ${baselineKomodorUidParams.kind} unavailable`;
        const resourceName = baselineResource?.fullObj
          ? comparedKomodorUidParams.resourceName
          : baselineKomodorUidParams.resourceName;
        return (
          <Typography variant="h4">
            {title}:{" "}
            <Typography variant="body1" component="span">
              {resourceName}
            </Typography>
          </Typography>
        );
      }
    }
    return undefined;
  }, [
    isLoading,
    baselineResource?.fullObj,
    baselineKomodorUidParams,
    comparedResource?.fullObj,
    comparedKomodorUidParams,
  ]);
  return (
    <DiffModal
      title="Yaml Comparison"
      baselineUid={buildKomodorUid(baselineKomodorUidParams)}
      comparedUid={buildKomodorUid(comparedKomodorUidParams)}
      oldObj={baselineResource?.fullObj}
      newObj={comparedResource?.fullObj}
      emptyStateTitle={emptyStateTitle}
      isLoading={isLoading}
      onClose={onClose}
      isYaml
    />
  );
};
