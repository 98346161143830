import {
  MuiSelectionOption,
  Compared,
} from "@komodorio/design-system/komodor-ui";

import {
  ComparisonHelmResourcesWithResources,
  ResourcesRecords,
} from "./types";

import {
  BaselineHelmResources,
  ComparisonAttributeCommon,
  HelmComparisonResponseBaselineAttributes,
  HelmComparisonResponseTargetAttributes,
  HelmResourcesComparisonForKind,
} from "@/generated/workspacesApi";

const generateTargetResourcesMap = (
  baselineHelmResourcesData: BaselineHelmResources,
  targetHelmResources: ComparisonAttributeCommon[]
) => {
  return new Map(
    targetHelmResources.map((resource, index) => {
      const key = getResourceKey(
        baselineHelmResourcesData.resources[index].value,
        baselineHelmResourcesData.resources[index].namespace
      );
      const value = {
        isDiff: resource.isDiff,
        isEmpty: resource.isEmpty,
      };
      return [key, value];
    })
  );
};

const generateBaselineResourcesMap = (
  baselineHelmResourcesData: BaselineHelmResources
) => {
  return new Map(
    baselineHelmResourcesData.resources
      .filter((resource) => resource.value !== undefined)
      .map((resource) => [
        getResourceKey(resource.value, resource.namespace),
        {
          value: resource.value as string,
          namespace: resource.namespace,
          isDiff: resource.isDiff,
        },
      ])
  );
};

export const getComparedResourcesTableData = ({
  baselineHelmResourcesData,
  resourceKindComparison,
  comparedSelections,
  showOnlyDrift,
  targetAttributesComparisonData,
}: {
  baselineHelmResourcesData: BaselineHelmResources;
  resourceKindComparison: HelmResourcesComparisonForKind;
  comparedSelections: MuiSelectionOption<string>[] | null;
  showOnlyDrift: boolean;
  targetAttributesComparisonData: HelmComparisonResponseTargetAttributes[];
}): Compared<ComparisonHelmResourcesWithResources>[] => {
  return (
    comparedSelections
      ?.map((uid, i) => {
        const targetHelmResources =
          resourceKindComparison.targetHelmsResources?.[i];
        const targetHelmAttributes = targetAttributesComparisonData?.[i];

        if (!targetHelmResources || !targetHelmAttributes) {
          return {
            isLoading: true,
            isMissingData: false,
          };
        }
        const resources = Object.fromEntries(
          generateTargetResourcesMap(
            baselineHelmResourcesData,
            targetHelmResources.resources
          )
        );
        return {
          isLoading: false,
          isMissingData: false,
          data: {
            komodorUidValue: {
              value: uid.value,
              isDiff: false,
            },
            komodorUidLabel: {
              value: uid.label,
              isDiff: false,
            },
            cluster: {
              value: targetHelmAttributes.cluster,
              isDiff: false,
              isEmpty: false,
            },
            nameAndChart: {
              value: `${targetHelmAttributes.name} - ${targetHelmAttributes.chart}`,
              isDiff: false,
              isEmpty: false,
            },
            version: targetHelmAttributes.version,
            count: {
              ...targetHelmResources.count,
              value: String(targetHelmResources.count.value ?? 0),
            },
            ...resources,
          },
        };
      })
      .filter((_, i) => {
        if (!showOnlyDrift) {
          return true;
        }
        return Boolean(targetAttributesComparisonData?.[i]?.isDiff);
      }) ?? []
  );
};

export const getResourceKey = (
  value?: string,
  namespace?: string
): keyof ResourcesRecords => {
  return namespace ? `resource-${value}-${namespace}` : `resource-${value}`;
};

export const getBaselineResourceKindComparison = ({
  baselineSelection,
  baselineHelmResourcesData,
  baselineAttributesComparisonData,
}: {
  baselineSelection: MuiSelectionOption<string> | null;
  baselineHelmResourcesData: BaselineHelmResources;
  baselineAttributesComparisonData?: HelmComparisonResponseBaselineAttributes;
}): ComparisonHelmResourcesWithResources => {
  const resources: ResourcesRecords = Object.fromEntries(
    generateBaselineResourcesMap(baselineHelmResourcesData)
  );
  return {
    cluster: baselineAttributesComparisonData?.cluster ?? "",
    nameAndChart: `${baselineAttributesComparisonData?.name ?? ""} - ${
      baselineAttributesComparisonData?.chart ?? ""
    }`,
    version: baselineAttributesComparisonData?.version?.value ?? "",
    count: String(baselineHelmResourcesData.count.value ?? 0),
    komodorUidValue: baselineSelection?.value ?? "",
    komodorUidLabel: baselineSelection?.label ?? "",
    ...resources,
  };
};
