import React, { useEffect } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { ResourceUsageProps } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageTypes";
import { ResourceUsageTooltipContent } from "@/components/k8sAddons/components/table/ResourceUsageBar/ResourceUsageTooltipContent";
import { resourceUsageColorScheme } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageConstants";
import {
  formatResourcePercentage,
  getUsagePercentage,
} from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageUtils";
import { k8sAriaLabels } from "@/components/k8sAddons/k8sAriaLabels";

enum LineThicknessMode {
  Fill = "fill",
  Thin = "thin",
}

const thicknessMapping: Record<LineThicknessMode, string> = {
  fill: "100%",
  thin: "4px",
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 2fr auto;
  column-gap: 8px;
`;

const LinesContainer = styled.div`
  flex: 1;
  position: relative;
`;

const HorizontalLine = styled.div<{
  $lengthPercent: number;
  color: string | undefined;
  $thickness: LineThicknessMode;
}>`
  position: absolute;
  height: ${(props) => thicknessMapping[props.$thickness]};
  width: ${(props) => props.$lengthPercent}%;
  background-color: ${(props) => props.color};
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s;
`;

const StyledTypography = styled(Typography)`
  && {
    text-wrap: auto;
    word-break: break-word;
    line-height: 1.2;
  }
`;

export const ResourceUsageSingleMetric: React.FC<ResourceUsageProps> = ({
  title,
  resourceData,
  type,
  percentage,
}) => {
  const [usageToUse, setUsageToUse] = React.useState(0);

  useEffect(() => {
    setTimeout(() => setUsageToUse(resourceData.usage), 1);
  }, [resourceData]);

  const { capacity, allocated } = resourceData;
  const transitionUsagePercent = getUsagePercentage({
    ...resourceData,
    usage: usageToUse,
  });

  const allocatedPercent = (allocated / capacity) * 100;
  return (
    <Tooltip
      title={
        <ResourceUsageTooltipContent
          allocatedPercentage={percentage}
          resourceData={resourceData}
          type={type}
        />
      }
      placement={"top"}
      arrow={false}
      slotProps={{
        popper: {
          modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
        },
        tooltip: {
          sx: {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <Container aria-label={`${k8sAriaLabels.resourceUsageBar} for ${type}`}>
        <StyledTypography variant="body3" color={"text.secondary"}>
          {title}
        </StyledTypography>
        <LinesContainer>
          <HorizontalLine
            $lengthPercent={100}
            $thickness={LineThicknessMode.Fill}
            color={resourceUsageColorScheme.capacity}
          />
          <HorizontalLine
            $lengthPercent={allocatedPercent}
            $thickness={LineThicknessMode.Fill}
            color={resourceUsageColorScheme.allocated}
          />
          <HorizontalLine
            $lengthPercent={transitionUsagePercent}
            $thickness={LineThicknessMode.Thin}
            color={resourceUsageColorScheme.usage}
          />
        </LinesContainer>
        <StyledTypography variant="body3" color={"text.secondary"}>
          {formatResourcePercentage(percentage ?? allocatedPercent)}
        </StyledTypography>
      </Container>
    </Tooltip>
  );
};
