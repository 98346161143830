import { useMemo, useState } from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridPaginationModel,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORT_DIRECTION,
  EMPTY_CELL,
} from "./constants";

import { DefaultCell } from "components/komodor-ui/DataGrid/cells/DefaultCell";

export interface DataGridProComponent {
  <R extends GridValidRowModel = unknown>(
    props: DataGridProProps<R> &
      React.RefAttributes<HTMLDivElement> & {
        /**
         * Support rendering falsy defined values (false, 0, "") in the grid.
         * Default: false
         */
        renderFalsyValues?: boolean;
      }
  ): React.JSX.Element;
}

export const DataGrid: DataGridProComponent = ({
  columns,
  initialState,
  renderFalsyValues = false,
  ...props
}) => {
  const defaultPaginationModel = {
    ...DEFAULT_PAGINATION_MODEL,
    ...initialState?.pagination?.paginationModel,
  };
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    defaultPaginationModel
  );
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const defaultSortModel: GridSortModel = [
    { field: columns[0].field, sort: DEFAULT_SORT_DIRECTION },
  ];

  const customInitialState = {
    ...initialState,
    pagination: {
      ...initialState?.pagination,
      paginationModel:
        initialState?.pagination?.paginationModel ?? DEFAULT_PAGINATION_MODEL,
    },
    sorting: {
      ...initialState?.sorting,
      sortModel: initialState?.sorting?.sortModel ?? defaultSortModel,
    },
  };

  const customColumns = useMemo(
    () =>
      columns.map((c) => ({
        ...c,
        renderCell: (params) => {
          if (params.value == null || (!renderFalsyValues && !params.value)) {
            return EMPTY_CELL;
          }
          return c.renderCell ? (
            c.renderCell(params)
          ) : (
            <DefaultCell {...params} />
          );
        },
      })),
    [columns, renderFalsyValues]
  );

  const enablePagination =
    props.paginationMode === "server"
      ? true
      : props.rows.length > Math.min(...DEFAULT_PAGE_SIZES);

  return (
    <DataGridPro
      columns={customColumns}
      initialState={customInitialState}
      disableColumnMenu
      hideFooter={!enablePagination}
      pagination={enablePagination}
      pageSizeOptions={DEFAULT_PAGE_SIZES}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel);
        if (enablePagination) {
          setPaginationModel((prev) => ({
            ...prev,
            page: DEFAULT_PAGINATION_MODEL.page,
          }));
        }
      }}
      aria-label={props["aria-label"] ?? "data-grid"}
      {...props}
    />
  );
};
