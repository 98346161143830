/* eslint-disable max-lines */
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { muiColors, palette, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Deploy24 } from "@komodorio/design-system/icons";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { formatDistance } from "date-fns";
import { omit } from "lodash";
import { IFlatChange } from "json-diff-ts/lib/jsonDiff";

import { Container } from "../common/styles";
import { Deploy } from "../../../../generated/monitorsApi";
import { LinksButton } from "../../LinksMenu";
import { useDeployRelatedLinks } from "../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import getSpecsForDiff, {
  filterDiffKeys,
} from "../../../common/EventGroup/deployEvent/getSpecsForDiff";
import { GitCompare } from "../../../../shared/types/git";
import EventDrawer from "../../../common/ProcessList/EventDrawer";
import DeployEventGroup, {
  DeployEvent,
} from "../../../common/EventGroup/deployEvent/DeployEventGroup";
import getChangesSummary from "../../../common/EventGroup/deployEvent/getChangesSummary";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { RollbackButton } from "../../../Actions/buttons/RollbackButton";
import { getButton } from "../../../Actions/common/getActionButtonStyle";
import { AvailableActions } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useKomodorServiceAsResourceWithInterval } from "../../../ResourceView/useKomodorServiceAsResource";
import getDeployStatus, {
  DeployStatusEnum,
} from "../../../common/EventGroup/deployEvent/getDeployStatus";
import K8sConfig from "../../../common/EventGroup/deployEvent/K8sConfig";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { getAnnotations } from "../../../common/ProcessList/details/deployEvent/utils";

import { GitDiff } from "./GitDiff";
import { ConfigDiff } from "./ConfigDiff";
import { K8sDiff } from "./K8SDiff";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const HeaderLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  cursor: pointer;
`;

const HeaderRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  cursor: pointer;
`;

const HeaderMainContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const HeaderTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const HeaderTitleText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-width: 40rem;
  align-items: center;
`;

const StyledContainer = styled(Container)<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  && {
    overflow: visible;
  }
  border: 1px solid transparent;

  ${({ collapsed }) =>
    collapsed &&
    `
    :hover {
      border: 1px solid ${muiColors.blue[500]};
    }
  `};
`;

export const DeployCard: React.FC<{
  deploy: Deploy;
  isCollapsed: boolean;
  canRollback: boolean;
  trend?: React.ReactElement | undefined;
  filterK8sChangesMethod?: (changes: IFlatChange[]) => IFlatChange[];
  customHeader?: React.ReactElement | null;
}> = ({
  deploy,
  isCollapsed,
  canRollback,
  trend,
  filterK8sChangesMethod,
  customHeader,
}) => {
  const issue = useInvestigationModeStore(issueSelector);
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [openDeploy, setOpenDeploy] = useState(false);

  const excludedKeys: string[] = useMemo(
    () => ["metadata.managedFields", "status"],
    []
  );

  const oldSpec = useMemo(() => {
    return omit(
      JSON.parse((deploy?.oldSpec as unknown as string) || "{}") as Record<
        string,
        unknown
      >,
      excludedKeys
    );
  }, [deploy?.oldSpec, excludedKeys]);

  const newSpec = useMemo(() => {
    return omit(
      JSON.parse((deploy?.newSpec as unknown as string) || "{}") as Record<
        string,
        unknown
      >,
      excludedKeys
    );
  }, [deploy?.newSpec, excludedKeys]);

  const specForDiff = useMemo(() => {
    return getSpecsForDiff(oldSpec, newSpec);
  }, [newSpec, oldSpec]);

  const deployRelatedLinks = useDeployRelatedLinks(
    getAnnotations(specForDiff?.newSpec as Record<string, unknown>),
    {
      cluster: deploy?.clusterName,
      serviceId: deploy?.services?.[0],
      namespace: deploy?.namespace,
    }
  );

  const timeBeforeIssue = formatDistance(
    new Date(deploy?.eventTime ?? 0),
    new Date(issue?.eventTime ?? 0),
    {
      addSuffix: false,
    }
  );

  const deployEventGroup = useMemo(() => {
    return new DeployEventGroup([
      new DeployEvent(deploy, deploy?.services?.[0] ?? "", undefined),
    ]);
  }, [deploy]);

  const displaySrcControl = useMemo(() => {
    return !!deploy?.gitCompare?.length;
  }, [deploy]);

  const displayConfigChanges = useMemo(() => {
    return !!deploy?.kubernetesConfigChanges?.length;
  }, [deploy]);

  const failedDeploy = useMemo(() => {
    return getDeployStatus(deploy?.status) === DeployStatusEnum.Failed;
  }, [deploy?.status]);

  const [resource] = useKomodorServiceAsResourceWithInterval(
    deploy?.services?.[0] ?? ""
  );

  return (
    <StyledContainer
      collapsed={collapsed}
      aria-label={
        AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard.Container
      }
      data-testid={`deploy-card-${deploy?.id}`}
    >
      <EventDrawer
        open={openDeploy}
        onClose={() => setOpenDeploy(false)}
        event={deployEventGroup}
      >
        {deployEventGroup?.renderEventDetails()}
      </EventDrawer>
      <HeaderContainer
        onClick={() => setCollapsed((prev) => !prev)}
        aria-label={
          AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard.Header
        }
      >
        <HeaderLeftContent>
          {customHeader}
          <HeaderMainContent>
            <HeaderTitle>
              <Deploy24
                color={
                  failedDeploy
                    ? muiColors.pink[500]
                    : collapsed
                    ? muiColors.gray[400]
                    : palette.black["0"]
                }
              />
              <HeaderTitleText>
                <Typography variant="h4" color={theme.foreground.fgPrimary}>
                  {failedDeploy ? "Deploy failed" : "Deployed"}{" "}
                  {timeBeforeIssue} before 1st issue occurrence
                </Typography>
                <Typography variant="body2" color={palette.gray["600"]}>
                  {Object.keys(oldSpec).length && Object.keys(newSpec).length
                    ? getChangesSummary(
                        filterDiffKeys(deploy?.deploymentDiffKeys ?? [])
                      )
                    : "New deployment"}
                </Typography>
              </HeaderTitleText>
            </HeaderTitle>
            {trend ? trend : null}
          </HeaderMainContent>
        </HeaderLeftContent>
        <HeaderRightContent>
          <ChevronRight
            sx={{
              transform: !collapsed ? "rotate(90deg)" : "",
              color: muiColors.gray[400],
            }}
          />
        </HeaderRightContent>
      </HeaderContainer>
      {!collapsed && resource ? (
        <>
          <K8sDiff
            resource={resource}
            oldSpec={oldSpec}
            newSpec={newSpec}
            filterChangesMethod={filterK8sChangesMethod}
          />
          {displaySrcControl && resource && (
            <GitDiff
              resource={resource}
              gitCompares={(deploy?.gitCompare as GitCompare[]) ?? []}
            />
          )}
          {displayConfigChanges && (
            <ConfigDiff
              diffs={(deploy?.kubernetesConfigChanges as K8sConfig[]) ?? []}
            />
          )}
          <FooterContainer>
            <Button
              variant={"outlined"}
              size={"medium"}
              onClick={() => {
                setOpenDeploy(true);
              }}
              aria-label={
                AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard
                  .ViewMoreButton
              }
            >
              View more details
            </Button>
            <LinksButton
              links={deployRelatedLinks}
              buttonComponent={
                <Button
                  variant={"text"}
                  size={"medium"}
                  aria-label={
                    AriaLabels.InvestigationMode.CorrelatedDeploysStep
                      .DeployCard.LinksButton
                  }
                >
                  Links
                  <ArrowDropDown
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      marginLeft: "0.2rem",
                    }}
                  />
                </Button>
              }
            />
            {canRollback &&
            resource?.actions.includes(AvailableActions.Rollback) ? (
              <RollbackButton
                resource={resource}
                Button={getButton(AvailableActions.Rollback, false, undefined, {
                  buttonVariant: "primary",
                  actionItemVariant: "default",
                  text: "Review & Rollback",
                })}
                ariaLabel={
                  AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard
                    .RollbackButton
                }
              />
            ) : null}
          </FooterContainer>
        </>
      ) : null}
    </StyledContainer>
  );
};
