import React, { useMemo } from "react";

import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { HealthSection } from "@/components/ClustersView/ClusterOverview/components/HealthSection/HealthSection";
import { AddonsSection } from "@/components/ClustersView/ClusterOverview/components/AddonsSection/AddonsSection";
import { CostSection } from "@/components/ClustersView/ClusterOverview/components/CostSection/CostSection";
import { OverviewContainer } from "@/components/ClustersView/ClusterOverview/styles";
import { OverviewHeader } from "@/components/workspaces/Overview/OverviewHeader";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";
import { WorkspaceInventorySection } from "@/components/workspaces/Overview/WorkspaceInventorySection";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { ScopedWorkspaceSubKindEnum } from "@/generated/workspacesApi";

interface OverviewProps {
  workspace: TypedWorkspace;
  scopedWorkspaceSubKind: ScopedWorkspaceSubKindEnum | undefined;
}
export const OverviewComponent: React.FC<OverviewProps> = ({
  workspace,
  scopedWorkspaceSubKind,
}) => {
  const inventorySection = useMemo(() => {
    if (workspace.kind === "scoped" && scopedWorkspaceSubKind !== "clusters") {
      return null;
    }

    return <WorkspaceInventorySection />;
  }, [workspace, scopedWorkspaceSubKind]);
  return (
    <>
      <OverviewHeader workspace={workspace} />
      <ResponsiveLayout>
        <OverviewContainer>
          <HealthSection />
          <AddonsSection />
          <CostSection />
          {inventorySection}
        </OverviewContainer>
      </ResponsiveLayout>
    </>
  );
};

export const Overview: React.FC<OverviewProps> = (props) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.workspaceOverview,
        context: {
          feTeam: "troubleshooting",
          beTeam: "troubleshooting",
          workspaceKind: props.workspace.kind,
        },
      }}
    >
      <OverviewComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
