import { MonitorsApiApiV1SearchGetRequest } from "@/generated/monitorsApi";

export const ServiceOpenAvailabilityIssuesFields: MonitorsApiApiV1SearchGetRequest["fields"] =
  [
    "id",
    "type",
    "shortResourceName",
    "sourceEventId",
    "createdAt",
    "closedAt",
    "updatedAt",
    "closeReason",
    "services",
    "workflowConfiguration",
    "resultsSummary",
    "cluster",
    "namespace",
    "reasons",
    "eventTime",
  ];
