import React, { useEffect, useMemo } from "react";
import { SingleSelect } from "@komodorio/design-system/komodor-ui";

import { InspectionFilterContainer } from "../../Inspection/filters/InspectionResourceListSearch";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

const MonitorsListClusterFilter: React.FC<{
  clusters: string[];
  cluster: string | undefined;
  setCluster: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ clusters, cluster, setCluster }) => {
  const suggestions = useMemo(() => {
    return [
      { label: "ALL", value: "" },
      ...clusters.map((c) => ({ label: c, value: c })),
    ];
  }, [clusters]);

  useEffect(() => {
    if (!cluster) {
      setCluster(suggestions[0].value);
    }
  }, [cluster, setCluster, suggestions]);

  return (
    <InspectionFilterContainer>
      <SingleSelect
        width="14rem"
        enableSearch
        options={suggestions}
        selectPopoverProps={{
          maxHeight: "300px",
        }}
        label="Cluster"
        value={suggestions.find((s) => s.value === cluster)}
        disableBlankOption
        onChange={(value) => {
          if (value?.value !== cluster) {
            setCluster(value?.value);
            dispatchEvent(AnalyticEvents.MonitorsView.Filter_Selected, {
              filterCategory: "cluster",
              filterOption: value,
            });
          }
        }}
      />
    </InspectionFilterContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MonitorsListClusterFilter;
