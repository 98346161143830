import pluralize from "pluralize";
import { merge } from "lodash";

import {
  ONE_DAY_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_MINUTE_IN_SECONDS,
} from "@/shared/constants/time";

export const unixTimeToMilliseconds = (unixTime: number): number => {
  const isUnixTimeInSeconds = unixTime.toString().length === 10;
  if (isUnixTimeInSeconds) {
    return unixTime * 1000;
  }
  return unixTime;
};

export const millisecondsToUnixTime = (time: number): number => {
  if (time.toString().length === 13) {
    return Math.floor(time / 1000);
  }
  return time;
};

type TimeFormatConfig = {
  shouldCapitalize?: boolean;
};

const defaultTimeFormatConfig: Required<TimeFormatConfig> = {
  shouldCapitalize: false,
};

type TextBuildingValue = {
  value: number;
  unit: string;
};

function buildText(
  value: TextBuildingValue,
  config: Required<TimeFormatConfig>
): string {
  const baseUnitText = config.shouldCapitalize
    ? value.unit.charAt(0).toUpperCase() + value.unit.slice(1)
    : value.unit;
  const unitText = pluralize(baseUnitText, value.value);
  return `${value.value} ${unitText}`;
}

export function formatSeconds(
  seconds: number,
  config: TimeFormatConfig = defaultTimeFormatConfig
): string {
  const mergedConfig = merge(defaultTimeFormatConfig, config);

  if (seconds < ONE_MINUTE_IN_SECONDS) {
    return buildText({ value: seconds, unit: "second" }, mergedConfig);
  }
  if (seconds < ONE_HOUR_IN_SECONDS) {
    const minutes = Number.parseFloat(
      (seconds / ONE_MINUTE_IN_SECONDS).toFixed(1)
    );
    return buildText({ value: minutes, unit: "minute" }, mergedConfig);
  }
  if (seconds < ONE_DAY_IN_SECONDS) {
    const hours = Math.floor(seconds / ONE_HOUR_IN_SECONDS);
    return buildText({ value: hours, unit: "hour" }, mergedConfig);
  }
  const days = Math.floor(seconds / ONE_DAY_IN_SECONDS);
  return buildText({ value: days, unit: "day" }, mergedConfig);
}
