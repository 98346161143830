/* eslint-disable max-lines */
import React, { useEffect, useMemo } from "react";
import { differenceInSeconds, formatDistanceStrict } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  CloseButton,
  CopyButton,
  DrawerHeader,
  Metadata,
  MetadataView,
} from "@komodorio/design-system/komodor-ui";

import { EventDetailsContainer } from "../common";
import reasonIcon from "../../assets/reason.svg";
import EventDetailsSection from "../components/EventDetailsSection";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import JobEventGroup, {
  JobEvent,
} from "../../../EventGroup/jobEvent/JobEventGroup";
import { JobState } from "../../../../../shared/types/job";
import DrawerEventLinksSection from "../deployEvent/DrawerEventLinksSection";
import useK8sData from "../deployEvent/useK8sData";
import eventsIcon from "../../assets/events.svg";
import detailsIcon from "../../assets/details.svg";
import EventsGrid from "../components/EventsGrid";
import DetailsList from "../components/DetailsList";
import {
  LatestDeployDetails,
  useRelatedDeploy,
} from "../deployEvent/RelatedDeploy";
import { useDeployRelatedLinks } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import { getServiceInfo } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/utils";
import { useWorkflowRunBySensorIds } from "../../../../monitorsView/useWorkflowsRuns";
import {
  CheckDetails,
  JobCheckTypeEnum,
  WorkflowConfigType,
} from "../../../../monitorsView/common/types";
import useWorkflowsChecks from "../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import JobsPodsSummary from "../../../../monitorsView/workflowRunView/checks/job/JobPodsSummary";
import { useKomodorServiceAsResourceWithInterval } from "../../../../ResourceView/useKomodorServiceAsResource";
import { RerunJobButton } from "../../../../Actions/buttons/RerunJobButton";
import { getButton } from "../../../../Actions/common/getActionButtonStyle";
import { AvailableActions } from "../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import EventDetailsCheckCard from "../WorkflowIssueEventDetails/EventDetailsCheckCard";
import { JobsPodsOutput } from "../../../../monitorsView/workflowRunView/checks/availability/types";
import { getAnnotations } from "../deployEvent/utils";
import ViewJobPodsButton from "../../../EventGroup/jobEvent/ViewJobPodsButton";

import { FailedJobInvestigationSection } from "@/components/common/ProcessList/details/JobEventDetails/FailedJobInvestigationSection";
import {
  ResourceStatusEnum,
  StatusTag,
} from "@/components/ResourceView/headers/common";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const LeftPadding = styled.div`
  width: 20px;
`;

const usePreparedEvents = (events: JobEvent[]) =>
  useMemo(
    () =>
      events.map((e) => ({
        eventTime: e.eventTime,
        status: e.state,
        details: e.message,
      })),
    [events]
  );

const useDetails = (eventGroup: JobEventGroup) => {
  const { active, failed, succeeded } = eventGroup.podsStatus;
  return useMemo(
    () => [
      {
        label: "Duration",
        value: formatDistanceStrict(eventGroup.endTime, eventGroup.startTime),
      },
      {
        label: "Pod status",
        value: `${active} Running / ${succeeded} Succeeded / ${failed} Failed`,
      },
    ],
    [active, eventGroup.endTime, eventGroup.startTime, failed, succeeded]
  );
};

interface JobEventDetailsProps {
  eventGroup: JobEventGroup;
  onClose?: () => void;
}
const JobEventDetailsComponent: React.FC<JobEventDetailsProps> = ({
  eventGroup,
  onClose,
}) => {
  const { klaudiaAiJobRun } = useOverridableFlags();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const lastEvent = eventGroup.events[eventGroup.events.length - 1];
  const preparedEvents = usePreparedEvents(eventGroup.events);
  const preparedDetails = useDetails(eventGroup);
  const failedEventIds = useMemo(() => {
    return eventGroup.events
      .filter((e) => e.state === "Failed")
      .map((e) => e.id);
  }, [eventGroup.events]);

  const [wfRun, isFetchingWfRun] = useWorkflowRunBySensorIds(failedEventIds, [
    WorkflowConfigType.Job,
    WorkflowConfigType.CronJob,
  ]);

  const checkDetails: CheckDetails = useMemo(
    () => ({
      workflowType: WorkflowConfigType.Job,
      results: wfRun?.results ?? [],
      eventTime: wfRun?.eventTime,
      services: wfRun?.services,
      clusterName: wfRun?.cluster,
      namespace: wfRun?.namespace,
    }),
    [
      wfRun?.cluster,
      wfRun?.eventTime,
      wfRun?.namespace,
      wfRun?.results,
      wfRun?.services,
    ]
  );
  const allChecks = useWorkflowsChecks(checkDetails);
  const queryParams = queryString.parse(useLocation().search);

  useEffect(() => {
    if (wfRun) {
      dispatchEvent(AnalyticEvents.MonitorsView.Event_Drawer_Opened, {
        type: WorkflowConfigType.Job,
        referer: queryParams?.referer,
      });

      dispatchEvent(AnalyticEvents.MonitorsView.Any_Result_Opened, {
        type: WorkflowConfigType.Job,
        referer: queryParams?.referer,
        eventTime: wfRun.eventTime,
        secondsTillOpen: differenceInSeconds(new Date(), wfRun.eventTime),
      });
    }
  }, [queryParams?.referer, wfRun]);

  const { relatedDeployEventGroup } = useRelatedDeploy(eventGroup);
  const lastDeployEvent = relatedDeployEventGroup
    ? relatedDeployEventGroup.events[relatedDeployEventGroup.events.length - 1]
    : undefined;
  const diffData = useK8sData(lastDeployEvent?.id);

  const specsForDiff = diffData?.specsForDiff;
  // when a job event is related to a cronjob, it is not a komodor service
  const serviceId = lastEvent.cronJobUid ? undefined : eventGroup.serviceId;
  const [resource, isFetchingResource] =
    useKomodorServiceAsResourceWithInterval(serviceId);

  const deployRelatedLinks = useDeployRelatedLinks(
    getAnnotations(specsForDiff?.newSpec),
    getServiceInfo(resource)
  );

  const shouldShowReRunJobButton =
    (eventGroup.status === JobState.completed ||
      eventGroup.status === JobState.failed ||
      eventGroup.status === JobState.deleted) &&
    resource !== undefined;

  const podsHealthyCheck = useMemo(() => {
    return allChecks.find((c) => c.type === JobCheckTypeEnum.JobCheckType);
  }, [allChecks]);
  const podsCheckOutput = podsHealthyCheck?.output
    ? (podsHealthyCheck?.output as JobsPodsOutput)
    : undefined;

  const isFetching =
    isFetchingWfRun || isFetchingResource || diffData.isFetching;

  useEffect(() => {
    reportLoadingState("isFetchingJobEvent", isFetching);
  }, [reportLoadingState, isFetching]);

  const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `;

  return (
    <EventDetailsContainer>
      <DrawerHeader
        title={
          eventGroup.isFailed ? (
            <StatusContainer>
              <Typography variant="h3" fontWeight="500">
                {eventGroup.name}
              </Typography>

              <StatusTag status={ResourceStatusEnum.Failed} tagVariant="round">
                Failed
              </StatusTag>
            </StatusContainer>
          ) : (
            <Typography variant="h3" fontWeight="500">
              {eventGroup.name}
            </Typography>
          )
        }
        leftActions={
          onClose && (
            <CloseButton
              ariaLabel={AriaLabels.EventDrawer.CloseButton}
              onClose={onClose}
            />
          )
        }
        rightActions={
          <CopyButton
            ariaLabel={AriaLabels.EventDrawer.CopyButton}
            onCopyFailed={notifyDDError}
          />
        }
      />
      <Divider />
      {eventGroup.isFailed && (
        <>
          <MetadataView>
            <LeftPadding />
            <Metadata title="cluster" value={eventGroup.clusterName} />
            <Metadata title="namespace" value={eventGroup.namespace} />
            <Metadata title="job" value={eventGroup.name} />
          </MetadataView>
          <Divider />
        </>
      )}
      {shouldShowReRunJobButton && (
        <EventDetailsSection
          icon={detailsIcon}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Actions"
        >
          <RerunJobButton
            resource={resource}
            Button={getButton(AvailableActions.JobRerun)}
          />
        </EventDetailsSection>
      )}
      {!!klaudiaAiJobRun && eventGroup.isFailed && (
        <FailedJobInvestigationSection eventGroup={eventGroup} />
      )}
      {eventGroup.isFailed && (eventGroup.reason || eventGroup.message) && (
        <EventDetailsSection
          icon={reasonIcon}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Reason"
        >
          {eventGroup.reason}: {eventGroup.message}
        </EventDetailsSection>
      )}
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        icon={detailsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Details"
      >
        <Flex>
          <DetailsList details={preparedDetails} rows={1} />
          <ViewJobPodsButton eventGroup={eventGroup} />
        </Flex>
      </EventDetailsSection>
      {relatedDeployEventGroup ? (
        <LatestDeployDetails
          k8sDiff={diffData.k8sDiff}
          eventGroup={relatedDeployEventGroup}
        />
      ) : null}
      <DrawerEventLinksSection
        linksTemplates={deployRelatedLinks}
        resource={resource}
        deploySpec={specsForDiff?.newSpec}
        startTime={eventGroup.startTime}
        endTime={eventGroup.endTime}
        isCompleted={eventGroup.isFinished}
      />
      {podsCheckOutput && (
        <>
          <JobsPodsSummary
            podsCheckOutput={podsCheckOutput}
            clusterName={wfRun?.cluster ?? ""}
            namespace={wfRun?.namespace ?? ""}
            endTime={eventGroup.endTime}
          />
          {podsHealthyCheck &&
            podsCheckOutput?.pods &&
            podsCheckOutput.pods.length > 0 && (
              <EventDetailsCheckCard check={podsHealthyCheck} />
            )}
        </>
      )}
    </EventDetailsContainer>
  );
};

export const JobEventDetails: React.FC<JobEventDetailsProps> = ({
  eventGroup,
  onClose,
}) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.eventJob,
        context: {
          feTeam: "troubleshooting",
          beTeam: "barzelim",
        },
      }}
    >
      <JobEventDetailsComponent eventGroup={eventGroup} onClose={onClose} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
