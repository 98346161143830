import React from "react";
import { formatDistance } from "date-fns";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../../common/Insight";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { getColor } from "@/components/common/ProcessList/Status";
import { BasicViolation, CheckType } from "@/generated/reliabilityApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { OverflowTextStyle } from "@/shared/styles/styles";

const TypographyWithTextEllipsis = styled(Typography)`
  && {
    ${OverflowTextStyle};
  }
`;

type Props = {
  issues: BasicViolation[];
  issueStartTime: Date;
};

export const CascadingIssuesTooltip: React.FC<Props> = ({
  issues,
  issueStartTime,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  if (issues.length === 0) return null;

  return (
    <InsightTooltipContainer
      aria-label={AriaLabels.AvailabilityTriage.CascadingIssueTooltip}
    >
      {issues.map((issue, index) => {
        const { id, status, createdAt, supportingData } = issue;
        const { title, duration } = supportingData.cascadingFailure || {};

        return (
          <React.Fragment key={id}>
            <InsightTooltipDetails>
              <Typography variant="body3">Started</Typography>
              <Typography variant="h6">
                {formatDistance(issueStartTime, createdAt)} before current issue
              </Typography>

              <Typography variant="body3">Violation</Typography>
              <TypographyWithTextEllipsis variant="h6">
                {title}
              </TypographyWithTextEllipsis>

              <Typography variant="body3">Duration</Typography>
              <Typography variant="h6">{duration}</Typography>

              <Typography variant="body3">Status</Typography>
              <Typography
                variant="h6"
                color={getColor(status || "")}
                sx={{ textTransform: "capitalize" }}
              >
                {status}
              </Typography>

              <Link
                onClick={() =>
                  pushDrawer({
                    drawerType: DrawerType.ViolationDrawer,
                    violationId: id,
                    checkType: CheckType.CascadingFailure,
                  })
                }
                aria-label={
                  AriaLabels.AvailabilityTriage.SameIssuesInsightTooltipLink
                }
              >
                View details
              </Link>
            </InsightTooltipDetails>

            {index < issues.length - 1 && (
              <Divider sx={{ marginBlock: "1rem" }} />
            )}
          </React.Fragment>
        );
      })}
    </InsightTooltipContainer>
  );
};
