import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  WorkspacesApiApiV1WorkspacesGetRequest,
  WorkspacesResponse,
  apiV1WorkspacesGetUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspacesApiClient } from "../apiClient";
import { convertWorkspaceToTypedWorkspace } from "../utils";

export const WORKSPACES_PATH = "/workspaces";

const fetchWorkspaces = async (
  apiClient: AxiosInstance,
  params?: WorkspacesApiApiV1WorkspacesGetRequest
): Promise<WorkspacesResponse> => {
  const { data } = await apiClient.get<WorkspacesResponse>(
    apiV1WorkspacesGetUrl(params || {}, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetWorkspaces = (options?: {
  enabled?: boolean;
  refetchInterval?: number;
  params?: WorkspacesApiApiV1WorkspacesGetRequest;
}) => {
  const apiClient = useWorkspacesApiClient({
    skipWorkspaceHeader: true,
  });

  return useQuery(
    [WORKSPACES_PATH, options?.params],
    () => fetchWorkspaces(apiClient, options?.params),
    {
      ...options,
      select: (data) => data.data?.map(convertWorkspaceToTypedWorkspace),
    }
  );
};
