import { CheckIconType } from "./CheckCard";

import type {
  AllChecksTypes,
  BaseWorkflowCheck,
} from "@/components/monitorsView/common/types";
import { StateInSearchParamsType } from "@/shared/hooks/state/useStateInSearchParams";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default abstract class WorkflowCheck {
  icon: CheckIconType;
  type: AllChecksTypes;
  title = "";
  shortOutput: string | JSX.Element = "";
  description: string | JSX.Element = "";
  action: string | JSX.Element = "";
  question = "";
  passed: boolean;
  isFinding = false;
  output: unknown;

  constructor(check: BaseWorkflowCheck) {
    this.icon = (check.passed ? "success" : "fail") as CheckIconType;
    this.type = check.type;
    this.shortOutput = check.shortOutput;
    this.passed = check.passed;
  }

  abstract renderCheckDetails(
    setSelectedCheckType: StateInSearchParamsType[1]
  ): JSX.Element;
  renderCheckEventDetails(
    setSelectedCheckType: StateInSearchParamsType[1]
  ): JSX.Element | null {
    return null;
  }
}
