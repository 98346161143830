import Button from "@mui/material/Button";
import React, { useRef } from "react";
import styled from "styled-components";
import { muiColors, muiTheme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { AriaLabels } from "../../../../../../../../../shared/config/ariaLabels";

import { CascadingIssuesTooltip } from "./CascadingIssuesTooltip";

import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { WorkflowRunWithResults } from "@/components/monitorsView/common/types";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import {
  CheckCategory,
  CheckType,
  ImpactGroupIdentifier,
  ImpactGroupType,
} from "@/generated/reliabilityApi";
import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";

const IssueBannerContainer = styled.div<{
  borderTop?: boolean;
  clickable?: boolean;
}>`
  display: grid;
  padding: 12px 24px;
  background-color: ${muiColors.pink[25]};
  grid-template-columns: 24px auto 156px;
  gap: 8px;
  ${({ borderTop = true }) =>
    borderTop && `border-top: 1px solid ${muiColors.gray[200]};`}
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  pointer-events: ${({ clickable }) => (clickable ? "auto" : "none")};
`;

const IssueMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  wfRun: WorkflowRunWithResults;
};

export const CascadingIssuesBanner: React.FC<Props> = ({ wfRun }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { data } = useGetViolations({
    impactGroupId: [ImpactGroupIdentifier.CascadingFailure],
    checkCategory: [CheckCategory.Workload],
    impactGroupType: [ImpactGroupType.Dynamic],
    clusterName: wfRun.cluster ? [wfRun.cluster] : undefined,
    supportingDataFilter: {
      field: "issues",
      operator: "include",
      value: [wfRun.id],
    },
  });

  const violations = data?.data?.violations ?? [];
  const ref = useRef<HTMLElement>(null);
  const isTruncated =
    ref.current?.scrollWidth ?? 0 > (ref.current?.clientWidth ?? 0);

  if (violations.length === 0) return null;

  const renderIssueTitle = (title?: string) => (
    <LightTooltip title={isTruncated ? title ?? "" : ""} placement="top">
      <Typography ref={ref} variant="body2" noWrap>
        {title ?? ""}
      </Typography>
    </LightTooltip>
  );
  const isSingleViolation = violations.length === 1;
  return (
    <IssueBannerContainer
      aria-label={
        AriaLabels.ResourceView.IssueBanner.AvailabilityIssue.CascadingIssue
      }
      clickable={isSingleViolation}
      onClick={() =>
        pushDrawer({
          drawerType: DrawerType.ViolationDrawer,
          violationId: violations[0].id,
          checkType: CheckType.CascadingFailure,
        })
      }
    >
      <AccountTreeOutlined
        sx={{ color: muiTheme.palette.error.dark, width: 20, height: 20 }}
      />
      <IssueMessageContainer>
        <Typography variant="overline2" color={muiTheme.palette.error.dark}>
          The issue is correlated to{" "}
          {isSingleViolation
            ? "a cascading issue"
            : `${violations.length} cascading issues`}
          :
        </Typography>
        <div style={{ display: "flex", gap: "8px" }}>
          {renderIssueTitle(
            violations[0]?.supportingData?.cascadingFailure?.title
          )}

          {!isSingleViolation && (
            <LightTooltip
              title={
                <CascadingIssuesTooltip
                  issues={violations}
                  issueStartTime={wfRun.eventTime}
                />
              }
            >
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                color={muiTheme.palette.text.secondary}
              >
                +{violations.length - 1}
              </Typography>
            </LightTooltip>
          )}
        </div>
      </IssueMessageContainer>

      {isSingleViolation && (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          endIcon={<ChevronRight fontSize="small" />}
          sx={{ height: 28, padding: "5px 8px", fontSize: 12 }}
          onClick={() =>
            pushDrawer({
              drawerType: DrawerType.ViolationDrawer,
              violationId: violations[0].id,
              checkType: CheckType.CascadingFailure,
            })
          }
        >
          View cascading issue
        </Button>
      )}
    </IssueBannerContainer>
  );
};
