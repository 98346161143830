import { useQuery } from "@tanstack/react-query";

import {
  apiV1ClustersNodesOverTimeGetUrl,
  ClustersApiApiV1ClustersNodesOverTimeGetRequest,
  NodesOverTimeResponse,
} from "@/generated/metricsApi";
import { useMetricsApiClient } from "@/shared/hooks/metrics-api/client/apiClient";

export const METRICS_CLUSTERS_NODES_OVER_TIME_ENDPOINT =
  "/clusters/nodes-over-time";

export const useFetchClustersNodesOverTime = (
  params: ClustersApiApiV1ClustersNodesOverTimeGetRequest,
  enabled: boolean
) => {
  const apiClient = useMetricsApiClient();
  let url = "";
  try {
    url = apiV1ClustersNodesOverTimeGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    );
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useQuery(
    [METRICS_CLUSTERS_NODES_OVER_TIME_ENDPOINT, params],
    async () => await apiClient.get<NodesOverTimeResponse>(url),
    { enabled: enabled && !!url }
  );
};
