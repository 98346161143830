// https://app.clickup.com/t/86c2dqm28
/* eslint-disable */
// @ts-nocheck
import { useCallback, useMemo } from "react";
import {
  QueryBuilder as ReactQueryBuilder,
  QueryBuilderProps as ReactQueryBuilderProps,
  useQueryBuilderSetup,
  RuleType,
} from "react-querybuilder";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { CustomGroup } from "./CustomRuleGroup";
import { ariaLabels, defaultAddRuleGroupLabel } from "./constants";

type CustomQueryBuilderProps = {
  newRuleGroupStructure?: RuleType[];
  showAddRuleGroup?: boolean;
  addRuleGroupLabel?: string;
  disabledAddRuleGroup?: boolean;
};

export type QueryBuilderProps = ReactQueryBuilderProps &
  CustomQueryBuilderProps;

export const QueryBuilder = (props: QueryBuilderProps) => {
  const qb = useQueryBuilderSetup(props.query, props.onQueryChange);

  const {
    disabledAddRuleGroup,
    showAddRuleGroup,
    addRuleGroupLabel = defaultAddRuleGroupLabel,
  } = props;

  const handleCreateRuleGroup = useCallback(() => {
    const newRules = props.newRuleGroupStructure ?? [
      {
        ...qb.createRule(),
        field: props.fields[0]?.name,
        operator: props.operators[0].name,
      },
    ];

    const newGroup = qb.createRuleGroup();
    newGroup.rules = newRules;

    const newQuery = {
      ...props.query,
      rules: [...props.query.rules, newGroup],
    };
    props.onQueryChange(newQuery);
  }, [qb, props.query, props.onQueryChange]);

  const context = useMemo(() => {
    const { rules } = props.query;
    const canRemoveRules = rules?.length > 1;

    return { ...qb.rqbContext, canRemoveRules };
  }, [props.query, qb.rqbContext]);

  return (
    <Stack rowGap={3}>
      <ReactQueryBuilder
        {...props}
        context={context}
        autoSelectField
        autoSelectOperator
        fields={props.fields}
        controlElements={{
          ruleGroup: CustomGroup,
        }}
      />
      {showAddRuleGroup && (
        <Box>
          <Button
            startIcon={<AddIcon />}
            onClick={handleCreateRuleGroup}
            disabled={disabledAddRuleGroup}
            aria-label={ariaLabels.addRuleGroup}
          >
            {addRuleGroupLabel}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
