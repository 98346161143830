/* eslint-disable max-lines */
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Close24, Plus16 } from "@komodorio/design-system/icons";
import { muiColors, muiTheme } from "@komodorio/design-system";
import styled from "styled-components";
import { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import { useWorkspaces } from "../WorkspacesTopBar/hooks";

import {
  FormContent,
  FormFooter,
  FormInputLabel,
  FormSection,
  PADDING_INLINE,
  StyledForm,
  StyledTextField,
  TEXT_FIELD_WIDTH,
} from "./styles";
import { ClustersPreviewList } from "./ClustersPreviewList";
import { ClustersGroupPatternFields } from "./ClustersGroupPatternFields";
import { ClusterGroupConfirmationDialog } from "./ClusterGroupConfirmationDialog";

import {
  WorkspaceGenerationTypeEnum,
  WorkspaceKind,
} from "@/generated/workspacesApi";
import {
  TypedClustersGroupWorkspace,
  TypedWorkspace,
} from "@/shared/hooks/workspaces-api/types";
import { useCreateWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useCreateWorkspace";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { useGetClusterGroupPreview } from "@/shared/hooks/workspaces-api/workspaces/useGetClusterGroupPreview";
import { useEditWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useEditWorkspaces";

type ClusterGroupFormProps = {
  existingWorkspace?: TypedClustersGroupWorkspace;
  onCancel: () => void;
  onSuccess: (newWorkspace: TypedWorkspace) => void;
};

export function ClusterGroupForm({
  existingWorkspace,
  onCancel,
  onSuccess,
}: ClusterGroupFormProps) {
  const [name, setName] = useState(existingWorkspace?.name ?? "");
  const [description, setDescription] = useState(
    existingWorkspace?.description ?? ""
  );
  const [patterns, setPatterns] = useState<string[]>(
    existingWorkspace?.value.patterns ?? [""]
  );
  const { workspaces } = useWorkspaces();
  const createMutation = useCreateWorkspace();
  const editMutation = useEditWorkspace();

  const [hasNameError, setHasNameError] = useState(false);
  const validateNameField = useCallback((): boolean => {
    if (existingWorkspace && name === existingWorkspace.name) {
      return false;
    }
    const error =
      name === "" || (workspaces ?? [])?.some((w) => w.name === name);
    setHasNameError(error);
    return error;
  }, [existingWorkspace, name, workspaces]);
  const [hasPattersErrors, setHasPattersErrors] = useState(false);

  const {
    data: clustersPreview,
    isFetching: isFetchingClustersPreview,
    refetch,
  } = useGetClusterGroupPreview(
    {
      resolveClustersRequest: {
        patterns: patterns.filter((p) => p.trim() !== ""),
      },
    },
    {
      enabled: existingWorkspace !== undefined,
    }
  );

  const refreshClustersPreview = () => {
    if (hasPattersErrors || patterns.some((p) => p === "")) {
      return;
    }
    refetch();
  };

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const updatedClusterGroup = useMemo(
    () => ({
      id: existingWorkspace?.id ?? "",
      name,
      description,
      kind: WorkspaceKind.ClustersGroup,
      value: {
        patterns: patterns.filter((p) => p.trim() !== ""),
        totalClusters: 0,
        clusters: [],
        authorizedClusters: 0,
        kind: WorkspaceKind.ClustersGroup,
      },
      generationType: WorkspaceGenerationTypeEnum.Manual,
      createdAt: existingWorkspace?.createdAt ?? new Date().toISOString(),
      lastUpdated: existingWorkspace?.lastUpdated ?? new Date().toISOString(),
    }),
    [
      description,
      existingWorkspace?.id,
      name,
      patterns,
      existingWorkspace?.createdAt,
      existingWorkspace?.lastUpdated,
    ]
  );

  const onAddEdit = useCallback(() => {
    existingWorkspace
      ? editMutation.mutate(updatedClusterGroup, { onSuccess })
      : createMutation.mutate(updatedClusterGroup, { onSuccess });
  }, [
    createMutation,
    editMutation,
    existingWorkspace,
    onSuccess,
    updatedClusterGroup,
  ]);

  const action = existingWorkspace ? "Edit" : "Add";

  return (
    <StyledForm>
      {isConfirmDialogOpen ? (
        <ClusterGroupConfirmationDialog
          onClose={function (isOk: boolean): void {
            setIsConfirmDialogOpen(false);
            if (isOk) {
              onAddEdit();
            }
          }}
        />
      ) : null}
      <FormContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginBottom="16px"
        >
          <Typography variant="h3" sx={{ flexGrow: 1 }}>
            {action} Cluster Group
          </Typography>
          <IconButton
            onClick={onCancel}
            sx={{ marginRight: "-36px", color: muiTheme.palette.grey[500] }}
          >
            <Close24 />
          </IconButton>
        </Box>
        <FormSection>
          <FormControl>
            <StyledTextField
              inputProps={{
                "aria-label": AriaLabels.Workspaces.ClusterGroupForm.NameInput,
              }}
              label="Cluster group name"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={validateNameField}
              error={hasNameError}
              helperText={
                hasNameError ? "Cluster group name already in use" : null
              }
            />
          </FormControl>
          <FormControl>
            <FormInputLabel variant="body2">
              Description (optional)
            </FormInputLabel>
            <TextField
              inputProps={{
                "aria-label":
                  AriaLabels.Workspaces.ClusterGroupForm.DescriptionInput,
              }}
              size="small"
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  width: TEXT_FIELD_WIDTH,
                  fontSize: "14px",
                },
                "&& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderWidth: "1px",
                  },
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Cluster group description"
              multiline={true}
              rows={2}
            />
          </FormControl>
        </FormSection>
        <Typography
          variant="h4"
          sx={{ marginTop: "24px", marginBottom: "8px" }}
        >
          Cluster Group Scope
        </Typography>
        <FormSection>
          <FormControl>
            <Box
              display="flex"
              flexDirection="row"
              gap="4px"
              alignItems="center"
            >
              <FormInputLabel variant="body2">Cluster Pattern</FormInputLabel>
              <ClusterGroupPatternHelp />
            </Box>
            <ClustersGroupPatternFields
              patterns={patterns}
              onRemove={(i) => {
                setPatterns((current) =>
                  current.filter((_, index) => index !== i)
                );
              }}
              onPatternBlur={() => refreshClustersPreview()}
              onChange={(value, i) => {
                setPatterns((current) =>
                  current.map((p, index) => (index === i ? value : p))
                );
              }}
              onReportErrors={(value) => setHasPattersErrors(value)}
            />
            <Button
              variant="text"
              color="primary"
              startIcon={<Plus16 width={16} height={16} />}
              sx={{
                width: "fit-content",
                paddingLeft: "0",
                "&:hover": { background: "none" },
              }}
              onClick={() => setPatterns((p) => [...p, ""])}
            >
              Add pattern
            </Button>
          </FormControl>
        </FormSection>
        <FormSection sx={{ gap: "8px", marginTop: "8px" }}>
          <Divider
            variant="fullWidth"
            sx={{
              marginLeft: `-${PADDING_INLINE}`,
              marginRight: `-${PADDING_INLINE}`,
            }}
          />
          <ClustersPreviewList
            clusters={clustersPreview?.clusters ?? []}
            isLoading={isFetchingClustersPreview}
          />
        </FormSection>
      </FormContent>
      <FormFooter>
        <Button
          variant="text"
          color="primary"
          onClick={onCancel}
          disabled={createMutation.isLoading || editMutation.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={createMutation.isLoading || editMutation.isLoading}
          onClick={() => {
            const newHasNameError = validateNameField();
            if (hasPattersErrors || newHasNameError) {
              return;
            }

            if (
              patterns.every((p) => p === "") ||
              clustersPreview?.clusters?.length === 0
            ) {
              setIsConfirmDialogOpen(true);
              return;
            }

            onAddEdit();
          }}
        >
          {action} Cluster Group
        </LoadingButton>
      </FormFooter>
    </StyledForm>
  );
}

const tooltipStyle = {
  arrow: {
    sx: {
      "&&.MuiTooltip-arrow": {
        color: muiTheme.palette.common.white,
        "&::before": {
          backgroundColor: muiTheme.palette.common.white,
        },
        fill: "white",
        filter: "drop-shadow(0 0 3px gray)",
        clipPath: "inset(0 -10px -10px -10px)",
      },
    },
  },
  tooltip: {
    sx: {
      "&&.MuiTooltip-tooltipPlacementTop": {
        backgroundColor: muiColors.common.white,
        padding: "4px 8px",
        color: muiTheme.palette.text.primary,
        boxShadow: muiTheme.shadows[1],
        "& ul": {
          listStyleType: "disc",
          margin: 0,
          paddingLeft: "24px",
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
};

const StyledTooltip = styled(Tooltip).attrs({
  arrow: true,
  placement: "top",
  componentsProps: tooltipStyle,
})``;

function ClusterGroupPatternHelp() {
  return (
    <StyledTooltip
      title={
        <Typography variant="body3">
          Wildcard Usage
          <ul>
            <li>
              *: Matches any sequence of characters.
              <ul>
                <li>*prod*, dev-*</li>
              </ul>
            </li>
          </ul>
        </Typography>
      }
    >
      <InfoOutlined sx={{ fontSize: "16px", color: muiColors.gray[500] }} />
    </StyledTooltip>
  );
}
