import { useMemo } from "react";

import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { useSelectedPodsForEvents } from "../../../eventsView/PodsSelector/SelectedPodsProvider";
import { useBuildKomodorUidsFromPodUids } from "../nativePodEvent/useNativePodEvents";
import { useGetGroupedPodEvents } from "../../../../shared/hooks/resources-api/client/events/useGetGroupedPodEvents";
import { PodGroups } from "../../../../generated/resourcesApi";
import { deconstructUid } from "../../../../shared/utils/generateUid";

export const useGetGroupedPodEventForPodNames = (
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
): { data: PodGroups[]; isFetching: boolean } => {
  const { selectedPodUidsForEvents } = useSelectedPodsForEvents();
  const komodorUids = useBuildKomodorUidsFromPodUids(
    selectedPodUidsForEvents?.map((pod) => deconstructUid(pod)) ?? null
  );

  const { data: groupedPodEventsData, isFetching } = useGetGroupedPodEvents(
    {
      podIdentifiers: komodorUids.map((komodorUid) => ({
        komodorUid,
      })),
      fromEpoch: timeWindow?.start.getTime(),
      toEpoch: timeWindow?.end.getTime(),
    },
    komodorUids.length > 0
  );
  const data = useMemo(() => {
    if (komodorUids.length > 0) {
      return groupedPodEventsData?.data ?? [];
    }
    return [];
  }, [groupedPodEventsData, komodorUids]);

  return { data, isFetching };
};
