import { LinesLoader, TextInput } from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { InfoCircleOutlined16 } from "@komodorio/design-system/icons";
import { muiPalette } from "@komodorio/design-system";
import Link from "@mui/material/Link";

import { WorkspaceDetails } from "../../types";
import { useScopedWorkspacesFormActions } from "../../hooks/useFormActions";

import { SCOPED_WS_DESCRIPTION_MAX_LENGTH } from "./scopedWorkspaceValidationSchema";
import { ScopedWorkspaceQueryBuilder } from "./ScopedWorkspaceQueryBuilder";

import { AriaLabels } from "@/shared/config/ariaLabels";

interface Props {
  onClose: () => void;
  workspaceId?: string;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const WorkspaceDetailsForm: React.FC<Props> = ({
  onClose,
  workspaceId,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useFormContext<WorkspaceDetails>();
  const {
    isCreatingWorkspace,
    isEditingWorkspace,
    onSubmit,
    onSetDefinition,
    onAddDefinition,
  } = useScopedWorkspacesFormActions(
    {
      getFormValues: getValues,
      setError,
      setValue,
      onCloseDrawer: onClose,
    },
    workspaceId
  );

  const workspaceDefinitionValue = watch("definitions");

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      data-testid="workspace-details-form"
    >
      <Stack direction="column" gap="16px" flex={1}>
        <Stack
          direction="column"
          gap="16px"
          marginLeft="56px"
          marginRight="24px"
          flex={1}
        >
          <Stack direction="column" gap="16px" width="480px">
            <FieldContainer>
              <Typography variant="h5">Name</Typography>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Workspace name is required",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    placeholder="Workspace name"
                    aria-label={
                      AriaLabels.Workspaces.Management.AddWorkspaceDrawer.Name
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                  />
                )}
              />
            </FieldContainer>

            <FieldContainer>
              <Typography variant="h5">Description</Typography>
              <Controller
                name="description"
                control={control}
                rules={{
                  maxLength: SCOPED_WS_DESCRIPTION_MAX_LENGTH,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    placeholder="e.g. business logic, scope"
                    aria-label={
                      AriaLabels.Workspaces.Management.AddWorkspaceDrawer
                        .Description
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    fullWidth
                  />
                )}
              />
            </FieldContainer>
          </Stack>

          <Stack direction="column" gap="8px">
            <Stack direction="column">
              <Typography variant="h5">Workspace definition</Typography>

              <Typography variant="body2">
                Scope the resources of your workspace based on following
                statements:
              </Typography>

              <Stack
                direction="row"
                gap="8px"
                marginTop="8px"
                alignItems="center"
              >
                <InfoCircleOutlined16
                  width="13px"
                  height="13px"
                  fill={muiPalette.text?.secondary}
                />

                <Typography variant="body3" color="text.secondary">
                  Workspaces that use labels and/or annotations in their
                  definition will be limited to 5,000 services
                </Typography>

                <Link
                  href="#"
                  underline="none"
                  variant="body3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </Link>
              </Stack>
            </Stack>

            <ScopedWorkspaceQueryBuilder
              definitions={workspaceDefinitionValue}
              setDefinition={onSetDefinition}
              onAddDefinition={onAddDefinition}
            />
          </Stack>
        </Stack>

        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          marginRight="24px"
          marginBottom="16px"
          gap="8px"
        >
          {isCreatingWorkspace || isEditingWorkspace ? (
            <LinesLoader />
          ) : (
            <>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>

              <Button
                variant="contained"
                type="submit"
                aria-label={
                  AriaLabels.Workspaces.Management.AddWorkspaceDrawer
                    .CreateWorkspaceButton
                }
              >
                {workspaceId ? "Edit workspace" : "Create workspace"}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </StyledForm>
  );
};
