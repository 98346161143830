import { useQuery } from "@tanstack/react-query";
import { cloneDeep, merge } from "lodash";

import { useWorkspaceQueryKey } from "../workspaces-api/useWorkspaceQueryKey";

import { useK8sAddonsApiClient } from "./apiClient";

import {
  getEntityAggregationsUrl,
  EntityAggregationResponse,
  AddonsApiGetEntityAggregationsRequest,
} from "@/generated/addonsApi";
import { getReactQueryOptions } from "@/shared/utils/react-query/getReactQueryOptions";
import { QueryOptions } from "@/shared/constants/reactQueryOptions";

export const GET_ADDONS_AGGREGATIONS_PATH =
  "api/v1/addons/:addon/:entity/aggregations";

export const useGetAddonAggregations = (
  params: AddonsApiGetEntityAggregationsRequest,
  queryOptions?: QueryOptions
) => {
  const apiClient = useK8sAddonsApiClient(queryOptions?.skipWorkspaceId);

  let url = "";
  try {
    url = getEntityAggregationsUrl(params, apiClient.defaults.baseURL ?? "");
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  // Remove mostRecentDataFrom from the query key to avoid unnecessary re-fetching
  const paramsKeys = merge(cloneDeep(params), {
    getEntityAggregationsRequest: { mostRecentDataFrom: "" },
  });
  const keys = useWorkspaceQueryKey([url, paramsKeys]);

  return useQuery(
    keys,
    async () =>
      await apiClient.post<EntityAggregationResponse>(
        getEntityAggregationsUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.getEntityAggregationsRequest }
      ),

    getReactQueryOptions<EntityAggregationResponse>(queryOptions, url)
  );
};
