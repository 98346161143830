import React, { useMemo } from "react";

import FetchDataLoading from "../common/loaders/LoadingState";

import { KubernetesResource } from "./inspectionConfiguration/SupportedResourcesTypes";
import { useInspectionParams } from "./InspectionViews/common";
import InspectionViewAgentTask from "./InspectionViews/InspectionViewAgentTask";
import { InspectionViewAtm } from "./InspectionViews/InspectionViewAtm";
import { Panel } from "./styles";

import { parseAgentToAgentProperties } from "@/shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportResourceListFromATM } from "@/shared/utils/agent/agent";
import { useNodeAccessibility } from "@/components/Inspection/NodeAccessibility/useNodeAccessibility";
import { NodeAccessibilityComponent } from "@/components/Inspection/NodeAccessibility/NodeAccessibilityComponent";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum InspectionType {
  DB = "db",
  OldAgentTask = "oldAgentTask",
  ATM = "atm",
}

const InspectionView: React.FC<{
  kubernetesResource: KubernetesResource;
}> = ({ kubernetesResource }) => {
  const { cluster, onClusterChange, agent, agentId } =
    useInspectionParams(kubernetesResource);

  const isNodeAccessible = useNodeAccessibility(kubernetesResource);
  const agentProps = useMemo(() => {
    if (!agent) return null;
    return parseAgentToAgentProperties(agent);
  }, [agent]);

  const inspectionType = useMemo(() => {
    if (!cluster || !agentProps) return undefined;
    if (doesAgentVersionSupportResourceListFromATM(agentProps)) {
      return InspectionType.ATM;
    }

    return InspectionType.OldAgentTask;
  }, [agentProps, cluster]);
  if (isNodeAccessible) {
    return <NodeAccessibilityComponent />;
  }
  if (!inspectionType || !cluster || !agentId) {
    return (
      <Panel>
        <FetchDataLoading
          dataName={
            kubernetesResource.PresentName ?? kubernetesResource.NameInK8S
          }
        />
      </Panel>
    );
  }
  if (inspectionType === InspectionType.OldAgentTask) {
    return (
      <InspectionViewAgentTask
        kubernetesResource={kubernetesResource}
        onClusterChange={onClusterChange}
        cluster={cluster}
        agentInfo={agentProps}
        agentId={agentId}
      />
    );
  }
  return (
    <InspectionViewAtm
      kubernetesResource={kubernetesResource}
      onClusterChange={onClusterChange}
      cluster={cluster}
      agentInfo={agentProps}
      agentId={agentId}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InspectionView;
