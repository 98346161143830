import { getAppConfig } from "../../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

export const useSearchApiClient = (
  skipAppViewInHeaders?: boolean,
  skipWorkspaceHeader?: boolean
) => {
  return useCreateAxiosClient(getAppConfig().searchAPIServerURL, {
    skipAppViewInHeaders,
    skipWorkspaceHeader,
  });
};
