import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { AnimatedKeyboardArrowDown } from "../../../../ReliabilityStyles";
import { ExpandableContentContainer } from "../../../shared/ExpandableContentContainer";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { ViolationsAggregationGroup } from "../../../../../../generated/reliabilityApi";

import { formatNumber } from "@/shared/utils/formatNumber";
import { FeatureTag } from "@/shared/components/FeatureTag";

const Container = styled(Card)`
  cursor: pointer;
`;

const InteractiveSectionContainer = styled.div`
  cursor: pointer;
  padding: 8px 0;
`;

const PaddedDiv = styled.div<{ $paddingBottom: boolean }>`
  padding: 0 16px ${({ $paddingBottom }) => ($paddingBottom ? 24 : 0)}px 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AnimatedArrow = styled(AnimatedKeyboardArrowDown)`
  && {
    width: 20px;
    height: 20px;
    padding: 6px;
    margin-right: 8px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledFeatureTag = styled(FeatureTag)`
  && {
    margin-left: 8px;
    padding: 2px 8px;
  }
`;

const {
  groups: {
    singleGroup: singleGroupArialLabel,
    Title: groupTitleArialLabel,
    expandHitArea: expandHitAreaArialLabel,
  },
} = reliabilityArialLabels;

type Props = ViolationsAggregationGroup & {
  onExpandClick: () => void;
  isOpen: boolean;
  title: string;
  cardContent: ReactElement;
  totalContentRows: number;
  closeCardPaddingBottom?: boolean;
  summaryElement?: ReactElement;
  additionalCardContent?: ReactElement;
  isBeta?: boolean;
};

export const GroupCard: React.FC<Props> = ({
  id,
  onExpandClick,
  isOpen,
  title,
  closeCardPaddingBottom = false,
  summaryElement,
  additionalCardContent,
  cardContent,
  totalContentRows,
  isBeta,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const ariaLabel = `${singleGroupArialLabel} ${id} ${isOpen ? "open" : ""}`;

  return (
    <Container elevation={isHovering ? 8 : 1} aria-label={ariaLabel}>
      <InteractiveSectionContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onExpandClick}
        aria-label={expandHitAreaArialLabel}
      >
        <PaddedDiv $paddingBottom={closeCardPaddingBottom}>
          <HeaderContainer>
            <AnimatedArrow open={isOpen} color="action" />
            <TitleContainer aria-label={groupTitleArialLabel}>
              <Typography variant={"h4"}>{title}</Typography>
              <Typography variant={"h4"}>
                ({formatNumber(totalContentRows)})
              </Typography>
              {!!isBeta && <StyledFeatureTag tag="beta" size={"large"} />}
            </TitleContainer>
            {summaryElement}
          </HeaderContainer>
          {additionalCardContent}
        </PaddedDiv>
      </InteractiveSectionContainer>
      <ExpandableContentContainer
        isExpanded={isOpen}
        autoDetectContentHeight={false}
      >
        <PaddedDiv $paddingBottom>{cardContent}</PaddedDiv>
      </ExpandableContentContainer>
    </Container>
  );
};
