import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Breadcrumbs } from "@komodorio/design-system/komodor-ui";
import Button from "@mui/material/Button";
import { muiColors } from "@komodorio/design-system";

import { AppNotificationStatus } from "@/shared/components/AppNotificationsCenter/AppNotificationStatus";
import { Notification } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";
import { appNotificationsAriaLabels } from "@/shared/components/AppNotificationsCenter/appNotificationsAriaLabels";

const {
  container: containerAriaLabel,
  title: titleAriaLabel,
  message: messageAriaLabel,
  close: closeAriaLabel,
  detailsButton: detailsButtonAriaLabel,
} = appNotificationsAriaLabels.item;

const Container = styled.div<{ $hasOnClick: boolean }>`
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  ${({ $hasOnClick }) =>
    $hasOnClick &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${muiColors.gray[25]};
        & button {
          opacity:1
        }
      }
    `}
`;

const Title = styled(Typography)`
  max-width: 120px;
`;

const TitleAndStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
`;

const ViewDetailsButton = styled(Button)`
  && {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s, background-color 0.2s;
    background-color: ${muiColors.gray[25]};
    &:hover {
      background-color: ${muiColors.gray[100]};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: 0;
  }
`;

const TypographyWithMaxLines = styled(Typography)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

type AppNotificationItemProps = {
  notification: Notification;
  onClose: () => void;
  onClick?: () => void;
};

export const AppNotificationItem = ({
  notification,
  onClose,
  onClick,
}: AppNotificationItemProps) => {
  const { title, status, message, resource } = notification;

  return (
    <Container $hasOnClick={!!onClick} aria-label={containerAriaLabel}>
      <TitleAndStatus>
        <AppNotificationStatus status={status} />
        <Title
          variant={"overline2"}
          color={"text.secondary"}
          aria-label={titleAriaLabel}
        >
          {title}
        </Title>
      </TitleAndStatus>
      <ContentContainer>
        {!!resource && (
          <Breadcrumbs
            allowOverflow={false}
            breadcrumbs={[
              resource.clusterName,
              resource.namespace,
              resource.name,
            ].filter((r): r is string => Boolean(r))}
          />
        )}
        <TypographyWithMaxLines variant={"h6"} aria-label={messageAriaLabel}>
          {message}
        </TypographyWithMaxLines>
        {onClick && (
          <ViewDetailsButton
            onClick={onClick}
            aria-label={detailsButtonAriaLabel}
          >
            View details
          </ViewDetailsButton>
        )}
      </ContentContainer>
      <StyledIconButton onClick={onClose} aria-label={closeAriaLabel}>
        <CloseIcon fontSize={"small"} />
      </StyledIconButton>
    </Container>
  );
};
