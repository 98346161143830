import React from "react";
import Skeleton from "@mui/material/Skeleton";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DeployEvent } from "../../../EventGroup/deployEvent/DeployEventGroup";
import EventDetailsSection from "../components/EventDetailsSection";
import k8sIcon from "../../../../integrations/management/logos/kubernetes.svg";
import { K8sDiff } from "../../../EventGroup/deployEvent/getK8sDiff";
import { Specs } from "../../../EventGroup/deployEvent/getSpecsForDiff";

import DiffEntry from "./DiffEntry";
import K8sDiffLink from "./K8sDiffLink";

export const PREVIEW_LIMIT = 8;

interface K8sSectionProps {
  firstEvent: DeployEvent;
  k8sDiff: K8sDiff | undefined;
  specsForDiff: Specs | null;
  isFirstDeployment: boolean | undefined;
  isFetchingK8sData: boolean;
}
const K8sSection: React.FC<K8sSectionProps> = ({
  firstEvent,
  k8sDiff,
  specsForDiff,
  isFirstDeployment,
  isFetchingK8sData,
}) => {
  const { changes } = k8sDiff ?? {
    changes: [],
    hiddenCount: 0,
  };

  return (
    <EventDetailsSection
      icon={k8sIcon}
      title="Kubernetes"
      info="We compare the previous yaml to the new version, showing the changes in the service yaml, configmaps and other related resources."
    >
      {isFetchingK8sData ? (
        <K8sSectionLoader />
      ) : (
        <>
          {!isFirstDeployment && changes.length
            ? changes
                .slice(0, PREVIEW_LIMIT)
                .map((c) => <DiffEntry key={c.name} change={c} />)
            : null}
          <K8sDiffLink
            k8sDiff={k8sDiff}
            specsForDiff={specsForDiff}
            isFirstDeployment={isFirstDeployment}
            versionFrom={firstEvent.versionFrom ?? ""}
            versionTo={firstEvent.versionTo ?? ""}
            serviceName={firstEvent.deploymentName ?? ""}
            clusterName={firstEvent.clusterName ?? ""}
            namespace={firstEvent.namespace ?? ""}
          />
        </>
      )}
    </EventDetailsSection>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default K8sSection;

const K8sSectionLoader: React.FC = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
    </>
  );
};
