import { useCallback } from "react";
import { ResourceTableModelRow } from "komodor-types";

import {
  ResourcesTags,
  ResourceType,
} from "../../../components/appView/sections/AppView/types/appViewsResourcesTypes";
import { KubernetesResource } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { getResourceIdentification } from "../../utils/appViews/getResourceIdentification";
import { findServiceForPod } from "../../utils/serviceHelpers";
import { useKomodorServices } from "../useKomodorServices";
import EventGroup from "../../../components/common/EventGroup";
import { useAppViewsStore } from "../../store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../store/appViewsStore/appViewStoreSelectors";
import { useAppViewsResourcesStore } from "../../store/appViewsResourcesStore/appViewsResourcesStore";
import {
  selectClusters,
  selectResources,
  selectTags,
} from "../../store/appViewsResourcesStore/appViewsResourcesStoreSelectors";
import { useWorkspaces } from "../../../components/workspaces/WorkspacesTopBar/hooks";

export type FilterByAppViewParams = {
  dataList?: ResourceTableModelRow[];
  kubernetesResource: KubernetesResource;
  cluster?: string;
};
export const useFilterByAppView = (): ((
  params: FilterByAppViewParams
) => ResourceTableModelRow[]) => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const { isWorkspaceKindBackendFiltered } = useWorkspaces();
  const resources = useAppViewsResourcesStore(selectResources);
  const services = useKomodorServices().all;

  return useCallback(
    ({
      kubernetesResource,
      cluster = "",
      dataList = [],
    }: FilterByAppViewParams) => {
      const resource = resources[kubernetesResource.NameInK8S as ResourceType];
      if (
        isWorkspaceKindBackendFiltered ||
        !selectedAppViewId ||
        !resource ||
        !dataList.length
      )
        return dataList;

      const isPodResource = kubernetesResource.NameInK8S === "pods";
      return dataList.filter((item) => {
        if (isPodResource) {
          //pods are ephemeral, compare them with found services and not with static resources inventory
          return findServiceForPod({
            services,
            podControlledBy: item.controlledBy,
            namespace: item.namespace,
            clusterName: cluster,
          });
        }
        const resourceIdentifier = getResourceIdentification({
          clusterName: cluster,
          resourceName: item.name,
          k8sResourceName: kubernetesResource.NameInK8S,
          namespace: item.namespace,
        });
        return !!resource[resourceIdentifier];
      });
    },
    [isWorkspaceKindBackendFiltered, resources, selectedAppViewId, services]
  );
};

export const useFilterTagsByAppViews = (): ((
  tagKey: keyof ResourcesTags,
  tagValues?: string[]
) => string[] | undefined) => {
  const tags = useAppViewsResourcesStore(selectTags);
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const { isWorkspaceKindBackendFiltered } = useWorkspaces();

  return useCallback(
    (tagKey: keyof ResourcesTags, tagValues?: string[]) => {
      if (!tagValues || !selectedAppViewId || isWorkspaceKindBackendFiltered)
        return tagValues;
      return tagValues.filter((tag) => tags[tagKey].has(tag));
    },
    [selectedAppViewId, tags, isWorkspaceKindBackendFiltered]
  );
};

export const useFilterClusterNamesByAppViews = (): ((
  clusterNames: string[]
) => string[]) => {
  const appViewClusters = useAppViewsResourcesStore(selectClusters);
  return useCallback(
    (clusterNames: string[]) => {
      if (appViewClusters.size === 0) return clusterNames;
      return clusterNames.filter((clusterName) =>
        appViewClusters.has(clusterName)
      );
    },
    [appViewClusters]
  );
};

export const useFilterEventGroupsByAppView = (): ((
  eventGroups: EventGroup[]
) => EventGroup[]) => {
  const { isWorkspaceKindBackendFiltered, isLoading } = useWorkspaces();
  const services = useKomodorServices().all;
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  return useCallback(
    (eventGroups: EventGroup[]) => {
      // TODO: remove this filtering when it's in the backend
      if (
        !selectedAppViewId ||
        (!isLoading && isWorkspaceKindBackendFiltered)
      ) {
        return eventGroups;
      }
      return eventGroups.filter(({ serviceId }) =>
        services?.find((service) => service.id === serviceId)
      );
    },
    [isLoading, isWorkspaceKindBackendFiltered, selectedAppViewId, services]
  );
};
