import React, { memo, useEffect, useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { muiTheme } from "@komodorio/design-system";

import { EffectivePermission } from "@/generated/auth";
import {
  PAGE_SIZE_OPTIONS,
  useDataGridPagination,
} from "@/shared/hooks/dataGrid/useDataGridPagination";
import { ariaLabels } from "@/components/Settings/Users/const";
import { useEffectivePermissions } from "@/components/Settings/Users/hooks/useEffectivePermissions";
import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { getColumnDefinition } from "@/components/Settings/Users/EffectivePermissions/gridHelpers";
import { UserDrawerContentProps } from "@/components/Settings/Users/EffectivePermissions/types";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DdTimingsViews } from "@/components/Settings/Users/Permissions/ddTimings";
import { Filter } from "@/components/Settings/Users/EffectivePermissions/Filter";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { IRbacV2Flag } from "@/shared/types/featureFlags";

const getEffectivePermissionRowID = (row: EffectivePermission) =>
  `${row.action}.${row.policyName}.${row.roleName}.${row.cluster}.${row.namespace}.${row.selector}.${row.selectorPattern?.type}|${row.selectorPattern?.key}|${row.selectorPattern?.value.include}|${row.selectorPattern?.value.exclude}`;

export const EffectivePermissionsContainer: React.FC<UserDrawerContentProps> =
  memo(({ user, allowLinks = false, width }) => {
    const { rbacV2 } = useOverridableFlags();
    const rbacV2Flag = rbacV2 as IRbacV2Flag;

    const {
      rawData,
      isFetching,
      error,
      uniqueActionNamesOptions,
      uniquePolicyNamesOptions,
      uniqueRoleNamesOptions,
      uniqueClusterNamesOptions,
      uniqueNamespaceNamesOptions,
      dataFilters,
      setSelectedProperty,
      filteredDataRows,
    } = useEffectivePermissions({
      user,
    });

    const { paginationModel, handlePaginationModelChange } =
      useDataGridPagination();

    const { reportLoadingState } = useDatadogReportLoadingTimeContext();

    useEffect(() => {
      reportLoadingState(DdTimingsViews.effectivePermissions, isFetching);
    }, [isFetching, reportLoadingState]);

    const columnsDefinitions = useMemo(() => {
      const definitions = getColumnDefinition({ allowLinks });
      return definitions.filter((definition) => {
        if (definition.showOnlyForV2) {
          return rbacV2Flag?.resolveResourcesScopePermissions;
        }
        return true;
      });
    }, [allowLinks, rbacV2Flag?.resolveResourcesScopePermissions]);

    const containerWidth = useMemo(() => {
      if (!width || width <= 0) {
        return "100%";
      }

      return width;
    }, [width]);

    return useMemo(() => {
      if (error) {
        return (
          <Typography color={muiTheme.palette.error.main}>
            There was an error fetching the effective permissions
          </Typography>
        );
      }
      if (isFetching || (width && width < 0)) {
        return (
          <Stack
            flexGrow={1}
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CenteredLoader />
          </Stack>
        );
      }

      if (!rawData?.length) {
        return <Typography>No effective permissions found</Typography>;
      }

      return (
        <Stack direction="column" rowGap="24px" flexGrow={1}>
          <Box
            sx={{
              width: containerWidth,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Stack direction="row" columnGap={1} width={"100%"}>
              <Filter
                onChange={(value) => setSelectedProperty("cluster", value)}
                id="cluster"
                options={uniqueClusterNamesOptions}
                value={dataFilters.cluster}
              />
              <Filter
                onChange={(value) => setSelectedProperty("namespace", value)}
                id="namespace"
                options={uniqueNamespaceNamesOptions}
                value={dataFilters.namespace}
              />
              <Filter
                onChange={(value) => setSelectedProperty("action", value)}
                id="action"
                options={uniqueActionNamesOptions}
                value={dataFilters.action}
              />
              <Filter
                onChange={(value) => setSelectedProperty("policyName", value)}
                id="policy"
                options={uniquePolicyNamesOptions}
                value={dataFilters.policyName}
              />
              <Filter
                id="role"
                options={uniqueRoleNamesOptions}
                value={dataFilters.roleName}
                onChange={(value) => setSelectedProperty("roleName", value)}
              />
            </Stack>
          </Box>
          <Paper sx={{ width: width || "100%", overflow: "auto" }}>
            <DataGridPro
              autoHeight
              rows={filteredDataRows}
              columns={columnsDefinitions}
              getRowId={getEffectivePermissionRowID}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationModelChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              disableColumnMenu
              aria-label={ariaLabels.userPermissionsDrawer.table}
            />
          </Paper>
        </Stack>
      );
    }, [
      isFetching,
      error,
      rawData,
      dataFilters,
      paginationModel,
      handlePaginationModelChange,
      filteredDataRows,
      columnsDefinitions,
      width,
      setSelectedProperty,
      uniqueActionNamesOptions,
      uniquePolicyNamesOptions,
      uniqueRoleNamesOptions,
      uniqueClusterNamesOptions,
      uniqueNamespaceNamesOptions,
      containerWidth,
    ]);
  });

type EffectivePermissionsProps = React.ComponentProps<
  typeof EffectivePermissionsContainer
>;

export const EffectivePermissionsContent: React.FC<
  EffectivePermissionsProps
> = (props) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.userSettingsEffectivePermissionsPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <EffectivePermissionsContainer {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
