"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventTypesMultipleTypes = exports.EventRunTypes = exports.eventTypeFilterDisplayName = exports.eventTypeFilterName = exports.servicesFilterDisplayName = void 0;
const runtypes_1 = require("runtypes");
exports.servicesFilterDisplayName = "Services";
exports.eventTypeFilterName = "eventType";
exports.eventTypeFilterDisplayName = "Event types";
exports.EventRunTypes = (0, runtypes_1.Union)(
// Union supports up to 20 arguments
(0, runtypes_1.Union)((0, runtypes_1.Literal)("deploy"), (0, runtypes_1.Literal)("nodeIssue"), (0, runtypes_1.Literal)("configMap"), (0, runtypes_1.Literal)("job"), (0, runtypes_1.Literal)("event_job"), (0, runtypes_1.Literal)("workflowResults"), (0, runtypes_1.Literal)("kubernetesServiceResource"), (0, runtypes_1.Literal)("komodorAlerts"), (0, runtypes_1.Literal)("resourceQuota"), (0, runtypes_1.Literal)("pdb"), (0, runtypes_1.Literal)("secret")), (0, runtypes_1.Union)((0, runtypes_1.Literal)("pagerduty"), (0, runtypes_1.Literal)("topology"), (0, runtypes_1.Literal)("hpa"), (0, runtypes_1.Literal)("sentryIssue"), (0, runtypes_1.Literal)("ingress"), (0, runtypes_1.Literal)("config"), (0, runtypes_1.Literal)("networkPolicies"), (0, runtypes_1.Literal)("actions"), (0, runtypes_1.Literal)("limitRanges"), (0, runtypes_1.Literal)("nodeChange")));
exports.eventTypesMultipleTypes = [
    "workflowResults",
    "komodorAlerts",
    "topology",
];
