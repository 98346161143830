import React from "react";
import styled from "styled-components";

import { indigo } from "../../../themes/colors";

type LinesLoaderProps = React.HTMLAttributes<HTMLDivElement> & {
  renderInContainerCenter?: boolean;
};

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  width: 72px;
  height: 50px;

  & > div {
    height: 16px;
    width: 18px;
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
    animation: growShrink 1.8s infinite ease-in-out;
  }

  & > div:nth-child(1) {
    background-color: ${indigo[600]};
  }
  & > div:nth-child(2) {
    background-color: ${indigo[400]};
    animation-delay: -1.2s;
  }
  & > div:nth-child(3) {
    background-color: ${indigo[200]};
    animation-delay: -0.6s;
  }

  @keyframes growShrink {
    0%,
    100% {
      width: 30px;
    }
    50% {
      width: 70px;
    }
  }
`;

export const LinesLoader = (props: LinesLoaderProps) => {
  const { renderInContainerCenter = true } = props;
  const loader = (
    <LoaderContainer {...props} aria-label="Loading...">
      <div></div>
      <div></div>
      <div></div>
    </LoaderContainer>
  );

  if (renderInContainerCenter) {
    return <CenterContainer>{loader}</CenterContainer>;
  }
  return loader;
};
