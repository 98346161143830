import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";
import { DISABLED_ITEM_OPACITY } from "../shared/constants";
import { OverflowTextStyle } from "../../../../styles/styles";

const DisabledMenuItem = styled(MenuItem)`
  && {
    cursor: default;
    opacity: ${DISABLED_ITEM_OPACITY};
    width: fit-content;
    &:hover {
      background-color: unset;
    }
  }
`;

const TruncatedTypography = styled(Typography)`
  && {
    ${OverflowTextStyle};
  }
`;

interface SelectItemProps<T extends MuiSelectionOptionValue> {
  option: MuiSelectionOption<T>;
  onSelect: (value: T) => void;
  selectedOption?: MuiSelectionOption<T>;
  customOptionElement?: (option: MuiSelectionOption<T>) => React.ReactNode;
  className?: string;
}

export const SelectItem = <T extends MuiSelectionOptionValue>({
  option,
  onSelect,
  selectedOption,
  customOptionElement,
  className,
}: SelectItemProps<T>): React.ReactNode => {
  const Item = option.disabled ? DisabledMenuItem : MenuItem;
  return (
    <Tooltip title={option.disabledTooltipContent} placement={"top"}>
      <Item
        value={option.value}
        selected={selectedOption?.value === option.value}
        aria-label={option.label}
        {...(!option.disabled && {
          onClick: () => onSelect(option.value),
        })}
        className={className}
      >
        {customOptionElement ? (
          customOptionElement(option)
        ) : (
          <TruncatedTypography variant={"body2"}>
            {option.label}
          </TruncatedTypography>
        )}
      </Item>
    </Tooltip>
  );
};
