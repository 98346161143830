import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { muiTheme } from "@komodorio/design-system";

import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";
import { RbacPolicyStatementsInner } from "@/generated/auth";
import { getScopeFromStatement } from "@/pages/organization-settings/access-management/PoliciesPage/utils/getScopeFromStatement";
import { scopeTitles } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/constants";
import { useRbacActionsContext } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/useRbacActionsContext";

type StatementBarProps = {
  statement: RbacPolicyStatementsInner;
  index: number;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
};

export const StatementBar = ({
  statement,
  index,
  onEdit,
  onDelete,
}: StatementBarProps) => {
  const { globalActions } = useRbacActionsContext();
  const scope = useMemo(() => {
    return getScopeFromStatement(globalActions, statement);
  }, [globalActions, statement]);

  return (
    <Stack rowGap="4px">
      {index > 0 && <Typography variant="overline2">OR</Typography>}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: "8px 16px",
          backgroundColor: muiTheme.palette.background.paper,
          border: "1px solid",
          borderColor: muiTheme.palette.divider,
        }}
        aria-label={`${createPolicyAriaLabels.statements.barContainer} ${
          index + 1
        }`}
        role={"listitem"}
      >
        <Stack direction="row" columnGap={1}>
          <Typography variant="h5">Statement #{index + 1}</Typography>
          <Typography variant="body2" color="text.secondary">
            {scopeTitles[scope]}
          </Typography>
        </Stack>
        <Stack direction="row" columnGap={1}>
          <Button
            aria-label={`${createPolicyAriaLabels.statements.editStatement} ${index}`}
            onClick={() => onEdit(index)}
          >
            Edit
          </Button>
          <Button
            aria-label={`${createPolicyAriaLabels.statements.deleteStatement} ${index}`}
            onClick={() => onDelete(index)}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
