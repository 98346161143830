import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { MenuItem as MenuItemType, isPage } from "../types";

import { LinkedItem, LockedItem } from "./components";

interface useMenuArgs {
  id: string;
  items: MenuItemType[];
  tooltip?: string;
  LinkComponent: React.ElementType;
}

type UseMenuOutput = {
  BUTTON_ID: string;
  MENU_ID: string;
  TooltipWrapper: React.FC<{ children: React.ReactNode }>;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  menuItems: React.ReactNode[];
  open: boolean;
};

export const useMenu = ({
  id,
  items,
  LinkComponent,
  tooltip,
}: useMenuArgs): UseMenuOutput => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const TooltipWrapper = React.useCallback(
    ({ children }) => {
      if (tooltip) {
        return <Tooltip title={tooltip}>{children}</Tooltip>;
      }

      return children;
    },
    [tooltip]
  );

  const { BUTTON_ID, MENU_ID } = React.useMemo(() => {
    return {
      BUTTON_ID: `${id}-button`,
      MENU_ID: `${id}-menu`,
    };
  }, [id]);

  const menuItems = React.useMemo(() => {
    return items.map((item, itemIndex) => {
      if (isPage(item)) {
        // If the item has a special component implementation, use it
        if (item.CustomComponent) {
          return (
            <item.CustomComponent
              key={item.route}
              aria-label={item.ariaLabel || item.title}
              {...item}
              onClose={handleClose}
            />
          );
        }

        if (item.disabled) {
          return (
            <LockedItem
              title={item.title}
              key={item.route}
              ariaLabel={item.ariaLabel}
            />
          );
        }
        return (
          <LinkedItem
            key={item.route}
            LinkComponent={LinkComponent}
            onClick={handleClose}
            divider={item.divider}
            {...item}
          />
        );
      }

      return [
        <MenuItem key={itemIndex} disabled aria-label={`${item.title}`}>
          <Typography variant="overline">{item.title}</Typography>
        </MenuItem>,
        ...item.pages.map((page, pageIndex) => {
          // If the item has a special component implementation, use it
          if (page.CustomComponent) {
            return (
              <page.CustomComponent
                key={page.route}
                aria-label={page.ariaLabel || page.title}
              />
            );
          }

          const isDivider =
            itemIndex !== items.length - 1 &&
            pageIndex === item.pages.length - 1;

          if (page.disabled) {
            return (
              <LockedItem
                key={page.route}
                title={page.title}
                divider={isDivider}
                ariaLabel={page.ariaLabel}
              />
            );
          }

          return (
            <LinkedItem
              key={page.route}
              LinkComponent={LinkComponent}
              onClick={handleClose}
              divider={isDivider}
              {...page}
            />
          );
        }),
      ];
    });
  }, [LinkComponent, items]);

  return {
    BUTTON_ID,
    MENU_ID,
    TooltipWrapper,
    anchorEl,
    handleClick,
    handleClose,
    menuItems,
    open,
  };
};
