import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import type { AxiosResponse } from "axios";
import { UseQueryResult } from "@tanstack/react-query";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
  GridRowParams,
} from "@mui/x-data-grid-pro";

import {
  BasicViolation,
  GetViolationsResponse,
} from "../../../../../../../../generated/reliabilityApi";
import {
  useEmptyColumns,
  useViolationsTableColumns,
} from "../hooks/violationTableHooks";
import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { useOpenDrawerFromDataGrid } from "../../../ViolationsDrawer/hooks/drawerHooks";
import { useInitialGroupState } from "../hooks/useInitialGroupState";
import { tableProps } from "../../violationsBreakdownConstants";
import { VIOLATIONS_TABLE_ROW_HEIGHT } from "../violationTableConstants";

import { EmptyTableResults } from "./EmptyTableResults";

import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import { selectViolationsState } from "@/components/reliability/store/reliabilityStoreSelectors";
import {
  ViolationsTableType,
  ViolationTableCustomPaginationModel,
} from "@/components/reliability/ReliabilityTypes";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";
import { generateEmptyRows } from "@/shared/utils/tableUtils";
import { useRowsCount } from "@/components/reliability/components/shared/table/useRowsCount";
import { useSetPaginationModel } from "@/components/reliability/hooks/useViolationTableHooks";
import { getGroupData } from "@/components/reliability/utils/getGroupData";
import { useReportTableLoadingState } from "@/components/reliability/hooks/useReportTableLoadingState";

type StyledComponentsProps = {
  $isEmptyResults: boolean;
};

const EmptyResultsStyle = css<StyledComponentsProps>`
  opacity: ${({ $isEmptyResults }) => ($isEmptyResults ? 0 : 1)};
  pointer-events: ${({ $isEmptyResults }) =>
    $isEmptyResults ? "none" : "auto"};
`;

const Container = styled.div<StyledComponentsProps>`
  position: relative;
  && {
    .MuiDataGrid-footerContainer {
      ${EmptyResultsStyle};
    }
    .MuiDataGrid-row {
      ${EmptyResultsStyle};
    }
  }
`;

const StyledDataGridPro = styled(DataGridPro)`
  && {
    .MuiDataGrid-row {
      min-height: ${VIOLATIONS_TABLE_ROW_HEIGHT}px!important;
    }
    .MuiDataGrid-row:hover {
      cursor: pointer;
    }
  }
`;

type ViolationsTableProps = {
  violationsRes: UseQueryResult<AxiosResponse<GetViolationsResponse>>;
  containerWidth?: number;
  customPaginationObj?: ViolationTableCustomPaginationModel;
  violationTableType?: ViolationsTableType;
  hideFooter?: boolean;
  /**
   * A callback to be called when user click on a row in the Table.
   * NOTE: this is a smart component, a navigation to the violation drawer is being held by this component. This
   * callback will be called before the navigation and won't replace it.
   * @param violation The violation the user clicked on.
   */
  onRowClicked?: (violation: BasicViolation) => void;
};

export const ViolationsTable: React.FC<ViolationsTableProps> = ({
  violationsRes,
  containerWidth,
  customPaginationObj,
  violationTableType,
  onRowClicked,
  hideFooter = false,
}) => {
  const { uid, violationsGroupBy } = useViolationsTableContext();
  const isBestPracticeTab = useIsBestPracticesTab();

  const { customPaginationModel, setCustomPaginationModel } =
    customPaginationObj ?? {};

  const { groups } = useReliabilityStore(selectViolationsState);

  const initialGroupState = useInitialGroupState(violationsGroupBy);

  const { data: violationsData, isFetching, error } = violationsRes;
  const rowsCount = useRowsCount(violationsData?.data?.totalResults);

  useReportTableLoadingState({
    uid: uid ?? "",
    violationsRes,
  });

  const paginationModel = useMemo(() => {
    if (customPaginationModel) {
      return customPaginationModel;
    }
    return (
      getGroupData({
        groupState: groups,
        violationsGroupBy,
        uid,
      })?.paginationModel ?? initialGroupState.paginationModel
    );
  }, [
    customPaginationModel,
    groups,
    initialGroupState.paginationModel,
    uid,
    violationsGroupBy,
  ]);

  const violationTableTypeByTab: ViolationsTableType =
    violationTableType ??
    (isBestPracticeTab
      ? ViolationsTableType.impactAnalysis
      : ViolationsTableType.riskAssessment);

  const columns = useViolationsTableColumns({
    violationTableType: violationTableTypeByTab,
  });
  const emptyColumns = useEmptyColumns({
    violationTableType: violationTableTypeByTab,
  });

  const isEmptyResults = violationsData?.data.violations?.length === 0;
  const hasNoResults = isEmptyResults || !!error;

  const columnsToUse = isFetching || hasNoResults ? emptyColumns : columns;

  const rowsToUse =
    isFetching || hasNoResults
      ? generateEmptyRows<BasicViolation>(paginationModel.pageSize)
      : violationsData?.data.violations;

  const setPaginationModel = useSetPaginationModel(uid, violationsGroupBy);

  const rowClickHandler = useOpenDrawerFromDataGrid();
  const handleRowClick = useCallback(
    (rowParams: GridRowParams<BasicViolation>) => {
      if (onRowClicked) {
        onRowClicked(rowParams.row);
      }
      rowClickHandler(rowParams);
    },
    [onRowClicked, rowClickHandler]
  );
  return (
    <Container
      $isEmptyResults={hasNoResults}
      style={containerWidth ? { width: containerWidth } : {}}
    >
      <StyledDataGridPro
        {...tableProps}
        getRowHeight={() => "auto"}
        rows={rowsToUse ?? []}
        onRowClick={handleRowClick}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowsCount}
        hideFooter={hideFooter}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setCustomPaginationModel ?? setPaginationModel}
      />
      <>{hasNoResults && <EmptyTableResults isError={!!error} />}</>
    </Container>
  );
};
