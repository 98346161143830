import React, { useEffect } from "react";

import {
  useActiveAgent,
  useAgentPropertiesById,
} from "../../../../../../../shared/hooks/useAgents";
import { doesAgentVersionSupportResourceListFromATM } from "../../../../../../../shared/utils/agent/agent";
import { ResourceListTable } from "../../../../../../common/ResourceListTable/ResourceListTable";
import InspectionUpgradeRequired from "../../../../../../Inspection/InspectionUpgradeRequired";
import { Panel } from "../../../../../../Inspection/styles";
import { SelfSupplyingResourceListTableProps } from "../types";
import { useK8sResources } from "../../../../../../../shared/hooks/useK8sResources";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const ServicesListTable: React.FC<
  SelfSupplyingResourceListTableProps
> = ({
  serviceId,
  cluster,
  rowSelection,
  namespace,
  eventsDetectionTimeframe,
  searchTerm,
  showOnlySelectedRows = false,
  ...props
}) => {
  const agentId = useActiveAgent(cluster);
  const { agentInfo } = useAgentPropertiesById(agentId ?? "");

  const { refresh, result, rows, isLoading } = useK8sResources({
    cluster,
    serviceId,
    namespace,
  });

  const filteredRows = filterResourceTableBySearchTerm(rows, searchTerm);
  const unselectableRowNames = getUnselectableRowNames(rows, rowSelection);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("relatedResourcesK8sServicesData", !!isLoading),
    [isLoading, reportLoadingState]
  );

  if (!doesAgentVersionSupportResourceListFromATM(agentInfo)) {
    return (
      <Panel>
        <InspectionUpgradeRequired />
      </Panel>
    );
  }

  return (
    <ResourceListTable
      cluster={cluster}
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredRows, rowSelection?.selectedRowNames ?? [])
          : filteredRows
      }
      refreshResults={refresh}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      loadingProps={{ isLoading }}
      isPaginating={result?.isPaginating}
      errorMessage={result?.failureMessage ?? undefined}
      {...props}
    />
  );
};
