import type { DrawerProps } from "@/shared/store/drawersStackStore/types";
import { KlaudiaChatDemoDrawer } from "@/components/KlaudiaChat/demo/KlaudiaChatDemoDrawer";

export type KlaudiaChatProps = {
  sessionId: string;
};

type KlaudiaChatDrawerProps = DrawerProps & KlaudiaChatProps;
export const KlaudiaChatDrawer: React.FC<KlaudiaChatDrawerProps> = ({
  open,
  onClose,
}) => {
  return <KlaudiaChatDemoDrawer open={open} onClose={onClose} />;
};
