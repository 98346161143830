import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import { KomoBot24 } from "@komodorio/design-system/icons";

import Resource from "../../ResourceView/resources";
import { AvailabilityRunWithResults } from "../types";
import { KubernetesNodesResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";
import { KubernetesPVResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPVResource";

import { EnrichedResource } from "./EnrichedResource";
import { EnrichedMessage, TokenType, Token } from "./types";
import { K8sResourceToken, NodeResourceToken } from "./tokens";
import { EnrichedNodeResource } from "./EnrichedNodeResource";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

export interface EnrichedErrorMessageProps {
  enrichedMessage: EnrichedMessage;
  agentId: string;
  cluster: string;
  namespace: string;
  onClick?: (resource: Resource) => void;
  issue?: AvailabilityRunWithResults;
  checks?: BaseWorkflowCheck[];
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: 12px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: ${muiColors.indigo[25]};
`;

const MessageSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  align-self: stretch;
`;

const DefaultValues = ({ index, token }: { index: number; token: Token }) => (
  <Typography key={index} variant={"body1"}>
    {token.getValue()}
  </Typography>
);

export const EnrichedErrorMessage: React.FC<EnrichedErrorMessageProps> = ({
  enrichedMessage,
  agentId,
  cluster,
  namespace,
  onClick,
  issue,
  checks,
}) => {
  return (
    <Container>
      <KomoBot24 color={muiColors.indigo[500]} />
      <MessageSection>
        {enrichedMessage.messageTokens.map((token, index) => {
          switch (token.getType()) {
            case TokenType.String:
              return <DefaultValues index={index} token={token} />;
            case TokenType.K8sResource:
              return (
                <K8sResourceComponent
                  token={token as K8sResourceToken}
                  agentId={agentId}
                  cluster={cluster}
                  namespace={getNamespace(token as K8sResourceToken, namespace)}
                  onClick={onClick}
                />
              );
            case TokenType.NodeResource:
              return (
                <NodeResourceComponent
                  index={index}
                  token={token as NodeResourceToken}
                  agentId={agentId}
                  cluster={cluster}
                  issue={issue}
                  checks={checks}
                  onClick={onClick}
                />
              );
            default:
              return <DefaultValues index={index} token={token} />;
          }
        })}
      </MessageSection>
    </Container>
  );
};

const K8sResourceComponent = ({
  token,
  agentId,
  cluster,
  namespace,
  onClick,
}: {
  token: K8sResourceToken;
  agentId: string;
  cluster: string;
  namespace: string;
  onClick?: (resource: Resource) => void;
}) => {
  const kind = token.getResourceKind();
  const name = token.getResourceName();

  if (!kind || !name) {
    return null;
  }

  return (
    <EnrichedResource
      value={token.getValue()}
      resourceKind={kind}
      resourceName={name}
      agentId={agentId}
      cluster={cluster}
      namespace={namespace}
      onClick={onClick}
    />
  );
};

const NodeResourceComponent = ({
  index,
  token,
  agentId,
  cluster,
  onClick,
  issue,
  checks,
}: {
  index: number;
  token: NodeResourceToken;
  agentId: string;
  cluster: string;
  issue?: AvailabilityRunWithResults;
  checks?: BaseWorkflowCheck[];
  onClick?: (resource: Resource) => void;
}) => {
  if (!issue && !checks) {
    return <DefaultValues index={index} token={token} />;
  }

  return (
    <EnrichedNodeResource
      index={index}
      token={token}
      agentId={agentId}
      cluster={cluster}
      issue={issue}
      checks={checks}
      onClick={onClick}
    />
  );
};

const getNamespace = (token: K8sResourceToken, namespace: string) => {
  if (
    [KubernetesNodesResource.Kind, KubernetesPVResource.Kind].includes(
      token.getResourceKind() ?? ""
    )
  ) {
    return "";
  }
  return namespace;
};
