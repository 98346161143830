import React from "react";
import styled from "styled-components";
import { muiPalette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Komo from "../../../../../../shared/assets/komo.svg?react";
import { appViewAriaLabels } from "../../appViewAriaLabels";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  max-width: 330px;
  text-align: center;
`;

const StyledTitle = styled(Typography)`
  && {
    margin-bottom: 10px;
  }
`;

type ApplicationScopingErrorMessageProps = {
  cta: React.ReactNode | string;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

const {
  applicationsScopingErrorPage: { errorMessage: errorMessageAriaLabel },
} = appViewAriaLabels;

export const ApplicationScopingErrorMessage: React.FC<
  ApplicationScopingErrorMessageProps
> = ({ cta, subtitle, title, buttonText, onButtonClick: buttonCallback }) => {
  return (
    <Container aria-label={errorMessageAriaLabel}>
      <ContentContainer>
        <Komo />
        {title && <StyledTitle variant={"h4"}>{title}</StyledTitle>}
        {subtitle && (
          <Typography variant={"body2"} color={muiPalette.text?.secondary}>
            {subtitle}
          </Typography>
        )}
        <p />
        <Typography variant={"body2"} color={muiPalette.text?.secondary}>
          {cta}
        </Typography>
        {buttonText && buttonCallback && (
          <Button
            color="primary"
            variant="contained"
            onClick={buttonCallback}
            sx={{ marginTop: "24px" }}
          >
            {buttonText}
          </Button>
        )}
      </ContentContainer>
    </Container>
  );
};
