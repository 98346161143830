import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { WorkflowsRoutes } from "./types";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { K8S_ADDONS_WORKFLOWS_ROUTE } from "@/components/routes/routes";

type KomodorWorkflowId = {
  cluster: string;
  namespace: string;
  engine: string;
  dagId: string;
};
export const parseKomodorWorkflowId = (
  komodorWorkflowId: string
): KomodorWorkflowId => {
  const [cluster, namespace, engine, dagId] = komodorWorkflowId.split("|");
  return {
    cluster,
    namespace,
    engine,
    dagId,
  };
};

export const buildKomodorWorkflowId = ({
  cluster,
  namespace,
  engine,
  dagId,
}: KomodorWorkflowId): string => {
  return `${cluster}|${namespace}|${engine}|${dagId}`;
};

export const useWorkflowRoute = (): WorkflowsRoutes | undefined => {
  const { sparkWorkflows: sparkWorkflowsFF } = useOverridableFlags();
  const location = useLocation();
  const routes = useMemo(
    () => [
      { route: WorkflowsRoutes.airflow, condition: true },
      { route: WorkflowsRoutes.spark, condition: !!sparkWorkflowsFF },
      { route: WorkflowsRoutes.argo, condition: true },
      { route: WorkflowsRoutes.custom, condition: true },
    ],
    [sparkWorkflowsFF]
  );

  return useMemo(() => {
    for (const { route, condition } of routes) {
      if (
        condition &&
        location.pathname.includes(`${K8S_ADDONS_WORKFLOWS_ROUTE}/${route}`)
      ) {
        return route;
      }
    }

    return undefined;
  }, [location.pathname, routes]);
};
