import { useMemo } from "react";

import { useGetDeletedPod } from "@/components/common/ProcessList/details/PodPhaseDetails/useGetDeletedPod";
import { PodStateRequestFieldsEnum } from "@/generated/resourcesApi";
import { useGetPodsState } from "@/shared/hooks/resources-api/client/useGetPodsState";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { KubernetesPodsResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import useParsedResource from "@/shared/hooks/resources/useParsedResource";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import Pod from "@/components/ResourceView/resources/pod";
import DeletedPod from "@/components/ResourceView/resources/deletedPod";

export interface PodResourceResult {
  podResource: Pod | DeletedPod | undefined;
  podResourceData:
    | {
        controlledBy?: string;
        nodeName?: string;
        labels?: Record<string, string>;
      }
    | undefined;
}
export const useGetPodResourceData = ({
  fromEpoch,
  toEpoch,
  clusterName,
  namespace,
  podName,
  podStatus,
  labels,
}: {
  fromEpoch?: string;
  toEpoch?: string;
  clusterName: string;
  namespace: string;
  podName: string;
  podStatus?: string;
  labels?: Record<string, string>;
}): PodResourceResult => {
  const podsStateResponse = useGetPodsState({
    clusterName,
    namespace,
    komodorUid: buildKomodorUid({
      clusterName,
      namespace,
      resourceName: podName,
      kind: KubernetesPodsResource.Kind,
    }),
    fields: [PodStateRequestFieldsEnum.Json],
    labels,
    limit: 1,
  });
  const livePod = podsStateResponse?.data?.data?.json?.[0];
  const agentId = useActiveAgent(clusterName);
  const parsedPodResource = useParsedResource(
    livePod,
    KubernetesPodsResource.Kind,
    clusterName,
    agentId ?? "",
    ""
  );

  const deletedResource = useGetDeletedPod({
    clusterName,
    namespace,
    podName,
    fromEpoch,
    toEpoch,
    status: podStatus,
    labels,
    enabled: !livePod,
  });
  return useMemo(() => {
    return {
      podResource: (parsedPodResource as Pod | undefined) || deletedResource,
      podResourceData:
        livePod || deletedResource
          ? {
              controlledBy:
                livePod?.metadata?.ownerReferences?.[0]?.name ??
                deletedResource?.controlledBy,
              nodeName:
                livePod?.spec?.nodeName ??
                (deletedResource?.fullObj.spec?.nodeName as string | undefined),
              labels: livePod?.metadata?.labels ?? deletedResource?.labels,
            }
          : undefined,
    };
  }, [parsedPodResource, livePod, deletedResource]);
};
