import styled, { css } from "styled-components";

const cellPadding = css`
  && {
    padding: 8px 12px;
  }
`;

export const TextCell = styled.td`
  white-space: pre-line;
  ${cellPadding}
`;

export const MaxWidthText = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TimeCell = styled.td`
  white-space: nowrap;
  ${cellPadding}
`;
