import { useCallback, useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useGetUserEffectivePermissions } from "@/shared/hooks/auth-service/client/users/useGetUserEffectivePermissions";
import {
  AuthzSelector,
  AuthzSelectorPattern,
  EffectivePermission,
  User,
} from "@/generated/auth";
import { mapObjectsToOptions } from "@/shared/utils/selectInputOptions";

type FilterableEffectivePermission = Omit<
  EffectivePermission,
  "selector" | "selectorPattern"
>;

type EffectivePermissionDataRow = Omit<
  EffectivePermission,
  "selector" | "selectorPattern"
> & { selectorData?: AuthzSelector | AuthzSelectorPattern };

type UseEffectivePermissionsTableDataFunc = ({ user }: { user: User }) => {
  rawData?: EffectivePermission[];
  isFetching: boolean;
  error: unknown;
  uniqueActionNamesOptions: MuiSelectionOption<string>[];
  uniquePolicyNamesOptions: MuiSelectionOption<string>[];
  uniqueRoleNamesOptions: MuiSelectionOption<string>[];
  uniqueClusterNamesOptions: MuiSelectionOption<string>[];
  uniqueNamespaceNamesOptions: MuiSelectionOption<string>[];
  dataFilters: {
    action: MuiSelectionOption<string>[];
    policyName: MuiSelectionOption<string>[];
    roleName: MuiSelectionOption<string>[];
    cluster: MuiSelectionOption<string>[];
    namespace: MuiSelectionOption<string>[];
  };
  setSelectedProperty: (
    property: keyof FilterableEffectivePermission,
    values: MuiSelectionOption<
      EffectivePermission[keyof FilterableEffectivePermission]
    >[]
  ) => void;
  filteredDataRows: EffectivePermissionDataRow[];
};

// mapObjectsToOptions returns a list of generic MuiSelectionOption objects, but eventually it will be returned like a MuiSelectionOption<string>[]
// This function is used to cast the return value of mapObjectsToOptions to MuiSelectionOption<string>[]
const getUniqueOptions = (
  data: EffectivePermission[],
  key: keyof EffectivePermission
) => mapObjectsToOptions(data, key) as MuiSelectionOption<string>[];

export const useEffectivePermissions: UseEffectivePermissionsTableDataFunc = ({
  user,
}) => {
  const { data, isFetching, error } = useGetUserEffectivePermissions(
    {
      id: user.id,
    },
    true,
    true
  );

  const [dataFilters, setDataFilters] = useState<{
    action: MuiSelectionOption<string>[];
    policyName: MuiSelectionOption<string>[];
    roleName: MuiSelectionOption<string>[];
    cluster: MuiSelectionOption<string>[];
    namespace: MuiSelectionOption<string>[];
  }>({
    action: [],
    policyName: [],
    roleName: [],
    cluster: [],
    namespace: [],
  });

  type DataFiltersKey = keyof typeof dataFilters;

  const setSelectedProperty = useCallback(
    (
      property: keyof typeof dataFilters,
      values: MuiSelectionOption<
        EffectivePermission[keyof FilterableEffectivePermission]
      >[]
    ) => {
      setDataFilters((prev) => ({
        ...prev,
        [property]: values,
      }));
    },
    [setDataFilters]
  );

  const filteredRows = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    let filteredData: EffectivePermission[] = [...data];

    Object.keys(dataFilters).forEach((key: string) => {
      if (dataFilters[key as DataFiltersKey].length === 0) {
        return;
      }
      filteredData = filteredData.filter((row) =>
        dataFilters[key as DataFiltersKey].some(
          (filter) => filter.value === row[key as DataFiltersKey]
        )
      );
    });

    return filteredData;
  }, [dataFilters, data]);

  const {
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
  } = useMemo(() => {
    if (!filteredRows?.length) {
      return {
        uniqueActionNamesOptions: [],
        uniquePolicyNamesOptions: [],
        uniqueRoleNamesOptions: [],
        uniqueClusterNamesOptions: [],
        uniqueNamespaceNamesOptions: [],
      };
    }
    return {
      uniqueActionNamesOptions: getUniqueOptions(filteredRows, "action"),
      uniquePolicyNamesOptions: getUniqueOptions(filteredRows, "policyName"),
      uniqueRoleNamesOptions: getUniqueOptions(filteredRows, "roleName"),
      uniqueClusterNamesOptions: getUniqueOptions(filteredRows, "cluster"),
      uniqueNamespaceNamesOptions: getUniqueOptions(filteredRows, "namespace"),
    };
  }, [filteredRows]);

  const formattedFilteredRows: EffectivePermissionDataRow[] = useMemo(() => {
    if (!filteredRows?.length) {
      return [];
    }

    return filteredRows.map((row) => {
      return {
        ...row,
        selectorData: row.selector ?? row.selectorPattern,
      };
    });
  }, [filteredRows]);

  return {
    rawData: data,
    isFetching,
    error,
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
    dataFilters,
    setSelectedProperty,
    filteredDataRows: formattedFilteredRows,
  };
};
