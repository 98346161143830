import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  getTrackedKeysUrl,
  TrackedKey,
  TrackedKeysApiGetTrackedKeysRequest,
} from "@/generated/accounts";
import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";

export const TRACKED_KEYS_PATH = "/tracked-keys";

const getTrackedKeys = async (
  apiClient: AxiosInstance,
  params: TrackedKeysApiGetTrackedKeysRequest
): Promise<TrackedKey[]> => {
  const { data } = await apiClient.get(
    getTrackedKeysUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetTrackedKeys = ({
  enabled,
  params = { withDeleted: false },
}: {
  enabled?: boolean;
  params?: TrackedKeysApiGetTrackedKeysRequest;
}): UseQueryResult<TrackedKey[], unknown> => {
  const apiClient = useAccountsApiClient();
  return useQuery(
    [TRACKED_KEYS_PATH, params],
    () => getTrackedKeys(apiClient, params),
    {
      enabled,
    }
  );
};
