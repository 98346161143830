import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styled from "styled-components";

import { overProvisionedAriaLabels } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProviosionedAriaLabel";
import { NodesTableGroupBy } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/OverProvisionedCluster/OverProvisionedDataTypes";

const MarginedSelect = styled(SingleSelect)`
  margin-left: 6px;
`;
const NodeGroupBySelectorContainer = styled(Stack)`
  margin-bottom: 8px;
`;

const nodeGroupBySelectItems: Record<
  NodesTableGroupBy,
  MuiSelectionOption<MuiSelectionOptionValue>
> = {
  [NodesTableGroupBy.Nodes]: {
    label: "Node type",
    value: NodesTableGroupBy.Nodes,
  },
  [NodesTableGroupBy.NodePools]: {
    label: "Node pools",
    value: NodesTableGroupBy.NodePools,
  },
};

type OverProvisionedNodeGroupBySelectorProps = {
  selectedValue: NodesTableGroupBy;
  onSelectedValueChange: (value: NodesTableGroupBy) => void;
};

export const OverProvisionedNodeGroupBySelector: React.FC<
  OverProvisionedNodeGroupBySelectorProps
> = ({ selectedValue, onSelectedValueChange }) => {
  return (
    <NodeGroupBySelectorContainer direction="row">
      <Typography variant="h5">Group nodes by </Typography>
      <MarginedSelect
        ariaLabel={overProvisionedAriaLabels.dataGroupByToggle}
        size="small"
        options={Object.values(nodeGroupBySelectItems)}
        value={nodeGroupBySelectItems[selectedValue]}
        onChange={(event) => {
          if (event) {
            onSelectedValueChange(event.value as NodesTableGroupBy);
          }
        }}
      />
    </NodeGroupBySelectorContainer>
  );
};
