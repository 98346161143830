import { useCallback } from "react";

import { useLocalStorage } from "@/shared/hooks/localStorage/useLocalStorage";
import {
  defaultLogsViewerSettings,
  logsViewerSettingsInLsItem,
  LogsViewerLocalStorageState,
} from "@/components/common/LogsViewer/localStorage/logsViewerLocalStorage";

export const useLogsViewerInLocalStorageState = () => {
  return useLocalStorage<LogsViewerLocalStorageState>({
    localStorageItem: logsViewerSettingsInLsItem,
    defaultValue: defaultLogsViewerSettings,
  });
};

export const useLogsViewerInLocalStorage = (): [
  LogsViewerLocalStorageState,
  (newSettings: Partial<LogsViewerLocalStorageState>) => void
] => {
  const [settings, setSettings] = useLogsViewerInLocalStorageState();

  const onSettingsChange = useCallback(
    (newSettings: Partial<LogsViewerLocalStorageState>) => {
      const areSettingsChanged = Object.keys(newSettings).some((key) => {
        const stateKey = key as keyof LogsViewerLocalStorageState;
        return newSettings[stateKey] !== settings[stateKey];
      });

      if (!areSettingsChanged) {
        return;
      }

      const combinedSettings = {
        ...settings,
        ...newSettings,
      };

      setSettings(JSON.stringify(combinedSettings));
    },
    [setSettings, settings]
  );

  return [settings, onSettingsChange];
};
