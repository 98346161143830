import React, { useCallback, useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  ConfirmationDialog,
  TextInput,
} from "@komodorio/design-system/komodor-ui";
import { muiTheme } from "@komodorio/design-system";

import { ApiKeysTTLFeature } from "@/components/Settings/ApiKeys/types";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useUpdateAccountPublicAPIKey } from "@/shared/hooks/accounts-service/client/features/useUpdatePublicKeyTTLFeature";
import { disabledGray } from "@/Colors";
import { apiKeysAriaLabels } from "@/components/Settings/ApiKeys/apiKeysAriaLabels";
import { Result } from "@/components/Actions/common/styles";

export const ConfigKeyModal: React.FC<{
  handleClose: () => void;
  refreshKeys: () => void;
  refreshFeatures: () => void;
}> = ({ handleClose, refreshKeys, refreshFeatures }) => {
  const { data: features } = useAccountFeatures();
  const [error, setError] = useState<string | undefined>();
  const [apiKeysTTLFeature, setApiKeysTTLFeature] = useState<ApiKeysTTLFeature>(
    features.apiKeysTTL ?? { enabled: false, TTLInDays: 1 }
  );

  const { mutateAsync: updateTTL } = useUpdateAccountPublicAPIKey();

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setApiKeysTTLFeature((prev) => ({
        ...prev,
        enabled: event.target.checked,
      }));
    },
    []
  );

  const handleTTLChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setApiKeysTTLFeature((prev) => ({
        ...prev,
        TTLInDays: Number(event.target.value),
      }));
      setError(undefined);
    },
    []
  );
  const handleSave = useCallback(
    async (confirmed: boolean) => {
      try {
        if (confirmed) {
          await updateTTL({
            accountFeatureApiKeysTTL: {
              apiKeysTTL: { ...apiKeysTTLFeature },
            },
          });
          refreshFeatures();
          refreshKeys();
        }
        handleClose();
      } catch (e) {
        setError(
          "Encountered an issue while updating the API key expiration. Please retry or contact support for assistance."
        );
      }
    },
    [updateTTL, refreshFeatures, refreshKeys, handleClose, apiKeysTTLFeature]
  );

  const dialogContent = useMemo(() => {
    const disabledSx = {
      color: !apiKeysTTLFeature.enabled ? disabledGray : "inherit",
    };
    return (
      <Stack rowGap={2} aria-labe={apiKeysAriaLabels.modals.config.modal}>
        <Typography variant="body2">
          <Switch
            aria-label={apiKeysAriaLabels.modals.config.enable}
            checked={apiKeysTTLFeature.enabled}
            onChange={handleSwitchChange}
            sx={{ mr: 1 }}
          />
          Turn on API key expiration time (TTL)
        </Typography>
        <Box>
          <Typography variant="h5" sx={disabledSx}>
            API Time-to-live:
          </Typography>
          <Stack
            maxWidth={120}
            alignItems={"center"}
            direction={"row"}
            sx={disabledSx}
            columnGap={1}
          >
            <TextInput
              error={apiKeysTTLFeature.TTLInDays < 1}
              aria-label={apiKeysAriaLabels.modals.config.days}
              inputProps={{ type: "number", min: 1 }}
              disabled={!apiKeysTTLFeature.enabled}
              value={apiKeysTTLFeature.TTLInDays}
              onChange={handleTTLChange}
            />
            <Typography variant={"body2"} sx={{ color: "inherit" }}>
              Days
            </Typography>
          </Stack>
        </Box>
        {error && (
          <Result>
            <Typography color={muiTheme.palette.error.main}>{error}</Typography>
          </Result>
        )}
      </Stack>
    );
  }, [apiKeysTTLFeature, handleSwitchChange, handleTTLChange, error]);
  return (
    <ConfirmationDialog
      title={"API Key Expiration"}
      content={dialogContent}
      onClose={handleSave}
      height={"unset"}
    />
  );
};
