import { RbacPolicy } from "@/generated/auth";

export type OnEdit = (policy: RbacPolicy) => void;
export type OnDelete = (policy: RbacPolicy) => void;

export enum Scope {
  cluster = "cluster",
  namespace = "namespace",
  cwr = "cwr",
  "komodor-actions" = "komodor-actions",
}
