import React from "react";

import { IconProps } from "../types";

export const Namespaces24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18.2222V20C2 21.1046 2.89543 22 4 22H5.77778V20H4L4 18.2222H2ZM5.77778 2H4C2.89543 2 2 2.89543 2 4V5.77778H4V4L5.77778 4V2ZM2 16.4444H4V12.8889H2V16.4444ZM2 11.1111H4V7.55556H2V11.1111ZM7.55556 2V4H11.1111V2H7.55556ZM12.8889 2V4H16.4444V2H12.8889ZM18.2222 2V4H20V5.77778H22V4C22 2.89543 21.1046 2 20 2H18.2222ZM22 7.55556H20V11.1111H22V7.55556ZM22 12.8889H20V16.4444H22V12.8889ZM22 18.2222H20V20H18.2222V22H20C21.1046 22 22 21.1046 22 20V18.2222ZM16.4444 22V20H12.8889V22H16.4444ZM11.1111 22V20H7.55556V22H11.1111Z"
      />
    </svg>
  );
};

Namespaces24.defaultProps = {
  fill: "currentColor",
};
