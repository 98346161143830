/* tslint:disable */
/* eslint-disable */
/**
 * Actions API
 * This is an API that exposes actions related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {ActionType}
     * @memberof Action
     */
    'type': ActionType;
    /**
     * 
     * @type {Resource}
     * @memberof Action
     */
    'resource': Resource;
    /**
     * 
     * @type {ActionAllOfMetadata}
     * @memberof Action
     */
    'metadata'?: ActionAllOfMetadata;
}




/**
 * Check if a given object implements the Action interface.
 */
export function instanceOfAction(value: object): value is Action {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('resource' in value) || value['resource'] === undefined) return false;
    return true;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ActionTypeFromJSON(json['type']),
        'resource': ResourceFromJSON(json['resource']),
        'metadata': json['metadata'] == null ? undefined : ActionAllOfMetadataFromJSON(json['metadata']),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ActionTypeToJSON(value['type']),
        'resource': ResourceToJSON(value['resource']),
        'metadata': ActionAllOfMetadataToJSON(value['metadata']),
    };
}

        /**
 * 
 * @export
 * @interface ActionAllOf
 */
export interface ActionAllOf {
    /**
     * 
     * @type {ActionType}
     * @memberof ActionAllOf
     */
    'type': ActionType;
    /**
     * 
     * @type {ActionAllOfMetadata}
     * @memberof ActionAllOf
     */
    'metadata'?: ActionAllOfMetadata;
    /**
     * 
     * @type {Resource}
     * @memberof ActionAllOf
     */
    'resource': Resource;
}




/**
 * Check if a given object implements the ActionAllOf interface.
 */
export function instanceOfActionAllOf(value: object): value is ActionAllOf {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('resource' in value) || value['resource'] === undefined) return false;
    return true;
}

export function ActionAllOfFromJSON(json: any): ActionAllOf {
    return ActionAllOfFromJSONTyped(json, false);
}

export function ActionAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ActionTypeFromJSON(json['type']),
        'metadata': json['metadata'] == null ? undefined : ActionAllOfMetadataFromJSON(json['metadata']),
        'resource': ResourceFromJSON(json['resource']),
    };
}

export function ActionAllOfToJSON(value?: ActionAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ActionTypeToJSON(value['type']),
        'metadata': ActionAllOfMetadataToJSON(value['metadata']),
        'resource': ResourceToJSON(value['resource']),
    };
}

        /**
 * 
 * @export
 * @interface ActionAllOfMetadata
 */
export interface ActionAllOfMetadata {
    /**
     * 
     * @type {number}
     * @memberof ActionAllOfMetadata
     */
    'newValue': number;
}


/**
 * Check if a given object implements the ActionAllOfMetadata interface.
 */
export function instanceOfActionAllOfMetadata(value: object): value is ActionAllOfMetadata {
    if (!('newValue' in value) || value['newValue'] === undefined) return false;
    return true;
}

export function ActionAllOfMetadataFromJSON(json: any): ActionAllOfMetadata {
    return ActionAllOfMetadataFromJSONTyped(json, false);
}

export function ActionAllOfMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionAllOfMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'newValue': json['newValue'],
    };
}

export function ActionAllOfMetadataToJSON(value?: ActionAllOfMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newValue': value['newValue'],
    };
}

        /**
 * 
 * @export
 * @interface ActionCommandReport
 */
export interface ActionCommandReport {
    /**
     * 
     * @type {string}
     * @memberof ActionCommandReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionCommandReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof ActionCommandReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {string}
     * @memberof ActionCommandReport
     */
    'commandResult': string;
}


/**
 * Check if a given object implements the ActionCommandReport interface.
 */
export function instanceOfActionCommandReport(value: object): value is ActionCommandReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('commandResult' in value) || value['commandResult'] === undefined) return false;
    return true;
}

export function ActionCommandReportFromJSON(json: any): ActionCommandReport {
    return ActionCommandReportFromJSONTyped(json, false);
}

export function ActionCommandReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionCommandReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'commandResult': json['commandResult'],
    };
}

export function ActionCommandReportToJSON(value?: ActionCommandReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'commandResult': value['commandResult'],
    };
}

        /**
 * 
 * @export
 * @interface ActionCommandReportAllOf
 */
export interface ActionCommandReportAllOf {
    /**
     * 
     * @type {string}
     * @memberof ActionCommandReportAllOf
     */
    'commandResult': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionCommandReportAllOf
     */
    'isSuccessful'?: boolean;
}


/**
 * Check if a given object implements the ActionCommandReportAllOf interface.
 */
export function instanceOfActionCommandReportAllOf(value: object): value is ActionCommandReportAllOf {
    if (!('commandResult' in value) || value['commandResult'] === undefined) return false;
    return true;
}

export function ActionCommandReportAllOfFromJSON(json: any): ActionCommandReportAllOf {
    return ActionCommandReportAllOfFromJSONTyped(json, false);
}

export function ActionCommandReportAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionCommandReportAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'commandResult': json['commandResult'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
    };
}

export function ActionCommandReportAllOfToJSON(value?: ActionCommandReportAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'commandResult': value['commandResult'],
        'isSuccessful': value['isSuccessful'],
    };
}

        /**
 * 
 * @export
 * @interface ActionResult
 */
export interface ActionResult {
    /**
     * 
     * @type {ActionType}
     * @memberof ActionResult
     */
    'type': ActionType;
    /**
     * 
     * @type {Resource}
     * @memberof ActionResult
     */
    'resource': Resource;
    /**
     * 
     * @type {string}
     * @memberof ActionResult
     */
    'id': string;
    /**
     * 
     * @type {ActionState}
     * @memberof ActionResult
     */
    'state': ActionState;
    /**
     * 
     * @type {string}
     * @memberof ActionResult
     */
    'failureMessage'?: string;
    /**
     * 
     * @type {object}
     * @memberof ActionResult
     */
    'metadata': object;
}




/**
 * Check if a given object implements the ActionResult interface.
 */
export function instanceOfActionResult(value: object): value is ActionResult {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('resource' in value) || value['resource'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    return true;
}

export function ActionResultFromJSON(json: any): ActionResult {
    return ActionResultFromJSONTyped(json, false);
}

export function ActionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionResult {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ActionTypeFromJSON(json['type']),
        'resource': ResourceFromJSON(json['resource']),
        'id': json['id'],
        'state': ActionStateFromJSON(json['state']),
        'failureMessage': json['failureMessage'] == null ? undefined : json['failureMessage'],
        'metadata': json['metadata'],
    };
}

export function ActionResultToJSON(value?: ActionResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ActionTypeToJSON(value['type']),
        'resource': ResourceToJSON(value['resource']),
        'id': value['id'],
        'state': ActionStateToJSON(value['state']),
        'failureMessage': value['failureMessage'],
        'metadata': value['metadata'],
    };
}

        /**
 * 
 * @export
 * @interface ActionResultAllOf
 */
export interface ActionResultAllOf {
    /**
     * 
     * @type {string}
     * @memberof ActionResultAllOf
     */
    'id': string;
    /**
     * 
     * @type {ActionState}
     * @memberof ActionResultAllOf
     */
    'state': ActionState;
    /**
     * 
     * @type {string}
     * @memberof ActionResultAllOf
     */
    'failureMessage'?: string;
    /**
     * 
     * @type {object}
     * @memberof ActionResultAllOf
     */
    'metadata': object;
}




/**
 * Check if a given object implements the ActionResultAllOf interface.
 */
export function instanceOfActionResultAllOf(value: object): value is ActionResultAllOf {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    return true;
}

export function ActionResultAllOfFromJSON(json: any): ActionResultAllOf {
    return ActionResultAllOfFromJSONTyped(json, false);
}

export function ActionResultAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionResultAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': ActionStateFromJSON(json['state']),
        'failureMessage': json['failureMessage'] == null ? undefined : json['failureMessage'],
        'metadata': json['metadata'],
    };
}

export function ActionResultAllOfToJSON(value?: ActionResultAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'state': ActionStateToJSON(value['state']),
        'failureMessage': value['failureMessage'],
        'metadata': value['metadata'],
    };
}

        
/**
 * 
 * @export
 */
export const ActionState = {
    Initiated: 'initiated',
    InProgress: 'in-progress',
    Completed: 'completed',
    Timeout: 'timeout'
} as const;
export type ActionState = typeof ActionState[keyof typeof ActionState];


export function instanceOfActionState(value: any): boolean {
    for (const key in ActionState) {
        if (Object.prototype.hasOwnProperty.call(ActionState, key)) {
            if (ActionState[key as keyof typeof ActionState] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ActionStateFromJSON(json: any): ActionState {
    return ActionStateFromJSONTyped(json, false);
}

export function ActionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionState {
    return json as ActionState;
}

export function ActionStateToJSON(value?: ActionState | null): any {
    return value as any;
}

        
/**
 * 
 * @export
 */
export const ActionType = {
    DeleteResource: 'deleteResource',
    RestartService: 'restartService',
    Scale: 'scale',
    ArgoRolloutRestart: 'argoRolloutRestart'
} as const;
export type ActionType = typeof ActionType[keyof typeof ActionType];


export function instanceOfActionType(value: any): boolean {
    for (const key in ActionType) {
        if (Object.prototype.hasOwnProperty.call(ActionType, key)) {
            if (ActionType[key as keyof typeof ActionType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ActionTypeFromJSON(json: any): ActionType {
    return ActionTypeFromJSONTyped(json, false);
}

export function ActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionType {
    return json as ActionType;
}

export function ActionTypeToJSON(value?: ActionType | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface BaseTaskReport
 */
export interface BaseTaskReport {
    /**
     * 
     * @type {string}
     * @memberof BaseTaskReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseTaskReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof BaseTaskReport
     */
    'metadata'?: BaseTaskReportMetadata;
}


/**
 * Check if a given object implements the BaseTaskReport interface.
 */
export function instanceOfBaseTaskReport(value: object): value is BaseTaskReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    return true;
}

export function BaseTaskReportFromJSON(json: any): BaseTaskReport {
    return BaseTaskReportFromJSONTyped(json, false);
}

export function BaseTaskReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseTaskReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
    };
}

export function BaseTaskReportToJSON(value?: BaseTaskReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
    };
}

        /**
* @type BaseTaskReportMetadata
* 
* @export
*/
export type BaseTaskReportMetadata = { type: 'FreeCommandMetadata' } & TaskFreeCommandReportMetadata;


export function BaseTaskReportMetadataFromJSON<T extends keyof BaseTaskReportMetadataTypeMap>(json: any): BaseTaskReportMetadataType<T> {
  const type = json['type'];
  return BaseTaskReportMetadataFromJSONTyped<T>(type, json, false);
}

type BaseTaskReportMetadataTypeMap = {
    'TaskFreeCommandReportMetadata': { type: 'FreeCommandMetadata' } & TaskFreeCommandReportMetadata;
};

type BaseTaskReportMetadataType<T extends keyof BaseTaskReportMetadataTypeMap> = BaseTaskReportMetadataTypeMap[T];

export function BaseTaskReportMetadataFromJSONTyped<T extends keyof BaseTaskReportMetadataTypeMap>(type: T, json: any, ignoreDiscriminator: boolean): BaseTaskReportMetadataTypeMap[T] {
  if (json == null) {
    return json;
  }
  switch (json['type']) {
    case 'FreeCommandMetadata':
      return Object.assign({}, TaskFreeCommandReportMetadataFromJSONTyped(json, true), { type: 'FreeCommandMetadata' } as const) as BaseTaskReportMetadataTypeMap[T];
    default:
      throw new Error(`No variant of BaseTaskReportMetadata exists with 'type=${json['type']}'`);
  }
}

export function BaseTaskReportMetadataToJSON(value?: BaseTaskReportMetadata | null): any {
  if (value == null) {
    return value;
  }
  switch (value['type']) {
    case 'FreeCommandMetadata':
      return TaskFreeCommandReportMetadataToJSON(value);
    default:
      throw new Error(`No variant of BaseTaskReportMetadata exists with 'type=${value['type']}'`);
  }

}

        /**
 * 
 * @export
 * @interface BulkPodLogsReport
 */
export interface BulkPodLogsReport {
    /**
     * 
     * @type {string}
     * @memberof BulkPodLogsReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof BulkPodLogsReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof BulkPodLogsReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {Array<SinglePodLogReport>}
     * @memberof BulkPodLogsReport
     */
    'data': Array<SinglePodLogReport>;
}


/**
 * Check if a given object implements the BulkPodLogsReport interface.
 */
export function instanceOfBulkPodLogsReport(value: object): value is BulkPodLogsReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BulkPodLogsReportFromJSON(json: any): BulkPodLogsReport {
    return BulkPodLogsReportFromJSONTyped(json, false);
}

export function BulkPodLogsReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkPodLogsReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'data': ((json['data'] as Array<any>).map(SinglePodLogReportFromJSON)),
    };
}

export function BulkPodLogsReportToJSON(value?: BulkPodLogsReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'data': ((value['data'] as Array<any>).map(SinglePodLogReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface BulkPodLogsReportAllOf
 */
export interface BulkPodLogsReportAllOf {
    /**
     * 
     * @type {Array<SinglePodLogReport>}
     * @memberof BulkPodLogsReportAllOf
     */
    'data': Array<SinglePodLogReport>;
}


/**
 * Check if a given object implements the BulkPodLogsReportAllOf interface.
 */
export function instanceOfBulkPodLogsReportAllOf(value: object): value is BulkPodLogsReportAllOf {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BulkPodLogsReportAllOfFromJSON(json: any): BulkPodLogsReportAllOf {
    return BulkPodLogsReportAllOfFromJSONTyped(json, false);
}

export function BulkPodLogsReportAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkPodLogsReportAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(SinglePodLogReportFromJSON)),
    };
}

export function BulkPodLogsReportAllOfToJSON(value?: BulkPodLogsReportAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(SinglePodLogReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ExternalDNSReport
 */
export interface ExternalDNSReport {
    /**
     * 
     * @type {string}
     * @memberof ExternalDNSReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalDNSReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof ExternalDNSReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {Array<SingleExternalDNSReport>}
     * @memberof ExternalDNSReport
     */
    'commandResult': Array<SingleExternalDNSReport>;
}


/**
 * Check if a given object implements the ExternalDNSReport interface.
 */
export function instanceOfExternalDNSReport(value: object): value is ExternalDNSReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('commandResult' in value) || value['commandResult'] === undefined) return false;
    return true;
}

export function ExternalDNSReportFromJSON(json: any): ExternalDNSReport {
    return ExternalDNSReportFromJSONTyped(json, false);
}

export function ExternalDNSReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalDNSReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'commandResult': ((json['commandResult'] as Array<any>).map(SingleExternalDNSReportFromJSON)),
    };
}

export function ExternalDNSReportToJSON(value?: ExternalDNSReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'commandResult': ((value['commandResult'] as Array<any>).map(SingleExternalDNSReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ExternalDNSReportAllOf
 */
export interface ExternalDNSReportAllOf {
    /**
     * 
     * @type {Array<SingleExternalDNSReport>}
     * @memberof ExternalDNSReportAllOf
     */
    'commandResult': Array<SingleExternalDNSReport>;
}


/**
 * Check if a given object implements the ExternalDNSReportAllOf interface.
 */
export function instanceOfExternalDNSReportAllOf(value: object): value is ExternalDNSReportAllOf {
    if (!('commandResult' in value) || value['commandResult'] === undefined) return false;
    return true;
}

export function ExternalDNSReportAllOfFromJSON(json: any): ExternalDNSReportAllOf {
    return ExternalDNSReportAllOfFromJSONTyped(json, false);
}

export function ExternalDNSReportAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalDNSReportAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'commandResult': ((json['commandResult'] as Array<any>).map(SingleExternalDNSReportFromJSON)),
    };
}

export function ExternalDNSReportAllOfToJSON(value?: ExternalDNSReportAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'commandResult': ((value['commandResult'] as Array<any>).map(SingleExternalDNSReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface FreeCommandReport
 */
export interface FreeCommandReport {
    /**
     * 
     * @type {string}
     * @memberof FreeCommandReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof FreeCommandReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof FreeCommandReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {string}
     * @memberof FreeCommandReport
     */
    'commandResult': string;
}


/**
 * Check if a given object implements the FreeCommandReport interface.
 */
export function instanceOfFreeCommandReport(value: object): value is FreeCommandReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('commandResult' in value) || value['commandResult'] === undefined) return false;
    return true;
}

export function FreeCommandReportFromJSON(json: any): FreeCommandReport {
    return FreeCommandReportFromJSONTyped(json, false);
}

export function FreeCommandReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreeCommandReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'commandResult': json['commandResult'],
    };
}

export function FreeCommandReportToJSON(value?: FreeCommandReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'commandResult': value['commandResult'],
    };
}

        /**
 * 
 * @export
 * @interface GetBulkActionsResultsResp
 */
export interface GetBulkActionsResultsResp {
    /**
     * 
     * @type {boolean}
     * @memberof GetBulkActionsResultsResp
     */
    'isInProgress': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBulkActionsResultsResp
     */
    'bulkId': string;
    /**
     * 
     * @type {Array<ActionResult>}
     * @memberof GetBulkActionsResultsResp
     */
    'actions': Array<ActionResult>;
}


/**
 * Check if a given object implements the GetBulkActionsResultsResp interface.
 */
export function instanceOfGetBulkActionsResultsResp(value: object): value is GetBulkActionsResultsResp {
    if (!('isInProgress' in value) || value['isInProgress'] === undefined) return false;
    if (!('bulkId' in value) || value['bulkId'] === undefined) return false;
    if (!('actions' in value) || value['actions'] === undefined) return false;
    return true;
}

export function GetBulkActionsResultsRespFromJSON(json: any): GetBulkActionsResultsResp {
    return GetBulkActionsResultsRespFromJSONTyped(json, false);
}

export function GetBulkActionsResultsRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBulkActionsResultsResp {
    if (json == null) {
        return json;
    }
    return {
        
        'isInProgress': json['isInProgress'],
        'bulkId': json['bulkId'],
        'actions': ((json['actions'] as Array<any>).map(ActionResultFromJSON)),
    };
}

export function GetBulkActionsResultsRespToJSON(value?: GetBulkActionsResultsResp | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isInProgress': value['isInProgress'],
        'bulkId': value['bulkId'],
        'actions': ((value['actions'] as Array<any>).map(ActionResultToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface MinimalAction
 */
export interface MinimalAction {
    /**
     * 
     * @type {ActionType}
     * @memberof MinimalAction
     */
    'type': ActionType;
    /**
     * 
     * @type {Resource}
     * @memberof MinimalAction
     */
    'resource': Resource;
}




/**
 * Check if a given object implements the MinimalAction interface.
 */
export function instanceOfMinimalAction(value: object): value is MinimalAction {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('resource' in value) || value['resource'] === undefined) return false;
    return true;
}

export function MinimalActionFromJSON(json: any): MinimalAction {
    return MinimalActionFromJSONTyped(json, false);
}

export function MinimalActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinimalAction {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ActionTypeFromJSON(json['type']),
        'resource': ResourceFromJSON(json['resource']),
    };
}

export function MinimalActionToJSON(value?: MinimalAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ActionTypeToJSON(value['type']),
        'resource': ResourceToJSON(value['resource']),
    };
}

        /**
 * 
 * @export
 * @interface MinimalAgentTask
 */
export interface MinimalAgentTask {
    /**
     * 
     * @type {string}
     * @memberof MinimalAgentTask
     */
    'id': string;
    /**
     * 
     * @type {TaskType}
     * @memberof MinimalAgentTask
     */
    'type': TaskType;
    /**
     * 
     * @type {object}
     * @memberof MinimalAgentTask
     */
    'data': object;
    /**
     * 
     * @type {object}
     * @memberof MinimalAgentTask
     */
    'tracingMetadata': object;
    /**
     * 
     * @type {string}
     * @memberof MinimalAgentTask
     */
    'createdAt': string;
}




/**
 * Check if a given object implements the MinimalAgentTask interface.
 */
export function instanceOfMinimalAgentTask(value: object): value is MinimalAgentTask {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('tracingMetadata' in value) || value['tracingMetadata'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function MinimalAgentTaskFromJSON(json: any): MinimalAgentTask {
    return MinimalAgentTaskFromJSONTyped(json, false);
}

export function MinimalAgentTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinimalAgentTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': TaskTypeFromJSON(json['type']),
        'data': json['data'],
        'tracingMetadata': json['tracingMetadata'],
        'createdAt': json['createdAt'],
    };
}

export function MinimalAgentTaskToJSON(value?: MinimalAgentTask | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': TaskTypeToJSON(value['type']),
        'data': value['data'],
        'tracingMetadata': value['tracingMetadata'],
        'createdAt': value['createdAt'],
    };
}

        /**
 * 
 * @export
 * @interface PodListItemReport
 */
export interface PodListItemReport {
    /**
     * 
     * @type {object}
     * @memberof PodListItemReport
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof PodListItemReport
     */
    'spec': object;
    /**
     * 
     * @type {object}
     * @memberof PodListItemReport
     */
    'status': object;
}


/**
 * Check if a given object implements the PodListItemReport interface.
 */
export function instanceOfPodListItemReport(value: object): value is PodListItemReport {
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    if (!('spec' in value) || value['spec'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function PodListItemReportFromJSON(json: any): PodListItemReport {
    return PodListItemReportFromJSONTyped(json, false);
}

export function PodListItemReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodListItemReport {
    if (json == null) {
        return json;
    }
    return {
        
        'metadata': json['metadata'],
        'spec': json['spec'],
        'status': json['status'],
    };
}

export function PodListItemReportToJSON(value?: PodListItemReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metadata': value['metadata'],
        'spec': value['spec'],
        'status': value['status'],
    };
}

        /**
 * 
 * @export
 * @interface PodListReport
 */
export interface PodListReport {
    /**
     * 
     * @type {string}
     * @memberof PodListReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof PodListReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof PodListReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {Array<PodListItemReport>}
     * @memberof PodListReport
     */
    'podList': Array<PodListItemReport>;
}


/**
 * Check if a given object implements the PodListReport interface.
 */
export function instanceOfPodListReport(value: object): value is PodListReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('podList' in value) || value['podList'] === undefined) return false;
    return true;
}

export function PodListReportFromJSON(json: any): PodListReport {
    return PodListReportFromJSONTyped(json, false);
}

export function PodListReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodListReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'podList': ((json['podList'] as Array<any>).map(PodListItemReportFromJSON)),
    };
}

export function PodListReportToJSON(value?: PodListReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'podList': ((value['podList'] as Array<any>).map(PodListItemReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface PodListReportAllOf
 */
export interface PodListReportAllOf {
    /**
     * 
     * @type {Array<PodListItemReport>}
     * @memberof PodListReportAllOf
     */
    'podList': Array<PodListItemReport>;
}


/**
 * Check if a given object implements the PodListReportAllOf interface.
 */
export function instanceOfPodListReportAllOf(value: object): value is PodListReportAllOf {
    if (!('podList' in value) || value['podList'] === undefined) return false;
    return true;
}

export function PodListReportAllOfFromJSON(json: any): PodListReportAllOf {
    return PodListReportAllOfFromJSONTyped(json, false);
}

export function PodListReportAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodListReportAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'podList': ((json['podList'] as Array<any>).map(PodListItemReportFromJSON)),
    };
}

export function PodListReportAllOfToJSON(value?: PodListReportAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'podList': ((value['podList'] as Array<any>).map(PodListItemReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface PodLogsReport
 */
export interface PodLogsReport {
    /**
     * 
     * @type {string}
     * @memberof PodLogsReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof PodLogsReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof PodLogsReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {string}
     * @memberof PodLogsReport
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof PodLogsReport
     */
    'logTime': string;
    /**
     * 
     * @type {Array<SinglePodLogReport>}
     * @memberof PodLogsReport
     */
    'data': Array<SinglePodLogReport>;
}


/**
 * Check if a given object implements the PodLogsReport interface.
 */
export function instanceOfPodLogsReport(value: object): value is PodLogsReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('logTime' in value) || value['logTime'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function PodLogsReportFromJSON(json: any): PodLogsReport {
    return PodLogsReportFromJSONTyped(json, false);
}

export function PodLogsReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodLogsReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'message': json['message'],
        'logTime': json['logTime'],
        'data': ((json['data'] as Array<any>).map(SinglePodLogReportFromJSON)),
    };
}

export function PodLogsReportToJSON(value?: PodLogsReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'message': value['message'],
        'logTime': value['logTime'],
        'data': ((value['data'] as Array<any>).map(SinglePodLogReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ReportActionResultsRequest
 */
export interface ReportActionResultsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportActionResultsRequest
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportActionResultsRequest
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof ReportActionResultsRequest
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {string}
     * @memberof ReportActionResultsRequest
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ReportActionResultsRequest
     */
    'logTime': string;
    /**
     * 
     * @type {Array<SinglePodLogReport>}
     * @memberof ReportActionResultsRequest
     */
    'data': Array<SinglePodLogReport>;
    /**
     * 
     * @type {Array<PodListItemReport>}
     * @memberof ReportActionResultsRequest
     */
    'podList': Array<PodListItemReport>;
    /**
     * 
     * @type {Array<SingleExternalDNSReport>}
     * @memberof ReportActionResultsRequest
     */
    'commandResult': Array<SingleExternalDNSReport>;
}


/**
 * Check if a given object implements the ReportActionResultsRequest interface.
 */
export function instanceOfReportActionResultsRequest(value: object): value is ReportActionResultsRequest {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('logTime' in value) || value['logTime'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('podList' in value) || value['podList'] === undefined) return false;
    if (!('commandResult' in value) || value['commandResult'] === undefined) return false;
    return true;
}

export function ReportActionResultsRequestFromJSON(json: any): ReportActionResultsRequest {
    return ReportActionResultsRequestFromJSONTyped(json, false);
}

export function ReportActionResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportActionResultsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'message': json['message'],
        'logTime': json['logTime'],
        'data': ((json['data'] as Array<any>).map(SinglePodLogReportFromJSON)),
        'podList': ((json['podList'] as Array<any>).map(PodListItemReportFromJSON)),
        'commandResult': ((json['commandResult'] as Array<any>).map(SingleExternalDNSReportFromJSON)),
    };
}

export function ReportActionResultsRequestToJSON(value?: ReportActionResultsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'message': value['message'],
        'logTime': value['logTime'],
        'data': ((value['data'] as Array<any>).map(SinglePodLogReportToJSON)),
        'podList': ((value['podList'] as Array<any>).map(PodListItemReportToJSON)),
        'commandResult': ((value['commandResult'] as Array<any>).map(SingleExternalDNSReportToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ReportActionResultsResp
 */
export interface ReportActionResultsResp {
    /**
     * 
     * @type {string}
     * @memberof ReportActionResultsResp
     */
    'status': ReportActionResultsRespStatusEnum;
}


/**
 * @export
 */
export const ReportActionResultsRespStatusEnum = {
    Ok: 'ok'
} as const;
export type ReportActionResultsRespStatusEnum = typeof ReportActionResultsRespStatusEnum[keyof typeof ReportActionResultsRespStatusEnum];



/**
 * Check if a given object implements the ReportActionResultsResp interface.
 */
export function instanceOfReportActionResultsResp(value: object): value is ReportActionResultsResp {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ReportActionResultsRespFromJSON(json: any): ReportActionResultsResp {
    return ReportActionResultsRespFromJSONTyped(json, false);
}

export function ReportActionResultsRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportActionResultsResp {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function ReportActionResultsRespToJSON(value?: ReportActionResultsResp | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
    };
}

        /**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'name': string;
}


/**
 * Check if a given object implements the Resource interface.
 */
export function instanceOfResource(value: object): value is Resource {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'kind': json['kind'],
        'name': json['name'],
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'namespace': value['namespace'],
        'kind': value['kind'],
        'name': value['name'],
    };
}

        /**
 * 
 * @export
 * @interface ScaleMetadata
 */
export interface ScaleMetadata {
    /**
     * 
     * @type {number}
     * @memberof ScaleMetadata
     */
    'newValue': number;
}


/**
 * Check if a given object implements the ScaleMetadata interface.
 */
export function instanceOfScaleMetadata(value: object): value is ScaleMetadata {
    if (!('newValue' in value) || value['newValue'] === undefined) return false;
    return true;
}

export function ScaleMetadataFromJSON(json: any): ScaleMetadata {
    return ScaleMetadataFromJSONTyped(json, false);
}

export function ScaleMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScaleMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'newValue': json['newValue'],
    };
}

export function ScaleMetadataToJSON(value?: ScaleMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newValue': value['newValue'],
    };
}

        /**
 * 
 * @export
 * @interface SingleExternalDNSReport
 */
export interface SingleExternalDNSReport {
    /**
     * 
     * @type {string}
     * @memberof SingleExternalDNSReport
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof SingleExternalDNSReport
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof SingleExternalDNSReport
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SingleExternalDNSReport
     */
    'dns': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SingleExternalDNSReport
     */
    'resolvedAddrs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SingleExternalDNSReport
     */
    'targetAddrs': Array<string>;
}


/**
 * Check if a given object implements the SingleExternalDNSReport interface.
 */
export function instanceOfSingleExternalDNSReport(value: object): value is SingleExternalDNSReport {
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('dns' in value) || value['dns'] === undefined) return false;
    if (!('resolvedAddrs' in value) || value['resolvedAddrs'] === undefined) return false;
    if (!('targetAddrs' in value) || value['targetAddrs'] === undefined) return false;
    return true;
}

export function SingleExternalDNSReportFromJSON(json: any): SingleExternalDNSReport {
    return SingleExternalDNSReportFromJSONTyped(json, false);
}

export function SingleExternalDNSReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleExternalDNSReport {
    if (json == null) {
        return json;
    }
    return {
        
        'namespace': json['namespace'],
        'kind': json['kind'],
        'name': json['name'],
        'dns': json['dns'],
        'resolvedAddrs': json['resolvedAddrs'],
        'targetAddrs': json['targetAddrs'],
    };
}

export function SingleExternalDNSReportToJSON(value?: SingleExternalDNSReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'namespace': value['namespace'],
        'kind': value['kind'],
        'name': value['name'],
        'dns': value['dns'],
        'resolvedAddrs': value['resolvedAddrs'],
        'targetAddrs': value['targetAddrs'],
    };
}

        /**
 * 
 * @export
 * @interface SinglePodLogReport
 */
export interface SinglePodLogReport {
    /**
     * 
     * @type {string}
     * @memberof SinglePodLogReport
     */
    'task': string;
    /**
     * 
     * @type {boolean}
     * @memberof SinglePodLogReport
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {BaseTaskReportMetadata}
     * @memberof SinglePodLogReport
     */
    'metadata'?: BaseTaskReportMetadata;
    /**
     * 
     * @type {string}
     * @memberof SinglePodLogReport
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SinglePodLogReport
     */
    'logTime': string;
}


/**
 * Check if a given object implements the SinglePodLogReport interface.
 */
export function instanceOfSinglePodLogReport(value: object): value is SinglePodLogReport {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('logTime' in value) || value['logTime'] === undefined) return false;
    return true;
}

export function SinglePodLogReportFromJSON(json: any): SinglePodLogReport {
    return SinglePodLogReportFromJSONTyped(json, false);
}

export function SinglePodLogReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): SinglePodLogReport {
    if (json == null) {
        return json;
    }
    return {
        
        'task': json['task'],
        'isSuccessful': json['isSuccessful'] == null ? undefined : json['isSuccessful'],
        'metadata': json['metadata'] == null ? undefined : BaseTaskReportMetadataFromJSON(json['metadata']),
        'message': json['message'],
        'logTime': json['logTime'],
    };
}

export function SinglePodLogReportToJSON(value?: SinglePodLogReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task': value['task'],
        'isSuccessful': value['isSuccessful'],
        'metadata': BaseTaskReportMetadataToJSON(value['metadata']),
        'message': value['message'],
        'logTime': value['logTime'],
    };
}

        /**
 * 
 * @export
 * @interface SinglePodLogReportAllOf
 */
export interface SinglePodLogReportAllOf {
    /**
     * 
     * @type {string}
     * @memberof SinglePodLogReportAllOf
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SinglePodLogReportAllOf
     */
    'logTime': string;
}


/**
 * Check if a given object implements the SinglePodLogReportAllOf interface.
 */
export function instanceOfSinglePodLogReportAllOf(value: object): value is SinglePodLogReportAllOf {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('logTime' in value) || value['logTime'] === undefined) return false;
    return true;
}

export function SinglePodLogReportAllOfFromJSON(json: any): SinglePodLogReportAllOf {
    return SinglePodLogReportAllOfFromJSONTyped(json, false);
}

export function SinglePodLogReportAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): SinglePodLogReportAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'logTime': json['logTime'],
    };
}

export function SinglePodLogReportAllOfToJSON(value?: SinglePodLogReportAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'logTime': value['logTime'],
    };
}

        /**
 * 
 * @export
 * @interface SubmitBulkActionsRequest
 */
export interface SubmitBulkActionsRequest {
    /**
     * 
     * @type {Array<Action>}
     * @memberof SubmitBulkActionsRequest
     */
    'actions': Array<Action>;
}


/**
 * Check if a given object implements the SubmitBulkActionsRequest interface.
 */
export function instanceOfSubmitBulkActionsRequest(value: object): value is SubmitBulkActionsRequest {
    if (!('actions' in value) || value['actions'] === undefined) return false;
    return true;
}

export function SubmitBulkActionsRequestFromJSON(json: any): SubmitBulkActionsRequest {
    return SubmitBulkActionsRequestFromJSONTyped(json, false);
}

export function SubmitBulkActionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitBulkActionsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'actions': ((json['actions'] as Array<any>).map(ActionFromJSON)),
    };
}

export function SubmitBulkActionsRequestToJSON(value?: SubmitBulkActionsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actions': ((value['actions'] as Array<any>).map(ActionToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface TaskFreeCommandReportMetadata
 */
export interface TaskFreeCommandReportMetadata {
    /**
     * 
     * @type {number}
     * @memberof TaskFreeCommandReportMetadata
     */
    'exitCode': number;
    /**
     * 
     * @type {string}
     * @memberof TaskFreeCommandReportMetadata
     */
    'type': TaskFreeCommandReportMetadataTypeEnum;
}


/**
 * @export
 */
export const TaskFreeCommandReportMetadataTypeEnum = {
    FreeCommand: 'free-command'
} as const;
export type TaskFreeCommandReportMetadataTypeEnum = typeof TaskFreeCommandReportMetadataTypeEnum[keyof typeof TaskFreeCommandReportMetadataTypeEnum];



/**
 * Check if a given object implements the TaskFreeCommandReportMetadata interface.
 */
export function instanceOfTaskFreeCommandReportMetadata(value: object): value is TaskFreeCommandReportMetadata {
    if (!('exitCode' in value) || value['exitCode'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function TaskFreeCommandReportMetadataFromJSON(json: any): TaskFreeCommandReportMetadata {
    return TaskFreeCommandReportMetadataFromJSONTyped(json, false);
}

export function TaskFreeCommandReportMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskFreeCommandReportMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'exitCode': json['exitCode'],
        'type': json['type'],
    };
}

export function TaskFreeCommandReportMetadataToJSON(value?: TaskFreeCommandReportMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'exitCode': value['exitCode'],
        'type': value['type'],
    };
}

        
/**
 * 
 * @export
 */
export const TaskType = {
    PodLogs: 'pod-logs',
    PodDescribe: 'pod-describe',
    PodList: 'pod-list',
    ServiceDescribe: 'service-describe',
    ResourceList: 'resource-list',
    PodMetrics: 'pod-metrics',
    NodeMetrics: 'node-metrics',
    ActionCommand: 'action-command',
    FreeCommand: 'free-command',
    RestartAgent: 'restart-agent',
    ExternalDns: 'external-dns'
} as const;
export type TaskType = typeof TaskType[keyof typeof TaskType];


export function instanceOfTaskType(value: any): boolean {
    for (const key in TaskType) {
        if (Object.prototype.hasOwnProperty.call(TaskType, key)) {
            if (TaskType[key as keyof typeof TaskType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TaskTypeFromJSON(json: any): TaskType {
    return TaskTypeFromJSONTyped(json, false);
}

export function TaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskType {
    return json as TaskType;
}

export function TaskTypeToJSON(value?: TaskType | null): any {
    return value as any;
}

    
        /**
* Get all tasks
* @param params ActionsApiActionsApiV1ActionsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const actionsApiV1ActionsGetUrl = (params: ActionsApiActionsApiV1ActionsGetRequest, baseUrl: string): string => {
    const localVarPath = `/actions/api/v1/actions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.agentId !== undefined) {
            localVarQueryParameter['agentId'] = params.agentId;
    }
    if (params.state !== undefined) {
            localVarQueryParameter['state'] = params.state;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the status and results for all the associated actions to the provided bulkId
* @param params ActionsApiGetBulkActionsResultsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getBulkActionsResultsUrl = (params: ActionsApiGetBulkActionsResultsRequest, baseUrl: string): string => {
    // verify required parameter 'bulkId' is not null or undefined
    assertParamExists('getBulkActionsResults', 'bulkId', params.bulkId)
    const localVarPath = `/actions/api/v1/bulk/{bulkId}`
        .replace(`{${"bulkId"}}`, encodeURIComponent(String(params.bulkId)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Submit batch of actions to run simultaneously
* @param params ActionsApiSubmitBulkActionsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const submitBulkActionsUrl = (params: ActionsApiSubmitBulkActionsRequest, baseUrl: string): string => {
    // verify required parameter 'submitBulkActionsRequest' is not null or undefined
    assertParamExists('submitBulkActions', 'submitBulkActionsRequest', params.submitBulkActionsRequest)
    const localVarPath = `/actions/api/v1/bulk`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for actionsApiV1ActionsGet operation in ActionsApi.
* @export
* @interface ActionsApiActionsApiV1ActionsGetRequest
*/
export interface ActionsApiActionsApiV1ActionsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof ActionsApiActionsApiV1ActionsGet
    */
    readonly agentId?: string

    /**
    * 
    * @type {ActionState}
    * @memberof ActionsApiActionsApiV1ActionsGet
    */
    readonly state?: ActionState
}

/**
* Request parameters for getBulkActionsResults operation in ActionsApi.
* @export
* @interface ActionsApiGetBulkActionsResultsRequest
*/
export interface ActionsApiGetBulkActionsResultsRequest {
    /**
    * 
    * @type {string}
    * @memberof ActionsApiGetBulkActionsResults
    */
    readonly bulkId: string
}

/**
* Request parameters for submitBulkActions operation in ActionsApi.
* @export
* @interface ActionsApiSubmitBulkActionsRequest
*/
export interface ActionsApiSubmitBulkActionsRequest {
    /**
    * 
    * @type {SubmitBulkActionsRequest}
    * @memberof ActionsApiSubmitBulkActions
    */
    readonly submitBulkActionsRequest: SubmitBulkActionsRequest
}


    
        /**
* Report on tasks completion
* @param params AgentApiActionsApiV1ActionsActionIdResultPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const actionsApiV1ActionsActionIdResultPostUrl = (params: AgentApiActionsApiV1ActionsActionIdResultPostRequest, baseUrl: string): string => {
    // verify required parameter 'actionId' is not null or undefined
    assertParamExists('actionsApiV1ActionsActionIdResultPost', 'actionId', params.actionId)
    // verify required parameter 'agentId' is not null or undefined
    assertParamExists('actionsApiV1ActionsActionIdResultPost', 'agentId', params.agentId)
    // verify required parameter 'reportActionResultsRequest' is not null or undefined
    assertParamExists('actionsApiV1ActionsActionIdResultPost', 'reportActionResultsRequest', params.reportActionResultsRequest)
    const localVarPath = `/actions/api/v1/actions/{actionId}/result`
        .replace(`{${"actionId"}}`, encodeURIComponent(String(params.actionId)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.agentId !== undefined) {
            localVarQueryParameter['agentId'] = params.agentId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for actionsApiV1ActionsActionIdResultPost operation in AgentApi.
* @export
* @interface AgentApiActionsApiV1ActionsActionIdResultPostRequest
*/
export interface AgentApiActionsApiV1ActionsActionIdResultPostRequest {
    /**
    * 
    * @type {string}
    * @memberof AgentApiActionsApiV1ActionsActionIdResultPost
    */
    readonly actionId: string

    /**
    * 
    * @type {string}
    * @memberof AgentApiActionsApiV1ActionsActionIdResultPost
    */
    readonly agentId: string

    /**
    * 
    * @type {ReportActionResultsRequest}
    * @memberof AgentApiActionsApiV1ActionsActionIdResultPost
    */
    readonly reportActionResultsRequest: ReportActionResultsRequest
}


    
