import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { TooltipProps } from "@mui/material";
import { muiColors } from "@komodorio/design-system";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

type TooltipPropsWithoutTitle = Omit<TooltipProps, "title">;

export const AskAdminTooltip: React.FC<TooltipPropsWithoutTitle> = ({
  children,
  ...rest
}) => {
  const content = (
    <Container>
      <Typography variant="h4" color={muiColors.common.black}>
        Automate root cause detection with Komodor AI
      </Typography>
      <Typography variant="body2" color={muiColors.common.black}>
        Contact your Komodor account admin to enable this feature.
      </Typography>
    </Container>
  );

  return (
    <LightTooltip arrow={false} {...rest} title={content} children={children} />
  );
};
