import {
  Array as RTArray,
  Boolean,
  Partial,
  Record,
  Static,
  String,
} from "runtypes";

import { Agent } from "@/generated/agents";

export const ConfigurationType = Partial({
  appmetadata: Partial({
    version: String,
  }),
  watchnamespace: String,
  enableagenttaskexecution: Boolean,
  enableagenttaskexecutionv2: Boolean.optional(),
  enablehelm: Boolean.optional(),
  daemon: Partial({
    enabled: Boolean,
  }),
  networkmapper: Partial({
    enable: Boolean,
  }),
  actions: Partial({
    basic: Boolean,
    advanced: Boolean,
    podexec: Boolean,
    portforward: Boolean,
  }),
  resources: Partial({
    job: Boolean,
    cronjob: Boolean,
    secret: Boolean,
    argoworkflows: Partial({
      cronworkflows: Boolean,
      workflows: Boolean,
      workflowtemplates: Boolean,
      clusterworkflowtemplates: Boolean,
    }),
  }),
  chartversion: String.optional(),
  monitoringproviders: RTArray(
    Record({
      type: String,
    })
  ).optional(),
});
export type Configuration = Static<typeof ConfigurationType>;

export interface AgentProperties {
  version: string;
  helmChartVersion?: string;
  clusterName: string;
  inactive: boolean;
  enableAgentTaskExecution: boolean;
  enableAgentTaskExecutionV2?: boolean;
  enableHelm?: boolean;
  watchNamespace: string;
  daemon?: {
    enabled?: boolean;
  };
  networkMapper?: {
    enabled?: boolean;
  };
  actions: {
    basic: boolean;
    advanced: boolean;
    podExec?: boolean;
    portForward?: boolean;
  };
  resources: {
    job: boolean;
    cronJob: boolean;
    secret: boolean;
    argoWorkflows?: {
      cronWorkflows: boolean;
      workflows: boolean;
      workflowTemplates: boolean;
      clusterWorkflowTemplates: boolean;
    };
  };
  monitoringProviders: {
    type: string;
  }[];
}

const getDefaultRequiredAgentConfigurationPropsForUndefinedAgent =
  (): AgentProperties => ({
    clusterName: "",
    version: "0.0.0",
    inactive: false,
    actions: {
      basic: false,
      advanced: false,
    },
    resources: {
      job: false,
      cronJob: false,
      secret: false,
    },
    enableAgentTaskExecution: false,
    enableAgentTaskExecutionV2: false,
    watchNamespace: "all",
    monitoringProviders: [],
  });

// TODO: CU-86c2h2ytd Temporary conversion solution to avoid breaking changes all over the web codebase, it should definitely be removed and replaced with the actual AgentConfiguration type in a separate pull request
export const parseAgentToAgentProperties = (agent?: Agent): AgentProperties => {
  if (!agent) {
    return getDefaultRequiredAgentConfigurationPropsForUndefinedAgent();
  }
  const agentProps: AgentProperties = {
    clusterName: agent.clusterName,
    inactive: agent.inactive,

    version: agent.configuration.appmetadata?.version ?? "0.0.0",
    watchNamespace: agent.configuration.watchnamespace ?? "all",
    enableAgentTaskExecution: !!agent.configuration.enableagenttaskexecution,
    enableAgentTaskExecutionV2:
      !!agent.configuration.enableagenttaskexecutionv2,
    daemon: {
      enabled: !!agent.configuration.daemon?.enabled,
    },
    networkMapper: {
      enabled: !!agent.configuration.networkmapper?.enable,
    },
    actions: {
      basic: !!agent.configuration.actions?.basic,
      advanced: !!agent.configuration.actions?.advanced,
      podExec: !!agent.configuration.actions?.podexec,
      portForward: !!agent.configuration.actions?.portforward,
    },
    resources: {
      cronJob: !!agent.configuration.resources?.cronjob,
      job: !!agent.configuration.resources?.job,
      secret: !!agent.configuration.resources?.secret,
      argoWorkflows: {
        cronWorkflows:
          !!agent.configuration.resources?.argoworkflows?.cronworkflows,
        workflows: !!agent.configuration.resources?.argoworkflows?.workflows,
        workflowTemplates:
          !!agent.configuration.resources?.argoworkflows?.workflowtemplates,
        clusterWorkflowTemplates:
          !!agent.configuration.resources?.argoworkflows
            ?.clusterworkflowtemplates,
      },
    },
    monitoringProviders: agent.configuration.monitoringproviders ?? [],
    enableHelm: agent.configuration.enablehelm,
    helmChartVersion: agent.configuration.chartversion,
  };
  if (agentProps.version === "development") {
    agentProps.version = "999.0.0+development";
  }
  return agentProps;
};
