import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

import { useInspectionFilters } from "@/components/Inspection/filters/useInspectionFilters";
import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";

export const useCancelSelectionOnFilterChange = (
  currentPage: number,
  pageSize: number
) => {
  const { clusterName, clearSelectedTableRows } =
    useInspectionBatchActionsContext();
  const inspectionFilters = useInspectionFilters(clusterName);

  const filersRef = useRef(inspectionFilters);
  const currentPageRef = useRef(currentPage);
  const pageSizeRef = useRef(pageSize);

  useEffect(() => {
    if (
      currentPageRef.current !== currentPage ||
      pageSizeRef.current !== pageSize
    ) {
      clearSelectedTableRows();
      return;
    }

    if (!isEqual(filersRef.current, inspectionFilters)) {
      filersRef.current = inspectionFilters;
      clearSelectedTableRows();
    }
  }, [inspectionFilters, clearSelectedTableRows, currentPage, pageSize]);
};
