import { ChatMessageType } from "@komodorio/design-system/komodor-ui";

import { BaseMessage, Marker, MessageType } from "@/generated/aiApi";
import { GroupedMessage } from "@/components/AiInvestigation/AiInvestigationChat/GroupedMessage";

export const parseMessages = (messages: BaseMessage[]) => {
  const parsedMessages: ChatMessageType[] = [];
  let isGrouping = false;
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    if (message.markers?.includes(Marker.ContainsQuery)) {
      const groupEndIndex = messages
        .slice(i)
        .findIndex((msg) => !msg.markers?.length);
      const isGroupCompleted = groupEndIndex !== -1;
      isGrouping = !isGroupCompleted;
      parsedMessages.push({
        id: isGrouping ? "typing" : i,
        content: (
          <GroupedMessage
            messages={messages.slice(
              i,
              isGroupCompleted ? i + groupEndIndex + 1 : undefined
            )}
            isGroupCompleted={isGroupCompleted}
          />
        ),
        isUser: false,
      });
      if (!isGroupCompleted) break;
      i += groupEndIndex;
    } else {
      parsedMessages.push({
        id: i,
        content: message.content,
        isUser: message.type === MessageType.Human,
      });
    }
  }
  return { parsedMessages, isGrouping };
};
