import { palette } from "@komodorio/design-system";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import { ZIndex } from "../../constants/zIndex";

import { MinimizedShellRow } from "./MinimizedShellRow";
import { MinimizedShellRowHeader } from "./MinimizedShellRowHeader";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const ContainerStyle = css`
  background-color: ${palette.gray["900"]};
  box-shadow: 0 -0.125rem 0.5rem 1px rgba(22, 24, 31, 0.15);
  border-radius: 0.5rem 0.5rem 0 0;
`;
const FixedContainer = styled.div`
  ${ContainerStyle};
  position: fixed;
  bottom: 0;
  right: 5rem;
  z-index: ${ZIndex.AboveMuiDrawer};
`;

const RelativeContainer = styled.div`
  ${ContainerStyle};
`;

interface PodExecMinimizedSessionsProps {
  sessionIds: string[];
  onMaximize: (id: string) => void;
  onCloseSession: (id: string) => void;
}

export const PodExecMinimizedSessions: React.FC<
  PodExecMinimizedSessionsProps
> = ({ sessionIds, onMaximize, onCloseSession }) => {
  const [isOpen, setOpen] = useState(false);
  const { useAppNotifications } = useOverridableFlags();

  const handleMaximize = (id: string) => {
    setOpen(false);
    onMaximize(id);
  };

  const handleClose = (id: string) => {
    setOpen(false);
    onCloseSession(id);
  };

  const handleHeaderClicked = () => {
    return sessionIds.length > 1
      ? setOpen((open) => !open) // if there are enough elements, lets toggle
      : onMaximize(sessionIds[0]); // for one element we need to just open it
  };

  if (!sessionIds.length) return null;

  const Container = useAppNotifications ? RelativeContainer : FixedContainer;

  return (
    <Container aria-label="Pod exec minimized drawer">
      <MinimizedShellRowHeader
        isOpen={isOpen}
        onClick={handleHeaderClicked}
        numOfOpenSessions={sessionIds.length}
        sessionId={sessionIds[0]}
      />
      {isOpen &&
        sessionIds.map((id) => (
          <MinimizedShellRow
            key={id}
            sessionId={id}
            onClose={handleClose}
            onMaximize={handleMaximize}
          />
        ))}
    </Container>
  );
};
