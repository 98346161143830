import { useMemo } from "react";
import {
  Dictionary,
  Record,
  String,
  Boolean,
  Null,
  Array,
  Static,
  BigInt,
} from "runtypes";

import { useOverridableFlags } from "../../../context/featureFlags/OverridableFlags";
import { getATMResourcesURL } from "../client";
import { useAtmRequest, AtmRequestResult } from "../useAtmRequest";

const DEFAULT_ATM_PAGE_SIZE = 50;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResourceListResponse = Record({
  data: Array(Dictionary(String, String)),
  isPaginating: Boolean,
  mostRecentUpdate: String.Or(Null),
  mostRecentSequenceId: BigInt.Or(Null),
  sessionId: String,
  failureMessage: String.Or(Null),
});
export type AtmResourceListResponseType = Static<typeof ResourceListResponse>;

export interface RequestListResourcesBody {
  resourceKind: string;
  agentId: string;
  namespaces: string[];
  clusterName: string;
  outputType?: string;
  mostRecentDataFrom?: string | null;
  mostRecentSequenceIdFrom?: bigint | null;
  pageSize?: number;
  labelSelector?: string;
  fieldSelector?: string;
}

const useAtmListResources = (
  agentId: string,
  cluster: string,
  resource: string,
  outputType: string,
  handleResponse: (responseJSON: AtmResourceListResponseType) => void,
  mostRecentDataFrom: string | null = null,
  mostRecentSequenceId: bigint | null = null,
  namespaces: string[] = [],
  labelSelector?: string,
  fieldSelector?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): AtmRequestResult => {
  const { atmPagesize } = useOverridableFlags();

  let pageSize = DEFAULT_ATM_PAGE_SIZE;
  if (Number.isInteger(atmPagesize)) {
    pageSize = atmPagesize as number;
  }

  const reqBody = useMemo<RequestListResourcesBody | null>(() => {
    if (!cluster || !agentId || !namespaces) return null;
    return {
      resourceKind: resource,
      agentId,
      namespaces,
      clusterName: cluster,
      outputType: outputType,
      mostRecentDataFrom: mostRecentDataFrom,
      mostRecentSequenceIdFrom: mostRecentSequenceId,
      pageSize: pageSize,
      labelSelector,
      fieldSelector,
    };
  }, [
    cluster,
    agentId,
    namespaces,
    resource,
    outputType,
    mostRecentDataFrom,
    mostRecentSequenceId,
    pageSize,
    labelSelector,
    fieldSelector,
  ]);

  const handleValidatedResponse = (response: unknown) => {
    handleResponse(response as AtmResourceListResponseType);
    return true;
  };

  return useAtmRequest(
    getATMResourcesURL(),
    JSON.stringify(reqBody),
    handleValidatedResponse
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useAtmListResources;
