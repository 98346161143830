import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { Dictionary } from "lodash";

import { comparedOptionsSorter } from "../common/selectionUtils";

import { ServiceInfo } from "@/shared/types/ServiceInfo";
import {
  buildKomodorUid,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const buildKomodorUidFromServiceInfo = (
  serviceInfo: ServiceInfo
): string => {
  const namespace = serviceInfo?.env ?? "";
  const kind = serviceInfo?.kind ?? "";
  return buildKomodorUid({
    kind,
    namespace,
    resourceName: serviceInfo?.title ?? "",
    clusterName: serviceInfo?.k8sCluster ?? "",
  });
};

export const sortServicesSelection = ({
  toSort,
  baselineSelection,
  comparedSelections,
}: {
  toSort: Dictionary<ServiceInfo>;
  baselineSelection: MuiSelectionOption<string> | null;
  comparedSelections: MuiSelectionOption<string>[] | null;
}) => {
  return Object.values(toSort).sort((a, b) => {
    return comparedOptionsSorter({
      aKomodorUidParams: {
        clusterName: a.k8sCluster,
        namespace: a.env,
        resourceName: a.title,
        kind: a.kind ?? "",
      },
      bKomodorUidParams: {
        clusterName: b.k8sCluster,
        namespace: b.env,
        resourceName: b.title,
        kind: b.kind ?? "",
      },
      baselineSelection,
      comparedSelections,
    });
  });
};

export const getComparedServicesOptions = ({
  services,
  baselineServiceId,
  comparedServicesIds,
}: {
  services: Dictionary<ServiceInfo>;
  baselineServiceId: MuiSelectionOption<string> | null;
  comparedServicesIds: MuiSelectionOption<string>[] | null;
}) => {
  return sortServicesSelection({
    toSort: services,
    baselineSelection: baselineServiceId,
    comparedSelections: comparedServicesIds,
  }).reduce<MuiSelectionOption<string>[]>((acc, service) => {
    const uid = buildKomodorUidFromServiceInfo(service);
    const baselineParsedKomodorUid = baselineServiceId
      ? parseKomodorUid(baselineServiceId.value)
      : null;
    if (
      uid !== baselineServiceId?.value &&
      baselineParsedKomodorUid?.kind === service.kind
    ) {
      acc.push({
        label: uid,
        value: uid,
      });
    }
    return acc;
  }, []);
};
