import React, { createContext, useContext } from "react";

import { GetPlansDataQuery, useGetPlansDataQuery } from "@/generated/graphql";
import {
  GetPlansData,
  IHasuraMigrations,
} from "@/shared/constants/hasuraMigrationFeatureFlag";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

type PlansDataOutput = GetPlansDataQuery | undefined;
const useGetPlansData = (shouldUseMigration: boolean): PlansDataOutput => {
  const [{ data }] = useGetPlansDataQuery({ pause: shouldUseMigration });
  return data;
};

const PlansDataContext = createContext<PlansDataOutput>(undefined);

// Can be deleted after verifying no usage
export const PlansDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { hasuraMigrations } = useOverridableFlags();
  const hasuraMigrationFF = hasuraMigrations as IHasuraMigrations;
  const shouldUseMigration =
    hasuraMigrationFF?.endpoints.includes(GetPlansData);
  const plansData = useGetPlansData(shouldUseMigration);
  return (
    <PlansDataContext.Provider value={plansData}>
      {children}
    </PlansDataContext.Provider>
  );
};

const usePlansData = (): PlansDataOutput => useContext(PlansDataContext);

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default usePlansData;
