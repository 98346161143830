import styled, { css, keyframes } from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import { styled as muiStyled } from "@mui/material";
import { MagicAi24 } from "@komodorio/design-system/icons";

const bannerBgAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -180% 50%;
  }
`;
export const AiBannerContainer = styled.div<{ loading?: boolean }>`
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 0.25rem;
  border: 1px solid var(--secondary-dark, ${muiColors.blue[300]});
  background: linear-gradient(
      270deg,
      rgba(0, 122, 255, 0) 0%,
      rgba(0, 122, 255, 0.2) 20%,
      rgba(0, 122, 255, 0) 40%
    ),
    ${muiColors.blue[50]};
  background-size: 200% 200%;

  ${({ loading }) =>
    loading
      ? css`
          animation: ${bannerBgAnimation} 1.5s infinite ease-in-out;
        `
      : ""}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2rem;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
`;

export const StyledButton = muiStyled(Button)({
  position: "unset",
});

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const MagicAiAnimated = styled(MagicAi24)<{ loading?: boolean }>`
  ${({ loading }) =>
    loading
      ? css`
          animation: ${spin} 2s linear infinite;
        `
      : ""}
`;
