import React, { JSX } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import MenuBook from "@mui/icons-material/MenuBook";
import { format } from "date-fns";

import { ContextMenu } from "../Menus/ContextMenu";
import { Ellipsis16 } from "../../../icons";
import { muiTheme } from "../../../themes";
import { lightMuiTooltipStyle } from "../../../styles/styles";
import { Status } from "../Status/Status";

import { InstallationDetailsProps, LogoProps } from "./types";
import { DeleteMenuItem, InstallationInfo, InstalledBy } from "./components";

const getPrettyDate = (date: Date) => {
  return format(date, "MMM d, p");
};

const INNER_WIDTH = "calc(100% - 152px)";

const InstallationDetails = ({
  installedBy,
  installedAt,
  info,
}: InstallationDetailsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body3" color={muiTheme.palette.text.secondary}>
        Installed on {getPrettyDate(installedAt)}
      </Typography>
      <Grid container columns={3} alignItems="baseline">
        <Grid item maxWidth="max-content">
          <InstalledBy installedBy={installedBy} />
        </Grid>
        <Grid item xs={2} width="auto">
          <InstallationInfo info={info} />
        </Grid>
      </Grid>
    </Stack>
  );
};

interface IntegrationCardProps {
  Logo: React.FC<LogoProps>;
  logoSize: number;
  title: string;
  description: string;
  onInstall?: () => void;
  isInstalled?: boolean;
  installationDetails?: InstallationDetailsProps;
  buttonText?: string;
  InstallCTA?: JSX.Element;
  canUninstall?: boolean;
  onUninstall?: () => void;
  documentationLink?: string;
}

const CARD_ACTIONS_MENU_ID = "IntegrationCardActions";

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  Logo,
  logoSize,
  title,
  description,
  isInstalled,
  installationDetails,
  InstallCTA,
  buttonText = "Install Integration",
  onInstall,
  canUninstall = false,
  onUninstall,
  documentationLink,
}) => {
  const InstallButton = () => {
    if (InstallCTA) {
      return InstallCTA;
    }
    return (
      <Button
        variant="outlined"
        sx={{ width: "fit-content" }}
        onClick={onInstall}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Card sx={{ width: 448, height: 170 }}>
      <CardContent>
        <Stack direction="row" spacing={2} width="100%">
          <Logo width={logoSize} height={logoSize} style={{ flexShrink: 0 }} />
          <Stack
            spacing={1}
            useFlexGap
            sx={{ flexWrap: "wrap" }}
            width={INNER_WIDTH}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip
                title={title}
                placement="top"
                componentsProps={lightMuiTooltipStyle}
              >
                <Typography
                  variant="h3"
                  noWrap
                  maxWidth={isInstalled ? "220px" : "auto"}
                >
                  {title}
                </Typography>
              </Tooltip>
              {isInstalled && (
                <Status color="success" label="Installed" size="small" />
              )}
            </Stack>
            {isInstalled ? (
              <InstallationDetails {...installationDetails} />
            ) : (
              <Stack
                direction="column"
                minHeight={90}
                justifyContent={"space-between"}
              >
                <Typography variant="body2">{description}</Typography>
                <InstallButton />
              </Stack>
            )}
          </Stack>
          {isInstalled && (
            <Stack
              direction="row"
              alignItems="start"
              sx={{ "&&": { marginLeft: "auto" } }}
            >
              {documentationLink && (
                <IconButton
                  color="primary"
                  href={documentationLink}
                  target="_blank"
                  sx={{ width: "32px", height: "32px" }}
                >
                  <MenuBook />
                </IconButton>
              )}
              {canUninstall && (
                <ContextMenu
                  id={CARD_ACTIONS_MENU_ID}
                  ariaLabels={{ button: `Actions for ${title}` }}
                  items={[
                    {
                      route: ".",
                      CustomComponent: DeleteMenuItem,
                      title: "delete",
                      // https://app.clickup.com/t/86c2dqm28
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onUninstall,
                    },
                  ]}
                  buttonComponent={IconButton}
                  LinkComponent={IconButton}
                >
                  <Ellipsis16 />
                </ContextMenu>
              )}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
