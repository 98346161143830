import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { RichSessionData } from "../useEnrichedSession";

const EvidenceContainer = styled.pre`
  background-color: ${muiColors.gray[800]};
  padding: 4px 16px;
  border-radius: 4px;
  overflow: auto;
  max-height: 160px;
  white-space: pre-wrap;

  & .MuiLink-root {
    color: ${muiColors.blue[300]};
  }
`;

export const RelatedEvidence: React.FC<{ session: RichSessionData }> = ({
  session,
}) => {
  return session.evidence ? (
    <>
      <Typography variant="body2" color="text.secondary">
        Related Evidence:
      </Typography>
      <Stack spacing={1}>
        <Typography variant="body2">From: {session.evidenceSource}</Typography>
        <EvidenceContainer>
          <Typography variant="code1" color={muiColors.gray[100]}>
            {session.evidence}
          </Typography>
        </EvidenceContainer>
      </Stack>
    </>
  ) : null;
};
