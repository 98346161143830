import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  ServicesObject,
  useFetchAllServices,
} from "../../components/servicesView/serviceFetchHooks/serviceFetchHook";
import { ServiceInfo } from "../types/ServiceInfo";
import { FetchModeState } from "../../components/servicesView/serviceFetchHooks/types";

import {
  initialStateAsDict,
  ServicesDictionary,
  useScopeServicesObject,
} from "./useKomodorServicesAsDictionary";

import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";

type ExpandedServiceState = {
  unscopedServices: ServiceInfo[] | undefined;
  setServicesScope: (serviceIds?: string[]) => void;
  servicesAsDictionary: ServicesDictionary;
  isFetchingWorkspacesServicesData: boolean;
  setIsFetchingWorkspacesServicesData: (val: boolean) => void;
  isInitialServicesJobsFetchingOnWorkspaceChange: boolean;
  scopeHasBeenLoaded: boolean;
  fetchState: FetchModeState;
};

type ServicesState = ServicesObject & ExpandedServiceState;

const initialState: ServicesState = {
  services: undefined,
  jobs: undefined,
  all: undefined,
  servicesAsDictionary: initialStateAsDict,
  unscopedServices: undefined,
  setServicesScope: () => undefined,
  isFetchingWorkspacesServicesData: false,
  isInitialServicesJobsFetchingOnWorkspaceChange: false,
  setIsFetchingWorkspacesServicesData: () => undefined,
  scopeHasBeenLoaded: false,
  fetchState: {
    services: "full",
    jobs: "full",
  },
};
const ServicesContext = createContext<ServicesState>(initialState);

export const ServicesProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const isAnonymousUser = useIsAnonymousUser();
  if (isAnonymousUser) {
    return <AnonymousServicesProvider>{children}</AnonymousServicesProvider>;
  }
  return (
    <AuthenticatedServicesProvider>{children}</AuthenticatedServicesProvider>
  );
};

const AuthenticatedServicesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const {
    servicesLists,
    servicesAsDictionary,
    fetchState,
    isFetching: isFetchingAllServicesOrJobs,
  } = useFetchAllServices();
  const [servicesScope, setServicesScope] = useState<string[]>();
  const [
    isFetchingWorkspacesServicesData,
    setIsFetchingWorkspacesServicesData,
  ] = useState<boolean>(true);
  const [
    isInitialServicesJobsFetchingOnWorkspaceChange,
    setIsInitialServicesJobsFetchingOnWorkspaceChange,
  ] = useState<boolean>(false);
  const latestWorkspaceId = useRef<string>();
  const { currentWorkspace } = useWorkspaces();

  useEffect(() => {
    // if the workspace has changed, the services will be refetched and we need to update the isFetchingWorkspacesServicesData state
    // for the <ServicesLoader /> to be displayed inside PrivateRoutes.
    // we need to check if the workspace has changed to avoid setting the state to true when simply fetching updates for the data in the background.
    if (
      latestWorkspaceId.current !== currentWorkspace?.id &&
      !isInitialServicesJobsFetchingOnWorkspaceChange
    ) {
      latestWorkspaceId.current = currentWorkspace?.id;
      setIsInitialServicesJobsFetchingOnWorkspaceChange(true);
    }
    if (
      !isFetchingAllServicesOrJobs &&
      isInitialServicesJobsFetchingOnWorkspaceChange
    ) {
      setIsInitialServicesJobsFetchingOnWorkspaceChange(false);
    }
  }, [
    currentWorkspace?.id,
    isFetchingAllServicesOrJobs,
    isInitialServicesJobsFetchingOnWorkspaceChange,
  ]);

  const scopeServicesObject = useScopeServicesObject();

  const getFullServicesObj = useCallback(() => {
    if (servicesScope?.length) {
      return scopeServicesObject(servicesScope, servicesAsDictionary);
    }
    return {
      ...servicesLists,
      servicesAsDictionary,
    };
  }, [scopeServicesObject, servicesAsDictionary, servicesLists, servicesScope]);

  const allFilteredServices = useMemo(() => {
    return getFullServicesObj();
  }, [getFullServicesObj]);

  const state: ServicesState = useMemo(
    () => ({
      ...allFilteredServices,
      servicesAsDictionary: {
        ...allFilteredServices.servicesAsDictionary,
        unscopedServices: servicesAsDictionary.all,
      },
      unscopedServices: servicesLists.all,
      setServicesScope,
      isFetchingWorkspacesServicesData,
      setIsFetchingWorkspacesServicesData,
      isInitialServicesJobsFetchingOnWorkspaceChange,
      scopeHasBeenLoaded: (servicesScope?.length ?? 0) > 0,
      fetchState,
    }),
    [
      allFilteredServices,
      fetchState,
      isInitialServicesJobsFetchingOnWorkspaceChange,
      isFetchingWorkspacesServicesData,
      servicesAsDictionary.all,
      servicesLists.all,
      servicesScope?.length,
    ]
  );

  return (
    <ServicesContext.Provider value={state}>
      {children}
    </ServicesContext.Provider>
  );
};

const AnonymousServicesProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ServicesContext.Provider value={initialState}>
      {children}
    </ServicesContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useKomodorServices = (): ServicesState =>
  useContext(ServicesContext);
