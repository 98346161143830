import React from "react";

export type Page = {
  route: string;
  title: string | React.ReactNode;
  disabled?: boolean;
  divider?: boolean;
  CustomComponent?: React.ElementType;
  ariaLabel?: string;
};

export type Section = {
  title: string | React.ReactNode;
  pages: Page[];
  ariaLabel?: string;
};

export type MenuItem = Page | Section;

export function isSection(item: MenuItem): item is Section {
  return "pages" in item;
}

export function isPage(item: MenuItem): item is Page {
  return !isSection(item);
}
