import { ReactNode } from "react";

import {
  CustomColumnDefinition,
  ProcessListColumnDefinition,
  ProcessListColumnTypes,
  ProcessListSortKeys,
} from "@/components/common/ProcessList/processListConstants";
import { EventTableHeader } from "@/components/common/enums";
import SortTitle, {
  Direction,
} from "@/components/common/ProcessList/SortTitle";
import EventGroup from "@/components/common/EventGroup";
import { ServiceInfo } from "@/shared/types/ServiceInfo";
import ViewJobPodsButton from "@/components/common/EventGroup/jobEvent/ViewJobPodsButton";
import Status from "@/components/common/ProcessList/Status";
import Arrow from "@/components/common/ProcessList/Arrow";
import TypeTag from "@/components/common/ProcessList/TypeTag";
import {
  MaxWidthText,
  TextCell,
  TimeCell,
} from "@/components/common/ProcessList/CellComponents";
import {
  createCustomHeaderCellAreaLabel,
  createHeaderCellAreaLabel,
} from "@/components/common/ProcessList/AriaLabels";

export type EventsExtraDataMap = Record<string, Record<string, unknown>>;

type HeaderRenderConfig = {
  timeZoneName: string;
  onSortClicked: (sortKey: ProcessListSortKeys) => void;
  selectedSort?: { key: ProcessListSortKeys; direction: Direction };
};

type CellProps = {
  columnDefinition: ProcessListColumnDefinition;
  eventGroup: EventGroup;
  service?: ServiceInfo;
  serviceLink: string | ReactNode;
};

type CellRenderConfig = {
  format: (date?: Date | number) => string;
  getDetails: () => string;
  shouldRenderArrow: boolean;
  eventsExtraDataMap?: EventsExtraDataMap;
};

export function getCustomHeaderCellForColumn(
  columnDefinition: CustomColumnDefinition
) {
  return (
    <th
      aria-label={createCustomHeaderCellAreaLabel(
        columnDefinition.customDataKey
      )}
    >
      {columnDefinition.columnHeader}
    </th>
  );
}

export function getHeaderCellForColumn(
  columnDefinition: ProcessListColumnDefinition,
  config: HeaderRenderConfig
) {
  if (typeof columnDefinition === "object") {
    return getCustomHeaderCellForColumn(columnDefinition);
  }

  const { timeZoneName, onSortClicked, selectedSort } = config;
  switch (columnDefinition) {
    case ProcessListColumnTypes.Type:
      return (
        <th aria-label={createHeaderCellAreaLabel(ProcessListColumnTypes.Type)}>
          {EventTableHeader.EventType}
        </th>
      );
    case ProcessListColumnTypes.Summary:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(ProcessListColumnTypes.Summary)}
        >
          {EventTableHeader.Summary}
        </th>
      );
    case ProcessListColumnTypes.StartDate:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(
            ProcessListColumnTypes.StartDate
          )}
        >
          <SortTitle
            title={`Start time (${timeZoneName})`}
            onClick={() => onSortClicked(ProcessListSortKeys.starttime)}
            direction={selectedSort?.direction ?? Direction.down}
            active={selectedSort?.key === ProcessListSortKeys.starttime}
          />
        </th>
      );
    case ProcessListColumnTypes.EndDate:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(ProcessListColumnTypes.EndDate)}
        >
          <SortTitle
            title="Last updated time"
            onClick={() => onSortClicked(ProcessListSortKeys.endtime)}
            direction={selectedSort?.direction ?? Direction.down}
            active={selectedSort?.key === ProcessListSortKeys.endtime}
          />
        </th>
      );
    case ProcessListColumnTypes.Details:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(ProcessListColumnTypes.Details)}
        >
          {EventTableHeader.Details}
        </th>
      );
    case ProcessListColumnTypes.Service:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(ProcessListColumnTypes.Service)}
        >
          {EventTableHeader.Service}
        </th>
      );
    case ProcessListColumnTypes.Status:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(ProcessListColumnTypes.Status)}
        >
          {EventTableHeader.Status}
        </th>
      );
    case ProcessListColumnTypes.ClickableArrow:
      return (
        <th
          aria-label={createHeaderCellAreaLabel(
            ProcessListColumnTypes.ClickableArrow
          )}
        ></th>
      );
    default:
      return "";
  }
}

type CustomCellProps = {
  eventGroup: EventGroup;
  columnDefinition: CustomColumnDefinition;
};

function getCustomCellForColumn(
  cellDefinitions: CustomCellProps,
  config: CellRenderConfig
) {
  const { columnDefinition, eventGroup } = cellDefinitions;
  const { eventsExtraDataMap } = config;
  const value =
    eventsExtraDataMap?.[
      columnDefinition.getEventGroupIdForCustomData(eventGroup)
    ][columnDefinition.customDataKey] ??
    columnDefinition.fallbackValue ??
    "--";
  return (
    <TextCell
      data-e2e-selector={`tableCell-Custom ${columnDefinition.customDataKey}`}
    >
      {String(value)}
    </TextCell>
  );
}

type CellRenderProps = {
  cell: CellProps;
  config: CellRenderConfig;
};

export function getCellForColumn(props: CellRenderProps) {
  const { cell, config } = props;
  const { columnDefinition, eventGroup, service, serviceLink } = cell;
  const { format, getDetails, shouldRenderArrow } = config;

  if (typeof columnDefinition === "object") {
    return getCustomCellForColumn({ eventGroup, columnDefinition }, config);
  }

  switch (columnDefinition) {
    case ProcessListColumnTypes.Type:
      return (
        <td data-e2e-selector={"tableCell-Type"}>
          <TypeTag
            backgroundColor={eventGroup.backgroundColor}
            fillColor={eventGroup.fillColor}
            icon={eventGroup.icon}
            eventType={eventGroup.title ?? eventGroup.type}
          />
        </td>
      );
    case ProcessListColumnTypes.Summary:
      return (
        <TextCell data-e2e-selector={"tableCell-Summary"}>
          <MaxWidthText maxWidth="16rem">{eventGroup.summary}</MaxWidthText>
        </TextCell>
      );
    case ProcessListColumnTypes.StartDate:
      return (
        <TimeCell data-e2e-selector={"tableCell-StartDate"}>
          {format(eventGroup.startTime)}
        </TimeCell>
      );
    case ProcessListColumnTypes.EndDate:
      return (
        <TimeCell data-e2e-selector={"tableCell-EndDate"}>
          {format(eventGroup.endTime)}
        </TimeCell>
      );
    case ProcessListColumnTypes.Details:
      return (
        <TextCell data-e2e-selector={"tableCell-Details"}>
          <MaxWidthText maxWidth="20rem">{getDetails()}</MaxWidthText>
          <ViewJobPodsButton eventGroup={eventGroup} service={service} />
        </TextCell>
      );
    case ProcessListColumnTypes.Service:
      return <td data-e2e-selector={"tableCell-Service"}>{serviceLink}</td>;
    case ProcessListColumnTypes.Status:
      return (
        <td
          data-e2e-selector={"tableCell-Status"}
          data-e2e-selector-status={eventGroup.status}
        >
          <Status status={eventGroup.status} />
        </td>
      );
    case ProcessListColumnTypes.ClickableArrow:
      return (
        <td data-e2e-selector={"tableCell-ClickableArrow"}>
          {shouldRenderArrow && (
            <Arrow width="0.75rem" color="#A8AFC0" direction="right" />
          )}
        </td>
      );
  }
}
