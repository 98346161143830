import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";

import { useBatchActionsLabel } from "@/components/common/ResourceListTable/batchActions/components/BatchActionsDialog/hooks/useBatchActionsLabel";
import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { selectBatchActionResponseToShow } from "@/shared/store/batchActionsStore/batchActionsSelectors";
import { batchActionsAriaLabels } from "@/components/common/ResourceListTable/batchActions/batchActionsAriaLabels";

const { title: titleAriaLabel, subtitle: subtitleAriaLabel } =
  batchActionsAriaLabels.batchActionsDialog;
export const ResultsTitle: React.FC = () => {
  const currentResponse = useBatchActionsStore(selectBatchActionResponseToShow);

  const actionLabel = useBatchActionsLabel().toLowerCase();
  const totalResults = currentResponse?.actions.length ?? 0;
  const resourceKind = pluralize(
    currentResponse?.actions[0].resource.kind ?? "",
    totalResults
  ).toLowerCase();

  const title = `Batch ${actionLabel} ${resourceKind} results`;

  const subtitle = useMemo(() => {
    const totalFailedActions = currentResponse?.actions.reduce<number>(
      (acc, curr) => {
        if (curr.failureMessage) {
          return acc + 1;
        }
        return acc;
      },
      0
    );

    return `The following ${totalFailedActions} ${resourceKind} (out of ${totalResults}) failed to ${actionLabel}`;
  }, [actionLabel, currentResponse?.actions, resourceKind, totalResults]);

  return (
    <>
      <Typography variant={"h3"} aria-label={titleAriaLabel}>
        {title}
      </Typography>
      <Typography variant={"body2"} aria-label={subtitleAriaLabel}>
        {subtitle}
      </Typography>
    </>
  );
};
