import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Eye16, Pencil16 } from "@komodorio/design-system/icons";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { muiTheme } from "@komodorio/design-system";

import { ariaLabels } from "../ariaLabels";

import { RbacRole } from "@/generated/auth";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/RolesPage/types";
import { isRoleEditable } from "@/pages/organization-settings/access-management/RolesPage/utils";

export interface ActionsCellProps {
  params: GridRenderCellParams;
  onEdit: OnEdit;
  onDelete: OnDelete;
}

const cellAriaLabels = ariaLabels.table.actionsCell;

export const ActionsCell = ({ params, onEdit, onDelete }: ActionsCellProps) => {
  const canEdit = isRoleEditable(params.row as RbacRole);

  const deleteAction = useMemo(() => {
    const canDelete = !params.row.isDefault;

    const tooltipTitle = canDelete
      ? "Delete role"
      : "Can't delete a default role";

    const color = canDelete ? muiTheme.palette.error.dark : "disabled";

    return (
      <LightTooltip title={tooltipTitle} key="delete">
        <Box>
          <IconButton
            onClick={() => onDelete(params.row as RbacRole)}
            aria-label={cellAriaLabels.delete}
            disabled={!canDelete}
          >
            <DeleteOutlineIcon sx={{ color }} fontSize="small" />
          </IconButton>
        </Box>
      </LightTooltip>
    );
  }, [params.row, onDelete]);

  const actions = useMemo(() => {
    if (!canEdit) {
      return [
        <LightTooltip title="View role" key="view">
          {/*  EditModal has isReadOnly prop that represent "view" mode  */}
          <IconButton
            onClick={() => onEdit(params.row as RbacRole)}
            aria-label={cellAriaLabels.view}
          >
            <Eye16 />
          </IconButton>
        </LightTooltip>,
      ];
    }

    return [
      <LightTooltip title="Edit role" key="edit">
        <IconButton
          onClick={() => onEdit(params.row as RbacRole)}
          aria-label={cellAriaLabels.edit}
        >
          <Pencil16 />
        </IconButton>
      </LightTooltip>,
      deleteAction,
    ];
  }, [canEdit, onEdit, params.row, deleteAction]);

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="end"
      justifyContent="center"
      aria-label={ariaLabels.table.actionsCell.container}
    >
      {params.row.hovered && <Stack direction="row">{actions}</Stack>}
    </Stack>
  );
};
