import { ResourceTableModelRow } from "komodor-types";
import React, { useCallback, useMemo } from "react";

import {
  PODS_SELECTION_SEARCH_PARAM_KEY,
  PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { columns } from "../../eventsView/PodsSelector/columns";
import { DeletedPodsSwitch } from "../../eventsView/PodsSelector/DeletedPodsSwitch";
import { KubernetesPodsResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import ResourceListErrorState from "../../Inspection/ResourceListErrorState";
import { LinesLoader } from "../loaders/Line";
import { ResourceListTable } from "../ResourceListTable/ResourceListTable";
import { filterResourceTableBySearchTerm } from "../../Inspection/utils/filterResultsList";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

type PodsResult = {
  pods: ResourceTableModelRow[];
  fetching: boolean;
  refresh: () => void;
  errorMessage: string;
  isPaginating?: boolean;
};
const onlySelected = (
  pods: ResourceTableModelRow[],
  selectedRowUids: string[]
) => pods.filter(({ uid: rowUid }) => selectedRowUids.includes(rowUid));

const Content: React.FC<{
  selectedRowsData: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  podsResult: PodsResult;
  cluster: string;
  maxSelectableRows: number;
  showSelectedRowsOnly: boolean;
}> = ({
  selectedRowsData: [selectedRowUids, setSelectedRowUids],
  podsResult: {
    pods,
    fetching,
    refresh,
    errorMessage,
    isPaginating = undefined,
  },
  cluster,
  maxSelectableRows,
  showSelectedRowsOnly,
}) => {
  const [searchTerm] = useStateInSearchParams(PODS_SELECTION_SEARCH_PARAM_KEY);
  const filteredPods = useMemo(() => {
    if (!searchTerm) {
      return pods;
    }
    return filterResourceTableBySearchTerm(pods, searchTerm);
  }, [pods, searchTerm]);
  const toggleRowSelection = useCallback(
    (row: ResourceTableModelRow) => {
      setSelectedRowUids((prevSelectedRowUids) => {
        if (prevSelectedRowUids.includes(row.uid)) {
          return prevSelectedRowUids.filter((rowUid) => rowUid !== row.uid);
        }
        return [...prevSelectedRowUids, row.uid];
      });
    },
    [setSelectedRowUids]
  );

  const unselectableRowIds = useMemo(() => {
    if (selectedRowUids.length === maxSelectableRows) {
      return filteredPods
        .map(({ id }) => id)
        .filter((rowId) => !selectedRowUids.includes(rowId));
    }
    return [];
  }, [maxSelectableRows, filteredPods, selectedRowUids]);
  const [showDeletedPodsParam, setShowDeletedPods] =
    useStringifiedStateInSearchParams<boolean>(
      PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY
    );

  return (
    <>
      {errorMessage ? (
        <>
          <br />
          <ResourceListErrorState
            resourceName="pods"
            errorMessage={errorMessage}
            refreshCallback={refresh}
          />
        </>
      ) : fetching ? (
        <LinesLoader />
      ) : (
        <div style={{ marginTop: "0.6rem" }}>
          <ResourceListTable
            rows={
              showSelectedRowsOnly
                ? onlySelected(filteredPods, selectedRowUids)
                : filteredPods
            }
            overridingColumns={columns}
            resourceType={KubernetesPodsResource}
            cluster={cluster}
            refreshResults={refresh}
            rowSelection={{
              selectedRowNames: selectedRowUids,
              onRowSelection: toggleRowSelection,
              unselectableRowNames: unselectableRowIds,
              maxSelectableRows,
              includeAllRowsSelection: true,
            }}
            overrideRowClick={(row) => {
              toggleRowSelection(row);
            }}
            configurablePageSize={false}
            defaultPageSize={5}
            includeActionsColumn={false}
            pageLabelAccessory={
              <DeletedPodsSwitch
                checked={showDeletedPodsParam ?? false}
                onToggle={() => {
                  setShowDeletedPods(!showDeletedPodsParam);
                }}
              />
            }
            isPaginating={isPaginating}
          />
        </div>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Content;
