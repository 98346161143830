import React from "react";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { TrackedKeysPageContent } from "@/pages/organization-settings/configurations/TrackedKeysPage/TrackedKeysPageContent";

export const TrackedKeysPage = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.trackedKeysPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <TrackedKeysPageContent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
