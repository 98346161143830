import { useMemo } from "react";

import { useAvailableActions } from "@/components/Inspection/utils/useAvailableActions";
import { AvailableActionsToBatchApiActionsMap } from "@/components/common/ResourceListTable/batchActions/batchActionsConstants";
import { ActionType } from "@/generated/actionsApi";
import { useInspectionBatchActionsContext } from "@/components/common/ResourceListTable/batchActions/context/useInspectionBatchActionsContext";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useGetAvailableActions = () => {
  const { resourceType } = useInspectionBatchActionsContext();
  const { availableBatchActions } = useOverridableFlags();

  const availableResourceActions = useAvailableActions(
    resourceType.SupportedActions,
    resourceType.Kind
  );

  return useMemo(
    () =>
      availableResourceActions?.reduce<ActionType[]>((acc, action) => {
        const batchAction = AvailableActionsToBatchApiActionsMap[action];
        if (batchAction) {
          const isActionAllowed = (
            availableBatchActions as Partial<Record<ActionType, boolean>>
          )[batchAction];
          if (isActionAllowed) {
            acc.push(batchAction);
          }
        }
        return acc;
      }, []),
    [availableBatchActions, availableResourceActions]
  );
};
