import { muiColors } from "@komodorio/design-system";

type TextTickProps = {
  x?: number;
  y?: number;
  payload?: { value: string };
};

export const TextTick: React.FC<TextTickProps> = ({ payload, x, y }) => {
  return (
    <text
      fontSize={12}
      fontWeight={500}
      x={x}
      y={y}
      fill={muiColors.gray[800]}
      textAnchor="middle"
      alignmentBaseline="hanging"
    >
      {payload?.value}
    </text>
  );
};
