import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import * as colors from "../../../../themes/colors";

export interface ConfirmationDialogProps {
  title: string;
  content: React.ReactNode;
  okText?: string;
  okDisabled?: boolean;
  cancelText?: string;
  onClose: (confirmed: boolean) => void;
  width?: string;
  height?: string;
  className?: string;
  type?: "primary" | "error" | "warning" | "success";
}

export function ConfirmationDialog({
  title,
  content,
  okText,
  okDisabled = false,
  cancelText,
  onClose,
  width = "629px",
  height = "188px",
  className,
  type = "primary",
}: ConfirmationDialogProps) {
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width,
          height,
        },
      }}
      maxWidth={false}
      open={true}
      onClose={handleCancel}
      className={className}
    >
      <DialogTitle
        sx={{ display: "flex", alignItems: "center" }}
        component={"div"}
      >
        <Typography sx={{ flexGrow: 1 }} variant="h3">
          {title}
        </Typography>
        <IconButton
          onClick={handleCancel}
          sx={{
            color: colors.gray[500],
            width: "20px",
            height: "20px",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Button autoFocus onClick={handleCancel}>
          {cancelText || "Cancel"}
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          color={type}
          disabled={okDisabled}
        >
          {okText || "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
