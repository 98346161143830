import { useMemo } from "react";

import { AgentDowntimeRawKubernetesAgentInfo } from "./agentDowntimeTypes";

import { useDataForFlag } from "@/shared/hooks/useDataForFlag";
import { TimeWindow } from "@/shared/types/TimeWindow";
import { formatDateWithUtcSuffix } from "@/shared/utils/dateUtils";
import { useListAgents } from "@/shared/hooks/agents-api/agents/useListAgents";
import { AgentsApiApiV1AgentsGetRequest } from "@/generated/agents";

const formatCreatedAtAndLastSeenAlive = (
  row: AgentDowntimeRawKubernetesAgentInfo
) => {
  const { createdAt, lastSeenAlive } = row;
  return {
    ...row,
    createdAt: formatDateWithUtcSuffix(createdAt),
    lastSeenAlive: formatDateWithUtcSuffix(lastSeenAlive),
  };
};

export const useAgentDowntimeRawData = (
  shouldShowAgentDowntimeEvent: boolean,
  timeWindow: Pick<TimeWindow, "start" | "end">,
  clusterName?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): AgentDowntimeRawKubernetesAgentInfo[] | null => {
  const agentDowntimeEventsParams: AgentsApiApiV1AgentsGetRequest =
    useMemo(() => {
      return {
        startDate: timeWindow.start.toISOString(),
        endDate: timeWindow.end.toISOString(),
        clusterNames: clusterName ? [clusterName] : undefined,
      };
    }, [timeWindow.start, timeWindow.end, clusterName]);

  const { data: agents } = useListAgents({
    enabled: shouldShowAgentDowntimeEvent,
    params: agentDowntimeEventsParams,
  });

  const relevantResults = useMemo(
    () => agents?.map(formatCreatedAtAndLastSeenAlive),
    [agents]
  );
  return useDataForFlag(shouldShowAgentDowntimeEvent, relevantResults);
};
