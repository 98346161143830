import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import {
  DEFAULT_PAGE_SIZES,
  selectAriaLabels,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
`;

const pageSizes = DEFAULT_PAGE_SIZES.map((size) => ({
  label: size.toString(),
  value: size,
}));

const fontSize = "14px";

type PageSizeProps = {
  onChange(size: number): void;
  defaultPageSize?: number;
};

export const PageSize: React.FC<PageSizeProps> = ({
  onChange: onLocalStorageChange,
  defaultPageSize,
}) => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      pageSize: defaultPageSize,
    },
  });
  const initialPageSize = defaultPageSize ?? pageSizes[0].value;

  const pageSize = watch("pageSize", initialPageSize);

  return (
    <Container>
      <Typography fontSize={fontSize} color={muiColors.gray[600]}>
        Show
      </Typography>

      <Controller
        name="pageSize"
        control={control}
        defaultValue={pageSize}
        render={({ field }) => {
          return (
            <SingleSelect
              ariaLabel={selectAriaLabels.singleSelect}
              size="small"
              options={pageSizes}
              value={{
                label: (field.value ?? initialPageSize).toString(),
                value: field.value,
              }}
              onChange={(selectedOption) => {
                const newValue = selectedOption?.value ?? initialPageSize;

                setValue("pageSize", newValue);
                onLocalStorageChange(newValue);
              }}
            />
          );
        }}
      />

      <Typography fontSize={fontSize} color={muiColors.gray[600]}>
        per page
      </Typography>
    </Container>
  );
};
