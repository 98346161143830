import { array, object, string } from "yup";

const POLICIES_MESSAGE = "Please select policies";

export const roleValidationSchema = object()
  .shape({
    name: string().required("Please fill role name"),
    rbacPolicies: array().min(1, POLICIES_MESSAGE),
  })
  .required();
