import { Quota } from "@komodorio/design-system/icons";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesResourceQuotaResource = {
  NameInK8S: "resourcequotas",
  Group: "configuration",
  NavBarName: "Resource Quotas",
  PresentName: "Resource Quotas",
  Namespaced: true,
  UnnecessaryColumns: ["Request", "Limit"],
  Kind: SupportedResourcesKinds.KubernetesResourceQuotaResource,
  Icon: Quota,
  hasLabels: true,
};
