import _ from "lodash";
import { Pod } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { KubernetesResource } from "./KubernetesResource";
import { PodStatuses } from "./PodStatuses";
import { InitPodStatuses } from "./InitPodStatuses";
import { ContainerNames, OwnerReferenceNameJsonPath } from "./constants";
import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/SupportedResourcesKinds";

export const KubernetesPodsResource: KubernetesResource = {
  NameInK8S: "pods",
  Group: "workloads",
  NavBarName: "Pods",
  PresentName: "Pods",
  Namespaced: true,
  ShowStatusFilter: true,
  UnnecessaryColumns: ["Nominated node"],
  DefaultSort: {
    ColumnName: "status",
    Direction: Direction.up,
  },
  CustomSortOrder: {
    status: _.merge(PodStatuses, InitPodStatuses),
  },
  CustomColumns: [OwnerReferenceNameJsonPath, ContainerNames],
  SupportedActions: [
    AvailableActions.FetchLogs,
    AvailableActions.Delete,
    AvailableActions.ExecPodShell,
    AvailableActions.PortForward,
  ],
  Headers: [
    {
      name: "Name",
    },
    {
      name: "Namespace",
    },
    {
      name: "Ready",
    },
    {
      name: "Status",
    },
    {
      name: "Restarts",
    },
    {
      name: "Age",
    },
    {
      name: "ReadinessGates",
      hide: true,
    },
    {
      name: "ControlledBy",
    },
    {
      name: "Node",
    },
    {
      name: "ContainerNames",
      hide: true,
    },
    {
      name: "Images",
      hide: true,
    },
    {
      name: "ip",
      hide: true,
      displayName: "IP",
    },
  ],
  Kind: SupportedResourcesKinds.KubernetesPodsResource,
  Icon: Pod,
  DatabaseFetch: true,
  hasLabels: true,
};
