import React, { useEffect } from "react";

import {
  useActiveAgent,
  useAgentPropertiesById,
} from "../../../../../../../shared/hooks/useAgents";
import { doesAgentVersionSupportResourceListFromATM } from "../../../../../../../shared/utils/agent/agent";
import { ResourceListTable } from "../../../../../../common/ResourceListTable/ResourceListTable";
import InspectionUpgradeRequired from "../../../../../../Inspection/InspectionUpgradeRequired";
import { Panel } from "../../../../../../Inspection/styles";
import { SelfSupplyingResourceListTableProps } from "../types";
import { useIngressResources } from "../../../../../../../shared/hooks/useIngressResources/useIngressResources";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

export const IngressListTable: React.FC<
  SelfSupplyingResourceListTableProps
> = ({
  serviceId,
  cluster,
  rowSelection,
  namespace,
  eventsDetectionTimeframe,
  searchTerm,
  showOnlySelectedRows = false,
  ...props
}) => {
  const agentId = useActiveAgent(cluster);
  const { agentInfo } = useAgentPropertiesById(agentId ?? "");

  const { ingresses, refresh, isLoading } = useIngressResources(
    serviceId,
    cluster
  );

  const filteredRows = filterResourceTableBySearchTerm(ingresses, searchTerm);
  const unselectableRowNames = getUnselectableRowNames(ingresses, rowSelection);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(
    () => reportLoadingState("relatedResourcesIngressData", !!isLoading),
    [isLoading, reportLoadingState]
  );

  if (!doesAgentVersionSupportResourceListFromATM(agentInfo)) {
    return (
      <Panel>
        <InspectionUpgradeRequired />
      </Panel>
    );
  }

  return (
    <ResourceListTable
      cluster={cluster}
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredRows, rowSelection?.selectedRowNames ?? [])
          : filteredRows
      }
      refreshResults={refresh}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      isPaginating={isLoading}
      overridingColumns={overridingColumns}
      {...props}
    />
  );
};

const overridingColumns = [
  {
    name: "name",
    clickable: false,
  },
  {
    name: "namespace",
    clickable: false,
  },
];
