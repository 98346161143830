import { GridColDef } from "@mui/x-data-grid-pro";
import { useCallback } from "react";
import Skeleton from "@mui/material/Skeleton";

import { BasicViolation } from "@/generated/reliabilityApi";
import {
  useEmptyColumns,
  useViolationsTableColumns,
} from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/violationTableHooks";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { CustomColumnDefinition } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/context/ViolationsTableContext";
import { Dictionary } from "@/shared/types/Dictionary";
import { ConnectedWorkloadsCell } from "@/shared/components/ConnectedWorkloadsCell/ConnectedWorkloadsCell";
import { connectedWorkloadsTableColumns } from "@/shared/components/ConnectedWorkloadsCell/constants";

export const customColumnsDefinitions: Dictionary<GridColDef<BasicViolation>> =
  {
    duration: {
      field: "duration",
      headerName: "Duration",
      width: 120,
      sortable: false,
    },
    impactedServices: {
      field: "impactedServices",
      headerName: "Impacted Services",
      width: 140,
      sortable: false,
    },
  };

const customColumns: GridColDef<BasicViolation>[] = [
  {
    ...customColumnsDefinitions.duration,
    valueGetter: (params) => {
      return params.row.supportingData?.cascadingFailure?.duration ?? "N/A";
    },
  },
  {
    ...customColumnsDefinitions.impactedServices,
    renderCell: (params) => {
      const impactedServices =
        params.row.supportingData?.cascadingFailure?.impactedServices ?? [];
      return (
        <ConnectedWorkloadsCell
          host={""}
          source={""}
          connectedWorkloadsUids={impactedServices}
          modalProps={{ showMetadata: false }}
          tableProps={{ customColumns: getConnectedWorkloadsColumns() }}
        />
      );
    },
  },
];

const customLoadingStateColumns: GridColDef<BasicViolation>[] = [
  {
    ...customColumnsDefinitions.duration,
    renderCell: () => <Skeleton variant="text" width={100} />,
  },
  {
    ...customColumnsDefinitions.impactedServices,
    renderCell: () => <Skeleton variant="text" width={100} />,
  },
];

export const useGetCascadingFailureColumns = () => {
  const standardColumns = useViolationsTableColumns({
    violationTableType: ViolationsTableType.riskAssessment,
  });
  const loadingStateColumns = useEmptyColumns({
    violationTableType: ViolationsTableType.riskAssessment,
  });

  return useCallback((): CustomColumnDefinition => {
    return {
      customColumns: addCustomColumns([...standardColumns], customColumns),
      loadingStateColumns: addCustomColumns(
        [...loadingStateColumns],
        customLoadingStateColumns
      ),
    };
  }, [loadingStateColumns, standardColumns]);
};

const addCustomColumns = (
  orgColumns: GridColDef<BasicViolation>[],
  customColumns: GridColDef<BasicViolation>[]
) => {
  const statusColumnIndex = orgColumns.findIndex(
    (column) => column.field === "status"
  );
  const statusColumn = orgColumns.splice(statusColumnIndex, 1);
  const allColumns = [...orgColumns, ...customColumns];
  allColumns.push(statusColumn[0]);

  return allColumns;
};

export const getConnectedWorkloadsColumns = () => {
  const originalColumns = [...connectedWorkloadsTableColumns];
  const clusterCell = {
    field: "cluster",
    headerName: "Cluster",
    flex: 1,
  };
  originalColumns.splice(1, 0, clusterCell);
  return originalColumns;
};
