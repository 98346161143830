import { useMemo } from "react";
import { toNumber } from "lodash";

import { Args as UseInitiateSocketSession } from "../../../sockets/useInitiateSocketSession";
import { logsCommandOptions } from "../constants";
import { getTail } from "../podLogsUtils";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useLogsViewerInLocalStorage } from "@/components/common/LogsViewer/localStorage/useLogsViewerInLocalStorage";

interface Args {
  containerName?: string;
  podName: string;
  namespace: string;
  sinceTime?: string;
  isPreviousLogs?: boolean;
}

export const useGetLivePodLogsInitData = ({
  containerName,
  podName,
  namespace,
  sinceTime,
  isPreviousLogs,
}: Args) => {
  const { podsLogsTail, newLogsSettings } = useOverridableFlags();
  const [{ tail, sendTail }] = useLogsViewerInLocalStorage();

  //CU-86c14fqqq: this hook needs to be removed and use the tail from store once we remove newLogsSettings FF
  const tailLines = useMemo(() => {
    if (newLogsSettings) return getTail(tail, sendTail);
    return podsLogsTail ? toNumber(podsLogsTail) : logsCommandOptions.tail;
  }, [newLogsSettings, podsLogsTail, sendTail, tail]);

  return useMemo((): UseInitiateSocketSession["initData"] => {
    if (!containerName || !podName) {
      return {};
    }

    return {
      podName,
      containerName,
      namespace,
      podLogOptions: {
        tailLines: tailLines,
        follow: Boolean(!isPreviousLogs),
        sinceTime: !isPreviousLogs ? sinceTime : undefined,
        previous: Boolean(isPreviousLogs),
      },
    };
  }, [containerName, podName, namespace, tailLines, isPreviousLogs, sinceTime]);
};
