import React, { createContext, PropsWithChildren, useState } from "react";
import { AlertColor } from "@mui/material/Alert/Alert";

export type SnackbarMessage = {
  message: string;
  color: AlertColor;
};

export type SnackbarMessageContextState = {
  snackbarMessage: SnackbarMessage | undefined;
  setSnackbarMessage: (message: SnackbarMessage | undefined) => void;
};

const initialState: SnackbarMessageContextState = {
  snackbarMessage: undefined,
  setSnackbarMessage: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const SnackbarMessageContext =
  createContext<SnackbarMessageContextState>(initialState);

export const SnackbarMessageContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState<
    SnackbarMessage | undefined
  >(initialState.snackbarMessage);

  const state: SnackbarMessageContextState = {
    snackbarMessage,
    setSnackbarMessage,
  };

  return (
    <SnackbarMessageContext.Provider value={state}>
      {children}
    </SnackbarMessageContext.Provider>
  );
};
