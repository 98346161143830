import { useCallback } from "react";

import { useLocalStorage } from "@/shared/hooks/localStorage/useLocalStorage";
import {
  defaultInspectionViewPreferences,
  InspectionViewPreferences,
  inspectionViewPreferencesInLsItem,
} from "@/components/Inspection/InspectionViews/localStorage";

export const useInspectionPreferencesInLocalStorage = () => {
  return useLocalStorage<InspectionViewPreferences>({
    localStorageItem: inspectionViewPreferencesInLsItem,
    defaultValue: defaultInspectionViewPreferences,
  });
};

export const useTablePageSizeInLs = () => {
  const [inspectionViewPreferencesInLs, setInspectionViewPreferencesInLs] =
    useInspectionPreferencesInLocalStorage();

  const onSetPageSize = useCallback(
    (pageSize: number) => {
      const newPreferences = {
        ...inspectionViewPreferencesInLs,
        tablePreferences: {
          pageSize,
        },
      };
      setInspectionViewPreferencesInLs(JSON.stringify(newPreferences));
    },
    [inspectionViewPreferencesInLs, setInspectionViewPreferencesInLs]
  );

  return [
    inspectionViewPreferencesInLs.tablePreferences.pageSize,
    onSetPageSize,
  ] as const;
};
