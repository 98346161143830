import { useMemo } from "react";
import { Area } from "recharts";

import { colorPalette, GraphDataKeyIndicator } from "../constants";

import { CustomGraphDataPoint } from "@/components/Metrics/types";
import {
  AggregationType,
  Datapoint,
  NodesOverTimeResponse,
  NodesOverTimeResponseSingleAggregationGrouped,
} from "@/generated/metricsApi";
import { MetricArea } from "@/components/Metrics/graphs/StackedAreaChart";
import { LegendItem } from "@/components/Metrics/GenericLegend/GenericLegend";

type Props = {
  data: NodesOverTimeResponse | undefined;
  aggregation: AggregationType;
};

type ClusterData = { clusterName: string; count: Datapoint[] };

const allClustersWithOther = (
  nodesOverTimeResponse:
    | NodesOverTimeResponseSingleAggregationGrouped
    | undefined
): ClusterData[] => {
  return [
    ...(nodesOverTimeResponse?.topClusters
      ? Object.entries(nodesOverTimeResponse.topClusters).map(
          ([clusterName, count]) => ({
            clusterName,
            count,
          })
        )
      : []),
    ...(nodesOverTimeResponse?.other
      ? [{ clusterName: "Other", count: nodesOverTimeResponse.other }]
      : []),
  ];
};

const useTransformClustersNodesOverTimeData = ({
  data,
  aggregation,
}: Props) => {
  return useMemo<CustomGraphDataPoint[]>(() => {
    if (!data?.[aggregation]) return [];

    const timeObj = allClustersWithOther(data[aggregation]).reduce<
      Record<number, Record<string, number | null>>
    >((acc, cluster) => {
      cluster.count?.forEach((datapoint) => {
        const clusterKey = cluster.clusterName;
        if (!clusterKey) return;

        const value = datapoint.value >= 0 ? datapoint.value : null;

        if (!acc[datapoint.timestampMs]) {
          acc[datapoint.timestampMs] = { [clusterKey]: value };
        }
        acc[datapoint.timestampMs] = {
          ...acc[datapoint.timestampMs],
          [clusterKey]: value,
        };
      });
      return acc;
    }, {});

    return Object.entries(timeObj).map(([timestamp, clusterData]) => ({
      time: Number(timestamp),
      ...clusterData,
    }));
  }, [aggregation, data]);
};

const useTooltipItems = ({ data, aggregation }: Props) => {
  return useMemo(() => {
    if (!data?.[aggregation]) return [];
    return (
      allClustersWithOther(data[aggregation]).map((cluster, index) => ({
        dataKey: cluster.clusterName,
        title: cluster.clusterName,
        tooltipItemKey: cluster.clusterName,
        icon: <GraphDataKeyIndicator index={index} />,
      })) ?? []
    );
  }, [aggregation, data]);
};

const useLegendItems = ({ data, aggregation }: Props): LegendItem[] => {
  return useMemo(() => {
    if (!data?.[aggregation]) return [];
    return allClustersWithOther(data[aggregation]).reduce<LegendItem[]>(
      // [CU-86c1gn74n] fix max-params
      // eslint-disable-next-line max-params
      (acc, curr, index) => {
        if (curr?.clusterName) {
          acc.push({
            label: curr.clusterName,
            color: colorPalette[index % colorPalette.length].stroke,
          });
        }
        return acc;
      },
      []
    );
  }, [aggregation, data]);
};

const useNodesOverTimeAreas = ({ data, aggregation }: Props) => {
  return useMemo<MetricArea[]>(() => {
    if (!data?.[aggregation]) return [];
    const areas: MetricArea[] = [];
    allClustersWithOther(data[aggregation]).forEach((cluster, index) => {
      if (cluster?.clusterName) {
        const colorItem = colorPalette[index % colorPalette.length];
        areas.push(
          <Area
            key={cluster.clusterName}
            type="step"
            dataKey={cluster.clusterName}
            stackId="1"
            stroke={colorItem.stroke}
            strokeWidth={2}
            fill={colorItem.fill}
          />
        );
      }
    });

    return areas;
  }, [aggregation, data]);
};

export const useStackedAreaChartProps = (props: Props) => {
  const tooltipItems = useTooltipItems(props);
  const nodesOverTimeData = useTransformClustersNodesOverTimeData(props);
  const areas = useNodesOverTimeAreas(props);
  const legendItems = useLegendItems(props);

  return {
    tooltipItems,
    nodesOverTimeData,
    areas,
    legendItems,
  };
};
