import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ACTIONS_CONTAINER_WIDTHS } from "../../../../../../shared-ui/design-system/src/components/komodor-ui/Select/shared/components/DropDownTriggerField";
import { SingleSelectProps } from "../../../../../../shared-ui/design-system/src/components/komodor-ui/Select/SingleSelect/singleSelectTypes";
import { AdvancedMultiSelectProps } from "../../../../../../shared-ui/design-system/src/components/komodor-ui/Select/AdvancedMultiSelect/multiSelectTypes";
import { ResourceBreadcrumbs } from "../ResourceBreadcrumbs";
import { useDriftComparisonContext } from "../context/useDriftComparisonContext";

import {
  Container,
  StyledAdvancedMultiSelect,
  StyledSingleSelect,
} from "./styles";
import {
  customSearch,
  optionToCustomElement,
  optionToCustomElementMultiSelect,
} from "./selectionUtils";
import { useResetBaselineOnWorkspaceChange } from "./useResetBaselineOnWorkspaceChange";
import { policyPopupStorage } from "./constants";
import { PolicyCreationPopup } from "./PolicyCreationPopup";

import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { dispatchEvent } from "@/shared/hooks/analytics";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export interface ComparisonSelectionProps {
  singleSelectProps: SingleSelectProps<string>;
  advancedMultiSelectProps: AdvancedMultiSelectProps<string>;
  baselineKind?: string;
  maxCompared: number;
  comparedOptionsLength: number;
  baselineSelectEvent: string;
  comparedSelectEvent: string;
  withPolicyPopup?: boolean;
}

export const BaseComparisonSelection: React.FC<ComparisonSelectionProps> = ({
  singleSelectProps,
  advancedMultiSelectProps,
  baselineKind,
  maxCompared,
  comparedOptionsLength,
  baselineSelectEvent,
  comparedSelectEvent,
  withPolicyPopup = false,
}) => {
  const {
    baselineSelection,
    setBaselineSelection,
    setComparedSelections,
    comparedSelections,
    isComparedSelectorOpen,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  } = useDriftComparisonContext();

  const ceilComparedOptions = useMemo(
    () => Math.min(maxCompared, comparedOptionsLength),
    [maxCompared, comparedOptionsLength]
  );

  const onBaselineSelect = useCallback(
    (value: MuiSelectionOption<string> | undefined) => {
      setComparedSelections([]);
      setBaselineSelection(value ? value : null);
      const parsedKomodorUid = value?.label
        ? parseKomodorUid(value?.label)
        : undefined;
      if (!parsedKomodorUid) return;
      dispatchEvent(baselineSelectEvent, parsedKomodorUid);
    },
    [setBaselineSelection, setComparedSelections, baselineSelectEvent]
  );

  const onComparedServicesSelectionChange = useCallback(
    (value: MuiSelectionOption<string>[]) => {
      const isAdded = value.length > (comparedSelections?.length ?? 0);
      if (isAdded) {
        const parsedKomodorUids = value
          .map((val) => parseKomodorUid(val.label))
          .filter(Boolean);
        dispatchEvent(comparedSelectEvent, {
          services: parsedKomodorUids,
        });
      }

      setComparedSelections(
        value.map((v) => ({
          value: v.value,
          label: v.label,
        }))
      );
    },
    [comparedSelections?.length, comparedSelectEvent, setComparedSelections]
  );

  useEffect(() => {
    if (!baselineSelection) {
      setComparedSelections([]);
    }
  }, [baselineSelection, setComparedSelections]);

  useResetBaselineOnWorkspaceChange();
  const { driftAnalysisPolicyPopup } = useOverridableFlags();

  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  useEffect(() => {
    if (
      !isComparedSelectorOpen &&
      comparedSelections?.length &&
      !policyPopupStorage.get() &&
      withPolicyPopup &&
      driftAnalysisPolicyPopup
    ) {
      setShowPolicyPopup(true);
    }
  }, [
    isComparedSelectorOpen,
    comparedSelections,
    withPolicyPopup,
    driftAnalysisPolicyPopup,
  ]);

  const handleClosePolicyPopup = useCallback(() => {
    setShowPolicyPopup(false);
  }, []);
  return (
    <ResponsiveLayout>
      <Container>
        <StyledSingleSelect
          virtualizerProps={{ estimateSize: () => 28 }}
          width="360px"
          minWidth="360px"
          enableSearch
          customSearch={customSearch}
          disableBlankOption
          onChange={onBaselineSelect}
          customOptionElement={optionToCustomElement}
          selectPopoverProps={{
            maxHeight: "544px",
            width: "480px",
          }}
          enableVirtualization
          classNames={{
            menuList: "narrowMenuList",
            menuItem: "narrowMenuItem",
            searchBar: "narrowSearchBar",
          }}
          onClose={() => setIsBaselineSelectorOpen(false)}
          customValueElement={(option) => {
            const { cluster, namespace, name } =
              parseKomodorUid(option.label) ?? {};
            return (
              <ResourceBreadcrumbs
                clusterName={cluster ?? ""}
                namespace={namespace ?? ""}
                name={name ?? ""}
                maxWidth={`${360 - ACTIONS_CONTAINER_WIDTHS.withClear}px`}
              />
            );
          }}
          {...singleSelectProps}
        />
        <StyledAdvancedMultiSelect
          enableLineBreaks={false}
          virtualizerProps={{ estimateSize: () => 28 }}
          minWidth="220px"
          enableSelectAll
          searchWithSelectAllHeight="62px"
          menuListPadding="0px"
          selectionLimit={maxCompared}
          customOptionElement={optionToCustomElementMultiSelect}
          onChange={onComparedServicesSelectionChange}
          enableSearch
          customSearch={customSearch}
          texts={{
            deselectAllText: "Clear all",
            selectAllText:
              ceilComparedOptions < maxCompared
                ? "Select all"
                : `Select the first ${ceilComparedOptions}`,
          }}
          width="100%"
          selectPopoverProps={{
            maxHeight: "346px",
          }}
          classNames={{
            disabledItems: "narrowMenuList",
            enabledItems: "narrowMenuList",
            menuListContainer: "narrowMenuListContainer",
            menuItem: "narrowMenuItem",
            searchBar: "narrowSearchBar",
            stickyContainer: "stickyContainer",
            itemSelectionMenu: "narrowItemSelectionMenu",
          }}
          enableVirtualization
          onClose={() => setIsComparedSelectorOpen(false)}
          onOpen={() => setIsComparedSelectorOpen(true)}
          {...advancedMultiSelectProps}
        />
      </Container>
      {showPolicyPopup && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: "100%",
              positionArea: "center",
              marginTop: "-10px",
            }}
          >
            <PolicyCreationPopup onClose={handleClosePolicyPopup} />
          </Box>
        </Box>
      )}
      {baselineKind && (
        <Box sx={{ display: "flex", marginTop: "5px", gap: "5px" }}>
          <Typography variant="h6" color="text.secondary">
            Kind:
          </Typography>
          <Typography fontSize="12px">{baselineKind}</Typography>
        </Box>
      )}
    </ResponsiveLayout>
  );
};
