import { ResourcesScope } from "@/generated/auth";
import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";

export const prepareResourcesScope = (
  resourceScope: ResourcesScope,
  scope: Scope
) => {
  if (scope === Scope.namespace) {
    return resourceScope;
  }

  resourceScope.namespacesPatterns.push({
    include: "*",
    exclude: "",
  });
  return resourceScope;
};
