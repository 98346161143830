import { TagsCell } from "@komodorio/design-system/komodor-ui";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { policyTagsToArray } from "@/components/Settings/Policies/policyForm/PolicyTags";

const LABEL_PREFIX = "Policy";

export const getTagsCell = ({
  params,
  width,
}: {
  params: GridRenderCellParams;
  width: number;
}) => {
  const itemMapper = (tag: string) => ({
    key: tag,
    label: tag,
  });

  return (
    <TagsCell
      items={policyTagsToArray(params.row.tags) ?? []}
      textPath=""
      itemMapper={itemMapper}
      labelPrefix={LABEL_PREFIX}
      cellWidth={width}
    />
  );
};
