import { isRuleGroupType, RuleGroupType, RuleType } from "react-querybuilder";

import {
  createEmptyResourcesScope,
  handleClusterField,
  handleKeyField,
  handleNamespaceField,
  handleValueField,
} from "@/pages/organization-settings/access-management/PoliciesPage/utils/queryToResourcesScope";
import { ResourcesScope } from "@/generated/auth";

const handlers = {
  cluster: handleClusterField,
  namespace: handleNamespaceField,
  key: handleKeyField,
  value: handleValueField,
};

type FieldName = "cluster" | "namespace" | "key" | "value";

export const getFieldsRulesFromQuery = (
  query: RuleGroupType | string | null,
  fieldsNames: FieldName[]
): Partial<ResourcesScope> | undefined => {
  if (!isRuleGroupType(query)) {
    return undefined;
  }

  const groups = query.rules;
  if (groups.length === 0) {
    return undefined;
  }

  const resourcesScope = createEmptyResourcesScope();
  groups.forEach((group: RuleGroupType | RuleType) => {
    if (!isRuleGroupType(group)) {
      return;
    }

    group.rules.forEach((rule) => {
      if (!isRuleGroupType(rule)) {
        if (fieldsNames.includes(rule.field as FieldName)) {
          handlers[rule.field as FieldName]({
            rule,
            resourcesScope,
            ruleGroup: group,
          });
        }
      }
    });
  });

  return resourcesScope;
};
