import React from "react";
import styled, { css } from "styled-components";
import ReactModal from "react-modal";

import { IconButton } from "../IconButton/IconButton";
import { Close24 } from "../../../icons";

const modalStyleProperties = (
  width: string,
  height: string
): ReactModal.Styles => ({
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    background: "rgba(22, 24, 31, 0.5)",
  },
  content: {
    width,
    height,
    maxHeight: "calc(100vh - 60px)",
    borderRadius: "1rem",
    padding: "1rem 0",
    top: "unset",
    left: "unset",
    right: "unset",
    bottom: "unset",
    border: "unset",
  },
});

const CloseButton = styled(IconButton).attrs({ icon: Close24, noBorder: true })`
  position: absolute;
  margin-inline: 1rem;
  right: 0;
`;

const paddingStyle = css`
  padding-inline: 2rem;
`;

/** @deprecated */
export const ModalHeader = styled.div`
  ${paddingStyle}
  padding-block-end: 1rem;
`;

/** @deprecated */
export const ModalContent = styled.div`
  ${paddingStyle}
  padding-block: 1rem;
`;

/** @deprecated */
export type ModalActionsAlignment = "start" | "center" | "end";

/** @deprecated */
export const ModalActions = styled.div<{
  align?: ModalActionsAlignment;
}>`
  ${paddingStyle}
  padding-block-start: 1rem;
  display: flex;
  column-gap: 1rem;
  justify-content: ${({ align }) => align ?? "end"};
`;

/** @deprecated */
export interface ModalProps extends ReactModal.Props {
  width?: string;
  height?: string;
  hideClose?: boolean;
}

/**
 * @deprecated Use MUI Modal instead.
 */
export const Modal: React.FC<ModalProps> = ({
  width = "fit-content",
  height = "fit-content",
  hideClose = false,
  children,
  ...rest
}) => {
  const { onRequestClose } = rest;
  return (
    <ReactModal style={modalStyleProperties(width, height)} {...rest}>
      {!hideClose && <CloseButton onClick={onRequestClose} />}
      {children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  width: "fit-content",
  height: "fit-content",
  hideClose: false,
  shouldReturnFocusAfterClose: false,
  ariaHideApp: false,
};
