/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Code from "@mui/icons-material/Code";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import omit from "lodash/omit";
import { useNavigate, useParams } from "react-router-dom";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { POLICIES_ROUTE, POLICY_ID_PARAM } from "@/components/routes/routes";
import { createPolicyAriaLabels } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ariaLabels";
import { StatementBar } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/StatementBar";
import { DeleteStatementConfirmationDialog } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/DeleteStatementConfirmationDialog";
import { Dictionary } from "@/shared/types/Dictionary";
import { ShowJSONDrawer } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ShowJsonDrawer/ShowJSONDrawer";
import { ShowJSONDrawerContent } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/ShowJsonDrawer/ShowJSONDrawerContent";
import { UnsavedChangesDialog } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/UnsavedChangesDialog";
import {
  RbacPolicy,
  RbacPolicyStatementsInner,
  RbacPolicyType,
} from "@/generated/auth";
import { useCreateRbacPolicy } from "@/shared/hooks/auth-service/client/rbacPolicies/useCreateRbacPolicy";
import { validatePolicySchema } from "@/pages/organization-settings/access-management/PoliciesPage/utils/policyValidation";
import {
  ButtonPanel,
  H5LabeledTextInput,
  PageTitle,
  PolicySkeleton,
} from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/components";
import { useGetRbacPolicyById } from "@/shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicyById";
import { useUpdateRbacPolicy } from "@/shared/hooks/auth-service/client/rbacPolicies/useUpdateRbacPolicy";
import { useGetRbacPolicies } from "@/shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicies";
import { RbacActionsProvider } from "@/pages/organization-settings/access-management/PoliciesPage/RbacActionsContext/RbacActionsProvider";
import { StatementDrawerProvider } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/StatementDrawerProvider";
import { AddStatementDrawer } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/AddStatementDrawer";
import { AddStatementDrawerContent } from "@/pages/organization-settings/access-management/PoliciesPage/CreatePolicy/AddStatementDrawer/AddStatementDrawerContent";

enum PageMode {
  Create = "create",
  Edit = "edit",
}

export const CreatePolicyPage = () => {
  const [addStatementOpen, setAddStatementOpen] = useState(false);
  const [showJSONDrawer, setShowJSONDrawer] = useState(false);
  const [statementToEdit, setStatementToEdit] = useState<{
    statement: RbacPolicyStatementsInner;
    index: number;
  } | null>(null);
  const [statementToDelete, setStatementToDelete] = useState<number | null>(
    null
  );
  const [isDirty, setIsDirty] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { refetch: refetchPolicies } = useGetRbacPolicies();
  const { mutateAsync: createPolicy, isLoading: addLoading } =
    useCreateRbacPolicy();
  const { mutateAsync: updatePolicy, isLoading: updateLoading } =
    useUpdateRbacPolicy();

  const [policy, setPolicy] = useState<Partial<RbacPolicy>>({
    statements: [],
  });
  const [errors, setErrors] = useState<Dictionary<string>>({});

  const policyId = useMemo(() => params?.[POLICY_ID_PARAM] || "", [params]);

  const { data, isFetching } = useGetRbacPolicyById(
    {
      id: policyId,
    },
    { enabled: !!policyId, refetchOnMount: "always" }
  );

  useEffect(() => {
    if (data) {
      setPolicy(data[0]);
    }
  }, [data, policy?.id]);

  const pageMode = useMemo(() => {
    return policyId ? PageMode.Edit : PageMode.Create;
  }, [policyId]);

  const submitButtonText = useMemo(() => {
    if (addLoading) {
      return "Creating";
    }
    if (updateLoading) {
      return "Updating";
    }
    return pageMode === PageMode.Create ? "Create" : "Update";
  }, [addLoading, updateLoading, pageMode]);

  const onAddStatement = useCallback(
    (newStatement: RbacPolicyStatementsInner) => {
      setPolicy((prevPolicy) => ({
        ...prevPolicy,
        statements: [
          ...(prevPolicy?.statements as RbacPolicyStatementsInner[]),
          newStatement,
        ],
      }));
      setIsDirty(true);
    },
    []
  );

  const onSubmit = useCallback(async () => {
    const validationErrors = await validatePolicySchema(policy);

    if (Object.keys(validationErrors ?? {}).length > 0) {
      setErrors(validationErrors ?? {});
      return;
    }

    if (!policy.statements || policy.statements.length === 0) {
      setErrors({ general: "At least one statement is required" });
      return;
    }

    try {
      if (pageMode === PageMode.Create) {
        await createPolicy({
          name: policy.name,
          type: RbacPolicyType.V2,
          description: policy.description,
          statements: policy.statements,
        });
      } else {
        await updatePolicy({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: params.id!,
          updateRbacPolicyRequest: {
            name: policy.name,
            description: policy.description,
            statements: policy.statements,
          },
        });
      }

      await refetchPolicies();
      navigate(POLICIES_ROUTE);
    } catch (e) {
      if (e instanceof Error) {
        setErrors({
          general: e.message,
        });
      }

      setErrors({
        general: "An error occurred. Please try again later.",
      });
    }
  }, [
    createPolicy,
    navigate,
    pageMode,
    params.id,
    policy,
    updatePolicy,
    refetchPolicies,
  ]);

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrors(omit(errors, "name"));
      setPolicy({ ...policy, name: e.target.value });
      setIsDirty(true);
    },
    [policy, errors]
  );

  const handleDeleteStatement = useCallback(
    (index: number) => {
      const { statements } = policy;
      if (!statements) return;

      const newStatements = [
        ...statements.slice(0, index),
        ...statements.slice(index + 1),
      ];
      setPolicy({
        ...policy,
        statements: newStatements,
      });
    },
    [policy]
  );

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm && statementToDelete !== null) {
        handleDeleteStatement(statementToDelete);
      }
      setStatementToDelete(null);
    },
    [handleDeleteStatement, statementToDelete]
  );

  const statements = useMemo(
    () =>
      policy.statements?.map((statement, index: number) => (
        <StatementBar
          key={index}
          index={index}
          statement={statement}
          onEdit={() => setStatementToEdit({ statement, index })}
          onDelete={setStatementToDelete}
        />
      )),
    [policy.statements, setStatementToDelete]
  );

  const handleCancel = useCallback(() => {
    if (isDirty) {
      setShowUnsavedChangesDialog(true);
    } else {
      navigate(POLICIES_ROUTE);
    }
  }, [isDirty, navigate]);

  const handleCloseUnsavedChangesDialog = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        navigate(POLICIES_ROUTE);
      }
      setShowUnsavedChangesDialog(false);
    },
    [navigate]
  );

  const handleEditStatement = useCallback(
    (updatedStatement: RbacPolicyStatementsInner) => {
      if (!updatedStatement) return;

      const index = statementToEdit?.index;
      if (index === undefined || index === null) return;
      const newStatements = [...(policy.statements ?? [])];
      newStatements.splice(index, 1, updatedStatement);

      setStatementToEdit(null);
      setPolicy((prevPolicy) => ({
        ...prevPolicy,
        statements: newStatements,
      }));
    },
    [policy.statements, statementToEdit?.index]
  );

  const handleCloseDrawer = useCallback(() => {
    setAddStatementOpen(false);
    setStatementToEdit(null);
  }, []);

  const handleAddStatementDrawerOpen = useCallback(() => {
    setStatementToEdit(null);
    setAddStatementOpen(true);
  }, []);

  const form = useMemo(
    () => (
      <Stack rowGap={"16px"}>
        <H5LabeledTextInput
          label={"Name"}
          placeholder="Policy name"
          aria-label={createPolicyAriaLabels.policyForm.name}
          onChange={handleChangeName}
          error={!!errors.name}
          helperText={errors.name}
          value={policy.name ?? ""}
        />
        <H5LabeledTextInput
          value={policy.description ?? ""}
          label={"Description"}
          placeholder="What does this policy enable users to do?"
          aria-label={createPolicyAriaLabels.policyForm.description}
          onChange={(e) =>
            setPolicy({ ...policy, description: e.target.value })
          }
        />
        <Stack rowGap="16px">
          <Stack>
            <Typography variant={"h5"}>Policy statements</Typography>
            <Typography variant={"body2"}>
              The policy will apply for <b>ANY</b> of the added statements
            </Typography>
          </Stack>
          <Stack rowGap="4px">{statements}</Stack>
          <Stack direction="row" columnGap="8px">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddStatementDrawerOpen}
              aria-label={createPolicyAriaLabels.buttons.addStatement}
            >
              Add statement
            </Button>
            <Button
              startIcon={<Code />}
              onClick={() => setShowJSONDrawer(true)}
              aria-label={createPolicyAriaLabels.buttons.viewJson}
              disabled={policy.statements?.length === 0}
            >
              View JSON
            </Button>
          </Stack>
        </Stack>

        {(errors.statements || errors.general) && (
          <Typography color="error" variant="body3">
            {errors.statements || errors.general}
          </Typography>
        )}
      </Stack>
    ),
    [
      errors.general,
      errors.name,
      errors.statements,
      handleAddStatementDrawerOpen,
      handleChangeName,
      policy,
      statements,
    ]
  );

  return (
    <SettingsViewVerticalLayout
      title={
        <PageTitle
          title={pageMode === PageMode.Edit ? "Edit policy" : "Create policy"}
          onCancelClick={handleCancel}
        />
      }
    >
      <RbacActionsProvider>
        {isFetching ? <PolicySkeleton /> : form}
        <ButtonPanel>
          <Stack direction="row" justifyContent="flex-end" columnGap="8px">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={addLoading}
              aria-label={createPolicyAriaLabels.policyForm.createButton}
            >
              {submitButtonText} Policy
            </Button>
          </Stack>
        </ButtonPanel>
        <StatementDrawerProvider
          open={addStatementOpen || statementToEdit !== null}
          statement={statementToEdit?.statement ?? undefined}
        >
          <AddStatementDrawer onClose={handleCloseDrawer}>
            <AddStatementDrawerContent
              onAddStatement={onAddStatement}
              onEditStatement={handleEditStatement}
              onClose={handleCloseDrawer}
            />
          </AddStatementDrawer>
        </StatementDrawerProvider>
        {statementToDelete !== null && (
          <DeleteStatementConfirmationDialog onConfirm={handleDeleteConfirm} />
        )}
        <ShowJSONDrawer
          open={showJSONDrawer}
          onClose={() => setShowJSONDrawer(false)}
        >
          <ShowJSONDrawerContent policy={policy} />
        </ShowJSONDrawer>
        {showUnsavedChangesDialog && (
          <UnsavedChangesDialog
            onClose={handleCloseUnsavedChangesDialog}
            entityName={"policy"}
          />
        )}
      </RbacActionsProvider>
    </SettingsViewVerticalLayout>
  );
};
