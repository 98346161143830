import { HelmChartInfo, HelmChartSummary, HelmManifest } from "komodor-types";
import pako from "pako";
import yaml from "js-yaml";

import getAgeFromDate from "../../utils/getAgeFromDate";

import {
  HelmChartAgentResponse,
  HelmChartSummaryWithAge,
  HelmSecret,
} from "./types";

export const HELM_SECRET_PREFIX = "sh.helm.release.v1.";
const CHART_NAME_PREFIX = "ChartName:";
const REVISION_PREFIX = "Revision:";

export interface HelmChartConvertedResponse {
  data: HelmChartInfo | undefined;
  errorMessage: string;
}

export const getHelmSecretName = (
  releaseName: string,
  revision: number
): string => {
  return `${HELM_SECRET_PREFIX}${releaseName}.v${revision}`;
};

export const parseHelmSecretName = (
  secretName: string
): {
  releaseName: string;
  revision: number;
} => {
  const match = secretName.match(
    new RegExp(`${HELM_SECRET_PREFIX}(.*?).v(\\d+)$`)
  );
  if (!match) {
    return { releaseName: "", revision: 0 };
  }
  return { releaseName: match[1] ?? "", revision: Number(match[2]) };
};

export const convertHelmChartsAgentDataToHelmChartsSummary = (
  data: unknown,
  cluster: string,
  setErrorMessage: (message: string) => void,
  namespace?: string,
  chartName?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): HelmChartSummary[] => {
  let helmChartSummaryList: HelmChartSummary[] = [];

  try {
    const parsedData = JSON.parse(data as string) as HelmChartAgentResponse[];
    helmChartSummaryList = parsedData.map(
      (item): HelmChartSummary => ({
        secretId: generateSecretId(
          chartName ? chartName : item.name,
          item.revision
        ),
        cluster: cluster,
        name: chartName ? chartName : item.name,
        namespace: namespace ? namespace : item.namespace,
        revision: String(item.revision),
        version: item.chart,
        status: item.status,
        lastUpdated: item.updated ? new Date(item.updated).toISOString() : "",
        ...(item.description && { description: item.description }),
      })
    );
  } catch (e) {
    setErrorMessage("Failed to parse Helm charts data");
  }
  return helmChartSummaryList;
};

export const convertHelmChartsAgentDataToHelmChartsSummaryWithAge = (
  data: unknown,
  cluster: string,
  setErrorMessage: (message: string) => void,
  namespace: string,
  chartName: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
): HelmChartSummaryWithAge[] => {
  return convertHelmChartsAgentDataToHelmChartsSummary(
    data,
    cluster,
    setErrorMessage,
    namespace,
    chartName
  ).map((c) => ({
    ...c,
    age: getAgeFromDate(new Date(c.lastUpdated)),
  }));
};

export const getSecretNameFromSecretId = (secretId: string): string => {
  const match = secretId.match(
    new RegExp(`${CHART_NAME_PREFIX}(.*?)${REVISION_PREFIX}(.*?)$`)
  );

  if (!match) {
    return "";
  }

  const [, chartName, revision] = match;

  return `${HELM_SECRET_PREFIX}${chartName}.v${revision}`;
};

export const generateSecretId = (
  releaseName: string,
  revision: string
): string => {
  return `${CHART_NAME_PREFIX}${releaseName}${REVISION_PREFIX}${revision}`;
};

const decodeTwiceAndUnzip = (result: string) => {
  const decodedBase64 = window.atob(result);
  const decodedBase64Twice = window.atob(decodedBase64);

  const arr = new Uint8Array(
    decodedBase64Twice.split("").map((char) => char.charCodeAt(0))
  );

  const data = pako.inflate(arr, { to: "string" });
  return JSON.parse(data) as HelmSecret;
};

export const convertResultToHelmChartInfo = (
  result: string,
  cluster: string
): HelmChartConvertedResponse => {
  let helmChartInfo = undefined;
  if (!result) return { data: helmChartInfo, errorMessage: "" };
  try {
    const parsedData = decodeTwiceAndUnzip(result);
    helmChartInfo = {
      cluster: cluster,
      secretId: generateSecretId(parsedData.name, parsedData.revision),
      name: parsedData.name,
      manifest: parsedData.manifest,
      parsedManifest: yaml
        .loadAll(parsedData.manifest, null, { json: true })
        .filter((item) => (item as HelmManifest)?.metadata?.name) as Record<
        string,
        unknown
      >[],
      values: parsedData.chart.values,
      notes: parsedData.info.notes ?? "",
    };
  } catch (e) {
    return {
      data: helmChartInfo,
      errorMessage: "failed to parse helm chart info",
    };
  }
  return { data: helmChartInfo, errorMessage: "" };
};
