import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { format } from "date-fns";

import { HelmChart } from "@/generated/addonsApi";
import { useDisableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import { generateRandRangeList } from "@/components/k8sAddons/utils/tableUtils";
import { useGenerateSkeleton } from "@/components/k8sAddons/hooks/table/useGenerateSkeleton";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import {
  HELM_UPDATED_AT_DATE_FORMAT,
  helmChartStatusToColor,
} from "@/components/k8sAddons/addons/HelmCharts/helmChartsConstants";

export const useColumnsConfig = () =>
  useDisableLocalSortingInTableColumns<HelmChart>({
    name: {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    clusterName: {
      field: "clusterName",
      headerName: "Cluster",
      flex: 1,
    },
    namespace: {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    revision: {
      field: "revision",
      headerName: "Revision",
      maxWidth: 80,
    },
    updatedAt: {
      field: "updatedAt",
      headerName: "Updated",
      flex: 1,
    },
    chart: {
      field: "chart",
      headerName: "Chart",
      minWidth: 180,
    },
    appVersion: {
      field: "appVersion",
      headerName: "Version",
      maxWidth: 70,
    },
    status: {
      field: "status",
      headerName: "Chart Status",
      width: 150,
    },
  });

export const useHelmChartsTableColumns = (): GridColDef<HelmChart>[] => {
  const columnsConfig = useColumnsConfig();
  return useMemo(() => {
    return [
      { ...columnsConfig.name },
      { ...columnsConfig.clusterName },
      { ...columnsConfig.namespace },
      { ...columnsConfig.revision },
      {
        ...columnsConfig.updatedAt,
        valueFormatter: ({ value }) => {
          let formattedDate;
          try {
            formattedDate = format(
              new Date(value),
              HELM_UPDATED_AT_DATE_FORMAT
            );
          } catch (e) {
            formattedDate = "";
          }
          return formattedDate;
        },
      },
      { ...columnsConfig.chart },
      { ...columnsConfig.appVersion },
      {
        ...columnsConfig.status,
        renderCell: ({ row }) => {
          return (
            <AddonStatusChip
              color={helmChartStatusToColor[row.status]}
              label={row.status}
            />
          );
        },
      },
    ];
  }, [
    columnsConfig.appVersion,
    columnsConfig.chart,
    columnsConfig.clusterName,
    columnsConfig.name,
    columnsConfig.namespace,
    columnsConfig.revision,
    columnsConfig.status,
    columnsConfig.updatedAt,
  ]);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof HelmChart, number[]>> = {
  name: generateRandRangeList(50, 150),
  clusterName: generateRandRangeList(50, 150),
  namespace: generateRandRangeList(50, 150),
  revision: generateRandRangeList(30, 60),
  updatedAt: generateRandRangeList(130, 150),
  chart: generateRandRangeList(120, 160),
  appVersion: generateRandRangeList(50, 80),
  status: generateRandRangeList(100, 150),
};

export const useHelmChartsLoadingColumns = (): GridColDef<HelmChart>[] => {
  const columnsConfig = useColumnsConfig();
  const generateSkeleton = useGenerateSkeleton(randRangeColumns);
  return useMemo(
    () => [
      {
        ...columnsConfig.name,
        renderCell: (gridRenderCellParams) => {
          return generateSkeleton({ gridRenderCellParams, columnName: "name" });
        },
      },
      {
        ...columnsConfig.clusterName,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "clusterName" }),
      },
      {
        ...columnsConfig.namespace,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "namespace" }),
      },
      {
        ...columnsConfig.revision,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "revision" }),
      },
      {
        ...columnsConfig.updatedAt,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "updatedAt" }),
      },
      {
        ...columnsConfig.chart,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "chart" }),
      },
      {
        ...columnsConfig.appVersion,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({ gridRenderCellParams, columnName: "appVersion" }),
      },
      {
        ...columnsConfig.status,
        renderCell: (gridRenderCellParams) =>
          generateSkeleton({
            gridRenderCellParams,
            columnName: "status",
            skeletonProps: { sx: { borderRadius: "16px" } },
          }),
      },
    ],
    [
      columnsConfig.appVersion,
      columnsConfig.chart,
      columnsConfig.clusterName,
      columnsConfig.name,
      columnsConfig.namespace,
      columnsConfig.revision,
      columnsConfig.status,
      columnsConfig.updatedAt,
      generateSkeleton,
    ]
  );
};
