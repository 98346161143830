import React, { useCallback, useMemo } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getAllChildIds, getSelectedChildrenIds } from "./utils";
import { TreeNodeProps } from "./types";
import { StyledAccordion } from "./styles";

export const TreeNode = ({
  node,
  level,
  selectedNodes,
  onCheck,
  expanded,
  onToggle,
  findNode,
}: TreeNodeProps) => {
  const { id, label, children, disabled } = node;
  const marginLeft = level * 3;

  const originalNode = useMemo(
    () => findNode(node.id) ?? node,
    [node, findNode]
  );

  const hasChildren = useMemo(
    () => originalNode.children?.length > 0,
    [originalNode.children?.length]
  );

  // Calculate checkbox state
  const isChecked = useMemo(() => {
    if (!hasChildren) return selectedNodes.has(id);
    const childIds = getAllChildIds(originalNode);
    const selectedChildIds = getSelectedChildrenIds(childIds, selectedNodes);

    return selectedChildIds.length === childIds.length;
  }, [hasChildren, selectedNodes, id, originalNode]);

  const isIndeterminate = useMemo(() => {
    if (!hasChildren || isChecked) return false;

    const childIds = getAllChildIds(originalNode);
    const selectedChildIds = getSelectedChildrenIds(childIds, selectedNodes);

    return (
      selectedChildIds.length > 0 && selectedChildIds.length < childIds.length
    );
  }, [originalNode, selectedNodes, hasChildren, isChecked]);

  const handleItemClick = useCallback(() => {
    if (disabled) return;
    if (hasChildren) return onToggle(node);
    return onCheck(node, !isChecked);
  }, [disabled, hasChildren, isChecked, node, onCheck, onToggle]);

  const AccordionItemWrapper = useMemo(
    () =>
      ({ children }) => {
        if (!hasChildren) {
          return (
            <AccordionDetails
              onClick={handleItemClick}
              aria-label={id}
              sx={{
                marginLeft: `${(marginLeft ?? 0) - 4}px`,
                padding: "0 16px",
                cursor: disabled ? "disabled" : "pointer",
              }}
            >
              {children}
            </AccordionDetails>
          );
        }

        return (
          <AccordionSummary
            sx={{
              marginLeft: `${(marginLeft ?? 0) - 4}px`,
              flexDirection: "row-reverse",
              padding: "0 16px",
              "& .MuiAccordionSummary-content": { marginY: 0 },
              minHeight: "0px",
            }}
            aria-label={id}
          >
            {children}
          </AccordionSummary>
        );
      },
    [hasChildren, marginLeft, id, handleItemClick, disabled]
  );

  return (
    <StyledAccordion
      elevation={0}
      sx={{
        borderWidth: hasChildren ? "1px" : "0px",
      }}
      // https://app.clickup.com/t/86c2dqm28
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      expanded={expanded}
      onChange={() => onToggle(node)}
    >
      <AccordionItemWrapper>
        <Stack direction="row" alignItems="center">
          <Box sx={{ paddingLeft: marginLeft }} />
          <Checkbox
            edge="start"
            checked={isChecked}
            indeterminate={isIndeterminate}
            inputProps={{
              "aria-checked": isIndeterminate ? "mixed" : isChecked,
              "aria-label": `${id}-checkbox`,
            }}
            onChange={(e) => onCheck(node, e.target.checked)}
            onClick={(e) => e.stopPropagation()}
            disabled={disabled}
            size="small"
          />
          {typeof label !== "string" ? (
            label
          ) : (
            <Typography
              variant={hasChildren ? "h5" : "body2"}
              color={disabled ? "text.disabled" : "text.primary"}
            >
              {label}
            </Typography>
          )}
        </Stack>
      </AccordionItemWrapper>
      {hasChildren && (
        <>
          {children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              level={level + 1}
              selectedNodes={selectedNodes}
              onCheck={onCheck}
              expanded={expanded}
              onToggle={onToggle}
              findNode={findNode}
            />
          ))}
        </>
      )}
    </StyledAccordion>
  );
};
