import React, { useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@komodorio/design-system/komodor-ui";
import { muiTheme } from "@komodorio/design-system";

import { useGetTrackedKeys } from "@/shared/hooks/accounts-service/client/trackedKeys/useGetTrackedKeys";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { StyledExternalLink } from "@/components/integrations/installation/sentry/steps/styles";
import { getColumnsDefinitions } from "@/pages/organization-settings/configurations/TrackedKeysPage/Grid/getColumnsDefinitions";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";
import { TrackedKey } from "@/generated/accounts";
import { DeleteTrackedKeyModal } from "@/pages/organization-settings/configurations/TrackedKeysPage/DeleteTrackedKeyModal";
import { AddKeyButton } from "@/pages/organization-settings/configurations/TrackedKeysPage/AddKeyButton";
import { Refetch } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const TrackedKeysPageContent = () => {
  const { data, isFetching, refetch } = useGetTrackedKeys({});
  const { maxAccountTrackedKeys } = useOverridableFlags();

  const [keyToDelete, setKeyToDelete] = React.useState<TrackedKey | null>(null);

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingTrackingKeys", isFetching);
  }, [isFetching, reportLoadingState]);

  const { ref, width } = usePageWidth();

  const shouldDisableAddButton = useMemo(
    () => (data?.length ?? 0) >= (maxAccountTrackedKeys as number),
    [data, maxAccountTrackedKeys]
  );

  const columns = useMemo(
    () => getColumnsDefinitions({ onDelete: setKeyToDelete }),
    [setKeyToDelete]
  );

  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();

  const rows = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((trackedKey: TrackedKey) => ({
      ...trackedKey,
      hovered: trackedKey.id === hoveredRowId,
    }));
  }, [data, hoveredRowId]);

  return (
    <SettingsViewVerticalLayout title="Tracked Keys">
      <Stack rowGap={3} flexGrow={1} ref={ref}>
        <Stack direction="row" columnGap={2} alignItems="center">
          <Typography variant="body2">
            Manage labels and annotations keys used in RBAC policies and
            workspace scoping
          </Typography>
          {/* TODO Lior: Add href */}
          <StyledExternalLink href={""} color={muiTheme.palette.primary.main}>
            <Stack direction="row" alignItems="center" columnGap={0.5}>
              <LaunchOutlined sx={{ height: "16px", width: "16px" }} />
              <Typography variant="body2" color="inherit">
                Learn More
              </Typography>
            </Stack>
          </StyledExternalLink>
          <Box sx={{ marginLeft: "auto" }}>
            <AddKeyButton
              handleRefetch={refetch as Refetch}
              disabled={shouldDisableAddButton}
            />
          </Box>
        </Stack>
        <Paper
          sx={{
            display: "flex",
            flexGrow: 1,
            width: width || "100%",
            overflow: "auto",
            maxHeight: "max-content",
          }}
          variant="elevation"
          elevation={1}
        >
          <DataGrid
            autoHeight
            columns={columns}
            rows={rows}
            loading={isFetching}
            slotProps={{
              row: {
                onMouseEnter: handleRowHovered,
                onMouseLeave: handleRowLeaved,
              },
            }}
          />
        </Paper>
        {!!keyToDelete && (
          <DeleteTrackedKeyModal
            onClose={() => setKeyToDelete(null)}
            keyToDelete={keyToDelete}
            refetch={refetch}
          />
        )}
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
