import { useMemo } from "react";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { Plan, useGetPlanDataQuery } from "@/generated/graphql";
import { useQueryWithVariables } from "@/shared/hooks/useQueryWithVariables";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  GetSinglePlansData,
  IHasuraMigrations,
} from "@/shared/constants/hasuraMigrationFeatureFlag";
import { useGetAccountPlan } from "@/shared/hooks/accounts-service/client/plan/useGetAccountPlan";

const useGetAccountPlanData = (): Plan | undefined => {
  const { accountPlan } = useUserMetadata();
  const { hasuraMigrations } = useOverridableFlags();
  const hasuraMigrationFF = hasuraMigrations as IHasuraMigrations;
  const shouldUseMigration =
    hasuraMigrationFF?.endpoints.includes(GetSinglePlansData);
  const fetchPlanVars = useMemo(
    () => (shouldUseMigration ? null : { accountPlan }),
    [accountPlan, shouldUseMigration]
  );
  const GqlData = useQueryWithVariables(useGetPlanDataQuery, fetchPlanVars);
  const restData = useGetAccountPlan(
    shouldUseMigration ? accountPlan : undefined
  );
  const data = shouldUseMigration ? GqlData : restData;
  return data?.plan?.length && data?.plan?.length > 0
    ? data.plan[0]
    : undefined;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useGetAccountPlanData;
