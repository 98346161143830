import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { muiColors, muiPalette } from "@komodorio/design-system";

type ErrorStateProps = {
  onRetryClick?: () => void;
  title?: string;
};

export function ErrorState({ onRetryClick, title }: ErrorStateProps) {
  return (
    <Stack
      gap="8px"
      alignItems="center"
      justifyContent="center"
      padding="32px"
      borderTop={`1px solid ${muiPalette.divider}`}
      sx={{ backgroundColor: muiColors.common.white }}
    >
      <ErrorOutline color="action" />
      <Typography variant="body2" color="error.dark">
        {title ?? "An error occurred"}
      </Typography>
      {onRetryClick && (
        <Button
          sx={{ flexGrow: 0 }}
          variant="contained"
          size="medium"
          onClick={onRetryClick}
        >
          Try again
        </Button>
      )}
    </Stack>
  );
}
