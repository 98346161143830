import React, { ComponentProps, useEffect } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";

import { MetricGraph } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/MetricGraph";
import { useNodesMetrics } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/useNodesMetrics";
import { createGraphContainerProps } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/createGraphContainerProps";
import { NodeCountGraph } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/NodeCount/NodeCountGraph";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useMemoryAndCPUXAxisConfig } from "@/components/k8sAddons/addons/NodeAutoscalers/hooks/useMemoryAndCpuXAxisConfig";
import { ClearLoader } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/ClearLoader";

type TabNodeMetricsProps = { cluster: string; onLoad?: () => void };

const GraphsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  & > * {
    &:is(:first-child) {
      min-width: 250px;
      flex: 1;
    }
    margin-bottom: 8px;
  }
`;

export const TabNodeMetrics: React.FC<TabNodeMetricsProps> = ({
  cluster,
  onLoad,
}) => {
  const { cpuData, error, isLoading, memoryData, timeRange } = useNodesMetrics({
    clusterNames: [cluster],
    isDataFetchEnabled: true,
  });

  const graphContainerProps: ComponentProps<
    typeof MetricGraph
  >["graphContainerProps"] = {
    ...createGraphContainerProps({
      hasError: !!error,
      cluster,
      isLoading,
    }),
    renderEmptyStateCB: ClearLoader,
  };

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  useEffect(() => {
    reportLoadingState("TabNodeMetrics", isLoading);
  }, [isLoading, reportLoadingState]);

  const { cpuXAxisConfig, memoryXAxisConfig } = useMemoryAndCPUXAxisConfig({
    cpuData: cpuData.allocated,
    memoryData: memoryData.allocated,
    fromEpoch: timeRange.fromEpoch,
    numOfTicks: 4,
  });

  return (
    <GraphsContainer>
      <NodeCountGraph clusterNames={[cluster]} />
      <Stack direction="row" flex={2} minWidth={"600px"} gap={2}>
        <MetricGraph
          withBorder
          graphContainerProps={graphContainerProps}
          metric="memory"
          metricData={memoryData}
          xAxisConfig={memoryXAxisConfig}
          showLabel={false}
        />
        <MetricGraph
          withBorder
          graphContainerProps={graphContainerProps}
          metric="cpu"
          metricData={cpuData}
          xAxisConfig={cpuXAxisConfig}
          showLabel={false}
        />
      </Stack>
    </GraphsContainer>
  );
};
