import React, {
  createContext,
  useState,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import noop from "lodash/noop";

import { CreateTrackedKeyModal } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/CreateTrackedKeyModal";
import { Refetch } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export type TrackedKeysContextState = {
  addNewKey: (refetch: Refetch) => void;
  maxAccountTrackedKeys: number;
};

const initialState: TrackedKeysContextState = {
  addNewKey: noop,
  maxAccountTrackedKeys: 0,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const TrackedKeysContext =
  createContext<TrackedKeysContextState>(initialState);

export const TrackedKeysContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [refetch, setRefetch] = useState<Refetch | null>(null);
  const [openCreateTrackedKeyModal, setOpenCreateTrackedKeyModal] =
    useState<boolean>(false);

  const { maxAccountTrackedKeys } = useOverridableFlags();

  const addNewKey = useCallback(
    async (newRefetch: Refetch) => {
      setRefetch(() => newRefetch);
      setOpenCreateTrackedKeyModal(true);
    },
    [setOpenCreateTrackedKeyModal]
  );

  const state = useMemo(
    () => ({
      addNewKey,
      maxAccountTrackedKeys: maxAccountTrackedKeys as number,
    }),
    [addNewKey, maxAccountTrackedKeys]
  );

  const handleClose = useCallback(() => {
    setRefetch(null);
    setOpenCreateTrackedKeyModal(false);
  }, []);

  return (
    <TrackedKeysContext.Provider value={state}>
      {children}
      {openCreateTrackedKeyModal && (
        <CreateTrackedKeyModal onClose={handleClose} onRefetch={refetch} />
      )}
    </TrackedKeysContext.Provider>
  );
};
