import { intervalToDuration, isAfter } from "date-fns";

import { ViolationSupportingData } from "../../../../../../../../../generated/reliabilityApi";

export const getIsEolDatePassed = (eolDate: Date) =>
  isAfter(new Date(), eolDate);

export const getTimeToEolString = (eolDate: Date) => {
  const timeElements: string[] = [];

  const insertIfNeeded = (number: number, unit: string) => {
    if (number > 0) {
      if (number === 1) {
        timeElements.push(`${number} ${unit}`);
      } else {
        timeElements.push(`${number} ${unit}s`);
      }
    }
  };

  const { start, end } = getIsEolDatePassed(eolDate)
    ? { start: eolDate, end: new Date() }
    : { start: new Date(), end: eolDate };

  const duration = intervalToDuration({
    start,
    end,
  });

  const { months = 0, days: totalDays = 0, years = 0 } = duration;

  const weeks = Math.floor(totalDays / 7);
  const days = totalDays % 7;

  insertIfNeeded(years, "year");
  insertIfNeeded(months, "month");
  insertIfNeeded(weeks, "week");
  insertIfNeeded(days, "day");

  if (timeElements.length === 3) timeElements.pop();

  return timeElements.join(" and ");
};

export const getK8sEOLTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const { eolDate: eol } = supportingData?.kubernetesEndOfLife || {};
  const eolDate = new Date(eol ?? 0);

  const isEolDatePassed = getIsEolDatePassed(eolDate);

  if (isEolDatePassed) {
    return "Cluster version has reached its end of life";
  }

  return `Cluster version will reach its end of life in ${getTimeToEolString(
    eolDate
  )}`;
};
