import { useMemo } from "react";

import { doesAgentVersionSupportSecretRedaction } from "../../../../shared/utils/agent/agent";

import { useActiveAgents } from "@/shared/hooks/useAgents";
import { parseAgentToAgentProperties } from "@/shared/hooks/useAgentInfo/useAgentInfo";

export const useAgentSupportSecretRedaction = (clusterName: string) => {
  const agents = useActiveAgents();

  const isAgentSupportSecretRedaction = useMemo(() => {
    const agent = agents?.find((agent) => agent.clusterName === clusterName);

    return doesAgentVersionSupportSecretRedaction(
      parseAgentToAgentProperties(agent)
    );
  }, [agents, clusterName]);

  return agents && isAgentSupportSecretRedaction;
};
