import { useCallback, useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { useAppViewsStore } from "../../../shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";

import { SearchButton } from "./components/SearchButton";
import { CommandBarContent } from "./CommandBarContent";

export const CommandBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const [searchInAllWorkspaces, setSearchInAllWorkspaces] = useState(
    selectedAppViewId === undefined
  );

  const onOpen = useCallback(
    (method: "keyboard" | "button") => {
      setQuery("");
      setSearchInAllWorkspaces(selectedAppViewId === undefined);
      dispatchEvent(AnalyticEvents.GlobalSearch.Opened, {
        method,
      });
      if (method === "keyboard") {
        datadogRum.addAction("global search - keyboard");
      }
      setIsOpen(true);
    },
    [selectedAppViewId]
  );

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && !e.shiftKey && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onOpen("keyboard");
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [onOpen]);

  return (
    <>
      <SearchButton onClick={() => onOpen("button")} />
      {isOpen && (
        <CommandBarContent
          setIsOpen={setIsOpen}
          query={query}
          setQuery={setQuery}
          searchInAllWorkspaces={searchInAllWorkspaces}
          setSearchInAllWorkspaces={setSearchInAllWorkspaces}
        />
      )}
    </>
  );
};
