import { useEffect } from "react";

import isElectronContext from "./isElectronContext";

import { useAgentsContext } from "@/shared/hooks/agents/context/useAgentsContext";

const useUpdateAgentConnectionStatus = (): void => {
  const { agents } = useAgentsContext();
  const activeAgentsName = agents?.activeAgents.map(
    (agent) => agent.clusterName
  );

  useEffect(() => {
    if (!isElectronContext()) return;
    window.electronAPI?.updateAgentsConnectionStatus?.(activeAgentsName);
  }, [activeAgentsName]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useUpdateAgentConnectionStatus;
