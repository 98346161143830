import { useMemo } from "react";

import { useAppNotificationsStore } from "@/shared/store/appNotificationsStore/appNotificationsStore";
import { selectAppNotifications } from "@/shared/store/appNotificationsStore/appNotificationsStoreSelectors";
import { Notification } from "@/shared/store/appNotificationsStore/appNotificationsStoreTypes";

export const useFindNotificationById = (
  notificationId: string
): Notification | undefined => {
  const currentNotification = useAppNotificationsStore(selectAppNotifications);
  return useMemo(
    () => currentNotification.find((n) => n.id === notificationId),
    [notificationId, currentNotification]
  );
};
