import {
  BasicViolation,
  CheckType,
  ExtendedViolation,
} from "@/generated/reliabilityApi";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";

export const violationToSupportingDataField: Record<
  CheckType,
  keyof BasicViolation["supportingData"] | null
> = {
  [CheckType.CascadingFailure]: "cascadingFailure",
  [CheckType.CertificateExpiration]: "certificateExpiration",
  [CheckType.CpuLimitsMissing]: "cpuLimitsMissing",
  [CheckType.CpuRequestsMissing]: "cpuRequestsMissing",
  [CheckType.DeploymentMissingReplicas]: null,
  [CheckType.DeprecatedApis]: "deprecatedApis",
  [CheckType.ExternalDnsNotSynced]: "externalDNSNotSynced",
  [CheckType.FailedCronJob]: "failedCronJob",
  [CheckType.FailedJob]: "failedJob",
  [CheckType.HpaMax]: "HPAMax",
  [CheckType.HpaMinAvailability]: "HPAMinAvailability",
  [CheckType.KubernetesEndOfLife]: "kubernetesEndOfLife",
  [CheckType.KubernetesVersionDeprecated]: null,
  [CheckType.LivenessProbeMissing]: "livenessProbeMissing",
  [CheckType.MemoryLimitsMissing]: "memoryLimitsMissing",
  [CheckType.MemoryRequestsMissing]: "memoryRequestsMissing",
  [CheckType.MissingHpa]: null,
  [CheckType.MissingPdb]: null,
  [CheckType.MissingTopologySpreadConstraint]: null,
  [CheckType.NodeTerminationAutoScaling]: "nodeTerminationAutoScaling",
  [CheckType.NodeTerminationSpotInstance]: "nodeTerminationSpotInstance",
  [CheckType.NoisyNeighbor]: "noisyNeighbor",
  [CheckType.OverProvisionedCluster]: "overProvisionedCluster",
  [CheckType.PriorityClassNotSet]: null,
  [CheckType.ReadinessProbeMissing]: "readinessProbeMissing",
  [CheckType.RequestsLimitsRatio]: "requestsLimitsRatio",
  [CheckType.RestartingContainers]: "restartingContainers",
  [CheckType.ScaleDownImpact]: "scaleDownImpact",
  [CheckType.SinglePointOfFailure]: "singlePointOfFailure",
  [CheckType.SyntheticDegradedService]: "syntheticDegradedService",
  [CheckType.SyntheticEolDeprecationOutdated]:
    "syntheticEOLDeprecationOutdated",
  [CheckType.SyntheticNodePressure]: "syntheticNodePressure",
  [CheckType.ThrottledCpu]: "throttledCPU",
  [CheckType.UnderProvisionedWorkloads]: "underProvisionedWorkloads",
  [CheckType.UnhealthyNode]: "unhealthyNode",
  [CheckType.UnhealthyPvc]: "unhealthyPVC",
  [CheckType.UnhealthyService]: "unhealthyService",
  [CheckType.UnhealthyWorkflow]: "unhealthyWorkflow",
  [CheckType.UnschedulablePods]: "unschedulablePods",
  [CheckType.UnschedulableTime]: null,
};

export function validateViolation(violation: ExtendedViolation): void | never {
  const supportingDataField =
    violationToSupportingDataField[violation.checkType];
  if (!supportingDataField) {
    return;
  }

  if (
    supportingDataField &&
    violation.supportingData[supportingDataField] == null
  ) {
    const error = new Error(
      `Supporting Data mismatch for violation ${violation.id}`
    );
    notifyDDError(error);
  }
}
