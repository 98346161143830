import React from "react";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { useGridPagination } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithHeaderAndTitle/useGridPagination";
import { VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";
import {
  CheckType,
  ImpactGroupType,
  ViolationsApiGetViolationsRequest,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import { TabNodeMetrics } from "@/components/k8sAddons/addons/NodeAutoscalers/AutoscalerTab/TabNodeMetrics";
import { ViolationTableWithTitleAndFooter } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithHeaderAndTitle/ViolationTableWithTitleAndFooter";
import { AutoscalerConfigView } from "@/components/k8sAddons/addons/NodeAutoscalers/AutoscalerTab/AutoscalerConfigView";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity } from "@/generated/addonsApi";
import { NoViolationMessage } from "@/components/k8sAddons/addons/NodeAutoscalers/AutoscalerTab/NoViolationMessage";
import { ResourceTab } from "@/components/ResourceView/types";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useGetLoadingTimeContextPropsForResource } from "@/components/ResourceView/resources/hooks/resourceHooks";
import { getViolationTitle } from "@/components/reliability/utils/reliabilityUtils";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";
import { AutoscalerRiskClickParams } from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersTypes";

export type AutoscalerConfig = {
  cluster: string;
  komodorUid: string;
  name: string;
};
export type AutoscalerTabProps = {
  resource: AutoscalerConfig;
};

const baseViolationsFetchConfig: Pick<
  ViolationsApiGetViolationsRequest,
  "impactGroupType" | "status" | "checkType"
> = {
  impactGroupType: [ImpactGroupType.Dynamic, ImpactGroupType.Static],
  checkType: [CheckType.OverProvisionedCluster, CheckType.ScaleDownImpact],
  status: [
    ViolationStatus.Open,
    ViolationStatus.Confirmed,
    ViolationStatus.Dismissed,
    ViolationStatus.Ignored,
  ],
};

const AutoscalerTabContent: React.FC<AutoscalerTabProps> = ({ resource }) => {
  const { page, pageSize, addPaginationParams, setPaginationModel } =
    useGridPagination({
      defaultPageSize: VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS[0],
    });
  const violationFetchParams = addPaginationParams({
    ...baseViolationsFetchConfig,
    clusterName: [resource.cluster],
  });
  const violationsRes = useGetViolations(
    violationFetchParams,
    resource.cluster != null
  );
  const violationsCount = violationsRes?.data?.data?.violations.length ?? 0;
  const isViolationsFetching = violationsRes.isFetching;
  const successfulNoViolations = !isViolationsFetching && violationsCount === 0;

  return (
    <AddonContextProvider
      addonEntity={Entity.AutoScaler}
      addonType={Addon.AutoScalers}
    >
      <div aria-label={nodeAutoscalersPageAriaLabels.summaryTab}>
        <AccordionWrapper
          disabled={successfulNoViolations}
          forceShrink={successfulNoViolations}
          title={`Risks (${violationsCount})`}
          accordionSummary={
            successfulNoViolations ? (
              <NoViolationMessage
                title="Risks"
                message="There are no reliability risks for this autoscaler"
              />
            ) : undefined
          }
          accordionContent={
            <ViolationTableWithTitleAndFooter
              paginationConfig={{ page, pageSize, setPaginationModel }}
              violationTableType={"RiskAssessment"}
              violations={violationsRes}
              onRowClicked={(violation) => {
                dispatchEvent<AutoscalerRiskClickParams>(
                  AnalyticEvents.NodeAutoscalers.DrawerRiskClicked,
                  {
                    violationType: violation.checkType,
                    violationName:
                      getViolationTitle({
                        checkType: violation.checkType,
                        komodorUid: violation.komodorUid,
                        supportingData: violation.supportingData,
                      }) ?? "unknown violation",
                  }
                );
              }}
            />
          }
        ></AccordionWrapper>
        <AccordionWrapper
          title="Node Metrics (last 7 days)"
          accordionContent={<TabNodeMetrics cluster={resource.cluster} />}
        />
        <AccordionWrapper
          title="Configuration"
          accordionContent={
            <AutoscalerConfigView
              clusterName={resource.cluster}
              komodorUid={resource.komodorUid}
              resourceName={resource.name}
            />
          }
        />
      </div>
    </AddonContextProvider>
  );
};

export const AutoscalerTab: React.FC<AutoscalerTabProps> = (props) => {
  const getLoadingTimeContextPropsForResource =
    useGetLoadingTimeContextPropsForResource(ResourceTab.AutoscalerTab);
  return (
    <DatadogReportLoadingTimeContextProvider
      {...getLoadingTimeContextPropsForResource()}
    >
      <AutoscalerTabContent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
