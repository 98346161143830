import React, { HTMLAttributes } from "react";
import styled from "styled-components";

import { theme } from "../../../theme";
import { Typography } from "../Typography/Typography";

/** @deprecated */
export type TabType = { label: string; disabled?: boolean };
/** @deprecated */
export interface TabsProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  tabs: TabType[];
  value?: number;
  onChange?: (value: number) => void;
}

const Container = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const Tab = styled.button<{ selected: boolean }>`
  border: none;
  background-color: unset;
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ selected }) =>
      selected ? theme.foreground.fgDarkBlue : "transparent"};
  :disabled {
    cursor: not-allowed;
    border-bottom: 2px solid
      ${({ selected }) =>
        selected ? theme.foreground.fgDisabled : "transparent"};
  }
`;

/**
 * @deprecated Use MUI Tabs instead.
 */
export const Tabs: React.FC<TabsProps> = ({
  tabs,
  children,
  onChange,
  value,
  ...rest
}) => {
  return (
    <Container {...rest} role="tabpanel">
      {tabs.map((t, i) => (
        <Tab
          key={i}
          selected={value === i}
          disabled={t.disabled}
          onClick={() => onChange?.(i)}
          role="tab"
          aria-label={`Go to ${t.label} tab`}
        >
          <Typography
            size="medium"
            bold={value === i}
            color={t.disabled ? theme.foreground.fgDisabled : undefined}
          >
            {t.label}
          </Typography>
        </Tab>
      ))}
    </Container>
  );
};

Tabs.defaultProps = {
  value: 0,
};
