import { Tooltip as RechartTooltip } from "recharts";

import { SmartPopper } from "@/components/common/EventsChart/SmartPopper";
import { Tooltip } from "@/components/Metrics/Tooltip/Tooltip";
import { TooltipItemProps } from "@/components/Metrics/types";
import { defaultFormatTooltipTick } from "@/components/Metrics/utils";

type TooltipProps = {
  boundingRect: DOMRect | undefined;
  open: boolean;
  mousePos: {
    x: number;
    y: number;
  };
};

type GraphTooltipProps = {
  tooltipItems: TooltipItemProps[];
  shouldUseTimeTooltipHeader?: boolean;
  tooltipProps: TooltipProps;
};

/**
 * This is a helper function to create a tooltip for the graph.
 * NOTE: Recharts expect the RechartTooltip to be a direct child of the graph therefore we can't make a component out
 * of it as it won't work with the graph.
 * @param GraphTooltipProps properties for the tooltip
 */
export const getGraphTooltip = ({
  tooltipItems,
  shouldUseTimeTooltipHeader,
  tooltipProps,
}: GraphTooltipProps) => {
  return (
    <RechartTooltip
      isAnimationActive={false}
      content={(props) => {
        return (
          <SmartPopper
            {...props}
            tooltipContent={(tooltipProps) => {
              return (
                <Tooltip
                  tooltipProps={tooltipProps}
                  dataTransformFunction={defaultFormatTooltipTick}
                  tooltipItems={tooltipItems}
                  displayProps={{
                    ignoreLabelFormatting: !shouldUseTimeTooltipHeader,
                  }}
                />
              );
            }}
            {...tooltipProps}
          />
        );
      }}
    />
  );
};
