import React from "react";
import Menu from "@mui/material/Menu";
import { SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";

import { useMenu } from "./useMenu";

interface MenuProps {
  id: string;
  items: unknown;
  LinkComponent: React.ElementType;
  tooltip?: string;
  ariaLabels?: {
    [key: string]: string;
  };
  children?: React.ReactNode;
  buttonComponent?: React.ElementType;
  sx?: SxProps<Theme>;
}

export const ContextMenu: React.FC<MenuProps> = ({
  id,
  items,
  tooltip,
  ariaLabels,
  LinkComponent,
  children,
  buttonComponent,
  sx,
}: MenuProps) => {
  const {
    BUTTON_ID,
    MENU_ID,
    TooltipWrapper,
    anchorEl,
    handleClick,
    handleClose,
    menuItems,
    open,
  } = useMenu({
    id,
    tooltip,
    // https://app.clickup.com/t/86c2dqm28
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    items,
    LinkComponent,
  });

  const Component = buttonComponent || Button;

  return (
    <>
      <TooltipWrapper>
        <Component
          id={BUTTON_ID}
          aria-controls={open ? MENU_ID : undefined}
          aria-haspopup="true"
          aria-label={ariaLabels?.button}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {children}
        </Component>
      </TooltipWrapper>
      <Menu
        id={MENU_ID}
        MenuListProps={{
          "aria-labelledby": BUTTON_ID,
        }}
        aria-label={ariaLabels?.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={sx}
      >
        {menuItems}
      </Menu>
    </>
  );
};
