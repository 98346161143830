import React from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";

// https://app.clickup.com/t/86c2dqm28
// eslint-disable-next-line react-refresh/only-export-components
export enum Direction {
  left = "row",
  right = "row-reverse",
}

export interface LabeledSwitchProps {
  label: string;
  onCheck?: (value: boolean) => void;
  checked?: boolean;
  labelPosition?: "left" | "right";
  size?: "medium" | "small";
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default";
  disabled?: boolean;
  ariaLabel?: string;
  stackProps?: React.ComponentProps<typeof Stack>;
  sx?: {
    typography?: TypographyProps["sx"];
  };
}

export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  label,
  labelPosition = "right",
  checked = false,
  size = "medium",
  color = "primary",
  disabled = false,
  onCheck,
  ariaLabel,
  stackProps = {},
  sx = {},
}) => {
  const onChange = React.useCallback(() => {
    if (onCheck) {
      onCheck(!checked);
    }
  }, [checked, onCheck]);

  const direction = Direction[labelPosition];
  return (
    <Stack
      gap={1}
      direction={direction}
      justifyContent="start"
      alignItems="center"
      role="group"
      padding={"4px 0"}
      {...stackProps}
    >
      <Typography variant="body2" sx={sx.typography}>
        {label}
      </Typography>
      <Switch
        color={color}
        size={size}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        aria-label={ariaLabel}
      />
    </Stack>
  );
};
