import { Operator } from "react-querybuilder";

export const multiIsOperator: Operator = {
  name: "multiIs",
  label: "Is",
  editorType: "multiselect",
  placeholder: "Select values",
};

export const isOperator: Operator = {
  name: "is",
  label: "Is",
  editorType: "select",
  placeholder: "Select value",
};

export const matchesWildcardOperator: Operator = {
  name: "matchesWildcard",
  label: "Matches wildcard",
  editorType: "text",
  placeholder: "* (all)",
  successors: ["doesntMatchWildcard"],
  defaultValue: "*",
};

export const doesntMatchWildcardOperator: Operator = {
  ...matchesWildcardOperator,
  name: "doesntMatchWildcard",
  label: "Doesn't Match Wildcard",
  successors: undefined,
  predecessors: ["matchesWildcard"],
};
