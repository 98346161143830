import { useOpenPolicyDrawer } from "../../../policies/policiesHooks";
import { MetadataItemContent } from "../violationDrawerTypes";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DisplayMode } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { useCanEditPolicy } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useCanEditPolicy";

type UseGetPolicyLinkArgs = {
  policyName?: string;
  policyId?: string;
};

export const useGetPolicyLink = ({
  policyName,
  policyId,
}: UseGetPolicyLinkArgs): MetadataItemContent | undefined => {
  const onPolicyNameClick = useOpenPolicyDrawer();
  const { showReliabilityPolicies } = useOverridableFlags();
  const canEditPolicy = useCanEditPolicy(policyId);

  if (showReliabilityPolicies && policyName && policyId) {
    const displayMode: DisplayMode = canEditPolicy ? "edit" : "view";
    return {
      title: "policy",
      value: policyName,
      onValueClick: () => onPolicyNameClick(displayMode, policyId),
    };
  }

  return undefined;
};
