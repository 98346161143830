import { muiColors } from "@komodorio/design-system";
import { ChipList } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

export const StyledChipList = styled(ChipList)`
  && {
    max-width: 100%;
    & .MuiChip-label {
      color: ${muiColors.gray[600]};
    }
  }
`;
