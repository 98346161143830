export const k8sAriaLabels = {
  dataAggregations: {
    container: "Data aggregations container",
    item: "data aggregation item",
  },
  noResultsFound: "No results found",
  errorMessageContainer: "Error message container",
  resourceUsageBar: "Resource usage bar",
  noViolationMessage: "No violation message",
};
