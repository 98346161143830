import React from "react";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import { NodeAutoscalersComingSoon } from "@/pages/coming-soon/node-autoscalers/NodeAutoscalersComingSoon";
import { Alert } from "@/components/common/Alert/Alert";
import {
  NO_AUTOSCALERS_FOUND_TEXT,
  NO_AUTOSCALERS_FOUND_TITLE,
} from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersConstants";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";

const NoAutoscalersAlert = styled(Alert)`
  margin: 16px;
`;

const WarningIcon: React.FC = () => (
  <WarningAmberOutlined htmlColor={muiColors.orange[700]} />
);

export const NoAutoscalersFoundPage: React.FC = () => {
  return (
    <>
      <NoAutoscalersAlert
        ariaLabel={nodeAutoscalersPageAriaLabels.notFound.banner}
        severity="warning"
        icon={WarningIcon}
        showCloseButton
        title={NO_AUTOSCALERS_FOUND_TITLE}
        text={NO_AUTOSCALERS_FOUND_TEXT}
      />
      <NodeAutoscalersComingSoon joinButton={false} />
    </>
  );
};
