import Drawer from "@mui/material/Drawer";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseButton, DrawerHeader } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";

import { DriftType } from "../../context/types";
import { DriftComparisonContextProvider } from "../../context/DriftSelectionContext";
import { SHOW_ONLY_DRIFT_SERVICES_DRAWER_KEY } from "../constants";

import { AttributesComparison } from "./AttributesComparison";
import { DriftServicesAttributesComparisonDrawerProps } from "./types";

import { AriaLabels } from "@/shared/config/ariaLabels";

export const DriftServicesAttributesComparisonDrawer: React.FC<
  DriftServicesAttributesComparisonDrawerProps
> = ({ open, onClose, baselineSelection, comparedSelections }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          width: "60%",
        },
      }}
      aria-label={AriaLabels.DriftAnalysis.Helm.ServicesComparisonDrawer}
    >
      <Stack direction="column" padding={"12px 24px 0px 16px"}>
        <DrawerHeader
          title={<Typography variant="h3">Compare Services</Typography>}
          leftActions={<CloseButton onClose={onClose} />}
        />
        <DriftComparisonContextProvider
          driftType={DriftType.Services}
          baselineSelectionDefault={baselineSelection}
          comparedSelectionsDefault={comparedSelections}
          showOnlyDriftKey={SHOW_ONLY_DRIFT_SERVICES_DRAWER_KEY}
        >
          <Box padding={"0px 16px 16px 16px"}>
            <AttributesComparison isInDrawer />
          </Box>
        </DriftComparisonContextProvider>
      </Stack>
    </Drawer>
  );
};
