import React, { ComponentProps, useMemo } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { selectAriaLabels } from "../ariaLabels";
import { selectSize } from "../../../../../constants";
import { Size } from "../../../../../types";
import { lightMuiTooltipStyle } from "../../../../../styles/styles";
import { Close16 } from "../../../../../icons";
import { muiTheme } from "../../../../../themes";
import { ErrorInputAdornment } from "../../../ErrorInputAdornment/ErrorInputAdornment";

// https://app.clickup.com/t/86c2dqm28
// eslint-disable-next-line react-refresh/only-export-components
export const ACTIONS_CONTAINER_WIDTHS = {
  withClear: 80,
  withoutClear: 44,
};

const getContainerStyle = (error) => ({
  width: "100%",
  border: "1px solid",
  borderColor: error ? muiTheme.palette.error.main : muiTheme.palette.grey[300],
  borderRadius: "4px",
  boxSizing: "border-box",
  backgroundColor: muiTheme.palette.common.white,
  display: "flex",
  cursor: "pointer",
  transition: "border-color 0.2s ease-in-out",

  "&:hover": {
    borderColor: error
      ? muiTheme.palette.error.dark
      : muiTheme.palette.grey[600],
  },
});

type DropDownTriggerFieldProps = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  dropDownOpen: boolean;
  enableLineBreaks?: boolean;
  className?: string;
  size: Size;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: ComponentProps<typeof Tooltip>["placement"];
  clearable?: boolean;
  onClear?: () => void;
  error?: React.ReactNode;
  isInlineError?: boolean;
};

export const DropDownTriggerField: React.FC<DropDownTriggerFieldProps> = ({
  children,
  onClick,
  dropDownOpen,
  className,
  enableLineBreaks,
  size,
  disabled,
  tooltip = "",
  tooltipPlacement = "top",
  clearable = false,
  onClear,
  error,
  isInlineError = false,
}) => {
  const containerHeightStyling = useMemo(
    () => ({
      height: enableLineBreaks ? "auto" : `${selectSize[size]}px`,
      minHeight: enableLineBreaks ? `${selectSize[size]}px` : "unset",
    }),
    [enableLineBreaks, size]
  );

  const arrowStyle = useMemo(
    () => ({
      marginRight: "12px",
      "& > svg": {
        color: muiTheme.palette.action.active,
        transition: "transform 0.2s ease-in-out",
        transform: dropDownOpen ? "rotate(180deg)" : "rotate(0deg)",
      },
    }),
    [dropDownOpen]
  );

  const valueMaxWidth = useMemo(
    () =>
      `calc(100% - ${
        clearable
          ? ACTIONS_CONTAINER_WIDTHS.withClear
          : ACTIONS_CONTAINER_WIDTHS.withoutClear
      }px)`,
    [clearable]
  );

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClear?.();
  };

  const errorComponent = useMemo(() => {
    if (!error || isInlineError) {
      return null;
    }

    return typeof error === "string" ? (
      <Typography
        variant="body3"
        color={muiTheme.palette.error.main}
        sx={{ marginTop: "4px" }}
      >
        {error}
      </Typography>
    ) : (
      error
    );
  }, [error, isInlineError]);

  return (
    <Tooltip
      title={tooltip}
      placement={tooltipPlacement}
      componentsProps={lightMuiTooltipStyle}
    >
      <Box component="span" sx={{ pointerEvents: "auto" }}>
        <Stack
          direction="row"
          alignItems="center"
          onClick={onClick}
          className={className}
          aria-label={selectAriaLabels.dropDownList}
          sx={{
            ...getContainerStyle(error),
            ...containerHeightStyling,
            pointerEvents: disabled ? "none" : "auto",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            className="value-container"
            sx={{
              paddingInline: "12px 0",
              paddingBlock:
                size === "small" ? "3px" : size === "medium" ? "5px" : "9px",
              position: "relative",
              width: "100%",
              height: "100%",
              maxWidth: valueMaxWidth,
            }}
          >
            {children}
          </Stack>
          {error && isInlineError && (
            <ErrorInputAdornment
              tooltipText={error as string}
              tooltipPlacement="top"
            />
          )}
          <Stack
            sx={{ marginLeft: "auto" }}
            direction="row"
            alignItems="center"
          >
            {clearable && (
              <IconButton
                aria-label={selectAriaLabels.clearButton}
                onClick={handleClear}
              >
                <Close16 />
              </IconButton>
            )}
            <Stack
              alignItems="center"
              justifyContent="flex-start"
              sx={arrowStyle}
            >
              <ArrowDropDownIcon />
            </Stack>
          </Stack>
        </Stack>
        {errorComponent}
      </Box>
    </Tooltip>
  );
};

DropDownTriggerField.displayName = "DropDownTriggerField";
