import React, { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { muiColors, muiTheme } from "@komodorio/design-system";
import { InfoCircleOutlined16 } from "@komodorio/design-system/icons";

import { infoByScope, scopeTitles } from "../constants";

import { Scope } from "@/pages/organization-settings/access-management/PoliciesPage/types";
import { useStatementDrawerContext } from "@/pages/organization-settings/access-management/PoliciesPage/StatementDrawerContext/useStatementDrawerContext";

export const ScopePicker = ({
  onSelectScope,
}: {
  onSelectScope: (selected: Scope) => void;
}) => {
  const { scope: selectedScope } = useStatementDrawerContext();

  const pickers = useMemo(
    () =>
      Object.keys(Scope).map((scope: string) => (
        <FormControlLabel
          key={scope}
          control={<Radio />}
          label={<Typography variant="body2">{scopeTitles[scope]}</Typography>}
          value={scope}
          aria-label={scope}
        />
      )),
    []
  );

  return (
    <Stack width={"100%"}>
      <FormControl>
        <Typography variant="h4">Scope</Typography>
        <RadioGroup
          row
          defaultValue={Scope.cluster}
          value={selectedScope}
          onChange={(e) => onSelectScope(e.target.value as Scope)}
          sx={{
            "& .MuiSvgIcon-root": {
              height: 20,
              width: 20,
            },
          }}
        >
          {pickers}
        </RadioGroup>
      </FormControl>
      <Stack
        direction="row"
        columnGap="8px"
        alignItems="center"
        sx={{
          backgroundColor: muiColors.gray[50],
          padding: "4px 8px",
          width: "fit-content",
        }}
        aria-label={"Scope info"}
      >
        <InfoCircleOutlined16 color={muiTheme.palette.action.active} />
        <Typography variant="body3">{infoByScope[selectedScope]}</Typography>
      </Stack>
    </Stack>
  );
};
