import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";

import { Form, FormError } from "../styles";
import { useFormValidations } from "../../../shared/context/ValidationsProvider";
import { useCreateApiKey } from "../../../shared/hooks/auth-service/client/apiKeys/useCreateApiKey";

import { apiKeysAriaLabels } from "@/components/Settings/ApiKeys/apiKeysAriaLabels";

const validateDescription = (description: string | undefined) =>
  !description ? "Please fill description" : undefined;

const GenerateKeyModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  refreshKeys: () => void;
}> = ({ open, handleClose, refreshKeys }) => {
  const [description, setDescription] = useState<string>("");
  const { errors, setError } = useFormValidations();

  const { mutateAsync: addApiKey, isLoading: addLoading } = useCreateApiKey();

  const handleSave = async () => {
    const descriptionError = validateDescription(description);
    setError("description", descriptionError);
    if (descriptionError) {
      return;
    }
    try {
      await addApiKey({ name: description });
    } catch (e) {
      setError(
        "general",
        "Encountered an issue while generating the API key. Please retry or contact support for assistance."
      );
      return;
    }
    refreshKeys();
    handleClose();
  };

  return (
    <Modal
      aria-label={apiKeysAriaLabels.modals.generate}
      isOpen={open}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>
        <Typography variant="headline">Generate new API Key</Typography>
      </ModalHeader>
      <ModalContent>
        <Form width="30rem">
          <Typography variant="text" size="medium">
            The API key will have the same access permissions as your user
            account.
          </Typography>
          <Input
            size="medium"
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setError("description", validateDescription(e.target.value));
            }}
            errorMessage={errors["description"]}
          />
          <FormError errMsg={errors["general"]} />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={addLoading}>
          {addLoading ? "Generate..." : "Generate"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default GenerateKeyModal;
