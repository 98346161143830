import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { podContainersType } from "../types";

import { useMetricsTabContext } from "@/components/ResourceView/tabs/MetricsTab/context/useMetricsTabContext";

type PodContainerStates = Record<string, PodContainerAggregatedStatus>;
type PodContainerAggregatedStatus = {
  isLoading: boolean;
  hasError: boolean;
};

export type PodContainersRequestStatusContextState = {
  memoryStatus: PodContainerAggregatedStatus;
  cpuStatus: PodContainerAggregatedStatus;
  updatePodContainerState: (
    type: podContainersType,
    status: PodContainerStates
  ) => void;
};

const initialState: PodContainersRequestStatusContextState = {
  memoryStatus: {
    isLoading: true,
    hasError: false,
  },
  cpuStatus: {
    isLoading: true,
    hasError: false,
  },
  updatePodContainerState: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const PodContainersRequestStatusContext =
  createContext<PodContainersRequestStatusContextState>(initialState);

const getStatus = (list: PodContainerStates) => {
  const values = Object.values(list);
  const isEmpty = values.length === 0;
  return {
    isLoading: isEmpty || values.some((status) => status.isLoading),
    hasError: isEmpty ? false : values.every((status) => status.hasError),
  };
};

export const PodContainersRequestStatusContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [memoryStatuses, setMemoryStatuses] = useState<PodContainerStates>({});
  const [cpuStatuses, setCpuStatuses] = useState<PodContainerStates>({});
  const { timeWindow } = useMetricsTabContext();

  const onPodContainerStatusUpdate = useCallback(
    (type: podContainersType, status: PodContainerStates) => {
      if (type === "cpu") {
        setCpuStatuses((prevState) => ({ ...prevState, ...status }));
      } else {
        setMemoryStatuses((prevState) => ({ ...prevState, ...status }));
      }
    },
    []
  );

  const memoryStatus = useMemo(() => {
    return getStatus(memoryStatuses);
  }, [memoryStatuses]);

  const cpuStatus = useMemo(() => {
    return getStatus(cpuStatuses);
  }, [cpuStatuses]);

  useEffect(() => {
    setMemoryStatuses({});
    setCpuStatuses({});
  }, [timeWindow]);

  const contextState: PodContainersRequestStatusContextState = {
    memoryStatus,
    cpuStatus,
    updatePodContainerState: onPodContainerStatusUpdate,
  };

  return (
    <PodContainersRequestStatusContext.Provider value={contextState}>
      {children}
    </PodContainersRequestStatusContext.Provider>
  );
};
