import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import { sortBy } from "lodash";
import { useNavigate } from "react-router-dom";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { RBAC_POLICY } from "@/shared/config/urlSearchParamsKeys";
import { useGetRbacPolicies } from "@/shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicies";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { RbacPolicy } from "@/generated/auth";
import { ValidationsProvider } from "@/shared/context/ValidationsProvider";
import PolicyFormModal from "@/components/Settings/Policies/policyForm/PolicyFormModal";
import DeleteRoleModal from "@/components/Settings/Policies/DeletePolicyModal";
import { isPolicyEditable } from "@/pages/organization-settings/access-management/PoliciesPage/utils";
import { PoliciesGrid } from "@/pages/organization-settings/access-management/PoliciesPage/Grid/PoliciesGrid";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import {
  CREATE_POLICY_ROUTE,
  EDIT_POLICY_ROUTE,
  POLICY_ID_PARAM,
} from "@/components/routes/routes";

const V2_POLICY_TYPE = "v2";

export const PoliciesPageContent: React.FC = () => {
  const { showExtraPolicyTypes, rbacV2Policy } = useOverridableFlags();
  const [policySearch, setPolicySearch] = useStateInSearchParams(RBAC_POLICY);
  const { data: policies, refetch, isFetching } = useGetRbacPolicies();
  const navigate = useNavigate();

  const [openAddPolicy, setOpenAddPolicy] = useState(false);
  const [policyToEdit, setPolicyToEdit] = useState<RbacPolicy>();
  const [policyToDelete, setPolicyToDelete] = useState<RbacPolicy>();

  const { ref, width } = usePageWidth();

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingPolicies", isFetching);
  }, [isFetching, reportLoadingState]);

  const filteredPolicies = useMemo(() => {
    const policiesFilteredByV2 = rbacV2Policy
      ? policies
      : policies?.filter((p) => (p.type as string) !== V2_POLICY_TYPE);

    if (!policySearch) {
      return policiesFilteredByV2 || [];
    }

    const lowerCaseFilterOrs = policySearch.toLowerCase().split("|");

    return policies
      ? sortBy(
          policiesFilteredByV2?.filter((p) => {
            return lowerCaseFilterOrs.some((filter) => {
              return p.name.toLowerCase().includes(filter);
            });
          }),
          (p) => p.name
        )
      : [];
  }, [policies, policySearch, rbacV2Policy]);

  const onAddPolicyClick = useCallback(() => {
    if (rbacV2Policy) {
      return navigate(CREATE_POLICY_ROUTE);
    }
    return setOpenAddPolicy(true);
  }, [navigate, rbacV2Policy]);

  const onEditPolicyClick = useCallback(
    (policy: RbacPolicy) => {
      if (rbacV2Policy && policy.type === V2_POLICY_TYPE) {
        const url = EDIT_POLICY_ROUTE.replace(`:${POLICY_ID_PARAM}`, policy.id);
        return navigate(url);
      }

      setPolicyToEdit(policy);
    },
    [navigate, rbacV2Policy]
  );

  return (
    <SettingsViewVerticalLayout title={"Policies"}>
      <Stack ref={ref} rowGap={"16px"}>
        <Stack direction="row" justifyContent={"space-between"}>
          <SearchField
            size="medium"
            width="15rem"
            placeholder="Search"
            value={policySearch || ""}
            showCloseIcon={true}
            ariaLabel="Search policies"
            onSearch={setPolicySearch}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onAddPolicyClick}
          >
            Add policy
          </Button>
        </Stack>
        <Divider />
        <PoliciesGrid
          width={width}
          filteredPolicies={filteredPolicies}
          setPolicyToEdit={onEditPolicyClick}
          setPolicyToDelete={setPolicyToDelete}
          showExtraPolicyTypes={!!showExtraPolicyTypes}
          isLoading={isFetching}
        />
      </Stack>
      {openAddPolicy && (
        <ValidationsProvider>
          <PolicyFormModal
            open
            handleClose={() => setOpenAddPolicy(false)}
            refreshPolicies={refetch}
          />
        </ValidationsProvider>
      )}
      {!!policyToEdit && (
        <ValidationsProvider>
          <PolicyFormModal
            open
            handleClose={() => setPolicyToEdit(undefined)}
            policy={policyToEdit}
            readOnly={!isPolicyEditable(policyToEdit)}
            refreshPolicies={refetch}
          />
        </ValidationsProvider>
      )}
      {policyToDelete && (
        <DeleteRoleModal
          open
          handleClose={() => setPolicyToDelete(undefined)}
          policy={policyToDelete}
          refreshPolicies={refetch}
        />
      )}
    </SettingsViewVerticalLayout>
  );
};
