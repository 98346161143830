import { datadogRum } from "@datadog/browser-rum";

import { dispatchEvent } from "@/shared/hooks/analytics";
import { SessionData } from "@/generated/aiApi";

export type ResourceIdentifier = {
  cluster: string;
  namespace?: string;
  name: string;
  kind: string;
  container?: string;
};

const getSessionState = (session: SessionData | null) => {
  if (session?.problemShort === "The analysis has failed") {
    return "error";
  }
  if (session?.isStuck) {
    return "stuck";
  }
  if (session?.isComplete) {
    return "completed_successfuly";
  }
  return "loading";
};

export function sendResourceInvestigationStateEvent({
  event,
  session,
  resource,
  flowType,
}: {
  event: string;
  session: SessionData | null;
  resource: ResourceIdentifier | undefined;
  flowType: string;
}) {
  const data = {
    klaudiaSessionId: session?.id,
    type: "root_cause_analysis",
    cluster: resource?.cluster,
    namespace: resource?.namespace,
    name: resource?.name,
    kind: resource?.kind,
    state: getSessionState(session),
    isAnalysisCompletedWithError:
      session?.problemShort === "The analysis has failed",
    isStuck: session?.isStuck ?? false,
    isComplete: session?.isComplete ?? false,
    flowType: flowType,
  };

  datadogRum.addAction(event, data);
  dispatchEvent(event, data);
}
