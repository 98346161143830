import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type AiInvestigationState = {
  chatSessionIdToShare: string | undefined;
};

export type AiInvestigationActions = {
  setChatSessionIdToShare: (sessionId: string | undefined) => void;
};

export type AiInvestigationStore = AiInvestigationState &
  AiInvestigationActions;

export const initialState: AiInvestigationState = {
  chatSessionIdToShare: undefined,
};

export const useAiInvestigationStore = create(
  immer<AiInvestigationStore>((set, get) => ({
    ...initialState,
    setChatSessionIdToShare: (sessionId: string | undefined) => {
      set({
        chatSessionIdToShare: sessionId,
      });
    },
  }))
);

export const resetAiInvestigationStore = (): void => {
  useAiInvestigationStore.setState({
    ...initialState,
  });
};
