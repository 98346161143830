import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  CloseButton,
  DrawerHeader,
  ChatMessageType,
  ChatMessagesList,
  ChatInput,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiColors, muiPalette } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import RestartAlt from "@mui/icons-material/RestartAlt";
import styled from "styled-components";

import response1 from "./assets/response1.png";
import response2 from "./assets/response2.png";
import response3 from "./assets/response3.png";

import type { DrawerProps } from "@/shared/store/drawersStackStore/types";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { WelcomePage } from "@/components/KlaudiaChat/WelcomePage";

const Img = styled.img`
  width: 100%;
  max-width: 693px;
`;

const BOT_RESPONSES = [
  <Img key={0} src={response1} alt="response1" />,
  <Img key={1} src={response2} alt="response2" />,
  <Img key={2} src={response3} alt="response3" />,
];

export const KlaudiaChatDemoDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
}) => {
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseIndex, setResponseIndex] = useState(0);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage: ChatMessageType = {
      id: messages.length + 1,
      content: input,
      isUser: true,
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    const botMessage: ChatMessageType = {
      id: messages.length + 2,
      content: BOT_RESPONSES[responseIndex],
      isUser: false,
    };

    setMessages((prev) => [...prev, botMessage]);
    setResponseIndex((prev) => (prev + 1) % BOT_RESPONSES.length);
    setLoading(false);
  };

  const handleResetConversation = () => {
    setMessages([]);
    setResponseIndex(0);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          width: "50%",
        },
      }}
      aria-label={AriaLabels.KlaudiaChat.ChatDrawer}
    >
      <Box paddingTop="16px" />
      <DrawerHeader
        title={
          <Box display="flex" alignItems="center" gap="8px">
            <MagicAi24 color={muiPalette.action?.active} />
            <Typography variant="h3">Klaudia co-pilot</Typography>
          </Box>
        }
        leftActions={<CloseButton onClose={onClose} />}
        rightActions={
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResetConversation}
            startIcon={<RestartAlt />}
            disabled={!messages.length}
          >
            Reset Conversation
          </Button>
        }
      />
      <Divider />
      {messages.length === 0 ? (
        <WelcomePage />
      ) : (
        <ChatMessagesList messages={messages} isLoading={loading} />
      )}
      <Divider />
      <Box padding="12px 16px" sx={{ backgroundColor: muiColors.gray[50] }}>
        <ChatInput
          value={input}
          size="large"
          onChange={(e) => setInput(e.target.value)}
          onSend={handleSendMessage}
          disabled={loading}
          placeholder="Ask me a question about your Kubernetes environment"
        />
      </Box>
    </Drawer>
  );
};
