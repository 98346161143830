import {
  CountedDataContent,
  CountedDataValue,
} from "@/components/reliability/components/shared/CountedData/CountedDataContent";
import { formatNumber } from "@/shared/utils/formatNumber";
import { formatSeconds } from "@/shared/utils/timeUtils";

type UnschedulablePodsCountedDataProps = {
  unschedulablePods: number;
  unschedulableTime: number;
  avgTimeToSchedule: number;
};

export const UnschedulablePodsCountedData: React.FC<
  UnschedulablePodsCountedDataProps
> = ({ unschedulablePods, avgTimeToSchedule, unschedulableTime }) => {
  const countedDataItems: CountedDataValue[] = [
    { title: "Unschedulable pods", value: formatNumber(unschedulablePods) },
    {
      title: "Unschedulable time",
      value: formatSeconds(unschedulableTime, { shouldCapitalize: true }),
    },
    {
      title: "Avg time to schedule",
      value: formatSeconds(avgTimeToSchedule, { shouldCapitalize: true }),
    },
  ];
  return <CountedDataContent items={countedDataItems} />;
};
