import React, { useEffect, useMemo, useRef } from "react";
import Drawer from "@mui/material/Drawer";

import { useAddonFullLiveState } from "@/components/k8sAddons/LiveStateDrawer/hooks/useAddonFullLiveState";
import { PageLoader } from "@/shared/components/PageLoader";
import { CertificateDrawerContent } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/CertificateDrawerContent";
import { useCommonDrawerProps } from "@/components/k8sAddons/LiveStateDrawer/hooks/useCommonDrawerProps";
import { selectCertificate } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/certificateDrawerUtils";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { ErrorMessage } from "@/components/k8sAddons/components/ErrorMessage";

const CertificateDrawerImplementation: React.FC = () => {
  const { isLoading, error } = useAddonFullLiveState(selectCertificate);
  const { reportLoadingState, setMainContainerDivRef } =
    useDatadogReportLoadingTimeContext();

  const content = useMemo(() => {
    if (isLoading) {
      return <PageLoader />;
    }
    if (error) {
      return <ErrorMessage error={error} />;
    }
    return <CertificateDrawerContent />;
  }, [isLoading, error]);

  const mainContainerRef = useRef<HTMLDivElement>(null);

  setMainContainerDivRef(mainContainerRef);
  useEffect(() => {
    reportLoadingState("loading", isLoading);
  }, [reportLoadingState, isLoading]);

  return (
    <Drawer
      ref={mainContainerRef}
      {...useCommonDrawerProps()}
      aria-label={certificatesPageAriaLabels.drawer.drawerContainer}
    >
      {content}
    </Drawer>
  );
};

export const CertificateDrawer: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.certManagerCertificateDrawer,
        context: {
          feTeam: "operate",
          beTeam: "operate",
        },
      }}
    >
      <CertificateDrawerImplementation />
    </DatadogReportLoadingTimeContextProvider>
  );
};
