import { LinkCell } from "@komodorio/design-system/komodor-ui";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useModal } from "@/shared/context/modal/useModal";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const WorkloadNameCell: React.FC<KomodorUidParams> = ({
  cluster,
  namespace,
  kind,
  name,
}) => {
  const { onClose: closeModal } = useModal();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return (
    <LinkCell
      onClick={() => {
        closeModal();
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster,
          namespace,
          resourceType: kind,
          resourceName: name,
        });
      }}
    >
      {name}
    </LinkCell>
  );
};
