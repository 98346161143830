import React, { useState } from "react";
import styled from "styled-components";
import { Route, Routes, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

import { TopBar } from "../TopBar";
import overviewUrl from "../../assets/Screen - Drift.png";
import { ClickableArea, RelativeContainer } from "../../styles";
import packageScreenUrl from "../../assets/Screen - Drift - Chart.png";
import policyScreenUrl from "../../assets/Screen - Drift - Policy.png";
import driftPoliciesUrl from "../../assets/Screen - Drift - Policies.png";
import packageDeploymentScreenUrl from "../../assets/Screen - Drift - Workload.png";
import analysisDrawerUrl from "../../assets/Drawer - Drift Analysis.png";
import investigateDrawerUrl from "../../assets/Drawer - Drift Analysis - Failed deploy.png";
import enforcePolicyUrl from "../../assets/Drawer - Drift - policy.png";
import editDesiredState from "../../assets/Drawer - Edit Desired State.png";

import { DEMO, DRIFT_ANALYSIS_DEMO } from "@/components/routes/routes";

export const SelectableRow = styled(ClickableArea)<{
  height: string;
  top: string;
}>`
  width: 96.35%;
  height: ${({ height }) => height};
  left: 1.8%;
  top: ${({ top }) => top};
`;

export const DriftAnalysisDemoView: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<DriftPoliciesOverview />} />
      <Route path="/analysis" element={<DriftAnalysisOverview />} />
      <Route path="/package" element={<PackageScreen />} />
      <Route path="/package/deployment" element={<PackageDeploymentScreen />} />
      <Route path="/policy" element={<PolicyScreen />} />
    </Routes>
  );
};

export const DriftPoliciesOverview: React.FC = () => {
  const navigate = useNavigate();
  return (
    <RelativeContainer>
      <TopBar page="drift" />
      <img src={driftPoliciesUrl}></img>
      <SelectableRow
        height="5.1%"
        top="76.9%"
        onClick={() => {
          navigate(`./analysis`);
        }}
      />
    </RelativeContainer>
  );
};

export const DriftAnalysisOverview: React.FC = () => {
  const navigate = useNavigate();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  return (
    <RelativeContainer>
      <TopBar page="drift" />
      <img src={overviewUrl}></img>
      <SelectableRow
        height="5.1%"
        top="35.9%"
        onClick={() => {
          navigate(`/${DEMO}/${DRIFT_ANALYSIS_DEMO}/package`);
        }}
      />
      <SelectableRow
        height="5.1%"
        top="81.5%"
        onClick={() => {
          navigate(`/${DEMO}/${DRIFT_ANALYSIS_DEMO}/policy`);
        }}
      />
      <EditDesiredStateDrawer
        open={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
      />
      <EditDisiredStateButton
        onClick={() => {
          setIsEditDrawerOpen(true);
        }}
      />
    </RelativeContainer>
  );
};

const BackButton = styled(ClickableArea)<{
  top: string;
}>`
  width: 2.5%;
  height: 3.5%;
  left: 1.8%;
  top: ${({ top }) => top};
`;

const PackageScreen: React.FC = () => {
  const navigate = useNavigate();
  return (
    <RelativeContainer>
      <TopBar page="drift" />
      <img src={packageScreenUrl}></img>
      <SelectableRow
        height="6.5%"
        top="52%"
        onClick={() => {
          navigate(`./deployment`);
        }}
      />
      <BackButton onClick={() => navigate("../analysis")} top="8.5%" />
    </RelativeContainer>
  );
};

const AnalyzeButton = styled(ClickableArea)`
  width: 7.4%;
  height: 3.6%;
  left: 74.6%;
  top: 33%;
`;

const PackageDeploymentScreen: React.FC = () => {
  const navigate = useNavigate();
  const [isAnalysisDrawerOpen, setIsAnalysisDrawerOpen] = useState(false);
  const [isInvestigateDrawerOpen, setIsInvestigateDrawerOpen] = useState(false);
  return (
    <RelativeContainer>
      <TopBar page="drift" />
      <img src={packageDeploymentScreenUrl}></img>
      <AnalyzeButton onClick={() => setIsAnalysisDrawerOpen(true)} />
      <AnalysisDrawer
        open={isAnalysisDrawerOpen}
        onClose={() => setIsAnalysisDrawerOpen(false)}
        onInvestigate={() => setIsInvestigateDrawerOpen(true)}
      />
      <InvestigateDrawer
        open={isInvestigateDrawerOpen}
        onClose={() => setIsInvestigateDrawerOpen(false)}
      />
      <BackButton onClick={() => navigate("../package")} top="7.5%" />
    </RelativeContainer>
  );
};

type AnalysisDrawerProps = {
  open: boolean;
  onClose: () => void;
  onInvestigate: () => void;
};

const CloseButtonArea = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 18px;
  left: 18.5px;
`;

const InvestigateButton = styled(ClickableArea)`
  width: 85px;
  height: 30px;
  top: 159px;
  left: 88%;
`;

export const AnalysisDrawer: React.FC<AnalysisDrawerProps> = ({
  open,
  onClose,
  onInvestigate,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img src={analysisDrawerUrl} width={907} />
      <InvestigateButton onClick={onInvestigate} />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};

type InvestigateDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export const InvestigateDrawer: React.FC<InvestigateDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      PaperProps={{ sx: { overflowY: "scroll" } }}
    >
      <img src={investigateDrawerUrl} width={907} />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};

const PolicyScreen: React.FC = () => {
  const navigate = useNavigate();
  const [isPolicyDrawerOpen, setIsPolicyDrawerOpen] = useState(false);
  return (
    <RelativeContainer>
      <TopBar page="drift" />
      <img src={policyScreenUrl}></img>
      <SelectableRow
        height="7.2%"
        top="67.4%"
        onClick={() => {
          setIsPolicyDrawerOpen(true);
        }}
      />
      {isPolicyDrawerOpen && (
        <EnforcePolicyDrawer
          open={isPolicyDrawerOpen}
          onClose={() => setIsPolicyDrawerOpen(false)}
        />
      )}
      <BackButton onClick={() => navigate("../analysis")} top="10.8%" />
    </RelativeContainer>
  );
};

const LearnMoreButton = styled(ClickableArea)`
  width: 25%;
  height: 2%;
  top: 25%;
  left: 2%;
`;

export const EnforcePolicyDrawer: React.FC<InvestigateDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <Box position="relative">
        <img src={enforcePolicyUrl} width={907} />
        <CloseButtonArea onClick={onClose} />
        <LearnMoreButton
          onClick={() => {
            window.open(
              "https://kubernetes.io/docs/concepts/services-networking/network-policies/",
              "_blank"
            );
          }}
        />
      </Box>
    </Drawer>
  );
};

const SaveButtonArea = styled(ClickableArea)`
  position: absolute;
  width: 97px;
  height: 35px;
  top: 96.5%;
  right: 724px;
`;

const CancelButtonArea = styled(ClickableArea)`
  position: absolute;
  width: 60px;
  height: 35px;
  top: 96.5%;
  right: 830px;
`;

const EditDisiredStateButton = styled(ClickableArea)`
  width: 9.5%;
  height: 3.6%;
  top: 11%;
  left: 88.6%;
`;

export const EditDesiredStateDrawer: React.FC<InvestigateDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <Box position="relative">
        <img src={editDesiredState} width={907} />
        <CloseButtonArea onClick={onClose} />
        <SaveButtonArea onClick={onClose} />
        <CancelButtonArea onClick={onClose} />
      </Box>
    </Drawer>
  );
};
