export const appNotificationsAriaLabels = {
  getMainContainerAriaLabel: (notificationsNum: number) =>
    `app notifications center with ${notificationsNum} notifications`,
  itemsContainer: "app notifications center items",
  header: {
    container: "app notifications center header",
    close: "app notification header close",
  },
  status: {
    loading: "app notification status loading",
    error: "app notification status error",
    success: "app notification status success",
  },
  item: {
    container: "app notification item",
    title: "app notification item title",
    message: "app notification item message",
    close: "app notification item close",
    detailsButton: "app notification item view details",
    status: "app notification item status",
  },
};
