import React from "react";
import Button from "@mui/material/Button";

import { useBatchActionsStore } from "@/shared/store/batchActionsStore/batchActionsStore";
import { clearBatchResponseToShowSelector } from "@/shared/store/batchActionsStore/batchActionsSelectors";

export const ResponseActionButtons: React.FC = () => {
  const clearBatchResponseToShow = useBatchActionsStore(
    clearBatchResponseToShowSelector
  );

  const onClose = () => {
    clearBatchResponseToShow();
  };

  return <Button onClick={onClose}>Close</Button>;
};
