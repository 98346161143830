import {
  Label,
  MuiSelectionOption,
  MuiSelectionOptionValue,
  SearchField,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import React, { useMemo } from "react";

import { AriaLabels } from "@/shared/config/ariaLabels";

const optionAll = {
  label: "ALL",
  value: "ALL",
};

interface Props {
  controlsDisabled: boolean;
  addWorkspaceButtonDisabled?: boolean;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  createdBy: string | null;
  setCreatedBy: (newCreatedBy: string) => void;
  createdByValues: MuiSelectionOption<MuiSelectionOptionValue>[];
  onAddWorkspaceClicked: () => void;
}

export const WorkspaceManagementControls: React.FC<Props> = ({
  controlsDisabled,
  addWorkspaceButtonDisabled = false,
  searchTerm,
  setSearchTerm,
  createdBy,
  setCreatedBy,
  createdByValues,
  onAddWorkspaceClicked,
}) => {
  const createdByOptions = useMemo(
    () => [
      optionAll,
      ...createdByValues.map(({ label }) => ({
        label,
        value: label,
      })),
    ],
    [createdByValues]
  );

  const createdByValue = useMemo(() => {
    if (!createdBy) {
      return optionAll;
    }

    return {
      label: createdBy,
      value: createdBy,
    };
  }, [createdBy]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap="16px" marginRight="16px">
        <Stack direction="column" gap="3px">
          <Label value="Search" disabled={controlsDisabled} />
          <SearchField
            value={searchTerm}
            onSearch={setSearchTerm}
            ariaLabel={AriaLabels.Workspaces.Management.SearchField}
            disabled={controlsDisabled}
          />
        </Stack>

        <Stack direction="column" gap="3px">
          <Label value="Created By" disabled={controlsDisabled} />
          <SingleSelect
            enableSearch
            width="209px"
            options={createdByOptions}
            value={createdByValue}
            onChange={(option) => setCreatedBy(option?.value ?? "")}
            ariaLabel={AriaLabels.Workspaces.Management.CreatedByFilter}
            disabled={controlsDisabled}
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "end",
        }}
      >
        <Button
          variant="contained"
          disabled={addWorkspaceButtonDisabled}
          onClick={onAddWorkspaceClicked}
        >
          Create Workspace
        </Button>
      </Stack>
    </Stack>
  );
};
