import { getUnsupportedWorkspaceText } from "./constants";
import { HelmDriftContent } from "./HelmDriftContent";

import { Addon, Entity } from "@/generated/addonsApi";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { WorkspaceGenericEmptyState } from "@/components/workspaces/WorkspaceGenericEmptyState";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";

export const HelmDriftView: React.FC = () => {
  const { isWorkspaceKindBackendFiltered, currentWorkspace } = useWorkspaces();
  if (!isWorkspaceKindBackendFiltered && currentWorkspace) {
    return <WorkspaceGenericEmptyState title={getUnsupportedWorkspaceText()} />;
  }
  return (
    <AddonContextProvider
      addonType={Addon.HelmCharts}
      addonEntity={Entity.HelmChart}
      disableServerSidePagination
      staleTime={180000} // 3 minutes -- Releases data is updated every ~10-15 minutes
    >
      <HelmDriftContent />
    </AddonContextProvider>
  );
};
