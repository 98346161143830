import { Label } from "../../../resources";

import { ImagesValue } from "@/components/ResourceView/common/Header/imageLabelComponents";

export const useGetImageLabel = (
  images: (string | undefined)[] | undefined
): Label | undefined => {
  const filteredImages = images?.filter((image): image is string => !!image);
  if (!filteredImages || !filteredImages.length) return;

  return {
    name: "image",
    value: <ImagesValue images={filteredImages} />,
  };
};
