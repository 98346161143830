/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Record, String } from "runtypes";
import { ChannelType } from "komodor-types";
import {
  Button,
  Input,
  Select,
  Typography,
} from "@komodorio/design-system/deprecated";
import { PagerDuty } from "@komodorio/design-system/icons";

import { useUpsertInstallationWithType } from "../../../../integrations/installation/useUpsertInstallation";
import useInstallationSubscription from "../../../../integrations/management/installed/useInstallationSubscription";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import ModalComponent from "../../../common/ModalComponent";
import { Divider, LinkButton } from "../../../common/styles";
import {
  Container,
  Content,
  lightStyle,
  Spacer,
  WorkflowConfigModalStyle,
} from "../styles";
import { useFetchChannelConfiguration } from "../../../../notification/useFetchChannelConfiguration";
import PlusIcon from "../../../assets/plus.svg?react";
import { GenericSuggestions } from "../suggestions";

import { StyledIntegrationTile } from "./OpsgenieSinkOutput";
import {
  AddPagerDutyServiceIntegrationKeyModal,
  InfoButton,
} from "./AddPagerDutyIntegrationKeyModal";
import { ChannelLabel } from "./common";

import { MonitorConfiguration, PagerDutySink } from "@/generated/monitorsApi";

const PAGERDUTY_INTEGRATION_TYPE = "pagerduty-eventsv2";

const Label = styled.div`
  ${lightStyle}
  padding-bottom: 0.5rem;
`;

const pdGenerateAccessKeyInfoUrl =
  "https://support.pagerduty.com/docs/api-access-keys#generate-a-general-access-rest-api-key";

const IntegrationConfig = Record({
  channel: String,
  integrationKey: String,
  pagerDutyAccountName: String,
});

const defaultChannel = {
  channel: "",
  integrationKey: "",
  pagerDutyAccountName: "",
};

const PagerDutySinkOutput: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule: configData, setRule: setConfigData }) => {
  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [pdAccountName, setPdAccountName] = useState(
    configData.sinks?.pagerduty?.[0].pagerDutyAccountName ?? ""
  );
  const [channel, setChannel] = useState(configData.sinks?.pagerduty?.[0]);

  const installations = useInstallationSubscription().data?.filter(
    (i) => !i.deletedAt && i.integration === PAGERDUTY_INTEGRATION_TYPE
  );
  const accountSuggestions = useMemo<GenericSuggestions<string>>(() => {
    if (installations?.length) {
      return installations.map((i) => {
        const accountName =
          (i.configuration as PagerDutySink).pagerDutyAccountName ?? "";
        return {
          label: accountName,
          value: accountName,
        };
      });
    }
    return [];
  }, [installations]);

  const channelConfigurations = useFetchChannelConfiguration();
  const serviceSuggestions = useMemo<GenericSuggestions<PagerDutySink>>(() => {
    const configurations = channelConfigurations
      ?.filter(
        (c) =>
          c?.type === ChannelType.PagerDuty &&
          IntegrationConfig.guard(c?.configuration) &&
          c.configuration.pagerDutyAccountName === pdAccountName
      )
      .map((c) => c?.configuration)
      .sort() as PagerDutySink[] | undefined;
    if (configurations?.length) {
      return configurations.map((nc) => ({
        label: nc.channel,
        value: nc,
      }));
    }
    return [];
  }, [channelConfigurations, pdAccountName]);

  useEffect(() => {
    setConfigData({
      ...configData,
      sinks: {
        ...configData.sinks,
        pagerduty: !channel ? undefined : [channel],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return !installations ? null : installations.length > 0 ? (
    <>
      <ChannelLabel>
        <Typography>PagerDuty Account</Typography>
        <LinkButton onClick={() => setIsIntegrationModalOpen(true)}>
          <PlusIcon /> Add New PagerDuty Account
        </LinkButton>
      </ChannelLabel>
      <Select
        size="small"
        width="100%"
        listMaxHeight="15rem"
        placeholder="select account"
        searchable={accountSuggestions.length > 5}
        options={accountSuggestions}
        value={accountSuggestions.find((o) => o.value === pdAccountName)}
        onChange={(selected) => {
          setPdAccountName(selected.value);
          setChannel(undefined);
        }}
        onClean={() => setPdAccountName("")}
      />
      <br />
      <ChannelLabel>
        <Typography>PagerDuty Service</Typography>
        <LinkButton onClick={() => setIsAddModalOpen(true)}>
          <PlusIcon /> Add New PagerDuty Service
        </LinkButton>
      </ChannelLabel>
      <Select
        size="small"
        width="100%"
        listMaxHeight="15rem"
        placeholder="select service"
        searchable={serviceSuggestions.length > 5}
        options={serviceSuggestions}
        value={
          serviceSuggestions.find((o) => o.label === channel?.channel) ?? {
            label: channel?.channel ?? "",
            value: defaultChannel,
          }
        }
        onChange={(selected) => {
          setChannel(selected.value);
        }}
        onClean={() => setChannel(undefined)}
      />
      {isIntegrationModalOpen && (
        <AddPagerDutyIntegration
          isOpen
          handleClose={() => setIsIntegrationModalOpen(false)}
        />
      )}
      {isAddModalOpen && (
        <AddPagerDutyServiceIntegrationKeyModal
          isOpen
          handleClose={() => setIsAddModalOpen(false)}
          setChannel={setChannel}
          pagerDutyAccountName={pdAccountName}
        />
      )}
    </>
  ) : (
    <>
      <Label>
        Keep your team up-to-date with the current state and health of your
        system by installing PagerDuty EventsV2 integration
      </Label>
      <StyledIntegrationTile
        logoWidth="70px"
        logo={PagerDuty}
        title="PagerDuty"
      >
        <div>Get your Komodor alerts when and where it matters</div>
        <Button
          variant="primary"
          onClick={() => setIsIntegrationModalOpen(true)}
        >
          Add Alerts Integration
        </Button>
      </StyledIntegrationTile>
      {isIntegrationModalOpen && (
        <AddPagerDutyIntegration
          isOpen
          handleClose={() => setIsIntegrationModalOpen(false)}
        />
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PagerDutySinkOutput;

export const AddPagerDutyIntegration: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
}> = ({ isOpen, handleClose }) => {
  const [pdAccountName, setPdAccountName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [pdAccountNameValid, setPdAccountNameValid] = useState(true);
  const [accessTokenValid, setAccessTokenValid] = useState(true);

  const createPagerDutyInstallation = useUpsertInstallationWithType<{
    pagerDutyAccountName: string;
    accessToken: string;
  }>(PAGERDUTY_INTEGRATION_TYPE);

  const validateAccountName = () => {
    const valid = pdAccountName !== "";
    setPdAccountNameValid(valid);
    return valid;
  };
  const validateAccessToken = () => {
    const valid = accessToken !== "";
    setAccessTokenValid(valid);
    return valid;
  };

  const handleAddAccountIntegration = async () => {
    const accountNameValid = validateAccountName();
    const accessTokenValid = validateAccessToken();
    if (accountNameValid && accessTokenValid) {
      await createPagerDutyInstallation({
        pagerDutyAccountName: pdAccountName,
        accessToken,
      });
      setPdAccountName(pdAccountName);
      setAccessToken(accessToken);
      handleClose();
    }
  };

  return (
    <ModalComponent
      title=""
      isOpen={isOpen}
      handleCloseModal={handleClose}
      customStyle={WorkflowConfigModalStyle}
    >
      <Container>
        <Header
          closeModalCallback={handleClose}
          title="PagerDuty EventsV2 Integration"
        />
        <Divider />
        <Content>
          <Typography size="medium">
            To integrate between Komodor and your PagerDuty account, enter your
            account api access key
          </Typography>
          <Spacer />
          <Input
            size="medium"
            width="15rem"
            label="Account Name"
            placeholder="Enter account name"
            value={pdAccountName}
            onChange={(e) => {
              setPdAccountName(e.target.value);
            }}
            errorMessage={
              !pdAccountNameValid ? "An account name is required" : undefined
            }
          />
          <Spacer />
          <Input
            size="medium"
            width="15rem"
            label="Access Key"
            placeholder="Enter access key"
            value={accessToken}
            onChange={(e) => {
              setAccessToken(e.target.value);
            }}
            errorMessage={
              !accessTokenValid ? "An access key is required" : undefined
            }
          />
          <Spacer />
          <InfoButton href={pdGenerateAccessKeyInfoUrl} target="_blank">
            How do I generate access key?
          </InfoButton>
        </Content>
        <Divider />
        <Footer
          backData={{
            label: "Cancel",
            action: handleClose,
          }}
          nextData={{ label: "Continue", action: handleAddAccountIntegration }}
        />
      </Container>
    </ModalComponent>
  );
};
