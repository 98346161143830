import { LogsViewerStore } from "./types";

// getters
export const selectIsAnalyzingLogs = (state: LogsViewerStore) =>
  state.isAnalyzingLogs;
export const selectIsLiveTailEnabled = (state: LogsViewerStore) =>
  state.isLiveTailEnabled;

// setters
export const setIsAnalyzingLogs = (state: LogsViewerStore) =>
  state.setAnalyzingLogs;
export const setLiveTailEnabled = (state: LogsViewerStore) =>
  state.setLiveTailEnabled;
