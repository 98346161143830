import { Deploy } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";

import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const KubernetesDeploymentResource = {
  NameInK8S: "deployments",
  Group: "workloads",
  NavBarName: "Deployments",
  PresentName: "Deployments",
  Namespaced: true,
  ShowStatusFilter: false,
  UnnecessaryColumns: ["id", "deletedAt"],
  SupportedActions: [
    AvailableActions.Edit,
    AvailableActions.Scale,
    AvailableActions.Restart,
    AvailableActions.Delete,
  ],
  Icon: Deploy,
  Headers: [
    { name: "Name" },
    { name: "Health" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Ready" },
    { name: "Images" },
    { name: "Selector" },
    { name: "Up To Date", hide: true },
    { name: "Available", hide: true },
    { name: "Containers" },
  ],
  DefaultSort: {
    ColumnName: "Health",
    Direction: Direction.down,
  },
  Kind: SupportedResourcesKinds.KubernetesDeploymentResource,
  ShouldBackgroundTrigger: true,
  hasLabels: true,
};
