import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { AgentDrawerProps } from "@/pages/organization-settings/account/AgentsPage/components/types";
import { Agent } from "@/generated/agents";

export interface AgentDrawerHeaderProps extends AgentDrawerProps {
  agent: Agent;
  restartAgent: (agent: Agent) => void;
}

export const AgentDrawerHeader: React.FC<AgentDrawerHeaderProps> = ({
  agent,
  onClose,
  restartAgent,
}) => {
  return (
    <Stack direction="row" columnGap={2} alignItems="center">
      <IconButton onClick={onClose} sx={{ height: "fit-content" }}>
        <Close />
      </IconButton>
      <Stack rowGap={1}>
        <Typography variant="body3">agent for</Typography>
        <Typography variant="h3">{agent.clusterName}</Typography>
      </Stack>
      {!agent.inactive && (
        <Button
          sx={{
            marginLeft: "auto",
            height: "fit-content",
            alignSelf: "flex-start",
          }}
          variant="outlined"
          onClick={() => restartAgent(agent)}
        >
          Restart
        </Button>
      )}
    </Stack>
  );
};
