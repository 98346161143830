import ReactMarkdown, { Components, Options } from "react-markdown";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { Pre } from "components/komodor-ui/Markdown/Pre";
import { urlTransform } from "components/komodor-ui/Markdown/urlTransform";

const markdownComponents: Components = {
  p: ({ ref, ...props }) => <Typography variant="body2" {...props} />,
  pre: Pre,
  code: ({ ref, ...props }) => <Typography variant="code1" {...props} />,
  a: ({ ref, ...props }) => (
    <Link target="_blank" rel="noopener noreferrer" {...props} />
  ),
};

export const Markdown = ({ components, ...props }: Options) => {
  return (
    <ReactMarkdown
      components={{ ...markdownComponents, ...components }}
      urlTransform={urlTransform}
      {...props}
    />
  );
};
