import { getAppConfig } from "../../config/appConfig";

import { useCreateAxiosClient } from "@/shared/hooks/useCreateAxiosClient";

export const useK8sAddonsApiClient = (skipWorkspaceId?: boolean) => {
  return useCreateAxiosClient(getAppConfig().addonsAPIServiceServerURL, {
    skipAppViewInHeaders: skipWorkspaceId,
    skipWorkspaceHeader: skipWorkspaceId,
  });
};
