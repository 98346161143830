import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import {
  apiV1EventCountsLimitsGetUrl,
  EventCountLimits,
  EventsCountsApiApiV1EventCountsLimitsGetRequest,
} from "@/generated/accounts";

const EVENT_LIMIT_PATH = "/event-count/limits";

interface EventsLimitDateResponse {
  eventsCountStartEpoch: number | null;
  eventsLimitEpoch: number | null;
  eventsTotalSum: number | null;
}

const getAccountEventLimit = async (
  apiClient: AxiosInstance,
  params: EventsCountsApiApiV1EventCountsLimitsGetRequest | null
): Promise<EventCountLimits> => {
  if (!params) {
    return {} as EventCountLimits;
  }
  const { data } = await apiClient.get(
    apiV1EventCountsLimitsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAccountEventLimit = (
  enabled: boolean
): { fetchEventsLimits: EventsLimitDateResponse } | null => {
  const apiClient = useAccountsApiClient();
  const { accountId } = useUserMetadata();
  const { data } = useQuery([EVENT_LIMIT_PATH], async () =>
    getAccountEventLimit(apiClient, enabled ? { accountId } : null)
  );
  return data
    ? {
        fetchEventsLimits: {
          eventsCountStartEpoch: data?.collectedFrom ?? null,
          eventsLimitEpoch: data?.collectedTo ?? null,
          eventsTotalSum: data?.sumEvents ?? null,
        },
      }
    : null;
};
