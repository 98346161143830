import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export const Loading: React.FC = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      aria-label="Loading overlay"
    >
      <CircularProgress />
    </Stack>
  );
};
