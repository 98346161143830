import { ChatInput, LightTooltip } from "@komodorio/design-system/komodor-ui";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Loop from "@mui/icons-material/Loop";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { FeatureTag } from "@/shared/components/FeatureTag";

export const CHAT_INPUT_PLACEHOLDER = "Ask me a question about this issue";

const {
  ChatResetButton,
  ChatInput: ChatInputLabel,
  ChatInputSkeleton,
} = AriaLabels.KlaudiaChat;

interface ChatFooterProps {
  input: string;
  setInput: (value: string) => void;
  handleSendMessage: () => void;
  resetChat: () => void;
  isChatAllowed: boolean;
  isLoading?: boolean;
  disableSend?: boolean;
  disableInput?: boolean;
  disableReset?: boolean;
}

export const ChatFooter: React.FC<ChatFooterProps> = ({
  input,
  setInput,
  handleSendMessage,
  resetChat,
  isChatAllowed,
  isLoading,
  disableSend,
  disableInput,
  disableReset,
}) => {
  return (
    <>
      <Divider />
      {!isChatAllowed ? (
        <Alert
          variant="outlined"
          severity="info"
          color="running"
          sx={{ border: "none" }}
        >
          <Typography variant="body2" color="inherit">
            We're unable to continue this chat because the investigation was
            handled by someone else.
          </Typography>
        </Alert>
      ) : (
        <Box
          display="grid"
          gridTemplateColumns="max-content auto max-content"
          gap="8px"
          alignItems="center"
          padding="10px 16px"
        >
          <Typography
            variant="h5"
            marginRight="8px"
            display="flex"
            alignItems="center"
            gap="8px"
          >
            Chat with Klaudia <FeatureTag tag="beta" />
          </Typography>
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height="100%"
              aria-label={ChatInputSkeleton}
            />
          ) : (
            <ChatInput
              value={input}
              size="medium"
              onChange={(e) => setInput(e.target.value)}
              onSend={handleSendMessage}
              placeholder={CHAT_INPUT_PLACEHOLDER}
              disableSend={disableSend}
              disabled={disableInput}
              focusOnMount={false}
              aria-label={ChatInputLabel}
            />
          )}
          <LightTooltip placement="top" title="Reset conversation">
            <IconButton
              size="small"
              onClick={resetChat}
              color="primary"
              disabled={disableReset}
              aria-label={ChatResetButton}
            >
              <Loop fontSize="small" />
            </IconButton>
          </LightTooltip>
        </Box>
      )}
    </>
  );
};
