import Typography from "@mui/material/Typography";
import styled from "styled-components";
import {
  Deploy24,
  Namespaces24,
  WorkloadMonitor24,
} from "@komodorio/design-system/icons";
import { muiPalette } from "@komodorio/design-system";
import Stack from "@mui/material/Stack";

const Header = styled.div`
  background-color: ${muiPalette.background?.default};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-bottom: 1px solid ${muiPalette.divider};
`;

const Links = styled.div`
  display: grid;
  grid-template-columns: max-content;
  gap: 16px;
  justify-content: center;
`;

const QuickLink = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 24px;
  border: 1px solid ${muiPalette.divider};
  border-radius: 32px;
  cursor: pointer;
`;

export const WelcomePage: React.FC = () => {
  return (
    <Stack spacing={4} height="100%">
      <Header>
        <Typography variant="h2">👋 Welcome!</Typography>
        <Typography variant="h3">What can I help you with?</Typography>
      </Header>
      <Links>
        <QuickLink>
          <WorkloadMonitor24 color={muiPalette.action?.active} />
          <Typography>
            Show me cascading issues from the last 48 hours
          </Typography>
        </QuickLink>
        <QuickLink>
          <Deploy24 color={muiPalette.action?.active} />
          <Typography>
            Which recent deploys caused failures in dev clusters?
          </Typography>
        </QuickLink>
        <QuickLink>
          <Namespaces24 color={muiPalette.action?.active} />
          <Typography>
            Find unhealthy namespaces with the highest number of failed services
          </Typography>
        </QuickLink>
      </Links>
    </Stack>
  );
};
