import React, { useCallback, useMemo } from "react";
import {
  useValueSelector,
  UseRule,
  UseValueSelectorParams,
  BaseOption,
  OptionGroup,
} from "react-querybuilder";

import { SingleSelect } from "../Select";

import { ReadonlyBox } from "./ReadonlyBox";
import { ariaLabels } from "./constants";
import { isBaseOption } from "./types";

export const CustomFieldSelector = (
  props: UseRule & UseValueSelectorParams
) => {
  const valueSelectorProps = useValueSelector(props);
  const selector = useMemo(
    () => ({ ...props, ...valueSelectorProps }),
    [props, valueSelectorProps]
  );

  const { onPropChange } = props.actions;

  const onChangeField = useCallback(
    (selected) =>
      onPropChange("field", selected.value, selector.path, props.context),
    [onPropChange, props, selector]
  );

  const options = useMemo(() => {
    return selector.schema.fields.map((field: BaseOption) => ({
      label: field.label,
      value: field.value,
    }));
  }, [selector.schema.fields]);

  const selectedField = useMemo(() => {
    const selected = props.schema.fields.find(
      (field: BaseOption | OptionGroup<BaseOption>) =>
        isBaseOption(field) && field.value === props.rule.field
    ) as BaseOption;

    return {
      label: selected?.label ?? "",
      value: selected?.value ?? "",
    };
  }, [props.schema.fields, props.rule.field]);

  if (selector.fieldData.disabled) {
    return <ReadonlyBox label={selector.fieldData.label} boxType="field" />;
  }

  return (
    <SingleSelect
      options={options}
      value={selectedField}
      onChange={onChangeField}
      width="fit-content"
      selectPopoverProps={{
        width: "max-content",
      }}
      aria-label={`${ariaLabels.switcher.field} ${selectedField}`}
    />
  );
};
