import React, { useMemo } from "react";
import { MetadataProps } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { matchAgeWithUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellUtils";
import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { SeverityColor } from "@/components/k8sAddons/types";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";

export const useTopSectionProps = () => {
  const { data: certificateData } = useCertificateData();

  return useMemo(() => {
    if (!certificateData?.data)
      return {
        title: "",
        status: "",
        color: "info" as SeverityColor,
      };
    const { name, status } = certificateData.data.object.header;
    const color = certificateStatusToColor[status];
    const expiration = certificateData.data.object.status.notAfter;

    return {
      title: name,
      status,
      color,
      expiration,
    };
  }, [certificateData]);
};

export const useMetadataItems = () => {
  const { data: certificateData } = useCertificateData();

  return useMemo<MetadataProps[]>(() => {
    if (!certificateData?.data) return [];
    const { clusterName, namespace, age, generation } =
      certificateData.data.object.header;
    return [
      {
        title: "Type",
        value: <Typography variant={"body2"}>Certificate</Typography>,
      },
      {
        title: "Cluster",
        value: <Typography variant={"body2"}>{clusterName}</Typography>,
      },
      {
        title: "Namespace",
        value: <Typography variant={"body2"}>{namespace}</Typography>,
      },
      {
        title: "Age",
        value: (
          <Typography variant={"body2"}>
            {matchAgeWithUnits(["d"], age)}
          </Typography>
        ),
      },
      {
        title: "Generation",
        value: <Typography variant={"body2"}>{generation}</Typography>,
      },
    ];
  }, [certificateData]);
};
