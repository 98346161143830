import React, { useEffect } from "react";
import styled from "styled-components";
import { HttpStatusCode, isAxiosError } from "axios";
import { IntegrationType } from "komodor-types";

import { InstallationScriptStepsNumber, StepsNumber } from "../types";

import { WelcomeButton, BackButton, NextButton } from "./Buttons";

import { useInitAttributesConfigurationsMutation } from "@/generated/graphql";
import { H4Light } from "@/components/common/typography";
import { gray10 } from "@/Colors";
import { useCreateInstallation } from "@/shared/hooks/accounts-service/client/installations/useCreateInstallation";
import { contactMessage } from "@/constants";
import {
  freemiumModalNames,
  useFreemiumModalsUpdate,
} from "@/shared/hooks/useFreemiumModals";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  IHasuraMigrations,
  InitAttributesConfiguration,
} from "@/shared/constants/hasuraMigrationFeatureFlag";
import { useInitializeServiceAttributesForAccountRest } from "@/components/servicesView/servicesAttributes/useInitializeServiceAttributesForAccountRest";

export const H4Lighter = styled(H4Light)`
  color: ${gray10};
  margin-top: 0.5rem;
`;
const StepsButtonsContainer = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ center }) => (center ? "center" : "flex-end")};
  grid-row: buttons / last-line;
  gap: 1rem;
  align-items: center;
`;

type StepsButtonsProps = {
  currentInstallStep: StepsNumber | InstallationScriptStepsNumber;
  setCurrentInstallStep: React.Dispatch<
    React.SetStateAction<StepsNumber | InstallationScriptStepsNumber>
  >;
  stepsLength: number;
  showWelcomeStep: boolean;
  isClusterNameValid: boolean;
  setApiKey: React.Dispatch<React.SetStateAction<string | null>>;
  apiKey: string | null;
  clusterName: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  isSupportedDemoAccount: boolean;
};

const shouldShowWelcomeButton = (currentInstallStep: number) =>
  currentInstallStep === StepsNumber.Welcome;

const shouldHideBackButton = (
  isFirstInstallation: boolean,
  currentInstallStep: number
) => {
  if (!isFirstInstallation) {
    return currentInstallStep === StepsNumber.Cluster_Name;
  }

  return currentInstallStep === StepsNumber.Welcome;
};

const shouldDisableNextButton = (
  isLoading: boolean,
  isFirstInstallation: boolean,
  currentInstallStep: number,
  isClusterNameValid: boolean,
  stepsLength: number
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  if (isLoading) return true;

  const isClusterNameStep = isFirstInstallation
    ? false
    : currentInstallStep === StepsNumber.Cluster_Name;

  if (isClusterNameStep && !isClusterNameValid) return true;
  const lastStep = stepsLength - 1;
  return currentInstallStep === lastStep;
};

const StepsButtons: React.FC<StepsButtonsProps> = ({
  currentInstallStep,
  setCurrentInstallStep,
  stepsLength,
  showWelcomeStep,
  isClusterNameValid,
  setApiKey,
  apiKey,
  clusterName,
  isSupportedDemoAccount,
  setErrorMessage,
}) => {
  const { hasuraMigrations } = useOverridableFlags();
  const hasuraMigrationFF = hasuraMigrations as IHasuraMigrations;
  const shouldUseMigration = hasuraMigrationFF?.endpoints.includes(
    InitAttributesConfiguration
  );
  const updateOpenFreemiumModal = useFreemiumModalsUpdate();

  const { mutateAsync: createInstallation, isLoading } =
    useCreateInstallation();
  const [, initAttsConfigsGql] = useInitAttributesConfigurationsMutation();
  const { mutateAsync: initAttsConfigsRest } =
    useInitializeServiceAttributesForAccountRest();

  const installKubernetes = async () => {
    try {
      const installation = await createInstallation({
        integration: IntegrationType.KUBERNETES,
        configuration: {
          labels: {
            clusterName: clusterName,
          },
        },
      });
      setApiKey(installation.id);
      if (shouldUseMigration) {
        await initAttsConfigsRest();
      } else {
        initAttsConfigsGql();
      }
      return installation.id;
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.status === HttpStatusCode.Forbidden
      ) {
        updateOpenFreemiumModal?.(freemiumModalNames.CLUSTERS_RESTRICTION);
      } else {
        setErrorMessage(contactMessage);
      }
      return null;
    }
  };

  const isShouldWelcomeButton = shouldShowWelcomeButton(currentInstallStep);

  const isShouldHideBackButton = shouldHideBackButton(
    showWelcomeStep,
    currentInstallStep
  );

  const isShouldDisableNextButton = shouldDisableNextButton(
    isLoading,
    showWelcomeStep,
    currentInstallStep,
    isClusterNameValid,
    stepsLength
  );

  useEffect(() => {
    const shouldCreateApiKey = !apiKey && showWelcomeStep;
    if (shouldCreateApiKey) {
      installKubernetes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, showWelcomeStep]);

  return (
    <StepsButtonsContainer center={currentInstallStep === StepsNumber.Welcome}>
      {isShouldWelcomeButton ? (
        <WelcomeButton
          currentInstallStep={currentInstallStep}
          setCurrentInstallStep={setCurrentInstallStep}
          isSupportedDemoAccount={isSupportedDemoAccount}
        />
      ) : (
        <>
          {!isShouldHideBackButton && (
            <BackButton
              currentInstallStep={currentInstallStep}
              setCurrentInstallStep={setCurrentInstallStep}
              isSupportedDemoAccount={isSupportedDemoAccount}
            />
          )}
          <NextButton
            currentInstallStep={currentInstallStep}
            isFirstInstallation={showWelcomeStep}
            setCurrentInstallStep={setCurrentInstallStep}
            disabled={isShouldDisableNextButton}
            installKubernetes={installKubernetes}
          />
        </>
      )}
    </StepsButtonsContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StepsButtons;
