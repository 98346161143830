import { useQuery } from "@tanstack/react-query";

import {
  apiV1ClustersPodsDataGetUrl,
  ClustersApiApiV1ClustersPodsDataGetRequest,
  ClustersPodsDataResponse,
} from "@/generated/metricsApi";
import { useMetricsApiClient } from "@/shared/hooks/metrics-api/client/apiClient";

export const METRICS_CLUSTERS_PODS_DATA_ENDPOINT = "/clusters/pods-data";

const MINUTE = 60 * 1000;
const PODS_DATA_STALE_TIME = 60 * MINUTE;

export const useFetchPodsData = (
  params: ClustersApiApiV1ClustersPodsDataGetRequest,
  enabled: boolean
) => {
  const apiClient = useMetricsApiClient();
  let url = "";
  try {
    url = apiV1ClustersPodsDataGetUrl(params, apiClient.defaults.baseURL ?? "");
  } catch (error) {
    // there was an issue getting the url, probably due to a missing parameter
  }

  return useQuery(
    [METRICS_CLUSTERS_PODS_DATA_ENDPOINT, params],
    async () => await apiClient.get<ClustersPodsDataResponse>(url),
    {
      enabled: enabled && !!url,
      staleTime: PODS_DATA_STALE_TIME,
    }
  );
};
