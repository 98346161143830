import React, { useRef } from "react";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

export const Value = ({ value }: { value: string }) => {
  const ref = useRef<HTMLElement>(null);
  const isTruncated =
    ref.current?.scrollWidth ?? 0 > (ref.current?.clientWidth ?? 0);

  return (
    <LightTooltip title={isTruncated ? value : ""} placement="top">
      <Typography ref={ref} variant="body2" noWrap>
        {value}
      </Typography>
    </LightTooltip>
  );
};
