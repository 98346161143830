import styled from "styled-components";
import { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";

import { ComparisonTabs } from "./Services/ComparisonTabs";
import { DriftComparisonContextProvider } from "./context/DriftSelectionContext";
import { DriftAnalysisTabType } from "./types";
import { DriftAnalysisTabs } from "./DriftAnalysisTabs";
import { HelmDriftView } from "./Helm/HelmDriftView";
import { DriftType } from "./context/types";
import { ServicesComparisonSelection } from "./Services/ServicesComparisonSelection";
import { SHOW_ONLY_DRIFT_SERVICES_KEY } from "./Services/constants";
import { SHOW_ONLY_DRIFT_HELM_KEY } from "./Helm/constants";

import Divider from "@/components/monitorsView/common/Divider";
import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DRIFT_ANALYSIS_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-block-start: 24px;
`;
const HeaderContainer = styled.div`
  padding: 0 16px 0px 24px;
  background-color: ${muiColors.common.white};
`;

export const DriftAnalysisView: React.FC = () => {
  const services = useKomodorServices().servicesAsDictionary.services;
  const numServices = useMemo(() => Object.keys(services).length, [services]);
  const [selectedTab] = useStringifiedStateInSearchParams<DriftAnalysisTabType>(
    DRIFT_ANALYSIS_TAB_PARAM_KEY
  );
  const { helmDrift } = useOverridableFlags();
  const { canManageHelm } = useHasPermissions();

  const showHelmDriftTab = useMemo(
    () => !!helmDrift && canManageHelm,
    [helmDrift, canManageHelm]
  );
  const showHelmDrift = useMemo(
    () => showHelmDriftTab && selectedTab === DriftAnalysisTabType.HelmReleases,
    [selectedTab, showHelmDriftTab]
  );
  const showServices = useMemo(
    () => !helmDrift || selectedTab === DriftAnalysisTabType.Services,
    [helmDrift, selectedTab]
  );
  return (
    <>
      {showHelmDriftTab && (
        <HeaderContainer>
          <DriftAnalysisTabs />
        </HeaderContainer>
      )}

      <Divider />
      <Container>
        {showServices ? (
          <DriftComparisonContextProvider
            driftType={DriftType.Services}
            showOnlyDriftKey={SHOW_ONLY_DRIFT_SERVICES_KEY}
          >
            <ServicesComparisonSelection
              key={`comparison-selection-${numServices}`}
            />
            <Divider />
            <ResponsiveLayout>
              <ComparisonTabs />
            </ResponsiveLayout>
          </DriftComparisonContextProvider>
        ) : showHelmDrift ? (
          <DriftComparisonContextProvider
            driftType={DriftType.Helm}
            showOnlyDriftKey={SHOW_ONLY_DRIFT_HELM_KEY}
          >
            <HelmDriftView />
          </DriftComparisonContextProvider>
        ) : null}
      </Container>
    </>
  );
};
