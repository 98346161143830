import { useMemo } from "react";

import { nowTime, ONE_WEEK_IN_MS } from "@/shared/constants/time";

export const usePastWeekEpochs = () => {
  return useMemo(() => {
    const currentDate = Math.round(nowTime.getTime() / 1_000);
    return {
      fromEpoch: currentDate - ONE_WEEK_IN_MS / 1000,
      toEpoch: currentDate,
    };
  }, []);
};
