import styled from "styled-components";

import { muiPalette } from "themes";

const LENGTH = "8px";
const DOT_SIZE = "5px";
const DOT_COLOR = muiPalette.text.disabled;

export const TypingIndicator = styled.div`
  width: ${DOT_SIZE};
  margin: 8px;
  aspect-ratio: 1;
  border-radius: 100%;
  animation: l5 1s infinite linear alternate;

  @keyframes l5 {
    0% {
      box-shadow: ${LENGTH} 0 ${DOT_COLOR}, -${LENGTH} 0 #0002;
      background: ${DOT_COLOR};
    }
    33% {
      box-shadow: ${LENGTH} 0 ${DOT_COLOR}, -${LENGTH} 0 #0002;
      background: #0002;
    }
    66% {
      box-shadow: ${LENGTH} 0 #0002, -${LENGTH} 0 ${DOT_COLOR};
      background: #0002;
    }
    100% {
      box-shadow: ${LENGTH} 0 #0002, -${LENGTH} 0 ${DOT_COLOR};
      background: ${DOT_COLOR};
    }
  }
`;
