import React from "react";
import { HelmChartSummary } from "komodor-types";
import styled from "styled-components";

// eslint-disable-next-line import/no-cycle
import HelmChart, {
  getResourceStatusByChartStatus,
} from "@/components/Inspection/Helm/helmChart";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { EnableAiBanner } from "@/components/AiInvestigation/AiBanner/EnableAiBanner";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";
import { FlowType } from "@/components/AiInvestigation/types";
import { ResourceStatusEnum } from "@/components/ResourceView/headers/common";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { AriaLabels } from "@/shared/config/ariaLabels";

const StyledContainer = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

export type HelmAiInvestigationTabProps = {
  resource: HelmChart;
  runInBackground?: boolean;
};

const {
  ChartReleaseDrawer: { RootCauseAnalysisTab: rcaAriaLabel },
} = AriaLabels;

export const HelmAiInvestigationTab: React.FC<HelmAiInvestigationTabProps> = ({
  resource,
  runInBackground,
}) => {
  const { data: features } = useAccountFeatures();
  const isHealthy =
    getResourceStatusByChartStatus(resource.status) !==
    ResourceStatusEnum.Failed;

  if (!resource.id) {
    return null;
  }

  const sessionId = `${resource.id}-${resource.currentRevision}`;

  const chartSummary: HelmChartSummary = {
    cluster: resource.cluster,
    namespace: resource.namespace,
    name: resource.name,
    revision: resource.currentRevision,
    status: resource.status,
    secretId: resource.id,
    version: resource.appVersion,
    lastUpdated: "",
  };

  return (
    <StyledContainer aria-label={rcaAriaLabel} visible={!runInBackground}>
      {!isHealthy && !features.klaudiaAi && (
        <EnableAiBanner title="Root Cause Analysis" />
      )}
      <AiInvestigation
        drawerData={{
          drawerType: DrawerType.HelmReleaseDrawer,
          chartSummary: chartSummary,
        }}
        requestId={sessionId}
        resource={resource}
        isHealthy={false}
        flowType={FlowType.HelmReleaseIssue}
        backgroundMode={runInBackground}
        maxHeight="100%"
      />
    </StyledContainer>
  );
};
