import React, { memo, useCallback, useMemo } from "react";
import useClipboard from "react-use-clipboard";
import { parseISO } from "date-fns";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import { muiColors, muiTheme } from "@komodorio/design-system";
import {
  Check16,
  Copy16,
  Trash16,
  Reload16,
} from "@komodorio/design-system/icons";
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";

import { API_KEY_ACTIONS_CLASSNAME, ariaLabels } from "./constants";

import { Agent } from "@/generated/agents";
import { useListAgents } from "@/shared/hooks/agents-api/agents/useListAgents";
import { LinesLoader } from "@/components/common/loaders/Line";
import { getUniqueValues } from "@/shared/utils/selectInputOptions";
import { useUpdateInstallation } from "@/shared/hooks/accounts-service/client/installations/useUpdateInstallation";
import { APIKeyCellProps } from "@/pages/organization-settings/account/AgentsPage/types";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

const STATUS_SIZE = 6;

export const AgentStatus: React.FC<{ inactive: boolean }> = ({ inactive }) => {
  const color = inactive
    ? muiTheme.palette.action.disabled
    : muiTheme.palette.success.main;
  return (
    <Box
      width={STATUS_SIZE}
      height={STATUS_SIZE}
      borderRadius={STATUS_SIZE * 2}
      bgcolor={color}
      data-inactive={inactive}
      margin={"0 auto"}
    />
  );
};

export const DateCell = (params: GridRenderCellParams<Agent>) => {
  const { format } = useDateFormatter({
    timeZoneName: undefined,
    year: "2-digit",
  });
  return (
    <Typography variant="body2" sx={{ textWrap: "initial" }}>
      {format(parseISO(params.value))}
    </Typography>
  );
};

export const APIKeyCell = (
  params: Omit<APIKeyCellProps, "setRestartAgent">
) => {
  const [copied, copy] = useClipboard(params.row.installationId || "", {
    successDuration: 3000,
  });

  if (!params.row.installationId) {
    return null;
  }

  return (
    <Stack
      direction="row"
      columnGap={1}
      aria-label={ariaLabels.apiKeyCell}
      alignItems="center"
    >
      <Typography variant="body2">
        {"*".repeat(8) + params.row.installationId.slice(-4)}
      </Typography>
      <Stack
        direction="row"
        className={API_KEY_ACTIONS_CLASSNAME}
        aria-label={API_KEY_ACTIONS_CLASSNAME}
        sx={{ visibility: params.visibility }}
      >
        <Tooltip title={copied ? "Copied" : "Copy"} placement="top">
          <IconButton
            size="small"
            onClick={(e: React.FormEvent<HTMLButtonElement>) => {
              e.preventDefault();
              e.stopPropagation();
              copy();
            }}
            aria-label={ariaLabels.copyApiKey}
          >
            {copied ? (
              <Check16 color={muiTheme.palette.success.main} />
            ) : (
              <Copy16 />
            )}
          </IconButton>
        </Tooltip>
        <RemoveAPIKeyModalWrapper
          apikey={params.row.installationId}
          refetch={params.refetch}
        />
      </Stack>
    </Stack>
  );
};

export const ColumnHeader = (params: GridColumnHeaderParams<Agent>) => (
  <Typography variant="h5" sx={{ textWrap: "initial" }}>
    {params.colDef.headerName}
  </Typography>
);

export const TextCell = (params: GridRenderCellParams<Agent>) => (
  <Tooltip
    title={params.value}
    placement="top"
    componentsProps={lightMuiTooltipStyle}
  >
    <Typography noWrap variant="body2">
      {params.value}
    </Typography>
  </Tooltip>
);

export const RemoveAPIKeyModalContent: React.FC<{
  apiKey: string;
}> = ({ apiKey }) => {
  const { data, isFetching } = useListAgents({
    params: {
      inactive: false,
      installationId: apiKey,
    },
  });

  const clusters = useMemo(
    () => (isFetching || !data ? [] : getUniqueValues(data, "clusterName")),
    [data, isFetching]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body2">
        Are you sure you want to remove API key?
      </Typography>
      {isFetching ? (
        <LinesLoader />
      ) : (
        <Stack direction="column" rowGap={1}>
          <Typography variant="body2">
            The API will be removed from the following clusters (
            {clusters?.length} clusters)
          </Typography>
          <List
            sx={{
              height: 220,
              maxWidth: 360,
              overflow: "auto",
              border: `1px solid ${muiColors.gray[300]}`,
              borderRadius: "4px",
              paddingTop: 0,
            }}
          >
            {clusters?.map((agent: Agent) => (
              <Box
                borderBottom={`1px solid ${muiColors.gray[200]}`}
                padding="4.5px 8px"
                key={agent.clusterName}
              >
                <Typography variant="body2">{agent.clusterName}</Typography>
              </Box>
            ))}
          </List>
        </Stack>
      )}
    </Stack>
  );
};

export const RemoveAPIKeyModalWrapper: React.FC<{
  apikey: string;
  refetch: () => void;
}> = memo(({ apikey, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const { mutateAsync: updateIntegration } = useUpdateInstallation();

  const deleteInstallation = useCallback(async () => {
    await updateIntegration({
      id: apikey,
      installationUpdateRequest: { isDeleted: true },
    });
  }, [apikey, updateIntegration]);

  const handleOpen = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = useCallback(
    async (confirmed: boolean) => {
      if (confirmed) {
        await deleteInstallation();
        refetch();
      }
      setOpen(false);
    },
    [refetch, deleteInstallation]
  );

  return (
    <>
      {open && (
        <ConfirmationDialog
          title="Remove API Key"
          content={<RemoveAPIKeyModalContent apiKey={apikey} />}
          onClose={handleClose}
          okText="Remove"
          height="unset"
          type="error"
        />
      )}
      <Tooltip title="Delete" placement="top">
        <IconButton
          size="small"
          sx={{ color: muiTheme.palette.error.dark }}
          onClick={handleOpen}
          aria-label={ariaLabels.deleteApiKey}
        >
          <Trash16 />
        </IconButton>
      </Tooltip>
    </>
  );
});

export const RestartCell = (params: APIKeyCellProps) => {
  const { row: selectedAgent, setRestartAgent } = params;

  if (selectedAgent.inactive) {
    return null;
  }

  return (
    <>
      <Tooltip title="Restart agent">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setRestartAgent(selectedAgent);
          }}
        >
          <Reload16 />
        </IconButton>
      </Tooltip>
    </>
  );
};
