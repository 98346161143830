export const createPolicyAriaLabels = {
  policyForm: {
    name: "Policy name",
    description: "Policy description",
    createButton: "Create Policy",
  },
  buttons: {
    addStatement: "Add statement",
    viewJson: "View JSON",
  },
  addStatementDrawer: "Add statement drawer",
  statements: {
    barContainer: "Policy statements",
    editStatement: "Edit statement",
    deleteStatement: "Delete statement",
  },
  actionsPanel: {
    selectedActions: "Selected actions",
  },
  statementForm: {
    title: "Statement form title",
    submitButton: "Statement form submit",
    cancelButton: "Cancel",
    previewDefinition: "Preview definition",
    closeButton: "Close statement drawer",
  },
  showJSONDrawer: "Show JSON drawer",
};
