import { useState, useMemo, useEffect, useCallback } from "react";

import { sendResourceInvestigationStateEvent } from "./analytics";
import { useIsAiEnabledForAccount } from "./useIsAiEnabledForAccount";

import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import {
  KLAUDIA_SESSION_ID,
  DRAWER_STACK_STATE_URL_PARAM,
  KLAUDIA_CHAT_SESSION_ID,
} from "@/shared/config/urlSearchParamsKeys";
import { useAiInvestigations } from "@/shared/context/aiInvestigations/useAiInvestigations";
import {
  useGetLatestSessionForResource,
  useGetSessionById,
} from "@/shared/hooks/ai-api/client/sessions/useGetSession";
import { usePostSession } from "@/shared/hooks/ai-api/client/sessions/usePostSession";
import { drawerAtSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import {
  useDrawersStackStore,
  DrawersStackState,
  getURLSearchParams,
} from "@/shared/store/drawersStackStore/drawersStackStore";
import {
  DrawerStatePush,
  DrawerStateType,
} from "@/shared/store/drawersStackStore/types";
import { copyContentToClipboard } from "@/shared/utils/copyContentToClipboard";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import {
  FlowType,
  ResourceDataForAiInvestigation,
} from "@/components/AiInvestigation/types";
import { INVESTIGATION_POLL_INTERVAL } from "@/components/AiInvestigation/AiInvestigationContent/constants";
import { useEnrichedSession } from "@/components/AiInvestigation/useEnrichedSession";
import { useAiInvestigationStore } from "@/shared/store/aiInvestigationStore/aiInvestigationStore";
import {
  chatSessionIdToShareSelector,
  setChatSessionIdToShareSelector,
} from "@/shared/store/aiInvestigationStore/aiInvestigationSelectors";

type ResourceAiInvestigationParams = {
  requestId: string;
  resource?: ResourceDataForAiInvestigation;
  isHealthy?: boolean;
  sessionId?: string;
  flowType: FlowType;
  drawerData: DrawerStatePush;
  disableTrigger?: boolean;
  backgroundMode: boolean | undefined;
};

export const useResourceAiInvestigation = ({
  requestId,
  resource,
  isHealthy,
  flowType,
  drawerData,
  sessionId,
  disableTrigger,
  backgroundMode,
}: ResourceAiInvestigationParams) => {
  const enabled = useIsAiEnabledForAccount();
  const { addMinimizedSession } = useAiInvestigations();
  const [shouldPollSession, setShouldPollSession] = useState<boolean>(false);
  const [datadogLoadingActionSent, setDatadogLoadingActionSent] =
    useState<boolean>(false);
  const [sharedSessionId, setSharedSessionId] =
    useDrawerUrlState<string>(KLAUDIA_SESSION_ID);
  const sessionIdToUse = sessionId || sharedSessionId;

  const sessionResource = useMemo(
    () => ({
      requestId,
      cluster: resource?.cluster ?? "",
      namespace: resource?.namespace ?? "",
      name: resource?.name ?? "",
      kind: resource?.kind ?? "",
    }),
    [requestId, resource]
  );

  const {
    mutate: triggerSession,
    isError: isTriggerSessionError,
    isLoading: isTriggerSessionLoading,
    isSuccess: isTriggerSessionSuccess,
  } = usePostSession(sessionResource, {
    onMutate: () => {
      if (backgroundMode) return;
      addMinimizedSession(flowType, sessionResource, drawerData);
    },
  });

  const sessionRes = useGetLatestSessionForResource(sessionResource, {
    enabled:
      enabled && !!resource && sessionIdToUse === null && shouldPollSession,
    refetchInterval: INVESTIGATION_POLL_INTERVAL,
    staleTime: 0,
    cacheTime: 0,
  });
  const sharedSessionRes = useGetSessionById(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { id: sessionIdToUse! },
    {
      enabled: enabled && sessionIdToUse !== null,
    }
  );
  const {
    data,
    isError: isGetSessionError,
    refetch: refetchGetSession,
  } = sessionIdToUse !== null ? sharedSessionRes : sessionRes;
  const session = data?.session;

  useEffect(() => {
    const isInvestigationRunning =
      session?.isComplete === false && session.isStuck === false;
    const isSessionTriggered =
      isTriggerSessionSuccess && !isTriggerSessionLoading;
    const isNoSessionExists = session === undefined && !isGetSessionError;

    setShouldPollSession(
      isInvestigationRunning || isSessionTriggered || isNoSessionExists
    );
  }, [
    isGetSessionError,
    isTriggerSessionLoading,
    isTriggerSessionSuccess,
    session,
  ]);

  useEffect(() => {
    if (
      !disableTrigger &&
      enabled &&
      sessionIdToUse === null &&
      session === null &&
      !isTriggerSessionSuccess &&
      !isHealthy
    ) {
      triggerSession();
    }
  }, [
    isHealthy,
    session,
    sessionIdToUse,
    triggerSession,
    enabled,
    isTriggerSessionSuccess,
    disableTrigger,
  ]);

  const isLoading = useMemo(
    () => !session || session?.isComplete === false,
    [session]
  );

  const isError = useMemo(
    () =>
      (!session && isGetSessionError) ||
      isTriggerSessionError ||
      session?.isStuck ||
      (session?.isComplete && !session.problemShort),
    [isGetSessionError, isTriggerSessionError, session]
  );

  useEffect(() => {
    if (!enabled || !session) {
      return;
    }
    const isStuck = session.isStuck ?? false;
    if ((session.isComplete || isStuck) && !datadogLoadingActionSent) {
      sendResourceInvestigationStateEvent({
        event: AnalyticEvents.KlaudiaAi.Investigation,
        session: session,
        resource: resource,
        flowType: drawerData.drawerType,
      });
      setDatadogLoadingActionSent(true);
    }
  }, [
    datadogLoadingActionSent,
    drawerData.drawerType,
    enabled,
    resource,
    session,
  ]);

  const richSession = useEnrichedSession(resource, session ?? null);
  const currentDrawer = useDrawersStackStore(drawerAtSelector(-1));
  const chatSessionIdToShare = useAiInvestigationStore(
    chatSessionIdToShareSelector
  );
  const setChatSessionIdToShare = useAiInvestigationStore(
    setChatSessionIdToShareSelector
  );
  const onShareClicked = useCallback(async () => {
    if (!richSession) {
      return;
    }
    const url = new URL(window.location.href);
    let searchParams = url.searchParams;

    if (currentDrawer) {
      //If the share button was clicked from a drawer in the drawer stack, we update the drawer's URL states.
      const drawerState: DrawersStackState = {
        drawersStack: [
          {
            ...currentDrawer,
            urlStates: {
              ...(currentDrawer.urlStates ?? {}),
              [KLAUDIA_SESSION_ID]: richSession.id,
              ...(chatSessionIdToShare
                ? { [KLAUDIA_CHAT_SESSION_ID]: chatSessionIdToShare }
                : {}),
            },
          } as unknown as DrawerStateType,
        ],
      };
      searchParams = getURLSearchParams(
        DRAWER_STACK_STATE_URL_PARAM,
        drawerState
      );
    } else {
      //Otherwise, if the share button was clicked from a page or a drawer that doesn't use the drawer stack, we update the search parameters as usual.
      //Notice: To share investigation in drawer that is not in the drawer stack, the drawer itself must be shareable through any other method in the URL.
      searchParams.set(KLAUDIA_SESSION_ID, richSession.id);
      if (chatSessionIdToShare) {
        searchParams.set(KLAUDIA_CHAT_SESSION_ID, chatSessionIdToShare);
      }
    }

    url.search = searchParams.toString();
    await copyContentToClipboard(url.toString());
  }, [chatSessionIdToShare, currentDrawer, richSession]);

  const onRetryCallback = useCallback(() => {
    if (isGetSessionError) {
      refetchGetSession();
    } else {
      if (sharedSessionId) {
        setSharedSessionId(null);
      }
      if (chatSessionIdToShare) {
        setChatSessionIdToShare(undefined);
      }
      triggerSession();
    }
  }, [
    chatSessionIdToShare,
    isGetSessionError,
    refetchGetSession,
    setChatSessionIdToShare,
    setSharedSessionId,
    sharedSessionId,
    triggerSession,
  ]);

  return {
    enabled,
    isLoading,
    isError,
    onShareClicked,
    onRetryCallback,
    richSession,
  };
};
