import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiPalette } from "@komodorio/design-system";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const ChatAIButton: React.FC = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { klaudiaChatAmaDemo } = useOverridableFlags();
  if (!klaudiaChatAmaDemo) {
    return null;
  }

  const onClick = () => {
    pushDrawer({
      drawerType: DrawerType.KlaudiaChatDrawer,
      sessionId: "",
    });
  };

  return (
    <Tooltip title="Chat with Klaudia">
      <IconButton aria-label={AriaLabels.TopBar.ChatAIButton} onClick={onClick}>
        <MagicAi24 color={muiPalette.action?.active} />
      </IconButton>
    </Tooltip>
  );
};
