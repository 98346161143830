import React, { ComponentProps } from "react";
import styled from "styled-components";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { LoaderContainer } from "@/components/reliability/ReliabilityStyles";
import { ProcessListContainerContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerStyles";
import { ProcessList } from "@/components/common/ProcessList";
import { ProcessListSortKeys } from "@/components/common/ProcessList/processListConstants";

const Container = styled.div``;

type ProcessListWrapperProps = {
  isLoadingIssues: boolean;
  processListProps: ComponentProps<typeof ProcessList>;
};

export const ProcessListWrapper: React.FC<ProcessListWrapperProps> = ({
  isLoadingIssues,
  processListProps,
}) => {
  const { eventGroups } = processListProps;
  const shouldDisplayProcessList = !isLoadingIssues && eventGroups.length > 0;

  if (!isLoadingIssues && eventGroups.length === 0) return null;

  return (
    <Container>
      {isLoadingIssues && (
        <LoaderContainer>
          <LinesLoader />
        </LoaderContainer>
      )}
      {shouldDisplayProcessList && (
        <ProcessListContainerContainer>
          <ProcessList
            showMoreEventsPanel={true}
            defaultSortKey={ProcessListSortKeys.endtime}
            {...processListProps}
          />
        </ProcessListContainerContainer>
      )}
    </Container>
  );
};
