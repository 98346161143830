import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { OptionType } from "@komodorio/design-system/deprecated";
import React, { useCallback } from "react";

import { storedLastCluster } from "../../Inspection/utils/localStorage";
import Autocomplete from "../../common/controls/Autocomplete";

import { ArgoFilterContainer, ContainedInputContainer } from "./styles";

export const ArgoTableClusterFilter: React.FC<{
  cluster: string | undefined;
  setCluster: (cluster: string) => void;
  options: OptionType<string>[];
}> = ({ cluster, setCluster, options }) => {
  const onChange = useCallback(
    (value: string) => {
      storedLastCluster.set(value);
      setCluster(value);
    },
    [setCluster]
  );

  return (
    <ArgoFilterContainer>
      <Typography variant={"h6"} color={muiColors.gray[400]}>
        Cluster
      </Typography>
      <ContainedInputContainer>
        <Autocomplete
          fieldname="cluster"
          placeholder="choose cluster"
          defaultSelected={
            cluster ? { label: cluster, value: cluster } : undefined
          }
          suggestions={options}
          onSelectedChange={onChange}
        />
      </ContainedInputContainer>
    </ArgoFilterContainer>
  );
};
