import styled from "styled-components";

export const ArgoFilterContainer = styled.div`
  gap: 0.3rem;
  display: grid;
  width: 14rem;
  height: 3.5rem;
`;

export const InputContainer = styled.div<{ inputHeight?: string }>`
  > input {
    height: ${({ inputHeight }) => inputHeight || "1.2rem"};
    width: -webkit-fill-available;
  }
`;

export const ContainedInputContainer = styled.div<{ inputHeight?: string }>`
  > * > input {
    height: ${({ inputHeight }) => inputHeight || "1.2rem"};
    width: -webkit-fill-available;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
`;
