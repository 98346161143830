import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAccountsApiClient } from "../apiClient";

import {
  AccountFeaturesResponse,
  AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest,
  apiV1AccountsFeaturesPublicApiTtlPutUrl,
} from "@/generated/accounts";

const ACCOUNT_PUBLIC_KEYS_PATH = "/features/public-api-ttl";

const updateAccountPublicAPIKey = async (
  apiClient: AxiosInstance,
  params: AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest
): Promise<AccountFeaturesResponse> => {
  const { data } = await apiClient.put(
    apiV1AccountsFeaturesPublicApiTtlPutUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    ),
    params.accountFeatureApiKeysTTL
  );
  return data;
};

export const useUpdateAccountPublicAPIKey = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    [ACCOUNT_PUBLIC_KEYS_PATH],
    (params: AccountsApiApiV1AccountsFeaturesPublicApiTtlPutRequest) =>
      updateAccountPublicAPIKey(apiClient, params)
  );
};
