export const certificatesPageAriaLabels = {
  table: "Certificates page table",
  drawer: {
    drawerContainer: "Certificate drawer container",
    contentContainer: "Certificate content drawer",
    sections: {
      relatedResources: "Related resources section",
      status: "Status section",
      spec: "Spec section",
      annotations: "Annotations section",
      labels: "Labels section",
    },
    status: {
      info: "More info",
      statusConditions: "Status conditions",
      statusConditionsPopover: "Status conditions popover",
    },
    resourceLink: "resource link",
    failedStatusMessage: "Certificate status failed message",
  },
  statusChipWithValidation: {
    container: "Status chip with validation",
    tooltipContent: "Warning icon tooltip content",
  },
};
